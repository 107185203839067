import { fetchGet, fetchPost, fetchPut } from '~utilities/fetch';
import IUser from './IUser';
import { transformUser } from './transformers';

export async function userBlockRequest(userUuid: string): Promise<Response> {
    return fetchPost(`/users/${userUuid}/block`);
}

export async function userEmailRequest(uuid: string, email: string): Promise<Response> {
    return fetchPost(`/users/${uuid}/email`, {
        email,
    });
}

export async function userGetRequest(uuid: string): Promise<Response> {
    return fetchGet(`/users/${uuid}`);
}

export async function userHistoriesListRequest(uuid: string): Promise<Response> {
    return fetchGet(`/users/${uuid}/history`);
}

export async function usersListRequest(): Promise<Response> {
    return fetchGet('/users');
}

export async function usersSuspiciousListRequest(): Promise<Response> {
    return fetchGet('/users/suspicious');
}

export async function userPhoneRequest(uuid: string, phone: string): Promise<Response> {
    return fetchPost(`/users/${uuid}/phone`, {
        phone,
    });
}

export async function userInvestorsListRequest(uuid: string): Promise<Response> {
    return fetchGet(`/users/${uuid}/investors`);
}

export async function userUnblockRequest(userUuid: string): Promise<Response> {
    return fetchPost(`/users/${userUuid}/unblock`);
}

export async function userUpdateRequest(user: IUser): Promise<Response> {
    return fetchPut(`/users/${user.uuid}`, transformUser(user));
}
