import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '~Layout';
import Broker from './Broker';
import Dashboard from './Dashboard';
import List from './List';
import Map from './Map';

class Brokers extends React.Component {
    public render(): JSX.Element {
        return (
            <Layout topSection='brokers'>
                <Switch>
                    <Route exact={true} path='/brokers' component={Dashboard} />
                    <Route exact={true} path='/brokers/list' component={List} />
                    <Route exact={true} path='/brokers/map' component={Map} />
                    <Route path='/brokers/:brokerUuid' component={Broker} />
                </Switch>
            </Layout>
        );
    }
}

export default Brokers;
