import { AnyAction } from 'redux';
import {
    IAuthJokeSetAction,
    IAuthLoginErrorSetAction,
    IAuthLoginErrorsSetAction,
    IAuthLoginInProgressSetAction,
    IAuthLogoutAction,
} from './actions';
import ActionsEnum from './ActionsEnum';
import history from '~history';

export interface IAuthState {
    joke: string;
    loginErrors: any;
    loginInProgress: boolean;
    previousPath: string;
}

const initialData: IAuthState = {
    joke: null,
    loginErrors: {},
    loginInProgress: false,
    previousPath: null,
};

export function authReducer(state: IAuthState = initialData, action: AnyAction): IAuthState {
    switch (action.type) {
        case ActionsEnum.JokeSet: {
            const typedAction: IAuthJokeSetAction = action as IAuthJokeSetAction;

            return {
                ...state,
                joke: typedAction.joke,
            };
        }

        case ActionsEnum.LoginErrorsSet: {
            const typedAction: IAuthLoginErrorsSetAction = action as IAuthLoginErrorsSetAction;

            return {
                ...state,
                loginErrors: typedAction.errors,
            };
        }

        case ActionsEnum.LoginErrorSet: {
            const typedAction: IAuthLoginErrorSetAction = action as IAuthLoginErrorSetAction;

            return {
                ...state,
                loginErrors: {
                    ...state.loginErrors,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        case ActionsEnum.LoginInProgressSet: {
            const typedAction: IAuthLoginInProgressSetAction = action as IAuthLoginInProgressSetAction;

            return {
                ...state,
                loginInProgress: typedAction.inProgress,
            };
        }

        case ActionsEnum.Logout: {
            const typedAction: IAuthLogoutAction = action as IAuthLogoutAction;

            return {
                ...state,
                previousPath: typedAction.rememberPath && history.location.pathname !== '/login' ? history.location.pathname : null,
            };
        }

        default:
            return state;
    }
}
