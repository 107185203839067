import { Modal, Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import IInvestor from '~Api/Investor/IInvestor';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import { investmentsListAction } from '~Investments/actions';
import { investmentsSelector } from '~Investments/selectors';
import { investorsListAction } from '~Investors/actions';
import { investorsSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { paymentsFromInvestorsListAction } from './actions';
import Layout from './Layout';
import { paymentsFromInvestorsSelector } from './selectors';

const historyDays: number = 30;

interface IState {
    modalIsOpen: boolean;
    modalUniqueId: string;
}

interface IPaymentFromInvestor {
    date: string;
    investmentUuid: string;
    investorAccountInvestmentTransactions: IInvestorAccountInvestmentTransaction[];
    total: number;
    uniqueId: string;
}

interface IDay {
    dailyTotal: number;
    date: string;
    investmentCount: number;
    primaryRowUniqueId?: string;
}

interface IPropsSelector {
    investments: IDictionary<IInvestment>;
    investorAccountInvestmentTransactions: IDictionary<IInvestorAccountInvestmentTransaction>;
    investors: IDictionary<IInvestor>;
}

interface IPropsDispatch {
    investmentsList: () => void;
    investorsList: () => void;
    paymentsFromInvestorsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class PaymentsFromInvestors extends React.Component<Props, IState> {
    public state: IState = {
        modalIsOpen: false,
        modalUniqueId: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickTransactions = this.onClickTransactions.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    public componentDidMount(): void {
        const { investments, investors } = this.props;

        if (!investments) {
            this.props.investmentsList();
        }

        if (!investors) {
            this.props.investorsList();
        }

        this.props.paymentsFromInvestorsList();
    }

    public render(): JSX.Element {
        const { investments, investorAccountInvestmentTransactions, investors } = this.props;
        const { modalIsOpen, modalUniqueId } = this.state;

        if (!investments || !investorAccountInvestmentTransactions || !investors) {
            return (
                <Layout section='payments-from-investors'>
                    <Typography.Title level={2}>Payments From Investors</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const days: IDictionary<IDay> = {};
        const paymentsFromInvestors: IPaymentFromInvestor[] = [];

        for (let i: number = 0; i <= historyDays; i++) {
            const loopDate: string = dayjs().subtract(i, 'days').format('YYYY-MM-DD');
            days[loopDate] = {
                dailyTotal: 0,
                date: loopDate,
                investmentCount: 0,
            };
            paymentsFromInvestors.push({
                date: loopDate,
                investmentUuid: null,
                investorAccountInvestmentTransactions: [],
                total: 0,
                uniqueId: loopDate,
            });
        }

        const dayInvestments: IDictionary<IPaymentFromInvestor> = {};

        _.each(investorAccountInvestmentTransactions, (investorAccountInvestmentTransaction: IInvestorAccountInvestmentTransaction) => {
            const transactionTimeDaysjs: Dayjs = dayjs(investorAccountInvestmentTransaction.transactionTime);
            const transactionDate: string = transactionTimeDaysjs.format('YYYY-MM-DD');

            const uniqueId: string = `${transactionDate}|${investorAccountInvestmentTransaction.investmentUuid}`;

            if (!dayInvestments[uniqueId]) {
                dayInvestments[uniqueId] = {
                    date: transactionDate,
                    investmentUuid: investorAccountInvestmentTransaction.investmentUuid,
                    investorAccountInvestmentTransactions: [],
                    total: 0,
                    uniqueId,
                };
            }

            dayInvestments[uniqueId].total += investorAccountInvestmentTransaction.principalAmount;
            dayInvestments[uniqueId].investorAccountInvestmentTransactions.push(investorAccountInvestmentTransaction);
        });

        _.each(dayInvestments, (dayInvestment: IPaymentFromInvestor) => {
            const day: IDay = days[dayInvestment.date];
            if (!day.primaryRowUniqueId) {
                day.primaryRowUniqueId = dayInvestment.uniqueId;

                const initialPaymentFromInvestor: IPaymentFromInvestor = _.find(paymentsFromInvestors, (paymentFromInvestor: IPaymentFromInvestor) => paymentFromInvestor.date === dayInvestment.date);
                initialPaymentFromInvestor.investmentUuid = dayInvestment.investmentUuid;
                initialPaymentFromInvestor.investorAccountInvestmentTransactions = dayInvestment.investorAccountInvestmentTransactions;
                initialPaymentFromInvestor.total = dayInvestment.total;
                initialPaymentFromInvestor.uniqueId = dayInvestment.uniqueId;
            } else {
                paymentsFromInvestors.push(dayInvestment);
            }

            day.investmentCount++;
            day.dailyTotal += dayInvestment.total;
        });

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnsType<IPaymentFromInvestor> = [
            {
                className: 'date',
                render: (paymentFromInvestor: IPaymentFromInvestor) => {
                    if (!paymentFromInvestor.investmentUuid) {
                        return dayjs(paymentFromInvestor.date).format('D/M');
                    }

                    const day: IDay = days[paymentFromInvestor.date];
                    return {
                        children: dayjs(paymentFromInvestor.date).format('D/M'),
                        props: {
                            // When the rowSpan of the cell is set to zero, it essentially hides the cell.
                            rowSpan: (day.primaryRowUniqueId === paymentFromInvestor.uniqueId) ? day.investmentCount : 0,
                        },
                    };
                },
                title: 'Date',
                width: '10%',
            },
            {
                className: 'day',
                render: (paymentFromInvestor: IPaymentFromInvestor) => {
                    if (!paymentFromInvestor.investmentUuid) {
                        return dayjs(paymentFromInvestor.date).format('dddd');
                    }

                    const day: IDay = days[paymentFromInvestor.date];
                    return {
                        children: dayjs(paymentFromInvestor.date).format('dddd'),
                        props: {
                            rowSpan: (day.primaryRowUniqueId === paymentFromInvestor.uniqueId) ? day.investmentCount : 0,
                        },
                    };
                },
                title: 'Day',
                width: '15%',
            },
            {
                className: 'daily-total',
                render: (paymentFromInvestor: IPaymentFromInvestor) => {
                    if (!paymentFromInvestor.investmentUuid) {
                        return '-';
                    }

                    const day: IDay = days[paymentFromInvestor.date];
                    return {
                        children: day.dailyTotal !== 0 ? currencyFormatter.format(day.dailyTotal) : '-',
                        props: {
                            rowSpan: (day.primaryRowUniqueId === paymentFromInvestor.uniqueId) ? day.investmentCount : 0,
                        },
                    };
                },
                title: 'Daily Total',
                width: '20%',
            },
            {
                dataIndex: 'investmentUuid',
                render: (investmentUuid: string) => {
                    if (!investmentUuid) {
                        return '-';
                    }

                    return (
                        <>
                            <Link to={`/investments/${investmentUuid}`}>{investments[investmentUuid].code}</Link>
                            {investments[investmentUuid].loanCode && investments[investmentUuid].loanCode !== investments[investmentUuid].code && ` (${investments[investmentUuid].loanCode})`}
                        </>
                    );
                },
                title: 'Investment',
            },
            {
                dataIndex: 'total',
                render: (total: number, paymentFromInvestor: IPaymentFromInvestor) => {
                    if (total === 0) {
                        return '-';
                    }

                    let modalBlock: JSX.Element = null;

                    if (modalUniqueId === paymentFromInvestor.uniqueId) {
                        const modalColumns: ColumnsType<IInvestorAccountInvestmentTransaction> = [
                            {
                                dataIndex: 'investorUuid',
                                render: (investorUuid: string) => <Link to={`/investors/${investorUuid}`}>{investors[investorUuid].name}</Link>,
                                title: 'Investor',
                            },
                            {
                                dataIndex: 'principalAmount',
                                render: (principalAmount: number) => currencyFormatter.format(principalAmount),
                                title: 'Amount',
                                width: '20%',
                            },
                        ];

                        modalBlock = (
                            <Modal
                                footer={false}
                                onCancel={this.onCloseModal}
                                open={modalIsOpen}
                                title='Investment Details'
                                width={1000}
                            >
                                <Table
                                    columns={modalColumns}
                                    dataSource={paymentFromInvestor.investorAccountInvestmentTransactions}
                                    rowKey='uuid'
                                    pagination={false}
                                    size='middle'
                                />
                            </Modal>
                        );
                    }

                    const onClick: () => void = () => this.onClickTransactions(paymentFromInvestor.uniqueId);

                    return (
                        <>
                            <a onClick={onClick}>{currencyFormatter.format(total)}</a>
                            {modalBlock}
                        </>
                    );
                },
                title: 'Amount',
                width: '20%',
            },
        ];

        return (
            <Layout section='payments-from-investors'>
                <Typography.Title level={2}>Payments From Investors</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(_.orderBy(paymentsFromInvestors, ['date'], ['desc']))}
                    rowKey='uniqueId'
                    pagination={false}
                    size='middle'
                />
            </Layout>
        );
    }

    private onClickTransactions(uniqueId: string): void {
        this.setState({
            modalIsOpen: true,
            modalUniqueId: uniqueId,
        });
    }

    private onCloseModal(): void {
        this.setState({
            modalIsOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        investments: investmentsSelector(state),
        investorAccountInvestmentTransactions: paymentsFromInvestorsSelector(state),
        investors: investorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        investmentsList: () => dispatch(investmentsListAction()),
        investorsList: () => dispatch(investorsListAction()),
        paymentsFromInvestorsList: () => dispatch(paymentsFromInvestorsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PaymentsFromInvestors);
