import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Edit from './Edit';
import History from './History';
import Overview from './Overview';
import Passkeys from './Passkeys';

export default function router(): JSX.Element {
    return (
        <Switch>
            <Route exact={true} path='/administrators/:administratorUuid' component={Overview} />
            <Route exact={true} path='/administrators/:administratorUuid/edit' component={Edit} />
            <Route exact={true} path='/administrators/:administratorUuid/history' component={History} />
            <Route exact={true} path='/administrators/:administratorUuid/passkeys' component={Passkeys} />
        </Switch>
    );
}
