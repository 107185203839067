import { Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import IInvestmentTransaction from '~Api/Investment/IInvestmentTransaction';
import { investmentsListAction } from '~Investments/actions';
import TransactionModal from '~Investments/Investment/TransactionModal';
import { investmentsSelector } from '~Investments/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { paymentsToInvestorsListAction } from './actions';
import Layout from './Layout';
import { paymentsToInvestorsSelector } from './selectors';

const historyDays: number = 30;

interface IState {
    modalIsOpen: boolean;
    modalUuid: string;
}

interface IPaymentToInvestor {
    date: string;
    investmentTransaction?: IInvestmentTransaction;
    uuid: string;
}

interface IDay {
    dailyTotal: number;
    date: string;
    investmentTransactionCount: number;
    primaryRowUuid?: string;
}

interface IPropsSelector {
    investmentTransactions: IDictionary<IInvestmentTransaction>;
    investments: IDictionary<IInvestment>;
}

interface IPropsDispatch {
    investmentsList: () => void;
    paymentsToInvestorsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class PaymentsToInvestors extends React.Component<Props, IState> {
    public state: IState = {
        modalIsOpen: false,
        modalUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickInvestors = this.onClickInvestors.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    public componentDidMount(): void {
        const { investments } = this.props;

        if (!investments) {
            this.props.investmentsList();
        }

        this.props.paymentsToInvestorsList();
    }

    public render(): JSX.Element {
        const { investments, investmentTransactions } = this.props;
        const { modalIsOpen, modalUuid } = this.state;

        if (!investments || !investmentTransactions) {
            return (
                <Layout section='payments-to-investors'>
                    <Typography.Title level={2}>Payments To Investors</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const days: IDictionary<IDay> = {};
        const paymentsToInvestors: IPaymentToInvestor[] = [];

        for (let i: number = 0; i <= historyDays; i++) {
            const loopDate: string = dayjs().subtract(i, 'days').format('YYYY-MM-DD');
            days[loopDate] = {
                dailyTotal: 0,
                date: loopDate,
                investmentTransactionCount: 0,
            };
            paymentsToInvestors.push({
                date: loopDate,
                uuid: loopDate,
            });
        }

        _.each(investmentTransactions, (investmentTransaction: IInvestmentTransaction) => {
            const transactionTimeDaysjs: Dayjs = dayjs(investmentTransaction.transactionTime);
            const transactionDate: string = transactionTimeDaysjs.format('YYYY-MM-DD');

            const day: IDay = days[transactionDate];
            if (!day.primaryRowUuid) {
                day.primaryRowUuid = investmentTransaction.uuid;

                const initialPaymentToInvestor: IPaymentToInvestor = _.find(paymentsToInvestors, (paymentToInvestor: IPaymentToInvestor) => paymentToInvestor.date === transactionDate);
                initialPaymentToInvestor.investmentTransaction = investmentTransaction;
                initialPaymentToInvestor.uuid = investmentTransaction.uuid;
            } else {
                paymentsToInvestors.push({
                    date: transactionDate,
                    investmentTransaction,
                    uuid: investmentTransaction.uuid,
                });
            }

            day.investmentTransactionCount++;
            day.dailyTotal += investmentTransaction.amountInterest + investmentTransaction.amountPrincipal;
        });

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnsType<IPaymentToInvestor> = [
            {
                className: 'date',
                render: (paymentToInvestor: IPaymentToInvestor) => {
                    if (!paymentToInvestor.investmentTransaction) {
                        return dayjs(paymentToInvestor.date).format('D/M');
                    }

                    const day: IDay = days[paymentToInvestor.date];
                    return {
                        children: dayjs(paymentToInvestor.date).format('D/M'),
                        props: {
                            // When the rowSpan of the cell is set to zero, it essentially hides the cell.
                            rowSpan: (day.primaryRowUuid === paymentToInvestor.investmentTransaction.uuid) ? day.investmentTransactionCount : 0,
                        },
                    };
                },
                title: 'Date',
                width: '5%',
            },
            {
                className: 'day',
                render: (paymentToInvestor: IPaymentToInvestor) => {
                    if (!paymentToInvestor.investmentTransaction) {
                        return dayjs(paymentToInvestor.date).format('dddd');
                    }

                    const day: IDay = days[paymentToInvestor.date];
                    return {
                        children: dayjs(paymentToInvestor.date).format('dddd'),
                        props: {
                            rowSpan: (day.primaryRowUuid === paymentToInvestor.investmentTransaction.uuid) ? day.investmentTransactionCount : 0,
                        },
                    };
                },
                title: 'Day',
                width: '10%',
            },
            {
                className: 'daily-total',
                render: (paymentToInvestor: IPaymentToInvestor) => {
                    if (!paymentToInvestor.investmentTransaction) {
                        return '-';
                    }

                    const day: IDay = days[paymentToInvestor.date];
                    return {
                        children: day.dailyTotal !== 0 ? currencyFormatter.format(day.dailyTotal) : '-',
                        props: {
                            rowSpan: (day.primaryRowUuid === paymentToInvestor.investmentTransaction.uuid) ? day.investmentTransactionCount : 0,
                        },
                    };
                },
                title: 'Daily Total',
                width: '15%',
            },
            {
                dataIndex: 'investmentTransaction',
                render: (investmentTransaction: IInvestmentTransaction) => {
                    if (!investmentTransaction) {
                        return '-';
                    }

                    return (
                        <>
                            <Link to={`/investments/${investmentTransaction.investmentUuid}`}>{investments[investmentTransaction.investmentUuid].code}</Link>
                            {investments[investmentTransaction.investmentUuid].loanCode && investments[investmentTransaction.investmentUuid].loanCode !== investments[investmentTransaction.investmentUuid].code && ` (${investments[investmentTransaction.investmentUuid].loanCode})`}
                        </>
                    );
                },
                title: 'Investment',
            },
            {
                dataIndex: 'investmentTransaction',
                render: (investmentTransaction: IInvestmentTransaction) => {
                    if (!investmentTransaction || investmentTransaction.amountPrincipal + investmentTransaction.amountInterest <= 0) {
                        return '-';
                    }

                    const modalBlock: JSX.Element = modalUuid === investmentTransaction.uuid && (
                        <TransactionModal
                            investmentTransactionUuid={investmentTransaction.uuid}
                            isOpen={modalIsOpen}
                            onCancel={this.onCloseModal}
                        />
                    );

                    const onClick: () => void = () => this.onClickInvestors(investmentTransaction.uuid);

                    return (
                        <>
                            <a onClick={onClick}>{currencyFormatter.format(investmentTransaction.amountPrincipal + investmentTransaction.amountInterest)}</a>
                            {modalBlock}
                        </>
                    );
                },
                title: 'Total',
                width: '15%',
            },
            {
                dataIndex: 'investmentTransaction',
                render: (investmentTransaction: IInvestmentTransaction) => investmentTransaction && investmentTransaction.amountPrincipal !== 0 ? currencyFormatter.format(investmentTransaction.amountPrincipal) : '-',
                title: 'Principal',
                width: '15%',
            },
            {
                dataIndex: 'investmentTransaction',
                render: (investmentTransaction: IInvestmentTransaction) => investmentTransaction && investmentTransaction.amountInterest !== 0 ? currencyFormatter.format(investmentTransaction.amountInterest) : '-',
                title: 'Interest',
                width: '15%',
            },
        ];

        return (
            <Layout section='payments-to-investors'>
                <Typography.Title level={2}>Payments To Investors</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(_.orderBy(paymentsToInvestors, ['date'], ['desc']))}
                    rowKey='uuid'
                    pagination={false}
                    size='middle'
                />
            </Layout>
        );
    }

    private onClickInvestors(transactionUuid: string): void {
        this.setState({
            modalIsOpen: true,
            modalUuid: transactionUuid,
        });
    }

    private onCloseModal(): void {
        this.setState({
            modalIsOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        investmentTransactions: paymentsToInvestorsSelector(state),
        investments: investmentsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        investmentsList: () => dispatch(investmentsListAction()),
        paymentsToInvestorsList: () => dispatch(paymentsToInvestorsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PaymentsToInvestors);
