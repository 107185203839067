import { Form, Input, InputNumber, Modal, Select, Tabs, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AddressVersionEnum from '~Api/Deal/AddressVersionEnum';
import IProperty from '~Api/Deal/IProperty';
import OwnershipTypeEnum from '~Api/Deal/OwnershipTypeEnum';
import PropertyPurposeEnum from '~Api/Deal/PropertyPurposeEnum';
import PropertyStateEnum from '~Api/Deal/PropertyStateEnum';
import PropertyStreetTypeEnum from '~Api/Deal/PropertyStreetTypeEnum';
import propertyStreetTypeLabels from '~Api/Deal/PropertyStreetTypeLabels';
import StrataTypeEnum from '~Api/Deal/StrataTypeEnum';
import TenancyTypeEnum from '~Api/Deal/TenancyTypeEnum';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';
import DatePicker from '~UI/DatePicker';
import { dealPropertiesAddAction } from './actions';
import './deals.less';

interface IState {
    currentDebt: number;
    dwellingCount: number;
    estimatedValue: number;
    folioNumber: string;
    insuranceExpiryDate: string;
    insuranceReplacementValue: number;
    lotNumber: string;
    lotSizeSquareMetres: number;
    ownershipType: OwnershipTypeEnum;
    planNumber: string;
    postcode: string;
    purpose: PropertyPurposeEnum;
    state: PropertyStateEnum;
    strataType: StrataTypeEnum;
    streetName: string;
    streetNumber: string;
    streetType: PropertyStreetTypeEnum;
    suburb: string;
    tenancyType: TenancyTypeEnum;
    unitNumber?: string;
    volumeNumber: string;
    zoneType: ZoneTypeEnum;
}

interface IProps {
    dealUuid: string;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsDispatch {
    propertyAdd: (property: IProperty) => void;
}

type Props = IProps & IPropsDispatch;

class PropertyModal extends React.Component<Props, IState> {
    public state: IState = {
        currentDebt: null,
        dwellingCount: null,
        estimatedValue: null,
        folioNumber: null,
        insuranceExpiryDate: null,
        insuranceReplacementValue: null,
        lotNumber: null,
        lotSizeSquareMetres: null,
        ownershipType: null,
        planNumber: null,
        postcode: null,
        purpose: null,
        state: null,
        strataType: null,
        streetName: null,
        streetNumber: null,
        streetType: null,
        suburb: null,
        tenancyType: null,
        unitNumber: null,
        volumeNumber: null,
        zoneType: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeCurrentDebt = this.onChangeCurrentDebt.bind(this);
        this.onChangeDwellingCount = this.onChangeDwellingCount.bind(this);
        this.onChangeEstimatedValue = this.onChangeEstimatedValue.bind(this);
        this.onChangeFolioNumber = this.onChangeFolioNumber.bind(this);
        this.onChangeInsuranceExpiryDate = this.onChangeInsuranceExpiryDate.bind(this);
        this.onChangeInsuranceReplacementValue = this.onChangeInsuranceReplacementValue.bind(this);
        this.onChangeLotNumber = this.onChangeLotNumber.bind(this);
        this.onChangeLotSizeSquareMetres = this.onChangeLotSizeSquareMetres.bind(this);
        this.onChangeOwnershipType = this.onChangeOwnershipType.bind(this);
        this.onChangePlanNumber = this.onChangePlanNumber.bind(this);
        this.onChangePostcode = this.onChangePostcode.bind(this);
        this.onChangePurpose = this.onChangePurpose.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeStrataType = this.onChangeStrataType.bind(this);
        this.onChangeUnitNumber = this.onChangeUnitNumber.bind(this);
        this.onChangeStreetNumber = this.onChangeStreetNumber.bind(this);
        this.onChangeStreetName = this.onChangeStreetName.bind(this);
        this.onChangeStreetType = this.onChangeStreetType.bind(this);
        this.onChangeSuburb = this.onChangeSuburb.bind(this);
        this.onChangeTenancyType = this.onChangeTenancyType.bind(this);
        this.onChangeVolumeNumber = this.onChangeVolumeNumber.bind(this);
        this.onChangeZoneType = this.onChangeZoneType.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const {
            currentDebt,
            dwellingCount,
            estimatedValue,
            folioNumber,
            insuranceExpiryDate,
            insuranceReplacementValue,
            lotNumber,
            lotSizeSquareMetres,
            ownershipType,
            planNumber,
            postcode,
            purpose,
            state,
            strataType,
            streetName,
            streetNumber,
            streetType,
            suburb,
            tenancyType,
            unitNumber,
            volumeNumber,
            zoneType,
        } = this.state;

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        return (
            <Modal
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Property'
                width={530}
                wrapClassName='deal-property-add-modal'
            >
                <Tabs defaultActiveKey='address' type='card'>
                    <Tabs.TabPane tab={<Typography>Address</Typography>} key='address'>
                        <Form.Item className='unit-number' label='Unit Number'>
                            <Input onChange={this.onChangeUnitNumber} value={unitNumber} />
                        </Form.Item>
                        <Form.Item className='street-number' label='Street Number'>
                            <Input onChange={this.onChangeStreetNumber} value={streetNumber} />
                        </Form.Item>
                        <Form.Item className='street-name' label='Street Name'>
                            <Input onChange={this.onChangeStreetName} value={streetName} />
                        </Form.Item>
                        <Form.Item className='street-type' label='Street Type'>
                            <Select
                                showSearch={true}
                                onChange={this.onChangeStreetType}
                                value={streetType}
                                filterOption={this.onStreetTypeFilterOption}
                            >
                                {_.keys(propertyStreetTypeLabels).map((code: string): JSX.Element => <Select.Option key={code} value={code}>{propertyStreetTypeLabels[code]}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item className='suburb' label='Suburb'>
                            <Input onChange={this.onChangeSuburb} value={suburb} />
                        </Form.Item>
                        <Form.Item className='state' label='State'>
                            <Select onChange={this.onChangeState} value={state}>
                                <Select.Option value={PropertyStateEnum.AustralianCapitalTerritory}>ACT</Select.Option>
                                <Select.Option value={PropertyStateEnum.NewSouthWales}>NSW</Select.Option>
                                <Select.Option value={PropertyStateEnum.NorthernTerritory}>NT</Select.Option>
                                <Select.Option value={PropertyStateEnum.Queensland}>QLD</Select.Option>
                                <Select.Option value={PropertyStateEnum.SouthAustralia}>SA</Select.Option>
                                <Select.Option value={PropertyStateEnum.Tasmania}>TAS</Select.Option>
                                <Select.Option value={PropertyStateEnum.Victoria}>VIC</Select.Option>
                                <Select.Option value={PropertyStateEnum.WesternAustralia}>WA</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className='postcode' label='Postcode'>
                            <Input onChange={this.onChangePostcode} value={postcode} />
                        </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<Typography>Details</Typography>} key='details'>
                        <Form.Item className='estimated-value' extra={currencyFormatter.format(estimatedValue)} label='Estimated Value'>
                            <Input addonBefore='$' min={0} onChange={this.onChangeEstimatedValue} type='number' value={estimatedValue} />
                        </Form.Item>
                        <Form.Item className='current-debt' extra={currencyFormatter.format(currentDebt)} label='Current Debt'>
                            <Input addonBefore='$' min={0} onChange={this.onChangeCurrentDebt} type='number' value={currentDebt} />
                        </Form.Item>
                        <Form.Item className='zone-type' label='Zoning'>
                            <Select onChange={this.onChangeZoneType} value={zoneType}>
                                <Select.Option value={ZoneTypeEnum.ResidentialHouse}>Residential - House</Select.Option>
                                <Select.Option value={ZoneTypeEnum.ResidentialTownhouse}>Residential - Townhouse / Villa</Select.Option>
                                <Select.Option value={ZoneTypeEnum.ResidentialUnit}>Residential - Unit / Apartment</Select.Option>
                                <Select.Option value={ZoneTypeEnum.ResidentialLand}>Residential - Land</Select.Option>
                                <Select.Option value={ZoneTypeEnum.CommercialOffice}>Commercial - Office</Select.Option>
                                <Select.Option value={ZoneTypeEnum.CommercialRetail}>Commercial - Retail</Select.Option>
                                <Select.Option value={ZoneTypeEnum.CommercialIndustrial}>Commercial - Industrial</Select.Option>
                                <Select.Option value={ZoneTypeEnum.CommercialLand}>Commercial - Land</Select.Option>
                                <Select.Option value={ZoneTypeEnum.RuralResidential}>Rural - Residential</Select.Option>
                                <Select.Option value={ZoneTypeEnum.RuralLand}>Rural - Land</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className='purpose' label='Purpose'>
                            <Select onChange={this.onChangePurpose} value={purpose}>
                                <Select.Option value={PropertyPurposeEnum.Develop}>Develop</Select.Option>
                                <Select.Option value={PropertyPurposeEnum.Purchase}>Purchase</Select.Option>
                                <Select.Option value={PropertyPurposeEnum.PurchaseDevelop}>Purchase and Develop</Select.Option>
                                <Select.Option value={PropertyPurposeEnum.Refinance}>Refinance</Select.Option>
                                <Select.Option value={PropertyPurposeEnum.Security}>Security</Select.Option>
                                <Select.Option value={PropertyPurposeEnum.Sell}>Sell</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className='strata-type' label='Strata Type'>
                            <Select onChange={this.onChangeStrataType} value={strataType}>
                                <Select.Option value={StrataTypeEnum.None}>None</Select.Option>
                                <Select.Option value={StrataTypeEnum.Strata}>Strata Title</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className='tenancy-type' label='Tenancy Type'>
                            <Select onChange={this.onChangeTenancyType} value={tenancyType}>
                                <Select.Option value={TenancyTypeEnum.None}>None</Select.Option>
                                <Select.Option value={TenancyTypeEnum.Tenanted}>Tenanted</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className='ownership-type' label='Ownership Type'>
                            <Select onChange={this.onChangeOwnershipType} value={ownershipType}>
                                <Select.Option value={OwnershipTypeEnum.CrownLeasehold}>Crown Leasehold</Select.Option>
                                <Select.Option value={OwnershipTypeEnum.Freehold}>Freehold</Select.Option>
                                <Select.Option value={OwnershipTypeEnum.Leasehold}>Leasehold</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className='lot-size-square-metres' label='Lot Size'>
                            <Input addonAfter='m&sup2;' min={0} onChange={this.onChangeLotSizeSquareMetres} type='number' value={lotSizeSquareMetres} />
                        </Form.Item>
                        <Form.Item className='dwelling-count' label='Number of Dwellings'>
                            <InputNumber min={0} onChange={this.onChangeDwellingCount} value={dwellingCount} />
                        </Form.Item>
                        <Form.Item label='Insurance Expiry Date' className='insurance-expiry-date'>
                            <DatePicker onChange={this.onChangeInsuranceExpiryDate} format='DD/MM/YYYY' value={insuranceExpiryDate ? dayjs(insuranceExpiryDate) : null} />
                        </Form.Item>
                        <Form.Item className='insurance-replacement-value' extra={currencyFormatter.format(insuranceReplacementValue)} label='Insurance Replacement Value'>
                            <Input addonBefore='$' min={0} onChange={this.onChangeInsuranceReplacementValue} type='number' value={insuranceReplacementValue} />
                        </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<Typography>Title Information</Typography>} key='title-information'>
                        <Form.Item className='folio-number' label='Folio Number'>
                            <Input onChange={this.onChangeFolioNumber} value={folioNumber} />
                        </Form.Item>
                        <Form.Item className='lot-number' label='Lot Number'>
                            <Input onChange={this.onChangeLotNumber} value={lotNumber} />
                        </Form.Item>
                        <Form.Item className='plan-number' label='Plan Number'>
                            <Input onChange={this.onChangePlanNumber} value={planNumber} />
                        </Form.Item>
                        <Form.Item className='volume-number' label='Volume Number'>
                            <Input onChange={this.onChangeVolumeNumber} value={volumeNumber} />
                        </Form.Item>
                    </Tabs.TabPane>
                </Tabs>
            </Modal>
        );
    }

    private onChangeCurrentDebt(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            currentDebt: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeDwellingCount(value: number): void {
        this.setState({
            dwellingCount: value,
        });
    }

    private onChangeEstimatedValue(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            estimatedValue: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeFolioNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            folioNumber: event.target.value,
        });
    }

    private onChangeInsuranceExpiryDate(date: Dayjs) {
        this.setState({
            insuranceExpiryDate: date ? date.format('YYYY-MM-DD') : null,
        });
    }

    private onChangeInsuranceReplacementValue(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            insuranceReplacementValue: event.target.value ? event.target.valueAsNumber : null,
        });
    }

    private onChangeLotNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            lotNumber: event.target.value,
        });
    }

    private onChangeLotSizeSquareMetres(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            lotSizeSquareMetres: event.target.value ? event.target.valueAsNumber : null,
        });
    }

    private onChangeOwnershipType(value: OwnershipTypeEnum): void {
        this.setState({
            ownershipType: value,
        });
    }

    private onChangePlanNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            planNumber: event.target.value,
        });
    }

    private onChangePostcode(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value) || event.target.value.trim().length > 4) {
            return;
        }

        this.setState({
            postcode: event.target.value,
        });
    }

    private onChangePurpose(value: PropertyPurposeEnum) {
        this.setState({
            purpose: value,
        });
    }

    private onChangeState(value: PropertyStateEnum) {
        this.setState({
            state: value,
        });
    }

    private onChangeStrataType(value: StrataTypeEnum) {
        this.setState({
            strataType: value,
        });
    }

    private onChangeUnitNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            unitNumber: event.target.value,
        });
    }

    private onChangeStreetNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            streetNumber: event.target.value,
        });
    }

    private onChangeStreetName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            streetName: event.target.value,
        });
    }

    private onStreetTypeFilterOption(input: string, option: any): boolean {
        return option.children.toString().toLocaleLowerCase().includes(input.toLocaleLowerCase());
    }

    private onChangeStreetType(value: PropertyStreetTypeEnum): void {
        this.setState({
            streetType: value,
        });
    }

    private onChangeSuburb(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            suburb: event.target.value,
        });
    }

    private onChangeTenancyType(value: TenancyTypeEnum) {
        this.setState({
            tenancyType: value,
        });
    }

    private onChangeVolumeNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            volumeNumber: event.target.value,
        });
    }

    private onChangeZoneType(value: ZoneTypeEnum) {
        this.setState({
            zoneType: value,
        });
    }

    private onClickOk(): void {
        const { dealUuid } = this.props;
        const {
            currentDebt,
            dwellingCount,
            estimatedValue,
            folioNumber,
            insuranceExpiryDate,
            insuranceReplacementValue,
            lotNumber,
            lotSizeSquareMetres,
            ownershipType,
            planNumber,
            postcode,
            purpose,
            state,
            strataType,
            streetName,
            streetNumber,
            streetType,
            suburb,
            tenancyType,
            unitNumber,
            volumeNumber,
            zoneType,
        } = this.state;

        this.props.onCancel();

        this.setState({
            streetName: null,
            streetNumber: null,
            streetType: null,
            unitNumber: null,
        });

        const property: IProperty = {
            addressVersion: AddressVersionEnum.V2,
            currentDebt,
            dealUuid,
            dwellingCount,
            estimatedValue,
            folioNumber,
            insuranceExpiryDate,
            insuranceReplacementValue,
            lotNumber,
            lotSizeSquareMetres,
            ownershipType,
            planNumber,
            postcode,
            purpose,
            state,
            strataType,
            streetName,
            streetNumber,
            streetType,
            suburb,
            tenancyType,
            unitNumber,
            volumeNumber,
            zoneType,
        };

        this.props.propertyAdd(property);
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        propertyAdd: (property: IProperty) => dispatch(dealPropertiesAddAction(ownProps.dealUuid, property)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(PropertyModal);
