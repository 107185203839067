import _ from 'lodash';
import roundTo from 'round-to';
import MortgageTypeEnum from '~Api/Application/MortgageTypeEnum';
import ApplicationFeeFormatEnum from '~Api/Deal/ApplicationFeeFormatEnum';
import BrokerageFeeFormatEnum from '~Api/Deal/BrokerageFeeFormatEnum';
import EstablishmentFeeFormatEnum from '~Api/Deal/EstablishmentFeeFormatEnum';
import IPostcodeCategory from '~Api/Deal/IPostcodeCategory';
import IProperty from '~Api/Deal/IProperty';
import LoanPurposeEnum from '~Api/Deal/LoanPurposeEnum';
import PropertyPurposeEnum from '~Api/Deal/PropertyPurposeEnum';
import RiskBandEnum from '~Api/Deal/RiskBandEnum';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';
import constants from '~constants';
import { IDictionary } from '~utilities/IDictionary';
import ICalculatedInterestRate from './ICalculatedInterestRate';
import ICalculatorProperty from './ICalculatorProperty';

const zoneRiskBandLoadings: IDictionary<IDictionary<number>> = {
    [ZoneTypeEnum.CommercialIndustrial]: {
        [RiskBandEnum.BandOne]: 3,
        [RiskBandEnum.BandTwo]: 3.71,
        [RiskBandEnum.BandThree]: 5.21,
    },
    [ZoneTypeEnum.CommercialLand]: {
        // No
    },
    [ZoneTypeEnum.CommercialOffice]: {
        [RiskBandEnum.BandOne]: 3,
        [RiskBandEnum.BandTwo]: 3.71,
        [RiskBandEnum.BandThree]: 5.21,
    },
    [ZoneTypeEnum.CommercialRetail]: {
        [RiskBandEnum.BandOne]: 3,
        [RiskBandEnum.BandTwo]: 3.71,
        [RiskBandEnum.BandThree]: 5.21,
    },
    [ZoneTypeEnum.ResidentialHouse]: {
        [RiskBandEnum.BandOne]: 0,
        [RiskBandEnum.BandTwo]: 0.51,
        [RiskBandEnum.BandThree]: 2.46,
    },
    [ZoneTypeEnum.ResidentialLand]: {
        [RiskBandEnum.BandOne]: 2.51,
        [RiskBandEnum.BandTwo]: 2.96,
        [RiskBandEnum.BandThree]: 4.46,
    },
    [ZoneTypeEnum.ResidentialTownhouse]: {
        [RiskBandEnum.BandOne]: 0,
        [RiskBandEnum.BandTwo]: 0.51,
        [RiskBandEnum.BandThree]: 2.46,
    },
    [ZoneTypeEnum.ResidentialUnit]: {
        [RiskBandEnum.BandOne]: 0,
        [RiskBandEnum.BandTwo]: 0.51,
        [RiskBandEnum.BandThree]: 2.46,
    },
    [ZoneTypeEnum.RuralLand]: {
        // No
    },
    [ZoneTypeEnum.RuralResidential]: {
        [RiskBandEnum.BandOne]: 2,
        [RiskBandEnum.BandTwo]: 3,
        [RiskBandEnum.BandThree]: 4.5,
    },
};

export enum DealCalculateBaseAmountEnum {
    GrossLoanAmount = 'GROSS_LOAN_AMOUNT',
    RequestedPayoutAmount = 'REQUESTED_PAYOUT_AMOUNT',
}

export interface IDealCalculateParameters {
    applicationFeeDollars: number;
    applicationFeeFormat: ApplicationFeeFormatEnum;
    applicationFeePercentage: number;
    baseDealAmount?: DealCalculateBaseAmountEnum;
    brokerageFeeDollars: number;
    brokerageFeeFormat: BrokerageFeeFormatEnum;
    brokerageFeePercentage: number;
    establishmentFeeDollars: number;
    establishmentFeeDollarsMinimumOverride?: number;
    establishmentFeeFormat: EstablishmentFeeFormatEnum;
    establishmentFeePercentage: number;
    establishmentFeePercentageMinimumOverride?: number;
    establishmentFeeTotal?: number;
    estimatedOutlays: number;
    grossLoanAmount?: number;
    interestRate: number;
    interestRateMinimumOverride?: number;
    isApplication?: boolean;
    isBroker?: boolean;
    isReferralPartner?: boolean;
    legalFees: number;
    legalFeesDollarsMinimumOverride?: number;
    loanPurpose?: LoanPurposeEnum;
    lvrMaximumOverride?: number;
    maximumLvr: number;
    mortgageType?: MortgageTypeEnum;
    netPrepaidBalanceOnSettlement?: number;
    postcodeCategories?: IDictionary<IPostcodeCategory>;
    properties?: ICalculatorProperty[];
    requestedLoanAmount?: number;
    requestedPayoutAmount?: number;
    termMonths: number;
    totalCurrentDebt?: number;
}

export interface IDealCalculatedAmounts {
    applicationFeeTotal: number;
    brokerageFeeTotal: number;
    establishmentFeeTotal: number;
    grossLoanAmount: number;
    interestPayable: number;
    lvr: number;
    netPrepaidBalanceOnSettlement: number;
    totalCurrentDebt: number;
    totalValue: number;
}

export function propertyRequiresInsurance(property: IProperty): boolean {
    const isLand: boolean = [ZoneTypeEnum.ResidentialLand, ZoneTypeEnum.CommercialLand, ZoneTypeEnum.RuralLand].includes(property.zoneType);

    switch (property.purpose) {
        case PropertyPurposeEnum.Purchase:
        case PropertyPurposeEnum.Refinance:
        case PropertyPurposeEnum.Security:
        case PropertyPurposeEnum.Sell:
            if (isLand) {
                return false;
            }
            break;
    }

    return true;
}

export function getDealCalculatedInterestRate(parameters: ICalculatedInterestRate): number {
    const {
        loanPurpose,
        mortgageType,
        postcodeCategories,
        properties,
        requestedPayoutAmount,
        termMonths,
    } = parameters;

    // Only quote first mortgages
    if (mortgageType !== MortgageTypeEnum.FirstMortgage) {
        return;
    }

    // Only quote for $1.5M or below
    if (requestedPayoutAmount > 1500000) {
        return;
    }

    // Only quote for 12 month terms or less
    if (termMonths > 12) {
        return;
    }

    // Do not quote for building or renovation
    if (loanPurpose && loanPurpose === LoanPurposeEnum.RenovateOrBuild) {
        return;
    }

    // Only quote if we have enough property details, and zone + risk band combination
    if (_.some(properties, (property: ICalculatorProperty) => !property.postcode || !property.zoneType || !postcodeCategories[property.postcode] || undefined === zoneRiskBandLoadings[property.zoneType]?.[postcodeCategories[property.postcode].riskBand])) {
        return;
    }

    if (properties && properties.length > 0) {
        const maxCalculatedRate: number = properties.reduce((result: number, property: ICalculatorProperty): number => {
            const postcodeCategory: IPostcodeCategory = postcodeCategories[property.postcode];
            const propertyInterestRate: number = zoneRiskBandLoadings[property.zoneType][postcodeCategory.riskBand] + constants.DEAL_INTEREST_RATE_BASE;
            return Math.max(result, propertyInterestRate);
        }, 0);

        return roundTo(maxCalculatedRate, 2);
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getDealCalculatedEstablishmentFeePercentage(parameters: IDealCalculateParameters): number {
    return constants.DEAL_ESTABLISHMENT_FEE_PERCENTAGE_DEFAULT;
}

export function getDealCalculatedAmounts(parameters: IDealCalculateParameters): IDealCalculatedAmounts {
    const {
        applicationFeeDollars,
        applicationFeeFormat,
        applicationFeePercentage,
        baseDealAmount,
        brokerageFeeDollars,
        brokerageFeeFormat,
        brokerageFeePercentage,
        establishmentFeeDollars,
        establishmentFeeFormat,
        establishmentFeePercentage,
        estimatedOutlays,
        interestRate,
        isApplication,
        legalFees,
        maximumLvr,
        mortgageType,
        properties,
        requestedLoanAmount,
        requestedPayoutAmount,
        termMonths,
        totalCurrentDebt,
    } = parameters;

    const propertyCurrentDebt: number = totalCurrentDebt ?? _.sumBy(properties, (property: ICalculatorProperty) => property.currentDebt);
    const propertyValue: number = _.sumBy(properties, (property: ICalculatorProperty) => property.valuationValue || property.estimatedValue);

    let grossLoanAmount: number = 0;
    let netPrepaidBalanceOnSettlement: number = 0;
    let interestPayable: number = 0;
    let establishmentFeeTotal: number = 0;
    let applicationFeeTotal: number = 0;
    let brokerageFeeTotal: number = 0;
    let lvr: number = 0;

    const requestedPayoutAmountWithDebt: number = requestedPayoutAmount + (mortgageType === MortgageTypeEnum.FirstMortgage ? propertyCurrentDebt : 0);

    // Only calculate if we have enough details to do so
    if (interestRate && termMonths && (requestedPayoutAmountWithDebt || requestedLoanAmount)) {
        let minimum: number = 1;
        let maximum: number = 20000000; // $20M

        const targetNetBalance: boolean = !isApplication || baseDealAmount === DealCalculateBaseAmountEnum.RequestedPayoutAmount;

        while (minimum < maximum) {
            // Binary search - find the middle of min and max
            grossLoanAmount = minimum + Math.round((maximum - minimum) / 2);

            interestPayable = interestRate ? Math.ceil(grossLoanAmount * (interestRate / 100) / 12 * termMonths) : null;
            establishmentFeeTotal = establishmentFeeFormat === EstablishmentFeeFormatEnum.Percentage ? Math.ceil(grossLoanAmount * (establishmentFeePercentage / 100)) : establishmentFeeDollars;
            applicationFeeTotal = applicationFeeFormat === ApplicationFeeFormatEnum.Percentage ? Math.ceil(grossLoanAmount * (applicationFeePercentage / 100)) : applicationFeeDollars;
            brokerageFeeTotal = brokerageFeeFormat === BrokerageFeeFormatEnum.Percentage ? roundTo(grossLoanAmount * (brokerageFeePercentage / 100), 2) : brokerageFeeDollars;

            netPrepaidBalanceOnSettlement = grossLoanAmount - interestPayable - establishmentFeeTotal - (applicationFeeTotal * 1.1) - (brokerageFeeTotal * 1.1) - (legalFees * 1.1) - estimatedOutlays;

            lvr = Number((grossLoanAmount + (mortgageType === MortgageTypeEnum.FirstMortgage ? 0 : propertyCurrentDebt || 0)) / propertyValue * 100);

            // When the net payout goes negative things get wonky, so just abort
            if (netPrepaidBalanceOnSettlement < 0) {
                break;
            }

            const calculatedValue: number = targetNetBalance ? netPrepaidBalanceOnSettlement : grossLoanAmount;
            const targetValue: number = targetNetBalance ? requestedPayoutAmountWithDebt : requestedLoanAmount;

            let direction: number = 1;
            if (maximumLvr && lvr > maximumLvr) {
                // We never want to be above the max LVR
                direction = -1;
            } else if (calculatedValue === targetValue) {
                direction = 0;
            } else if (calculatedValue > targetValue) {
                direction = -1;
            } else if (maximumLvr && lvr === maximumLvr) {
                // If we're not high enough but we've reached out max LVR, stop
                direction = 0;
            }

            // If we get a 0 it means we have found our goal
            if (0 === direction) {
                break;
            }

            // If we get 1 it means we need to search higher than the middle, -1 means we need to search lower
            if (direction > 0) {
                // Abort when we're within a rounding issue
                if (minimum === grossLoanAmount) {
                    break;
                }

                // Binary search - update the minimum to the current middle so our new middle will be higher
                minimum = grossLoanAmount;
            } else {
                // Abort when we're within a rounding issue
                if (maximum === grossLoanAmount) {
                    break;
                }

                // Binary search - update the maximum to the current middle so our new middle will be lower
                maximum = grossLoanAmount;
            }
        }

        if (mortgageType === MortgageTypeEnum.FirstMortgage) {
            netPrepaidBalanceOnSettlement -= propertyCurrentDebt;
        }

        lvr = roundTo(lvr, 2);
    }

    return {
        applicationFeeTotal,
        brokerageFeeTotal,
        establishmentFeeTotal,
        grossLoanAmount,
        interestPayable,
        lvr,
        netPrepaidBalanceOnSettlement,
        totalCurrentDebt: propertyCurrentDebt,
        totalValue: propertyValue,
    };
}
