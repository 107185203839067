import { IDictionary } from '~utilities/IDictionary';
import LoanPurposeEnum from './LoanPurposeEnum';

export const loanPurposeLabels: IDictionary<string> = {
    [LoanPurposeEnum.BridgingLoan]: 'Bridging Loan',
    [LoanPurposeEnum.BusinessLoan]: 'Business Loan',
    [LoanPurposeEnum.DebtConsolidation]: 'Debt Consolidation',
    [LoanPurposeEnum.DevelopmentLoan]: 'Development Loan',
    [LoanPurposeEnum.PersonalLoan]: 'Personal Loan',
    [LoanPurposeEnum.PurchaseNew]: 'Purchase New',
    [LoanPurposeEnum.Refinance]: 'Refinance',
    [LoanPurposeEnum.RenovateOrBuild]: 'Renovate or Build',
};
