import { Alert, Button, Checkbox, Divider, Form, Input, Space, Spin, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import BorrowerCategoryEnum from '~Api/Application/BorrowerCategoryEnum';
import BorrowerTypeEnum from '~Api/Application/BorrowerTypeEnum';
import ConditionalApprovalStatusEnum from '~Api/Application/ConditionalApprovalStatusEnum';
import IApplication from '~Api/Application/IApplication';
import IBorrower from '~Api/Application/IBorrower';
import IConditionalCondition from '~Api/Application/IConditionalCondition';
import IConditional from '~Api/Application/IConditional';
import ICondition from '~Api/Application/ICondition';
import {
    applicationConditionalConditionAddAction,
    applicationConditionalConditionDeleteAction,
    applicationConditionalGetAction,
    applicationConditionalsStandardConditionsListAction,
    applicationConditionsListAction,
    applicationGetAction,
} from '~Applications/actions';
import {
    applicationConditionalSelector,
    applicationConditionalsStandardConditionsSelector,
    applicationConditionsSelector,
    applicationSelector,
} from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import { IDictionary } from '~utilities/IDictionary';
import { IStandardCondition } from '~Api/Application/IStandardCondition';
import { IStandardConditionGroup } from '~Api/Application/IStandardConditionGroup';

interface IState {
    customCondition: string;
}

interface IMatch {
    applicationUuid: string;
    conditionalUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    application: IApplication;
    applicationConditions: IDictionary<ICondition>;
    conditional: IConditional;
    standardConditionLabels: IStandardConditionGroup[];
}

interface IPropsDispatch {
    applicationGet: () => void;
    applicationConditionalGet: () => void;
    conditionAdd: (condition: IConditionalCondition) => void;
    conditionDelete: (condition: IConditionalCondition) => void;
    conditionsList: () => void;
    standardConditionLabelsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class ConditionalEdit extends React.Component<Props, IState> {
    public state: IState = {
        customCondition: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeCustomCondition = this.onChangeCustomCondition.bind(this);
        this.onClickCustomConditionAdd = this.onClickCustomConditionAdd.bind(this);
        this.onClickCondition = this.onClickCondition.bind(this);
    }

    public componentDidMount(): void {
        const { application, applicationConditions, conditional, standardConditionLabels } = this.props;

        if (!application) {
            this.props.applicationGet();
        }

        if (!applicationConditions) {
            this.props.conditionsList();
        }

        if (!conditional) {
            this.props.applicationConditionalGet();
        }

        if (!standardConditionLabels) {
            this.props.standardConditionLabelsList();
        }
    }

    public render(): JSX.Element {
        const { application, applicationConditions, conditional, match, standardConditionLabels } = this.props;
        const {
            customCondition,
        } = this.state;

        if (!application || !applicationConditions || !conditional) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='conditional-approval'>
                    <Typography.Title level={2}>Conditional Approval</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        if (conditional.approvalStatus !== ConditionalApprovalStatusEnum.Draft) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='conditional-approval'>
                    <Typography.Title level={2}>Conditional Approval</Typography.Title>
                    <Alert
                        message='Unable to edit this Conditional Approval'
                        description='This Conditional Approval is not in the Draft status and is unable to be edited.'
                        type='error'
                        showIcon={true}
                    />
                </Layout>
            );
        }

        const informationErrors: string[] = [];
        application.borrowers.forEach((borrower: IBorrower) => {
            switch (borrower.dealBorrower.type) {
                case BorrowerTypeEnum.Company:
                    if (_.filter(application.borrowers, (loopBorrower: IBorrower) => loopBorrower.uuid !== borrower.uuid && loopBorrower.dealBorrower.category === BorrowerCategoryEnum.Guarantor).length === 0) {
                        informationErrors.push('Company Borrower Present - Guarantor required');
                    }
                    break;
                case BorrowerTypeEnum.Trust:
                    if (_.filter(application.borrowers, (loopBorrower: IBorrower) => loopBorrower.uuid !== borrower.uuid && loopBorrower.dealBorrower.category === BorrowerCategoryEnum.Trustee).length === 0) {
                        informationErrors.push('Trust Borrower Present - Trustee required');
                    }
                    break;
            }
        });

        if (informationErrors.length > 0) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='conditional-approval'>
                    <Typography.Title level={2}>Conditional Approval</Typography.Title>
                    <React.Fragment>
                        {informationErrors.map((error: string, index: number) => <p key={`error-${index}`}>{error}</p>)}
                    </React.Fragment>
                </Layout>
            );
        }

        const conditionalConditions: string[] = _.map(conditional.conditions, (loopCondition: IConditionalCondition) => loopCondition.name);

        // Keep track of conditions we use along the way so we can populate the custom conditions from the previous conditions
        const removeConditions: string[] = [];

        const standardConditionsBlock: JSX.Element[] = _.map(standardConditionLabels, (standardConditionGroup: IStandardConditionGroup) => {
            const conditionsBlock: JSX.Element[] = standardConditionGroup.conditions.map((condition: IStandardCondition) => {
                removeConditions.push(condition.label);

                return (
                    <Form.Item className='standard-condition' help={condition.hint} key={condition.label}>
                        <Checkbox key={condition.label} checked={conditionalConditions.includes(condition.label)} onChange={this.onClickCondition} value={condition.label}>{condition.label}</Checkbox>
                    </Form.Item>
                );
            });

            return (
                <React.Fragment key={standardConditionGroup.heading}>
                    <Divider orientation='left'>{standardConditionGroup.heading}</Divider>
                    {conditionsBlock}
                </React.Fragment>
            );
        });

        const applicationConditionsBlock: JSX.Element[] = _.map(applicationConditions, (loopCondition: ICondition) => {
            removeConditions.push(loopCondition.name);

            return (
                <Form.Item className='application-condition' key={loopCondition.uuid}>
                    <Checkbox checked={conditionalConditions.includes(loopCondition.name)} onChange={this.onClickCondition} value={loopCondition.name}>{loopCondition.name}</Checkbox>
                </Form.Item>
            );
        });

        // Remove any standard conditions from the selected conditions so we are left with the custom conditions
        _.pullAll(conditionalConditions, removeConditions);

        const customConditionsBlock: JSX.Element[] = [...conditionalConditions].map((loopCustomCondition: string) => (
            <Form.Item className='custom-condition' key={loopCustomCondition}>
                <Checkbox checked={true} onChange={this.onClickCondition} value={loopCustomCondition}>{loopCustomCondition}</Checkbox>
            </Form.Item>
        ));

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='conditional-approval'>
                <Typography.Title level={2}>Conditional Approval</Typography.Title>
                <React.Fragment>
                    {standardConditionsBlock}
                </React.Fragment>
                <Divider orientation='left'>Application Conditions</Divider>
                <React.Fragment>
                    {applicationConditionsBlock}
                </React.Fragment>
                <Divider orientation='left'>Custom Conditions</Divider>
                <React.Fragment>
                    {customConditionsBlock}
                </React.Fragment>
                <Space className='custom-condition-add'>
                    <Input onChange={this.onChangeCustomCondition} value={customCondition || ''} maxLength={255} />
                    <Button onClick={this.onClickCustomConditionAdd}>Add</Button>
                </Space>
            </Layout>
        );
    }

    private onChangeCustomCondition(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            customCondition: event.target.value.substr(0, 255),
        });
    }

    private onClickCustomConditionAdd(): void {
        const { customCondition } = this.state;

        if (!customCondition) {
            return;
        }

        const condition: IConditionalCondition = {
            name: customCondition,
        };

        this.props.conditionAdd(condition);

        this.setState({
            customCondition: null,
        });
    }

    private onClickCondition(event: CheckboxChangeEvent): void {
        const { conditional } = this.props;

        if (!event.target.checked) {
            const condition: IConditionalCondition = _.find(conditional.conditions, (loopCondition: IConditionalCondition) => event.target.value === loopCondition.name);

            this.props.conditionDelete(condition);
        } else {
            const condition: IConditionalCondition = {
                name: event.target.value,
            };

            this.props.conditionAdd(condition);
        }
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
        applicationConditions: applicationConditionsSelector(state, ownProps.match.params.applicationUuid),
        conditional: applicationConditionalSelector(state, ownProps.match.params.conditionalUuid),
        standardConditionLabels: applicationConditionalsStandardConditionsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationConditionalGet: () => dispatch(applicationConditionalGetAction(ownProps.match.params.conditionalUuid)),
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
        conditionAdd: (condition: IConditionalCondition) => dispatch(applicationConditionalConditionAddAction(ownProps.match.params.conditionalUuid, condition)),
        conditionDelete: (condition: IConditionalCondition) => dispatch(applicationConditionalConditionDeleteAction(ownProps.match.params.conditionalUuid, condition)),
        conditionsList: () => dispatch(applicationConditionsListAction(ownProps.match.params.applicationUuid)),
        standardConditionLabelsList: () => dispatch(applicationConditionalsStandardConditionsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConditionalEdit);
