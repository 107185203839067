import { Form, Input, Modal, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IdProofTypeEnum from '~Api/Investor/IdProofTypeEnum';
import IIndividual from '~Api/Investor/IIndividual';
import IInvestor from '~Api/Investor/IInvestor';
import ResidentTypeEnum from '~Api/Investor/ResidentTypeEnum';
import StateEnum from '~Api/Investor/StateEnum';
import { countriesGetAction } from '~Countries/actions';
import ICountry from '~Countries/ICountry';
import { countriesSelector } from '~Countries/selectors';
import { investorGetAction, investorIndividualAddAction } from '~Investors/actions';
import { investorSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import DatePicker from '~UI/DatePicker';

interface IState {
    country: string;
    dateOfBirth: string;
    driverLicenceCardNumber: string;
    driverLicenceState: StateEnum;
    email: string;
    firstName: string;
    idProofExpiryDate: string;
    idProofNumber: string;
    idProofType: IdProofTypeEnum;
    lastName: string;
    postcode: string;
    residentType: ResidentTypeEnum;
    state: string;
    streetAddress: string;
    suburb: string;
    taxFileNumber: string;
}

interface IProps {
    investorUuid: string;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsSelector {
    countries: ICountry[];
    investor: IInvestor;
}

interface IPropsDispatch {
    countriesGet: () => void;
    individualAdd: (individual: IIndividual) => void;
    investorGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class IndividualAddModal extends React.Component<Props> {
    public state: IState = {
        country: 'AUS',
        dateOfBirth: null,
        driverLicenceCardNumber: null,
        driverLicenceState: null,
        email: null,
        firstName: null,
        idProofExpiryDate: null,
        idProofNumber: null,
        idProofType: null,
        lastName: null,
        postcode: null,
        residentType: null,
        state: null,
        streetAddress: null,
        suburb: null,
        taxFileNumber: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeDateOfBirth = this.onChangeDateOfBirth.bind(this);
        this.onChangeTaxFileNumber = this.onChangeTaxFileNumber.bind(this);
        this.onChangeStreetAddress = this.onChangeStreetAddress.bind(this);
        this.onChangeSuburb = this.onChangeSuburb.bind(this);
        this.onChangePostcode = this.onChangePostcode.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeResidentType = this.onChangeResidentType.bind(this);
        this.onChangeIdProofType = this.onChangeIdProofType.bind(this);
        this.onChangeIdProofNumber = this.onChangeIdProofNumber.bind(this);
        this.onChangeIdProofExpiryDate = this.onChangeIdProofExpiryDate.bind(this);
        this.onChangeDriverLicenceCardNumber = this.onChangeDriverLicenceCardNumber.bind(this);
        this.onChangeDriverLicenceState = this.onChangeDriverLicenceState.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public componentDidMount(): void {
        const { countries, investor } = this.props;

        if (!countries) {
            this.props.countriesGet();
        }

        if (!investor) {
            this.props.investorGet();
        }
    }

    public render(): JSX.Element {
        const { countries, investor, isOpen } = this.props;

        if (!countries || !investor) {
            return null;
        }

        const {
            country,
            dateOfBirth,
            driverLicenceCardNumber,
            driverLicenceState,
            email,
            firstName,
            idProofExpiryDate,
            idProofNumber,
            idProofType,
            lastName,
            postcode,
            residentType,
            state,
            streetAddress,
            suburb,
            taxFileNumber,
        } = this.state;

        let idProofBlock: JSX.Element = null;

        switch (idProofType) {
            case IdProofTypeEnum.DriverLicence:
                idProofBlock = (
                    <React.Fragment>
                        <Form.Item label='Licence Number' className='id-proof-number'>
                            <Input onChange={this.onChangeIdProofNumber} value={idProofNumber} />
                        </Form.Item>
                        <Form.Item label='Licence Card Number' className='drivers-licence-card-number'>
                            <Input onChange={this.onChangeDriverLicenceCardNumber} value={driverLicenceCardNumber} />
                        </Form.Item>
                        <Form.Item label='Licence State' className='drivers-licence-state'>
                            <Select onChange={this.onChangeDriverLicenceState} value={driverLicenceState}>
                                <Select.Option value={StateEnum.AustralianCapitalTerritory}>ACT</Select.Option>
                                <Select.Option value={StateEnum.NewSouthWales}>NSW</Select.Option>
                                <Select.Option value={StateEnum.NorthernTerritory}>NT</Select.Option>
                                <Select.Option value={StateEnum.Queensland}>QLD</Select.Option>
                                <Select.Option value={StateEnum.SouthAustralia}>SA</Select.Option>
                                <Select.Option value={StateEnum.Tasmania}>TAS</Select.Option>
                                <Select.Option value={StateEnum.Victoria}>VIC</Select.Option>
                                <Select.Option value={StateEnum.WesternAustralia}>WA</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label='Licence Expiry Date' className='id-proof-expiry-date'>
                            <DatePicker onChange={this.onChangeIdProofExpiryDate} format='DD/MM/YYYY' value={idProofExpiryDate ? dayjs(idProofExpiryDate) : null} />
                        </Form.Item>
                    </React.Fragment>
                );

                break;
            case IdProofTypeEnum.Passport:
                idProofBlock = (
                    <React.Fragment>
                        <Form.Item label='Passport Number' className='id-proof-number'>
                            <Input onChange={this.onChangeIdProofNumber} value={idProofNumber} />
                        </Form.Item>
                        <Form.Item label='Passport Expiry Date' className='id-proof-expiry-date'>
                            <DatePicker onChange={this.onChangeIdProofExpiryDate} format='DD/MM/YYYY' value={idProofExpiryDate ? dayjs(idProofExpiryDate) : null} />
                        </Form.Item>
                    </React.Fragment>
                );

                break;
        }

        return (
            <Modal
                okText='Add'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Individual'
                wrapClassName='investor-individual-add-modal'
            >
                <Form.Item label='First Name' className='first-name'>
                    <Input onChange={this.onChangeFirstName} value={firstName} />
                </Form.Item>
                <Form.Item label='Last Name' className='last-name'>
                    <Input onChange={this.onChangeLastName} value={lastName} />
                </Form.Item>
                <Form.Item label='Email' className='email'>
                    <Input onChange={this.onChangeEmail} value={email} />
                </Form.Item>
                <Form.Item label='Date of Birth' className='date-of-birth'>
                    <DatePicker onChange={this.onChangeDateOfBirth} format='DD/MM/YYYY' value={dateOfBirth ? dayjs(dateOfBirth) : null} />
                </Form.Item>
                <Form.Item label='TFN' className='tax-file-number'>
                    <Input onChange={this.onChangeTaxFileNumber} value={taxFileNumber} />
                </Form.Item>
                <Form.Item label='Street Address' className='street-address'>
                    <Input onChange={this.onChangeStreetAddress} value={streetAddress} />
                </Form.Item>
                <Form.Item label='Suburb' className='suburb'>
                    <Input onChange={this.onChangeSuburb} value={suburb} />
                </Form.Item>
                <Form.Item label='Postcode' className='postcode'>
                    <Input onChange={this.onChangePostcode} value={postcode} />
                </Form.Item>
                <Form.Item label='State' className='state'>
                    <Input onChange={this.onChangeState} value={state} />
                </Form.Item>
                <Form.Item label='Country' className='country'>
                    <Select onChange={this.onChangeCountry} value={country || 'AUS'}>
                        {_.sortBy(countries, ['name']).map((loopCountry: ICountry) => <Select.Option key={loopCountry.iso3} value={loopCountry.iso3}>{loopCountry.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label='Resident Type' className='resident-type'>
                    <Select onChange={this.onChangeResidentType} value={residentType}>
                        <Select.Option value={ResidentTypeEnum.Resident}>Resident</Select.Option>
                        <Select.Option value={ResidentTypeEnum.NonResident}>Non Resident</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label='ID Proof Type' className='id-proof-type'>
                    <Select onChange={this.onChangeIdProofType} value={idProofType}>
                        <Select.Option value={IdProofTypeEnum.DriverLicence}>Australian Driver Licence</Select.Option>
                        <Select.Option value={IdProofTypeEnum.Passport}>Passport</Select.Option>
                    </Select>
                </Form.Item>
                {idProofBlock}
            </Modal>
        );
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            firstName: event.target.value,
        });
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            lastName: event.target.value,
        });
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            email: event.target.value,
        });
    }

    private onChangeDateOfBirth(date: Dayjs): void {
        this.setState({
            dateOfBirth: date ? date.format('YYYY-MM-DD') : null,
        });
    }

    private onChangeTaxFileNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            taxFileNumber: event.target.value,
        });
    }

    private onChangeStreetAddress(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            streetAddress: event.target.value,
        });
    }

    private onChangeSuburb(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            suburb: event.target.value,
        });
    }

    private onChangePostcode(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            postcode: event.target.value,
        });
    }

    private onChangeState(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            state: event.target.value,
        });
    }

    private onChangeCountry(value: string): void {
        this.setState({
            country: value,
        });
    }

    private onChangeResidentType(value: ResidentTypeEnum): void {
        this.setState({
            residentType: value,
        });
    }

    private onChangeIdProofType(value: IdProofTypeEnum): void {
        this.setState({
            idProofType: value,
        });
    }

    private onChangeIdProofNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            idProofNumber: event.target.value,
        });
    }

    private onChangeDriverLicenceCardNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            driverLicenceCardNumber: event.target.value,
        });
    }

    private onChangeDriverLicenceState(value: StateEnum): void {
        this.setState({
            driverLicenceState: value,
        });
    }

    private onChangeIdProofExpiryDate(date: Dayjs): void {
        this.setState({
            idProofExpiryDate: date ? date.format('YYYY-MM-DD') : null,
        });
    }

    private onClickOk(): void {
        const { investor } = this.props;
        const {
            country,
            dateOfBirth,
            driverLicenceCardNumber,
            driverLicenceState,
            email,
            firstName,
            idProofExpiryDate,
            idProofNumber,
            idProofType,
            lastName,
            postcode,
            residentType,
            state,
            streetAddress,
            suburb,
            taxFileNumber,
        } = this.state;

        const individual: IIndividual = {
            country,
            dateOfBirth,
            driverLicenceCardNumber,
            driverLicenceState,
            email,
            firstName,
            idProofExpiryDate,
            idProofNumber,
            idProofType,
            investorUuid: investor.uuid,
            lastName,
            postcode,
            residentType,
            state,
            streetAddress,
            suburb,
            taxFileNumber,
        };

        this.props.individualAdd(individual);

        this.props.onCancel();
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        countries: countriesSelector(state),
        investor: investorSelector(state, ownProps.investorUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        countriesGet: () => dispatch(countriesGetAction()),
        individualAdd: (individual: IIndividual) => dispatch(investorIndividualAddAction(individual)),
        investorGet: () => dispatch(investorGetAction(ownProps.investorUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IndividualAddModal);
