import { Skeleton, Space } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import { loanWarehouseLoansListAction } from '~Loans/actions';
import { loanWarehouseLoansSelector } from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import { currencyFormatter } from '~utilities/formatters';
import { IDictionary } from '~utilities/IDictionary';
import { warehousesListAction } from '~Warehouses/actions';
import { warehousesSelector } from '~Warehouses/selectors';

interface IProps {
    loanUuid: string;
}

interface IPropsSelector {
    warehouseLoans: IDictionary<IWarehouseLoan>;
    warehouses: IDictionary<IWarehouse>;
}

interface IPropsDispatch {
    loanWarehouseLoansList: () => void;
    warehousesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class WarehouseLoanBreakdownList extends React.Component<Props> {
    public componentDidMount(): void {
        const { warehouseLoans, warehouses } = this.props;

        if (!warehouseLoans) {
            this.props.loanWarehouseLoansList();
        }

        if (!warehouses) {
            this.props.warehousesList();
        }
    }

    public render(): JSX.Element {
        const { warehouseLoans, warehouses } = this.props;

        if (!warehouseLoans || !warehouses) {
            return (
                <Skeleton paragraph={false} active={true} />
            );
        }

        const breakdownList: JSX.Element[] = _.map(warehouseLoans, (warehouseLoan: IWarehouseLoan) => {
            if (warehouseLoan.balancePrincipal) {
                return (
                    <div key={warehouseLoan.uuid}>
                        <Link to={`/warehouses/${warehouseLoan.warehouseUuid}/loans/${warehouseLoan.uuid}`}>{warehouses[warehouseLoan.warehouseUuid].name}</Link> - {currencyFormatter.format(warehouseLoan.balancePrincipal)}
                    </div>
                );
            } else if (_.size(warehouseLoans) === 1) {
                return <div>-</div>;
            }
        });

        return (
            <Space direction='vertical'>
                {breakdownList.length === 0 ? '-' : breakdownList}
            </Space>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        warehouseLoans: loanWarehouseLoansSelector(state, ownProps.loanUuid),
        warehouses: warehousesSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        loanWarehouseLoansList: () => dispatch(loanWarehouseLoansListAction(ownProps.loanUuid)),
        warehousesList: () => dispatch(warehousesListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WarehouseLoanBreakdownList);
