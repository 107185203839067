enum AdvisersActionsEnum {
    AdvisersAdd = 'ADVISERS/ADD',
    AdvisersList = 'ADVISERS/LIST',
    AdvisersSet = 'ADVISERS/SET',

    AdviserApprove = 'ADVISER/APPROVE',
    AdviserGet = 'ADVISER/GET',
    AdviserReject = 'ADVISER/REJECT',
    AdviserResetApprovalStatus = 'ADVISER/RESET_APPROVAL_STATUS',
    AdviserSend = 'ADVISER/SEND',
    AdviserSet = 'ADVISER/SET',
    AdviserValueSet = 'ADVISER/VALUE/SET',

    AdviserInvestorsList = 'ADVISER/INVESTORS/LIST',
    AdviserInvestorsSet = 'ADVISER/INVESTORS/SET',

    AdviserHistoriesList = 'ADVISER/HISTORIES/LIST',
    AdviserHistoriesSet = 'ADVISER/HISTORIES/SET',

    AdviserNotesAdd = 'ADVISER/NOTES/ADD',
    AdviserNotesList = 'ADVISER/NOTES/LIST',
    AdviserNotesSet = 'ADVISER/NOTES/SET',

    AdviserNoteRemove = 'ADVISER/NOTE/REMOVE',
    AdviserNoteSet = 'ADVISER/NOTE/SET',
}

export default AdvisersActionsEnum;
