import _ from 'lodash';
import IInvestorAccountIncomeTrust from '~Api/IncomeTrust/IInvestorAccountIncomeTrust';
import IInvestorIncomeTrustTransaction from '~Api/IncomeTrust/IInvestorIncomeTrustTransaction';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IAnnualStatement from '~Api/Investor/IAnnualStatement';
import IBankAccount from '~Api/Investor/IBankAccount';
import IComplianceDocument from '~Api/Investor/IComplianceDocument';
import IDocument from '~Api/Investor/IDocument';
import IHistory from '~Api/Investor/IHistory';
import IInvestor from '~Api/Investor/IInvestor';
import IInvestorAccountInvestment from '~Api/Investor/IInvestorAccountInvestment';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import IInvestorAccountTransaction from '~Api/Investor/IInvestorAccountTransaction';
import IInvestorUser from '~Api/Investor/IInvestorUser';
import IMonthlyStatement from '~Api/Investor/IMonthlyStatement';
import INote from '~Api/Investor/INote';
import IPendingWithdrawal from '~Api/Investor/IPendingWithdrawal';
import IReferral from '~Api/Investor/IReferral';
import IShare from '~Api/Investor/IShare';
import ITransaction from '~Api/Investor/ITransaction';
import IWithdrawalAbaInvestorTransaction from '~Api/WithdrawalAba/IWithdrawalAbaInvestorTransaction';
import { investmentSelector } from '~Investments/selectors';
import { IGlobalState } from '~reducer';
import { userSelector } from '~Users/selectors';
import { IDictionary } from '~utilities/IDictionary';

function hydrateInvestor(state: IGlobalState, investor: IInvestor): IInvestor {
    const cloneInvestor: IInvestor = { ...investor };
    cloneInvestor.bankAccounts = investorBankAccountsSelector(state, investor.uuid);
    cloneInvestor.accounts = {};
    _.each(cloneInvestor.accountTypeUuids, (accountUuid: string) => {
        cloneInvestor.accounts[state.investors.accounts[accountUuid].accountType] = state.investors.accounts[accountUuid];
    });

    return cloneInvestor;
}

function hydrateInvestorAccount(state: IGlobalState, investorAccount: IAccount): IAccount {
    const cloneInvestorAccount: IAccount = { ...investorAccount };
    cloneInvestorAccount.incomeTrusts = _.values(investorAccountIncomeTrustsSelector(state, investorAccount.uuid));
    return cloneInvestorAccount;
}

export function investorsBoardSelector(state: IGlobalState): IDictionary<IInvestor> {
    if (!state.investors.boardListed || !state.investors.investors) {
        return null;
    }

    const investors: IDictionary<IInvestor> = {};

    _.forEach(state.investors.investors, (investor: IInvestor) => {
        investors[investor.uuid] = hydrateInvestor(state, investor);
    });

    return investors;
}

export function investorsSearchResultsSelector(state: IGlobalState): IDictionary<IInvestor> {
    if (!state.investors.investorsSearchResultUuids) {
        return null;
    }

    const investorsSearchResults: IDictionary<IInvestor> = {};
    _.each(state.investors.investorsSearchResultUuids, (uuid: string) => {
        investorsSearchResults[uuid] = investorSelector(state, uuid);
    });

    return investorsSearchResults;
}

export function investorsSelector(state: IGlobalState): IDictionary<IInvestor> {
    if (!state.investors.investorsListed || !state.investors.investors) {
        return null;
    }

    const investors: IDictionary<IInvestor> = {};
    _.each(state.investors.investors, (investor: IInvestor) => {
        investors[investor.uuid] = hydrateInvestor(state, investor);
    });

    return investors;
}

export function investorAccountSelector(state: IGlobalState, uuid: string): IAccount {
    if (!state.investors.accounts || !state.investors.accounts[uuid]) {
        return null;
    }

    return hydrateInvestorAccount(state, state.investors.accounts[uuid]);
}

export function investorAccountsSelector(state: IGlobalState): IDictionary<IAccount> {
    if (!state.investors.accounts || !state.investors.accountsListed) {
        return null;
    }

    const investorAccounts: IDictionary<IAccount> = {};
    _.each(state.investors.accounts, (investorAccount: IAccount) => {
        investorAccounts[investorAccount.uuid] = hydrateInvestorAccount(state, state.investors.accounts[investorAccount.uuid]);
    });

    return investorAccounts;
}

export function investorIncomeTrustAccountsSelector(state: IGlobalState): IDictionary<IAccount> {
    if (!state.investors.accountsIncomeTrustUuids) {
        return null;
    }

    const investorAccounts: IDictionary<IAccount> = {};
    _.each(state.investors.accountsIncomeTrustUuids, (investorAccountUuid: string) => {
        investorAccounts[investorAccountUuid] = hydrateInvestorAccount(state, state.investors.accounts[investorAccountUuid]);
    });

    return investorAccounts;
}

export function investorAccountInvestmentSelector(state: IGlobalState, investorAccountInvestmentUuid: string): IInvestorAccountInvestment {
    if (!state.investors.investorAccountInvestments[investorAccountInvestmentUuid]) {
        return null;
    }

    const investorAccountInvestmentClone: IInvestorAccountInvestment = { ...state.investors.investorAccountInvestments[investorAccountInvestmentUuid] };
    investorAccountInvestmentClone.investment = investmentSelector(state, investorAccountInvestmentClone.investmentUuid);
    investorAccountInvestmentClone.investor = investorSelector(state, investorAccountInvestmentClone.investorUuid);

    return investorAccountInvestmentClone;
}

export function investorAccountInvestmentsSelector(state: IGlobalState, accountUuid: string): IDictionary<IInvestorAccountInvestment> {
    if (!state.investors.investorAccountInvestmentUuids[accountUuid]) {
        return null;
    }

    const investorAccountInvestments: IDictionary<IInvestorAccountInvestment> = {};
    _.each(state.investors.investorAccountInvestmentUuids[accountUuid], (investorAccountInvestmentUuid: string) => {
        investorAccountInvestments[investorAccountInvestmentUuid] = investorAccountInvestmentSelector(state, investorAccountInvestmentUuid);
    });

    return investorAccountInvestments;
}

export function investorAccountInvestmentTransactionsSelector(state: IGlobalState, investorAccountInvestmentUuid: string): IDictionary<IInvestorAccountInvestmentTransaction> {
    if (!state.investors.investorAccountInvestmentTransactionUuids[investorAccountInvestmentUuid]) {
        return null;
    }

    const transactions: IDictionary<IInvestorAccountInvestmentTransaction> = {};
    _.each(state.investors.investorAccountInvestmentTransactionUuids[investorAccountInvestmentUuid], (investorAccountInvestmentTransactionUuid: string) => {
        transactions[investorAccountInvestmentTransactionUuid] = state.investors.investorAccountInvestmentTransactions[investorAccountInvestmentTransactionUuid];
    });

    return transactions;
}

export function investorAccountSharesSelector(state: IGlobalState, accountUuid: string): IShare[] {
    return state.investors.shares && state.investors.shares[accountUuid] &&  _.values(state.investors.shares[accountUuid]);
}

export function investorAccountTransactionsSelector(state: IGlobalState, accountUuid: string): IDictionary<ITransaction> {
    return state.investors.transactions[accountUuid];
}

export function investorPendingWithdrawalsUnapprovedSelector(state: IGlobalState): IDictionary<IPendingWithdrawal> {
    if (!state.investors.investorPendingWithdrawals) {
        return null;
    }

    const pendingWithdrawalsUnapproved: IDictionary<IPendingWithdrawal> = {};
    _.each(state.investors.investorPendingWithdrawals, (pendingWithdrawal: IPendingWithdrawal) => {
        if (pendingWithdrawal.approvedTime !== null) {
            return;
        }

        pendingWithdrawalsUnapproved[pendingWithdrawal.uuid] = {
            ...pendingWithdrawal,
            investorAccountTransaction: investorAccountTransactionSelector(state, pendingWithdrawal.investorAccountTransactionUuid),
        };
    });

    return pendingWithdrawalsUnapproved;
}

export function investorAccountTransactionSelector(state: IGlobalState, transactionUuid: string): IInvestorAccountTransaction {
    if (!state.investors.investorAccountTransactions[transactionUuid]) {
        return null;
    }

    const cloneInvestorAccountTransaction: IInvestorAccountTransaction = { ...state.investors.investorAccountTransactions[transactionUuid] };
    cloneInvestorAccountTransaction.investorAccount = investorAccountSelector(state, cloneInvestorAccountTransaction.investorAccountUuid);
    return cloneInvestorAccountTransaction;
}

export function investorAccountAnnualStatementsSelector(state: IGlobalState, investorAccountUuid: string): IDictionary<IAnnualStatement> {
    if (!state.investors.annualStatements || !state.investors.investorAccountAnnualStatementUuids || !state.investors.investorAccountAnnualStatementUuids[investorAccountUuid]) {
        return null;
    }

    const annualStatements: IDictionary<IAnnualStatement> = {};
    _.each(state.investors.investorAccountAnnualStatementUuids[investorAccountUuid], (investorStatementUuid: string) => {
        annualStatements[investorStatementUuid] = state.investors.annualStatements[investorStatementUuid];
    });

    return annualStatements;
}

export function investorAccountIncomeTrustsSelector(state: IGlobalState, investorAccountUuid: string): IDictionary<IInvestorAccountIncomeTrust> {
    return state.investors.incomeTrusts[investorAccountUuid];
}

export function investorAccountIncomeTrustTransactionsSelector(state: IGlobalState, investorAccountIncomeTrustUuid: string): IDictionary<IInvestorIncomeTrustTransaction> {
    return state.investors.incomeTrustTransactions[investorAccountIncomeTrustUuid];
}

export function investorAccountMonthlyStatementsSelector(state: IGlobalState, investorAccountUuid: string): IDictionary<IMonthlyStatement> {
    if (!state.investors.monthlyStatements || !state.investors.investorAccountMonthlyStatementUuids || !state.investors.investorAccountMonthlyStatementUuids[investorAccountUuid]) {
        return null;
    }

    const monthlyStatements: IDictionary<IMonthlyStatement> = {};
    _.each(state.investors.investorAccountMonthlyStatementUuids[investorAccountUuid], (investorStatementUuid: string) => {
        monthlyStatements[investorStatementUuid] = state.investors.monthlyStatements[investorStatementUuid];
    });

    return monthlyStatements;
}

export function investorAccountWithdrawalAbaInvestorTransactionsSelector(state: IGlobalState, investorAccountUuid: string): IDictionary<IWithdrawalAbaInvestorTransaction> {
    return state.investors.investorAccountWithdrawalAbaInvestorTransactions[investorAccountUuid];
}

export function investorAccountsPendingApprovalSelector(state: IGlobalState): IDictionary<IAccount> {
    if (!state.investors.accountsPendingApprovalUuids) {
        return null;
    }

    const investorAccounts: IDictionary<IAccount> = {};
    _.each(state.investors.accountsPendingApprovalUuids, (investorAccountUuid: string) => {
        investorAccounts[investorAccountUuid] = hydrateInvestorAccount(state, state.investors.accounts[investorAccountUuid]);
    });

    return investorAccounts;
}

export function investorBankAccountSelector(state: IGlobalState, bankAccountUuid: string): IBankAccount {
    return state.investors.bankAccounts[bankAccountUuid];
}

export function investorBankAccountsSelector(state: IGlobalState, investorUuid: string): IDictionary<IBankAccount> {
    const investorBankAccounts: IDictionary<IBankAccount> = {};
    _.each(state.investors.investorBankAccountUuids[investorUuid], (bankAccountUuid: string) => {
        investorBankAccounts[bankAccountUuid] = investorBankAccountSelector(state, bankAccountUuid);
    });

    return investorBankAccounts;
}

export function investorComplianceDocumentsSelector(state: IGlobalState, investorUuid: string): IDictionary<IComplianceDocument> {
    if (!state.investors.investorComplianceDocumentUuids[investorUuid]) {
        return null;
    }

    const complianceDocuments: IDictionary<IComplianceDocument> = {};

    state.investors.investorComplianceDocumentUuids[investorUuid].forEach((complianceDocumentUuid: string) => {
        if (!state.investors.complianceDocuments[complianceDocumentUuid]) {
            // Handle deleted documents
            return;
        }

        complianceDocuments[complianceDocumentUuid] = state.investors.complianceDocuments[complianceDocumentUuid];
    });

    return complianceDocuments;
}

export function investorDocumentsSelector(state: IGlobalState, investorUuid: string): IDocument[] {
    return state.investors.documents[investorUuid] && _.values(state.investors.documents[investorUuid]);
}

export function investorHistoriesSelector(state: IGlobalState, investorUuid: string): IHistory[] {
    return state.investors.histories[investorUuid] && _.values(state.investors.histories[investorUuid]);
}

export function investorNotesSelector(state: IGlobalState, investorUuid: string): INote[] {
    return state.investors.notes[investorUuid] && _.values(state.investors.notes[investorUuid]);
}

export function investorReferralsInvestorSelector(state: IGlobalState, uuid: string): IReferral[] {
    if (!state.investors.referrals || !state.investors.referrals[uuid]) {
        return null;
    }

    return _.values(state.investors.referrals[uuid]).map((referral: IReferral) => {
        const clonedReferral: IReferral = { ...referral };
        clonedReferral.referralInvestor = investorSelector(state, clonedReferral.referralInvestorUuid);
        return clonedReferral;
    });
}

export function investorReferralsSelector(state: IGlobalState): IReferral[] {
    if (!state.investors.referralsListed || !state.investors.referrals) {
        return null;
    }

    const referrals: IReferral[] = [];

    _.values(state.investors.referrals).forEach((investorReferral: { [referralId: string]: IReferral }) => {
        _.values(investorReferral).forEach((referral: IReferral) => {
            const clonedReferral: IReferral = { ...referral };
            clonedReferral.investor = investorSelector(state, clonedReferral.investorUuid);
            clonedReferral.referralInvestor = investorSelector(state, clonedReferral.referralInvestorUuid);
            referrals.push(clonedReferral);
        });
    });

    return referrals;
}

export function investorSelector(state: IGlobalState, investorUuid: string): IInvestor {
    const investor: IInvestor = state.investors.investors && state.investors.investors[investorUuid];
    if (investor) {
        return hydrateInvestor(state, investor);
    }
    return investor;
}

export function investorUsersSelector(state: IGlobalState, investorUuid: string): IInvestorUser[] {
    return state.investors.investorUsers && state.investors.investorUsers[investorUuid] &&  _.values(state.investors.investorUsers[investorUuid]).map((investorUser: IInvestorUser) => {
        investorUser.user = userSelector(state, investorUser.userUuid);
        return investorUser;
    });
}

export function managedInvestorsSelector(state: IGlobalState): IDictionary<IInvestor> {
    if (!state.investors.managedListed || !state.investors.investors) {
        return null;
    }

    const investors: IDictionary<IInvestor> = {};

    _.each(state.investors.investors, (investor: IInvestor) => {
        if (!investor.isManaged) {
            return;
        }

        investors[investor.uuid] = hydrateInvestor(state, investor);
    });

    return investors;
}

export function pendingDepositsSelector(state: IGlobalState): IDictionary<IAccount> {
    if (!state.investors.pendingDepositUuids) {
        return null;
    }

    const accounts: IDictionary<IAccount> = {};

    state.investors.pendingDepositUuids.forEach((accountUuid: string) => {
        accounts[accountUuid] = investorAccountSelector(state, accountUuid);
    });

    return accounts;
}

export function privateInvestorsSelector(state: IGlobalState): IDictionary<IInvestor> {
    if (!state.investors.privateListed) {
        return null;
    }

    const investors: IDictionary<IInvestor> = {};

    _.each(state.investors.accounts, (account: IAccount) => {
        if (AccountTypeEnum.Private !== account.accountType) {
            return;
        }

        const investor: IInvestor = investorSelector(state, account.investorUuid);

        investors[investor.uuid] = investor;
    });

    return investors;
}