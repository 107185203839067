import { SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Layout, Space, Spin, Table, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import { administratorsActiveSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import IApplication from '~Api/Application/IApplication';
import WorkflowStatusEnum from '~Api/Application/WorkflowStatusEnum';
import LoanProcessorSelector from '~Deals/LoanProcessorSelector';
import { IGlobalState } from '~reducer';
import SearchDropdown from '~UI/SearchDropdown';
import { applicationsListAction } from './actions';
import './applications.less';
import Search from './Search';
import { applicationsSelector } from './selectors';
import { IDictionary } from '~utilities/IDictionary';
import { ColumnType } from 'antd/lib/table';
import { ColumnFilterItem, FilterDropdownProps } from 'antd/lib/table/interface';
import AssigneeSelector from './Application/AssigneeSelector';

const workflowStatusLabels: IDictionary<string> = {
    [WorkflowStatusEnum.ConditionalApproval]: 'Conditional Approval',
    [WorkflowStatusEnum.Draft]: 'Draft',
    [WorkflowStatusEnum.LegalDocuments]: 'Legal Documents',
    [WorkflowStatusEnum.New]: 'New',
    [WorkflowStatusEnum.Settlement]: 'Settlement',
    [WorkflowStatusEnum.Underwriting]: 'Underwriting',
    [WorkflowStatusEnum.Warehoused]: 'Settled',
};

interface IPropsSelector {
    activeAdministrators: IAdministrator[];
    applications: IApplication[];
}

interface IPropsDispatch {
    administratorsList: () => void;
    applicationsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class List extends React.Component<Props> {
    private refreshInterval: NodeJS.Timeout;

    public componentDidMount() {
        const { activeAdministrators } = this.props;

        this.props.applicationsList();

        if (!activeAdministrators) {
            this.props.administratorsList();
        }

        this.refreshInterval = setInterval(() => {
            this.props.applicationsList();
        }, 5 * 60 * 1000);
    }

    public componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    public render(): JSX.Element {
        const { activeAdministrators, applications } = this.props;

        if (!applications || !activeAdministrators) {
            return (
                <Layout className='applications'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Applications</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const workflowFilters: ColumnFilterItem[] = _.keys(workflowStatusLabels).map((workflowStatus: string) => ({
            text: workflowStatusLabels[workflowStatus],
            value: workflowStatus,
        }));

        const columns: ColumnType<IApplication>[] = [
            {
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: boolean|number|string, application: IApplication) => application.code.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                render: (application: IApplication) => (
                    <Space>
                        <Link to={`/applications/${application.uuid}`}>{application.code}</Link>
                        {application.isExtension && <Tag color='blue'>E</Tag>}
                    </Space>
                ),
                sorter: (a: IApplication, b: IApplication) => a.code.localeCompare(b.code),
                title: 'Code',
                width: '10%',
            },
            {
                dataIndex: 'formattedName',
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: boolean|number|string, application: IApplication) => application.formattedName.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                render: (formattedName: string, application: IApplication) => <Link to={`/applications/${application.uuid}`}>{formattedName}</Link>,
                sorter: (a: IApplication, b: IApplication) => a.formattedName.localeCompare(b.formattedName),
                title: 'Name',
            },
            {
                dataIndex: 'loanAmount',
                render: (loanAmount: number) => currencyFormatter.format(loanAmount),
                sorter: (a: IApplication, b: IApplication) => a.loanAmount > b.loanAmount ? 1 : -1,
                title: 'Loan Amount',
                width: '15%',
            },
            {
                filters: activeAdministrators.map((administrator: IAdministrator) => ({
                    text: administrator.name,
                    value: administrator.uuid,
                })),
                onFilter: (value: boolean|number|string, application: IApplication) => application.administratorUuid === value,
                render: (application: IApplication) => <AssigneeSelector application={application} />,
                title: 'Loan Officer',
                width: '15%',
            },
            {
                filters: activeAdministrators.map((administrator: IAdministrator) => ({
                    text: administrator.name,
                    value: administrator.uuid,
                })),
                onFilter: (value: boolean|number|string, application: IApplication) => application.deal.loanProcessorUuids.includes(value.toLocaleString()),
                render: (application: IApplication) => <LoanProcessorSelector deal={application.deal} />,
                title: 'Loan Processor',
                width: '15%',
            },
            {
                dataIndex: 'workflowStatus',
                filters: [
                    ...workflowFilters,
                    {
                        text: 'Closed',
                        value: 'CLOSED',
                    },
                ],
                onFilter: (value: boolean|number|string, application: IApplication) => value === (application.workflowStatus === WorkflowStatusEnum.Warehoused || !application.closeReason ? application.workflowStatus : 'CLOSED'),
                render: (workflowStatus: WorkflowStatusEnum, application: IApplication) => workflowStatus === WorkflowStatusEnum.Warehoused || !application.closeReason ? workflowStatusLabels[workflowStatus] : 'Closed',
                title: 'Status',
                width: '15%',
            },
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => dayjs(createdTime).fromNow(),
                sorter: (a: IApplication, b: IApplication) => dayjs(a.createdTime) > dayjs(b.createdTime)  ? 1 : -1,
                title: 'Created',
                width: '10%',
            },
        ];

        return (
            <Layout className='applications'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Applications</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Search/>
                            <Link to='/applications'><Button>Board View</Button></Link>
                        </Space>
                        <Typography.Title level={2}>Applications</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.values(applications)}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        activeAdministrators: administratorsActiveSelector(state),
        applications: applicationsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        applicationsList: () => dispatch(applicationsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(List);
