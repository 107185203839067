import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Spin } from 'antd';
import _ from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import {
    administratorsActiveSelector,
    administratorsSelector,
    currentAdministratorSelector,
} from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import { IDictionary } from '~utilities/IDictionary';
import { applicationAssignAction } from '~Applications/actions';
import IApplication from '~Api/Application/IApplication';
import WorkflowStatusEnum from '~Api/Application/WorkflowStatusEnum';

export default function AssigneeSelector(props: {
    application: IApplication;
}): ReactElement {
    const { application } = props;

    const activeAdministrators: IAdministrator[] = useSelector(administratorsActiveSelector);
    const administrators: IDictionary<IAdministrator> = useSelector(administratorsSelector);
    const currentAdministrator: IAdministrator = useSelector(currentAdministratorSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!activeAdministrators || !administrators) {
            dispatch(administratorsListAction());
        }
    }, []);

    if (!activeAdministrators || !administrators || !currentAdministrator) {
        return (
            <Spin/>
        );
    }

    const assignee: IAdministrator = !!application.administratorUuid && administrators[application.administratorUuid];

    if ([
        RoleEnum.BusinessDevelopmentManager,
        RoleEnum.InternalBusinessDevelopmentManager,
        RoleEnum.SeniorBusinessDevelopmentManager,
    ].includes(currentAdministrator.role)) {
        return <>{application.administratorUuid ? (assignee ? assignee.name : 'Inactive User') : 'None'}</>;
    }

    const filteredAssignees: IAdministrator[] = _.filter(activeAdministrators, (admin: IAdministrator) => {
        return [
            RoleEnum.CreditManager,
            RoleEnum.SeniorCreditManager,
            RoleEnum.SeniorLoanManager,
            RoleEnum.LoanManager,
        ].includes(admin.role);
    });

    const administratorsMenu: MenuProps = {
        items: _.sortBy(filteredAssignees, ['name']).map((administrator: IAdministrator) => ({
            key: administrator.uuid,
            label: administrator.name,
            onClick: () => dispatch(applicationAssignAction(application.uuid, administrator.uuid)),
        })),
    };

    return (
        <Dropdown
            disabled={WorkflowStatusEnum.Warehoused === application.workflowStatus || null !== application.closeReason}
            menu={administratorsMenu}
        >
            <a>
                {application.administratorUuid ? (assignee ? assignee.name : 'Inactive User') : 'None'}
                {' '}<DownOutlined/>
            </a>
        </Dropdown>
    );
}
