import { Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IDeal from '~Api/Deal/IDeal';
import IQuote from '~Api/Deal/IQuote';
import { leadQuoteSendAction } from '~Leads/actions';

interface IState {
    email: string;
    firstName: string;
    lastName: string;
}

interface IProps {
    isOpen: boolean;
    deal: IDeal;
    quote: IQuote;
    onCancel: () => void;
}

interface IPropsDispatch {
    send: (email: string, firstName: string, lastName: string) => void;
}

type Props = IProps & IPropsDispatch;

class SendModal extends React.Component<Props, IState> {
    public state: IState = {
        email: null,
        firstName: null,
        lastName: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { email, firstName, lastName } = this.state;

        return (
            <Modal
                okText='Send'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Send Quote'
                wrapClassName='lead-quote-send-modal'
            >
                <Form.Item label='First Name' className='first-name'>
                    <Input onChange={this.onChangeFirstName} value={firstName} />
                </Form.Item>
                <Form.Item label='Last Name' className='last-name'>
                    <Input onChange={this.onChangeLastName} value={lastName} />
                </Form.Item>
                <Form.Item label='Email' className='email'>
                    <Input onChange={this.onChangeEmail} value={email} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            firstName: event.target.value,
        });
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            lastName: event.target.value,
        });
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            email: event.target.value,
        });
    }

    private onClickOk() {
        const { email, firstName, lastName } = this.state;

        this.props.onCancel();

        this.props.send(email, firstName, lastName);
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        send: (email: string, firstName: string, lastName: string) => dispatch(leadQuoteSendAction(ownProps.deal.uuid, ownProps.quote.uuid, email, firstName, lastName)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(SendModal);
