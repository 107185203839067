export default {
    APPLICATION_ADVERSE_CREDIT_SCORE_THRESHOLD: 621,
    BROKER_BDM_UUID_HEAD_OFFICE: 'HEAD_OFFICE',
    DEAL_ESTABLISHMENT_FEE_DOLLARS_MINIMUM: 2500,
    DEAL_ESTABLISHMENT_FEE_PERCENTAGE_DEFAULT: 2.5,
    DEAL_ESTABLISHMENT_FEE_PERCENTAGE_MINIMUM: 2,
    DEAL_INTEREST_RATE_BASE: 8.99,
    DEAL_INTEREST_RATE_MINIMUM: 6.95,
    DEAL_INTEREST_RATE_SECOND_MORTGAGE_MINIMUM: 17.99,
    DEAL_LEGAL_FEES_DOLLARS_MINIMUM: 1250,
    DEAL_LVR_MAXIMUM: 67.5,
    IDLE_TIMEOUT_SECONDS: 900, // 15 minutes
};
