import ISolicitor from './ISolicitor';

export function parseSolicitor(raw: any): ISolicitor {
    return {
        email: raw.email,
        name: raw.name,
        phone: raw.phone,
        uuid: raw.uuid,
    };
}
