import { Checkbox, Form, Modal, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IDeal from '~Api/Deal/IDeal';
import RejectReasonEnum from '~Api/Deal/RejectReasonEnum';
import { leadRejectAction } from '~Leads/actions';

interface IState {
    reason: RejectReasonEnum;
    sendEmail: boolean;
}

interface IProps {
    isOpen: boolean;
    deal: IDeal;
    onCancel: () => void;
}

interface IPropsDispatch {
    reject: (reason: RejectReasonEnum, sendEmail: boolean) => void;
}

type Props = IProps & IPropsDispatch;

class RejectModal extends React.Component<Props, IState> {
    public state: IState = {
        reason: null,
        sendEmail: true,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeReason = this.onChangeReason.bind(this);
        this.onChangeSendEmail = this.onChangeSendEmail.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { reason, sendEmail } = this.state;

        const sendEmailBlock: JSX.Element = (reason !== RejectReasonEnum.Product) && (
            <Form.Item className='send-email' label='Send Customer Email'>
                <Checkbox checked={sendEmail} onChange={this.onChangeSendEmail} />
            </Form.Item>
        );

        return (
            <Modal
                okText='Reject'
                okType='danger'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Reject'
                wrapClassName='lead-reject-modal'
            >
                <Form.Item className='reason' label='Reason'>
                    <Select onChange={this.onChangeReason} value={reason}>
                        <Select.Option value={RejectReasonEnum.AssetClass}>Asset Class</Select.Option>
                        <Select.Option value={RejectReasonEnum.Location}>Location</Select.Option>
                        <Select.Option value={RejectReasonEnum.Lvr}>LVR</Select.Option>
                        <Select.Option value={RejectReasonEnum.Product}>Product Mismatch</Select.Option>
                    </Select>
                </Form.Item>
                {sendEmailBlock}
            </Modal>
        );
    }

    private onChangeReason(value: RejectReasonEnum): void {
        this.setState({
            reason: value,
        });
    }

    private onChangeSendEmail(event: CheckboxChangeEvent): void {
        this.setState({
            sendEmail: event.target.checked,
        });
    }

    private onClickOk(): void {
        const { reason, sendEmail } = this.state;

        this.props.onCancel();

        this.props.reject(reason, sendEmail);
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        reject: (reason: RejectReasonEnum, sendEmail: boolean) => dispatch(leadRejectAction(ownProps.deal.uuid, reason, sendEmail)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(RejectModal);
