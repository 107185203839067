enum LeadsActionsEnum {
    LeadsList = 'LEADS/LIST',
    LeadsSet = 'LEADS/SET',

    LeadsBoardList = 'LEADS/BOARD/LIST',
    LeadsBoardSet = 'LEADS/BOARD/SET',

    LeadsOriginationReportList = 'LEADS/ORIGINATION_REPORT/LIST',
    LeadsOriginationReportSet = 'LEADS/ORIGINATION_REPORT/SET',
    LeadsMovementReportList = 'LEADS/MOVEMENT_REPORT/LIST',
    LeadsMovementReportSet = 'LEADS/MOVEMENT_REPORT/SET',

    LeadsPendingQuotesList = 'LEADS/PENDING_QUOTES/LIST',
    LeadsPendingQuotesSet = 'LEADS/PENDING_QUOTES/SET',

    LeadsSearch = 'LEADS/SEARCH',
    LeadsSearchResultsSet = 'LEADS/SEARCH/RESULTS/SET',
    LeadsSearchResultsClear = 'LEADS/SEARCH/RESULTS/CLEAR',

    LeadAdd = 'LEAD/ADD',
    LeadGet = 'LEAD/GET',
    LeadInitialCall = 'LEAD/INITIAL_CALL',
    LeadRefer = 'LEAD/REFER',
    LeadReject = 'LEAD/REJECT',
    LeadSend = 'LEAD/SEND',
    LeadSet = 'LEAD/SET',
    LeadValueSet = 'LEAD/VALUE/SET',

    LeadAddErrorSet = 'LEAD/ADD/ERROR_SET',

    LeadBrokerSearch = 'LEAD/BROKER_SEARCH',
    LeadBrokerSearchResultsClear = 'LEAD/BROKER_SEARCH/RESULTS_CLEAR',
    LeadBrokerSearchResultsSet = 'LEAD/BROKER_SEARCH/RESULTS_SET',

    LeadQuotesAdd = 'LEAD/QUOTES/ADD',
    LeadQuotesList = 'LEAD/QUOTES/LIST',
    LeadQuotesSet = 'LEAD/QUOTES/SET',

    LeadQuoteCreateApplication = 'LEAD/QUOTE/CREATE_APPLICATION',
    LeadQuoteSend = 'LEAD/QUOTE/SEND',
    LeadQuoteSet = 'LEAD/QUOTE/SET',
}

export default LeadsActionsEnum;
