import IInvestment from './IInvestment';

export function transformInvestment(investment: IInvestment): any {
    return {
        description: investment.description,
        memoSynopsis: investment.memoSynopsis,
        propertySecurity: investment.propertySecurity,
        propertyType: investment.propertyType,
    };
}
