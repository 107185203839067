import { DownOutlined } from '@ant-design/icons';
import { Affix, Breadcrumb, Checkbox, Col, Dropdown, Form, Input, Layout, MenuProps, Row, Select, Space, Spin, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import objectHash from 'object-hash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import roundTo from 'round-to';
import ApplicationFeeFormatEnum from '~Api/Deal/ApplicationFeeFormatEnum';
import BrokerageFeeFormatEnum from '~Api/Deal/BrokerageFeeFormatEnum';
import EstablishmentFeeFormatEnum from '~Api/Deal/EstablishmentFeeFormatEnum';
import IPostcodeCategory from '~Api/Deal/IPostcodeCategory';
import MortgageTypeEnum from '~Api/Deal/MortgageTypeEnum';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';
import constants from '~constants';
import { dealPropertiesPostcodeCategoriesListAction } from '~Deals/actions';
import ICalculatorProperty from '~Deals/ICalculatorProperty';
import { dealPropertiesPostcodeCategoriesSelector } from '~Deals/selectors';
import {
    IDealCalculateParameters,
    IDealCalculatedAmounts,
    getDealCalculatedAmounts,
    getDealCalculatedEstablishmentFeePercentage,
    getDealCalculatedInterestRate,
} from '~Deals/utilities';
import {
    IDealCalculateValidationErrors,
    validateDealEstablishmentFee,
    validateDealInterestRate,
    validateDealLegalFees,
    validateDealMaximumLvr,
} from '~Leads/validators';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import './scenario-calculator.less';
import { currencyFormatter } from '~utilities/formatters';

interface IState {
    applicationFeeDollars: number;
    applicationFeeFormat: ApplicationFeeFormatEnum;
    applicationFeePercentage: number;
    brokerageFeeDollars: number;
    brokerageFeeFormat: BrokerageFeeFormatEnum;
    brokerageFeePercentage: number;
    calculateParametersHash: string;
    calculatedAmounts: IDealCalculatedAmounts;
    commitmentFee: number;
    dirtyFields: IDictionary<boolean>;
    errors: IDealCalculateValidationErrors;
    establishmentFeeDollars: number;
    establishmentFeeFormat: EstablishmentFeeFormatEnum;
    establishmentFeePercentage: number;
    estimatedOutlays: number;
    interestRate: number;
    isThirdParty: boolean;
    legalFees: number;
    maximumLvr: number;
    mortgageType: MortgageTypeEnum;
    properties: ICalculatorProperty[];
    requestedPayoutAmount: number;
    termMonths: number;
}

interface IPropsSelector {
    postcodeCategories: IDictionary<IPostcodeCategory>;
}

interface IPropsDispatch {
    postcodeCategoriesList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class ScenarioCalculator extends React.Component<Props, IState> {
    public state: IState = {
        applicationFeeDollars: null,
        applicationFeeFormat: ApplicationFeeFormatEnum.Percentage,
        applicationFeePercentage: null,
        brokerageFeeDollars: null,
        brokerageFeeFormat: BrokerageFeeFormatEnum.Percentage,
        brokerageFeePercentage: null,
        calculateParametersHash: null,
        calculatedAmounts: null,
        commitmentFee: null,
        dirtyFields: {},
        errors: {},
        establishmentFeeDollars: null,
        establishmentFeeFormat: EstablishmentFeeFormatEnum.Percentage,
        establishmentFeePercentage: null,
        estimatedOutlays: 600,
        interestRate: null,
        isThirdParty: null,
        legalFees: 1500,
        maximumLvr: 60,
        mortgageType: MortgageTypeEnum.FirstMortgage,
        properties: [
            {
                currentDebt: null,
                estimatedValue: null,
                postcode: null,
                zoneType: null,
            },
        ],
        requestedPayoutAmount: null,
        termMonths: null,
    };

    private debouncedCalculateDealAmounts: () => void = null;

    constructor(props: Props) {
        super(props);

        this.getInterestRate = this.getInterestRate.bind(this);
        this.getCalculatedInterestRate = this.getCalculatedInterestRate.bind(this);
        this.onClickUseMatrixInterestRate = this.onClickUseMatrixInterestRate.bind(this);
        this.getEstablishmentFeePercentage = this.getEstablishmentFeePercentage.bind(this);
        this.getCalculatedEstablishmentFeePercentage = this.getCalculatedEstablishmentFeePercentage.bind(this);
        this.onClickApplicationFeeFormat = this.onClickApplicationFeeFormat.bind(this);
        this.onClickBrokerageFeeFormat = this.onClickBrokerageFeeFormat.bind(this);
        this.onClickEstablishmentFeeFormat = this.onClickEstablishmentFeeFormat.bind(this);

        this.onChangeApplicationFeePercentage = this.onChangeApplicationFeePercentage.bind(this);
        this.onChangeApplicationFeeDollars = this.onChangeApplicationFeeDollars.bind(this);
        this.onChangeBrokerageFeePercentage = this.onChangeBrokerageFeePercentage.bind(this);
        this.onChangeBrokerageFeeDollars = this.onChangeBrokerageFeeDollars.bind(this);
        this.onChangeCommitmentFee = this.onChangeCommitmentFee.bind(this);
        this.onChangeEstablishmentFeePercentage = this.onChangeEstablishmentFeePercentage.bind(this);
        this.onChangeEstablishmentFeeDollars = this.onChangeEstablishmentFeeDollars.bind(this);
        this.onChangeEstimatedOutlays = this.onChangeEstimatedOutlays.bind(this);
        this.onChangeIsThirdParty = this.onChangeIsThirdParty.bind(this);
        this.onChangeLoanAmount = this.onChangeLoanAmount.bind(this);
        this.onChangePropertyValue = this.onChangePropertyValue.bind(this);
        this.onChangePropertyCurrentDebt = this.onChangePropertyCurrentDebt.bind(this);
        this.onChangePostcode = this.onChangePostcode.bind(this);
        this.onChangeZoneType = this.onChangeZoneType.bind(this);
        this.onChangeLoanAmount = this.onChangeLoanAmount.bind(this);
        this.onChangeInterestRate = this.onChangeInterestRate.bind(this);
        this.onChangeLegalFees = this.onChangeLegalFees.bind(this);
        this.onChangeMaximumLvr = this.onChangeMaximumLvr.bind(this);

        this.validateEstablishmentFee = this.validateEstablishmentFee.bind(this);
        this.validateInterestRate = this.validateInterestRate.bind(this);
        this.validateLegalFees = this.validateLegalFees.bind(this);
        this.validateMaximumLvr = this.validateMaximumLvr.bind(this);

        this.setError = this.setError.bind(this);

        this.onChangeMortgageType = this.onChangeMortgageType.bind(this);
        this.onChangeTermMonths = this.onChangeTermMonths.bind(this);

        this.debouncedCalculateDealAmounts = _.debounce(this.calculateDealAmounts, 250);
    }

    public componentDidMount(): void {
        const { postcodeCategories } = this.props;

        if (!postcodeCategories) {
            this.props.postcodeCategoriesList();
        }

        this.calculateDealAmounts();
    }

    public componentDidUpdate(): void {
        this.debouncedCalculateDealAmounts();
    }

    private calculateDealAmounts(): void {
        const calculateParameters: IDealCalculateParameters = _.omit(this.state, ['calculateParametersHash', 'calculatedAmounts']);

        const calculateParametersHash: string = objectHash(calculateParameters);

        if (calculateParametersHash !== this.state.calculateParametersHash) {
            const calculatedAmounts: IDealCalculatedAmounts = getDealCalculatedAmounts({
                ...calculateParameters,
                establishmentFeePercentage: this.getEstablishmentFeePercentage(),
                interestRate: this.getInterestRate(),
            });

            this.setState({
                calculateParametersHash,
                calculatedAmounts,
            });
        }
    }

    public render(): JSX.Element {
        const { postcodeCategories } = this.props;
        const {
            applicationFeeDollars,
            applicationFeeFormat,
            applicationFeePercentage,
            calculatedAmounts,
            commitmentFee,
            brokerageFeeDollars,
            brokerageFeeFormat,
            brokerageFeePercentage,
            errors,
            establishmentFeeDollars,
            establishmentFeeFormat,
            estimatedOutlays,
            legalFees,
            maximumLvr,
            mortgageType,
            properties,
            requestedPayoutAmount,
            termMonths,
            isThirdParty,
        } = this.state;

        if (!calculatedAmounts || !postcodeCategories) {
            return (
                <Layout className='leads-scenario-calculator'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Leads</Breadcrumb.Item>
                        <Breadcrumb.Item>Scenario Calculator</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Scenario Calculator</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const {
            applicationFeeTotal,
            brokerageFeeTotal,
            establishmentFeeTotal,
            grossLoanAmount,
            interestPayable,
            lvr,
            netPrepaidBalanceOnSettlement,
        } = calculatedAmounts;

        const property: ICalculatorProperty = properties[0];
        const interestRate: number = this.getInterestRate();
        const calculatedInterestRate: number = this.getCalculatedInterestRate();
        const establishmentFeePercentage: number = this.getEstablishmentFeePercentage();
        const calculatedEstablishmentFeePercentage: number = this.getCalculatedEstablishmentFeePercentage();

        const defaultInterestRate: number = interestRate ? roundTo(interestRate + 10, 2) : null;

        const monthlyInterestPaymentsNormal: number = Math.ceil(grossLoanAmount * (interestRate / 100) / 12);
        const monthlyInterestPaymentsDefault: number = Math.ceil(grossLoanAmount * (defaultInterestRate / 100) / 12);
        const grossBalanceOnSettlement: number = grossLoanAmount - interestPayable - establishmentFeeTotal - applicationFeeTotal - brokerageFeeTotal - legalFees - estimatedOutlays;

        const totalCostsDollars: number = grossLoanAmount - netPrepaidBalanceOnSettlement;
        const totalCostsPercentage: number = Number((grossLoanAmount - netPrepaidBalanceOnSettlement) / grossLoanAmount * 100);
        const totalCostsPercentagePerAnnum: number = Number((establishmentFeeTotal + applicationFeeTotal + brokerageFeeTotal + legalFees + estimatedOutlays + (monthlyInterestPaymentsNormal * 12)) / grossLoanAmount * 100);

        const establishmentFeeMenu: MenuProps = {
            items: [
                {
                    key: 'establishment-fee-format',
                    label: establishmentFeeFormat === EstablishmentFeeFormatEnum.Percentage ? 'Dollar Value' : 'Percentage',
                    onClick: this.onClickEstablishmentFeeFormat,
                },
            ],
        };

        const establishmentFeeLabel: JSX.Element = (
            <Dropdown menu={establishmentFeeMenu}>
                <a>Establishment Fee <DownOutlined /></a>
            </Dropdown>
        );

        const applicationFeeMenu: MenuProps = {
            items: [
                {
                    key: 'application-fee-format',
                    label: applicationFeeFormat === ApplicationFeeFormatEnum.Percentage ? 'Dollar Value' : 'Percentage',
                    onClick: this.onClickApplicationFeeFormat,
                },
            ],
        };

        const applicationFeeLabel: JSX.Element = (
            <Dropdown menu={applicationFeeMenu}>
                <a>Application Fee <DownOutlined /></a>
            </Dropdown>
        );

        const brokerageFeeMenu: MenuProps = {
            items: [
                {
                    key: 'brokerage-fee-format',
                    label: brokerageFeeFormat === BrokerageFeeFormatEnum.Percentage ? 'Dollar Value' : 'Percentage',
                    onClick: this.onClickBrokerageFeeFormat,
                },
            ],
        };

        const brokerageFeeLabel: JSX.Element = (
            <Dropdown menu={brokerageFeeMenu}>
                <a>Brokerage Fee <DownOutlined /></a>
            </Dropdown>
        );

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const interestRateMinimumLimit: number = mortgageType === MortgageTypeEnum.SecondMortgage ? constants.DEAL_INTEREST_RATE_SECOND_MORTGAGE_MINIMUM : constants.DEAL_INTEREST_RATE_MINIMUM;

        return (
            <Layout className='leads-scenario-calculator'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Leads</Breadcrumb.Item>
                    <Breadcrumb.Item>Scenario Calculator</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Scenario Calculator</Typography.Title>
                        <Row>
                            <Col span={12}>
                                <Form.Item label='Mortgage Type' className='mortgage-type'>
                                    <Select onChange={this.onChangeMortgageType} value={mortgageType}>
                                        <Select.Option value={MortgageTypeEnum.FirstMortgage}>First Mortgage</Select.Option>
                                        <Select.Option value={MortgageTypeEnum.SecondMortgage}>Second Mortgage</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label='Loan Term' className='term-months'>
                                    <Select onChange={this.onChangeTermMonths} value={termMonths}>
                                        {_.times(36, (months: number) => (<Select.Option key={months + 1} value={months + 1}>{months + 1} months</Select.Option>))}
                                    </Select>
                                </Form.Item>
                                <Form.Item className='loan-amount' label='Requested Payout Amount' extra={currencyFormatter.format(requestedPayoutAmount)}>
                                    <Input addonBefore='$' onChange={this.onChangeLoanAmount} type='number' value={requestedPayoutAmount} />
                                </Form.Item>
                                <Form.Item className='property-value' label='Property Value' extra={currencyFormatter.format(property.estimatedValue)}>
                                    <Input addonBefore='$' onChange={this.onChangePropertyValue} type='number' value={property.estimatedValue} />
                                </Form.Item>
                                <Form.Item className='current-debt' label='Current Debt' extra={currencyFormatter.format(property.currentDebt)}>
                                    <Input addonBefore='$' onChange={this.onChangePropertyCurrentDebt} type='number' value={property.currentDebt} />
                                </Form.Item>
                                <Form.Item className='postcode' label='Postcode'>
                                    <Input onChange={this.onChangePostcode} value={property.postcode} />
                                </Form.Item>
                                <Form.Item className='zone-type' label='Zoning'>
                                    <Select onChange={this.onChangeZoneType} value={property.zoneType}>
                                        <Select.Option value={ZoneTypeEnum.ResidentialHouse}>Residential - House</Select.Option>
                                        <Select.Option value={ZoneTypeEnum.ResidentialTownhouse}>Residential - Townhouse / Villa</Select.Option>
                                        <Select.Option value={ZoneTypeEnum.ResidentialUnit}>Residential - Unit / Apartment</Select.Option>
                                        <Select.Option value={ZoneTypeEnum.ResidentialLand}>Residential - Land</Select.Option>
                                        <Select.Option value={ZoneTypeEnum.CommercialOffice}>Commercial - Office</Select.Option>
                                        <Select.Option value={ZoneTypeEnum.CommercialRetail}>Commercial - Retail</Select.Option>
                                        <Select.Option value={ZoneTypeEnum.CommercialIndustrial}>Commercial - Industrial</Select.Option>
                                        <Select.Option value={ZoneTypeEnum.CommercialLand}>Commercial - Land</Select.Option>
                                        <Select.Option value={ZoneTypeEnum.RuralResidential}>Rural - Residential</Select.Option>
                                        <Select.Option value={ZoneTypeEnum.RuralLand}>Rural - Land</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label='Broker or Referral Partner' className='is-third-party'>
                                    <Checkbox
                                        checked={isThirdParty}
                                        onChange={this.onChangeIsThirdParty}
                                    />
                                </Form.Item>
                                <Form.Item className='matrix-rate' label='Matrix Rate'>
                                    <span>
                                        {calculatedInterestRate ? percentageFormatter.format(calculatedInterestRate / 100) : '-'}
                                        {calculatedInterestRate && <a onClick={this.onClickUseMatrixInterestRate}> (Use Matrix Rate)</a>}
                                    </span>
                                </Form.Item>
                                <Form.Item
                                    className='interest-rate'
                                    help={errors.interestRate}
                                    label='Interest Rate'
                                    validateStatus={errors.interestRate ? 'error' : (calculatedInterestRate && calculatedInterestRate !== interestRate) && 'warning'}
                                >
                                    <Space>
                                        <Input addonAfter='%' min={interestRateMinimumLimit} onBlur={this.validateInterestRate} onChange={this.onChangeInterestRate} step={0.05} type='number' value={interestRate} />
                                        <span>({percentageFormatter.format(defaultInterestRate / 100)} default)</span>
                                    </Space>
                                </Form.Item>
                                <Form.Item className='maximum-lvr' help={errors.maximumLvr} label='Maximum LVR' validateStatus={errors.maximumLvr && 'error'}>
                                    <Input addonAfter='%' max={constants.DEAL_LVR_MAXIMUM} onBlur={this.validateMaximumLvr} onChange={this.onChangeMaximumLvr} type='number' value={maximumLvr} />
                                </Form.Item>
                                <Form.Item className='commitment-fee' label='Commitment Fee'>
                                    <Input addonBefore='$' onChange={this.onChangeCommitmentFee} type='number' value={commitmentFee} />
                                </Form.Item>
                                <Form.Item
                                    className={establishmentFeeFormat === EstablishmentFeeFormatEnum.Dollars ? 'establishment-fee-dollars' : 'establishment-fee-percentage'}
                                    help={errors.establishmentFee}
                                    label={establishmentFeeLabel}
                                    validateStatus={errors.establishmentFee ? 'error' : (calculatedEstablishmentFeePercentage && calculatedEstablishmentFeePercentage !== establishmentFeePercentage) && 'warning'}
                                >
                                    {establishmentFeeFormat === EstablishmentFeeFormatEnum.Dollars && <Space><Input addonBefore='$' min={constants.DEAL_ESTABLISHMENT_FEE_DOLLARS_MINIMUM} onBlur={this.validateEstablishmentFee} onChange={this.onChangeEstablishmentFeeDollars} type='number' value={establishmentFeeDollars} /><span>({percentageFormatter.format(establishmentFeeTotal ? establishmentFeeTotal / grossLoanAmount : 0)})</span></Space>}
                                    {establishmentFeeFormat === EstablishmentFeeFormatEnum.Percentage && <Space><Input addonAfter='%' min={constants.DEAL_ESTABLISHMENT_FEE_PERCENTAGE_MINIMUM} onBlur={this.validateEstablishmentFee} onChange={this.onChangeEstablishmentFeePercentage} step={0.1} type='number' value={establishmentFeePercentage} /><span>({currencyFormatter.format(establishmentFeeTotal)})</span></Space>}
                                </Form.Item>
                                <Form.Item className={applicationFeeFormat === ApplicationFeeFormatEnum.Dollars ? 'application-fee-dollars' : 'application-fee-percentage'} label={applicationFeeLabel}>
                                    {applicationFeeFormat === ApplicationFeeFormatEnum.Dollars && <Space><Input addonBefore='$' addonAfter='+ GST' onChange={this.onChangeApplicationFeeDollars} type='number' value={applicationFeeDollars} /><span>({percentageFormatter.format(applicationFeeTotal ? applicationFeeTotal / grossLoanAmount : 0)})</span></Space>}
                                    {applicationFeeFormat === ApplicationFeeFormatEnum.Percentage && <Space><Input addonAfter='%' onChange={this.onChangeApplicationFeePercentage} step={0.1} type='number' value={applicationFeePercentage} /><span>({currencyFormatter.format(applicationFeeTotal)} + GST)</span></Space>}
                                </Form.Item>
                                <Form.Item className={brokerageFeeFormat === BrokerageFeeFormatEnum.Dollars ? 'brokerage-fee-dollars' : 'brokerage-fee-percentage'} label={brokerageFeeLabel}>
                                    {brokerageFeeFormat === BrokerageFeeFormatEnum.Dollars && <Space><Input addonBefore='$' addonAfter='+ GST' onChange={this.onChangeBrokerageFeeDollars} type='number' value={brokerageFeeDollars} /><span>({percentageFormatter.format(brokerageFeeTotal ? brokerageFeeTotal / grossLoanAmount : 0)})</span></Space>}
                                    {brokerageFeeFormat === BrokerageFeeFormatEnum.Percentage && <Space><Input addonAfter='%' onChange={this.onChangeBrokerageFeePercentage} step={0.1} type='number' value={brokerageFeePercentage} /><span>({currencyFormatter.format(brokerageFeeTotal)} + GST)</span></Space>}
                                </Form.Item>
                                <Form.Item className='legal-fees' help={errors.legalFees} label='Legal Fees' validateStatus={errors.legalFees && 'error'}>
                                    <Input addonBefore='$' addonAfter='+ GST' min={constants.DEAL_LEGAL_FEES_DOLLARS_MINIMUM} onBlur={this.validateLegalFees} onChange={this.onChangeLegalFees} type='number' value={legalFees} />
                                </Form.Item>
                                <Form.Item className='approximate-outlays' label='Approximate Outlays'>
                                    <Input addonBefore='$' onChange={this.onChangeEstimatedOutlays} type='number' value={estimatedOutlays} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Affix offsetTop={50}>
                                    <Form.Item className='gross-loan-amount' label='Gross Loan Amount'>
                                        {monthlyInterestPaymentsNormal ? currencyFormatter.format(grossLoanAmount) : '-'}
                                    </Form.Item>
                                    <Form.Item className='lvr' label='LVR'>
                                        {monthlyInterestPaymentsNormal ? percentageFormatter.format(lvr / 100) : '-'}
                                    </Form.Item>
                                    <Form.Item className='interest-payable' label='Interest Payable'>
                                        {monthlyInterestPaymentsNormal ? currencyFormatter.format(interestPayable) : '-'}
                                    </Form.Item>
                                    <Form.Item className='monthly-interest-payments' label='Monthly Interest Payments'>
                                        {monthlyInterestPaymentsNormal ? currencyFormatter.format(monthlyInterestPaymentsNormal) + ` (${currencyFormatter.format(monthlyInterestPaymentsDefault)} default)` : '-'}
                                    </Form.Item>
                                    <Form.Item className='gross-balance-on-settlement' label='Gross Balance on Settlement'>
                                        {monthlyInterestPaymentsNormal ? currencyFormatter.format(grossBalanceOnSettlement) : '-'}
                                    </Form.Item>
                                    <Form.Item className='net-prepaid-balance-on-settlement' label='Net Balance on Settlement'>
                                        {monthlyInterestPaymentsNormal ? currencyFormatter.format(netPrepaidBalanceOnSettlement) : '-'}
                                    </Form.Item>
                                    <Form.Item className='total-costs-dollars' label='Total Costs'>
                                        {monthlyInterestPaymentsNormal ? currencyFormatter.format(totalCostsDollars) + ` (${percentageFormatter.format(totalCostsPercentage / 100)}, ${percentageFormatter.format(totalCostsPercentagePerAnnum / 100)} pa)` : '-'}
                                    </Form.Item>
                                </Affix>
                            </Col>
                        </Row>
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    private getInterestRate(): number {
        if (this.state.dirtyFields.interestRate) {
            return this.state.interestRate;
        }

        return this.getCalculatedInterestRate();
    }

    private getCalculatedInterestRate(): number {
        const { postcodeCategories } = this.props;

        return getDealCalculatedInterestRate({
            ...this.state,
            postcodeCategories,
        });
    }

    private onClickUseMatrixInterestRate(): void {
        this.setState({
            dirtyFields: {
                interestRate: null,
            },
        });
    }

    private getEstablishmentFeePercentage(): number {
        if (this.state.dirtyFields.establishmentFeePercentage) {
            return this.state.establishmentFeePercentage;
        }

        return this.getCalculatedEstablishmentFeePercentage();
    }

    private getCalculatedEstablishmentFeePercentage(): number {
        return getDealCalculatedEstablishmentFeePercentage({
            ...this.state,
        });
    }

    private onClickApplicationFeeFormat(): void {
        const { applicationFeeFormat } = this.state;

        this.setState({
            applicationFeeFormat: applicationFeeFormat === ApplicationFeeFormatEnum.Dollars ? ApplicationFeeFormatEnum.Percentage : ApplicationFeeFormatEnum.Dollars,
        });
    }

    private onClickBrokerageFeeFormat(): void {
        const { brokerageFeeFormat } = this.state;

        this.setState({
            brokerageFeeFormat: brokerageFeeFormat === BrokerageFeeFormatEnum.Dollars ? BrokerageFeeFormatEnum.Percentage : BrokerageFeeFormatEnum.Dollars,
        });
    }

    private onClickEstablishmentFeeFormat(): void {
        const { establishmentFeeFormat } = this.state;

        this.setState({
            establishmentFeeFormat: establishmentFeeFormat === EstablishmentFeeFormatEnum.Dollars ? EstablishmentFeeFormatEnum.Percentage : EstablishmentFeeFormatEnum.Dollars,
        });
    }

    private onChangeApplicationFeeDollars(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            applicationFeeDollars: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeApplicationFeePercentage(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            applicationFeePercentage: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeBrokerageFeeDollars(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            brokerageFeeDollars: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeBrokerageFeePercentage(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            brokerageFeePercentage: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeCommitmentFee(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            commitmentFee: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeEstablishmentFeeDollars(event: React.ChangeEvent<HTMLInputElement>): void {
        const establishmentFeeDollars: number = Number(event.target.value);

        this.setState({
            establishmentFeeDollars: event.target.value ? establishmentFeeDollars : null,
        });
    }

    private onChangeEstablishmentFeePercentage(event: React.ChangeEvent<HTMLInputElement>): void {
        const establishmentFeePercentage: number = Number(event.target.value);

        const state: IState = {
            ...this.state,
            dirtyFields: {
                ...this.state.dirtyFields,
                establishmentFeePercentage: true,
            },
            establishmentFeePercentage: event.target.value ? establishmentFeePercentage : null,
        };

        this.setState(state);
    }

    private onChangeEstimatedOutlays(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            estimatedOutlays: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeIsThirdParty(event: CheckboxChangeEvent): void {
        this.setState({
            isThirdParty: event.target.checked,
        });
    }

    private onChangeLoanAmount(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            requestedPayoutAmount: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangePropertyValue(event: React.ChangeEvent<HTMLInputElement>): void {
        const { properties } = this.state;

        properties[0].estimatedValue = event.target.value ? Number(event.target.value) : null;

        this.setState({
            properties,
        });
    }

    private onChangePropertyCurrentDebt(event: React.ChangeEvent<HTMLInputElement>): void {
        const { properties } = this.state;

        properties[0].currentDebt = event.target.value ? Number(event.target.value) : null;

        this.setState({
            properties,
        });
    }

    private onChangePostcode(event: React.ChangeEvent<HTMLInputElement>): void {
        if (/[^0-9]/.test(event.target.value) || event.target.value.trim().length > 4) {
            return;
        }

        const { properties } = this.state;

        properties[0].postcode = event.target.value;

        this.setState({
            properties,
        });
    }

    private onChangeZoneType(zoneType: ZoneTypeEnum): void {
        const { properties } = this.state;

        properties[0].zoneType = zoneType;

        this.setState({
            properties,
        });
    }

    private onChangeInterestRate(event: React.ChangeEvent<HTMLInputElement>): void {
        const interestRate: number = Number(event.target.value);

        const state: IState = {
            ...this.state,
            dirtyFields: {
                ...this.state.dirtyFields,
                interestRate: true,
            },
            interestRate: event.target.value ? interestRate : null,
        };

        this.setState(state);
    }

    private onChangeLegalFees(event: React.ChangeEvent<HTMLInputElement>): void {
        const legalFees: number = Number(event.target.value);

        this.setState({
            legalFees: event.target.value ? legalFees : null,
        });
    }

    private onChangeMaximumLvr(event: React.ChangeEvent<HTMLInputElement>): void {
        const maximumLvr: number = Number(event.target.value);

        this.setState({
            maximumLvr: event.target.value ? maximumLvr : null,
        });
    }

    private validateEstablishmentFee(): boolean {
        const {
            establishmentFeeTotal,
            grossLoanAmount,
        } = this.state.calculatedAmounts;

        return validateDealEstablishmentFee({
            ...this.state,
            establishmentFeeTotal,
            grossLoanAmount,
        }, this.setError);
    }

    private validateInterestRate(): boolean {
        return validateDealInterestRate({
            ...this.state,
        }, this.setError);
    }

    private validateLegalFees(): boolean {
        return validateDealLegalFees({
            ...this.state,
        }, this.setError);
    }

    private validateMaximumLvr(): boolean {
        const { netPrepaidBalanceOnSettlement } = this.state.calculatedAmounts;

        return validateDealMaximumLvr({
            ...this.state,
            netPrepaidBalanceOnSettlement,
        }, this.setError);
    }

    private onChangeMortgageType(value: MortgageTypeEnum): void {
        this.setState({
            mortgageType: value,
        });
    }

    private onChangeTermMonths(value: number): void {
        this.setState({
            termMonths: value,
        });
    }

    private setError(key: keyof IState['errors'], value: string): void {
        this.setState((previousState: IState) => ({
            errors: {
                ...previousState.errors,
                [key]: value,
            },
        }));
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        postcodeCategories: dealPropertiesPostcodeCategoriesSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        postcodeCategoriesList: () => dispatch(dealPropertiesPostcodeCategoriesListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ScenarioCalculator);
