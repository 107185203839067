import { fetchDelete, fetchGet, fetchPost, fetchPostFile, fetchPut } from '~utilities/fetch';
import INote from './INote';
import IReferralPartner from './IReferralPartner';
import {
    transformReferralPartner,
    transformReferralPartnerNote,
} from './transformers';

export async function referralPartnerApproveRequest(referralPartnerUuid: string): Promise<Response> {
    return fetchPost(`/referral-partners/${referralPartnerUuid}/approve`);
}

export async function referralPartnerGetRequest(referralPartnerUuid: string): Promise<Response> {
    return fetchGet(`/referral-partners/${referralPartnerUuid}`);
}

export async function referralPartnerDealsListRequest(referralPartnerUuid: string): Promise<Response> {
    return fetchGet(`/referral-partners/${referralPartnerUuid}/deals`);
}

export async function referralPartnerApplicationsListRequest(referralPartnerUuid: string): Promise<Response> {
    return fetchGet(`/referral-partners/${referralPartnerUuid}/applications`);
}

export async function referralPartnerDocumentsAddRequest(referralPartnerUuid: string, file: File): Promise<Response> {
    return fetchPostFile(`/referral-partners/${referralPartnerUuid}/documents`, file);
}

export async function referralPartnerDocumentsListRequest(referralPartnerUuid: string): Promise<Response> {
    return fetchGet(`/referral-partners/${referralPartnerUuid}/documents`);
}

export async function referralPartnerDocumentDeleteRequest(referralPartnerUuid: string, documentUuid: string): Promise<Response> {
    return fetchDelete(`/referral-partners/${referralPartnerUuid}/documents/${documentUuid}`);
}

export async function referralPartnerHistoriesListRequest(referralPartnerUuid: string): Promise<Response> {
    return fetchGet(`/referral-partners/${referralPartnerUuid}/history`);
}

export async function referralPartnerRejectRequest(referralPartnerUuid: string): Promise<Response> {
    return fetchPost(`/referral-partners/${referralPartnerUuid}/reject`);
}

export async function referralPartnerResetRequest(referralPartnerUuid: string): Promise<Response> {
    return fetchPost(`/referral-partners/${referralPartnerUuid}/reset`);
}

export async function referralPartnerUpdateRequest(referralPartner: IReferralPartner): Promise<Response> {
    return fetchPut(`/referral-partners/${referralPartner.uuid}`, transformReferralPartner(referralPartner));
}

export async function referralPartnersAddRequest(referralPartner: IReferralPartner): Promise<Response> {
    return fetchPost('/referral-partners', transformReferralPartner(referralPartner));
}

export async function referralPartnersListRequest(): Promise<Response> {
    return fetchGet('/referral-partners');
}

export async function referralPartnerNotesAddRequest(referralPartnerUuid: string, note: INote): Promise<Response> {
    return fetchPost(`/referral-partners/${referralPartnerUuid}/notes`, transformReferralPartnerNote(note));
}

export async function referralPartnerNotesListRequest(referralPartnerUuid: string): Promise<Response> {
    return fetchGet(`/referral-partners/${referralPartnerUuid}/notes`);
}
