import IAggregator from './IAggregator';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseAggregator(raw: any): IAggregator {
    return {
        invoiceType: raw.invoiceType,
        name: raw.name,
        uuid: raw.uuid,
    };
}
