import { Progress, Table } from 'antd';
import React, { ReactElement, ReactNode } from 'react';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';
import { ColumnType } from 'antd/lib/table';
import WarehouseParameterFormatEnum from '~Api/Warehouse/WarehouseParameterFormatEnum';
import IWarehouseParameter from '~Api/Warehouse/IWarehouseParameter';
import WarehouseParameterLimitTypeEnum from '~Api/Warehouse/WarehouseParameterLimitTypeEnum';

export default function Parameters(props: {
    parameters: IWarehouseParameter[];
}): ReactElement {
    const { parameters } = props;

    const monthsFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
    });

    const parameterColumns: ColumnType<IWarehouseParameter>[] = [
        {
            dataIndex: 'name',
            title: 'Parameter',
        },
        {
            render: (parameter: IWarehouseParameter): ReactNode => {
                if (null === parameter.actual) {
                    return;
                }

                if (WarehouseParameterLimitTypeEnum.Minimum === parameter.limitType) {
                    return (
                        <Progress
                            percent={parameter.actual / parameter.limit * 100}
                            showInfo={false}
                            size='small'
                            status={parameter.actual < parameter.limit ? 'exception' : 'success'}
                            style={{ width: '150px' }}
                        />
                    );
                }

                return (
                    <Progress
                        percent={parameter.actual / parameter.limit * 100}
                        showInfo={false}
                        size='small'
                        status={parameter.actual > parameter.limit ? 'exception' : 'success'}
                        style={{ width: '150px' }}
                    />
                );
            },
            title: '',
            width: 250,
        },
        {
            dataIndex: 'limit',
            render: (limit: number, parameter: IWarehouseParameter): ReactNode => {
                const direction: string = WarehouseParameterLimitTypeEnum.Maximum === parameter.limitType ? '' : '>';

                if (WarehouseParameterFormatEnum.Dollars === parameter.format) {
                    return `${direction} ${currencyFormatter.format(limit)}`;
                }

                if (WarehouseParameterFormatEnum.Months === parameter.format) {
                    return `${direction} ${monthsFormatter.format(limit)} months`;
                }

                if (WarehouseParameterFormatEnum.Percentage === parameter.format) {
                    return `${direction} ${percentageFormatter.format(limit)}`;
                }

                return limit;
            },
            title: 'Limit',
            width: 150,
        },
        {
            dataIndex: 'actual',
            render: (actual: number, parameter: IWarehouseParameter): ReactNode => {
                if (null === actual) {
                    return;
                }

                if (WarehouseParameterFormatEnum.Dollars === parameter.format) {
                    return currencyFormatter.format(actual);
                }

                if (WarehouseParameterFormatEnum.Months === parameter.format) {
                    return `${monthsFormatter.format(actual)} months`;
                }

                if (WarehouseParameterFormatEnum.Percentage === parameter.format) {
                    return percentageFormatter.format(actual);
                }

                return actual;
            },
            title: 'Actual',
            width: 150,
        },
    ];

    return (
        <Table
            columns={parameterColumns}
            dataSource={parameters}
            pagination={false}
            size='middle'
        />
    );
}
