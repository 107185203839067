import { Layout as AntLayout, Breadcrumb, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';

export default function Layout(props: {
    children: JSX.Element | JSX.Element[];
    section: string;
}): ReactElement {
    const { children, section } = props;

    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);

    const menuItems: ItemType[] = [
        {
            key: 'investors',
            label: <NavLink to={'/investments/income-trust'}>Investors</NavLink>,
        },
        {
            key: 'distributions',
            label: <NavLink to={'/investments/income-trust/distributions'}>Distributions</NavLink>,
        },
        ...(currentUser.permissions.includes(PermissionsEnum.InvestorIncomeTrustInvestmentRequests) ? [
            {
                key: 'pending-requests',
                label: <NavLink to={'/investments/income-trust/pending-requests'}>Pending Requests</NavLink>,
            },
        ] : []),
    ];

    return (
        <AntLayout className='investment'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/investments'>Investments</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Income Trust</Breadcrumb.Item>
            </Breadcrumb>
            <AntLayout className='content-wrapper'>
                <AntLayout.Sider className='menu' theme='light' width={240}>
                    <Menu
                        defaultSelectedKeys={[section]}
                        items={menuItems}
                        mode='inline'
                    />
                </AntLayout.Sider>
                <AntLayout.Content className={`content ${section}`}>
                    {children}
                </AntLayout.Content>
            </AntLayout>
        </AntLayout>
    );
}
