import _ from 'lodash';
import IApplication from '~Api/Application/IApplication';
import IDeal from '~Api/Deal/IDeal';
import ApprovalStatusEnum from '~Api/ReferralPartner/ApprovalStatusEnum';
import IDocument from '~Api/ReferralPartner/IDocument';
import IHistory from '~Api/ReferralPartner/IHistory';
import INote from '~Api/ReferralPartner/INote';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import { applicationSelector } from '~Applications/selectors';
import { leadSelector } from '~Leads/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

export function approvedReferralPartnersSelector(state: IGlobalState): IDictionary<IReferralPartner> {
    const referralPartners: IDictionary<IReferralPartner> = {};

    _.values(state.referralPartners.referralPartners).forEach((referralPartner: IReferralPartner) => {
        if (referralPartner.approvalStatus === ApprovalStatusEnum.Approved) {
            referralPartners[referralPartner.uuid] = referralPartner;
        }
    });

    return referralPartners;
}

export function referralPartnerApplicationsSelector(state: IGlobalState, referralPartnerUuid: string): IDictionary<IApplication> {
    if (!state.referralPartners.referralPartnerApplicationUuids || !state.referralPartners.referralPartnerApplicationUuids[referralPartnerUuid]) {
        return null;
    }

    const applications: IDictionary<IApplication> = {};

    _.each(state.referralPartners.referralPartnerApplicationUuids[referralPartnerUuid], (applicationUuid: string) => {
        applications[applicationUuid] = applicationSelector(state, applicationUuid);
    });

    return applications;
}

export function referralPartnerDealsSelector(state: IGlobalState, referralPartnerUuid: string): IDictionary<IDeal> {
    if (!state.referralPartners.referralPartnerDealUuids || !state.referralPartners.referralPartnerDealUuids[referralPartnerUuid]) {
        return null;
    }

    const deals: IDictionary<IDeal> = {};

    _.each(state.referralPartners.referralPartnerDealUuids[referralPartnerUuid], (dealUuid: string) => {
        deals[dealUuid] = leadSelector(state, dealUuid);
    });

    return deals;
}

export function referralPartnerDocumentsSelector(state: IGlobalState, referralPartnerUuid: string): IDictionary<IDocument> {
    return state.referralPartners.documents[referralPartnerUuid] && state.referralPartners.documents[referralPartnerUuid];
}

export function referralPartnerHistoriesSelector(state: IGlobalState, referralPartnerUuid: string): IDictionary<IHistory> {
    return state.referralPartners.histories[referralPartnerUuid] && state.referralPartners.histories[referralPartnerUuid];
}

export function referralPartnerSelector(state: IGlobalState, referralPartnerUuid: string): IReferralPartner {
    return state.referralPartners.referralPartners && state.referralPartners.referralPartners[referralPartnerUuid];
}

export function referralPartnersSelector(state: IGlobalState): IDictionary<IReferralPartner> {
    return state.referralPartners.referralPartnersListed && state.referralPartners.referralPartners;
}

export function referralPartnerNotesSelector(state: IGlobalState, referralPartnerUuid: string): IDictionary<INote> {
    return state.referralPartners.referralPartners[referralPartnerUuid] && state.referralPartners.notes[referralPartnerUuid];
}
