import { SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Layout, Space, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { FilterDropdownProps, SortOrder, TablePaginationConfig } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import { IGlobalState } from '~reducer';
import SearchDropdown from '~UI/SearchDropdown';
import { IDictionary } from '~utilities/IDictionary';
import {
    loanListSettingsSetAction,
    loansPaginatedListAction,
} from './actions';
import './loans.less';
import Search from './Search';
import {
    loanListSettingsSelector,
    loansPaginatedCountSelector,
    loansPaginatedSelector,
} from './selectors';
import { administratorsActiveSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import AssigneeSelector from './Loan/AssigneeSelector';
import { administratorsListAction } from '~Administrators/actions';
import ListSortFieldEnum from '~Api/Loan/ListSortFieldEnum';
import ListSortOrderEnum from '~Api/Loan/ListSortOrderEnum';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';

const tableSortFieldMappings: IDictionary<ListSortFieldEnum> = {
    amount: ListSortFieldEnum.Amount,
    code: ListSortFieldEnum.Code,
    name: ListSortFieldEnum.Name,
    rate: ListSortFieldEnum.Rate,
    startDate: ListSortFieldEnum.StartDate,
};

const tableSortOrderMappings: IDictionary<ListSortOrderEnum> = {
    ascend: ListSortOrderEnum.Ascending,
    descend: ListSortOrderEnum.Descending,
};

const loanStatusLabels: IDictionary<string> = {
    [LoanStatusEnum.ActiveBadStanding]: 'Active - Bad Standing',
    [LoanStatusEnum.ActiveGoodStanding]: 'Active - Good Standing',
    [LoanStatusEnum.ActiveMatured]: 'Active - Matured',
    [LoanStatusEnum.Approved]: 'Approved',
    [LoanStatusEnum.Cancelled]: 'Cancelled',
    [LoanStatusEnum.ClosedObligationsMet]: 'Closed - Obligations Met',
    [LoanStatusEnum.ClosedRefinanced]: 'Closed - Refinanced',
    [LoanStatusEnum.ClosedWrittenOff]: 'Closed - Written Off',
    [LoanStatusEnum.PartialApplication]: 'Partial Application',
    [LoanStatusEnum.PendingApproval]: 'Pending Approval',
};

export interface ILoanListSettings {
    administratorUuidFilter: string[];
    codeSearch: string[];
    nameSearch: string[];
    currentPage: number;
    pageSize: number;
    sortField: string;
    sortOrder: SortOrder;
    statusFilter: LoanStatusEnum[];
}

interface IPropsSelector {
    activeAdministrators: IAdministrator[];
    loanListSettings: ILoanListSettings;
    loans: IDictionary<ILoan>;
    loansPaginatedCount: number;
}

interface IPropsDispatch {
    administratorsList: () => void;
    loansList: (page: number, perPage: number, orderBy: ListSortFieldEnum, order: ListSortOrderEnum, statuses: LoanStatusEnum[], administratorUuids: string[], code?: string, name?: string) => void;
    settingsSet: (settings: ILoanListSettings) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class List extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeTable = this.onChangeTable.bind(this);
    }

    public componentDidMount(): void {
        const { activeAdministrators, loanListSettings } = this.props;

        this.props.loansList(
            loanListSettings.currentPage,
            loanListSettings.pageSize,
            ListSortFieldEnum.StartDate,
            ListSortOrderEnum.Descending,
            loanListSettings.statusFilter,
            loanListSettings.administratorUuidFilter,
            loanListSettings.codeSearch ? loanListSettings.codeSearch[0] : null,
            loanListSettings.nameSearch ? loanListSettings.nameSearch[0] : null,
        );

        if (!activeAdministrators) {
            this.props.administratorsList();
        }
    }

    public render(): JSX.Element {
        const { activeAdministrators, loanListSettings, loans, loansPaginatedCount } = this.props;

        if (!loans || !activeAdministrators) {
            return (
                <Layout className='loans'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Loans</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Loans</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const columns: ColumnType<ILoan>[] = [
            {
                dataIndex: 'code',
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                filteredValue: loanListSettings.codeSearch,
                onFilter: (value: string | number | boolean, loan: ILoan) => `${loan.salesforceCode} ${loan.code}`.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                render: (code: string, loan: ILoan) => <Link to={`/loans/${loan.uuid}`}>{code || loan.salesforceCode}</Link>,
                sortOrder: loanListSettings.sortField === 'code' ? loanListSettings.sortOrder : null,
                sorter: (a: ILoan, b: ILoan) => (a.code || a.salesforceCode).localeCompare(b.code || b.salesforceCode),
                title: 'Code',
                width: '10%',
            },
            {
                dataIndex: 'contactName',
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                filteredValue: loanListSettings.nameSearch,
                onFilter: (value: string | number | boolean, loan: ILoan) => (loan.contactName || '').toLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                render: (name: string) => name || '-',
                sortOrder: loanListSettings.sortField === 'contactName' ? loanListSettings.sortOrder : null,
                sorter: (a: ILoan, b: ILoan) => (a.contactName || '').localeCompare(b.contactName || ''),
                title: 'Name',
            },
            {
                dataIndex: 'amount',
                render: (amount: number) => currencyFormatter.format(amount),
                sortOrder: loanListSettings.sortField === 'amount' ? loanListSettings.sortOrder : null,
                sorter: (a: ILoan, b: ILoan) => a.amount > b.amount  ? 1 : -1,
                title: 'Amount',
                width: '15%',
            },
            {
                dataIndex: 'administratorUuid',
                filteredValue: loanListSettings.administratorUuidFilter,
                filters: activeAdministrators.map((administrator: IAdministrator) => ({
                    text: administrator.name,
                    value: administrator.uuid,
                })),
                onFilter: (value: boolean|number|string, loan: ILoan) => loan.administratorUuid === value,
                render: (administratorUuid: string, loan: ILoan) => <AssigneeSelector loan={loan} />,
                title: 'Loan Manager',
                width: '15%',
            },
            {
                dataIndex: 'interestRate',
                render: (interestRate: number) => interestRate ? percentageFormatter.format(interestRate / 100) : '-',
                sortOrder: loanListSettings.sortField === 'interestRate' ? loanListSettings.sortOrder : null,
                sorter: (a: ILoan, b: ILoan) => a.interestRate > b.interestRate  ? 1 : -1,
                title: 'Rate',
                width: '10%',
            },
            {
                dataIndex: 'status',
                filteredValue: loanListSettings.statusFilter,
                filters: _.keys(loanStatusLabels).map((status: string) => {
                    return {
                        text: loanStatusLabels[status],
                        value: status,
                    };
                }),
                onFilter: (value: string | number | boolean, loan: ILoan) => loan.status === value,
                render: (status: LoanStatusEnum) => loanStatusLabels[status],
                title: 'Status',
                width: '20%',
            },
            {
                dataIndex: 'startDate',
                defaultSortOrder: 'descend',
                render: (startDate: string) => startDate ? dayjs(startDate).format('Do MMMM YYYY') : '-',
                sortOrder: loanListSettings.sortField === 'startDate' ? loanListSettings.sortOrder : null,
                sorter: (a: ILoan, b: ILoan) => dayjs(a.startDate) > dayjs(b.startDate)  ? 1 : -1,
                title: 'Start Date',
                width: '15%',
            },
        ];

        return (
            <Layout className='loans'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Loans</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Search/>
                            <Link to='/loans'><Button>Board View</Button></Link>
                        </Space>
                        <Typography.Title level={2}>Loans</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.values(loans)}
                            loading={!loans}
                            onChange={this.onChangeTable}
                            pagination={{ current: loanListSettings.currentPage, pageSize: loanListSettings.pageSize, total: loansPaginatedCount }}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    private onChangeTable(pagination: TablePaginationConfig, filters: any, sorter: any): void {
        this.props.loansList(
            pagination.current,
            pagination.pageSize,
            tableSortFieldMappings[sorter.columnKey] || ListSortFieldEnum.StartDate,
            tableSortOrderMappings[sorter.order] || ListSortOrderEnum.Descending,
            filters.status || [],
            filters.administratorUuid || [],
            filters.code ? filters.code[0] : null,
            filters.name ? filters.name[0] : null,
        );

        this.props.settingsSet({
            administratorUuidFilter: filters.administratorUuid,
            codeSearch: filters.code,
            currentPage: pagination.current,
            nameSearch: filters.contactName,
            pageSize: pagination.pageSize,
            sortField: sorter.field,
            sortOrder: sorter.order,
            statusFilter: filters.status,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        activeAdministrators: administratorsActiveSelector(state),
        loanListSettings: loanListSettingsSelector(state),
        loans: loansPaginatedSelector(state),
        loansPaginatedCount: loansPaginatedCountSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        loansList: (
            page: number,
            perPage: number,
            orderBy: ListSortFieldEnum,
            order: ListSortOrderEnum,
            statuses: LoanStatusEnum[],
            administratorUuids: string[],
            code?: string,
            name?: string,
        ) => dispatch(loansPaginatedListAction(
            page,
            perPage,
            orderBy,
            order,
            statuses,
            administratorUuids,
            code,
            name,
        )),
        settingsSet: (settings: ILoanListSettings) => dispatch(loanListSettingsSetAction(settings)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(List);
