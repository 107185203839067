import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import Layout from '~Layout';
import Dashboard from './Dashboard';
import Investors from './IncomeTrust/Investors';
import Investment from './Investment';
import List from './List';
import PendingDistributions from './PendingDistributions';
import Distributions from './IncomeTrust/Distributions';
import PendingRequests from './IncomeTrust/PendingRequests';

export default function Router(): ReactElement {
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);

    return (
        <Layout topSection='investments'>
            <Switch>
                <Route exact={true} path='/investments' component={Dashboard} />
                <Route exact={true} path='/investments/income-trust' component={Investors} />
                <Route exact={true} path='/investments/income-trust/distributions' component={Distributions} />
                {currentUser.permissions.includes(PermissionsEnum.InvestorIncomeTrustInvestmentRequests) && <Route exact={true} path='/investments/income-trust/pending-requests' component={PendingRequests} />}
                <Route exact={true} path='/investments/list' component={List} />
                {currentUser.permissions.includes(PermissionsEnum.InvestmentPendingDistributions) && <Route exact={true} path='/investments/pending-distributions' component={PendingDistributions} />}
                <Route path='/investments/:uuid' component={Investment} />
            </Switch>
        </Layout>
    );
}
