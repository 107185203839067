import { DownOutlined, LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { G2, Pie } from '@ant-design/plots';
import { IGroup } from '@antv/g2';
import { PieOptions } from '@antv/g2plot';
import { IG } from '@antv/g2/lib/dependents';
import { Datum, MappingDatum } from '@antv/g2/lib/interface.d';
import { Breadcrumb, Button, Card, Col, Dropdown, Form, Layout, MenuProps, Radio, RadioChangeEvent, Row, Space, Spin, Statistic, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IOriginationReportLead from '~Api/Deal/IOriginationReportLead';
import referredToLabels from '~Leads/referredToLabels';
import sourceLabels from '~Leads/sourceLabels';
import { IGlobalState } from '~reducer';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';
import { IDictionary } from '~utilities/IDictionary';
import { CountOrDollarEnum, DirectionEnum, IPieData, PeriodRangeEnum, directionToParameterMap, periodRangeLabels, rangeToParameterMap } from '~utilities/reportUtilities';
import DatePicker from '~UI/DatePicker';
import {
    ILeadsOriginationReportListAction,
    leadsOriginationReportListAction,
} from './actions';
import './origination-report.less';
import {
    leadsOriginationReportEndDateSelector,
    leadsOriginationReportLeadsSelector,
    leadsOriginationReportPeriodRangeSelector,
    leadsOriginationReportStartDateSelector,
} from './selectors';

interface IPropsSelector {
    endDate: string;
    originationReportLeads: IDictionary<IOriginationReportLead>;
    selectedPeriodRange: PeriodRangeEnum,
    startDate: string;
}

interface IPropsDispatch {
    originationReportLeadsList: (startDate: string, endDate: string, periodRange: PeriodRangeEnum) => ILeadsOriginationReportListAction
}

interface IState {
    countOrDollar: CountOrDollarEnum;
}

type Props = IPropsSelector & IPropsDispatch;

class OriginationReport extends React.Component<Props> {
    public state: IState = {
        countOrDollar: CountOrDollarEnum.Dollar,
    };

    constructor(props: Props) {
        super(props);
        this.onChangeCountValueSwitcher = this.onChangeCountValueSwitcher.bind(this);
        this.onChangeDateRange = this.onChangeDateRange.bind(this);
        this.onChangeSelectedPeriodRange = this.onChangeSelectedPeriodRange.bind(this);
        this.onClickPrevious = this.onClickPrevious.bind(this);
        this.onClickNext = this.onClickNext.bind(this);
    }

    public componentDidMount(): void {
        this.props.originationReportLeadsList(
            dayjs().startOf('week').format('YYYY-MM-DD'),
            dayjs().endOf('week').format('YYYY-MM-DD'),
            PeriodRangeEnum.Week
        );
    }

    public render(): JSX.Element {
        const { endDate, originationReportLeads, selectedPeriodRange, startDate } = this.props;
        const { countOrDollar } = this.state;

        const periodRangeMenu: MenuProps = {
            items: [
                {
                    key: 'day',
                    label: 'Day',
                    onClick: () => this.onChangeSelectedPeriodRange(PeriodRangeEnum.Day),
                },
                {
                    key: 'week',
                    label: 'Week',
                    onClick: () => this.onChangeSelectedPeriodRange(PeriodRangeEnum.Week),
                },
                {
                    key: 'month',
                    label: 'Month',
                    onClick: () => this.onChangeSelectedPeriodRange(PeriodRangeEnum.Month),
                },
                {
                    key: 'year',
                    label: 'Year',
                    onClick: () => this.onChangeSelectedPeriodRange(PeriodRangeEnum.Year),
                },
            ],
        };

        const dateRangeSelector: JSX.Element = (
            <Space className='date-picker'>
                <Button.Group>
                    <Button icon={<LeftOutlined/>} onClick={this.onClickPrevious} disabled={!originationReportLeads}/>
                    <Dropdown menu={periodRangeMenu} disabled={!originationReportLeads}>
                        <Button>
                            <Space>
                                {periodRangeLabels[selectedPeriodRange]}
                                <DownOutlined/>
                            </Space>
                        </Button>
                    </Dropdown>
                    <Button icon={<RightOutlined/>} onClick={this.onClickNext} disabled={!originationReportLeads}/>
                </Button.Group>
                <Form.Item>
                    <DatePicker.RangePicker
                        picker='date'
                        format='DD/MM/YYYY'
                        onChange={this.onChangeDateRange}
                        value={[startDate ? dayjs(startDate) : null, endDate ? dayjs(endDate) : null]}
                        disabled={!originationReportLeads}
                    />
                </Form.Item>
            </Space>
        );

        const countValueSwitcher: JSX.Element = (
            <Radio.Group
                value={countOrDollar}
                onChange={this.onChangeCountValueSwitcher}
                disabled={!originationReportLeads}
            >
                <Radio.Button value={CountOrDollarEnum.Dollar}>Value</Radio.Button>
                <Radio.Button value={CountOrDollarEnum.Count}>Count</Radio.Button>
            </Radio.Group>
        );

        if (!originationReportLeads) {
            return (
                <Layout className='leads-origination-report'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/leads'>Leads</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Origination Report</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Space className='actions'>
                                {dateRangeSelector}
                                {countValueSwitcher}
                            </Space>
                            <Typography.Title level={2}>Leads Origination Report</Typography.Title>
                        </Layout.Content>
                    </Layout>
                    <Spin />
                </Layout>
            );
        }

        const aggregatorData: IDictionary<IPieData> = {};
        const brokerData: IDictionary<IPieData> = {};
        const bdmData: IDictionary<IPieData> = {};
        const referredToData: IDictionary<IPieData> = {};
        const sourceData: IDictionary<IPieData> = {};
        let newLeadsCount: number = 0;
        let newLeadsValue: number = 0;

        _.forEach(originationReportLeads, (lead: IOriginationReportLead) => {
            const sourceType: string = lead.source === null ? 'Others' : sourceLabels[lead.source];
            if (!sourceData[lead.source]) {
                sourceData[lead.source] = {
                    type: sourceType,
                    value: 0,
                };
            }
            sourceData[lead.source].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : lead.loanAmount);

            const referredToType: string = lead.referredTo === null ? 'Retained' : referredToLabels[lead.referredTo];
            if (!referredToData[lead.referredTo]) {
                referredToData[lead.referredTo] = {
                    type: referredToType,
                    value: 0,
                };
            }
            referredToData[lead.referredTo].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : lead.loanAmount);

            const brokerLabel: string = lead.brokerUuid === null ? 'Direct' : `${lead.brokerFirstName} ${lead.brokerLastName}`;
            if (!brokerData[lead.brokerUuid]) {
                brokerData[lead.brokerUuid] = {
                    type: brokerLabel,
                    value: 0,
                };
            }
            brokerData[lead.brokerUuid].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : lead.loanAmount);

            const bdmLabel: string = lead.bdmUuid === null ? 'No BDM' : lead.bdmName;
            if (!bdmData[lead.bdmUuid]) {
                bdmData[lead.bdmUuid] = {
                    type: bdmLabel,
                    value: 0,
                };
            }
            bdmData[lead.bdmUuid].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : lead.loanAmount);

            if (lead.aggregatorUuid === null) {
                if (lead.brokerUuid === null) {
                    if (!aggregatorData['NO_BROKER']) {
                        aggregatorData['NO_BROKER'] = {
                            type: 'No Broker',
                            value: 0,
                        };
                    }
                    aggregatorData['NO_BROKER'].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : lead.loanAmount);
                } else {
                    if (!aggregatorData['NO_AGGREGATOR']) {
                        aggregatorData['NO_AGGREGATOR'] = {
                            type: 'No Aggregator',
                            value: 0,
                        };
                    }
                    aggregatorData['NO_AGGREGATOR'].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : lead.loanAmount);
                }
            } else {
                if (!aggregatorData[lead.aggregatorUuid]) {
                    aggregatorData[lead.aggregatorUuid] = {
                        type: lead.aggregatorName,
                        value: 0,
                    };
                }
                aggregatorData[lead.aggregatorUuid].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : lead.loanAmount);
            }

            newLeadsCount++;
            newLeadsValue += lead.loanAmount;
        });

        return (
            <Layout className='leads-origination-report'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/leads'>Leads</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Origination Report</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            {dateRangeSelector}
                            {countValueSwitcher}
                        </Space>
                        <Typography.Title level={2}>Leads Origination Report</Typography.Title>
                    </Layout.Content>
                </Layout>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} className='new-leads-count'>
                            <Typography.Title level={4}>New Leads Count</Typography.Title>
                            <Statistic
                                value={newLeadsCount}
                                precision={0}
                                prefix={<PlusOutlined/>}
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} className='new-leads-value'>
                            <Typography.Title level={4}>New Leads Value</Typography.Title>
                            <Statistic
                                value={currencyFormatter.format(newLeadsValue)}
                                precision={0}
                                prefix={<PlusOutlined/>}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} className='pie-chart-left'>
                            <Typography.Title level={4}>Source</Typography.Title>
                            {this.renderPieChart(_.values(sourceData))}
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} className='pie-chart-right'>
                            <Typography.Title level={4}>Referred To</Typography.Title>
                            {this.renderPieChart(_.values(referredToData))}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} className='pie-chart-left'>
                            <Typography.Title level={4}>Broker</Typography.Title>
                            {this.renderPieChart(this.topsSelector(_.values(brokerData), 15))}
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} className='pie-chart-right'>
                            <Typography.Title level={4}>Aggregator</Typography.Title>
                            {this.renderPieChart(this.topsSelector(_.values(aggregatorData), 15))}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} className='pie-chart-left'>
                            <Typography.Title level={4}>BDM</Typography.Title>
                            {this.renderPieChart(_.values(bdmData))}
                        </Card>
                    </Col>
                </Row>
            </Layout>
        );
    }

    private onChangeSelectedPeriodRange(selectedPeriodRange: PeriodRangeEnum): void {
        const updatedEndDate: string = dayjs().endOf(rangeToParameterMap[selectedPeriodRange]).format('YYYY-MM-DD');
        const updatedStartDate: string = dayjs().startOf(rangeToParameterMap[selectedPeriodRange]).format('YYYY-MM-DD');

        this.props.originationReportLeadsList(updatedStartDate, updatedEndDate, selectedPeriodRange);
    }

    private onClickPrevious(): void {
        this.onChangePeriod(DirectionEnum.Previous);
    }

    private onClickNext(): void {
        this.onChangePeriod(DirectionEnum.Next);
    }

    private onChangeDateRange(dateRange: [Dayjs, Dayjs]): void {
        const { selectedPeriodRange } = this.props;
        const updatedEndDate: string = dateRange[1].format('YYYY-MM-DD');
        const updatedStartDate: string = dateRange[0].format('YYYY-MM-DD');
        this.props.originationReportLeadsList(updatedStartDate, updatedEndDate, selectedPeriodRange);
    }

    private onChangePeriod(direction: DirectionEnum): void {
        const { selectedPeriodRange, startDate } = this.props;

        const from: Dayjs = (startDate ? dayjs(startDate) : dayjs());
        const updatedStartDate: Dayjs = from.add(directionToParameterMap[direction], rangeToParameterMap[selectedPeriodRange]).startOf(rangeToParameterMap[selectedPeriodRange]);
        const updatedEndDate: Dayjs = updatedStartDate.endOf(rangeToParameterMap[selectedPeriodRange]);

        this.props.originationReportLeadsList(updatedStartDate.format('YYYY-MM-DD'), updatedEndDate.format('YYYY-MM-DD'), selectedPeriodRange);
    }

    private onChangeCountValueSwitcher(e: RadioChangeEvent): void {
        this.setState({
            countOrDollar: e.target.value,
        });
    }

    private renderPieChart(data: IPieData[]): JSX.Element {
        const G: IG = G2.getEngine('canvas');
        const labelFormatter: (data: Datum, mappingData: MappingDatum) => IGroup = (data: Datum, mappingData: MappingDatum) => {
            const group: IGroup = new G.Group({});
            group.addShape({
                attrs: {
                    fill: mappingData.color,
                    height: 50,
                    r: 5,
                    width: 40,
                    x: 0,
                    y: 0,
                },
                type: 'circle',
            });
            group.addShape({
                attrs: {
                    fill: mappingData.color,
                    text: `${data.type}`,
                    x: 10,
                    y: 8,
                },
                type: 'text',
            });
            group.addShape({
                attrs: {
                    fill: 'rgba(0, 0, 0, 0.65)',
                    fontWeight: 700,
                    text: (this.state.countOrDollar === CountOrDollarEnum.Count)
                        ? `${data.value} - ${percentageFormatter.format(data.percent)}`
                        : `${currencyFormatter.format(data.value)} - ${percentageFormatter.format(data.percent)}`,
                    x: 0,
                    y: 25,
                },
                type: 'text',
            });
            return group;
        };

        const config: PieOptions = {
            angleField: 'value',
            appendPadding: 10,
            colorField: 'type',
            data,
            interactions: [
                {
                    type: 'element-selected',
                },
                {
                    type: 'element-active',
                },
            ],
            label: {
                formatter: labelFormatter,
                labelHeight: 40,
                type: 'spider',
            },
            radius: 0.75,
            tooltip: {
                formatter: (data: Datum) => {
                    return {
                        name: data.type,
                        value: (this.state.countOrDollar === CountOrDollarEnum.Count) ? data.value : `${currencyFormatter.format(data.value)}`,
                    };
                },
            },
        };

        return <Pie {...config} />;
    }

    private topsSelector: (data: IPieData[], shortlist: number) => IPieData[] = (data: IPieData[], shortlist: number) => {
        if (data.length > shortlist) {
            data.sort((a: IPieData, b: IPieData) => b.value - a.value);

            const tops: IPieData[] = data.slice(0, shortlist);
            const others: IPieData = data.slice(shortlist+1).reduce((acc: IPieData, curr: IPieData) => {
                acc.value += curr.value;
                return acc;
            }, { type: 'Others', value: 0 });

            return [...tops, others];
        }

        return data;
    };
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        endDate: leadsOriginationReportEndDateSelector(state),
        originationReportLeads: leadsOriginationReportLeadsSelector(state),
        selectedPeriodRange: leadsOriginationReportPeriodRangeSelector(state),
        startDate: leadsOriginationReportStartDateSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        originationReportLeadsList: (startDate: string, endDate: string, periodRange: PeriodRangeEnum) => dispatch(leadsOriginationReportListAction(startDate, endDate, periodRange)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OriginationReport);
