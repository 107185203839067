import _ from 'lodash';
import IDistribution from '~Api/IncomeTrust/IDistribution';
import IIncomeTrustInvestmentRequest from '~Api/IncomeTrust/IIncomeTrustInvestmentRequest';
import IIncomeTrustTransaction from '~Api/IncomeTrust/IIncomeTrustTransaction';
import IInvestorIncomeTrustTransaction from '~Api/IncomeTrust/IInvestorIncomeTrustTransaction';
import IncomeTrustClassTypeEnum from '~Api/IncomeTrust/IncomeTrustClassTypeEnum';
import IHistory from '~Api/Investment/IHistory';
import IInvestment from '~Api/Investment/IInvestment';
import IInvestmentTransaction from '~Api/Investment/IInvestmentTransaction';
import IPendingDistribution from '~Api/Investment/IPendingDistribution';
import IReservation from '~Api/Investment/IReservation';
import IInvestorAccountInvestment from '~Api/Investor/IInvestorAccountInvestment';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import {
    investorAccountInvestmentSelector,
    investorAccountSelector,
} from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { keyMap } from '~utilities/utils';

export function incomeTrustDistributionsSelector(state: IGlobalState, classType: IncomeTrustClassTypeEnum): IDistribution[] {
    return state.investments.incomeTrustDistributions[classType];
}

export function incomeTrustInvestmentRequestsPendingSelector(state: IGlobalState): IDictionary<IIncomeTrustInvestmentRequest> {
    return state.investments.pendingIncomeTrustInvestmentRequests;
}

export function incomeTrustTransactionsSelector(state: IGlobalState): IDictionary<IIncomeTrustTransaction> {
    return state.investments.incomeTrustTransactions;
}

export function incomeTrustTransactionInvestorsSelector(state: IGlobalState, incomeTrustTransactionUuid: string): IInvestorIncomeTrustTransaction[] {
    return state.investments.investorIncomeTrustTransactions[incomeTrustTransactionUuid];
}

export function investmentInvestorAccountInvestmentsSelector(state: IGlobalState, investmentUuid: string): IDictionary<IInvestorAccountInvestment> {
    if (!state.investments.investmentInvestorAccountInvestmentUuids[investmentUuid]) {
        return null;
    }

    const investorAccountInvestments: IDictionary<IInvestorAccountInvestment> = {};
    state.investments.investmentInvestorAccountInvestmentUuids[investmentUuid].forEach((investorAccountInvestmentUuid: string) => {
        investorAccountInvestments[investorAccountInvestmentUuid] = investorAccountInvestmentSelector(state, investorAccountInvestmentUuid);
    });

    return investorAccountInvestments;
}

export function investmentSelector(state: IGlobalState, uuid: string): IInvestment {
    return state.investments.investments && state.investments.investments[uuid];
}

export function investmentHistoriesSelector(state: IGlobalState, uuid: string): IHistory[] {
    return state.investments.histories[uuid] && _.values(state.investments.histories[uuid]);
}

export function investmentPendingDistributionsSelector(state: IGlobalState): IDictionary<IPendingDistribution> {
    if (!state.investments.pendingDistributions) {
        return null;
    }

    const pendingDistributions: IDictionary<IPendingDistribution> = {};

    _.forEach(state.investments.pendingDistributions, (pendingDistribution: IPendingDistribution) => {
        const clonedPendingDistribution: IPendingDistribution = { ...pendingDistribution };
        clonedPendingDistribution.investment = investmentSelector(state, pendingDistribution.investmentUuid);
        pendingDistributions[clonedPendingDistribution.warehouseLoanTransactionUuid] = clonedPendingDistribution;
    });

    return pendingDistributions;
}

export function investmentPhotoInProgressSelector(state: IGlobalState): boolean {
    return state.investments.photoInProgress;
}

export function investmentReservationSelector(state: IGlobalState, investmentReservationUuid: string): IReservation {
    return state.investments.reservations[investmentReservationUuid];
}

export function investmentReservationsSelector(state: IGlobalState, investmentUuid: string): IDictionary<IReservation> {
    if (!state.investments.investmentReservationUuids[investmentUuid]) {
        return null;
    }

    const reservations: IDictionary<IReservation> = {};
    state.investments.investmentReservationUuids[investmentUuid].forEach((reservationUuid: string) => {
        reservations[reservationUuid] = {
            ...state.investments.reservations[reservationUuid],
            investorAccount: investorAccountSelector(state, state.investments.reservations[reservationUuid].investorAccountUuid),
        };

    });

    return reservations;
}

export function investmentTransactionsSelector(state: IGlobalState, investmentUuid: string): IDictionary<IInvestmentTransaction> {
    if (!state.investments.investmentTransactionUuids[investmentUuid]) {
        return null;
    }

    const transactions: IDictionary<IInvestmentTransaction> = {};
    state.investments.investmentTransactionUuids[investmentUuid].forEach((transactionUuid: string) => {
        transactions[transactionUuid] = state.investments.transactions[transactionUuid];
    });

    return transactions;
}

export function investmentTransactionSelector(state: IGlobalState, investmentTransactionUuid: string): IInvestmentTransaction {
    return state.investments.transactions[investmentTransactionUuid];
}

export function investmentTransactionInvestorsSelector(state: IGlobalState, investmentTransactionUuid: string): IDictionary<IInvestorAccountInvestmentTransaction> {
    if (!state.investments.investmentTransactionInvestorAccountInvestmentTransactionUuids[investmentTransactionUuid]) {
        return null;
    }

    const transactions: IDictionary<IInvestorAccountInvestmentTransaction> = {};
    state.investments.investmentTransactionInvestorAccountInvestmentTransactionUuids[investmentTransactionUuid].forEach((transactionUuid: string) => {
        transactions[transactionUuid] = state.investors.investorAccountInvestmentTransactions[transactionUuid];
    });

    return transactions;
}

export function investmentsDashboardSelector(state: IGlobalState): IDictionary<IInvestment> {
    if (!state.investments.dashboardUuids) {
        return null;
    }

    return  keyMap(state.investments.dashboardUuids, 'uuid', (investmentUuid: string) => investmentSelector(state, investmentUuid));
}

export function investmentsSearchResultsSelector(state: IGlobalState): IInvestment[] {
    if (!state.investments.investmentsSearchResultUuids) {
        return null;
    }

    return state.investments.investmentsSearchResultUuids.map((investmentUuid: string) => investmentSelector(state, investmentUuid));
}

export function investmentsSelector(state: IGlobalState): IDictionary<IInvestment> {
    return state.investments.investmentsListed && state.investments.investments;
}
