import { Spin } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import { loanGetAction } from '~Loans/actions';
import { loanSelector } from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import PropertiesList from '~Applications/PropertiesList';

interface IMatch {
    loanUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    loan: ILoan;
}

interface IPropsDispatch {
    loanGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class DealProperties extends React.Component<Props> {
    public componentDidMount() {
        const { loan } = this.props;

        if (!loan) {
            this.props.loanGet();
        }
    }

    public render(): JSX.Element {
        const { loan, match } = this.props;

        if (!loan) {
            return (
                <Layout uuid={match.params.loanUuid} section='properties'>
                    <Spin/>
                </Layout>
            );
        }

        return (
            <Layout uuid={match.params.loanUuid} section='properties'>
                <PropertiesList
                    applicationUuid={loan.applicationUuid}
                />
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        loan: loanSelector(state, ownProps.match.params.loanUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        loanGet: () => dispatch(loanGetAction(ownProps.match.params.loanUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DealProperties);
