import { AutoComplete, Input, Tag } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import history from '~history';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { applicationsSearchAction, applicationsSearchResultsClearAction } from './actions';
import { applicationsSearchResultsSelector } from './selectors';

interface IOption {
    label: JSX.Element;
    value: string;
}

interface IState {
    results: IOption[];
    searchText: string;
}

interface IPropsSelector {
    applicationsSearchResults: IDictionary<IApplication>;
}

interface IPropsDispatch {
    applicationsSearchResultsClear: () => void;
    applicationsSearch: (keyword: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Search extends React.Component<Props, IState> {
    public state: IState = {
        results: [],
        searchText: '',
    };

    constructor(props: Props) {
        super(props);

        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    public componentDidMount(): void {
        this.props.applicationsSearchResultsClear();
    }

    public render(): JSX.Element {
        const { applicationsSearchResults } = this.props;

        const options: IOption[] = applicationsSearchResults && _.map(applicationsSearchResults, (application: IApplication) => {
            const label: JSX.Element = (
                <div className='application-search-option'>
                    <span className='option-code'>{application.code}</span>
                    {application.isExtension && <Tag className='option-tag' color='blue'>E</Tag>}
                    <span className='option-name'>{application.formattedName}</span>
                </div>
            );

            return {
                label,
                value: `${application.uuid}`,
            };
        });

        return (
            <AutoComplete
                dropdownMatchSelectWidth={300}
                onSearch={this.onSearch}
                onSelect={this.onSelect}
                options={_.slice(options, 0, 20)}
                value={this.state.searchText}
            >
                <Input.Search/>
            </AutoComplete>
        );
    }

    private onSearch(value: string): void {
        this.setState({
            searchText: value,
        });

        this.props.applicationsSearch(value);
    }

    private onSelect(value: string): void {
        this.props.applicationsSearchResultsClear();

        history.push(`/applications/${value}`);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        applicationsSearchResults: applicationsSearchResultsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        applicationsSearch: (keyword) => dispatch(applicationsSearchAction(keyword)),
        applicationsSearchResultsClear: () => dispatch(applicationsSearchResultsClearAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Search);
