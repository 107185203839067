import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Applications from './Applications';
import Deals from './Deals';
import Documents from './Documents';
import Edit from './Edit';
import History from './History';
import Notes from './Notes';
import Overview from './Overview';

export default function router(): JSX.Element {
    return (
        <Switch>
            <Route exact={true} path='/brokers/:brokerUuid' component={Overview} />
            <Route exact={true} path='/brokers/:brokerUuid/applications' component={Applications} />
            <Route exact={true} path='/brokers/:brokerUuid/documents' component={Documents} />
            <Route exact={true} path='/brokers/:brokerUuid/edit' component={Edit} />
            <Route exact={true} path='/brokers/:brokerUuid/history' component={History} />
            <Route exact={true} path='/brokers/:brokerUuid/leads' component={Deals} />
            <Route exact={true} path='/brokers/:brokerUuid/notes' component={Notes} />
        </Switch>
    );
}
