import { Form, Input, Modal, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import { applicationPropertyValuerQuoteReceivedAction } from '~Applications/actions';
import DatePicker from '~UI/DatePicker';
import TimePicker from '~UI/TimePicker';
import IValuer from '~Valuers/IValuer';

interface IState {
    quoteAmount: number;
    quoteReceivedDateTime: string;
    quoteTimeFrameDays: number;
}

interface IProps {
    applicationProperty: IApplicationProperty;
    isOpen: boolean;
    onCancel: () => void;
    valuer: IValuer;
}

interface IPropsDispatch {
    quoteReceived: (quoteAmount: number, quoteReceivedDateTime: string, quoteTimeFrameDays: number) => void;
}

type Props = IProps & IPropsDispatch;

class EnterQuoteModal extends React.Component<Props, IState> {
    public state: IState = {
        quoteAmount: null,
        quoteReceivedDateTime: null,
        quoteTimeFrameDays: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeQuoteAmount = this.onChangeQuoteAmount.bind(this);
        this.onChangeTime = this.onChangeTime.bind(this);
        this.onChangeQuoteTimeFrameDays = this.onChangeQuoteTimeFrameDays.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen, onCancel } = this.props;
        const { quoteAmount, quoteReceivedDateTime, quoteTimeFrameDays} = this.state;

        const quoteReceivedDateTimeDayjs: Dayjs = quoteReceivedDateTime ? dayjs(quoteReceivedDateTime) : null;

        return (
            <Modal
                okText='Enter Quote'
                onCancel={onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Enter Valuation Quote Information'
                wrapClassName='property-valuation-enter-quote-modal'
            >
                <Form.Item className='quote-received-date' label='Received Date'>
                    <Space>
                        <DatePicker onChange={this.onChangeDate} value={quoteReceivedDateTimeDayjs || dayjs()} format='DD/MM/YYYY' />
                        <TimePicker onChange={this.onChangeTime} value={quoteReceivedDateTimeDayjs || dayjs()} format='H:mm' />
                    </Space>
                </Form.Item>
                <Form.Item className='amount' label='Amount'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeQuoteAmount} type='number' value={quoteAmount || ''} />
                </Form.Item>
                <Form.Item className='time-frame' label='Time Frame'>
                    <Input min={0} addonAfter='days' onChange={this.onChangeQuoteTimeFrameDays} type='number' value={quoteTimeFrameDays || ''} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeDate(date: Dayjs) {
        const { quoteReceivedDateTime } = this.state;

        this.setState({
            quoteReceivedDateTime: dayjs(`${date.format('YYYY-MM-DD')} ${dayjs(quoteReceivedDateTime || undefined).format('HH:mm')}`).format(),
        });
    }

    private onChangeTime(time: Dayjs) {
        const { quoteReceivedDateTime } = this.state;

        this.setState({
            quoteReceivedDateTime: dayjs(`${dayjs(quoteReceivedDateTime || undefined).format('YYYY-MM-DD')} ${time.format('HH:mm')}`).format(),
        });
    }

    private onChangeQuoteAmount(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            quoteAmount: event.target.value ? event.target.valueAsNumber : null,
        });
    }

    private onChangeQuoteTimeFrameDays(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            quoteTimeFrameDays: event.target.value ? event.target.valueAsNumber : null,
        });
    }

    private onClickOk() {
        const { quoteAmount, quoteReceivedDateTime, quoteTimeFrameDays} = this.state;

        this.props.quoteReceived(quoteAmount, quoteReceivedDateTime, quoteTimeFrameDays);
        this.props.onCancel();
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        quoteReceived: (quoteAmount: number, quoteReceivedDateTime: string, quoteTimeFrameDays: number) => dispatch(applicationPropertyValuerQuoteReceivedAction(ownProps.applicationProperty.uuid, ownProps.valuer.uuid, quoteAmount, quoteReceivedDateTime, quoteTimeFrameDays)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(EnterQuoteModal);
