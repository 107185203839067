enum ApprovalStatusEnum {
    Application = 'APPLICATION',
    Draft = 'DRAFT',
    FollowUp1 = 'FOLLOW_UP_1',
    FollowUp2 = 'FOLLOW_UP_2',
    FollowUp3 = 'FOLLOW_UP_3',
    InitialCall = 'INITIAL_CALL',
    UncontactableFollowUp1 = 'UNCONTACTABLE_FOLLOW_UP_1',
    UncontactableFollowUp2 = 'UNCONTACTABLE_FOLLOW_UP_2',
    UncontactableFollowUp3 = 'UNCONTACTABLE_FOLLOW_UP_3',
    Pending = 'PENDING',
    Quote = 'APPROVED',
    Referred = 'REFERRED',
    Rejected = 'REJECTED',
}

export default ApprovalStatusEnum;
