import { Action } from 'redux';
import ErrorActionsEnum from './ActionsEnum';

export interface IErrorSetAction extends Action {
    error: string;
}

export function errorSetAction(error: string): IErrorSetAction {
    return {
        error,
        type: ErrorActionsEnum.ErrorSet,
    };
}
