import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import Account from './Account';
import AnnualStatements from './AnnualStatements';
import BankAccounts from './BankAccounts';
import Documents from './Documents';
import Edit from './Edit';
import History from './History';
import IncomeTrustInvestments from './IncomeTrustInvestments';
import Individuals from './Individuals';
import Investments from './Investments';
import MonthlyStatements from './MonthlyStatements';
import Notes from './Notes';
import Overview from './Overview';
import Referrals from './Referrals';
import Shares from './Shares';
import Transactions from './Transactions';
import Users from './Users';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import { useSelector } from 'react-redux';
import Compliance from './Compliance';
import WithdrawalAbaInvestorTransactions from './WithdrawalAbaInvestorTransactions';

export default function Router(): ReactElement {
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);

    return (
        <Switch>
            <Route exact={true} path='/investors/:uuid' component={Overview} />
            <Route exact={true} path='/investors/:investorUuid/accounts/:accountUuid' component={Account} />
            <Route exact={true} path='/investors/:investorUuid/accounts/:accountUuid/income-trust-investments' component={IncomeTrustInvestments} />
            <Route exact={true} path='/investors/:investorUuid/accounts/:accountUuid/investments' component={Investments} />
            <Route exact={true} path='/investors/:investorUuid/accounts/:accountUuid/shares' component={Shares} />
            <Route exact={true} path='/investors/:investorUuid/accounts/:accountUuid/transactions' component={Transactions} />
            <Route exact={true} path='/investors/:investorUuid/accounts/:accountUuid/monthly-statements' component={MonthlyStatements} />
            <Route exact={true} path='/investors/:investorUuid/accounts/:accountUuid/annual-statements' component={AnnualStatements} />
            <Route exact={true} path='/investors/:investorUuid/accounts/:accountUuid/withdrawal-aba-investor-transactions' component={WithdrawalAbaInvestorTransactions} />
            <Route exact={true} path='/investors/:investorUuid/bank-accounts' component={BankAccounts} />
            <Route exact={true} path='/investors/:investorUuid/compliance' component={Compliance} />
            <Route exact={true} path='/investors/:uuid/documents' component={Documents} />
            <Route exact={true} path='/investors/:uuid/edit' component={Edit} />
            <Route exact={true} path='/investors/:uuid/history' component={History} />
            <Route exact={true} path='/investors/:uuid/individuals' component={Individuals} />
            <Route exact={true} path='/investors/:uuid/notes' component={Notes} />
            <Route exact={true} path='/investors/:uuid/referrals' component={Referrals} />
            {currentUser.permissions.includes(PermissionsEnum.Users) && <Route exact={true} path='/investors/:uuid/users' component={Users} />}
        </Switch>
    );
}
