import { Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IUser from '~Api/User/IUser';
import { userPhoneAction } from '~Users/actions';

interface IState {
    error: string;
    isChangePending: boolean;
    phone: string;
}

interface IProps {
    isOpen: boolean;
    onCancel: () => void;
    user: IUser;
}

interface IPropsDispatch {
    changePhone: (phone: string) => void;
}

type Props = IProps & IPropsDispatch;

class PhoneModal extends React.Component<Props, IState> {
    public state: IState = {
        error: null,
        isChangePending: false,
        phone: null,
    };

    constructor(props: Props) {
        super(props);

        this.afterClose = this.afterClose.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);

        this.state.phone = props.user.phone;
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { phone, error, isChangePending } = this.state;

        return (
            <Modal
                afterClose={this.afterClose}
                cancelButtonProps={{ disabled: isChangePending }}
                destroyOnClose={true}
                okButtonProps={{ loading: isChangePending }}
                okText='Change Phone'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Change Phone'
            >
                <Form.Item help={error} label='Phone' className='phone'>
                    <Input onChange={this.onChangePhone} value={phone} />
                </Form.Item>
            </Modal>
        );
    }

    private afterClose() {
        this.setState({
            error: null,
            isChangePending: false,
            phone: null,
        });
    }

    private onClickOk() {
        const { phone } = this.state;

        this.setState({
            isChangePending: true,
        });

        this.props.changePhone(phone);

        this.props.onCancel();
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            phone: event.target.value,
        });
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        changePhone: (phone: string) => dispatch(userPhoneAction(ownProps.user.uuid, phone)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(PhoneModal);
