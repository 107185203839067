import { CheckOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Modal, Space } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IReferral from '~Api/Investor/IReferral';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import { IGlobalState } from '~reducer';
import {
    investorReferralProcessAction,
    investorReferralUnprocessAction,
} from './actions';

interface IProps {
    referral: IReferral;
}

interface IPropsSelector {
    currentUser: IAuthUser;
}

interface IPropsDispatch {
    process: (referralUuid: string, sendEmail: boolean, process: boolean) => void;
    unprocess: (referralUuid: string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class ReferralActions extends React.Component<Props> {
    public render(): JSX.Element {
        const { currentUser, referral } = this.props;

        if (!currentUser.permissions.includes(PermissionsEnum.InvestorReferrals) || !referral.isEligible) {
            return referral.isProcessed ? <CheckOutlined/> : <Space>-</Space>;
        }

        const menu: MenuProps = {
            items: [
                ...(referral.isEligible && !referral.isProcessed ? [
                    {
                        key: 'mark-processed-email',
                        label: 'Mark as Processed + Email',
                        onClick: () => this.onClickMarkedAsProcessed(referral, true),
                    },
                ] : []),
                ...(referral.isEligible && !referral.isProcessed ? [
                    {
                        key: 'mark-processed',
                        label: 'Mark as Processed',
                        onClick: () => this.onClickMarkedAsProcessed(referral, false),
                    },
                ] : []),
                ...(referral.isEligible && !referral.isProcessed ? [
                    {
                        key: 'process-email',
                        label: 'Process + Email',
                        onClick: () => this.onClickProcessReferral(referral, true),
                    },
                ] : []),
                ...(referral.isEligible && !referral.isProcessed ? [
                    {
                        key: 'process',
                        label: 'Process',
                        onClick: () => this.onClickProcessReferral(referral, false),
                    },
                ] : []),
                ...(referral.isProcessed ? [
                    {
                        key: 'unprocess',
                        label: 'Unprocess',
                        onClick: () => this.onClickUnprocessReferral(referral),
                    },
                ] : []),
            ],
        };

        return (
            <Dropdown menu={menu} ><Button icon={referral.isProcessed ? <CheckOutlined/> : <EllipsisOutlined/>} /></Dropdown>
        );
    }

    private onClickMarkedAsProcessed(referral: IReferral, sendEmail: boolean): void {
        Modal.confirm({
            content: `Are you sure you want to mark this referral as processed${sendEmail ? ' and send an email to the investors' : ''}?`,
            okText: `Mark as Processed ${sendEmail ? ' and Send' : ''}`,
            onOk: () => {
                this.props.process(referral.uuid, sendEmail, false);
            },
            title: `Mark as Processed${sendEmail ? ' and Send Email' : ''}`,
        });
    }

    private onClickProcessReferral(referral: IReferral, sendEmail: boolean): void {
        Modal.confirm({
            content: `Are you sure you want to process the transaction for this referral${sendEmail ? ' and send an email to the investors' : ''}?`,
            okText: `Process${sendEmail ? ' and Send' : ''}`,
            onOk: () => {
                this.props.process(referral.uuid, sendEmail, true);
            },
            title: `Process Referral${sendEmail ? ' and Send Email' : ''}`,
        });
    }

    private onClickUnprocessReferral(referral: IReferral): void {
        Modal.confirm({
            content: 'Are you sure you want to mark this referral as unprocessed?',
            okText: 'Unprocess',
            onOk: () => {
                this.props.unprocess(referral.uuid);
            },
            title: 'Unprocess Referral',
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentUser: authCurrentUserSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        process: (referralUuid: string, sendEmail: boolean, process: boolean) => dispatch(investorReferralProcessAction(referralUuid, sendEmail, process)),
        unprocess: (referralUuid: string) => dispatch(investorReferralUnprocessAction(referralUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReferralActions);
