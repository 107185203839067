import { Button, Form, Input, Modal, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IdProofTypeEnum from '~Api/Investor/IdProofTypeEnum';
import IIndividual from '~Api/Investor/IIndividual';
import IInvestor from '~Api/Investor/IInvestor';
import ResidentTypeEnum from '~Api/Investor/ResidentTypeEnum';
import StateEnum from '~Api/Investor/StateEnum';
import { countriesGetAction } from '~Countries/actions';
import ICountry from '~Countries/ICountry';
import { countriesSelector } from '~Countries/selectors';
import { investorGetAction, investorIndividualValueSetAction } from '~Investors/actions';
import { investorSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import DatePicker from '~UI/DatePicker';

interface IProps {
    investorUuid: string;
    index: number;
    isOpen: boolean;
    onCancel: (event: React.MouseEvent) => void;
}

interface IPropsSelector {
    countries: ICountry[];
    investor: IInvestor;
}

interface IPropsDispatch {
    countriesGet: () => void;
    investorGet: () => void;
    valueSet: (key: keyof IIndividual, value: any) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class IndividualEditModal extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeDateOfBirth = this.onChangeDateOfBirth.bind(this);
        this.onChangeTaxFileNumber = this.onChangeTaxFileNumber.bind(this);
        this.onChangeStreetAddress = this.onChangeStreetAddress.bind(this);
        this.onChangeSuburb = this.onChangeSuburb.bind(this);
        this.onChangePostcode = this.onChangePostcode.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeResidentType = this.onChangeResidentType.bind(this);
        this.onChangeIdProofType = this.onChangeIdProofType.bind(this);
        this.onChangeIdProofNumber = this.onChangeIdProofNumber.bind(this);
        this.onChangeIdProofExpiryDate = this.onChangeIdProofExpiryDate.bind(this);
        this.onChangeDriverLicenceCardNumber = this.onChangeDriverLicenceCardNumber.bind(this);
        this.onChangeDriverLicenceState = this.onChangeDriverLicenceState.bind(this);
    }

    public componentDidMount(): void {
        const { countries, investor } = this.props;

        if (!countries) {
            this.props.countriesGet();
        }

        if (!investor) {
            this.props.investorGet();
        }
    }

    public render(): JSX.Element {
        const { countries, index, investor, isOpen } = this.props;

        if (!countries || !investor) {
            return null;
        }

        const individual: IIndividual = investor.individuals[index] || {
            country: 'AUS',
            dateOfBirth: null,
            driverLicenceCardNumber: null,
            driverLicenceState: null,
            email: null,
            firstName: null,
            idProofExpiryDate: null,
            idProofNumber: null,
            idProofType: null,
            index,
            lastName: null,
            postcode: null,
            residentType: null,
            state: null,
            streetAddress: null,
            suburb: null,
            taxFileNumber: null,
        };

        let idProofBlock: JSX.Element = null;

        switch (individual.idProofType) {
            case IdProofTypeEnum.DriverLicence:
                idProofBlock = (
                    <React.Fragment>
                        <Form.Item label='Licence Number' className='id-proof-number'>
                            <Input onChange={this.onChangeIdProofNumber} value={individual.idProofNumber} />
                        </Form.Item>
                        <Form.Item label='Licence Card Number' className='drivers-licence-card-number'>
                            <Input onChange={this.onChangeDriverLicenceCardNumber} value={individual.driverLicenceCardNumber} />
                        </Form.Item>
                        <Form.Item label='Licence State' className='drivers-licence-state'>
                            <Select onChange={this.onChangeDriverLicenceState} value={individual.driverLicenceState}>
                                <Select.Option value={StateEnum.AustralianCapitalTerritory}>ACT</Select.Option>
                                <Select.Option value={StateEnum.NewSouthWales}>NSW</Select.Option>
                                <Select.Option value={StateEnum.NorthernTerritory}>NT</Select.Option>
                                <Select.Option value={StateEnum.Queensland}>QLD</Select.Option>
                                <Select.Option value={StateEnum.SouthAustralia}>SA</Select.Option>
                                <Select.Option value={StateEnum.Tasmania}>TAS</Select.Option>
                                <Select.Option value={StateEnum.Victoria}>VIC</Select.Option>
                                <Select.Option value={StateEnum.WesternAustralia}>WA</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label='Licence Expiry Date' className='id-proof-expiry-date'>
                            <DatePicker onChange={this.onChangeIdProofExpiryDate} format='DD/MM/YYYY' value={individual.idProofExpiryDate ? dayjs(individual.idProofExpiryDate) : null} />
                        </Form.Item>
                    </React.Fragment>
                );

                break;
            case IdProofTypeEnum.Passport:
                idProofBlock = (
                    <React.Fragment>
                        <Form.Item label='Passport Number' className='id-proof-number'>
                            <Input onChange={this.onChangeIdProofNumber} value={individual.idProofNumber} />
                        </Form.Item>
                        <Form.Item label='Passport Expiry Date' className='id-proof-expiry-date'>
                            <DatePicker onChange={this.onChangeIdProofExpiryDate} format='DD/MM/YYYY' value={individual.idProofExpiryDate ? dayjs(individual.idProofExpiryDate) : null} />
                        </Form.Item>
                    </React.Fragment>
                );

                break;
        }

        return (
            <Modal
                footer={<Button className='close' onClick={this.props.onCancel}>Close</Button>}
                okText='Close'
                onCancel={this.props.onCancel}
                onOk={this.props.onCancel}
                open={isOpen}
                title='Edit Individual'
                wrapClassName='investor-individual-edit-modal'
            >
                <Form.Item label='First Name' className='first-name'>
                    <Input onChange={this.onChangeFirstName} value={individual.firstName} />
                </Form.Item>
                <Form.Item label='Last Name' className='last-name'>
                    <Input onChange={this.onChangeLastName} value={individual.lastName} />
                </Form.Item>
                <Form.Item label='Date of Birth' className='date-of-birth'>
                    <DatePicker onChange={this.onChangeDateOfBirth} format='DD/MM/YYYY' value={individual.dateOfBirth ? dayjs(individual.dateOfBirth) : null} />
                </Form.Item>
                <Form.Item label='TFN' className='tax-file-number'>
                    <Input onChange={this.onChangeTaxFileNumber} value={individual.taxFileNumber} />
                </Form.Item>
                <Form.Item label='Street Address' className='street-address'>
                    <Input onChange={this.onChangeStreetAddress} value={individual.streetAddress} />
                </Form.Item>
                <Form.Item label='Suburb' className='suburb'>
                    <Input onChange={this.onChangeSuburb} value={individual.suburb} />
                </Form.Item>
                <Form.Item label='Postcode' className='postcode'>
                    <Input onChange={this.onChangePostcode} value={individual.postcode} />
                </Form.Item>
                <Form.Item label='State' className='state'>
                    <Input onChange={this.onChangeState} value={individual.state} />
                </Form.Item>
                <Form.Item label='Country' className='country'>
                    <Select onChange={this.onChangeCountry} value={individual.country || 'AUS'}>
                        {_.sortBy(countries, ['name']).map((country: ICountry) => <Select.Option key={country.iso3} value={country.iso3}>{country.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label='Resident Type' className='resident-type'>
                    <Select onChange={this.onChangeResidentType} value={individual.residentType}>
                        <Select.Option value={ResidentTypeEnum.Resident}>Resident</Select.Option>
                        <Select.Option value={ResidentTypeEnum.NonResident}>Non Resident</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label='ID Proof Type' className='id-proof-type'>
                    <Select onChange={this.onChangeIdProofType} value={individual.idProofType}>
                        <Select.Option value={IdProofTypeEnum.DriverLicence}>Australian Driver Licence</Select.Option>
                        <Select.Option value={IdProofTypeEnum.Passport}>Passport</Select.Option>
                    </Select>
                </Form.Item>
                {idProofBlock}
            </Modal>
        );
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.valueSet('firstName', event.target.value);
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.valueSet('lastName', event.target.value);
    }

    private onChangeDateOfBirth(date: Dayjs): void {
        this.props.valueSet('dateOfBirth', date ? date.format('YYYY-MM-DD') : null);
    }

    private onChangeTaxFileNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.valueSet('taxFileNumber', event.target.value);
    }

    private onChangeStreetAddress(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.valueSet('streetAddress', event.target.value);
    }

    private onChangeSuburb(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.valueSet('suburb', event.target.value);
    }

    private onChangePostcode(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.valueSet('postcode', event.target.value);
    }

    private onChangeState(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.valueSet('state', event.target.value);
    }

    private onChangeCountry(value: string): void {
        this.props.valueSet('country', value);
    }

    private onChangeResidentType(value: ResidentTypeEnum): void {
        this.props.valueSet('residentType', value);
    }

    private onChangeIdProofType(value: IdProofTypeEnum): void {
        this.props.valueSet('idProofType', value);
    }

    private onChangeIdProofNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.valueSet('idProofNumber', event.target.value);
    }

    private onChangeDriverLicenceCardNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.valueSet('driverLicenceCardNumber', event.target.value);
    }

    private onChangeDriverLicenceState(value: StateEnum): void {
        this.props.valueSet('driverLicenceState', value);
    }

    private onChangeIdProofExpiryDate(date: Dayjs): void {
        this.props.valueSet('idProofExpiryDate', date ? date.format('YYYY-MM-DD') : null);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        countries: countriesSelector(state),
        investor: investorSelector(state, ownProps.investorUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        countriesGet: () => dispatch(countriesGetAction()),
        investorGet: () => dispatch(investorGetAction(ownProps.investorUuid)),
        valueSet: (key: keyof IIndividual, value: any) => dispatch(investorIndividualValueSetAction(ownProps.investorUuid, ownProps.index, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IndividualEditModal);
