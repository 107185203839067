import { Form, Input, Modal, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplicationWarehouse from '~Api/Application/IApplicationWarehouse';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import WarehouseTypeEnum from '~Api/Warehouse/WarehouseTypeEnum';
import { applicationWarehousesAddAction } from '~Applications/actions';
import { applicationWarehousesSelector } from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { warehousesListAction } from '~Warehouses/actions';
import { warehousesSelector } from '~Warehouses/selectors';

interface IState {
    amount: number;
    errors: {
        amount?: string;
        warehouseUuid?: string;
    };
    warehouseUuid: string;
}

interface IProps {
    isOpen: boolean;
    applicationUuid: string;
    onClose: () => void;
}

interface IPropsSelector {
    applicationWarehouses: IDictionary<IApplicationWarehouse>;
    warehouses: IDictionary<IWarehouse>;
}

interface IPropsDispatch {
    add: (warehouse: IApplicationWarehouse) => void;
    warehousesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class WarehouseAddModal extends React.Component<Props, IState> {
    public state: IState = {
        amount: null,
        errors: {},
        warehouseUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeWarehouseUuid = this.onChangeWarehouseUuid.bind(this);
        this.onChangeAmount = this.onChangeAmount.bind(this);

        this.validateWarehouseUuid = this.validateWarehouseUuid.bind(this);
        this.validateAmount = this.validateAmount.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public componentDidMount(): void {
        const { warehouses } = this.props;

        if (!warehouses) {
            this.props.warehousesList();
        }
    }

    public render(): JSX.Element {
        const { isOpen, warehouses } = this.props;
        const { amount, errors, warehouseUuid } = this.state;

        if (!warehouses) {
            return null;
        }

        const warehouseOptions: JSX.Element[] = [];

        _.sortBy(warehouses, 'name').forEach((warehouse: IWarehouse) => {
            // FIT, FWT1 and FWT2 can't fund loans
            if ([WarehouseTypeEnum.Fit, WarehouseTypeEnum.Fwt1, WarehouseTypeEnum.Fwt2].includes(warehouse.type)) {
                return;
            }

            warehouseOptions.push(<Select.Option key={warehouse.uuid} value={warehouse.uuid}>{warehouse.name}</Select.Option>);
        });

        return (
            <Modal
                destroyOnClose={true}
                okText='Add'
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Warehouse'
                wrapClassName='application-warehouse-add-modal'
            >
                <Form.Item label='Warehouse' help={errors.warehouseUuid} validateStatus={errors.warehouseUuid && 'error'}>
                    <Select value={warehouseUuid} onChange={this.onChangeWarehouseUuid} onBlur={this.validateWarehouseUuid}>
                        {warehouseOptions}
                    </Select>
                </Form.Item>
                <Form.Item label='Amount' help={errors.amount} validateStatus={errors.amount && 'error'}>
                    <Input type='number' value={amount} placeholder='0' addonBefore='$' min={0} onChange={this.onChangeAmount} onBlur={this.validateAmount} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeWarehouseUuid(value: string): void {
        this.setState({
            warehouseUuid: value,
        });
    }

    private onChangeAmount(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            amount: event.target.value ? event.target.valueAsNumber : null,
        });
    }

    private onClickOk(): void {
        const { amount, warehouseUuid } = this.state;
        const { applicationUuid } = this.props;

        let valid: boolean = true;

        valid = this.validateWarehouseUuid() && valid;
        valid = this.validateAmount() && valid;

        if (!valid) {
            return;
        }

        this.props.add({
            amount,
            applicationUuid,
            warehouseUuid,
        });

        this.props.onClose();
    }

    private validateWarehouseUuid(): boolean {
        const { applicationWarehouses } = this.props;
        const { errors, warehouseUuid } = this.state;

        let error: string = null;

        const applicationWarehouseExists: boolean = _.some(applicationWarehouses, { warehouseUuid });

        if (!warehouseUuid) {
            error = 'Please select a warehouse';
        } else if (applicationWarehouseExists) {
            error = 'An amount is already allocated to this warehouse';
        }

        this.setState({
            errors: {
                ...errors,
                warehouseUuid: error,
            },
        });

        return !error;
    }

    private validateAmount(): boolean {
        const { amount, errors } = this.state;

        let error: string;

        if (!amount) {
            error = 'Please enter an amount';
        }

        this.setState({
            errors: {
                ...errors,
                amount: error,
            },
        });

        return !error;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        applicationWarehouses: applicationWarehousesSelector(state, ownProps.applicationUuid),
        warehouses: warehousesSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        add: (warehouse: IApplicationWarehouse) => dispatch(applicationWarehousesAddAction(ownProps.applicationUuid, warehouse)),
        warehousesList: () => dispatch(warehousesListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WarehouseAddModal);
