import _ from 'lodash';
import { AnyAction } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IDeal from '~Api/Deal/IDeal';
import ApprovalStatusEnum from '~Api/ReferralPartner/ApprovalStatusEnum';
import IDocument from '~Api/ReferralPartner/IDocument';
import IHistory from '~Api/ReferralPartner/IHistory';
import INote from '~Api/ReferralPartner/INote';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import {
    IReferralPartnerApplicationsSetAction,
    IReferralPartnerApproveAction,
    IReferralPartnerDealsSetAction,
    IReferralPartnerDocumentRemoveAction,
    IReferralPartnerDocumentSetAction,
    IReferralPartnerDocumentsSetAction,
    IReferralPartnerHistoriesSetAction,
    IReferralPartnerNoteRemoveAction,
    IReferralPartnerNoteSetAction,
    IReferralPartnerNotesSetAction,
    IReferralPartnerRejectAction,
    IReferralPartnerResetAction,
    IReferralPartnerSetAction,
    IReferralPartnersSetAction,
    IReferralPartnerValueSetAction,
} from '~ReferralPartners/actions';
import ReferralPartnersActionsEnum from '~ReferralPartners/ActionsEnum';
import { IDictionary } from '~utilities/IDictionary';

export interface IReferralPartnersState {
    documents: IDictionary<IDictionary<IDocument>>;
    histories: IDictionary<IDictionary<IHistory>>;
    notes: IDictionary<IDictionary<INote>>;
    referralPartnerApplicationUuids: IDictionary<string[]>;
    referralPartnerDealUuids: IDictionary<string[]>;
    referralPartners: IDictionary<IReferralPartner>;
    referralPartnersListed: boolean;
}

const initialData: IReferralPartnersState = {
    documents: {},
    histories: {},
    notes: {},
    referralPartnerApplicationUuids: {},
    referralPartnerDealUuids: {},
    referralPartners: {},
    referralPartnersListed: false,
};

export function referralPartnersReducer(state: IReferralPartnersState = initialData, action: AnyAction): IReferralPartnersState {
    switch (action.type) {
        case ReferralPartnersActionsEnum.ReferralPartnerApprove: {
            const typedAction: IReferralPartnerApproveAction = action as IReferralPartnerApproveAction;

            return {
                ...state,
                referralPartners: {
                    ...state.referralPartners,
                    [typedAction.referralPartnerUuid]: {
                        ...state.referralPartners[typedAction.referralPartnerUuid],
                        approvalStatus: ApprovalStatusEnum.Approved,
                    },
                },
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerReject: {
            const typedAction: IReferralPartnerRejectAction = action as IReferralPartnerRejectAction;

            return {
                ...state,
                referralPartners: {
                    ...state.referralPartners,
                    [typedAction.referralPartnerUuid]: {
                        ...state.referralPartners[typedAction.referralPartnerUuid],
                        approvalStatus: ApprovalStatusEnum.Rejected,
                    },
                },
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerReset: {
            const typedAction: IReferralPartnerResetAction = action as IReferralPartnerResetAction;

            return {
                ...state,
                referralPartners: {
                    ...state.referralPartners,
                    [typedAction.referralPartnerUuid]: {
                        ...state.referralPartners[typedAction.referralPartnerUuid],
                        approvalStatus: ApprovalStatusEnum.Pending,
                    },
                },
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerSet: {
            const typedAction: IReferralPartnerSetAction = action as IReferralPartnerSetAction;

            return {
                ...state,
                referralPartners: {
                    ...state.referralPartners,
                    [typedAction.referralPartner.uuid]: typedAction.referralPartner,
                },
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerValueSet: {
            const typedAction: IReferralPartnerValueSetAction = action as IReferralPartnerValueSetAction;

            return {
                ...state,
                referralPartners: {
                    ...state.referralPartners,
                    [typedAction.referralPartnerUuid]: {
                        ...state.referralPartners[typedAction.referralPartnerUuid],
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerDealsSet: {
            const typedAction: IReferralPartnerDealsSetAction = action as IReferralPartnerDealsSetAction;
            const referralPartnerDealUuids: IDictionary<string[]> = { ...state.referralPartnerDealUuids };

            referralPartnerDealUuids[typedAction.referralPartnerUuid] = [];

            _.each(typedAction.deals, (deal: IDeal) => {
                referralPartnerDealUuids[typedAction.referralPartnerUuid].push(deal.uuid);
            });

            return {
                ...state,
                referralPartnerDealUuids: referralPartnerDealUuids,
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerApplicationsSet: {
            const typedAction: IReferralPartnerApplicationsSetAction = action as IReferralPartnerApplicationsSetAction;
            const referralPartnerApplicationUuids: IDictionary<string[]> = { ...state.referralPartnerApplicationUuids };

            referralPartnerApplicationUuids[typedAction.referralPartnerUuid] = [];

            _.each(typedAction.applications, (application: IApplication) => {
                referralPartnerApplicationUuids[typedAction.referralPartnerUuid].push(application.uuid);
            });

            return {
                ...state,
                referralPartnerApplicationUuids,
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerDocumentsSet: {
            const typedAction: IReferralPartnerDocumentsSetAction = action as IReferralPartnerDocumentsSetAction;

            return {
                ...state,
                documents: {
                    ...state.documents,
                    [typedAction.referralPartnerUuid]: _.keyBy(typedAction.documents, 'uuid'),
                },
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerDocumentRemove: {
            const typedAction: IReferralPartnerDocumentRemoveAction = action as IReferralPartnerDocumentRemoveAction;

            return {
                ...state,
                documents: {
                    ...state.documents,
                    [typedAction.referralPartnerUuid]: _.omit(state.documents[typedAction.referralPartnerUuid] || {}, typedAction.documentUuid),
                },
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerDocumentSet: {
            const typedAction: IReferralPartnerDocumentSetAction = action as IReferralPartnerDocumentSetAction;

            return {
                ...state,
                documents: {
                    ...state.documents,
                    [typedAction.referralPartnerUuid]: {
                        ...state.documents[typedAction.referralPartnerUuid],
                        [typedAction.document.uuid]: typedAction.document,
                    },
                },
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerHistoriesSet: {
            const typedAction: IReferralPartnerHistoriesSetAction = action as IReferralPartnerHistoriesSetAction;

            return {
                ...state,
                histories: {
                    ...state.histories,
                    [typedAction.referralPartnerUuid]: _.keyBy(typedAction.histories, 'uuid'),
                },
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnersSet: {
            const typedAction: IReferralPartnersSetAction = action as IReferralPartnersSetAction;

            return {
                ...state,
                referralPartners: _.keyBy(typedAction.referralPartners, 'uuid'),
                referralPartnersListed: true,
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerNotesSet: {
            const typedAction: IReferralPartnerNotesSetAction = action as IReferralPartnerNotesSetAction;

            const notes: IDictionary<INote> = {};

            typedAction.notes.forEach((note) => {
                notes[note.uuid] = note;
            });

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.referralPartnerUuid]: notes,
                },
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerNoteRemove: {
            const typedAction: IReferralPartnerNoteRemoveAction = action as IReferralPartnerNoteRemoveAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.referralPartnerUuid]: _.omit(state.notes[typedAction.referralPartnerUuid] || {}, action.noteUuid),
                },
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerNoteSet: {
            const typedAction: IReferralPartnerNoteSetAction = action as IReferralPartnerNoteSetAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.referralPartnerUuid]: {
                        ...state.notes[typedAction.referralPartnerUuid],
                        [typedAction.note.uuid || 'new']: typedAction.note,
                    },
                },
            };
        }

        default: {
            return state;
        }
    }
}
