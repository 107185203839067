import { Spin, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IHistory from '~Api/Investor/IHistory';
import IInvestor from '~Api/Investor/IInvestor';
import {
    investorGetAction,
    investorHistoriesListAction,
} from '~Investors/actions';
import {
    investorHistoriesSelector,
    investorSelector,
} from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    histories: IHistory[];
    investor: IInvestor;
}

interface IPropsDispatch {
    historiesList: () => void;
    investorGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class History extends React.Component<Props> {
    public componentDidMount() {
        const { investor } = this.props;

        if (!investor) {
            this.props.investorGet();
        }

        this.props.historiesList();
    }

    public render(): JSX.Element {
        const { investor, match, histories } = this.props;

        if (!investor || !histories) {
            return (
                <Layout uuid={match.params.uuid} section='history'>
                    <Typography.Title level={2}>History</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const historiesBlock: JSX.Element[] = _.sortBy(_.filter(histories, (history: IHistory) => !!history.uuid), ['createdTime']).map((loopHistory: IHistory) => (
            <Timeline.Item key={loopHistory.uuid} label={dayjs(loopHistory.createdTime).format('YYYY-MM-DD - HH:mm')}>
                <Typography.Text>{loopHistory.description}</Typography.Text>
            </Timeline.Item>
        ));

        return (
            <Layout uuid={match.params.uuid} section='history'>
                <Typography.Title level={2}>History</Typography.Title>
                <Timeline mode='left' reverse={true}>
                    {historiesBlock}
                </Timeline>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        histories: investorHistoriesSelector(state, ownProps.match.params.uuid),
        investor: investorSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        historiesList: () => dispatch(investorHistoriesListAction(ownProps.match.params.uuid)),
        investorGet: () => dispatch(investorGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(History);
