import { Action } from 'redux';
import ApprovalStatusEnum from '~Api/Deal/ApprovalStatusEnum';
import IDeal from '~Api/Deal/IDeal';
import IMovementReportLead from '~Api/Deal/IMovementReportLead';
import IOriginationReportLead from '~Api/Deal/IOriginationReportLead';
import IQuote from '~Api/Deal/IQuote';
import ListSortOrderEnum from '~Api/Deal/ListSortOrderEnum';
import ReferredToEnum from '~Api/Deal/ReferredToEnum';
import RejectReasonEnum from '~Api/Deal/RejectReasonEnum';
import { PeriodRangeEnum } from '~utilities/reportUtilities';
import LeadsActionsEnum from './ActionsEnum';
import IBroker from '~Api/Broker/IBroker';

export interface ILeadsListAction extends Action {
    approvalStatuses: ApprovalStatusEnum[];
    administratorUuids: string[];
    name: string;
    page: number;
    perPage: number;
    order: ListSortOrderEnum;
    orderBy: string;
}

export function leadsListAction(page: number, perPage: number, orderBy: string, order: ListSortOrderEnum, administratorUuids: string[], approvalStatuses: ApprovalStatusEnum[], name: string): ILeadsListAction {
    return {
        administratorUuids,
        approvalStatuses,
        name,
        order,
        orderBy,
        page,
        perPage,
        type: LeadsActionsEnum.LeadsList,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILeadsBoardListAction extends Action {}

export function leadsBoardListAction(): ILeadsBoardListAction {
    return {
        type: LeadsActionsEnum.LeadsBoardList,
    };
}

export interface ILeadsBoardSetAction extends Action {
    deals: IDeal[];
}

export function leadsBoardSetAction(deals: IDeal[]): ILeadsBoardSetAction {
    return {
        deals,
        type: LeadsActionsEnum.LeadsBoardSet,
    };
}

export interface ILeadsOriginationReportListAction extends Action {
    endDate: string;
    periodRange: PeriodRangeEnum;
    startDate: string;
}

export function leadsOriginationReportListAction(startDate: string, endDate: string, periodRange: PeriodRangeEnum): ILeadsOriginationReportListAction {
    return {
        endDate,
        periodRange,
        startDate,
        type: LeadsActionsEnum.LeadsOriginationReportList,
    };
}

export interface ILeadsOriginationReportSetAction extends Action {
    originationReportLeads: IOriginationReportLead[];
}

export function leadsOriginationReportSetAction(originationReportLeads: IOriginationReportLead[]): ILeadsOriginationReportSetAction {
    return {
        originationReportLeads,
        type: LeadsActionsEnum.LeadsOriginationReportSet,
    };
}

export interface ILeadsMovementReportListAction extends Action {
    endTime: string;
    periodRange: PeriodRangeEnum;
    startTime: string;
}

export function leadsMovementReportListAction(startTime: string, endTime: string, periodRange: PeriodRangeEnum): ILeadsMovementReportListAction {
    return {
        endTime,
        periodRange,
        startTime,
        type: LeadsActionsEnum.LeadsMovementReportList,
    };
}

export interface ILeadsMovementReportSetAction extends Action {
    movementReportLeads: IMovementReportLead[];
}

export function leadsMovementReportSetAction(movementReportLeads: IMovementReportLead[]): ILeadsMovementReportSetAction {
    return {
        movementReportLeads,
        type: LeadsActionsEnum.LeadsMovementReportSet,
    };
}

export type ILeadsPendingQuotesListAction = Action<LeadsActionsEnum.LeadsPendingQuotesList>;

export function leadsPendingQuotesListAction(): ILeadsPendingQuotesListAction {
    return {
        type: LeadsActionsEnum.LeadsPendingQuotesList,
    };
}

export interface ILeadsPendingQuotesSetAction extends Action {
    quotes: IQuote[];
}

export function leadsPendingQuotesSetAction(quotes: IQuote[]): ILeadsPendingQuotesSetAction {
    return {
        quotes,
        type: LeadsActionsEnum.LeadsPendingQuotesSet,
    };
}

export interface ILeadsSetAction extends Action {
    deals: IDeal[];
    leadsPaginatedCount: number;
    page: number;
    perPage: number;
}

export function leadsSetAction(deals: IDeal[], leadsPaginatedCount: number, page: number, perPage: number): ILeadsSetAction {
    return {
        deals,
        leadsPaginatedCount,
        page,
        perPage,
        type: LeadsActionsEnum.LeadsSet,
    };
}

export interface ILeadsSearchAction extends Action {
    keyword: string;
}

export function leadsSearchAction(keyword: string): ILeadsSearchAction {
    return {
        keyword,
        type: LeadsActionsEnum.LeadsSearch,
    };
}

export interface ILeadsSearchResultSetAction extends Action {
    deals: IDeal[];
}

export function leadsSearchResultsSetAction(deals: IDeal[]): ILeadsSearchResultSetAction {
    return {
        deals,
        type: LeadsActionsEnum.LeadsSearchResultsSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILeadsSearchResultsClearAction extends Action {}

export function leadsSearchResultsClearAction(): ILeadsSearchResultsClearAction {
    return {
        type: LeadsActionsEnum.LeadsSearchResultsClear,
    };
}

export interface ILeadBrokerSearchAction extends Action {
    keyword: string;
}

export function leadBrokerSearchAction(keyword: string): ILeadsSearchAction {
    return {
        keyword,
        type: LeadsActionsEnum.LeadBrokerSearch,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILeadBrokerSearchResultsClearAction extends Action {}

export function leadBrokerSearchResultsClearAction(): ILeadBrokerSearchResultsClearAction {
    return {
        type: LeadsActionsEnum.LeadBrokerSearchResultsClear,
    };
}

export interface ILeadBrokerSearchResultsSetAction extends Action {
    brokers: IBroker[];
    keyword: string;
}

export function leadBrokerSearchResultsSetAction(keyword: string, brokers: IBroker[]): ILeadBrokerSearchResultsSetAction {
    return {
        brokers,
        keyword,
        type: LeadsActionsEnum.LeadBrokerSearchResultsSet,
    };
}

export interface ILeadQuotesAddAction extends Action {
    dealUuid: string;
    isScenario: boolean;
    quote: IQuote;
    sendEmail: boolean;
}

export function leadQuotesAddAction(dealUuid: string, quote: IQuote, sendEmail: boolean, isScenario: boolean): ILeadQuotesAddAction {
    return {
        dealUuid,
        isScenario,
        quote,
        sendEmail,
        type: LeadsActionsEnum.LeadQuotesAdd,
    };
}

export interface ILeadQuotesListAction extends Action {
    dealUuid: string;
}

export function leadQuotesListAction(dealUuid: string): ILeadQuotesListAction {
    return {
        dealUuid,
        type: LeadsActionsEnum.LeadQuotesList,
    };
}

export interface ILeadQuoteCreateApplicationAction extends Action {
    dealUuid: string;
    quoteUuid: string;
}

export function leadQuoteCreateApplicationAction(dealUuid: string, quoteUuid: string): ILeadQuoteCreateApplicationAction {
    return {
        dealUuid,
        quoteUuid,
        type: LeadsActionsEnum.LeadQuoteCreateApplication,
    };
}

export interface ILeadQuotesSetAction extends Action {
    dealUuid: string;
    quotes: IQuote[];
}

export function leadQuotesSetAction(dealUuid: string, quotes: IQuote[]): ILeadQuotesSetAction {
    return {
        dealUuid,
        quotes,
        type: LeadsActionsEnum.LeadQuotesSet,
    };
}

export interface ILeadQuoteSendAction extends Action {
    dealUuid: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    quoteUuid: string;
}

export function leadQuoteSendAction(dealUuid: string, quoteUuid: string, email?: string, firstName?: string, lastName?: string): ILeadQuoteSendAction {
    return {
        dealUuid,
        email,
        firstName,
        lastName,
        quoteUuid,
        type: LeadsActionsEnum.LeadQuoteSend,
    };
}

export interface ILeadQuoteSetAction extends Action {
    dealUuid: string;
    quote: IQuote;
}

export function leadQuoteSetAction(dealUuid: string, quote: IQuote): ILeadQuoteSetAction {
    return {
        dealUuid,
        quote,
        type: LeadsActionsEnum.LeadQuoteSet,
    };
}

export interface ILeadAddAction extends Action {
    deal: IDeal;
}

export function leadAddAction(deal: IDeal): ILeadAddAction {
    return {
        deal,
        type: LeadsActionsEnum.LeadAdd,
    };
}

export interface ILeadAddErrorSetAction extends Action {
    key: keyof IDeal;
    value: any;
}

export function leadAddErrorSetAction(key: keyof IDeal, value: any): ILeadAddErrorSetAction {
    return {
        key,
        type: LeadsActionsEnum.LeadAddErrorSet,
        value,
    };
}

export interface ILeadGetAction extends Action {
    dealUuid: string;
}

export function leadGetAction(dealUuid: string): ILeadGetAction {
    return {
        dealUuid,
        type: LeadsActionsEnum.LeadGet,
    };
}

export interface ILeadInitialCallAction extends Action {
    dealUuid: string;
}

export function leadInitialCallAction(dealUuid: string): ILeadInitialCallAction {
    return {
        dealUuid,
        type: LeadsActionsEnum.LeadInitialCall,
    };
}

export interface ILeadReferAction extends Action {
    dealUuid: string;
    referredTo: ReferredToEnum;
    sendEmail: boolean;
}

export function leadReferAction(dealUuid: string, referredTo: ReferredToEnum, sendEmail: boolean): ILeadReferAction {
    return {
        dealUuid,
        referredTo,
        sendEmail,
        type: LeadsActionsEnum.LeadRefer,
    };
}

export interface ILeadRejectAction extends Action {
    dealUuid: string;
    reason: RejectReasonEnum;
    sendEmail: boolean;
}

export function leadRejectAction(dealUuid: string, reason: RejectReasonEnum, sendEmail: boolean): ILeadRejectAction {
    return {
        dealUuid,
        reason,
        sendEmail,
        type: LeadsActionsEnum.LeadReject,
    };
}

export interface ILeadSendAction extends Action {
    dealUuid: string;
}

export function leadSendAction(dealUuid: string): ILeadSendAction {
    return {
        dealUuid,
        type: LeadsActionsEnum.LeadSend,
    };
}

export interface ILeadSetAction extends Action {
    deal: IDeal;
}

export function leadSetAction(deal: IDeal): ILeadSetAction {
    return {
        deal,
        type: LeadsActionsEnum.LeadSet,
    };
}

export interface ILeadValueSetAction extends Action {
    dealUuid: string;
    key: keyof IDeal;
    value: boolean|number|string;
}

export function leadValueSetAction(dealUuid: string, key: keyof IDeal, value: boolean|number|string): ILeadValueSetAction {
    return {
        dealUuid,
        key,
        type: LeadsActionsEnum.LeadValueSet,
        value,
    };
}
