import { Button, Form, Modal, Select, Upload } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IAnnualStatement from '~Api/Investor/IAnnualStatement';
import { investorAccountAnnualStatementsAddAction } from '~Investors/actions';
import { investorAccountAnnualStatementsSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

const fundingStartYear = 2015;

interface IState {
    errorYear?: string;
    file: File;
    year: string;
}

interface IProps {
    accountUuid: string;
    investorUuid: string;
    isOpen: boolean;
    onClose: () => void;
}

interface IPropsSelector {
    annualStatements: IDictionary<IAnnualStatement>;
}

interface IPropsDispatch {
    addStatement: (file: File, year: string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class AnnualStatementAddModal extends React.Component<Props, IState> {
    public state: IState = {
        file: null,
        year: null,
    };

    constructor(props: Props) {
        super(props);

        this.beforeUpload = this.beforeUpload.bind(this);

        this.onChangeYear = this.onChangeYear.bind(this);
        this.onClickOk = this.onClickOk.bind(this);

        this.validateYear = this.validateYear.bind(this);
    }

    public render(): JSX.Element {
        const { annualStatements, isOpen } = this.props;
        const { errorYear, year } = this.state;

        const currentYear: number = dayjs().year();
        const usedYears: number[] = _.map(annualStatements, (loopStatement: IAnnualStatement) => parseInt(loopStatement.year, 10));

        const yearOptions: number[] = [];
        for (let i = fundingStartYear; i <= currentYear; i++) {
            if (!usedYears.includes(i)) {
                yearOptions.push(i);
            }
        }

        return (
            <Modal
                destroyOnClose={true}
                okButtonProps={{ disabled: !!errorYear }}
                okText='Add Statement'
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Annual Statement'
                wrapClassName='investor-annual-statement-add-modal'
            >
                <Form.Item className='year' label='Year Ending' help={errorYear} validateStatus={errorYear && 'error'}>
                    <Select onChange={this.onChangeYear} value={year} onBlur={this.validateYear}>
                        {yearOptions.map((loopYear: number) => <Select.Option key={`year${loopYear}`} value={`${loopYear}`}>{loopYear}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item className='statement-file' label='Statement File'>
                    <Upload className='add-statement' beforeUpload={this.beforeUpload} showUploadList={true} multiple={false}>
                        <Button>Add Statement</Button>
                    </Upload>
                </Form.Item>
            </Modal>
        );
    }

    private beforeUpload(file: File): boolean {
        this.setState({
            file,
        });

        return false;
    }

    private onChangeYear(value: string) {
        this.setState({
            year: value,
        });
    }

    private onClickOk() {
        const { file, year } = this.state;

        let valid: boolean = true;

        valid = this.validateYear() && valid;

        if (!valid) {
            return;
        }

        this.props.addStatement(file, year);
        this.props.onClose();
        this.setState({
            file: null,
            year: null,
        });
    }

    private validateYear(): boolean {
        const { annualStatements } = this.props;
        const { year } = this.state;

        let error: string = null;

        const yearAnnualStatement: IAnnualStatement = _.find(annualStatements, { year });
        if (yearAnnualStatement) {
            error = 'There is already a statement with the given year';
        }

        this.setState({
            errorYear: error,
        });

        return !error;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        annualStatements: investorAccountAnnualStatementsSelector(state, ownProps.accountUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        addStatement: (file: File, year: string) => dispatch(investorAccountAnnualStatementsAddAction(ownProps.accountUuid, ownProps.investorUuid, file, year)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AnnualStatementAddModal);
