import { all, call, debounce, put, select, takeEvery } from '@redux-saga/core/effects';
import { notification } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { currentAdministratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import IApplication from '~Api/Application/IApplication';
import { parseApplication } from '~Api/Application/parsers';
import BrokerDocumentTypeEnum from '~Api/Broker/BrokerDocumentTypeEnum';
import IBroker from '~Api/Broker/IBroker';
import IDocument from '~Api/Broker/IDocument';
import IHistory from '~Api/Broker/IHistory';
import INote from '~Api/Broker/INote';
import {
    parseBroker,
    parseBrokerDocument,
    parseBrokerHistory,
    parseBrokerNote,
} from '~Api/Broker/parsers';
import {
    brokerApproveRequest,
    brokerAssignBdmRequest,
    brokerDocumentsListRequest,
    brokerGetRequest,
    brokerHistoriesListRequest,
    brokerInsuranceProofRequest,
    brokerMembershipDocumentRequest,
    brokerNextContactRequest,
    brokerNotesAddRequest,
    brokerNotesListRequest,
    brokerPhotoIdRequest,
    brokerRecordContactRequest,
    brokerRejectRequest,
    brokerResetApprovalStatusRequest,
    brokerUnassignBdmRequest,
    brokerUpdateRequest,
    brokersAddRequest,
    brokersApplicationsListRequest,
    brokersDealsListRequest,
    brokersListRequest,
    brokersSearchRequest,
} from '~Api/Broker/requests';
import IDeal from '~Api/Deal/IDeal';
import { parseDeal } from '~Api/Deal/parsers';
import { IFetchResponse } from '~utilities/fetch';
import { renderNotificationLoadingIcon } from '~utilities/utils';
import {
    IBrokerApplicationsListAction,
    IBrokerApproveAction,
    IBrokerAssignBdmAction,
    IBrokerContactDateAction,
    IBrokerDealsListAction,
    IBrokerDocumentUploadAction,
    IBrokerDocumentsListAction,
    IBrokerGetAction,
    IBrokerHistoriesListAction,
    IBrokerNotesAddAction,
    IBrokerNotesListAction,
    IBrokerRecordContactAction,
    IBrokerRejectAction,
    IBrokerResetApprovalStatusAction,
    IBrokerSendAction,
    IBrokerUnassignBdmAction,
    IBrokerValueSetAction,
    IBrokersAddAction,
    IBrokersListAction,
    IBrokersSearchAction,
    brokerApplicationsSetAction,
    brokerDealsSetAction,
    brokerDocumentSetAction,
    brokerDocumentsSetAction,
    brokerHistoriesSetAction,
    brokerNoteRemoveAction,
    brokerNoteSetAction,
    brokerNotesSetAction,
    brokerSendAction,
    brokerSetAction,
    brokersSearchResultsSetAction,
    brokersSetAction,
} from './actions';
import BrokersActionsEnum from './ActionsEnum';
import { brokerSelector } from './selectors';

function* brokersAdd(action: IBrokersAddAction): Iterator<unknown> {
    const key: string = `brokersAdd ${dayjs().format()}`;
    const message: string = 'Add Broker';

    notification.open({
        description: 'Adding broker...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const brokersAddResponse: IFetchResponse = yield call(brokersAddRequest, action.broker);
    if (brokersAddResponse.status === 422) {
        notification.error({
            description: `There was a problem adding the broker: ${_.values(brokersAddResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const broker: IBroker = parseBroker(brokersAddResponse.body);
        yield put(brokerSetAction(broker));

        notification.success({
            description: 'The broker has been added.',
            duration: 4.5,
            key,
            message,
        });
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* brokersList(action: IBrokersListAction): Iterator<unknown> {
    const brokersListResponse: IFetchResponse = yield call(brokersListRequest);
    const brokers: IBroker[] = yield Promise.all(brokersListResponse.body.map(parseBroker));
    yield put(brokersSetAction(brokers));
}

function* brokersSearch(action: IBrokersSearchAction): Iterator<unknown> {
    const brokersSearchResponse: IFetchResponse = yield call(brokersSearchRequest, action.keyword);
    const brokers: IBroker[] = yield Promise.all(brokersSearchResponse.body.map(parseBroker));
    yield put(brokersSearchResultsSetAction(brokers));
}

function* brokerGet(action: IBrokerGetAction): Iterator<unknown> {
    const brokerGetResponse: IFetchResponse = yield call(brokerGetRequest, action.brokerUuid);
    const parsedbroker: IBroker = yield parseBroker(brokerGetResponse.body);
    yield put(brokerSetAction(parsedbroker));
}

function* brokerApplicationsList(action: IBrokerApplicationsListAction): Iterator<unknown> {
    const brokerApplicationsListResponse: IFetchResponse = yield call(brokersApplicationsListRequest, action.brokerUuid);
    const applications: IApplication[] = yield Promise.all(brokerApplicationsListResponse.body.map(parseApplication));
    yield put(brokerApplicationsSetAction(action.brokerUuid, applications));
}

function* brokerApprove(action: IBrokerApproveAction): Iterator<unknown> {
    const key: string = `brokerApprove ${action.brokerUuid}`;

    notification.open({
        description: 'Approving broker...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Approve Broker',
    });

    const broker: IBroker = yield select(brokerSelector, action.brokerUuid);
    yield call(brokerApproveRequest, broker.uuid, action.sendEmail);

    notification.success({
        description: 'The broker has been approved.',
        duration: 4.5,
        key,
        message: 'Approve Broker',
    });
}

function* brokerAssignBdm(action: IBrokerAssignBdmAction): Iterator<unknown> {
    const key: string = `brokerAssignBdm ${action.brokerUuid}`;
    const message: string = 'Assign BDM to broker';

    notification.open({
        description: 'Assigning BDM to broker...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const brokerAssignBdmResponse: IFetchResponse = yield call(brokerAssignBdmRequest, action.brokerUuid, action.administratorUuid);
    if (brokerAssignBdmResponse.status === 422) {
        notification.error({
            description: `There was a problem assigning the BDM: ${_.values(brokerAssignBdmResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const updatedBroker: IBroker = yield parseBroker(brokerAssignBdmResponse.body);
        yield put(brokerSetAction(updatedBroker));

        notification.success({
            description: 'The BDM has been assigned.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* brokerContactDate(action: IBrokerContactDateAction): Iterator<unknown> {
    const key: string = `brokerContactDate ${action.brokerUuid}`;

    notification.open({
        description: 'Updating...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Next Contact',
    });

    yield call(brokerNextContactRequest, action.brokerUuid, action.date);

    notification.success({
        description: 'The next contact date has been updated.',
        duration: 4.5,
        key,
        message: 'Next Contact',
    });
}

function* brokerRecordContact(action: IBrokerRecordContactAction): Iterator<unknown> {
    const key: string = `brokerRecordContact ${action.brokerUuid}`;
    const message: string = 'Record Contact';

    notification.open({
        description: 'Recording contact...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    yield call(brokerRecordContactRequest, action.brokerUuid, action.note);

    notification.success({
        description: 'The contact has been recorded',
        duration: 4.5,
        key,
        message,
    });
}

function* brokerReject(action: IBrokerRejectAction): Iterator<unknown> {
    const key: string = `brokerReject ${action.brokerUuid}`;

    notification.open({
        description: 'Rejecting broker...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Reject Broker',
    });

    yield call(brokerRejectRequest, action.brokerUuid, action.reason, action.note);

    notification.success({
        description: 'The broker has been rejected.',
        duration: 4.5,
        key,
        message: 'Reject Broker',
    });
}

function* brokerSend(action: IBrokerSendAction): Iterator<unknown> {
    const broker: IBroker = yield select(brokerSelector, action.brokerUuid);
    yield call(brokerUpdateRequest, broker);
}

function* brokerUnassignBdm(action: IBrokerUnassignBdmAction): Iterator<unknown> {
    const key: string = `brokerUnassignBdm ${action.brokerUuid}`;
    const message: string = 'Unassign BDM from broker';

    notification.open({
        description: 'Unassigning BDM from broker...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const brokerUnassignBdmResponse: IFetchResponse = yield call(brokerUnassignBdmRequest, action.brokerUuid);
    if (brokerUnassignBdmResponse.status === 422) {
        notification.error({
            description: `There was a problem unassigning the BDM: ${_.values(brokerUnassignBdmResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const updatedBroker: IBroker = yield parseBroker(brokerUnassignBdmResponse.body);
        yield put(brokerSetAction(updatedBroker));

        notification.success({
            description: 'The BDM has been unassigned.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* brokerResetApprovalStatus(action: IBrokerResetApprovalStatusAction): Iterator<unknown> {
    const key: string = `brokerResetApprovalStatus ${action.brokerUuid}`;
    const message: string = 'Reset Broker Approval Status';

    notification.open({
        description: 'Resetting broker status...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const brokerResetApprovalStatusResponse: IFetchResponse = yield call(brokerResetApprovalStatusRequest, action.brokerUuid);
    if (brokerResetApprovalStatusResponse.status === 422) {
        notification.error({
            description: `There was a problem resetting the broker: ${_.values(brokerResetApprovalStatusResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const updatedBroker: IBroker = yield parseBroker(brokerResetApprovalStatusResponse.body);
        yield put(brokerSetAction(updatedBroker));

        notification.success({
            description: 'The broker approval status as been reset.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* brokerValueSet(action: IBrokerValueSetAction): Iterator<unknown> {
    yield put(brokerSendAction(action.brokerUuid));
}

function* brokerDocumentsList(action: IBrokerDocumentsListAction): Iterator<unknown> {
    const brokerDocumentsListResponse: IFetchResponse = yield call(brokerDocumentsListRequest, action.brokerUuid);
    const documents: IDocument[] = yield Promise.all(brokerDocumentsListResponse.body.map(parseBrokerDocument));
    yield put(brokerDocumentsSetAction(action.brokerUuid, documents));
}

function* brokerDocumentUpload(action: IBrokerDocumentUploadAction): Iterator<unknown> {
    const key: string = `brokerDocumentUpload ${dayjs().format()}`;

    notification.open({
        description: 'Uploading document...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Upload Document',
    });

    const broker: IBroker = yield select(brokerSelector, action.brokerUuid);

    const preDocument: IDocument = {
        isUploading: true,
        type: action.documentType,
    };

    yield put(brokerDocumentSetAction(broker.uuid, preDocument));

    let documentResponse: IFetchResponse;

    switch (action.documentType) {
        case BrokerDocumentTypeEnum.InsuranceProof:
            documentResponse = yield call(brokerInsuranceProofRequest, broker.uuid, action.document);
            break;
        case BrokerDocumentTypeEnum.MembershipDocument:
            documentResponse = yield call(brokerMembershipDocumentRequest, broker.uuid, action.document);
            break;
        case BrokerDocumentTypeEnum.PhotoId:
            documentResponse = yield call(brokerPhotoIdRequest, broker.uuid, action.document);
            break;
    }

    const document: IDocument = parseBrokerDocument(documentResponse.body);

    yield put(brokerDocumentSetAction(broker.uuid, document));

    notification.success({
        description: 'The document has been uploaded.',
        duration: 4.5,
        key,
        message: 'Upload Document',
    });
}

function* brokerHistoriesList(action: IBrokerHistoriesListAction): Iterator<unknown> {
    const brokerHistoriesListResponse: IFetchResponse = yield call(brokerHistoriesListRequest, action.brokerUuid);
    const histories: IHistory[] = yield Promise.all(brokerHistoriesListResponse.body.map(parseBrokerHistory));
    yield put(brokerHistoriesSetAction(action.brokerUuid, histories));
}

function* brokerDealsList(action: IBrokerDealsListAction): Iterator<unknown> {
    const brokerDealsListResponse: IFetchResponse = yield call(brokersDealsListRequest, action.brokerUuid);
    const deals: IDeal[] = yield Promise.all(brokerDealsListResponse.body.map(parseDeal));
    yield put(brokerDealsSetAction(action.brokerUuid, deals));
}

function* brokerNotesAdd(action: IBrokerNotesAddAction): Iterator<unknown> {
    const key: string = `brokerNotesAdd ${dayjs().format()}`;

    notification.open({
        description: 'Adding note...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Note',
    });

    const currentAdministrator: IAdministrator = yield select(currentAdministratorSelector);

    const preNote: INote = {
        administratorUuid: currentAdministrator.uuid,
        createdTime: dayjs().format(),
        note: action.note,
    };

    yield put(brokerNoteSetAction(action.brokerUuid, preNote));

    const brokerNotesAddResponse: IFetchResponse = yield call(brokerNotesAddRequest, action.brokerUuid, preNote);
    const note: INote = parseBrokerNote(brokerNotesAddResponse.body);

    yield put(brokerNoteRemoveAction(action.brokerUuid, 'new'));
    yield put(brokerNoteSetAction(action.brokerUuid, note));

    notification.success({
        description: 'The note has been added.',
        duration: 4.5,
        key,
        message: 'Add Note',
    });
}

function* brokerNotesList(action: IBrokerNotesListAction): Iterator<unknown> {
    const brokerNotesListResponse: IFetchResponse = yield call(brokerNotesListRequest, action.brokerUuid);
    const notes: INote[] = yield Promise.all(brokerNotesListResponse.body.map(parseBrokerNote));
    yield put(brokerNotesSetAction(action.brokerUuid, notes));
}

export function* BrokersSagas(): Iterator<unknown> {
    yield all([
        takeEvery(BrokersActionsEnum.BrokersAdd, brokersAdd),
        debounce(50, BrokersActionsEnum.BrokersList, brokersList),

        debounce(200, BrokersActionsEnum.BrokersSearch, brokersSearch),

        takeEvery(BrokersActionsEnum.BrokerApprove, brokerApprove),
        takeEvery(BrokersActionsEnum.BrokerAssignBdm, brokerAssignBdm),
        takeEvery(BrokersActionsEnum.BrokerContactDate, brokerContactDate),
        takeEvery(BrokersActionsEnum.BrokerGet, brokerGet),
        takeEvery(BrokersActionsEnum.BrokerRecordContact, brokerRecordContact),
        takeEvery(BrokersActionsEnum.BrokerReject, brokerReject),
        debounce(20, BrokersActionsEnum.BrokerResetApprovalStatus, brokerResetApprovalStatus),
        debounce(500, BrokersActionsEnum.BrokerSend, brokerSend),
        takeEvery(BrokersActionsEnum.BrokerUnassignBdm, brokerUnassignBdm),
        takeEvery(BrokersActionsEnum.BrokerValueSet, brokerValueSet),

        takeEvery(BrokersActionsEnum.BrokerApplicationsList, brokerApplicationsList),

        takeEvery(BrokersActionsEnum.BrokerDocumentsList, brokerDocumentsList),

        takeEvery(BrokersActionsEnum.BrokerDocumentUpload, brokerDocumentUpload),

        takeEvery(BrokersActionsEnum.BrokerHistoriesList, brokerHistoriesList),

        takeEvery(BrokersActionsEnum.BrokerDealsList, brokerDealsList),

        takeEvery(BrokersActionsEnum.BrokerNotesAdd, brokerNotesAdd),
        takeEvery(BrokersActionsEnum.BrokerNotesList, brokerNotesList),
    ]);
}
