import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import Layout from '~Layout';
import Dashboard from './Dashboard';
import Investor from './Investor';
import List from './List';
import Managed from './Managed';
import PendingApproval from './PendingApproval';
import PendingDeposits from './PendingDeposits';
import PendingWithdrawals from './PendingWithdrawals';
import Referrals from './Referrals';

export default function Router(): ReactElement {
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);

    return (
        <Layout topSection='investors'>
            <Switch>
                <Route exact={true} path='/investors' component={Dashboard} />
                <Route exact={true} path='/investors/list' component={List} />
                <Route exact={true} path='/investors/managed' component={Managed} />
                <Route exact={true} path='/investors/pending-approval' component={PendingApproval} />
                {currentUser.permissions.includes(PermissionsEnum.InvestorPendingDeposits) && <Route exact={true} path='/investors/pending-deposits' component={PendingDeposits} />}
                {currentUser.permissions.includes(PermissionsEnum.InvestorPendingWithdrawals) && <Route exact={true} path='/investors/pending-withdrawals' component={PendingWithdrawals} />}
                <Route exact={true} path='/investors/referrals' component={Referrals} />
                <Route path='/investors/:investorUuid' component={Investor} />
            </Switch>
        </Layout>
    );
}
