import { ArrowLeftOutlined, ArrowRightOutlined, DownOutlined } from '@ant-design/icons';
import { Layout as AntLayout, Breadcrumb, Button, Descriptions, Dropdown, Menu, MenuProps, Modal, PageHeader, Space, Spin, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import ExtensionTypeEnum from '~Api/Loan/ExtensionTypeEnum';
import ILoan from '~Api/Loan/ILoan';
import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import WorkflowStatusEnum from '~Api/Loan/WorkflowStatusEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import {
    loanActiveAction,
    loanDrawdownAction,
    loanExtensionAction,
    loanGetAction,
    loanGracePeriodAction,
    loanRecordNextPaymentAction,
    loanRecoveryAction,
} from '~Loans/actions';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { loanSelector } from '../selectors';
import DischargeDateModal from './DischargeDateModal';
import DischargeModal from './DischargeModal';
import FundModal from './FundModal';
import './loan.less';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import menuDivider from '~UI/menuDivider';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';
import AssigneeSelector from './AssigneeSelector';
import PrincipalReduction from './PrincipalReductionModal';

const workflowStatusLabels: IDictionary<string> = {
    [WorkflowStatusEnum.Active]: 'Active',
    [WorkflowStatusEnum.Discharge]: 'Discharge',
    [WorkflowStatusEnum.Drawdown]: 'Drawdown',
    [WorkflowStatusEnum.Extension]: 'Extension',
    [WorkflowStatusEnum.GracePeriod]: 'Grace Period',
    [WorkflowStatusEnum.Recovery]: 'Recovery',
};

const loanStatusLabels: IDictionary<string> = {
    [LoanStatusEnum.ActiveBadStanding]: 'Active - Bad Standing',
    [LoanStatusEnum.ActiveGoodStanding]: 'Active - Good Standing',
    [LoanStatusEnum.ActiveMatured]: 'Active - Matured',
    [LoanStatusEnum.Approved]: 'Approved',
    [LoanStatusEnum.Cancelled]: 'Cancelled',
    [LoanStatusEnum.ClosedObligationsMet]: 'Closed - Obligations Met',
    [LoanStatusEnum.ClosedRefinanced]: 'Closed - Refinanced',
    [LoanStatusEnum.ClosedWrittenOff]: 'Closed - Written Off',
    [LoanStatusEnum.PartialApplication]: 'Partial Application',
    [LoanStatusEnum.PendingApproval]: 'Pending Approval',
};

export default function Layout(props: {
    uuid: string;
    breadcrumb?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    section: string;
}): ReactElement {
    const { breadcrumb, children, section, uuid } = props;

    const [ isDischargeModalOpen, setIsDischargeModalOpen ] = useState<boolean>(false);
    const [ isDischargeDateModalOpen, setIsDischargeDateModalOpen ] = useState<boolean>(false);
    const [ isFundModalOpen, setIsFundModalOpen ] = useState<boolean>(false);
    const [ isPrincipalReductionModalOpen, setIsPrincipalReductionModalOpen ] = useState<boolean>(false);

    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);
    const loan: ILoan = useSelector((state: IGlobalState) => loanSelector(state, uuid));

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!loan) {
            dispatch(loanGetAction(uuid));
        }
    }, []);

    const drawdown: () => void = useCallback(() => {
        dispatch(loanDrawdownAction(uuid));
    }, []);

    const active: () => void = useCallback(() => {
        dispatch(loanActiveAction(uuid));
    }, []);

    const gracePeriod: () => void = useCallback(() => {
        dispatch(loanGracePeriodAction(uuid));
    }, []);

    const extension: () => void = useCallback(() => {
        dispatch(loanExtensionAction(uuid));
    }, []);

    const onClickDischarge: () => void = useCallback(() => {
        setIsDischargeModalOpen(true);
    }, []);

    const onDischargeCancel: () => void = useCallback(() => {
        setIsDischargeModalOpen(false);
    }, []);

    const onClickDischargeDate: () => void = useCallback(() => {
        setIsDischargeDateModalOpen(true);
    }, []);

    const onDischargeDateCancel: () => void = useCallback(() => {
        setIsDischargeDateModalOpen(false);
    }, []);

    const onClickFund: () => void = useCallback(() => {
        setIsFundModalOpen(true);
    }, []);

    const onFundCancel: () => void = useCallback(() => {
        setIsFundModalOpen(false);
    }, []);

    const onClickRecordNextPayment: () => void = useCallback(() => {
        const code: string = loan.salesforceCode && loan.salesforceCode !== loan.code ? loan.code + ' (' + loan.salesforceCode + ')' : loan.code;

        Modal.confirm({
            content: `Are you sure you want to record a payment of ${currencyFormatter.format(loan.paymentAmountNext)} for ${code}?`,
            okText: 'Record',
            onOk: () => {
                dispatch(loanRecordNextPaymentAction(uuid));
            },
            title: 'Record Next Payment',
        });
    }, [loan]);

    const onClickRecordFinalPayment: () => void = useCallback(() => {
        const code: string = loan.salesforceCode && loan.salesforceCode !== loan.code ? loan.code + ' (' + loan.salesforceCode + ')' : loan.code;

        Modal.confirm({
            content: <>Are you sure you want to record a final <strong>interest only</strong> payment of {currencyFormatter.format(loan.paymentAmountNext)} for {code}?</>,
            okText: 'Record',
            onOk: () => {
                dispatch(loanRecordNextPaymentAction(uuid));
            },
            title: 'Record Final Interest Payment',
        });
    }, [loan]);

    const onClickRecovery: () => void = useCallback(() => {
        Modal.confirm({
            content: 'Are you sure you want to move this loan to recovery?',
            okText: 'Move',
            onOk: () => {
                dispatch(loanRecoveryAction(uuid));
            },
            title: 'Move to Recovery',
        });
    }, []);

    const onClickPrincipalReduction: () => void = useCallback(() => {
        setIsPrincipalReductionModalOpen(true);
    }, []);

    const onPrincipalReductionCancel: () => void = useCallback(() => {
        setIsPrincipalReductionModalOpen(false);
    }, []);

    if (!loan) {
        return (
            <AntLayout className='loan'>
                <Spin/>
            </AntLayout>
        );
    }

    const isActive: boolean = [LoanStatusEnum.ActiveBadStanding, LoanStatusEnum.ActiveGoodStanding, LoanStatusEnum.ActiveMatured].includes(loan.status);

    const breadcrumbBlock: JSX.Element = breadcrumb ? (
        <>
            <Breadcrumb.Item><Link to={`/loans/${loan.uuid}`}>{loan.code || loan.salesforceCode}</Link></Breadcrumb.Item>
            {breadcrumb}
        </>
    ) : (
        <Breadcrumb.Item>{loan.code || loan.salesforceCode}</Breadcrumb.Item>
    );

    const workflowMenu: MenuProps = {
        items: [
            ...(loan.workflowStatus !== WorkflowStatusEnum.Drawdown ? [
                {
                    key: 'drawdown',
                    label: 'Move to Drawdown',
                    onClick: drawdown,
                },
            ] : []),
            ...(loan.workflowStatus !== WorkflowStatusEnum.Active ? [
                {
                    key: 'active',
                    label: 'Move to Active',
                    onClick: active,
                },
            ] : []),
            ...(loan.workflowStatus !== WorkflowStatusEnum.GracePeriod ? [
                {
                    key: 'grace-period',
                    label: 'Move to Grace Period',
                    onClick: gracePeriod,
                },
            ] : []),
            ...(loan.workflowStatus !== WorkflowStatusEnum.Extension ? [
                {
                    key: 'extension',
                    label: 'Move to Extension',
                    onClick: extension,
                },
            ] : []),
            ...(loan.workflowStatus !== WorkflowStatusEnum.Recovery ? [
                {
                    key: 'recovery',
                    label: 'Move to Recovery',
                    onClick: onClickRecovery,
                },
            ] : []),
            ...(loan.workflowStatus !== WorkflowStatusEnum.Discharge ? [
                {
                    key: 'discharge',
                    label: 'Move to Discharge',
                    onClick: onClickDischarge,
                },
            ] : []),
        ],
    };

    const recordFinalInterestPaymentButton: JSX.Element = (
        currentUser.permissions.includes(PermissionsEnum.Accounts)
        && loan.paymentDateNext
        && dayjs(loan.paymentDateNext).isSame(dayjs(loan.endDate))
        && dayjs().isSameOrAfter(dayjs(loan.endDate), 'day')
        && isActive
        && loan.extensionType !== ExtensionTypeEnum.GracePeriod
    ) && (
        <Button danger={true} onClick={onClickRecordFinalPayment} type='primary'>Record Final Interest Payment</Button>
    );

    const recordNextPaymentButton: JSX.Element = (
        currentUser.permissions.includes(PermissionsEnum.Accounts)
        && loan.paymentDateNext
        && dayjs(loan.paymentDateNext) < dayjs()
        && !dayjs(loan.paymentDateNext).isSame(dayjs(loan.endDate))
        && isActive
        && loan.extensionType !== ExtensionTypeEnum.GracePeriod
    ) && (
        <Button onClick={onClickRecordNextPayment} type='primary'>Record Next Payment</Button>
    );

    const recordPrincipalReductionButton: JSX.Element = (
        currentUser.permissions.includes(PermissionsEnum.Accounts)
        && isActive
        && loan.extensionType !== ExtensionTypeEnum.GracePeriod
    ) && (
        <Button onClick={onClickPrincipalReduction} danger={true}>Record Principal Reduction</Button>
    );

    const extra: JSX.Element = (
        <Space>
            <Tag className='loan-status'>{loanStatusLabels[loan.status]}</Tag>
            {loan.gracePeriodMonths && <Tag className='grace-period-status'>Grace Period</Tag>}
            {recordFinalInterestPaymentButton}
            {recordNextPaymentButton}
            {recordPrincipalReductionButton}
            {isActive && <Dropdown menu={workflowMenu}><Button>{workflowStatusLabels[loan.workflowStatus]} <DownOutlined /></Button></Dropdown>}
            {currentUser.permissions.includes(PermissionsEnum.LoanFund) && loan.status === LoanStatusEnum.PartialApplication && <Button onClick={onClickFund} type='primary'>Fund</Button>}
        </Space>
    );

    const fundModal: JSX.Element = currentUser.permissions.includes(PermissionsEnum.LoanFund) && loan.status === LoanStatusEnum.PartialApplication && (
        <FundModal
            loan={loan}
            isOpen={isFundModalOpen}
            onCancel={onFundCancel}
        />
    );

    const tagsBlock: JSX.Element = ExtensionTypeEnum.GracePeriod === loan.extensionType && <Tag color='orange'>Grace Period</Tag>;

    const menuItems: ItemType[] = [
        {
            key: 'overview',
            label: <NavLink to={`/loans/${loan.uuid}`}>Overview</NavLink>,
        },
        ...(ExtensionTypeEnum.GracePeriod !== loan.extensionType ? [
            {
                key: 'edit',
                label: <NavLink to={`/loans/${loan.uuid}/edit`}>Edit</NavLink>,
            },
        ] : []),
        ...(!loan.applicationUuid ? [
            {
                key: 'properties',
                label: <NavLink to={`/loans/${loan.uuid}/properties`}>Properties</NavLink>,
            },
        ] : []),
        ...(ExtensionTypeEnum.GracePeriod !== loan.extensionType ? [
            {
                key: 'disbursements',
                label: <NavLink to={`/loans/${loan.uuid}/disbursements`}>Disbursements</NavLink>,
            },
        ] : []),
        {
            key: 'fees',
            label: <NavLink to={`/loans/${loan.uuid}/fees`}>Fees &amp; Outlays</NavLink>,
        },
        ...(ExtensionTypeEnum.GracePeriod !== loan.extensionType ? [
            {
                key: 'transactions',
                label: <NavLink to={`/loans/${loan.uuid}/transactions`}>Transactions <Tag color='warning'>BETA</Tag></NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.Warehouses) ? [
            {
                key: 'warehouses',
                label: <NavLink to={`/loans/${loan.uuid}/warehouses`}>Warehouses</NavLink>,
            },
        ] : []),
        {
            key: 'payout-figures',
            label: <NavLink to={`/loans/${loan.uuid}/payout-figures`}>Payout Figures</NavLink>,
        },
        {
            key: 'grace-periods',
            label: <NavLink to={`/loans/${loan.uuid}/grace-periods`}>Grace Periods</NavLink>,
        },
        {
            key: 'notes',
            label: <NavLink to={`/loans/${loan.uuid}/notes`}>Notes</NavLink>,
        },
        {
            key: 'documents',
            label: <NavLink to={`/loans/${loan.uuid}/documents`}>Documents</NavLink>,
        },
        menuDivider,
        ...(loan.applicationUuid ? [
            {
                key: 'deal-properties',
                label: <NavLink to={`/loans/${loan.uuid}/deal-properties`}>Properties</NavLink>,
            },
        ] : []),
        {
            key: 'history',
            label: <NavLink to={`/loans/${loan.uuid}/history`}>History</NavLink>,
        },
        ...(loan.applicationUuid && currentUser.permissions.includes(PermissionsEnum.Leads) ? [
            menuDivider,
            {
                key: 'application',
                label: <NavLink to={`/applications/${loan.applicationUuid}`}><ArrowLeftOutlined/> Application</NavLink>,
            },
        ] : []),
        ...(loan.extensionApplicationUuid && currentUser.permissions.includes(PermissionsEnum.Leads) ? [
            {
                key: 'extension-application',
                label: <NavLink to={`/applications/${loan.extensionApplicationUuid}`}><ArrowRightOutlined/> Extension Application</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.Investments) && loan.investmentUuid ? [
            menuDivider,
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.Investments) && loan.investmentUuid ? [
            {
                key: 'investment',
                label: <NavLink to={`/investments/${loan.investmentUuid}`}><ArrowRightOutlined/> Investment</NavLink>,
            },
        ] : []),
    ];

    return (
        <AntLayout className='loan'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/loans'>Loans</Link></Breadcrumb.Item>
                {breadcrumbBlock}
            </Breadcrumb>
            <PageHeader
                className='header'
                extra={extra}
                ghost={false}
                subTitle={loan.code ? loan.salesforceCode : null}
                tags={tagsBlock}
                title={loan.code || loan.salesforceCode}
            >
                <Descriptions size='small' column={4}>
                    <Descriptions.Item label='Amount'>{currencyFormatter.format(loan.amount)} {loan.amountRemaining < loan.amount && ` (${currencyFormatter.format(loan.amountRemaining)} remaining)`}</Descriptions.Item>
                    <Descriptions.Item label='Term'>{loan.termMonths} months {loan.gracePeriodMonths ? `+ ${loan.gracePeriodMonths} month${loan.gracePeriodMonths !== 1 ? 's' : ''} of Grace Period` : ''}</Descriptions.Item>
                    <Descriptions.Item label='Interest Rate'>{percentageFormatter.format(loan.interestRate / 100)}</Descriptions.Item>
                    <Descriptions.Item label='Created'>{loan.createdTime ? dayjs(loan.createdTime).format('Do MMMM YYYY HH:mm:ss') : '-'}</Descriptions.Item>
                    <Descriptions.Item label='Loan Manager'><AssigneeSelector loan={loan} /></Descriptions.Item>
                    <Descriptions.Item label='Primary Contact'>{loan.contactName}</Descriptions.Item>
                    <Descriptions.Item label='Email'>{loan.contactEmail ? <a href={`mailto:${loan.contactEmail}`}>{loan.contactEmail}</a> : '-'}</Descriptions.Item>
                    <Descriptions.Item label='Phone'>{loan.contactPhone ? <a href={`tel:${loan.contactPhone}`}>{loan.contactPhone}</a> : '-'}</Descriptions.Item>
                    <Descriptions.Item className='discharge-date' label='Discharge Date'>
                        <Button onClick={onClickDischargeDate} type='link'>{loan.dischargeDate ? dayjs(loan.dischargeDate).format('Do MMMM YYYY') : 'Unknown'}</Button>
                    </Descriptions.Item>
                </Descriptions>
            </PageHeader>
            <AntLayout className='content-wrapper'>
                <AntLayout.Sider className='menu' theme='light' width={240}>
                    <Menu
                        defaultSelectedKeys={[section]}
                        items={menuItems}
                        mode='inline'
                    />
                </AntLayout.Sider>
                <AntLayout.Content className={`content ${section}`}>
                    {children}
                </AntLayout.Content>
            </AntLayout>
            <DischargeModal
                loan={loan}
                isOpen={isDischargeModalOpen}
                onCancel={onDischargeCancel}
            />
            <DischargeDateModal
                loan={loan}
                isOpen={isDischargeDateModalOpen}
                onCancel={onDischargeDateCancel}
            />
            {fundModal}
            <PrincipalReduction
                loan={loan}
                isOpen={isPrincipalReductionModalOpen}
                onCancel={onPrincipalReductionCancel}
            />
        </AntLayout>
    );
}
