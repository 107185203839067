import { Breadcrumb, Button, Layout, Space, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { GrNew } from 'react-icons/gr';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import IAdviser from '~Api/Adviser/IAdviser';
import { advisersListAction } from './actions';
import { advisersSelector } from './selectors';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import SearchDropdown from '~UI/SearchDropdown';
import {
    CheckOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import AddModal from './AddModal';
import { currencyFormatter } from '~utilities/formatters';
import ApprovalStatusEnum from '~Api/Adviser/ApprovalStatusEnum';

interface IState {
    isAddModalOpen: boolean;
}

interface IPropsSelector {
    advisers: IDictionary<IAdviser>;
}

interface IPropsDispatch {
    advisersList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class List extends React.Component<Props> {
    private refreshInterval: NodeJS.Timeout;

    public state: IState = {
        isAddModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAdd = this.onClickAdd.bind(this);
        this.onCloseAddModal = this.onCloseAddModal.bind(this);
    }

    public componentDidMount(): void {
        this.props.advisersList();

        this.refreshInterval = setInterval(() => {
            this.props.advisersList();
        }, 5 * 60 * 1000);
    }

    public componentWillUnmount(): void {
        clearInterval(this.refreshInterval);
    }

    public render(): JSX.Element {
        const { advisers } = this.props;
        const { isAddModalOpen } = this.state;

        if (!advisers) {
            return (
                <Layout className='advisers'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Advisers</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Advisers</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const columns: ColumnType<IAdviser>[] = [
            {
                dataIndex: 'approvalStatus',
                defaultFilteredValue: [ApprovalStatusEnum.Approved, ApprovalStatusEnum.Pending, ApprovalStatusEnum.Legacy],
                filters: [
                    {
                        text: 'Approved',
                        value: ApprovalStatusEnum.Approved,
                    },
                    {
                        text: 'Rejected',
                        value: ApprovalStatusEnum.Rejected,
                    },
                    {
                        text: 'Pending',
                        value: ApprovalStatusEnum.Pending,
                    },
                    {
                        text: 'Legacy',
                        value: ApprovalStatusEnum.Legacy,
                    },
                ],
                onFilter: (value: string | number | boolean, adviser: IAdviser) => adviser.approvalStatus === value,
                render: (approvalStatus: ApprovalStatusEnum) => {
                    if (approvalStatus === ApprovalStatusEnum.Approved) {
                        return <CheckOutlined />;
                    }

                    if (approvalStatus === ApprovalStatusEnum.Rejected) {
                        return <CloseOutlined />;
                    }

                    if (approvalStatus === ApprovalStatusEnum.Pending) {
                        return <GrNew />;
                    }

                    if (approvalStatus === ApprovalStatusEnum.Legacy) {
                        return <ClockCircleOutlined />;
                    }
                },
                width: '5%',
            },
            {
                dataIndex: 'code',
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string | number | boolean, adviser: IAdviser) => adviser.code.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                sorter: (a: IAdviser, b: IAdviser) => a.code.localeCompare(b.code),
                title: 'Code',
                width: '10%',
            },
            {
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string | number | boolean, adviser: IAdviser) => adviser.formattedName.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                render: (adviser: IAdviser) => <Link to={`/advisers/${adviser.uuid}`}>{adviser.formattedName}</Link>,
                sorter: (a: IAdviser, b: IAdviser) => a.formattedName.localeCompare(b.formattedName),
                title: 'Name',
            },
            {
                dataIndex: 'companyName',
                sorter: (a: IAdviser, b: IAdviser) => a.companyName.localeCompare(b.companyName),
                title: 'Company',
                width: '20%',
            },
            {
                dataIndex: 'dealerGroup',
                sorter: (a: IAdviser, b: IAdviser) => a.dealerGroup.localeCompare(b.dealerGroup),
                title: 'Dealer Group',
                width: '20%',
            },
            {
                dataIndex: 'investorCount',
                sorter: (a: IAdviser, b: IAdviser) => a.investorCount > b.investorCount ? 1 : -1,
                title: 'Investor Count',
                width: '10%',
            },
            {
                dataIndex: 'investorsPortfolioTotal',
                render: (investorsPortfolioTotal: number) => currencyFormatter.format(investorsPortfolioTotal),
                sorter: (a: IAdviser, b: IAdviser) => a.investorsPortfolioTotal > b.investorsPortfolioTotal ? 1 : -1,
                title: 'Investors Portfolio Total',
                width: '15%',
            },
        ];

        return (
            <Layout className='advisers'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Advisers</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Button onClick={this.onClickAdd}>Add Adviser</Button>
                        </Space>
                        <Typography.Title level={2}>Advisers</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.values(advisers)}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>

                <AddModal
                    isOpen={isAddModalOpen}
                    onCancel={this.onCloseAddModal}
                />
            </Layout>
        );
    }

    private onClickAdd(): void {
        this.setState({
            isAddModalOpen: true,
        });
    }

    private onCloseAddModal(): void {
        this.setState({
            isAddModalOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        advisers: advisersSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        advisersList: () => dispatch(advisersListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(List);
