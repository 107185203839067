import {
    Button,
    Form,
    Typography,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { authTokenSelector } from '~Auth/selectors';
import { IGlobalState } from '~reducer';
import Layout from '~Reports/Layout';
import DatePicker from '~UI/DatePicker';

interface IPropsSelector {
    authToken: string;
}

interface IState {
    endDate: string;
    errors: {
        dateRange?: string,
    };
    startDate: string;
}

type Props = IPropsSelector;

class LeadsExport extends React.Component<Props, IState> {
    public state: IState = {
        endDate: null,
        errors: {},
        startDate: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeDateRange = this.onChangeDateRange.bind(this);
        this.onClickDownload = this.onClickDownload.bind(this);

        this.validateDateRange = this.validateDateRange.bind(this);
    }

    render(): JSX.Element {
        const { endDate, errors, startDate } = this.state;

        return (
            <Layout section='leads-export'>
                <Typography.Title level={2}>Leads Export</Typography.Title>
                <Form.Item help={errors.dateRange} className='date-range' label='Created Date Range' validateStatus={errors.dateRange && 'error'}>
                    <DatePicker.RangePicker
                        picker='date'
                        format='DD/MM/YYYY'
                        onBlur={this.validateDateRange}
                        onChange={this.onChangeDateRange}
                        value={[startDate ? dayjs(startDate) : null, endDate ? dayjs(endDate) : null]}
                    />
                </Form.Item>
                <Form.Item>
                    <Button onClick={this.onClickDownload} type='primary'>Download</Button>
                </Form.Item>
            </Layout>
        );
    }

    private validateDateRange(): boolean {
        const { endDate, startDate } = this.state;

        let error: string;

        if (!startDate || !endDate) {
            error = 'Please enter a date range';
        }

        if (dayjs(endDate).diff(startDate, 'day') > 31) {
            error = 'Please enter dates that span a month at most';
        }

        this.setError('dateRange', error);

        return !error;
    }

    private setError(key: keyof IState['errors'], value: string): void {
        this.setState({
            errors: {
                [key]: value,
            },
        });
    }

    private onChangeDateRange(dateRange: [Dayjs, Dayjs]): void {
        this.setState({
            endDate: dateRange[1].format('YYYY-MM-DD'),
            startDate: dateRange[0].format('YYYY-MM-DD'),
        });
    }

    private onClickDownload(): void {
        const { authToken } = this.props;
        const { startDate, endDate } = this.state;

        let valid: boolean = true;

        valid = this.validateDateRange() && valid;

        if (!valid) {
            return;
        }

        window.open(`${process.env.API_HOST}/deals/download-report?startDate=${startDate}&endDate=${endDate}&token=${authToken}`, '_blank');
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        authToken: authTokenSelector(state),
    };
}

export default connect(
    mapStateToProps,
)(LeadsExport);
