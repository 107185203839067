import { Layout as AntLayout, Button, Form, Input, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import Layout from '~Layout';
import { authMagicLinkCreateAction } from './actions';
import './auth.less';

interface IState {
    email: string;
}

interface IPropsDispatch {
    magicLink: (email: string) => void;
}

type Props = IPropsDispatch;

class AccountRecovery extends React.Component<Props, IState> {
    public state: IState = {
        email: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onClickSubmit = this.onClickSubmit.bind(this);
    }

    public render(): JSX.Element {
        const { email } = this.state;

        return (
            <Layout topSection='account-recovery'>
                <AntLayout className='account-recovery'>
                    <AntLayout className='content-wrapper'>
                        <AntLayout.Content className='content'>
                            <Typography.Title level={2}>Account Recovery</Typography.Title>
                            <Form.Item className='email'>
                                <Input onChange={this.onChangeEmail} placeholder='Email' value={email} />
                            </Form.Item>
                            <Form.Item>
                                <Link className='login' to='/login'>Login</Link>
                                <Button disabled={!email} onClick={this.onClickSubmit} type='primary'>Submit</Button>
                            </Form.Item>
                        </AntLayout.Content>
                    </AntLayout>
                </AntLayout>
            </Layout>
        );
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            email: event.target.value,
        });
    }

    private onClickSubmit() {
        const { email } = this.state;

        this.props.magicLink(email);
    }
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        magicLink: (email: string) => dispatch(authMagicLinkCreateAction(email)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(AccountRecovery);
