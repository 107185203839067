import { Breadcrumb, Layout, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IDeal from '~Api/Deal/IDeal';
import { applicationsListAction } from '~Applications/actions';
import { applicationsSelector } from '~Applications/selectors';
import AssigneeSelector from '~Deals/AssigneeSelector';
import { IGlobalState } from '~reducer';
import { dashboardVisibleStatuses } from './Dashboard';

interface IApplicationPropertyPair {
    application: IApplication;
    applicationProperty: IApplicationProperty;
    deal: IDeal;
    uuid: string;
}

interface IPropsSelector {
    applications: IApplication[];
}

interface IPropsDispatch {
    applicationsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class ValuationExpiryNotifications extends React.Component<Props> {
    public componentDidMount() {
        this.props.applicationsList();
    }

    public render(): JSX.Element {
        const { applications } = this.props;

        if (!applications) {
            return (
                <Layout className='applications'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/applications'>Applications</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Expiring Valuations</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Expiring Valuations</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const warningDate: Dayjs = dayjs().subtract(69, 'days');
        const properties: IApplicationPropertyPair[] = [];
        _.each(applications, (application: IApplication) => {
            const { deal } = application;

            if (deal.closedTime || !(dashboardVisibleStatuses.includes(application.workflowStatus))) {
                return;
            }

            _.each(application.properties, (applicationProperty: IApplicationProperty) => {
                if (!applicationProperty.valuationInspectionDate) {
                    return;
                }

                if (dayjs(applicationProperty.valuationInspectionDate) > warningDate) {
                    return;
                }

                properties.push({
                    application,
                    applicationProperty,
                    deal,
                    uuid: applicationProperty.uuid,
                });
            });
        });

        const columns: ColumnType<IApplicationPropertyPair>[] = [
            {
                key: 'applicationCode',
                render: (applicationPropertyPair: IApplicationPropertyPair) => <Link to={`/applications/${applicationPropertyPair.application.uuid}`}>{applicationPropertyPair.application.code}</Link>,
                title: 'Application Code',
                width: '15%',
            },
            {
                key: 'applicationName',
                render: (applicationPropertyPair: IApplicationPropertyPair) => <Link to={`/applications/${applicationPropertyPair.application.uuid}`}>{applicationPropertyPair.application.formattedName}</Link>,
                title: 'Application Name',
                width: '20%',
            },
            {
                key: 'address',
                render: (applicationPropertyPair: IApplicationPropertyPair) => applicationPropertyPair.applicationProperty.dealProperty.formattedAddress,
                title: 'Address',
            },
            {
                key: 'expiry',
                render: (applicationPropertyPair: IApplicationPropertyPair) => dayjs(applicationPropertyPair.applicationProperty.valuationInspectionDate).add(90, 'days').format('Do MMMM YYYY'),
                title: 'Valuation Expiry',
                width: '15%',
            },
            {
                key: 'loanOfficer',
                render: (applicationPropertyPair: IApplicationPropertyPair) => <AssigneeSelector deal={applicationPropertyPair.deal} />,
                title: 'Loan Officer',
                width: '15%',
            },
        ];

        return (
            <Layout className='applications'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/applications'>Applications</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Expiring Valuations</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Expiring Valuations</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.sortBy(properties, (applicationPropertyPair: IApplicationPropertyPair) => [applicationPropertyPair.applicationProperty.valuationInspectionDate, applicationPropertyPair.application.code])}
                            rowKey='uuid'
                            pagination={false}
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        applications: applicationsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        applicationsList: () => dispatch(applicationsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ValuationExpiryNotifications);
