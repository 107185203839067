import { Breadcrumb, Layout, Space, Spin, Table, Typography } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { loansDischargeForecastListAction } from './actions';
import LoanStatusTag from './LoanStatusTag';
import { loansDischargeForecastSelector } from './selectors';
import WarehouseLoanBreakdownList from './WarehouseLoanBreakdownList';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import { warehousesSelector } from '~Warehouses/selectors';
import { warehousesListAction } from '~Warehouses/actions';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import { currencyFormatter } from '~utilities/formatters';
import { FilterValue } from 'antd/lib/table/interface';
import DatePicker from '~UI/DatePicker';
import WorkflowStatusEnum from '~Api/Loan/WorkflowStatusEnum';

interface ILoanDischarge {
    date: string;
    loan?: ILoan;
    warehouseCount: number;
    warehouseLoan?: IWarehouseLoan;
    uuid: string;
}

interface IDay {
    dailyTotal: number;
    date: string;
    loanCount: number;
    primaryRowUuid?: string;
    warehouseCount: number;
}

interface IState {
    endDate: string;
    loanWorkflowStatusFilter: WorkflowStatusEnum[];
    startDate: string;
    warehouseUuidFilter: string[];
}

interface IPropsSelector {
    loans: IDictionary<ILoan>;
    warehouses: IDictionary<IWarehouse>;
}

interface IPropsDispatch {
    loansList: () => void;
    warehousesList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class DischargeForecast extends React.Component<Props, IState> {
    private refreshInterval: NodeJS.Timeout;

    public state: IState = {
        endDate: dayjs().add(30, 'day').format('YYYY-MM-DD'),
        loanWorkflowStatusFilter: null,
        startDate: dayjs().format('YYYY-MM-DD'),
        warehouseUuidFilter: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeDateRange = this.onChangeDateRange.bind(this);
        this.onChangeTable = this.onChangeTable.bind(this);
    }

    public componentDidMount(): void {
        const { warehouses } = this.props;

        this.props.loansList();

        if (!warehouses) {
            this.props.warehousesList();
        }

        this.refreshInterval = setInterval(() => {
            this.props.loansList();
        }, 5 * 60 * 1000);
    }

    public componentWillUnmount(): void {
        clearInterval(this.refreshInterval);
    }

    public render(): JSX.Element {
        const { loans, warehouses } = this.props;
        const { endDate, loanWorkflowStatusFilter, startDate, warehouseUuidFilter } = this.state;

        if (!loans || !warehouses) {
            return (
                <Layout className='loans-discharge-forecast'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/loans'>Loans</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Discharge Forecast</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Discharge Forecast</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const days: IDictionary<IDay> = {};
        const loanDischarges: ILoanDischarge[] = [];
        const expiredDischarges: ILoan[] = [];

        const endDateDayjs: Dayjs = dayjs(endDate);
        const startDateDayjs: Dayjs = dayjs(startDate);

        for (let i: number = 0; i <= endDateDayjs.diff(startDateDayjs, 'day'); i++) {
            const loopDate: string = startDateDayjs.add(i, 'days').format('YYYY-MM-DD');
            days[loopDate] = {
                dailyTotal: 0,
                date: loopDate,
                loanCount: 0,
                warehouseCount: 0,
            };
            loanDischarges.push({
                date: loopDate,
                uuid: loopDate,
                warehouseCount: 0,
            });
        }

        _.each(loans, (loan: ILoan) => {
            // Filter the loan status column
            if (loanWorkflowStatusFilter && !loanWorkflowStatusFilter.includes(loan.workflowStatus)) {
                return;
            }

            const dischargeDateDaysjs: Dayjs = dayjs(loan.dischargeDate || loan.endDate);

            if (dischargeDateDaysjs.isAfter(endDateDayjs, 'day')) {
                return;
            }

            if (dischargeDateDaysjs.isBefore(startDateDayjs, 'day')) {
                expiredDischarges.push(loan);
                return;
            }

            const warehouseLoans: IWarehouseLoan[] = _.filter(loan.warehouseLoans, (warehouseLoan: IWarehouseLoan) => {
                // Exclude anything that is no longer active
                if (warehouseLoan.balancePrincipal >= 0) {
                    return false;
                }

                // Apply the filter from the warehouse name column
                if (warehouseUuidFilter && !warehouseUuidFilter.includes(warehouseLoan.warehouseUuid)) {
                    return false;
                }

                return true;
            });

            // If the warehouse loans have been filtered, skip this loan
            if (0 === warehouseLoans.length) {
                return;
            }

            const formattedDischargeDate: string = dischargeDateDaysjs.format('YYYY-MM-DD');

            const day: IDay = days[formattedDischargeDate];

            if (!day.primaryRowUuid) {
                day.primaryRowUuid = warehouseLoans[0].uuid;

                const initialLoanDischarge: ILoanDischarge = _.find(loanDischarges, (loanDischarge: ILoanDischarge) => loanDischarge.date === formattedDischargeDate);
                initialLoanDischarge.date = formattedDischargeDate;
                initialLoanDischarge.loan = loan;
                initialLoanDischarge.warehouseCount = warehouseLoans.length;
                initialLoanDischarge.warehouseLoan = warehouseLoans[0];
            } else {
                loanDischarges.push({
                    date: formattedDischargeDate,
                    loan,
                    uuid: warehouseLoans[0].uuid,
                    warehouseCount: warehouseLoans.length,
                    warehouseLoan: warehouseLoans[0],
                });
            }
            day.loanCount++;
            day.dailyTotal += loan.amountRemaining;
            day.warehouseCount += warehouseLoans.length;

            for (let i: number = 1; i < warehouseLoans.length; i++) {
                loanDischarges.push({
                    date: formattedDischargeDate,
                    loan,
                    uuid: warehouseLoans[i].uuid,
                    warehouseCount: 0,
                    warehouseLoan: warehouseLoans[i],
                });
            }
        });

        const columns: ColumnsType<ILoanDischarge> = [
            {
                className: 'date',
                fixed: 'left',
                render: (loanDischarge: ILoanDischarge) => {
                    if (!loanDischarge.loan) {
                        return dayjs(loanDischarge.date).format('D/M');
                    }

                    const day: IDay = days[loanDischarge.date];
                    return {
                        children: dayjs(loanDischarge.date).format('D/M'),
                        props: {
                            // When the rowSpan of the cell is set to zero, it essentially hides the cell.
                            rowSpan: (day.primaryRowUuid === loanDischarge.warehouseLoan.uuid ? day.warehouseCount : 0),
                        },
                    };
                },
                title: 'Date',
                width: 80,
            },
            {
                className: 'day',
                fixed: 'left',
                render: (loanDischarge: ILoanDischarge) => {
                    if (!loanDischarge.warehouseLoan) {
                        return dayjs(loanDischarge.date).format('dddd');
                    }

                    const day: IDay = days[loanDischarge.date];
                    return {
                        children: dayjs(loanDischarge.date).format('dddd'),
                        props: {
                            rowSpan: (day.primaryRowUuid === loanDischarge.warehouseLoan.uuid) ? day.warehouseCount : 0,
                        },
                    };
                },
                title: 'Day',
                width: 150,
            },
            {
                className: 'daily-total',
                fixed: 'left',
                render: (loanDischarge: ILoanDischarge) => {
                    if (!loanDischarge.loan) {
                        return '-';
                    }

                    const day: IDay = days[loanDischarge.date];
                    return {
                        children: currencyFormatter.format(day.dailyTotal),
                        props: {
                            rowSpan: (day.primaryRowUuid === loanDischarge.warehouseLoan.uuid) ? day.warehouseCount : 0,
                        },
                    };
                },
                title: 'Daily Total',
                width: 150,
            },
            {
                className: 'loan-code',
                dataIndex: 'loan',
                fixed: 'left',
                render: (loan: ILoan, loanDischarge: ILoanDischarge) => {
                    if (!loanDischarge.loan) {
                        return '-';
                    }

                    return {
                        children: loan ? <Link to={`/loans/${loan.uuid}`}>{loan.code}</Link> : '-',
                        props: {
                            rowSpan: loanDischarge.warehouseCount,
                        },
                    };
                },
                title: 'Loan Code',
                width: 150,
            },
            {
                className: 'loan-name',
                dataIndex: 'loan',
                render: (loan: ILoan, loanDischarge: ILoanDischarge) => {
                    if (!loanDischarge.loan) {
                        return '-';
                    }

                    return {
                        children: loan ? <Link to={`/loans/${loan.uuid}`}>{loan.name}</Link> : '-',
                        props: {
                            rowSpan: loanDischarge.warehouseCount,
                        },
                    };
                },
                title: 'Loan Name',
                width: 400,
            },
            {
                className: 'loan-amount',
                dataIndex: 'loan',
                render: (loan: ILoan, loanDischarge: ILoanDischarge) => {
                    if (!loanDischarge.loan) {
                        return '-';
                    }

                    return {
                        children: loan ? currencyFormatter.format(loan.amountRemaining) : '-',
                        props: {
                            rowSpan: loanDischarge.warehouseCount,
                        },
                    };
                },
                title: 'Loan Amount',
                width: 150,
            },
            {
                className: 'loan-status',
                dataIndex: ['loan', 'workflowStatus'],
                filteredValue: loanWorkflowStatusFilter,
                filters: [
                    {
                        text: 'Maturing',
                        value: WorkflowStatusEnum.Active,
                    },
                    {
                        text: 'Discharge',
                        value: WorkflowStatusEnum.Discharge,
                    },
                    {
                        text: 'Extension',
                        value: WorkflowStatusEnum.Extension,
                    },
                    {
                        text: 'Grace Period',
                        value: WorkflowStatusEnum.GracePeriod,
                    },
                    {
                        text: 'Recovery',
                        value: WorkflowStatusEnum.Recovery,
                    },
                ],
                render: (workflowStatus: WorkflowStatusEnum, loanDischarge: ILoanDischarge): JSX.Element => {
                    if (!loanDischarge.loan) {
                        return;
                    }

                    return <LoanStatusTag loan={loanDischarge.loan} />;
                },
                title: 'Loan Status',
                width: 150,
            },
            {
                dataIndex: ['warehouseLoan', 'warehouseUuid'],
                filteredValue: warehouseUuidFilter,
                filters: _.values(_.sortBy(warehouses, ['name'])).map((warehouse: IWarehouse) => {
                    return {
                        text: warehouse.name,
                        value: warehouse.uuid,
                    };
                }),
                render: (warehouseUuid: string) => warehouseUuid ? warehouses[warehouseUuid].name : '-',
                title: 'Warehouse',
                width: 250,
            },
            {
                dataIndex: ['warehouseLoan', 'balancePrincipal'],
                render: (balancePrincipal: number) => balancePrincipal ? currencyFormatter.format(balancePrincipal) : '-',
                title: 'Warehouse Amount',
                width: 200,
            },
        ];

        const expiredColumns: ColumnsType<ILoan> = [
            {
                render: (loan: ILoan) => <Link to={`/loans/${loan.uuid}`}>{loan.code}</Link>,
                title: 'Loan',
                width: '10%',
            },
            {
                dataIndex: 'contactName',
                title: 'Name',
                width: '20%',
            },
            {
                dataIndex: 'amountRemaining',
                render: (amountRemaining: number) => currencyFormatter.format(amountRemaining),
                title: 'Amount',
                width: '10%',
            },
            {
                dataIndex: 'dischargeDate',
                render: (dischargeDate: string) => dischargeDate ? dayjs(dischargeDate).format('D/M/YYYY') : '-',
                title: 'Discharge Date',
                width: '10%',
            },
            {
                dataIndex: 'endDate',
                render: (endDate: string) => dayjs(endDate).format('D/M/YYYY'),
                title: 'End Date',
                width: '10%',
            },
            {
                render: (loan: ILoan) => <WarehouseLoanBreakdownList loanUuid={loan.uuid} />,
                title: 'Warehouses',
            },
        ];

        const hasPastDays: boolean = expiredDischarges.length > 0;
        const pastDischargeBlock: JSX.Element = hasPastDays && (
            <div className='expired-discharges'>
                <h3>Expired Discharges</h3>
                <Table
                    columns={expiredColumns}
                    dataSource={_.orderBy(expiredDischarges, ['dischargeDate'], ['asc'])}
                    pagination={false}
                    rowClassName='forecast-expired-discharge-row'
                    rowKey='uuid'
                    size='middle'
                />
            </div>
        );

        return (
            <Layout className='loans forecast'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/loans'>Loans</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Discharge Forecast</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <DatePicker.RangePicker
                                allowClear={false}
                                disabledDate={this.onDisabledDate}
                                picker='date'
                                format='DD/MM/YYYY'
                                onChange={this.onChangeDateRange}
                                value={[startDate ? dayjs(startDate) : null, endDate ? dayjs(endDate) : null]}
                            />
                        </Space>
                        <Typography.Title level={2}>Discharge Forecast</Typography.Title>
                        <div className='forecast-current'>
                            <Table
                                columns={columns}
                                dataSource={_.sortBy(loanDischarges, ['date'])}
                                onChange={this.onChangeTable}
                                pagination={false}
                                rowKey='uuid'
                                scroll={{ x: true, y: 800 }}
                                size='middle'
                                summary={this.summary}
                            />
                        </div>
                        {pastDischargeBlock}
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    private onChangeDateRange(dateRange: [Dayjs, Dayjs]): void {
        this.setState({
            endDate: dateRange[1].format('YYYY-MM-DD'),
            startDate: dateRange[0].format('YYYY-MM-DD'),
        });
    }

    private onChangeTable(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>): void {
        this.setState({
            loanWorkflowStatusFilter: (filters['loan.workflowStatus'] as WorkflowStatusEnum[]) || null, // loan status column
            warehouseUuidFilter: (filters['warehouseLoan.warehouseUuid'] as string[]) || null, // warehouse name column
        });
    }

    private onDisabledDate(currentDate: Dayjs): boolean {
        return currentDate.format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD');
    }

    private summary(loanDischarges: readonly ILoanDischarge[]): JSX.Element {
        let loanAmountTotal: number = 0;
        let warehouseAmountTotal: number = 0;

        const loanUuids: string[] = [];

        _.each(loanDischarges, (loanDischarge: ILoanDischarge) => {
            if (!loanDischarge.loan) {
                return;
            }

            loanAmountTotal += loanDischarge.loan.amountRemaining;
            loanUuids.push(loanDischarge.loan.uuid);
            warehouseAmountTotal += loanDischarge.warehouseLoan.balancePrincipal;
        });

        const loanCount: number = _.uniq(loanUuids).length;

        return (
            <Table.Summary fixed={true}>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>&nbsp;</Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>&nbsp;</Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>{loanCount}</Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>&nbsp;</Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>{currencyFormatter.format(loanAmountTotal)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={6}>&nbsp;</Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>&nbsp;</Table.Summary.Cell>
                    <Table.Summary.Cell index={8}>{currencyFormatter.format(warehouseAmountTotal)}</Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        loans: loansDischargeForecastSelector(state),
        warehouses: warehousesSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        loansList: () => dispatch(loansDischargeForecastListAction()),
        warehousesList: () => dispatch(warehousesListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DischargeForecast);
