import { Button, Modal, Space, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IApplication from '~Api/Application/IApplication';
import IFormal from '~Api/Application/IFormal';
import {
    applicationFormalsAddAction,
    applicationFormalsListAction,
    applicationGetAction,
} from '~Applications/actions';
import {
    applicationFormalsSelector,
    applicationSelector,
} from '~Applications/selectors';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';

interface IMatch {
    applicationUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    application: IApplication;
    currentUser: IAuthUser;
    formals: IFormal[];
}

interface IPropsDispatch {
    applicationGet: () => void;
    formalAdd: () => void;
    formalsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class FormalApprovals extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickIssueFormalApproval = this.onClickIssueFormalApproval.bind(this);
    }

    public componentDidMount() {
        const { application } = this.props;

        if (!application) {
            this.props.applicationGet();
        }

        this.props.formalsList();
    }

    public render(): JSX.Element {
        const { application, currentUser, formals, match } = this.props;

        if (!application || !formals) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='formal-approvals'>
                    <Typography.Title level={2}>Formal Approvals</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const columns: ColumnType<IFormal>[] = [
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => dayjs(createdTime).format('DD/MM/YYYY HH:mm'),
                sorter: (a: IFormal, b: IFormal) => dayjs(a.createdTime) > dayjs(b.createdTime) ? 1 : -1,
                title: 'Created',
                width: '15%',
            },
            {
                dataIndex: 'code',
                title: 'Code',
            },
            {
                dataIndex: 'loanAmount',
                render: (loanAmount: number) => currencyFormatter.format(loanAmount),
                title: 'Loan Amount',
                width: '15%',
            },
            {
                dataIndex: 'termMonths',
                render: (termMonths: number) => `${termMonths} months`,
                title: 'Term',
                width: '15%',
            },
            {
                dataIndex: 'interestRate',
                render: (interestRate: number) => percentageFormatter.format(interestRate / 100),
                title: 'Interest Rate',
                width: '15%',
            },
        ];

        const issueApprovalButton: JSX.Element = currentUser.permissions.includes(PermissionsEnum.ApplicationFormalApprovalAdd) && (
            <Space className='issue'>
                <Button className='issue-formal-approval' onClick={this.onClickIssueFormalApproval}>Issue Formal Approval</Button>
            </Space>
        );

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='formal-approvals'>
                {issueApprovalButton}
                <Typography.Title level={2}>Formal Approvals</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(formals)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                />
            </Layout>
        );
    }

    private onClickIssueFormalApproval() {
        Modal.confirm({
            content: 'Are you sure you want to issue a formal approval for this application?',
            okText: 'Issue Formal Approval',
            onOk: () => {
                this.props.formalAdd();
            },
            title: 'Issue Formal Approval',
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
        currentUser: authCurrentUserSelector(state),
        formals: applicationFormalsSelector(state, ownProps.match.params.applicationUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
        formalAdd: () => dispatch(applicationFormalsAddAction(ownProps.match.params.applicationUuid)),
        formalsList: () => dispatch(applicationFormalsListAction(ownProps.match.params.applicationUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FormalApprovals);
