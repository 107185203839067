import IBankAccount from './IBankAccount';
import ICompany from './ICompany';
import IComplianceDocument from './IComplianceDocument';
import IIndividual from './IIndividual';
import IInvestor from './IInvestor';
import INote from './INote';
import ITrust from './ITrust';

export function transformBankAccount(bankAccount: IBankAccount): any {
    return {
        accountName: bankAccount.accountName,
        accountNumber: bankAccount.accountNumber,
        bankName: bankAccount.bankName,
        bsb: bankAccount.bsb,
    };
}

export function transformComplianceDocument(complianceDocument: IComplianceDocument): any {
    return {
        documentType: complianceDocument.documentType,
        investorAccountUuid: complianceDocument.investorAccountUuid,
        investorFileUuid: complianceDocument.investorFileUuid,
        investorIndividualUuid: complianceDocument.investorIndividualUuid,
        investorUuid: complianceDocument.investorUuid,
    };
}

export function transformInvestor(investor: IInvestor): any {
    return {
        availableFundsType: investor.availableFundsType,
        company: investor.company && transformCompany(investor.company),
        contactDateNext: investor.contactDateNext,
        individuals: investor.individuals.map(transformIndividual),
        isManaged: investor.isManaged,
        landingPage: investor.landingPage,
        name: investor.name,
        source: investor.source,
        trust: investor.trust && transformTrust(investor.trust),
        type: investor.type,
    };
}

export function transformCompany(company: ICompany): any {
    return {
        australianCompanyNumber: company.australianCompanyNumber,
        name: company.name,
        postcode: company.postcode,
        state: company.state,
        streetAddress: company.streetAddress,
        suburb: company.suburb,
        taxFileNumber: company.taxFileNumber,
    };
}

export function transformIndividual(individual: IIndividual): any {
    return {
        country: individual.country,
        dateOfBirth: individual.dateOfBirth,
        driverLicenceCardNumber: individual.driverLicenceCardNumber,
        driverLicenceState: individual.driverLicenceState,
        email: individual.email,
        firstName: individual.firstName,
        idProofExpiryDate: individual.idProofExpiryDate,
        idProofNumber: individual.idProofNumber,
        idProofType: individual.idProofType,
        lastName: individual.lastName,
        postcode: individual.postcode,
        residentType: individual.residentType,
        state: individual.state,
        streetAddress: individual.streetAddress,
        suburb: individual.suburb,
        taxFileNumber: individual.taxFileNumber,
    };
}

export function transformNote(note: INote): any {
    return {
        note: note.note,
    };
}

export function transformTrust(trust: ITrust): any {
    return {
        australianBusinessNumber: trust.australianBusinessNumber,
        name: trust.name,
        taxFileNumber: trust.taxFileNumber,
    };
}
