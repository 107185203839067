import { Spin, Tabs, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestor from '~Api/Investor/IInvestor';
import { investorGetAction } from '~Investors/actions';
import { investorSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import IIndividual from '~Api/Investor/IIndividual';
import IAccount from '~Api/Investor/IAccount';
import accountTypeLabels from '~Api/Investor/accountTypeLabels';
import ComplianceDocumentsList from './ComplianceDocumentsList';

interface IMatch {
    investorUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    investor: IInvestor;
}

interface IPropsDispatch {
    investorGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Compliance extends React.Component<Props> {
    public componentDidMount(): void {
        const { investor } = this.props;

        if (!investor) {
            this.props.investorGet();
        }
    }

    public render(): JSX.Element {
        const { investor, match } = this.props;

        if (!investor) {
            return (
                <Layout uuid={match.params.investorUuid} section='compliance'>
                    <Typography.Title level={2}>Compliance</Typography.Title>
                    <Spin />
                </Layout>
            );
        }

        const investorTabBlock: JSX.Element = (
            <Tabs.TabPane
                tab='Investor'
                key={`investor-${investor.uuid}`}
            >
                <ComplianceDocumentsList
                    investor={investor}
                    investorUuid={investor.uuid}
                />
            </Tabs.TabPane>
        );

        const individualTabsBlock: JSX.Element[] = investor.individuals.map((individual: IIndividual) => (
            <Tabs.TabPane
                tab={`${individual.firstName} ${individual.lastName}`}
                key={`individual-${individual.uuid}`}
            >
                <ComplianceDocumentsList
                    individual={individual}
                    investorUuid={investor.uuid}
                />
            </Tabs.TabPane>
        ));

        const accountsTabsBlock: JSX.Element[] = _.map(investor.accounts, (account: IAccount) => (
            <Tabs.TabPane
                tab={accountTypeLabels[account.accountType]}
                key={`account-${account.uuid}`}
            >
                <ComplianceDocumentsList
                    account={account}
                    investorUuid={investor.uuid}
                />
            </Tabs.TabPane>
        ));

        return (
            <Layout uuid={match.params.investorUuid} section='compliance'>
                <Typography.Title level={2}>Compliance</Typography.Title>
                <Tabs defaultActiveKey={`investor-${investor.uuid}`} type='card'>
                    {investorTabBlock}
                    {individualTabsBlock}
                    {accountsTabsBlock}
                </Tabs>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investor: investorSelector(state, ownProps.match.params.investorUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investorGet: () => dispatch(investorGetAction(ownProps.match.params.investorUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Compliance);
