import { CheckOutlined, ClockCircleOutlined, QuestionOutlined } from '@ant-design/icons';
import { Descriptions, Popover, Spin, Table, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { FiDollarSign } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IApplicationPropertyValuer from '~Api/Application/IApplicationPropertyValuer';
import ValuerStatusEnum from '~Api/Application/ValuerStatusEnum';
import ConditionTypeEnum from '~Api/Application/ConditionTypeEnum';
import { applicationPropertyValuersListAction } from '~Applications/actions';
import { applicationPropertyValuersSelector } from '~Applications/selectors';
import PropertyEditModal from '~Deals/PropertyEditModal';
import UploadedConditionDocumentList from '~Applications/Application/UploadedConditionDocumentList';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { valuersListAction } from '~Valuers/actions';
import IValuer from '~Valuers/IValuer';
import { valuersSelector } from '~Valuers/selectors';
import PropertyValuerActions from './PropertyValuerActions';
import UpdateDueDateModal from './UpdateDueDateModal';
import UpdateQuote from './UpdateQuote';
import UpdateValuationValueModal from './UpdateValuationValueModal';
import ValuationInspectionDateModal from './ValuationInspectionDateModal';
import ValuationReceivedDateModal from './ValuationReceivedDateModal';
import { currencyFormatter } from '~utilities/formatters';
import UpdateTimeFrame from './UpdateTimeFrame';

const statusIconsMap: IDictionary<JSX.Element> = {
    [ValuerStatusEnum.QuoteReceived]: <Tooltip title='Quote Received'><FiDollarSign /></Tooltip>,
    [ValuerStatusEnum.QuoteRequested]: <Tooltip title='Quote Requested'><QuestionOutlined/></Tooltip>,
    [ValuerStatusEnum.ValuationOrdered]: <Tooltip title='Valuation Ordered'><ClockCircleOutlined/></Tooltip>,
    [ValuerStatusEnum.ValuationReceived]: <Tooltip title='Valuation Received'><CheckOutlined/></Tooltip>,
};

interface IPropertyValuerValuerPair {
    propertyValuer: IApplicationPropertyValuer;
    uid: string;
    valuer: IValuer;
}

interface IState {
    isUpdateDueDateModalOpen: boolean;
    isUpdateEstimatedValueModalOpen: boolean;
    isUpdateInspectionDateModalOpen: boolean;
    isUpdateReceivedDateModalOpen: boolean;
    isUpdateValuationValueModalOpen: boolean;
}

interface IProps {
    application: IApplication;
    applicationProperty: IApplicationProperty;
}

interface IPropsSelector {
    propertyValuers: IDictionary<IApplicationPropertyValuer>;
    valuers: IDictionary<IValuer>;
}

interface IPropsDispatch {
    propertyValuersList: () => void;
    valuersList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class PropertyValuation extends React.Component<Props> {
    public state: IState = {
        isUpdateDueDateModalOpen: false,
        isUpdateEstimatedValueModalOpen: false,
        isUpdateInspectionDateModalOpen: false,
        isUpdateReceivedDateModalOpen: false,
        isUpdateValuationValueModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickUpdateDueDate = this.onClickUpdateDueDate.bind(this);
        this.onClickUpdateDueDateCancel = this.onClickUpdateDueDateCancel.bind(this);
        this.onClickUpdateEstimatedValue = this.onClickUpdateEstimatedValue.bind(this);
        this.onClickUpdateEstimatedValueCancel = this.onClickUpdateEstimatedValueCancel.bind(this);
        this.onClickUpdateInspectionDate = this.onClickUpdateInspectionDate.bind(this);
        this.onClickUpdateInspectionDateCancel = this.onClickUpdateInspectionDateCancel.bind(this);
        this.onClickUpdateReceivedDate = this .onClickUpdateReceivedDate.bind(this);
        this.onClickUpdateReceivedDateCancel = this .onClickUpdateReceivedDateCancel.bind(this);
        this.onClickUpdateValuationValue = this.onClickUpdateValuationValue.bind(this);
        this.onClickUpdateValuationValueCancel = this.onClickUpdateValuationValueCancel.bind(this);
    }

    public componentDidMount() {
        const { propertyValuers, valuers } = this.props;

        if (!propertyValuers) {
            this.props.propertyValuersList();
        }

        if (!valuers) {
            this.props.valuersList();
        }
    }

    public render(): JSX.Element {
        const {
            application,
            applicationProperty,
            propertyValuers,
            valuers,
        } = this.props;
        const {
            isUpdateDueDateModalOpen,
            isUpdateEstimatedValueModalOpen,
            isUpdateInspectionDateModalOpen,
            isUpdateReceivedDateModalOpen,
            isUpdateValuationValueModalOpen,
        } = this.state;

        if (!application || !applicationProperty || !valuers) {
            return (
                <Spin/>
            );
        }

        const propertyValuerValuerPairs: IPropertyValuerValuerPair[] = _.filter(valuers, { 'state': applicationProperty.dealProperty.state }).map((loopValuer: IValuer) => ({
            propertyValuer: _.find(propertyValuers, { valuerUuid: loopValuer.uuid }),
            uid: _.uniqueId('property-valuer-valuer-pair-'),
            valuer: loopValuer,
        }));

        const columns: ColumnType<IPropertyValuerValuerPair>[] = [
            {
                render: (propertyValuerValuerPair: IPropertyValuerValuerPair) => propertyValuerValuerPair.propertyValuer && statusIconsMap[propertyValuerValuerPair.propertyValuer.status],
                width: '5%',
            },
            {
                render: (propertyValuerValuerPair: IPropertyValuerValuerPair) => {
                    const valuerContentBlock: JSX.Element = (
                        <React.Fragment>
                            <p>Phone: {propertyValuerValuerPair.valuer.phone}</p>
                            <p>Email: {propertyValuerValuerPair.valuer.email}</p>
                        </React.Fragment>
                    );

                    return (
                        <Popover content={valuerContentBlock} title={propertyValuerValuerPair.valuer.name}>
                            <a>{propertyValuerValuerPair.valuer.name}</a>
                        </Popover>
                    );
                },
                title: 'Valuer',
            },
            {
                render: (propertyValuerValuerPair: IPropertyValuerValuerPair) => {
                    if (!propertyValuerValuerPair.propertyValuer || !propertyValuerValuerPair.propertyValuer.quoteAmount) {
                        return null;
                    }

                    return (
                        <UpdateQuote
                            applicationProperty={applicationProperty}
                            propertyValuer={propertyValuerValuerPair.propertyValuer}
                        />
                    );
                },
                title: 'Quote',
                width: '15%',
            },
            {
                render: (propertyValuerValuerPair: IPropertyValuerValuerPair) => {
                    if (!propertyValuerValuerPair.propertyValuer || !propertyValuerValuerPair.propertyValuer.quoteTimeFrameDays) {
                        return null;
                    }

                    return (
                        <UpdateTimeFrame
                            applicationProperty={applicationProperty}
                            propertyValuer={propertyValuerValuerPair.propertyValuer}
                        />
                    );
                },
                title: 'Time Frame',
                width: '15%',
            },
            {
                render: (propertyValuerValuerPair: IPropertyValuerValuerPair) => (
                    <PropertyValuerActions
                        application={application}
                        applicationProperty={applicationProperty}
                        valuation={propertyValuerValuerPair.propertyValuer}
                        valuer={propertyValuerValuerPair.valuer}
                    />
                ),
                title: 'Actions',
                width: '15%',
            },
        ];

        return (
            <div className='property-valuation'>
                <Typography.Title level={3}>Details</Typography.Title>
                <Descriptions size='small' column={3} bordered={true} layout='vertical'>
                    <Descriptions.Item label='Estimated Value'>
                        <a onClick={this.onClickUpdateEstimatedValue}>{currencyFormatter.format(applicationProperty.dealProperty.estimatedValue)}</a>
                        <PropertyEditModal
                            dealUuid={application.dealUuid}
                            propertyUuid={applicationProperty.dealPropertyUuid}
                            isOpen={isUpdateEstimatedValueModalOpen}
                            onCancel={this.onClickUpdateEstimatedValueCancel}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label='Valuation'>
                        <a onClick={this.onClickUpdateValuationValue}>{applicationProperty.valuationValue ? currencyFormatter.format(applicationProperty.valuationValue) : '-'}</a>
                        <UpdateValuationValueModal
                            applicationProperty={applicationProperty}
                            isOpen={isUpdateValuationValueModalOpen}
                            onCancel={this.onClickUpdateValuationValueCancel}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label='Documents'>
                        <UploadedConditionDocumentList
                            application={application}
                            applicationProperty={applicationProperty}
                            conditionType={ConditionTypeEnum.Valuation}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label='Due Date'>
                        <a onClick={this.onClickUpdateDueDate}>{applicationProperty.valuationDueDate ? dayjs(applicationProperty.valuationDueDate).format('Do MMMM YYYY') : '-'}</a>
                        <UpdateDueDateModal
                            applicationProperty={applicationProperty}
                            isOpen={isUpdateDueDateModalOpen}
                            onCancel={this.onClickUpdateDueDateCancel}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label='Inspection Date'>
                        <a onClick={this.onClickUpdateInspectionDate}>{applicationProperty.valuationInspectionDate ? dayjs(applicationProperty.valuationInspectionDate).format('Do MMMM YYYY') : '-'}</a>
                        <ValuationInspectionDateModal
                            application={application}
                            applicationProperty={applicationProperty}
                            isOpen={isUpdateInspectionDateModalOpen}
                            onCancel={this.onClickUpdateInspectionDateCancel}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label='Received Date'>
                        <a onClick={this.onClickUpdateReceivedDate}>{applicationProperty.valuationReceivedDate ? dayjs(applicationProperty.valuationReceivedDate).format('Do MMMM YYYY') : '-'}</a>
                        <ValuationReceivedDateModal
                            applicationProperty={applicationProperty}
                            isOpen={isUpdateReceivedDateModalOpen}
                            onCancel={this.onClickUpdateReceivedDateCancel}
                        />
                    </Descriptions.Item>
                </Descriptions>
                <Typography.Title className='valuers-title' level={3}>Valuers</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={propertyValuerValuerPairs}
                    pagination={false}
                    size='small'
                    rowKey='uid'
                />
            </div>
        );
    }

    private onClickUpdateDueDate() {
        this.setState({
            isUpdateDueDateModalOpen: true,
        });
    }

    private onClickUpdateDueDateCancel() {
        this.setState({
            isUpdateDueDateModalOpen: false,
        });
    }

    private onClickUpdateEstimatedValue() {
        this.setState({
            isUpdateEstimatedValueModalOpen: true,
        });
    }

    private onClickUpdateEstimatedValueCancel() {
        this.setState({
            isUpdateEstimatedValueModalOpen: false,
        });
    }

    private onClickUpdateInspectionDate() {
        this.setState({
            isUpdateInspectionDateModalOpen: true,
        });
    }

    private onClickUpdateInspectionDateCancel() {
        this.setState({
            isUpdateInspectionDateModalOpen: false,
        });
    }

    private onClickUpdateReceivedDate() {
        this.setState({
            isUpdateReceivedDateModalOpen: true,
        });
    }

    private onClickUpdateReceivedDateCancel() {
        this.setState({
            isUpdateReceivedDateModalOpen: false,
        });
    }

    private onClickUpdateValuationValue() {
        this.setState({
            isUpdateValuationValueModalOpen: true,
        });
    }

    private onClickUpdateValuationValueCancel() {
        this.setState({
            isUpdateValuationValueModalOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        propertyValuers: applicationPropertyValuersSelector(state, ownProps.applicationProperty.uuid),
        valuers: valuersSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        propertyValuersList: () => dispatch(applicationPropertyValuersListAction(ownProps.applicationProperty.uuid)),
        valuersList: () => dispatch(valuersListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PropertyValuation);
