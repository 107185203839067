import { Form, Input, Spin, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IUser from '~Api/User/IUser';
import { IGlobalState } from '~reducer';
import {
    userGetAction,
    userValueSetAction,
} from '~Users/actions';
import { userSelector } from '~Users/selectors';
import Layout from './Layout';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    user: IUser;
}

interface IPropsDispatch {
    userGet: () => void;
    userValueSet: (key: keyof IUser, value: any) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Edit extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
    }

    public componentDidMount() {
        const { user } = this.props;

        if (!user) {
            this.props.userGet();
        }
    }

    public render(): JSX.Element {
        const { user, match } = this.props;

        if (!user) {
            return (
                <Layout uuid={match.params.uuid} section='edit'>
                    <Typography.Title level={2}>Edit</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        return (
            <Layout uuid={match.params.uuid} section='edit'>
                <Typography.Title level={2}>Edit</Typography.Title>
                <Form.Item label='First Name' className='first-name'>
                    <Input onChange={this.onChangeFirstName} value={user.firstName} />
                </Form.Item>
                <Form.Item label='Last Name' className='last-name'>
                    <Input onChange={this.onChangeLastName} value={user.lastName} />
                </Form.Item>
            </Layout>
        );
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.userValueSet('firstName', event.target.value);
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.userValueSet('lastName', event.target.value);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        user: userSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        userGet: () => dispatch(userGetAction(ownProps.match.params.uuid)),
        userValueSet: (key: keyof IUser, value: any) => dispatch(userValueSetAction(ownProps.match.params.uuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Edit);
