import { Layout as AntLayout, Breadcrumb, Button, Menu, Modal, PageHeader, Space, Spin } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import IWithdrawalAba from '~Api/WithdrawalAba/IWithdrawalAba';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector, authTokenSelector } from '~Auth/selectors';
import { IGlobalState } from '~reducer';
import { withdrawalAbaGetAction, withdrawalAbaSendAction } from '~WithdrawalAbas/actions';
import { withdrawalAbaSelector } from '~WithdrawalAbas/selectors';
import './withdrawal-aba.less';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import menuDivider from '~UI/menuDivider';

interface IProps {
    uuid: string;
    breadcrumb?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    section: string;
}

interface IPropsSelector {
    withdrawalAba: IWithdrawalAba;
    currentUser: IAuthUser;
    token: string;
}

interface IPropsDispatch {
    withdrawalAbaGet: () => void;
    withdrawalAbaSend: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Layout extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickSend = this.onClickSend.bind(this);
    }

    public componentDidMount(): void {
        const { withdrawalAba } = this.props;

        if (!withdrawalAba) {
            this.props.withdrawalAbaGet();
        }
    }

    public render(): JSX.Element {
        const { breadcrumb, children, section, token, withdrawalAba } = this.props;

        if (!withdrawalAba) {
            return (
                <AntLayout className='withdrawal-aba'>
                    <Spin />
                </AntLayout>
            );
        }

        const breadcrumbBlock: JSX.Element = breadcrumb ? (
            <React.Fragment>
                <Breadcrumb.Item><Link to={`/investors/withdrawal-abas/${withdrawalAba.uuid}`}>{dayjs(withdrawalAba.createdTime).format('DD/MM/YYYY HH:mm')}</Link></Breadcrumb.Item>
                {breadcrumb}
            </React.Fragment>
        ) : (
            <Breadcrumb.Item>{dayjs(withdrawalAba.createdTime).format('DD/MM/YYYY HH:mm')}</Breadcrumb.Item>
        );

        const extra: JSX.Element = (
            <Space>
                <Button href={`${process.env.API_HOST}/withdrawal-abas/${withdrawalAba.uuid}/download?token=${token}`} target='_blank'>
                    ABA File
                </Button>
                <Button href={`${process.env.API_HOST}/withdrawal-abas/${withdrawalAba.uuid}/instructions?token=${token}`} target='_blank'>
                    Trustee Instructions
                </Button>
                <Button onClick={this.onClickSend} type={'primary'}>Send</Button>
            </Space>
        );

        const menuItems: ItemType[] = [
            {
                key: 'transactions',
                label: <NavLink to={`/investors/withdrawal-abas/${withdrawalAba.uuid}/transactions`}>Transactions</NavLink>,
            },
            menuDivider,
            {
                key: 'history',
                label: <NavLink to={`/investors/withdrawal-abas/${withdrawalAba.uuid}/history`}>History</NavLink>,
            },
        ];

        return (
            <AntLayout className='withdrawal-aba'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to=''>Investors</Link></Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/investors/withdrawal-abas/'>Withdrawal ABAs</Link></Breadcrumb.Item>
                    {breadcrumbBlock}
                </Breadcrumb>
                <PageHeader
                    className='header'
                    extra={extra}
                    ghost={false}
                    title={dayjs(withdrawalAba.createdTime).format('DD/MM/YYYY HH:mm')}
                />
                <AntLayout className='content-wrapper'>
                    <AntLayout.Sider className='menu' theme='light' width={240}>
                        <Menu
                            defaultSelectedKeys={[section]}
                            items={menuItems}
                            mode='inline'
                        />
                    </AntLayout.Sider>
                    <AntLayout.Content className={`content ${section}`}>
                        {children}
                    </AntLayout.Content>
                </AntLayout>
            </AntLayout>
        );
    }

    private onClickSend(): void {
        Modal.confirm({
            content: 'Are you sure you want to send the ABA File and Trustee Instructions?',
            okText: 'Send',
            onOk: () => {
                this.props.withdrawalAbaSend();
            },
            title: 'Send',
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        currentUser: authCurrentUserSelector(state),
        token: authTokenSelector(state),
        withdrawalAba: withdrawalAbaSelector(state, ownProps.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        withdrawalAbaGet: () => dispatch(withdrawalAbaGetAction(ownProps.uuid)),
        withdrawalAbaSend: () => dispatch(withdrawalAbaSendAction(ownProps.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Layout);
