import { Action } from 'redux';

import IInvestmentSaleTransfer from '~Api/InvestmentSaleTransfer/IInvestmentSaleTransfer';
import InvestmentSaleTransfersActionsEnum from '~InvestmentSaleTransfers/ActionsEnum';
import IHistory from '~Api/Administrator/IHistory';
import IUnallocatedInvestment from '~Api/InvestmentSaleTransfer/IUnallocatedInvestment';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';

export interface IInvestmentSaleTransferGetAction extends Action {
    investmentSaleTransferUuid: string;
}

export function investmentSaleTransferGetAction(investmentSaleTransferUuid: string): IInvestmentSaleTransferGetAction {
    return {
        investmentSaleTransferUuid,
        type: InvestmentSaleTransfersActionsEnum.InvestmentSaleTransferGet,
    };
}

export interface IInvestmentSaleTransferSendAction extends Action {
    investmentSaleTransferUuid: string;
}

export function investmentSaleTransferSendAction(investmentSaleTransferUuid: string): IInvestmentSaleTransferSendAction {
    return {
        investmentSaleTransferUuid,
        type: InvestmentSaleTransfersActionsEnum.InvestmentSaleTransferSend,
    };
}

export interface IInvestmentSaleTransferSetAction extends Action {
    investmentSaleTransfer: IInvestmentSaleTransfer;
}

export function investmentSaleTransferSetAction(investmentSaleTransfer: IInvestmentSaleTransfer): IInvestmentSaleTransferSetAction {
    return {
        investmentSaleTransfer,
        type: InvestmentSaleTransfersActionsEnum.InvestmentSaleTransferSet,
    };
}

export interface IInvestmentSaleTransferHistoriesListAction extends Action {
    investmentSaleTransferUuid: string;
}

export function investmentSaleTransferHistoriesListAction(investmentSaleTransferUuid: string): IInvestmentSaleTransferHistoriesListAction {
    return {
        investmentSaleTransferUuid,
        type: InvestmentSaleTransfersActionsEnum.InvestmentSaleTransferHistoriesList,
    };
}

export interface IInvestmentSaleTransferHistoriesSetAction extends Action {
    histories: IHistory[];
    investmentSaleTransferUuid: string;
}

export function investmentSaleTransferHistoriesSetAction(investmentSaleTransferUuid: string, histories: IHistory[]): IInvestmentSaleTransferHistoriesSetAction {
    return {
        histories,
        investmentSaleTransferUuid,
        type: InvestmentSaleTransfersActionsEnum.InvestmentSaleTransferHistoriesSet,
    };
}

export interface IInvestmentSaleTransferInvestorAccountInvestmentTransactionsListAction extends Action {
    investmentSaleTransferUuid: string;
}

export function investmentSaleTransferInvestorAccountInvestmentTransactionsListAction(investmentSaleTransferUuid: string): IInvestmentSaleTransferInvestorAccountInvestmentTransactionsListAction {
    return {
        investmentSaleTransferUuid,
        type: InvestmentSaleTransfersActionsEnum.InvestmentSaleTransferInvestorAccountInvestmentTransactionsList,
    };
}

export interface IInvestmentSaleTransferInvestorAccountInvestmentTransactionsSetAction extends Action {
    investorAccountInvestmentTransactions: IInvestorAccountInvestmentTransaction[];
    investmentSaleTransferUuid: string;
}

export function investmentSaleTransferInvestorAccountInvestmentTransactionsSetAction(investmentSaleTransferUuid: string, investorAccountInvestmentTransactions: IInvestorAccountInvestmentTransaction[]): IInvestmentSaleTransferInvestorAccountInvestmentTransactionsSetAction {
    return {
        investmentSaleTransferUuid,
        investorAccountInvestmentTransactions,
        type: InvestmentSaleTransfersActionsEnum.InvestmentSaleTransferInvestorAccountInvestmentTransactionsSet,
    };
}

export interface IInvestmentSaleTransfersAddAction extends Action {
    investmentUuid: string,
    investorAccountInvestmentTransactionsUuids: string[];
}

export function investmentSaleTransfersAddAction(investmentUuid: string, investorAccountInvestmentTransactionsUuids: string[]): IInvestmentSaleTransfersAddAction {
    return {
        investmentUuid,
        investorAccountInvestmentTransactionsUuids,
        type: InvestmentSaleTransfersActionsEnum.InvestmentSaleTransfersAdd,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestmentSaleTransfersListAction extends Action {}

export function investmentSaleTransfersListAction(): IInvestmentSaleTransfersListAction {
    return {
        type: InvestmentSaleTransfersActionsEnum.InvestmentSaleTransfersList,
    };
}

export interface IInvestmentSaleTransfersSetAction extends Action {
    investmentSaleTransfers: IInvestmentSaleTransfer[];
}

export function investmentSaleTransfersSetAction(investmentSaleTransfers: IInvestmentSaleTransfer[]): IInvestmentSaleTransfersSetAction {
    return {
        investmentSaleTransfers,
        type: InvestmentSaleTransfersActionsEnum.InvestmentSaleTransfersSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestmentSaleTransfersUnallocatedInvestmentsListAction extends Action {}

export function investmentSaleTransfersUnallocatedInvestmentsListAction(): IInvestmentSaleTransfersUnallocatedInvestmentsListAction {
    return {
        type: InvestmentSaleTransfersActionsEnum.InvestmentSaleTransfersUnallocatedInvestmentsList,
    };
}

export interface IInvestmentSaleTransfersUnallocatedInvestmentsSetAction extends Action {
    unallocatedInvestments: IUnallocatedInvestment[];
}

export function investmentSaleTransfersUnallocatedInvestmentsSetAction(unallocatedInvestments: IUnallocatedInvestment[]): IInvestmentSaleTransfersUnallocatedInvestmentsSetAction {
    return {
        type: InvestmentSaleTransfersActionsEnum.InvestmentSaleTransfersUnallocatedInvestmentsSet,
        unallocatedInvestments,
    };
}