import { Button, Form, Modal, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IInvestor from '~Api/Investor/IInvestor';
import { investorContactDateAction } from '~Investors/actions';
import DatePicker from '~UI/DatePicker';
import { IDictionary } from '~utilities/IDictionary';

interface IDefaultedValues {
    date: string;
}

interface IState {
    date: string;
    dirtyFields: IDictionary<boolean>;
}

interface IProps {
    isOpen: boolean;
    investor: IInvestor;
    onCancel: () => void;
}

interface IPropsDispatch {
    nextContact: (date: string) => void;
}

type Props = IProps & IPropsDispatch;

class NextContactModal extends React.Component<Props, IState> {
    public state: IState = {
        date: null,
        dirtyFields: {},
    };

    constructor(props: Props) {
        super(props);

        this.onClickClear = this.onClickClear.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { investor, isOpen } = this.props;
        const { date } = this.getDefaultedValues();

        return (
            <Modal
                okText='Schedule'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title={`Next Contact with ${investor.name}`}
                wrapClassName='investor-next-contact-modal'
            >
                <Form.Item className='date' label='Date'>
                    <Space>
                        <DatePicker onChange={this.onChangeDate} format='DD/MM/YYYY' value={date ? dayjs(date) : null} />
                        <Button onClick={this.onClickClear}>Clear</Button>
                    </Space>
                </Form.Item>
            </Modal>
        );
    }

    private getDefaultedValues(): IDefaultedValues {
        const { investor } = this.props;
        const { date, dirtyFields } = this.state;
        return {
            date: dirtyFields.date ? date : investor.contactDateNext ? investor.contactDateNext : null,
        };
    }

    private onClickClear() {
        const { dirtyFields } = this.state;
        this.setState({
            date: null,
            dirtyFields: {
                ...dirtyFields,
                date: true,
            },
        });
    }

    private onChangeDate(date: Dayjs) {
        const { dirtyFields } = this.state;
        this.setState({
            date: date ? date.format('YYYY-MM-DD') : null,
            dirtyFields: {
                ...dirtyFields,
                date: true,
            },
        });
    }

    private onClickOk() {
        const { date } = this.state;

        this.props.onCancel();
        this.props.nextContact(date);
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        nextContact: (date: string) => dispatch(investorContactDateAction(ownProps.investor.uuid, date)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(NextContactModal);
