import ILoan from './ILoan';
import ILoanDisbursement from './ILoanDisbursement';
import ILoanFee from './ILoanFee';
import ILoanGracePeriod from './ILoanGracePeriod';
import ILoanNote from './ILoanNote';
import ILoanPayoutFigure from './ILoanPayoutFigure';
import ILoanPayoutFigureItem from './ILoanPayoutFigureItem';
import ILoanPayoutFigureSection from './ILoanPayoutFigureSection';
import ILoanProperty from './ILoanProperty';

export function transformLoan(loan: ILoan): any {
    return {
        applicationUuid: loan.applicationUuid,
        dischargeMethod: loan.dischargeMethod,
        interestAmountPrepaid: loan.interestAmountPrepaid,
        termMonthsMinimum: loan.termMonthsMinimum,
    };
}

export function transformLoanDisbursement(loanDisbursement: ILoanDisbursement): any {
    return {
        amount: loanDisbursement.amount,
        description: loanDisbursement.description,
        payeeName: loanDisbursement.payeeName,
        type: loanDisbursement.type,
    };
}

export function transformLoanFee(fee: ILoanFee): any {
    return {
        amount: fee.amount,
        description: fee.description,
    };
}

export function transformLoanGracePeriod(loanGracePeriod: ILoanGracePeriod): any {
    return {
        startDate: loanGracePeriod.startDate,
    };
}

export function transformLoanNote(note: ILoanNote): any {
    return {
        note: note.note,
    };
}

export function transformLoanPayoutFigure(payoutFigure: ILoanPayoutFigure): any {
    return {
        accruedInterest: payoutFigure.accruedInterest,
        accruedInterestLabel: payoutFigure.accruedInterestLabel,
        accruedInterestMode: payoutFigure.accruedInterestMode,
        approvalStatus: payoutFigure.approvalStatus,
        borrowerName: payoutFigure.borrowerName,
        dischargeDate: payoutFigure.dischargeDate,
        interestAmount: payoutFigure.interestAmount,
        loanCode: payoutFigure.loanCode,
        loanUuid: payoutFigure.loanUuid,
        minimumTermInterest: payoutFigure.minimumTermInterest,
        minimumTermInterestMode: payoutFigure.minimumTermInterestMode,
        prepaidInterest: payoutFigure.prepaidInterest,
        prepaidInterestAuto: payoutFigure.prepaidInterestAuto,
        prepaidInterestMode: payoutFigure.prepaidInterestMode,
        principalAmount: payoutFigure.principalAmount,
        sections: payoutFigure.sections.map(transformLoanPayoutFigureSection),
        trustAmount: payoutFigure.trustAmount,
    };
}

export function transformLoanPayoutFigureItem(payoutFigureItem: ILoanPayoutFigureItem): any {
    return {
        amount: payoutFigureItem.amount,
        name: payoutFigureItem.name,
        payoutFigureSectionUuid: payoutFigureItem.payoutFigureSectionUuid,
    };
}

export function transformLoanPayoutFigureSection(payoutFigureSection: ILoanPayoutFigureSection): any {
    return {
        items: payoutFigureSection.items.map(transformLoanPayoutFigureItem),
        name: payoutFigureSection.name,
        payoutFigureUuid: payoutFigureSection.payoutFigureUuid,
        type: payoutFigureSection.type,
    };
}

export function transformLoanProperty(property: ILoanProperty): any {
    return {
        insuranceExpiryDate: property.insuranceExpiryDate,
        insuranceReplacementValue: property.insuranceReplacementValue,
        insuranceRequired: property.insuranceRequired,
    };
}
