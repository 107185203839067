import { Checkbox, Form, Input, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import { investmentShowAction } from '~Investments/actions';
import { IDictionary } from '~utilities/IDictionary';

interface IDefaultedValues {
    interestRate: number;
}

interface IState {
    dirtyFields: IDictionary<boolean>;
    errors: {
        interestRate?: string;
    };
    interestRate: number;
    sendEmail: boolean;
}

interface IProps {
    investment: IInvestment;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsDispatch {
    investmentShow: (sendEmail: boolean, interestRate?: number) => void;
}

type Props = IProps & IPropsDispatch;

class ShowModal extends React.Component<Props, IState> {
    public state: IState = {
        dirtyFields: {},
        errors: {},
        interestRate: null,
        sendEmail: true,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeInterestRate = this.onChangeInterestRate.bind(this);
        this.onChangeSendEmail = this.onChangeSendEmail.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
        this.validateInterestRate = this.validateInterestRate.bind(this);
    }

    public render(): JSX.Element {
        const { errors, sendEmail } = this.state;
        const { isOpen } = this.props;

        const { interestRate } = this.getDefaultedValues();

        return (
            <Modal
                className='modal'
                okText='Show'
                okType='danger'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Show Investment'
                wrapClassName='investment-show-modal'
            >
                <Form.Item className='interest-rate' label='Interest Rate' help={errors.interestRate} validateStatus={errors.interestRate && 'error'}>
                    <Input addonAfter='%' min={0} step={0.1} type='number' value={interestRate} disabled={!this.canChangeInterestRate()} onChange={this.onChangeInterestRate} onBlur={this.validateInterestRate} />
                </Form.Item>
                <Form.Item className='send-email' label='Email Investors'>
                    <Checkbox onChange={this.onChangeSendEmail} checked={sendEmail} />
                </Form.Item>
            </Modal>
        );
    }

    private getDefaultedValues(): IDefaultedValues {
        const { investment } = this.props;
        const { dirtyFields, interestRate } = this.state;

        return {
            interestRate: dirtyFields.interestRate ? interestRate : investment.interestRate !== 0 ? investment.interestRate : null,
        };
    }

    private onChangeInterestRate(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                interestRate: true,
            },
            interestRate: event.target.value ? event.target.valueAsNumber : null,
        });
    }

    private onChangeSendEmail(event: CheckboxChangeEvent): void {
        this.setState({
            sendEmail: event.target.checked,
        });
    }

    private onClickOk(): void {
        const { interestRate, sendEmail } = this.state;

        let valid: boolean = true;

        if (this.canChangeInterestRate()) {
            valid = this.validateInterestRate() && valid;
        }

        if (!valid) {
            return;
        }

        if (this.canChangeInterestRate()) {
            this.props.investmentShow(sendEmail, interestRate);
        } else {
            this.props.investmentShow(sendEmail);
        }

        this.props.onCancel();
    }

    private validateInterestRate(): boolean {
        const { interestRate, errors } = this.state;

        let error: string;

        if (!interestRate && interestRate !== 0) {
            error = 'Please enter an interest rate';
        } else if (interestRate <= 0) {
            error = 'Interest rate must be greater than 0';
        }

        this.setState({
            errors: {
                ...errors,
                interestRate: error,
            },
        });

        return !error;
    }

    private canChangeInterestRate(): boolean {
        const { investment } = this.props;
        return investment.amountTotal === investment.amountRemaining;
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investmentShow: (sendEmail: boolean, interestRate?: number) => dispatch(investmentShowAction(ownProps.investment.uuid, sendEmail, interestRate)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(ShowModal);
