import _ from 'lodash';
import { AnyAction } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IApplicationDisbursement from '~Api/Application/IApplicationDisbursement';
import IApplicationFee from '~Api/Application/IApplicationFee';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IApplicationPropertyValuer from '~Api/Application/IApplicationPropertyValuer';
import IApplicationWarehouse from '~Api/Application/IApplicationWarehouse';
import IBorrower from '~Api/Application/IBorrower';
import IConditionalCondition from '~Api/Application/IConditionalCondition';
import IConditional from '~Api/Application/IConditional';
import IFormal from '~Api/Application/IFormal';
import IHistory from '~Api/Application/IHistory';
import INote from '~Api/Application/INote';
import IRfiItem from '~Api/Application/IRfiItem';
import WorkflowStatusEnum from '~Api/Application/WorkflowStatusEnum';
import { IBrokerApplicationsSetAction } from '~Brokers/actions';
import BrokersActionsEnum from '~Brokers/ActionsEnum';
import { IReferralPartnerApplicationsSetAction } from '~ReferralPartners/actions';
import ReferralPartnersActionsEnum from '~ReferralPartners/ActionsEnum';
import { IDictionary } from '~utilities/IDictionary';
import {
    IWarehouseEligibleLoansSetAction,
    IWarehouseLoansSetAction,
    IWarehousePendingApplicationsSetAction,
} from '~Warehouses/actions';
import WarehousesActionsEnum from '~Warehouses/ActionsEnum';
import {
    IApplicationBorrowerRemoveAction,
    IApplicationBorrowerSetAction,
    IApplicationBorrowerValueSetAction,
    IApplicationBorrowersSetAction,
    IApplicationCloseAction,
    IApplicationConditionDocumentApproveAction,
    IApplicationConditionDocumentRejectAction,
    IApplicationConditionDocumentRemoveAction,
    IApplicationConditionDocumentResetApprovalStatusAction,
    IApplicationConditionDocumentSetAction,
    IApplicationConditionDocumentsSetAction,
    IApplicationConditionDocumentsUpdatingSolicitorAccessSetAction,
    IApplicationConditionRemoveAction,
    IApplicationConditionSetAction,
    IApplicationConditionalApprovalAction,
    IApplicationConditionalConditionDeleteAction,
    IApplicationConditionalConditionSetAction,
    IApplicationConditionalDeleteAction,
    IApplicationConditionalSetAction,
    IApplicationConditionalsAddAction,
    IApplicationConditionalsSetAction,
    IApplicationConditionalsStandardConditionsSetAction,
    IApplicationConditionsSetAction,
    IApplicationDisbursementDeleteAction,
    IApplicationDisbursementSetAction,
    IApplicationDisbursementSuggestionsSetAction,
    IApplicationDisbursementValueSetAction,
    IApplicationDisbursementsSetAction,
    IApplicationFeeRemoveAction,
    IApplicationFeeSetAction,
    IApplicationFeeValueSetAction,
    IApplicationFeesRemoveAction,
    IApplicationFeesSetAction,
    IApplicationFormalsAddAction,
    IApplicationFormalsSetAction,
    IApplicationHistoriesSetAction,
    IApplicationLegalDocumentsAction,
    IApplicationMarkBrokerCommissionPaidAction,
    IApplicationNewAction,
    IApplicationNoteRemoveAction,
    IApplicationNoteSetAction,
    IApplicationNotesSetAction,
    IApplicationPropertiesSetAction,
    IApplicationPropertiesValuationsOutstandingSetAction,
    IApplicationPropertySetAction,
    IApplicationPropertyValuerMarkPaidAction,
    IApplicationPropertyValuerSetAction,
    IApplicationPropertyValuersSetAction,
    IApplicationPropertyValuersUnpaidSetAction,
    IApplicationRfiItemRemoveAction,
    IApplicationRfiItemSetAction,
    IApplicationRfiItemsSetAction,
    IApplicationSetAction,
    IApplicationSettlementAction,
    IApplicationSettlementDateAction,
    IApplicationUnderwritingAction,
    IApplicationValueSetAction,
    IApplicationWarehouseDeleteAction,
    IApplicationWarehouseSetAction,
    IApplicationWarehouseValueSetAction,
    IApplicationWarehousedAction,
    IApplicationWarehousesSetAction,
    IApplicationsCreateRenewalFromLoanErrorSetAction,
    IApplicationsCreateRenewalFromLoanErrorsSetAction,
    IApplicationsCreateRenewalFromLoanInProgressSetAction,
    IApplicationsDashboardSetAction,
    IApplicationsOutstandingBrokerCommissionsSetAction,
    IApplicationsSearchResultsSetAction,
    IApplicationsSetAction,
    IApplicationsSettlementForecastSetAction,
    IApplicationsSettlementReportListAction,
    IApplicationsSettlementReportSetAction,
} from './actions';
import ApplicationsActionsEnum from './ActionsEnum';
import { dashboardVisibleStatuses } from './Dashboard';
import ConditionDocumentApprovalStatusEnum from '~Api/Application/ConditionDocumentApprovalStatusEnum';
import ICondition from '~Api/Application/ICondition';
import IConditionDocument from '~Api/Application/IConditionDocument';
import ISettlementReportApplication from '~Api/Application/ISettlementReportApplication';
import { IStandardConditionGroup } from '~Api/Application/IStandardConditionGroup';
import { PeriodRangeEnum } from '~utilities/reportUtilities';
import LoansActionsEnum from '~Loans/ActionsEnum';
import {
    ILoanSetAction,
    ILoansDrawdownsSetAction,
    ILoansPaginatedSetAction,
    ILoansSearchResultsSetAction,
    ILoansSetAction,
} from '~Loans/actions';
import ILoan from '~Api/Loan/ILoan';
import ValuerInvoiceStatusEnum from '~Api/Application/ValuerInvoiceStatusEnum';
import CodeTypeEnum from '~Api/Application/CodeTypeEnum';
import DischargeInterestTypeEnum from '~Api/Application/DischargeInterestTypeEnum';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import IWarehouseEligibleLoan from '~Api/Warehouse/IWarehouseEligibleLoan';

export interface IApplicationsState {
    applicationConditionDocumentUuids: IDictionary<string[]>;
    applicationConditionalConditionUuids: IDictionary<string[]>;
    applicationConditionalConditions: IDictionary<IConditionalCondition>;
    applicationConditionalUuids: IDictionary<string[]>;
    applicationConditionals: IDictionary<IConditional>;
    applicationDisbursementUuids: IDictionary<string[]>;
    applicationFeeUuids: IDictionary<string[]>;
    applicationProperties: IDictionary<IApplicationProperty>;
    applicationPropertyUuids: IDictionary<string[]>;
    applicationPropertyValuerUnpaidUuids: string[];
    applicationPropertyValuerUuids: IDictionary<string[]>;
    applicationPropertyValuers: IDictionary<IApplicationPropertyValuer>;
    applicationWarehouseUuids: IDictionary<string[]>;
    applications: IDictionary<IApplication>;
    applicationsListed: boolean;
    applicationsSearchResultUuids: string[];
    borrowers: {
        [applicationUuid: string]: IDictionary<IBorrower>;
    };
    conditionDocuments: IDictionary<IConditionDocument>;
    conditionDocumentsUpdatingSolicitorAccess: boolean;
    conditions: IDictionary<IDictionary<ICondition>>;
    createRenewalFromLoanErrors: IDictionary<IDictionary<string>>;
    createRenewalFromLoanInProgress: boolean;
    dashboardUuids: string[];
    dealApplicationUuids: IDictionary<string[]>;
    disbursementSuggestions: {
        descriptions: IDictionary<number>;
        payeeNames: IDictionary<number>;
    };
    disbursements: IDictionary<IApplicationDisbursement>;
    fees: IDictionary<IApplicationFee>;
    formals: {
        [applicationUuid: string]: IDictionary<IFormal>;
    };
    histories: IDictionary<IDictionary<IHistory>>;
    notes: IDictionary<IDictionary<INote>>;
    outstandingBrokerCommissionsUuids: string[];
    outstandingValuationsApplicationPropertyUuids: string[];
    pendingWarehouseUuids: IDictionary<string[]>;
    rfiItems: IDictionary<IDictionary<IRfiItem>>;
    settlementForecastUuids: string[];
    settlementReport: {
        applications: IDictionary<ISettlementReportApplication>;
        endDate: string;
        periodRange: PeriodRangeEnum;
        startDate: string;
    };
    standardConditions: IStandardConditionGroup[];
    warehouses: IDictionary<IApplicationWarehouse>;
}

const initialData: IApplicationsState = {
    applicationConditionDocumentUuids: {},
    applicationConditionalConditionUuids: {},
    applicationConditionalConditions: {},
    applicationConditionalUuids: {},
    applicationConditionals: {},
    applicationDisbursementUuids: {},
    applicationFeeUuids: {},
    applicationProperties: {},
    applicationPropertyUuids: {},
    applicationPropertyValuerUnpaidUuids: null,
    applicationPropertyValuerUuids: {},
    applicationPropertyValuers: {},
    applicationWarehouseUuids: {},
    applications: null,
    applicationsListed: false,
    applicationsSearchResultUuids: null,
    borrowers: {},
    conditionDocuments: {},
    conditionDocumentsUpdatingSolicitorAccess: false,
    conditions: {},
    createRenewalFromLoanErrors: {},
    createRenewalFromLoanInProgress: false,
    dashboardUuids: null,
    dealApplicationUuids: {},
    disbursementSuggestions: null,
    disbursements: {},
    fees: {},
    formals: {},
    histories: {},
    notes: {},
    outstandingBrokerCommissionsUuids: null,
    outstandingValuationsApplicationPropertyUuids: null,
    pendingWarehouseUuids: {},
    rfiItems: {},
    settlementForecastUuids: null,
    settlementReport: {
        applications: null,
        endDate: null,
        periodRange: null,
        startDate: null,
    },
    standardConditions: null,
    warehouses: {},
};

function dehydrateApplication(application: IApplication): IApplication {
    return _.omit(application, ['borrowers', 'deal', 'properties']);
}

function dehydrateApplicationBorrower(applicationBorrower: IBorrower): IBorrower {
    return _.omit(applicationBorrower, ['dealBorrower']);
}

function dehydrateApplicationConditional(applicationConditional: IConditional): IConditional {
    return _.omit(applicationConditional, ['conditions']);
}

function dehydrateApplicationProperty(applicationProperty: IApplicationProperty): IApplicationProperty {
    return _.omit(applicationProperty, ['application', 'dealProperty', 'valuers']);
}

function dehydrateApplicationWarehouse(applicationWarehouse: IApplicationWarehouse): IApplicationWarehouse {
    return _.omit(applicationWarehouse, ['application']);
}

function statifyApplication(state: IApplicationsState, application: IApplication): IApplicationsState {
    const applications: IDictionary<IApplication> = {};
    const applicationProperties: IDictionary<IApplicationProperty> = {};
    const applicationPropertyUuids: IDictionary<string[]> = {};
    const borrowers: IDictionary<IDictionary<IBorrower>> = {};
    const dashboardUuids: string[] = [];
    const dealApplicationUuids: IDictionary<string[]> = {};
    const applicationBorrowers: IDictionary<IBorrower> = {};
    const propertyUuids: string[] = [];

    _.each(application.borrowers, (borrower: IBorrower) => {
        applicationBorrowers[borrower.uuid] = dehydrateApplicationBorrower(borrower);
    });
    borrowers[application.uuid] = applicationBorrowers;

    _.each(application.properties, (applicationProperty: IApplicationProperty) => {
        applicationProperties[applicationProperty.uuid] = dehydrateApplicationProperty(applicationProperty);
        propertyUuids.push(applicationProperty.uuid);
    });

    applicationPropertyUuids[application.uuid] = propertyUuids;
    applications[application.uuid] = dehydrateApplication(application);

    if (!application.closeReason && dashboardVisibleStatuses.includes(application.workflowStatus)) {
        dashboardUuids.push(application.uuid);
    }

    if (!dealApplicationUuids[application.dealUuid]) {
        dealApplicationUuids[application.dealUuid] = [];
    }
    dealApplicationUuids[application.dealUuid].push(application.uuid);

    return {
        ...state,
        applicationProperties: {
            ...state.applicationProperties,
            ...applicationProperties,
        },
        applicationPropertyUuids: {
            ...state.applicationPropertyUuids,
            ...applicationPropertyUuids,
        },
        applications: {
            ...state.applications,
            ...applications,
        },
        borrowers: {
            ...state.borrowers,
            ...borrowers,
        },
        dealApplicationUuids: {
            ...state.dealApplicationUuids,
            ...dealApplicationUuids,
        },
    };
}

function statifyApplications(state: IApplicationsState, actionApplications: IApplication[]): IApplicationsState {
    let clonedState: IApplicationsState = { ...state };

    _.each(actionApplications, (application: IApplication) => {
        clonedState = statifyApplication(clonedState, application);
    });

    return clonedState;
}

function statifyApplicationConditional(state: IApplicationsState, applicationConditional: IConditional): IApplicationsState {
    const applicationConditionalConditionUuids: IDictionary<string[]> = { ...state.applicationConditionalConditionUuids };
    const applicationConditionalConditions: IDictionary<IConditionalCondition> = { ...state.applicationConditionalConditions };
    const applicationConditionalUuids: IDictionary<string[]> = { ...state.applicationConditionalUuids };
    const applicationConditionals: IDictionary<IConditional> = { ...state.applicationConditionals };

    applicationConditional.conditions.forEach((condition: IConditionalCondition) => {
        applicationConditionalConditions[condition.uuid] = condition;
        applicationConditionalConditionUuids[applicationConditional.uuid] = [
            ...(applicationConditionalConditionUuids[applicationConditional.uuid] || []),
            condition.uuid,
        ];
    });

    applicationConditionals[applicationConditional.uuid] = dehydrateApplicationConditional(applicationConditional);

    applicationConditionalUuids[applicationConditional.applicationUuid] = [
        ...(applicationConditionalUuids[applicationConditional.applicationUuid] || []),
        applicationConditional.uuid,
    ];

    return {
        ...state,
        applicationConditionalConditionUuids: {
            ...state.applicationConditionalConditionUuids,
            [applicationConditional.uuid]: _.uniq(applicationConditionalConditionUuids[applicationConditional.uuid]),
        },
        applicationConditionalConditions,
        applicationConditionalUuids: {
            ...state.applicationConditionalUuids,
            [applicationConditional.applicationUuid]: _.uniq(applicationConditionalUuids[applicationConditional.applicationUuid]),
        },
        applicationConditionals,
    };
}

function statifyApplicationConditionals(state: IApplicationsState, applicationConditionals: IConditional[], applicationUuid: string): IApplicationsState {
    let clonedState: IApplicationsState = {
        ...state,
        applicationConditionalUuids: {
            ...state.applicationConditionalUuids,
            [applicationUuid]: (state.applicationConditionalUuids[applicationUuid] || []),
        },
    };

    applicationConditionals.forEach((applicationConditional: IConditional) => {
        clonedState = statifyApplicationConditional(clonedState, applicationConditional);
    });

    return clonedState;
}

function statifyConditionDocument(state: IApplicationsState, conditionDocument: IConditionDocument): IApplicationsState {
    return {
        ...state,
        conditionDocuments: {
            ...state.conditionDocuments,
            [conditionDocument.uuid]: _.omit(conditionDocument, 'document'),
        },
    };
}

function statifyConditionDocuments(state: IApplicationsState, conditionDocuments: IConditionDocument[]): IApplicationsState {
    let clonedState: IApplicationsState = { ...state };

    _.each(conditionDocuments, (conditionDocument: IConditionDocument) => {
        clonedState = statifyConditionDocument(clonedState, conditionDocument);
    });

    return clonedState;
}

function statifyLoan(state: IApplicationsState, loan: ILoan): IApplicationsState {
    let clonedState: IApplicationsState = { ...state };

    if (loan.extensionApplication) {
        clonedState = statifyApplication(clonedState, loan.extensionApplication);
    }

    if (loan.application) {
        clonedState = statifyApplication(clonedState, loan.application);
    }

    return clonedState;
}

function statifyLoans(state: IApplicationsState, loans: ILoan[]): IApplicationsState {
    let clonedState: IApplicationsState = { ...state };

    _.each(loans, (loan: ILoan) => {
        clonedState = statifyLoan(clonedState, loan);
    });

    return clonedState;
}

export function applicationsReducer(state: IApplicationsState = initialData, action: AnyAction): IApplicationsState {
    switch (action.type) {
        case ApplicationsActionsEnum.ApplicationsCreateRenewalFromLoanInProgressSet: {
            const typedAction: IApplicationsCreateRenewalFromLoanInProgressSetAction = action as IApplicationsCreateRenewalFromLoanInProgressSetAction;

            return {
                ...state,
                createRenewalFromLoanInProgress: typedAction.inProgress,
            };
        }

        case ApplicationsActionsEnum.ApplicationsCreateRenewalFromLoanErrorSet: {
            const typedAction: IApplicationsCreateRenewalFromLoanErrorSetAction = action as IApplicationsCreateRenewalFromLoanErrorSetAction;

            return {
                ...state,
                createRenewalFromLoanErrors: {
                    ...state.createRenewalFromLoanErrors,
                    [typedAction.loanPayoutFigureUuid]: {
                        ...state.createRenewalFromLoanErrors[typedAction.loanPayoutFigureUuid],
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationsCreateRenewalFromLoanErrorsSet: {
            const typedAction: IApplicationsCreateRenewalFromLoanErrorsSetAction = action as IApplicationsCreateRenewalFromLoanErrorsSetAction;

            return {
                ...state,
                createRenewalFromLoanErrors: {
                    ...state.createRenewalFromLoanErrors,
                    [typedAction.loanPayoutFigureUuid]: typedAction.errors,
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationsDashboardSet: {
            const typedAction: IApplicationsDashboardSetAction = action as IApplicationsDashboardSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
                dashboardUuids: typedAction.applications.map((application: IApplication) => application.uuid),
            };
        }

        case ApplicationsActionsEnum.ApplicationsSearchResultsSet: {
            const typedAction: IApplicationsSearchResultsSetAction = action as IApplicationsSearchResultsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
                applicationsSearchResultUuids: _.map(typedAction.applications, (application: IApplication) => application.uuid),
            };
        }

        case ApplicationsActionsEnum.ApplicationsSearchResultsClear: {
            return {
                ...state,
                applicationsSearchResultUuids: [],
            };
        }

        case ApplicationsActionsEnum.ApplicationsSet: {
            const typedAction: IApplicationsSetAction = action as IApplicationsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
                applicationsListed: true,
            };
        }

        case ApplicationsActionsEnum.ApplicationsSettlementForecastSet: {
            const typedAction: IApplicationsSettlementForecastSetAction = action as IApplicationsSettlementForecastSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
                settlementForecastUuids: typedAction.applications.map((application: IApplication) => application.uuid),
            };
        }

        case ApplicationsActionsEnum.ApplicationsSettlementReportList: {
            const typedAction: IApplicationsSettlementReportListAction = action as IApplicationsSettlementReportListAction;

            return {
                ...state,
                settlementReport: {
                    applications: null,
                    endDate: typedAction.endDate,
                    periodRange: typedAction.periodRange,
                    startDate: typedAction.startDate,
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationsSettlementReportSet: {
            const typedAction: IApplicationsSettlementReportSetAction = action as IApplicationsSettlementReportSetAction;

            return {
                ...state,
                settlementReport: {
                    ...state.settlementReport,
                    applications: _.keyBy(typedAction.settlementReportApplications, 'applicationUuid'),
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationClose: {
            const typedAction: IApplicationCloseAction = action as IApplicationCloseAction;

            return {
                ...state,
                applications: {
                    ...state.applications,
                    [typedAction.applicationUuid]: {
                        ...state.applications[typedAction.applicationUuid],
                        closeReason: typedAction.reason,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionDocumentApprove: {
            const typedAction: IApplicationConditionDocumentApproveAction = action as IApplicationConditionDocumentApproveAction;

            return {
                ...state,
                conditionDocuments: {
                    ...state.conditionDocuments,
                    [typedAction.conditionDocumentUuid]: {
                        ...state.conditionDocuments[typedAction.conditionDocumentUuid],
                        approvalStatus: ConditionDocumentApprovalStatusEnum.Approved,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionDocumentReject: {
            const typedAction: IApplicationConditionDocumentRejectAction = action as IApplicationConditionDocumentRejectAction;

            return {
                ...state,
                conditionDocuments: {
                    ...state.conditionDocuments,
                    [typedAction.conditionDocumentUuid]: {
                        ...state.conditionDocuments[typedAction.conditionDocumentUuid],
                        approvalStatus: ConditionDocumentApprovalStatusEnum.Rejected,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionDocumentRemove: {
            const typedAction: IApplicationConditionDocumentRemoveAction = action as IApplicationConditionDocumentRemoveAction;

            return {
                ...state,
                applicationConditionDocumentUuids: {
                    ...state.applicationConditionDocumentUuids,
                    [typedAction.applicationUuid]: _.without(state.applicationConditionDocumentUuids[typedAction.applicationUuid], typedAction.conditionDocumentUuid),
                },
                conditionDocuments: _.omit(state.conditionDocuments, typedAction.conditionDocumentUuid),
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionDocumentResetApprovalStatus: {
            const typedAction: IApplicationConditionDocumentResetApprovalStatusAction = action as IApplicationConditionDocumentResetApprovalStatusAction;

            return {
                ...state,
                conditionDocuments: {
                    ...state.conditionDocuments,
                    [typedAction.conditionDocumentUuid]: {
                        ...state.conditionDocuments[typedAction.conditionDocumentUuid],
                        approvalStatus: ConditionDocumentApprovalStatusEnum.Pending,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionDocumentsSet: {
            const typedAction: IApplicationConditionDocumentsSetAction = action as IApplicationConditionDocumentsSetAction;

            return {
                ...state,
                ...statifyConditionDocuments(state, typedAction.conditionDocuments),
                applicationConditionDocumentUuids: {
                    ...state.applicationConditionDocumentUuids,
                    [typedAction.applicationUuid]: _.map(typedAction.conditionDocuments, 'uuid'),
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionDocumentsUpdatingSolicitorAccessSet: {
            const typedAction: IApplicationConditionDocumentsUpdatingSolicitorAccessSetAction = action as IApplicationConditionDocumentsUpdatingSolicitorAccessSetAction;

            return {
                ...state,
                conditionDocumentsUpdatingSolicitorAccess: typedAction.updatingSolicitorAccess,
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionDocumentSet: {
            const typedAction: IApplicationConditionDocumentSetAction = action as IApplicationConditionDocumentSetAction;

            return {
                ...state,
                ...statifyConditionDocument(state, typedAction.conditionDocument),
                applicationConditionDocumentUuids: {
                    ...state.applicationConditionDocumentUuids,
                    [typedAction.applicationUuid]: _.uniq([
                        ...state.applicationConditionDocumentUuids[typedAction.applicationUuid] || [],
                        typedAction.conditionDocument.uuid,
                    ]),
                },

            };
        }

        case ApplicationsActionsEnum.ApplicationConditionsSet: {
            const typedAction: IApplicationConditionsSetAction = action as IApplicationConditionsSetAction;

            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    [typedAction.applicationUuid]: _.keyBy(typedAction.conditions, 'uuid'),
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionRemove: {
            const typedAction: IApplicationConditionRemoveAction = action as IApplicationConditionRemoveAction;

            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    [typedAction.applicationUuid]: _.omit(state.conditions[typedAction.applicationUuid] || {}, typedAction.conditionUuid),
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionSet: {
            const typedAction: IApplicationConditionSetAction = action as IApplicationConditionSetAction;

            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    [typedAction.applicationUuid]: {
                        ...(state.conditions[typedAction.applicationUuid] || {}),
                        [typedAction.condition.uuid || 'new']: typedAction.condition,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionalsStandardConditionsSet: {
            const typedAction: IApplicationConditionalsStandardConditionsSetAction = action as IApplicationConditionalsStandardConditionsSetAction;

            return {
                ...state,
                standardConditions: typedAction.standardConditions,
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionalApproval: {
            const typedAction: IApplicationConditionalApprovalAction = action as IApplicationConditionalApprovalAction;

            return {
                ...state,
                applications: {
                    [typedAction.applicationUuid]: {
                        ...state.applications[typedAction.applicationUuid],
                        workflowStatus: WorkflowStatusEnum.ConditionalApproval,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationDisbursementDelete: {
            const typedAction: IApplicationDisbursementDeleteAction = action as IApplicationDisbursementDeleteAction;

            return {
                ...state,
                disbursements: _.omit(state.disbursements, typedAction.disbursementUuid),
            };
        }

        case ApplicationsActionsEnum.ApplicationDisbursementSet: {
            const typedAction: IApplicationDisbursementSetAction = action as IApplicationDisbursementSetAction;

            return {
                ...state,
                applicationDisbursementUuids: {
                    ...state.applicationDisbursementUuids,
                    [typedAction.disbursement.applicationUuid]: _.uniq([
                        ...state.applicationDisbursementUuids[typedAction.disbursement.applicationUuid],
                        typedAction.disbursement.uuid,
                    ]),
                },
                disbursements: {
                    ...state.disbursements,
                    [typedAction.disbursement.uuid]: typedAction.disbursement,
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationDisbursementValueSet: {
            const typedAction: IApplicationDisbursementValueSetAction = action as IApplicationDisbursementValueSetAction;

            return {
                ...state,
                disbursements: {
                    ...state.disbursements,
                    [typedAction.disbursementUuid]: {
                        ...state.disbursements[typedAction.disbursementUuid],
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationDisbursementsSet: {
            const typedAction: IApplicationDisbursementsSetAction = action as IApplicationDisbursementsSetAction;

            const disbursements: IDictionary<IApplicationDisbursement> = { ...state.disbursements };
            const applicationDisbursementUuids: string[] = [];

            typedAction.disbursements.forEach((disbursement: IApplicationDisbursement) => {
                disbursements[disbursement.uuid] = disbursement;
                applicationDisbursementUuids.push(disbursement.uuid);
            });

            return {
                ...state,
                applicationDisbursementUuids: {
                    ...state.applicationDisbursementUuids,
                    [action.applicationUuid]: applicationDisbursementUuids,
                },
                disbursements,
            };
        }

        case ApplicationsActionsEnum.ApplicationDisbursementSuggestionsSet: {
            const typedAction: IApplicationDisbursementSuggestionsSetAction = action as IApplicationDisbursementSuggestionsSetAction;

            return {
                ...state,
                disbursementSuggestions: {
                    descriptions: typedAction.descriptions,
                    payeeNames: typedAction.payeeNames,
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationFeeRemove: {
            const typedAction: IApplicationFeeRemoveAction = action as IApplicationFeeRemoveAction;

            const applicationFee: IApplicationFee = state.fees[typedAction.applicationFeeUuid];

            return {
                ...state,
                applicationFeeUuids: {
                    ...state.applicationFeeUuids,
                    [applicationFee.applicationUuid]: _.without(state.applicationFeeUuids[applicationFee.applicationUuid], typedAction.applicationFeeUuid),
                },
                fees: _.omit(state.fees || {}, typedAction.applicationFeeUuid),
            };
        }

        case ApplicationsActionsEnum.ApplicationFeeSet: {
            const typedAction: IApplicationFeeSetAction = action as IApplicationFeeSetAction;

            const applicationFeeUuids: string[] = (state.applicationFeeUuids[typedAction.fee.applicationUuid] || []);
            applicationFeeUuids.push(typedAction.fee.uuid);

            return {
                ...state,
                applicationFeeUuids: {
                    ...state.applicationFeeUuids,
                    [typedAction.fee.applicationUuid]: _.uniq(applicationFeeUuids),
                },
                fees: {
                    ...state.fees,
                    [typedAction.fee.uuid]: typedAction.fee,
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationFeeValueSet: {
            const typedAction: IApplicationFeeValueSetAction = action as IApplicationFeeValueSetAction;

            return {
                ...state,
                fees: {
                    ...state.fees,
                    [typedAction.applicationFeeUuid]: {
                        ...state.fees[typedAction.applicationFeeUuid],
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationFeesRemove: {
            const typedAction: IApplicationFeesRemoveAction = action as IApplicationFeesRemoveAction;

            return {
                ...state,
                applicationFeeUuids: _.omit(state.applicationFeeUuids || {}, typedAction.applicationUuid),
            };
        }

        case ApplicationsActionsEnum.ApplicationFeesSet: {
            const typedAction: IApplicationFeesSetAction = action as IApplicationFeesSetAction;

            const applicationFeeUuids: string[] = [];
            const fees: IDictionary<IApplicationFee> = {};

            typedAction.fees.forEach((fee: IApplicationFee) => {
                applicationFeeUuids.push(fee.uuid);
                fees[fee.uuid] = fee;
            });

            return {
                ...state,
                applicationFeeUuids: {
                    ...state.applicationFeeUuids,
                    [typedAction.applicationUuid]: applicationFeeUuids,
                },
                fees: {
                    ...state.fees,
                    ...fees,
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationFormalsAdd: {
            const typedAction: IApplicationFormalsAddAction = action as IApplicationFormalsAddAction;

            return {
                ...state,
                applications: {
                    [typedAction.applicationUuid]: {
                        ...state.applications[typedAction.applicationUuid],
                        workflowStatus: WorkflowStatusEnum.LegalDocuments,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationHistoriesSet: {
            const typedAction: IApplicationHistoriesSetAction = action as IApplicationHistoriesSetAction;

            return {
                ...state,
                histories: {
                    ...state.histories,
                    [typedAction.applicationUuid]: _.keyBy(typedAction.histories, 'uuid'),
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationNotesSet: {
            const typedAction: IApplicationNotesSetAction = action as IApplicationNotesSetAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.applicationUuid]: _.keyBy(typedAction.notes, 'uuid'),
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationNoteRemove: {
            const typedAction: IApplicationNoteRemoveAction = action as IApplicationNoteRemoveAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.applicationUuid]: _.omit(state.notes[typedAction.applicationUuid] || {}, typedAction.noteUuid),
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationNoteSet: {
            const typedAction: IApplicationNoteSetAction = action as IApplicationNoteSetAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.applicationUuid]: {
                        ...state.notes[typedAction.applicationUuid],
                        [typedAction.note.uuid || 'new']: typedAction.note,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationMarkBrokerCommissionPaid: {
            const typedAction: IApplicationMarkBrokerCommissionPaidAction = action as IApplicationMarkBrokerCommissionPaidAction;

            return {
                ...state,
                outstandingBrokerCommissionsUuids: _.without(state.outstandingBrokerCommissionsUuids, typedAction.applicationUuid),
            };
        }

        case ApplicationsActionsEnum.ApplicationsOutstandingBrokerCommissionsSet: {
            const typedAction: IApplicationsOutstandingBrokerCommissionsSetAction = action as IApplicationsOutstandingBrokerCommissionsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
                outstandingBrokerCommissionsUuids: _.map(typedAction.applications, (application: IApplication) => application.uuid),
            };
        }

        case ApplicationsActionsEnum.ApplicationLegalDocuments: {
            const typedAction: IApplicationLegalDocumentsAction = action as IApplicationLegalDocumentsAction;

            return {
                ...state,
                applications: {
                    [typedAction.applicationUuid]: {
                        ...state.applications[typedAction.applicationUuid],
                        workflowStatus: WorkflowStatusEnum.LegalDocuments,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationNew: {
            const typedAction: IApplicationNewAction = action as IApplicationNewAction;

            return {
                ...state,
                applications: {
                    [typedAction.applicationUuid]: {
                        ...state.applications[typedAction.applicationUuid],
                        workflowStatus: WorkflowStatusEnum.New,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationRfiItemRemove: {
            const typedAction: IApplicationRfiItemRemoveAction = action as IApplicationRfiItemRemoveAction;

            return {
                ...state,
                rfiItems: {
                    [typedAction.applicationUuid]: _.omit(state.rfiItems[typedAction.applicationUuid], typedAction.rfiItemUuid),
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationRfiItemSet: {
            const typedAction: IApplicationRfiItemSetAction = action as IApplicationRfiItemSetAction;

            return {
                ...state,
                rfiItems: {
                    ...state.rfiItems,
                    [typedAction.applicationUuid]: {
                        ...state.rfiItems[typedAction.applicationUuid],
                        [typedAction.rfiItem.uuid]: typedAction.rfiItem,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationRfiItemsSet: {
            const typedAction: IApplicationRfiItemsSetAction = action as IApplicationRfiItemsSetAction;

            return {
                ...state,
                rfiItems: {
                    ...state.rfiItems,
                    [typedAction.applicationUuid]: _.keyBy(typedAction.rfiItems, 'uuid'),
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationSet: {
            const typedAction: IApplicationSetAction = action as IApplicationSetAction;

            const borrowers: IDictionary<IBorrower> = {};
            const applicationProperties: IDictionary<IApplicationProperty> = { ...state.applicationProperties };

            typedAction.application.borrowers.forEach((borrower: IBorrower) => {
                borrowers[borrower.uuid] = dehydrateApplicationBorrower(borrower);
            });

            const applicationPropertyUuids: string[] = [];
            typedAction.application.properties.forEach((applicationProperty: IApplicationProperty) => {
                applicationProperties[applicationProperty.uuid] = dehydrateApplicationProperty(applicationProperty);
                applicationPropertyUuids.push(applicationProperty.uuid);
            });

            return {
                ...state,
                applicationProperties,
                applicationPropertyUuids: {
                    ...state.applicationPropertyUuids,
                    [typedAction.application.uuid]: applicationPropertyUuids,
                },
                applications: {
                    ...state.applications,
                    [typedAction.application.uuid]: dehydrateApplication(typedAction.application),
                },
                borrowers: {
                    ...state.borrowers,
                    [typedAction.application.uuid]: borrowers,
                },
                dealApplicationUuids: {
                    [typedAction.application.dealUuid]: _.uniq([
                        ...(state.dealApplicationUuids[typedAction.application.dealUuid] || []),
                        typedAction.application.uuid,
                    ]),
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationSettlementDate: {
            const typedAction: IApplicationSettlementDateAction = action as IApplicationSettlementDateAction;

            return {
                ...state,
                applications: {
                    ...state.applications,
                    [typedAction.applicationUuid]: {
                        ...state.applications[typedAction.applicationUuid],
                        settlementDate: typedAction.settlementDate,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationSettlement: {
            const typedAction: IApplicationSettlementAction = action as IApplicationSettlementAction;

            return {
                ...state,
                applications: {
                    [typedAction.applicationUuid]: {
                        ...state.applications[typedAction.applicationUuid],
                        workflowStatus: WorkflowStatusEnum.Settlement,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationUnderwriting: {
            const typedAction: IApplicationUnderwritingAction = action as IApplicationUnderwritingAction;

            return {
                ...state,
                applications: {
                    [typedAction.applicationUuid]: {
                        ...state.applications[typedAction.applicationUuid],
                        workflowStatus: WorkflowStatusEnum.Underwriting,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationValueSet: {
            const typedAction: IApplicationValueSetAction = action as IApplicationValueSetAction;

            // By default, the discharge interest type is BreakCost. However, if the key is 'codeType' and the value is 'NonCode',
            // we need to change the discharge interest type to MinimumTerm. This is because for Non Code applications,
            // we require the discharge interest type to be MinimumTerm instead of the default BreakCost.
            let dischargeInterestType: DischargeInterestTypeEnum = state.applications[typedAction.applicationUuid].dischargeInterestType;
            if (typedAction.key === 'codeType' && typedAction.value === CodeTypeEnum.NonCode) {
                dischargeInterestType = DischargeInterestTypeEnum.MinimumTerm;
            }

            return {
                ...state,
                applications: {
                    ...state.applications,
                    [typedAction.applicationUuid]: {
                        ...state.applications[typedAction.applicationUuid],
                        dischargeInterestType,
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationWarehoused: {
            const typedAction: IApplicationWarehousedAction = action as IApplicationWarehousedAction;

            return {
                ...state,
                applications: {
                    [typedAction.applicationUuid]: {
                        ...state.applications[typedAction.applicationUuid],
                        workflowStatus: WorkflowStatusEnum.Warehoused,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationBorrowersSet: {
            const typedAction: IApplicationBorrowersSetAction = action as IApplicationBorrowersSetAction;

            const borrowers: IDictionary<IBorrower> = {};

            typedAction.borrowers.forEach((borrower: IBorrower) => {
                borrowers[borrower.uuid] = dehydrateApplicationBorrower(borrower);
            });

            return {
                ...state,
                borrowers: {
                    ...state.borrowers,
                    [typedAction.applicationUuid]: borrowers,
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationBorrowerRemove: {
            const typedAction: IApplicationBorrowerRemoveAction = action as IApplicationBorrowerRemoveAction;

            return {
                ...state,
                borrowers: {
                    ...state.borrowers,
                    [typedAction.applicationUuid]: _.omit(state.borrowers[typedAction.applicationUuid] || {}, typedAction.borrowerUuid),
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationBorrowerSet: {
            const typedAction: IApplicationBorrowerSetAction = action as IApplicationBorrowerSetAction;

            return {
                ...state,
                borrowers: {
                    ...state.borrowers,
                    [typedAction.applicationUuid]: {
                        ...(state.borrowers[typedAction.applicationUuid] || {}),
                        [typedAction.borrower.uuid]: dehydrateApplicationBorrower(typedAction.borrower),
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationBorrowerValueSet: {
            const typedAction: IApplicationBorrowerValueSetAction = action as IApplicationBorrowerValueSetAction;

            return {
                ...state,
                borrowers: {
                    ...state.borrowers,
                    [typedAction.applicationUuid]: {
                        ...state.borrowers[typedAction.applicationUuid],
                        [typedAction.borrowerUuid]: {
                            ...state.borrowers[typedAction.applicationUuid][typedAction.borrowerUuid],
                            [typedAction.key]: typedAction.value,
                        },
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionalsAdd: {
            const typedAction: IApplicationConditionalsAddAction = action as IApplicationConditionalsAddAction;

            return {
                ...state,
                applications: {
                    [typedAction.applicationUuid]: {
                        ...state.applications[typedAction.applicationUuid],
                        workflowStatus: WorkflowStatusEnum.ConditionalApproval,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionalsSet: {
            const typedAction: IApplicationConditionalsSetAction = action as IApplicationConditionalsSetAction;

            return {
                ...state,
                ...statifyApplicationConditionals(state, typedAction.conditionals, typedAction.applicationUuid),
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionalDelete: {
            const typedAction: IApplicationConditionalDeleteAction = action as IApplicationConditionalDeleteAction;

            const applicationUuid: string = state.applicationConditionals[typedAction.conditionalUuid].applicationUuid;
            const applicationConditionalConditionUuids: IDictionary<string[]> = { ...state.applicationConditionalConditionUuids };
            const applicationConditionalConditions: IDictionary<IConditionalCondition> = { ...state.applicationConditionalConditions };

            _.forEach(applicationConditionalConditionUuids[typedAction.conditionalUuid], (conditionUuid: string) => {
                delete applicationConditionalConditions[conditionUuid];
            });
            delete applicationConditionalConditionUuids[typedAction.conditionalUuid];

            return {
                ...state,
                applicationConditionalConditionUuids,
                applicationConditionalConditions,
                applicationConditionalUuids: {
                    ...state.applicationConditionalUuids,
                    [typedAction.conditionalUuid]: _.filter(state.applicationConditionalUuids[applicationUuid], (conditionalUuid: string) => conditionalUuid !== typedAction.conditionalUuid),
                },
                applicationConditionals: _.omit(state.applicationConditionals, typedAction.conditionalUuid),
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionalSet: {
            const typedAction: IApplicationConditionalSetAction = action as IApplicationConditionalSetAction;

            return {
                ...state,
                ...statifyApplicationConditional(state, typedAction.conditional),
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionalConditionDelete: {
            const typedAction: IApplicationConditionalConditionDeleteAction = action as IApplicationConditionalConditionDeleteAction;

            return {
                ...state,
                applicationConditionalConditionUuids: {
                    ...state.applicationConditionalConditionUuids,
                    [typedAction.conditionalUuid]: _.filter(state.applicationConditionalConditionUuids[typedAction.conditionalUuid], (conditionUuid: string) => conditionUuid !== typedAction.condition.uuid),
                },
                applicationConditionalConditions: _.omit(state.applicationConditionalConditions, typedAction.condition.uuid),
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionalConditionSet: {
            const typedAction: IApplicationConditionalConditionSetAction = action as IApplicationConditionalConditionSetAction;

            const applicationConditionalConditionUuids: IDictionary<string[]> = { ...state.applicationConditionalConditionUuids };
            const applicationConditionalConditions: IDictionary<IConditionalCondition> = { ...state.applicationConditionalConditions };

            applicationConditionalConditions[typedAction.condition.uuid] = typedAction.condition;
            applicationConditionalConditionUuids[typedAction.conditionalUuid] = [
                ...(applicationConditionalConditionUuids[typedAction.conditionalUuid] || []),
                typedAction.condition.uuid,
            ];

            return {
                ...state,
                applicationConditionalConditionUuids,
                applicationConditionalConditions,
            };
        }

        case ApplicationsActionsEnum.ApplicationFormalsSet: {
            const typedAction: IApplicationFormalsSetAction = action as IApplicationFormalsSetAction;

            const formals: IDictionary<IFormal> = {};

            typedAction.formals.forEach((formal: IFormal) => {
                formals[formal.uuid] = formal;
            });

            return {
                ...state,
                formals: {
                    ...state.formals,
                    [typedAction.applicationUuid]: formals,
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationPropertiesSet: {
            const typedAction: IApplicationPropertiesSetAction = action as IApplicationPropertiesSetAction;

            const applicationPropertyUuids: string[] = [];
            const applicationProperties: IDictionary<IApplicationProperty> = { ...state.applicationProperties };

            typedAction.applicationProperties.forEach((applicationProperty: IApplicationProperty) => {
                applicationProperties[applicationProperty.uuid] = dehydrateApplicationProperty(applicationProperty);
                applicationPropertyUuids.push(applicationProperty.uuid);
            });

            return {
                ...state,
                applicationProperties,
                applicationPropertyUuids: {
                    ...state.applicationPropertyUuids,
                    [typedAction.applicationUuid]: applicationPropertyUuids,
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationPropertiesValuationsOutstandingSet: {
            const typedAction: IApplicationPropertiesValuationsOutstandingSetAction = action as IApplicationPropertiesValuationsOutstandingSetAction;

            const applications: IApplication[] = [];
            const applicationProperties: IDictionary<IApplicationProperty> = { ...state.applicationProperties };
            const applicationPropertyValuerUuids: IDictionary<string[]> = { ...state.applicationPropertyValuerUuids };
            const applicationPropertyValuers: IDictionary<IApplicationPropertyValuer> = { ...state.applicationPropertyValuers };
            const outstandingValuationsApplicationPropertyUuids: string[] = [];

            typedAction.applicationProperties.forEach((applicationProperty: IApplicationProperty) => {
                applications.push(applicationProperty.application);
                applicationProperties[applicationProperty.uuid] = dehydrateApplicationProperty(applicationProperty);
                outstandingValuationsApplicationPropertyUuids.push(applicationProperty.uuid);
                _.forEach(applicationProperty.valuers, (valuer: IApplicationPropertyValuer) => {
                    applicationPropertyValuers[valuer.uuid] = valuer;
                    applicationPropertyValuerUuids[applicationProperty.uuid] = [
                        ...(applicationPropertyValuerUuids[applicationProperty.uuid] || []),
                        valuer.uuid,
                    ];
                });
            });

            return {
                ...state,
                ...statifyApplications(state, applications),
                applicationProperties,
                applicationPropertyValuerUuids,
                applicationPropertyValuers,
                outstandingValuationsApplicationPropertyUuids,
            };
        }

        case ApplicationsActionsEnum.ApplicationPropertySet: {
            const typedAction: IApplicationPropertySetAction = action as IApplicationPropertySetAction;

            const applicationPropertyUuids: IDictionary<string[]> = { ...state.applicationPropertyUuids };

            return {
                ...state,
                applicationProperties: {
                    ...state.applicationProperties,
                    [typedAction.applicationProperty.uuid]: dehydrateApplicationProperty(typedAction.applicationProperty),
                },
                applicationPropertyUuids: {
                    ...state.applicationPropertyUuids,
                    [typedAction.applicationProperty.applicationUuid]: _.uniq([...(applicationPropertyUuids[typedAction.applicationProperty.applicationUuid] || []), typedAction.applicationProperty.uuid]),
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationPropertyValuerMarkPaid: {
            const typedAction: IApplicationPropertyValuerMarkPaidAction = action as IApplicationPropertyValuerMarkPaidAction;

            return {
                ...state,
                applicationPropertyValuerUnpaidUuids: _.without(state.applicationPropertyValuerUnpaidUuids, typedAction.applicationPropertyValuerUuid),
                applicationPropertyValuers: {
                    ...state.applicationPropertyValuers,
                    [typedAction.applicationPropertyValuerUuid]: {
                        ...state.applicationPropertyValuers[typedAction.applicationPropertyValuerUuid],
                        invoiceStatus: ValuerInvoiceStatusEnum.Paid,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationPropertyValuerSet: {
            const typedAction: IApplicationPropertyValuerSetAction = action as IApplicationPropertyValuerSetAction;

            const applicationPropertyValuerUuids: IDictionary<string[]> = { ...state.applicationPropertyValuerUuids };

            return {
                ...state,
                applicationPropertyValuerUuids: {
                    ...state.applicationPropertyValuerUuids,
                    [typedAction.applicationPropertyUuid]: _.uniq([...(applicationPropertyValuerUuids[typedAction.applicationPropertyUuid] || []), typedAction.valuer.uuid]),
                },
                applicationPropertyValuers: {
                    ...state.applicationPropertyValuers,
                    [typedAction.valuer.uuid]: typedAction.valuer,
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationPropertyValuersSet: {
            const typedAction: IApplicationPropertyValuersSetAction = action as IApplicationPropertyValuersSetAction;

            const applicationPropertyValuerUuids: IDictionary<string[]> = { ...state.applicationPropertyValuerUuids };
            const applicationPropertyValuers: IDictionary<IApplicationPropertyValuer> = { ...state.applicationPropertyValuers };
            typedAction.valuers.forEach((valuer: IApplicationPropertyValuer) => {
                applicationPropertyValuers[valuer.uuid] = valuer;

                applicationPropertyValuerUuids[typedAction.applicationPropertyUuid] = [
                    ...(applicationPropertyValuerUuids[typedAction.applicationPropertyUuid] || []),
                    valuer.uuid,
                ];
            });

            return {
                ...state,
                applicationPropertyValuerUuids: {
                    ...state.applicationPropertyValuerUuids,
                    [typedAction.applicationPropertyUuid]: _.uniq(applicationPropertyValuerUuids[typedAction.applicationPropertyUuid]),
                },
                applicationPropertyValuers,
            };
        }

        case ApplicationsActionsEnum.ApplicationPropertyValuersUnpaidSet: {
            const typedAction: IApplicationPropertyValuersUnpaidSetAction = action as IApplicationPropertyValuersUnpaidSetAction;

            return {
                ...state,
                applicationPropertyValuerUnpaidUuids: _.map(typedAction.valuers, 'uuid'),
                applicationPropertyValuers: _.keyBy(typedAction.valuers, 'uuid'),
            };
        }

        case ApplicationsActionsEnum.ApplicationWarehouseDelete: {
            const typedAction: IApplicationWarehouseDeleteAction = action as IApplicationWarehouseDeleteAction;

            return {
                ...state,
                warehouses: _.omit(state.warehouses, typedAction.applicationWarehouseUuid),
            };
        }

        case ApplicationsActionsEnum.ApplicationWarehouseSet: {
            const typedAction: IApplicationWarehouseSetAction = action as IApplicationWarehouseSetAction;

            return {
                ...state,
                applicationWarehouseUuids: {
                    ...state.applicationWarehouseUuids,
                    [typedAction.warehouse.applicationUuid]: _.uniq([
                        ...state.applicationWarehouseUuids[typedAction.warehouse.applicationUuid],
                        typedAction.warehouse.uuid,
                    ]),
                },
                warehouses: {
                    ...state.warehouses,
                    [typedAction.warehouse.uuid]: dehydrateApplicationWarehouse(typedAction.warehouse),
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationWarehouseValueSet: {
            const typedAction: IApplicationWarehouseValueSetAction = action as IApplicationWarehouseValueSetAction;

            return {
                ...state,
                warehouses: {
                    ...state.warehouses,
                    [typedAction.warehouseUuid]: {
                        ...state.warehouses[typedAction.warehouseUuid],
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationWarehousesSet: {
            const typedAction: IApplicationWarehousesSetAction = action as IApplicationWarehousesSetAction;

            const applicationWarehouses: IDictionary<IApplicationWarehouse> = { ...state.warehouses };
            const applicationWarehouseUuids: string[] = [];

            typedAction.warehouses.forEach((applicationWarehouse: IApplicationWarehouse) => {
                applicationWarehouses[applicationWarehouse.uuid] = dehydrateApplicationWarehouse(applicationWarehouse);
                applicationWarehouseUuids.push(applicationWarehouse.uuid);
            });

            return {
                ...state,
                applicationWarehouseUuids: {
                    ...state.applicationWarehouseUuids,
                    [action.applicationUuid]: applicationWarehouseUuids,
                },
                warehouses: applicationWarehouses,
            };
        }

        case BrokersActionsEnum.BrokerApplicationsSet: {
            const typedAction: IBrokerApplicationsSetAction = action as IBrokerApplicationsSetAction;

            const applications: IDictionary<IApplication> = {};
            const borrowers: IDictionary<IDictionary<IBorrower>> = {};
            const dashboardUuids: string[] = [];
            const dealApplicationUuids: IDictionary<string[]> = {};

            typedAction.applications.forEach((application: IApplication) => {
                const applicationBorrowers: IDictionary<IBorrower> = {};
                application.borrowers.forEach((borrower: IBorrower) => {
                    applicationBorrowers[borrower.uuid] = dehydrateApplicationBorrower(borrower);
                });
                borrowers[application.uuid] = applicationBorrowers;

                applications[application.uuid] = dehydrateApplication(application);

                if (!application.closeReason && dashboardVisibleStatuses.includes(application.workflowStatus)) {
                    dashboardUuids.push(application.uuid);
                }

                if (!dealApplicationUuids[application.dealUuid]) {
                    dealApplicationUuids[application.dealUuid] = [];
                }
                dealApplicationUuids[application.dealUuid].push(application.uuid);
            });

            return {
                ...state,
                applications,
                borrowers,
                dashboardUuids,
                dealApplicationUuids,
            };
        }

        case LoansActionsEnum.LoanSet: {
            const typedAction: ILoanSetAction = action as ILoanSetAction;

            return {
                ...state,
                ...statifyLoan(state, typedAction.loan),
            };
        }

        case LoansActionsEnum.LoansSearchResultsSet: {
            const typedAction: ILoansSearchResultsSetAction = action as ILoansSearchResultsSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        case LoansActionsEnum.LoansSet: {
            const typedAction: ILoansSetAction = action as ILoansSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        case LoansActionsEnum.LoansDrawdownsSet: {
            const typedAction: ILoansDrawdownsSetAction = action as ILoansDrawdownsSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        case LoansActionsEnum.LoansPaginatedSet: {
            const typedAction: ILoansPaginatedSetAction = action as ILoansPaginatedSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerApplicationsSet: {
            const typedAction: IReferralPartnerApplicationsSetAction = action as IReferralPartnerApplicationsSetAction;

            const applications: IDictionary<IApplication> = { ...state.applications };
            const borrowers: IDictionary<IDictionary<IBorrower>> = { ...state.borrowers };
            const dealApplicationUuids: IDictionary<string[]> = { ...state.dealApplicationUuids };

            typedAction.applications.forEach((application: IApplication) => {
                const applicationBorrowers: IDictionary<IBorrower> = {};
                application.borrowers.forEach((borrower: IBorrower) => {
                    applicationBorrowers[borrower.uuid] = dehydrateApplicationBorrower(borrower);
                });
                borrowers[application.uuid] = applicationBorrowers;

                applications[application.uuid] = dehydrateApplication(application);

                if (!dealApplicationUuids[application.dealUuid]) {
                    dealApplicationUuids[application.dealUuid] = [];
                }
                dealApplicationUuids[application.dealUuid].push(application.uuid);
            });

            return {
                ...state,
                applications,
                borrowers,
                dealApplicationUuids,
            };
        }

        case WarehousesActionsEnum.WarehouseLoansSet: {
            const typedAction: IWarehouseLoansSetAction = action as IWarehouseLoansSetAction;
            let clonedState: IApplicationsState = { ...state };

            _.each(typedAction.warehouseLoans, (warehouseLoan: IWarehouseLoan) => {
                clonedState = statifyLoan(clonedState, warehouseLoan.loan);
            });

            return clonedState;
        }

        case WarehousesActionsEnum.WarehousePendingApplicationsSet: {
            const typedAction: IWarehousePendingApplicationsSetAction = action as IWarehousePendingApplicationsSetAction;

            const applicationWarehouses: IDictionary<IApplicationWarehouse> = { ...state.warehouses };
            const pendingWarehouseUuids: string[] = [];
            const newApplications: IApplication[] = [];

            typedAction.applicationWarehouses.forEach((applicationWarehouse: IApplicationWarehouse) => {
                applicationWarehouses[applicationWarehouse.uuid] = dehydrateApplicationWarehouse(applicationWarehouse);
                pendingWarehouseUuids.push(applicationWarehouse.uuid);
                newApplications.push(applicationWarehouse.application);
            });

            return {
                ...state,
                ...statifyApplications(state, newApplications),
                pendingWarehouseUuids: {
                    ...state.pendingWarehouseUuids,
                    [typedAction.warehouseUuid]: pendingWarehouseUuids,
                },
                warehouses: applicationWarehouses,
            };
        }

        case WarehousesActionsEnum.WarehouseEligibleLoansSet: {
            const typedAction: IWarehouseEligibleLoansSetAction = action as IWarehouseEligibleLoansSetAction;

            return {
                ...state,
                ...statifyLoans(state, _.map(typedAction.eligibleLoans, (eligibleLoan: IWarehouseEligibleLoan) => eligibleLoan.loan)),
            };
        }

        default:
            return state;
    }
}
