import React, { ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from '~Layout';
import InvestorMovements from './InvestorMovements';
import LoanPayments from './LoanPayments';
import PaymentsFromInvestors from './PaymentsFromInvestors';
import PaymentsToInvestors from './PaymentsToInvestors';
import WarehouseMovements from './WarehouseMovements';

export default function Router(): ReactElement {
    return (
        <Layout topSection='accounts'>
            <Switch>
                <Route exact={true} path='/accounts/investor-movements' component={InvestorMovements} />
                <Route exact={true} path='/accounts/loan-payments' component={LoanPayments} />
                <Route exact={true} path='/accounts/payments-from-investors' component={PaymentsFromInvestors} />
                <Route exact={true} path='/accounts/payments-to-investors' component={PaymentsToInvestors} />
                <Route exact={true} path='/accounts/warehouse-movements' component={WarehouseMovements} />

                <Redirect to='/accounts/investor-movements' />
            </Switch>
        </Layout>
    );
}
