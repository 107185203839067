import { Action } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IDeal from '~Api/Deal/IDeal';
import IDocument from '~Api/ReferralPartner/IDocument';
import IHistory from '~Api/ReferralPartner/IHistory';
import INote from '~Api/ReferralPartner/INote';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import ReferralPartnersActionsEnum from '~ReferralPartners/ActionsEnum';

export interface IReferralPartnerApproveAction extends Action {
    referralPartnerUuid: string;
}

export function referralPartnerApproveAction(referralPartnerUuid: string): IReferralPartnerApproveAction {
    return {
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerApprove,
    };
}

export interface IReferralPartnerGetAction extends Action {
    referralPartnerUuid: string;
}

export function referralPartnerGetAction(referralPartnerUuid: string): IReferralPartnerGetAction {
    return {
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerGet,
    };
}

export interface IReferralPartnerRejectAction extends Action {
    referralPartnerUuid: string;
}

export function referralPartnerRejectAction(referralPartnerUuid: string): IReferralPartnerRejectAction {
    return {
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerReject,
    };
}

export interface IReferralPartnerResetAction extends Action {
    referralPartnerUuid: string;
}

export function referralPartnerResetAction(referralPartnerUuid: string): IReferralPartnerResetAction {
    return {
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerReset,
    };
}

export interface IReferralPartnerSetAction extends Action {
    referralPartner: IReferralPartner;
}

export function referralPartnerSetAction(referralPartner: IReferralPartner): IReferralPartnerSetAction {
    return {
        referralPartner,
        type: ReferralPartnersActionsEnum.ReferralPartnerSet,
    };
}

export interface IReferralPartnerNotesAddAction extends Action {
    referralPartnerUuid: string;
    note: string;
}

export function referralPartnerNotesAddAction(referralPartnerUuid: string, note: string): IReferralPartnerNotesAddAction {
    return {
        note,
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerNotesAdd,
    };
}

export interface IReferralPartnerNotesListAction extends Action {
    referralPartnerUuid: string;
}

export function referralPartnerNotesListAction(referralPartnerUuid: string): IReferralPartnerNotesListAction {
    return {
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerNotesList,
    };
}

export interface IReferralPartnerNotesSetAction extends Action {
    referralPartnerUuid: string;
    notes: INote[];
}

export function referralPartnerNotesSetAction(referralPartnerUuid: string, notes: INote[]): IReferralPartnerNotesSetAction {
    return {
        notes,
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerNotesSet,
    };
}

export interface IReferralPartnerNoteRemoveAction extends Action {
    referralPartnerUuid: string;
    noteUuid: string;
}

export function referralPartnerNoteRemoveAction(referralPartnerUuid: string, noteUuid: string): IReferralPartnerNoteRemoveAction {
    return {
        noteUuid,
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerNoteRemove,
    };
}

export interface IReferralPartnerNoteSetAction extends Action {
    referralPartnerUuid: string;
    note: INote;
}

export function referralPartnerNoteSetAction(referralPartnerUuid: string, note: INote): IReferralPartnerNoteSetAction {
    return {
        note,
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerNoteSet,
    };
}

export interface IReferralPartnerDealsListAction extends Action {
    referralPartnerUuid: string;
}

export function referralPartnerDealsListAction(referralPartnerUuid: string): IReferralPartnerDealsListAction {
    return {
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerDealsList,
    };
}

export interface IReferralPartnerDealsSetAction extends Action {
    deals: IDeal[];
    referralPartnerUuid: string;
}

export function referralPartnerDealsSetAction(referralPartnerUuid: string, deals: IDeal[]): IReferralPartnerDealsSetAction {
    return {
        deals,
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerDealsSet,
    };
}

export interface IReferralPartnerApplicationsListAction extends Action {
    referralPartnerUuid: string;
}

export function referralPartnerApplicationsListAction(referralPartnerUuid: string): IReferralPartnerApplicationsListAction {
    return {
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerApplicationsList,
    };
}

export interface IReferralPartnerApplicationsSetAction extends Action {
    applications: IApplication[];
    referralPartnerUuid: string;
}

export function referralPartnerApplicationsSetAction(referralPartnerUuid: string, applications: IApplication[]): IReferralPartnerApplicationsSetAction {
    return {
        applications,
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerApplicationsSet,
    };
}

export interface IReferralPartnerDocumentSetAction extends Action {
    document: IDocument;
    referralPartnerUuid: string;
}

export interface IReferralPartnerSendAction extends Action {
    referralPartnerUuid: string;
}

export function referralPartnerSendAction(referralPartnerUuid: string): IReferralPartnerSendAction {
    return {
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerSend,
    };
}

export interface IReferralPartnerValueSetAction extends Action {
    key: keyof IReferralPartner;
    referralPartnerUuid: string;
    value: any;
}

export function referralPartnerValueSetAction(referralPartnerUuid: string, key: keyof IReferralPartner, value: any): IReferralPartnerValueSetAction {
    return {
        key,
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerValueSet,
        value,
    };
}

export function referralPartnerDocumentSetAction(referralPartnerUuid: string, document: IDocument): IReferralPartnerDocumentSetAction {
    return {
        document,
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerDocumentSet,
    };
}

export interface IReferralPartnerDocumentDeleteAction extends Action {
    documentUuid: string;
    referralPartnerUuid: string;
}

export function referralPartnerDocumentDeleteAction(referralPartnerUuid: string, documentUuid: string): IReferralPartnerDocumentDeleteAction {
    return {
        documentUuid,
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerDocumentDelete,
    };
}

export interface IReferralPartnerDocumentRemoveAction extends Action {
    documentUuid: string;
    referralPartnerUuid: string;
}

export function referralPartnerDocumentRemoveAction(referralPartnerUuid: string, documentUuid: string): IReferralPartnerDocumentRemoveAction {
    return {
        documentUuid,
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerDocumentRemove,
    };
}

export interface IReferralPartnerDocumentsAddAction extends Action {
    file: File;
    referralPartnerUuid: string;
}

export function referralPartnerDocumentsAddAction(referralPartnerUuid: string, file: File): IReferralPartnerDocumentsAddAction {
    return {
        file,
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerDocumentsAdd,
    };
}

export interface IReferralPartnerDocumentsListAction extends Action {
    referralPartnerUuid: string;
}

export function referralPartnerDocumentsListAction(referralPartnerUuid: string): IReferralPartnerDocumentsListAction {
    return {
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerDocumentsList,
    };
}

export interface IReferralPartnerDocumentsSetAction extends Action {
    documents: IDocument[];
    referralPartnerUuid: string;
}

export function referralPartnerDocumentsSetAction(referralPartnerUuid: string, documents: IDocument[]): IReferralPartnerDocumentsSetAction {
    return {
        documents,
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerDocumentsSet,
    };
}

export interface IReferralPartnerHistoriesListAction extends Action {
    referralPartnerUuid: string;
}

export function referralPartnerHistoriesListAction(referralPartnerUuid: string): IReferralPartnerHistoriesListAction {
    return {
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerHistoriesList,
    };
}

export interface IReferralPartnerHistoriesSetAction extends Action {
    histories: IHistory[];
    referralPartnerUuid: string;
}

export function referralPartnerHistoriesSetAction(referralPartnerUuid: string, histories: IHistory[]): IReferralPartnerHistoriesSetAction {
    return {
        histories,
        referralPartnerUuid,
        type: ReferralPartnersActionsEnum.ReferralPartnerHistoriesSet,
    };
}

export interface IReferralPartnersAddAction extends Action {
    referralPartner: IReferralPartner;
}

export function referralPartnersAddAction(referralPartner: IReferralPartner): IReferralPartnersAddAction {
    return {
        referralPartner,
        type: ReferralPartnersActionsEnum.ReferralPartnersAdd,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IReferralPartnersListAction extends Action {}

export function referralPartnersListAction(): IReferralPartnersListAction {
    return {
        type: ReferralPartnersActionsEnum.ReferralPartnersList,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IReferralPartnersSetAction extends Action {
    referralPartners: IReferralPartner[];
}

export function referralPartnersSetAction(referralPartners: IReferralPartner[]): IReferralPartnersSetAction {
    return {
        referralPartners,
        type: ReferralPartnersActionsEnum.ReferralPartnersSet,
    };
}
