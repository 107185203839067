import { parseInvestorAccountTransaction } from '~Api/Investor/parsers';
import IHistory from './IHistory';
import IWithdrawalAba from './IWithdrawalAba';
import IWithdrawalAbaInvestorTransaction from './IWithdrawalAbaInvestorTransaction';

export function parseWithdrawalAba(rawWithdrawalAba: any): IWithdrawalAba {
    return {
        accountType: rawWithdrawalAba.accountType,
        createdTime: rawWithdrawalAba.createdTime,
        filename: rawWithdrawalAba.filename,
        transactionAmountTotal: rawWithdrawalAba.transactionAmountTotal,
        transactionCount: rawWithdrawalAba.transactionCount,
        uuid: rawWithdrawalAba.uuid,
    };
}

export function paseWithdrawalAbaHistory(rawHistory: any): IHistory {
    return {
        createdTime: rawHistory.createdTime,
        description: rawHistory.description,
        uuid: rawHistory.uuid,
    };
}

export function parseWithdrawalAbaInvestorTransaction(rawWithdrawalAbaInvestorAccount: any): IWithdrawalAbaInvestorTransaction {
    return {
        createdTime: rawWithdrawalAbaInvestorAccount.createdTime,
        investorAccountTransaction: parseInvestorAccountTransaction(rawWithdrawalAbaInvestorAccount.investorAccountTransaction),
        uuid: rawWithdrawalAbaInvestorAccount.uuid,
        withdrawalAba: parseWithdrawalAba(rawWithdrawalAbaInvestorAccount.withdrawalAba),
    };
}

