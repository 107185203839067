import { Modal, Spin, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IIncomeTrustTransaction from '~Api/IncomeTrust/IIncomeTrustTransaction';
import IInvestorIncomeTrustTransaction from '~Api/IncomeTrust/IInvestorIncomeTrustTransaction';
import { investmentIncomeTrustTransactionInvestorsListAction } from '~Investments/actions';
import { incomeTrustTransactionInvestorsSelector } from '~Investments/selectors';
import { IGlobalState } from '~reducer';
import { currencyFormatter } from '~utilities/formatters';

interface IProps {
    transaction: IIncomeTrustTransaction;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsSelector {
    investorTransactions: IInvestorIncomeTrustTransaction[];
}

interface IPropsDispatch {
    investorTransactionsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class TransactionModal extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.summary = this.summary.bind(this);
    }

    public componentDidMount(): void {
        const { investorTransactions } = this.props;

        if (!investorTransactions) {
            this.props.investorTransactionsList();
        }
    }

    public render(): JSX.Element {
        const { investorTransactions, isOpen } = this.props;

        if (!investorTransactions) {
            return (
                <Modal
                    footer={false}
                    onCancel={this.props.onCancel}
                    open={isOpen}
                    title='Transaction Details'
                    width={1000}
                >
                    <Spin/>
                </Modal>
            );
        }

        const columns: ColumnType<IInvestorIncomeTrustTransaction>[] = [
            {
                defaultSortOrder: 'ascend',
                render: (investorIncomeTrustTransaction: IInvestorIncomeTrustTransaction) => <Link to={`/investors/${investorIncomeTrustTransaction.investorUuid}`}>{investorIncomeTrustTransaction.investorName}</Link>,
                sorter: (a: IInvestorIncomeTrustTransaction, b: IInvestorIncomeTrustTransaction) => {
                    return a.investorName.localeCompare(b.investorName);
                },
                title: 'Investor',
            },
            {
                dataIndex: 'interestAmount',
                render: (interestAmount: number) => currencyFormatter.format(interestAmount),
                sorter: (a: IInvestorIncomeTrustTransaction, b: IInvestorIncomeTrustTransaction) => {
                    return a.interestAmount > b.interestAmount ? 1 : -1;
                },
                title: 'Interest',
                width: '20%',
            },
        ];

        return (
            <Modal
                footer={false}
                onCancel={this.props.onCancel}
                open={isOpen}
                title='Transaction Details'
                width={1000}
            >
                <Table
                    columns={columns}
                    dataSource={_.values(investorTransactions)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                    summary={this.summary}
                />
            </Modal>
        );
    }

    private summary(): JSX.Element {
        const { investorTransactions } = this.props;

        let investorCount: number = 0;
        let interestTotal: number = 0;

        _.forEach(investorTransactions, (investorTransaction: IInvestorIncomeTrustTransaction) => {
            investorCount++;
            interestTotal += investorTransaction.interestAmount;
        });

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}><strong>{investorCount}</strong></Table.Summary.Cell>
                <Table.Summary.Cell index={1}><strong>{currencyFormatter.format(interestTotal)}</strong></Table.Summary.Cell>
            </Table.Summary.Row>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investorTransactions: incomeTrustTransactionInvestorsSelector(state, ownProps.transaction.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investorTransactionsList: () => dispatch(investmentIncomeTrustTransactionInvestorsListAction(ownProps.transaction.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TransactionModal);
