import { Breadcrumb, Layout, Space, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IDeal from '~Api/Deal/IDeal';
import ILoan from '~Api/Loan/ILoan';
import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import WorkflowStatusEnum from '~Api/Loan/WorkflowStatusEnum';
import { applicationsListAction } from '~Applications/actions';
import { applicationsSelector } from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import { loansListAction } from './actions';
import { loansSelector } from './selectors';
import { ColumnType } from 'antd/lib/table';
import { IDictionary } from '~utilities/IDictionary';

const loanWorkflowStatusLabels: { [workflowStatus: string]: string } = {
    [WorkflowStatusEnum.Active]: 'Maturation',
    [WorkflowStatusEnum.Discharge]: 'Discharge',
    [WorkflowStatusEnum.Extension]: 'Extension',
};

interface IDay {
    details: IDetail[];
    date: string;
    inTotal: number;
    outTotal: number;
    uuid: string;
}

interface IDetail {
    application?: IApplication;
    loan?: ILoan;
}

interface IPropsSelector {
    applications: IApplication[];
    loans: { [loanUuid: string]: ILoan };
}

interface IPropsDispatch {
    applicationsList: () => void;
    loansList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Forecast extends React.Component<Props> {
    public componentDidMount() {
        this.props.applicationsList();
        this.props.loansList();
    }

    public render(): JSX.Element {
        const { applications, loans } = this.props;

        if (!applications || !loans) {
            return null;
        }

        const days: IDictionary<IDay> = {};

        for (let i: number = 0; i < 30; i++) {
            const loopDate: dayjs.Dayjs = dayjs().add(i, 'days');
            days[loopDate.format('YYYY-MM-DD')] = {
                date: loopDate.format('YYYY-MM-DD'),
                details: [],
                inTotal: 0,
                outTotal: 0,
                uuid: loopDate.format('YYYY-MM-DD'),
            };
        }

        applications.forEach((application: IApplication) => {
            if (!application.settlementDate || !days[application.settlementDate]) {
                return;
            }

            const deal: IDeal = application.deal;

            if (!deal || deal.closedTime) {
                return;
            }

            days[application.settlementDate].outTotal += application.loanAmount;
            days[application.settlementDate].details.push({ application });
        });

        _.each(loans, (loan: ILoan) => {
            if (![LoanStatusEnum.ActiveBadStanding, LoanStatusEnum.ActiveGoodStanding].includes(loan.status)) {
                return;
            }

            switch (loan.workflowStatus) {
                case WorkflowStatusEnum.Active:
                case WorkflowStatusEnum.GracePeriod:
                    if (!days[loan.endDate]) {
                        return;
                    }
                    days[loan.endDate].inTotal += loan.amount;
                    days[loan.endDate].details.push({ loan });
                    break;
                case WorkflowStatusEnum.Discharge:
                    if (!days[loan.dischargeDate]) {
                        return;
                    }
                    days[loan.dischargeDate].inTotal += loan.amount;
                    days[loan.dischargeDate].details.push({ loan });
                    break;
                case WorkflowStatusEnum.Extension:
                    if (!days[loan.endDate]) {
                        return;
                    }
                    days[loan.endDate].inTotal += loan.amount;
                    days[loan.endDate].outTotal += loan.amount;
                    days[loan.endDate].details.push({ loan });
                    break;
            }
        });

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnType<IDay>[] = [
            {
                dataIndex: 'date',
                render: (date: string) => dayjs(date).format('D/M'),
                title: 'Date',
                width: '10%',
            },
            {
                dataIndex: 'date',
                render: (date: string) => dayjs(date).format('dddd'),
                title: 'Day',
                width: '15%',
            },
            {
                dataIndex: 'inTotal',
                render: (inTotal: number) => inTotal > 0 ? currencyFormatter.format(inTotal) : '-',
                title: 'In',
                width: '15%',
            },
            {
                dataIndex: 'outTotal',
                render: (outTotal: number) => outTotal > 0 ? currencyFormatter.format(outTotal) : '-',
                title: 'Out',
                width: '15%',
            },
            {
                dataIndex: 'details',
                render: (loopDetails: IDetail[]) => {
                    const detailsBlock: JSX.Element[] = loopDetails.map((loopDetail: IDetail, index: number) => (
                        <div key={index}>
                            {loopDetail.application && <React.Fragment>Application Settlement: <Link to={`/applications/${loopDetail.application.uuid}`}>{loopDetail.application.formattedName}</Link> - {currencyFormatter.format(loopDetail.application.loanAmount)}</React.Fragment>}
                            {loopDetail.loan && <React.Fragment>Loan {loanWorkflowStatusLabels[loopDetail.loan.workflowStatus]}: <Link to={`/loans/${loopDetail.loan.uuid}`}>{loopDetail.loan.contactName}</Link> - {currencyFormatter.format(loopDetail.loan.amount)}</React.Fragment>}
                        </div>
                    ));

                    return (
                        <Space direction='vertical'>
                            {detailsBlock}
                        </Space>
                    );
                },
                title: 'Details',
            },
        ];

        return (
            <Layout className='applications'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/loans'>Loans</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Cash Flow Forecast</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Cash Flow Forecast</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.values(days)}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        applications: applicationsSelector(state),
        loans: loansSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        applicationsList: () => dispatch(applicationsListAction()),
        loansList: () => dispatch(loansListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Forecast);
