import { Spin, Tabs, Typography } from 'antd';
import _ from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import { applicationGetAction } from '~Applications/actions';
import { applicationSelector } from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import PropertyValuation from './PropertyValuation';
import type { Tab } from 'rc-tabs/lib/interface';

export default function PropertyValuations(): ReactElement {
    const {
        activeTab,
        applicationUuid,
    } = useParams<{
        activeTab: string;
        applicationUuid: string;
    }>();

    const application: IApplication = useSelector((state: IGlobalState) => applicationSelector(state, applicationUuid));

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!application) {
            dispatch(applicationGetAction(applicationUuid));
        }
    }, [
        application,
        applicationUuid,
        dispatch,
    ]);

    if (!application) {
        return (
            <Layout applicationUuid={applicationUuid} section='property-valuations'>
                <Spin/>
            </Layout>
        );
    }

    const tabItems: Tab[] = _.map(application.properties, (applicationProperty: IApplicationProperty) => ({
        children: <PropertyValuation application={application} applicationProperty={applicationProperty} />,
        key: applicationProperty.uuid,
        label: <Typography>{applicationProperty.dealProperty.streetAddress}</Typography>,
    }));

    return (
        <Layout applicationUuid={applicationUuid} section='property-valuations'>
            <Typography.Title level={2}>Property Valuations</Typography.Title>
            <Tabs
                defaultActiveKey={activeTab ? activeTab : _.values(application.properties)[0].uuid}
                items={tabItems}
                type='card'
            />
        </Layout>
    );
}
