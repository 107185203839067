import { ForkEffectDescriptor, SimpleEffect, all, call, put, spawn } from '@redux-saga/core/effects';
import { Saga } from 'redux-saga';
import { AccountsSagas } from '~Accounts/sagas';
import { AdministratorsSagas } from '~Administrators/sagas';
import { AdvisersSagas } from '~Advisers/sagas';
import { AggregatorsSagas } from '~Aggregators/sagas';
import { ApplicationsSagas } from '~Applications/sagas';
import { authLogoutAction } from '~Auth/actions';
import { AuthSagas } from '~Auth/sagas';
import { BrokersSagas } from '~Brokers/sagas';
import { CountriesSagas } from '~Countries/sagas';
import { DealsSagas } from '~Deals/sagas';
import { errorSetAction } from '~Error/actions';
import { ErrorSagas } from '~Error/sagas';
import { InvestmentSaleTransfersSagas } from '~InvestmentSaleTransfers/sagas';
import { InvestmentsSagas } from '~Investments/sagas';
import { InvestorsSagas } from '~Investors/sagas';
import { LeadsSagas } from '~Leads/sagas';
import { LoansSagas } from '~Loans/sagas';
import { ReferralPartnersSaga } from '~ReferralPartners/sagas';
import { SolicitorsSagas } from '~Solicitors/sagas';
import { UsersSagas } from '~Users/sagas';
import { ValuersSagas } from '~Valuers/sagas';
import { WarehousesSagas } from '~Warehouses/sagas';
import { WithdrawalAbasSagas } from '~WithdrawalAbas/sagas';

export default function* rootSaga(): Iterator<unknown> {
    const sagas: Saga[] = [
        AccountsSagas,
        AdministratorsSagas,
        AdvisersSagas,
        AggregatorsSagas,
        ApplicationsSagas,
        AuthSagas,
        BrokersSagas,
        CountriesSagas,
        DealsSagas,
        ErrorSagas,
        InvestmentSaleTransfersSagas,
        InvestmentsSagas,
        InvestorsSagas,
        LeadsSagas,
        LoansSagas,
        ReferralPartnersSaga,
        SolicitorsSagas,
        UsersSagas,
        ValuersSagas,
        WarehousesSagas,
        WithdrawalAbasSagas,
    ];

    yield all(
        sagas.map((saga: Saga): SimpleEffect<'FORK', ForkEffectDescriptor<unknown>> => {
            return spawn(function*(): Iterator<unknown> {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e: any) {
                        switch (e.name) {
                            case 'TokenExpiredError':
                                yield put(authLogoutAction(true));
                                break;
                            default:
                                yield put(errorSetAction(`${e}`));
                                throw e;
                        }
                    }
                }
            });
        }),
    );
}
