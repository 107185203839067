import { Button, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { authTokenSelector } from '~Auth/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';

interface IPropsSelector {
    token: string;
}

type Props = IPropsSelector;

class InvestmentsExport extends React.Component<Props> {
    public render(): JSX.Element {
        const { token } = this.props;

        return (
            <Layout section='investments-export'>
                <Typography.Title level={2}>Investments Export</Typography.Title>
                <Button
                    href={`${process.env.API_HOST}/investment-reports/export?token=${token}`}
                    target='_blank'
                    type='primary'
                >Download</Button>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        token: authTokenSelector(state),
    };
}

export default connect(
    mapStateToProps,
    null,
)(InvestmentsExport);
