import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import Borrower from './Borrower';
import Borrowers from './Borrowers';
import Calculator from './Calculator';
import ConditionalAdd from './ConditionalAdd';
import ConditionalEdit from './ConditionalEdit';
import Conditionals from './Conditionals';
import Conditions from './Conditions';
import Disbursements from './Disbursements';
import Documents from './Documents';
import Edit from './Edit';
import Fees from './Fees';
import FormalApprovals from './FormalApprovals';
import History from './History';
import Notes from './Notes';
import Overview from './Overview';
import Properties from './Properties';
import PropertyValuations from './PropertyValuations';
import RequiredInformation from './RequiredInformation';
import SolicitorInstructions from './SolicitorInstructions';
import Underwriting from './Underwriting';
import Warehouses from './Warehouses';
import SolicitorDownloads from './SolicitorDownloads';

export default function Router(): ReactElement {
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);

    return (
        <Switch>
            <Route exact={true} path='/applications/:applicationUuid' component={Overview} />
            <Route exact={true} path='/applications/:applicationUuid/borrowers' component={Borrowers} />
            <Route exact={true} path='/applications/:applicationUuid/borrowers/:applicationBorrowerUuid' component={Borrower} />
            <Route exact={true} path='/applications/:applicationUuid/calculator' component={Calculator} />
            <Route exact={true} path='/applications/:applicationUuid/conditional-approvals' component={Conditionals} />
            <Route exact={true} path='/applications/:applicationUuid/conditional-approvals/add' component={ConditionalAdd} />
            <Route exact={true} path='/applications/:applicationUuid/conditional-approvals/:conditionalUuid/edit' component={ConditionalEdit} />
            <Route exact={true} path='/applications/:applicationUuid/conditions' component={Conditions} />
            <Route exact={true} path='/applications/:applicationUuid/disbursements' component={Disbursements} />
            <Route exact={true} path='/applications/:applicationUuid/documents' component={Documents} />
            <Route exact={true} path='/applications/:applicationUuid/edit' component={Edit} />
            <Route exact={true} path='/applications/:applicationUuid/fees' component={Fees} />
            <Route exact={true} path='/applications/:applicationUuid/formal-approvals' component={FormalApprovals} />
            <Route exact={true} path='/applications/:applicationUuid/history' component={History} />
            <Route exact={true} path='/applications/:applicationUuid/notes' component={Notes} />
            <Route exact={true} path='/applications/:applicationUuid/properties' component={Properties} />
            <Route exact={true} path='/applications/:applicationUuid/required-information' component={RequiredInformation} />
            <Route exact={true} path='/applications/:applicationUuid/solicitor-downloads' component={SolicitorDownloads} />
            <Route exact={true} path='/applications/:applicationUuid/solicitor-instructions' component={SolicitorInstructions} />
            <Route exact={true} path='/applications/:applicationUuid/underwriting' component={Underwriting} />
            {currentUser.permissions.includes(PermissionsEnum.ApplicationWarehouses) && <Route exact={true} path='/applications/:applicationUuid/warehouses' component={Warehouses} />}
            <Route exact={true} path='/applications/:applicationUuid/property-valuations/:activeTab?' component={PropertyValuations} />
        </Switch>
    );
}
