import { Card as AntCard, Breadcrumb, Button, Layout, Space, Spin, Typography } from 'antd';
import _ from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import WorkflowStatusEnum from '~Api/Investment/WorkflowStatusEnum';
import { IDictionary } from '~utilities/IDictionary';
import { investmentsDashboardListAction } from './actions';
import './investments.less';
import Search from './Search';
import { investmentsDashboardSelector } from './selectors';
import { currencyFormatter } from '~utilities/formatters';
import Card from './Investment/Card';

const statusNames: IDictionary<string> = {
    [WorkflowStatusEnum.Ready]: 'Ready',
    [WorkflowStatusEnum.Live]: 'Live',
    [WorkflowStatusEnum.Funded]: 'Funded',
    [WorkflowStatusEnum.GracePeriod]: 'Grace Period',
    [WorkflowStatusEnum.Repaid]: 'Recently Repaid',
};

export default function Dashboard(): ReactElement {
    const investments: IDictionary<IInvestment> = useSelector(investmentsDashboardSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        dispatch(investmentsDashboardListAction());

        const refreshInterval: NodeJS.Timeout = setInterval(() => {
            dispatch(investmentsDashboardListAction());
        }, 5 * 60 * 1000);

        return () => {
            clearInterval(refreshInterval);
        };
    }, []);

    if (!investments) {
        return (
            <Layout className='investments'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Investments</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Search/>
                            <Link to='/investments/list'><Button>List View</Button></Link>
                        </Space>
                        <Typography.Title level={2}>Investments</Typography.Title>
                        <Spin/>
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    const columns: IDictionary<IInvestment[]> = {
        [WorkflowStatusEnum.Ready]: [],
        [WorkflowStatusEnum.Live]: [],
        [WorkflowStatusEnum.Funded]: [],
        [WorkflowStatusEnum.GracePeriod]: [],
        [WorkflowStatusEnum.Repaid]: [],
    };
    const statusAmounts: IDictionary<number> = {
        [WorkflowStatusEnum.Ready]: 0,
        [WorkflowStatusEnum.Live]: 0,
        [WorkflowStatusEnum.Funded]: 0,
        [WorkflowStatusEnum.GracePeriod]: 0,
        [WorkflowStatusEnum.Repaid]: 0,
    };

    _.reverse(_.sortBy(investments, ['createdTime'])).forEach((investment: IInvestment) => {
        if (!statusNames[investment.workflowStatus]) {
            return;
        }

        columns[investment.workflowStatus].push(investment);

        if ([WorkflowStatusEnum.Live, WorkflowStatusEnum.Ready].includes(investment.workflowStatus)) {
            statusAmounts[investment.workflowStatus] += investment.amountRemaining;
        } else {
            statusAmounts[investment.workflowStatus] += investment.amountTotal;
        }
    });

    const columnsBlock: JSX.Element[] = _.keys(statusNames).map((status: string) => {
        const amountBlock: JSX.Element = (
            <div className='amount'>
                {columns[status] ? columns[status].length : 0} - {currencyFormatter.format(statusAmounts[status] || 0)}
            </div>
        );

        return (
            <AntCard key={status} title={<>{statusNames[status]}{amountBlock}</>}>
                <Space direction='vertical'>
                    {_.map(columns[status], (investment: IInvestment): ReactElement => <Card investment={investment} key={investment.uuid} />)}
                </Space>
            </AntCard>
        );
    });

    return (
        <Layout className='investments'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Investments</Breadcrumb.Item>
            </Breadcrumb>
            <Layout className='content-wrapper'>
                <Layout.Content className='content'>
                    <Space className='actions'>
                        <Search/>
                        <Link to='/investments/list'><Button>List View</Button></Link>
                    </Space>
                    <Typography.Title level={2}>Investments</Typography.Title>
                    <Space align='start' className='columns'>
                        {columnsBlock}
                    </Space>
                </Layout.Content>
            </Layout>
        </Layout>
    );
}
