import { Layout as AntLayout, Breadcrumb, Menu } from 'antd';
import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import './accounts.less';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

export default function Layout(props: {
    breadcrumb?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    section: string;
}): ReactElement {
    const { breadcrumb, children, section } = props;

    const menuItems: ItemType[] = [
        {
            key: 'payments-to-investors',
            label: <NavLink to='/accounts/payments-to-investors'>Payments To Investors</NavLink>,
        },
        {
            key: 'payments-from-investors',
            label: <NavLink to='/accounts/payments-from-investors'>Payments From Investors</NavLink>,
        },
        {
            key: 'investor-movements',
            label: <NavLink to='/accounts/investor-movements'>Investor Movements</NavLink>,
        },
        {
            key: 'loan-payments',
            label: <NavLink to='/accounts/loan-payments'>Loan Payments</NavLink>,
        },
        {
            key: 'warehouse-movements',
            label: <NavLink to='/accounts/warehouse-movements'>Warehouse Movements</NavLink>,
        },
    ];

    return (
        <AntLayout className='accounts'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Accounts</Breadcrumb.Item>
                {breadcrumb}
            </Breadcrumb>
            <AntLayout className='content-wrapper'>
                <AntLayout.Sider className='menu' theme='light' width={240}>
                    <Menu
                        defaultSelectedKeys={[section]}
                        items={menuItems}
                        mode='inline'
                    />
                </AntLayout.Sider>
                <AntLayout.Content className={`content ${section}`}>
                    {children}
                </AntLayout.Content>
            </AntLayout>
        </AntLayout>
    );
}
