enum InvestmentsActionsEnum {
    InvestmentGet = 'INVESTMENT/GET',
    InvestmentGracePeriod = 'INVESTMENT/GRACE_PERIOD',
    InvestmentHide = 'INVESTMENT/HIDE',
    InvestmentInterestRate = 'INVESTMENT/INTEREST_RATE',
    InvestmentInvestorEmailSend = 'INVESTMENT/INVESTOR_EMAIL/SEND',
    InvestmentPayoutEmailSend = 'INVESTMENT/PAYOUT_EMAIL/SEND',
    InvestmentPhoto = 'INVESTMENT/PHOTO',
    InvestmentPhotoInProgressSet = 'INVESTMENT/PHOTO/IN_PROGRESS/SET',
    InvestmentSend = 'INVESTMENT/SEND',
    InvestmentSet = 'INVESTMENT/SET',
    InvestmentShow = 'INVESTMENT/SHOW',
    InvestmentValueSet = 'INVESTMENT/VALUE/SET',

    InvestmentHistoriesList = 'INVESTMENT/HISTORIES/LIST',
    InvestmentHistoriesSet = 'INVESTMENT/HISTORIES/SET',

    InvestmentIncomeTrustDistributionsAdd = 'INVESTMENT/INCOME_TRUST/DISTRIBUTIONS/ADD',
    InvestmentIncomeTrustDistributionsList = 'INVESTMENT/INCOME_TRUST/DISTRIBUTIONS/LIST',
    InvestmentIncomeTrustDistributionsSet = 'INVESTMENT/INCOME_TRUST/DISTRIBUTIONS/SET',

    InvestmentIncomeTrustInvestmentRequestCancel = 'INVESTMENT/INCOME_TRUST_INVESTMENT_REQUEST/CANCEL',
    InvestmentIncomeTrustInvestmentRequestProcess = 'INVESTMENT/INCOME_TRUST_INVESTMENT_REQUEST/PROCESS',
    InvestmentIncomeTrustInvestmentRequestsPendingList = 'INVESTMENT/INCOME_TRUST_INVESTMENT_REQUESTS/PENDING/LIST',
    InvestmentIncomeTrustInvestmentRequestsPendingSet = 'INVESTMENT/INCOME_TRUST_INVESTMENT_REQUESTS/PENDING/SET',

    InvestmentIncomeTrustTransactionsList = 'INVESTMENT/INCOME_TRUST/TRANSACTIONS/LIST',
    InvestmentIncomeTrustTransactionsSet = 'INVESTMENT/INCOME_TRUST/TRANSACTIONS/SET',

    InvestmentIncomeTrustTransactionInvestorsList = 'INVESTMENT/INCOME_TRUST/TRANSACTION/INVESTORS/LIST',
    InvestmentIncomeTrustTransactionInvestorsSet = 'INVESTMENT/INCOME_TRUST/TRANSACTION/INVESTORS/SET',

    InvestmentInvestorsList = 'INVESTMENT/INVESTORS/LIST',
    InvestmentInvestorsSet = 'INVESTMENT/INVESTORS/SET',

    InvestmentPendingDistributionsList = 'INVESTMENT/PENDING_DISTRIBUTIONS/LIST',
    InvestmentPendingDistributionsSet = 'INVESTMENT/PENDING_DISTRIBUTIONS/SET',

    InvestmentPendingDistributionProcess = 'INVESTMENT/PENDING_DISTRIBUTION/PROCESS',

    InvestmentReservationProcess = 'INVESTMENT/RESERVATION/PROCESS',

    InvestmentReservationsList = 'INVESTMENT/RESERVATIONS/LIST',
    InvestmentReservationsSet = 'INVESTMENT/RESERVATIONS/SET',

    InvestmentTransactionsList = 'INVESTMENT/TRANSACTIONS/LIST',
    InvestmentTransactionsSet = 'INVESTMENT/TRANSACTIONS/SET',

    InvestmentTransactionGet = 'INVESTMENT/TRANSACTION/GET',
    InvestmentTransactionSet = 'INVESTMENT/TRANSACTION/SET',

    InvestmentTransactionInvestorsList = 'INVESTMENT/TRANSACTION/INVESTORS/LIST',
    InvestmentTransactionInvestorsSet = 'INVESTMENT/TRANSACTION/INVESTORS/SET',

    InvestmentsDashboardList = 'INVESTMENTS/DASHBOARD_LIST',
    InvestmentsDashboardSet = 'INVESTMENTS/DASHBOARD_SET',

    InvestmentsList = 'INVESTMENTS/LIST',
    InvestmentsSet = 'INVESTMENTS/SET',

    InvestmentsSearch = 'INVESTMENTS/SEARCH',
    InvestmentsSearchResultsSet = 'INVESTMENTS/SEARCH/RESULTS/SET',
    InvestmentsSearchResultsClear = 'INVESTMENTS/SEARCH/RESULTS/CLEAR',
}

export default InvestmentsActionsEnum;
