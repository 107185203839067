import { Button, Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplicationFee from '~Api/Application/IApplicationFee';
import { applicationFeeValueSetAction } from '~Applications/actions';
import { applicationFeeSelector } from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

interface IDefaultedValues {
    amount: number;
}

interface IState {
    amount: number;
    dirtyFields: IDictionary<boolean>;
}

interface IProps {
    feeUuid: string;
    isOpen: boolean;
    applicationUuid: string;
    onClose: () => void;
}

interface IPropsSelector {
    fee: IApplicationFee;
}

interface IPropsDispatch {
    update: (key: keyof IApplicationFee, value: boolean|number|string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class FeeEditModal extends React.Component<Props> {
    public state: IState = {
        amount: null,
        dirtyFields: {},
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { fee, isOpen } = this.props;

        if (!fee) {
            return null;
        }

        const { amount } = this.getDefaultedValues();

        const footer: JSX.Element = (
            <React.Fragment>
                <Button className='close' onClick={this.props.onClose}>Close</Button>
            </React.Fragment>
        );

        return (
            <Modal
                destroyOnClose={true}
                footer={footer}
                okText='Close'
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title='Edit Fee/Outlay'
                wrapClassName='application-fee-edit-modal'
            >
                <Form.Item label='Amount' className='amount'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeAmount} type='number' value={amount} placeholder='0' />
                </Form.Item>
                <Form.Item label='Description' className='description'>
                    <Input maxLength={255} onChange={this.onChangeDescription} value={fee.description} />
                </Form.Item>
            </Modal>
        );
    }

    private getDefaultedValues(): IDefaultedValues {
        const { fee } = this.props;
        const { dirtyFields, amount } = this.state;

        return {
            amount: dirtyFields.amount ? amount : fee.amount !== 0 ? fee.amount : null,
        };
    }

    private onChangeAmount(event: React.ChangeEvent<HTMLInputElement>) {
        const { dirtyFields } = this.state;

        this.setState({
            amount: event.target.value ? event.target.valueAsNumber : null,
            dirtyFields: {
                ...dirtyFields,
                amount: true,
            },
        });
        this.props.update('amount', event.target.value ? event.target.valueAsNumber : 0);
    }

    private onChangeDescription(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.update('description', event.target.value);
    }

    private onClickOk() {
        this.props.onClose();
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        fee: applicationFeeSelector(state, ownProps.feeUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        update: (key: keyof IApplicationFee, value: boolean|number|string) => dispatch(applicationFeeValueSetAction(ownProps.feeUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FeeEditModal);
