import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '~Layout';
import List from './List';
import ReferralPartner from './ReferralPartner';

export default function Router(): ReactElement {
    return (
        <Layout topSection='leads'>
            <Switch>
                <Route exact={true} path='/referral-partners' component={List} />
                <Route path='/referral-partners/:uuid' component={ReferralPartner} />
            </Switch>
        </Layout>
    );
}
