import { Spin, Table, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import {
    applicationConditionsListAction,
    applicationGetAction,
    applicationRfiItemsListAction,
} from '~Applications/actions';
import {
    applicationConditionsSelector,
    applicationRfiItemsSelector,
    applicationSelector,
} from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import ConditionTypeEnum from '~Api/Application/ConditionTypeEnum';
import { CheckOutlined, WarningOutlined } from '@ant-design/icons';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IBorrower from '~Api/Application/IBorrower';
import { conditionLabels } from '~Applications/utilities';
import { IDictionary } from '~utilities/IDictionary';
import ICondition from '~Api/Application/ICondition';
import IRfiItem from '~Api/Application/IRfiItem';
import RfiWorkflowStatusEnum from '~Api/Application/RfiWorkflowStatusEnum';
import UploadConditionDocument from './UploadConditionDocument';
import UploadedConditionDocumentList from './UploadedConditionDocumentList';

interface IMatch {
    applicationUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    application: IApplication;
    conditions: IDictionary<ICondition>;
    rfiItems: IDictionary<IRfiItem>;
}

interface IPropsDispatch {
    applicationGet: () => void;
    conditionsList: () => void;
    rfiItemsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class RequiredInformation extends React.Component<Props> {
    public componentDidMount() {
        const { application, conditions, rfiItems } = this.props;

        if (!application) {
            this.props.applicationGet();
        }

        if (!conditions) {
            this.props.conditionsList();
        }

        if (!rfiItems) {
            this.props.rfiItemsList();
        }
    }

    public render(): JSX.Element {
        const { application, conditions, match, rfiItems } = this.props;

        if (!application || !conditions || !rfiItems) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='required-information'>
                    <Typography.Title level={2}>Required Information</Typography.Title>
                    <Spin />
                </Layout>
            );
        }

        const columns: ColumnType<IRfiItem>[] = [
            {
                dataIndex: 'workflowStatus',
                render: (workflowStatus: RfiWorkflowStatusEnum): JSX.Element => {
                    if (workflowStatus === RfiWorkflowStatusEnum.Supplied) {
                        return <CheckOutlined/>;
                    }

                    return <WarningOutlined/>;
                },
                title: '',
                width: '5%',
            },
            {
                render: (rfiItem: IRfiItem): string => {
                    if (rfiItem.applicationBorrowerUuid) {
                        const applicationBorrower: IBorrower = _.find(application.borrowers, { uuid: rfiItem.applicationBorrowerUuid });

                        return applicationBorrower.dealBorrower.formattedName;
                    }

                    if (rfiItem.applicationPropertyUuid) {
                        const applicationProperty: IApplicationProperty = _.find(application.properties, { uuid: rfiItem.applicationPropertyUuid });

                        return applicationProperty.dealProperty.formattedAddress;
                    }

                    return 'Application';
                },
                title: 'Entity',
                width: '35%',
            },
            {
                dataIndex: 'conditionType',
                render: (conditionType: ConditionTypeEnum, rfiItem: IRfiItem): string => {
                    if (!conditionType) {
                        return conditions[rfiItem.applicationConditionUuid].name;
                    }

                    return conditionLabels[conditionType];
                },
                title: 'Condition',
            },
            {
                render: (rfiItem: IRfiItem) => {
                    let applicationBorrower: IBorrower = null;
                    let applicationProperty: IApplicationProperty = null;

                    if (rfiItem.applicationBorrowerUuid) {
                        applicationBorrower = _.find(application.borrowers, { uuid: rfiItem.applicationBorrowerUuid });
                    }

                    if (rfiItem.applicationPropertyUuid) {
                        applicationProperty = _.find(application.properties, { uuid: rfiItem.applicationPropertyUuid });
                    }

                    return (
                        <UploadedConditionDocumentList
                            application={application}
                            applicationBorrower={applicationBorrower}
                            applicationProperty={applicationProperty}
                            conditionUuid={rfiItem.applicationConditionUuid}
                            conditionType={rfiItem.conditionType}
                        />
                    );
                },
                title: 'Documents',
                width: '30%',
            },
            {
                render: (rfiItem: IRfiItem) => {
                    let applicationBorrower: IBorrower = null;
                    let applicationProperty: IApplicationProperty = null;

                    if (rfiItem.applicationBorrowerUuid) {
                        applicationBorrower = _.find(application.borrowers, { uuid: rfiItem.applicationBorrowerUuid });
                    }

                    if (rfiItem.applicationPropertyUuid) {
                        applicationProperty = _.find(application.properties, { uuid: rfiItem.applicationPropertyUuid });
                    }

                    return (
                        <UploadConditionDocument
                            application={application}
                            applicationBorrower={applicationBorrower}
                            applicationProperty={applicationProperty}
                            conditionUuid={rfiItem.applicationConditionUuid}
                            conditionType={rfiItem.conditionType}
                        />
                    );
                },
                title: 'Actions',
                width: '10%',
            },
        ];

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='required-information'>
                <Typography.Title level={2}>Required Information</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(rfiItems)}
                    pagination={false}
                    rowKey='uuid'
                />
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
        conditions: applicationConditionsSelector(state, ownProps.match.params.applicationUuid),
        rfiItems: applicationRfiItemsSelector(state, ownProps.match.params.applicationUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
        conditionsList: () => dispatch(applicationConditionsListAction(ownProps.match.params.applicationUuid)),
        rfiItemsList: () => dispatch(applicationRfiItemsListAction(ownProps.match.params.applicationUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RequiredInformation);
