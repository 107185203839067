enum ActionsEnum {
    InvestorMovementsList = 'ACCOUNTS/INVESTOR_MOVEMENTS/LIST',
    InvestorMovementsSet = 'ACCOUNTS/INVESTOR_MOVEMENTS/SET',

    LoanPaymentsList = 'ACCOUNTS/LOAN_PAYMENTS/LIST',
    LoanPaymentsSet = 'ACCOUNTS/LOAN_PAYMENTS/SET',

    LoanTransactionWarehouseLoanTransactionsList = 'ACCOUNTS/LOAN_TRANSACTION/WAREHOUSE_LOAN_TRANSACTIONS/LIST',
    LoanTransactionWarehouseLoanTransactionsSet = 'ACCOUNTS/LOAN_TRANSACTION/WAREHOUSE_LOAN_TRANSACTIONS/SET',

    PaymentsFromInvestorsList = 'ACCOUNTS/PAYMENTS_FROM_INVESTORS/LIST',
    PaymentsFromInvestorsSet = 'ACCOUNTS/PAYMENTS_FROM_INVESTORS/SET',

    PaymentsToInvestorsList = 'ACCOUNTS/PAYMENTS_TO_INVESTORS/LIST',
    PaymentsToInvestorsSet = 'ACCOUNTS/PAYMENTS_TO_INVESTORS/SET',

    WarehouseMovementsList = 'ACCOUNTS/WAREHOUSE_MOVEMENTS/LIST',
    WarehouseMovementsSet = 'ACCOUNTS/WAREHOUSE_MOVEMENTS/SET',
}

export default ActionsEnum;
