import _ from 'lodash';
import { AnyAction } from 'redux';
import IApplication from '~Api/Application/IApplication';
import ApprovalStatusEnum from '~Api/Deal/ApprovalStatusEnum';
import IDeal from '~Api/Deal/IDeal';
import IOriginationReportLead from '~Api/Deal/IOriginationReportLead';
import PhoneSourceEnum from '~Api/Deal/PhoneSourceEnum';
import {
    IApplicationPropertiesValuationsOutstandingSetAction,
    IApplicationSetAction,
    IApplicationsDashboardSetAction,
    IApplicationsOutstandingBrokerCommissionsSetAction,
    IApplicationsSearchResultsSetAction,
    IApplicationsSetAction,
    IApplicationsSettlementForecastSetAction,
} from '~Applications/actions';
import ApplicationsActionsEnum from '~Applications/ActionsEnum';
import { IBrokerDealsSetAction } from '~Brokers/actions';
import BrokersActionsEnum from '~Brokers/ActionsEnum';
import {
    IDealFollowUpTimeAction,
    IDealQuoteApproveAction,
    IDealQuoteRejectAction,
} from '~Deals/actions';
import DealsActionsEnum from '~Deals/ActionsEnum';
import {
    IReferralPartnerApplicationsSetAction,
    IReferralPartnerDealsSetAction,
} from '~ReferralPartners/actions';
import ReferralPartnersActionsEnum from '~ReferralPartners/ActionsEnum';
import { IDictionary } from '~utilities/IDictionary';
import {
    ILeadAddErrorSetAction,
    ILeadBrokerSearchAction,
    ILeadBrokerSearchResultsSetAction,
    ILeadInitialCallAction,
    ILeadQuoteSetAction,
    ILeadQuotesAddAction,
    ILeadQuotesSetAction,
    ILeadReferAction,
    ILeadRejectAction,
    ILeadSetAction,
    ILeadValueSetAction,
    ILeadsBoardSetAction,
    ILeadsMovementReportListAction,
    ILeadsMovementReportSetAction,
    ILeadsOriginationReportListAction,
    ILeadsOriginationReportSetAction,
    ILeadsPendingQuotesSetAction,
    ILeadsSearchResultSetAction,
    ILeadsSetAction,
} from './actions';
import LeadsActionsEnum from './ActionsEnum';
import IApplicationWarehouse from '~Api/Application/IApplicationWarehouse';
import IQuote from '~Api/Deal/IQuote';
import IMovementReportLead from '~Api/Deal/IMovementReportLead';
import { PeriodRangeEnum } from '~utilities/reportUtilities';
import LoansActionsEnum from '~Loans/ActionsEnum';
import {
    ILoanSetAction,
    ILoansPaginatedSetAction,
    ILoansSearchResultsSetAction,
    ILoansSetAction,
} from '~Loans/actions';
import ILoan from '~Api/Loan/ILoan';
import IBroker from '~Api/Broker/IBroker';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import WarehousesActionsEnum from '~Warehouses/ActionsEnum';
import {
    IWarehouseEligibleLoansSetAction,
    IWarehousePendingApplicationsSetAction,
} from '~Warehouses/actions';
import IWarehouseEligibleLoan from '~Api/Warehouse/IWarehouseEligibleLoan';

export interface ILeadAddErrors {
    email: string;
    firstName: string;
    lastName: string;
    loanAmount: string;
    loanPurpose: string;
    otherSource: string;
    phone: string;
    phoneSource: PhoneSourceEnum;
    referralPartner: string;
    termMonths: string;
}

export interface ILeadsState {
    addErrors: ILeadAddErrors;
    deals: IDictionary<IDeal>;
    dashboardUuids: string[];
    leadBrokerSearchLoading: boolean;
    leadBrokerSearchTerm: string;
    leadBrokerSearchUuids: string[];
    leadQuoteUuids: IDictionary<string[]>;
    leadsPaginatedCount: number;
    leadsPaginatedUuids: string[];
    pendingQuoteUuids: string[];
    leadsSearchResultUuids: string[];
    movementReport: {
        endTime: string;
        leads: IDictionary<IMovementReportLead>;
        periodRange: PeriodRangeEnum;
        startTime: string;
    };
    originationReport: {
        endDate: string;
        leads: IDictionary<IOriginationReportLead>;
        periodRange: PeriodRangeEnum;
        startDate: string;
    };
    quotes: IDictionary<IQuote>;
}

const initialData: ILeadsState = {
    addErrors: {
        email: null,
        firstName: null,
        lastName: null,
        loanAmount: null,
        loanPurpose: null,
        otherSource: null,
        phone: null,
        phoneSource: null,
        referralPartner: null,
        termMonths: null,
    },
    dashboardUuids: null,
    deals: null,
    leadBrokerSearchLoading: false,
    leadBrokerSearchTerm: null,
    leadBrokerSearchUuids: null,
    leadQuoteUuids: {},
    leadsPaginatedCount: null,
    leadsPaginatedUuids: null,
    leadsSearchResultUuids: null,
    movementReport: {
        endTime: null,
        leads: null,
        periodRange: null,
        startTime: null,
    },
    originationReport: {
        endDate: null,
        leads: null,
        periodRange: null,
        startDate: null,
    },
    pendingQuoteUuids: null,
    quotes: {},
};

function statifyApplication(state: ILeadsState, application: IApplication): ILeadsState {
    return {
        ...state,
        deals: {
            ...state.deals,
            [application.deal.uuid]: application.deal,
        },
    };
}

function statifyApplications(state: ILeadsState, applications: IApplication[]): ILeadsState {
    let clonedState: ILeadsState = { ...state };

    applications.forEach((application: IApplication) => {
        clonedState = statifyApplication(clonedState, application);
    });

    return clonedState;
}

function statifyLoan(state: ILeadsState, loan: ILoan): ILeadsState {
    let clonedState: ILeadsState = { ...state };

    if (loan.extensionApplication) {
        clonedState = statifyApplication(clonedState, loan.extensionApplication);
    }

    if (loan.application) {
        clonedState = statifyApplication(clonedState, loan.application);
    }

    return clonedState;
}

function statifyLoans(state: ILeadsState, loans: ILoan[]): ILeadsState {
    let clonedState: ILeadsState = { ...state };

    _.each(loans, (loan: ILoan) => {
        clonedState = statifyLoan(clonedState, loan);
    });

    return clonedState;
}

function dehydrateLead(deal: IDeal): IDeal {
    return _.omit(deal, ['broker', 'properties']);
}

export function leadsReducer(state: ILeadsState = initialData, action: AnyAction): ILeadsState {
    switch (action.type) {
        case ApplicationsActionsEnum.ApplicationPropertiesValuationsOutstandingSet: {
            const typedAction: IApplicationPropertiesValuationsOutstandingSetAction = action as IApplicationPropertiesValuationsOutstandingSetAction;

            const deals: IDictionary<IDeal> = {};

            typedAction.applicationProperties.forEach((applicationProperty: IApplicationProperty) => {
                deals[applicationProperty.application.dealUuid] = applicationProperty.application.deal;
            });

            return {
                ...state,
                deals: {
                    ...state.deals,
                    ...deals,
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationsDashboardSet: {
            const typedAction: IApplicationsDashboardSetAction = action as IApplicationsDashboardSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case ApplicationsActionsEnum.ApplicationsSearchResultsSet: {
            const typedAction: IApplicationsSearchResultsSetAction = action as IApplicationsSearchResultsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case ApplicationsActionsEnum.ApplicationsSet: {
            const typedAction: IApplicationsSetAction = action as IApplicationsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case ApplicationsActionsEnum.ApplicationsSettlementForecastSet: {
            const typedAction: IApplicationsSettlementForecastSetAction = action as IApplicationsSettlementForecastSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case ApplicationsActionsEnum.ApplicationSet: {
            const typedAction: IApplicationSetAction = action as IApplicationSetAction;

            return {
                ...state,
                ...statifyApplication(state, typedAction.application),
            };
        }

        case LeadsActionsEnum.LeadsBoardSet: {
            const typedAction: ILeadsBoardSetAction = action as ILeadsBoardSetAction;

            const deals: IDictionary<IDeal> = { ...state.deals };

            typedAction.deals.forEach((deal: IDeal) => {
                deals[deal.uuid] = dehydrateLead(deal);
            });

            return {
                ...state,
                dashboardUuids: typedAction.deals.map((deal: IDeal) => deal.uuid),
                deals,
            };
        }

        case LeadsActionsEnum.LeadsOriginationReportList: {
            const typedAction: ILeadsOriginationReportListAction = action as ILeadsOriginationReportListAction;

            return {
                ...state,
                originationReport: {
                    endDate: typedAction.endDate,
                    leads: null,
                    periodRange: typedAction.periodRange,
                    startDate: typedAction.startDate,
                },
            };
        }

        case LeadsActionsEnum.LeadsOriginationReportSet: {
            const typedAction: ILeadsOriginationReportSetAction = action as ILeadsOriginationReportSetAction;

            return {
                ...state,
                originationReport: {
                    ...state.originationReport,
                    leads: _.keyBy(typedAction.originationReportLeads, 'uuid'),
                },
            };
        }

        case LeadsActionsEnum.LeadsMovementReportList: {
            const typedAction: ILeadsMovementReportListAction = action as ILeadsMovementReportListAction;

            return {
                ...state,
                movementReport: {
                    endTime: typedAction.endTime,
                    leads: null,
                    periodRange: typedAction.periodRange,
                    startTime: typedAction.startTime,
                },
            };
        }

        case LeadsActionsEnum.LeadsMovementReportSet: {
            const typedAction: ILeadsMovementReportSetAction = action as ILeadsMovementReportSetAction;
            const keyedLeads: IDictionary<IMovementReportLead> = {};

            typedAction.movementReportLeads.forEach((lead: IMovementReportLead) => {
                const key: string = lead.dealWorkflowTimeUuid || lead.dealUuid;
                keyedLeads[key] = lead;
            });

            return {
                ...state,
                movementReport: {
                    ...state.movementReport,
                    leads: keyedLeads,
                },
            };
        }

        case LeadsActionsEnum.LeadsList: {
            return {
                ...state,
                leadsPaginatedUuids: null,
            };
        }

        case LeadsActionsEnum.LeadsPendingQuotesSet: {
            const typedAction: ILeadsPendingQuotesSetAction = action as ILeadsPendingQuotesSetAction;
            const quotes: IDictionary<IQuote> = {};
            const deals: IDictionary<IDeal> = {};
            const pendingQuoteUuids: string[] = [];

            _.each(typedAction.quotes, (quote: IQuote) => {
                pendingQuoteUuids.push(quote.uuid);
                quotes[quote.uuid] = quote;
                deals[quote.deal.uuid] = quote.deal;
            });

            return {
                ...state,
                deals: {
                    ...(state.deals || {}),
                    ...deals,
                },
                pendingQuoteUuids,
                quotes: {
                    ...state.quotes,
                    ...quotes,
                },
            };
        }

        case DealsActionsEnum.DealQuoteApprove: {
            const typedAction: IDealQuoteApproveAction = action as IDealQuoteApproveAction;

            return {
                ...state,
                pendingQuoteUuids: state.pendingQuoteUuids ? _.without(state.pendingQuoteUuids, typedAction.quoteUuid) : null,
            };
        }

        case DealsActionsEnum.DealQuoteReject: {
            const typedAction: IDealQuoteRejectAction = action as IDealQuoteRejectAction;

            return {
                ...state,
                pendingQuoteUuids: state.pendingQuoteUuids ? _.without(state.pendingQuoteUuids, typedAction.quoteUuid) : null,
            };
        }

        case LeadsActionsEnum.LeadsSet: {
            const typedAction: ILeadsSetAction = action as ILeadsSetAction;

            const deals: IDictionary<IDeal> = { ...state.deals };
            const leadsPaginatedUuids: string[] = [];

            typedAction.deals.forEach((deal: IDeal) => {
                leadsPaginatedUuids.push(deal.uuid);
                deals[deal.uuid] = dehydrateLead(deal);
            });

            return {
                ...state,
                deals,
                leadsPaginatedCount: typedAction.leadsPaginatedCount,
                leadsPaginatedUuids,
            };
        }

        case LeadsActionsEnum.LeadsSearchResultsSet: {
            const typedAction: ILeadsSearchResultSetAction = action as ILeadsSearchResultSetAction;

            const deals: IDictionary<IDeal> = { ...state.deals };

            const leadsSearchResultUuids: string[] = [];

            typedAction.deals.forEach((deal: IDeal) => {
                deals[deal.uuid] = dehydrateLead(deal);
                leadsSearchResultUuids.push(deal.uuid);
            });

            return {
                ...state,
                deals,
                leadsSearchResultUuids,
            };
        }

        case LeadsActionsEnum.LeadsSearchResultsClear: {
            return {
                ...state,
                leadsSearchResultUuids: [],
            };
        }

        case LeadsActionsEnum.LeadAddErrorSet: {
            const typedAction: ILeadAddErrorSetAction = action as ILeadAddErrorSetAction;

            return {
                ...state,
                addErrors: {
                    ...state.addErrors,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        case LeadsActionsEnum.LeadBrokerSearch: {
            const typedAction: ILeadBrokerSearchAction = action as ILeadBrokerSearchAction;

            return {
                ...state,
                leadBrokerSearchLoading: !!typedAction.keyword,
                leadBrokerSearchTerm: typedAction.keyword,
                leadBrokerSearchUuids: typedAction.keyword ? null : [],
            };
        }

        case LeadsActionsEnum.LeadBrokerSearchResultsClear: {
            return {
                ...state,
                leadBrokerSearchLoading: false,
                leadBrokerSearchTerm: null,
                leadBrokerSearchUuids: null,
            };
        }

        case LeadsActionsEnum.LeadBrokerSearchResultsSet: {
            const typedAction: ILeadBrokerSearchResultsSetAction = action as ILeadBrokerSearchResultsSetAction;

            if (state.leadBrokerSearchTerm !== typedAction.keyword) {
                return { ...state };
            }

            return {
                ...state,
                leadBrokerSearchLoading: false,
                leadBrokerSearchUuids: _.map(typedAction.brokers, (broker: IBroker) => broker.uuid),
            };
        }

        case LeadsActionsEnum.LeadInitialCall: {
            const typedAction: ILeadInitialCallAction = action as ILeadInitialCallAction;

            return {
                ...state,
                deals: {
                    ...state.deals,
                    [typedAction.dealUuid]: {
                        ...state.deals[typedAction.dealUuid],
                        approvalStatus: ApprovalStatusEnum.InitialCall,
                    },
                },
            };
        }

        case LeadsActionsEnum.LeadQuotesAdd: {
            const typedAction: ILeadQuotesAddAction = action as ILeadQuotesAddAction;

            return {
                ...state,
                deals: {
                    ...state.deals,
                    [typedAction.dealUuid]: {
                        ...state.deals[typedAction.dealUuid],
                        approvalStatus: ApprovalStatusEnum.Quote,
                        closeReason: null,
                        closedTime: null,
                    },
                },
            };
        }

        case LeadsActionsEnum.LeadReject: {
            const typedAction: ILeadRejectAction = action as ILeadRejectAction;

            return {
                ...state,
                deals: {
                    ...state.deals,
                    [typedAction.dealUuid]: {
                        ...state.deals[typedAction.dealUuid],
                        approvalStatus: ApprovalStatusEnum.Rejected,
                        rejectReason: typedAction.reason,
                    },
                },
            };
        }

        case LeadsActionsEnum.LeadRefer: {
            const typedAction: ILeadReferAction = action as ILeadReferAction;

            return {
                ...state,
                deals: {
                    ...state.deals,
                    [typedAction.dealUuid]: {
                        ...state.deals[typedAction.dealUuid],
                        approvalStatus: ApprovalStatusEnum.Referred,
                        referredTo: typedAction.referredTo,
                    },
                },
            };
        }

        case LeadsActionsEnum.LeadSet: {
            const typedAction: ILeadSetAction = action as ILeadSetAction;

            return {
                ...state,
                deals: {
                    ...state.deals,
                    [typedAction.deal.uuid]: dehydrateLead(typedAction.deal),
                },
            };
        }

        case LeadsActionsEnum.LeadQuotesSet: {
            const typedAction: ILeadQuotesSetAction = action as ILeadQuotesSetAction;
            const quotes: IDictionary<IQuote> = {};
            const quoteUuids: string[] = [];

            _.each(typedAction.quotes, (quote: IQuote) => {
                quotes[quote.uuid] = quote;
                quoteUuids.push(quote.uuid);
            });

            return {
                ...state,
                leadQuoteUuids: {
                    ...state.leadQuoteUuids,
                    [typedAction.dealUuid]: quoteUuids,
                },
                quotes: {
                    ...state.quotes,
                    ...quotes,
                },
            };
        }

        case LeadsActionsEnum.LeadQuoteSet: {
            const typedAction: ILeadQuoteSetAction = action as ILeadQuoteSetAction;

            return {
                ...state,
                quotes: {
                    ...state.quotes,
                    [typedAction.quote.uuid]: typedAction.quote,
                },
            };
        }

        case LeadsActionsEnum.LeadValueSet: {
            const typedAction: ILeadValueSetAction = action as ILeadValueSetAction;

            return {
                ...state,
                deals: {
                    ...state.deals,
                    [typedAction.dealUuid]: {
                        ...state.deals[typedAction.dealUuid],
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationsOutstandingBrokerCommissionsSet: {
            const typedAction: IApplicationsOutstandingBrokerCommissionsSetAction = action as IApplicationsOutstandingBrokerCommissionsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case BrokersActionsEnum.BrokerDealsSet: {
            const typedAction: IBrokerDealsSetAction = action as IBrokerDealsSetAction;

            const deals: IDictionary<IDeal> = {};

            typedAction.deals.forEach((deal: IDeal) => {
                deals[deal.uuid] = dehydrateLead(deal);
            });

            return {
                ...state,
                deals,
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerDealsSet: {
            const typedAction: IReferralPartnerDealsSetAction = action as IReferralPartnerDealsSetAction;

            const deals: IDictionary<IDeal> = { ...state.deals };

            typedAction.deals.forEach((deal: IDeal) => {
                deals[deal.uuid] = dehydrateLead(deal);
            });

            return {
                ...state,
                deals,
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerApplicationsSet: {
            const typedAction: IReferralPartnerApplicationsSetAction = action as IReferralPartnerApplicationsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case DealsActionsEnum.DealFollowUpTime: {
            const typedAction: IDealFollowUpTimeAction = action as IDealFollowUpTimeAction;

            return {
                ...state,
                deals: {
                    ...state.deals,
                    [typedAction.dealUuid]: {
                        ...state.deals[typedAction.dealUuid],
                        followUpTime: typedAction.followUpTime,
                    },
                },
            };
        }

        case LoansActionsEnum.LoanSet: {
            const typedAction: ILoanSetAction = action as ILoanSetAction;

            return {
                ...state,
                ...statifyLoan(state, typedAction.loan),
            };
        }

        case LoansActionsEnum.LoansSet: {
            const typedAction: ILoansSetAction = action as ILoansSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        case LoansActionsEnum.LoansPaginatedSet: {
            const typedAction: ILoansPaginatedSetAction = action as ILoansPaginatedSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        case LoansActionsEnum.LoansSearchResultsSet: {
            const typedAction: ILoansSearchResultsSetAction = action as ILoansSearchResultsSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        case WarehousesActionsEnum.WarehousePendingApplicationsSet: {
            const typedAction: IWarehousePendingApplicationsSetAction = action as IWarehousePendingApplicationsSetAction;
            let clonedState: ILeadsState = { ...state };

            _.each(typedAction.applicationWarehouses, (applicationWarehouse: IApplicationWarehouse) => {
                clonedState = statifyApplication(clonedState, applicationWarehouse.application);
            });

            return clonedState;
        }

        case WarehousesActionsEnum.WarehouseEligibleLoansSet: {
            const typedAction: IWarehouseEligibleLoansSetAction = action as IWarehouseEligibleLoansSetAction;

            return {
                ...state,
                ...statifyLoans(state, _.map(typedAction.eligibleLoans, (eligibleLoan: IWarehouseEligibleLoan) => eligibleLoan.loan)),
            };
        }

        default:
            return state;
    }
}
