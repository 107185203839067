enum WorkflowStatusEnum {
    Cancelled = 'CANCELLED',
    Funded = 'FUNDED',
    GracePeriod = 'GRACE_PERIOD',
    Incomplete = 'INCOMPLETE',
    Live = 'LIVE',
    Ready = 'READY',
    Repaid = 'REPAID',
}

export default WorkflowStatusEnum;
