import _ from 'lodash';
import { AnyAction } from 'redux';
import IInvestmentTransaction from '~Api/Investment/IInvestmentTransaction';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import IInvestorAccountTransaction from '~Api/Investor/IInvestorAccountTransaction';
import ILoanTransaction from '~Api/Loan/ILoanTransaction';
import IWarehouseLoanTransaction from '~Api/Warehouse/IWarehouseLoanTransaction';
import { IDictionary } from '~utilities/IDictionary';
import {
    IInvestorMovementsSetAction,
    ILoanPaymentsSetAction,
    ILoanTransactionWarehouseLoanTransactionsSetAction,
    IPaymentsFromInvestorsSetAction,
    IPaymentsToInvestorsSetAction,
    IWarehouseMovementsSetAction,
} from './actions';
import AccountsActionsEnum from './ActionsEnum';

export interface IAccountsState {
    investorMovements: IDictionary<IInvestorAccountTransaction>;
    loanPayments: IDictionary<ILoanTransaction>;
    loanTransactionWarehouseLoanTransactions: IDictionary<IWarehouseLoanTransaction[]>;
    paymentsFromInvestors: IDictionary<IInvestorAccountInvestmentTransaction>;
    paymentsToInvestors: IDictionary<IInvestmentTransaction>;
    warehouseMovements: IDictionary<IWarehouseLoanTransaction>;
}

const initialData: IAccountsState = {
    investorMovements: null,
    loanPayments: null,
    loanTransactionWarehouseLoanTransactions: {},
    paymentsFromInvestors: null,
    paymentsToInvestors: null,
    warehouseMovements: null,
};

export function accountsReducer(state: IAccountsState = initialData, action: AnyAction): IAccountsState {
    switch (action.type) {
        case AccountsActionsEnum.InvestorMovementsSet: {
            const typedAction: IInvestorMovementsSetAction = action as IInvestorMovementsSetAction;

            const investorMovements: IDictionary<IInvestorAccountTransaction> = {};
            typedAction.transactions.forEach((transaction: IInvestorAccountTransaction) => {
                investorMovements[transaction.uuid] = _.omit(transaction, ['investor']);
            });

            return {
                ...state,
                investorMovements,
            };
        }

        case AccountsActionsEnum.LoanPaymentsSet: {
            const typedAction: ILoanPaymentsSetAction = action as ILoanPaymentsSetAction;

            return {
                ...state,
                loanPayments: _.keyBy(typedAction.loanPayments, 'uuid'),
            };
        }

        case AccountsActionsEnum.LoanTransactionWarehouseLoanTransactionsSet: {
            const typedAction: ILoanTransactionWarehouseLoanTransactionsSetAction = action as ILoanTransactionWarehouseLoanTransactionsSetAction;

            return {
                ...state,
                loanTransactionWarehouseLoanTransactions: {
                    ...state.loanTransactionWarehouseLoanTransactions,
                    [typedAction.loanTransactionUuid]: typedAction.warehouseLoanTransactions,
                },
            };
        }

        case AccountsActionsEnum.PaymentsFromInvestorsSet: {
            const typedAction: IPaymentsFromInvestorsSetAction = action as IPaymentsFromInvestorsSetAction;

            return {
                ...state,
                paymentsFromInvestors: _.keyBy(typedAction.investorAccountInvestmentTransactions, 'uuid'),
            };
        }

        case AccountsActionsEnum.PaymentsToInvestorsSet: {
            const typedAction: IPaymentsToInvestorsSetAction = action as IPaymentsToInvestorsSetAction;

            return {
                ...state,
                paymentsToInvestors: _.keyBy(typedAction.investmentTransactions, 'uuid'),
            };
        }

        case AccountsActionsEnum.WarehouseMovementsSet: {
            const typedAction: IWarehouseMovementsSetAction = action as IWarehouseMovementsSetAction;

            return {
                ...state,
                warehouseMovements: _.keyBy(typedAction.transactions, 'uuid'),
            };
        }

        default:
            return state;
    }
}
