import {
    Alert,
    Form,
    Input,
    Modal,
    Select,
} from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AddressVersionEnum from '~Api/Deal/AddressVersionEnum';
import IProperty from '~Api/Deal/IProperty';
import PropertyStateEnum from '~Api/Deal/PropertyStateEnum';
import PropertyStreetTypeEnum from '~Api/Deal/PropertyStreetTypeEnum';
import propertyStreetTypeLabels from '~Api/Deal/PropertyStreetTypeLabels';
import {
    dealPropertyGetAction,
    dealPropertySetAction,
    dealPropertyValueSetAction,
} from '~Deals/actions';
import { dealPropertySelector } from '~Deals/selectors';
import { IGlobalState } from '~reducer';

interface IProps {
    dealUuid: string;
    isOpen: boolean;
    onCancel: () => void;
    propertyUuid: string;
}

interface IPropsDispatch {
    propertyGet: () => void;
    propertySet: (property: IProperty) => void;
    propertyValueSet: (key: keyof IProperty, value: any) => void;
}

interface IPropsSelector {
    property: IProperty;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

/**
 * This is a temporary modal to allow a seamless upgrade of all our property address to the V2 format (granular street address).
 */
class PropertyAddressVersionUpgradeModal extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeUnitNumber = this.onChangeUnitNumber.bind(this);
        this.onChangeStreetNumber = this.onChangeStreetNumber.bind(this);
        this.onChangeStreetName = this.onChangeStreetName.bind(this);
        this.onChangeStreetType = this.onChangeStreetType.bind(this);
        this.onChangeSuburb = this.onChangeSuburb.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangePostcode = this.onChangePostcode.bind(this);

        this.onUpgradeAddressVersion = this.onUpgradeAddressVersion.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen, property } = this.props;

        if (!property) {
            return null;
        }

        return (
            <Modal
                okText='Confirm'
                onCancel={this.props.onCancel}
                onOk={this.onUpgradeAddressVersion}
                open={isOpen}
                title='Upgrade Address to V2'
                wrapClassName='deal-property-upgrade-address-version-modal'
            >
                <Alert className='original-address' message={`Original address: ${property.formattedAddress}`} type='info' />

                <Form.Item className='unit-number' label='Unit Number'>
                    <Input onChange={this.onChangeUnitNumber} value={property.unitNumber} />
                </Form.Item>
                <Form.Item className='street-number' label='Street Number'>
                    <Input onChange={this.onChangeStreetNumber} value={property.streetNumber} />
                </Form.Item>
                <Form.Item className='street-name' label='Street Name'>
                    <Input onChange={this.onChangeStreetName} value={property.streetName} />
                </Form.Item>
                <Form.Item className='street-type' label='Street Type'>
                    <Select
                        showSearch={true}
                        onChange={this.onChangeStreetType}
                        value={property.streetType}
                        filterOption={this.onStreetTypeFilterOption}
                    >
                        {_.keys(propertyStreetTypeLabels).map((code: string): JSX.Element => <Select.Option key={code} value={code}>{propertyStreetTypeLabels[code]}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item className='suburb' label='Suburb'>
                    <Input onChange={this.onChangeSuburb} value={property.suburb} />
                </Form.Item>
                <Form.Item className='state' label='State'>
                    <Select onChange={this.onChangeState} value={property.state}>
                        <Select.Option value={PropertyStateEnum.AustralianCapitalTerritory}>ACT</Select.Option>
                        <Select.Option value={PropertyStateEnum.NewSouthWales}>NSW</Select.Option>
                        <Select.Option value={PropertyStateEnum.NorthernTerritory}>NT</Select.Option>
                        <Select.Option value={PropertyStateEnum.Queensland}>QLD</Select.Option>
                        <Select.Option value={PropertyStateEnum.SouthAustralia}>SA</Select.Option>
                        <Select.Option value={PropertyStateEnum.Tasmania}>TAS</Select.Option>
                        <Select.Option value={PropertyStateEnum.Victoria}>VIC</Select.Option>
                        <Select.Option value={PropertyStateEnum.WesternAustralia}>WA</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item className='postcode' label='Postcode'>
                    <Input onChange={this.onChangePostcode} value={property.postcode} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeUnitNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('unitNumber', event.target.value);
    }

    private onChangeStreetNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('streetNumber', event.target.value);
    }

    private onChangeStreetName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('streetName', event.target.value);
    }

    private onStreetTypeFilterOption(input: string, option: any): boolean {
        return option.children.toString().toLocaleLowerCase().includes(input.toLocaleLowerCase());
    }

    private onChangeStreetType(value: PropertyStreetTypeEnum): void {
        this.props.propertyValueSet('streetType', value);
    }

    private onChangeSuburb(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('suburb', event.target.value);
    }

    private onChangeState(value: PropertyStateEnum): void {
        this.props.propertyValueSet('state', value);
    }

    private onChangePostcode(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('postcode', event.target.value);
    }

    private onUpgradeAddressVersion(): void {
        this.props.propertyValueSet('addressVersion', AddressVersionEnum.V2);

        this.props.onCancel();
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        property: dealPropertySelector(state, ownProps.propertyUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        propertyGet: () => dispatch(dealPropertyGetAction(ownProps.dealUuid, ownProps.propertyUuid)),
        propertySet: (property: IProperty) => dispatch(dealPropertySetAction(ownProps.dealUuid, property)),
        propertyValueSet: (key: keyof IProperty, value: any) => dispatch(dealPropertyValueSetAction(ownProps.dealUuid, ownProps.propertyUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PropertyAddressVersionUpgradeModal);
