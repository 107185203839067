import { Form, Input, Modal, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import WorkflowStatusEnum from '~Api/Investment/WorkflowStatusEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import { investmentsListAction } from '~Investments/actions';
import { investmentsSelector } from '~Investments/selectors';
import { investorAccountInvestAction } from '~Investors/actions';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

interface IState {
    amount: number;
    investmentUuid: string;
}

interface IProps {
    investor: IInvestor;
    investorAccount: IAccount;
    isOpen: boolean;
    onClose: () => void;
}

interface IPropsSelector {
    investments: IDictionary<IInvestment>;
}

interface IPropsDispatch {
    invest: (investmentUuid: string, amount: number) => void;
    investmentsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class InvestModal extends React.Component<Props, IState> {
    public state: IState = {
        amount: null,
        investmentUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onChangeInvestment = this.onChangeInvestment.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public componentDidMount(): void {
        const { investments } = this.props;

        if (!investments) {
            this.props.investmentsList();
        }
    }

    public render(): JSX.Element {
        const { investments, isOpen } = this.props;
        const { amount, investmentUuid } = this.state;

        if (!investments) {
            return null;
        }

        const investmentOptions: JSX.Element[] = _.sortBy(_.filter(investments, (investment: IInvestment) => {
            return [WorkflowStatusEnum.Live, WorkflowStatusEnum.Ready].includes(investment.workflowStatus) && investment.interestRate !== 0;
        }), ['code']).map((investment: IInvestment) => (
            <Select.Option key={investment.uuid} value={`${investment.uuid}`}>
                {investment.code} - {investment.name} - {investment.amountRemaining}
            </Select.Option>
        ));

        return (
            <Modal
                destroyOnClose={true}
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title='Invest'
                wrapClassName='investor-invest-modal'
            >
                <Form.Item className='investment' label='Investment'>
                    <Select
                        filterOption={this.filterInvestmentOption}
                        onChange={this.onChangeInvestment}
                        showSearch={true}
                        value={`${investmentUuid || ''}`}
                    >
                        {investmentOptions}
                    </Select>
                </Form.Item>
                <Form.Item className='amount' label='Amount'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeAmount} type='number' value={amount || ''} />
                </Form.Item>
            </Modal>
        );
    }

    private filterInvestmentOption(input: string, option: any): boolean {
        return option.children.toString().toLocaleLowerCase().includes(input.toLocaleLowerCase());
    }

    private onChangeInvestment(value: string): void {
        this.setState({
            investmentUuid: value,
        });
    }

    private onChangeAmount(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            amount: event.target.value ? event.target.valueAsNumber : null,
        });
    }

    private onClickOk(): void {
        const { amount, investmentUuid } = this.state;

        this.props.invest(investmentUuid, amount);
        this.props.onClose();
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        investments: investmentsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        invest: (investmentUuid: string, amount: number) => dispatch(investorAccountInvestAction(ownProps.investorAccount.uuid, investmentUuid, amount)),
        investmentsList: () => dispatch(investmentsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InvestModal);
