import { SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Popover, Space, Spin, Table, Tag, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import { investorsManagedListAction } from '~Investors/actions';
import { IGlobalState } from '~reducer';
import SearchDropdown from '~UI/SearchDropdown';
import { IDictionary } from '~utilities/IDictionary';
import { managedInvestorsSelector } from './selectors';
import accountTypeLabels from '~Api/Investor/accountTypeLabels';

interface IPropsSelector {
    managedInvestors: IDictionary<IInvestor>;
}

interface IPropsDispatch {
    managedInvestorsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Managed extends React.Component<Props> {
    public componentDidMount(): void {
        const { managedInvestors } = this.props;

        if (!managedInvestors) {
            this.props.managedInvestorsList();
        }
    }

    public render(): JSX.Element {
        const { managedInvestors } = this.props;

        if (!managedInvestors) {
            return (
                <Layout className='managed'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/investors'>Investors</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Managed Investors</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Managed Investors</Typography.Title>
                            <Spin />
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnType<IInvestor>[] = [
            {
                defaultSortOrder: 'ascend',
                filterDropdown: (params: any) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string, investor: IInvestor) => investor.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
                render: (investor: IInvestor) => <Link to={`/investors/${investor.uuid}`}>{investor.name}</Link>,
                sorter: (a: IInvestor, b: IInvestor) => a.name.localeCompare(b.name),
                title: 'Investor',
            },
            {
                render: (investor: IInvestor) => {
                    const popoverContent: JSX.Element[] = _.map(investor.accounts, (account: IAccount) => <p>{accountTypeLabels[account.accountType]}: {currencyFormatter.format(account.balance)}</p>);
                    return investor.balanceTotal !== 0 ? <Space>{currencyFormatter.format(investor.balanceTotal)} <Popover content={popoverContent}><Tag>∑</Tag></Popover></Space> : '';
                },
                sorter: (a: IInvestor, b: IInvestor) => a.balanceTotal > b.balanceTotal ? 1 : -1,
                title: 'Cash Balance',
                width: '15%',
            },
            {
                render: (investor: IInvestor) => {
                    const popoverContent: JSX.Element[] = _.map(investor.accounts, (account: IAccount) => <p>{accountTypeLabels[account.accountType]}: {currencyFormatter.format(account.investedAmountCurrent)}</p>);
                    return investor.investedAmountCurrentTotal !== 0 ? <Space>{currencyFormatter.format(investor.investedAmountCurrentTotal)} <Popover content={popoverContent}><Tag>∑</Tag></Popover></Space> : '';
                },
                sorter: (a: IInvestor, b: IInvestor) => a.investedAmountCurrentTotal > b.investedAmountCurrentTotal ? 1 : -1,
                title: 'Currently Invested',
                width: '15%',
            },
        ];
        return (
            <Layout className='managed'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/investors'>Investors</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Managed Investors</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Managed Investors</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.values(managedInvestors)}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        managedInvestors: managedInvestorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        managedInvestorsList: () => dispatch(investorsManagedListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Managed);
