import { CheckOutlined, SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Layout, Space, Spin, Table, Tag, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import AddModal from '~Administrators/AddModal';
import ActiveStatusEnum from '~Api/Administrator/ActiveStatusEnum';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import { IGlobalState } from '~reducer';
import SearchDropdown from '~UI/SearchDropdown';
import { IDictionary } from '~utilities/IDictionary';
import { administratorsListAction } from './actions';
import { administratorsSelector } from './selectors';
import { ColumnType } from 'antd/lib/table';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import IAuthUser from '~Auth/IAuthUser';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import { authCurrentUserSelector } from '~Auth/selectors';

const roleNames: IDictionary<string> = {
    [RoleEnum.BusinessDevelopmentManager]: 'Business Development Manager',
    [RoleEnum.CreditManager]: 'Credit Manager',
    [RoleEnum.InternalBusinessDevelopmentManager]: 'Internal Business Development Manager',
    [RoleEnum.InvestorManager]: 'Investor Manager',
    [RoleEnum.LoanManager]: 'Loan Manager',
    [RoleEnum.SeniorBusinessDevelopmentManager]: 'Senior Business Development Manager',
    [RoleEnum.SeniorCreditManager]: 'Senior Credit Manager',
    [RoleEnum.SeniorInvestorManager]: 'Senior Investor Manager',
    [RoleEnum.SeniorLoanManager]: 'Senior Loan Manager',
    [RoleEnum.SuperAdmin]: 'Super Admin',
};

interface IState {
    isAddModalOpen: boolean;
}

interface IPropsSelector {
    administrators: IDictionary<IAdministrator>;
    currentUser: IAuthUser;
}

interface IPropsDispatch {
    administratorsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class List extends React.Component<Props, IState> {
    public state: IState = {
        isAddModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAdd = this.onClickAdd.bind(this);
        this.onCloseAddModal = this.onCloseAddModal.bind(this);
    }

    public componentDidMount() {
        const { administrators } = this.props;

        if (!administrators) {
            this.props.administratorsList();
        }
    }

    public render(): JSX.Element {
        const { administrators, currentUser } = this.props;
        const { isAddModalOpen } = this.state;

        if (!administrators) {
            return (
                <Layout className='administrators'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Administrators</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Administrators</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnType<IAdministrator>[] = [
            {
                defaultSortOrder: 'ascend',
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: boolean|number|string, administrator: IAdministrator) => administrator.name.toLowerCase().includes(value.toLocaleString().toLowerCase()),
                render: (administrator: IAdministrator) => {
                    return (
                        <Space>
                            <Link to={`/administrators/${administrator.uuid}`}>{administrator.name || '-'}</Link>
                            {currentUser.permissions.includes(PermissionsEnum.AdministratorMagicLink) && administrator.magicLinkRequestedTime && <Tag color='red'>Magic Link Request</Tag>}
                        </Space>
                    );
                },
                sorter: (a: IAdministrator, b: IAdministrator) => a.name.localeCompare(b.name),
                title: 'Name',
            },
            {
                render: (administrator: IAdministrator) => <Link to={`/administrators/${administrator.uuid}/passkeys`}>{administrator.activePasskeyCount || '-'}</Link>,
                title: 'Passkeys',
                width: '6%',
            },
            {
                dataIndex: 'role',
                filters: _.keys(roleNames).map((role: string) => ({
                    text: roleNames[role],
                    value: role as RoleEnum,
                })),
                onFilter: (role: boolean|number|string, administrator: IAdministrator) => role === administrator.role,
                render: (role: RoleEnum) => roleNames[role],
                title: 'Role',
                width: '20%',
            },
            {
                dataIndex: 'dealMinimumValue',
                render: (dealMinimumValue: number) => dealMinimumValue ? currencyFormatter.format(dealMinimumValue) : '-',
                sorter: (a: IAdministrator, b: IAdministrator) => a.dealMinimumValue || 0 > b.dealMinimumValue || 0 ? 1 : -1,
                title: 'Deal Minimum',
                width: '10%',
            },
            {
                dataIndex: 'dealMaximumValue',
                render: (dealMaximumValue: number) => dealMaximumValue ? currencyFormatter.format(dealMaximumValue) : '-',
                sorter: (a: IAdministrator, b: IAdministrator) => a.dealMaximumValue || 0 > b.dealMaximumValue || 0 ? 1 : -1,
                title: 'Deal Maximum',
                width: '10%',
            },
            {
                dataIndex: 'status',
                defaultFilteredValue: [ActiveStatusEnum.Active],
                filters: [
                    {
                        text: 'Active',
                        value: ActiveStatusEnum.Active,
                    },
                    {
                        text: 'Inactive',
                        value: ActiveStatusEnum.Inactive,
                    },
                ],
                onFilter: (value: boolean|number|string, administrator: IAdministrator) => value === administrator.status,
                render: (status: ActiveStatusEnum) => status === ActiveStatusEnum.Active && <CheckOutlined/>,
                title: 'Active',
                width: '5%',
            },
        ];

        return (
            <Layout className='administrators'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Administrators</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Button className='add' onClick={this.onClickAdd}>Add Administrator</Button>
                        <Typography.Title level={2}>Administrators</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.values(administrators)}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
                <AddModal isOpen={isAddModalOpen} onCancel={this.onCloseAddModal} />
            </Layout>
        );
    }

    private onClickAdd() {
        this.setState({
            isAddModalOpen: true,
        });
    }

    private onCloseAddModal() {
        this.setState({
            isAddModalOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        administrators: administratorsSelector(state),
        currentUser: authCurrentUserSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(List);
