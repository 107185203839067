enum ActionsEnum {
    JokeGet = 'JOKE/GET',
    JokeSet = 'JOKE/SET',

    Login = 'AUTH/LOGIN',
    LoginErrorSet = 'AUTH/LOGIN/ERROR/SET',
    LoginErrorsSet = 'AUTH/LOGIN/ERRORS/SET',
    LoginInProgressSet = 'AUTH/LOGIN/IN_PROGRESS/SET',

    Logout = 'AUTH/LOGOUT',

    MagicLinkCreate = 'AUTH/MAGIC_LINK/CREATE',
    MagicLinkRedeem = 'AUTH/MAGIC_LINK/REDEEM',
}

export default ActionsEnum;
