function base64UrlDecode(input: string) {
    input = input.replace(/-/g, '+').replace(/_/g, '/');

    const pad: number = input.length % 4;
    if (pad) {
        if (pad === 1) {
            throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
        }
        input += new Array(5-pad).join('=');
    }

    return window.atob(input);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function arrayToBase64String(a: any): string {
    return btoa(String.fromCharCode(...a));
}

export interface IAttestationPublicKeyCredential extends PublicKeyCredential {
    response: AuthenticatorAttestationResponse;
}

export interface IPreparedAttestationPublicKeyCredential {
    id: string;
    rawId: string;
    response: {
        attestationObject?: string;
        clientDataJSON: string;
    };
    type: string;
}

export interface IAssertionPublicKeyCredential extends PublicKeyCredential {
    response: AuthenticatorAssertionResponse;
}

export interface IPreparedAssertionPublicKeyCredential {
    id: string;
    rawId: string;
    response: {
        authenticatorData?: string;
        clientDataJSON: string;
        signature?: string;
        userHandle?: string;
    };
    type: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function preparePublicKeyCredentialCreationOptions(publicKey: any): PublicKeyCredentialCreationOptions {
    const publicKeyCredentialCreationOptions: PublicKeyCredentialCreationOptions = {
        ...publicKey,
        challenge: Uint8Array.from(base64UrlDecode(publicKey.challenge), (c: string) => c.charCodeAt(0)),
    };

    //Convert the user ID from Base64 string to Uint8Array
    if (publicKey.user !== undefined) {
        publicKeyCredentialCreationOptions.user = {
            ...publicKey.user,
            id: Uint8Array.from(window.atob(publicKey.user.id), (c: string) => c.charCodeAt(0)),
        };
    }

    //If excludeCredentials is defined, we convert all IDs to Uint8Array
    if (publicKey.excludeCredentials !== undefined) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        publicKeyCredentialCreationOptions.excludeCredentials = publicKey.excludeCredentials.map((excludeCredential: any) => ({
            ...excludeCredential,
            id: Uint8Array.from(base64UrlDecode(excludeCredential.id), (c: string) => c.charCodeAt(0)),
        }));
    }

    return publicKeyCredentialCreationOptions;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function preparePublicKeyCredentialRequestOptions(publicKey: any): PublicKeyCredentialRequestOptions {
    const publicKeyCredentialRequestOptions: PublicKeyCredentialRequestOptions = {
        ...publicKey,
        challenge: Uint8Array.from(base64UrlDecode(publicKey.challenge), (c: string) => c.charCodeAt(0)),
    };

    if (publicKey.allowCredentials !== undefined) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        publicKeyCredentialRequestOptions.allowCredentials = publicKey.allowCredentials.map((allowCredential: any) => ({
            ...allowCredential,
            id: Uint8Array.from(base64UrlDecode(allowCredential.id), (c: string) => c.charCodeAt(0)),
        }));
    }

    return publicKeyCredentialRequestOptions;
}

export function prepareAttestationPublicKeyCredentials(data: IAttestationPublicKeyCredential): IPreparedAttestationPublicKeyCredential {
    const publicKeyCredential: IPreparedAttestationPublicKeyCredential = {
        id: data.id,
        rawId: arrayToBase64String(new Uint8Array(data.rawId)),
        response: {
            clientDataJSON: arrayToBase64String(new Uint8Array(data.response.clientDataJSON)),
        },
        type: data.type,
    };

    if (data.response.attestationObject !== undefined) {
        publicKeyCredential.response.attestationObject = arrayToBase64String(new Uint8Array(data.response.attestationObject));
    }

    return publicKeyCredential;
}

export function prepareAssertionPublicKeyCredentials(data: IAssertionPublicKeyCredential): IPreparedAssertionPublicKeyCredential {
    const publicKeyCredential: IPreparedAssertionPublicKeyCredential = {
        id: data.id,
        rawId: arrayToBase64String(new Uint8Array(data.rawId)),
        response: {
            clientDataJSON: arrayToBase64String(new Uint8Array(data.response.clientDataJSON)),
        },
        type: data.type,
    };

    if (data.response.authenticatorData !== undefined) {
        publicKeyCredential.response.authenticatorData = arrayToBase64String(new Uint8Array(data.response.authenticatorData));
    }

    if (data.response.signature !== undefined) {
        publicKeyCredential.response.signature = arrayToBase64String(new Uint8Array(data.response.signature));
    }

    if (data.response.userHandle !== undefined) {
        publicKeyCredential.response.userHandle = arrayToBase64String(new Uint8Array(data.response.userHandle));
    }

    return publicKeyCredential;
}
