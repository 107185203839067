import { Breadcrumb, Button, Layout, Space, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IWithdrawalAba from '~Api/WithdrawalAba/IWithdrawalAba';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { withdrawalAbasListAction } from './actions';
import AddModal from './AddModal';
import { withdrawalAbasSelector } from './selectors';
import './withdrawal-abas.less';
import AccountTypeEnum from '~Api/WithdrawalAba/AccountTypeEnum';
import accountTypeLabels from '~Api/WithdrawalAba/accountTypeLabels';
import { currencyFormatter } from '~utilities/formatters';
import ModalStateEnum from '~UI/ModalStateEnum';

interface IState {
    isAddMarketplaceModalOpen: ModalStateEnum;
    isAddIncomeTrustModalOpen: ModalStateEnum;
}

interface IPropsSelector {
    withdrawalAbas: IDictionary<IWithdrawalAba>;
}

interface IPropsDispatch {
    withdrawalAbasList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class List extends React.Component<Props, IState> {
    public state: IState = {
        isAddIncomeTrustModalOpen: ModalStateEnum.Off,
        isAddMarketplaceModalOpen: ModalStateEnum.Off,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAddIncomeTrust = this.onClickAddIncomeTrust.bind(this);
        this.onClickAddMarketplace = this.onClickAddMarketplace.bind(this);
        this.onCloseAddIncomeTrustModal = this.onCloseAddIncomeTrustModal.bind(this);
        this.onCloseAddMarketplaceModal = this.onCloseAddMarketplaceModal.bind(this);
    }

    public componentDidMount(): void {
        this.props.withdrawalAbasList();
    }

    public render(): JSX.Element {
        const { withdrawalAbas } = this.props;
        const { isAddMarketplaceModalOpen, isAddIncomeTrustModalOpen } = this.state;

        if (!withdrawalAbas) {
            return (
                <Layout className='withdrawal-abas-list'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/investors'>Investors</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Withdrawal ABAs</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Withdrawal ABAs</Typography.Title>
                            <Spin />
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const columns: ColumnType<IWithdrawalAba>[] = [
            {
                defaultSortOrder: 'descend',
                render: (withdrawalAba: IWithdrawalAba) => <Link to={`/investors/withdrawal-abas/${withdrawalAba.uuid}/transactions`}>{dayjs(withdrawalAba.createdTime).format('DD/MM/YYYY HH:mm')}</Link>,
                sorter: (a: IWithdrawalAba, b: IWithdrawalAba) => dayjs(a.createdTime) > dayjs(b.createdTime) ? 1 : -1,
                title: 'Created',
            },
            {
                filters: _.keys(accountTypeLabels).map((accountType: string) => ({ text: accountTypeLabels[accountType], value: accountType })),
                onFilter: (value: string | number | boolean, withdrawalAba: IWithdrawalAba) => (withdrawalAba.accountType).toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                render: (withdrawalAba: IWithdrawalAba) => accountTypeLabels[withdrawalAba.accountType],
                title: 'Account Type',
            },
            {
                render: (withdrawalAba: IWithdrawalAba) => withdrawalAba.transactionCount,
                title: 'Transaction Count',
                width: '20%',
            },
            {
                render: (withdrawalAba: IWithdrawalAba) => currencyFormatter.format(withdrawalAba.transactionAmountTotal),
                title: 'Transaction Total',
                width: '20%',
            },
        ];

        const marketPlaceAddModal: JSX.Element = ModalStateEnum.Off !== isAddMarketplaceModalOpen && (
            <AddModal
                isOpen={ModalStateEnum.Open === isAddMarketplaceModalOpen}
                accountType={AccountTypeEnum.Marketplace}
                onCancel={this.onCloseAddMarketplaceModal}
            />
        );

        const incomeTrustAddModal: JSX.Element = ModalStateEnum.Off !== isAddIncomeTrustModalOpen && (
            <AddModal
                isOpen={ModalStateEnum.Open === isAddIncomeTrustModalOpen}
                accountType={AccountTypeEnum.IncomeTrust}
                onCancel={this.onCloseAddIncomeTrustModal}
            />
        );

        return (
            <Layout className='withdrawal-abas-list'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/investors'>Investors</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Withdrawal ABAs</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Button onClick={this.onClickAddMarketplace}>Add Marketplace Withdrawal ABA</Button>
                            <Button onClick={this.onClickAddIncomeTrust}>Add Income Trust Withdrawal ABA</Button>
                        </Space>
                        <Typography.Title level={2}>Withdrawal ABAs</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.values(withdrawalAbas)}
                            pagination={{ defaultPageSize: 50 }}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
                {marketPlaceAddModal}
                {incomeTrustAddModal}
            </Layout>
        );
    }

    private onClickAddIncomeTrust(): void {
        this.setState({
            isAddIncomeTrustModalOpen: ModalStateEnum.Open,
        });
    }

    private onClickAddMarketplace(): void {
        this.setState({
            isAddMarketplaceModalOpen: ModalStateEnum.Open,
        });
    }

    private onCloseAddIncomeTrustModal(): void {
        this.setState({
            isAddIncomeTrustModalOpen: ModalStateEnum.Closed,
        });
    }

    private onCloseAddMarketplaceModal(): void {
        this.setState({
            isAddMarketplaceModalOpen: ModalStateEnum.Closed,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        withdrawalAbas: withdrawalAbasSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        withdrawalAbasList: () => dispatch(withdrawalAbasListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(List);
