import { Button, Form, Input, Modal, Space } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IApplicationPropertyValuer from '~Api/Application/IApplicationPropertyValuer';
import { applicationPropertyValuerQuoteTimeFrameAction } from '~Applications/actions';
import { IDictionary } from '~utilities/IDictionary';

interface IDefaultedValues {
    quoteTimeFrameDays: number;
}

interface IState {
    dirtyFields: IDictionary<boolean>;
    isModalOpen: boolean;
    quoteTimeFrameDays: number;
}

interface IProps {
    applicationProperty: IApplicationProperty;
    propertyValuer: IApplicationPropertyValuer;
}

interface IPropsDispatch {
    updateQuoteTimeFrameDays: (timeFrameDays: number) => void;
}

type Props = IProps & IPropsDispatch;

class UpdateTimeFrame extends React.Component<Props, IState> {
    public state: IState = {
        dirtyFields: {},
        isModalOpen: false,
        quoteTimeFrameDays: null,
    };

    constructor(props: Props) {
        super(props);

        this.getDefaultedValues = this.getDefaultedValues.bind(this);

        this.onChangeQuoteTimeFrameDays = this.onChangeQuoteTimeFrameDays.bind(this);

        this.onClickUpdateQuoteTimeFrameDays = this.onClickUpdateQuoteTimeFrameDays.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
        this.onClickClear = this.onClickClear.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isModalOpen } = this.state;
        const { quoteTimeFrameDays } = this.getDefaultedValues();

        return (
            <React.Fragment>
                <a onClick={this.onClickUpdateQuoteTimeFrameDays}>{quoteTimeFrameDays} days</a>
                <Modal
                    okText='Update Time Frame'
                    onCancel={this.onClickCancel}
                    onOk={this.onClickOk}
                    open={isModalOpen}
                    title='Update Time Frame'
                    wrapClassName='property-valuation-update-time-frame-modal'
                >
                    <Form.Item className='time-frame' label='Time Frame'>
                        <Space>
                            <Input min={0} addonAfter='days' onChange={this.onChangeQuoteTimeFrameDays} type='number' value={quoteTimeFrameDays} />
                            <Button onClick={this.onClickClear}>Clear</Button>
                        </Space>
                    </Form.Item>
                </Modal>
            </React.Fragment>
        );
    }

    private getDefaultedValues(): IDefaultedValues {
        const { propertyValuer } = this.props;
        const { dirtyFields, quoteTimeFrameDays } = this.state;

        return {
            quoteTimeFrameDays: dirtyFields.quoteTimeFrameDays ? quoteTimeFrameDays : propertyValuer.quoteTimeFrameDays,
        };
    }

    private onChangeQuoteTimeFrameDays(event: React.ChangeEvent<HTMLInputElement>) {
        const { dirtyFields } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                quoteTimeFrameDays: true,
            },
            quoteTimeFrameDays: event.target.value ? event.target.valueAsNumber : null,
        });
    }

    private onClickUpdateQuoteTimeFrameDays() {
        this.setState({
            isModalOpen: true,
        });
    }

    private onClickCancel() {
        this.setState({
            isModalOpen: false,
        });
    }

    private onClickClear() {
        const { dirtyFields } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                quoteTimeFrameDays: true,
            },
            quoteTimeFrameDays: null,
        });
    }

    private onClickOk() {
        const { quoteTimeFrameDays } = this.getDefaultedValues();

        this.props.updateQuoteTimeFrameDays(quoteTimeFrameDays);
        this.onClickCancel();
    }

}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        updateQuoteTimeFrameDays: (timeFrame: number) => dispatch(applicationPropertyValuerQuoteTimeFrameAction(ownProps.applicationProperty.uuid, ownProps.propertyValuer.uuid, timeFrame)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(UpdateTimeFrame);
