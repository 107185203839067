import { AnyAction } from 'redux';
import { IDictionary } from '~utilities/IDictionary';
import { IValuersSetAction } from './actions';
import ValuersActionsEnum from './ActionsEnum';
import IValuer from './IValuer';

export interface IValuersState {
    valuers: IDictionary<IValuer>;
}

const initialData: IValuersState = {
    valuers: null,
};

export function valuersReducer(state: IValuersState = initialData, action: AnyAction): IValuersState {
    switch (action.type) {
        case ValuersActionsEnum.ValuersSet: {
            const typedAction: IValuersSetAction = action as IValuersSetAction;

            const valuers: IDictionary<IValuer> = {};
            typedAction.valuers.forEach((valuer: IValuer) => {
                valuers[valuer.uuid] = valuer;
            });

            return {
                ...state,
                valuers,
            };
        }

        default:
            return state;
    }
}
