enum WarehousesActionsEnum {
    WarehouseActiveReportList = 'WAREHOUSE/ACTIVE_REPORT/LIST',
    WarehouseActiveReportSet = 'WAREHOUSE/ACTIVE_REPORT/SET',

    WarehouseAdjustInterestRate = 'WAREHOUSE/ADJUST_INTEREST_RATE',
    WarehouseAdjustPrincipal = 'WAREHOUSE/ADJUST_PRINCIPAL',

    WarehouseEligibleLoansList = 'WAREHOUSE/ELIGIBLE_LOANS/LIST',
    WarehouseEligibleLoansSet = 'WAREHOUSE/ELIGIBLE_LOANS/SET',

    WarehouseGet = 'WAREHOUSE/GET',

    WarehouseLoanGet = 'WAREHOUSE/LOAN/GET',
    WarehouseLoanSell = 'WAREHOUSE/LOAN/SELL',
    WarehouseLoanSet = 'WAREHOUSE/LOAN/SET',

    WarehouseLoanTransactionsList = 'WAREHOUSE/LOAN/TRANSACTIONS/LIST',
    WarehouseLoanTransactionsSet = 'WAREHOUSE/LOAN/TRANSACTIONS/SET',

    WarehouseLoansList = 'WAREHOUSE/LOANS/LIST',
    WarehouseLoansSet = 'WAREHOUSE/LOANS/SET',

    WarehouseParametersList = 'WAREHOUSE/PARAMETERS/LIST',
    WarehouseParametersSet = 'WAREHOUSE/PARAMETERS/SET',

    WarehousePendingApplicationsList = 'WAREHOUSE/PENDING_APPLICATIONS/LIST',
    WarehousePendingApplicationsSet = 'WAREHOUSE/PENDING_APPLICATIONS/SET',

    WarehouseProposedPurchaseParametersList = 'WAREHOUSE/PROPOSED_PURCHASE_PARAMETERS/LIST',
    WarehouseProposedPurchaseParametersSet = 'WAREHOUSE/PROPOSED_PURCHASE_PARAMETERS/SET',

    WarehouseProposedSalesAdd = 'WAREHOUSE/PROPOSED_SALES/ADD',
    WarehouseProposedSalesPendingList = 'WAREHOUSE/PROPOSED_SALES/PENDING/LIST',
    WarehouseProposedSalesPendingSet = 'WAREHOUSE/PROPOSED_SALES/PENDING/SET',

    WarehouseProposedSaleProcess = 'WAREHOUSE/PROPOSED_SALE/PROCESS',
    WarehouseProposedSaleCancel = 'WAREHOUSE/PROPOSED_SALE/CANCEL',

    WarehouseTransactionsList = 'WAREHOUSE/TRANSACTIONS/LIST',
    WarehouseTransactionsSet = 'WAREHOUSE/TRANSACTIONS/SET',

    WarehousesList = 'WAREHOUSES/LIST',
    WarehousesSet = 'WAREHOUSES/SET',

    WarehousesLoanBookForecastList = 'WAREHOUSES/LOAN_BOOK_FORECAST/LIST',
    WarehousesLoanBookForecastSet = 'WAREHOUSES/LOAN_BOOK_FORECAST/SET',

    WarehousesPortfolioDashboardList = 'WAREHOUSES/PORTFOLIO_DASHBOARD/LIST',
    WarehousesPortfolioDashboardSet = 'WAREHOUSES/PORTFOLIO_DASHBOARD/SET',
}

export default WarehousesActionsEnum;
