import { Button, Form, Input, Modal, Space } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IApplicationPropertyValuer from '~Api/Application/IApplicationPropertyValuer';
import { applicationPropertyValuerQuoteAmountAction } from '~Applications/actions';
import { IDictionary } from '~utilities/IDictionary';

interface IDefaultedValues {
    quoteAmount: number;
}

interface IState {
    dirtyFields: IDictionary<boolean>;
    isModalOpen: boolean;
    quoteAmount: number;
}

interface IProps {
    applicationProperty: IApplicationProperty;
    propertyValuer: IApplicationPropertyValuer;
}

interface IPropsDispatch {
    updateQuoteAmount: (amount: number) => void;
}

type Props = IProps & IPropsDispatch;

class UpdateQuote extends React.Component<Props, IState> {
    public state: IState = {
        dirtyFields: {},
        isModalOpen: false,
        quoteAmount: null,
    };

    constructor(props: Props) {
        super(props);

        this.getDefaultedValues = this.getDefaultedValues.bind(this);

        this.onChangeQuoteAmount = this.onChangeQuoteAmount.bind(this);

        this.onClickUpdateQuoteAmount = this.onClickUpdateQuoteAmount.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
        this.onClickClear = this.onClickClear.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isModalOpen } = this.state;
        const { quoteAmount } = this.getDefaultedValues();

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        return (
            <React.Fragment>
                <a onClick={this.onClickUpdateQuoteAmount}>{currencyFormatter.format(quoteAmount)}</a>
                <Modal
                    okText='Update Quote'
                    onCancel={this.onClickCancel}
                    onOk={this.onClickOk}
                    open={isModalOpen}
                    title='Update Quote'
                    wrapClassName='property-valuation-update-quote-modal'
                >
                    <Form.Item className='amount' label='Amount'>
                        <Space>
                            <Input addonBefore='$' min={0} onChange={this.onChangeQuoteAmount} type='number' value={quoteAmount} />
                            <Button onClick={this.onClickClear}>Clear</Button>
                        </Space>
                    </Form.Item>
                </Modal>
            </React.Fragment>
        );
    }

    private getDefaultedValues(): IDefaultedValues {
        const { propertyValuer } = this.props;
        const { dirtyFields, quoteAmount } = this.state;

        return {
            quoteAmount: dirtyFields.quoteAmount ? quoteAmount : propertyValuer.quoteAmount,
        };
    }

    private onChangeQuoteAmount(event: React.ChangeEvent<HTMLInputElement>) {
        const { dirtyFields } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                quoteAmount: true,
            },
            quoteAmount: event.target.value ? event.target.valueAsNumber : null,
        });
    }

    private onClickUpdateQuoteAmount() {
        this.setState({
            isModalOpen: true,
        });
    }

    private onClickCancel() {
        this.setState({
            isModalOpen: false,
        });
    }

    private onClickClear() {
        const { dirtyFields } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                quoteAmount: true,
            },
            quoteAmount: null,
        });
    }

    private onClickOk() {
        const { quoteAmount } = this.getDefaultedValues();

        this.props.updateQuoteAmount(quoteAmount);
        this.onClickCancel();
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        updateQuoteAmount: (amount: number) => dispatch(applicationPropertyValuerQuoteAmountAction(ownProps.applicationProperty.uuid, ownProps.propertyValuer.uuid, amount)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(UpdateQuote);
