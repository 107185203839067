import { fetchGet, fetchPost } from '~utilities/fetch';
import InterestPaymentStrategyEnum from './InterestPaymentStrategyEnum';
import IncomeTrustClassTypeEnum from './IncomeTrustClassTypeEnum';

export async function incomeTrustDivestRequest(investorAccountIncomeTrustUuid: string, amount: number): Promise<Response> {
    return fetchPost(`/investor-income-trust/${investorAccountIncomeTrustUuid}/divest`, {
        amount,
    });
}

export async function incomeTrustInvestRequest(investorAccountIncomeTrustUuid: string, amount: number, sendEmail:boolean): Promise<Response> {
    return fetchPost(`/investor-income-trust/${investorAccountIncomeTrustUuid}/invest`, {
        amount,
        sendEmail,
    });
}

export async function investorAccountIncomeTrustsListRequest(investorAccountUuid: string): Promise<Response> {
    return fetchGet(`/investor-accounts/${investorAccountUuid}/income-trusts`);
}

export async function investorAccountIncomeTrustInterestPaymentStrategyRequest(investorAccountIncomeTrustUuid: string, interestPaymentStrategy: InterestPaymentStrategyEnum): Promise<Response> {
    return fetchPost(`/investor-income-trust/${investorAccountIncomeTrustUuid}/interest-payment-strategy`, {
        interestPaymentStrategy,
    });
}

export async function investorAccountIncomeTrustTransactionsListRequest(investorAccountIncomeTrustUuid: string): Promise<Response> {
    return fetchGet(`/investor-income-trust/${investorAccountIncomeTrustUuid}/transactions`);
}

export async function incomeTrustProcessInterestDryRunRequest(classType: IncomeTrustClassTypeEnum): Promise<Response> {
    return fetchGet('/income-trust/process-interest', {
        class: classType,
    });
}

export async function incomeTrustProcessInterestRequest(classType: IncomeTrustClassTypeEnum): Promise<Response> {
    return fetchPost('/income-trust/process-interest', {
        class: classType,
    });
}

export async function incomeTrustTransactionsListRequest(): Promise<Response> {
    return fetchGet('/income-trust-transactions');
}

export async function incomeTrustTransactionInvestorIncomeTrustTransactionsListRequest(incomeTrustTransactionUuid: string): Promise<Response> {
    return fetchGet(`/income-trust-transactions/${incomeTrustTransactionUuid}/investor-income-trust-transactions`);
}
