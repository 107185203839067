import { Spin, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IAdviser from '~Api/Adviser/IAdviser';
import IHistory from '~Api/Adviser/IHistory';
import {
    adviserGetAction,
    adviserHistoriesListAction,
} from '~Advisers/actions';
import {
    adviserHistoriesSelector,
    adviserSelector,
} from '~Advisers/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import { IDictionary } from '~utilities/IDictionary';

interface IMatch {
    adviserUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    adviser: IAdviser;
    histories: IDictionary<IHistory>;
}

interface IPropsDispatch {
    adviserGet: () => void;
    historiesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class History extends React.Component<Props> {
    public componentDidMount() {
        const { adviser } = this.props;

        if (!adviser) {
            this.props.adviserGet();
        }

        this.props.historiesList();
    }

    public render(): JSX.Element {
        const { adviser, match, histories } = this.props;

        if (!adviser || !histories) {
            return (
                <Layout adviserUuid={match.params.adviserUuid} section='history'>
                    <Typography.Title level={2}>History</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const historiesBlock: JSX.Element[] = _.sortBy(_.filter(histories, (history: IHistory) => !!history.uuid), ['createdTime']).map((loopHistory: IHistory) => (
            <Timeline.Item key={loopHistory.uuid} label={dayjs(loopHistory.createdTime).format('YYYY-MM-DD - HH:mm')}>
                <Typography.Text>{loopHistory.description}</Typography.Text>
            </Timeline.Item>
        ));

        return (
            <Layout adviserUuid={match.params.adviserUuid} section='history'>
                <Typography.Title level={2}>History</Typography.Title>
                <Timeline mode='left' reverse={true}>
                    {historiesBlock}
                </Timeline>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        adviser: adviserSelector(state, ownProps.match.params.adviserUuid),
        histories: adviserHistoriesSelector(state, ownProps.match.params.adviserUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        adviserGet: () => dispatch(adviserGetAction(ownProps.match.params.adviserUuid)),
        historiesList: () => dispatch(adviserHistoriesListAction(ownProps.match.params.adviserUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(History);
