import { parseAccount } from '~Api/Investor/parsers';
import AccountTypeEnum from './AccountTypeEnum';
import IHistory from './IHistory';
import IInvestment from './IInvestment';
import IInvestmentPhoto from './IInvestmentPhoto';
import IInvestmentTransaction from './IInvestmentTransaction';
import InvestmentCodeTypeEnum from './InvestmentCodeTypeEnum';
import IPendingDistribution from './IPendingDistribution';
import IPendingDistributionDetail from './IPendingDistributionDetail';
import IReservation from './IReservation';
import WorkflowStatusEnum from './WorkflowStatusEnum';
import VisibilityStatusEnum from './VisibilityStatusEnum';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvestment(raw: any): IInvestment {
    return {
        accountType: raw.accountType as AccountTypeEnum,
        amountRemaining: raw.amountRemaining,
        amountReserved: raw.amountReserved,
        amountTotal: raw.amountTotal,
        closedDate: raw.closedDate,
        code: raw.code,
        codeType: raw.codeType as InvestmentCodeTypeEnum,
        createdTime: raw.createdTime,
        description: raw.description,
        endDate: raw.endDate,
        gracePeriodMonths: raw.gracePeriodMonths,
        interestRate: raw.interestRate,
        loanCode: raw.loanCode,
        loanUuid: raw.loanUuid,
        lvr: raw.lvr,
        memoSynopsis: raw.memoSynopsis,
        name: raw.name,
        photos: raw.photos.map(parseInvestmentPhoto),
        propertySecurity: raw.propertySecurity,
        propertyType: raw.propertyType,
        propertyValue: raw.propertyValue,
        purpose: raw.purpose,
        salesforceLoanId: raw.loanId,
        termMonths: raw.termMonths,
        uuid: raw.uuid,
        visibilityStatus: raw.visibilityStatus as VisibilityStatusEnum,
        workflowStatus: raw.workflowStatus as WorkflowStatusEnum,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvestmentHistory(raw: any): IHistory {
    return {
        createdTime: raw.createdTime,
        description: raw.description,
        uuid: raw.uuid,
    };
}

export function parseInvestmentPhoto(raw: any): IInvestmentPhoto {
    return {
        url: raw.url,
        uuid: raw.uuid,
    };
}

export function parsePendingDistribution(raw: any): IPendingDistribution {
    const investment: IInvestment = parseInvestment(raw.investment);

    return {
        distributions: raw.distributions.map(parsePendingDistributionDetail),
        interestAmount: raw.interestAmount,
        investment,
        investmentUuid: investment.uuid,
        loanCode: raw.loanCode,
        principalAmount: raw.principalAmount,
        transactionTime: raw.transactionTime,
        warehouseLoanTransactionUuid: raw.warehouseLoanTransactionUuid,
        warning: raw.warning,
    };
}

export function parsePendingDistributionDetail(raw: any): IPendingDistributionDetail {
    return {
        amount: raw.amount,
        description: raw.description,
        interestAmount: raw.interestAmount,
        investorName: raw.investorName,
        principalAmount: raw.principalAmount,
        whtAmount: raw.whtAmount,
    };
}

export function parseInvestmentTransaction(raw: any): IInvestmentTransaction {
    return {
        amountInterest: raw.amountInterest,
        amountInvestment: raw.amountInvestment,
        amountPrincipal: raw.amountPrincipal,
        createdTime: raw.createdTime,
        description: raw.description,
        investmentUuid: raw.investmentUuid,
        transactionTime: raw.transactionTime,
        uuid: raw.uuid,
    };
}

export function parseReservation(raw: any): IReservation {
    return {
        amount: raw.amount,
        investmentUuid: raw.investmentUuid,
        investorAccount: parseAccount(raw.investorAccount),
        investorAccountUuid: raw.investorAccountUuid,
        uuid: raw.uuid,
    };
}
