import IDistribution from './IDistribution';
import IInvestorAccountIncomeTrust from './IInvestorAccountIncomeTrust';
import IIncomeTrustTransaction from './IIncomeTrustTransaction';
import IInvestorIncomeTrustTransaction from './IInvestorIncomeTrustTransaction';
import IncomeTrustClassTypeEnum from './IncomeTrustClassTypeEnum';
import InterestPaymentStrategyEnum from './InterestPaymentStrategyEnum';
import IIncomeTrustInvestmentRequest from './IIncomeTrustInvestmentRequest';
import { parseAccount } from '~Api/Investor/parsers';
import IncomeTrustInvestmentRequestEnum from './IncomeTrustInvestmentRequestEnum';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvestorAccountIncomeTrust(raw: any): IInvestorAccountIncomeTrust {
    return {
        classType: raw.class as IncomeTrustClassTypeEnum,
        interestBalance: raw.interestBalance,
        interestPaymentStrategy: raw.interestPaymentStrategy as InterestPaymentStrategyEnum,
        investorAcountUuid: raw.investorAcountUuid,
        principalBalance: raw.principalBalance,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseIncomeTrustDistribution(raw: any): IDistribution {
    return {
        interestAmount: raw.interestAmount,
        investorAccountUuid: raw.investorAccountUuid,
        investorName: raw.investorName,
        investorUuid: raw.investorUuid,
        warning: raw.warning,
        whtAmount: raw.whtAmount,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseIncomeTrustInvestmentRequest(raw: any): IIncomeTrustInvestmentRequest {

    return {
        amount: raw.amount,
        createdTime: raw.createdTime,
        investorAccount: parseAccount(raw.investorAccount),
        investorAccountIncomeTrust: parseInvestorAccountIncomeTrust(raw.investorIncomeTrust),
        status: raw.status as IncomeTrustInvestmentRequestEnum,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseIncomeTrustTransaction(raw: any): IIncomeTrustTransaction {
    return {
        classType: raw.class as IncomeTrustClassTypeEnum,
        interestRate: raw.interestRate,
        investorCount: raw.investorCount,
        totalAmount: raw.totalAmount,
        transactionTime: raw.transactionTime,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvestorIncomeTrustTransaction(raw: any): IInvestorIncomeTrustTransaction {
    return {
        createdTime: raw.createdTime,
        description: raw.description,
        interestAmount: raw.interestAmount,
        investorAccountUuid: raw.investorAccountUuid,
        investorName: raw.investorName,
        investorUuid: raw.investorUuid,
        principalAmount: raw.principalAmount,
        transactionTime: raw.transactionTime,
        uuid: raw.uuid,
    };
}
