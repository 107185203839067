import { Result } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router as ReactRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import Accounts from '~Accounts';
import Administrators from '~Administrators';
import Advisers from '~Advisers';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import Applications from '~Applications';
import { authLogoutAction } from '~Auth/actions';
import AccountRecovery from '~Auth/AccountRecovery';
import IAuthUser from '~Auth/IAuthUser';
import Login from '~Auth/Login';
import LoginLink from '~Auth/LoginLink';
import RolesEnum from '~Auth/RolesEnum';
import { authCurrentUserSelector } from '~Auth/selectors';
import Brokers from '~Brokers';
import { errorSelector } from '~Error/selectors';
import history from '~history';
import Investments from '~Investments';
import InvestmentSaleTransfers from '~InvestmentSaleTransfers';
import Investors from '~Investors';
import Leads from '~Leads';
import Loans from '~Loans';
import ReferralPartners from '~ReferralPartners';
import Reports from '~Reports/router';
import Users from '~Users';
import Warehouses from '~Warehouses';
import WithdrawalAbas from '~WithdrawalAbas';
import dayjs from 'dayjs';
import constants from '~constants';
import _ from 'lodash';

export default function Router(): ReactElement {
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);
    const error: string = useSelector(errorSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        const checkIdle: () => void = () => {
            if (currentUser.role === RolesEnum.Unauthenticated) {
                return;
            }

            // If the last action occurred more than the allowable number of seconds, log them out
            const difference: number = dayjs().diff(dayjs(localStorage.getItem('lastAction')), 'second');
            if (difference > constants.IDLE_TIMEOUT_SECONDS) {
                dispatch(authLogoutAction(true));
            }
        };

        if (currentUser.role === RolesEnum.Authenticated) {
            // Check if they were idle in case we need to log them out before they can reset the timer
            checkIdle();
        }

        // Add the event listeners to detect user activity
        [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange',
        ].forEach((eventType: string) => {
            document.addEventListener(eventType, _.debounce(() => {
                // Store the action time in local storage where all tabs can access it
                localStorage.setItem('lastAction', dayjs().toISOString());
            }, 100));
        });

        // Check every second if they are idle or not
        const timeoutInterval: NodeJS.Timeout = setInterval(checkIdle, 1000);

        return () => {
            clearInterval(timeoutInterval);
        };
    }, [
        currentUser,
    ]);

    if (error) {
        return (
            <Result
                status='500'
                title='Something broke!'
                subTitle={'It\'s probably Jack\'s fault'}
            />
        );
    }

    if (currentUser.role === RolesEnum.Authenticated) {
        return (
            <>
                <ReactRouter history={history}>
                    <Switch>
                        {currentUser.permissions.includes(PermissionsEnum.Accounts) && <Route path='/investment-sale-transfers' component={InvestmentSaleTransfers} />}
                        {currentUser.permissions.includes(PermissionsEnum.Accounts) && <Route path='/accounts' component={Accounts} />}
                        {currentUser.permissions.includes(PermissionsEnum.Administrators) && <Route path='/administrators' component={Administrators} />}
                        {currentUser.permissions.includes(PermissionsEnum.Advisers) && <Route path='/advisers' component={Advisers} />}
                        {currentUser.permissions.includes(PermissionsEnum.Applications) && <Route path='/applications' component={Applications} />}
                        {currentUser.permissions.includes(PermissionsEnum.Brokers) && <Route path='/brokers' component={Brokers} />}
                        {currentUser.permissions.includes(PermissionsEnum.Investments) && <Route path='/investments' component={Investments} />}
                        {currentUser.permissions.includes(PermissionsEnum.WithdrawalAbas) && <Route path='/investors/withdrawal-abas' component={WithdrawalAbas} />}
                        {currentUser.permissions.includes(PermissionsEnum.Investors) && <Route path='/investors' component={Investors} />}
                        {currentUser.permissions.includes(PermissionsEnum.Leads) && <Route path='/leads' component={Leads} />}
                        {currentUser.permissions.includes(PermissionsEnum.Loans) && <Route path='/loans' component={Loans} />}
                        {currentUser.permissions.includes(PermissionsEnum.ReferralPartners) && <Route path='/referral-partners' component={ReferralPartners} />}
                        {currentUser.permissions.includes(PermissionsEnum.Users) && <Route path='/users' component={Users} />}
                        {currentUser.permissions.includes(PermissionsEnum.Reports) && <Route path='/reports' component={Reports} />}
                        {currentUser.permissions.includes(PermissionsEnum.Warehouses) && <Route path='/warehouses' component={Warehouses} />}

                        {currentUser.permissions.includes(PermissionsEnum.Accounts) && <Redirect to='/accounts' />}
                        {currentUser.permissions.includes(PermissionsEnum.Brokers) && <Redirect to='/brokers' />}
                        {currentUser.permissions.includes(PermissionsEnum.Leads) && <Redirect to='/leads' />}
                        {currentUser.permissions.includes(PermissionsEnum.Investors) && <Redirect to='/investors' />}
                        {currentUser.permissions.includes(PermissionsEnum.Loans) && <Redirect to='/loans' />}
                    </Switch>
                </ReactRouter>
            </>
        );
    }

    return (
        <ReactRouter history={history}>
            <Switch>
                <Route exact={true} path='/account-recovery' component={AccountRecovery} />
                <Route exact={true} path='/login' component={Login} />
                <Route exact={true} path='/login-link/:code' component={LoginLink} />
                <Redirect to='/login' />
            </Switch>
        </ReactRouter>
    );
}
