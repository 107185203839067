import _ from 'lodash';
import { fetchGet, fetchPost } from '~utilities/fetch';

export async function investmentSaleTransferGetRequest(investmentSaleTransferUuid: string): Promise<Response> {
    return fetchGet(`/investment-sale-transfers/${investmentSaleTransferUuid}`);
}

export async function investmentSaleTransferSendRequest(investmentSaleTransferUuid: string): Promise<Response> {
    return fetchPost(`/investment-sale-transfers/${investmentSaleTransferUuid}/send`);
}

export async function investmentSaleTransfersAddRequest(investmentUuid: string, investorAccountInvestmentTransactionsUuids: string[]): Promise<Response> {
    return fetchPost(`/investments/${investmentUuid}/investment-sale-transfers`, { 
        investorAccountInvestmentTransactionsUuids, 
    });
}

export async function investmentSaleTransfersListRequest(): Promise<Response> {
    return fetchGet('/investment-sale-transfers');
}

export async function investmentSaleTransfersUnallocatedInvestmentsListRequest(): Promise<Response> {
    return fetchGet(`/investment-sale-transfers/unallocated-investments`);
}

export async function investmentSaleTransferHistoriesListRequest(investmentSaleTransferUuid: string): Promise<Response> {
    return fetchGet(`/investment-sale-transfers/${investmentSaleTransferUuid}/history`);
}

export async function investmentSaleTransferInvestorAccountInvestmentTransactionsListRequest( investmentSaleTransferUuid: string): Promise<Response> {
    return fetchGet(`/investment-sale-transfers/${investmentSaleTransferUuid}/investor-investment-transactions`);
}