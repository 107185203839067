import IAdviser from './IAdviser';
import IHistory from './IHistory';
import INote from './INote';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseAdviser(raw: any): IAdviser {
    return {
        afslNumber: raw.afslNumber,
        approvalStatus: raw.approvalStatus,
        authorisedRepresentativeNumber: raw.authorisedRepresentativeNumber,
        code: raw.code,
        companyName: raw.companyName,
        dealerGroup: raw.dealerGroup,
        email: raw.email,
        firstName: raw.firstName,
        formattedName: raw.formattedName,
        investorCount: raw.investorCount,
        investorsPortfolioTotal: raw.investorsPortfolioTotal,
        lastName: raw.lastName,
        phone: raw.phone,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseAdviserHistory(raw: any): IHistory {
    return {
        createdTime: raw.createdTime,
        description: raw.description,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseAdviserNote(raw: any): INote {
    return {
        administratorUuid: raw.administratorUuid,
        createdTime: raw.createdTime,
        note: raw.note,
        uuid: raw.uuid,
    };
}
