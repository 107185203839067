import _ from 'lodash';
import IApplication from '~Api/Application/IApplication';
import IBroker from '~Api/Broker/IBroker';
import IDocument from '~Api/Broker/IDocument';
import IHistory from '~Api/Broker/IHistory';
import INote from '~Api/Broker/INote';
import IDeal from '~Api/Deal/IDeal';
import { applicationSelector } from '~Applications/selectors';
import { leadSelector } from '~Leads/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

export function brokersSelector(state: IGlobalState, skipListCheck: boolean = false): IDictionary<IBroker> {
    if (!skipListCheck && !state.brokers.brokersListed) {
        return null;
    }

    return state.brokers.brokers;
}

export function brokersSearchResultsSelector(state: IGlobalState): IBroker[] {
    if (!state.brokers.brokersSearchResultUuids) {
        return null;
    }

    return state.brokers.brokersSearchResultUuids.map((dealUuid: string) => brokerSelector(state, dealUuid));
}

export function brokerSelector(state: IGlobalState, brokerUuid: string): IBroker {
    return state.brokers.brokers[brokerUuid];
}

export function brokerApplicationsSelector(state: IGlobalState, brokerUuid: string): IDictionary<IApplication> {
    if (!state.brokers.brokerApplicationUuids || !state.brokers.brokerApplicationUuids[brokerUuid]) {
        return null;
    }

    const applications: IDictionary<IApplication> = {};

    _.each(state.brokers.brokerApplicationUuids[brokerUuid], (applicationUuid: string) => {
        applications[applicationUuid] = applicationSelector(state, applicationUuid);
    });

    return applications;
}

export function brokerDocumentsSelector(state: IGlobalState, brokerUuid: string): IDocument[] {
    return state.brokers.documents[brokerUuid] && _.values(state.brokers.documents[brokerUuid]);
}

export function brokerHistoriesSelector(state: IGlobalState, brokerUuid: string): IHistory[] {
    return state.brokers.histories[brokerUuid] && _.values(state.brokers.histories[brokerUuid]);
}

export function brokerDealsSelector(state: IGlobalState, brokerUuid: string): IDictionary<IDeal> {
    if (!state.brokers.brokerDealUuids || !state.brokers.brokerDealUuids[brokerUuid]) {
        return null;
    }

    const deals: IDictionary<IDeal> = {};

    _.each(state.brokers.brokerDealUuids[brokerUuid], (dealUuid: string) => {
        deals[dealUuid] = leadSelector(state, dealUuid);
    });

    return deals;
}

export function brokerNotesSelector(state: IGlobalState, brokerUuid: string): INote[] {
    return state.brokers.notes[brokerUuid] && _.values(state.brokers.notes[brokerUuid]);
}
