import { Button, Col, Modal, Row, Spin, Table } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import {
    investmentSaleTransfersAddAction,
    investmentSaleTransfersUnallocatedInvestmentsListAction,
} from './actions';
import _ from 'lodash';
import './investment-sale-transfers.less';
import IUnallocatedInvestment from '~Api/InvestmentSaleTransfer/IUnallocatedInvestment';
import { currencyFormatter } from '~utilities/formatters';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import IAccount from '~Api/Investor/IAccount';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { TableRowSelection } from 'antd/lib/table/interface';
import { investmentSaleTransfersUnallocatedInvestmentsSelector } from './selectors';
import Text from 'antd/lib/typography/Text';

interface IProps {
    isOpen: boolean;
    onCancel: () => void;
}

interface IState {
    selectedRowKeys: string[];
    selectedUnallocatedInvestmentUuid: string;
}

interface IPropsSelector {
    unallocatedInvestments: IDictionary<IUnallocatedInvestment>;
}

interface IPropsDispatch {
    investmentSaleTransferAdd: (investmentUuid: string, selectedRowKeys: string[]) => void;
    unallocatedInvestmentsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class AddModal extends React.Component<Props, IState> {
    public state: IState = {
        selectedRowKeys: [],
        selectedUnallocatedInvestmentUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeSelectedRow = this.onChangeSelectedRow.bind(this);
        this.onClickUnallocatedInvestment = this.onClickUnallocatedInvestment.bind(this);
        this.onClickBack = this.onClickBack.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public componentDidMount(): void {
        this.props.unallocatedInvestmentsList();
    }

    public render(): JSX.Element {
        const { isOpen, unallocatedInvestments } = this.props;
        const { selectedRowKeys, selectedUnallocatedInvestmentUuid } = this.state;

        if (!unallocatedInvestments) {
            return (
                <Modal
                    onCancel={this.props.onCancel}
                    okButtonProps={{ style: { display: 'none' } }}
                    open={isOpen}
                    title='Add Investment Sale Transfer'
                    width={900}
                    wrapClassName='investment-sale-transfers-add-modal'
                >
                    <Spin />
                </Modal>
            );
        }

        const columns: ColumnType<IUnallocatedInvestment>[] = [
            {
                dataIndex: 'investment',
                render: (investment: IInvestment, unallocatedInvestment: IUnallocatedInvestment) => {
                    const onClickUnallocatedInvestment: () => void = () => this.onClickUnallocatedInvestment(unallocatedInvestment);

                    return <Button type='link' onClick={onClickUnallocatedInvestment}>{investment.code}</Button>;
                },
                title: 'Investment',
            },
            {
                dataIndex: 'investment',
                render: (investment: IInvestment) => investment.loanCode,
                title: 'Loan',
                width: '30%',
            },
            {
                dataIndex: 'investorAccountInvestmentTransactions',
                render: (investorAccountInvestmentTransactions: IDictionary<IInvestorAccountInvestmentTransaction>) => _.size(investorAccountInvestmentTransactions),
                title: 'Transaction Count',
                width: '20%',
            },
            {
                dataIndex: 'investorAccountInvestmentTransactions',
                render: (investorAccountInvestmentTransactions: IDictionary<IInvestorAccountInvestmentTransaction>) => currencyFormatter.format(_.reduce(investorAccountInvestmentTransactions, (sum: number, transaction: IInvestorAccountInvestmentTransaction) => sum + transaction.principalAmount, 0)),
                title: 'Transaction Total',
                width: '20%',
            },
        ];

        const nestedColumns: ColumnType<IInvestorAccountInvestmentTransaction>[] = [
            {
                dataIndex: 'investorAccount',
                render: (investorAccount: IAccount) => <Link to={`/investors/${investorAccount.investorUuid}/accounts/${investorAccount.uuid}`}>{investorAccount.code}</Link>,
                title: 'Account ID',
                width: '15%',
            },
            {
                dataIndex: 'investorAccount',
                render: (investorAccount: IAccount) => investorAccount.investorName,
                title: 'Investor',
            },
            {
                dataIndex: 'principalAmount',
                render: (principalAmount: number) => currencyFormatter.format(principalAmount),
                sorter: (a: IInvestorAccountInvestmentTransaction, b: IInvestorAccountInvestmentTransaction) => a.principalAmount > b.principalAmount ? 1 : -1,
                title: 'Amount',
                width: '15%',
            },
            {
                dataIndex: 'transactionTime',
                defaultSortOrder: 'descend',
                render: (transactionTime: string) => dayjs(transactionTime).format('DD/MM/YYYY HH:mm:ss'),
                sorter: (a: IInvestorAccountInvestmentTransaction, b: IInvestorAccountInvestmentTransaction) => dayjs(a.transactionTime) > dayjs(b.transactionTime) ? 1 : -1,
                title: 'Date',
                width: '20%',
            },
        ];

        const rowSelection: TableRowSelection<IInvestorAccountInvestmentTransaction> = {
            onChange: this.onChangeSelectedRow,
            selectedRowKeys,
        };

        const total: number = _.reduce(
            _.filter(
                unallocatedInvestments[selectedUnallocatedInvestmentUuid]?.investorAccountInvestmentTransactions,
                (transaction: IInvestorAccountInvestmentTransaction) => selectedRowKeys.includes(transaction.uuid)
            ),
            (sum: number, transaction: IInvestorAccountInvestmentTransaction) => sum + transaction.principalAmount,
            0,
        );

        const footer: JSX.Element = (
            <Row align='middle' justify='space-between'>
                <Col>
                    <Row style={{ gap: '66px' }}>
                        <Row align="middle" style={{ display: 'flex', gap: '8px' }}>
                            <Col>
                                <Text>Total Count:</Text>
                            </Col>
                            <Col>
                                <Text>{selectedRowKeys.length}</Text>
                            </Col>
                        </Row>
                        <Row align="middle" style={{ display: 'flex', gap: '8px' }}>
                            <Col>
                                <Text>Total Amount:</Text>
                            </Col>
                            <Col>
                                <Text>{currencyFormatter.format(total)}</Text>
                            </Col>
                        </Row>
                    </Row>
                </Col>
                <Col>
                    <Button key='cancel' type='text' onClick={this.onClickCancel}>Cancel</Button>
                    <Button key='back' onClick={this.onClickBack}>Back</Button>
                    <Button key='submit' type='primary' onClick={this.onClickOk}>Add</Button>
                </Col>
            </Row>
        );

        if (selectedUnallocatedInvestmentUuid) {
            return (
                <Modal
                    onCancel={this.onClickCancel}
                    open={isOpen}
                    title={`Add Investment Sale Transfer - ${unallocatedInvestments[selectedUnallocatedInvestmentUuid].investment.code} / ${unallocatedInvestments[selectedUnallocatedInvestmentUuid].investment.loanCode}`}
                    width={900}
                    wrapClassName='investment-sale-transfers-add-modal'
                    footer={footer}
                >
                    <Table
                        columns={nestedColumns}
                        dataSource={_.values(unallocatedInvestments[selectedUnallocatedInvestmentUuid].investorAccountInvestmentTransactions)}
                        pagination={false}
                        rowKey='uuid'
                        size='middle'
                        rowSelection={rowSelection}
                        scroll={{ y: 600 }}
                    />
                </Modal>
            );
        }

        return (
            <Modal
                okButtonProps={{ style: { display: 'none' } }}
                onCancel={this.onClickCancel}
                open={isOpen}
                title='Add Investment Sale Transfer'
                width={900}
                wrapClassName='investment-sale-transfers-add-modal'
            >
                <Table
                    columns={columns}
                    dataSource={_.values(unallocatedInvestments)}
                    pagination={false}
                    rowKey='investmentUuid'
                    size='middle'
                />
            </Modal>
        );
    }

    private onChangeSelectedRow(selectedRowKeys: string[]): void {
        this.setState({ selectedRowKeys });
    }

    private onClickUnallocatedInvestment(unallocatedInvestment: IUnallocatedInvestment): void {
        const selectedRowKeys: string[] = _.keys(unallocatedInvestment.investorAccountInvestmentTransactions);

        this.setState({
            selectedRowKeys,
            selectedUnallocatedInvestmentUuid: unallocatedInvestment.investmentUuid,
        });
    }

    private onClickOk(): void {
        const { selectedUnallocatedInvestmentUuid, selectedRowKeys } = this.state;

        this.props.investmentSaleTransferAdd(selectedUnallocatedInvestmentUuid, selectedRowKeys);
        this.onClickCancel();
    }

    private onClickBack(): void {
        this.setState({
            selectedRowKeys: [],
            selectedUnallocatedInvestmentUuid: null,
        });
    }

    private onClickCancel(): void {
        this.onClickBack();
        this.props.onCancel();
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        unallocatedInvestments: investmentSaleTransfersUnallocatedInvestmentsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        investmentSaleTransferAdd: (investmentUuid: string, selectedRowKeys: string[]) => dispatch(investmentSaleTransfersAddAction(investmentUuid, selectedRowKeys)),
        unallocatedInvestmentsList: () => dispatch(investmentSaleTransfersUnallocatedInvestmentsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddModal);