import { SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Layout, Modal, Spin, Table, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IUser from '~Api/User/IUser';
import RoleEnum from '~Api/User/RoleEnum';
import { IGlobalState } from '~reducer';
import SearchDropdown from '~UI/SearchDropdown';
import { userBlockAction, usersSuspiciousListAction } from './actions';
import { usersSuspiciousSelector } from './selectors';
import './users.less';
import { IDictionary } from '~utilities/IDictionary';
import { ColumnsType } from 'antd/lib/table';
import { FilterDropdownProps } from 'antd/lib/table/interface';

const roleLabels: IDictionary<string> = {
    [RoleEnum.Borrower]: 'Borrower',
    [RoleEnum.Broker]: 'Broker',
    [RoleEnum.Investor]: 'Investor',
};

interface IPropsSelector {
    users: IDictionary<IUser>;
}

interface IPropsDispatch {
    block: (userUuid: string) => void;
    usersSuspiciousList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class List extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickBlock = this.onClickBlock.bind(this);
    }

    public componentDidMount() {
        const { users } = this.props;

        if (!users) {
            this.props.usersSuspiciousList();
        }
    }

    public render(): JSX.Element {
        const { users } = this.props;

        if (!users) {
            return (
                <Layout className='users'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Users</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const columns: ColumnsType<IUser> = [
            {
                dataIndex: 'name',
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string|number|boolean, user: IUser) => `${user.firstName || ''} ${user.lastName || ''}`.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                render: (name: string, user: IUser) => <Link to={`/users/${user.uuid}`}>{`${user.firstName || ''} ${user.lastName || ''}`.trim() || '-'}</Link>,
                sorter: (a: IUser, b: IUser) => `${a.firstName || ''} ${a.lastName || ''}`.localeCompare(`${b.firstName || ''} ${b.lastName || ''}`),
                title: 'Name',
                width: '20%',
            },
            {
                dataIndex: 'email',
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string|number|boolean, user: IUser) => user.email.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                render: (email: string) => <a href={`mailto:${email}`}>{email || '-'}</a>,
                sorter: (a: IUser, b: IUser) => a.email.localeCompare(b.email),
                title: 'Email',
            },
            {
                dataIndex: 'phone',
                render: (phone: string) => <a href={`tel:${phone}`}>{phone || '-'}</a>,
                title: 'Phone',
                width: '20%',
            },
            {
                dataIndex: 'role',
                filters: [
                    {
                        text: roleLabels[RoleEnum.Borrower],
                        value: RoleEnum.Borrower,
                    },
                    {
                        text: roleLabels[RoleEnum.Broker],
                        value: RoleEnum.Broker,
                    },
                    {
                        text: roleLabels[RoleEnum.Investor],
                        value: RoleEnum.Investor,
                    },
                ],
                onFilter: (value: string|number|boolean, user: IUser) => user.role === value,
                render: (role: RoleEnum) => roleLabels[role],
                title: 'Role',
                width: '10%',
            },
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => createdTime ? <Tooltip title={dayjs(createdTime).format('Do MMMM YYYY HH:mm:ss')}><span>{dayjs(createdTime).fromNow()}</span></Tooltip> : '-',
                sorter: (a: IUser, b: IUser) => {
                    if (!a.createdTime || !b.createdTime) {
                        return !a.createdTime ? -1 : 1;
                    }

                    return dayjs(a.createdTime) > dayjs(b.createdTime)  ? 1 : -1;
                },
                title: 'Created',
                width: '10%',
            },
            {
                render: (user: IUser) => {
                    const onClickBlock: () => void = () => this.onClickBlock(user.uuid);

                    return <Button onClick={onClickBlock} danger={true}>Block</Button>;
                },
                title: 'Actions',
                width: '10%',
            },
        ];

        return (
            <Layout className='users'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Users</Breadcrumb.Item>
                    <Breadcrumb.Item>Suspicious Activity</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Suspicious Activity</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.values(users)}
                            pagination={{ defaultPageSize: 50 }}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    private onClickBlock(userUuid: string) {
        Modal.confirm({
            content: 'Are you sure you want to block this user?',
            okText: 'Block',
            onOk: () => {
                this.props.block(userUuid);
            },
            title: 'Block User',
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        users: usersSuspiciousSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        block: (userUuid: string) => dispatch(userBlockAction(userUuid)),
        usersSuspiciousList: () => dispatch(usersSuspiciousListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(List);
