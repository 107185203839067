import { BookOutlined, CloseOutlined, EditOutlined, PhoneOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';
import { BsChat } from 'react-icons/bs';
import { FiDollarSign } from 'react-icons/fi';
import { GrNew } from 'react-icons/gr';
import ApprovalStatusEnum from '~Api/Deal/ApprovalStatusEnum';

interface IProps {
    approvalStatus: ApprovalStatusEnum;
}

type Props = IProps;

class ApprovalStatusBadge extends React.Component<Props> {
    public render(): JSX.Element {
        const { approvalStatus } = this.props;

        if (approvalStatus === ApprovalStatusEnum.Draft) {
            return <EditOutlined/>;
        }

        if (approvalStatus === ApprovalStatusEnum.FollowUp1) {
            return <Badge className='follow-up' count={1}><BsChat/></Badge>;
        }

        if (approvalStatus === ApprovalStatusEnum.FollowUp2) {
            return <Badge className='follow-up' count={2}><BsChat/></Badge>;
        }

        if (approvalStatus === ApprovalStatusEnum.FollowUp3) {
            return <Badge className='follow-up' count={3}><BsChat/></Badge>;
        }

        if (approvalStatus === ApprovalStatusEnum.InitialCall) {
            return <PhoneOutlined/>;
        }

        if (approvalStatus === ApprovalStatusEnum.UncontactableFollowUp1) {
            return <Badge className='follow-up' count={1}><PhoneOutlined/></Badge>;
        }

        if (approvalStatus === ApprovalStatusEnum.UncontactableFollowUp2) {
            return <Badge className='follow-up' count={2}><PhoneOutlined/></Badge>;
        }

        if (approvalStatus === ApprovalStatusEnum.UncontactableFollowUp3) {
            return <Badge className='follow-up' count={3}><PhoneOutlined/></Badge>;
        }

        if (approvalStatus === ApprovalStatusEnum.Quote) {
            return <FiDollarSign/>;
        }

        if (approvalStatus === ApprovalStatusEnum.Rejected) {
            return <CloseOutlined/>;
        }

        if (approvalStatus === ApprovalStatusEnum.Referred) {
            return <ShareAltOutlined />;
        }

        if (approvalStatus === ApprovalStatusEnum.Pending) {
            return <GrNew/>;
        }

        if (approvalStatus === ApprovalStatusEnum.Application) {
            return <BookOutlined/>;
        }
    }
}

export default ApprovalStatusBadge;
