enum ReferralPartnersActionsEnum {
    ReferralPartnerApprove = 'REFERRAL_PARTNER/APPROVE',
    ReferralPartnerGet = 'REFERRAL_PARTNER/GET',
    ReferralPartnerReject = 'REFERRAL_PARTNER/REJECT',
    ReferralPartnerReset = 'REFERRAL_PARTNER/RESET',
    ReferralPartnerSend = 'REFERRAL_PARTNER/SEND',
    ReferralPartnerSet = 'REFERRAL_PARTNER/SET',
    ReferralPartnerValueSet = 'REFERRAL_PARTNER/VALUE/SET',

    ReferralPartnerDealsList = 'REFERRAL_PARTNER/DEALS/LIST',
    ReferralPartnerDealsSet = 'REFERRAL_PARTNER/DEALS/SET',

    ReferralPartnerApplicationsList = 'REFERRAL_PARTNER/APPLICATIONS/LIST',
    ReferralPartnerApplicationsSet = 'REFERRAL_PARTNER/APPLICATIONS/SET',

    ReferralPartnerDocumentDelete = 'REFERRAL_PARTNER/DOCUMENT/DELETE',
    ReferralPartnerDocumentRemove = 'REFERRAL_PARTNER/DOCUMENT/REMOVE',
    ReferralPartnerDocumentSet = 'REFERRAL_PARTNER/DOCUMENT/SET',

    ReferralPartnerDocumentsAdd = 'REFERRAL_PARTNER/DOCUMENTS/ADD',
    ReferralPartnerDocumentsList = 'REFERRAL_PARTNER/DOCUMENTS/LIST',
    ReferralPartnerDocumentsSet = 'REFERRAL_PARTNER/DOCUMENTS/SET',

    ReferralPartnerHistoriesList = 'REFERRAL_PARTNER/HISTORIES/LIST',
    ReferralPartnerHistoriesSet = 'REFERRAL_PARTNER/HISTORIES/SET',

    ReferralPartnersAdd = 'REFERRAL_PARTNERS/ADD',
    ReferralPartnersList = 'REFERRAL_PARTNERS/LIST',
    ReferralPartnersSet = 'REFERRAL_PARTNERS/SET',

    ReferralPartnerNotesAdd = 'REFERRAL_PARTNER/NOTES/ADD',
    ReferralPartnerNotesList = 'REFERRAL_PARTNER/NOTES/LIST',
    ReferralPartnerNotesSet = 'REFERRAL_PARTNER/NOTES/SET',

    ReferralPartnerNoteRemove = 'REFERRAL_PARTNER/NOTE/REMOVE',
    ReferralPartnerNoteSet = 'REFERRAL_PARTNER/NOTE/SET',
}

export default ReferralPartnersActionsEnum;
