import { DownOutlined, LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { G2, Pie } from '@ant-design/plots';
import { IGroup } from '@antv/g2';
import { PieOptions } from '@antv/g2plot';
import { IG } from '@antv/g2/lib/dependents';
import { Datum, MappingDatum } from '@antv/g2/lib/interface.d';
import { Breadcrumb, Button, Card, Col, Dropdown, Form, Layout, MenuProps, Radio, RadioChangeEvent, Row, Space, Spin, Statistic, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import ISettlementReportApplication from '~Api/Application/ISettlementReportApplication';
import { loanPurposeLabels } from '~Api/Application/loanPurposeLabels';
import zoneTypeLabels from '~Api/Deal/ZoneTypeLabels';
import { IGlobalState } from '~reducer';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';
import { IDictionary } from '~utilities/IDictionary';
import { CountOrDollarEnum, DirectionEnum, IPieData, PeriodRangeEnum, directionToParameterMap, periodRangeLabels, rangeToParameterMap } from '~utilities/reportUtilities';
import DatePicker from '~UI/DatePicker';
import {
    IApplicationsSettlementReportListAction,
    applicationsSettlementReportListAction,
} from './actions';
import {
    applicationsSettlementReportApplicationSelector,
    applicationsSettlementReportEndDateSelector,
    applicationsSettlementReportPeriodRangeSelector,
    applicationsSettlementReportStartDateSelector,
} from './selectors';
import './settlement-report.less';

interface IPropsSelector {
    endDate: string;
    selectedPeriodRange: PeriodRangeEnum,
    settlementReportApplications: IDictionary<ISettlementReportApplication>;
    startDate: string;
}

interface IPropsDispatch {
    settlementReportApplicationsList: (startDate: string, endDate: string, periodRange: PeriodRangeEnum) => IApplicationsSettlementReportListAction
}

interface IState {
    countOrDollar: CountOrDollarEnum;
}

type Props = IPropsSelector & IPropsDispatch;

class SettlementReport extends React.Component<Props> {
    public state: IState = {
        countOrDollar: CountOrDollarEnum.Dollar,
    };

    constructor(props: Props) {
        super(props);
        this.onChangeCountValueSwitcher = this.onChangeCountValueSwitcher.bind(this);
        this.onChangeDateRange = this.onChangeDateRange.bind(this);
        this.onChangeSelectedPeriodRange = this.onChangeSelectedPeriodRange.bind(this);
        this.onClickPrevious = this.onClickPrevious.bind(this);
        this.onClickNext = this.onClickNext.bind(this);
    }

    public componentDidMount(): void {
        this.props.settlementReportApplicationsList(
            dayjs().startOf('week').format('YYYY-MM-DD'),
            dayjs().endOf('week').format('YYYY-MM-DD'),
            PeriodRangeEnum.Week
        );
    }

    public render(): JSX.Element {
        const { endDate, settlementReportApplications, selectedPeriodRange, startDate } = this.props;
        const { countOrDollar } = this.state;

        const periodRangeMenu: MenuProps = {
            items: [
                {
                    key: 'day',
                    label: 'Day',
                    onClick: () => this.onChangeSelectedPeriodRange(PeriodRangeEnum.Day),
                },
                {
                    key: 'week',
                    label: 'Week',
                    onClick: () => this.onChangeSelectedPeriodRange(PeriodRangeEnum.Week),
                },
                {
                    key: 'month',
                    label: 'Month',
                    onClick: () => this.onChangeSelectedPeriodRange(PeriodRangeEnum.Month),
                },
                {
                    key: 'year',
                    label: 'Year',
                    onClick: () => this.onChangeSelectedPeriodRange(PeriodRangeEnum.Year),
                },
            ],
        };

        const dateRangeSelector: JSX.Element = (
            <Space className='date-picker'>
                <Button.Group>
                    <Button icon={<LeftOutlined/>} onClick={this.onClickPrevious} disabled={!settlementReportApplications}/>
                    <Dropdown menu={periodRangeMenu} disabled={!settlementReportApplications}>
                        <Button>
                            <Space>
                                {periodRangeLabels[selectedPeriodRange]}
                                <DownOutlined/>
                            </Space>
                        </Button>
                    </Dropdown>
                    <Button icon={<RightOutlined/>} onClick={this.onClickNext} disabled={!settlementReportApplications}/>
                </Button.Group>
                <Form.Item>
                    <DatePicker.RangePicker
                        picker='date'
                        format='DD/MM/YYYY'
                        onChange={this.onChangeDateRange}
                        value={[startDate ? dayjs(startDate) : null, endDate ? dayjs(endDate) : null]}
                        disabled={!settlementReportApplications}
                    />
                </Form.Item>
            </Space>
        );

        const countValueSwitcher: JSX.Element = (
            <Radio.Group
                value={countOrDollar}
                onChange={this.onChangeCountValueSwitcher}
                disabled={!settlementReportApplications}
            >
                <Radio.Button value={CountOrDollarEnum.Dollar}>Value</Radio.Button>
                <Radio.Button value={CountOrDollarEnum.Count}>Count</Radio.Button>
            </Radio.Group>
        );

        if (!settlementReportApplications) {
            return (
                <Layout className='applications-settlement-report'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/applications'>Applications</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>New Settlement Report</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Space className='actions'>
                                {dateRangeSelector}
                                {countValueSwitcher}
                            </Space>
                            <Typography.Title level={2}>New Settlement Report</Typography.Title>
                        </Layout.Content>
                    </Layout>
                    <Spin />
                </Layout>
            );
        }

        const aggregatorData: IDictionary<IPieData> = {};
        const bdmData: IDictionary<IPieData> = {};
        const brokerData: IDictionary<IPieData> = {};
        const loanPurposeData: IDictionary<IPieData> = {};
        const lvrData: IDictionary<IPieData> = {};
        const propertyLocationData: IDictionary<IPieData> = {};
        const propertyTypeData: IDictionary<IPieData> = {};
        let settledApplicationsCount: number = 0;
        let settledApplicationsValue: number = 0;

        _.forEach(settlementReportApplications, (application: ISettlementReportApplication) => {
            const brokerLabel: string = application.brokerUuid === null ? 'Direct' : `${application.brokerFirstName} ${application.brokerLastName}`;
            if (!brokerData[application.brokerUuid]) {
                brokerData[application.brokerUuid] = {
                    type: brokerLabel,
                    value: 0,
                };
            }
            brokerData[application.brokerUuid].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : application.loanAmount);

            if (application.aggregatorUuid === null) {
                if (application.brokerUuid === null) {
                    if (!aggregatorData['NO_BROKER']) {
                        aggregatorData['NO_BROKER'] = {
                            type: 'No Broker',
                            value: 0,
                        };
                    }
                    aggregatorData['NO_BROKER'].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : application.loanAmount);
                } else {
                    if (!aggregatorData['NO_AGGREGATOR']) {
                        aggregatorData['NO_AGGREGATOR'] = {
                            type: 'No Aggregator',
                            value: 0,
                        };
                    }
                    aggregatorData['NO_AGGREGATOR'].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : application.loanAmount);
                }
            } else {
                if (!aggregatorData[application.aggregatorUuid]) {
                    aggregatorData[application.aggregatorUuid] = {
                        type: application.aggregatorName,
                        value: 0,
                    };
                }
                aggregatorData[application.aggregatorUuid].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : application.loanAmount);
            }

            const loanPurposeType: string = application.loanPurpose === null ? 'Unknown' : loanPurposeLabels[application.loanPurpose];
            if (!loanPurposeData[application.loanPurpose]) {
                loanPurposeData[application.loanPurpose] = {
                    type: loanPurposeType,
                    value: 0,
                };
            }
            loanPurposeData[application.loanPurpose].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : application.loanAmount);

            const lvr: number = application.lvr === null ? 0 : application.lvr;
            const lvrRangeStart: number = Math.floor(lvr / 10) * 10;
            const lvrRangeEnd: number = lvrRangeStart + 10;
            const lvrRange: string = lvrRangeStart === 0 ? `0% - ${lvrRangeEnd}%` : `${lvrRangeStart}% - ${lvrRangeEnd}%`;
            if (!lvrData[lvrRange]) {
                lvrData[lvrRange] = {
                    type: lvrRange,
                    value: 0,
                };
            }
            lvrData[lvrRange].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : application.loanAmount);

            const propertyLocationType: string = application.propertyLocation === null ? 'Others' : application.propertyLocation;
            if (!propertyLocationData[application.propertyLocation]) {
                propertyLocationData[application.propertyLocation] = {
                    type: propertyLocationType,
                    value: 0,
                };
            }
            propertyLocationData[application.propertyLocation].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : application.loanAmount);

            const propertyType: string = application.propertyType === null ? 'Others' : zoneTypeLabels[application.propertyType];
            if (!propertyTypeData[application.propertyType]) {
                propertyTypeData[application.propertyType] = {
                    type: propertyType,
                    value: 0,
                };
            }
            propertyTypeData[application.propertyType].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : application.loanAmount);

            const bdmLabel: string = application.bdmUuid === null ? 'No BDM' : application.bdmName;
            if (!bdmData[application.bdmUuid]) {
                bdmData[application.bdmUuid] = {
                    type: bdmLabel,
                    value: 0,
                };
            }
            bdmData[application.bdmUuid].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : application.loanAmount);

            settledApplicationsCount++;
            settledApplicationsValue += application.loanAmount;
        });

        return (
            <Layout className='applications-settlement-report'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/applications'>Applications</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>New Settlement Report</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            {dateRangeSelector}
                            {countValueSwitcher}
                        </Space>
                        <Typography.Title level={2}>New Settlement Report</Typography.Title>
                    </Layout.Content>
                </Layout>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} className='settled-applications-count'>
                            <Typography.Title level={4}>Settled New Applications Count</Typography.Title>
                            <Statistic
                                value={settledApplicationsCount}
                                precision={0}
                                prefix={<PlusOutlined/>}
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} className='settled-applications-value'>
                            <Typography.Title level={4}>Settled New Applications Value</Typography.Title>
                            <Statistic
                                value={currencyFormatter.format(settledApplicationsValue)}
                                precision={0}
                                prefix={<PlusOutlined/>}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} className='pie-chart-left'>
                            <Typography.Title level={4}>Broker</Typography.Title>
                            {this.renderPieChart(this.topsSelector(_.values(brokerData), 15))}
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} className='pie-chart-right'>
                            <Typography.Title level={4}>Aggregator</Typography.Title>
                            {this.renderPieChart(this.topsSelector(_.values(aggregatorData), 15))}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} className='pie-chart-left'>
                            <Typography.Title level={4}>Loan Purpose</Typography.Title>
                            {this.renderPieChart(_.values(loanPurposeData))}
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} className='pie-chart-right'>
                            <Typography.Title level={4}>LVR</Typography.Title>
                            {this.renderPieChart(_.values(lvrData))}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} className='pie-chart-left'>
                            <Typography.Title level={4}>Property Type</Typography.Title>
                            {this.renderPieChart(_.values(propertyTypeData))}
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} className='pie-chart-right'>
                            <Typography.Title level={4}>Property Location</Typography.Title>
                            {this.renderPieChart(_.values(propertyLocationData))}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} className='pie-chart-left'>
                            <Typography.Title level={4}>BDM</Typography.Title>
                            {this.renderPieChart(_.values(bdmData))}
                        </Card>
                    </Col>
                </Row>
            </Layout>
        );
    }

    private onChangeSelectedPeriodRange(selectedPeriodRange: PeriodRangeEnum): void {
        const updatedEndDate: string = dayjs().endOf(rangeToParameterMap[selectedPeriodRange]).format('YYYY-MM-DD');
        const updatedStartDate: string = dayjs().startOf(rangeToParameterMap[selectedPeriodRange]).format('YYYY-MM-DD');

        this.props.settlementReportApplicationsList(updatedStartDate, updatedEndDate, selectedPeriodRange);
    }

    private onClickPrevious(): void {
        this.onChangePeriod(DirectionEnum.Previous);
    }

    private onClickNext(): void {
        this.onChangePeriod(DirectionEnum.Next);
    }

    private onChangeDateRange(dateRange: [Dayjs, Dayjs]): void {
        const { selectedPeriodRange } = this.props;
        const updatedEndDate: string = dateRange[1].format('YYYY-MM-DD');
        const updatedStartDate: string = dateRange[0].format('YYYY-MM-DD');
        this.props.settlementReportApplicationsList(updatedStartDate, updatedEndDate, selectedPeriodRange);
    }

    private onChangePeriod(direction: DirectionEnum): void {
        const { selectedPeriodRange, startDate } = this.props;

        const from: Dayjs = (startDate ? dayjs(startDate) : dayjs());
        const updatedStartDate: Dayjs = from.add(directionToParameterMap[direction], rangeToParameterMap[selectedPeriodRange]).startOf(rangeToParameterMap[selectedPeriodRange]);
        const updatedEndDate: Dayjs = updatedStartDate.endOf(rangeToParameterMap[selectedPeriodRange]);

        this.props.settlementReportApplicationsList(updatedStartDate.format('YYYY-MM-DD'), updatedEndDate.format('YYYY-MM-DD'), selectedPeriodRange);
    }

    private onChangeCountValueSwitcher(e: RadioChangeEvent): void {
        this.setState({
            countOrDollar: e.target.value,
        });
    }

    private renderPieChart(data: IPieData[]): JSX.Element {
        const G: IG = G2.getEngine('canvas');
        const labelFormatter: (data: Datum, mappingData: MappingDatum) => IGroup = (data: Datum, mappingData: MappingDatum) => {
            const group: IGroup = new G.Group({});
            group.addShape({
                attrs: {
                    fill: mappingData.color,
                    height: 50,
                    r: 5,
                    width: 40,
                    x: 0,
                    y: 0,
                },
                type: 'circle',
            });
            group.addShape({
                attrs: {
                    fill: mappingData.color,
                    text: `${data.type}`,
                    x: 10,
                    y: 8,
                },
                type: 'text',
            });
            group.addShape({
                attrs: {
                    fill: 'rgba(0, 0, 0, 0.65)',
                    fontWeight: 700,
                    text: (this.state.countOrDollar === CountOrDollarEnum.Count)
                        ? `${data.value} - ${percentageFormatter.format(data.percent)}`
                        : `${currencyFormatter.format(data.value)} - ${percentageFormatter.format(data.percent)}`,
                    x: 0,
                    y: 25,
                },
                type: 'text',
            });
            return group;
        };

        const config: PieOptions = {
            angleField: 'value',
            appendPadding: 10,
            colorField: 'type',
            data,
            interactions: [
                {
                    type: 'element-selected',
                },
                {
                    type: 'element-active',
                },
            ],
            label: {
                formatter: labelFormatter,
                labelHeight: 40,
                type: 'spider',
            },
            radius: 0.75,
            tooltip: {
                formatter: (data: Datum) => {
                    return {
                        name: data.type,
                        value: (this.state.countOrDollar === CountOrDollarEnum.Count) ? data.value : `${currencyFormatter.format(data.value)}`,
                    };
                },
            },
        };

        return <Pie {...config} />;
    }

    private topsSelector: (data: IPieData[], shortlist: number) => IPieData[] = (data: IPieData[], shortlist: number) => {
        if (data.length > shortlist) {
            data.sort((a: IPieData, b: IPieData) => b.value - a.value);

            const tops: IPieData[] = data.slice(0, shortlist);
            const others: IPieData = data.slice(shortlist+1).reduce((acc: IPieData, curr: IPieData) => {
                acc.value += curr.value;
                return acc;
            }, { type: 'Others', value: 0 });

            return [...tops, others];
        }

        return data;
    };
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        endDate: applicationsSettlementReportEndDateSelector(state),
        selectedPeriodRange: applicationsSettlementReportPeriodRangeSelector(state),
        settlementReportApplications: applicationsSettlementReportApplicationSelector(state),
        startDate: applicationsSettlementReportStartDateSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        settlementReportApplicationsList: (startDate: string, endDate: string, periodRange: PeriodRangeEnum) => dispatch(applicationsSettlementReportListAction(startDate, endDate, periodRange)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettlementReport);
