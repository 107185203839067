import { Breadcrumb, Layout, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import accountTypeLabels from '~Api/Investor/accountTypeLabels';
import IAccount from '~Api/Investor/IAccount';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import './investors.less';
import { investorAccountsPendingApprovalListAction } from './actions';
import { investorAccountsPendingApprovalSelector } from './selectors';

interface IPropsSelector {
    investorAccounts: IDictionary<IAccount>;
}

interface IPropsDispatch {
    investorAccountsPendingApprovalList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class PendingApproval extends React.Component<Props> {
    public componentDidMount(): void {
        this.props.investorAccountsPendingApprovalList();
    }

    public render(): JSX.Element {
        const { investorAccounts } = this.props;

        if (!investorAccounts) {
            return (
                <Layout className='investors'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/investors'>Investors</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Pending Approval</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Pending Approval</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnType<IAccount>[] = [
            {
                render: (account: IAccount) => accountTypeLabels[account.accountType] || account.accountType,
                title: 'Account Type',
                width: '10%',
            },
            {
                render: (account: IAccount) => <Link to={`/investors/${account.investorUuid}/accounts/${account.uuid}`}>{account.code || '-'}</Link>,
                title: 'Account ID',
                width: '10%',
            },
            {
                render: (account: IAccount) => <Link to={`/investors/${account.investorUuid}`}>{account.investorName || '-'}</Link>,
                title: 'Name',
            },
            {
                render: (account: IAccount): string => currencyFormatter.format(account.balance),
                sorter: (a: IAccount, b: IAccount): number => {
                    return a.balance > b.balance ? 1 : -1;
                },
                title: 'Balance',
                width: '15%',
            },
            {
                defaultSortOrder: 'descend',
                render: (account: IAccount): string => currencyFormatter.format(account.depositAmountPending),
                sorter: (a: IAccount, b: IAccount): number => {
                    return a.depositAmountPending > b.depositAmountPending ? 1 : -1;
                },
                title: 'Pending Topup Amount',
                width: '15%',
            },
        ];

        return (
            <Layout className='investors'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/investors'>Investors</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Pending Approval</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Pending Approval</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.values(investorAccounts)}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        investorAccounts: investorAccountsPendingApprovalSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        investorAccountsPendingApprovalList: () => dispatch(investorAccountsPendingApprovalListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PendingApproval);
