import { all, call, put, takeEvery } from '@redux-saga/core/effects';
import { IFetchResponse } from '~utilities/fetch';
import { countriesSetAction, ICountriesGetAction } from './actions';
import CountriesActionsEnum from './ActionsEnum';
import ICountry from './ICountry';
import { parseCountry } from './parsers';
import { countryListRequest } from './requests';

function* countriesGet(action: ICountriesGetAction): Iterator<any> {
    const rawCountries: IFetchResponse = yield call(countryListRequest);
    const countries: ICountry[] = rawCountries.body.map(parseCountry);
    yield put(countriesSetAction(countries));
}

export function* CountriesSagas(): any {
    yield all([
        takeEvery(CountriesActionsEnum.CountriesGet, countriesGet),
    ]);
}
