import _ from 'lodash';
import { AnyAction } from 'redux';
import { ICountriesSetAction } from './actions';
import CountriesActionsEnum from './ActionsEnum';
import ICountry from './ICountry';

export interface ICountriesState {
    countries: ICountry[];
}

const initialData: ICountriesState = {
    countries: null,
};

export function countriesReducer(state: ICountriesState = initialData, action: AnyAction): ICountriesState {
    switch (action.type) {
        case CountriesActionsEnum.CountriesSet: {
            const typedAction: ICountriesSetAction = action as ICountriesSetAction;

            return {
                ...state,
                countries: typedAction.countries,
            };
        }

        default:
            return state;
    }
}
