import { IDictionary } from '~utilities/IDictionary';
import ReferredToEnum from '../Api/Deal/ReferredToEnum';

const referredToLabels: IDictionary<string> = {
    [ReferredToEnum.Bizcap]: 'Bizcap',
    [ReferredToEnum.DirectCredit]: 'Direct Credit',
    [ReferredToEnum.TLC]: 'TLC',
};

export default referredToLabels;
