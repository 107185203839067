import IBroker from './IBroker';
import INote from './INote';

export function transformBroker(broker: IBroker): any {
    return {
        aclNumber: broker.aclNumber,
        aggregatorUuid: broker.aggregatorUuid,
        bdmUuid: broker.bdmUuid,
        companyName: broker.companyName,
        email: broker.email,
        firstName: broker.firstName,
        hasBeenBankrupted: broker.hasBeenBankrupted,
        hasBeenConvicted: broker.hasBeenConvicted,
        hasIndemnityInsurance: broker.hasIndemnityInsurance,
        lastName: broker.lastName,
        linkedInUrl: broker.linkedInUrl,
        phone: broker.phone,
        postalAddress: broker.postalAddress,
        postcode: broker.postcode,
        state: broker.state,
        suburb: broker.suburb,
        type: broker.type,
    };
}

export function transformBrokerNote(note: INote): any {
    return {
        note: note.note,
    };
}
