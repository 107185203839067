import { AutoComplete, Input } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IDeal from '~Api/Deal/IDeal';
import history from '~history';
import {
    leadsSearchAction,
    leadsSearchResultsClearAction,
} from '~Leads/actions';
import { leadsSearchResultsSelector } from '~Leads/selectors';
import { IGlobalState } from '~reducer';

interface IOption {
    label: JSX.Element;
    value: string;
}

interface IState {
    searchText: string;
}

interface IPropsSelector {
    dealSearchResults: IDeal[];
}

interface IPropsDispatch {
    leadsSearchResultsClear: () => void;
    leadsSearch: (keyword: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Search extends React.Component<Props, IState> {
    public state: IState = {
        searchText: '',
    };

    constructor(props: Props) {
        super(props);

        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    public render(): JSX.Element {
        const { dealSearchResults } = this.props;
        const { searchText } = this.state;

        const searchResults: IDeal[] = dealSearchResults ?? [];

        const options: IOption[] = searchResults.map((deal: IDeal) => {
            const label: JSX.Element = (
                <div className='lead-search-option'>
                    <span className='option-code'>{deal.code}</span>
                    <span className='option-name'>{deal.firstName || '-'} {deal.lastName || '-'}</span>
                </div>
            );

            return {
                label,
                value: `${deal.uuid}`,
            };
        });

        return (
            <AutoComplete
                dropdownMatchSelectWidth={300}
                onSearch={this.onSearch}
                onSelect={this.onSelect}
                options={_.slice(options, 0, 20)}
                value={searchText}
            >
                <Input.Search/>
            </AutoComplete>
        );
    }

    private onSearch(value: string) {
        this.setState({
            searchText: value,
        });

        this.props.leadsSearch(value);
    }

    private onSelect(value: string) {
        this.props.leadsSearchResultsClear();

        history.push(`/leads/${value}`);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        dealSearchResults: leadsSearchResultsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        leadsSearch: (keyword: string) => dispatch(leadsSearchAction(keyword)),
        leadsSearchResultsClear: () => dispatch(leadsSearchResultsClearAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Search);
