import _ from 'lodash';
import { fetchGet, fetchPost } from '~utilities/fetch';
import AccountTypeEnum from './AccountTypeEnum';

export async function withdrawalAbaGetRequest(withdrawalAbaUuid: string): Promise<Response> {
    return fetchGet(`/withdrawal-abas/${withdrawalAbaUuid}`);
}

export async function withdrawalAbaSendRequest(withdrawalAbaUuid: string): Promise<Response> {
    return fetchPost(`/withdrawal-abas/${withdrawalAbaUuid}/send`);
}

export async function withdrawalAbasAddRequest(accountType: AccountTypeEnum, investorTransactionsUuids: string[]): Promise<Response> {
    return fetchPost('/withdrawal-abas', { accountType, investorTransactionsUuids });
}

export async function withdrawalAbasListRequest(): Promise<Response> {
    return fetchGet('/withdrawal-abas');
}

export async function withdrawalAbasUnallocatedInvestorTransactionsListRequest(accountType: AccountTypeEnum): Promise<Response> {
    return fetchGet('/withdrawal-abas/unallocated-investor-transactions', {
        accountType,
    });
}

export async function withdrawalAbasInvalidInvestorTransactionsListRequest(accountType: AccountTypeEnum): Promise<Response> {
    return fetchGet('/withdrawal-abas/invalid-investor-transactions', {
        accountType,
    });
}

export async function withdrawalAbaHistoriesListRequest(withdrawalAbaUuid: string): Promise<Response> {
    return fetchGet(`/withdrawal-abas/${withdrawalAbaUuid}/history`);
}

export async function withdrawalAbaInvestorTransactionsListRequest(withdrawalAbaUuid: string): Promise<Response> {
    return fetchGet(`/withdrawal-abas/${withdrawalAbaUuid}/investor-transactions`);
}
