import { WarningOutlined } from '@ant-design/icons';
import { Button, Descriptions, Popover, Space, Spin, Table, Tag, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { FiMapPin } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import roundTo from 'round-to';
import { currentAdministratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import BorrowerCategoryEnum from '~Api/Application/BorrowerCategoryEnum';
import BorrowerTypeEnum from '~Api/Application/BorrowerTypeEnum';
import ExtensionTypeEnum from '~Api/Application/ExtensionTypeEnum';
import IApplication from '~Api/Application/IApplication';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IBorrower from '~Api/Application/IBorrower';
import MortgageTypeEnum from '~Api/Application/MortgageTypeEnum';
import IProperty from '~Api/Deal/IProperty';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';
import {
    applicationBorrowersListAction,
    applicationGetAction,
} from '~Applications/actions';
import {
    applicationBorrowersSelector,
    applicationDealPropertiesSelector,
    applicationSelector,
} from '~Applications/selectors';
import PostcodeCategory from '~Deals/PostcodeCategory';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import { ColumnType } from 'antd/lib/table';
import { calculateNetPrepaidBalanceOnSettlement } from '../utilities';

const categoryLabels: IDictionary<string> = {
    [BorrowerCategoryEnum.Borrower]: 'Borrower',
    [BorrowerCategoryEnum.Guarantor]: 'Guarantor',
    [BorrowerCategoryEnum.Trustee]: 'Trustee',
};

const mortgageTypeLabels: IDictionary<string> = {
    [MortgageTypeEnum.FirstMortgage]: 'First Mortgage',
    [MortgageTypeEnum.SecondMortgage]: 'Second Mortgage',
};

const typeLabels: IDictionary<string> = {
    [BorrowerTypeEnum.Company]: 'Company',
    [BorrowerTypeEnum.Individual]: 'Individual',
    [BorrowerTypeEnum.Trust]: 'Trust',
};

const zoneTypeLabels: IDictionary<string> = {
    [ZoneTypeEnum.ResidentialHouse]: 'Residential - House',
    [ZoneTypeEnum.ResidentialTownhouse]: 'Residential - Townhouse / Villa',
    [ZoneTypeEnum.ResidentialUnit]: 'Residential - Unit / Apartment',
    [ZoneTypeEnum.ResidentialLand]: 'Residential - Land',
    [ZoneTypeEnum.CommercialOffice]: 'Commercial - Office',
    [ZoneTypeEnum.CommercialRetail]: 'Commercial - Retail',
    [ZoneTypeEnum.CommercialIndustrial]: 'Commercial - Industrial',
    [ZoneTypeEnum.CommercialLand]: 'Commercial - Land',
    [ZoneTypeEnum.RuralResidential]: 'Rural - Residential',
    [ZoneTypeEnum.RuralLand]: 'Rural - Land',
};

interface IMatch {
    applicationUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    application: IApplication;
    borrowers: IBorrower[];
    currentAdministrator: IAdministrator;
    dealProperties: IDictionary<IProperty>;
}

interface IPropsDispatch {
    applicationGet: () => void;
    borrowersList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Overview extends React.Component<Props> {
    public componentDidMount(): void {
        const { application, borrowers } = this.props;

        if (!application) {
            this.props.applicationGet();
        }

        if (!borrowers) {
            this.props.borrowersList();
        }
    }

    public render(): JSX.Element {
        const { application, borrowers, currentAdministrator, match, dealProperties } = this.props;

        if (!application || !borrowers || !currentAdministrator || !dealProperties) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='overview'>
                    <Typography.Title level={2}>Overview</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        if (ExtensionTypeEnum.GracePeriod === application.extensionType) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='overview'>
                    <Typography.Title level={2}>Overview</Typography.Title>
                    <p>This application is for a Grace Period. For details please refer to the previous loan and application.</p>
                </Layout>
            );
        }

        let valuationsComplete: boolean = true;
        let propertyValue: number = 0;

        _.forEach(application.properties, (applicationProperty: IApplicationProperty) => {
            if (!!applicationProperty.valuationValue) {
                propertyValue += applicationProperty.valuationValue;
            } else {
                valuationsComplete = false;
                propertyValue += dealProperties[applicationProperty.dealPropertyUuid].estimatedValue;
            }
        });

        const totalCurrentDebt: number = application.payoutFigure ? application.payoutFigure.balanceAmount : _.sumBy(_.values(dealProperties), 'currentDebt');

        const interestRateDefault: number = application.interestRateDefault || (application.interestRate + 10);
        const interestPayable: number = Math.ceil(application.loanAmount * (application.interestRate / 100) / 12 * application.termMonths);

        const netPrepaidBalanceOnSettlement: number = calculateNetPrepaidBalanceOnSettlement(application, dealProperties);

        const calculatedLvr: number = roundTo(((application.loanAmount + (application.mortgageType === MortgageTypeEnum.FirstMortgage ? 0 : totalCurrentDebt || 0)) / propertyValue * 100), 2);
        const lvrDifference: number = calculatedLvr - application.lvr;

        const monthlyInterestPaymentsNormal: number = Math.ceil(application.loanAmount * (application.interestRate / 100) / 12);
        const monthlyInterestPaymentsDefault: number = Math.ceil(application.loanAmount * (interestRateDefault / 100) / 12);
        const grossBalanceOnSettlement: number = application.loanAmount - interestPayable - application.establishmentFee - application.applicationFee - application.brokerageFee - application.legalFees - application.estimatedOutlays;

        const totalCostsDollars: number = application.loanAmount - netPrepaidBalanceOnSettlement;
        const totalCostsPercentage: number = (application.loanAmount - netPrepaidBalanceOnSettlement) / application.loanAmount * 100;
        const totalCostsPercentagePerAnnum: number = (application.establishmentFee + application.applicationFee + application.brokerageFee + application.legalFees + application.estimatedOutlays + (monthlyInterestPaymentsNormal * 12)) / application.loanAmount * 100;

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const lvrBlock: JSX.Element = lvrDifference !== 0 ? (
            <Popover content='LVR difference - The property value has changed and an LVR recalculation is required.'>
                <Typography.Text type='danger'>{percentageFormatter.format((application.lvr) / 100)} <WarningOutlined/></Typography.Text>
            </Popover>
        ) : (
            <Typography.Text>{percentageFormatter.format((application.lvr) / 100)}</Typography.Text>
        );

        const netBalanceOnSettlementBlock: JSX.Element = application.payoutFigure && netPrepaidBalanceOnSettlement < 0 ? (
            <Popover content={'Net payout amount can\'t be lower than $0'}>
                <Typography.Text type='danger'>{currencyFormatter.format(netPrepaidBalanceOnSettlement)} <WarningOutlined/></Typography.Text>
            </Popover>
        ) : (
            <Typography.Text>{currencyFormatter.format(netPrepaidBalanceOnSettlement)}</Typography.Text>
        );

        const borrowersColumns: ColumnType<IBorrower>[] = [
            {
                render: (borrower: IBorrower) => (
                    <Space>
                        <Link to={`/applications/${application.uuid}/borrowers/${borrower.uuid}`}>{borrower.dealBorrower.formattedName || '-'}</Link>
                        {application.primaryBorrowerUuid === borrower.uuid && <Tag>Primary</Tag>}
                    </Space>
                ),
                title: 'Name',
            },
            {
                render: (borrower: IBorrower) => <a href={`tel:${borrower.dealBorrower.phone}`}>{borrower.dealBorrower.phone}</a>,
                title: 'Phone',
                width: '15%',
            },
            {
                render: (borrower: IBorrower) => <a href={`mailto:${borrower.dealBorrower.email}`}>{borrower.dealBorrower.email}</a>,
                title: 'Email',
                width: '20%',
            },
            {
                render: (borrower: IBorrower) => categoryLabels[borrower.dealBorrower.category],
                title: 'Category',
                width: '10%',
            },
            {
                render: (borrower: IBorrower) => typeLabels[borrower.dealBorrower.type],
                title: 'Type',
                width: '10%',
            },
        ];

        const propertiesColumns: ColumnType<IProperty>[] = [
            {
                dataIndex: 'formattedAddress',
                render: (formattedAddress: string, dealProperty: IProperty) => (
                    <Space>
                        {formattedAddress}
                        <PostcodeCategory property={dealProperty} />
                    </Space>
                ),
                title: 'Address',
            },
            {
                dataIndex: 'formattedAddress',
                render: (formattedAddress: string) => <Button href={`https://www.google.com/maps/place/${formattedAddress}`} target='_blank' type='link' danger={true}><FiMapPin /></Button>,
                width: '5%',
            },
            {
                render: (property: IProperty) => {
                    const applicationProperty: IApplicationProperty = _.find(application.properties, (loopApplicationProperty: IApplicationProperty) => loopApplicationProperty.dealPropertyUuid === property.uuid);

                    if (applicationProperty.valuationValue) {
                        return currencyFormatter.format(applicationProperty.valuationValue);
                    }

                    if (property.estimatedValue) {
                        return <Popover content='Estimated value - Professional property valuation required.'>{currencyFormatter.format(property.estimatedValue)} <WarningOutlined/></Popover>;
                    }

                    return '-';
                },
                title: 'Property Value',
                width: '15%',
            },
            {
                dataIndex: 'currentDebt',
                render: (currentDebt: number) => currentDebt ? currencyFormatter.format(currentDebt) : '-',
                title: 'Current Debt',
                width: '15%',
            },
            {
                dataIndex: 'zoneType',
                render: (zoneType: ZoneTypeEnum) => zoneType ? zoneTypeLabels[zoneType] : '-',
                title: 'Zoning',
                width: '20%',
            },
        ];

        let currentDebtText: string = currencyFormatter.format(totalCurrentDebt);
        if (ExtensionTypeEnum.Renewal === application.extensionType) {
            currentDebtText = application.payoutFigure
                ? `${currencyFormatter.format(totalCurrentDebt)} (Payout Figure)`
                : 'Not Applicable';
        }

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='overview'>
                <Typography.Title level={2}>Overview</Typography.Title>
                <Typography.Title level={3}>Borrowers</Typography.Title>
                <Table
                    columns={borrowersColumns}
                    dataSource={borrowers}
                    pagination={false}
                    rowKey='uuid'
                />
                <Typography.Title level={3}>Properties</Typography.Title>
                <Table
                    columns={propertiesColumns}
                    dataSource={_.values(dealProperties)}
                    pagination={false}
                    rowKey='uuid'
                />
                {!application.legacy && ![RoleEnum.BusinessDevelopmentManager, RoleEnum.InternalBusinessDevelopmentManager, RoleEnum.SeniorBusinessDevelopmentManager].includes(currentAdministrator.role) && <Link className='recalculate' to={`/applications/${application.uuid}/calculator`}><Button>Recalculate</Button></Link>}
                <Typography.Title level={3}>Breakdown</Typography.Title>
                <Descriptions bordered={true} column={2}>
                    <Descriptions.Item label='Loan Amount'>{currencyFormatter.format(application.loanAmount)}</Descriptions.Item>
                    <Descriptions.Item label='Mortgage Type'>{mortgageTypeLabels[application.mortgageType]}</Descriptions.Item>
                    <Descriptions.Item label='Term'>{application.termMonths} months</Descriptions.Item>
                    <Descriptions.Item label='Property Value'>{!valuationsComplete ? <Popover content='Estimated value - Professional property valuation required.'>{currencyFormatter.format(propertyValue)} <WarningOutlined/></Popover> : currencyFormatter.format(propertyValue)}</Descriptions.Item>
                    <Descriptions.Item label='Interest Rate'>{percentageFormatter.format(application.interestRate / 100)} ({percentageFormatter.format(interestRateDefault / 100)} default)</Descriptions.Item>
                    <Descriptions.Item label='Current Debt'>{currentDebtText}</Descriptions.Item>
                    <Descriptions.Item label='Maximum LVR'>{percentageFormatter.format((application.maximumLvr || application.lvr) / 100)}</Descriptions.Item>
                    <Descriptions.Item label='LVR'>{lvrBlock}</Descriptions.Item>
                    <Descriptions.Item label='Commitment Fee'>{currencyFormatter.format(application.commitmentFee)}</Descriptions.Item>
                    <Descriptions.Item label='Interest Payable'>{currencyFormatter.format(interestPayable)}</Descriptions.Item>
                    <Descriptions.Item label={ExtensionTypeEnum.Renewal === application.extensionType ? 'Renewal Fee' : 'Establishment Fee'}>{currencyFormatter.format(application.establishmentFee)} ({percentageFormatter.format(application.establishmentFee / application.loanAmount)})</Descriptions.Item>
                    <Descriptions.Item label='Monthly Interest Payments'>{currencyFormatter.format(monthlyInterestPaymentsNormal)}  ({currencyFormatter.format(monthlyInterestPaymentsDefault)} default)</Descriptions.Item>
                    <Descriptions.Item label='Application Fee'>{currencyFormatter.format(application.applicationFee)} ({percentageFormatter.format(application.applicationFee / application.loanAmount)})<br/>{currencyFormatter.format(application.applicationFee * 1.1)} inc. GST</Descriptions.Item>
                    <Descriptions.Item label='Gross Balance on Settlement'>{currencyFormatter.format(grossBalanceOnSettlement)}</Descriptions.Item>
                    <Descriptions.Item label='Brokerage Fee'>{currencyFormatter.format(application.brokerageFee)} ({percentageFormatter.format(application.brokerageFee / application.loanAmount)})<br/>{currencyFormatter.format(application.brokerageFee * 1.1)} inc. GST</Descriptions.Item>
                    <Descriptions.Item label='Net Balance on Settlement'>{netBalanceOnSettlementBlock}</Descriptions.Item>
                    <Descriptions.Item label='Legal Documents'>{currencyFormatter.format(application.legalFees)} ({percentageFormatter.format(application.legalFees / application.loanAmount)})<br/>{currencyFormatter.format(application.legalFees * 1.1)} inc. GST</Descriptions.Item>
                    <Descriptions.Item label='Total Costs'>{currencyFormatter.format(totalCostsDollars)} ({percentageFormatter.format(totalCostsPercentage / 100)}, {percentageFormatter.format(totalCostsPercentagePerAnnum / 100)} pa)</Descriptions.Item>
                    <Descriptions.Item label='Approximate Outlays'>{currencyFormatter.format(application.estimatedOutlays)}</Descriptions.Item>
                </Descriptions>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
        borrowers: applicationBorrowersSelector(state, ownProps.match.params.applicationUuid),
        currentAdministrator: currentAdministratorSelector(state),
        dealProperties: applicationDealPropertiesSelector(state, ownProps.match.params.applicationUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
        borrowersList: () => dispatch(applicationBorrowersListAction(ownProps.match.params.applicationUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Overview);
