enum WarehouseTypeEnum {
    Fcom = 'FCOM',
    Fin = 'FIN',
    Fit = 'FIT',
    Fnom = 'FNOM',
    Fwt1 = 'FWT1',
    Fwt2 = 'FWT2',
    Private = 'PRIVATE',
}

export default WarehouseTypeEnum;
