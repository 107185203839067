import { Form, Input, Modal, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IDeal from '~Api/Deal/IDeal';
import { leadSelector } from '~Leads/selectors';
import { IGlobalState } from '~reducer';
import DatePicker from '~UI/DatePicker';
import TimePicker from '~UI/TimePicker';
import { dealFollowUpTimeAction } from './actions';

interface IState {
    followUpTime: string;
    followUpTimeDirty: boolean;
    reason: string;
    showModal: boolean;
}

interface IProps {
    dealUuid: string;
}

interface IPropsSelector {
    deal: IDeal;
}

interface IPropsDispatch {
    followUpTime: (followUpTime: string, reason: string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class FollowUpTime extends React.Component<Props, IState> {
    public state: IState = {
        followUpTime: null,
        followUpTimeDirty: false,
        reason: null,
        showModal: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickChange = this.onClickChange.bind(this);
        this.onCancelChange = this.onCancelChange.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeTime = this.onChangeTime.bind(this);
        this.onChangeReason = this.onChangeReason.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { deal } = this.props;
        const { followUpTime, followUpTimeDirty, reason, showModal } = this.state;

        if (!deal) {
            return null;
        }

        const followUpTimeDayjs: Dayjs = followUpTimeDirty ? (followUpTime ? dayjs(followUpTime) : null) : (deal.followUpTime ? dayjs(deal.followUpTime) : null);

        return (
            <React.Fragment>
                <a onClick={this.onClickChange}>{followUpTimeDayjs ? followUpTimeDayjs.format('D/M H:mm') : 'Never'}</a>
                <Modal
                    onCancel={this.onCancelChange}
                    onOk={this.onClickOk}
                    open={showModal}
                    title='Change Follow Up'
                    wrapClassName='deal-follow-up-modal'
                >
                    <Form.Item className='follow-up-time' label='New Date/Time'>
                        <Space>
                            <DatePicker onChange={this.onChangeDate} value={followUpTimeDayjs || undefined} />
                            <TimePicker onChange={this.onChangeTime} value={followUpTimeDayjs || undefined} format='H:mm' />
                        </Space>
                    </Form.Item>
                    <Form.Item label='Reason' className='reason'>
                        <Input.TextArea onChange={this.onChangeReason} value={reason || ''} />
                    </Form.Item>
                </Modal>
            </React.Fragment>
        );
    }

    private onClickChange() {
        this.setState({
            showModal: true,
        });
    }

    private onCancelChange() {
        const { deal } = this.props;

        this.setState({
            followUpTime: deal.followUpTime,
            followUpTimeDirty: false,
            showModal: false,
        });
    }

    private onChangeDate(date: Dayjs) {
        const { followUpTime } = this.state;

        this.setState({
            followUpTime: dayjs(`${date.format('YYYY-MM-DD')} ${dayjs(followUpTime || undefined).format('HH:mm')}`).format(),
            followUpTimeDirty: true,
        });
    }

    private onChangeTime(time: Dayjs) {
        const { followUpTime } = this.state;

        this.setState({
            followUpTime: dayjs(`${dayjs(followUpTime || undefined).format('YYYY-MM-DD')} ${time.format('HH:mm')}`).format(),
            followUpTimeDirty: true,
        });
    }

    private onChangeReason(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            reason: event.target.value,
        });
    }

    private onClickOk() {
        const { followUpTime, reason } = this.state;

        this.props.followUpTime(followUpTime, reason);
        this.onCancelChange();
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        deal: leadSelector(state, ownProps.dealUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        followUpTime: (followUpTime: string, reason: string) => dispatch(dealFollowUpTimeAction(ownProps.dealUuid, followUpTime, reason)),
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FollowUpTime);
