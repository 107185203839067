import {
    all,
    call,
    debounce,
    put,
    select,
    takeEvery,
} from '@redux-saga/core/effects';
import { notification } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { currentAdministratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import IApplication from '~Api/Application/IApplication';
import { parseApplication } from '~Api/Application/parsers';
import IDeal from '~Api/Deal/IDeal';
import { parseDeal } from '~Api/Deal/parsers';
import ApprovalStatusEnum from '~Api/ReferralPartner/ApprovalStatusEnum';
import IDocument from '~Api/ReferralPartner/IDocument';
import IHistory from '~Api/ReferralPartner/IHistory';
import INote from '~Api/ReferralPartner/INote';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import {
    parseReferralPartner,
    parseReferralPartnerDocument,
    parseReferralPartnerHistory,
    parseReferralPartnerNote,
} from '~Api/ReferralPartner/parsers';
import {
    referralPartnerApplicationsListRequest,
    referralPartnerApproveRequest,
    referralPartnerDealsListRequest,
    referralPartnerDocumentDeleteRequest,
    referralPartnerDocumentsAddRequest,
    referralPartnerDocumentsListRequest,
    referralPartnerGetRequest,
    referralPartnerHistoriesListRequest,
    referralPartnerNotesAddRequest,
    referralPartnerNotesListRequest,
    referralPartnerRejectRequest,
    referralPartnerResetRequest,
    referralPartnersAddRequest,
    referralPartnersListRequest,
    referralPartnerUpdateRequest,
} from '~Api/ReferralPartner/requests';
import { IFetchResponse } from '~utilities/fetch';
import { renderNotificationLoadingIcon } from '~utilities/utils';
import {
    IReferralPartnerApplicationsListAction,
    IReferralPartnerApproveAction,
    IReferralPartnerDealsListAction,
    IReferralPartnerDocumentDeleteAction,
    IReferralPartnerDocumentsAddAction,
    IReferralPartnerDocumentsListAction,
    IReferralPartnerGetAction,
    IReferralPartnerHistoriesListAction,
    IReferralPartnerNotesAddAction,
    IReferralPartnerNotesListAction,
    IReferralPartnerRejectAction,
    IReferralPartnerResetAction,
    IReferralPartnersAddAction,
    IReferralPartnerSendAction,
    IReferralPartnersListAction,
    IReferralPartnerValueSetAction,
    referralPartnerApplicationsSetAction,
    referralPartnerDealsSetAction,
    referralPartnerDocumentRemoveAction,
    referralPartnerDocumentSetAction,
    referralPartnerDocumentsSetAction,
    referralPartnerHistoriesSetAction,
    referralPartnerNoteRemoveAction,
    referralPartnerNoteSetAction,
    referralPartnerNotesSetAction,
    referralPartnerSendAction,
    referralPartnerSetAction,
    referralPartnersSetAction,
} from './actions';
import ReferralPartnersActionsEnum from './ActionsEnum';
import { referralPartnerSelector } from './selectors';

function* getReferralPartner(referralPartnerUuid: string): Iterator<any> {
    const referralPartnerResponse: IFetchResponse = yield call(referralPartnerGetRequest, referralPartnerUuid);
    const referralPartner: IReferralPartner = yield parseReferralPartner(referralPartnerResponse.body);
    yield put(referralPartnerSetAction(referralPartner));
    return referralPartner;
}

function* referralPartnerApprove(action: IReferralPartnerApproveAction): Iterator<any> {
    const key: string = `referralPartnerApprove ${action.referralPartnerUuid}`;
    const message: string = 'Approve Referral Partner';

    notification.open({
        description: 'Approving referral partner...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const referralPartnerApproveResponse: IFetchResponse = yield call(referralPartnerApproveRequest, action.referralPartnerUuid);

    if (referralPartnerApproveResponse.status === 422) {
        notification.error({
            description: `There was a problem approving the referral partner: ${_.values(referralPartnerApproveResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        notification.success({
            description: 'The referral partner has been approved.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* referralPartnerGet(action: IReferralPartnerGetAction): Iterator<any> {
    const referralPartnerResponse: IFetchResponse = yield call(referralPartnerGetRequest, action.referralPartnerUuid);
    const referralPartner: IReferralPartner = parseReferralPartner(referralPartnerResponse.body);
    yield put(referralPartnerSetAction(referralPartner));
}

function* referralPartnerReject(action: IReferralPartnerRejectAction): Iterator<any> {
    const key: string = `referralPartnerReject ${action.referralPartnerUuid}`;
    const message: string = 'Reject Referral Partner';

    notification.open({
        description: 'Rejecting referral partner...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const referralPartnerRejectResponse: IFetchResponse = yield call(referralPartnerRejectRequest, action.referralPartnerUuid);

    if (referralPartnerRejectResponse.status === 422) {
        notification.error({
            description: `There was a problem rejecting the referral partner: ${_.values(referralPartnerRejectResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        notification.success({
            description: 'The referral partner has been rejected.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* referralPartnerReset(action: IReferralPartnerResetAction): Iterator<any> {
    const referralPartner: IReferralPartner = yield select(referralPartnerSelector, action.referralPartnerUuid);

    const key: string = `referralPartner${referralPartner.approvalStatus === ApprovalStatusEnum.Approved ? 'Unapprove' : 'Unreject'} ${action.referralPartnerUuid}`;
    const message: string = `${referralPartner.approvalStatus === ApprovalStatusEnum.Approved ? 'Unapprove' : 'Unreject'} Referral Partner`;

    notification.open({
        description: `${referralPartner.approvalStatus === ApprovalStatusEnum.Approved ? 'Unapproving' : 'Unrejecting'} referral partner...`,
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const referralPartnerResetResponse: IFetchResponse = yield call(referralPartnerResetRequest, action.referralPartnerUuid);

    if (referralPartnerResetResponse.status === 422) {
        notification.error({
            description: `There was a problem ${referralPartner.approvalStatus === ApprovalStatusEnum.Approved ? 'unapproving' : 'unrejecting'} the referral partner: ${_.values(referralPartnerResetResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        notification.success({
            description: `The referral partner has been ${referralPartner.approvalStatus === ApprovalStatusEnum.Approved ? 'unapproved' : 'unrejected'}.`,
            duration: 4.5,
            key,
            message,
        });
    }
}

function* referralPartnerSend(action: IReferralPartnerSendAction): Iterator<any> {
    const referralPartner: IReferralPartner = yield select(referralPartnerSelector, action.referralPartnerUuid);
    yield call(referralPartnerUpdateRequest, referralPartner);
}

function* referralPartnerValueSet(action: IReferralPartnerValueSetAction): Iterator<any> {
    yield put(referralPartnerSendAction(action.referralPartnerUuid));
}

function* referralPartnerDealsList(action: IReferralPartnerDealsListAction): Iterator<any> {
    let referralPartner: IReferralPartner = yield select(referralPartnerSelector, action.referralPartnerUuid);

    if (!referralPartner) {
        referralPartner = yield call(getReferralPartner, action.referralPartnerUuid);
    }

    const dealsResponse: IFetchResponse = yield call(referralPartnerDealsListRequest, referralPartner.uuid);
    const deals: IDeal[] = yield Promise.all(dealsResponse.body.map(parseDeal));
    yield put(referralPartnerDealsSetAction(action.referralPartnerUuid, deals));
}

function* referralPartnerApplicationsList(action: IReferralPartnerApplicationsListAction): Iterator<any> {
    let referralPartner: IReferralPartner = yield select(referralPartnerSelector, action.referralPartnerUuid);

    if (!referralPartner) {
        referralPartner = yield call(getReferralPartner, action.referralPartnerUuid);
    }

    const applicationsResponse: IFetchResponse = yield call(referralPartnerApplicationsListRequest, referralPartner.uuid);
    const applications: IApplication[] = yield Promise.all(applicationsResponse.body.map(parseApplication));
    yield put(referralPartnerApplicationsSetAction(action.referralPartnerUuid, applications));
}

function* referralPartnerDocumentDelete(action: IReferralPartnerDocumentDeleteAction): Iterator<any> {
    const key: string = `referralPartnerDocumentDelete ${action.referralPartnerUuid} ${action.documentUuid}`;
    const message: string = 'Delete Referral Partner Document';

    notification.open({
        description: 'Deleting referral partner document...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const referralPartnerDocumentDeleteResponse: IFetchResponse = yield call(referralPartnerDocumentDeleteRequest, action.referralPartnerUuid, action.documentUuid);

    if (referralPartnerDocumentDeleteResponse.status === 422) {
        notification.error({
            description: `There was a problem deleting the document: ${_.values(referralPartnerDocumentDeleteResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(referralPartnerDocumentRemoveAction(action.referralPartnerUuid, action.documentUuid));
        notification.success({
            description: 'The referral partner document has been deleted.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* referralPartnerDocumentsAdd(action: IReferralPartnerDocumentsAddAction): Iterator<any> {
    const key: string = `referralPartnerDocumentsAdd ${dayjs().format()}`;

    notification.open({
        description: 'Adding document...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Document',
    });

    const documentResponse: IFetchResponse = yield call(referralPartnerDocumentsAddRequest, action.referralPartnerUuid, action.file);
    const document: IDocument = parseReferralPartnerDocument(documentResponse.body);
    yield put(referralPartnerDocumentSetAction(action.referralPartnerUuid, document));

    notification.success({
        description: 'The document has been added.',
        duration: 4.5,
        key,
        message: 'Add Document',
    });
}

function* referralPartnerDocumentsList(action: IReferralPartnerDocumentsListAction): Iterator<any> {
    const documentsResponse: IFetchResponse = yield call(referralPartnerDocumentsListRequest, action.referralPartnerUuid);
    const documents: IDocument[] = yield Promise.all(documentsResponse.body.map(parseReferralPartnerDocument));
    yield put(referralPartnerDocumentsSetAction(action.referralPartnerUuid, documents));
}

function* referralPartnerHistoriesList(action: IReferralPartnerHistoriesListAction): Iterator<any> {
    const historiesResponse: IFetchResponse = yield call(referralPartnerHistoriesListRequest, action.referralPartnerUuid);
    const histories: IHistory[] = yield Promise.all(historiesResponse.body.map(parseReferralPartnerHistory));
    yield put(referralPartnerHistoriesSetAction(action.referralPartnerUuid, histories));
}

function* referralPartnersAdd(action: IReferralPartnersAddAction): Iterator<any> {
    const key: string = `referralPartnersAdd ${dayjs().format()}`;
    const message: string = 'Add Referral Partner';

    notification.open({
        description: 'Adding referral partner...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const referralPartnersAddResponse: IFetchResponse = yield call(referralPartnersAddRequest, action.referralPartner);
    if (referralPartnersAddResponse.status === 422) {
        notification.error({
            description: `There was a problem adding the referral partner: ${_.values(referralPartnersAddResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const referralPartner: IReferralPartner = parseReferralPartner(referralPartnersAddResponse.body);
        yield put(referralPartnerSetAction(referralPartner));

        notification.success({
            description: 'The referral partner has been added.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* referralPartnersList(action: IReferralPartnersListAction): Iterator<any> {
    const referralPartnersResponse: IFetchResponse = yield call(referralPartnersListRequest);
    const referralPartners: IReferralPartner[] = yield Promise.all(referralPartnersResponse.body.map(parseReferralPartner));

    yield put(referralPartnersSetAction(referralPartners));
}

function* referralPartnerNotesAdd(action: IReferralPartnerNotesAddAction): Iterator<any> {
    const key: string = `referralPartnerNotesAdd ${dayjs().format()}`;

    notification.open({
        description: 'Adding note...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Note',
    });

    const currentAdministrator: IAdministrator = yield select(currentAdministratorSelector);

    const preNote: INote = {
        administratorUuid: currentAdministrator.uuid,
        createdTime: dayjs().format(),
        note: action.note,
    };

    yield put(referralPartnerNoteSetAction(action.referralPartnerUuid, preNote));

    const noteResponse: IFetchResponse = yield call(referralPartnerNotesAddRequest, action.referralPartnerUuid, preNote);
    const note: INote = parseReferralPartnerNote(noteResponse.body);

    yield put(referralPartnerNoteRemoveAction(action.referralPartnerUuid, 'new'));
    yield put(referralPartnerNoteSetAction(action.referralPartnerUuid, note));

    notification.success({
        description: 'The note has been added.',
        duration: 4.5,
        key,
        message: 'Add Note',
    });
}

function* referralPartnerNotesList(action: IReferralPartnerNotesListAction): Iterator<any> {
    const notesResponse: IFetchResponse = yield call(referralPartnerNotesListRequest, action.referralPartnerUuid);
    const notes: INote[] = yield Promise.all(notesResponse.body.map(parseReferralPartnerNote));
    yield put(referralPartnerNotesSetAction(action.referralPartnerUuid, notes));
}

export function* ReferralPartnersSaga(): any {
    yield all([
        takeEvery(ReferralPartnersActionsEnum.ReferralPartnerApprove, referralPartnerApprove),
        takeEvery(ReferralPartnersActionsEnum.ReferralPartnerGet, referralPartnerGet),
        takeEvery(ReferralPartnersActionsEnum.ReferralPartnerReject, referralPartnerReject),
        takeEvery(ReferralPartnersActionsEnum.ReferralPartnerReset, referralPartnerReset),
        debounce(500, ReferralPartnersActionsEnum.ReferralPartnerSend, referralPartnerSend),
        takeEvery(ReferralPartnersActionsEnum.ReferralPartnerValueSet, referralPartnerValueSet),

        takeEvery(ReferralPartnersActionsEnum.ReferralPartnerDealsList, referralPartnerDealsList),
        takeEvery(ReferralPartnersActionsEnum.ReferralPartnerApplicationsList, referralPartnerApplicationsList),

        debounce(20, ReferralPartnersActionsEnum.ReferralPartnerDocumentDelete, referralPartnerDocumentDelete),

        takeEvery(ReferralPartnersActionsEnum.ReferralPartnerDocumentsAdd, referralPartnerDocumentsAdd),
        takeEvery(ReferralPartnersActionsEnum.ReferralPartnerDocumentsList, referralPartnerDocumentsList),

        takeEvery(ReferralPartnersActionsEnum.ReferralPartnerHistoriesList, referralPartnerHistoriesList),

        takeEvery(ReferralPartnersActionsEnum.ReferralPartnersAdd, referralPartnersAdd),
        takeEvery(ReferralPartnersActionsEnum.ReferralPartnersList, referralPartnersList),

        takeEvery(ReferralPartnersActionsEnum.ReferralPartnerNotesAdd, referralPartnerNotesAdd),
        takeEvery(ReferralPartnersActionsEnum.ReferralPartnerNotesList, referralPartnerNotesList),
    ]);
}
