import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Form, Input, MenuProps, Select, Space, Spin, Tabs, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import BrokerCommissionFormatEnum from '~Api/Application/BrokerCommissionFormatEnum';
import CodeTypeEnum from '~Api/Application/CodeTypeEnum';
import DisbursalTypeEnum from '~Api/Application/DisbursalTypeEnum';
import IApplication from '~Api/Application/IApplication';
import IBorrower from '~Api/Application/IBorrower';
import InterestPaymentMethodEnum from '~Api/Application/InterestPaymentMethodEnum';
import LoanPurposeEnum from '~Api/Application/LoanPurposeEnum';
import MortgageTypeEnum from '~Api/Application/MortgageTypeEnum';
import PartnerCommissionFormatEnum from '~Api/Application/PartnerCommissionFormatEnum';
import RepaymentStrategyEnum from '~Api/Application/RepaymentStrategyEnum';
import BankBranchStateEnum from '~Api/Deal/BankBranchStateEnum';
import IDeal from '~Api/Deal/IDeal';
import {
    applicationBorrowersListAction,
    applicationDealValueSetAction,
    applicationGetAction,
    applicationValueSetAction,
} from '~Applications/actions';
import {
    applicationBorrowersSelector,
    applicationSelector,
} from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import { solicitorsListAction } from '~Solicitors/actions';
import ISolicitor from '~Solicitors/ISolicitor';
import { solicitorsSelector } from '~Solicitors/selectors';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import DischargeInterestTypeEnum from '~Api/Application/DischargeInterestTypeEnum';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';

const disbursalTypeLabels: IDictionary<string> = {
    [DisbursalTypeEnum.Drawdown]: 'Drawdown',
    [DisbursalTypeEnum.Full]: 'Full',
};

const loanPurposeLabels: IDictionary<string> = {
    [LoanPurposeEnum.BridgingLoan]: 'Bridging loan',
    [LoanPurposeEnum.Refinance]: 'Refinance an existing property',
    [LoanPurposeEnum.BusinessLoan]: 'Business loan',
    [LoanPurposeEnum.PersonalLoan]: 'Personal loan',
    [LoanPurposeEnum.RenovateOrBuild]: 'Renovate or build',
    [LoanPurposeEnum.PurchaseNew]: 'Purchase a new property',
    [LoanPurposeEnum.DebtConsolidation]: 'Debt consolidation',
    [LoanPurposeEnum.DevelopmentLoan]: 'Development loan',
};

interface IMatch {
    applicationUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    application: IApplication;
    borrowers: IBorrower[];
    solicitors: IDictionary<ISolicitor>;
}

interface IPropsDispatch {
    applicationGet: () => void;
    applicationValueSet: (key: keyof IApplication, value: boolean|number|string) => void;
    borrowersList: () => void;
    dealValueSet: (key: keyof IDeal, value: boolean|number|string) => void;
    solicitorsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Edit extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeCodeType = this.onChangeCodeType.bind(this);
        this.onChangeDischargeInterestType = this.onChangeDischargeInterestType.bind(this);
        this.onChangeMortgageType = this.onChangeMortgageType.bind(this);
        this.onChangeLoanPurpose = this.onChangeLoanPurpose.bind(this);
        this.onChangeRepaymentStrategy = this.onChangeRepaymentStrategy.bind(this);
        this.onChangeSecondaryRepaymentStrategy = this.onChangeSecondaryRepaymentStrategy.bind(this);
        this.onChangeInterestPaymentMethod = this.onChangeInterestPaymentMethod.bind(this);
        this.onChangePrimaryBorrowerUuid = this.onChangePrimaryBorrowerUuid.bind(this);
        this.onChangeTermMonthsMinimum = this.onChangeTermMonthsMinimum.bind(this);
        this.onChangeDisbursalType = this.onChangeDisbursalType.bind(this);
        this.onChangeBrokerCommissionDollars = this.onChangeBrokerCommissionDollars.bind(this);
        this.onChangeBrokerCommissionFormat = this.onChangeBrokerCommissionFormat.bind(this);
        this.onChangeBrokerCommissionPercentage = this.onChangeBrokerCommissionPercentage.bind(this);
        this.onChangePartnerCommissionDollars = this.onChangePartnerCommissionDollars.bind(this);
        this.onChangePartnerCommissionFormat = this.onChangePartnerCommissionFormat.bind(this);
        this.onChangePartnerCommissionPercentage = this.onChangePartnerCommissionPercentage.bind(this);

        this.onChangeBankName = this.onChangeBankName.bind(this);
        this.onChangeBankBsb = this.onChangeBankBsb.bind(this);
        this.onChangeBankAccountName = this.onChangeBankAccountName.bind(this);
        this.onChangeBankAccountNumber = this.onChangeBankAccountNumber.bind(this);
        this.onChangeBankBranchAddress = this.onChangeBankBranchAddress.bind(this);
        this.onChangeBankBranchState = this.onChangeBankBranchState.bind(this);
        this.onChangeBankBranchPostcode = this.onChangeBankBranchPostcode.bind(this);

        this.onChangeSolicitorFirmName = this.onChangeSolicitorFirmName.bind(this);
        this.onChangeSolicitorName = this.onChangeSolicitorName.bind(this);
        this.onChangeSolicitorMobile = this.onChangeSolicitorMobile.bind(this);
        this.onChangeSolicitorPhone = this.onChangeSolicitorPhone.bind(this);
        this.onChangeSolicitorEmail = this.onChangeSolicitorEmail.bind(this);

        this.onChangeAccountantFirmName = this.onChangeAccountantFirmName.bind(this);
        this.onChangeAccountantName = this.onChangeAccountantName.bind(this);
        this.onChangeAccountantPhone = this.onChangeAccountantPhone.bind(this);
        this.onChangeAccountantEmail = this.onChangeAccountantEmail.bind(this);

        this.onChangeApplicationSolicitor = this.onChangeApplicationSolicitor.bind(this);
    }

    public componentDidMount(): void {
        const { application, borrowers, solicitors } = this.props;

        if (!application) {
            this.props.applicationGet();
        }

        if (!borrowers) {
            this.props.borrowersList();
        }

        if (!solicitors) {
            this.props.solicitorsList();
        }
    }

    public render(): JSX.Element {
        const { application, borrowers, match, solicitors } = this.props;

        if (!application || !application.deal || !borrowers || !solicitors) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='edit'>
                    <Spin/>
                </Layout>
            );
        }

        const brokerCommissionFormat: BrokerCommissionFormatEnum = application.brokerCommissionFormat || BrokerCommissionFormatEnum.Percentage;

        const brokerCommissionMenu: MenuProps = {
            items: [
                {
                    key: 'broker-commission-format',
                    label: brokerCommissionFormat === BrokerCommissionFormatEnum.Percentage ? 'Dollar Value' : 'Percentage',
                    onClick: this.onChangeBrokerCommissionFormat,
                },
            ],
        };

        const brokerCommissionLabel: JSX.Element = (
            <Dropdown menu={brokerCommissionMenu}>
                <a>Broker Commission<DownOutlined/></a>
            </Dropdown>
        );

        const partnerCommissionFormat: PartnerCommissionFormatEnum = application.partnerCommissionFormat || PartnerCommissionFormatEnum.Percentage;

        const partnerCommissionMenu: MenuProps = {
            items: [
                {
                    key: 'partner-commission-format',
                    label: partnerCommissionFormat === PartnerCommissionFormatEnum.Percentage ? 'Dollar Value' : 'Percentage',
                    onClick: this.onChangePartnerCommissionFormat,
                },
            ],
        };

        const partnerCommissionLabel: JSX.Element = (
            <Dropdown menu={partnerCommissionMenu}>
                <a>Partner Commission<DownOutlined/></a>
            </Dropdown>
        );

        const termMonthsMinimumBlock: JSX.Element = application.dischargeInterestType === DischargeInterestTypeEnum.MinimumTerm && (
            <Form.Item label='Minimum Term' className='term-months-minimum'>
                <Select onChange={this.onChangeTermMonthsMinimum} value={application.termMonthsMinimum}>
                    <Select.Option key='none' value={null}>Loan Term</Select.Option>
                    {_.times(37, (months: number) => (<Select.Option key={months} value={months}>{months} months</Select.Option>))}
                </Select>
            </Form.Item>
        );

        const dischargeInterestTypeBlock: JSX.Element = application.codeType === CodeTypeEnum.Code && (
            <Form.Item label='Discharge Interest Type' className='discharge-interest-type'>
                <Select onChange={this.onChangeDischargeInterestType} value={application.dischargeInterestType}>
                    <Select.Option value={DischargeInterestTypeEnum.BreakCost}>Break Cost</Select.Option>
                    <Select.Option value={DischargeInterestTypeEnum.MinimumTerm}>Minimum Term</Select.Option>
                </Select>
            </Form.Item>
        );

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='edit'>
                <Typography.Title level={2}>Edit</Typography.Title>
                <Tabs defaultActiveKey='general'>
                    <Tabs.TabPane key='general' tab='General'>
                        <Form.Item label='Loan Type' className='code-type'>
                            <Select onChange={this.onChangeCodeType} value={application.codeType}>
                                <Select.Option value={CodeTypeEnum.Code}>NCCP Regulated</Select.Option>
                                <Select.Option value={CodeTypeEnum.NonCode}>Non NCCP Regulated</Select.Option>
                            </Select>
                        </Form.Item>
                        {dischargeInterestTypeBlock}
                        <Form.Item label='Mortgage Type' className='mortgage-type'>
                            <Select onChange={this.onChangeMortgageType} value={application.mortgageType}>
                                <Select.Option value={MortgageTypeEnum.FirstMortgage}>First Mortgage</Select.Option>
                                <Select.Option value={MortgageTypeEnum.SecondMortgage}>Second Mortgage</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label='Loan Purpose' className='loan-purpose'>
                            <Select onChange={this.onChangeLoanPurpose} value={application.loanPurpose}>
                                {_.map(loanPurposeLabels, (loanPurposeLabel: string, key: LoanPurposeEnum) => <Select.Option value={key}>{loanPurposeLabel}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label='Repayment Strategy' className='repayment-strategy'>
                            <Select onChange={this.onChangeRepaymentStrategy} value={application.repaymentStrategy}>
                                <Select.Option value={RepaymentStrategyEnum.PropertySaleSold}>Sale of Property - Already Sold</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.PropertySaleNotSold}>Sale of Property - Not Yet Sold</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.RefinanceApproved}>Refinance - Already Approved</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.RefinanceNotApproved}>Refinance - Not Yet Approved</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.BusinessSaleSold}>Sale of Business - Already Sold</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.BusinessSaleNotSold}>Sale of Business - Not Yet Sold</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.BusinessCashFlow}>Business Cash Flow</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.Inheritance}>Inheritance</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.CourtSettlement}>Court Settlement / Compensation</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.Other}>Other</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label='Secondary Repayment Strategy' className='secondary-repayment-strategy'>
                            <Select onChange={this.onChangeSecondaryRepaymentStrategy} value={application.secondaryRepaymentStrategy}>
                                <Select.Option value={RepaymentStrategyEnum.PropertySaleSold}>Sale of Property - Already Sold</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.PropertySaleNotSold}>Sale of Property - Not Yet Sold</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.RefinanceApproved}>Refinance - Already Approved</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.RefinanceNotApproved}>Refinance - Not Yet Approved</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.BusinessSaleSold}>Sale of Business - Already Sold</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.BusinessSaleNotSold}>Sale of Business - Not Yet Sold</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.BusinessCashFlow}>Business Cash Flow</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.Inheritance}>Inheritance</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.CourtSettlement}>Court Settlement / Compensation</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.Other}>Other</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label='Interest Payment Method' className='interest-payment-method'>
                            <Select onChange={this.onChangeInterestPaymentMethod} value={application.interestPaymentMethod}>
                                <Select.Option value={InterestPaymentMethodEnum.Monthly}>Monthly</Select.Option>
                                <Select.Option value={InterestPaymentMethodEnum.Prepaid}>Prepaid</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className='primary-borrower' label='Primary Borrower'>
                            <Select onChange={this.onChangePrimaryBorrowerUuid} value={application.primaryBorrowerUuid}>
                                {borrowers.map((borrower: IBorrower) => (<Select.Option key={borrower.uuid} value={borrower.uuid}>{borrower.dealBorrower.formattedName}</Select.Option>))}
                            </Select>
                        </Form.Item>
                        {termMonthsMinimumBlock}
                        <Form.Item label='Disbursal Type' className='disbursal-type'>
                            <Select onChange={this.onChangeDisbursalType} value={application.disbursalType}>
                                {_.keys(disbursalTypeLabels).map((disbursalType: string) => <Select.Option key={disbursalType} value={disbursalType}>{disbursalTypeLabels[disbursalType]}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item className='broker-commission' label={brokerCommissionLabel}>
                            {brokerCommissionFormat === BrokerCommissionFormatEnum.Dollars && <Space><Input addonBefore='$' onChange={this.onChangeBrokerCommissionDollars} type='number' max={application.loanAmount} value={application.brokerCommissionDollars} /><span>({percentageFormatter.format(application.brokerCommissionDollars / application.loanAmount)})</span></Space>}
                            {brokerCommissionFormat === BrokerCommissionFormatEnum.Percentage && <Space><Input addonAfter='%' onChange={this.onChangeBrokerCommissionPercentage} step={0.1} type='number' max={100} value={application.brokerCommissionPercentage} /><span>({currencyFormatter.format(Math.ceil((application.brokerCommissionPercentage / 100) * application.loanAmount))})</span></Space>}
                        </Form.Item>
                        <Form.Item className='partner-commission' label={partnerCommissionLabel}>
                            {partnerCommissionFormat === PartnerCommissionFormatEnum.Dollars && <Space><Input addonBefore='$' onChange={this.onChangePartnerCommissionDollars} type='number' max={application.loanAmount} value={application.partnerCommissionDollars} /><span>({percentageFormatter.format(application.partnerCommissionDollars / application.loanAmount)})</span></Space>}
                            {partnerCommissionFormat === PartnerCommissionFormatEnum.Percentage && <Space><Input addonAfter='%' onChange={this.onChangePartnerCommissionPercentage} step={0.1} type='number' max={100} value={application.partnerCommissionPercentage} /><span>({currencyFormatter.format(Math.ceil((application.partnerCommissionPercentage / 100) * application.loanAmount))})</span></Space>}
                        </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane key='bank-account' tab='Bank Account'>
                        <Form.Item label='Bank Name' className='bank-name'>
                            <Input maxLength={50} onChange={this.onChangeBankName} value={application.deal.bankName} />
                        </Form.Item>
                        <Form.Item label='BSB' className='bank-bsb'>
                            <Input maxLength={6} onChange={this.onChangeBankBsb} value={application.deal.bankBsb} />
                        </Form.Item>
                        <Form.Item label='Account Name' className='bank-account-name'>
                            <Input maxLength={255} onChange={this.onChangeBankAccountName} value={application.deal.bankAccountName} />
                        </Form.Item>
                        <Form.Item label='Account Number' className='bank-account-number'>
                            <Input maxLength={20} onChange={this.onChangeBankAccountNumber} value={application.deal.bankAccountNumber} />
                        </Form.Item>
                        <Form.Item label='Branch Address' className='bank-branch-address'>
                            <Input maxLength={255} onChange={this.onChangeBankBranchAddress} value={application.deal.bankBranchAddress} />
                        </Form.Item>
                        <Form.Item label='Branch State' className='bank-branch-state'>
                            <Select onChange={this.onChangeBankBranchState} value={application.deal.bankBranchState}>
                                <Select.Option value={BankBranchStateEnum.AustralianCapitalTerritory}>ACT</Select.Option>
                                <Select.Option value={BankBranchStateEnum.NewSouthWales}>NSW</Select.Option>
                                <Select.Option value={BankBranchStateEnum.NorthernTerritory}>NT</Select.Option>
                                <Select.Option value={BankBranchStateEnum.Queensland}>QLD</Select.Option>
                                <Select.Option value={BankBranchStateEnum.SouthAustralia}>SA</Select.Option>
                                <Select.Option value={BankBranchStateEnum.Tasmania}>TAS</Select.Option>
                                <Select.Option value={BankBranchStateEnum.Victoria}>VIC</Select.Option>
                                <Select.Option value={BankBranchStateEnum.WesternAustralia}>WA</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label='Branch Postcode' className='bank-branch-postcode'>
                            <Input maxLength={4} onChange={this.onChangeBankBranchPostcode} value={application.deal.bankBranchPostcode} />
                        </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane key='solicitor' tab='Borrower Solicitor'>
                        <Form.Item label='Firm Name' className='solicitor-firm-name'>
                            <Input maxLength={255} onChange={this.onChangeSolicitorFirmName} value={application.deal.solicitorFirmName} />
                        </Form.Item>
                        <Form.Item label='Solicitor' className='solicitor-name'>
                            <Input maxLength={255} onChange={this.onChangeSolicitorName} value={application.deal.solicitorName} />
                        </Form.Item>
                        <Form.Item label='Mobile' className='solicitor-mobile'>
                            <Input maxLength={20} onChange={this.onChangeSolicitorMobile} value={application.deal.solicitorMobile} />
                        </Form.Item>
                        <Form.Item label='Phone' className='solicitor-phone'>
                            <Input maxLength={20} onChange={this.onChangeSolicitorPhone} value={application.deal.solicitorPhone} />
                        </Form.Item>
                        <Form.Item label='Email' className='solicitor-email'>
                            <Input maxLength={255} onChange={this.onChangeSolicitorEmail} value={application.deal.solicitorEmail} />
                        </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane key='accountant' tab='Accountant'>
                        <Form.Item label='Firm Name' className='accountant-firm-name'>
                            <Input maxLength={255} onChange={this.onChangeAccountantFirmName} value={application.deal.accountantFirmName} />
                        </Form.Item>
                        <Form.Item label='Accountant' className='accountant-name'>
                            <Input maxLength={255} onChange={this.onChangeAccountantName} value={application.deal.accountantName} />
                        </Form.Item>
                        <Form.Item label='Phone' className='accountant-phone'>
                            <Input maxLength={20} onChange={this.onChangeAccountantPhone} value={application.deal.accountantPhone} />
                        </Form.Item>
                        <Form.Item label='Email' className='accountant-email'>
                            <Input maxLength={255} onChange={this.onChangeAccountantEmail} value={application.deal.accountantEmail} />
                        </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane key='application-solicitor' tab='Application Solicitor'>
                        <Form.Item label='Application Solicitor' className='application-solicitor'>
                            <Select onChange={this.onChangeApplicationSolicitor} value={application.solicitorUuid} allowClear={true}>
                                {_.map(solicitors, (loopSolicitor: ISolicitor) => <Select.Option value={loopSolicitor.uuid}>{loopSolicitor.name}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Tabs.TabPane>
                </Tabs>
            </Layout>
        );
    }

    private onChangeCodeType(value: CodeTypeEnum) {
        this.props.applicationValueSet('codeType', value);
    }

    private onChangeDischargeInterestType(value: DischargeInterestTypeEnum) {
        this.props.applicationValueSet('dischargeInterestType', value);
    }

    private onChangeMortgageType(value: MortgageTypeEnum) {
        this.props.applicationValueSet('mortgageType', value);
    }

    private onChangeLoanPurpose(value: LoanPurposeEnum) {
        this.props.applicationValueSet('loanPurpose', value);
    }

    private onChangeRepaymentStrategy(value: RepaymentStrategyEnum) {
        this.props.applicationValueSet('repaymentStrategy', value);
    }

    private onChangeSecondaryRepaymentStrategy(value: RepaymentStrategyEnum) {
        this.props.applicationValueSet('secondaryRepaymentStrategy', value);
    }

    private onChangeInterestPaymentMethod(value: InterestPaymentMethodEnum) {
        this.props.applicationValueSet('interestPaymentMethod', value);
    }

    private onChangePrimaryBorrowerUuid(value: string) {
        this.props.applicationValueSet('primaryBorrowerUuid', value);
    }

    private onChangeTermMonthsMinimum(value: number) {
        this.props.applicationValueSet('termMonthsMinimum', value);
    }

    private onChangeDisbursalType(value: DisbursalTypeEnum) {
        this.props.applicationValueSet('disbursalType', value);
    }

    private onChangeBrokerCommissionDollars(event: React.ChangeEvent<HTMLInputElement>) {
        const { application } = this.props;
        const brokerCommissionDollars: number = event.target.value ? event.target.valueAsNumber : null;

        this.props.applicationValueSet('brokerCommissionDollars', brokerCommissionDollars);
        this.props.applicationValueSet('brokerCommissionPercentage', ((brokerCommissionDollars / application.loanAmount) * 100).toFixed(2));
    }

    private onChangeBrokerCommissionFormat() {
        const { application } = this.props;
        const brokerCommissionFormat: BrokerCommissionFormatEnum = application.brokerCommissionFormat === BrokerCommissionFormatEnum.Dollars ? BrokerCommissionFormatEnum.Percentage : BrokerCommissionFormatEnum.Dollars;

        this.props.applicationValueSet('brokerCommissionFormat', brokerCommissionFormat);
    }

    private onChangeBrokerCommissionPercentage(event: React.ChangeEvent<HTMLInputElement>) {
        const { application } = this.props;
        const brokerCommissionPercentage: number = event.target.value ? event.target.valueAsNumber : null;

        this.props.applicationValueSet('brokerCommissionPercentage', brokerCommissionPercentage);
        this.props.applicationValueSet('brokerCommissionDollars', Math.ceil((brokerCommissionPercentage / 100) * application.loanAmount));
    }

    private onChangePartnerCommissionDollars(event: React.ChangeEvent<HTMLInputElement>): void {
        const { application } = this.props;
        const partnerCommissionDollars: number = event.target.value ? event.target.valueAsNumber : null;

        this.props.applicationValueSet('partnerCommissionDollars', partnerCommissionDollars);
        this.props.applicationValueSet('partnerCommissionPercentage', ((partnerCommissionDollars / application.loanAmount) * 100).toFixed(2));
    }

    private onChangePartnerCommissionFormat(): void {
        const { application } = this.props;
        const partnerCommissionFormat: PartnerCommissionFormatEnum = application.partnerCommissionFormat === PartnerCommissionFormatEnum.Dollars ? PartnerCommissionFormatEnum.Percentage : PartnerCommissionFormatEnum.Dollars;

        this.props.applicationValueSet('partnerCommissionFormat', partnerCommissionFormat);
    }

    private onChangePartnerCommissionPercentage(event: React.ChangeEvent<HTMLInputElement>): void {
        const { application } = this.props;
        const partnerCommissionPercentage: number = event.target.value ? event.target.valueAsNumber : null;

        this.props.applicationValueSet('partnerCommissionPercentage', partnerCommissionPercentage);
        this.props.applicationValueSet('partnerCommissionDollars', Math.ceil((partnerCommissionPercentage / 100) * application.loanAmount));
    }

    private onChangeBankName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.dealValueSet('bankName', event.target.value);
    }

    private onChangeBankBsb(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.dealValueSet('bankBsb', event.target.value);
    }

    private onChangeBankAccountName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.dealValueSet('bankAccountName', event.target.value);
    }

    private onChangeBankAccountNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.dealValueSet('bankAccountNumber', event.target.value);
    }

    private onChangeBankBranchAddress(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.dealValueSet('bankBranchAddress', event.target.value);
    }

    private onChangeBankBranchState(value: BankBranchStateEnum) {
        this.props.dealValueSet('bankBranchState', value);
    }

    private onChangeBankBranchPostcode(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.dealValueSet('bankBranchPostcode', event.target.value);
    }

    private onChangeSolicitorFirmName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.dealValueSet('solicitorFirmName', event.target.value);
    }

    private onChangeSolicitorName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.dealValueSet('solicitorName', event.target.value);
    }

    private onChangeSolicitorMobile(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.dealValueSet('solicitorMobile', event.target.value);
    }

    private onChangeSolicitorPhone(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.dealValueSet('solicitorPhone', event.target.value);
    }

    private onChangeSolicitorEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.dealValueSet('solicitorEmail', event.target.value);
    }

    private onChangeAccountantFirmName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.dealValueSet('accountantFirmName', event.target.value);
    }

    private onChangeAccountantName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.dealValueSet('accountantName', event.target.value);
    }

    private onChangeAccountantPhone(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.dealValueSet('accountantPhone', event.target.value);
    }

    private onChangeAccountantEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.dealValueSet('accountantEmail', event.target.value);
    }

    private onChangeApplicationSolicitor(value: string) {
        this.props.applicationValueSet('solicitorUuid', value || null);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
        borrowers: applicationBorrowersSelector(state, ownProps.match.params.applicationUuid),
        solicitors: solicitorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
        applicationValueSet: (key: keyof IApplication, value: boolean|number|string) => dispatch(applicationValueSetAction(ownProps.match.params.applicationUuid, key, value)),
        borrowersList: () => dispatch(applicationBorrowersListAction(ownProps.match.params.applicationUuid)),
        dealValueSet: (key: keyof IDeal, value: boolean|number|string) => dispatch(applicationDealValueSetAction(ownProps.match.params.applicationUuid, key, value)),
        solicitorsList: () => dispatch(solicitorsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Edit);
