import { Button, Form, Image, Input, Spin, Typography, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import CKEditor from 'ckeditor4-react';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import {
    investmentGetAction,
    investmentPhotoAction,
    investmentValueSetAction,
} from '~Investments/actions';
import {
    investmentPhotoInProgressSelector,
    investmentSelector,
} from '~Investments/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    investment: IInvestment;
    photoInProgress: boolean;
}

interface IPropsDispatch {
    investmentGet: () => void;
    investmentPhoto: (file: File) => void;
    investmentValueSet: (key: keyof IInvestment, value: any) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Edit extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.beforeUpload = this.beforeUpload.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangePropertySecurity = this.onChangePropertySecurity.bind(this);
        this.onChangePropertyType = this.onChangePropertyType.bind(this);
        this.onChangeMemoSynopsis = this.onChangeMemoSynopsis.bind(this);
    }

    public componentDidMount() {
        const { investment } = this.props;

        if (!investment) {
            this.props.investmentGet();
        }
    }

    public render(): JSX.Element {
        const { investment, match, photoInProgress } = this.props;

        if (!investment) {
            return (
                <Layout uuid={match.params.uuid} section='edit'>
                    <Spin/>
                </Layout>
            );
        }

        const editorConfig: any = {
            disableNativeSpellChecker: false,
            toolbar: [
                ['Bold', 'Italic', '-', 'NumberedList', 'BulletedList', '-', 'Link', 'Unlink'],
                ['FontSize', 'TextColor', 'BGColor'],
            ],
        };

        return (
            <Layout uuid={match.params.uuid} section='edit'>
                <Typography.Title level={2}>Edit</Typography.Title>
                <Form.Item label='Photo' className='photo'>
                    <Image
                        height={200}
                        placeholder={true}
                        preview={investment.photos.length > 0}
                        src={investment.photos.length > 0 ? investment.photos[0].url : null}
                        width={200}
                    />
                    <Upload
                        beforeUpload={this.beforeUpload}
                        disabled={photoInProgress}
                        showUploadList={false}
                    >
                        <Button loading={photoInProgress}>Upload Photo</Button>
                    </Upload>
                </Form.Item>
                <Form.Item label='Description' className='description'>
                    <CKEditor
                        config={editorConfig}
                        data={investment.description}
                        onChange={this.onChangeDescription}
                    />
                </Form.Item>
                <Form.Item label='Property Type' className='property-type'>
                    <Input onChange={this.onChangePropertyType} value={investment.propertyType} />
                </Form.Item>
                <Form.Item label='Property Security' className='property-security'>
                    <Input onChange={this.onChangePropertySecurity} value={investment.propertySecurity} />
                </Form.Item>
                <Form.Item label='Memo Synopsis' className='memo-synopsis'>
                    <TextArea onChange={this.onChangeMemoSynopsis} rows={12} value={investment.memoSynopsis} />
                </Form.Item>
            </Layout>
        );
    }

    private beforeUpload(file: File): boolean {
        this.props.investmentPhoto(file);

        return false;
    }

    private onChangeDescription(event: any) {
        this.props.investmentValueSet('description', event.editor.getData());
    }

    private onChangeMemoSynopsis(event: React.ChangeEvent<HTMLTextAreaElement>): void {
        this.props.investmentValueSet('memoSynopsis', event.target.value);
    }

    private onChangePropertySecurity(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.investmentValueSet('propertySecurity', event.target.value);
    }

    private onChangePropertyType(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.investmentValueSet('propertyType', event.target.value);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investment: investmentSelector(state, ownProps.match.params.uuid),
        photoInProgress: investmentPhotoInProgressSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investmentGet: () => dispatch(investmentGetAction(ownProps.match.params.uuid)),
        investmentPhoto: (file: File) => dispatch(investmentPhotoAction(ownProps.match.params.uuid, file)),
        investmentValueSet: (key: keyof IInvestment, value: any) => dispatch(investmentValueSetAction(ownProps.match.params.uuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Edit);
