import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Spin } from 'antd';
import _ from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import {
    administratorsActiveSelector,
    administratorsSelector,
    currentAdministratorSelector,
} from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import ApprovalStatusEnum from '~Api/Deal/ApprovalStatusEnum';
import IDeal from '~Api/Deal/IDeal';
import { IDictionary } from '~utilities/IDictionary';
import { dealAssignAction } from './actions';

interface IProps {
    deal: IDeal;
}

export default function AssigneeSelector(props: IProps): ReactElement {
    const { deal } = props;

    const activeAdministrators: IAdministrator[] = useSelector(administratorsActiveSelector);
    const administrators: IDictionary<IAdministrator> = useSelector(administratorsSelector);
    const currentAdministrator: IAdministrator = useSelector(currentAdministratorSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!activeAdministrators || !administrators) {
            dispatch(administratorsListAction());
        }
    }, []);

    if (!activeAdministrators || !administrators) {
        return <Spin/>;
    }

    const assignee: IAdministrator = !!deal.administratorUuid && administrators[deal.administratorUuid];

    if ([
        RoleEnum.BusinessDevelopmentManager,
        RoleEnum.InternalBusinessDevelopmentManager,
        RoleEnum.SeniorBusinessDevelopmentManager,
    ].includes(currentAdministrator.role)) {
        return <>{deal.administratorUuid ? (assignee ? assignee.name : 'Inactive User') : 'None'}</>;
    }

    const filteredAssignees: IAdministrator[] = _.filter(activeAdministrators, (admin: IAdministrator) => {
        if ([
            RoleEnum.CreditManager,
            RoleEnum.SeniorCreditManager,
        ].includes(admin.role)) {
            return true;
        }

        if ([
            RoleEnum.BusinessDevelopmentManager,
            RoleEnum.InternalBusinessDevelopmentManager,
            RoleEnum.SeniorBusinessDevelopmentManager,
        ].includes(admin.role) && deal.approvalStatus !== ApprovalStatusEnum.Application) {
            return true;
        }

        return false;
    });

    const administratorsMenu: MenuProps = {
        items: _.sortBy(filteredAssignees, ['name']).map((administrator: IAdministrator) => ({
            key: administrator.uuid,
            label: administrator.name,
            onClick: () => dispatch(dealAssignAction(deal.uuid, administrator.uuid)),
        })),
    };

    return (
        <Dropdown
            disabled={!!deal.closedTime}
            menu={administratorsMenu}
        >
            <a>
                {deal.administratorUuid ? (assignee ? assignee.name : 'Inactive User') : 'None'}
                {' '}<DownOutlined/>
            </a>
        </Dropdown>
    );
}
