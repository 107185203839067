import SpRegionEnum from '~Api/Deal/SpRegionEnum';
import { IDictionary } from '~utilities/IDictionary';

const spRegionLabels: IDictionary<string> = {
    [SpRegionEnum.InnerCity]: 'Inner City',
    [SpRegionEnum.Metro]: 'Metro',
    [SpRegionEnum.NonMetro]: 'Non Metro',
};

export default spRegionLabels;
