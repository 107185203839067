import { Breadcrumb, Button, Descriptions, Layout as LayoutAnt, Menu, Modal, PageHeader, Space, Spin } from 'antd';
import React, { ReactElement, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import {
    administratorActivateAction,
    administratorDeactivateAction,
} from '~Administrators/actions';
import { administratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import { IGlobalState } from '~reducer';
import './administrator.less';
import ActiveStatusEnum from '~Api/Administrator/ActiveStatusEnum';
import PermissionsModal from './PermissionsModal';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

export default function Layout(props: {
    administratorUuid: string;
    breadcrumb?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    section: string;
}): ReactElement {
    const { administratorUuid, breadcrumb, children, section } = props;

    const [ isPermissionsModalOpen, setIsPermissionsModalOpen ] = useState<boolean>(false);

    const administrator: IAdministrator = useSelector((state: IGlobalState) => administratorSelector(state, administratorUuid));

    const dispatch: Dispatch = useDispatch();

    const onClickActivate: () => void = useCallback(() => {
        Modal.confirm({
            content: `Are you sure you want to activate ${administrator.name}?`,
            okText: 'Activate',
            okType: 'danger',
            onOk: () => {
                dispatch(administratorActivateAction(administratorUuid));
            },
            title: 'Activate Administrator',
        });
    }, []);

    const onClickDeactivate: () => void = useCallback(() => {
        Modal.confirm({
            content: `Are you sure you want to deactivate ${administrator.name}?`,
            okText: 'Deactivate',
            okType: 'danger',
            onOk: () => {
                dispatch(administratorDeactivateAction(administratorUuid));
            },
            title: 'Deactivate Administrator',
        });
    }, []);

    const onClickPermissions: () => void = useCallback(() => {
        setIsPermissionsModalOpen(true);
    }, []);

    const onClosePermissionsModal: () => void = useCallback(() => {
        setIsPermissionsModalOpen(false);
    }, []);

    if (!administrator) {
        return (
            <LayoutAnt className='administrator'>
                <Spin/>
            </LayoutAnt>
        );
    }

    const breadcrumbBlock: JSX.Element = breadcrumb ? (
        <>
            <Breadcrumb.Item><Link to={`/administrators/${administrator.uuid}`}>{administrator.name}</Link></Breadcrumb.Item>
            {breadcrumb}
        </>
    ) : (
        <Breadcrumb.Item>{administrator.name}</Breadcrumb.Item>
    );

    const extra: JSX.Element = (
        <Space>
            <Button onClick={onClickPermissions}>Permissions</Button>
            {ActiveStatusEnum.Active === administrator.status && <Button onClick={onClickDeactivate} danger={true}>Deactivate</Button>}
            {ActiveStatusEnum.Inactive === administrator.status && <Button onClick={onClickActivate} danger={true}>Activate</Button>}
        </Space>
    );

    const menuItems: ItemType[] = [
        {
            key: 'overview',
            label: <NavLink to={`/administrators/${administrator.uuid}`}>Overview</NavLink>,
        },
        {
            key: 'edit',
            label: <NavLink to={`/administrators/${administrator.uuid}/edit`}>Edit</NavLink>,
        },
        {
            key: 'passkeys',
            label: <NavLink to={`/administrators/${administrator.uuid}/passkeys`}>Passkeys</NavLink>,
        },
        {
            key: 'history',
            label: <NavLink to={`/administrators/${administrator.uuid}/history`}>History</NavLink>,
        },
    ];

    return (
        <LayoutAnt className='administrator'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/administrators'>Administrators</Link></Breadcrumb.Item>
                {breadcrumbBlock}
            </Breadcrumb>
            <PageHeader
                className='header'
                extra={extra}
                ghost={false}
                title={administrator.name}
            >
                <Descriptions size='small' column={3}>
                    <Descriptions.Item label='Email'><a href={`mailto:${administrator.email}`}>{administrator.email}</a></Descriptions.Item>
                </Descriptions>
            </PageHeader>
            <LayoutAnt className='content-wrapper'>
                <LayoutAnt.Sider className='menu' theme='light' width={240}>
                    <Menu
                        defaultSelectedKeys={[section]}
                        items={menuItems}
                        mode='inline'
                    />
                </LayoutAnt.Sider>
                <LayoutAnt.Content className={`content ${section}`}>
                    {children}
                </LayoutAnt.Content>
            </LayoutAnt>

            <PermissionsModal
                administratorUuid={administrator.uuid}
                close={onClosePermissionsModal}
                open={isPermissionsModalOpen}
            />
        </LayoutAnt>
    );
}
