import _ from 'lodash';
import ApprovalStatusEnum from '~Api/Deal/ApprovalStatusEnum';
import IDeal from '~Api/Deal/IDeal';
import IMovementReportLead from '~Api/Deal/IMovementReportLead';
import IOriginationReportLead from '~Api/Deal/IOriginationReportLead';
import IQuote from '~Api/Deal/IQuote';
import { dealPropertiesSelector } from '~Deals/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { PeriodRangeEnum } from '~utilities/reportUtilities';
import { ILeadAddErrors } from './reducer';
import { brokerSelector } from '~Brokers/selectors';
import IBroker from '~Api/Broker/IBroker';
import { keyMap } from '~utilities/utils';

const boardStatuses: ApprovalStatusEnum[] = [
    ApprovalStatusEnum.Draft,
    ApprovalStatusEnum.Pending,
    ApprovalStatusEnum.InitialCall,
    ApprovalStatusEnum.UncontactableFollowUp1,
    ApprovalStatusEnum.UncontactableFollowUp2,
    ApprovalStatusEnum.UncontactableFollowUp3,
    ApprovalStatusEnum.Quote,
    ApprovalStatusEnum.FollowUp1,
    ApprovalStatusEnum.FollowUp2,
    ApprovalStatusEnum.FollowUp3,
];

function hydrateLead(state: IGlobalState, deal: IDeal): IDeal {
    const cloneLead: IDeal = { ...deal };
    cloneLead.properties = _.values(dealPropertiesSelector(state, deal.uuid));
    if (cloneLead.brokerUuid) {
        cloneLead.broker = brokerSelector(state, cloneLead.brokerUuid);
    }
    return cloneLead;
}
function hydrateQuote(state: IGlobalState, quote: IQuote): IQuote {
    const cloneQuote: IQuote = { ...quote };
    if (cloneQuote.dealUuid) {
        cloneQuote.deal = leadSelector(state, cloneQuote.dealUuid);
    }
    return cloneQuote;
}

export function leadsPaginatedCountSelector(state: IGlobalState): number {
    return state.leads.leadsPaginatedCount;
}

export function leadsPaginatedSelector(state: IGlobalState): IDictionary<IDeal> {
    if (!state.leads.leadsPaginatedUuids) {
        return null;
    }

    const deals: IDictionary<IDeal> = {};

    state.leads.leadsPaginatedUuids.forEach((dealUuid: string) => {
        deals[dealUuid] = leadSelector(state, dealUuid);
    });

    return deals;
}

export function leadsBoardSelector(state: IGlobalState): IDictionary<IDeal> {
    if (!state.leads.dashboardUuids) {
        return null;
    }

    const deals: IDictionary<IDeal> = {};

    _.forEach(state.leads.deals, (deal: IDeal) => {
        if (!_.values(boardStatuses).includes(deal.approvalStatus) || deal.closedTime) {
            return;
        }

        deals[deal.uuid] = leadSelector(state, deal.uuid);
    });

    return deals;
}

export function leadsOriginationReportEndDateSelector(state: IGlobalState): string {
    return state.leads.originationReport.endDate;
}

export function leadsOriginationReportLeadsSelector(state: IGlobalState): IDictionary<IOriginationReportLead> {
    return state.leads.originationReport.leads;
}

export function leadsOriginationReportPeriodRangeSelector(state: IGlobalState): PeriodRangeEnum {
    return state.leads.originationReport.periodRange;
}

export function leadsOriginationReportStartDateSelector(state: IGlobalState): string {
    return state.leads.originationReport.startDate;
}

export function leadsMovementReportEndTimeSelector(state: IGlobalState): string {
    return state.leads.movementReport.endTime;
}

export function leadsMovementReportLeadsSelector(state: IGlobalState): IDictionary<IMovementReportLead> {
    return state.leads.movementReport.leads;
}

export function leadsMovementReportPeriodRangeSelector(state: IGlobalState): PeriodRangeEnum {
    return state.leads.movementReport.periodRange;
}

export function leadsMovementReportStartTimeSelector(state: IGlobalState): string {
    return state.leads.movementReport.startTime;
}

export function leadsPendingQuotesSelector(state: IGlobalState): IDictionary<IQuote> {
    if (!state.leads.pendingQuoteUuids) {
        return null;
    }
    
    return keyMap(state.leads.pendingQuoteUuids, 'uuid', (quoteUuid: string) => hydrateQuote(state, state.leads.quotes[quoteUuid]));
}

export function leadsSearchResultsSelector(state: IGlobalState): IDeal[] {
    if (!state.leads.leadsSearchResultUuids) {
        return null;
    }

    return state.leads.leadsSearchResultUuids.map((dealUuid: string) => leadSelector(state, dealUuid));
}

export function leadBrokerSearchLoadingSelector(state: IGlobalState): boolean {
    return state.leads.leadBrokerSearchLoading;
}

export function leadBrokerSearchResultsSelector(state: IGlobalState): IBroker[] {
    if (!state.leads.leadBrokerSearchUuids) {
        return null;
    }

    return state.leads.leadBrokerSearchUuids.map((brokerUuid: string): IBroker => brokerSelector(state, brokerUuid));
}

export function leadSelector(state: IGlobalState, dealUuid: string): IDeal {
    if (!state.leads.deals || !state.leads.deals[dealUuid]) {
        return null;
    }

    return hydrateLead(state, state.leads.deals[dealUuid]);
}

export function leadAddErrorsSelector(state: IGlobalState): ILeadAddErrors {
    return state.leads.addErrors;
}

export function leadQuotesSelector(state: IGlobalState, dealUuid: string): IQuote[] {
    if (!state.leads.leadQuoteUuids[dealUuid]) {
        return null;
    }
    
    return _.map(state.leads.leadQuoteUuids[dealUuid], (quoteUuid: string) => hydrateQuote(state, state.leads.quotes[quoteUuid]));
}
