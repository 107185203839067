import { Checkbox, Form, Input, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IInvestorAccountIncomeTrust from '~Api/IncomeTrust/IInvestorAccountIncomeTrust';
import classTypeLabels from '~Api/IncomeTrust/classTypeLabels';
import IAccount from '~Api/Investor/IAccount';
import { investorAccountIncomeTrustInvestAction } from '~Investors/actions';
import { investorAccountSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';

interface IState {
    amount: number;
    errors: {
        amount?: string;
    };
    sendEmail: boolean;
}

interface IProps {
    investorAccountIncomeTrust: IInvestorAccountIncomeTrust;
    isOpen: boolean;
    onClose: () => void;
}

interface IPropsDispatch {
    invest: (amount: number, sendEmail: boolean) => void;
}
interface IPropsSelector {
    investorAccount: IAccount;
}

type Props = IProps & IPropsDispatch & IPropsSelector;

class IncomeTrustInvestModal extends React.Component<Props, IState> {
    public state: IState = {
        amount: null,
        errors: {},
        sendEmail: true,
    };

    constructor(props: Props) {
        super(props);
        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.validateAmount = this.validateAmount.bind(this);
        this.onChangeSendEmail = this.onChangeSendEmail.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { investorAccountIncomeTrust, isOpen } = this.props;
        const { amount, errors, sendEmail } = this.state;

        return (
            <Modal
                destroyOnClose={true}
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title={`Invest in ${classTypeLabels[investorAccountIncomeTrust.classType]} Income Trust`}
                wrapClassName='investor-income-trust-invest-modal'
            >
                <Form.Item className='amount' label='Amount' help={errors.amount} validateStatus={errors.amount && 'error'}>
                    <Input addonBefore='$' min={0} onChange={this.onChangeAmount} onBlur={this.validateAmount} type='number' value={amount || ''} />
                </Form.Item>
                <Form.Item className='send-email' label='Email Investor'>
                    <Checkbox onChange={this.onChangeSendEmail} checked={sendEmail} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeSendEmail(event: CheckboxChangeEvent): void {
        this.setState({
            sendEmail: event.target.checked,
        });
    }

    private onChangeAmount(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            amount: event.target.value ? event.target.valueAsNumber : null,
        });
    }

    private onClickOk(): void {
        const { amount, sendEmail } = this.state;

        if (!this.validateAmount()) {
            return;
        }

        this.props.invest(amount, sendEmail);
        this.props.onClose();
    }

    private validateAmount(): boolean {
        const { amount, errors } = this.state;
        const { investorAccount } = this.props;

        let error: string;

        if (!amount) {
            error = 'Please enter an amount';
        } else if (amount > investorAccount.balance) {
            error = 'Amount exceeds account balance';
        }

        this.setState({
            errors: {
                ...errors,
                amount: error,
            },
        });

        return !error;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investorAccount: investorAccountSelector(state, ownProps.investorAccountIncomeTrust.investorAcountUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        invest: (amount: number, sendEmail: boolean) => dispatch(investorAccountIncomeTrustInvestAction(ownProps.investorAccountIncomeTrust.uuid, amount, sendEmail)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IncomeTrustInvestModal);
