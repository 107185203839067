import _ from 'lodash';
import { AnyAction } from 'redux';
import {
    IAggregatorsSetAction,
} from './actions';
import AggregatorsActionsEnum from './ActionsEnum';
import IAggregator from '~Api/Aggregator/IAggregator';
import { IDictionary } from '~utilities/IDictionary';

export interface IAggregatorsState {
    aggregators: IDictionary<IAggregator>;
}

const initialData: IAggregatorsState = {
    aggregators: null,
};

export function aggregatorsReducer(state: IAggregatorsState = initialData, action: AnyAction): IAggregatorsState {
    switch (action.type) {
        case AggregatorsActionsEnum.AggregatorsSet: {
            const typedAction: IAggregatorsSetAction = action as IAggregatorsSetAction;

            return {
                ...state,
                aggregators: _.keyBy(typedAction.aggregators, 'uuid'),
            };
        }

        default:
            return state;
    }
}
