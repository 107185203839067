import { Button, Modal, Space, Spin, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IApplicationFee from '~Api/Application/IApplicationFee';
import {
    applicationFeeDeleteAction,
    applicationFeesListAction,
    applicationGetAction,
} from '~Applications/actions';
import {
    applicationFeesSelector,
    applicationSelector,
} from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import FeeAddModal from './FeeAddModal';
import FeeEditModal from './FeeEditModal';
import Layout from './Layout';
import { ColumnType } from 'antd/lib/table';
import { IDictionary } from '~utilities/IDictionary';

interface IState {
    editFeeUuid: string;
    isAddModalOpen: boolean;
}

interface IMatch {
    applicationUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    application: IApplication;
    fees: IDictionary<IApplicationFee>;
}

interface IPropsDispatch {
    applicationGet: () => void;
    feeDelete: (feeUuid: string) => void;
    feesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Fees extends React.Component<Props, IState> {
    public state: IState = {
        editFeeUuid: null,
        isAddModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAdd = this.onClickAdd.bind(this);
        this.onClickEdit = this.onClickEdit.bind(this);
        this.onCloseAddModal = this.onCloseAddModal.bind(this);
        this.onCloseEditModal = this.onCloseEditModal.bind(this);

        this.summary = this.summary.bind(this);
    }

    public componentDidMount() {
        const { application, fees } = this.props;

        if (!application) {
            this.props.applicationGet();
        }

        if (!fees) {
            this.props.feesList();
        }
    }

    public render(): JSX.Element {
        const { application, match, fees } = this.props;
        const { editFeeUuid, isAddModalOpen } = this.state;

        if (!application || !fees) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='fees'>
                    <Typography.Title level={2}>Fees &amp; Outlays</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnType<IApplicationFee>[] = [
            {
                dataIndex: 'createdTime',
                render: (createdTime: string) => dayjs(createdTime).format('D/M/YYYY'),
                title: 'Date',
                width: '10%',
            },
            {
                dataIndex: 'description',
                title: 'Description',
            },
            {
                dataIndex: 'amount',
                render: (amount: number) => currencyFormatter.format(amount),
                title: 'Amount',
                width: '15%',
            },
            {
                render: (fee: IApplicationFee) => {
                    const onClickEdit = () => this.onClickEdit(fee.uuid);

                    const onClickDelete = () => {
                        Modal.confirm({
                            content: 'Are you sure you want to delete this fee/outlay?',
                            okText: 'Delete',
                            okType: 'danger',
                            onOk: () => {
                                this.props.feeDelete(fee.uuid);
                            },
                            title: 'Delete',
                        });
                    };

                    return (
                        <Space>
                            <Button onClick={onClickEdit}>Edit</Button>
                            <Button danger={true} onClick={onClickDelete}>Delete</Button>
                        </Space>
                    );
                },
                title: 'Actions',
                width: '15%',
            },
        ];

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='fees'>
                <Space className='actions'>
                    <Button onClick={this.onClickAdd}>Add Fee/Outlay</Button>
                </Space>
                <Typography.Title level={2}>Fees &amp; Outlays</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.sortBy(fees, ['createdTime'])}
                    rowKey='uuid'
                    pagination={false}
                    size='middle'
                    summary={this.summary}
                />
                <FeeEditModal
                    feeUuid={editFeeUuid}
                    applicationUuid={application.uuid}
                    isOpen={!!editFeeUuid}
                    onClose={this.onCloseEditModal}
                />
                <FeeAddModal
                    applicationUuid={application.uuid}
                    isOpen={isAddModalOpen}
                    onClose={this.onCloseAddModal}
                />
            </Layout>
        );
    }

    private onClickAdd() {
        this.setState({
            isAddModalOpen: true,
        });
    }

    private onClickEdit(feeUuid: string) {
        this.setState({
            editFeeUuid: feeUuid,
        });
    }

    private onCloseAddModal() {
        this.setState({
            isAddModalOpen: false,
        });
    }

    private onCloseEditModal() {
        this.setState({
            editFeeUuid: null,
        });
    }

    private summary(): JSX.Element {
        const { fees } = this.props;

        const total: number = _.reduce(fees, (sum: number, loopFee: IApplicationFee) => sum + loopFee.amount, 0);

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}><strong>Total</strong></Table.Summary.Cell>
                <Table.Summary.Cell index={1}/>
                <Table.Summary.Cell index={2}><strong>{currencyFormatter.format(total)}</strong></Table.Summary.Cell>
                <Table.Summary.Cell index={3}/>
            </Table.Summary.Row>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
        fees: applicationFeesSelector(state, ownProps.match.params.applicationUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
        feeDelete: (feeUuid: string) => dispatch(applicationFeeDeleteAction(feeUuid)),
        feesList: () => dispatch(applicationFeesListAction(ownProps.match.params.applicationUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Fees);
