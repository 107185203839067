import { SearchOutlined } from '@ant-design/icons';
import { Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import ITransaction from '~Api/Investor/ITransaction';
import {
    investorAccountTransactionsListAction,
    investorGetAction,
} from '~Investors/actions';
import {
    investorAccountSelector,
    investorAccountTransactionsSelector,
    investorSelector,
} from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import SearchDropdown from '~UI/SearchDropdown';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import TransactionTypeEnum from '~Api/Investor/TransactionTypeEnum';

const typeLabels: IDictionary<string> = {
    [TransactionTypeEnum.Deposit]: 'Deposit',
    [TransactionTypeEnum.LoanIssued]: 'Loan Issued',
    [TransactionTypeEnum.Payment]: 'Payment',
    [TransactionTypeEnum.Withdrawal]: 'Withdrawal',
};

interface IMatch {
    accountUuid: string;
    investorUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    investor: IInvestor;
    investorAccount: IAccount;
    transactions: IDictionary<ITransaction>;
}

interface IPropsDispatch {
    investorGet: () => void;
    transactionsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Transactions extends React.Component<Props> {
    private loadingUuid: string = null;

    public componentDidMount(): void {
        const { investor, match } = this.props;

        if (!investor) {
            this.props.investorGet();
        }

        this.props.transactionsList();

        this.loadingUuid = match.params.accountUuid;
    }

    public componentDidUpdate(): void {
        const { investor, match } = this.props;

        if (this.loadingUuid !== match.params.accountUuid) {
            if (!investor) {
                this.props.investorGet();
            }

            this.props.transactionsList();

            this.loadingUuid = match.params.accountUuid;
        }
    }

    public render(): JSX.Element {
        const { investor, match, transactions } = this.props;

        if (!investor || !transactions) {
            return (
                <Layout uuid={match.params.investorUuid} section={`transactions-${match.params.accountUuid}`} sectionClass='transactions'>
                    <Typography.Title level={2}>Transactions</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnType<ITransaction>[] = [
            {
                dataIndex: 'activityDate',
                defaultSortOrder: 'descend',
                render: (activityDate: string) => dayjs(activityDate).format('D MMM YYYY'),
                sorter: (a: ITransaction, b: ITransaction) => {
                    if (a.activityDate === b.activityDate) {
                        return a.amount > b.amount ? 1 : -1;
                    }

                    return a.activityDate > b.activityDate ? 1 : -1;
                },
                title: 'Date',
                width: '10%',
            },
            {
                dataIndex: 'type',
                filters: [
                    {
                        text: typeLabels[TransactionTypeEnum.Deposit],
                        value: TransactionTypeEnum.Deposit,
                    },
                    {
                        text: typeLabels[TransactionTypeEnum.LoanIssued],
                        value: TransactionTypeEnum.LoanIssued,
                    },
                    {
                        text: typeLabels[TransactionTypeEnum.Payment],
                        value: TransactionTypeEnum.Payment,
                    },
                    {
                        text: typeLabels[TransactionTypeEnum.Withdrawal],
                        value: TransactionTypeEnum.Withdrawal,
                    },
                ],
                onFilter: (value: string | number | boolean, transaction: ITransaction) => transaction.type === value,
                render: (type: TransactionTypeEnum) => typeLabels[type],
                title: 'Type',
                width: '15%',
            },
            {
                className: 'description',
                dataIndex: 'description',
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string | number | boolean, transaction: ITransaction) => transaction.description.toLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                title: 'Description',
            },
            {
                align: 'right',
                dataIndex: 'amount',
                render: (amount: number) => currencyFormatter.format(amount),
                sorter: (a: ITransaction, b: ITransaction) => a.amount > b.amount ? 1 : -1,
                title: 'Amount',
                width: '15%',
            },
            {
                align: 'right',
                dataIndex: 'balance',
                render: (balance: number) => currencyFormatter.format(balance),
                title: 'Balance',
                width: '15%',
            },
        ];

        return (
            <Layout uuid={match.params.investorUuid} section={`transactions-${match.params.accountUuid}`} sectionClass='transactions'>
                <Typography.Title level={2}>Transactions</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(transactions)}
                    pagination={{ defaultPageSize: 50 }}
                    rowKey='uuid'
                    size='middle'
                />
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investor: investorSelector(state, ownProps.match.params.investorUuid),
        investorAccount: investorAccountSelector(state, ownProps.match.params.accountUuid),
        transactions: investorAccountTransactionsSelector(state, ownProps.match.params.accountUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investorGet: () => dispatch(investorGetAction(ownProps.match.params.investorUuid)),
        transactionsList: () => dispatch(investorAccountTransactionsListAction(ownProps.match.params.accountUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Transactions);
