import { Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IApplicationWarehouse from '~Api/Application/IApplicationWarehouse';
import { warehousePendingApplicationSelector } from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import { currencyFormatter } from '~utilities/formatters';
import { IDictionary } from '~utilities/IDictionary';
import { warehousePendingApplicationsListAction } from '~Warehouses/actions';
import Layout from './Layout';

export default function PendingApplications(): ReactElement {
    const { warehouseUuid } = useParams<{ warehouseUuid: string }>();

    const applicationWarehouses: IDictionary<IApplicationWarehouse> = useSelector((state: IGlobalState) => warehousePendingApplicationSelector(state, warehouseUuid));

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!applicationWarehouses) {
            dispatch(warehousePendingApplicationsListAction(warehouseUuid));
        }
    }, []);

    if (!applicationWarehouses) {
        return (
            <Layout uuid={warehouseUuid} section='pending-applications'>
                <Typography.Title level={2}>Pending Applications</Typography.Title>
                <Spin/>
            </Layout>
        );
    }

    const columns: ColumnType<IApplicationWarehouse>[] = [
        {
            dataIndex: 'application',
            render: (application: IApplication) => <Link to={`/applications/${application.uuid}`}>{application.code}</Link>,
            title: 'Code',
            width: 180,
        },
        {
            dataIndex: 'application',
            render: (application: IApplication) => <Link to={`/applications/${application.uuid}`}>{application.formattedName}</Link>,
            title: 'Name',
        },
        {
            dataIndex: ['application', 'loanAmount'],
            render: (loanAmount: number) => currencyFormatter.format(loanAmount),
            title: 'Amount',
            width: 180,
        },
        {
            dataIndex: ['application', 'settlementDate'],
            defaultSortOrder: 'descend',
            render: (settlementDate: string) => dayjs(settlementDate).format('D/M'),
            sorter: (a: IApplicationWarehouse, b: IApplicationWarehouse) => {
                const aSettlementDate: Dayjs = dayjs(a.application.settlementDate);
                const bSettlementDate: Dayjs = dayjs(b.application.settlementDate);
                return aSettlementDate > bSettlementDate ? 1 : -1;
            },
            title: 'Settlement Date',
            width: 180,
        },
        {
            dataIndex: 'amount',
            render: (amount: number) => currencyFormatter.format(amount),
            title: 'Warehouse Amount',
            width: 180,
        },
    ];

    const summary: (data: readonly IApplicationWarehouse[]) => React.ReactNode = (data: readonly IApplicationWarehouse[]) => {
        let totalAmount: number = 0;
        let totalWarehouseAmount: number = 0;
        _.each(data, (applicationWarehouse: IApplicationWarehouse) => {
            totalAmount += applicationWarehouse.application.loanAmount;
            totalWarehouseAmount += applicationWarehouse.amount;
        });
    
        return (
            <Table.Summary fixed>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>&nbsp;</Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>{currencyFormatter.format(totalAmount)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>&nbsp;</Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>{currencyFormatter.format(totalWarehouseAmount)}</Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        );
    };

    return (
        <Layout uuid={warehouseUuid} section='pending-applications'>
            <Typography.Title level={2}>Pending Applications</Typography.Title>
            <Table
                    columns={columns}
                    dataSource={_.values(applicationWarehouses)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                    summary={summary}
                />
        </Layout>
    );
}
