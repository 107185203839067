import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Spin, Table, Tooltip, Typography, Upload } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IDocument from '~Api/Deal/IDocument';
import { authTokenSelector } from '~Auth/selectors';
import { IGlobalState } from '~reducer';
import {
    dealDocumentsAddAction,
    dealDocumentsListAction,
} from './actions';
import './deals.less';
import { dealDocumentsSelector } from './selectors';
import { ColumnType } from 'antd/lib/table';
import { IDictionary } from '~utilities/IDictionary';
import _ from 'lodash';

interface IProps {
    dealUuid: string;
}

interface IPropsSelector {
    documents: IDictionary<IDocument>;
    token: string;
}

interface IPropsDispatch {
    dealDocumentsAdd: (file: File) => void;
    dealDocumentsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class DocumentsList extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.beforeUpload = this.beforeUpload.bind(this);
    }

    public componentDidMount() {
        const { documents } = this.props;

        if (!documents) {
            this.props.dealDocumentsList();
        }
    }

    public render(): JSX.Element {
        const { documents, token } = this.props;

        if (!documents) {
            return (
                <Spin/>
            );
        }

        const columns: ColumnType<IDocument>[] = [
            {
                dataIndex: 'filename',
                title: 'Filename',
            },
            {
                render: (document: IDocument) => {
                    return (
                        <React.Fragment>
                            <Tooltip title='Download'>
                                <Button
                                    type='link'
                                    href={`${process.env.API_HOST}/deal-documents/${document.uuid}/download?token=${token}`}
                                    target='_blank'
                                >
                                    <CloudDownloadOutlined/>
                                </Button>
                            </Tooltip>
                        </React.Fragment>
                    );
                },
                title: 'Actions',
                width: '15%',
            },
        ];

        return (
            <div className='deal-documents'>
                <Upload className='add-document' beforeUpload={this.beforeUpload}>
                    <Button>Add Document</Button>
                </Upload>
                <Typography.Title level={2}>Documents</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(documents)}
                    pagination={false}
                    rowKey='uuid'
                />
            </div>
        );
    }

    private beforeUpload(file: File): boolean {
        this.props.dealDocumentsAdd(file);

        return false;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        documents: dealDocumentsSelector(state, ownProps.dealUuid),
        token: authTokenSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        dealDocumentsAdd: (file: File) => dispatch(dealDocumentsAddAction(ownProps.dealUuid, file)),
        dealDocumentsList: () => dispatch(dealDocumentsListAction(ownProps.dealUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DocumentsList);
