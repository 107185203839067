import { Button, Modal, Spin, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import {
    administratorPasskeyDeactivateAction,
    administratorPasskeysListAction,
} from '~Administrators/actions';
import { IDictionary } from '~utilities/IDictionary';
import IPasskey from '~Api/Administrator/IPasskey';
import { administratorPasskeysSelector } from '~Administrators/selectors';
import PasskeyStatusEnum from '~Api/Administrator/PasskeyStatusEnum';
import dayjs from 'dayjs';
import { CheckOutlined } from '@ant-design/icons';
import Table, { ColumnType } from 'antd/lib/table';
import _ from 'lodash';

interface IMatch {
    administratorUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    passkeys: IDictionary<IPasskey>;
}

interface IPropsDispatch {
    passkeyDeactivate: (passkeyUuid: string) => void;
    passkeysList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Passkeys extends React.Component<Props> {
    public componentDidMount() {
        this.props.passkeysList();
    }

    public render(): JSX.Element {
        const { match, passkeys } = this.props;

        if (!passkeys) {
            return (
                <Layout administratorUuid={match.params.administratorUuid} section='passkeys'>
                    <Typography.Title level={2}>Passkeys</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const columns: ColumnType<IPasskey>[] = [
            {
                dataIndex: 'createdTime',
                render: (createdTime: string) => dayjs(createdTime).fromNow(),
                sorter: (a: IPasskey, b: IPasskey) => dayjs(a.createdTime) > dayjs(b.createdTime) ? 1 : -1,
                title: 'Created',
            },
            {
                dataIndex: 'lastUsedTime',
                defaultSortOrder: 'descend',
                render: (lastUsedTime: string) => lastUsedTime ? dayjs(lastUsedTime).fromNow() : '-',
                sorter: (a: IPasskey, b: IPasskey) => (a.lastUsedTime && dayjs(a.lastUsedTime)) > (b.lastUsedTime && dayjs(b.lastUsedTime)) ? 1 : -1,
                title: 'Last Used',
                width: '20%',
            },
            {
                dataIndex: 'status',
                defaultFilteredValue: [PasskeyStatusEnum.Active],
                filters: [
                    {
                        text: 'Active',
                        value: PasskeyStatusEnum.Active,
                    },
                    {
                        text: 'Inactive',
                        value: PasskeyStatusEnum.Inactive,
                    },
                ],
                onFilter: (value: boolean|number|string, passkey: IPasskey) => value === passkey.status,
                render: (status: PasskeyStatusEnum) => status === PasskeyStatusEnum.Active && <CheckOutlined />,
                title: 'Active',
                width: '5%',
            },
            {
                render: (passkey: IPasskey) => {
                    if (PasskeyStatusEnum.Active !== passkey.status) {
                        return null;
                    }

                    const onClick: () => void = () => this.onClickDeactivate(passkey.uuid);

                    return (<Button danger={true} onClick={onClick}>Deactivate</Button>);
                },
                title: '',
                width: '5%',
            },
        ];

        return (
            <Layout administratorUuid={match.params.administratorUuid} section='passkeys'>
                <Typography.Title level={2}>Passkeys</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(passkeys)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                />
            </Layout>
        );
    }

    private onClickDeactivate(passkeyUuid: string): void {
        Modal.confirm({
            content: 'Are you sure you want to deactivate this passkey?',
            okText: 'Deactivate',
            okType: 'danger',
            onOk: () => {
                this.props.passkeyDeactivate(passkeyUuid);
            },
            title: 'Deactivate Passkey',
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        passkeys: administratorPasskeysSelector(state, ownProps.match.params.administratorUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        passkeyDeactivate: (passkeyUuid: string) => dispatch(administratorPasskeyDeactivateAction(passkeyUuid)),
        passkeysList: () => dispatch(administratorPasskeysListAction(ownProps.match.params.administratorUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Passkeys);
