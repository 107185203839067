import { Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestorAccountTransaction from '~Api/Investor/IInvestorAccountTransaction';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { investorMovementsListAction } from './actions';
import Layout from './Layout';
import { investorMovementsSelector } from './selectors';

const historyDays: number = 30;

interface IInvestorMovement {
    date: string;
    transaction?: IInvestorAccountTransaction;
    uuid: string;
}

interface IDay {
    dailyDeposits: number;
    dailyWithdrawals: number;
    date: string;
    transactionCount: number;
    primaryRowUuid?: string;
}

interface IPropsSelector {
    transactions: IDictionary<IInvestorAccountTransaction>;
}

interface IPropsDispatch {
    investorMovementsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class InvestorMovements extends React.Component<Props> {
    public componentDidMount(): void {
        this.props.investorMovementsList();
    }

    public render(): JSX.Element {
        const { transactions } = this.props;

        if (!transactions) {
            return (
                <Layout section='investor-movements'>
                    <Typography.Title level={2}>Investor Movements</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const days: IDictionary<IDay> = {};
        const investorMovements: IInvestorMovement[] = [];

        for (let i: number = 0; i <= historyDays; i++) {
            const loopDate: string = dayjs().subtract(i, 'days').format('YYYY-MM-DD');
            days[loopDate] = {
                dailyDeposits: 0,
                dailyWithdrawals: 0,
                date: loopDate,
                transactionCount: 0,
            };
            investorMovements.push({
                date: loopDate,
                uuid: loopDate,
            });
        }

        _.each(transactions, (transaction: IInvestorAccountTransaction) => {
            const transactionTimeDaysjs: Dayjs = dayjs(transaction.transactionTime);
            const transactionDate: string = transactionTimeDaysjs.format('YYYY-MM-DD');

            const day: IDay = days[transactionDate];
            if (!day.primaryRowUuid) {
                day.primaryRowUuid = transaction.uuid;

                const initialPaymentToInvestor: IInvestorMovement = _.find(investorMovements, (paymentToInvestor: IInvestorMovement) => paymentToInvestor.date === transactionDate);
                initialPaymentToInvestor.transaction = transaction;
                initialPaymentToInvestor.uuid = transaction.uuid;
            } else {
                investorMovements.push({
                    date: transactionDate,
                    transaction,
                    uuid: transaction.uuid,
                });
            }

            day.transactionCount++;
            if (transaction.amount > 0) {
                day.dailyDeposits += transaction.amount;
            }
            if (transaction.amount < 0) {
                day.dailyWithdrawals += transaction.amount;
            }
        });

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnsType<IInvestorMovement> = [
            {
                className: 'date',
                render: (investorMovement: IInvestorMovement) => {
                    if (!investorMovement.transaction) {
                        return dayjs(investorMovement.date).format('D/M');
                    }

                    const day: IDay = days[investorMovement.date];
                    return {
                        children: dayjs(investorMovement.date).format('D/M'),
                        props: {
                            // When the rowSpan of the cell is set to zero, it essentially hides the cell.
                            rowSpan: (day.primaryRowUuid === investorMovement.transaction.uuid) ? day.transactionCount : 0,
                        },
                    };
                },
                title: 'Date',
                width: '5%',
            },
            {
                className: 'day',
                render: (investorMovement: IInvestorMovement) => {
                    if (!investorMovement.transaction) {
                        return dayjs(investorMovement.date).format('dddd');
                    }

                    const day: IDay = days[investorMovement.date];
                    return {
                        children: dayjs(investorMovement.date).format('dddd'),
                        props: {
                            rowSpan: (day.primaryRowUuid === investorMovement.transaction.uuid) ? day.transactionCount : 0,
                        },
                    };
                },
                title: 'Day',
                width: '10%',
            },
            {
                className: 'daily-deposits',
                render: (investorMovement: IInvestorMovement) => {
                    if (!investorMovement.transaction) {
                        return '-';
                    }

                    const day: IDay = days[investorMovement.date];
                    return {
                        children: day.dailyDeposits !== 0 ? currencyFormatter.format(day.dailyDeposits) : '-',
                        props: {
                            rowSpan: (day.primaryRowUuid === investorMovement.transaction.uuid) ? day.transactionCount : 0,
                        },
                    };
                },
                title: 'Daily Deposits',
                width: '15%',
            },
            {
                className: 'daily-withdrawals',
                render: (paymentToInvestor: IInvestorMovement) => {
                    if (!paymentToInvestor.transaction) {
                        return '-';
                    }

                    const day: IDay = days[paymentToInvestor.date];
                    return {
                        children: day.dailyWithdrawals !== 0 ? currencyFormatter.format(day.dailyWithdrawals) : '-',
                        props: {
                            rowSpan: (day.primaryRowUuid === paymentToInvestor.transaction.uuid) ? day.transactionCount : 0,
                        },
                    };
                },
                title: 'Daily Withdrawals',
                width: '15%',
            },
            {
                dataIndex: 'transaction',
                render: (transaction: IInvestorAccountTransaction) => {
                    if (!transaction) {
                        return '-';
                    }

                    return <Link to={`/investors/${transaction.investorUuid}`}>{transaction.investor.name}</Link>;
                },
                title: 'Investor',
            },
            {
                dataIndex: 'transaction',
                render: (transaction: IInvestorAccountTransaction) => {
                    if (!transaction || transaction.amount < 0) {
                        return '-';
                    }

                    return currencyFormatter.format(transaction.amount);
                },
                title: 'Deposit',
                width: '15%',
            },
            {
                dataIndex: 'transaction',
                render: (transaction: IInvestorAccountTransaction) => {
                    if (!transaction || transaction.amount > 0) {
                        return '-';
                    }

                    return currencyFormatter.format(transaction.amount);
                },
                title: 'Withdrawal',
                width: '15%',
            },
        ];

        return (
            <Layout section='investor-movements'>
                <Typography.Title level={2}>Investor Movements</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(_.orderBy(investorMovements, ['date'], ['desc']))}
                    rowKey='uuid'
                    pagination={false}
                    size='middle'
                />
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        transactions: investorMovementsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        investorMovementsList: () => dispatch(investorMovementsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InvestorMovements);
