import { UserOutlined } from '@ant-design/icons';
import { Card, Space, Tooltip } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { GrNew } from 'react-icons/gr';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { currentAdministratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import ApprovalStatusEnum from '~Api/Investor/ApprovalStatusEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import { IGlobalState } from '~reducer';
import AccountTypeTag from './Investor/AccountTypeTag';
import ImSelector from './Investor/ImSelector';
import NextContactModal from './Investor/NextContactModal';
import ApprovalStatusIcon from './Investor/ApprovalStatusIcon';

interface IState {
    isNextContactModalOpen: boolean;
}

interface IProps {
    investor: IInvestor;
}

interface IPropsSelector {
    currentAdministrator: IAdministrator;
}

type Props = IProps & IPropsSelector;

class InvestorCard extends React.Component<Props, IState> {
    public state: IState = {
        isNextContactModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickNextContact = this.onClickNextContact.bind(this);
        this.onNextContactCancel = this.onNextContactCancel.bind(this);
    }

    public render(): JSX.Element {
        const { currentAdministrator, investor } = this.props;
        const { isNextContactModalOpen } = this.state;

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        let cardClassName: string = '';
        if (dayjs(investor.contactDateNext).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) {
            cardClassName = 'contact-today';
        } else if (dayjs(investor.contactDateNext) < dayjs()) {
            cardClassName = 'contact-overdue';
        }

        const imSelectorBlock: JSX.Element = currentAdministrator.role !== RoleEnum.InvestorManager && (
            <Tooltip className='im-selector' title='Assign IM' placement='left'>
                <UserOutlined />
                <ImSelector investor={investor} />
            </Tooltip>
        );
        const accountTypeTagsBlock: JSX.Element[] = [];

        _.forEach(investor.accounts, (account: IAccount) => {
            accountTypeTagsBlock.push(<AccountTypeTag account={account} />);
        });

        return (
            <Card className={cardClassName} key={investor.uuid}>
                {investor.approvalStatus === ApprovalStatusEnum.Pending && <ApprovalStatusIcon investor={investor} />}
                <Link className='name' to={`/investors/${investor.uuid}`}>{investor.name || '-'}</Link>
                <div className='balance'>{currencyFormatter.format(investor.balanceTotal + investor.investedAmountCurrentTotal)}</div>
                {imSelectorBlock}
                <a className='reschedule' onClick={this.onClickNextContact}>Reschedule</a>
                <Space className='account-types'>{accountTypeTagsBlock}</Space>
                <NextContactModal
                    investor={investor}
                    isOpen={isNextContactModalOpen}
                    onCancel={this.onNextContactCancel}
                />
            </Card>
        );
    }

    private onClickNextContact(): void {
        this.setState({
            isNextContactModalOpen: true,
        });
    }

    private onNextContactCancel(): void {
        this.setState({
            isNextContactModalOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentAdministrator: currentAdministratorSelector(state),
    };
}

export default connect(
    mapStateToProps,
    null,
)(InvestorCard);
