import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Spin } from 'antd';
import _ from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import {
    administratorsActiveSelector,
    administratorsSelector,
    currentAdministratorSelector,
} from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import { IDictionary } from '~utilities/IDictionary';
import ILoan from '~Api/Loan/ILoan';
import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import { loanAssignAction } from '~Loans/actions';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';

export default function AssigneeSelector(props: {
    loan: ILoan;
}): ReactElement {
    const { loan } = props;

    const activeAdministrators: IAdministrator[] = useSelector(administratorsActiveSelector);
    const administrators: IDictionary<IAdministrator> = useSelector(administratorsSelector);
    const currentAdministrator: IAdministrator = useSelector(currentAdministratorSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!activeAdministrators || !administrators) {
            dispatch(administratorsListAction());
        }
    }, []);

    if (!activeAdministrators || !administrators || !currentAdministrator) {
        return <Spin size='small' />;
    }

    const assignee: IAdministrator = !!loan.administratorUuid && administrators[loan.administratorUuid];

    if (!currentAdministrator.permissions.includes(PermissionsEnum.LoanAssign)) {
        return (
            <>
                {loan.administratorUuid ? (assignee ? assignee.name : 'Inactive User') : 'None'}
            </>
        );
    }

    const filteredAssignees: IAdministrator[] = _.filter(activeAdministrators, (admin: IAdministrator) => {
        if ([
            RoleEnum.LoanManager,
            RoleEnum.SeniorLoanManager,
        ].includes(admin.role)) {
            return true;
        }

        return false;
    });

    const administratorsMenu: MenuProps = {
        items: _.sortBy(filteredAssignees, ['name']).map((administrator: IAdministrator) => ({
            key: administrator.uuid,
            label: administrator.name,
            onClick: () => dispatch(loanAssignAction(loan.uuid, administrator.uuid)),
        })),
    };

    return (
        <Dropdown
            disabled={![LoanStatusEnum.ActiveBadStanding, LoanStatusEnum.ActiveGoodStanding, LoanStatusEnum.ActiveMatured].includes(loan.status)}
            menu={administratorsMenu}
        >
            <a>
                {loan.administratorUuid ? (assignee ? assignee.name : 'Inactive User') : 'None'}
                {' '}<DownOutlined/>
            </a>
        </Dropdown>
    );
}
