import { Layout as AntLayout, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import Layout from '~Layout';
import { authMagicLinkRedeemAction } from './actions';

interface IMatch {
    code: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsDispatch {
    magicLinkRedeem: () => void;
}

type Props = IProps & IPropsDispatch;

class LoginLink extends React.Component<Props> {
    public componentDidMount() {
        this.props.magicLinkRedeem();
    }

    public render(): JSX.Element {
        return (
            <Layout topSection='login-link'>
                <AntLayout className='login-link'>
                    <AntLayout className='content-wrapper'>
                        <AntLayout.Content className='content'>
                            <Spin/>
                        </AntLayout.Content>
                    </AntLayout>
                </AntLayout>
            </Layout>
        );
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        magicLinkRedeem: () => dispatch(authMagicLinkRedeemAction(ownProps.match.params.code)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(LoginLink);
