import { AutoComplete, Form, Input, InputNumber, Modal, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import DisbursalTypeEnum from '~Api/Application/DisbursalTypeEnum';
import DisbursementTypeEnum from '~Api/Application/DisbursementTypeEnum';
import IApplication from '~Api/Application/IApplication';
import IApplicationDisbursement from '~Api/Application/IApplicationDisbursement';
import {
    applicationDisbursementSuggestionsListAction,
    applicationDisbursementsAddAction,
} from '~Applications/actions';
import {
    applicationDisbursementDescriptionSuggestionsSelector,
    applicationDisbursementPayeeNameSuggestionsSelector,
    applicationDisbursementsSelector,
    applicationSelector,
} from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

interface IOption {
    label: string;
    value: string;
}

interface IState {
    amount: number;
    description: string;
    descriptionOptions: IOption[];
    errorType?: string;
    payeeName: string;
    payeeNameOptions: IOption[];
    type: DisbursementTypeEnum;
}

interface IProps {
    isOpen: boolean;
    applicationUuid: string;
    onClose: () => void;
}

interface IPropsSelector {
    application: IApplication;
    descriptionSuggestions: IDictionary<number>;
    disbursements: IDictionary<IApplicationDisbursement>;
    payeeNameSuggestions: IDictionary<number>;
}

interface IPropsDispatch {
    add: (disbursement: IApplicationDisbursement) => void;
    suggestionsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class DisbursementAddModal extends React.Component<Props, IState> {
    public state: IState = {
        amount: null,
        description: null,
        descriptionOptions: [],
        payeeName: null,
        payeeNameOptions: [],
        type: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onSearchDescription = this.onSearchDescription.bind(this);
        this.onSelectDescription = this.onSelectDescription.bind(this);
        this.onSearchPayeeName = this.onSearchPayeeName.bind(this);
        this.onSelectPayeeName = this.onSelectPayeeName.bind(this);
        this.onChangeType = this.onChangeType.bind(this);

        this.onClickOk = this.onClickOk.bind(this);

        this.validateType = this.validateType.bind(this);
    }

    public componentDidMount() {
        this.props.suggestionsList();
    }

    public render(): JSX.Element {
        const { application, descriptionSuggestions, isOpen, payeeNameSuggestions } = this.props;
        const {
            amount,
            description,
            descriptionOptions,
            errorType,
            payeeName,
            payeeNameOptions,
            type,
        } = this.state;

        if (!descriptionSuggestions || !payeeNameSuggestions) {
            return null;
        }

        return (
            <Modal
                destroyOnClose={true}
                okText='Add'
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Disbursement'
                wrapClassName='application-disbursement-add-modal'
            >
                <Form.Item label='Payee Name' className='payee-name'>
                    <AutoComplete
                        dropdownMatchSelectWidth={300}
                        maxLength={255}
                        onSearch={this.onSearchPayeeName}
                        onSelect={this.onSelectPayeeName}
                        options={_.slice(payeeNameOptions, 0, 20)}
                        value={payeeName}
                    />
                </Form.Item>
                <Form.Item label='Amount' className='amount'>
                    <InputNumber addonBefore='$' min={0} onChange={this.onChangeAmount} value={amount} placeholder='0' />
                </Form.Item>
                <Form.Item label='Description' className='description'>
                    <AutoComplete
                        dropdownMatchSelectWidth={300}
                        maxLength={255}
                        onSearch={this.onSearchDescription}
                        onSelect={this.onSelectDescription}
                        options={_.slice(descriptionOptions, 0, 20)}
                        value={description}
                    />
                </Form.Item>
                <Form.Item className='type' label='Disbursement Type' help={errorType} validateStatus={errorType && 'error'}>
                    <Select onChange={this.onChangeType} value={type} onBlur={this.validateType}>
                        <Select.Option value={null}>N/A</Select.Option>
                        <Select.Option value={DisbursementTypeEnum.PrepaidInterest}>Prepaid Interest</Select.Option>
                        {application.disbursalType === DisbursalTypeEnum.Drawdown && <Select.Option value={DisbursementTypeEnum.RetainedFunds}>Retained Funds</Select.Option>}
                        {application.disbursalType === DisbursalTypeEnum.Drawdown && <Select.Option value={DisbursementTypeEnum.RetainedContingency}>Retained Contingency</Select.Option>}
                    </Select>
                </Form.Item>
            </Modal>
        );
    }

    private onChangeAmount(value: number) {
        this.setState({
            amount: value,
        });
    }

    private onChangeType(value: DisbursementTypeEnum) {
        this.setState({
            type: value,
        });
    }

    private onClickOk() {
        const { amount, description, payeeName, type } = this.state;

        let valid: boolean = true;

        valid = this.validateType() && valid;

        if (!valid) {
            return;
        }

        this.props.add({
            amount,
            description,
            payeeName,
            type,
        });

        this.props.onClose();
    }

    private onSearchDescription(value: string) {
        const { descriptionSuggestions } = this.props;

        const searchValue: string = value.toLocaleLowerCase();

        const results: IOption[] = [];

        _.keys(descriptionSuggestions).forEach((suggestion: string) => {
            if (suggestion.toLocaleLowerCase().includes(searchValue)) {
                results.push({
                    label: suggestion,
                    value: suggestion,
                });
            }
        });

        this.setState({
            description: value,
            descriptionOptions: _.reverse(_.sortBy(results, (result: IOption) => descriptionSuggestions[result.value])),
        });
    }

    private onSelectDescription(value: string) {
        this.setState({
            description: value,
        });
    }

    private onSearchPayeeName(value: string) {
        const { payeeNameSuggestions } = this.props;

        const searchValue: string = value.toLocaleLowerCase();

        const results: IOption[] = [];

        _.keys(payeeNameSuggestions).forEach((suggestion: string) => {
            if (suggestion.toLocaleLowerCase().includes(searchValue)) {
                results.push({
                    label: suggestion,
                    value: suggestion,
                });
            }
        });

        this.setState({
            payeeName: value,
            payeeNameOptions: _.reverse(_.sortBy(results, (result: IOption) => payeeNameSuggestions[result.value])),
        });
    }

    private onSelectPayeeName(value: string) {
        this.setState({
            payeeName: value,
        });
    }

    private validateType(): boolean {
        const { disbursements } = this.props;
        const { type } = this.state;

        let error: string = null;

        if (type === DisbursementTypeEnum.PrepaidInterest) {
            const prepaidInterestDisbursement: IApplicationDisbursement = _.find(disbursements, { type: DisbursementTypeEnum.PrepaidInterest });
            if (prepaidInterestDisbursement) {
                error = 'There is already an existing prepaid interest disbursement';
            }
        }

        if (type === DisbursementTypeEnum.RetainedFunds) {
            const retainedFundsDisbursement: IApplicationDisbursement = _.find(disbursements, { type: DisbursementTypeEnum.RetainedFunds });
            if (retainedFundsDisbursement) {
                error = 'There is already an existing retained funds disbursement';
            }
        }

        if (type === DisbursementTypeEnum.RetainedContingency) {
            const retainedContingencyDisbursement: IApplicationDisbursement = _.find(disbursements, { type: DisbursementTypeEnum.RetainedContingency });
            if (retainedContingencyDisbursement) {
                error = 'There is already an existing retained contingency disbursement';
            }
        }

        this.setState({
            errorType: error,
        });

        return !error;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.applicationUuid),
        descriptionSuggestions: applicationDisbursementDescriptionSuggestionsSelector(state),
        disbursements: applicationDisbursementsSelector(state, ownProps.applicationUuid),
        payeeNameSuggestions: applicationDisbursementPayeeNameSuggestionsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        add: (disbursement: IApplicationDisbursement) => dispatch(applicationDisbursementsAddAction(ownProps.applicationUuid, disbursement)),
        suggestionsList: () => dispatch(applicationDisbursementSuggestionsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DisbursementAddModal);
