import { CheckOutlined, ClockCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { GrNew } from 'react-icons/gr';
import ApprovalStatusEnum from '~Api/Investor/ApprovalStatusEnum';
import IInvestor from '~Api/Investor/IInvestor';
import { IDictionary } from '~utilities/IDictionary';

interface IApprovalStatusIcon {
    icon?: JSX.Element,
    title: string,
};

const approvalStatusIconDetails: IDictionary<IApprovalStatusIcon> = {
    [ApprovalStatusEnum.Approved]: {
        icon: <CheckOutlined />,
        title: 'Approved',
    },
    [ApprovalStatusEnum.Legacy]: {
        icon: <ClockCircleOutlined />,
        title: 'Legacy',
    },
    [ApprovalStatusEnum.Pending]: {
        icon: <GrNew />,
        title: 'Pending',
    },
    [ApprovalStatusEnum.Rejected]: {
        icon: <CloseOutlined />,
        title: 'Rejected',
    },
    [ApprovalStatusEnum.Draft]: {
        title: 'Draft',
    },
};

interface IProps {
    investor: IInvestor;
}

type Props = IProps;

class ApprovalStatusIcon extends React.Component<Props> {
    public render(): JSX.Element {
        const { investor } = this.props;

        return (
            <Tooltip className='approval-status' title={approvalStatusIconDetails[investor.approvalStatus].title}>{approvalStatusIconDetails[investor.approvalStatus].icon}</Tooltip>
        );
    }
}

export default ApprovalStatusIcon;
