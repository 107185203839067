import { Form, Input, Modal, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import ConditionTypeEnum from '~Api/Application/ConditionTypeEnum';
import { applicationPropertyValuerValuationReceivedAction } from '~Applications/actions';
import UploadConditionDocument from '~Applications/Application/UploadConditionDocument';
import DatePicker from '~UI/DatePicker';
import TimePicker from '~UI/TimePicker';
import IValuer from '~Valuers/IValuer';

interface IState {
    valuationInspectionDate: string;
    valuationReceivedDateTime: string;
    valuationValue: number;
}

interface IProps {
    application: IApplication;
    applicationProperty: IApplicationProperty;
    isOpen: boolean;
    onCancel: () => void;
    valuer: IValuer;
}

interface IPropsDispatch {
    valuationReceived: (valuationInspectionDate: string, valuationReceivedDateTime: string, valuationValue: number) => void;
}

type Props = IProps & IPropsDispatch;

class EnterValuationModal extends React.Component<Props, IState> {
    public state: IState = {
        valuationInspectionDate: null,
        valuationReceivedDateTime: null,
        valuationValue: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeValuationInspectionDate = this.onChangeValuationInspectionDate.bind(this);
        this.onChangeValuationReceivedDate = this.onChangeValuationReceivedDate.bind(this);
        this.onChangeValuationReceivedTime = this.onChangeValuationReceivedTime.bind(this);
        this.onChangeValuationValue = this.onChangeValuationValue.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { application, applicationProperty, isOpen, onCancel } = this.props;
        const { valuationInspectionDate, valuationReceivedDateTime, valuationValue } = this.state;

        const valuationInspectionDateDayJs: Dayjs = valuationInspectionDate ? dayjs(valuationInspectionDate) : null;
        const valuationReceivedDateTimeDayjs: Dayjs = valuationReceivedDateTime ? dayjs(valuationReceivedDateTime) : null;

        return (
            <Modal
                okText='Enter Valuation'
                onCancel={onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Enter Valuation'
                wrapClassName='property-valuation-enter-valuation-modal'
            >
                <Form.Item className='received-date' label='Received Date'>
                    <Space>
                        <DatePicker onChange={this.onChangeValuationReceivedDate} value={valuationReceivedDateTimeDayjs || dayjs()} format='DD/MM/YYYY' />
                        <TimePicker onChange={this.onChangeValuationReceivedTime} value={valuationReceivedDateTimeDayjs || dayjs()} format='H:mm' />
                    </Space>
                </Form.Item>
                <Form.Item className='amount' label='Amount'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeValuationValue} type='number' value={valuationValue} />
                </Form.Item>
                <Form.Item className='inspection-date' label='Inspection Date'>
                    <DatePicker onChange={this.onChangeValuationInspectionDate} value={valuationInspectionDateDayJs || dayjs()} format='DD/MM/YYYY' />
                </Form.Item>
                <Form.Item label='Upload Report'>
                    <UploadConditionDocument
                        application={application}
                        conditionType={ConditionTypeEnum.Valuation}
                        applicationProperty={applicationProperty}
                    />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeValuationInspectionDate(date: Dayjs) {
        const { valuationInspectionDate } = this.state;

        this.setState({
            valuationInspectionDate: dayjs(`${date.format('YYYY-MM-DD')} ${dayjs(valuationInspectionDate || undefined).format('HH:mm')}`).format(),
        });
    }

    private onChangeValuationReceivedDate(date: Dayjs) {
        const { valuationReceivedDateTime } = this.state;

        this.setState({
            valuationReceivedDateTime: dayjs(`${date.format('YYYY-MM-DD')} ${dayjs(valuationReceivedDateTime || undefined).format('HH:mm')}`).format(),
        });
    }

    private onChangeValuationReceivedTime(time: Dayjs) {
        const { valuationReceivedDateTime } = this.state;

        this.setState({
            valuationReceivedDateTime: dayjs(`${dayjs(valuationReceivedDateTime || undefined).format('YYYY-MM-DD')} ${time.format('HH:mm')}`).format(),
        });
    }

    private onChangeValuationValue(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            valuationValue: event.target.value ? event.target.valueAsNumber : null,
        });
    }

    private onClickOk() {
        const { valuationInspectionDate, valuationReceivedDateTime, valuationValue } = this.state;

        this.props.valuationReceived(valuationInspectionDate, valuationReceivedDateTime, valuationValue);
        this.props.onCancel();
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        valuationReceived: (valuationInspectionDate: string, valuationReceivedDateTime: string, valuationValue: number) => dispatch(applicationPropertyValuerValuationReceivedAction(ownProps.applicationProperty.uuid, ownProps.valuer.uuid, valuationInspectionDate, valuationReceivedDateTime, valuationValue)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(EnterValuationModal);
