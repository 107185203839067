import { Action } from 'redux';
import SolicitorsActionsEnum from './ActionsEnum';
import ISolicitor from './ISolicitor';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISolicitorsListAction extends Action {}

export function solicitorsListAction(): ISolicitorsListAction {
    return {
        type: SolicitorsActionsEnum.SolicitorsList,
    };
}

export interface ISolicitorsSetAction extends Action {
    solicitors: ISolicitor[];
}

export function solicitorsSetAction(solicitors: ISolicitor[]): ISolicitorsSetAction {
    return {
        solicitors,
        type: SolicitorsActionsEnum.SolicitorsSet,
    };
}
