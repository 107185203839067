import { Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IUser from '~Api/User/IUser';
import { userEmailAction } from '~Users/actions';
import { validateEmail } from '~utilities/validators';

interface IState {
    email: string;
    error: string;
    isChangePending: boolean;
}

interface IProps {
    isOpen: boolean;
    onCancel: () => void;
    user: IUser;
}

interface IPropsDispatch {
    changeEmail: (email: string) => void;
}

type Props = IProps & IPropsDispatch;

class EmailModal extends React.Component<Props, IState> {
    public state: IState = {
        email: null,
        error: null,
        isChangePending: false,
    };

    constructor(props: Props) {
        super(props);

        this.afterClose = this.afterClose.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.validateEmail = this.validateEmail.bind(this);

        this.state.email = props.user.email;
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { email, error, isChangePending } = this.state;

        return (
            <Modal
                afterClose={this.afterClose}
                cancelButtonProps={{ disabled: isChangePending }}
                destroyOnClose={true}
                okButtonProps={{ loading: isChangePending }}
                okText='Change Email'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Change Email'
            >
                <Form.Item help={error} label='Email' className='email' validateStatus={error && 'error'}>
                    <Input onBlur={this.validateEmail} onChange={this.onChangeEmail} value={email} />
                </Form.Item>
            </Modal>
        );
    }

    private afterClose() {
        this.setState({
            email: null,
            error: null,
            isChangePending: false,
        });
    }

    private onClickOk() {
        let valid: boolean = true;

        valid = this.validateEmail() && valid;

        if (!valid) {
            return;
        }

        Modal.confirm({
            content: 'Are you sure you want to change this email?',
            onOk: () => {
                const { email } = this.state;

                this.setState({
                    isChangePending: true,
                });

                this.props.changeEmail(email);

                this.props.onCancel();
            },
            title: 'Change Email',
        });
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            email: event.target.value,
        });
    }

    private validateEmail(): boolean {
        const { email } = this.state;

        let error: string;

        if (!email || email.length === 0) {
            error = 'Please enter the email address';
        } else if (!validateEmail(email)) {
            error = 'Please enter a valid email address';
        }

        this.setState({
            error,
        });

        return !error;
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        changeEmail: (email: string) => dispatch(userEmailAction(ownProps.user.uuid, email)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(EmailModal);
