import { Button, Input } from 'antd';
import React from 'react';

interface IProps {
    params: any;
}

class SearchDropdown extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
        this.onClickReset = this.onClickReset.bind(this);
    }

    public render(): JSX.Element {
        const { params } = this.props;

        const search: string = params.selectedKeys[0];

        return (
            <div className='search-dropdown'>
                <div className='input-wrapper'>
                    <Input
                        onChange={this.onChange}
                        onPressEnter={this.onClickOk}
                        placeholder='Search'
                        value={search}
                    />
                </div>
                <div className='ant-table-filter-dropdown-btns'>
                    <Button
                        disabled={!search}
                        onClick={this.onClickReset}
                        size='small'
                        type='link'
                    >
                        Reset
                    </Button>
                    <Button
                        onClick={this.onClickOk}
                        size='small'
                        type='primary'
                    >
                        OK
                    </Button>
                </div>
            </div>
        );
    }

    private onChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.params.setSelectedKeys(event.target.value ? [event.target.value] : []);
    }

    private onClickOk() {
        this.props.params.confirm();
    }

    private onClickReset() {
        this.props.params.setSelectedKeys([]);
        this.props.params.confirm();
    }
}

export default SearchDropdown;
