import { EyeInvisibleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import IInvestorAccountInvestment from '~Api/Investor/IInvestorAccountInvestment';
import {
    investorAccountInvestmentsListAction,
    investorGetAction,
} from '~Investors/actions';
import {
    investorAccountInvestmentsSelector,
    investorAccountSelector,
    investorSelector,
} from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import InvestmentTransactionsModal from './InvestmentTransactionsModal';
import InvestModal from './InvestModal';
import Layout from './Layout';
import { currencyFormatter } from '~utilities/formatters';
import ReserveModal from './ReserveModal';
import VisibilityStatusEnum from '~Api/Investment/VisibilityStatusEnum';

interface IState {
    isInvestModalOpen: boolean;
    isReserveModalOpen: boolean;
    modalIsOpen: boolean;
    modalUuid: string;
}

interface IMatch {
    accountUuid: string;
    investorUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    account: IAccount;
    investor: IInvestor;
    investorAccountInvestments: IDictionary<IInvestorAccountInvestment>;
}

interface IPropsDispatch {
    investorAccountInvestmentsList: () => void;
    investorGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Investments extends React.Component<Props, IState> {
    public state: IState = {
        isInvestModalOpen: false,
        isReserveModalOpen: false,
        modalIsOpen: false,
        modalUuid: null,
    };

    private loadingUuid: string = null;

    constructor(props: Props) {
        super(props);

        this.onClickInvest = this.onClickInvest.bind(this);
        this.onCloseInvestModal = this.onCloseInvestModal.bind(this);
        this.onClickReserve = this.onClickReserve.bind(this);
        this.onCloseReserveModal = this.onCloseReserveModal.bind(this);
        this.onClickTransactions = this.onClickTransactions.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.summary = this.summary.bind(this);
    }

    public componentDidMount(): void {
        const { investor, match } = this.props;

        if (!investor) {
            this.props.investorGet();
        }

        this.props.investorAccountInvestmentsList();

        this.loadingUuid = match.params.accountUuid;
    }

    public componentDidUpdate(): void {
        const { investor, match } = this.props;

        if (this.loadingUuid !== match.params.accountUuid) {
            if (!investor) {
                this.props.investorGet();
            }

            this.props.investorAccountInvestmentsList();

            this.loadingUuid = match.params.accountUuid;
        }
    }

    public render(): JSX.Element {
        const { account, investor, investorAccountInvestments, match } = this.props;
        const { isInvestModalOpen, isReserveModalOpen, modalIsOpen, modalUuid } = this.state;

        if (!investor || !investorAccountInvestments) {
            return (
                <Layout uuid={match.params.investorUuid} section={`investments-${match.params.accountUuid}`} sectionClass='investments'>
                    <Typography.Title level={2}>Investments</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const columns: ColumnType<IInvestorAccountInvestment>[] = [
            {
                render: (investorAccountInvestment: IInvestorAccountInvestment) => <Link to={`/investments/${investorAccountInvestment.investment.uuid}`}>{investorAccountInvestment.investment.code}</Link>,
                sorter: (a: IInvestorAccountInvestment, b: IInvestorAccountInvestment) => {
                    return a.investment.code.localeCompare(b.investment.code);
                },
                title: 'Code',
                width: '15%',
            },
            {
                render: (investorAccountInvestment: IInvestorAccountInvestment) => (
                    <Space>
                        <Link to={`/investments/${investorAccountInvestment.investment.uuid}`}>{investorAccountInvestment.investment.name}</Link>
                        {VisibilityStatusEnum.Hidden === investorAccountInvestment.investment.visibilityStatus && <Tooltip title='This investment is not visible in the portal'><EyeInvisibleOutlined/></Tooltip>}
                    </Space>
                ),
                sorter: (a: IInvestorAccountInvestment, b: IInvestorAccountInvestment) => {
                    return a.investment.name.localeCompare(b.investment.name);
                },
                title: 'Name',
            },
            {
                dataIndex: 'principalBalance',
                defaultSortOrder: 'descend',
                render: (principalBalance: number) => currencyFormatter.format(principalBalance),
                sorter: (a: IInvestorAccountInvestment, b: IInvestorAccountInvestment) => {
                    return a.principalBalance > b.principalBalance ? 1 : -1;
                },
                title: 'Principal',
                width: '15%',
            },
            {
                dataIndex: 'interestBalance',
                render: (interestBalance: number) => currencyFormatter.format(interestBalance),
                sorter: (a: IInvestorAccountInvestment, b: IInvestorAccountInvestment) => {
                    return a.interestBalance > b.interestBalance ? 1 : -1;
                },
                title: 'Interest',
                width: '15%',
            },
            {
                dataIndex: 'uuid',
                render: (uuid: string) => {
                    const onClick: () => void = () => this.onClickTransactions(uuid);

                    const modalBlock: JSX.Element = modalUuid === uuid && (
                        <InvestmentTransactionsModal
                            investorAccountInvestmentUuid={uuid}
                            isOpen={modalIsOpen}
                            onCancel={this.onCloseModal}
                        />
                    );

                    return (
                        <>
                            <Button icon={<UnorderedListOutlined/>} onClick={onClick} />
                            {modalBlock}
                        </>
                    );
                },
                width: '2%',
            },
        ];

        const investModal: JSX.Element = account.accountType === AccountTypeEnum.Marketplace && (
            <InvestModal
                investor={investor}
                investorAccount={account}
                isOpen={isInvestModalOpen}
                onClose={this.onCloseInvestModal}
            />
        );

        const reserveModal: JSX.Element = account.accountType === AccountTypeEnum.Marketplace && (
            <ReserveModal
                investor={investor}
                investorAccount={account}
                isOpen={isReserveModalOpen}
                onClose={this.onCloseReserveModal}
            />
        );

        return (
            <Layout
                section={`investments-${match.params.accountUuid}`}
                sectionClass='investments'
                uuid={match.params.investorUuid}
            >
                <Space className='actions'>
                    {investModal && <Button disabled={account.balance === 0} onClick={this.onClickInvest}>Invest</Button>}
                    {reserveModal && <Button onClick={this.onClickReserve}>Reserve</Button>}
                </Space>
                <Typography.Title level={2}>Investments</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(investorAccountInvestments)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                    summary={this.summary}
                />
                {investModal}
                {reserveModal}
            </Layout>
        );
    }

    private summary(): JSX.Element {
        const { investorAccountInvestments } = this.props;

        let investmentCount: number = 0;
        let principalTotal: number = 0;
        let interestTotal: number = 0;

        _.forEach(investorAccountInvestments, (investorAccountInvestment: IInvestorAccountInvestment) => {
            investmentCount++;
            principalTotal += investorAccountInvestment.principalBalance;
            interestTotal += investorAccountInvestment.interestBalance;
        });

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}><strong>{investmentCount}</strong></Table.Summary.Cell>
                <Table.Summary.Cell index={1}/>
                <Table.Summary.Cell index={2}><strong>{currencyFormatter.format(principalTotal)}</strong></Table.Summary.Cell>
                <Table.Summary.Cell index={3}><strong>{currencyFormatter.format(interestTotal)}</strong></Table.Summary.Cell>
                <Table.Summary.Cell index={4}/>
            </Table.Summary.Row>
        );
    }

    private onClickTransactions(investorAccountInvestmentUuid: string): void {
        this.setState({
            modalIsOpen: true,
            modalUuid: investorAccountInvestmentUuid,
        });
    }

    private onCloseModal(): void {
        this.setState({
            modalIsOpen: false,
        });
    }

    private onClickInvest(): void {
        this.setState({
            isInvestModalOpen: true,
        });
    }

    private onCloseInvestModal(): void {
        this.setState({
            isInvestModalOpen: false,
        });
    }

    private onClickReserve(): void {
        this.setState({
            isReserveModalOpen: true,
        });
    }

    private onCloseReserveModal(): void {
        this.setState({
            isReserveModalOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        account: investorAccountSelector(state, ownProps.match.params.accountUuid),
        investor: investorSelector(state, ownProps.match.params.investorUuid),
        investorAccountInvestments: investorAccountInvestmentsSelector(state, ownProps.match.params.accountUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investorAccountInvestmentsList: () => dispatch(investorAccountInvestmentsListAction(ownProps.match.params.accountUuid)),
        investorGet: () => dispatch(investorGetAction(ownProps.match.params.investorUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Investments);
