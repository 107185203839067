import { IDictionary } from '~utilities/IDictionary';
import InvestorTypeEnum from './InvestorTypeEnum';

const investorTypeLabels: IDictionary<string> = {
    [InvestorTypeEnum.Company]: 'Company',
    [InvestorTypeEnum.Individual]: 'Individual',
    [InvestorTypeEnum.JointIndividuals]: 'Joint Individuals',
    [InvestorTypeEnum.TrustCompany]: 'Trust - Company',
    [InvestorTypeEnum.TrustIndividual]: 'Trust - Individual',
};

export default investorTypeLabels; 