import IDocument from './IDocument';
import IHistory from './IHistory';
import INote from './INote';
import IReferralPartner from './IReferralPartner';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseReferralPartner(raw: any): IReferralPartner {
    return {
        abn: raw.abn,
        acl: raw.acl,
        aggregatorUuid: raw.aggregatorUuid,
        approvalStatus: raw.approvalStatus,
        banned: raw.banned,
        chargesFee: raw.chargesFee,
        companyName: raw.companyName,
        conductsBusinessInPerson: raw.conductsBusinessInPerson,
        createdTime: raw.createdTime,
        disclosesBenefits: raw.disclosesBenefits,
        email: raw.email,
        firstName: raw.firstName,
        groupName: raw.groupName,
        includesCustomerInfo: raw.includesCustomerInfo,
        lastName: raw.lastName,
        linkedInUrl: raw.linkedInUrl,
        nccp: raw.nccp,
        phone: raw.phone,
        postcode: raw.postcode,
        refersClient: raw.refersClient,
        state: raw.state,
        streetAddress: raw.streetAddress,
        suburb: raw.suburb,
        type: raw.type,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseReferralPartnerDocument(raw: any): IDocument {
    return {
        createdTime: raw.createdTime,
        filename: raw.filename,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseReferralPartnerHistory(raw: any): IHistory {
    return {
        createdTime: raw.createdTime,
        description: raw.description,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseReferralPartnerNote(raw: any): INote {
    return {
        administratorUuid: raw.administratorUuid,
        createdTime: raw.createdTime,
        note: raw.note,
        uuid: raw.uuid,
    };
}
