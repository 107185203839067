import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '~Layout';
import List from './List';
import WithdrawalAba from './WithdrawalAba';

export default function Router(): ReactElement {
    return (
        <Layout topSection='investors'>
            <Switch>
                <Route exact={true} path='/investors/withdrawal-abas' component={List} />
                <Route path='/investors/withdrawal-abas/:withdrawalAbaUuid' component={WithdrawalAba} />
            </Switch>
        </Layout>
    );
}
