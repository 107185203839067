import { DownOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Dropdown, Layout,  MenuProps, Space, Spin, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import {
    administratorsSelector,
    currentAdministratorSelector,
} from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import ApprovalStatusEnum from '~Api/Broker/ApprovalStatusEnum';
import IBroker from '~Api/Broker/IBroker';
import constants from '~constants';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { brokersListAction } from './actions';
import AddModal from './AddModal';
import Search from './Search';
import BrokerCard from './Broker/Card';
import { brokersSelector } from './selectors';
import menuDivider from '~UI/menuDivider';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

interface IState {
    activeBdmFilter: string;
    isAddModalOpen: boolean;
}

interface IPropsSelector {
    administrators: IDictionary<IAdministrator>;
    brokers: IDictionary<IBroker>;
    currentAdministrator: IAdministrator;
}

interface IPropsDispatch {
    administratorsList: () => void;
    brokersList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Dashboard extends React.Component<Props> {
    public state: IState = {
        activeBdmFilter: null,
        isAddModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAdd = this.onClickAdd.bind(this);
        this.onCloseAddModal = this.onCloseAddModal.bind(this);
        this.onClickBdmAssignee = this.onClickBdmAssignee.bind(this);
    }

    public componentDidMount() {
        const { administrators, brokers, currentAdministrator } = this.props;

        if (!administrators || !currentAdministrator) {
            this.props.administratorsList();
        }

        if (!brokers) {
            this.props.brokersList();
        }
    }

    public render(): JSX.Element {
        const { administrators, brokers, currentAdministrator } = this.props;
        const { activeBdmFilter, isAddModalOpen } = this.state;

        let bdmAssigneeButton: JSX.Element = null;
        if (![
            RoleEnum.BusinessDevelopmentManager,
            RoleEnum.InternalBusinessDevelopmentManager,
            RoleEnum.CreditManager,
        ].includes(currentAdministrator.role)) {
            bdmAssigneeButton = <Button disabled={false}>All BDMs <DownOutlined/></Button>;
        }

        const addModalBlock: ReactElement = (
            <AddModal
                isOpen={isAddModalOpen}
                onCancel={this.onCloseAddModal}
            />
        );

        if (!administrators || !brokers) {
            return (
                <Layout className='brokers'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Brokers</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Space className='actions'>
                                <Search/>
                                {bdmAssigneeButton}
                                <Link to='/brokers/list'><Button>List View</Button></Link>
                                <Link to='/brokers/map'><Button>Map View</Button></Link>
                                <Button onClick={this.onClickAdd}>Add Broker</Button>
                            </Space>
                            <Typography.Title level={2}>Brokers</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                    {addModalBlock}
                </Layout>
            );
        }

        const columnBrokers: IDictionary<IBroker[]> = {};

        for (let i: number = 0; i < 7; i++) {
            const dateString: string = dayjs().add(i, 'day').format('YYYY-MM-DD');
            columnBrokers[dateString] = [];
        }

        const today: Dayjs = dayjs();
        const visibleAssignees: IDictionary<IAdministrator> = {};

        _.each(brokers, (broker: IBroker) => {
            if (broker.approvalStatus === ApprovalStatusEnum.Rejected) {
                return;
            }

            if (!broker.contactDateNext) {
                return;
            }

            if (broker.bdmUuid && !visibleAssignees[broker.bdmUuid] && administrators[broker.bdmUuid]) {
                visibleAssignees[broker.bdmUuid] = administrators[broker.bdmUuid];
            }

            if (RoleEnum.BusinessDevelopmentManager === currentAdministrator.role) {
                if (`${broker.bdmUuid}` !== `${currentAdministrator.uuid}`) {
                    return;
                }
            } else {
                if (activeBdmFilter) {
                    if (activeBdmFilter === 'unassigned' && broker.bdmUuid) {
                        return;
                    }

                    if (!['unassigned', `${broker.bdmUuid}`].includes(activeBdmFilter)) {
                        return;
                    }
                }
            }

            const contactDayjs: Dayjs = dayjs(broker.contactDateNext);
            const dateString: string = (contactDayjs.isBefore(today) ? today : contactDayjs).format('YYYY-MM-DD');
            if (!columnBrokers[dateString]) {
                return;
            }
            columnBrokers[dateString].push(broker);
        });

        const columnsBlock: JSX.Element[] = _.keys(columnBrokers).map((dateString: string) => {
            const cards: JSX.Element[] = _.sortBy(columnBrokers[dateString], ['contactDateNext']).map((broker: IBroker) => (
                <BrokerCard
                    broker={broker}
                    key={broker.uuid}
                />
            ));

            return (
                <Card key={dateString} title={dayjs(dateString).format('dddd')}>
                    <Space direction='vertical'>
                        {cards}
                    </Space>
                </Card>
            );
        });

        if (![
            RoleEnum.BusinessDevelopmentManager,
            RoleEnum.InternalBusinessDevelopmentManager,
            RoleEnum.CreditManager,
        ].includes(currentAdministrator.role)) {
            let bdmAssigneeName: string = 'All BDMs';

            switch (activeBdmFilter) {
                case 'unassigned':
                    bdmAssigneeName = 'Unassigned';
                    break;
                case constants.BROKER_BDM_UUID_HEAD_OFFICE:
                    bdmAssigneeName = 'Head Office';
                    break;
            }

            const bdmAssigneeItems: ItemType[] = _.sortBy(_.values(visibleAssignees), ['name']).map((administrator: IAdministrator): ItemType => {
                if (activeBdmFilter === administrator.uuid) {
                    bdmAssigneeName = administrator.name;
                }

                return {
                    key: administrator.uuid,
                    label: administrator.name,
                };
            });

            const bdmAssigneeMenu: MenuProps = {
                items: [
                    {
                        key: 'all',
                        label: 'All BDMs',
                    },
                    {
                        key: 'unassigned',
                        label: 'Unassigned',
                    },
                    {
                        key: constants.BROKER_BDM_UUID_HEAD_OFFICE,
                        label: 'Head Office',
                    },
                    ...(bdmAssigneeItems.length > 0 ? [
                        menuDivider,
                    ] : []),
                    ...bdmAssigneeItems,
                ],
                onClick: this.onClickBdmAssignee,
                selectedKeys: [activeBdmFilter || 'all'],
            };

            bdmAssigneeButton = (
                <Dropdown menu={bdmAssigneeMenu}>
                    <Button>{bdmAssigneeName} <DownOutlined/></Button>
                </Dropdown>
            );
        }

        return (
            <Layout className='brokers'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Brokers</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Search/>
                            {bdmAssigneeButton}
                            <Link to='/brokers/list'><Button>List View</Button></Link>
                            <Link to='/brokers/map'><Button>Map View</Button></Link>
                            <Button onClick={this.onClickAdd}>Add Broker</Button>
                        </Space>
                        <Typography.Title level={2}>Brokers</Typography.Title>
                        <Space align='start' className='columns'>
                            {columnsBlock}
                        </Space>
                    </Layout.Content>
                </Layout>
                {addModalBlock}
            </Layout>
        );
    }

    private onClickAdd() {
        this.setState({
            isAddModalOpen: true,
        });
    }

    private onCloseAddModal() {
        this.setState({
            isAddModalOpen: false,
        });
    }

    private onClickBdmAssignee(e: MenuInfo) {
        this.setState({
            activeBdmFilter: e.key !== 'all' ? e.key : null,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        administrators: administratorsSelector(state),
        brokers: brokersSelector(state),
        currentAdministrator: currentAdministratorSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        brokersList: () => dispatch(brokersListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dashboard);
