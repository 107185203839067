import PropertyStateEnum from '~Api/Deal/PropertyStateEnum';
import IValuer from './IValuer';

export function parseValuer(raw: any): IValuer {
    return {
        email: raw.email,
        name: raw.name,
        phone: raw.phone,
        state: raw.state as PropertyStateEnum,
        uuid: raw.uuid,
    };
}
