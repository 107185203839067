import { CalendarOutlined } from '@ant-design/icons';
import React from 'react';
import { connect } from 'react-redux';
import IApplication from '~Api/Application/IApplication';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import {
    applicationPropertySelector,
    applicationSelector,
} from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import ValuationInspectionDateModal from './ValuationInspectionDateModal';
import IConditionComponentProps from './IConditionComponentProps';

interface IState {
    isModalOpen: boolean;
}

interface IPropsSelector {
    application: IApplication;
    applicationProperty: IApplicationProperty;
}

type Props = IConditionComponentProps & IPropsSelector;

class ValuationInspectionDate extends React.Component<Props, IState> {
    public state: IState = {
        isModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickCalendar = this.onClickCalendar.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
    }

    public render(): JSX.Element {
        const { application, applicationProperty } = this.props;
        const { isModalOpen } = this.state;

        if (!application || !applicationProperty) {
            return null;
        }

        return (
            <React.Fragment>
                <a onClick={this.onClickCalendar}><CalendarOutlined/></a>
                <ValuationInspectionDateModal application={application} applicationProperty={applicationProperty} isOpen={isModalOpen} onCancel={this.onClickCancel} />
            </React.Fragment>
        );
    }

    private onClickCalendar() {
        this.setState({
            isModalOpen: true,
        });
    }

    private onClickCancel() {
        this.setState({
            isModalOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IConditionComponentProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.applicationUuid),
        applicationProperty: applicationPropertySelector(state, ownProps.applicationPropertyUuid),
    };
}

export default connect(
    mapStateToProps,
    null,
)(ValuationInspectionDate);
