import { Button, Space, Spin, Table, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { FiMapPin } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IProperty from '~Api/Deal/IProperty';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';
import PropertyAddressVersionUpgradeModal from '~Deals/PropertyAddressVersionUpgradeModal';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { dealPropertiesListAction } from './actions';
import './deals.less';
import PostcodeCategory from './PostcodeCategory';
import PropertyAddModal from './PropertyAddModal';
import PropertyEditModal from './PropertyEditModal';
import { dealPropertiesSelector } from './selectors';
import zoneTypeLabels from '~Api/Deal/ZoneTypeLabels';

interface IState {
    editPropertyUuid: string;
    isPropertyAddressVersionUpgradeModalOpen: boolean;
    isPropertyModalOpen: boolean;
}

interface IProps {
    dealUuid: string;
}

interface IPropsSelector {
    properties: IDictionary<IProperty>;
}

interface IPropsDispatch {
    propertiesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class PropertiesList extends React.Component<Props> {
    public state: IState = {
        editPropertyUuid: null,
        isPropertyAddressVersionUpgradeModalOpen: false,
        isPropertyModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.closePropertyUpgradeAddressVersionModal = this.closePropertyUpgradeAddressVersionModal.bind(this);
        this.onClickAddProperty = this.onClickAddProperty.bind(this);
        this.onCloseAddProperty = this.onCloseAddProperty.bind(this);
        this.onClickEditProperty = this.onClickEditProperty.bind(this);
        this.onClickPropertyUpgradeAddressVersion = this.onClickPropertyUpgradeAddressVersion.bind(this);
        this.onCloseEditProperty = this.onCloseEditProperty.bind(this);
    }

    public componentDidMount(): void {
        const { properties } = this.props;

        if (!properties) {
            this.props.propertiesList();
        }
    }

    public render(): JSX.Element {
        const { dealUuid, properties } = this.props;
        const { editPropertyUuid, isPropertyAddressVersionUpgradeModalOpen, isPropertyModalOpen } = this.state;

        if (!properties) {
            return (
                <Spin/>
            );
        }

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns = [
            {
                dataIndex: 'formattedAddress',
                render: (formattedAddress: string, dealProperty: IProperty) => {
                    const onClick = (event: React.MouseEvent) => {
                        event.stopPropagation();
                        this.onClickEditProperty(dealProperty.uuid);
                    };

                    return (
                        <Space>
                            <a onClick={onClick}>{formattedAddress}</a>
                            <PostcodeCategory property={dealProperty} />
                        </Space>
                    );
                },
                title: 'Address',
            },
            {
                dataIndex: 'formattedAddress',
                render: (formattedAddress: string) => <Button href={`https://www.google.com/maps/place/${formattedAddress}`} target='_blank' type='link' danger={true}><FiMapPin /></Button>,
                width: '5%',
            },
            {
                dataIndex: 'estimatedValue',
                render: (estimatedValue: number) => estimatedValue ? currencyFormatter.format(estimatedValue) : '-',
                title: 'Estimated Value',
                width: '15%',
            },
            {
                dataIndex: 'currentDebt',
                render: (currentDebt: number) => currentDebt ? currencyFormatter.format(currentDebt) : '-',
                title: 'Current Debt',
                width: '15%',
            },
            {
                dataIndex: 'zoneType',
                render: (zoneType: ZoneTypeEnum) => zoneType ? zoneTypeLabels[zoneType] : '-',
                title: 'Zoning',
                width: '20%',
            },
        ];

        return (
            <div className='deal-properties'>
                <Button className='add-property' onClick={this.onClickAddProperty}>Add Property</Button>
                <Typography.Title level={2}>Properties</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(properties)}
                    pagination={false}
                    rowKey='uuid'
                />
                <PropertyAddModal
                    dealUuid={dealUuid}
                    isOpen={isPropertyModalOpen}
                    onCancel={this.onCloseAddProperty}
                />
                <PropertyEditModal
                    dealUuid={dealUuid}
                    onCancel={this.onCloseEditProperty}
                    onClickAddressVersionUpgradeButton={this.onClickPropertyUpgradeAddressVersion}
                    propertyUuid={editPropertyUuid}
                    isOpen={!!editPropertyUuid}
                />
                <PropertyAddressVersionUpgradeModal
                    dealUuid={dealUuid}
                    isOpen={isPropertyAddressVersionUpgradeModalOpen}
                    onCancel={this.closePropertyUpgradeAddressVersionModal}
                    propertyUuid={editPropertyUuid}
                />
            </div>
        );
    }

    private closePropertyUpgradeAddressVersionModal(): void {
        this.setState({
            isPropertyAddressVersionUpgradeModalOpen: false,
        });
    }

    private onClickAddProperty(): void {
        this.setState({
            isPropertyModalOpen: true,
        });
    }

    private onCloseAddProperty(): void {
        this.setState({
            isPropertyModalOpen: false,
        });
    }

    private onClickEditProperty(editPropertyUuid: string): void {
        this.setState({
            editPropertyUuid,
        });
    }

    private onClickPropertyUpgradeAddressVersion(event: React.MouseEvent): void {
        event.stopPropagation();

        this.setState({
            isPropertyAddressVersionUpgradeModalOpen: true,
        });
    }

    private onCloseEditProperty(event: React.MouseEvent): void {
        event.stopPropagation();
        this.setState({
            editPropertyUuid: null,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        properties: dealPropertiesSelector(state, ownProps.dealUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        propertiesList: () => dispatch(dealPropertiesListAction(ownProps.dealUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PropertiesList);
