import { Spin, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import ILoanProperty from '~Api/Loan/ILoanProperty';
import {
    loanGetAction,
    loanPropertiesListAction,
} from '~Loans/actions';
import {
    loanPropertiesSelector,
    loanSelector,
} from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import PropertyEditModal from './PropertyEditModal';
import { ColumnType } from 'antd/lib/table';

interface IState {
    editPropertyUuid: string;
}

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    loan: ILoan;
    properties: ILoanProperty[];
}

interface IPropsDispatch {
    loanGet: () => void;
    propertiesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Fees extends React.Component<Props, IState> {
    public state: IState = {
        editPropertyUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickEdit = this.onClickEdit.bind(this);
        this.onCloseEditModal = this.onCloseEditModal.bind(this);
    }

    public componentDidMount() {
        const { loan, properties } = this.props;

        if (!loan) {
            this.props.loanGet();
        }

        if (!properties) {
            this.props.propertiesList();
        }
    }

    public render(): JSX.Element {
        const { loan, match, properties } = this.props;
        const { editPropertyUuid } = this.state;

        if (!loan || !properties) {
            return (
                <Layout uuid={match.params.uuid} section='properties'>
                    <Typography.Title level={2}>Properties</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const columns: ColumnType<ILoanProperty>[] = [
            {
                render: (property: ILoanProperty) => {
                    const onClickEdit: (event: React.MouseEvent) => void = (event: React.MouseEvent) => {
                        event.stopPropagation();
                        this.onClickEdit(property.uuid);
                    };

                    return (
                        <a onClick={onClickEdit}>{property.streetAddress || ''} {property.suburb || ''} {property.state || ''} {property.postcode || ''}</a>
                    );
                },
                title: 'Address',
            },
            {
                render: (property: ILoanProperty) => {
                    if (property.insuranceRequired === null) {
                        return 'Unknown';
                    }

                    if (!property.insuranceRequired) {
                        return 'N/A';
                    }

                    if (!property.insuranceExpiryDate) {
                        return 'Unknown';
                    }

                    return dayjs(property.insuranceExpiryDate).format('Do MMMM YYYY');
                },
                title: 'Insurance Expiry',
                width: '15%',
            },
        ];

        return (
            <Layout uuid={match.params.uuid} section='properties'>
                <Typography.Title level={2}>Properties</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={properties}
                    rowKey='uuid'
                    pagination={false}
                    size='middle'
                />
                <PropertyEditModal
                    propertyUuid={editPropertyUuid}
                    loanUuid={loan.uuid}
                    isOpen={!!editPropertyUuid}
                    onClose={this.onCloseEditModal}
                />
            </Layout>
        );
    }

    private onClickEdit(propertyUuid: string) {
        this.setState({
            editPropertyUuid: propertyUuid,
        });
    }

    private onCloseEditModal() {
        this.setState({
            editPropertyUuid: null,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        loan: loanSelector(state, ownProps.match.params.uuid),
        properties: loanPropertiesSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        loanGet: () => dispatch(loanGetAction(ownProps.match.params.uuid)),
        propertiesList: () => dispatch(loanPropertiesListAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Fees);
