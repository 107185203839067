import { Breadcrumb, Layout, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { constant } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IPostcodeCategory from '~Api/Deal/IPostcodeCategory';
import LoanPurposeEnum from '~Api/Deal/LoanPurposeEnum';
import MortgageTypeEnum from '~Api/Deal/MortgageTypeEnum';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';
import constants from '~constants';
import { dealPropertiesPostcodeCategoriesListAction } from '~Deals/actions';
import { dealPropertiesPostcodeCategoriesSelector } from '~Deals/selectors';
import { getDealCalculatedInterestRate } from '~Deals/utilities';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

const zoneTypeLabels: IDictionary<string> = {
    [ZoneTypeEnum.ResidentialHouse]: 'Residential - House',
    [ZoneTypeEnum.ResidentialTownhouse]: 'Residential - Townhouse / Villa',
    [ZoneTypeEnum.ResidentialUnit]: 'Residential - Unit / Apartment',
    [ZoneTypeEnum.ResidentialLand]: 'Residential - Land',
    [ZoneTypeEnum.CommercialOffice]: 'Commercial - Office',
    [ZoneTypeEnum.CommercialRetail]: 'Commercial - Retail',
    [ZoneTypeEnum.CommercialIndustrial]: 'Commercial - Industrial',
    [ZoneTypeEnum.CommercialLand]: 'Commercial - Land',
    [ZoneTypeEnum.RuralResidential]: 'Rural - Residential',
    [ZoneTypeEnum.RuralLand]: 'Rural - Land',
};

interface IPropsSelector {
    postcodeCategories: IDictionary<IPostcodeCategory>;
}

interface IPropsDispatch {
    postcodeCategoriesList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class RatesMatrix extends React.Component<Props> {
    public componentDidMount(): void {
        const { postcodeCategories } = this.props;

        if (!postcodeCategories) {
            this.props.postcodeCategoriesList();
        }
    }

    public render(): JSX.Element {
        const { postcodeCategories } = this.props;

        if (!postcodeCategories) {
            return null;
        }

        const catOneInterestRate = (propertyType: ZoneTypeEnum): number => getDealCalculatedInterestRate({
            loanPurpose: LoanPurposeEnum.BridgingLoan,
            mortgageType: MortgageTypeEnum.FirstMortgage,
            postcodeCategories,
            properties: [{
                currentDebt: 0,
                estimatedValue: 2000000,
                postcode: '2000',
                zoneType: propertyType,
            }],
            requestedPayoutAmount: 1000000,
            termMonths: 12,
        });

        const catTwoInterestRate = (propertyType: ZoneTypeEnum): number => getDealCalculatedInterestRate({
            loanPurpose: LoanPurposeEnum.BridgingLoan,
            mortgageType: MortgageTypeEnum.FirstMortgage,
            postcodeCategories,
            properties: [{
                currentDebt: 0,
                estimatedValue: 2000000,
                postcode: '2259',
                zoneType: propertyType,
            }],
            requestedPayoutAmount: 1000000,
            termMonths: 12,
        });

        const catThreeInterestRate = (propertyType: ZoneTypeEnum): number => getDealCalculatedInterestRate({
            loanPurpose: LoanPurposeEnum.BridgingLoan,
            mortgageType: MortgageTypeEnum.FirstMortgage,
            postcodeCategories,
            properties: [{
                currentDebt: 0,
                estimatedValue: 2000000,
                postcode: '2267',
                zoneType: propertyType,
            }],
            requestedPayoutAmount: 1000000,
            termMonths: 12,
        });

        const columns: ColumnType<any>[] = [{
            dataIndex: 'propertyType',
            render: (propertyType: ZoneTypeEnum) => zoneTypeLabels[propertyType],
            title: 'Property Type',
        }, {
            dataIndex: 'propertyType',
            render: catOneInterestRate,
            title: 'Cat 1',
        }, {
            dataIndex: 'propertyType',
            render: catTwoInterestRate,
            title: 'Cat 2',
        }, {
            dataIndex: 'propertyType',
            render: catThreeInterestRate,
            title: 'Cat 3',
        }];

        const rows: any[] = [
            {
                propertyType: ZoneTypeEnum.ResidentialHouse,
            },
            {
                propertyType: ZoneTypeEnum.ResidentialTownhouse,
            },
            {
                propertyType: ZoneTypeEnum.ResidentialUnit,
            },
            {
                propertyType: ZoneTypeEnum.ResidentialLand,
            },
            {
                propertyType: ZoneTypeEnum.CommercialOffice,
            },
            {
                propertyType: ZoneTypeEnum.CommercialRetail,
            },
            {
                propertyType: ZoneTypeEnum.CommercialIndustrial,
            },
            {
                propertyType: ZoneTypeEnum.CommercialLand,
            },
            {
                propertyType: ZoneTypeEnum.RuralResidential,
            },
            {
                propertyType: ZoneTypeEnum.RuralLand,
            },
        ];

        return (
            <Layout className='leads'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Leads</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Rates Matrix</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={rows}
                            rowKey='propertyType'
                            pagination={false}
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        postcodeCategories: dealPropertiesPostcodeCategoriesSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        postcodeCategoriesList: () => dispatch(dealPropertiesPostcodeCategoriesListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RatesMatrix);
