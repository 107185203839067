import { Breadcrumb, Layout, Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import _ from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import { IDictionary } from '~utilities/IDictionary';
import { loansDrawdownsListAction } from './actions';
import './loans.less';
import { loansDrawdownsSelector } from './selectors';
import { currencyFormatter } from '~utilities/formatters';
import { warehousesListAction } from '~Warehouses/actions';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import { warehousesSelector } from '~Warehouses/selectors';

export default function Drawdowns(): ReactElement {
    const loans: IDictionary<ILoan> = useSelector(loansDrawdownsSelector);
    const warehouses: IDictionary<IWarehouse> = useSelector(warehousesSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!loans) {
            dispatch(loansDrawdownsListAction());
        }
    }, [
        dispatch,
        loans,
    ]);

    useEffect(() => {
        if (!warehouses) {
            dispatch(warehousesListAction());
        }
    }, [
        dispatch,
        warehouses,
    ]);

    if (!loans || !warehouses) {
        return (
            <Layout className='loans'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/loans'>Loans</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Drawdowns</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Drawdowns</Typography.Title>
                        <Spin/>
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    const columns: ColumnsType<ILoan> = [
        {
            dataIndex: 'code',
            render: (code: string, loan: ILoan) => <Link to={`/loans/${loan.uuid}`}>{code}</Link>,
            title: 'Code',
            width: '10%',
        },
        {
            dataIndex: 'name',
            render: (name: string, loan: ILoan) => <Link to={`/loans/${loan.uuid}`}>{name}</Link>,
            title: 'Name',
        },
        {
            dataIndex: 'amount',
            render: (amount: number) => currencyFormatter.format(amount),
            title: 'Loan Amount',
            width: '12%',
        },
        {
            dataIndex: 'retainedFunds',
            render: (retainedFunds: number) => retainedFunds ? currencyFormatter.format(retainedFunds) : '-',
            title: 'Retained Funds',
            width: '12%',
        },
        {
            dataIndex: 'retainedContingency',
            render: (retainedContingency: number) => retainedContingency ? currencyFormatter.format(retainedContingency) : '-',
            title: 'Retained Contingency',
            width: '12%',
        },
        {
            defaultSortOrder: 'descend',
            render: (loan: ILoan) => (loan.retainedFunds + loan.retainedContingency) > 0 ? currencyFormatter.format(loan.retainedFunds + loan.retainedContingency) : '-',
            sorter: (a: ILoan, b: ILoan) => (a.retainedFunds + a.retainedContingency) - (b.retainedFunds + b.retainedContingency),
            title: 'Total Retained',
            width: '12%',
        },
        {
            render: (loan: ILoan) => warehouses[loan.warehouseLoans[0].warehouseUuid].name,
            title: 'Warehouse',
            width: '15%',
        },
    ];

    return (
        <Layout className='loans'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/loans'>Loans</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Drawdowns</Breadcrumb.Item>
            </Breadcrumb>
            <Layout className='content-wrapper'>
                <Layout.Content className='content'>
                    <Typography.Title level={2}>Drawdowns</Typography.Title>
                    <Table
                        columns={columns}
                        dataSource={_.values(loans)}
                        pagination={false}
                        rowKey='uuid'
                        size='middle'
                    />
                </Layout.Content>
            </Layout>
        </Layout>
    );
}
