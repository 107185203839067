import { Card as AntCard, Progress, Tag } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import ExtensionTypeEnum from '~Api/Application/ExtensionTypeEnum';
import ILoan from '~Api/Loan/ILoan';
import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import WorkflowStatusEnum from '~Api/Loan/WorkflowStatusEnum';
import { BoardColumnEnum } from './LegacyDashboard';
import { IGlobalState } from '~reducer';
import { currentAdministratorSelector } from '~Administrators/selectors';
import { connect } from 'react-redux';
import AssigneeSelector from './Loan/AssigneeSelector';
import IAdministrator from '~Api/Administrator/IAdministrator';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import RoleEnum from '~Api/Administrator/RoleEnum';

interface IProps {
    boardColumn: BoardColumnEnum;
    loan: ILoan;
}

interface IPropsSelector {
    currentAdministrator: IAdministrator;
}

type Props = IProps & IPropsSelector;

class LegacyCard extends React.Component<Props> {
    public render(): JSX.Element {
        const { boardColumn, currentAdministrator, loan } = this.props;

        const todayDayjs: Dayjs = dayjs();
        const maturingDate: Dayjs = dayjs().add(90, 'days');
        const maturingSoonDate: Dayjs = dayjs().add(14, 'days');

        const startDateDayjs: Dayjs = dayjs(loan.startDate);
        const endDateDayjs: Dayjs = dayjs(loan.endDate);
        const nextPaymentDateDayjs: Dayjs = !!loan.paymentDateNext ? dayjs(loan.paymentDateNext) : null;
        const gracePeriodEndDateDayjs: Dayjs = dayjs(loan.gracePeriodEndDate);
        const dischargeDateDayjs: Dayjs = !!loan.dischargeDate ? dayjs(loan.dischargeDate) : null;

        const monthDisplay: string = (loan.termMonths === 1) ? 'month' : 'months';
        const totalTermDays: number = dayjs.duration(endDateDayjs.diff(startDateDayjs)).as('days');
        const completedTermDays: number = dayjs.duration(todayDayjs.diff(startDateDayjs)).as('days');

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const isOverdue: boolean = (loan.workflowStatus === WorkflowStatusEnum.Active && loan.status === LoanStatusEnum.ActiveBadStanding);
        const isMaturingSoon: boolean = endDateDayjs < maturingSoonDate;
        const isMaturing: boolean = endDateDayjs < maturingDate;
        const hasGracePeriod: boolean = ExtensionTypeEnum.GracePeriod === loan.extensionType || loan.gracePeriodMonths >= 1 || loan.isInGracePeriod;

        const classNames: string[] = [];
        if (isOverdue) {
            classNames.push('overdue');
        }
        if (isMaturingSoon) {
            classNames.push('maturing-soon');
        } else if (isMaturing) {
            classNames.push('maturing');
        }

        return (
            <AntCard key={loan.uuid} className={classNames.join(' ')}>
                <div className='code'>{loan.code || loan.salesforceCode}</div>
                <Link className='name' to={`/loans/${loan.uuid}`}>{loan.contactName}</Link>
                <div className='amount'>{currencyFormatter.format(loan.amount)}</div>
                <div className='interest-rate'>{percentageFormatter.format(loan.interestRate / 100)}</div>
                <div className='assignee'><AssigneeSelector loan={loan} /></div>
                <div className='term'>{loan.termMonths} {monthDisplay}</div>
                {[BoardColumnEnum.Active, BoardColumnEnum.Drawdown, BoardColumnEnum.Due].includes(boardColumn) && <div className='next-payment-date'>Next payment {nextPaymentDateDayjs ? nextPaymentDateDayjs.fromNow() : 'date unknown'}</div>}
                {isOverdue && <div className='overdue-date'>Overdue {nextPaymentDateDayjs ? nextPaymentDateDayjs.fromNow() : 'date unknown'}</div>}
                {[BoardColumnEnum.Discharge, BoardColumnEnum.Extension, BoardColumnEnum.GracePeriod, BoardColumnEnum.Maturing].includes(boardColumn) && <div className='end-date'>Maturing {endDateDayjs ? endDateDayjs.fromNow() : 'date unknown'}</div>}
                {BoardColumnEnum.Discharge === boardColumn && <div className='discharge-date'>Discharges {dischargeDateDayjs ? dischargeDateDayjs.fromNow() : 'date unknown'}</div>}
                {loan.isInGracePeriod && <div className='grace-period-end-date'>Grace Period ends {gracePeriodEndDateDayjs.fromNow()}</div>}
                <Progress percent={completedTermDays / totalTermDays * 100} showInfo={false} size='small' strokeColor='#1890ff' />
                {hasGracePeriod && <Tag color='orange'>Grace Period</Tag>}
            </AntCard>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentAdministrator: currentAdministratorSelector(state),
    };
}

export default connect(
    mapStateToProps,
    null,
)(LegacyCard);

