import { OpUnitType } from 'dayjs';
import { IDictionary } from '~utilities/IDictionary';

export enum PeriodRangeEnum {
    Day = 'DAY',
    Week = 'WEEK',
    Month = 'MONTH',
    Year = 'YEAR'
}

export enum CountOrDollarEnum {
    Count = 'COUNT',
    Dollar = 'DOLLAR',
}

export enum DirectionEnum {
    Previous = 'PREVIOUS',
    Next = 'NEXT',
}

export const directionToParameterMap: IDictionary<number> = {
    [DirectionEnum.Previous]: -1,
    [DirectionEnum.Next]: 1,
};

export const periodRangeLabels: IDictionary<string> = {
    [PeriodRangeEnum.Day]: 'Day',
    [PeriodRangeEnum.Week]: 'Week',
    [PeriodRangeEnum.Month]: 'Month',
    [PeriodRangeEnum.Year]: 'Year',
};

export const rangeToParameterMap: IDictionary<OpUnitType>= {
    [PeriodRangeEnum.Day]: 'day',
    [PeriodRangeEnum.Week]: 'week',
    [PeriodRangeEnum.Month]: 'month',
    [PeriodRangeEnum.Year]: 'year',
};

export interface IPieData {
    type: string;
    value: number;
}
