import { Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import { loanWarehouseLoansListAction } from '~Loans/actions';
import { loanWarehouseLoansSelector } from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { warehousesListAction } from '~Warehouses/actions';
import { warehousesSelector } from '~Warehouses/selectors';
import Layout from './Layout';

interface IMatch {
    loanUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    warehouseLoans: IDictionary<IWarehouseLoan>;
    warehouses: IDictionary<IWarehouse>;
}

interface IPropsDispatch {
    loanWarehouseLoansList: () => void;
    warehousesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Warehouses extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.summary = this.summary.bind(this);
    }

    public componentDidMount(): void {
        const { warehouseLoans, warehouses } = this.props;

        if (!warehouseLoans) {
            this.props.loanWarehouseLoansList();
        }

        if (!warehouses) {
            this.props.warehousesList();
        }
    }

    public render(): JSX.Element {
        const { match, warehouseLoans, warehouses } = this.props;

        if (!warehouseLoans || !warehouses) {
            return (
                <Layout uuid={match.params.loanUuid} section='warehouses'>
                    <Typography.Title level={2}>Warehouses</Typography.Title>
                    <Spin />
                </Layout>
            );
        }

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnType<IWarehouseLoan>[] = [
            {
                defaultSortOrder: 'ascend',
                render: (warehouseLoan: IWarehouseLoan) => <Link to={`/warehouses/${warehouseLoan.warehouseUuid}/loans/${warehouseLoan.uuid}`}>{warehouses[warehouseLoan.warehouseUuid].name}</Link>,
                title: 'Warehouse',
                width: '50%',
            },
            {
                dataIndex: 'balancePrincipal',
                render: (balancePrincipal: number) => currencyFormatter.format(balancePrincipal),
                title: 'Principal Balance',
            },
        ];

        return (
            <Layout uuid={match.params.loanUuid} section='warehouses'>
                <Typography.Title level={2}>Warehouses</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(warehouseLoans)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                    summary={this.summary}
                />
            </Layout>
        );
    }

    private summary(): JSX.Element {
        const { warehouseLoans } = this.props;

        const totalPrincipal: number = _.reduce(warehouseLoans, (sum, warehouseLoan: IWarehouseLoan) => {
            if (null === warehouseLoan.balancePrincipal) {
                return sum + (warehouseLoan.loan ? warehouseLoan.loan.amount * -1 : 0);
            }

            return sum + warehouseLoan.balancePrincipal;
        }, 0);

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}><strong>Total</strong></Table.Summary.Cell>
                <Table.Summary.Cell index={1}><strong>{currencyFormatter.format(totalPrincipal)}</strong></Table.Summary.Cell>
            </Table.Summary.Row>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        warehouseLoans: loanWarehouseLoansSelector(state, ownProps.match.params.loanUuid),
        warehouses: warehousesSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        loanWarehouseLoansList: () => dispatch(loanWarehouseLoansListAction(ownProps.match.params.loanUuid)),
        warehousesList: () => dispatch(warehousesListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Warehouses);
