import { Button, Modal, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import {
    investmentGetAction,
    investmentReservationProcessAction,
    investmentReservationsListAction,
} from '~Investments/actions';
import {
    investmentReservationsSelector,
    investmentSelector,
} from '~Investments/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import IReservation from '~Api/Investment/IReservation';
import IAccount from '~Api/Investor/IAccount';
import { currencyFormatter } from '~utilities/formatters';

interface IMatch {
    investmentUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    investment: IInvestment;
    reservations: IDictionary<IReservation>;
}

interface IPropsDispatch {
    investmentGet: () => void;
    reservationProcess: (investmentReservationUuid: string) => void;
    reservationsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Transactions extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.summary = this.summary.bind(this);
    }

    public componentDidMount(): void {
        const { investment, reservations } = this.props;

        if (!investment) {
            this.props.investmentGet();
        }

        if (!reservations) {
            this.props.reservationsList();
        }
    }

    public render(): JSX.Element {
        const { investment, match, reservations } = this.props;

        if (!investment || !reservations) {
            return (
                <Layout uuid={match.params.investmentUuid} section='reservations'>
                    <Typography.Title level={2}>Reservations</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const columns: ColumnType<IReservation>[] = [
            {
                dataIndex: 'investorAccount',
                render: (investorAccount: IAccount) => <Link to={`/investors/${investorAccount.investorUuid}/accounts/${investorAccount.uuid}`}>{investorAccount.investorName}</Link>,
                title: 'Investor',
            },
            {
                dataIndex: 'amount',
                render: (amount: number) => currencyFormatter.format(amount),
                title: 'Amount',
                width: '15%',
            },
            {
                dataIndex: 'uuid',
                render: (uuid: string) => {
                    const onClick: () => void = () => this.processReservation(uuid);

                    return <Button onClick={onClick} type='primary'>Process</Button>;
                },
                width: '5%',
            },
        ];

        return (
            <Layout uuid={match.params.investmentUuid} section='reservations'>
                <Typography.Title level={2}>Reservations</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(reservations)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                    summary={this.summary}
                />
            </Layout>
        );
    }

    private summary(): JSX.Element {
        const { reservations } = this.props;

        let investmentCount: number = 0;
        let amountTotal: number = 0;

        _.forEach(reservations, (reservation: IReservation) => {
            investmentCount++;
            amountTotal += reservation.amount;
        });

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}><strong>{investmentCount}</strong></Table.Summary.Cell>
                <Table.Summary.Cell index={1}><strong>{currencyFormatter.format(amountTotal)}</strong></Table.Summary.Cell>
            </Table.Summary.Row>
        );
    }

    private processReservation(reservationUuid: string): void {
        const { reservations } = this.props;

        const reservation: IReservation = reservations[reservationUuid];

        Modal.confirm({
            content: `Are you sure you want to process this reservation of ${currencyFormatter.format(reservation.amount)} for ${reservation.investorAccount.investorName}?`,
            okText: 'Process',
            okType: 'danger',
            onOk: () => {
                this.props.reservationProcess(reservationUuid);
            },
            title: 'Process Reservation',
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investment: investmentSelector(state, ownProps.match.params.investmentUuid),
        reservations: investmentReservationsSelector(state, ownProps.match.params.investmentUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investmentGet: () => dispatch(investmentGetAction(ownProps.match.params.investmentUuid)),
        reservationProcess: (investmentReservationUuid: string) => dispatch(investmentReservationProcessAction(investmentReservationUuid)),
        reservationsList: () => dispatch(investmentReservationsListAction(ownProps.match.params.investmentUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Transactions);
