import PropertyStateEnum from '~Api/Deal/PropertyStateEnum';
import { IDictionary } from '~utilities/IDictionary';

const propertyStateLabels: IDictionary<string> = {
    [PropertyStateEnum.AustralianCapitalTerritory]: 'ACT',
    [PropertyStateEnum.NewSouthWales]: 'NSW',
    [PropertyStateEnum.NorthernTerritory]: 'NT',
    [PropertyStateEnum.Queensland]: 'QLD',
    [PropertyStateEnum.SouthAustralia]: 'SA',
    [PropertyStateEnum.Tasmania]: 'TAS',
    [PropertyStateEnum.Victoria]: 'VIC',
    [PropertyStateEnum.WesternAustralia]: 'WA',
};

export default propertyStateLabels;
