import { all, call, put, takeLeading } from '@redux-saga/core/effects';
import { IFetchResponse } from '~utilities/fetch';
import {
    aggregatorsSetAction,
    IAggregatorsListAction,
} from './actions';
import AggregatorsActionsEnum from './ActionsEnum';
import IAggregator from '~/Api/Aggregator/IAggregator';
import { parseAggregator } from '~Api/Aggregator/parsers';
import {
    aggregatorsListRequest,
} from '~Api/Aggregator/requests';

function* aggregatorsList(action: IAggregatorsListAction): Iterator<any> {
    const rawAggregators: IFetchResponse = yield call(aggregatorsListRequest);
    const aggregators: IAggregator[] = yield Promise.all(rawAggregators.body.map(parseAggregator));
    yield put(aggregatorsSetAction(aggregators));
}

export function* AggregatorsSagas(): any {
    yield all([
        takeLeading(AggregatorsActionsEnum.AggregatorsList, aggregatorsList),
    ]);
}
