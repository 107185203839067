import { Breadcrumb, Layout, Spin, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IDeal from '~Api/Deal/IDeal';
import IProperty from '~Api/Deal/IProperty';
import ILoan from '~Api/Loan/ILoan';
import ILoanProperty from '~Api/Loan/ILoanProperty';
import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import { applicationsListAction } from '~Applications/actions';
import { applicationsSelector } from '~Applications/selectors';
import DealPropertyEditModal from '~Deals/PropertyEditModal';
import { propertyRequiresInsurance } from '~Deals/utilities';
import { IGlobalState } from '~reducer';
import { loansListAction } from './actions';
import LoanPropertyEditModal from './Loan/PropertyEditModal';
import { loansSelector } from './selectors';
import { ColumnType } from 'antd/lib/table';

interface IState {
    editDealUuid: string;
    editDealPropertyUuid: string;
    editLoanPropertyUuid: string;
    editLoanUuid: string;
}

interface ILoanPropertyPair {
    dealProperty?: IProperty;
    deal?: IDeal;
    loan: ILoan;
    loanProperty?: ILoanProperty;
    uuid: string;
}

interface IPropsSelector {
    applications: IApplication[];
    loans: { [loanUuid: string]: ILoan };
}

interface IPropsDispatch {
    applicationsList: () => void;
    loansList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class ExpiringInsurance extends React.Component<Props, IState> {
    public state: IState = {
        editDealPropertyUuid: null,
        editDealUuid: null,
        editLoanPropertyUuid: null,
        editLoanUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickEditDealProperty = this.onClickEditDealProperty.bind(this);
        this.onClickEditLoanProperty = this.onClickEditLoanProperty.bind(this);
        this.onCloseEditModal = this.onCloseEditModal.bind(this);
    }

    public componentDidMount() {
        this.props.applicationsList();
        this.props.loansList();
    }

    public render(): JSX.Element {
        const { applications, loans } = this.props;
        const { editDealUuid, editDealPropertyUuid, editLoanUuid, editLoanPropertyUuid: editLoanPropertyId } = this.state;

        if (!applications || !loans) {
            return (
                <Layout className='loans'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/loans'>Loans</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Expiring Insurance</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Expiring Insurance</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const ageOutDate: dayjs.Dayjs = dayjs().add(30, 'days');

        const properties: ILoanPropertyPair[] = [];

        _.each(loans, (loan: ILoan) => {
            if (![LoanStatusEnum.ActiveBadStanding, LoanStatusEnum.ActiveGoodStanding, LoanStatusEnum.ActiveMatured].includes(loan.status)) {
                return;
            }

            const loanEndDate: dayjs.Dayjs = dayjs(loan.endDate);

            if (loan.applicationUuid) {
                const application: IApplication = _.find(applications, { uuid: loan.applicationUuid }) as IApplication;

                if (application) {
                    const deal: IDeal = application.deal;

                    _.each(deal.properties, (dealProperty: IProperty) => {
                        if (!propertyRequiresInsurance(dealProperty)) {
                            return;
                        }

                        if (!!dealProperty.insuranceExpiryDate && (dayjs(dealProperty.insuranceExpiryDate).isAfter(loanEndDate) || dayjs(dealProperty.insuranceExpiryDate).isAfter(ageOutDate))) {
                            return;
                        }

                        properties.push({
                            deal,
                            dealProperty,
                            loan,
                            uuid: dealProperty.uuid,
                        });
                    });
                }
            } else {
                _.each(loan.properties, (loanProperty: ILoanProperty) => {
                    if (null !== loanProperty.insuranceRequired && !loanProperty.insuranceRequired) {
                        return;
                    }

                    if (!!loanProperty.insuranceExpiryDate && (dayjs(loanProperty.insuranceExpiryDate).isAfter(loanEndDate) || dayjs(loanProperty.insuranceExpiryDate).isAfter(ageOutDate))) {
                        return;
                    }

                    properties.push({
                        loan,
                        loanProperty,
                        uuid: loanProperty.uuid,
                    });
                });
            }
        });

        const columns: ColumnType<ILoanPropertyPair>[] = [
            {
                dataIndex: 'loan',
                render: (loan: ILoan) => <Link to={`/loans/${loan.uuid}`}>{loan.code}</Link>,
                title: 'Loan',
                width: '15%',
            },
            {
                render: (loanPropertyPair: ILoanPropertyPair) => {
                    const { deal, dealProperty, loan, loanProperty } = loanPropertyPair;
                    const onClickEdit: (event: React.MouseEvent) => void = loanProperty ? (event: React.MouseEvent) => {
                        event.stopPropagation();
                        this.onClickEditLoanProperty(loan.uuid, loanProperty.uuid);
                    } : (event: React.MouseEvent) => {
                        event.stopPropagation();
                        this.onClickEditDealProperty(deal.uuid, dealProperty.uuid);
                    };

                    if (loanProperty) {
                        return (
                            <a onClick={onClickEdit}>{loanProperty.streetAddress || ''} {loanProperty.suburb || ''} {loanProperty.state || ''} {loanProperty.postcode || ''}</a>
                        );
                    }

                    return (
                        <a onClick={onClickEdit}>{dealProperty.streetAddress || ''} {dealProperty.suburb || ''} {dealProperty.state || ''} {dealProperty.postcode || ''}</a>
                    );
                },
                title: 'Address',
            },
            {
                render: (loanPropertyPair: ILoanPropertyPair) => {
                    if (loanPropertyPair.loanProperty) {
                        if (loanPropertyPair.loanProperty.insuranceRequired === null) {
                            return 'Unknown';
                        }

                        if (!loanPropertyPair.loanProperty.insuranceRequired) {
                            return 'N/A';
                        }

                        if (!loanPropertyPair.loanProperty.insuranceExpiryDate) {
                            return 'Unknown';
                        }

                        return dayjs(loanPropertyPair.loanProperty.insuranceExpiryDate).format('Do MMMM YYYY');
                    }

                    if (!loanPropertyPair.dealProperty.insuranceExpiryDate) {
                        return 'Unknown';
                    }

                    return dayjs(loanPropertyPair.dealProperty.insuranceExpiryDate).format('Do MMMM YYYY');
                },
                title: 'Insurance Expiry',
                width: '15%',
            },
        ];

        return (
            <Layout className='loans'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/loans'>Loans</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Expiring Insurance</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Expiring Insurance</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.sortBy(properties, (loanPropertyPair: ILoanPropertyPair) => loanPropertyPair.loanProperty ? loanPropertyPair.loanProperty.insuranceExpiryDate : loanPropertyPair.dealProperty.insuranceExpiryDate)}
                            rowKey='uuid'
                            pagination={false}
                            size='middle'
                        />
                        <LoanPropertyEditModal
                            propertyUuid={editLoanPropertyId}
                            isOpen={!!editLoanPropertyId}
                            loanUuid={editLoanUuid}
                            onClose={this.onCloseEditModal}
                        />
                        <DealPropertyEditModal
                            propertyUuid={editDealPropertyUuid}
                            dealUuid={editDealUuid}
                            isOpen={!!editDealPropertyUuid}
                            onCancel={this.onCloseEditModal}
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    private onClickEditDealProperty(dealUuid: string, propertyUuid: string) {
        this.setState({
            editDealPropertyUuid: propertyUuid,
            editDealUuid: dealUuid,
        });
    }

    private onClickEditLoanProperty(loanUuid: string, propertyUuid: string) {
        this.setState({
            editLoanPropertyUuid: propertyUuid,
            editLoanUuid: loanUuid,
        });
    }

    private onCloseEditModal() {
        this.setState({
            editDealPropertyUuid: null,
            editDealUuid: null,
            editLoanPropertyUuid: null,
            editLoanUuid: null,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        applications: applicationsSelector(state),
        loans: loansSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        applicationsList: () => dispatch(applicationsListAction()),
        loansList: () => dispatch(loansListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ExpiringInsurance);
