import { AnyAction } from 'redux';
import IAdviser from '~Api/Adviser/IAdviser';
import IHistory from '~Api/Adviser/IHistory';
import INote from '~Api/Adviser/INote';
import { IDictionary } from '~utilities/IDictionary';
import {
    IAdviserApproveAction,
    IAdviserHistoriesSetAction,
    IAdviserInvestorsSetAction,
    IAdviserNoteRemoveAction,
    IAdviserNoteSetAction,
    IAdviserNotesSetAction,
    IAdviserRejectAction,
    IAdviserResetApprovalStatusAction,
    IAdviserSetAction,
    IAdviserValueSetAction,
    IAdvisersSetAction,
} from './actions';
import AdvisersActionsEnum from './ActionsEnum';
import _ from 'lodash';
import IInvestor from '~Api/Investor/IInvestor';
import ApprovalStatusEnum from '~Api/Adviser/ApprovalStatusEnum';

export interface IAdvisersState {
    adviserInvestorUuids: IDictionary<string[]>;
    advisers: IDictionary<IAdviser>;
    advisersListed: boolean;
    histories: IDictionary<IDictionary<IHistory>>;
    notes: IDictionary<IDictionary<INote>>;
}

const initialData: IAdvisersState = {
    adviserInvestorUuids: {},
    advisers: {},
    advisersListed: false,
    histories: {},
    notes: {},
};

export function advisersReducer(state: IAdvisersState = initialData, action: AnyAction): IAdvisersState {
    switch (action.type) {
        case AdvisersActionsEnum.AdvisersSet: {
            const typedAction: IAdvisersSetAction = action as IAdvisersSetAction;

            return {
                ...state,
                advisers: _.keyBy(typedAction.advisers, 'uuid'),
                advisersListed: true,
            };
        }

        case AdvisersActionsEnum.AdviserSet: {
            const typedAction: IAdviserSetAction = action as IAdviserSetAction;

            return {
                ...state,
                advisers: {
                    ...state.advisers,
                    [typedAction.adviser.uuid]: typedAction.adviser,
                },
            };
        }

        case AdvisersActionsEnum.AdviserApprove: {
            const typedAction: IAdviserApproveAction = action as IAdviserApproveAction;

            return {
                ...state,
                advisers: {
                    ...state.advisers,
                    [typedAction.adviserUuid]: {
                        ...state.advisers[typedAction.adviserUuid],
                        approvalStatus: ApprovalStatusEnum.Approved,
                    },
                },
            };
        }

        case AdvisersActionsEnum.AdviserReject: {
            const typedAction: IAdviserRejectAction = action as IAdviserRejectAction;

            return {
                ...state,
                advisers: {
                    ...state.advisers,
                    [typedAction.adviserUuid]: {
                        ...state.advisers[typedAction.adviserUuid],
                        approvalStatus: ApprovalStatusEnum.Rejected,
                    },
                },
            };
        }

        case AdvisersActionsEnum.AdviserResetApprovalStatus: {
            const typedAction: IAdviserResetApprovalStatusAction = action as IAdviserResetApprovalStatusAction;

            return {
                ...state,
                advisers: {
                    ...state.advisers,
                    [typedAction.adviserUuid]: {
                        ...state.advisers[typedAction.adviserUuid],
                        approvalStatus: ApprovalStatusEnum.Pending,
                    },
                },
            };
        }

        case AdvisersActionsEnum.AdviserValueSet: {
            const typedAction: IAdviserValueSetAction = action as IAdviserValueSetAction;

            return {
                ...state,
                advisers: {
                    ...state.advisers,
                    [typedAction.adviserUuid]: {
                        ...state.advisers[typedAction.adviserUuid],
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        case AdvisersActionsEnum.AdviserHistoriesSet: {
            const typedAction: IAdviserHistoriesSetAction = action as IAdviserHistoriesSetAction;

            return {
                ...state,
                histories: {
                    ...state.histories,
                    [typedAction.adviserUuid]: _.keyBy(typedAction.histories, 'uuid'),
                },
            };
        }

        case AdvisersActionsEnum.AdviserInvestorsSet: {
            const typedAction: IAdviserInvestorsSetAction = action as IAdviserInvestorsSetAction;

            return {
                ...state,
                adviserInvestorUuids: {
                    ...state.adviserInvestorUuids,
                    [typedAction.adviserUuid]: _.map(typedAction.investors, (investor: IInvestor) => investor.uuid),
                },
            };
        }

        case AdvisersActionsEnum.AdviserNotesSet: {
            const typedAction: IAdviserNotesSetAction = action as IAdviserNotesSetAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.adviserUuid]: _.keyBy(typedAction.notes, 'uuid'),
                },
            };
        }

        case AdvisersActionsEnum.AdviserNoteRemove: {
            const typedAction: IAdviserNoteRemoveAction = action as IAdviserNoteRemoveAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.adviserUuid]: _.omit(state.notes[typedAction.adviserUuid] || {}, typedAction.noteUuid),
                },
            };
        }

        case AdvisersActionsEnum.AdviserNoteSet: {
            const typedAction: IAdviserNoteSetAction = action as IAdviserNoteSetAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.adviserUuid]: {
                        ...(state.notes[typedAction.adviserUuid] || {}),
                        [typedAction.note.uuid || 'new']: typedAction.note,
                    },
                },
            };
        }

        default:
            return state;
    }
}
