import { Button, Modal, Space, Spin, Table, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import BankAccountConfirmationStatusEnum from '~Api/Investor/ConfirmationStatusEnum';
import IBankAccount from '~Api/Investor/IBankAccount';
import IInvestor from '~Api/Investor/IInvestor';
import { investorGetAction, investorPrimaryBankAccountAction } from '~Investors/actions';
import { investorBankAccountsSelector, investorSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { investorBankAccountConfirmAction, investorBankAccountUnconfirmAction } from '../actions';
import AddModal from './BankAccountsAddModal';
import Layout from './Layout';
import IAuthUser from '~Auth/IAuthUser';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import { authCurrentUserSelector } from '~Auth/selectors';

interface IState {
    isAddModalOpen: boolean;
}

interface IMatch {
    investorUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    bankAccounts: IDictionary<IBankAccount>;
    currentUser: IAuthUser;
    investor: IInvestor;

}

interface IPropsDispatch {
    confirm: (bankAccountUuid: string) => void;
    investorGet: () => void;
    primary: (bankAccountUuid: string) => void;
    unconfirm: (bankAccountUuid: string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class BankAccounts extends React.Component<Props> {
    public state: IState = {
        isAddModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAdd = this.onClickAdd.bind(this);
        this.onCloseAddModal = this.onCloseAddModal.bind(this);
    }

    public componentDidMount(): void {
        const { investor } = this.props;

        if (!investor) {
            this.props.investorGet();
        }
    }

    public render(): JSX.Element {
        const { currentUser, investor, match } = this.props;
        const { isAddModalOpen } = this.state;

        if (!investor) {
            return (
                <Layout uuid={match.params.investorUuid} section='bank-accounts'>
                    <Typography.Title level={2}>Bank Accounts</Typography.Title>
                    <Spin />
                </Layout>
            );
        }

        const columns: ColumnsType<IBankAccount> = [
            {
                render: (bankAccount: IBankAccount) => investor.primaryBankAccountUuid === bankAccount.uuid && <Tag color='blue'>Primary</Tag>,
                title: '',
                width: '5%',
            },
            {
                dataIndex: 'bankName',
                render: (bankName: string) => bankName || '-',
                title: 'Bank Name',
                width: '20%',
            },
            {
                dataIndex: 'accountName',
                render: (accountName: string) => accountName || '-',
                title: 'Account Name',
            },
            {
                dataIndex: 'bsb',
                render: (bsb: string) => bsb || '-',
                title: 'BSB',
                width: '15%',
            },
            {
                dataIndex: 'accountNumber',
                render: (accountNumber: string) => accountNumber || '-',
                title: 'Account Number',
                width: '20%',
            },
            {
                render: (bankAccount: IBankAccount) => {
                    const onClickConfirm = () => this.onClickConfirm(bankAccount.uuid);
                    const onClickPrimary = () => this.onClickPrimary(bankAccount.uuid);
                    const onClickUnconfirm = () => this.onClickUnconfirm(bankAccount.uuid);

                    const isPrimary: boolean = investor.primaryBankAccountUuid === bankAccount.uuid;
                    const isApproved: boolean = bankAccount.confirmationStatus === BankAccountConfirmationStatusEnum.Approved;
                    return (
                        <Space>
                            {!isApproved && currentUser.permissions.includes(PermissionsEnum.InvestorBankAccountConfirm) && <Button onClick={onClickConfirm}>Confirm</Button>}
                            {isApproved && currentUser.permissions.includes(PermissionsEnum.InvestorBankAccountConfirm) && <Button onClick={onClickUnconfirm}>Unconfirm</Button>}
                            {!isPrimary && <Button onClick={onClickPrimary}>Primary</Button>}
                        </Space>
                    );
                },
                title: 'Actions',
                width: '15%',
            },
        ];

        return (
            <Layout uuid={match.params.investorUuid} section='bank-accounts'>
                <Space className='actions'>
                    <Button onClick={this.onClickAdd}>Add Bank Account</Button>
                </Space>
                <Typography.Title level={2}>Bank Accounts</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(investor.bankAccounts)}
                    rowKey='uuid'
                    size='middle'
                />
                <AddModal
                    investorUuid={match.params.investorUuid}
                    isOpen={isAddModalOpen}
                    onCancel={this.onCloseAddModal}
                />
            </Layout>
        );
    }

    private onClickAdd(): void {
        this.setState({
            isAddModalOpen: true,
        });
    }

    private onCloseAddModal(): void {
        this.setState({
            isAddModalOpen: false,
        });
    }

    private onClickConfirm(bankAccountUuid: string): void {
        Modal.confirm({
            content: 'Have you performed the relevant checks?',
            okText: 'Confirm',
            onOk: () => {
                this.props.confirm(bankAccountUuid);
            },
            title: 'Do you want to confirm these account details as accurate?',
        });
    }

    private onClickPrimary(bankAccountUuid: string): void {
        Modal.confirm({
            content: 'This account will be used to receive withdrawals',
            okText: 'Primary',
            onOk: () => {
                this.props.primary(bankAccountUuid);
            },
            title: 'Do you want to make this account the primary account?',
        });
    }

    private onClickUnconfirm(bankAccountUuid: string): void {
        Modal.confirm({
            okText: 'Unconfirm',
            onOk: () => {
                this.props.unconfirm(bankAccountUuid);
            },
            title: 'Do you want to unconfirm these account details?',
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        bankAccounts: investorBankAccountsSelector(state, ownProps.match.params.investorUuid),
        currentUser: authCurrentUserSelector(state),
        investor: investorSelector(state, ownProps.match.params.investorUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        confirm: (bankAccountUuid: string) => dispatch(investorBankAccountConfirmAction(bankAccountUuid)),
        investorGet: () => dispatch(investorGetAction(ownProps.match.params.investorUuid)),
        primary: (bankAccountUuid: string) => dispatch(investorPrimaryBankAccountAction(bankAccountUuid, ownProps.match.params.investorUuid)),
        unconfirm: (bankAccountUuid: string) => dispatch(investorBankAccountUnconfirmAction(bankAccountUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BankAccounts);
