import { Spin, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IHistory from '~Api/WithdrawalAba/IHistory';
import IWithdrawalAba from '~Api/WithdrawalAba/IWithdrawalAba';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { withdrawalAbaGetAction, withdrawalAbaHistoriesListAction } from '~WithdrawalAbas/actions';
import { withdrawalAbaHistoriesSelector, withdrawalAbaSelector } from '~WithdrawalAbas/selectors';
import Layout from './Layout';

interface IMatch {
    withdrawalAbaUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    histories: IDictionary<IHistory>;
    withdrawalAba: IWithdrawalAba;
}

interface IPropsDispatch {
    historiesList: () => void;
    withdrawalAbaGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class History extends React.Component<Props> {
    public componentDidMount(): void {
        const { histories, withdrawalAba } = this.props;

        if (!withdrawalAba) {
            this.props.withdrawalAbaGet();
        }

        if (!histories) {
            this.props.historiesList();
        }
    }

    public render(): JSX.Element {
        const { histories, match, withdrawalAba } = this.props;

        if (!withdrawalAba || !histories) {
            return (
                <Layout uuid={match.params.withdrawalAbaUuid} section='history'>
                    <Typography.Title level={2}>History</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const historiesBlock: JSX.Element[] = _.sortBy(_.filter(_.values(histories), (history) => !!history.uuid), ['createdTime']).map((loopHistory) => (
            <Timeline.Item key={loopHistory.uuid} label={dayjs(loopHistory.createdTime).format('YYYY-MM-DD - HH:mm')}>
                <Typography.Text>{loopHistory.description}</Typography.Text>
            </Timeline.Item>
        ));

        return (
            <Layout uuid={match.params.withdrawalAbaUuid} section='history'>
                <Typography.Title level={2}>History</Typography.Title>
                <Timeline mode='left' reverse={true}>
                    {historiesBlock}
                </Timeline>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        histories: withdrawalAbaHistoriesSelector(state, ownProps.match.params.withdrawalAbaUuid),
        withdrawalAba: withdrawalAbaSelector(state, ownProps.match.params.withdrawalAbaUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        historiesList: () => dispatch(withdrawalAbaHistoriesListAction(ownProps.match.params.withdrawalAbaUuid)),
        withdrawalAbaGet: () => dispatch(withdrawalAbaGetAction(ownProps.match.params.withdrawalAbaUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(History);
