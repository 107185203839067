import {
    CloudDownloadOutlined,
    LoadingOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import { Button, Spin, Table, Tooltip, Typography, Upload } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IBroker from '~Api/Broker/IBroker';
import IDocument from '~Api/Broker/IDocument';
import { authTokenSelector } from '~Auth/selectors';
import {
    brokerDocumentsListAction,
    brokerDocumentUploadAction,
    brokerGetAction,
} from '~Brokers/actions';
import BrokerDocumentTypeEnum from '~Api/Broker/BrokerDocumentTypeEnum';
import { brokerDocumentsSelector, brokerSelector } from '~Brokers/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';

const typeLabels: any = {
    [BrokerDocumentTypeEnum.InsuranceProof]: 'Proof of Professional Indemnity Insurance',
    [BrokerDocumentTypeEnum.MembershipDocument]: 'ACL/CR Membership Document',
    [BrokerDocumentTypeEnum.PhotoId]: 'Photo ID',
};

interface IListDocument {
    hasFile: boolean;
    isUploading: boolean;
    onUpload?: (file: File) => boolean;
    type: BrokerDocumentTypeEnum;
    uuid: string;
}

interface IMatch {
    brokerUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    broker: IBroker;
    documents: IDocument[];
    token: string;
}

interface IPropsDispatch {
    brokerDocumentUpload: (file: File, type: BrokerDocumentTypeEnum) => void;
    brokerDocumentsList: () => void;
    brokerGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Documents extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onUploadInsuranceProof = this.onUploadInsuranceProof.bind(this);
        this.onUploadMembershipDocument = this.onUploadMembershipDocument.bind(this);
        this.onUploadPhotoId = this.onUploadPhotoId.bind(this);
    }

    public componentDidMount() {
        const { broker, documents } = this.props;

        if (!broker) {
            this.props.brokerGet();
        }

        if (!documents) {
            this.props.brokerDocumentsList();
        }
    }

    public render(): JSX.Element {
        const { broker, documents, match, token } = this.props;

        if (!broker || !documents) {
            return (
                <Layout brokerUuid={match.params.brokerUuid} section='documents'>
                    <Spin/>
                </Layout>
            );
        }

        const columns = [
            {
                dataIndex: 'type',
                render: (type: BrokerDocumentTypeEnum) => typeLabels[type],
                title: 'Type',
            },
            {
                render: (document: IListDocument) => {
                    return (
                        <React.Fragment>
                            <Upload beforeUpload={document.onUpload}>
                                <Tooltip title='Upload'>
                                    <Button type='link'>
                                        {!document.isUploading && <UploadOutlined/>}
                                        {document.isUploading && <LoadingOutlined/>}
                                    </Button>
                                </Tooltip>
                            </Upload>
                            <Tooltip title='Download'>
                                <Button type='link' disabled={!document.hasFile} href={`${process.env.API_HOST}/brokers/${broker.uuid}/documents/${document.uuid}/download?token=${token}`}><CloudDownloadOutlined/></Button>
                            </Tooltip>
                        </React.Fragment>
                    );
                },
                title: 'Actions',
                width: '15%',
            },
        ];

        const insuranceProof = _.find(documents, { type: BrokerDocumentTypeEnum.InsuranceProof });
        const membershipDocument = _.find(documents, { type: BrokerDocumentTypeEnum.MembershipDocument });
        const photoId = _.find(documents, { type: BrokerDocumentTypeEnum.PhotoId });

        const data: IListDocument[] = [
            {
                hasFile: insuranceProof && !!insuranceProof.uuid,
                uuid: insuranceProof && insuranceProof.uuid,
                isUploading: insuranceProof && insuranceProof.isUploading,
                onUpload: this.onUploadInsuranceProof,
                type: BrokerDocumentTypeEnum.InsuranceProof,
            },
            {
                hasFile: membershipDocument && !!membershipDocument.uuid,
                uuid: membershipDocument && membershipDocument.uuid,
                isUploading: membershipDocument && membershipDocument.isUploading,
                onUpload: this.onUploadMembershipDocument,
                type: BrokerDocumentTypeEnum.MembershipDocument,
            },
            {
                hasFile: photoId && !!photoId.uuid,
                uuid: photoId && photoId.uuid,
                isUploading: photoId && photoId.isUploading,
                onUpload: this.onUploadPhotoId,
                type: BrokerDocumentTypeEnum.PhotoId,
            },
        ];

        return (
            <Layout brokerUuid={match.params.brokerUuid} section='documents'>
                <Typography.Title level={2}>Documents</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    rowKey='type'
                />
            </Layout>
        );
    }

    private onUploadInsuranceProof(file: File): boolean {
        this.props.brokerDocumentUpload(file, BrokerDocumentTypeEnum.InsuranceProof);

        return false;
    }

    private onUploadMembershipDocument(file: File): boolean {
        this.props.brokerDocumentUpload(file, BrokerDocumentTypeEnum.MembershipDocument);

        return false;
    }

    private onUploadPhotoId(file: File): boolean {
        this.props.brokerDocumentUpload(file, BrokerDocumentTypeEnum.PhotoId);

        return false;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        broker: brokerSelector(state, ownProps.match.params.brokerUuid),
        documents: brokerDocumentsSelector(state, ownProps.match.params.brokerUuid),
        token: authTokenSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        brokerDocumentUpload: (file: File, type: BrokerDocumentTypeEnum) => dispatch(brokerDocumentUploadAction(ownProps.match.params.brokerUuid, file, type)),
        brokerDocumentsList: () => dispatch(brokerDocumentsListAction(ownProps.match.params.brokerUuid)),
        brokerGet: () => dispatch(brokerGetAction(ownProps.match.params.brokerUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Documents);
