import ApprovalStatusEnum from './ApprovalStatusEnum';
import BrokerTypeEnum from './BrokerTypeEnum';
import IBroker from './IBroker';
import IDocument from './IDocument';
import IHistory from './IHistory';
import INote from './INote';
import RegistrationSourceEnum from './RegistrationSourceEnum';
import RejectReasonEnum from './RejectReasonEnum';
import StateEnum from './StateEnum';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseBroker(raw: any): IBroker {
    return {
        aclNumber: raw.aclNumber,
        aggregatorUuid: raw.aggregatorUuid,
        approvalStatus: raw.approvalStatus as ApprovalStatusEnum,
        bdmUuid: raw.bdmUuid,
        code: raw.code,
        companyName: raw.companyName,
        contactDateNext: raw.contactDateNext,
        createdTime: raw.createdTime,
        email: raw.email,
        firstName: raw.firstName,
        hasBeenBankrupted: raw.hasBeenBankrupted,
        hasBeenConvicted: raw.hasBeenConvicted,
        hasIndemnityInsurance: raw.hasIndemnityInsurance,
        lastName: raw.lastName,
        latitude: raw.latitude,
        linkedInUrl: raw.linkedInUrl,
        longitude: raw.longitude,
        phone: raw.phone,
        postalAddress: raw.postalAddress,
        postcode: raw.postcode,
        registrationSource: raw.registrationSource as RegistrationSourceEnum,
        rejectReason: raw.rejectReason as RejectReasonEnum,
        state: raw.state as StateEnum,
        suburb: raw.suburb,
        type: raw.type as BrokerTypeEnum,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseBrokerDocument(raw: any): IDocument {
    return {
        createdTime: raw.createdTime,
        type: raw.type,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseBrokerHistory(raw: any): IHistory {
    return {
        createdTime: raw.createdTime,
        description: raw.description,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseBrokerNote(raw: any): INote {
    return {
        administratorUuid: raw.administratorUuid,
        createdTime: raw.createdTime,
        note: raw.note,
        uuid: raw.uuid,
    };
}
