import { all, call, debounce, put, select, takeEvery } from '@redux-saga/core/effects';
import { notification } from 'antd';
import AdvisersActionsEnum from './ActionsEnum';
import {
    IAdviserApproveAction,
    IAdviserGetAction,
    IAdviserHistoriesListAction,
    IAdviserInvestorsListAction,
    IAdviserNotesAddAction,
    IAdviserNotesListAction,
    IAdviserRejectAction,
    IAdviserResetApprovalStatusAction,
    IAdviserSendAction,
    IAdviserValueSetAction,
    IAdvisersAddAction,
    IAdvisersListAction,
    adviserHistoriesSetAction,
    adviserInvestorsSetAction,
    adviserNoteRemoveAction,
    adviserNoteSetAction,
    adviserNotesSetAction,
    adviserSendAction,
    adviserSetAction,
    advisersSetAction,
} from './actions';
import { renderNotificationLoadingIcon } from '~utilities/utils';
import dayjs from 'dayjs';
import { IFetchResponse } from '~utilities/fetch';
import {
    adviserApproveRequest,
    adviserGetRequest,
    adviserHistoriesListRequest,
    adviserNotesAddRequest,
    adviserNotesListRequest,
    adviserRejectRequest,
    adviserResetApprovalStatusRequest,
    adviserUpdateRequest,
    advisersAddRequest,
    advisersInvestorsListRequest,
    advisersListRequest,

} from '~Api/Adviser/requests';
import IAdviser from '~Api/Adviser/IAdviser';
import {
    parseAdviser,
    parseAdviserHistory,
    parseAdviserNote,
} from '~Api/Adviser/parsers';
import _ from 'lodash';
import { currentAdministratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import IHistory from '~Api/Administrator/IHistory';
import INote from '~Api/Application/INote';
import { adviserSelector } from './selectors';
import { parseInvestor } from '~Api/Investor/parsers';
import IInvestor from '~Api/Investor/IInvestor';

function* advisersAdd(action: IAdvisersAddAction): Iterator<unknown> {
    const key: string = `advisersAdd ${dayjs().format()}`;
    const message: string = 'Add Adviser';

    notification.open({
        description: 'Adding adviser...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const advisersAddResponse: IFetchResponse = yield call(advisersAddRequest, action.adviser);
    if (advisersAddResponse.status === 422) {
        notification.error({
            description: `There was a problem adding the adviser: ${_.values(advisersAddResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const adviser: IAdviser = parseAdviser(advisersAddResponse.body);
        yield put(adviserSetAction(adviser));

        notification.success({
            description: 'The adviser has been added.',
            duration: 4.5,
            key,
            message,
        });
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* advisersList(action: IAdvisersListAction): Iterator<unknown> {
    const advisersListResponse: IFetchResponse = yield call(advisersListRequest);
    const advisers: IAdviser[] = yield Promise.all(advisersListResponse.body.map(parseAdviser));
    yield put(advisersSetAction(advisers));
}

function* adviserGet(action: IAdviserGetAction): Iterator<unknown> {
    const adviserGetResponse: IFetchResponse = yield call(adviserGetRequest, action.adviserUuid);
    const parsedadviser: IAdviser = yield parseAdviser(adviserGetResponse.body);
    yield put(adviserSetAction(parsedadviser));
}

function* adviserApprove(action: IAdviserApproveAction): Iterator<unknown> {
    const key: string = `adviserApprove ${action.adviserUuid}`;
    const message: string = 'Approve Adviser';

    notification.open({
        description: 'Approving adviser...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const adviser: IAdviser = yield select(adviserSelector, action.adviserUuid);
    yield call(adviserApproveRequest, adviser.uuid);

    notification.success({
        description: 'The adviser has been approved.',
        duration: 4.5,
        key,
        message,
    });
}

function* adviserSend(action: IAdviserSendAction): Iterator<unknown> {
    const adviser: IAdviser = yield select(adviserSelector, action.adviserUuid);
    yield call(adviserUpdateRequest, adviser);
}

function* adviserValueSet(action: IAdviserValueSetAction): Iterator<unknown> {
    yield put(adviserSendAction(action.adviserUuid));
}

function* adviserHistoriesList(action: IAdviserHistoriesListAction): Iterator<unknown> {
    const adviserHistoriesListResponse: IFetchResponse = yield call(adviserHistoriesListRequest, action.adviserUuid);
    const histories: IHistory[] = yield Promise.all(adviserHistoriesListResponse.body.map(parseAdviserHistory));
    yield put(adviserHistoriesSetAction(action.adviserUuid, histories));
}

function* adviserInvestorsList(action: IAdviserInvestorsListAction): Iterator<unknown> {
    const adviserInvestorsListResponse: IFetchResponse = yield call(advisersInvestorsListRequest, action.adviserUuid);
    const investors: IInvestor[] = yield Promise.all(adviserInvestorsListResponse.body.map(parseInvestor));
    yield put(adviserInvestorsSetAction(action.adviserUuid, investors));
}

function* adviserNotesAdd(action: IAdviserNotesAddAction): Iterator<unknown> {
    const key: string = `adviserNotesAdd ${dayjs().format()}`;

    notification.open({
        description: 'Adding note...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Note',
    });

    const currentAdministrator: IAdministrator = yield select(currentAdministratorSelector);

    const preNote: INote = {
        administratorUuid: currentAdministrator.uuid,
        createdTime: dayjs().format(),
        note: action.note,
    };

    yield put(adviserNoteSetAction(action.adviserUuid, preNote));

    const adviserNotesAddResponse: IFetchResponse = yield call(adviserNotesAddRequest, action.adviserUuid, preNote);
    const note: INote = parseAdviserNote(adviserNotesAddResponse.body);

    yield put(adviserNoteRemoveAction(action.adviserUuid, 'new'));
    yield put(adviserNoteSetAction(action.adviserUuid, note));

    notification.success({
        description: 'The note has been added.',
        duration: 4.5,
        key,
        message: 'Add Note',
    });
}

function* adviserNotesList(action: IAdviserNotesListAction): Iterator<unknown> {
    const adviserNotesListResponse: IFetchResponse = yield call(adviserNotesListRequest, action.adviserUuid);
    const notes: INote[] = yield Promise.all(adviserNotesListResponse.body.map(parseAdviserNote));
    yield put(adviserNotesSetAction(action.adviserUuid, notes));
}

function* adviserReject(action: IAdviserRejectAction): Iterator<unknown> {
    const key: string = `adviserReject ${action.adviserUuid}`;
    const message: string = 'Reject Adviser';

    notification.open({
        description: 'Rejecting adviser...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    yield call(adviserRejectRequest, action.adviserUuid);

    notification.success({
        description: 'The adviser has been rejected.',
        duration: 4.5,
        key,
        message,
    });
}

function* adviserResetApprovalStatus(action: IAdviserResetApprovalStatusAction): Iterator<unknown> {
    const key: string = `adviserResetApprovalStatus ${action.adviserUuid}`;
    const message: string = 'Reset Adviser Approval Status';

    notification.open({
        description: 'Resetting adviser status...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const adviserResetApprovalStatusResponse: IFetchResponse = yield call(adviserResetApprovalStatusRequest, action.adviserUuid);
    if (adviserResetApprovalStatusResponse.status === 422) {
        notification.error({
            description: `There was a problem resetting the adviser: ${_.values(adviserResetApprovalStatusResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const adviser: IAdviser = yield parseAdviser(adviserResetApprovalStatusResponse.body);
        yield put(adviserSetAction(adviser));

        notification.success({
            description: 'The adviser approval status as been reset.',
            duration: 4.5,
            key,
            message,
        });
    }
}

export function* AdvisersSagas(): Iterator<unknown> {
    yield all([
        takeEvery(AdvisersActionsEnum.AdvisersAdd, advisersAdd),
        debounce(50, AdvisersActionsEnum.AdvisersList, advisersList),

        takeEvery(AdvisersActionsEnum.AdviserApprove, adviserApprove),
        takeEvery(AdvisersActionsEnum.AdviserGet, adviserGet),
        takeEvery(AdvisersActionsEnum.AdviserReject, adviserReject),
        takeEvery(AdvisersActionsEnum.AdviserResetApprovalStatus, adviserResetApprovalStatus),
        debounce(500, AdvisersActionsEnum.AdviserSend, adviserSend),
        takeEvery(AdvisersActionsEnum.AdviserValueSet, adviserValueSet),

        takeEvery(AdvisersActionsEnum.AdviserHistoriesList, adviserHistoriesList),

        takeEvery(AdvisersActionsEnum.AdviserInvestorsList, adviserInvestorsList),

        takeEvery(AdvisersActionsEnum.AdviserNotesAdd, adviserNotesAdd),
        takeEvery(AdvisersActionsEnum.AdviserNotesList, adviserNotesList),
    ]);
}