import dayjs from 'dayjs';
import React from 'react';
import IBroker from '~Api/Broker/IBroker';
import NextContactModal from './Broker/NextContactModal';

interface IState {
    isModalOpen: boolean;
}

interface IProps {
    broker: IBroker;
}

type Props = IProps;

class NextContact extends React.Component<Props, IState> {
    public state: IState = {
        isModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickNextContact = this.onClickNextContact.bind(this);
        this.onNextContactCancel = this.onNextContactCancel.bind(this);
    }

    public render(): JSX.Element {
        const { broker } = this.props;
        const { isModalOpen } = this.state;

        return (
            <>
                <a onClick={this.onClickNextContact}>{broker.contactDateNext ? dayjs(broker.contactDateNext).format('Do MMMM YYYY') : '-'}</a>
                <NextContactModal
                    broker={broker}
                    isOpen={isModalOpen}
                    onCancel={this.onNextContactCancel}
                />
            </>
        );
    }

    private onClickNextContact() {
        this.setState({
            isModalOpen: true,
        });
    }

    private onNextContactCancel() {
        this.setState({
            isModalOpen: false,
        });
    }
}

export default NextContact;
