import { Typography } from 'antd';
import React from 'react';
import Layout from '~Reports/Layout';

class List extends React.Component {
    render(): JSX.Element {
        return (
            <Layout section='list'>
                <Typography.Title level={2}>Reports</Typography.Title>
            </Layout>
        );
    }
}

export default List;
