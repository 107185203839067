import { Button, Form, Modal, Select, Space, Spin } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IAdviser from '~Api/Adviser/IAdviser';
import IInvestor from '~Api/Investor/IInvestor';
import {
    advisersListAction,
} from '~Advisers/actions';
import { advisersSelector } from '~Advisers/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import {
    investorAssignAdviserAction,
    investorUnassignAdviserAction,
} from './actions';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';

interface IState {
    showModal: boolean;
}

interface IProps {
    investor: IInvestor;
}

interface IPropsSelector {
    advisers: IDictionary<IAdviser>;
    currentUser: IAuthUser;
}

interface IPropsDispatch {
    advisersList: () => void;
    assign: (administratorUuid: string) => void;
    unassign: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class AdviserSelector extends React.Component<Props, IState> {
    public state: IState = {
        showModal: false,
    };

    public componentDidMount() {
        const { advisers, currentUser } = this.props;

        if (currentUser.permissions.includes(PermissionsEnum.Advisers) && !advisers) {
            this.props.advisersList();
        }
    }

    constructor(props: Props) {
        super(props);

        this.onChangeAdviser = this.onChangeAdviser.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
        this.onCancelChange = this.onCancelChange.bind(this);
        this.onClickAdviser = this.onClickAdviser.bind(this);
        this.onClickClear = this.onClickClear.bind(this);
    }

    public render(): JSX.Element {
        const { advisers, currentUser, investor } = this.props;
        const { showModal } = this.state;

        if (!currentUser.permissions.includes(PermissionsEnum.Advisers)) {
            return (
                <React.Fragment>
                    {investor.adviserName}
                </React.Fragment>
            );
        }

        if (!advisers) {
            return (
                <Spin />
            );
        }

        const currentAdviser: IAdviser = investor.adviserUuid && advisers[investor.adviserUuid];
        const currentAdviserName: string = currentAdviser ? currentAdviser.formattedName + (currentAdviser.companyName ? ` (${currentAdviser.companyName})` : '') : 'None';

        if (!currentUser.permissions.includes(PermissionsEnum.InvestorAssignAdviser)) {
            return (
                <React.Fragment>
                    {currentAdviserName}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <a onClick={this.onClickAdviser} >
                    {currentAdviserName}
                </a>
                <Modal
                    okText='Update'
                    onCancel={this.onCancelChange}
                    onOk={this.onClickOk}
                    open={showModal}
                    title='Change Adviser'
                    wrapClassName='investor-adviser-change-modal'
                >
                    <Form.Item className='adviser' label='Adviser'>
                        <Space>
                            <Select
                                filterOption={this.filterAdviserOption}
                                onChange={this.onChangeAdviser}
                                showSearch={true}
                                value={investor.adviserUuid}
                            >
                                {_.sortBy(advisers, (adviser: IAdviser) => adviser.formattedName.trim().toLowerCase()).map((adviser: IAdviser) => <Select.Option key={adviser.uuid} value={adviser.uuid}>{adviser.formattedName}{!!adviser.companyName && ` (${adviser.companyName})`}</Select.Option>)}
                            </Select>
                            <Button disabled={!investor.adviserUuid} onClick={this.onClickClear}>Clear</Button>
                        </Space>
                    </Form.Item>
                </Modal>
            </React.Fragment >
        );
    }

    private filterAdviserOption(input: string, option: OptionData | OptionGroupData): boolean {
        return option.children.toString().toLocaleLowerCase().includes(input.toLocaleLowerCase());
    }

    private onChangeAdviser(adviserUuid: string): void {
        this.props.assign(adviserUuid);
    }

    private onClickAdviser(): void {
        this.setState({
            showModal: true,
        });
    }

    private onCancelChange(): void {
        this.setState({
            showModal: false,
        });
    }

    private onClickClear(): void {
        this.props.unassign();
    }

    private onClickOk(): void {
        this.setState({
            showModal: false,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        advisers: advisersSelector(state),
        currentUser: authCurrentUserSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        advisersList: () => dispatch(advisersListAction()),
        assign: (adviserUuid: string) => dispatch(investorAssignAdviserAction(ownProps.investor.uuid, adviserUuid)),
        unassign: () => dispatch(investorUnassignAdviserAction(ownProps.investor.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdviserSelector);
