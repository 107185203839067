enum UsersActionsEnum {
    UsersList = 'USERS/LIST',
    UsersSet = 'USERS/SET',
    UsersSuspiciousList = 'USERS/SUSPICIOUS/LIST',
    UsersSuspiciousSet = 'USERS/SUSPICIOUS/SET',

    UserBlock = 'USER/BLOCK',
    UserEmail = 'USER/EMAIL',
    UserGet = 'USER/GET',
    UserPhone = 'USER/PHONE',
    UserSend = 'USER/SEND',
    UserSet = 'USER/SET',
    UserUnblock = 'USER/UNBLOCK',
    UserValueSet = 'USER/VALUE/SET',

    UserHistoriesList = 'USER/HISTORIES/LIST',
    UserHistoriesSet = 'USER/HISTORIES/SET',

    UserInvestorsList = 'USER/INVESTORS/LIST',
    UserInvestorsSet = 'USER/INVESTORS/SET',
}

export default UsersActionsEnum;
