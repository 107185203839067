enum DischargeMethodEnum {
    DirectTransfer = 'DIRECT_TRANSFER',
    DuffelBagOfCash = 'DUFFEL_BAG_OF_CASH',
    GracePeriod = 'GRACE_PERIOD',
    MortgageeSale = 'MORTGAGEE_SALE',
    Refinance = 'REFINANCE',
    RefinanceInternal = 'REFINANCE_INTERNAL',
    SaleOfProperty = 'SALE_OF_PROPERTY',
    WrittenOff = 'WRITTEN_OFF',
}

export default DischargeMethodEnum;
