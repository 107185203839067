import { Spin } from 'antd';
import React from 'react';
import { IDictionary } from '~utilities/IDictionary';

export function renderNotificationLoadingIcon(): JSX.Element {
    return <Spin/>;
}

export function keyMap<Interface>(
    uuids: string[],
    keyBy: keyof Interface,
    callback: (uuid: string) => Interface
): IDictionary<Interface> {
    const mappedObjects: IDictionary<Interface> = {};

    uuids.forEach((uuid: string): void => {
        const object: Interface = callback(uuid);
        const key: string = (object as unknown)[keyBy] as string;
        mappedObjects[key] = object;
    });

    return mappedObjects;
}
