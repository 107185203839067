import { all, call, debounce, put, select, takeEvery } from '@redux-saga/core/effects';
import { notification } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { currentAdministratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import ApprovalStatusEnum from '~Api/Deal/ApprovalStatusEnum';
import IDeal from '~Api/Deal/IDeal';
import IMovementReportLead from '~Api/Deal/IMovementReportLead';
import ILeadOriginated from '~Api/Deal/IOriginationReportLead';
import IQuote from '~Api/Deal/IQuote';
import {
    parseDeal,
    parseDealQuote,
    parseMovementReportLead,
    parseOriginationReportLead,
} from '~Api/Deal/parsers';
import {
    dealAddRequest,
    dealGetRequest,
    dealInitialCallRequest,
    dealMovementReportRequest,
    dealOriginationReportRequest,
    dealQuoteCreateApplicationRequest,
    dealQuoteSendRequest,
    dealQuotesAddRequest,
    dealQuotesListRequest,
    dealQuotesPendingListRequest,
    dealReferRequest,
    dealRejectRequest,
    dealUpdateRequest,
    dealsBoardRequest,
    dealsListRequest,
    dealsSearchRequest,
} from '~Api/Deal/requests';
import UiSourceEnum from '~Deals/UiSourceEnum';
import history from '~history';
import { IFetchResponse } from '~utilities/fetch';
import { renderNotificationLoadingIcon } from '~utilities/utils';
import {
    ILeadAddAction,
    ILeadBrokerSearchAction,
    ILeadGetAction,
    ILeadInitialCallAction,
    ILeadQuoteCreateApplicationAction,
    ILeadQuoteSendAction,
    ILeadQuotesAddAction,
    ILeadQuotesListAction,
    ILeadReferAction,
    ILeadRejectAction,
    ILeadSendAction,
    ILeadValueSetAction,
    ILeadsBoardListAction,
    ILeadsListAction,
    ILeadsMovementReportListAction,
    ILeadsOriginationReportListAction,
    ILeadsPendingQuotesListAction,
    ILeadsSearchAction,
    leadBrokerSearchResultsSetAction,
    leadQuoteSetAction,
    leadQuotesSetAction,
    leadSendAction,
    leadSetAction,
    leadsBoardSetAction,
    leadsMovementReportSetAction,
    leadsOriginationReportSetAction,
    leadsPendingQuotesSetAction,
    leadsSearchResultsSetAction,
    leadsSetAction,
} from './actions';
import LeadsActionsEnum from './ActionsEnum';
import {
    leadQuotesSelector,
    leadSelector,
} from './selectors';
import { brokersSearchRequest } from '~Api/Broker/requests';
import IBroker from '~Api/Broker/IBroker';
import { parseBroker } from '~Api/Broker/parsers';

function* leadsList(action: ILeadsListAction): Iterator<unknown> {
    const dealsListResponse: IFetchResponse = yield call(dealsListRequest, action.page, action.perPage, action.orderBy, action.order, action.administratorUuids, action.approvalStatuses, action.name);
    const leadsTotalCount: number = parseInt(dealsListResponse.headers.get('X-Pagination-Total-Records'), 10);
    const deals: IDeal[] = yield Promise.all(dealsListResponse.body.map(parseDeal));

    yield put(leadsSetAction(deals, leadsTotalCount, action.page, action.perPage));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* leadsBoardList(action: ILeadsBoardListAction): Iterator<unknown> {
    const leadsBoardResponse: IFetchResponse = yield call(dealsBoardRequest);
    const deals: IDeal[] = yield Promise.all(leadsBoardResponse.body.map(parseDeal));
    yield put(leadsBoardSetAction(deals));
}

function* leadsOriginationReportList(action: ILeadsOriginationReportListAction): Iterator<unknown> {
    const rawOriginationReportLeads: IFetchResponse = yield call(dealOriginationReportRequest, action.startDate, action.endDate);
    const originationReportLeads: ILeadOriginated[] = yield Promise.all(rawOriginationReportLeads.body.map(parseOriginationReportLead));
    yield put(leadsOriginationReportSetAction(originationReportLeads));
}

function* leadsMovementReportList(action: ILeadsMovementReportListAction): Iterator<unknown> {
    const rawMovementReportLeads: IFetchResponse = yield call(dealMovementReportRequest, action.startTime, action.endTime);
    const movementReportLeads: IMovementReportLead[] = yield Promise.all(rawMovementReportLeads.body.map(parseMovementReportLead));
    yield put(leadsMovementReportSetAction(movementReportLeads));
}

function* leadsPendingQuotesList(action: ILeadsPendingQuotesListAction): Iterator<unknown> {
    const dealsPendingQuotesResponse: IFetchResponse = yield call(dealQuotesPendingListRequest);
    const pendingQuotes: IQuote[] = yield Promise.all(dealsPendingQuotesResponse.body.map(parseDealQuote));
    yield put(leadsPendingQuotesSetAction(pendingQuotes));
} 

function* leadsSearch(action: ILeadsSearchAction): Iterator<unknown> {
    const dealsSearchResponse: IFetchResponse = yield call(dealsSearchRequest, action.keyword);
    const deals: IDeal[] = yield Promise.all(dealsSearchResponse.body.map(parseDeal));
    yield put(leadsSearchResultsSetAction(deals));
}

function* leadAdd(action: ILeadAddAction): Iterator<unknown> {
    const rawLead: IFetchResponse = yield call(dealAddRequest, { ...action.deal, uiSource: UiSourceEnum.AdminArea });
    const deal: IDeal = parseDeal(rawLead.body);
    yield put(leadSetAction(deal));

    history.push(`/leads/${deal.uuid}/properties`);
}

function* leadRefer(action: ILeadReferAction): Iterator<unknown> {
    const key: string = `leadRefer ${action.dealUuid}`;

    notification.open({
        description: 'Referring lead...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Refer Lead',
    });

    yield call(dealReferRequest, action.dealUuid, action.referredTo, action.sendEmail);

    notification.success({
        description: 'The lead has been referred.',
        duration: 4.5,
        key,
        message: 'Refer Lead',
    });
}

function* leadReject(action: ILeadRejectAction): Iterator<unknown> {
    const key: string = `leadReject ${action.dealUuid}`;

    notification.open({
        description: 'Rejecting lead',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Reject Lead',
    });

    yield call(dealRejectRequest, action.dealUuid, action.reason, action.sendEmail);

    notification.success({
        description: 'The lead has been rejected.',
        duration: 4.5,
        key,
        message: 'Reject Lead',
    });
}

function* leadInitialCall(action: ILeadInitialCallAction): Iterator<unknown> {
    const key: string = `leadInitialCall ${action.dealUuid}`;

    notification.open({
        description: 'Updating lead...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Update Lead',
    });

    yield call(dealInitialCallRequest, action.dealUuid);

    notification.success({
        description: 'The lead has been updated.',
        duration: 4.5,
        key,
        message: 'Update Lead',
    });
}

function* leadGet(action: ILeadGetAction): Iterator<unknown> {
    const dealGetResponse: IFetchResponse = yield call(dealGetRequest, action.dealUuid);
    const deal: IDeal = yield parseDeal(dealGetResponse.body);
    yield put(leadSetAction(deal));
}

function* leadSend(action: ILeadSendAction): Iterator<unknown> {
    const deal: IDeal = yield select(leadSelector, action.dealUuid);
    yield call(dealUpdateRequest, deal);
}

function* leadValueSet(action: ILeadValueSetAction): Iterator<unknown> {
    yield put(leadSendAction(action.dealUuid));
}

function* leadBrokerSearch(action: ILeadBrokerSearchAction): Iterator<unknown> {
    if (action.keyword) {
        const brokerSearchResponse: IFetchResponse = yield call(brokersSearchRequest, action.keyword);
        const brokers: IBroker[] = yield Promise.all(brokerSearchResponse.body.map(parseBroker));
        yield put(leadBrokerSearchResultsSetAction(action.keyword, brokers));
    }
}

function* leadQuotesList(action: ILeadQuotesListAction): Iterator<unknown> {
    const rawQuotes: IFetchResponse = yield call(dealQuotesListRequest, action.dealUuid);
    const quotes: IQuote[] = yield Promise.all(rawQuotes.body.map(parseDealQuote));
    yield put(leadQuotesSetAction(action.dealUuid, quotes));
}

function* leadQuotesAdd(action: ILeadQuotesAddAction): Iterator<unknown> {
    const key: string = `leadQuotesAdd ${dayjs().format()}`;

    notification.open({
        description: 'Creating quote...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Quote',
    });

    const currentAdministrator: IAdministrator = yield select(currentAdministratorSelector);

    const preQuote: IQuote = action.quote;

    preQuote.administratorUuid = currentAdministrator.uuid;
    preQuote.createdTime = dayjs().format();

    const dealQuotesAddResponse: IFetchResponse = yield call(dealQuotesAddRequest, action.dealUuid, preQuote, action.sendEmail, action.isScenario);
    if (dealQuotesAddResponse.status === 422) {
        notification.error({
            description: `There was a problem creating the quote: ${_.values(dealQuotesAddResponse.body)[0]}.`,
            duration: 0,
            key,
            message: 'Quote',
        });
    } else {
        const quote: IQuote = parseDealQuote(dealQuotesAddResponse.body);

        const quotes: IQuote[] = yield select(leadQuotesSelector, action.dealUuid);

        if (quotes) {
            yield put(leadQuoteSetAction(action.dealUuid, quote));
        }

        const deal: IDeal = yield select(leadSelector, action.dealUuid);

        deal.lastQuoteTime = dayjs().format();
        deal.approvalStatus = ApprovalStatusEnum.Quote;

        yield put(leadSetAction(deal));

        history.push(`/leads/${deal.uuid}/quotes`);

        notification.success({
            description: 'The quote has been created.',
            duration: 4.5,
            key,
            message: 'Quote',
        });
    }
}

function* leadQuoteCreateApplication(action: ILeadQuoteCreateApplicationAction): Iterator<unknown> {
    const key: string = `leadQuoteCreateApplication ${action.quoteUuid}`;

    notification.open({
        description: 'Creating application...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Create Application',
    });

    const rawLead: IFetchResponse = yield call(dealQuoteCreateApplicationRequest, action.quoteUuid);
    const deal: IDeal = parseDeal(rawLead.body);

    yield put(leadSetAction(deal));

    notification.success({
        description: 'The application has been created.',
        duration: 4.5,
        key,
        message: 'Create Application',
    });
}

function* leadQuoteSend(action: ILeadQuoteSendAction): Iterator<unknown> {
    const key: string = `leadQuoteSend ${action.quoteUuid}`;

    notification.open({
        description: 'Sending quote...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Send Quote',
    });

    yield call(dealQuoteSendRequest, action.quoteUuid, action.email, action.firstName, action.lastName);

    notification.success({
        description: 'The quote has been sent.',
        duration: 4.5,
        key,
        message: 'Send Quote',
    });
}

export function* LeadsSagas(): Iterator<unknown> {
    yield all([
        takeEvery(LeadsActionsEnum.LeadsList, leadsList),
        takeEvery(LeadsActionsEnum.LeadsBoardList, leadsBoardList),

        takeEvery(LeadsActionsEnum.LeadsOriginationReportList, leadsOriginationReportList),
        takeEvery(LeadsActionsEnum.LeadsMovementReportList, leadsMovementReportList),

        takeEvery(LeadsActionsEnum.LeadsPendingQuotesList, leadsPendingQuotesList),

        debounce(200, LeadsActionsEnum.LeadsSearch, leadsSearch),

        takeEvery(LeadsActionsEnum.LeadAdd, leadAdd),
        takeEvery(LeadsActionsEnum.LeadGet, leadGet),
        takeEvery(LeadsActionsEnum.LeadInitialCall, leadInitialCall),
        takeEvery(LeadsActionsEnum.LeadRefer, leadRefer),
        takeEvery(LeadsActionsEnum.LeadReject, leadReject),
        debounce(500, LeadsActionsEnum.LeadSend, leadSend),
        takeEvery(LeadsActionsEnum.LeadValueSet, leadValueSet),

        debounce(200, LeadsActionsEnum.LeadBrokerSearch, leadBrokerSearch),

        takeEvery(LeadsActionsEnum.LeadQuotesAdd, leadQuotesAdd),
        takeEvery(LeadsActionsEnum.LeadQuotesList, leadQuotesList),

        takeEvery(LeadsActionsEnum.LeadQuoteCreateApplication, leadQuoteCreateApplication),
        takeEvery(LeadsActionsEnum.LeadQuoteSend, leadQuoteSend),
    ]);
}
