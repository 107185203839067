import { Checkbox, Form, Input, Modal, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { aggregatorsListAction } from '~Aggregators/actions';
import IAggregator from '~Api/Aggregator/IAggregator';
import { aggregatorsSelector } from '~Aggregators/selectors';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import StateEnum from '~Api/ReferralPartner/StateEnum';
import TypeEnum from '~Api/ReferralPartner/TypeEnum';
import { IGlobalState } from '~reducer';
import { referralPartnersAddAction } from './actions';
import './referralPartners.less';
import { IDictionary } from '~utilities/IDictionary';

interface IState {
    abn: string;
    acl: boolean;
    aggregatorUuid: string;
    banned: boolean;
    chargesFee: boolean;
    companyName: string;
    conductsBusinessInPerson: boolean;
    disclosesBenefits: boolean;
    email: string;
    firstName: string;
    groupName: string;
    includesCustomerInfo: boolean;
    lastName: string;
    linkedInUrl: string;
    nccp: boolean;
    phone: string;
    postcode: string;
    refersClient: boolean;
    state: StateEnum;
    streetAddress: string;
    suburb: string;
    type: TypeEnum;
}

interface IProps {
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsSelector {
    aggregators: IDictionary<IAggregator>;
}

interface IPropsDispatch {
    aggregatorsList: () => void;
    referralPartnerAdd: (referralPartner: IReferralPartner) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class AddModal extends React.Component<Props, IState> {
    public state: IState = {
        abn: null,
        acl: false,
        aggregatorUuid: null,
        banned: false,
        chargesFee: false,
        companyName: null,
        conductsBusinessInPerson: false,
        disclosesBenefits: false,
        email: null,
        firstName: null,
        groupName: null,
        includesCustomerInfo: false,
        lastName: null,
        linkedInUrl: null,
        nccp: false,
        phone: null,
        postcode: null,
        refersClient: false,
        state: null,
        streetAddress: null,
        suburb: null,
        type: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAbn = this.onChangeAbn.bind(this);
        this.onChangeAggregatorUuid = this.onChangeAggregatorUuid.bind(this);
        this.onChangeDisclosesBenefits = this.onChangeDisclosesBenefits.bind(this);
        this.onChangeIncludesCustomerInfo = this.onChangeIncludesCustomerInfo.bind(this);
        this.onChangeNccp = this.onChangeNccp.bind(this);
        this.onChangeRefersClient = this.onChangeRefersClient.bind(this);
        this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
        this.onChangeChargesFee = this.onChangeChargesFee.bind(this);
        this.onChangeConductsBusinessInPerson = this.onChangeConductsBusinessInPerson.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeGroupName = this.onChangeGroupName.bind(this);
        this.onChangeAcl = this.onChangeAcl.bind(this);
        this.onChangeBanned = this.onChangeBanned.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeLinkedInUrl = this.onChangeLinkedInUrl.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangePostcode = this.onChangePostcode.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeStreetAddress = this.onChangeStreetAddress.bind(this);
        this.onChangeSuburb = this.onChangeSuburb.bind(this);
        this.onChangeType = this.onChangeType.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public componentDidMount(): void {
        const { aggregators } = this.props;

        if (!aggregators) {
            this.props.aggregatorsList();
        }
    }

    public render(): JSX.Element {
        const { aggregators, isOpen } = this.props;
        const {
            abn,
            acl,
            aggregatorUuid,
            banned,
            chargesFee,
            companyName,
            conductsBusinessInPerson,
            disclosesBenefits,
            email,
            firstName,
            groupName,
            includesCustomerInfo,
            lastName,
            linkedInUrl,
            nccp,
            phone,
            postcode,
            refersClient,
            state,
            streetAddress,
            suburb,
            type,
        } = this.state;

        return (
            <Modal
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Referral Partner'
                wrapClassName='referral-partner-add-modal'
            >
                <Form.Item label='First Name' className='first-name'>
                    <Input onChange={this.onChangeFirstName} value={firstName} />
                </Form.Item>
                <Form.Item label='Last Name' className='last-name'>
                    <Input onChange={this.onChangeLastName} value={lastName} />
                </Form.Item>
                <Form.Item label='Phone' className='phone'>
                    <Input onChange={this.onChangePhone} value={phone} />
                </Form.Item>
                <Form.Item label='Email' className='email'>
                    <Input onChange={this.onChangeEmail} value={email} />
                </Form.Item>
                <Form.Item label='Street Address' className='street-address'>
                    <Input onChange={this.onChangeStreetAddress} value={streetAddress} />
                </Form.Item>
                <Form.Item label='Suburb' className='suburb'>
                    <Input onChange={this.onChangeSuburb} value={suburb} />
                </Form.Item>
                <Form.Item label='Postcode' className='postcode'>
                    <Input onChange={this.onChangePostcode} value={postcode} />
                </Form.Item>
                <Form.Item label='State' className='state'>
                    <Select onChange={this.onChangeState} value={state}>
                        <Select.Option value={StateEnum.AustralianCapitalTerritory}>ACT</Select.Option>
                        <Select.Option value={StateEnum.NewSouthWales}>NSW</Select.Option>
                        <Select.Option value={StateEnum.NorthernTerritory}>NT</Select.Option>
                        <Select.Option value={StateEnum.Queensland}>QLD</Select.Option>
                        <Select.Option value={StateEnum.SouthAustralia}>SA</Select.Option>
                        <Select.Option value={StateEnum.Tasmania}>TAS</Select.Option>
                        <Select.Option value={StateEnum.Victoria}>VIC</Select.Option>
                        <Select.Option value={StateEnum.WesternAustralia}>WA</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label='Type' className='type'>
                    <Select onChange={this.onChangeType} value={type}>
                        <Select.Option value={TypeEnum.Accountant}>Accountant</Select.Option>
                        <Select.Option value={TypeEnum.Leads}>Leads</Select.Option>
                        <Select.Option value={TypeEnum.Lender}>Lender</Select.Option>
                        <Select.Option value={TypeEnum.RealEstate}>Real Estate</Select.Option>
                        <Select.Option value={TypeEnum.Other}>Other</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label='Company Name' className='company-name'>
                    <Input onChange={this.onChangeCompanyName} value={companyName} />
                </Form.Item>
                <Form.Item label='ABN/ACN' className='abn'>
                    <Input maxLength={11} onChange={this.onChangeAbn} value={abn} />
                </Form.Item>
                <Form.Item label='Group Name' className='group-name'>
                    <Input onChange={this.onChangeGroupName} value={groupName} />
                </Form.Item>
                <Form.Item label='Aggregator' className='aggregator'>
                    <Select onChange={this.onChangeAggregatorUuid} value={aggregatorUuid}>
                        <Select.Option value={null}>None</Select.Option>
                        {_.sortBy(aggregators, ['name']).map((aggregator: IAggregator) => <Select.Option key={aggregator.uuid} value={aggregator.uuid}>{aggregator.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label='LinkedIn' className='linked-in-url'>
                    <Input onChange={this.onChangeLinkedInUrl} value={linkedInUrl} />
                </Form.Item>
                <Form.Item label='Holds ACL?' className='acl'>
                    <Checkbox onChange={this.onChangeAcl} checked={acl} />
                </Form.Item>
                <Form.Item label='Agrees to comply with NCCP?' className='nccp'>
                    <Checkbox onChange={this.onChangeNccp} checked={nccp} />
                </Form.Item>
                <Form.Item label='Has been banned?' className='banned'>
                    <Checkbox onChange={this.onChangeBanned} checked={banned} />
                </Form.Item>
                <Form.Item label='Charges the consumer a referral fee?' className='charges-fee'>
                    <Checkbox onChange={this.onChangeChargesFee} checked={chargesFee} />
                </Form.Item>
                <Form.Item label='Conducts business in person?' className='conducts-business-in-person'>
                    <Checkbox onChange={this.onChangeConductsBusinessInPerson} checked={conductsBusinessInPerson} />
                </Form.Item>

                <Form.Item label='Agrees to disclose benefits?' className='discloses-benefits'>
                    <Checkbox onChange={this.onChangeDisclosesBenefits} checked={disclosesBenefits} />
                </Form.Item>
                <Form.Item label='Agrees to refer the client to Funding?' className='refers-client'>
                    <Checkbox onChange={this.onChangeRefersClient} checked={refersClient} />
                </Form.Item>
                <Form.Item label='Agrees to provide customer details?' className='includes-customer-info'>
                    <Checkbox onChange={this.onChangeIncludesCustomerInfo} checked={includesCustomerInfo} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeAbn(event: React.ChangeEvent<HTMLInputElement>): void {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.setState({
            abn: event.target.value,
        });
    }

    private onChangeAggregatorUuid(value: string): void {
        this.setState({
            aggregatorUuid: value,
        });
    }

    private onChangeDisclosesBenefits(event: CheckboxChangeEvent): void {
        this.setState({
            disclosesBenefits: event.target.checked,
        });
    }

    private onChangeIncludesCustomerInfo(event: CheckboxChangeEvent): void {
        this.setState({
            includesCustomerInfo: event.target.checked,
        });
    }

    private onChangeNccp(event: CheckboxChangeEvent): void {
        this.setState({
            nccp: event.target.checked,
        });
    }

    private onChangeRefersClient(event: CheckboxChangeEvent): void {
        this.setState({
            refersClient: event.target.checked,
        });
    }

    private onChangeCompanyName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            companyName: event.target.value,
        });
    }

    private onChangeChargesFee(event: CheckboxChangeEvent): void {
        this.setState({
            chargesFee: event.target.checked,
        });
    }

    private onChangeConductsBusinessInPerson(event: CheckboxChangeEvent): void {
        this.setState({
            conductsBusinessInPerson: event.target.checked,
        });
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            email: event.target.value,
        });
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            firstName: event.target.value,
        });
    }

    private onChangeGroupName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            groupName: event.target.value,
        });
    }

    private onChangeAcl(event: CheckboxChangeEvent): void {
        this.setState({
            acl: event.target.checked,
        });
    }

    private onChangeBanned(event: CheckboxChangeEvent): void {
        this.setState({
            banned: event.target.checked,
        });
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            lastName: event.target.value,
        });
    }

    private onChangeLinkedInUrl(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            linkedInUrl: event.target.value,
        });
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            phone: event.target.value,
        });
    }

    private onChangePostcode(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            postcode: event.target.value,
        });
    }

    private onChangeState(value: StateEnum): void {
        this.setState({
            state: value as StateEnum,
        });
    }

    private onChangeStreetAddress(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            streetAddress: event.target.value,
        });
    }

    private onChangeSuburb(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            suburb: event.target.value,
        });
    }

    private onChangeType(value: TypeEnum): void {
        this.setState({
            type: value as TypeEnum,
        });
    }

    private onClickOk(): void {
        const {
            abn,
            acl,
            aggregatorUuid,
            banned,
            chargesFee,
            companyName,
            conductsBusinessInPerson,
            disclosesBenefits,
            email,
            firstName,
            groupName,
            includesCustomerInfo,
            lastName,
            linkedInUrl,
            nccp,
            phone,
            postcode,
            refersClient,
            state,
            streetAddress,
            suburb,
            type,
        } = this.state;

        const referralPartner: IReferralPartner = {
            abn,
            acl,
            aggregatorUuid,
            banned,
            chargesFee,
            companyName,
            conductsBusinessInPerson,
            disclosesBenefits,
            email,
            firstName,
            groupName,
            includesCustomerInfo,
            lastName,
            linkedInUrl,
            nccp,
            phone,
            postcode,
            refersClient,
            state,
            streetAddress,
            suburb,
            type,
        };

        this.props.referralPartnerAdd(referralPartner);
        this.props.onCancel();
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        aggregators: aggregatorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        aggregatorsList: () => dispatch(aggregatorsListAction()),
        referralPartnerAdd: (referralPartner: IReferralPartner) => dispatch(referralPartnersAddAction(referralPartner)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddModal);
