import { Button, Form, Modal, Select, Space, Spin } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import _ from 'lodash';
import React, { ReactElement, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import IBroker from '~Api/Broker/IBroker';
import IDeal from '~Api/Deal/IDeal';
import { longFormatName } from '~Brokers/utilities';
import {
    leadBrokerSearchAction,
    leadBrokerSearchResultsClearAction,
    leadValueSetAction,
} from '~Leads/actions';
import {
    leadBrokerSearchLoadingSelector,
    leadBrokerSearchResultsSelector,
} from '~Leads/selectors';

export default function BrokerSelector(props: {
    deal: IDeal;
}): ReactElement {
    const { deal } = props;

    const [ showModal, setShowModal ] = useState<boolean>(false);

    const brokerSearchLoading: boolean = useSelector(leadBrokerSearchLoadingSelector);
    const brokerSearchResults: IBroker[] = useSelector(leadBrokerSearchResultsSelector);

    const dispatch: Dispatch = useDispatch();

    const onSearchBroker: (search: string) => void = useCallback((search: string) => {
        dispatch(leadBrokerSearchAction(search));
    }, [
        dispatch,
    ]);

    const onChangeBroker: (value: string) => void = useCallback((value: string) => {
        dispatch(leadValueSetAction(deal.uuid, 'brokerUuid', value));
        dispatch(leadBrokerSearchResultsClearAction());
    }, [
        deal,
        dispatch,
    ]);

    const onClickBroker: () => void = useCallback(() => {
        setShowModal(true);
    }, []);

    const onClickOk: () => void = useCallback(() => {
        setShowModal(false);
    }, []);

    if (!deal) {
        return (
            <Spin size='small' />
        );
    }

    const brokerOptions: DefaultOptionType[] = _.map(brokerSearchResults, (broker: IBroker) => ({
        label: longFormatName(broker),
        value: broker.uuid,
    }));

    return (
        <>
            <a onClick={onClickBroker} >
                {deal.broker ? longFormatName(deal.broker) : (deal.brokerUuid ? 'Broker Data Missing' : 'None')}
            </a>
            <Modal
                footer={<Button className='close' onClick={onClickOk}>Close</Button>}
                okText='Update'
                onCancel={onClickOk}
                onOk={onClickOk}
                open={showModal}
                title='Change Broker'
                wrapClassName='deal-broker-change'
            >
                <Form.Item className='broker' label='Broker'>
                    <Space>
                        <Select
                            allowClear={true}
                            filterOption={false}
                            notFoundContent={null}
                            onChange={onChangeBroker}
                            onSearch={onSearchBroker}
                            options={brokerOptions}
                            placeholder='None'
                            showArrow={false}
                            showSearch={true}
                            value={deal.broker ? longFormatName(deal.broker) : deal.brokerUuid}
                        />
                        {brokerSearchLoading && <Spin/>}
                    </Space>
                </Form.Item>
            </Modal>
        </>
    );
}
