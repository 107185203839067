import { Form, Modal, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IInvestor from '~Api/Investor/IInvestor';
import {
    investorReferralsAddAction,
    investorsListAction,
} from '~Investors/actions';
import { investorsSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

interface IState {
    referralInvestorUuid: string;
}

interface IProps {
    investorUuid: string;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsSelector {
    investors: IDictionary<IInvestor>;
}

interface IPropsDispatch {
    investorsList: () => void;
    referralAdd: (referralInvestorUuid: string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class ReferralAddModal extends React.Component<Props, IState> {
    public state: IState = {
        referralInvestorUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeReferralInvestorId = this.onChangeReferralInvestorId.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public componentDidMount() {
        const { investors } = this.props;

        if (!investors) {
            this.props.investorsList();
        }
    }

    public render(): JSX.Element {
        const { investors, isOpen } = this.props;

        if (!investors) {
            return null;
        }

        return (
            <Modal
                destroyOnClose={true}
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Referral'
            >
                <Form.Item label='Investor' className='referralInvestorId'>
                    <Select filterOption={this.filterOption} onChange={this.onChangeReferralInvestorId} showSearch={true}>
                        {_.sortBy(_.values(investors), ['name']).map((investor: IInvestor) => <Select.Option key={investor.uuid} value={investor.uuid}>{investor.name}</Select.Option>)}
                    </Select>
                </Form.Item>
            </Modal>
        );
    }

    private filterOption(input: any, option: any): boolean {
        return option.children.toLocaleLowerCase().includes(input.toLocaleLowerCase());
    }

    private onChangeReferralInvestorId(value: string) {
        this.setState({
            referralInvestorUuid: value,
        });
    }

    private onClickOk() {
        const { referralInvestorUuid } = this.state;

        this.props.referralAdd(referralInvestorUuid);
        this.props.onCancel();
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        investors: investorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investorsList: () => dispatch(investorsListAction()),
        referralAdd: (referralInvestorUuid: string) => dispatch(investorReferralsAddAction(ownProps.investorUuid, referralInvestorUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReferralAddModal);
