import { Breadcrumb, Button, Layout, Modal, Spin, Typography } from 'antd';
import Table, { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IApplicationPropertyValuer from '~Api/Application/IApplicationPropertyValuer';
import { IDictionary } from '~utilities/IDictionary';
import {
    applicationPropertyValuerMarkPaidAction,
    applicationPropertyValuersUnpaidListAction,
} from './actions';
import { applicationPropertyValuersUnpaidSelector } from './selectors';
import { currencyFormatter } from '~utilities/formatters';

export default function UnpaidValuations(): ReactElement {
    const applicationPropertyValuers: IDictionary<IApplicationPropertyValuer> = useSelector(applicationPropertyValuersUnpaidSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!applicationPropertyValuers) {
            dispatch(applicationPropertyValuersUnpaidListAction());
        }
    }, []);

    if (!applicationPropertyValuers) {
        return (
            <Layout className='applications'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/applications'>Applications</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Unpaid Valuations</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Unpaid Valuations</Typography.Title>
                        <Spin />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    const columns: ColumnType<IApplicationPropertyValuer>[] = [
        {
            dataIndex: 'applicationProperty',
            render: (applicationProperty: IApplicationProperty) => <Link to={`/applications/${applicationProperty.application.uuid}`}>{applicationProperty.application.code}</Link>,
            title: 'Code',
            width: '10%',
        },
        {
            dataIndex: ['applicationProperty', 'application', 'formattedName'],
            title: 'Application Name',
            width: '20%',
        },
        {
            dataIndex: 'applicationProperty',
            render: (applicationProperty: IApplicationProperty) => <Link to={`/applications/${applicationProperty.application.uuid}/property-valuations/${applicationProperty.uuid}?`}>{applicationProperty.dealProperty.formattedAddress}</Link>,
            title: 'Property',
        },
        {
            dataIndex: ['valuer', 'name'],
            title: 'Valuer',
            width: '25%',
        },
        {
            dataIndex: 'quoteAmount',
            render: (quoteAmount: number) => quoteAmount ? currencyFormatter.format(quoteAmount) : '-',
            title: 'Quote',
            width: '10%',
        },
        {
            dataIndex: 'uuid',
            render: (uuid: string) => {
                function onClickMarkAsPaid(): void {
                    Modal.confirm({
                        content: 'Are you sure you want to mark this invoice as paid?',
                        okText: 'Mark as Paid',
                        onOk: () => {
                            dispatch(applicationPropertyValuerMarkPaidAction(uuid));
                        },
                        title: 'Mark as Paid',
                    });
                }

                return <Button type='primary' onClick={onClickMarkAsPaid}>Mark As Paid</Button>;
            },
            title: 'Actions',
            width: '15%',
        },
    ];

    return (
        <Layout className='applications'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/applications'>Applications</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Unpaid Valuations</Breadcrumb.Item>
            </Breadcrumb>
            <Layout className='content-wrapper'>
                <Layout.Content className='content'>
                    <Typography.Title level={2}>Unpaid Valuations</Typography.Title>
                    <Table
                        columns={columns}
                        dataSource={_.values(applicationPropertyValuers)}
                        rowKey='uuid'
                        size='middle'
                    />
                </Layout.Content>
            </Layout>
        </Layout>
    );
}
