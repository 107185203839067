import _ from 'lodash';
import IInvestmentTransaction from '~Api/Investment/IInvestmentTransaction';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import IInvestorAccountTransaction from '~Api/Investor/IInvestorAccountTransaction';
import ILoanTransaction from '~Api/Loan/ILoanTransaction';
import IWarehouseLoanTransaction from '~Api/Warehouse/IWarehouseLoanTransaction';
import { investorSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

export function investorMovementsSelector(state: IGlobalState): IDictionary<IInvestorAccountTransaction> {
    if (!state.accounts.investorMovements) {
        return null;
    }

    const investorMovements: IDictionary<IInvestorAccountTransaction> = {};
    _.each(state.accounts.investorMovements, (transaction: IInvestorAccountTransaction) => {
        investorMovements[transaction.uuid] = {
            ...transaction,
            investor: investorSelector(state, transaction.investorUuid),
        };
    });

    return investorMovements;
}

export function loanPaymentsSelector(state: IGlobalState): IDictionary<ILoanTransaction> {
    return state.accounts.loanPayments;
}

export function loanTransactionSelector(state: IGlobalState, loanTransactionUuid: string): ILoanTransaction {
    return state.accounts.loanPayments[loanTransactionUuid];
}

export function loanTransactionWarehouseLoanTransactionsSelector(state: IGlobalState, loanTransactionUuid: string): IDictionary<IWarehouseLoanTransaction> {
    return state.accounts.loanTransactionWarehouseLoanTransactions[loanTransactionUuid] && _.keyBy(state.accounts.loanTransactionWarehouseLoanTransactions[loanTransactionUuid], 'uuid');
}

export function paymentsFromInvestorsSelector(state: IGlobalState): IDictionary<IInvestorAccountInvestmentTransaction> {
    return state.accounts.paymentsFromInvestors;
}

export function paymentsToInvestorsSelector(state: IGlobalState): IDictionary<IInvestmentTransaction> {
    return state.accounts.paymentsToInvestors;
}

export function warehouseMovementsSelector(state: IGlobalState): IDictionary<IWarehouseLoanTransaction> {
    return state.accounts.warehouseMovements;
}
