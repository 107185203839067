import { Spin, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IHistory from '~Api/User/IHistory';
import IUser from '~Api/User/IUser';
import { IGlobalState } from '~reducer';
import { userGetAction, userHistoriesListAction } from '~Users/actions';
import { userHistoriesSelector, userSelector } from '~Users/selectors';
import Layout from './Layout';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    histories: IHistory[];
    user: IUser;
}

interface IPropsDispatch {
    historiesList: () => void;
    userGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class History extends React.Component<Props> {
    public componentDidMount() {
        const { user } = this.props;

        if (!user) {
            this.props.userGet();
        }

        this.props.historiesList();
    }

    public render(): JSX.Element {
        const { user, match, histories } = this.props;

        if (!user || !histories) {
            return (
                <Layout uuid={match.params.uuid} section='history'>
                    <Typography.Title level={2}>History</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const historiesBlock: JSX.Element[] = _.sortBy(_.filter(histories, (history: IHistory) => !!history.uuid), ['createdTime']).map((loopHistory: IHistory) => (
            <Timeline.Item key={loopHistory.uuid} label={dayjs(loopHistory.createdTime).format('YYYY-MM-DD - HH:mm')}>
                <Typography.Text>{loopHistory.description}</Typography.Text>
            </Timeline.Item>
        ));

        return (
            <Layout uuid={match.params.uuid} section='history'>
                <Typography.Title level={2}>History</Typography.Title>
                <Timeline mode='left' reverse={true}>
                    {historiesBlock}
                </Timeline>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        histories: userHistoriesSelector(state, ownProps.match.params.uuid),
        user: userSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        historiesList: () => dispatch(userHistoriesListAction(ownProps.match.params.uuid)),
        userGet: () => dispatch(userGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(History);
