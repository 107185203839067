import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IBorrower from '~Api/Application/IBorrower';
import ConditionDocumentApprovalStatusEnum from '~Api/Application/ConditionDocumentApprovalStatusEnum';
import ConditionTypeEnum from '~Api/Application/ConditionTypeEnum';
import IConditionDocument from '~Api/Application/IConditionDocument';
import { applicationConditionDocumentsAddAction } from '~Applications/actions';
import IApplicationProperty from '~Api/Application/IApplicationProperty';

interface IProps {
    application: IApplication;
    applicationBorrower?: IBorrower;
    applicationProperty?: IApplicationProperty;
    conditionUuid?: string;
    conditionType?: ConditionTypeEnum;
}

interface IPropsDispatch {
    applicationConditionDocumentsAdd: (file: File, conditionDocument: IConditionDocument) => void;
}

type Props = IProps & IPropsDispatch;

class UploadConditionDocument extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.beforeUpload = this.beforeUpload.bind(this);
    }

    public render(): JSX.Element {
        return (
            <Upload className='add-document' beforeUpload={this.beforeUpload} showUploadList={false}>
                <Button type='link'><UploadOutlined/></Button>
            </Upload>
        );
    }

    private beforeUpload(file: File): boolean {
        const {
            application,
            applicationBorrower,
            conditionUuid,
            conditionType,
            applicationProperty,
        } = this.props;

        const conditionDocument: IConditionDocument = {
            approvalStatus: ConditionDocumentApprovalStatusEnum.Pending,
            dealUuid: application.dealUuid,
        };

        if (applicationBorrower) {
            conditionDocument.conditionType = conditionType;
            conditionDocument.borrowerUuid = applicationBorrower.uuid;
        } else if (applicationProperty) {
            conditionDocument.conditionType = conditionType;
            conditionDocument.applicationPropertyUuid = applicationProperty.uuid;
            conditionDocument.dealPropertyUuid = applicationProperty.dealPropertyUuid;
        } else if (conditionUuid) {
            conditionDocument.conditionType = null;
            conditionDocument.conditionUuid = conditionUuid;
        } else {
            conditionDocument.conditionType = conditionType;
            conditionDocument.applicationUuid = application.uuid;
        }

        this.props.applicationConditionDocumentsAdd(file, conditionDocument);

        return false;
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationConditionDocumentsAdd: (file: File, conditionDocument: IConditionDocument) => dispatch(applicationConditionDocumentsAddAction(ownProps.application.uuid, file, conditionDocument)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(UploadConditionDocument);
