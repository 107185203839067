import INote from './INote';
import IAdviser from './IAdviser';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformAdviser(adviser: IAdviser): any {
    return {
        afslNumber: adviser.afslNumber,
        authorisedRepresentativeNumber: adviser.authorisedRepresentativeNumber,
        companyName: adviser.companyName,
        dealerGroup: adviser.dealerGroup,
        email: adviser.email,
        firstName: adviser.firstName,
        lastName: adviser.lastName,
        phone: adviser.phone,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformAdviserNote(note: INote): any {
    return {
        note: note.note,
    };
}
