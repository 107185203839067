import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Calculator from './Calculator';
import Documents from './Documents';
import Edit from './Edit';
import History from './History';
import Notes from './Notes';
import Overview from './Overview';
import Properties from './Properties';
import Quotes from './Quotes';

export default function router(): JSX.Element {
    return (
        <Switch>
            <Route exact={true} path='/leads/:dealUuid' component={Overview} />
            <Route exact={true} path='/leads/:dealUuid/calculator' component={Calculator} />
            <Route exact={true} path='/leads/:dealUuid/documents' component={Documents} />
            <Route exact={true} path='/leads/:dealUuid/edit' component={Edit} />
            <Route exact={true} path='/leads/:dealUuid/history' component={History} />
            <Route exact={true} path='/leads/:dealUuid/notes' component={Notes} />
            <Route exact={true} path='/leads/:dealUuid/properties' component={Properties} />
            <Route exact={true} path='/leads/:dealUuid/quotes' component={Quotes} />
        </Switch>
    );
}
