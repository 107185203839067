import { Spin, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IHistory from '~Api/Deal/IHistory';
import { IGlobalState } from '~reducer';
import { dealHistoriesListAction } from './actions';
import './deals.less';
import { dealHistoriesSelector } from './selectors';

interface IProps {
    dealUuid: string;
}

interface IPropsSelector {
    histories: IHistory[];
}

interface IPropsDispatch {
    historiesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class HistoryList extends React.Component<Props> {
    public componentDidMount() {
        this.props.historiesList();
    }

    public render(): JSX.Element {
        const { histories } = this.props;

        if (!histories) {
            return (
                <Spin/>
            );
        }

        const historiesBlock: JSX.Element[] = _.sortBy(_.filter(histories, (history: IHistory) => !!history.uuid), ['createdTime']).map((loopHistory: IHistory) => (
            <Timeline.Item key={loopHistory.uuid} label={dayjs(loopHistory.createdTime).format('YYYY-MM-DD - HH:mm')}>
                <Typography.Text>{loopHistory.description}</Typography.Text>
            </Timeline.Item>
        ));

        return (
            <div className='deal-history'>
                <Typography.Title level={2}>History</Typography.Title>
                <Timeline mode='left' reverse={true}>
                    {historiesBlock}
                </Timeline>
            </div>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        histories: dealHistoriesSelector(state, ownProps.dealUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        historiesList: () => dispatch(dealHistoriesListAction(ownProps.dealUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HistoryList);
