import { Button, Modal, Switch, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React, { ReactElement, useCallback } from 'react';
import { useSelector } from 'react-redux';
import DischargeInterestTypeEnum from '~Api/Application/DischargeInterestTypeEnum';
import ILoan from '~Api/Loan/ILoan';
import ILoanPayoutFigure from '~Api/Loan/ILoanPayoutFigure';
import LoanPayoutFigureAccruedInterestModeEnum from '~Api/Loan/LoanPayoutFigureAccruedInterestModeEnum';
import LoanPayoutFigureMinimumTermInterestModeEnum from '~Api/Loan/LoanPayoutFigureMinimumTermInterestModeEnum';
import { loanSelector } from '~Loans/selectors';
import { getLoanAppliedAccruedInterestTotal } from '~Loans/utilities';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';

interface IProps {
    isOpen: boolean;
    onChangeAccruedInterestMode: (accruedInterestMode: LoanPayoutFigureAccruedInterestModeEnum) => void;
    onChangeMinimumTermInterestMode: (minimumTermInterestMode: LoanPayoutFigureMinimumTermInterestModeEnum) => void;
    onClose: () => void;
    payoutFigure: ILoanPayoutFigure;
}

enum InterestTypeEnum {
    AccruedInterest = 'ACCRUED_INTEREST',
    AccruedInterestTotal = 'ACCRUED_INTEREST_TOTAL',
    MinimumTermInterest = 'MINIMUM_TERM_INTEREST',
}

interface IInterestCalculation {
    amount: number;
    children?: IInterestCalculation[];
    disabled?: boolean;
    enabled: boolean;
    interestType: InterestTypeEnum;
    name: string;
    onSwitch?: (enabled: boolean) => void;
    showSwitch: boolean;
}

// Labels for the different types of interest
const interestTypeLabels: IDictionary<string> = {
    [InterestTypeEnum.AccruedInterest]: 'Accrued Interest',
    [InterestTypeEnum.MinimumTermInterest]: 'Minimum Term Interest',
};

export default function PayoutFigureInterestCalculatorModal(props: IProps): ReactElement {
    const {
        isOpen,
        onChangeAccruedInterestMode,
        onChangeMinimumTermInterestMode,
        onClose,
        payoutFigure,
    } = props;

    const loan: ILoan = useSelector((state: IGlobalState) => loanSelector(state, payoutFigure.loanUuid));

    // Certain rows will have bold text
    const rowClassName: (interestCalculation: IInterestCalculation) => string = useCallback((interestCalculation: IInterestCalculation) => {
        if (interestCalculation.interestType === InterestTypeEnum.AccruedInterestTotal) {
            return 'row-header';
        }

        return '';
    }, []);

    const accruedInterestTotalCalculations: IInterestCalculation[] = [];

    if (payoutFigure.accruedInterestMode === LoanPayoutFigureAccruedInterestModeEnum.Auto) {
        accruedInterestTotalCalculations.push({
            amount: payoutFigure.accruedInterest,
            enabled: payoutFigure.accruedInterestMode === LoanPayoutFigureAccruedInterestModeEnum.Auto,
            interestType: InterestTypeEnum.AccruedInterest,
            name: interestTypeLabels[InterestTypeEnum.AccruedInterest],
            showSwitch: false,
        });
    }

    if (
        payoutFigure.accruedInterestMode === LoanPayoutFigureAccruedInterestModeEnum.Auto
        && (loan.application.dischargeInterestType === DischargeInterestTypeEnum.MinimumTerm)
    ) {
        accruedInterestTotalCalculations.push({
            amount: payoutFigure.minimumTermInterest,
            disabled: payoutFigure.dischargeDate && !loan.application.dischargeInterestType,
            enabled: payoutFigure.minimumTermInterestMode === LoanPayoutFigureMinimumTermInterestModeEnum.Auto,
            interestType: InterestTypeEnum.MinimumTermInterest,
            name: interestTypeLabels[InterestTypeEnum.MinimumTermInterest],
            onSwitch: (enabled: boolean) => {
                onChangeMinimumTermInterestMode(enabled ? LoanPayoutFigureMinimumTermInterestModeEnum.Auto : LoanPayoutFigureMinimumTermInterestModeEnum.Off);
            },
            showSwitch: true,
        });
    }

    const payoutFigureInterestCalculation: IInterestCalculation[] = [
        {
            amount: getLoanAppliedAccruedInterestTotal(payoutFigure),
            children: accruedInterestTotalCalculations,
            enabled: payoutFigure.accruedInterestMode === LoanPayoutFigureAccruedInterestModeEnum.Auto,
            interestType: InterestTypeEnum.AccruedInterestTotal,
            name: payoutFigure.accruedInterestLabel || `Remaining interest payable at the rate of ${percentageFormatter.format(loan.interestRate / 100)} p.a`,
            onSwitch: (enabled: boolean) => {
                onChangeAccruedInterestMode(enabled ? LoanPayoutFigureAccruedInterestModeEnum.Auto : LoanPayoutFigureAccruedInterestModeEnum.Off);
            },
            showSwitch: true,
        },
    ];

    // Define the columns for the interest calculation table
    const columns: ColumnType<IInterestCalculation>[] = [
        {
            render: (interestCalculation: IInterestCalculation) => {
                if (!interestCalculation.showSwitch) {
                    return;
                }

                return (
                    <Switch
                        onChange={interestCalculation.onSwitch}
                        checked={interestCalculation.enabled}
                        disabled={interestCalculation.disabled}
                        size='small'
                    />
                );
            },
            width: '5%',
        },
        {
            dataIndex: 'name',
            title: 'Interest',
        },
        {
            dataIndex: 'amount',
            render: (amount: number, interestCalculation: IInterestCalculation) => {
                if (!interestCalculation.enabled) {
                    return '-';
                }

                return currencyFormatter.format(amount);
            },
            title: 'Amount',
            width: '20%',
        },
    ];

    return (
        <Modal
            open={isOpen}
            title='Interest Calculator'
            width={950}
            onCancel={onClose}
            footer={<Button className='close' onClick={onClose}>Close</Button>}
            wrapClassName='loan-payout-figure-interest-calculator-modal'
        >
            <Table
                columns={columns}
                dataSource={payoutFigureInterestCalculation}
                pagination={false}
                rowKey='interestType'
                size='middle'
                expandable={{
                    defaultExpandAllRows: true,
                    expandIcon: () => null,
                    expandIconColumnIndex: 1,
                }}
                rowClassName={rowClassName}
            />
        </Modal>
    );
}
