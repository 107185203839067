import { Form, Modal, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IMonthlyStatement from '~Api/Investor/IMonthlyStatement';
import { investorAccountMonthlyStatementsGenerateAction } from '~Investors/actions';
import { investorAccountMonthlyStatementsSelector, investorAccountSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

const fundingStartYear: number = 2015;

interface IState {
    date: string;
    errorDate?: string;
}

interface IProps {
    accountUuid: string;
    onClose: () => void;
    isOpen: boolean;
}

interface IPropsSelector {
    investorAccount: IAccount;
    monthlyStatements: IDictionary<IMonthlyStatement>;
}

interface IPropsDispatch {
    generateStatement: (month: string, year: string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class MonthlyStatementGenerateModal extends React.Component<Props, IState> {
    public state: IState = {
        date: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeDate = this.onChangeDate.bind(this);
        this.onClickOk = this.onClickOk.bind(this);

        this.validateDate = this.validateDate.bind(this);
    }

    public render(): JSX.Element {
        const { investorAccount, monthlyStatements, isOpen } = this.props;
        const { date, errorDate } = this.state;

        const endDate: Dayjs = dayjs().subtract(1, 'month');
        const usedDates: string[] = _.map(monthlyStatements, (loopStatement: IMonthlyStatement) => `${loopStatement.year}-${loopStatement.month}`);

        const dateOptions: string[] = [];
        for (let year: number = fundingStartYear; year <= endDate.year(); year++) {
            for (let month: number = 0; month <= (year === endDate.year() ? endDate.month() : 11); month++) {
                // Don't include in options if a statement already exists or if it's an income trust account and it's before December 2023 when the new statement layout that included class was introduced
                if (usedDates.includes(`${year}-${month + 1}`) || (investorAccount.accountType === AccountTypeEnum.IncomeTrust && (year < 2023 || (year === 2023 && month !== 11)))) {
                    continue;
                }
                dateOptions.unshift(`${year}-${month + 1}`);
            }
        }

        return (
            <Modal
                destroyOnClose={true}
                okButtonProps={{ disabled: !!errorDate }}
                okText='Generate'
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title='Generate Monthly Statement'
                wrapClassName='investor-monthly-statement-generate-modal'
            >
                <Form.Item className='date' label='Date' help={errorDate} validateStatus={errorDate && 'error'}>
                    <Select onChange={this.onChangeDate} value={date} onBlur={this.validateDate}>
                        {dateOptions.map((loopDate: string) => <Select.Option key={`date${loopDate}`} value={`${loopDate}`}>{dayjs(loopDate, 'YYYY-M').format('MMM-YYYY')}</Select.Option>)}
                    </Select>
                </Form.Item>
            </Modal>
        );
    }

    private onChangeDate(value: string): void {
        this.setState({
            date: value,
        });
    }

    private onClickOk(): void {
        const { date } = this.state;

        let valid: boolean = true;

        valid = this.validateDate() && valid;

        if (!valid) {
            return;
        }

        const dateSplit: string[] = date.split('-');

        this.props.generateStatement(dateSplit[1], dateSplit[0]);
        this.props.onClose();
        this.setState({
            date: null,
        });
    }

    private validateDate(): boolean {
        const { monthlyStatements } = this.props;
        const { date } = this.state;

        let error: string = null;

        if (!date) {
            error = 'Please choose a month';
        } else {
            const dateSplit: string[] = date.split('-');
            const existingMonthlyStatement: IMonthlyStatement = _.find(monthlyStatements, (loopStatement: IMonthlyStatement) => {
                return dateSplit[0] === loopStatement.year && dateSplit[1] === loopStatement.month;
            });

            if (existingMonthlyStatement) {
                error = 'There is already a statement for that month';
            }
        }

        this.setState({
            errorDate: error,
        });

        return !error;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investorAccount: investorAccountSelector(state, ownProps.accountUuid),
        monthlyStatements: investorAccountMonthlyStatementsSelector(state, ownProps.accountUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        generateStatement: (month: string, year: string) => dispatch(investorAccountMonthlyStatementsGenerateAction(ownProps.accountUuid, month, year)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MonthlyStatementGenerateModal);
