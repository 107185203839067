import dayjs from 'dayjs';
import { parsePermissions } from '~Api/Administrator/parsers';
import { IGlobalState } from '~reducer';
import IAuthUser from './IAuthUser';
import RolesEnum from './RolesEnum';
import IToken from './IToken';
import { verifyToken } from '~utilities/jwt';
import { TokenExpiredError } from 'jsonwebtoken';

export function authJokeSelector(state: IGlobalState): string {
    return state.auth.joke;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function authUsernameSelector(state: IGlobalState): string {
    return localStorage.getItem('username');
}

export function authCurrentUserSelector(state: IGlobalState): IAuthUser {
    const decodedToken: IToken = authDecodedTokenSelector(state);

    if (decodedToken && decodedToken.administratorUuid) {
        return {
            permissions: parsePermissions(decodedToken.permissions),
            role: RolesEnum.Authenticated,
            uuid: decodedToken.administratorUuid,
        };
    }

    return {
        permissions: [],
        role: RolesEnum.Unauthenticated,
    };
}

export function authPreviousPathSelector(state: IGlobalState): string {
    return state.auth.previousPath;
}

function authDecodedTokenSelector(state: IGlobalState): IToken {
    let decodedToken: IToken = localStorage.getItem('decodedToken') && JSON.parse(localStorage.getItem('decodedToken'));

    if (!decodedToken) {
        const token: string = authTokenSelector(state);
        if (token) {
            try {
                decodedToken = verifyToken(token);
            } catch (e: unknown) {
                if (e instanceof TokenExpiredError) {
                    // Clean up the expired token
                    localStorage.removeItem('token');
                    localStorage.removeItem('decodedToken');
                    return null;
                }

                throw e;
            }
        }
    }

    if (decodedToken && dayjs(decodedToken.exp * 1000) < dayjs()) {
        // Clean up the expired token
        localStorage.removeItem('token');
        localStorage.removeItem('decodedToken');
        return null;
    }

    return decodedToken;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function authTokenSelector(state: IGlobalState): string {
    return localStorage.getItem('token');
}

export function authLoginErrorsSelector(state: IGlobalState): any {
    return state.auth.loginErrors;
}

export function authLoginInProgressSelector(state: IGlobalState): boolean {
    return state.auth.loginInProgress;
}
