import { SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Layout, Popover, Space, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import { administratorsActiveSelector, administratorsSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import { IGlobalState } from '~reducer';
import SearchDropdown from '~UI/SearchDropdown';
import { IDictionary } from '~utilities/IDictionary';
import { investorsListAction } from './actions';
import AccountTypeTag from './Investor/AccountTypeTag';
import ImSelector from './Investor/ImSelector';
import './investors.less';
import Search from './Search';
import { investorsSelector } from './selectors';
import accountTypeLabels from '~Api/Investor/accountTypeLabels';

interface IPropsSelector {
    activeAdministrators: IAdministrator[];
    administrators: IDictionary<IAdministrator>;
    investors: IDictionary<IInvestor>;
}

interface IPropsDispatch {
    administratorsList: () => void;
    investorsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class List extends React.Component<Props> {
    private refreshInterval: any;

    public componentDidMount(): void {
        const { administrators } = this.props;

        if (!administrators) {
            this.props.administratorsList();
        }

        this.props.investorsList();

        this.refreshInterval = setInterval(() => {
            this.props.investorsList();
        }, 5 * 60 * 1000);
    }

    public componentWillUnmount(): void {
        clearInterval(this.refreshInterval);
    }

    public render(): JSX.Element {
        const { activeAdministrators, administrators, investors } = this.props;

        if (!activeAdministrators || !administrators || !investors) {
            return (
                <Layout className='investors'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Investors</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Investors</Typography.Title>
                            <Spin />
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnType<IInvestor>[] = [
            {
                filterDropdown: (params: any) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string | number | boolean, investor: IInvestor) => investor.name.toLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                render: (investor: IInvestor) => {
                    return (
                        <React.Fragment>
                            <Link to={`/investors/${investor.uuid}`}>{investor.name || '-'}</Link>
                        </React.Fragment>
                    );
                },
                sorter: (a: IInvestor, b: IInvestor) => a.name.localeCompare(b.name),
                title: 'Name',
            },
            {
                filters: [
                    {
                        text: 'Marketplace',
                        value: AccountTypeEnum.Marketplace,
                    },
                    {
                        text: 'Income Trust',
                        value: AccountTypeEnum.IncomeTrust,
                    },
                ],
                onFilter: (value: string | number | boolean, investor: IInvestor): boolean => Object.keys(investor.accounts).includes(value as AccountTypeEnum),
                render: (investor: IInvestor) => {
                    return (
                        <React.Fragment>
                            {_.map(investor.accounts, (account: IAccount) => <AccountTypeTag key={account.uuid} account={account} />)}
                        </React.Fragment>
                    );
                },
                title: 'Account Type',
                width: '15%',
            },
            {
                filterDropdown: (params: any) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string | number | boolean, investor: IInvestor) => investor.accounts[AccountTypeEnum.Marketplace] && (investor.accounts[AccountTypeEnum.Marketplace].code || '').toLocaleLowerCase().includes(value.toLocaleString().toLowerCase()),
                render: (investor: IInvestor) => {
                    const account: IAccount = investor.accounts[AccountTypeEnum.Marketplace];
                    return account ? account.code : '';
                },
                title: 'Account ID',
                width: '10%',
            },
            {
                render: (investor: IInvestor) => {
                    const popoverContent: JSX.Element[] = _.map(investor.accounts, (account: IAccount) => <p>{accountTypeLabels[account.accountType]}: {currencyFormatter.format(account.balance)}</p>);
                    return investor.balanceTotal !== 0 ? <Space className='balance-total'>{currencyFormatter.format(investor.balanceTotal)} <Popover content={popoverContent}><Tag>∑</Tag></Popover></Space> : '';
                },
                sorter: (a: IInvestor, b: IInvestor) => a.balanceTotal > b.balanceTotal ? 1 : -1,
                title: 'Balance',
                width: '10%',
            },
            {
                render: (investor: IInvestor) => {
                    const popoverContent: JSX.Element[] = _.map(investor.accounts, (account: IAccount) => <p>{accountTypeLabels[account.accountType]}: {currencyFormatter.format(account.investedAmountCurrent)}</p>);
                    return investor.investedAmountCurrentTotal !== 0 ? <Space>{currencyFormatter.format(investor.investedAmountCurrentTotal)} <Popover content={popoverContent}><Tag>∑</Tag></Popover></Space> : '';
                },
                sorter: (a: IInvestor, b: IInvestor) => a.investedAmountCurrentTotal > b.investedAmountCurrentTotal ? 1 : -1,
                title: 'Invested',
                width: '10%',
            },
            {
                filters: _.map(_.filter(activeAdministrators, (administrator: IAdministrator) => [RoleEnum.InvestorManager, RoleEnum.SeniorInvestorManager].includes(administrator.role)), (administrator: IAdministrator) => ({
                    text: administrator.name,
                    value: administrator.uuid,
                })),
                onFilter: (value: string | number | boolean, investor: IInvestor) => investor.imUuid === value,
                render: (investor: IInvestor) => <ImSelector investor={investor} />,
                sorter: (a: IInvestor, b: IInvestor) => {
                    const aName = a.imUuid ? administrators[a.imUuid].name : '';
                    const bName = b.imUuid ? administrators[b.imUuid].name : '';

                    return aName.localeCompare(bName);
                },
                title: 'IM',
                width: '20%',
            },
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => createdTime ? <Tooltip title={dayjs(createdTime).format('Do MMMM YYYY HH:mm:ss')}><span>{dayjs(createdTime).fromNow()}</span></Tooltip> : '-',
                sorter: (a: IInvestor, b: IInvestor) => {
                    if (!b.createdTime) {
                        return 1;
                    }

                    if (!a.createdTime) {
                        return -1;
                    }

                    return dayjs(a.createdTime) > dayjs(b.createdTime) ? 1 : -1;
                },
                title: 'Created',
                width: '10%',
            },
        ];

        return (
            <Layout className='investors'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Investors</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Search />
                            <Link to='/investors'><Button>Board View</Button></Link>
                        </Space>
                        <Typography.Title level={2}>Investors</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.values(investors)}
                            pagination={{ defaultPageSize: 50 }}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        activeAdministrators: administratorsActiveSelector(state),
        administrators: administratorsSelector(state),
        investors: investorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        investorsList: () => dispatch(investorsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(List);
