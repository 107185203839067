import { BankOutlined, ClockCircleOutlined, FilterOutlined, PhoneOutlined } from '@ant-design/icons';
import { Card as AntCard, Spin, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { currentAdministratorSelector } from '~Administrators/selectors';
import { aggregatorsListAction } from '~Aggregators/actions';
import IAggregator from '~Api/Aggregator/IAggregator';
import { aggregatorsSelector } from '~Aggregators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import ApprovalStatusEnum from '~Api/Broker/ApprovalStatusEnum';
import IBroker from '~Api/Broker/IBroker';
import BdmSelector from './BdmSelector';
import NextContactModal from './NextContactModal';
import { IDictionary } from '~utilities/IDictionary';

interface IProps {
    broker: IBroker;
}

export default function Card(props: IProps): ReactElement {
    const { broker } = props;

    const aggregators: IDictionary<IAggregator> = useSelector(aggregatorsSelector);
    const currentAdministrator: IAdministrator = useSelector(currentAdministratorSelector);

    const [ isNextContactModalOpen, setIsNextContactModalOpen ] = useState<boolean>(false);
    const [ renderNextContactModal, setRenderNextContactModal ] = useState<boolean>(false);

    const dispatch: Dispatch = useDispatch();
    useEffect(() => {
        if (!aggregators) {
            dispatch(aggregatorsListAction());
        }
    }, []);

    const onClickNextContact: () => void = () => {
        setRenderNextContactModal(true);
        setIsNextContactModalOpen(true);
    };

    const onNextContactCancel: () => void = () => {
        setIsNextContactModalOpen(false);
    };

    let cardClassName: string = '';
    if (!broker.contactDateNext) {
        cardClassName = 'no-contact-date-next';
    } else if (dayjs(broker.contactDateNext).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) {
        cardClassName = 'contact-today';
    } else if (dayjs(broker.contactDateNext) < dayjs()) {
        cardClassName = 'contact-overdue';
    }

    const bdmSelectorBlock: JSX.Element = ![
        RoleEnum.BusinessDevelopmentManager,
        RoleEnum.SeniorBusinessDevelopmentManager,
    ].includes(currentAdministrator.role) && (
        <Tooltip className='bdm-selector' title='Assign BDM' placement='left'>
            <PhoneOutlined />
            <BdmSelector broker={broker} />
        </Tooltip>
    );

    const contactModalBlock: ReactElement = renderNextContactModal && (
        <NextContactModal
            broker={broker}
            isOpen={isNextContactModalOpen}
            onCancel={onNextContactCancel}
        />
    );

    return (
        <AntCard className={cardClassName} key={broker.uuid}>
            {broker.approvalStatus === ApprovalStatusEnum.Pending && <Tooltip className='pending' title='Pending Approval'><ClockCircleOutlined /></Tooltip>}
            <div className='code'>{broker.code}</div>
            <Link className='name' to={`/brokers/${broker.uuid}`}>{broker.firstName || '-'} {broker.lastName || '-'}</Link>
            {broker.companyName && <div className='company-name'><BankOutlined/> {broker.companyName}</div>}
            {broker.aggregatorUuid && <div className='aggregator'><FilterOutlined/> {aggregators ? aggregators[broker.aggregatorUuid].name : <Spin size='small' />}</div>}
            <div className='created-time'>Created {dayjs(broker.createdTime).fromNow()}</div>
            {bdmSelectorBlock}
            <a className='reschedule' onClick={onClickNextContact}>Reschedule</a>
            {contactModalBlock}
        </AntCard>
    );
}
