import { Checkbox, Form, Input, Modal, Select, Space, Spin, Tabs, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import {
    administratorsSelector,
    currentAdministratorSelector,
} from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import RoleEnum from '~Api/Administrator/RoleEnum';
import IBroker from '~Api/Broker/IBroker';
import IDeal from '~Api/Deal/IDeal';
import LoanPurposeEnum from '~Api/Deal/LoanPurposeEnum';
import MortgageTypeEnum from '~Api/Deal/MortgageTypeEnum';
import PhoneSourceEnum from '~Api/Deal/PhoneSourceEnum';
import RepaymentStrategyEnum from '~Api/Deal/RepaymentStrategyEnum';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import constants from '~constants';
import {
    dealEstablishmentFeeMinimumAction,
    dealInterestRateMinimumAction,
    dealLegalFeesDollarsMinimumAction,
    dealLvrMaximumAction,
} from '~Deals/actions';
import {
    leadBrokerSearchAction,
    leadBrokerSearchResultsClearAction,
    leadGetAction,
    leadValueSetAction,
} from '~Leads/actions';
import {
    leadBrokerSearchLoadingSelector,
    leadBrokerSearchResultsSelector,
    leadSelector,
} from '~Leads/selectors';
import { IGlobalState } from '~reducer';
import { referralPartnersListAction } from '~ReferralPartners/actions';
import { approvedReferralPartnersSelector } from '~ReferralPartners/selectors';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import { DefaultOptionType } from 'antd/lib/select';
import { longFormatName } from '~Brokers/utilities';

const loanPurposeLabels: IDictionary<string> = {
    [LoanPurposeEnum.BridgingLoan]: 'Bridging loan',
    [LoanPurposeEnum.Refinance]: 'Refinance an existing property',
    [LoanPurposeEnum.BusinessLoan]: 'Business loan',
    [LoanPurposeEnum.PersonalLoan]: 'Personal loan',
    [LoanPurposeEnum.RenovateOrBuild]: 'Renovate or build',
    [LoanPurposeEnum.PurchaseNew]: 'Purchase a new property',
    [LoanPurposeEnum.DebtConsolidation]: 'Debt consolidation',
    [LoanPurposeEnum.DevelopmentLoan]: 'Development loan',
};

interface IDefaultedValues {
    establishmentFeeDollarsMinimum: number;
    establishmentFeePercentageMinimum: number;
}

interface IState {
    dirtyFields: IDictionary<boolean>;
    establishmentFeeDollarsMinimum: number;
    establishmentFeePercentageMinimum: number;
    interestRateMinimum: number;
    legalFeesDollarsMinimum: number;
    lvrMaximum: number;
    showEstablishmentFeeMinimumModal: boolean;
    showInterestRateMinimumModal: boolean;
    showLegalFeesDollarsMinimumModal: boolean;
    showLvrMaximumModal: boolean;
}

interface IMatch {
    dealUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    administrators: IDictionary<IAdministrator>;
    brokerSearchLoading: boolean;
    brokerSearchResults: IBroker[];
    currentAdministrator: IAdministrator;
    currentUser: IAuthUser;
    deal: IDeal;
    referralPartners: IDictionary<IReferralPartner>;
}

interface IPropsDispatch {
    administratorsList: () => void;
    establishmentFeeMinimum: (dealUuid: string, establishmentFeeDollarsMinimum: number, establishmentFeePercentageMinimum: number) => void;
    interestRateMinimum: (dealUuid: string, interestRateMinimum: number) => void;
    leadBrokerSearch: (keyword: string) => void;
    leadBrokerSearchClear: () => void;
    leadGet: () => void;
    leadValueSet: (key: keyof IDeal, value: boolean|number|string) => void;
    legalFeesDollarsMinimum: (dealUuid: string, legalFeesDollarsMinimum: number) => void;
    lvrMaximum: (dealUuid: string, lvrMaximum: number) => void;
    referralPartnersList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Edit extends React.Component<Props, IState> {
    public state: IState = {
        dirtyFields: {},
        establishmentFeeDollarsMinimum: null,
        establishmentFeePercentageMinimum: null,
        interestRateMinimum: null,
        legalFeesDollarsMinimum: null,
        lvrMaximum: null,
        showEstablishmentFeeMinimumModal: false,
        showInterestRateMinimumModal: false,
        showLegalFeesDollarsMinimumModal: false,
        showLvrMaximumModal: false,
    };

    constructor(props: Props) {
        super(props);

        this.getDefaultedValues = this.getDefaultedValues.bind(this);

        this.onSearchBroker = this.onSearchBroker.bind(this);

        this.onChangeMortgageType = this.onChangeMortgageType.bind(this);
        this.onChangeLoanPurpose = this.onChangeLoanPurpose.bind(this);
        this.onChangeTermMonths = this.onChangeTermMonths.bind(this);
        this.onChangeRepaymentStrategy = this.onChangeRepaymentStrategy.bind(this);
        this.onChangeLoanAmount = this.onChangeLoanAmount.bind(this);
        this.onChangeBdm = this.onChangeBdm.bind(this);
        this.onChangeIsBroker = this.onChangeIsBroker.bind(this);
        this.onChangeIsReferralPartner = this.onChangeIsReferralPartner.bind(this);
        this.onChangeBroker = this.onChangeBroker.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeOtherSource = this.onChangeOtherSource.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangePhoneSource = this.onChangePhoneSource.bind(this);
        this.onChangeReferralPartner = this.onChangeReferralPartner.bind(this);
        this.onChangeReferralPartnerFirstName = this.onChangeReferralPartnerFirstName.bind(this);
        this.onChangeReferralPartnerLastName = this.onChangeReferralPartnerLastName.bind(this);
        this.onChangeReferralPartnerEmail = this.onChangeReferralPartnerEmail.bind(this);
        this.onChangeReferralPartnerPhone = this.onChangeReferralPartnerPhone.bind(this);
        this.onChangeReferralPartnerCompanyName = this.onChangeReferralPartnerCompanyName.bind(this);
        this.onChangeComments = this.onChangeComments.bind(this);

        this.onClickEstablishmentFeeMinimum = this.onClickEstablishmentFeeMinimum.bind(this);
        this.onCancelEstablishmentFeeMinimum = this.onCancelEstablishmentFeeMinimum.bind(this);
        this.onClickEstablishmentFeeMinimumOk = this.onClickEstablishmentFeeMinimumOk.bind(this);

        this.onChangeEstablishmentFeeDollarsMinimum = this.onChangeEstablishmentFeeDollarsMinimum.bind(this);
        this.onChangeEstablishmentFeePercentageMinimum = this.onChangeEstablishmentFeePercentageMinimum.bind(this);

        this.onClickInterestRateMinimum = this.onClickInterestRateMinimum.bind(this);
        this.onChangeInterestRateMinimum = this.onChangeInterestRateMinimum.bind(this);
        this.onCancelInterestRateMinimum = this.onCancelInterestRateMinimum.bind(this);
        this.onClickInterestRateMinimumOk = this.onClickInterestRateMinimumOk.bind(this);

        this.onClickLegalFeesDollarsMinimum = this.onClickLegalFeesDollarsMinimum.bind(this);
        this.onChangeLegalFeesDollarsMinimum = this.onChangeLegalFeesDollarsMinimum.bind(this);
        this.onCancelLegalFeesDollarsMinimum = this.onCancelLegalFeesDollarsMinimum.bind(this);
        this.onClickLegalFeesDollarsMinimumOk = this.onClickLegalFeesDollarsMinimumOk.bind(this);

        this.onClickLvrMaximum = this.onClickLvrMaximum.bind(this);
        this.onChangeLvrMaximum = this.onChangeLvrMaximum.bind(this);
        this.onCancelLvrMaximum = this.onCancelLvrMaximum.bind(this);
        this.onClickLvrMaximumOk = this.onClickLvrMaximumOk.bind(this);
    }

    public componentDidMount() {
        const { deal } = this.props;

        this.props.administratorsList();
        this.props.referralPartnersList();

        if (!deal) {
            this.props.leadGet();
        }
    }

    public render(): JSX.Element {
        const {
            administrators,
            brokerSearchLoading,
            brokerSearchResults,
            currentAdministrator,
            currentUser,
            deal,
            match,
            referralPartners,
        } = this.props;
        const {
            interestRateMinimum,
            legalFeesDollarsMinimum,
            lvrMaximum,
            showEstablishmentFeeMinimumModal,
            showInterestRateMinimumModal,
            showLegalFeesDollarsMinimumModal,
            showLvrMaximumModal,
        } = this.state;

        if (!administrators || !currentAdministrator || !deal || !referralPartners) {
            return (
                <Layout dealUuid={match.params.dealUuid} section='edit'>
                    <Spin/>
                </Layout>
            );
        }

        const {
            establishmentFeeDollarsMinimum,
            establishmentFeePercentageMinimum,
        } = this.getDefaultedValues();

        const bdmBlock: JSX.Element = ![RoleEnum.BusinessDevelopmentManager, RoleEnum.InternalBusinessDevelopmentManager, RoleEnum.SeniorBusinessDevelopmentManager].includes(currentAdministrator.role) && (
            <Form.Item label='BDM' className='bdm'>
                <Select onChange={this.onChangeBdm} value={deal.bdmUuid}>
                    <Select.Option value={null}>None</Select.Option>
                    {_.sortBy(_.filter(administrators, (admin: IAdministrator) => [RoleEnum.BusinessDevelopmentManager, RoleEnum.InternalBusinessDevelopmentManager, RoleEnum.SeniorBusinessDevelopmentManager].includes(admin.role)), ['name']).map((administrator: IAdministrator) => <Select.Option key={administrator.uuid} value={administrator.uuid}>{administrator.name}</Select.Option>)}
                </Select>
            </Form.Item>
        );

        const brokerOptions: DefaultOptionType[] = _.map(brokerSearchResults, (broker: IBroker) => ({
            label: longFormatName(broker),
            value: broker.uuid,
        }));
        const brokerBlock: JSX.Element = deal.isBroker && (
            <Form.Item label='Broker' className='broker'>
                <Space>
                    <Select
                        allowClear={true}
                        filterOption={false}
                        notFoundContent={null}
                        onChange={this.onChangeBroker}
                        onSearch={this.onSearchBroker}
                        options={brokerOptions}
                        placeholder='None'
                        showArrow={false}
                        showSearch={true}
                        value={deal.broker ? longFormatName(deal.broker) : deal.brokerUuid}
                    />
                    {brokerSearchLoading && <Spin/>}
                </Space>
            </Form.Item>
        );

        const otherSourceBlock: JSX.Element = deal.phoneSource && deal.phoneSource === PhoneSourceEnum.Other && (
            <Form.Item label='Other' className='other-source'>
                <Input onChange={this.onChangeOtherSource} maxLength={50} value={deal.otherSource} />
            </Form.Item>
        );

        const referralPartnerBlock: JSX.Element = deal.isReferralPartner && (
            <React.Fragment>
                <Form.Item label='Referred By' className='referral-partner'>
                    <Select
                        onChange={this.onChangeReferralPartner}
                        value={deal.referralPartnerUuid}
                    >
                        <Select.Option value={null}>None</Select.Option>
                        {_.values(referralPartners).map((referralPartner: IReferralPartner) => <Select.Option key={referralPartner.uuid} value={referralPartner.uuid}>{referralPartner.firstName + ' ' + referralPartner.lastName}{referralPartner.companyName ? ' ' + referralPartner.companyName : ''}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item hidden={!!deal.referralPartnerUuid} label='Referral Partner First Name' className='referral-partner-first-name'>
                    <Input onChange={this.onChangeReferralPartnerFirstName} value={deal.referralPartnerFirstName} />
                </Form.Item>
                <Form.Item hidden={!!deal.referralPartnerUuid} label='Referral Partner Last Name' className='referral-partner-last-name'>
                    <Input onChange={this.onChangeReferralPartnerLastName} value={deal.referralPartnerLastName} />
                </Form.Item>
                <Form.Item hidden={!!deal.referralPartnerUuid} label='Referral Partner Email' className='referral-partner-email'>
                    <Input onChange={this.onChangeReferralPartnerEmail} value={deal.referralPartnerEmail} />
                </Form.Item>
                <Form.Item hidden={!!deal.referralPartnerUuid} label='Referral Partner Phone' className='referral-partner-phone'>
                    <Input onChange={this.onChangeReferralPartnerPhone} value={deal.referralPartnerPhone} />
                </Form.Item>
                <Form.Item hidden={!!deal.referralPartnerUuid} label='Referral Partner Company' className='referral-partner-company-name'>
                    <Input onChange={this.onChangeReferralPartnerCompanyName} value={deal.referralPartnerCompanyName} />
                </Form.Item>
            </React.Fragment>
        );

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const establishmentFeeDollarsMinimumLimit: number = deal.establishmentFeeDollarsMinimumOverride ?? constants.DEAL_ESTABLISHMENT_FEE_DOLLARS_MINIMUM;
        const establishmentFeePercentageMinimumLimit: number = deal.establishmentFeePercentageMinimumOverride ?? constants.DEAL_ESTABLISHMENT_FEE_PERCENTAGE_MINIMUM;
        const interestRateMinimumLimit: number = deal.interestRateMinimumOverride || (deal.mortgageType === MortgageTypeEnum.SecondMortgage ? constants.DEAL_INTEREST_RATE_SECOND_MORTGAGE_MINIMUM : constants.DEAL_INTEREST_RATE_MINIMUM);
        const legalFeesDollarsMinimumLimit: number = deal.legalFeesDollarsMinimumOverride ?? constants.DEAL_LEGAL_FEES_DOLLARS_MINIMUM;
        const lvrMaximumLimit: number = deal.lvrMaximumOverride || constants.DEAL_LVR_MAXIMUM;

        let establishmentFeeMinimumLimit: string = currencyFormatter.format(establishmentFeeDollarsMinimumLimit) + ' or ' + percentageFormatter.format(establishmentFeePercentageMinimumLimit / 100);
        if (null !== deal.establishmentFeeDollarsMinimumOverride && null === deal.establishmentFeePercentageMinimumOverride) {
            establishmentFeeMinimumLimit = currencyFormatter.format(deal.establishmentFeeDollarsMinimumOverride);
        } else if (null !== deal.establishmentFeePercentageMinimumOverride && null === deal.establishmentFeeDollarsMinimumOverride) {
            establishmentFeeMinimumLimit = percentageFormatter.format(deal.establishmentFeePercentageMinimumOverride / 100);
        }

        return (
            <Layout dealUuid={match.params.dealUuid} section='edit'>
                <Typography.Title level={2}>Edit</Typography.Title>
                <Tabs defaultActiveKey='general'>
                    <Tabs.TabPane key='general' tab='General'>
                        <Form.Item label='Mortgage Type' className='mortgage-type'>
                            <Select onChange={this.onChangeMortgageType} value={deal.mortgageType}>
                                <Select.Option value={MortgageTypeEnum.FirstMortgage}>First Mortgage</Select.Option>
                                <Select.Option value={MortgageTypeEnum.SecondMortgage}>Second Mortgage</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label='Loan Purpose' className='loan-purpose'>
                            <Select onChange={this.onChangeLoanPurpose} value={deal.loanPurpose}>
                                {_.map(loanPurposeLabels, (loanPurposeLabel: string, key: LoanPurposeEnum) => <Select.Option value={key}>{loanPurposeLabel}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label='Loan Term' className='term-months'>
                            <Select onChange={this.onChangeTermMonths} value={deal.termMonths}>
                                {deal.termMonths === null && <Select.Option value={null}>None</Select.Option>}
                                {_.times(36, (months: number) => (<Select.Option key={months + 1} value={months + 1}>{months + 1} months</Select.Option>))}
                            </Select>
                        </Form.Item>
                        <Form.Item label='Repayment Strategy' className='repayment-strategy'>
                            <Select onChange={this.onChangeRepaymentStrategy} value={deal.repaymentStrategy}>
                                <Select.Option value={RepaymentStrategyEnum.PropertySaleSold}>Sale of Property - Already Sold</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.PropertySaleNotSold}>Sale of Property - Not Yet Sold</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.RefinanceApproved}>Refinance - Already Approved</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.RefinanceNotApproved}>Refinance - Not Yet Approved</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.BusinessSaleSold}>Sale of Business - Already Sold</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.BusinessSaleNotSold}>Sale of Business - Not Yet Sold</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.BusinessCashFlow}>Business Cash Flow</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.Inheritance}>Inheritance</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.CourtSettlement}>Court Settlement / Compensation</Select.Option>
                                <Select.Option value={RepaymentStrategyEnum.Other}>Other</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label='Requested Payout Amount' className='loan-amount'>
                            <Input addonBefore='$' min={0} onChange={this.onChangeLoanAmount} type='number' value={deal.loanAmount} />
                        </Form.Item>
                        <Form.Item label='Is Broker' className='is-broker'>
                            <Checkbox
                                checked={deal.isBroker}
                                disabled={!!deal.isReferralPartner}
                                onChange={this.onChangeIsBroker}
                            />
                        </Form.Item>
                        {brokerBlock}
                        {bdmBlock}
                        <Form.Item label='First Name' className='first-name'>
                            <Input onChange={this.onChangeFirstName} value={deal.firstName} />
                        </Form.Item>
                        <Form.Item label='Last Name' className='last-name'>
                            <Input onChange={this.onChangeLastName} value={deal.lastName} />
                        </Form.Item>
                        <Form.Item label='Email' className='email'>
                            <Input onChange={this.onChangeEmail} value={deal.email} />
                        </Form.Item>
                        <Form.Item label='Phone' className='phone'>
                            <Input onChange={this.onChangePhone} value={deal.phone} />
                        </Form.Item>
                        <Form.Item label='Lead Source' className='phone-source'>
                            <Select onChange={this.onChangePhoneSource} value={deal.phoneSource}>
                                <Select.Option value={PhoneSourceEnum.Bing}>Bing</Select.Option>
                                <Select.Option value={PhoneSourceEnum.Facebook}>Facebook</Select.Option>
                                <Select.Option value={PhoneSourceEnum.FacebookAd}>Facebook Ad</Select.Option>
                                <Select.Option value={PhoneSourceEnum.Google}>Google</Select.Option>
                                <Select.Option value={PhoneSourceEnum.GoogleAd}>Google Ad</Select.Option>
                                <Select.Option value={PhoneSourceEnum.LinkedIn}>LinkedIn</Select.Option>
                                <Select.Option value={PhoneSourceEnum.ClientReferral}>Client Referral</Select.Option>
                                <Select.Option value={PhoneSourceEnum.EmailSubscriber}>Email Subscriber</Select.Option>
                                <Select.Option value={PhoneSourceEnum.Other}>Other</Select.Option>
                            </Select>
                        </Form.Item>
                        {otherSourceBlock}
                        <Form.Item label='Is Referral Partner' className='is-referral-partner'>
                            <Checkbox
                                checked={deal.isReferralPartner}
                                disabled={!!deal.isBroker}
                                onChange={this.onChangeIsReferralPartner}
                            />
                        </Form.Item>
                        {referralPartnerBlock}
                        <Form.Item label='Comments' className='comments'>
                            <TextArea onChange={this.onChangeComments} rows={15} value={deal.comments} />
                        </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane key='limits' tab='Limits'>
                        <Form.Item className='establishment-fee-minimum' label='Establishment Fee Minimum'>
                            {!currentUser.permissions.includes(PermissionsEnum.DealLimitsOverride) && establishmentFeeMinimumLimit}
                            {currentUser.permissions.includes(PermissionsEnum.DealLimitsOverride) && <a onClick={this.onClickEstablishmentFeeMinimum}>{establishmentFeeMinimumLimit}</a>}
                        </Form.Item>
                        <Form.Item className='interest-rate-minimum-limit' label='Interest Rate Minimum'>
                            {!currentUser.permissions.includes(PermissionsEnum.DealLimitsOverride) && percentageFormatter.format(interestRateMinimumLimit / 100)}
                            {currentUser.permissions.includes(PermissionsEnum.DealLimitsOverride) && <a onClick={this.onClickInterestRateMinimum}>{percentageFormatter.format(interestRateMinimumLimit / 100)}</a>}
                        </Form.Item>
                        <Form.Item className='legal-fee-minimum' label='Legal Fee Minimum'>
                            {!currentUser.permissions.includes(PermissionsEnum.DealLimitsOverride) && currencyFormatter.format(legalFeesDollarsMinimumLimit)}
                            {currentUser.permissions.includes(PermissionsEnum.DealLimitsOverride) && <a onClick={this.onClickLegalFeesDollarsMinimum}>{currencyFormatter.format(legalFeesDollarsMinimumLimit)}</a>}
                        </Form.Item>
                        <Form.Item className='lvr-maximum-limit' label='LVR Maximum'>
                            {!currentUser.permissions.includes(PermissionsEnum.DealLimitsOverride) && percentageFormatter.format(lvrMaximumLimit / 100)}
                            {currentUser.permissions.includes(PermissionsEnum.DealLimitsOverride) && <a onClick={this.onClickLvrMaximum}>{percentageFormatter.format(lvrMaximumLimit / 100)}</a>}
                        </Form.Item>
                    </Tabs.TabPane>
                </Tabs>

                <Modal
                    onCancel={this.onCancelEstablishmentFeeMinimum}
                    onOk={this.onClickEstablishmentFeeMinimumOk}
                    open={showEstablishmentFeeMinimumModal}
                    title='Override Limit'
                    wrapClassName='deal-establishment-fee-minimum-modal'
                >
                    <Form.Item className='establishment-fee-dollars-minimum' label='Establishment Fee Dollars Minimum'>
                        <Input addonAfter='$' onChange={this.onChangeEstablishmentFeeDollarsMinimum} type='number' value={establishmentFeeDollarsMinimum} />
                    </Form.Item>
                    <Form.Item className='establishment-fee-percentage-minimum' label='Establishment Fee Percentage Minimum'>
                        <Input addonAfter='%' onChange={this.onChangeEstablishmentFeePercentageMinimum} type='number' value={establishmentFeePercentageMinimum} />
                    </Form.Item>
                </Modal>

                <Modal
                    onCancel={this.onCancelInterestRateMinimum}
                    onOk={this.onClickInterestRateMinimumOk}
                    open={showInterestRateMinimumModal}
                    title='Override Limit'
                    wrapClassName='deal-interest-rate-minimum-modal'
                >
                    <Form.Item className='interest-rate-minimum' label='Interest Rate Minimum'>
                        <Input addonAfter='%' onChange={this.onChangeInterestRateMinimum} type='number' value={interestRateMinimum} />
                    </Form.Item>
                </Modal>

                <Modal
                    onCancel={this.onCancelLegalFeesDollarsMinimum}
                    onOk={this.onClickLegalFeesDollarsMinimumOk}
                    open={showLegalFeesDollarsMinimumModal}
                    title='Override Limit'
                    wrapClassName='deal-legal-fees-dollars-minimum-modal'
                >
                    <Form.Item className='legal-fees-dollars-minimum' label='Legal Fees Minimum'>
                        <Input addonBefore='$' onChange={this.onChangeLegalFeesDollarsMinimum} type='number' value={legalFeesDollarsMinimum} />
                    </Form.Item>
                </Modal>

                <Modal
                    onCancel={this.onCancelLvrMaximum}
                    onOk={this.onClickLvrMaximumOk}
                    open={showLvrMaximumModal}
                    title='Override Limit'
                    wrapClassName='deal-lvr-maximum-modal'
                >
                    <Form.Item className='lvr-maximum' label='LVR Maximum'>
                        <Input addonAfter='%' onChange={this.onChangeLvrMaximum} type='number' value={lvrMaximum} />
                    </Form.Item>
                </Modal>
            </Layout>
        );
    }

    private getDefaultedValues(): IDefaultedValues {
        const { deal } = this.props;
        const { dirtyFields, establishmentFeeDollarsMinimum, establishmentFeePercentageMinimum } = this.state;

        return {
            establishmentFeeDollarsMinimum: dirtyFields.establishmentFeeDollarsMinimum ? establishmentFeeDollarsMinimum : deal.establishmentFeeDollarsMinimumOverride ?? constants.DEAL_ESTABLISHMENT_FEE_DOLLARS_MINIMUM,
            establishmentFeePercentageMinimum: dirtyFields.establishmentFeePercentageMinimum ? establishmentFeePercentageMinimum : deal.establishmentFeePercentageMinimumOverride ?? constants.DEAL_ESTABLISHMENT_FEE_PERCENTAGE_MINIMUM,
        };
    }

    private onSearchBroker(search: string): void {
        this.props.leadBrokerSearch(search);
    }

    private onChangeMortgageType(value: MortgageTypeEnum) {
        this.props.leadValueSet('mortgageType', value);
    }

    private onChangeLoanPurpose(value: LoanPurposeEnum) {
        this.props.leadValueSet('loanPurpose', value);
    }

    private onChangeTermMonths(value: number) {
        this.props.leadValueSet('termMonths', value);
    }

    private onChangeRepaymentStrategy(value: RepaymentStrategyEnum) {
        this.props.leadValueSet('repaymentStrategy', value);
    }

    private onChangeLoanAmount(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.leadValueSet('loanAmount', Number(event.target.value));
    }

    private onChangeBdm(value: string) {
        this.props.leadValueSet('bdmUuid', value);
    }

    private onChangeIsBroker(event: CheckboxChangeEvent) {
        this.props.leadValueSet('isBroker', event.target.checked);
    }

    private onChangeIsReferralPartner(event: CheckboxChangeEvent) {
        this.props.leadValueSet('isReferralPartner', event.target.checked);
    }

    private onChangeBroker(value: string) {
        this.props.leadValueSet('brokerUuid', value);
        this.props.leadBrokerSearchClear();
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.leadValueSet('firstName', event.target.value);
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.leadValueSet('lastName', event.target.value);
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.leadValueSet('email', event.target.value);
    }

    private onChangeOtherSource(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.leadValueSet('otherSource', event.target.value.substr(0, 50));
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.leadValueSet('phone', event.target.value);
    }

    private onChangePhoneSource(value: PhoneSourceEnum) {
        this.props.leadValueSet('phoneSource', value);
    }

    private onChangeReferralPartner(value: string): void {
        this.props.leadValueSet('referralPartnerUuid', value);
    }

    private onChangeReferralPartnerFirstName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.leadValueSet('referralPartnerFirstName', event.target.value);
    }

    private onChangeReferralPartnerLastName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.leadValueSet('referralPartnerLastName', event.target.value);
    }

    private onChangeReferralPartnerEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.leadValueSet('referralPartnerEmail', event.target.value);
    }

    private onChangeReferralPartnerPhone(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.leadValueSet('referralPartnerPhone', event.target.value);
    }

    private onChangeReferralPartnerCompanyName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.leadValueSet('referralPartnerCompanyName', event.target.value);
    }

    private onChangeComments(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.props.leadValueSet('comments', event.target.value);
    }

    private onClickEstablishmentFeeMinimum() {
        this.setState({
            showEstablishmentFeeMinimumModal: true,
        });
    }

    private onCancelEstablishmentFeeMinimum() {
        this.setState({
            showEstablishmentFeeMinimumModal: false,
        });
    }

    private onClickEstablishmentFeeMinimumOk() {
        const { deal } = this.props;
        const { dirtyFields, establishmentFeeDollarsMinimum, establishmentFeePercentageMinimum } = this.state;

        const establishmentFeeDollarsMinimumSend: number = dirtyFields.establishmentFeeDollarsMinimum ? establishmentFeeDollarsMinimum : deal.establishmentFeeDollarsMinimumOverride || constants.DEAL_ESTABLISHMENT_FEE_DOLLARS_MINIMUM;
        const establishmentFeePercentageMinimumSend: number = dirtyFields.establishmentFeePercentageMinimum ? establishmentFeePercentageMinimum : deal.establishmentFeePercentageMinimumOverride || constants.DEAL_ESTABLISHMENT_FEE_PERCENTAGE_MINIMUM;

        this.onCancelEstablishmentFeeMinimum();
        this.props.establishmentFeeMinimum(deal.uuid, establishmentFeeDollarsMinimumSend, establishmentFeePercentageMinimumSend);
    }

    private onChangeEstablishmentFeeDollarsMinimum(event: React.ChangeEvent<HTMLInputElement>) {
        const { dirtyFields } = this.state;
        this.setState({
            dirtyFields: {
                ...dirtyFields,
                establishmentFeeDollarsMinimum: true,
            },
            establishmentFeeDollarsMinimum: event.target.value ? event.target.valueAsNumber : null,
        });
    }

    private onChangeEstablishmentFeePercentageMinimum(event: React.ChangeEvent<HTMLInputElement>) {
        const { dirtyFields } = this.state;
        this.setState({
            dirtyFields: {
                ...dirtyFields,
                establishmentFeePercentageMinimum: true,
            },
            establishmentFeePercentageMinimum: event.target.value ? event.target.valueAsNumber : null,
        });
    }

    private onClickInterestRateMinimum() {
        this.setState({
            showInterestRateMinimumModal: true,
        });
    }

    private onCancelInterestRateMinimum() {
        this.setState({
            showInterestRateMinimumModal: false,
        });
    }

    private onClickInterestRateMinimumOk() {
        const { deal } = this.props;
        const { interestRateMinimum } = this.state;

        this.onCancelInterestRateMinimum();
        this.props.interestRateMinimum(deal.uuid, interestRateMinimum);
    }

    private onChangeInterestRateMinimum(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            interestRateMinimum: event.target.value && event.target.valueAsNumber,
        });
    }

    private onClickLegalFeesDollarsMinimum() {
        this.setState({
            showLegalFeesDollarsMinimumModal: true,
        });
    }

    private onCancelLegalFeesDollarsMinimum() {
        this.setState({
            showLegalFeesDollarsMinimumModal: false,
        });
    }

    private onClickLegalFeesDollarsMinimumOk() {
        const { deal } = this.props;
        const { legalFeesDollarsMinimum } = this.state;

        this.onCancelLegalFeesDollarsMinimum();
        this.props.legalFeesDollarsMinimum(deal.uuid, legalFeesDollarsMinimum);
    }

    private onChangeLegalFeesDollarsMinimum(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            legalFeesDollarsMinimum: event.target.value && event.target.valueAsNumber,
        });
    }

    private onClickLvrMaximum() {
        this.setState({
            showLvrMaximumModal: true,
        });
    }

    private onCancelLvrMaximum() {
        this.setState({
            showLvrMaximumModal: false,
        });
    }

    private onClickLvrMaximumOk() {
        const { deal } = this.props;
        const { lvrMaximum } = this.state;

        this.onCancelLvrMaximum();
        this.props.lvrMaximum(deal.uuid, lvrMaximum);
    }

    private onChangeLvrMaximum(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            lvrMaximum: event.target.value && event.target.valueAsNumber,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        administrators: administratorsSelector(state),
        brokerSearchLoading: leadBrokerSearchLoadingSelector(state),
        brokerSearchResults: leadBrokerSearchResultsSelector(state),
        currentAdministrator: currentAdministratorSelector(state),
        currentUser: authCurrentUserSelector(state),
        deal: leadSelector(state, ownProps.match.params.dealUuid),
        referralPartners: approvedReferralPartnersSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        establishmentFeeMinimum: (dealUuid: string, establishmentFeeDollarsMinimum: number, establishmentFeePercentageMinimum: number) => dispatch(dealEstablishmentFeeMinimumAction(dealUuid, establishmentFeeDollarsMinimum, establishmentFeePercentageMinimum)),
        interestRateMinimum: (dealUuid: string, interestRateMinimum: number) => dispatch(dealInterestRateMinimumAction(dealUuid, interestRateMinimum)),
        leadBrokerSearch: (keyword: string) => dispatch(leadBrokerSearchAction(keyword)),
        leadBrokerSearchClear: () => dispatch(leadBrokerSearchResultsClearAction()),
        leadGet: () => dispatch(leadGetAction(ownProps.match.params.dealUuid)),
        leadValueSet: (key: keyof IDeal, value: boolean|number|string) => dispatch(leadValueSetAction(ownProps.match.params.dealUuid, key, value)),
        legalFeesDollarsMinimum: (dealUuid: string, legalFeesDollarsMinimum: number) => dispatch(dealLegalFeesDollarsMinimumAction(dealUuid, legalFeesDollarsMinimum)),
        lvrMaximum: (dealUuid: string, lvrMaximum: number) => dispatch(dealLvrMaximumAction(dealUuid, lvrMaximum)),
        referralPartnersList: () => dispatch(referralPartnersListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Edit);
