import { Action } from 'redux';
import IAdministrator from '~Api/Administrator/IAdministrator';
import IHistory from '~Api/Administrator/IHistory';
import AdministratorsActionsEnum from './ActionsEnum';
import IPasskey from '~Api/Administrator/IPasskey';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';

export interface IAdministratorActivateAction extends Action {
    administratorUuid: string;
}

export function administratorActivateAction(administratorUuid: string): IAdministratorActivateAction {
    return {
        administratorUuid,
        type: AdministratorsActionsEnum.AdministratorActivate,
    };
}

export interface IAdministratorDeactivateAction extends Action {
    administratorUuid: string;
}

export function administratorDeactivateAction(administratorUuid: string): IAdministratorDeactivateAction {
    return {
        administratorUuid,
        type: AdministratorsActionsEnum.AdministratorDeactivate,
    };
}

export interface IAdministratorHistoriesListAction extends Action {
    uuid: string;
}

export function administratorHistoriesListAction(uuid: string): IAdministratorHistoriesListAction {
    return {
        type: AdministratorsActionsEnum.AdministratorHistoriesList,
        uuid,
    };
}

export interface IAdministratorHistoriesSetAction extends Action {
    histories: IHistory[];
    uuid: string;
}

export function administratorHistoriesSetAction(uuid: string, histories: IHistory[]): IAdministratorHistoriesSetAction {
    return {
        histories,
        type: AdministratorsActionsEnum.AdministratorHistoriesSet,
        uuid,
    };
}

export interface IAdministratorFidoAction extends Action {
    administratorUuid: string;
}

export function administratorFidoAction(administratorUuid: string): IAdministratorFidoAction {
    return {
        administratorUuid,
        type: AdministratorsActionsEnum.AdministratorFido,
    };
}

export interface IAdministratorGetAction extends Action {
    uuid: string;
}

export function administratorGetAction(uuid: string): IAdministratorGetAction {
    return {
        type: AdministratorsActionsEnum.AdministratorGet,
        uuid,
    };
}

export interface IAdministratorMagicLinkRequestApproveAction extends Action {
    administratorUuid: string;
}

export function administratorMagicLinkRequestApproveAction(administratorUuid: string): IAdministratorMagicLinkRequestApproveAction {
    return {
        administratorUuid,
        type: AdministratorsActionsEnum.AdministratorMagicLinkRequestApprove,
    };
}

export interface IAdministratorMagicLinkRequestCancelAction extends Action {
    administratorUuid: string;
}

export function administratorMagicLinkRequestCancelAction(administratorUuid: string): IAdministratorMagicLinkRequestCancelAction {
    return {
        administratorUuid,
        type: AdministratorsActionsEnum.AdministratorMagicLinkRequestCancel,
    };
}

export interface IAdministratorPasskeyDeactivateAction extends Action {
    administratorPasskeyUuid: string;
}

export function administratorPasskeyDeactivateAction(administratorPasskeyUuid: string): IAdministratorPasskeyDeactivateAction {
    return {
        administratorPasskeyUuid,
        type: AdministratorsActionsEnum.AdministratorPasskeyDeactivate,
    };
}

export interface IAdministratorPasskeySetAction extends Action {
    administratorPasskey: IPasskey;
}

export function administratorPasskeySetAction(administratorPasskey: IPasskey): IAdministratorPasskeySetAction {
    return {
        administratorPasskey,
        type: AdministratorsActionsEnum.AdministratorPasskeySet,
    };
}

export interface IAdministratorPasskeysListAction extends Action {
    administratorUuid: string;
}

export function administratorPasskeysListAction(administratorUuid: string): IAdministratorPasskeysListAction {
    return {
        administratorUuid,
        type: AdministratorsActionsEnum.AdministratorPasskeysList,
    };
}

export interface IAdministratorPasskeysSetAction extends Action {
    administratorUuid: string;
    passkeys: IPasskey[];
}

export function administratorPasskeysSetAction(administratorUuid: string, passkeys: IPasskey[]): IAdministratorPasskeysSetAction {
    return {
        administratorUuid,
        passkeys,
        type: AdministratorsActionsEnum.AdministratorPasskeysSet,
    };
}

export interface IAdministratorPermissionsAction extends Action {
    administratorUuid: string;
    permissions: PermissionsEnum[];
}

export function administratorPermissionsSetAction(administratorUuid: string, permissions: PermissionsEnum[]): IAdministratorPermissionsAction {
    return {
        administratorUuid,
        permissions,
        type: AdministratorsActionsEnum.AdministratorPermissions,
    };
}

export interface IAdministratorSendAction extends Action {
    uuid: string;
}

export function administratorSendAction(uuid: string): IAdministratorSendAction {
    return {
        type: AdministratorsActionsEnum.AdministratorSend,
        uuid,
    };
}

export interface IAdministratorSetAction extends Action {
    administrator: IAdministrator;
}

export function administratorSetAction(administrator: IAdministrator): IAdministratorSetAction {
    return {
        administrator,
        type: AdministratorsActionsEnum.AdministratorSet,
    };
}

export interface IAdministratorValueSetAction extends Action {
    key: keyof IAdministrator;
    uuid: string;
    value: boolean|number|string|string[];
}

export function administratorValueSetAction(uuid: string, key: keyof IAdministrator, value: boolean|number|string|string[]): IAdministratorValueSetAction {
    return {
        key,
        type: AdministratorsActionsEnum.AdministratorValueSet,
        uuid,
        value,
    };
}

export interface IAdministratorsAddAction extends Action {
    administrator: IAdministrator;
}

export function administratorsAddAction(administrator: IAdministrator): IAdministratorsAddAction {
    return {
        administrator,
        type: AdministratorsActionsEnum.AdministratorsAdd,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAdministratorsListAction extends Action {}

export function administratorsListAction(): IAdministratorsListAction {
    return {
        type: AdministratorsActionsEnum.AdministratorsList,
    };
}

export interface IAdministratorsSetAction extends Action {
    administrators: IAdministrator[];
}

export function administratorsSetAction(administrators: IAdministrator[]): IAdministratorsSetAction {
    return {
        administrators,
        type: AdministratorsActionsEnum.AdministratorsSet,
    };
}
