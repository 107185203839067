import { Action } from 'redux';
import { IDictionary } from './IDictionary';
import objectHash from 'object-hash';
import { call } from '@redux-saga/core/effects';

const pendingActions: IDictionary<boolean> = {};

export default function parameterDebounce(worker: any): any {
    return function*(action: Action) {
        const hash: string = objectHash(action);
        const debounce: boolean = !!pendingActions[hash];

        if (!debounce) {
            pendingActions[hash] = true;
            yield call(worker, action);
            pendingActions[hash] = false;
        }
    };
}
