import { Action } from 'redux';
import IInvalidInvestorTransaction from '~Api/Investor/IInvalidInvestorTransaction';
import IInvestorAccountTransaction from '~Api/Investor/IInvestorAccountTransaction';
import AccountTypeEnum from '~Api/WithdrawalAba/AccountTypeEnum';
import IHistory from '~Api/WithdrawalAba/IHistory';
import IWithdrawalAba from '~Api/WithdrawalAba/IWithdrawalAba';
import WithdrawalAbasActionsEnum from '~WithdrawalAbas/ActionsEnum';

export interface IWithdrawalAbaGetAction extends Action {
    withdrawalAbaUuid: string;
}

export function withdrawalAbaGetAction(withdrawalAbaUuid: string): IWithdrawalAbaGetAction {
    return {
        type: WithdrawalAbasActionsEnum.WithdrawalAbaGet,
        withdrawalAbaUuid,
    };
}

export interface IWithdrawalAbaSendAction extends Action {
    withdrawalAbaUuid: string;
}

export function withdrawalAbaSendAction(withdrawalAbaUuid: string): IWithdrawalAbaSendAction {
    return {
        type: WithdrawalAbasActionsEnum.WithdrawalAbaSend,
        withdrawalAbaUuid,
    };
}

export interface IWithdrawalAbaSetAction extends Action {
    withdrawalAba: IWithdrawalAba;
}

export function withdrawalAbaSetAction(withdrawalAba: IWithdrawalAba): IWithdrawalAbaSetAction {
    return {
        type: WithdrawalAbasActionsEnum.WithdrawalAbaSet,
        withdrawalAba,
    };
}

export interface IWithdrawalAbasAddAction extends Action {
    accountType: AccountTypeEnum;
    investorTransactionsUuids: string[];
}

export function withdrawalAbasAddAction(accountType: AccountTypeEnum, investorTransactionsUuids: string[]): IWithdrawalAbasAddAction {
    return {
        accountType,
        investorTransactionsUuids,
        type: WithdrawalAbasActionsEnum.WithdrawalAbasAdd,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IWithdrawalAbasListAction extends Action { }

export function withdrawalAbasListAction(): IWithdrawalAbasListAction {
    return {
        type: WithdrawalAbasActionsEnum.WithdrawalAbasList,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IWithdrawalAbasSetAction extends Action {
    withdrawalAbas: IWithdrawalAba[];
}

export function withdrawalAbasSetAction(withdrawalAbas: IWithdrawalAba[]): IWithdrawalAbasSetAction {
    return {
        type: WithdrawalAbasActionsEnum.WithdrawalAbasSet,
        withdrawalAbas,
    };
}

export interface IWithdrawalAbasInvalidInvestorTransactionsListAction extends Action {
    accountType: AccountTypeEnum
}

export function withdrawalAbasInvalidInvestorTransactionsListAction(accountType: AccountTypeEnum): IWithdrawalAbasInvalidInvestorTransactionsListAction {
    return {
        accountType,
        type: WithdrawalAbasActionsEnum.WithdrawalAbasInvalidInvestorTransactionsList,
    };
}

export interface IWithdrawalAbasInvalidInvestorTransactionsSetAction extends Action {
    accountType: AccountTypeEnum,
    invalidInvestorTransactions: IInvalidInvestorTransaction[];
}

export function withdrawalAbasInvalidInvestorTransactionsSetAction(accountType: AccountTypeEnum, invalidInvestorTransactions: IInvalidInvestorTransaction[]): IWithdrawalAbasInvalidInvestorTransactionsSetAction {
    return {
        accountType,
        invalidInvestorTransactions,
        type: WithdrawalAbasActionsEnum.WithdrawalAbasInvalidInvestorTransactionsSet,
    };
}

export interface IWithdrawalAbasUnallocatedInvestorTransactionsListAction extends Action {
    accountType: AccountTypeEnum,
}

export function withdrawalAbasUnallocatedInvestorTransactionsListAction(accountType: AccountTypeEnum): IWithdrawalAbasUnallocatedInvestorTransactionsListAction {
    return {
        accountType,
        type: WithdrawalAbasActionsEnum.WithdrawalAbasUnallocatedInvestorTransactionsList,
    };
}

export interface IWithdrawalAbasUnallocatedInvestorTransactionsSetAction extends Action {
    accountType: AccountTypeEnum,
    unallocatedInvestorTransactions: IInvestorAccountTransaction[];
}

export function withdrawalAbasUnallocatedInvestorTransactionsSetAction(accountType: AccountTypeEnum, unallocatedInvestorTransactions: IInvestorAccountTransaction[]): IWithdrawalAbasUnallocatedInvestorTransactionsSetAction {
    return {
        accountType,
        type: WithdrawalAbasActionsEnum.WithdrawalAbasUnallocatedInvestorTransactionsSet,
        unallocatedInvestorTransactions,
    };
}

export interface IWithdrawalAbaHistoriesListAction extends Action {
    withdrawalAbaUuid: string;
}

export function withdrawalAbaHistoriesListAction(withdrawalAbaUuid: string): IWithdrawalAbaHistoriesListAction {
    return {
        type: WithdrawalAbasActionsEnum.WithdrawalAbaHistoriesList,
        withdrawalAbaUuid,
    };
}

export interface IWithdrawalAbaHistoriesSetAction extends Action {
    histories: IHistory[];
    withdrawalAbaUuid: string;
}

export function withdrawalAbaHistoriesSetAction(withdrawalAbaUuid: string, histories: IHistory[]): IWithdrawalAbaHistoriesSetAction {
    return {
        histories,
        type: WithdrawalAbasActionsEnum.WithdrawalAbaHistoriesSet,
        withdrawalAbaUuid,
    };
}

export interface IWithdrawalAbaInvestorTransactionsListAction extends Action {
    withdrawalAbaUuid: string;
}

export function withdrawalAbaInvestorTransactionsListAction(withdrawalAbaUuid: string): IWithdrawalAbaInvestorTransactionsListAction {
    return {
        type: WithdrawalAbasActionsEnum.WithdrawalAbaInvestorTransactionsList,
        withdrawalAbaUuid,
    };
}

export interface IWithdrawalAbaInvestorTransactionsSetAction extends Action {
    investorAccountTransactions: IInvestorAccountTransaction[];
    withdrawalAbaUuid: string;
}

export function withdrawalAbaInvestorTransactionsSetAction(withdrawalAbaUuid: string, investorAccountTransactions: IInvestorAccountTransaction[]): IWithdrawalAbaInvestorTransactionsSetAction {
    return {
        investorAccountTransactions,
        type: WithdrawalAbasActionsEnum.WithdrawalAbaInvestorTransactionsSet,
        withdrawalAbaUuid,
    };
}
