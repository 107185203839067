import { Button, Checkbox, Divider, Form, Input, Modal, Select, Space, Spin, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import BorrowerCategoryEnum from '~Api/Application/BorrowerCategoryEnum';
import BorrowerTypeEnum from '~Api/Application/BorrowerTypeEnum';
import CodeTypeEnum from '~Api/Application/CodeTypeEnum';
import IApplication from '~Api/Application/IApplication';
import IBorrower from '~Api/Application/IBorrower';
import IConditionalCondition from '~Api/Application/IConditionalCondition';
import IConditional from '~Api/Application/IConditional';
import LoanPurposeEnum from '~Api/Application/LoanPurposeEnum';
import MortgageTypeEnum from '~Api/Application/MortgageTypeEnum';
import ICondition from '~Api/Application/ICondition';
import {
    applicationConditionalsAddAction,
    applicationConditionalsListAction,
    applicationConditionalsStandardConditionsListAction,
    applicationConditionsListAction,
    applicationGetAction,
    applicationValueSetAction,
} from '~Applications/actions';
import {
    applicationConditionalsSelector,
    applicationConditionalsStandardConditionsSelector,
    applicationConditionsSelector,
    applicationSelector,
} from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import { IStandardCondition } from '~Api/Application/IStandardCondition';
import { IStandardConditionGroup } from '~Api/Application/IStandardConditionGroup';

const loanPurposeLabels: IDictionary<string> = {
    [LoanPurposeEnum.BridgingLoan]: 'Bridging loan',
    [LoanPurposeEnum.Refinance]: 'Refinance an existing property',
    [LoanPurposeEnum.BusinessLoan]: 'Business loan',
    [LoanPurposeEnum.PersonalLoan]: 'Personal loan',
    [LoanPurposeEnum.RenovateOrBuild]: 'Renovate or build',
    [LoanPurposeEnum.PurchaseNew]: 'Purchase a new property',
    [LoanPurposeEnum.DebtConsolidation]: 'Debt consolidation',
    [LoanPurposeEnum.DevelopmentLoan]: 'Development loan',
};

interface IState {
    applicationConditions: string[];
    changedConditions: string[];
    codeType: CodeTypeEnum;
    customCondition: string;
    customConditions: string[];
    isMissingDetailsModalOpen: boolean;
    loanPurpose: LoanPurposeEnum;
    mortgageType: MortgageTypeEnum;
    standardConditions: string[];
}

interface IMatch {
    applicationUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    application: IApplication;
    conditionals: IDictionary<IConditional>;
    conditions: IDictionary<ICondition>;
    standardConditionLabels: IStandardConditionGroup[];
}

interface IPropsDispatch {
    applicationGet: () => void;
    applicationValueSet: (key: keyof IApplication, value: boolean|number|string) => void;
    conditionalAdd: (conditions: string[]) => void;
    conditionalsList: () => void;
    conditionsList: () => void;
    standardConditionLabelsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class ConditionalAdd extends React.Component<Props, IState> {
    public state: IState = {
        applicationConditions: [],
        changedConditions: [],
        codeType: null,
        customCondition: null,
        customConditions: [],
        isMissingDetailsModalOpen: true,
        loanPurpose: null,
        mortgageType: null,
        standardConditions: [],
    };

    constructor(props: Props) {
        super(props);

        this.getPreviousConditions = this.getPreviousConditions.bind(this);

        this.onChangeCustomCondition = this.onChangeCustomCondition.bind(this);
        this.onClickCustomConditionAdd = this.onClickCustomConditionAdd.bind(this);
        this.onClickStandardCondition = this.onClickStandardCondition.bind(this);
        this.onClickApplicationCondition = this.onClickApplicationCondition.bind(this);
        this.onClickCustomCondition = this.onClickCustomCondition.bind(this);
        this.onClickIssue = this.onClickIssue.bind(this);

        this.onChangeCodeType = this.onChangeCodeType.bind(this);
        this.onChangeLoanPurpose = this.onChangeLoanPurpose.bind(this);
        this.onChangeMortgageType = this.onChangeMortgageType.bind(this);
        this.onClickMissingDetailsCancel = this.onClickMissingDetailsCancel.bind(this);
        this.onClickMissingDetailsOk = this.onClickMissingDetailsOk.bind(this);
    }

    public componentDidMount(): void {
        const { application, conditionals, conditions, standardConditionLabels } = this.props;

        if (!application) {
            this.props.applicationGet();
        }

        if (!conditionals) {
            this.props.conditionalsList();
        }

        if (!conditions) {
            this.props.conditionsList();
        }

        if (!standardConditionLabels) {
            this.props.standardConditionLabelsList();
        }
    }

    public render(): JSX.Element {
        const { application, conditionals, conditions, match, standardConditionLabels } = this.props;
        const {
            applicationConditions,
            codeType,
            customCondition,
            customConditions,
            isMissingDetailsModalOpen,
            loanPurpose,
            mortgageType,
            standardConditions,
        } = this.state;

        if (!application || !conditionals || !conditions) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='conditional-approval'>
                    <Typography.Title level={2}>Conditional Approval</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        if (!application.codeType || !application.loanPurpose || !application.mortgageType || (application.brokerageFee > 0 && !application.deal.brokerUuid)) {
            const codeTypeField: JSX.Element = !application.codeType && (
                <Form.Item label='Loan Type' className='code-type'>
                    <Select onChange={this.onChangeCodeType} value={codeType}>
                        <Select.Option value={CodeTypeEnum.Code}>NCCP Regulated</Select.Option>
                        <Select.Option value={CodeTypeEnum.NonCode}>Non NCCP Regulated</Select.Option>
                    </Select>
                </Form.Item>
            );

            const loanPurposeField: JSX.Element = !application.loanPurpose && (
                <Form.Item label='Loan Purpose' className='loan-purpose'>
                    <Select onChange={this.onChangeLoanPurpose} value={loanPurpose}>
                        {_.map(loanPurposeLabels, (loanPurposeLabel: string, key: LoanPurposeEnum) => <Select.Option value={key}>{loanPurposeLabel}</Select.Option>)}
                    </Select>
                </Form.Item>
            );

            const mortgageTypeField: JSX.Element = !application.mortgageType && (
                <Form.Item label='Mortgage Type' className='mortgage-type'>
                    <Select onChange={this.onChangeMortgageType} value={mortgageType}>
                        <Select.Option value={MortgageTypeEnum.FirstMortgage}>First Mortgage</Select.Option>
                        <Select.Option value={MortgageTypeEnum.SecondMortgage}>Second Mortgage</Select.Option>
                    </Select>
                </Form.Item>
            );

            const modal: JSX.Element = (codeTypeField || mortgageTypeField || loanPurposeField) && (
                <Modal
                    destroyOnClose={true}
                    okText='Confirm'
                    onCancel={this.onClickMissingDetailsCancel}
                    onOk={this.onClickMissingDetailsOk}
                    open={isMissingDetailsModalOpen}
                    title='Missing Details'
                    wrapClassName='application-conditional-missing-details-modal'
                >
                    {codeTypeField}
                    {mortgageTypeField}
                    {loanPurposeField}
                </Modal>
            );

            return (
                <Layout applicationUuid={match.params.applicationUuid} section='conditional-approval'>
                    <Typography.Title level={2}>Conditional Approval</Typography.Title>
                    {!application.codeType && <p>Please set the application loan type</p>}
                    {!application.loanPurpose && <p>Please set the application loan purpose</p>}
                    {!application.mortgageType && <p>Please set the application mortgage type</p>}
                    {application.brokerageFee > 0 && !application.deal.brokerUuid && <p>Please choose a broker or clear the brokerage fee</p>}
                    {modal}
                </Layout>
            );
        }

        const informationErrors: string[] = [];
        application.borrowers.forEach((borrower: IBorrower) => {
            switch (borrower.dealBorrower.type) {
                case BorrowerTypeEnum.Company:
                    if (_.filter(application.borrowers, (loopBorrower: IBorrower) => loopBorrower.uuid !== borrower.uuid && loopBorrower.dealBorrower.category === BorrowerCategoryEnum.Guarantor).length === 0) {
                        informationErrors.push('Company Borrower Present - Guarantor required');
                    }
                    break;
                case BorrowerTypeEnum.Trust:
                    if (_.filter(application.borrowers, (loopBorrower: IBorrower) => loopBorrower.uuid !== borrower.uuid && loopBorrower.dealBorrower.category === BorrowerCategoryEnum.Trustee).length === 0) {
                        informationErrors.push('Trust Borrower Present - Trustee required');
                    }
                    break;
            }
        });
        if (informationErrors.length > 0) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='conditional-approval'>
                    <Typography.Title level={2}>Conditional Approval</Typography.Title>
                    <React.Fragment>
                        {informationErrors.map((error: string, index: number) => <p key={`error-${index}`}>{error}</p>)}
                    </React.Fragment>
                </Layout>
            );
        }

        const previousConditions: string[] = this.getPreviousConditions();

        // Keep track of conditions we use along the way so we can populate the custom conditions from the previous conditions
        const removeConditions: string[] = [];

        const standardConditionsBlock: JSX.Element[] = _.map(standardConditionLabels, (standardConditionGroup: IStandardConditionGroup) => {
            const conditionsBlock: JSX.Element[] = standardConditionGroup.conditions.map((condition: IStandardCondition) => {
                removeConditions.push(condition.label);

                return (
                    <Form.Item className='standard-condition' help={condition.hint} key={condition.label}>
                        <Checkbox key={condition.label} checked={standardConditions.includes(condition.label) || previousConditions.includes(condition.label)} onChange={this.onClickStandardCondition} value={condition.label}>{condition.label}</Checkbox>
                    </Form.Item>
                );
            });

            return (
                <React.Fragment key={standardConditionGroup.heading}>
                    <Divider orientation='left'>{standardConditionGroup.heading}</Divider>
                    {conditionsBlock}
                </React.Fragment>
            );
        });
        const applicationConditionsBlock: JSX.Element[] = _.map(conditions, (loopCondition: ICondition) => {
            removeConditions.push(loopCondition.name);

            return (
                <Form.Item className='application-condition' key={loopCondition.uuid}>
                    <Checkbox checked={applicationConditions.includes(loopCondition.name) || previousConditions.includes(loopCondition.name)} onChange={this.onClickApplicationCondition} value={loopCondition.name}>{loopCondition.name}</Checkbox>
                </Form.Item>
            );
        });

        // Remove any previous conditions from the standard lists so we are left with the custom conditions
        _.pullAll(previousConditions, removeConditions);

        const customConditionsBlock: JSX.Element[] = [...customConditions, ...previousConditions].map((loopCustomCondition: string) => (
            <Form.Item className='custom-condition' key={loopCustomCondition}>
                <Checkbox checked={true} onChange={this.onClickCustomCondition} value={loopCustomCondition}>{loopCustomCondition}</Checkbox>
            </Form.Item>
        ));

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='conditional-approval'>
                <Space className='issue'>
                    <Button className='issue' onClick={this.onClickIssue}>Issue</Button>
                </Space>
                <Typography.Title level={2}>Conditional Approval</Typography.Title>
                <React.Fragment>
                    {standardConditionsBlock}
                </React.Fragment>
                <Divider orientation='left'>Application Conditions</Divider>
                <React.Fragment>
                    {applicationConditionsBlock}
                </React.Fragment>
                <Divider orientation='left'>Custom Conditions</Divider>
                <React.Fragment>
                    {customConditionsBlock}
                </React.Fragment>
                <Space className='custom-condition-add'>
                    <Input onChange={this.onChangeCustomCondition} value={customCondition || ''} maxLength={255} />
                    <Button onClick={this.onClickCustomConditionAdd}>Add</Button>
                </Space>
            </Layout>
        );
    }

    private getPreviousConditions(): string[] {
        const { conditionals } = this.props;
        const { changedConditions } = this.state;

        // Extract the conditions from the most recent CA
        const previousConditions: string[] = _.size(conditionals) > 0 ? [ ..._.map(_.reverse(_.sortBy(conditionals, ['createdTime']))[0].conditions, (condition: IConditionalCondition) => condition.name) ] : [];

        // Remove any conditions we may have deselected so they don't get forced ticked
        _.pullAll(previousConditions, changedConditions);

        return previousConditions;
    }

    private onChangeCustomCondition(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            customCondition: event.target.value.substr(0, 255),
        });
    }

    private onClickCustomConditionAdd(): void {
        const { changedConditions, customCondition, customConditions } = this.state;

        this.setState({
            changedConditions: _.uniq([
                ...changedConditions,
                customCondition,
            ]),
            customCondition: null,
            customConditions: [
                ...customConditions,
                customCondition,
            ],
        });
    }

    private onClickStandardCondition(event: CheckboxChangeEvent): void {
        const { changedConditions, standardConditions } = this.state;

        if (event.target.checked) {
            this.setState({
                changedConditions: _.uniq([
                    ...changedConditions,
                    event.target.value,
                ]),
                standardConditions: [
                    ...standardConditions,
                    event.target.value,
                ],
            });
        } else {
            this.setState({
                changedConditions: _.uniq([
                    ...changedConditions,
                    event.target.value,
                ]),
                standardConditions: _.pull(standardConditions, event.target.value),
            });
        }
    }

    private onClickApplicationCondition(event: CheckboxChangeEvent): void {
        const { applicationConditions, changedConditions } = this.state;

        if (event.target.checked) {
            this.setState({
                applicationConditions: [
                    ...applicationConditions,
                    event.target.value,
                ],
                changedConditions: _.uniq([
                    ...changedConditions,
                    event.target.value,
                ]),
            });
        } else {
            this.setState({
                applicationConditions: _.pull(applicationConditions, event.target.value),
                changedConditions: _.uniq([
                    ...changedConditions,
                    event.target.value,
                ]),
            });
        }
    }

    private onClickCustomCondition(event: CheckboxChangeEvent): void {
        const { changedConditions, customConditions } = this.state;

        this.setState({
            changedConditions: _.uniq([
                ...changedConditions,
                event.target.value,
            ]),
            customConditions: _.pull(customConditions, event.target.value),
        });
    }

    private onClickIssue(): void {
        const { applicationConditions, customConditions, standardConditions } = this.state;

        const previousConditions: string[] = this.getPreviousConditions();

        this.props.conditionalAdd([
            ...previousConditions,
            ...standardConditions,
            ...applicationConditions,
            ...customConditions,
        ]);
    }

    private onChangeCodeType(value: CodeTypeEnum): void {
        this.setState({
            codeType: value,
        });
    }

    private onChangeLoanPurpose(value: LoanPurposeEnum): void {
        this.setState({
            loanPurpose: value,
        });
    }

    private onChangeMortgageType(value: MortgageTypeEnum): void {
        this.setState({
            mortgageType: value,
        });
    }

    private onClickMissingDetailsCancel(): void {
        this.setState({
            isMissingDetailsModalOpen: false,
        });
    }

    private onClickMissingDetailsOk(): void {
        const { codeType, loanPurpose, mortgageType } = this.state;

        this.props.applicationValueSet('codeType', codeType);
        this.props.applicationValueSet('loanPurpose', loanPurpose);
        this.props.applicationValueSet('mortgageType', mortgageType);
        this.onClickMissingDetailsCancel();
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
        conditionals: applicationConditionalsSelector(state, ownProps.match.params.applicationUuid),
        conditions: applicationConditionsSelector(state, ownProps.match.params.applicationUuid),
        standardConditionLabels: applicationConditionalsStandardConditionsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
        applicationValueSet: (key: keyof IApplication, value: boolean|number|string) => dispatch(applicationValueSetAction(ownProps.match.params.applicationUuid, key, value)),
        conditionalAdd: (conditions: string[]) => dispatch(applicationConditionalsAddAction(ownProps.match.params.applicationUuid, conditions)),
        conditionalsList: () => dispatch(applicationConditionalsListAction(ownProps.match.params.applicationUuid)),
        conditionsList: () => dispatch(applicationConditionsListAction(ownProps.match.params.applicationUuid)),
        standardConditionLabelsList: () => dispatch(applicationConditionalsStandardConditionsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConditionalAdd);
