import { CheckOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Layout, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { GrNew } from 'react-icons/gr';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import ApprovalStatusEnum from '~Api/ReferralPartner/ApprovalStatusEnum';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import TypeEnum from '~Api/ReferralPartner/TypeEnum';
import { IGlobalState } from '~reducer';
import SearchDropdown from '~UI/SearchDropdown';
import { IDictionary } from '~utilities/IDictionary';
import { referralPartnersListAction } from './actions';
import AddModal from './AddModal';
import './referralPartners.less';
import { referralPartnersSelector } from './selectors';

const typeLabels: IDictionary<string> = {
    [TypeEnum.Accountant]: 'Accountant',
    [TypeEnum.Leads]: 'Leads',
    [TypeEnum.Lender]: 'Lender',
    [TypeEnum.Other]: 'Other',
    [TypeEnum.RealEstate]: 'Real Estate',
};

interface IState {
    isAddModalOpen: boolean;
}

interface IPropsSelector {
    referralPartners: IDictionary<IReferralPartner>;
}

interface IPropsDispatch {
    referralPartnersList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class List extends React.Component<Props, IState> {
    public state: IState = {
        isAddModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAdd = this.onClickAdd.bind(this);
        this.onCloseAddModal = this.onCloseAddModal.bind(this);
    }

    public componentDidMount(): void {
        this.props.referralPartnersList();
    }

    public render(): JSX.Element {
        const { referralPartners } = this.props;
        const { isAddModalOpen } = this.state;

        if (!referralPartners) {
            return (
                <Layout className='referral-partners'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Referral Partners</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Referral Partners</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const columns: ColumnType<IReferralPartner>[] = [
            {
                dataIndex: 'approvalStatus',
                filters: [
                    {
                        text: 'Approved',
                        value: ApprovalStatusEnum.Approved,
                    },
                    {
                        text: 'Pending',
                        value: ApprovalStatusEnum.Pending,
                    },
                    {
                        text: 'Rejected',
                        value: ApprovalStatusEnum.Rejected,
                    },
                ],
                onFilter: (value: ApprovalStatusEnum, referralPartner: IReferralPartner) => referralPartner.approvalStatus === value,
                render: (approvalStatus: ApprovalStatusEnum) => {
                    if (approvalStatus === ApprovalStatusEnum.Approved) {
                        return <CheckOutlined/>;
                    }

                    if (approvalStatus === ApprovalStatusEnum.Pending) {
                        return <GrNew/>;
                    }

                    if (approvalStatus === ApprovalStatusEnum.Rejected) {
                        return <CloseOutlined/>;
                    }
                },
                title: '',
                width: '5%',
            },
            {
                filterDropdown: (params: any) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string, referralPartner: IReferralPartner) => `${referralPartner.firstName || ''} ${referralPartner.lastName || ''}`.toLowerCase().includes(value.toLowerCase()),
                render: (referralPartner: IReferralPartner) => <Link to={`/referral-partners/${referralPartner.uuid}`}>{`${referralPartner.firstName || ''} ${referralPartner.lastName || ''}`.trim() || '-'}</Link>,
                sorter: (a: IReferralPartner, b: IReferralPartner) => `${a.firstName || ''} ${a.lastName || ''}`.localeCompare(`${b.firstName || ''} ${b.lastName || ''}`),
                title: 'Name',
            },
            {
                dataIndex: 'type',
                filters: _.keys(typeLabels).map((type: any) => {
                    return {
                        text: typeLabels[type],
                        value: type,
                    };
                }),
                onFilter: (value: TypeEnum, referralPartner: IReferralPartner) => value === referralPartner.type,
                render: (type: TypeEnum) => typeLabels[type],
                title: 'Type',
                width: '30%',
            },
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => createdTime ? <Tooltip title={dayjs(createdTime).format('Do MMMM YYYY HH:mm:ss')}><span>{dayjs(createdTime).fromNow()}</span></Tooltip> : '-',
                sorter: (a: IReferralPartner, b: IReferralPartner) => {
                    if (!b.createdTime) {
                        return 1;
                    }

                    if (!a.createdTime) {
                        return -1;
                    }

                    return dayjs(a.createdTime) > dayjs(b.createdTime) ? 1 : -1;
                },
                title: 'Created',
                width: '10%',
            },
        ];

        return (
            <Layout className='referral-partners'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Referral Partners</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Button onClick={this.onClickAdd}>Add Partner</Button>
                        </Space><Typography.Title level={2}>Referral Partners</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.values(referralPartners)}
                            pagination={{ defaultPageSize: 50 }}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
                <AddModal
                    isOpen={isAddModalOpen}
                    onCancel={this.onCloseAddModal}
                />
            </Layout>
        );
    }

    private onClickAdd(): void {
        this.setState({
            isAddModalOpen: true,
        });
    }

    private onCloseAddModal(): void {
        this.setState({
            isAddModalOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        referralPartners: referralPartnersSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        referralPartnersList: () => dispatch(referralPartnersListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(List);
