import { Checkbox, Form, Input, Select, Spin, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { aggregatorsListAction } from '~Aggregators/actions';
import IAggregator from '~Api/Aggregator/IAggregator';
import { aggregatorsSelector } from '~Aggregators/selectors';
import BrokerTypeEnum from '~Api/Broker/BrokerTypeEnum';
import IBroker from '~Api/Broker/IBroker';
import StateEnum from '~Api/Broker/StateEnum';
import {
    brokerGetAction,
    brokerValueSetAction,
} from '~Brokers/actions';
import { brokerSelector } from '~Brokers/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import { IDictionary } from '~utilities/IDictionary';

interface IMatch {
    brokerUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    aggregators: IDictionary<IAggregator>;
    broker: IBroker;
}

interface IPropsDispatch {
    aggregatorsList: () => void;
    brokerGet: () => void;
    brokerValueSet: (key: keyof IBroker, value: any) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Edit extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeAggregator = this.onChangeAggregator.bind(this);
        this.onChangeAclNumber = this.onChangeAclNumber.bind(this);
        this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeHasBeenBankrupted = this.onChangeHasBeenBankrupted.bind(this);
        this.onChangeHasBeenConvicted = this.onChangeHasBeenConvicted.bind(this);
        this.onChangeHasIndemnityInsurance = this.onChangeHasIndemnityInsurance.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeLinkedInUrl = this.onChangeLinkedInUrl.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangePostalAddress = this.onChangePostalAddress.bind(this);
        this.onChangePostcode = this.onChangePostcode.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeSuburb = this.onChangeSuburb.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
    }

    public componentDidMount() {
        this.props.brokerGet();
        this.props.aggregatorsList();
    }

    public render(): JSX.Element {
        const { aggregators, broker, match } = this.props;

        if (!aggregators || !broker) {
            return (
                <Layout brokerUuid={match.params.brokerUuid} section='edit'>
                    <Spin/>
                </Layout>
            );
        }

        return (
            <Layout brokerUuid={match.params.brokerUuid} section='edit'>
                <Typography.Title level={2}>Edit</Typography.Title>
                <Form.Item label='First Name' className='first-name'>
                    <Input onChange={this.onChangeFirstName} value={broker.firstName} />
                </Form.Item>
                <Form.Item label='Last Name' className='last-name'>
                    <Input onChange={this.onChangeLastName} value={broker.lastName} />
                </Form.Item>
                <Form.Item label='Phone' className='phone'>
                    <Input onChange={this.onChangePhone} value={broker.phone} />
                </Form.Item>
                <Form.Item label='Email' className='email'>
                    <Input onChange={this.onChangeEmail} value={broker.email} />
                </Form.Item>
                <Form.Item label='Company Name' className='company-name'>
                    <Input onChange={this.onChangeCompanyName} value={broker.companyName} />
                </Form.Item>
                <Form.Item label='Address' className='postal-address'>
                    <Input onChange={this.onChangePostalAddress} value={broker.postalAddress} />
                </Form.Item>
                <Form.Item label='Suburb' className='suburb'>
                    <Input onChange={this.onChangeSuburb} value={broker.suburb} />
                </Form.Item>
                <Form.Item className='state' label='State'>
                    <Select onChange={this.onChangeState} value={broker.state}>
                        <Select.Option value={StateEnum.AustralianCapitalTerritory}>ACT</Select.Option>
                        <Select.Option value={StateEnum.NewSouthWales}>NSW</Select.Option>
                        <Select.Option value={StateEnum.NorthernTerritory}>NT</Select.Option>
                        <Select.Option value={StateEnum.Queensland}>QLD</Select.Option>
                        <Select.Option value={StateEnum.SouthAustralia}>SA</Select.Option>
                        <Select.Option value={StateEnum.Tasmania}>TAS</Select.Option>
                        <Select.Option value={StateEnum.Victoria}>VIC</Select.Option>
                        <Select.Option value={StateEnum.WesternAustralia}>WA</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item className='postcode' label='Postcode'>
                    <Input onChange={this.onChangePostcode} value={broker.postcode} />
                </Form.Item>
                <Form.Item label='Type' className='type'>
                    <Select onChange={this.onChangeType} value={broker.type}>
                        <Select.Option value={BrokerTypeEnum.AustralianCreditLicence}>Australian Credit Licence</Select.Option>
                        <Select.Option value={BrokerTypeEnum.AuthorisedCreditRepresentative}>Authorised Credit Representative</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label='ACL/CR Number' className='acl-number'>
                    <Input onChange={this.onChangeAclNumber} value={broker.aclNumber} />
                </Form.Item>
                <Form.Item label='Aggregator' className='aggregator'>
                    <Select onChange={this.onChangeAggregator} value={broker.aggregatorUuid}>
                        <Select.Option value={null}>None</Select.Option>
                        {_.sortBy(aggregators, ['name']).map((aggregator) => <Select.Option key={aggregator.uuid} value={aggregator.uuid}>{aggregator.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label='LinkedIn URL' className='linkedin-url'>
                    <Input onChange={this.onChangeLinkedInUrl} value={broker.linkedInUrl} />
                </Form.Item>
                <Form.Item label='Has Indemnity Insurance' className='has-indemnity-insurance'>
                    <Checkbox checked={broker.hasIndemnityInsurance} onChange={this.onChangeHasIndemnityInsurance} />
                </Form.Item>
                <Form.Item label='Has Been Convicted' className='has-been-convicted'>
                    <Checkbox checked={broker.hasBeenConvicted} onChange={this.onChangeHasBeenConvicted} />
                </Form.Item>
                <Form.Item label='Has Been Bankrupted' className='has-been-bankrupted'>
                    <Checkbox checked={broker.hasBeenBankrupted} onChange={this.onChangeHasBeenBankrupted} />
                </Form.Item>
            </Layout>
        );
    }

    private onChangeAclNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.brokerValueSet('aclNumber', event.target.value);
    }

    private onChangeAggregator(value: string) {
        this.props.brokerValueSet('aggregatorUuid', value);
    }

    private onChangeCompanyName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.brokerValueSet('companyName', event.target.value);
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.brokerValueSet('email', event.target.value);
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.brokerValueSet('firstName', event.target.value);
    }

    private onChangeHasBeenBankrupted(event: CheckboxChangeEvent) {
        this.props.brokerValueSet('hasBeenBankrupted', event.target.checked);
    }

    private onChangeHasBeenConvicted(event: CheckboxChangeEvent) {
        this.props.brokerValueSet('hasBeenConvicted', event.target.checked);
    }

    private onChangeHasIndemnityInsurance(event: CheckboxChangeEvent) {
        this.props.brokerValueSet('hasIndemnityInsurance', event.target.checked);
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.brokerValueSet('lastName', event.target.value);
    }

    private onChangeLinkedInUrl(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.brokerValueSet('linkedInUrl', event.target.value);
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.brokerValueSet('phone', event.target.value);
    }

    private onChangePostalAddress(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.brokerValueSet('postalAddress', event.target.value);
    }

    private onChangePostcode(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.brokerValueSet('postcode', event.target.value);
    }

    private onChangeState(value: StateEnum) {
        this.props.brokerValueSet('state', value);
    }

    private onChangeSuburb(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.brokerValueSet('suburb', event.target.value);
    }

    private onChangeType(value: BrokerTypeEnum) {
        this.props.brokerValueSet('type', value);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        aggregators: aggregatorsSelector(state),
        broker: brokerSelector(state, ownProps.match.params.brokerUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        aggregatorsList: () => dispatch(aggregatorsListAction()),
        brokerGet: () => dispatch(brokerGetAction(ownProps.match.params.brokerUuid)),
        brokerValueSet: (key: keyof IBroker, value: any) => dispatch(brokerValueSetAction(ownProps.match.params.brokerUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Edit);
