import IHistory from './IHistory';
import IUser from './IUser';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseUser(raw: any): IUser {
    return {
        createdTime: raw.createdTime,
        email: raw.email,
        firstName: raw.firstName,
        isBlocked: raw.isBlocked,
        isSuspicious: raw.isSuspicious,
        lastName: raw.lastName,
        phone: raw.phone,
        role: raw.role,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseUserHistory(raw: any): IHistory {
    return {
        createdTime: raw.createdTime,
        description: raw.description,
        uuid: raw.uuid,
    };
}
