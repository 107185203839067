import { Layout as AntLayout, Breadcrumb, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';

export default function Layout(props: {
    breadcrumb?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    section: string;
}): ReactElement {
    const { breadcrumb, children, section } = props;

    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);

    const menuItems: ItemType[] = [
        ...(currentUser.permissions.includes(PermissionsEnum.Brokers) ? [
            {
                key: 'brokers',
                label: <NavLink to='/brokers'>Brokers</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsApplicationsTimeInStatus) ? [
            {
                key: 'applications-time-in-status',
                label: <NavLink to='/reports/applications-time-in-status'>Applications - Time In Status</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsBrokersExport) ? [
            {
                key: 'brokers-export',
                label: <NavLink to='/reports/brokers-export'>Brokers - Export</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsInvestmentsExport) ? [
            {
                key: 'investments-export',
                label: <NavLink to='/reports/investments-export'>Investments - Export</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsInvestorsFinRegister) ? [
            {
                key: 'investors-fin-register',
                label: <NavLink to='/reports/investors-fin-register'>Investors - FIN Register</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsInvestorsFitCashBalances) ? [
            {
                key: 'investors-fit-cash-balances',
                label: <NavLink to='/reports/investors-fit-cash-balances'>Investors - FIT Cash Balances</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsInvestorsFitCashTransactions) ? [
            {
                key: 'investors-fit-cash-transactions',
                label: <NavLink to='/reports/investors-fit-cash-transactions'>Investors - FIT Cash Transactions</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsInvestorsFitInvestmentBalances) ? [
            {
                key: 'investors-fit-investment-balances',
                label: <NavLink to='/reports/investors-fit-investment-balances'>Investors - FIT Investment Balances</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsInvestorsTfn) ? [
            {
                key: 'investors-tfn',
                label: <NavLink to='/reports/investors-tfn'>Investors - TFN</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsLeadsExport) ? [
            {
                key: 'leads-export',
                label: <NavLink to='/reports/leads-export'>Leads - Export</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsLeadsTimeInStatus) ? [
            {
                key: 'leads-time-in-status',
                label: <NavLink to='/reports/leads-time-in-status'>Leads - Time In Status</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsLoansExport) ? [
            {
                key: 'loans-export',
                label: <NavLink to='/reports/loans-export'>Loans - Export</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsLoansBorrowers) ? [
            {
                key: 'loans-borrower-export',
                label: <NavLink to='/reports/loans-borrower-export'>Loans - Borrowers</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsLoansPayments) ? [
            {
                key: 'loans-payments',
                label: <NavLink to='/reports/loans-payments'>Loans - Payments</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsLoansProperties) ? [
            {
                key: 'loans-property-export',
                label: <NavLink to='/reports/loans-property-export'>Loans - Properties</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsLoansPoolCut) ? [
            {
                key: 'loans-pool-cut',
                label: <NavLink to='/reports/loans-pool-cut'>Loans - Pool Cut Report</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsWarehouseFwt2PoolCut) ? [
            {
                key: 'warehouses-fwt2-pool-cut',
                label: <NavLink to='/reports/warehouses-fwt2-pool-cut'>Warehouses - FWT2 Pool Cut</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.ReportsWarehouseLoanBalances) ? [
            {
                key: 'warehouses-loan-balances',
                label: <NavLink to='/reports/warehouses-loan-balances'>Warehouses - Loan Balances</NavLink>,
            },
        ] : []),
    ];

    return (
        <AntLayout className='accounts'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Reports</Breadcrumb.Item>
                {breadcrumb}
            </Breadcrumb>
            <AntLayout className='content-wrapper'>
                <AntLayout.Sider className='menu' theme='light' width={240}>
                    <Menu
                        defaultSelectedKeys={[section]}
                        items={menuItems}
                        mode='inline'
                    />
                </AntLayout.Sider>
                <AntLayout.Content className={`content ${section}`}>
                    {children}
                </AntLayout.Content>
            </AntLayout>
        </AntLayout>
    );
}
