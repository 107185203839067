import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Space, Spin, Table, Tabs, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import IWarehouseLoanTransaction from '~Api/Warehouse/IWarehouseLoanTransaction';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import {
    warehouseGetAction,
    warehouseLoanGetAction,
    warehouseLoanTransactionsListAction,
} from '~Warehouses/actions';
import {
    warehouseLoanSelector,
    warehouseLoanTransactionsSelector,
    warehouseSelector,
} from '~Warehouses/selectors';
import Layout from './Layout';
import { currencyFormatter } from '~utilities/formatters';
import type { Tab } from 'rc-tabs/lib/interface';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import WarehouseTypeEnum from '~Api/Warehouse/WarehouseTypeEnum';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import { authCurrentUserSelector } from '~Auth/selectors';
import IAuthUser from '~Auth/IAuthUser';
import SellLoanModal from './SellLoanModal';
import ProposeSaleModal from './ProposeSaleModal';
import ModalStateEnum from '~UI/ModalStateEnum';

interface ITransaction {
    amount: number;
    balance: number;
    description: string;
    transactionTime: string;
    uuid: string;
}

export default function Loan(): ReactElement {
    const {
        warehouseLoanUuid,
        warehouseUuid,
    } = useParams<{
        warehouseLoanUuid: string;
        warehouseUuid: string;
    }>();

    const [ isProposeSaleModalOpen, setIsProposeSaleModalOpen ] = useState<ModalStateEnum>(ModalStateEnum.Off);
    const [ isSellLoanModalOpen, setIsSellLoanModalOpen ] = useState<ModalStateEnum>(ModalStateEnum.Off);

    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);
    const warehouse: IWarehouse = useSelector((state: IGlobalState) => warehouseSelector(state, warehouseUuid));
    const warehouseLoan: IWarehouseLoan = useSelector((state: IGlobalState) => warehouseLoanSelector(state, warehouseLoanUuid));
    const warehouseLoanTransactions: IDictionary<IWarehouseLoanTransaction> = useSelector((state: IGlobalState) => warehouseLoanTransactionsSelector(state, warehouseLoanUuid));

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!warehouse) {
            dispatch(warehouseGetAction(warehouseUuid));
        }
    }, [
        dispatch,
        warehouse,
        warehouseUuid,
    ]);

    useEffect(() => {
        if (!warehouseLoan) {
            dispatch(warehouseLoanGetAction(warehouseLoanUuid));
        }
    }, [
        dispatch,
        warehouseLoan,
        warehouseLoanUuid,
    ]);

    useEffect(() => {
        if (!warehouseLoanTransactions) {
            dispatch(warehouseLoanTransactionsListAction(warehouseLoanUuid));
        }
    }, [
        dispatch,
        warehouseLoanTransactions,
        warehouseLoanUuid,
    ]);

    const onClickSellLoan: () => void = useCallback(() => {
        setIsSellLoanModalOpen(ModalStateEnum.Open);
    }, []);

    const onClickSellLoanCancel: () => void = useCallback(() => {
        setIsSellLoanModalOpen(ModalStateEnum.Closed);
    }, []);

    const onClickProposeSale: () => void = useCallback(() => {
        setIsProposeSaleModalOpen(ModalStateEnum.Open);
    }, []);

    const onClickProposeSaleCancel: () => void = useCallback(() => {
        setIsProposeSaleModalOpen(ModalStateEnum.Closed);
    }, []);

    if (!warehouse || !warehouseLoan || !warehouseLoanTransactions) {
        return (
            <Layout uuid={warehouseUuid} section='loans'>
                <Typography.Title level={2}>Warehouse Loan</Typography.Title>
                <Spin/>
            </Layout>
        );
    }

    let interestBalance: number = 0;
    let principalBalance: number = 0;
    const interestTransactions: ITransaction[] = [];
    const principalTransactions: ITransaction[] = [];

    _.each(_.sortBy(warehouseLoanTransactions, ['transactionTime']), (warehouseLoanTransaction: IWarehouseLoanTransaction) => {
        if (warehouseLoanTransaction.amountInterest !== 0) {
            interestBalance += warehouseLoanTransaction.amountInterest;

            interestTransactions.push({
                amount: warehouseLoanTransaction.amountInterest,
                balance: interestBalance,
                description: warehouseLoanTransaction.description,
                transactionTime: warehouseLoanTransaction.transactionTime,
                uuid: warehouseLoanTransaction.uuid,
            });
        }

        if (warehouseLoanTransaction.amountPrincipal !== 0) {
            principalBalance += warehouseLoanTransaction.amountPrincipal;

            principalTransactions.push({
                amount: warehouseLoanTransaction.amountPrincipal,
                balance: principalBalance,
                description: warehouseLoanTransaction.description,
                transactionTime: warehouseLoanTransaction.transactionTime,
                uuid: warehouseLoanTransaction.uuid,
            });
        }
    });

    const columns: ColumnsType<ITransaction> = [
        {
            dataIndex: 'transactionTime',
            defaultSortOrder: 'descend',
            render: (transactionTime: string) => dayjs(transactionTime).format('D/M/YYYY HH:mm'),
            sorter: (a: ITransaction, b: ITransaction) => {
                return a.transactionTime > b.transactionTime ? 1 : -1;
            },
            title: 'Date',
            width: '15%',
        },
        {
            dataIndex: 'description',
            title: 'Description',
        },
        {
            dataIndex: 'amount',
            render: (amount: number) => currencyFormatter.format(amount),
            title: 'Amount',
            width: '15%',
        },
        {
            dataIndex: 'balance',
            render: (balance: number) => currencyFormatter.format(balance),
            title: 'Balance',
            width: '15%',
        },
    ];

    const tabItems: Tab[] = [
        {
            children: (
                <Table
                    columns={columns}
                    dataSource={principalTransactions}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                />
            ),
            key: 'principal',
            label: 'Principal',
        },
    ];

    let sellBlock: JSX.Element = null;
    if (warehouseLoan.balancePrincipal < 0 && currentUser.permissions.includes(PermissionsEnum.WarehouseLoanSell) && ![WarehouseTypeEnum.Private].includes(warehouse.type)) {
        const menu: MenuProps = {
            items: [
                {
                    key: 'sell-loan',
                    label: 'Sell Loan',
                    onClick: onClickSellLoan,
                },
            ],
        };

        sellBlock = (
            <Dropdown.Button
                menu={menu}
                type='primary'
                onClick={onClickProposeSale}
            >Propose Sale</Dropdown.Button>
        );
    }

    const sellLoanModalBlock:JSX.Element = isSellLoanModalOpen !== ModalStateEnum.Off && (
        <SellLoanModal
            isOpen={ModalStateEnum.Open === isSellLoanModalOpen}
            onCancel={onClickSellLoanCancel}
            warehouseLoanUuid={warehouseLoanUuid}
        />
    );

    const proposeSaleModalBlock:JSX.Element = isProposeSaleModalOpen !== ModalStateEnum.Off && (
        <ProposeSaleModal
            isOpen={ModalStateEnum.Open === isProposeSaleModalOpen}
            onCancel={onClickProposeSaleCancel}
            warehouseLoanUuid={warehouseLoanUuid}
        />
    );

    return (
        <Layout uuid={warehouseUuid} section='loans'>
            <Space className='actions'>
                {sellBlock}
                <Link to={`/loans/${warehouseLoan.loanUuid}`}><Button><ArrowRightOutlined/> Loan</Button></Link>
            </Space>
            <Typography.Title level={2}>{warehouseLoan.loan ? <>{warehouseLoan.loan.code} {warehouseLoan.loan.salesforceCode && warehouseLoan.loan.salesforceCode !== warehouseLoan.loan.code && `(${warehouseLoan.loan.salesforceCode})`}</> : '-'}</Typography.Title>
            <Tabs
                defaultActiveKey='principal'
                items={tabItems}
            />
            {sellLoanModalBlock}
            {proposeSaleModalBlock}
        </Layout>
    );
}
