import { Form, Modal, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CloseReasonEnum from '~Api/Application/CloseReasonEnum';
import IApplication from '~Api/Application/IApplication';
import { applicationCloseAction } from '~Applications/actions';
import { IDictionary } from '~utilities/IDictionary';

const reasonLabels: IDictionary<string> = {
    [CloseReasonEnum.Cancelled]: 'Cancel Application and Reopen Lead',
    [CloseReasonEnum.Cold]: 'Cold/Stale',
    [CloseReasonEnum.Credit]: 'Credit Issue',
    [CloseReasonEnum.LostToOther]: 'Lost to Other',
    [CloseReasonEnum.Valuation]: 'Valuation Issue',
};

interface IState {
    error: string;
    note: string;
    reason: CloseReasonEnum;
}

interface IProps {
    isOpen: boolean;
    application: IApplication;
    onCancel: () => void;
}

interface IPropsDispatch {
    close: (reason: CloseReasonEnum, note: string) => void;
}

type Props = IProps & IPropsDispatch;

class CloseModal extends React.Component<Props, IState> {
    public state: IState = {
        error: null,
        note: null,
        reason: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeReason = this.onChangeReason.bind(this);
        this.onChangeNote = this.onChangeNote.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { error, reason } = this.state;
        const { note } = this.state;

        return (
            <Modal
                okText='Close'
                okType='danger'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Close'
                wrapClassName='application-close-modal'
            >
                <Form.Item className='reason' label='Reason' help={error} validateStatus={error && 'error'}>
                    <Select onChange={this.onChangeReason} value={reason}>
                        {_.keys(reasonLabels).map((reasonKey: string) => <Select.Option key={reasonKey} value={reasonKey}>{reasonLabels[reasonKey]}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label='More Details' className='note'>
                    <TextArea onChange={this.onChangeNote} value={note} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeReason(value: CloseReasonEnum) {
        this.setState({
            reason: value,
        });
    }

    private onChangeNote(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            note: event.target.value,
        });
    }

    private onClickOk() {
        const { reason } = this.state;
        const { note } = this.state;

        if (!this.validateClose()){
            return;
        }

        this.props.onCancel();
        this.props.close(reason, note);
    }

    private validateClose(): boolean {
        const { reason } = this.state;

        let error: string;

        if (!reason) {
            error = 'Please select a reason';
        }

        this.setState({
            error,
        });

        return !error;
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        close: (reason: CloseReasonEnum, note: string) => dispatch(applicationCloseAction(ownProps.application.uuid, reason, note)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(CloseModal);
