import _ from 'lodash';
import { AnyAction } from 'redux';
import IDistribution from '~Api/IncomeTrust/IDistribution';
import IHistory from '~Api/Investment/IHistory';
import IInvestment from '~Api/Investment/IInvestment';
import IInvestmentTransaction from '~Api/Investment/IInvestmentTransaction';
import IPendingDistribution from '~Api/Investment/IPendingDistribution';
import WorkflowStatusEnum from '~Api/Investment/WorkflowStatusEnum';
import IInvestorAccountInvestment from '~Api/Investor/IInvestorAccountInvestment';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import {
    IInvestorAccountInvestmentSetAction,
    IInvestorAccountInvestmentsSetAction,
} from '~Investors/actions';
import InvestorsActionsEnum from '~Investors/ActionsEnum';
import { IDictionary } from '~utilities/IDictionary';
import {
    IInvestmentHideAction,
    IInvestmentHistoriesSetAction,
    IInvestmentIncomeTrustDistributionsSetAction,
    IInvestmentIncomeTrustInvestmentRequestCancelAction,
    IInvestmentIncomeTrustInvestmentRequestProcessAction,
    IInvestmentIncomeTrustInvestmentRequestsPendingSetAction,
    IInvestmentIncomeTrustTransactionInvestorsSetAction,
    IInvestmentIncomeTrustTransactionsSetAction,
    IInvestmentInvestorsSetAction,
    IInvestmentPendingDistributionProcessAction,
    IInvestmentPendingDistributionsSetAction,
    IInvestmentPhotoInProgressSetAction,
    IInvestmentReservationsSetAction,
    IInvestmentSetAction,
    IInvestmentTransactionInvestorsSetAction,
    IInvestmentTransactionSetAction,
    IInvestmentTransactionsSetAction,
    IInvestmentValueSetAction,
    IInvestmentsDashboardSetAction,
    IInvestmentsSearchResultsSetAction,
    IInvestmentsSetAction,
} from './actions';
import InvestmentsActionsEnum from './ActionsEnum';
import IIncomeTrustTransaction from '~Api/IncomeTrust/IIncomeTrustTransaction';
import IInvestorIncomeTrustTransaction from '~Api/IncomeTrust/IInvestorIncomeTrustTransaction';
import IReservation from '~Api/Investment/IReservation';
import InvestmentSaleTransfersActionsEnum from '~InvestmentSaleTransfers/ActionsEnum';
import {
    IInvestmentSaleTransferSetAction,
    IInvestmentSaleTransfersSetAction,
    IInvestmentSaleTransfersUnallocatedInvestmentsSetAction,
} from '~InvestmentSaleTransfers/actions';
import IInvestmentSaleTransfer from '~Api/InvestmentSaleTransfer/IInvestmentSaleTransfer';
import IUnallocatedInvestment from '~Api/InvestmentSaleTransfer/IUnallocatedInvestment';
import IIncomeTrustInvestmentRequest from '~Api/IncomeTrust/IIncomeTrustInvestmentRequest';

export interface IInvestmentsState {
    dashboardUuids: string[];
    histories: IDictionary<IDictionary<IHistory>>;
    incomeTrustDistributions: IDictionary<IDistribution[]>;
    incomeTrustTransactions: IDictionary<IIncomeTrustTransaction>;
    investmentInvestorAccountInvestmentUuids: IDictionary<string[]>;
    investmentReservationUuids: IDictionary<string[]>;
    investmentTransactionInvestorAccountInvestmentTransactionUuids: IDictionary<string[]>;
    investmentTransactionUuids: IDictionary<string[]>;
    investments: IDictionary<IInvestment>;
    investmentsListed: boolean;
    investmentsSearchResultUuids: string[];
    investorIncomeTrustTransactions: IDictionary<IInvestorIncomeTrustTransaction[]>;
    pendingDistributions: IDictionary<IPendingDistribution>;
    pendingIncomeTrustInvestmentRequests: IDictionary<IIncomeTrustInvestmentRequest>,
    photoInProgress: boolean;
    reservations: IDictionary<IReservation>;
    transactions: IDictionary<IInvestmentTransaction>;
}

const initialData: IInvestmentsState = {
    dashboardUuids: null,
    histories: {},
    incomeTrustDistributions: {},
    incomeTrustTransactions: null,
    investmentInvestorAccountInvestmentUuids: {},
    investmentReservationUuids: {},
    investmentTransactionInvestorAccountInvestmentTransactionUuids: {},
    investmentTransactionUuids: {},
    investments: null,
    investmentsListed: false,
    investmentsSearchResultUuids: null,
    investorIncomeTrustTransactions: {},
    pendingDistributions: null,
    pendingIncomeTrustInvestmentRequests: null,
    photoInProgress: false,
    reservations: {},
    transactions: {},
};

export function investmentsReducer(state: IInvestmentsState = initialData, action: AnyAction): IInvestmentsState {
    switch (action.type) {
        case InvestmentsActionsEnum.InvestmentIncomeTrustDistributionsSet: {
            const typedAction: IInvestmentIncomeTrustDistributionsSetAction = action as IInvestmentIncomeTrustDistributionsSetAction;

            return {
                ...state,
                incomeTrustDistributions: {
                    ...state.incomeTrustDistributions,
                    [typedAction.classType]: typedAction.distributions,
                },
            };
        }

        case InvestmentsActionsEnum.InvestmentIncomeTrustInvestmentRequestCancel: {
            const typedAction: IInvestmentIncomeTrustInvestmentRequestCancelAction = action as IInvestmentIncomeTrustInvestmentRequestCancelAction;

            return {
                ...state,
                pendingIncomeTrustInvestmentRequests: _.omit(state.pendingIncomeTrustInvestmentRequests, typedAction.incomeTrustInvestmentRequestUuid),
            };
        }

        case InvestmentsActionsEnum.InvestmentIncomeTrustInvestmentRequestProcess: {
            const typedAction: IInvestmentIncomeTrustInvestmentRequestProcessAction = action as IInvestmentIncomeTrustInvestmentRequestProcessAction;

            return {
                ...state,
                pendingIncomeTrustInvestmentRequests: _.omit(state.pendingIncomeTrustInvestmentRequests, typedAction.incomeTrustInvestmentRequestUuid),
            };
        }

        case InvestmentsActionsEnum.InvestmentIncomeTrustInvestmentRequestsPendingSet: {
            const typedAction: IInvestmentIncomeTrustInvestmentRequestsPendingSetAction = action as IInvestmentIncomeTrustInvestmentRequestsPendingSetAction;

            return {
                ...state,
                pendingIncomeTrustInvestmentRequests: _.keyBy(typedAction.incomeTrustInvestmentRequests, 'uuid'),
            };
        }

        case InvestmentsActionsEnum.InvestmentIncomeTrustTransactionsSet: {
            const typedAction: IInvestmentIncomeTrustTransactionsSetAction = action as IInvestmentIncomeTrustTransactionsSetAction;

            return {
                ...state,
                incomeTrustTransactions: _.keyBy(typedAction.transactions, 'uuid'),
            };
        }

        case InvestmentsActionsEnum.InvestmentIncomeTrustTransactionInvestorsSet: {
            const typedAction: IInvestmentIncomeTrustTransactionInvestorsSetAction = action as IInvestmentIncomeTrustTransactionInvestorsSetAction;

            return {
                ...state,
                investorIncomeTrustTransactions: {
                    ...state.investorIncomeTrustTransactions,
                    [typedAction.incomeTrustTransactionUuid]: typedAction.transactions,
                },
            };
        }

        case InvestmentsActionsEnum.InvestmentHide: {
            const typedAction: IInvestmentHideAction = action as IInvestmentHideAction;

            return {
                ...state,
                investments: {
                    ...state.investments,
                    [typedAction.uuid]: {
                        ...state.investments[typedAction.uuid],
                        workflowStatus: WorkflowStatusEnum.Ready,
                    },
                },
            };
        }

        case InvestmentsActionsEnum.InvestmentPendingDistributionsSet: {
            const typedAction: IInvestmentPendingDistributionsSetAction = action as IInvestmentPendingDistributionsSetAction;

            const investments: IDictionary<IInvestment> = {};
            const pendingDistributions: IDictionary<IPendingDistribution> = {};

            typedAction.pendingDistributions.forEach((pendingDistribution: IPendingDistribution) => {
                investments[pendingDistribution.investment.uuid] = pendingDistribution.investment;
                pendingDistributions[pendingDistribution.warehouseLoanTransactionUuid] = _.omit(pendingDistribution, ['investment']);
            });

            return {
                ...state,
                investments: {
                    ...state.investments,
                    ...investments,
                },
                pendingDistributions,
            };
        }

        case InvestmentsActionsEnum.InvestmentPendingDistributionProcess: {
            const typedAction: IInvestmentPendingDistributionProcessAction = action as IInvestmentPendingDistributionProcessAction;

            return {
                ...state,
                pendingDistributions: _.omit(state.pendingDistributions, typedAction.warehouseLoanTransactionUuid),
            };
        }

        case InvestmentsActionsEnum.InvestmentPhotoInProgressSet: {
            const typedAction: IInvestmentPhotoInProgressSetAction = action as IInvestmentPhotoInProgressSetAction;

            return {
                ...state,
                photoInProgress: typedAction.inProgress,
            };
        }

        case InvestmentsActionsEnum.InvestmentReservationsSet: {
            const typedAction: IInvestmentReservationsSetAction = action as IInvestmentReservationsSetAction;

            const reservations: IDictionary<IReservation> = {};
            const reservationUuids: string[] = [];

            typedAction.reservations.forEach((reservation: IReservation) => {
                reservations[reservation.uuid] = reservation;
                reservationUuids.push(reservation.uuid);
            });

            return {
                ...state,
                investmentReservationUuids: {
                    ...state.investmentReservationUuids,
                    [typedAction.investmentUuid]: reservationUuids,
                },
                reservations: {
                    ...state.reservations,
                    ...reservations,
                },
            };
        }

        case InvestmentsActionsEnum.InvestmentSet: {
            const typedAction: IInvestmentSetAction = action as IInvestmentSetAction;

            return {
                ...state,
                investments: {
                    ...state.investments,
                    [typedAction.investment.uuid]: typedAction.investment,
                },
            };
        }

        case InvestmentsActionsEnum.InvestmentHistoriesSet: {
            const typedAction: IInvestmentHistoriesSetAction = action as IInvestmentHistoriesSetAction;

            return {
                ...state,
                histories: {
                    ...state.histories,
                    [typedAction.uuid]: _.keyBy(typedAction.histories, 'uuid'),
                },
            };
        }

        case InvestmentsActionsEnum.InvestmentTransactionsSet: {
            const typedAction: IInvestmentTransactionsSetAction = action as IInvestmentTransactionsSetAction;

            const investmentTransactionUuids: string[] = [];
            const transactions: IDictionary<IInvestmentTransaction> = {};

            typedAction.transactions.forEach((transaction: IInvestmentTransaction) => {
                investmentTransactionUuids.push(transaction.uuid);
                transactions[transaction.uuid] = transaction;
            });

            return {
                ...state,
                investmentTransactionUuids: {
                    ...state.investmentTransactionUuids,
                    [typedAction.investmentUuid]: investmentTransactionUuids,
                },
                transactions: {
                    ...state.transactions,
                    ...transactions,
                },
            };
        }

        case InvestmentsActionsEnum.InvestmentTransactionSet: {
            const typedAction: IInvestmentTransactionSetAction = action as IInvestmentTransactionSetAction;

            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    [typedAction.transaction.uuid]: typedAction.transaction,
                },
            };
        }

        case InvestmentsActionsEnum.InvestmentTransactionInvestorsSet: {
            const typedAction: IInvestmentTransactionInvestorsSetAction = action as IInvestmentTransactionInvestorsSetAction;

            return {
                ...state,
                investmentTransactionInvestorAccountInvestmentTransactionUuids: {
                    ...state.investmentTransactionInvestorAccountInvestmentTransactionUuids,
                    [typedAction.investmentTransactionUuid]: typedAction.transactions.map((transaction: IInvestorAccountInvestmentTransaction) => transaction.uuid),
                },
            };
        }

        case InvestmentsActionsEnum.InvestmentValueSet: {
            const typedAction: IInvestmentValueSetAction = action as IInvestmentValueSetAction;

            return {
                ...state,
                investments: {
                    ...state.investments,
                    [typedAction.uuid]: {
                        ...state.investments[typedAction.uuid],
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        case InvestmentsActionsEnum.InvestmentsDashboardSet: {
            const typedAction: IInvestmentsDashboardSetAction = action as IInvestmentsDashboardSetAction;

            return {
                ...state,
                dashboardUuids: _.map(typedAction.investments, (investment: IInvestment) => investment.uuid),
                investments: {
                    ...state.investments,
                    ..._.keyBy(typedAction.investments, 'uuid'),
                },
            };
        }

        case InvestmentsActionsEnum.InvestmentsSearchResultsSet: {
            const typedAction: IInvestmentsSearchResultsSetAction = action as IInvestmentsSearchResultsSetAction;

            return {
                ...state,
                investments: {
                    ...state.investments,
                    ..._.keyBy(typedAction.investments, 'uuid'),
                },
                investmentsSearchResultUuids: _.map(typedAction.investments, (investment: IInvestment) => investment.uuid),
            };
        }

        case InvestmentsActionsEnum.InvestmentsSearchResultsClear: {
            return {
                ...state,
                investmentsSearchResultUuids: [],
            };
        }

        case InvestmentsActionsEnum.InvestmentsSet: {
            const typedAction: IInvestmentsSetAction = action as IInvestmentsSetAction;

            const investments: { [uuid: string]: IInvestment } = {};

            typedAction.investments.forEach((investment: IInvestment) => {
                investments[investment.uuid] = investment;
            });

            return {
                ...state,
                investments,
                investmentsListed: true,
            };
        }

        case InvestmentsActionsEnum.InvestmentInvestorsSet: {
            const typedAction: IInvestmentInvestorsSetAction = action as IInvestmentInvestorsSetAction;

            return {
                ...state,
                investmentInvestorAccountInvestmentUuids: {
                    ...state.investmentInvestorAccountInvestmentUuids,
                    [typedAction.investmentUuid]: _.map(typedAction.investorAccountInvestments, (investorAccountInvestment: IInvestorAccountInvestment) => investorAccountInvestment.uuid),
                },
            };
        }

        case InvestorsActionsEnum.InvestorAccountInvestmentSet: {
            const typedAction: IInvestorAccountInvestmentSetAction = action as IInvestorAccountInvestmentSetAction;

            return {
                ...state,
                investments: {
                    ...state.investments,
                    [typedAction.investorAccountInvestment.investment.uuid]: typedAction.investorAccountInvestment.investment,
                },
            };
        }

        case InvestorsActionsEnum.InvestorAccountInvestmentsSet: {
            const typedAction: IInvestorAccountInvestmentsSetAction = action as IInvestorAccountInvestmentsSetAction;

            const investments: IDictionary<IInvestment> = { ...state.investments };

            typedAction.investorAccountInvestments.forEach((investorAccountInvestment: IInvestorAccountInvestment) => {
                investments[investorAccountInvestment.investment.uuid] = investorAccountInvestment.investment;
            });

            return {
                ...state,
                investments,
            };
        }

        case InvestmentSaleTransfersActionsEnum.InvestmentSaleTransferSet: {
            const typedAction: IInvestmentSaleTransferSetAction = action as IInvestmentSaleTransferSetAction;

            return {
                ...state,
                investments: {
                    ...state.investments,
                    [typedAction.investmentSaleTransfer.investmentUuid]: typedAction.investmentSaleTransfer.investment,
                },
            };
        }

        case InvestmentSaleTransfersActionsEnum.InvestmentSaleTransfersSet: {
            const typedAction: IInvestmentSaleTransfersSetAction = action as IInvestmentSaleTransfersSetAction;

            const investments: IDictionary<IInvestment> = { ...state.investments };

            _.each(typedAction.investmentSaleTransfers, (investmentSaleTransfer: IInvestmentSaleTransfer) => {
                investments[investmentSaleTransfer.investmentUuid] = investmentSaleTransfer.investment;
            });

            return {
                ...state,
                investments,
            };
        }

        case InvestmentSaleTransfersActionsEnum.InvestmentSaleTransfersUnallocatedInvestmentsSet: {
            const typedAction: IInvestmentSaleTransfersUnallocatedInvestmentsSetAction = action as IInvestmentSaleTransfersUnallocatedInvestmentsSetAction;

            const investments: IDictionary<IInvestment> = { ...state.investments };

            _.each(typedAction.unallocatedInvestments, (unallocatedInvestment: IUnallocatedInvestment) => {
                investments[unallocatedInvestment.investmentUuid] = unallocatedInvestment.investment;
            });

            return {
                ...state,
                investments,
            };
        }

        default:
            return state;
    }
}
