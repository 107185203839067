import { AnyAction } from 'redux';
import { IDictionary } from '~utilities/IDictionary';
import { ISolicitorsSetAction } from './actions';
import SolicitorsActionsEnum from './ActionsEnum';
import ISolicitor from './ISolicitor';

export interface ISolicitorsState {
    solicitors: IDictionary<ISolicitor>;
}

const initialData: ISolicitorsState = {
    solicitors: null,
};

export function solicitorsReducer(state: ISolicitorsState = initialData, action: AnyAction): ISolicitorsState {
    switch (action.type) {
        case SolicitorsActionsEnum.SolicitorsSet: {
            const typedAction: ISolicitorsSetAction = action as ISolicitorsSetAction;

            const solicitors: IDictionary<ISolicitor> = {};
            typedAction.solicitors.forEach((solicitor: ISolicitor) => {
                solicitors[solicitor.uuid] = solicitor;
            });

            return {
                ...state,
                solicitors,
            };
        }

        default:
            return state;
    }
}
