import { Modal, Spin, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestmentTransaction from '~Api/Investment/IInvestmentTransaction';
import IInvestor from '~Api/Investor/IInvestor';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import {
    investmentTransactionGetAction,
    investmentTransactionInvestorsListAction,
} from '~Investments/actions';
import {
    investmentTransactionInvestorsSelector,
    investmentTransactionSelector,
} from '~Investments/selectors';
import { investorsListAction } from '~Investors/actions';
import { investorsSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

interface IProps {
    investmentTransactionUuid: string;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsSelector {
    investorTransactions: IDictionary<IInvestorAccountInvestmentTransaction>;
    investors: IDictionary<IInvestor>;
    transaction: IInvestmentTransaction;
}

interface IPropsDispatch {
    investorTransactionsList: () => void;
    investorsList: () => void;
    transactionGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class TransactionModal extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.summary = this.summary.bind(this);
    }

    public componentDidMount(): void {
        const { investorTransactions, investors, transaction } = this.props;

        if (!investorTransactions) {
            this.props.investorTransactionsList();
        }

        if (!investors) {
            this.props.investorsList();
        }

        if (!transaction) {
            this.props.transactionGet();
        }
    }

    public render(): JSX.Element {
        const { investorTransactions, investors, isOpen, transaction } = this.props;

        if (!investorTransactions || !investors || !transaction) {
            return (
                <Modal
                    footer={false}
                    onCancel={this.props.onCancel}
                    open={isOpen}
                    title='Transaction Details'
                    width={1000}
                >
                    <Spin/>
                </Modal>
            );
        }

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnType<IInvestorAccountInvestmentTransaction>[] = [
            {
                dataIndex: 'investorUuid',
                defaultSortOrder: 'ascend',
                render: (investorUuid: string) => investors[investorUuid] ? <Link to={`/investors/${investorUuid}`}>{investors[investorUuid].name}</Link> : '-',
                sorter: (a: IInvestorAccountInvestmentTransaction, b: IInvestorAccountInvestmentTransaction) => {
                    return investors[a.investorUuid].name.localeCompare(investors[b.investorUuid].name);
                },
                title: 'Investor',
            },
            {
                dataIndex: 'principalAmount',
                render: (principalAmount: number) => currencyFormatter.format(principalAmount),
                sorter: (a: IInvestorAccountInvestmentTransaction, b: IInvestorAccountInvestmentTransaction) => {
                    return a.principalAmount > b.principalAmount ? 1 : -1;
                },
                title: 'Principal',
                width: '20%',
            },
            {
                dataIndex: 'interestAmount',
                render: (interestAmount: number) => currencyFormatter.format(interestAmount),
                sorter: (a: IInvestorAccountInvestmentTransaction, b: IInvestorAccountInvestmentTransaction) => {
                    return a.interestAmount > b.interestAmount ? 1 : -1;
                },
                title: 'Interest',
                width: '20%',
            },
        ];

        return (
            <Modal
                footer={false}
                onCancel={this.props.onCancel}
                open={isOpen}
                title='Transaction Details'
                width={1000}
            >
                <Table
                    columns={columns}
                    dataSource={_.values(investorTransactions)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                    summary={this.summary}
                />
            </Modal>
        );
    }

    private summary(): JSX.Element {
        const { investorTransactions } = this.props;

        let investorCount: number = 0;
        let principalTotal: number = 0;
        let interestTotal: number = 0;

        _.forEach(investorTransactions, (investorTransaction: IInvestorAccountInvestmentTransaction) => {
            investorCount++;
            principalTotal += investorTransaction.principalAmount;
            interestTotal += investorTransaction.interestAmount;
        });

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}><strong>{investorCount}</strong></Table.Summary.Cell>
                <Table.Summary.Cell index={1}><strong>{currencyFormatter.format(principalTotal)}</strong></Table.Summary.Cell>
                <Table.Summary.Cell index={2}><strong>{currencyFormatter.format(interestTotal)}</strong></Table.Summary.Cell>
            </Table.Summary.Row>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investorTransactions: investmentTransactionInvestorsSelector(state, ownProps.investmentTransactionUuid),
        investors: investorsSelector(state),
        transaction: investmentTransactionSelector(state, ownProps.investmentTransactionUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investorTransactionsList: () => dispatch(investmentTransactionInvestorsListAction(ownProps.investmentTransactionUuid)),
        investorsList: () => dispatch(investorsListAction()),
        transactionGet: () => dispatch(investmentTransactionGetAction(ownProps.investmentTransactionUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TransactionModal);
