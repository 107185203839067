import { CheckOutlined, ClockCircleOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Layout, Space, Spin, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import { administratorsSelector } from '~Administrators/selectors';
import { aggregatorsListAction } from '~Aggregators/actions';
import IAggregator from '~Api/Aggregator/IAggregator';
import { aggregatorsSelector } from '~Aggregators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import ApprovalStatusEnum from '~Api/Broker/ApprovalStatusEnum';
import IBroker from '~Api/Broker/IBroker';
import StateEnum from '~Api/Broker/StateEnum';
import constants from '~constants';
import { IGlobalState } from '~reducer';
import SearchDropdown from '~UI/SearchDropdown';
import { IDictionary } from '~utilities/IDictionary';
import { brokersListAction } from './actions';
import AddModal from './AddModal';
import Search from './Search';
import './brokers.less';
import NextContact from './NextContact';
import { brokersSelector } from './selectors';
import { ColumnType } from 'antd/lib/table';
import { FilterDropdownProps } from 'antd/lib/table/interface';

interface IState {
    isAddModalOpen: boolean;
}

interface IPropsSelector {
    administrators: IDictionary<IAdministrator>;
    aggregators: IDictionary<IAggregator>;
    brokers: IDictionary<IBroker>;
}

interface IPropsDispatch {
    administratorsList: () => void;
    aggregatorsList: () => void;
    brokersList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class List extends React.Component<Props> {
    public state: IState = {
        isAddModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAdd = this.onClickAdd.bind(this);
        this.onCloseAddModal = this.onCloseAddModal.bind(this);
    }

    public componentDidMount() {
        const { administrators, aggregators, brokers } = this.props;

        if (!administrators) {
            this.props.administratorsList();
        }

        if (!brokers) {
            this.props.brokersList();
        }

        if (!aggregators) {
            this.props.aggregatorsList();
        }
    }

    public render(): JSX.Element {
        const { administrators, aggregators, brokers } = this.props;
        const { isAddModalOpen } = this.state;

        if (!administrators || !brokers || !aggregators) {
            return (
                <Layout className='brokers'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Brokers</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const columns: ColumnType<IBroker>[] = [
            {
                dataIndex: 'approvalStatus',
                defaultFilteredValue: [ApprovalStatusEnum.Approved, ApprovalStatusEnum.Pending],
                filters: [
                    {
                        text: 'Approved',
                        value: ApprovalStatusEnum.Approved,
                    },
                    {
                        text: 'Rejected',
                        value: ApprovalStatusEnum.Rejected,
                    },
                    {
                        text: 'Pending',
                        value: ApprovalStatusEnum.Pending,
                    },
                ],
                onFilter: (value: string | number | boolean, broker: IBroker) => broker.approvalStatus === value,
                render: (approvalStatus: ApprovalStatusEnum) => {
                    if (approvalStatus === ApprovalStatusEnum.Approved) {
                        return <CheckOutlined/>;
                    }

                    if (approvalStatus === ApprovalStatusEnum.Rejected) {
                        return <CloseOutlined/>;
                    }

                    if (approvalStatus === ApprovalStatusEnum.Pending) {
                        return <ClockCircleOutlined/>;
                    }
                },
                width: '5%',
            },
            {
                dataIndex: 'code',
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string | number | boolean, broker: IBroker) => broker.code.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                sorter: (a: IBroker, b: IBroker) => a.code.localeCompare(b.code),
                title: 'Code',
                width: '10%',
            },
            {
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string | number | boolean, broker: IBroker) => `${broker.firstName} ${broker.lastName}`.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                render: (broker: IBroker) => <Link to={`/brokers/${broker.uuid}`}>{broker.firstName} {broker.lastName}</Link>,
                sorter: (a: IBroker, b: IBroker) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
                title: 'Name',
            },
            {
                dataIndex: 'aggregatorUuid',
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string | number | boolean, broker: IBroker) => {
                    const name: string = broker.aggregatorUuid ? aggregators[broker.aggregatorUuid].name : '';
                    return name.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase());
                },
                render: (aggregatorUuid: string) => aggregatorUuid ? aggregators[aggregatorUuid].name : '-',
                sorter: (a: IBroker, b: IBroker) => {
                    const aName: string = a.aggregatorUuid ? aggregators[a.aggregatorUuid].name : '';
                    const bName: string = b.aggregatorUuid ? aggregators[b.aggregatorUuid].name : '';

                    return aName.localeCompare(bName);
                },
                title: 'Aggregator',
                width: '20%',
            },
            {
                dataIndex: 'bdmUuid',
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string | number | boolean, broker: IBroker) => {
                    const name: string = broker.bdmUuid === constants.BROKER_BDM_UUID_HEAD_OFFICE ? 'Head Office' : (broker.bdmUuid ? (_.find(administrators, { uuid: broker.bdmUuid }) as IAdministrator).name : '');
                    return name.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase());
                },
                render: (bdmUuid: string) => bdmUuid === constants.BROKER_BDM_UUID_HEAD_OFFICE ? 'Head Office' : (bdmUuid ? (_.find(administrators, { uuid: bdmUuid }) as IAdministrator).name : '-'),
                sorter: (a: IBroker, b: IBroker) => {
                    const aName: string = a.bdmUuid === constants.BROKER_BDM_UUID_HEAD_OFFICE ? 'Head Office' : (a.bdmUuid ? (_.find(administrators, { uuid: a.bdmUuid }) as IAdministrator).name : '');
                    const bName: string = b.bdmUuid === constants.BROKER_BDM_UUID_HEAD_OFFICE ? 'Head Office' : (b.bdmUuid ? (_.find(administrators, { uuid: b.bdmUuid }) as IAdministrator).name : '');

                    return aName.localeCompare(bName);
                },
                title: 'BDM',
                width: '20%',
            },
            {
                dataIndex: 'state',
                filters: _.values(StateEnum).map((state: StateEnum) => ({
                    text: state,
                    value: state,
                })),
                onFilter: (value: string | number | boolean, broker: IBroker) => broker.state === value,
                title: 'State',
                width: '10%',
            },
            {
                render: (broker: IBroker) => <NextContact broker={broker} />,
                sorter: (a: IBroker, b: IBroker) => {
                    if (!b.contactDateNext) {
                        return 1;
                    }

                    if (!a.contactDateNext) {
                        return -1;
                    }

                    return dayjs(a.contactDateNext) > dayjs(b.contactDateNext) ? 1 : -1;
                },
                title: 'Next Contact',
                width: '15%',
            },
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => dayjs(createdTime).fromNow(),
                sorter: (a: IBroker, b: IBroker) => dayjs(a.createdTime) > dayjs(b.createdTime) ? 1 : -1,
                title: 'Registered',
                width: '10%',
            },
        ];

        return (
            <Layout className='brokers'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Brokers</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Search/>
                            <Link to='/brokers'><Button>Board View</Button></Link>
                            <Link to='/brokers/map'><Button>Map View</Button></Link>
                            <Button onClick={this.onClickAdd}>Add Broker</Button>
                        </Space>
                        <Typography.Title level={2}>Brokers</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.values(brokers)}
                            pagination={{ defaultPageSize: 50 }}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>

                <AddModal
                    isOpen={isAddModalOpen}
                    onCancel={this.onCloseAddModal}
                />
            </Layout>
        );
    }

    private onClickAdd() {
        this.setState({
            isAddModalOpen: true,
        });
    }

    private onCloseAddModal() {
        this.setState({
            isAddModalOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        administrators: administratorsSelector(state),
        aggregators: aggregatorsSelector(state),
        brokers: brokersSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        aggregatorsList: () => dispatch(aggregatorsListAction()),
        brokersList: () => dispatch(brokersListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(List);
