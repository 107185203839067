import { Form, Modal, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import { applicationPropertyValuerQuoteRequestedAction } from '~Applications/actions';
import DatePicker from '~UI/DatePicker';
import TimePicker from '~UI/TimePicker';
import IValuer from '~Valuers/IValuer';

interface IState {
    quoteRequestedDateTime: string;
}

interface IProps {
    applicationProperty: IApplicationProperty;
    isOpen: boolean;
    onCancel: () => void;
    valuer: IValuer;
}

interface IPropsDispatch {
    quoteRequested: (quoteRequestedDateTime: string) => void;
}

type Props = IProps & IPropsDispatch;

class QuoteRequestedModal extends React.Component<Props, IState> {
    public state: IState = {
        quoteRequestedDateTime: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeTime = this.onChangeTime.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen, onCancel } = this.props;
        const { quoteRequestedDateTime } = this.state;

        const quoteRequestedDateTimeDayjs: Dayjs = quoteRequestedDateTime ? dayjs(quoteRequestedDateTime) : null;

        return (
            <Modal
                okText='Quote Requested'
                onCancel={onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Quote Requested'
                wrapClassName='property-valuation-request-quote-modal'
            >
                <Form.Item className='requested-date' label='Request Date'>
                    <Space>
                        <DatePicker onChange={this.onChangeDate} value={quoteRequestedDateTimeDayjs || dayjs()} format='DD/MM/YYYY' />
                        <TimePicker onChange={this.onChangeTime} value={quoteRequestedDateTimeDayjs || dayjs()} format='H:mm' />
                    </Space>
                </Form.Item>
            </Modal>
        );
    }

    private onChangeDate(date: Dayjs) {
        const { quoteRequestedDateTime } = this.state;

        this.setState({
            quoteRequestedDateTime: dayjs(`${date.format('YYYY-MM-DD')} ${dayjs(quoteRequestedDateTime || undefined).format('HH:mm')}`).format(),
        });
    }

    private onChangeTime(time: Dayjs) {
        const { quoteRequestedDateTime } = this.state;

        this.setState({
            quoteRequestedDateTime: dayjs(`${dayjs(quoteRequestedDateTime || undefined).format('YYYY-MM-DD')} ${time.format('HH:mm')}`).format(),
        });
    }

    private onClickOk() {
        const { quoteRequestedDateTime } = this.state;

        this.props.quoteRequested(quoteRequestedDateTime);
        this.props.onCancel();
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        quoteRequested: (quoteRequestedDateTime: string) => dispatch(applicationPropertyValuerQuoteRequestedAction(ownProps.applicationProperty.uuid, ownProps.valuer.uuid, quoteRequestedDateTime)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(QuoteRequestedModal);
