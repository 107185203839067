import { all, call, debounce, put, select, takeEvery, takeLeading } from '@redux-saga/core/effects';
import { notification } from 'antd';
import dayjs from 'dayjs';
import IAdministrator from '~Api/Administrator/IAdministrator';
import IHistory from '~Api/Administrator/IHistory';
import {
    parseAdministrator,
    parseAdministratorHistory,
    parsePasskey,
} from '~Api/Administrator/parsers';
import {
    administratorActivateRequest,
    administratorDeactivateRequest,
    administratorHistoriesListRequest,
    administratorMagicLinkRequestApproveRequest,
    administratorMagicLinkRequestCancelRequest,
    administratorPermissionsRequest,
    administratorUpdateRequest,
    administratorWebauthnDeactivateRequest,
    administratorWebauthnEnableRequest,
    administratorWebauthnOptionsRequest,
    administratorWebauthnsListRequest,
    administratorsAddRequest,
    administratorsListRequest,
} from '~Api/Administrator/requests';
import { IFetchResponse } from '~utilities/fetch';
import { renderNotificationLoadingIcon } from '~utilities/utils';
import {
    IAdministratorActivateAction,
    IAdministratorDeactivateAction,
    IAdministratorFidoAction,
    IAdministratorGetAction,
    IAdministratorHistoriesListAction,
    IAdministratorMagicLinkRequestApproveAction,
    IAdministratorMagicLinkRequestCancelAction,
    IAdministratorPasskeyDeactivateAction,
    IAdministratorPasskeysListAction,
    IAdministratorPermissionsAction,
    IAdministratorSendAction,
    IAdministratorValueSetAction,
    IAdministratorsAddAction,
    IAdministratorsListAction,
    administratorHistoriesSetAction,
    administratorPasskeySetAction,
    administratorPasskeysSetAction,
    administratorSendAction,
    administratorSetAction,
    administratorsSetAction,
} from './actions';
import AdministratorsActionsEnum from './ActionsEnum';
import { administratorSelector } from './selectors';
import {
    IAttestationPublicKeyCredential,
    IPreparedAttestationPublicKeyCredential,
    prepareAttestationPublicKeyCredentials,
    preparePublicKeyCredentialCreationOptions,
} from '~utilities/webauthn';
import IPasskey from '~Api/Administrator/IPasskey';

function* administratorActivate(action: IAdministratorActivateAction): Iterator<unknown> {
    const key: string = `administratorActivate ${action.administratorUuid}`;
    const message: string = 'Activate Administrator';

    notification.open({
        description: 'Activating administrator...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const administratorActivateResponse: IFetchResponse = yield call(administratorActivateRequest, action.administratorUuid);
    const administrator: IAdministrator = parseAdministrator(administratorActivateResponse.body);
    yield put(administratorSetAction(administrator));

    notification.success({
        description: 'The administrator has been activated.',
        duration: 4.5,
        key,
        message,
    });
}

function* administratorDeactivate(action: IAdministratorDeactivateAction): Iterator<unknown> {
    const key: string = `administratorDeactivate ${action.administratorUuid}`;
    const message: string = 'Deactivate Administrator';

    notification.open({
        description: 'Deactivating administrator...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const administratorDeactivateResponse: IFetchResponse = yield call(administratorDeactivateRequest, action.administratorUuid);
    const administrator: IAdministrator = parseAdministrator(administratorDeactivateResponse.body);
    yield put(administratorSetAction(administrator));

    notification.success({
        description: 'The administrator has been deactivated.',
        duration: 4.5,
        key,
        message,
    });
}

function* administratorFido(action: IAdministratorFidoAction): Iterator<unknown> {
    const key: string = `administratorFido ${action.administratorUuid}`;
    const message: string = 'Register Passkey';

    notification.open({
        description: 'Registering passkey...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const administatorWebauthnOptionsResponse: IFetchResponse = yield call(administratorWebauthnOptionsRequest, action.administratorUuid);
    const publicKey: PublicKeyCredentialCreationOptions = preparePublicKeyCredentialCreationOptions(administatorWebauthnOptionsResponse.body);
    const credentials: IAttestationPublicKeyCredential = yield Promise.resolve(navigator.credentials.create({ publicKey }));
    const publicKeyCredential: IPreparedAttestationPublicKeyCredential = prepareAttestationPublicKeyCredentials(credentials);
    const enableResponse: IFetchResponse = yield call(administratorWebauthnEnableRequest, action.administratorUuid, publicKeyCredential);
    const administrator: IAdministrator = parseAdministrator(enableResponse.body);
    yield put(administratorSetAction(administrator));

    notification.success({
        description: 'Passkey has been registered.',
        duration: 4.5,
        key,
        message,
    });
}

function* administratorHistoriesList(action: IAdministratorHistoriesListAction): Iterator<unknown> {
    const rawHistories: IFetchResponse = yield call(administratorHistoriesListRequest, action.uuid);
    const histories: IHistory[] = yield Promise.all(rawHistories.body.map(parseAdministratorHistory));
    yield put(administratorHistoriesSetAction(action.uuid, histories));
}

function* administratorPasskeysList(action: IAdministratorPasskeysListAction): Iterator<unknown> {
    const administratorWebauthnsListResponse: IFetchResponse = yield call(administratorWebauthnsListRequest, action.administratorUuid);
    const passkeys: IPasskey[] = yield Promise.all(administratorWebauthnsListResponse.body.map(parsePasskey));
    yield put(administratorPasskeysSetAction(action.administratorUuid, passkeys));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* administratorGet(action: IAdministratorGetAction): Iterator<unknown> {
    // @TODO: Get individual administrators
    const rawAdministrators: IFetchResponse = yield call(administratorsListRequest);
    const administrators: IAdministrator[] = yield Promise.all(rawAdministrators.body.map(parseAdministrator));
    yield put(administratorsSetAction(administrators));
}

function* administratorMagicLinkRequestApprove(action: IAdministratorMagicLinkRequestApproveAction): Iterator<unknown> {
    const key: string = `administratorMagicLinkRequestApprove ${dayjs().format()}`;
    const message: string = 'Approve Magic Link Request';

    notification.open({
        description: 'Approving Magic Link Request...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const administratorMagicLinkRequestApproveResponse: IFetchResponse = yield call(administratorMagicLinkRequestApproveRequest, action.administratorUuid);
    const administrator: IAdministrator = parseAdministrator(administratorMagicLinkRequestApproveResponse.body);
    yield put(administratorSetAction(administrator));

    notification.success({
        description: 'The Magic Link Request has been approved.',
        duration: 4.5,
        key,
        message,
    });
}

function* administratorMagicLinkRequestCancel(action: IAdministratorMagicLinkRequestCancelAction): Iterator<unknown> {
    const key: string = `administratorMagicLinkRequestCancel ${dayjs().format()}`;
    const message: string = 'Cancel Magic Link Request';

    notification.open({
        description: 'Cancelling Magic Link Request...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const administratorMagicLinkRequestCancelResponse: IFetchResponse = yield call(administratorMagicLinkRequestCancelRequest, action.administratorUuid);
    const administrator: IAdministrator = parseAdministrator(administratorMagicLinkRequestCancelResponse.body);
    yield put(administratorSetAction(administrator));

    notification.success({
        description: 'The Magic Link Request has been cancelled.',
        duration: 4.5,
        key,
        message,
    });
}

function* administratorPasskeyDeactivate(action: IAdministratorPasskeyDeactivateAction): Iterator<unknown> {
    const key: string = `administratorPasskeyDeactivate ${action.administratorPasskeyUuid}`;
    const message: string = 'Deactivate Passkey';

    notification.open({
        description: 'Deactivating passkey...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const passkeyDeactivateResponse: IFetchResponse = yield call(administratorWebauthnDeactivateRequest, action.administratorPasskeyUuid);
    const passkey: IPasskey = parsePasskey(passkeyDeactivateResponse.body);
    yield put(administratorPasskeySetAction(passkey));

    notification.success({
        description: 'The passkey has been deactivated.',
        duration: 4.5,
        key,
        message,
    });
}

function* administratorPermissions(action: IAdministratorPermissionsAction): Iterator<unknown> {
    const key: string = `administratorPermissions ${action.administratorUuid}`;
    const message: string = 'Update Permissions';

    notification.open({
        description: 'Updating permissions...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const permissionsResponse: IFetchResponse = yield call(administratorPermissionsRequest, action.administratorUuid, action.permissions);
    const administrator: IAdministrator = parseAdministrator(permissionsResponse.body);
    yield put(administratorSetAction(administrator));

    notification.success({
        description: 'The permissions have been updated.',
        duration: 4.5,
        key,
        message,
    });
}

function* administratorSend(action: IAdministratorSendAction): Iterator<unknown> {
    const administrator: IAdministrator = yield select(administratorSelector, action.uuid);
    yield call(administratorUpdateRequest, administrator);
}

function* administratorValueSet(action: IAdministratorValueSetAction): Iterator<unknown> {
    yield put(administratorSendAction(action.uuid));
}

function* administratorsAdd(action: IAdministratorsAddAction): Iterator<unknown> {
    const key: string = `administatorsAdd ${dayjs().format()}`;

    notification.open({
        description: 'Adding administrator...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Administrator',
    });

    const administratorsAddResponse: IFetchResponse = yield call(administratorsAddRequest, action.administrator);
    const administrator: IAdministrator = parseAdministrator(administratorsAddResponse.body);
    yield put(administratorSetAction(administrator));

    notification.success({
        description: 'The administrator has been added.',
        duration: 4.5,
        key,
        message: 'Add Administrator',
    });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* administratorsList(action: IAdministratorsListAction): Iterator<unknown> {
    const rawAdministrators: IFetchResponse = yield call(administratorsListRequest);
    const administrators: IAdministrator[] = yield Promise.all(rawAdministrators.body.map(parseAdministrator));
    yield put(administratorsSetAction(administrators));
}

export function* AdministratorsSagas(): Iterator<unknown> {
    yield all([
        takeEvery(AdministratorsActionsEnum.AdministratorsAdd, administratorsAdd),
        takeLeading(AdministratorsActionsEnum.AdministratorsList, administratorsList),

        takeEvery(AdministratorsActionsEnum.AdministratorActivate, administratorActivate),
        takeEvery(AdministratorsActionsEnum.AdministratorDeactivate, administratorDeactivate),
        takeEvery(AdministratorsActionsEnum.AdministratorFido, administratorFido),
        takeEvery(AdministratorsActionsEnum.AdministratorGet, administratorGet),
        takeEvery(AdministratorsActionsEnum.AdministratorMagicLinkRequestApprove, administratorMagicLinkRequestApprove),
        takeEvery(AdministratorsActionsEnum.AdministratorMagicLinkRequestCancel, administratorMagicLinkRequestCancel),
        takeEvery(AdministratorsActionsEnum.AdministratorPermissions, administratorPermissions),
        takeEvery(AdministratorsActionsEnum.AdministratorPasskeyDeactivate, administratorPasskeyDeactivate),
        debounce(500, AdministratorsActionsEnum.AdministratorSend, administratorSend),
        takeEvery(AdministratorsActionsEnum.AdministratorValueSet, administratorValueSet),

        takeEvery(AdministratorsActionsEnum.AdministratorHistoriesList, administratorHistoriesList),

        takeEvery(AdministratorsActionsEnum.AdministratorPasskeysList, administratorPasskeysList),
    ]);
}
