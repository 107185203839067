import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Spin } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import {
    administratorsActiveSelector,
    administratorsSelector,
} from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import RoleEnum from '~Api/Administrator/RoleEnum';
import IInvestor from '~Api/Investor/IInvestor';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import {
    investorAssignImAction,
    investorUnassignImAction,
} from '~Investors/actions';
import { IGlobalState } from '~reducer';
import menuDivider from '~UI/menuDivider';
import { IDictionary } from '~utilities/IDictionary';

interface IProps {
    investor: IInvestor;
}

interface IPropsSelector {
    activeAdministrators: IAdministrator[];
    administrators: IDictionary<IAdministrator>;
    currentUser: IAuthUser;
}

interface IPropsDispatch {
    administratorsList: () => void;
    assign: (administratorUuid: string) => void;
    unassign: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class ImSelector extends React.Component<Props> {
    public componentDidMount() {
        const { activeAdministrators, administrators } = this.props;

        if (!activeAdministrators || !administrators) {
            this.props.administratorsList();
        }
    }

    public render(): JSX.Element {
        const { activeAdministrators, administrators, investor, currentUser } = this.props;

        if (!activeAdministrators || !administrators || !investor) {
            return (
                <Spin/>
            );
        }

        const assignee: IAdministrator = !!investor.imUuid && administrators[investor.imUuid];
        const assigneeName: string = investor.imUuid ? (assignee ? assignee.name : 'Inactive User') : 'None';

        if (!currentUser.permissions.includes(PermissionsEnum.InvestorAssignIm)) {
            return (
                <>{assigneeName}</>
            );
        }

        const dropdown: MenuProps = {
            items: [
                {
                    key: 0,
                    label: 'None',
                    onClick: this.props.unassign,
                },
                menuDivider,
                ...(_.sortBy(_.filter(activeAdministrators, (admin: IAdministrator) => [
                    RoleEnum.InvestorManager,
                    RoleEnum.SeniorInvestorManager,
                ].includes(admin.role)), ['name']).map((administrator: IAdministrator): ItemType => ({
                    key: administrator.uuid,
                    label: administrator.name,
                    onClick: () => this.props.assign(administrator.uuid),
                }))),
            ],
        };

        return (
            <Dropdown menu={dropdown}>
                <a>
                    {assigneeName}
                    {' '}<DownOutlined/>
                </a>
            </Dropdown>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        activeAdministrators: administratorsActiveSelector(state),
        administrators: administratorsSelector(state),
        currentUser: authCurrentUserSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        assign: (administratorUuid: string) => dispatch(investorAssignImAction(ownProps.investor.uuid, administratorUuid)),
        unassign: () => dispatch(investorUnassignImAction(ownProps.investor.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ImSelector);
