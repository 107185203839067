import { Divider, Form, Input, Modal, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import BorrowerCategoryEnum from '~Api/Application/BorrowerCategoryEnum';
import GenderEnum from '~Api/Application/GenderEnum';
import IBorrower from '~Api/Application/IBorrower';
import IDealBorrower from '~Api/Deal/IDealBorrower';
import { applicationBorrowerCompanyTradingHistoryCheckAction } from '~Applications/actions';
import { applicationBorrowersSelector } from '~Applications/selectors';
import { dealBorrowerValueSetAction } from '~Deals/actions';
import { applicationBorrowerDealBorrowerSelector } from '~Deals/selectors';
import { IGlobalState } from '~reducer';
import IConditionComponentProps from './IConditionComponentProps';

interface IState {
    errors: { [fieldName: string] : string };
    isModalOpen: boolean;
}

interface IPropsSelector {
    allBorrowers: IBorrower[];
    dealBorrower: IDealBorrower;
}

interface IPropsDispatch {
    dealBorrowerValueSet: (dealBorrowerUuid: string, key: keyof IDealBorrower, value: boolean|number|string) => void;
    inDepthCompanyTradingHistory: () => void;
}

type Props = IConditionComponentProps & IPropsSelector & IPropsDispatch;

class CompanyTradingHistory extends React.Component<Props, IState> {
    public state: IState = {
        errors: {},
        isModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickSearch = this.onClickSearch.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);

        this.onChangeAbn = this.onChangeAbn.bind(this);
        this.onChangeBusinessName = this.onChangeBusinessName.bind(this);

        this.validateAbn = this.validateAbn.bind(this);
        this.validateAustralianBusinessNumber = this.validateAustralianBusinessNumber.bind(this);
        this.validateAustralianCompanyNumber = this.validateAustralianCompanyNumber.bind(this);
    }

    public render(): JSX.Element {
        const { allBorrowers, dealBorrower } = this.props;
        const { errors, isModalOpen } = this.state;

        if (!allBorrowers || !dealBorrower) {
            return null;
        }

        const columns: ColumnType<IBorrower>[] = [
            {
                dataIndex: 'formattedName',
                title: 'Name',
            },
            {
                dataIndex: 'gender',
                render: (gender: string) => {
                    switch (gender) {
                        case GenderEnum.Female:
                            return 'Female';
                        case GenderEnum.Male:
                            return 'Male';
                        default:
                            return 'Unknown';
                    }
                },
                title: 'Gender',
            },
            {
                dataIndex: 'dob',
                render: (dob: string) => dob ? dayjs(dob).format('D/M/YYYY') : '',
                title: 'DOB',
            },
        ];

        return (
            <React.Fragment>
                <a onClick={this.onClickSearch}><AiOutlineFileSearch/></a>
                <Modal
                    okText='Run History Check'
                    onCancel={this.onClickCancel}
                    onOk={this.onClickOk}
                    open={isModalOpen}
                    title='In-Depth Company Trading History Report'
                    wrapClassName='application-borrower-company-trading-history-modal'
                >
                    <Form.Item className='business-name' label='Business Name'>
                        <Input onChange={this.onChangeBusinessName} value={dealBorrower.businessName} />
                    </Form.Item>
                    <Form.Item className='abn' label='ABN/ACN' help={errors.abn} validateStatus={errors.abn && 'error'}>
                        <Input onBlur={this.validateAbn} onChange={this.onChangeAbn} value={dealBorrower.abn} />
                    </Form.Item>
                    <Divider className='idth-modal-director-divider' />
                    <h3>Company Directors</h3>
                    <Table
                        columns={columns}
                        dataSource={_.orderBy(_.filter(allBorrowers, (director: IBorrower) => dealBorrower.uuid !== director.dealBorrower.uuid && director.dealBorrower.category === BorrowerCategoryEnum.Guarantor), ['formattedName'], ['asc'])}
                        pagination={false}
                        rowKey='uuid'
                        size='small'
                    />
                </Modal>
            </React.Fragment>
        );
    }

    private onClickSearch() {
        this.setState({
            isModalOpen: true,
        });
    }

    private onClickOk() {
        let valid: boolean = true;

        valid = this.validateAbn() && valid;

        if (!valid) {
            return;
        }

        this.setState({
            isModalOpen: false,
        });

        this.props.inDepthCompanyTradingHistory();
    }

    private onClickCancel() {
        this.setState({
            isModalOpen: false,
        });
    }

    private onChangeAbn(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'abn', event.target.value.substr(0, 11));
    }

    private onChangeBusinessName(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'businessName', event.target.value.substr(0, 100));
    }

    private validateAbn(): boolean {
        const { dealBorrower } = this.props;
        const { errors } = this.state;

        let error: string;

        if (!dealBorrower.abn || dealBorrower.abn.length === 0) {
            error = 'Please enter an ABN or ACN';
        } else if (/[^0-9]/.test(dealBorrower.abn)) {
            error = 'Please enter a valid ABN or ACN';
        } else {
            if (dealBorrower.abn.length === 9) {
                error = this.validateAustralianCompanyNumber(dealBorrower.abn);
            } else if (dealBorrower.abn.length === 11) {
                error = this.validateAustralianBusinessNumber(dealBorrower.abn);
            } else {
                error = 'Please enter a valid ABN or ACN';
            }
        }

        this.setState({
            errors: {
                ...errors,
                abn: error,
            },
        });

        return !error;
    }

    private validateAustralianBusinessNumber(abn: string): string {
        const sum1: number = (parseInt(abn[0], 10) - 1) * 10;
        const sum2: number = parseInt(abn[1], 10) * 1;
        const sum3: number = parseInt(abn[2], 10) * 3;
        const sum4: number = parseInt(abn[3], 10) * 5;
        const sum5: number = parseInt(abn[4], 10) * 7;
        const sum6: number = parseInt(abn[5], 10) * 9;
        const sum7: number = parseInt(abn[6], 10) * 11;
        const sum8: number = parseInt(abn[7], 10) * 13;
        const sum9: number = parseInt(abn[8], 10) * 15;
        const sum10: number = parseInt(abn[9], 10) * 17;
        const sum11: number = parseInt(abn[10], 10) * 19;

        const sum: number = sum1 + sum2 + sum3 + sum4 + sum5 + sum6 + sum7 + sum8 + sum9 + sum10 + sum11;
        const remainder: number = sum % 89;

        if (remainder !== 0) {
            return 'Please enter a valid 11 digit ABN';
        }

        return null;
    }

    private validateAustralianCompanyNumber(acn: string): string {
        const sum1: number = parseInt(acn[0], 10) * 8;
        const sum2: number = parseInt(acn[1], 10) * 7;
        const sum3: number = parseInt(acn[2], 10) * 6;
        const sum4: number = parseInt(acn[3], 10) * 5;
        const sum5: number = parseInt(acn[4], 10) * 4;
        const sum6: number = parseInt(acn[5], 10) * 3;
        const sum7: number = parseInt(acn[6], 10) * 2;
        const sum8: number = parseInt(acn[7], 10) * 1;

        const sum: number = sum1 + sum2 + sum3 + sum4 + sum5 + sum6 + sum7 + sum8;
        const remainder: number = sum % 10;
        const checkDigit: number = 10 - remainder === 10 ? 0 : 10 - remainder;

        if (checkDigit !== parseInt(acn[8], 10)) {
            return 'Please enter a valid 9 digit ACN';
        }

        return null;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IConditionComponentProps): IPropsSelector {
    return {
        allBorrowers: applicationBorrowersSelector(state, ownProps.applicationUuid),
        dealBorrower: applicationBorrowerDealBorrowerSelector(state, ownProps.applicationUuid, ownProps.applicationBorrowerUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IConditionComponentProps): IPropsDispatch {
    return {
        dealBorrowerValueSet: (dealBorrowerUuid: string, key: keyof IDealBorrower, value: boolean|number|string) => dispatch(dealBorrowerValueSetAction(dealBorrowerUuid, key, value)),
        inDepthCompanyTradingHistory: () => dispatch(applicationBorrowerCompanyTradingHistoryCheckAction(ownProps.applicationUuid, ownProps.applicationBorrowerUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CompanyTradingHistory);
