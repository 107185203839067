import { Descriptions, Spin, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IAdviser from '~Api/Adviser/IAdviser';
import {
    adviserGetAction,
} from '~Advisers/actions';
import {
    adviserSelector,
} from '~Advisers/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import { currencyFormatter } from '~utilities/formatters';

interface IMatch {
    adviserUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    adviser: IAdviser;
}

interface IPropsDispatch {
    adviserGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Overview extends React.Component<Props> {
    public componentDidMount() {
        const { adviser } = this.props;

        if (!adviser) {
            this.props.adviserGet();
        }
    }

    public render(): JSX.Element {
        const { adviser, match } = this.props;

        if (!adviser) {
            return (
                <Layout adviserUuid={match.params.adviserUuid} section='overview'>
                    <Typography.Title level={2}>Overview</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        return (
            <Layout adviserUuid={match.params.adviserUuid} section='overview'>
                <Typography.Title level={2}>Overview</Typography.Title>
                <Descriptions bordered={true} layout='vertical' column={3}>
                    <Descriptions.Item label='Investor Count'>{adviser.investorCount}</Descriptions.Item>
                    <Descriptions.Item label='Investors Portfolio Total'> {adviser.investorsPortfolioTotal ?  currencyFormatter.format(adviser.investorsPortfolioTotal) : '-'} </Descriptions.Item>
                </Descriptions>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        adviser: adviserSelector(state, ownProps.match.params.adviserUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        adviserGet: () => dispatch(adviserGetAction(ownProps.match.params.adviserUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Overview);
