import { BankOutlined, FilterOutlined, UserOutlined } from '@ant-design/icons';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { Card } from 'antd';
import _ from 'lodash';
import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { aggregatorsListAction } from '~Aggregators/actions';
import IAggregator from '~Api/Aggregator/IAggregator';
import { aggregatorsSelector } from '~Aggregators/selectors';
import IBroker from '~Api/Broker/IBroker';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

interface IState {
    showInfoWindow: boolean;
}

interface IProps {
    broker: IBroker;
}

interface IPropsSelector {
    aggregators: IDictionary<IAggregator>;
}

interface IPropsDispatch {
    aggregatorsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class BrokerMarker extends React.Component<Props, IState> {
    public state: IState = {
        showInfoWindow: false,
    };

    constructor(props: Props) {
        super(props);

        this.onMouseExit = this.onMouseExit.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
    }

    public componentDidMount() {
        const { aggregators } = this.props;

        if (!aggregators) {
            this.props.aggregatorsList();
        }
    }

    public render() {
        const { aggregators, broker } = this.props;
        const { showInfoWindow } = this.state;

        if (!aggregators || !broker) {
            return null;
        }

        return (
            <Marker
                position={{
                    lat: broker.latitude,
                    lng: broker.longitude,
                }}
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseExit}
            >
                {showInfoWindow && (
                    <InfoWindow>
                        <Card className='marker-card' key={broker.uuid}>
                            <UserOutlined/> <Link className='name' to={`/brokers/${broker.uuid}`}>{broker.firstName || '-'} {broker.lastName || '-'}</Link>
                            {broker.companyName && <div className='company-name'><BankOutlined/> {broker.companyName}</div>}
                            {broker.aggregatorUuid && <div className='aggregator'><FilterOutlined/> {aggregators[broker.aggregatorUuid].name}</div>}
                            <div className='address'><FaMapMarkerAlt/> {`${broker.postalAddress || ''} ${broker.suburb || ''} ${broker.state || ''} ${broker.postcode || ''}`}</div>
                        </Card>
                    </InfoWindow>
                )}
            </Marker>
        );
    }

    private onMouseOver() {
        this.setState({
            showInfoWindow: true,
        });
    }

    private onMouseExit() {
        this.setState({
            showInfoWindow: false,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        aggregators: aggregatorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        aggregatorsList: () => dispatch(aggregatorsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BrokerMarker);
