import { Spin, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IBroker from '~Api/Broker/IBroker';
import IHistory from '~Api/Broker/IHistory';
import {
    brokerGetAction,
    brokerHistoriesListAction,
} from '~Brokers/actions';
import {
    brokerHistoriesSelector,
    brokerSelector,
} from '~Brokers/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';

interface IMatch {
    brokerUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    broker: IBroker;
    histories: IHistory[];
}

interface IPropsDispatch {
    brokerGet: () => void;
    historiesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class History extends React.Component<Props> {
    public componentDidMount() {
        const { broker } = this.props;

        if (!broker) {
            this.props.brokerGet();
        }

        this.props.historiesList();
    }

    public render(): JSX.Element {
        const { broker, match, histories } = this.props;

        if (!broker || !histories) {
            return (
                <Layout brokerUuid={match.params.brokerUuid} section='history'>
                    <Spin/>
                </Layout>
            );
        }

        const historiesBlock: JSX.Element[] = _.sortBy(_.filter(histories, (history) => !!history.uuid), ['createdTime']).map((loopHistory) => (
            <Timeline.Item key={loopHistory.uuid} label={dayjs(loopHistory.createdTime).format('YYYY-MM-DD - HH:mm')}>
                <Typography.Text>{loopHistory.description}</Typography.Text>
            </Timeline.Item>
        ));

        return (
            <Layout brokerUuid={match.params.brokerUuid} section='history'>
                <Typography.Title level={2}>History</Typography.Title>
                <Timeline mode='left' reverse={true}>
                    {historiesBlock}
                </Timeline>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        broker: brokerSelector(state, ownProps.match.params.brokerUuid),
        histories: brokerHistoriesSelector(state, ownProps.match.params.brokerUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        brokerGet: () => dispatch(brokerGetAction(ownProps.match.params.brokerUuid)),
        historiesList: () => dispatch(brokerHistoriesListAction(ownProps.match.params.brokerUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(History);
