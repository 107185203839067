import { all, call, debounce, put, select, takeEvery } from '@redux-saga/core/effects';
import { notification } from 'antd';
import _ from 'lodash';
import IDistribution from '~Api/IncomeTrust/IDistribution';
import {
    parseIncomeTrustDistribution,
    parseIncomeTrustInvestmentRequest,
    parseIncomeTrustTransaction,
    parseInvestorIncomeTrustTransaction,
} from '~Api/IncomeTrust/parsers';
import {
    incomeTrustProcessInterestDryRunRequest,
    incomeTrustProcessInterestRequest,
    incomeTrustTransactionInvestorIncomeTrustTransactionsListRequest,
    incomeTrustTransactionsListRequest,
} from '~Api/IncomeTrust/requests';
import IHistory from '~Api/Investment/IHistory';
import IInvestment from '~Api/Investment/IInvestment';
import IInvestmentTransaction from '~Api/Investment/IInvestmentTransaction';
import IPendingDistribution from '~Api/Investment/IPendingDistribution';
import {
    parseInvestment,
    parseInvestmentHistory,
    parseInvestmentTransaction,
    parsePendingDistribution,
    parseReservation,
} from '~Api/Investment/parsers';
import {
    investmentGetRequest,
    investmentGracePeriodRequest,
    investmentHideRequest,
    investmentHistoriesListRequest,
    investmentInterestRateRequest,
    investmentInvestorsListRequest,
    investmentPhotoRequest,
    investmentReservationProcessRequest,
    investmentReservationsListRequest,
    investmentSendInvestorEmailRequest,
    investmentSendPayoutEmailRequest,
    investmentShowRequest,
    investmentTransactionGetRequest,
    investmentTransactionInvestorsListRequest,
    investmentTransactionsCreateFromWarehouseLoanTransactionRequest,
    investmentTransactionsListRequest,
    investmentTransactionsPendingDistributionsListRequest,
    investmentUpdateRequest,
    investmentsDashboardRequest,
    investmentsListRequest,
    investmentsSearchRequest,
} from '~Api/Investment/requests';
import IInvestorAccountInvestment from '~Api/Investor/IInvestorAccountInvestment';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import {
    parseInvestorAccountInvestment,
    parseInvestorAccountInvestmentTransaction,
} from '~Api/Investor/parsers';
import { IFetchResponse } from '~utilities/fetch';
import { renderNotificationLoadingIcon } from '~utilities/utils';
import {
    IInvestmentGetAction,
    IInvestmentGracePeriodAction,
    IInvestmentHideAction,
    IInvestmentHistoriesListAction,
    IInvestmentIncomeTrustDistributionsAddAction,
    IInvestmentIncomeTrustDistributionsListAction,
    IInvestmentIncomeTrustInvestmentRequestCancelAction,
    IInvestmentIncomeTrustInvestmentRequestProcessAction,
    IInvestmentIncomeTrustInvestmentRequestsPendingListAction,
    IInvestmentIncomeTrustTransactionInvestorsListAction,
    IInvestmentIncomeTrustTransactionsListAction,
    IInvestmentInterestRateAction,
    IInvestmentInvestorEmailSendAction,
    IInvestmentInvestorsListAction,
    IInvestmentPayoutEmailSendAction,
    IInvestmentPendingDistributionProcessAction,
    IInvestmentPendingDistributionsListAction,
    IInvestmentPhotoAction,
    IInvestmentReservationProcessAction,
    IInvestmentReservationsListAction,
    IInvestmentSendAction,
    IInvestmentShowAction,
    IInvestmentTransactionGetAction,
    IInvestmentTransactionInvestorsListAction,
    IInvestmentTransactionsListAction,
    IInvestmentValueSetAction,
    IInvestmentsDashboardListAction,
    IInvestmentsListAction,
    IInvestmentsSearchAction,
    investmentGetAction,
    investmentHistoriesSetAction,
    investmentIncomeTrustDistributionsSetAction,
    investmentIncomeTrustInvestmentRequestsPendingSetAction,
    investmentIncomeTrustTransactionInvestorsSetAction,
    investmentIncomeTrustTransactionsListAction,
    investmentIncomeTrustTransactionsSetAction,
    investmentInvestorsSetAction,
    investmentPendingDistributionsSetAction,
    investmentPhotoInProgressSetAction,
    investmentReservationsListAction,
    investmentReservationsSetAction,
    investmentSendAction,
    investmentSetAction,
    investmentTransactionInvestorsSetAction,
    investmentTransactionSetAction,
    investmentTransactionsSetAction,
    investmentValueSetAction,
    investmentsDashboardSetAction,
    investmentsSearchResultsSetAction,
    investmentsSetAction,
} from './actions';
import InvestmentsActionsEnum from './ActionsEnum';
import { investmentReservationSelector, investmentSelector } from './selectors';
import IIncomeTrustTransaction from '~Api/IncomeTrust/IIncomeTrustTransaction';
import IInvestorIncomeTrustTransaction from '~Api/IncomeTrust/IInvestorIncomeTrustTransaction';
import dayjs from 'dayjs';
import IReservation from '~Api/Investment/IReservation';
import IIncomeTrustInvestmentRequest from '~Api/IncomeTrust/IIncomeTrustInvestmentRequest';
import {
    incomeTrustInvestmentRequestCancelRequest,
    incomeTrustInvestmentRequestProcessRequest,
    incomeTrustInvestmentRequestsPendingListRequest,
} from '~Api/Investor/requests';

function* investmentGet(action: IInvestmentGetAction): Iterator<unknown> {
    const rawInvestment: IFetchResponse = yield call(investmentGetRequest, action.uuid);
    const investment: IInvestment = parseInvestment(rawInvestment.body);
    yield put(investmentSetAction(investment));
}

function* investmentGracePeriod(action: IInvestmentGracePeriodAction): Iterator<unknown> {
    const key: string = `investmentGracePeriod ${action.uuid}`;
    const message: string = 'Enter Grace Period';

    notification.open({
        description: 'Entering grace period...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const investmentGracePeriodResponse: IFetchResponse = yield call(investmentGracePeriodRequest, action.uuid);
    if (investmentGracePeriodResponse.status === 422) {
        notification.error({
            description: `There was a problem entering grace period: ${_.values(investmentGracePeriodResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const investment: IInvestment = parseInvestment(investmentGracePeriodResponse.body);
        yield put(investmentSetAction(investment));

        notification.success({
            description: 'Investment has entered grace period.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investmentHide(action: IInvestmentHideAction): Iterator<unknown> {
    const key: string = `investmentHide ${action.uuid}`;

    notification.open({
        description: 'Hiding investment...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Hide Investment',
    });

    yield call(investmentHideRequest, action.uuid);

    notification.success({
        description: 'The investment has been hidden.',
        duration: 4.5,
        key,
        message: 'Hide Investment',
    });
}

function* investmentHistoriesList(action: IInvestmentHistoriesListAction): Iterator<unknown> {
    const rawHistories: IFetchResponse = yield call(investmentHistoriesListRequest, action.uuid);
    const histories: IHistory[] = yield Promise.all(rawHistories.body.map(parseInvestmentHistory));
    yield put(investmentHistoriesSetAction(action.uuid, histories));
}

function* investmentIncomeTrustDistributionsAdd(action: IInvestmentIncomeTrustDistributionsAddAction): Iterator<unknown> {
    const key: string = `investmentIncomeTrustDistributionsAdd ${dayjs().format()}`;
    const message: string = 'Add Income Trust Distribution';

    notification.open({
        description: 'Adding Income Trust distribution...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const distributionAddResponse: IFetchResponse = yield call(incomeTrustProcessInterestRequest, action.classType);

    if (distributionAddResponse.status === 422) {
        notification.error({
            description: `There was a problem adding the distribution: ${_.values(distributionAddResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(investmentIncomeTrustTransactionsListAction());

        notification.success({
            description: 'The distribution has been added.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investmentIncomeTrustDistributionsList(action: IInvestmentIncomeTrustDistributionsListAction): Iterator<unknown> {
    const investmentIncomeTrustDistributionsListResponse: IFetchResponse = yield call(incomeTrustProcessInterestDryRunRequest, action.classType);
    const distributions: IDistribution[] = yield Promise.all(investmentIncomeTrustDistributionsListResponse.body.map(parseIncomeTrustDistribution));
    yield put(investmentIncomeTrustDistributionsSetAction(action.classType, distributions));
}

function* investmentIncomeTrustInvestmentRequestCancel(action: IInvestmentIncomeTrustInvestmentRequestCancelAction): Iterator<unknown> {
    const key: string = `incomeTrustInvestmentRequestCancel ${action.incomeTrustInvestmentRequestUuid}`;
    const message: string = 'Cancel Investment Request';

    notification.open({
        description: 'Cancelling investment request...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const cancelResponse: IFetchResponse = yield call(incomeTrustInvestmentRequestCancelRequest, action.incomeTrustInvestmentRequestUuid, action.reason);

    if (cancelResponse.status === 422) {
        notification.error({
            description: `There was a problem cancelling the investment request: ${_.values(cancelResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        notification.success({
            description: 'The investment request has been canceled.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investmentIncomeTrustInvestmentRequestProcess(action: IInvestmentIncomeTrustInvestmentRequestProcessAction): Iterator<unknown> {
    const key: string = `incomeTrustInvestmentRequestProcess ${action.incomeTrustInvestmentRequestUuid}`;
    const message: string = 'Process Investment Request';

    notification.open({
        description: 'Processing investment request...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const processResponse: IFetchResponse = yield call(incomeTrustInvestmentRequestProcessRequest, action.incomeTrustInvestmentRequestUuid, action.sendEmail);

    if (processResponse.status === 422) {
        notification.error({
            description: `There was a problem processing the investment request: ${_.values(processResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        notification.success({
            description: 'The investment request has been processed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* investmentIncomeTrustInvestmentRequestsPendingList(action: IInvestmentIncomeTrustInvestmentRequestsPendingListAction): Iterator<unknown> {
    const incomeTrustInvestmentRequestsPendingResponse: IFetchResponse = yield call(incomeTrustInvestmentRequestsPendingListRequest);
    const incomeTrustInvestmentRequests: IIncomeTrustInvestmentRequest[] = yield Promise.all(incomeTrustInvestmentRequestsPendingResponse.body.map(parseIncomeTrustInvestmentRequest));
    yield put(investmentIncomeTrustInvestmentRequestsPendingSetAction(incomeTrustInvestmentRequests));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* investmentIncomeTrustTransactionsList(action: IInvestmentIncomeTrustTransactionsListAction): Iterator<unknown> {
    const investmentIncomeTrustTransactionsListResponse: IFetchResponse = yield call(incomeTrustTransactionsListRequest);
    const transactions: IIncomeTrustTransaction[] = yield Promise.all(investmentIncomeTrustTransactionsListResponse.body.map(parseIncomeTrustTransaction));
    yield put(investmentIncomeTrustTransactionsSetAction(transactions));
}

function* investmentIncomeTrustTransactionInvestorsList(action: IInvestmentIncomeTrustTransactionInvestorsListAction): Iterator<unknown> {
    const investmentIncomeTrustTransactionInvestorsListResponse: IFetchResponse = yield call(incomeTrustTransactionInvestorIncomeTrustTransactionsListRequest, action.incomeTrustTransactionUuid);
    const transactions: IInvestorIncomeTrustTransaction[] = yield Promise.all(investmentIncomeTrustTransactionInvestorsListResponse.body.map(parseInvestorIncomeTrustTransaction));
    yield put(investmentIncomeTrustTransactionInvestorsSetAction(action.incomeTrustTransactionUuid, transactions));
}

function* investmentInterestRate(action: IInvestmentInterestRateAction): Iterator<unknown> {
    const key: string = `investmentInterestRate ${action.investmentUuid}`;
    const message: string = 'Change Interest Rate';

    notification.open({
        description: 'Changing interest rate...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const investmentInterestRateResponse: IFetchResponse = yield call(investmentInterestRateRequest, action.investmentUuid, action.interestRate);

    if (investmentInterestRateResponse.status === 422) {
        notification.error({
            description: `There was a problem changing the interest rate: ${_.values(investmentInterestRateResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const investment: IInvestment = parseInvestment(investmentInterestRateResponse.body);
        yield put(investmentSetAction(investment));

        notification.success({
            description: 'The interest rate has been changed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investmentInvestorEmailSend(action: IInvestmentInvestorEmailSendAction): Iterator<unknown> {
    const key: string = `investmentInvestorEmailSend ${action.uuid}`;

    notification.open({
        description: 'Sending email...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Send Investor Email',
    });

    yield call(investmentSendInvestorEmailRequest, action.uuid, action.subject, action.message);

    notification.success({
        description: 'The email has been sent.',
        duration: 4.5,
        key,
        message: 'Send Investor Email',
    });
}

function* investmentInvestorsList(action: IInvestmentInvestorsListAction): Iterator<unknown> {
    const investmentInvestorsListResponse: IFetchResponse = yield call(investmentInvestorsListRequest, action.investmentUuid);
    const investorAccountInvestments: IInvestorAccountInvestment[] = yield Promise.all(investmentInvestorsListResponse.body.map(parseInvestorAccountInvestment));
    yield put(investmentInvestorsSetAction(action.investmentUuid, investorAccountInvestments));
}

function* investmentPayoutEmailSend(action: IInvestmentPayoutEmailSendAction): Iterator<unknown> {
    const key: string = `investmentPayoutEmailSend ${action.uuid}`;

    notification.open({
        description: 'Sending email...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Send Payout Email',
    });

    yield call(investmentSendPayoutEmailRequest, action.uuid);

    notification.success({
        description: 'The email has been sent.',
        duration: 4.5,
        key,
        message: 'Send Payout Email',
    });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* investmentPendingDistributionsList(action: IInvestmentPendingDistributionsListAction): Iterator<unknown> {
    const investmentPendingDistributionsListResponse: IFetchResponse = yield call(investmentTransactionsPendingDistributionsListRequest);
    const pendingDistributions: IPendingDistribution[] = yield Promise.all(investmentPendingDistributionsListResponse.body.map(parsePendingDistribution));
    yield put(investmentPendingDistributionsSetAction(pendingDistributions));
}

function* investmentPendingDistributionProcess(action: IInvestmentPendingDistributionProcessAction): Iterator<unknown> {
    const key: string = `investmentPendingDistributionProcess ${action.warehouseLoanTransactionUuid}`;
    const message: string = 'Process Pending Distribution';

    notification.open({
        description: 'Processing pending distribution...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const pendingDistributionProcessResponse: IFetchResponse = yield call(investmentTransactionsCreateFromWarehouseLoanTransactionRequest, action.warehouseLoanTransactionUuid);
    if (pendingDistributionProcessResponse.status === 422) {
        notification.error({
            description: `There was a problem processing the pending distribution: ${_.values(pendingDistributionProcessResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        notification.success({
            description: 'Pending distribution has been processed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investmentPhoto(action: IInvestmentPhotoAction): Iterator<unknown> {
    yield put(investmentPhotoInProgressSetAction(true));
    const rawInvestment: IFetchResponse = yield call(investmentPhotoRequest, action.uuid, action.file);
    const investment: IInvestment = parseInvestment(rawInvestment.body);
    yield put(investmentValueSetAction(action.uuid, 'photos', investment.photos));
    yield put(investmentPhotoInProgressSetAction(false));
}

function* investmentReservationProcess(action: IInvestmentReservationProcessAction): Iterator<unknown> {
    const key: string = `investmentReservationProcess ${action.investmentReservationUuid}`;
    const message: string = 'Process Reservation';

    notification.open({
        description: 'Processing reservation...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const investmentReservation: IReservation = yield select(investmentReservationSelector, action.investmentReservationUuid);
    const investmentReservationProcessResponse: IFetchResponse = yield call(investmentReservationProcessRequest, action.investmentReservationUuid);

    if (investmentReservationProcessResponse.status === 422) {
        notification.error({
            description: `There was a problem processing the reservation: ${_.values(investmentReservationProcessResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(investmentGetAction(investmentReservation.investmentUuid));
        yield put(investmentReservationsListAction(investmentReservation.investmentUuid));

        notification.success({
            description: 'The reservation has been processed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investmentReservationsList(action: IInvestmentReservationsListAction): Iterator<unknown> {
    const investmentReservationsListResponse: IFetchResponse = yield call(investmentReservationsListRequest, action.investmentUuid);
    const reservations: IReservation[] = yield Promise.all(investmentReservationsListResponse.body.map(parseReservation));
    yield put(investmentReservationsSetAction(action.investmentUuid, reservations));
}

function* investmentSend(action: IInvestmentSendAction): Iterator<unknown> {
    const investment: IInvestment = yield select(investmentSelector, action.uuid);
    yield call(investmentUpdateRequest, investment);
}

function* investmentShow(action: IInvestmentShowAction): Iterator<unknown> {
    const key: string = `investmentShow ${action.uuid}`;
    const message: string = 'Show Investment';

    notification.open({
        description: 'Showing investment...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const investmentShowResponse: IFetchResponse = yield call(investmentShowRequest, action.uuid, action.sendEmail, action.interestRate);

    if (investmentShowResponse.status === 422) {
        notification.error({
            description: `There was a problem showing the investment: ${_.values(investmentShowResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const investment: IInvestment = parseInvestment(investmentShowResponse.body);
        yield put(investmentSetAction(investment));

        notification.success({
            description: 'The investment has been shown.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investmentValueSet(action: IInvestmentValueSetAction): Iterator<unknown> {
    yield put(investmentSendAction(action.uuid));
}

function* investmentTransactionsList(action: IInvestmentTransactionsListAction): Iterator<unknown> {
    const investmentTransactionsListResponse: IFetchResponse = yield call(investmentTransactionsListRequest, action.investmentUuid);
    const transactions: IInvestmentTransaction[] = yield Promise.all(investmentTransactionsListResponse.body.map(parseInvestmentTransaction));
    yield put(investmentTransactionsSetAction(action.investmentUuid, transactions));
}

function* investmentTransactionGet(action: IInvestmentTransactionGetAction): Iterator<unknown> {
    const investmentTransactionGetResponse: IFetchResponse = yield call(investmentTransactionGetRequest, action.investmentTransactionUuid);
    const transaction: IInvestmentTransaction = yield call(parseInvestmentTransaction, investmentTransactionGetResponse.body);
    yield put(investmentTransactionSetAction(transaction));
}

function* investmentTransactionInvestorsList(action: IInvestmentTransactionInvestorsListAction): Iterator<unknown> {
    const investmentTransactionInvestorsListResponse: IFetchResponse = yield call(investmentTransactionInvestorsListRequest, action.investmentTransactionUuid);
    const transactions: IInvestorAccountInvestmentTransaction[] = yield Promise.all(investmentTransactionInvestorsListResponse.body.map(parseInvestorAccountInvestmentTransaction));
    yield put(investmentTransactionInvestorsSetAction(action.investmentTransactionUuid, transactions));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* investmentsDashboardList(action: IInvestmentsDashboardListAction): Iterator<unknown> {
    const investmentsDashboardResponse: IFetchResponse = yield call(investmentsDashboardRequest);
    const investments: IInvestment[] = yield Promise.all(investmentsDashboardResponse.body.map(parseInvestment));
    yield put(investmentsDashboardSetAction(investments));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* investmentsList(action: IInvestmentsListAction): Iterator<unknown> {
    const rawInvestments: IFetchResponse = yield call(investmentsListRequest);
    const investments: IInvestment[] = yield Promise.all(rawInvestments.body.map(parseInvestment));
    yield put(investmentsSetAction(investments));
}

function* investmentsSearch(action: IInvestmentsSearchAction): Iterator<unknown> {
    const investmentsSearchResponse: IFetchResponse = yield call(investmentsSearchRequest, action.keyword);
    const investments: IInvestment[] = yield Promise.all(investmentsSearchResponse.body.map(parseInvestment));
    yield put(investmentsSearchResultsSetAction(investments));
}

export function* InvestmentsSagas(): Iterator<unknown> {
    yield all([
        debounce(20, InvestmentsActionsEnum.InvestmentGet, investmentGet),
        takeEvery(InvestmentsActionsEnum.InvestmentGracePeriod, investmentGracePeriod),
        debounce(20, InvestmentsActionsEnum.InvestmentHide, investmentHide),
        takeEvery(InvestmentsActionsEnum.InvestmentInterestRate, investmentInterestRate),
        debounce(20, InvestmentsActionsEnum.InvestmentInvestorEmailSend, investmentInvestorEmailSend),
        debounce(20, InvestmentsActionsEnum.InvestmentPayoutEmailSend, investmentPayoutEmailSend),
        takeEvery(InvestmentsActionsEnum.InvestmentPhoto, investmentPhoto),
        debounce(500, InvestmentsActionsEnum.InvestmentSend, investmentSend),
        debounce(20, InvestmentsActionsEnum.InvestmentShow, investmentShow),
        takeEvery(InvestmentsActionsEnum.InvestmentValueSet, investmentValueSet),

        takeEvery(InvestmentsActionsEnum.InvestmentHistoriesList, investmentHistoriesList),

        takeEvery(InvestmentsActionsEnum.InvestmentIncomeTrustDistributionsAdd, investmentIncomeTrustDistributionsAdd),
        takeEvery(InvestmentsActionsEnum.InvestmentIncomeTrustDistributionsList, investmentIncomeTrustDistributionsList),

        takeEvery(InvestmentsActionsEnum.InvestmentIncomeTrustInvestmentRequestCancel, investmentIncomeTrustInvestmentRequestCancel),
        takeEvery(InvestmentsActionsEnum.InvestmentIncomeTrustInvestmentRequestProcess, investmentIncomeTrustInvestmentRequestProcess),
        takeEvery(InvestmentsActionsEnum.InvestmentIncomeTrustInvestmentRequestsPendingList, investmentIncomeTrustInvestmentRequestsPendingList),

        takeEvery(InvestmentsActionsEnum.InvestmentIncomeTrustTransactionsList, investmentIncomeTrustTransactionsList),
        takeEvery(InvestmentsActionsEnum.InvestmentIncomeTrustTransactionInvestorsList, investmentIncomeTrustTransactionInvestorsList),

        takeEvery(InvestmentsActionsEnum.InvestmentInvestorsList, investmentInvestorsList),

        takeEvery(InvestmentsActionsEnum.InvestmentPendingDistributionsList, investmentPendingDistributionsList),

        takeEvery(InvestmentsActionsEnum.InvestmentPendingDistributionProcess, investmentPendingDistributionProcess),

        takeEvery(InvestmentsActionsEnum.InvestmentReservationProcess, investmentReservationProcess),

        takeEvery(InvestmentsActionsEnum.InvestmentReservationsList, investmentReservationsList),

        takeEvery(InvestmentsActionsEnum.InvestmentTransactionsList, investmentTransactionsList),

        takeEvery(InvestmentsActionsEnum.InvestmentTransactionGet, investmentTransactionGet),
        takeEvery(InvestmentsActionsEnum.InvestmentTransactionInvestorsList, investmentTransactionInvestorsList),

        takeEvery(InvestmentsActionsEnum.InvestmentsDashboardList, investmentsDashboardList),
        debounce(20, InvestmentsActionsEnum.InvestmentsList, investmentsList),
        debounce(200, InvestmentsActionsEnum.InvestmentsSearch, investmentsSearch),
    ]);
}
