import { SettingOutlined } from '@ant-design/icons';
import { Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import {
    applicationGenerateMemoAction,
    applicationValueSetAction,
} from '~Applications/actions';
import { applicationSelector } from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import IConditionComponentProps from './IConditionComponentProps';

interface IState {
    isModalOpen: boolean;
}

interface IPropsSelector {
    application: IApplication;
}

interface IPropsDispatch {
    applicationValueSet: (key: keyof IApplication, value: boolean|number|string) => void;
    generateMemo: () => void;
}

type Props = IConditionComponentProps & IPropsSelector & IPropsDispatch;

class CreditMemo extends React.Component<Props, IState> {
    public state: IState = {
        isModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickGenerate = this.onClickGenerate.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);

        this.onChangeCreditNotes = this.onChangeCreditNotes.bind(this);
        this.onChangeLoanSynopsis = this.onChangeLoanSynopsis.bind(this);
    }

    public render(): JSX.Element {
        const { application } = this.props;
        const { isModalOpen } = this.state;

        return (
            <>
                <a onClick={this.onClickGenerate}><SettingOutlined/></a>
                <Modal
                    okText='Generate Memo'
                    onCancel={this.onClickCancel}
                    onOk={this.onClickOk}
                    open={isModalOpen}
                    title='Generate Credit Memo'
                    width={1000}
                    wrapClassName='application-generate-credit-memo-modal'
                >
                    <Form.Item label='Loan Synopsis' className='loan-synopsis'>
                        <Input.TextArea onChange={this.onChangeLoanSynopsis} rows={20} defaultValue={application.loanSynopsis} />
                    </Form.Item>
                    <Form.Item label='Credit Notes/Conditions' className='credit-notes-conditions'>
                        <Input.TextArea onChange={this.onChangeCreditNotes} rows={20} defaultValue={application.creditNotes} />
                    </Form.Item>
                </Modal>
            </>
        );
    }

    private onClickCancel(): void {
        this.setState({
            isModalOpen: false,
        });
    }

    private onClickGenerate(): void {
        this.setState({
            isModalOpen: true,
        });
    }

    private onClickOk(): void {
        this.setState({
            isModalOpen: false,
        });

        this.props.generateMemo();
    }

    private onChangeCreditNotes(event: React.ChangeEvent<HTMLTextAreaElement>): void {
        this.props.applicationValueSet('creditNotes', event.target.value);
    }

    private onChangeLoanSynopsis(event: React.ChangeEvent<HTMLTextAreaElement>): void {
        this.props.applicationValueSet('loanSynopsis', event.target.value);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IConditionComponentProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.applicationUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IConditionComponentProps): IPropsDispatch {
    return {
        applicationValueSet: (key: keyof IApplication, value: boolean|number|string) => dispatch(applicationValueSetAction(ownProps.applicationUuid, key, value)),
        generateMemo: () => dispatch(applicationGenerateMemoAction(ownProps.applicationUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreditMemo);
