enum AdministratorsActionsEnum {
    AdministratorsAdd = 'ADMINISTRATORS/ADD',
    AdministratorsList = 'ADMINISTRATORS/LIST',
    AdministratorsSet = 'ADMINISTRATORS/SET',

    AdministratorActivate = 'ADMINISTRATOR/ACTIVATE',
    AdministratorDeactivate = 'ADMINISTRATOR/DEACTIVATE',
    AdministratorFido = 'ADMINISTRATOR/FIDO',
    AdministratorGet = 'ADMINISTRATOR/GET',
    AdministratorMagicLinkRequestApprove = 'ADMINISTRATOR/MAGIC_LINK_REQUEST/APPROVE',
    AdministratorMagicLinkRequestCancel = 'ADMINISTRATOR/MAGIC_LINK_REQUEST/CANCEL',
    AdministratorPermissions = 'ADMINISTRATOR/PERMISSIONS',
    AdministratorSend = 'ADMINISTRATOR/SEND',
    AdministratorSet = 'ADMINISTRATOR/SET',
    AdministratorValueSet = 'ADMINISTRATOR/VALUE/SET',

    AdministratorHistoriesList = 'ADMINISTRATOR/HISTORIES/LIST',
    AdministratorHistoriesSet = 'ADMINISTRATOR/HISTORIES/SET',

    AdministratorPasskeyDeactivate = 'ADMINISTRATOR/PASSKEY/DEACTIVATE',
    AdministratorPasskeySet = 'ADMINISTRATOR/PASSKEY/SET',

    AdministratorPasskeysList = 'ADMINISTRATOR/PASSKEYS/LIST',
    AdministratorPasskeysSet = 'ADMINISTRATOR/PASSKEYS/SET',
}

export default AdministratorsActionsEnum;
