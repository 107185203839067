import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Descriptions, Dropdown, Layout as LayoutAnt, Menu, MenuProps, Modal, PageHeader, Popover, Space, Spin, Tag } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import {
    administratorsSelector,
    currentAdministratorSelector,
} from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import RoleEnum from '~Api/Administrator/RoleEnum';
import BorrowerCategoryEnum from '~Api/Application/BorrowerCategoryEnum';
import BorrowerTypeEnum from '~Api/Application/BorrowerTypeEnum';
import BrokerCommissionFormatEnum from '~Api/Application/BrokerCommissionFormatEnum';
import CloseReasonEnum from '~Api/Application/CloseReasonEnum';
import DisbursementTypeEnum from '~Api/Application/DisbursementTypeEnum';
import ExtensionTypeEnum from '~Api/Application/ExtensionTypeEnum';
import IApplication from '~Api/Application/IApplication';
import IApplicationDisbursement from '~Api/Application/IApplicationDisbursement';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IBorrower from '~Api/Application/IBorrower';
import PartnerCommissionFormatEnum from '~Api/Application/PartnerCommissionFormatEnum';
import WorkflowStatusEnum from '~Api/Application/WorkflowStatusEnum';
import BrokerApprovalStatusEnum from '~Api/Broker/ApprovalStatusEnum';
import ConditionTypeEnum from '~Api/Application/ConditionTypeEnum';
import IConditionDocument from '~Api/Application/IConditionDocument';
import IProperty from '~Api/Deal/IProperty';
import LoanPurposeEnum from '~Api/Deal/LoanPurposeEnum';
import PropertyPurposeEnum from '~Api/Deal/PropertyPurposeEnum';
import PartnerApprovalStatusEnum from '~Api/ReferralPartner/ApprovalStatusEnum';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import {
    applicationConditionDocumentsListAction,
    applicationConditionalApprovalAction,
    applicationConditionsListAction,
    applicationDisbursementsListAction,
    applicationFormalApprovalAction,
    applicationFormalsAddAction,
    applicationLegalDocumentsAction,
    applicationNewAction,
    applicationReopenAction,
    applicationSettlementAction,
    applicationUnderwritingAction,
} from '~Applications/actions';
import {
    applicationConditionDocumentsSelector,
    applicationConditionsSelector,
    applicationDealPropertiesSelector,
    applicationDisbursementsSelector,
    applicationSelector,
} from '~Applications/selectors';
import SettlementDate from '~Applications/SettlementDate';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import constants from '~constants';
import BdmSelector from '~Deals/BdmSelector';
import BrokerSelector from '~Deals/BrokerSelector';
import FollowUpTime from '~Deals/FollowUpTime';
import LoanProcessorSelector from '~Deals/LoanProcessorSelector';
import { propertyRequiresInsurance } from '~Deals/utilities';
import { IGlobalState } from '~reducer';
import { referralPartnersListAction } from '~ReferralPartners/actions';
import { referralPartnersSelector } from '~ReferralPartners/selectors';
import { IDictionary } from '~utilities/IDictionary';
import './application.less';
import CloseModal from './CloseModal';
import SettledModal from './SettledModal';
import {
    approvedApplicationConditionDocumentExists,
    approvedBorrowerConditionDocumentExists,
    approvedCustomConditionDocumentExists,
    approvedPropertyConditionDocumentExists,
    calculateNetPrepaidBalanceOnSettlement,
    conditionLabels,
    getApplicationConditions,
    getBorrowerConditions,
    getPropertyConditions,
    getRequiredConditionTypes,
} from '../utilities';
import ICondition from '~Api/Application/ICondition';
import AssigneeSelector from './AssigneeSelector';
import IAggregator from '~Api/Aggregator/IAggregator';
import { aggregatorsSelector } from '~Aggregators/selectors';
import { aggregatorsListAction } from '~Aggregators/actions';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import menuDivider from '~UI/menuDivider';
import { currencyFormatter } from '~utilities/formatters';

enum SectionEnum {
    Borrowers = 'BORROWERS',
    ConditionalApprovals = 'CONDITIONAL_APPROVALS',
    CustomConditions = 'CUSTOM_CONDITIONS',
    Disbursements = 'DISBURSEMENTS',
    Documents = 'DOCUMENTS',
    Edit = 'EDIT',
    Fees = 'FEES',
    FormalApprovals = 'FORMAL_APPROVALS',
    History = 'HISTORY',
    Notes = 'NOTES',
    Overview = 'OVERVIEW',
    Properties = 'PROPERTIES',
    PropertyValuations = 'PROPERTY_VALUATIONS',
    RequiredInformation = 'REQUIRED_INFORMATION',
    SolicitorInstructions = 'SOLICITOR_INSTRUCTIONS',
    Underwriting = 'UNDERWRITING',
    Warehouses = 'WAREHOUSES',
}

enum WorkflowStepEnum {
    ConditionalApproval = 'CONDITIONAL_APPROVAL',
    Draft = 'DRAFT',
    FormalApproval = 'FORMAL_APPROVAL',
    IssueConditionalApproval = 'ISSUE_CONDITIONAL_APPROVAL',
    IssueFormalApproval = 'ISSUE_FORMAL_APPROVAL',
    LegalDocuments = 'LEGAL_DOCUMENTS',
    New = 'NEW',
    Settled = 'SETTLED',
    Settlement = 'SETTLEMENT',
    Underwriting = 'UNDERWRITING',
}

const closeReasonLabels: IDictionary<string> = {
    [CloseReasonEnum.Cancelled]: 'Cancelled',
    [CloseReasonEnum.Cold]: 'Cold/Stale',
    [CloseReasonEnum.Credit]: 'Credit Issue',
    [CloseReasonEnum.LostToOther]: 'Lost to Other',
    [CloseReasonEnum.Settled]: 'Settled',
    [CloseReasonEnum.Valuation]: 'Valuation Issue',
};

const statusNames: IDictionary<string> = {
    [WorkflowStatusEnum.Draft]: 'Draft',
    [WorkflowStatusEnum.New]: 'New',
    [WorkflowStatusEnum.ConditionalApproval]: 'Conditional Approval',
    [WorkflowStatusEnum.Underwriting]: 'Underwriting',
    [WorkflowStatusEnum.FormalApproval]: 'Formal Approval',
    [WorkflowStatusEnum.LegalDocuments]: 'Legal Documents',
    [WorkflowStatusEnum.Settlement]: 'Settlement',
    [WorkflowStatusEnum.Warehoused]: 'Settled',
};

const buttonWorkflowStepAllowedStatusesMap: IDictionary<WorkflowStatusEnum[]> = {
    [WorkflowStepEnum.ConditionalApproval]: [],
    [WorkflowStepEnum.FormalApproval]: [WorkflowStatusEnum.Underwriting],
    [WorkflowStepEnum.IssueConditionalApproval]: [WorkflowStatusEnum.New],
    [WorkflowStepEnum.IssueFormalApproval]: [WorkflowStatusEnum.FormalApproval],
    [WorkflowStepEnum.LegalDocuments]: [],
    [WorkflowStepEnum.New]: [WorkflowStatusEnum.Draft],
    [WorkflowStepEnum.Settled]: [WorkflowStatusEnum.Settlement],
    [WorkflowStepEnum.Settlement]: [WorkflowStatusEnum.LegalDocuments],
    [WorkflowStepEnum.Underwriting]: [WorkflowStatusEnum.ConditionalApproval],
};

const menuItemWorkflowStepAllowedStatusesMap: IDictionary<WorkflowStatusEnum[]> = {
    [WorkflowStepEnum.ConditionalApproval]: [
        WorkflowStatusEnum.Draft,
        WorkflowStatusEnum.FormalApproval,
        WorkflowStatusEnum.LegalDocuments,
        WorkflowStatusEnum.New,
        WorkflowStatusEnum.Settlement,
        WorkflowStatusEnum.Underwriting,
        WorkflowStatusEnum.Warehoused,
    ],
    [WorkflowStepEnum.Draft]: [
        WorkflowStatusEnum.ConditionalApproval,
        WorkflowStatusEnum.FormalApproval,
        WorkflowStatusEnum.LegalDocuments,
        WorkflowStatusEnum.Settlement,
        WorkflowStatusEnum.Underwriting,
        WorkflowStatusEnum.Warehoused,
    ],
    [WorkflowStepEnum.FormalApproval]: [
        WorkflowStatusEnum.ConditionalApproval,
        WorkflowStatusEnum.Draft,
        WorkflowStatusEnum.LegalDocuments,
        WorkflowStatusEnum.New,
        WorkflowStatusEnum.Settlement,
        WorkflowStatusEnum.Warehoused,
    ],
    [WorkflowStepEnum.LegalDocuments]: [
        WorkflowStatusEnum.ConditionalApproval,
        WorkflowStatusEnum.Draft,
        WorkflowStatusEnum.FormalApproval,
        WorkflowStatusEnum.New,
        WorkflowStatusEnum.Settlement,
        WorkflowStatusEnum.Underwriting,
        WorkflowStatusEnum.Warehoused,
    ],
    [WorkflowStepEnum.New]: [
        WorkflowStatusEnum.ConditionalApproval,
        WorkflowStatusEnum.FormalApproval,
        WorkflowStatusEnum.LegalDocuments,
        WorkflowStatusEnum.Settlement,
        WorkflowStatusEnum.Underwriting,
        WorkflowStatusEnum.Warehoused,
    ],
    [WorkflowStepEnum.Settled]: [
        WorkflowStatusEnum.ConditionalApproval,
        WorkflowStatusEnum.Draft,
        WorkflowStatusEnum.FormalApproval,
        WorkflowStatusEnum.LegalDocuments,
        WorkflowStatusEnum.New,
        WorkflowStatusEnum.Underwriting,
    ],
    [WorkflowStepEnum.Settlement]: [
        WorkflowStatusEnum.ConditionalApproval,
        WorkflowStatusEnum.Draft,
        WorkflowStatusEnum.FormalApproval,
        WorkflowStatusEnum.New,
        WorkflowStatusEnum.Underwriting,
        WorkflowStatusEnum.Warehoused,
    ],
    [WorkflowStepEnum.Underwriting]: [
        WorkflowStatusEnum.Draft,
        WorkflowStatusEnum.FormalApproval,
        WorkflowStatusEnum.LegalDocuments,
        WorkflowStatusEnum.New,
        WorkflowStatusEnum.Settlement,
        WorkflowStatusEnum.Warehoused,
    ],
};

export default function Layout(props: {
    applicationUuid: string;
    breadcrumb?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    section: string;
}): ReactElement {
    const { applicationUuid, breadcrumb, children, section } = props;

    const [isCloseModalOpen, setIsCloseModalOpen] = useState<boolean>(false);
    const [isSettledModalOpen, setIsSettledModalOpen] = useState<boolean>(false);

    const administrators: IDictionary<IAdministrator> = useSelector(administratorsSelector);
    const aggregators: IDictionary<IAggregator> = useSelector(aggregatorsSelector);
    const application: IApplication = useSelector((state: IGlobalState) => applicationSelector(state, applicationUuid));
    const conditionDocuments: IDictionary<IConditionDocument> = useSelector((state: IGlobalState) => applicationConditionDocumentsSelector(state, applicationUuid));
    const conditions: IDictionary<ICondition> = useSelector((state: IGlobalState) => applicationConditionsSelector(state, applicationUuid));
    const currentAdministrator: IAdministrator = useSelector(currentAdministratorSelector);
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);
    const disbursements: IDictionary<IApplicationDisbursement> = useSelector((state: IGlobalState) => applicationDisbursementsSelector(state, applicationUuid));
    const partners: IDictionary<IReferralPartner> = useSelector(referralPartnersSelector);
    const properties: IDictionary<IProperty> = useSelector((state: IGlobalState) => applicationDealPropertiesSelector(state, applicationUuid));

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!administrators) {
            dispatch(administratorsListAction());
        }
    }, [administrators, dispatch]);

    useEffect(() => {
        if (!aggregators) {
            dispatch(aggregatorsListAction());
        }
    }, [aggregators, dispatch]);

    useEffect(() => {
        if (!conditions) {
            dispatch(applicationConditionsListAction(applicationUuid));
        }
    }, [applicationUuid, conditions, dispatch]);

    useEffect(() => {
        if (!conditionDocuments) {
            dispatch(applicationConditionDocumentsListAction(applicationUuid));
        }
    }, [applicationUuid, conditionDocuments, dispatch]);

    useEffect(() => {
        if (!disbursements) {
            dispatch(applicationDisbursementsListAction(applicationUuid));
        }
    }, [applicationUuid, disbursements, dispatch]);

    useEffect(() => {
        if (!partners) {
            dispatch(referralPartnersListAction());
        }
    }, [dispatch, partners]);

    const shouldDisplaySection: (section: SectionEnum) => boolean = useCallback((section: SectionEnum) => {
        const isGracePeriod: boolean = ExtensionTypeEnum.GracePeriod === application.extensionType;
        const isRenewal: boolean = ExtensionTypeEnum.Renewal === application.extensionType;
        const isBdm: boolean = [
            RoleEnum.BusinessDevelopmentManager,
            RoleEnum.InternalBusinessDevelopmentManager,
            RoleEnum.SeniorBusinessDevelopmentManager,
        ].includes(currentAdministrator.role);

        switch (section) {
            case SectionEnum.Borrowers:
                return !isBdm;
            case SectionEnum.ConditionalApprovals:
                return !application.legacy && !isGracePeriod && !isRenewal;
            case SectionEnum.CustomConditions:
                return !application.legacy && !isBdm && !isGracePeriod;
            case SectionEnum.Disbursements:
                return !isBdm && !isGracePeriod;
            case SectionEnum.Documents:
                return true;
            case SectionEnum.Edit:
                return !isBdm;
            case SectionEnum.Fees:
                return !application.legacy && !isBdm && !isGracePeriod;
            case SectionEnum.FormalApprovals:
                return !application.legacy && !isBdm && !isGracePeriod && !isRenewal;
            case SectionEnum.History:
                return true;
            case SectionEnum.Notes:
                return true;
            case SectionEnum.Overview:
                return true;
            case SectionEnum.Properties:
                return true;
            case SectionEnum.PropertyValuations:
                return !isBdm && !isGracePeriod;
            case SectionEnum.SolicitorInstructions:
                return !application.legacy && !isBdm && !isGracePeriod && !isRenewal;
            case SectionEnum.Underwriting:
                return !application.legacy && !isBdm && !isGracePeriod;
            case SectionEnum.RequiredInformation:
                return !application.legacy && !isGracePeriod;
            case SectionEnum.Warehouses:
                return !application.legacy && currentUser.permissions.includes(PermissionsEnum.ApplicationWarehouses);
        }

        return false;
    }, [
        application,
        currentAdministrator,
        currentUser,
    ]);

    const canMoveToWorkflowStep: (workflowStep: WorkflowStepEnum) => boolean = useCallback((workflowStep: WorkflowStepEnum) => {
        const isGracePeriod: boolean = ExtensionTypeEnum.GracePeriod === application.extensionType;
        const isRenewal: boolean = ExtensionTypeEnum.Renewal === application.extensionType;

        switch (workflowStep) {
            case WorkflowStepEnum.ConditionalApproval:
                return !isGracePeriod;
            case WorkflowStepEnum.Draft:
                return !isGracePeriod && !isRenewal;
            case WorkflowStepEnum.FormalApproval:
                return !isGracePeriod;
            case WorkflowStepEnum.IssueConditionalApproval:
                return !isGracePeriod && !isRenewal;
            case WorkflowStepEnum.IssueFormalApproval:
                return !isGracePeriod && !isRenewal && (currentUser.permissions.includes(PermissionsEnum.ApplicationFormalApprovalAdd));
            case WorkflowStepEnum.LegalDocuments:
                return !isGracePeriod && (currentUser.permissions.includes(PermissionsEnum.ApplicationFormalApprovalAdd) || [WorkflowStatusEnum.Settlement, WorkflowStatusEnum.Warehoused].includes(application.workflowStatus));
            case WorkflowStepEnum.New:
                return !isGracePeriod;
            case WorkflowStepEnum.Settled:
                return currentUser.permissions.includes(PermissionsEnum.ApplicationSettle);
            case WorkflowStepEnum.Settlement:
                return !isGracePeriod && (currentUser.permissions.includes(PermissionsEnum.ApplicationFormalApprovalAdd) || [WorkflowStatusEnum.LegalDocuments, WorkflowStatusEnum.Warehoused].includes(application.workflowStatus));
            case WorkflowStepEnum.Underwriting:
                return !isGracePeriod;
        }

        return false;
    }, [
        application,
        currentUser,
    ]);

    const shouldDisplayWorkflowMenuItem: (workflowStep: WorkflowStepEnum) => boolean = useCallback((workflowStep: WorkflowStepEnum) => {
        if (!canMoveToWorkflowStep(workflowStep)) {
            return false;
        }

        return menuItemWorkflowStepAllowedStatusesMap[workflowStep].includes(application.workflowStatus);
    }, [
        application,
        canMoveToWorkflowStep,
    ]);

    const shouldDisplayWorkflowButton: (workflowStep: WorkflowStepEnum) => boolean = useCallback((workflowStep: WorkflowStepEnum) => {
        if (application.closeReason || application.legacy) {
            return false;
        }

        if (!canMoveToWorkflowStep(workflowStep)) {
            return false;
        }

        return buttonWorkflowStepAllowedStatusesMap[workflowStep].includes(application.workflowStatus);
    }, [
        application,
        canMoveToWorkflowStep,
    ]);

    const onClickClose: () => void = useCallback(() => {
        setIsCloseModalOpen(true);
    }, []);

    const onCloseCancel: () => void = useCallback(() => {
        setIsCloseModalOpen(false);
    }, []);

    const getDestinationStatusDocumentErrors: (destinationStatus: WorkflowStatusEnum) => string[] = useCallback((destinationStatus: WorkflowStatusEnum) => {
        const destinationStatusRequiredConditionTypes: ConditionTypeEnum[] = getRequiredConditionTypes(application, destinationStatus, aggregators);

        const documentErrors: string[] = [];

        const applicationConditions: ConditionTypeEnum[] = getApplicationConditions(application);
        applicationConditions.forEach((condition: ConditionTypeEnum) => {
            if (destinationStatusRequiredConditionTypes.includes(condition) && !approvedApplicationConditionDocumentExists(_.values(conditionDocuments), application, condition)) {
                documentErrors.push(`Application - ${conditionLabels[condition]}`);
            }
        });

        application.borrowers.forEach((borrower: IBorrower) => {
            const borrowerConditions: ConditionTypeEnum[] = getBorrowerConditions(application, borrower);
            borrowerConditions.forEach((condition: ConditionTypeEnum) => {
                if (destinationStatusRequiredConditionTypes.includes(condition) && !approvedBorrowerConditionDocumentExists(_.values(conditionDocuments), borrower, condition)) {
                    if (condition === ConditionTypeEnum.AdverseCreditStatement && borrower.creditScore > constants.APPLICATION_ADVERSE_CREDIT_SCORE_THRESHOLD) {
                        return;
                    }
                    documentErrors.push(`${borrower.dealBorrower.formattedName} - ${conditionLabels[condition]}`);
                }
            });
        });

        _.each(application.properties, (applicationProperty: IApplicationProperty) => {
            const propertyConditions: ConditionTypeEnum[] = getPropertyConditions(application, applicationProperty);
            propertyConditions.forEach((condition: ConditionTypeEnum) => {
                if (destinationStatusRequiredConditionTypes.includes(condition) && !approvedPropertyConditionDocumentExists(_.values(conditionDocuments), applicationProperty, condition)) {
                    documentErrors.push(`${applicationProperty.dealProperty.formattedAddress} - ${conditionLabels[condition]}`);
                }
            });
        });

        if ([WorkflowStatusEnum.FormalApproval, WorkflowStatusEnum.Warehoused].includes(destinationStatus)) {
            _.each(conditions, (condition: ICondition) => {
                if (!approvedCustomConditionDocumentExists(_.values(conditionDocuments), condition)) {
                    documentErrors.push(`Application - ${condition.name}`);
                }
            });
        }

        return documentErrors;
    }, [
        aggregators,
        application,
        conditionDocuments,
        conditions,
    ]);

    const showErrorsModal: (informationErrors: string[], documentErrors: string[]) => void = useCallback((informationErrors: string[], documentErrors: string[]) => {
        const missingInformationBlock: JSX.Element = informationErrors.length > 0 && (
            <>
                <p>Missing Information</p>
                <ul>
                    {informationErrors.map((error: string) => <li key={error}>{error}</li>)}
                </ul>
            </>
        );

        const missingDocumentsBlock: JSX.Element = documentErrors.length > 0 && (
            <>
                <p>Missing Documents</p>
                <ul>
                    {documentErrors.map((error: string) => <li key={error}>{error}</li>)}
                </ul>
            </>
        );

        Modal.error({
            content: (
                <>
                    {missingInformationBlock}
                    {missingDocumentsBlock}
                </>
            ),
            title: 'Unable to Proceed',
            width: 600,
        });
    }, []);

    const onClickUnderwriting: () => void = useCallback(() => {
        const informationErrors: string[] = [];

        const documentErrors: string[] = getDestinationStatusDocumentErrors(WorkflowStatusEnum.Underwriting);

        if (informationErrors.length > 0 || documentErrors.length > 0) {
            showErrorsModal(informationErrors, documentErrors);
        } else {
            dispatch(applicationUnderwritingAction(applicationUuid));
        }
    }, [
        applicationUuid,
        dispatch,
        getDestinationStatusDocumentErrors,
        showErrorsModal,
    ]);

    const validatePropertyValuationInspectionDate: (applicationProperty: IApplicationProperty, informationErrors: string[]) => void = useCallback((applicationProperty: IApplicationProperty, informationErrors: string[]) => {
        if (!applicationProperty.valuationInspectionDate) {
            informationErrors.push(`${applicationProperty.dealProperty.formattedAddress} - Valuation Inspection Date`);
        } else if (dayjs(applicationProperty.valuationInspectionDate) < dayjs().subtract(90, 'days')) {
            informationErrors.push(`${applicationProperty.dealProperty.formattedAddress} - Valuation Inspection Date Expired`);
        }
    }, []);

    const onClickFormalApproval: () => void = useCallback(() => {
        const isRenewal: boolean = ExtensionTypeEnum.Renewal === application.extensionType;

        const informationErrors: string[] = [];

        // We don't involve brokers or partners in renewals
        if (!isRenewal) {
            if ((application.deal.broker && application.deal.broker.approvalStatus !== BrokerApprovalStatusEnum.Approved) || (!application.deal.broker && (application.brokerageFee || application.deal.isBroker))) {
                informationErrors.push('Application - Approved Broker required');
            }

            if (application.deal.broker && ((application.brokerCommissionFormat === BrokerCommissionFormatEnum.Percentage && !application.brokerCommissionPercentage) || (application.brokerCommissionFormat === BrokerCommissionFormatEnum.Dollars && !application.brokerCommissionDollars) || !application.brokerCommissionFormat)) {
                informationErrors.push('Application - Broker Commission required');
            }

            const partner: IReferralPartner = application.deal.referralPartnerUuid && partners[application.deal.referralPartnerUuid];

            if ((partner && partner.approvalStatus !== PartnerApprovalStatusEnum.Approved) || (application.deal.isReferralPartner && !partner)) {
                informationErrors.push('Application - Approved Partner required');
            }

            if (partner && ((application.partnerCommissionFormat === PartnerCommissionFormatEnum.Percentage && !application.partnerCommissionPercentage) || (application.partnerCommissionFormat === PartnerCommissionFormatEnum.Dollars && !application.partnerCommissionDollars) || !application.partnerCommissionFormat)) {
                informationErrors.push('Application - Partner Commission required');
            }
        }

        if (!application.codeType) {
            informationErrors.push('Application - Loan Type');
        }
        if (!application.mortgageType) {
            informationErrors.push('Application - Mortgage Type');
        }

        if (!application.loanPurpose) {
            informationErrors.push('Application - Loan Purpose');
        } else {
            // Don't check properties on renewals as we already have them
            if (!isRenewal) {
                switch (application.loanPurpose) {
                    case LoanPurposeEnum.BridgingLoan:
                        if (!_.some(application.properties, { dealProperty: { purpose: PropertyPurposeEnum.Purchase } }) && !_.some(application.properties, { dealProperty: { purpose: PropertyPurposeEnum.Sell } })) {
                            informationErrors.push('Bridging Loan Application - Purchase or Sell property required');
                        }
                        break;
                    case LoanPurposeEnum.DebtConsolidation:
                        if (!_.some(application.properties, { dealProperty: { purpose: PropertyPurposeEnum.Security } })) {
                            informationErrors.push('Debt Consolidation Loan Application - Security property required');
                        }
                        break;
                    case LoanPurposeEnum.PurchaseNew:
                        if (!_.some(application.properties, { dealProperty: { purpose: PropertyPurposeEnum.Purchase } })) {
                            informationErrors.push('Purchase Loan Application - Purchase property required');
                        }
                        break;
                    case LoanPurposeEnum.Refinance:
                        if (!_.some(application.properties, { dealProperty: { purpose: PropertyPurposeEnum.Refinance } })) {
                            informationErrors.push('Refinance Loan Application - Refinance property required');
                        }
                        break;
                    case LoanPurposeEnum.RenovateOrBuild:
                        if (!_.some(application.properties, { dealProperty: { purpose: PropertyPurposeEnum.Develop } }) && !_.some(application.properties, { dealProperty: { purpose: PropertyPurposeEnum.PurchaseDevelop } })) {
                            informationErrors.push('Develop Loan Application - Develop or Purchase and Develop property required');
                        }
                        break;
                }
            }
        }
        if (!application.repaymentStrategy) {
            informationErrors.push('Application - Repayment Strategy');
        }
        if (!application.secondaryRepaymentStrategy) {
            informationErrors.push('Application - Secondary Repayment Strategy');
        }
        if (!application.disbursalType) {
            informationErrors.push('Application - Disbursal Type');
        }

        // Don't check borrowers on renewals as we already have them
        if (!isRenewal) {
            application.borrowers.forEach((borrower: IBorrower) => {
                if (borrower.dealBorrower.type === BorrowerTypeEnum.Individual && !borrower.dealBorrower.incomeType) {
                    informationErrors.push(`${borrower.dealBorrower.formattedName} - Income Type`);
                }

                if ([BorrowerTypeEnum.Company, BorrowerTypeEnum.Individual].includes(borrower.dealBorrower.type) && !borrower.creditScore) {
                    informationErrors.push(`${borrower.dealBorrower.formattedName} - Credit Score`);
                }

                switch (borrower.dealBorrower.type) {
                    case BorrowerTypeEnum.Company:
                        if (_.filter(application.borrowers, (loopBorrower: IBorrower) => loopBorrower.uuid !== borrower.uuid && loopBorrower.dealBorrower.category === BorrowerCategoryEnum.Guarantor).length === 0) {
                            informationErrors.push('Company Borrower Present - Guarantor required');
                        }
                        break;
                    case BorrowerTypeEnum.Trust:
                        if (_.filter(application.borrowers, (loopBorrower: IBorrower) => loopBorrower.uuid !== borrower.uuid && loopBorrower.dealBorrower.category === BorrowerCategoryEnum.Trustee).length === 0) {
                            informationErrors.push('Trust Borrower Present - Trustee required');
                        }
                        break;
                }
            });
        }

        // Don't check properties on renewals as we already have them
        if (!isRenewal) {
            _.each(application.properties, (applicationProperty: IApplicationProperty) => {
                const property: IProperty = applicationProperty.dealProperty;
                if (!property.purpose) {
                    informationErrors.push(`${property.formattedAddress} - Purpose`);
                }
                if (!property.strataType) {
                    informationErrors.push(`${property.formattedAddress} - Strata Type`);
                }
                if (!property.tenancyType) {
                    informationErrors.push(`${property.formattedAddress} - Tenancy Type`);
                }
                validatePropertyValuationInspectionDate(applicationProperty, informationErrors);
                if (!property.zoneType) {
                    informationErrors.push(`${property.formattedAddress} - Zoning`);
                }
                if (!property.folioNumber && !property.lotNumber && !property.planNumber && !property.volumeNumber) {
                    informationErrors.push(`${property.formattedAddress} - Title Information`);
                }
                if (!property.state) {
                    informationErrors.push(`${property.formattedAddress} - State`);
                }
                if (!property.postcode) {
                    informationErrors.push(`${property.formattedAddress} - Postcode`);
                }
                if (!property.lotSizeSquareMetres) {
                    informationErrors.push(`${property.formattedAddress} - Lot Size`);
                }
                if (!property.dwellingCount) {
                    informationErrors.push(`${property.formattedAddress} - Number of Dwellings`);
                }
            });
        }

        const documentErrors: string[] = getDestinationStatusDocumentErrors(WorkflowStatusEnum.FormalApproval);

        if (informationErrors.length > 0 || documentErrors.length > 0) {
            showErrorsModal(informationErrors, documentErrors);
        } else {
            dispatch(applicationFormalApprovalAction(applicationUuid));
        }
    }, [
        application,
        applicationUuid,
        dispatch,
        getDestinationStatusDocumentErrors,
        partners,
        showErrorsModal,
        validatePropertyValuationInspectionDate,
    ]);

    const onClickIssueFormalApproval: () => void = useCallback(() => {
        const isRenewal: boolean = ExtensionTypeEnum.Renewal === application.extensionType;

        const informationErrors: string[] = [];

        // We don't use solicitors for extensions
        if (!isRenewal && !application.solicitorUuid) {
            informationErrors.push('Application - Application Solicitor');
        }

        const documentErrors: string[] = getDestinationStatusDocumentErrors(WorkflowStatusEnum.LegalDocuments);

        if (informationErrors.length > 0 || documentErrors.length > 0) {
            showErrorsModal(informationErrors, documentErrors);
        } else {
            Modal.confirm({
                content: 'Are you sure you want to issue a formal approval for this application?',
                okText: 'Issue Formal Approval',
                onOk: () => {
                    dispatch(applicationFormalsAddAction(applicationUuid));
                },
                title: 'Issue Formal Approval',
            });
        }
    }, [
        application,
        applicationUuid,
        dispatch,
        getDestinationStatusDocumentErrors,
        showErrorsModal,
    ]);

    const onClickLegalDocuments: () => void = useCallback(() => {
        const isRenewal: boolean = ExtensionTypeEnum.Renewal === application.extensionType;

        const informationErrors: string[] = [];

        // We don't use solicitors for extensions
        if (!isRenewal && !application.solicitorUuid) {
            informationErrors.push('Application - Application Solicitor');
        }

        const documentErrors: string[] = getDestinationStatusDocumentErrors(WorkflowStatusEnum.LegalDocuments);

        if (informationErrors.length > 0 || documentErrors.length > 0) {
            showErrorsModal(informationErrors, documentErrors);
        } else {
            dispatch(applicationLegalDocumentsAction(applicationUuid));
        }
    }, [
        application,
        applicationUuid,
        dispatch,
        getDestinationStatusDocumentErrors,
        showErrorsModal,
    ]);

    const onClickSettlement: () => void = useCallback(() => {
        const informationErrors: string[] = [];

        if (!application.primaryBorrowerUuid) {
            informationErrors.push('Application - Primary Borrower');
        }

        const validEmailRecipients: IBorrower[] = _.filter(application.borrowers, (borrower: IBorrower) => borrower.email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(borrower.email));
        if (validEmailRecipients.length === 0) {
            informationErrors.push('Application - A Borrower with a valid email address is required');
        }

        _.each(application.properties, (applicationProperty: IApplicationProperty) => {
            const property: IProperty = applicationProperty.dealProperty;

            validatePropertyValuationInspectionDate(applicationProperty, informationErrors);

            if (propertyRequiresInsurance(property)) {
                if (!property.insuranceExpiryDate) {
                    informationErrors.push(`${property.formattedAddress} - Insurance Expiry Date`);
                }
            }
        });

        const documentErrors: string[] = getDestinationStatusDocumentErrors(WorkflowStatusEnum.Settlement);

        if (informationErrors.length > 0 || documentErrors.length > 0) {
            showErrorsModal(informationErrors, documentErrors);
        } else {
            dispatch(applicationSettlementAction(applicationUuid));
        }
    }, [
        application,
        applicationUuid,
        dispatch,
        getDestinationStatusDocumentErrors,
        showErrorsModal,
        validatePropertyValuationInspectionDate,
    ]);

    const onClickSettled: () => void = useCallback(() => {
        const informationErrors: string[] = [];
        let documentErrors: string[] = [];

        const isGracePeriod: boolean = ExtensionTypeEnum.GracePeriod === application.extensionType;

        if (!isGracePeriod) {
            _.each(application.properties, (applicationProperty: IApplicationProperty) => {
                const property: IProperty = applicationProperty.dealProperty;
                if (propertyRequiresInsurance(property)) {
                    if (!property.insuranceExpiryDate || dayjs(property.insuranceExpiryDate) < dayjs()) {
                        informationErrors.push(`${property.formattedAddress} - Insurance Date`);
                    }
                    if (!property.insuranceReplacementValue) {
                        informationErrors.push(`${property.formattedAddress} - Insurance Replacement Value`);
                    }
                }
                validatePropertyValuationInspectionDate(applicationProperty, informationErrors);
            });

            const prepaidInterestDisbursements: IApplicationDisbursement[] = _.filter(disbursements, { type: DisbursementTypeEnum.PrepaidInterest });
            if (prepaidInterestDisbursements.length === 0) {
                informationErrors.push('Application - Prepaid interest disbursement not provided');
            } else if (prepaidInterestDisbursements.length > 1) {
                informationErrors.push('Application - There should only be one prepaid interest disbursement');
            }

            const disbursementsTotal: number = _.reduce(disbursements, (sum: number, loopDisbursement: IApplicationDisbursement) => sum + loopDisbursement.amount, 0);

            if (Math.round(disbursementsTotal * 100) !== Math.round(application.loanAmount * 100)) {
                informationErrors.push('Application - Disbursements total does not match the loan amount');
            }

            documentErrors = getDestinationStatusDocumentErrors(WorkflowStatusEnum.Warehoused);
        }

        if (application.payoutFigure) {
            const netPrepaidBalanceOnSettlement: number = calculateNetPrepaidBalanceOnSettlement(application, properties);
            if (netPrepaidBalanceOnSettlement < 0) {
                informationErrors.push('Application - Net payout amount can\'t be lower than $0');
            }
        }

        if (informationErrors.length > 0 || documentErrors.length > 0) {
            showErrorsModal(informationErrors, documentErrors);
        } else {
            setIsSettledModalOpen(true);
        }
    }, [
        application,
        disbursements,
        getDestinationStatusDocumentErrors,
        properties,
        showErrorsModal,
        validatePropertyValuationInspectionDate,
    ]);

    const onSettledCancel: () => void = useCallback(() => {
        setIsSettledModalOpen(false);
    }, []);

    const onClickReopen: () => void = useCallback(() => {
        Modal.confirm({
            content: 'Are you sure you want to reopen this application?',
            okText: 'Reopen',
            onOk: () => {
                dispatch(applicationReopenAction(applicationUuid));
            },
            title: 'Reopen Application',
        });
    }, [
        applicationUuid,
        dispatch,
    ]);

    const conditionalApproval: () => void = useCallback(() => {
        dispatch(applicationConditionalApprovalAction(applicationUuid));
    }, [
        applicationUuid,
        dispatch,
    ]);
    const moveNew: () => void = useCallback(() => {
        dispatch(applicationNewAction(applicationUuid));
    }, [
        applicationUuid,
        dispatch,
    ]);

    if (!application || !currentAdministrator || !partners) {
        return (
            <LayoutAnt className='application'>
                <Spin />
            </LayoutAnt>
        );
    }

    const isGracePeriod: boolean = ExtensionTypeEnum.GracePeriod === application.extensionType;
    const isRenewal: boolean = ExtensionTypeEnum.Renewal === application.extensionType;

    const primaryBorrower: IBorrower = _.find(application.borrowers, { uuid: application.primaryBorrowerUuid }) as IBorrower;

    const breadcrumbBlock: JSX.Element = breadcrumb ? (
        <>
            <Breadcrumb.Item><Link to={`/applications/${application.uuid}`}>{application.code}</Link></Breadcrumb.Item>
            {breadcrumb}
        </>
    ) : (
        <Breadcrumb.Item>{application.code}</Breadcrumb.Item>
    );

    const workflowMenu: MenuProps = {
        items: [
            ...(shouldDisplayWorkflowMenuItem(WorkflowStepEnum.New) ? [
                {
                    key: 'new',
                    label: 'Move to New',
                    onClick: moveNew,
                },
            ] : []),
            ...(shouldDisplayWorkflowMenuItem(WorkflowStepEnum.ConditionalApproval) ? [
                {
                    key: 'conditional-approval',
                    label: 'Move to Conditional Approval',
                    onClick: conditionalApproval,
                },
            ] : []),
            ...(shouldDisplayWorkflowMenuItem(WorkflowStepEnum.Underwriting) ? [
                {
                    key: 'underwriting',
                    label: 'Move to Underwriting',
                    onClick: onClickUnderwriting,
                },
            ] : []),
            ...(shouldDisplayWorkflowMenuItem(WorkflowStepEnum.FormalApproval) ? [
                {
                    key: 'formal-approval',
                    label: 'Move to Formal Approval',
                    onClick: onClickFormalApproval,
                },
            ] : []),
            ...(shouldDisplayWorkflowMenuItem(WorkflowStepEnum.LegalDocuments) ? [
                {
                    key: 'legal-documents',
                    label: 'Move to Legal Documents',
                    onClick: onClickLegalDocuments,
                },
            ] : []),
            ...(shouldDisplayWorkflowMenuItem(WorkflowStepEnum.Settlement) ? [
                {
                    key: 'settlement',
                    label: 'Move to Settlement',
                    onClick: onClickSettlement,
                },
            ] : []),
            ...(shouldDisplayWorkflowMenuItem(WorkflowStepEnum.Settled) ? [
                {
                    key: 'settled',
                    label: 'Move to Settled',
                    onClick: onClickSettled,
                },
            ] : []),
        ],
    };

    const extra: JSX.Element = ![RoleEnum.BusinessDevelopmentManager, RoleEnum.InternalBusinessDevelopmentManager, RoleEnum.SeniorBusinessDevelopmentManager].includes(currentAdministrator.role) && (
        <Space>
            <Tag className='workflow-status'>{statusNames[application.workflowStatus]}</Tag>
            {!isGracePeriod && !application.legacy && !application.closeReason && <Button danger={true} onClick={onClickClose}>Close</Button>}
            {shouldDisplayWorkflowButton(WorkflowStepEnum.New) && <Button type='primary' onClick={moveNew}>Move to New</Button>}
            {shouldDisplayWorkflowButton(WorkflowStepEnum.IssueConditionalApproval) && <Link to={`/applications/${application.uuid}/conditional-approvals/add`}><Button type='primary'>Issue Conditional Approval</Button></Link>}
            {shouldDisplayWorkflowButton(WorkflowStepEnum.Underwriting) && <Button type='primary' onClick={onClickUnderwriting}>Move to Underwriting</Button>}
            {shouldDisplayWorkflowButton(WorkflowStepEnum.FormalApproval) && <Button type='primary' onClick={onClickFormalApproval}>Move to Formal Approval</Button>}
            {shouldDisplayWorkflowButton(WorkflowStepEnum.IssueFormalApproval) && <Button type='primary' onClick={onClickIssueFormalApproval}>Issue Formal Approval</Button>}
            {shouldDisplayWorkflowButton(WorkflowStepEnum.Settlement) && <Button type='primary' onClick={onClickSettlement}>Move to Settlement</Button>}
            {shouldDisplayWorkflowButton(WorkflowStepEnum.Settled) && <Button type='primary' onClick={onClickSettled}>Move to Settled</Button>}
            {!isGracePeriod && !application.legacy && !application.closeReason && <Dropdown.Button className='workflow-dropdown-button' menu={workflowMenu} />}
            {!isGracePeriod && !isRenewal && !application.legacy && application.closeReason && application.closeReason !== CloseReasonEnum.Cancelled && application.workflowStatus !== WorkflowStatusEnum.Warehoused && <Button danger={true} onClick={onClickReopen}>Reopen</Button>}
        </Space>
    );

    const bdm: IAdministrator = application.deal.bdmUuid && administrators[application.deal.bdmUuid];

    const contactContentBlock: JSX.Element = bdm ? (
        <React.Fragment>
            <p>Email: <a href={`mailto:${bdm.email}`}>{bdm.email || '-'}</a></p>
        </React.Fragment>
    ) : (application.deal.broker ? (
        <React.Fragment>
            <p>Phone: <a href={`tel:${application.deal.broker.phone}`}>{application.deal.broker.phone || '-'}</a></p>
            <p>Email: <a href={`mailto:${application.deal.broker.email}`}>{application.deal.broker.email || '-'}</a></p>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <p>Phone: <a href={`tel:${primaryBorrower && primaryBorrower.dealBorrower.phone}`}>{primaryBorrower ? primaryBorrower.dealBorrower.phone || '-' : '-'}</a></p>
            <p>Email: <a href={`mailto:${primaryBorrower && primaryBorrower.dealBorrower.email}`}>{primaryBorrower ? primaryBorrower.dealBorrower.email || '-' : '-'}</a></p>
        </React.Fragment>
    ));

    const contactBlock: JSX.Element | string = bdm ? (
        <Popover content={contactContentBlock} title={`${bdm.name} (BDM)`}>
            <a>{bdm.name} (BDM)</a>
        </Popover>
    ) : (application.deal.broker ? (
        <Popover content={contactContentBlock} title={`${application.deal.broker.firstName} ${application.deal.broker.lastName} (Broker)`}>
            <a>{application.deal.broker.firstName} {application.deal.broker.lastName} (Broker)</a>
        </Popover>
    ) : (
        <Popover content={contactContentBlock} title={`${primaryBorrower ? primaryBorrower.dealBorrower.formattedName : '-'}`}>
            <a>{primaryBorrower ? primaryBorrower.dealBorrower.formattedName : '-'}</a>
        </Popover>
    ));

    const closeReasonBlock: JSX.Element = application.closeReason && (
        <Descriptions.Item label='Close Reason'>{application.closeReason ? closeReasonLabels[application.closeReason] : 'None'}</Descriptions.Item>
    );

    const tagsBlock: JSX.Element = application.isExtension && (isGracePeriod ? <Tag color='orange'>Grace Period</Tag> : <Tag color='blue'>Extension</Tag>);

    const menuItems: ItemType[] = [
        ...(shouldDisplaySection(SectionEnum.Overview) ? [
            {
                key: 'overview',
                label: <NavLink to={`/applications/${application.uuid}`}>Overview</NavLink>,
            },
        ] : []),
        ...(shouldDisplaySection(SectionEnum.Edit) ? [
            {
                key: 'edit',
                label: <NavLink to={`/applications/${application.uuid}/edit`}>Edit</NavLink>,
            },
        ] : []),
        ...(shouldDisplaySection(SectionEnum.Borrowers) ? [
            {
                key: 'borrowers',
                label: <NavLink to={`/applications/${application.uuid}/borrowers`}>Borrowers</NavLink>,
            },
        ] : []),
        ...(shouldDisplaySection(SectionEnum.ConditionalApprovals) ? [
            {
                key: 'conditional-approvals',
                label: <NavLink to={`/applications/${application.uuid}/conditional-approvals`}>Conditional Approvals</NavLink>,
            },
        ] : []),
        ...(shouldDisplaySection(SectionEnum.FormalApprovals) ? [
            {
                key: 'formal-approvals',
                label: <NavLink to={`/applications/${application.uuid}/formal-approvals`}>Formal Approvals</NavLink>,
            },
        ] : []),
        ...(shouldDisplaySection(SectionEnum.Fees) ? [
            {
                key: 'fees',
                label: <NavLink to={`/applications/${application.uuid}/fees`}>Fees &amp; Outlays</NavLink>,
            },
        ] : []),
        ...(shouldDisplaySection(SectionEnum.Underwriting) ? [
            {
                key: 'underwriting',
                label: <NavLink to={`/applications/${application.uuid}/underwriting`}>Underwriting</NavLink>,
            },
        ] : []),
        ...(shouldDisplaySection(SectionEnum.RequiredInformation) ? [
            {
                key: 'required-information',
                label: <NavLink to={`/applications/${application.uuid}/required-information`}>Required Information</NavLink>,
            },
        ] : []),
        ...(shouldDisplaySection(SectionEnum.PropertyValuations) ? [
            {
                key: 'property-valuations',
                label: <NavLink to={`/applications/${application.uuid}/property-valuations`}>Property Valuations</NavLink>,
            },
        ] : []),
        ...(shouldDisplaySection(SectionEnum.SolicitorInstructions) ? [
            {
                key: 'solicitor-instructions',
                label: <NavLink to={`/applications/${application.uuid}/solicitor-instructions`}>Solicitor Instructions</NavLink>,
            },
        ] : []),
        ...(shouldDisplaySection(SectionEnum.CustomConditions) ? [
            {
                key: 'conditions',
                label: <NavLink to={`/applications/${application.uuid}/conditions`}>Custom Conditions</NavLink>,
            },
        ] : []),
        ...(shouldDisplaySection(SectionEnum.Disbursements) ? [
            {
                key: 'disbursements',
                label: <NavLink to={`/applications/${application.uuid}/disbursements`}>Disbursements</NavLink>,
            },
        ] : []),
        ...(shouldDisplaySection(SectionEnum.Warehouses) ? [
            {
                key: 'warehouses',
                label: <NavLink to={`/applications/${application.uuid}/warehouses`}>Warehouses</NavLink>,
            },
        ] : []),
        menuDivider,
        ...(shouldDisplaySection(SectionEnum.Properties) ? [
            {
                key: 'properties',
                label: <NavLink to={`/applications/${application.uuid}/properties`}>Properties</NavLink>,
            },
        ] : []),
        ...(shouldDisplaySection(SectionEnum.Documents) ? [
            {
                key: 'documents',
                label: <NavLink to={`/applications/${application.uuid}/documents`}>Documents</NavLink>,
            },
        ] : []),
        ...(shouldDisplaySection(SectionEnum.Notes) ? [
            {
                key: 'notes',
                label: <NavLink to={`/applications/${application.uuid}/notes`}>Notes</NavLink>,
            },
        ] : []),
        ...(shouldDisplaySection(SectionEnum.History) ? [
            {
                key: 'history',
                label: <NavLink to={`/applications/${application.uuid}/history`}>History</NavLink>,
            },
        ] : []),
        menuDivider,
        {
            key: 'lead',
            label: <NavLink to={`/leads/${application.dealUuid}`}><ArrowLeftOutlined /> Lead</NavLink>,
        },
        ...(application.previousLoanUuid && currentUser.permissions.includes(PermissionsEnum.Loans) ? [
            {
                key: 'previous-loan',
                label: <NavLink to={`/loans/${application.previousLoanUuid}`}><ArrowLeftOutlined /> Previous Loan</NavLink>,
            },
        ] : []),
        ...(application.loanUuid ? [
            {
                key: 'loan',
                label: <NavLink to={`/loans/${application.loanUuid}`}><ArrowRightOutlined /> Loan</NavLink>,
            },
        ] : []),
    ];

    return (
        <LayoutAnt className='application'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/applications'>Applications</Link></Breadcrumb.Item>
                {breadcrumbBlock}
            </Breadcrumb>
            <PageHeader
                className='header'
                extra={extra}
                ghost={false}
                subTitle={application.code}
                title={application.formattedName}
                tags={tagsBlock}
            >
                <Descriptions size='small' column={4}>
                    <Descriptions.Item label='Loan Amount'>{currencyFormatter.format(application.loanAmount)}</Descriptions.Item>
                    <Descriptions.Item label='Term'>{application.termMonths} months {application.termMonthsMinimum && `(min. ${application.termMonthsMinimum} months)`}</Descriptions.Item>
                    <Descriptions.Item label='Interest Rate'>{application.interestRate}%</Descriptions.Item>
                    <Descriptions.Item className='settlement-date' label='Settlement Date'><SettlementDate application={application} /></Descriptions.Item>
                    <Descriptions.Item label='Loan Officer'>
                        <AssigneeSelector application={application} />
                    </Descriptions.Item>
                    <Descriptions.Item label='Loan Processors'>
                        <LoanProcessorSelector deal={application.deal} />
                    </Descriptions.Item>
                    <Descriptions.Item label='BDM'>
                        <BdmSelector deal={application.deal} />
                    </Descriptions.Item>
                    <Descriptions.Item label='Broker'>
                        <BrokerSelector deal={application.deal} />
                    </Descriptions.Item>
                    <Descriptions.Item label='Contact'>{contactBlock}</Descriptions.Item>
                    {closeReasonBlock}
                    <Descriptions.Item label='Follow Up'><FollowUpTime dealUuid={application.deal.uuid} /></Descriptions.Item>
                </Descriptions>
            </PageHeader>
            <LayoutAnt className='content-wrapper'>
                <LayoutAnt.Sider className='menu' theme='light' width={240}>
                    <Menu
                        defaultSelectedKeys={[section]}
                        items={menuItems}
                        mode='inline'
                    />
                </LayoutAnt.Sider>
                <LayoutAnt.Content className={`content ${section}`}>
                    {children}
                </LayoutAnt.Content>
            </LayoutAnt>
            <CloseModal
                application={application}
                isOpen={isCloseModalOpen}
                onCancel={onCloseCancel}
            />
            <SettledModal
                application={application}
                isOpen={isSettledModalOpen}
                onCancel={onSettledCancel}
            />
        </LayoutAnt>
    );
}
