import { Button, Form, Modal, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import { applicationPropertyValuationDueDateAction } from '~Applications/actions';
import DatePicker from '~UI/DatePicker';
import { IDictionary } from '~utilities/IDictionary';

interface IDefaultedValues {
    valuationDueDate: string;
}

interface IState {
    dirtyFields: IDictionary<boolean>;
    valuationDueDate: string;
}

interface IProps {
    applicationProperty: IApplicationProperty;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsDispatch {
    updateValuationDueDate: (dueDate: string) => void;
}

type Props = IProps & IPropsDispatch;

class UpdateDueDateModal extends React.Component<Props, IState> {
    public state: IState = {
        dirtyFields: {},
        valuationDueDate: null,
    };

    constructor(props: Props) {
        super(props);

        this.getDefaultedValues = this.getDefaultedValues.bind(this);

        this.onChangeValuationDueDate = this.onChangeValuationDueDate.bind(this);

        this.onClickClear = this.onClickClear.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen, onCancel } = this.props;
        const { valuationDueDate } = this.getDefaultedValues();

        const valuationDueDateDayJs: Dayjs = valuationDueDate ? dayjs(valuationDueDate) : null;

        return (
            <Modal
                okText='Update Due Date'
                onCancel={onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Update Valuation Due Date'
                wrapClassName='property-valuation-update-due-date-modal'
            >
                <Form.Item className='valuation-due-date' label='Due Date'>
                    <Space>
                        <DatePicker onChange={this.onChangeValuationDueDate} value={valuationDueDateDayJs} format='DD/MM/YYYY' />
                        <Button onClick={this.onClickClear}>Clear</Button>
                    </Space>
                </Form.Item>
            </Modal>
        );
    }

    private getDefaultedValues(): IDefaultedValues {
        const { applicationProperty } = this.props;
        const { dirtyFields, valuationDueDate } = this.state;

        return {
            valuationDueDate: dirtyFields.valuationDueDate ? valuationDueDate : applicationProperty.valuationDueDate,
        };
    }

    private onChangeValuationDueDate(date: Dayjs) {
        const { dirtyFields, valuationDueDate } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                valuationDueDate: true,
            },
            valuationDueDate: dayjs(`${date.format('YYYY-MM-DD')} ${dayjs(valuationDueDate || undefined).format('HH:mm')}`).format(),
        });
    }

    private onClickClear() {
        const { dirtyFields } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                valuationDueDate: true,
            },
            valuationDueDate: null,
        });
    }

    private onClickOk() {
        const { valuationDueDate } = this.getDefaultedValues();

        this.props.updateValuationDueDate(valuationDueDate);
        this.props.onCancel();
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        updateValuationDueDate: (dueDate: string) => dispatch(applicationPropertyValuationDueDateAction(ownProps.applicationProperty.uuid, dueDate)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(UpdateDueDateModal);
