import React from 'react';
import { Link, match as routerMatch } from 'react-router-dom';
import IInvestmentSaleTransfer from '~Api/InvestmentSaleTransfer/IInvestmentSaleTransfer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import { Spin, Typography } from 'antd';
import Table, { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { IGlobalState } from '~reducer';
import {
    investmentSaleTransferInvestorAccountInvestmentTransactionsSelector,
    investmentSaleTransferSelector,
} from '~InvestmentSaleTransfers/selectors';
import { Dispatch } from 'redux';
import {
    investmentSaleTransferGetAction,
    investmentSaleTransferInvestorAccountInvestmentTransactionsListAction,
} from '~InvestmentSaleTransfers/actions';
import { connect } from 'react-redux';
import _ from 'lodash';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import IAccount from '~Api/Investor/IAccount';
import { currencyFormatter } from '~utilities/formatters';

interface IMatch {
    investmentSaleTransferUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    investorAccountInvestmentTransactions: IDictionary<IInvestorAccountInvestmentTransaction>;
    investmentSaleTransfer: IInvestmentSaleTransfer;
}

interface IPropsDispatch {
    investorAccountInvestmentTransactionsList: () => void;
    investmentSaleTransferGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Transactions extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.summary = this.summary.bind(this);
    }

    public componentDidMount(): void {
        const { investmentSaleTransfer, investorAccountInvestmentTransactions } = this.props;

        if (!investmentSaleTransfer) {
            this.props.investmentSaleTransferGet();
        }

        if (!investorAccountInvestmentTransactions) {
            this.props.investorAccountInvestmentTransactionsList();
        }
    }

    public render(): JSX.Element {
        const { match, investorAccountInvestmentTransactions, investmentSaleTransfer } = this.props;

        if (!investorAccountInvestmentTransactions || !investmentSaleTransfer) {
            return (
                <Layout investmentSaleTransferUuid={match.params.investmentSaleTransferUuid} section='transactions'>
                    <Typography.Title level={2}>Transactions</Typography.Title>
                    <Spin />
                </Layout>
            );
        }

        const columns: ColumnType<IInvestorAccountInvestmentTransaction>[] = [
            {
                dataIndex: 'investorAccount',
                render: (investorAccount: IAccount) => <Link to={`/investors/${investorAccount.investorUuid}/accounts/${investorAccount.uuid}`}>{investorAccount.code}</Link>,
                title: 'Account ID',
                width: '15%',
            },
            {
                dataIndex: 'investorAccount',
                render: (investorAccount: IAccount) => investorAccount.investorName,
                title: 'Investor',
            },
            {
                dataIndex: 'principalAmount',
                render: (principalAmount: number) => currencyFormatter.format(principalAmount),
                sorter: (a: IInvestorAccountInvestmentTransaction, b: IInvestorAccountInvestmentTransaction) => a.principalAmount > b.principalAmount ? 1 : -1,
                title: 'Amount',
                width: '15%',
            },
            {
                dataIndex: 'transactionTime',
                defaultSortOrder: 'descend',
                render: (transactionTime: string) => dayjs(transactionTime).format('DD/MM/YYYY HH:mm:ss'),
                sorter: (a: IInvestorAccountInvestmentTransaction, b: IInvestorAccountInvestmentTransaction) => dayjs(a.transactionTime) > dayjs(b.transactionTime) ? 1 : -1,
                title: 'Date',
                width: '15%',
            },
        ];

        return (
            <Layout investmentSaleTransferUuid={match.params.investmentSaleTransferUuid} section='transactions'>
                <Typography.Title level={2}>Transactions</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(investorAccountInvestmentTransactions)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                    summary={this.summary}
                />
            </Layout>
        );
    }

    private summary(): JSX.Element {
        const { investorAccountInvestmentTransactions } = this.props;

        const total: number = _.reduce(investorAccountInvestmentTransactions, (sum: number, transaction: IInvestorAccountInvestmentTransaction) => sum + transaction.principalAmount, 0);

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1} />
                <Table.Summary.Cell index={2}>
                    <Typography.Text>{currencyFormatter.format(total)}</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} />
            </Table.Summary.Row>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investmentSaleTransfer: investmentSaleTransferSelector(state, ownProps.match.params.investmentSaleTransferUuid),
        investorAccountInvestmentTransactions: investmentSaleTransferInvestorAccountInvestmentTransactionsSelector(state, ownProps.match.params.investmentSaleTransferUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investmentSaleTransferGet: () => dispatch(investmentSaleTransferGetAction(ownProps.match.params.investmentSaleTransferUuid)),
        investorAccountInvestmentTransactionsList: () => dispatch(investmentSaleTransferInvestorAccountInvestmentTransactionsListAction(ownProps.match.params.investmentSaleTransferUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Transactions);
