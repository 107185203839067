import { all, call, put, takeEvery } from '@redux-saga/core/effects';
import { IFetchResponse } from '~utilities/fetch';
import { ISolicitorsListAction, solicitorsSetAction } from './actions';
import SolicitorsActionsEnum from './ActionsEnum';
import ISolicitor from './ISolicitor';
import { parseSolicitor } from './parsers';
import { solicitorsListRequest } from './requests';

function* solicitorsList(action: ISolicitorsListAction): Iterator<any> {
    const solicitorsListResponse: IFetchResponse = yield call(solicitorsListRequest);
    const solicitors: ISolicitor[] = solicitorsListResponse.body.map(parseSolicitor);
    yield put(solicitorsSetAction(solicitors));
}

export function* SolicitorsSagas(): any {
    yield all([
        takeEvery(SolicitorsActionsEnum.SolicitorsList, solicitorsList),
    ]);
}
