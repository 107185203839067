import { Descriptions, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import { loanGetAction } from '~Loans/actions';
import { loanSelector } from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    loan: ILoan;
}

interface IPropsDispatch {
    loanGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Overview extends React.Component<Props> {
    public componentDidMount(): void {
        const { loan } = this.props;

        if (!loan) {
            this.props.loanGet();
        }
    }

    public render(): JSX.Element {
        const { loan, match } = this.props;

        if (!loan) {
            return (
                <Layout uuid={match.params.uuid} section='overview'>
                    <Typography.Title level={2}>Overview</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        return (
            <Layout uuid={match.params.uuid} section='overview'>
                <Typography.Title level={2}>Overview</Typography.Title>
                <Descriptions bordered={true} layout='vertical'>
                    <Descriptions.Item label='Start Date'>{dayjs(loan.startDate).format('Do MMMM YYYY')}</Descriptions.Item>
                    <Descriptions.Item label='End Date'>{dayjs(loan.endDate).format('Do MMMM YYYY')}</Descriptions.Item>
                    <Descriptions.Item label='Next Payment Date'>{loan.paymentDateNext ? dayjs(loan.paymentDateNext).format('Do MMMM YYYY') : '-'}</Descriptions.Item>
                    <Descriptions.Item label='Next Payment Amount'>{loan.paymentAmountNext ? currencyFormatter.format(loan.paymentAmountNext) : '-'}</Descriptions.Item>
                </Descriptions>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        loan: loanSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        loanGet: () => dispatch(loanGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Overview);
