import { Form, Input, Modal, Space, Switch, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IDealBorrower from '~Api/Deal/IDealBorrower';
import { applicationBorrowerBankruptcyCheckAction } from '~Applications/actions';
import { dealBorrowerValueSetAction } from '~Deals/actions';
import { applicationBorrowerDealBorrowerSelector } from '~Deals/selectors';
import { IGlobalState } from '~reducer';
import DatePicker from '~UI/DatePicker';
import IConditionComponentProps from './IConditionComponentProps';

interface IState {
    errors: { [fieldName: string] : string };
    isModalOpen: boolean;
    useMiddleName: boolean;
}

interface IPropsSelector {
    dealBorrower: IDealBorrower;
}

interface IPropsDispatch {
    bankruptcyCheck: (useMiddleName: boolean) => void;
    dealBorrowerValueSet: (dealBorrowerUuid: string, key: keyof IDealBorrower, value: boolean|number|string) => void;
}

type Props = IConditionComponentProps & IPropsSelector & IPropsDispatch;

class BankruptcyCheck extends React.Component<Props, IState> {
    public state: IState = {
        errors: {},
        isModalOpen: false,
        useMiddleName: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickSearch = this.onClickSearch.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);

        this.onChangeDob = this.onChangeDob.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeMiddleName = this.onChangeMiddleName.bind(this);
        this.onChangeUseMiddleName = this.onChangeUseMiddleName.bind(this);

        this.validateDob = this.validateDob.bind(this);
    }

    public render(): JSX.Element {
        const { dealBorrower } = this.props;
        const { errors, isModalOpen, useMiddleName } = this.state;

        if (!dealBorrower) {
            return null;
        }

        return (
            <React.Fragment>
                <a onClick={this.onClickSearch}><AiOutlineFileSearch/></a>
                <Modal
                    okText='Run Bankruptcy Check'
                    onCancel={this.onClickCancel}
                    onOk={this.onClickOk}
                    open={isModalOpen}
                    title='Bankruptcy Register Search Report'
                    wrapClassName='application-borrower-bankruptcy-check-modal'
                >
                    <Form.Item className='first-name' label='First Name'>
                        <Input onChange={this.onChangeFirstName} value={dealBorrower.firstName} />
                    </Form.Item>
                    <Form.Item className='middle-name-switch' label='Middle Name(s)'>
                        <Space>
                            <Input defaultValue={dealBorrower.middleName} onChange={this.onChangeMiddleName} value={dealBorrower.middleName} disabled={!useMiddleName} />
                            <Tooltip placement='top' title='Include in search'>
                                <Switch size='small' onChange={this.onChangeUseMiddleName} checked={useMiddleName} />
                            </Tooltip>
                        </Space>
                    </Form.Item>
                    <Form.Item className='last-name' label='Last Name'>
                        <Input onChange={this.onChangeLastName} value={dealBorrower.lastName} />
                    </Form.Item>
                    <Form.Item className='dob' label='DOB' help={errors.dob} validateStatus={errors.dob && 'error'}>
                        <DatePicker onChange={this.onChangeDob} format='DD/MM/YYYY' value={dealBorrower.dob ? dayjs(dealBorrower.dob) : null} />
                    </Form.Item>
                </Modal>
            </React.Fragment>
        );
    }

    private onClickSearch() {
        this.setState({
            isModalOpen: true,
        });
    }

    private onClickOk() {
        const { useMiddleName } = this.state;

        let valid: boolean = true;

        valid = this.validateDob() && valid;

        if (!valid) {
            return;
        }

        this.setState({
            isModalOpen: false,
        });

        this.props.bankruptcyCheck(useMiddleName);
    }

    private onClickCancel() {
        this.setState({
            isModalOpen: false,
        });
    }

    private onChangeDob(date: Dayjs) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'dob', date ? date.format('YYYY-MM-DD') : null);
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'firstName', event.target.value.substr(0, 50));
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'lastName', event.target.value.substr(0, 50));
    }

    private onChangeMiddleName(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'middleName', event.target.value.substr(0, 255));
    }

    private onChangeUseMiddleName() {
        this.setState((prevState: IState) => ({
            useMiddleName: !prevState.useMiddleName,
        }));
    }

    private validateDob(): boolean {
        const { dealBorrower } = this.props;
        const { errors } = this.state;

        let error: string = null;

        if (!/^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.test(dealBorrower.dob)) {
            error = 'Please enter a valid DOB';
        } else {
            const diff: number = dayjs.duration(dayjs().diff(dayjs(dealBorrower.dob, 'YYYY-MM-DD', true))).as('years');
            if (diff < 18) {
                error = 'Borrower age must be at least 18';
            } else if (diff > 120) {
                error = 'Borrower age must be less than 120';
            }
        }

        this.setState({
            errors: {
                ...errors,
                dob: error,
            },
        });

        return !error;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IConditionComponentProps): IPropsSelector {
    return {
        dealBorrower: applicationBorrowerDealBorrowerSelector(state, ownProps.applicationUuid, ownProps.applicationBorrowerUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IConditionComponentProps): IPropsDispatch {
    return {
        bankruptcyCheck: (useMiddleName: boolean) => dispatch(applicationBorrowerBankruptcyCheckAction(ownProps.applicationUuid, ownProps.applicationBorrowerUuid, useMiddleName)),
        dealBorrowerValueSet: (dealBorrowerUuid: string, key: keyof IDealBorrower, value: boolean|number|string) => dispatch(dealBorrowerValueSetAction(dealBorrowerUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BankruptcyCheck);
