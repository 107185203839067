import { Breadcrumb, Button, Descriptions, Layout as LayoutAnt, Menu, Modal, PageHeader, Space, Spin } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import { aggregatorsListAction } from '~Aggregators/actions';
import IAggregator from '~Api/Aggregator/IAggregator';
import { aggregatorsSelector } from '~Aggregators/selectors';
import ApprovalStatusEnum from '~Api/ReferralPartner/ApprovalStatusEnum';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import TypeEnum from '~Api/ReferralPartner/TypeEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import { IGlobalState } from '~reducer';
import {
    referralPartnerApproveAction,
    referralPartnerGetAction,
    referralPartnerRejectAction,
    referralPartnerResetAction,
} from '~ReferralPartners/actions';
import { referralPartnerSelector } from '~ReferralPartners/selectors';
import { IDictionary } from '~utilities/IDictionary';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import menuDivider from '~UI/menuDivider';

const typeLabels: IDictionary<string> = {
    [TypeEnum.Accountant]: 'Accountant',
    [TypeEnum.Leads]: 'Leads',
    [TypeEnum.Lender]: 'Lender',
    [TypeEnum.Other]: 'Other',
    [TypeEnum.RealEstate]: 'Real Estate',
};

interface IProps {
    uuid: string;
    breadcrumb?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    section: string;
}

interface IPropsSelector {
    aggregators: IDictionary<IAggregator>;
    currentUser: IAuthUser;
    referralPartner: IReferralPartner;
}

interface IPropsDispatch {
    aggregatorsList: () => void;
    approve: () => void;
    reject: () => void;
    resetStatus: () => void;
    referralPartnerGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Layout extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickApprove = this.onClickApprove.bind(this);
        this.onClickReject = this.onClickReject.bind(this);
        this.onClickResetStatus = this.onClickResetStatus.bind(this);
    }

    public componentDidMount(): void {
        const { aggregators, referralPartner } = this.props;

        if (!aggregators) {
            this.props.aggregatorsList();
        }

        if (!referralPartner) {
            this.props.referralPartnerGet();
        }
    }

    public render(): JSX.Element {
        const { aggregators, breadcrumb, children, referralPartner, section } = this.props;

        if (!aggregators || !referralPartner) {
            return (
                <LayoutAnt className='referral-partner'>
                    <Spin/>
                </LayoutAnt>
            );
        }

        const breadcrumbBlock: JSX.Element = breadcrumb ? (
            <React.Fragment>
                <Breadcrumb.Item><Link to={`/referral-partner/${referralPartner.uuid}`}>{referralPartner.firstName} {referralPartner.lastName}</Link></Breadcrumb.Item>
                {breadcrumb}
            </React.Fragment>
        ) : (
            <Breadcrumb.Item>{referralPartner.firstName} {referralPartner.lastName}</Breadcrumb.Item>
        );

        const extra: JSX.Element = (
            <Space>
                {referralPartner.approvalStatus === ApprovalStatusEnum.Pending && <Button onClick={this.onClickApprove} type='primary'>Approve</Button>}
                {referralPartner.approvalStatus === ApprovalStatusEnum.Pending && <Button onClick={this.onClickReject}>Reject</Button>}
                {referralPartner.approvalStatus === ApprovalStatusEnum.Approved && <Button onClick={this.onClickResetStatus}>Unapprove</Button>}
                {referralPartner.approvalStatus === ApprovalStatusEnum.Rejected && <Button onClick={this.onClickResetStatus}>Unreject</Button>}
            </Space>
        );

        const menuItems: ItemType[] = [
            {
                key: 'overview',
                label: <NavLink to={`/referral-partners/${referralPartner.uuid}`}>Overview</NavLink>,
            },
            {
                key: 'edit',
                label: <NavLink to={`/referral-partners/${referralPartner.uuid}/edit`}>Edit</NavLink>,
            },
            {
                key: 'deals',
                label: <NavLink to={`/referral-partners/${referralPartner.uuid}/leads`}>Leads</NavLink>,
            },
            {
                key: 'applications',
                label: <NavLink to={`/referral-partners/${referralPartner.uuid}/applications`}>Applications</NavLink>,
            },
            {
                key: 'documents',
                label: <NavLink to={`/referral-partners/${referralPartner.uuid}/documents`}>Documents</NavLink>,
            },
            {
                key: 'notes',
                label: <NavLink to={`/referral-partners/${referralPartner.uuid}/notes`}>Notes</NavLink>,
            },
            menuDivider,
            {
                key: 'history',
                label: <NavLink to={`/referral-partners/${referralPartner.uuid}/history`}>History</NavLink>,
            },
        ];

        return (
            <LayoutAnt className='referral-partner'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/referral-partners'>Referral Partners</Link></Breadcrumb.Item>
                    {breadcrumbBlock}
                </Breadcrumb>
                <PageHeader
                    className='header'
                    extra={extra}
                    ghost={false}
                    title={referralPartner.firstName + ' ' + referralPartner.lastName}
                >
                    <Descriptions size='small' column={4}>
                        <Descriptions.Item label='Type'>{typeLabels[referralPartner.type]}</Descriptions.Item>
                        <Descriptions.Item label='Email'>{referralPartner.email ? <a href={`mailto:${referralPartner.email}`}>{referralPartner.email}</a> : '-'}</Descriptions.Item>
                        <Descriptions.Item label='Phone'>{referralPartner.phone ? <a href={`tel:${referralPartner.phone}`}>{referralPartner.phone}</a> : '-'}</Descriptions.Item>
                        {referralPartner.linkedInUrl && <Descriptions.Item label='LinkedIn'><a href={referralPartner.linkedInUrl} target='_blank' rel='noreferrer'>{referralPartner.linkedInUrl}</a></Descriptions.Item>}
                        <Descriptions.Item label='Aggregator'>{referralPartner.aggregatorUuid ? aggregators[referralPartner.aggregatorUuid].name : '-'}</Descriptions.Item>
                        <Descriptions.Item label='Created'>{dayjs(referralPartner.createdTime).format('Do MMMM YYYY')}</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
                <LayoutAnt className='content-wrapper'>
                    <LayoutAnt.Sider className='menu' theme='light' width={240}>
                        <Menu
                            defaultSelectedKeys={[section]}
                            items={menuItems}
                            mode='inline'
                        />
                    </LayoutAnt.Sider>
                    <LayoutAnt.Content className={`content ${section}`}>
                        {children}
                    </LayoutAnt.Content>
                </LayoutAnt>
            </LayoutAnt>
        );
    }

    private onClickApprove(): void {
        const { referralPartner } = this.props;

        const errors: string[] = [];

        if (!!referralPartner.acl) {
            errors.push('This partner has an ACL');
        }
        if (!referralPartner.nccp) {
            errors.push('This partner must agree to comply with the NCCP Act');
        }
        if (!!referralPartner.banned) {
            errors.push('This partner has been banned');
        }
        if (!!referralPartner.chargesFee) {
            errors.push('This partner charges a fee to the consumer');
        }
        if (!!referralPartner.conductsBusinessInPerson) {
            errors.push('This partner conducts business in person');
        }
        if (!referralPartner.disclosesBenefits) {
            errors.push('This partner must agree to disclose to the consumer any benefits that may be received by the referrer');
        }
        if (!referralPartner.refersClient) {
            errors.push('This partner must agree to refer the client for Funding to deal with the client');
        }
        if (!referralPartner.includesCustomerInfo) {
            errors.push('This partner must agree to provide customer details');
        }

        if (errors.length > 0) {
            Modal.error({
                content: (
                    <React.Fragment>
                        <ul>
                            {errors.map((error: string) => <li key={error}>{error}</li>)}
                        </ul>
                    </React.Fragment>
                ),
                title: 'Unable to Proceed',
                width: 600,
            });
        } else {
            Modal.confirm({
                content: 'Are you sure you want to approve this referral partner?',
                okText: 'Approve',
                onOk: () => {
                    this.props.approve();
                },
                title: 'Approve',
            });
        }
    }

    private onClickReject(): void {
        Modal.confirm({
            content: 'Are you sure you want to reject this referral partner?',
            okText: 'Reject',
            onOk: () => {
                this.props.reject();
            },
            title: 'Reject',
        });
    }

    private onClickResetStatus(): void {
        const { referralPartner } = this.props;

        const actionText: string = referralPartner.approvalStatus === ApprovalStatusEnum.Approved ? 'Unapprove' : 'Unreject';
        Modal.confirm({
            content: `Are you sure you want to ${actionText.toLocaleLowerCase()} this referral partner?`,
            okText: actionText,
            onOk: () => {
                this.props.resetStatus();
            },
            title: actionText,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        aggregators: aggregatorsSelector(state),
        currentUser: authCurrentUserSelector(state),
        referralPartner: referralPartnerSelector(state, ownProps.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        aggregatorsList: () => dispatch(aggregatorsListAction()),
        approve: () => dispatch(referralPartnerApproveAction(ownProps.uuid)),
        referralPartnerGet: () => dispatch(referralPartnerGetAction(ownProps.uuid)),
        reject: () => dispatch(referralPartnerRejectAction(ownProps.uuid)),
        resetStatus: () => dispatch(referralPartnerResetAction(ownProps.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Layout);
