import { CheckOutlined } from '@ant-design/icons';
import { Button, Spin, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IReferral from '~Api/Investor/IReferral';
import { investorReferralsInvestorListAction } from '~Investors/actions';
import ReferralActions from '~Investors/ReferralActions';
import { investorReferralsInvestorSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import ReferralAddModal from './ReferralAddModal';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IState {
    isReferralModalOpen: boolean;
}

interface IPropsSelector {
    referrals: IReferral[];
}

interface IPropsDispatch {
    referralsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Referrals extends React.Component<Props, IState> {
    public state: IState = {
        isReferralModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickReferralAdd = this.onClickReferralAdd.bind(this);
        this.onCancelReferralAdd = this.onCancelReferralAdd.bind(this);
    }

    public componentDidMount() {
        const { referrals } = this.props;

        if (!referrals) {
            this.props.referralsList();
        }
    }

    public render(): JSX.Element {
        const { match, referrals } = this.props;
        const { isReferralModalOpen } = this.state;

        if (!referrals) {
            return (
                <Layout uuid={match.params.uuid} section='referrals'>
                    <Typography.Title level={2}>Referrals</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: any[] = [
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => dayjs(createdTime).format('Do MMMM YYYY HH:mm:ss'),
                sorter: (a: IReferral, b: IReferral) => dayjs(a.createdTime) > dayjs(b.createdTime)  ? 1 : -1,
                title: 'Created',
                width: '20%',
            },
            {
                dataIndex: 'investorRewardAmount',
                render: (investorRewardAmount: number) => currencyFormatter.format(investorRewardAmount),
                sorter: (a: IReferral, b: IReferral) => a.investorRewardAmount > b.investorRewardAmount ? 1 : -1,
                title: 'Reward',
                width: '10%',
            },
            {
                render: (referral: IReferral) => {
                    if (referral.referralInvestor) {
                        return <Link to={`/investors/${referral.referralInvestor.uuid}`}>{referral.referralInvestor.name}</Link>;
                    }

                    return referral.referralEmailAddress;
                },
                title: 'Referral',
                width: '40%',
            },
            {
                dataIndex: 'referralRewardAmount',
                render: (referralRewardAmount: number) => currencyFormatter.format(referralRewardAmount),
                sorter: (a: IReferral, b: IReferral) => a.referralRewardAmount > b.referralRewardAmount ? 1 : -1,
                title: 'Reward',
                width: '10%',
            },
            {
                filters: [{ text: 'Yes', value: true }, { text: 'No', value: false }],
                onFilter: (value: boolean, referral: IReferral) => value === referral.isEligible,
                render: (referral: IReferral) => referral.isEligible ? <CheckOutlined/> : '-',
                title: 'Eligible',
                width: '5%',
            },
            {
                filters: [{ text: 'Yes', value: true }, { text: 'No', value: false }],
                onFilter: (value: boolean, referral: IReferral) => value === referral.isProcessed,
                render: (referral: IReferral) => <ReferralActions referral={referral} />,
                title: 'Processed',
                width: '5%',
            },
        ];

        return (
            <Layout uuid={match.params.uuid} section='referrals'>
                <Button className='add' onClick={this.onClickReferralAdd}>Add Referral</Button>
                <Typography.Title level={2}>Referrals</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={referrals}
                    pagination={{ defaultPageSize: 50 }}
                    rowKey='uuid'
                    size='middle'
                />
                <ReferralAddModal
                    investorUuid={match.params.uuid}
                    isOpen={isReferralModalOpen}
                    onCancel={this.onCancelReferralAdd}
                />
            </Layout>
        );
    }

    private onClickReferralAdd() {
        this.setState({
            isReferralModalOpen: true,
        });
    }

    private onCancelReferralAdd() {
        this.setState({
            isReferralModalOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        referrals: investorReferralsInvestorSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        referralsList: () => dispatch(investorReferralsInvestorListAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Referrals);
