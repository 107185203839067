import _ from 'lodash';
import IInvestorAccountTransaction from '~Api/Investor/IInvestorAccountTransaction';
import IHistory from '~Api/WithdrawalAba/IHistory';
import IWithdrawalAba from '~Api/WithdrawalAba/IWithdrawalAba';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import IInvalidInvestorTransaction from '../Api/Investor/IInvalidInvestorTransaction';
import AccountTypeEnum from '~Api/WithdrawalAba/AccountTypeEnum';
import {
    investorAccountSelector,
    investorAccountTransactionSelector,
} from '~Investors/selectors';

export function withdrawalAbaSelector(state: IGlobalState, withdrawalAbaUuid: string): IWithdrawalAba {
    return state.withdrawalAbas.withdrawalAbas && state.withdrawalAbas.withdrawalAbas[withdrawalAbaUuid];
}

export function withdrawalAbasSelector(state: IGlobalState): IDictionary<IWithdrawalAba> {
    return state.withdrawalAbas.withdrawalAbasListed && state.withdrawalAbas.withdrawalAbas;
}

export function withdrawalAbaHistoriesSelector(state: IGlobalState, withdrawalAbaUuid: string): IDictionary<IHistory> {
    return state.withdrawalAbas.histories && state.withdrawalAbas.histories[withdrawalAbaUuid];
}

export function withdrawalAbasUnallocatedInvestorTransactionsSelector(state: IGlobalState, accountType: AccountTypeEnum): IDictionary<IInvestorAccountTransaction> {
    if (!state.withdrawalAbas.unallocatedInvestorTransactionUuids[accountType]) {
        return null;
    }

    const unallocatedInvestorTransactions: IDictionary<IInvestorAccountTransaction> = {};

    _.each(state.withdrawalAbas.unallocatedInvestorTransactionUuids[accountType], (transactionUuid: string) => {
        unallocatedInvestorTransactions[transactionUuid] = investorAccountTransactionSelector(state, transactionUuid);
    });

    return unallocatedInvestorTransactions;
}

export function withdrawalAbasInvalidInvestorTransactionsSelector(state: IGlobalState, accountType: AccountTypeEnum): IDictionary<IInvalidInvestorTransaction> {
    if (!state.withdrawalAbas.invalidInvestorTransactions[accountType]) {
        return null;
    }

    const invalidInvestorTransactions: IDictionary<IInvalidInvestorTransaction> = {};

    _.each(state.withdrawalAbas.invalidInvestorTransactions[accountType], (invalidInvestorTransaction: IInvalidInvestorTransaction) => {
        invalidInvestorTransactions[invalidInvestorTransaction.uuid] = {
            ...invalidInvestorTransaction,
            investorAccount: investorAccountSelector(state, invalidInvestorTransaction.investorAccountUuid),
        };
    });

    return invalidInvestorTransactions;
}

export function withdrawalAbaInvestorTransactionsSelector(state: IGlobalState, withdrawalAbaUuid: string): IDictionary<IInvestorAccountTransaction> {
    if (!state.investors.investorAccountTransactions || !state.withdrawalAbas.withdrawalAbaInvestorTransactionUuids || !state.withdrawalAbas.withdrawalAbaInvestorTransactionUuids[withdrawalAbaUuid]) {
        return null;
    }

    const investorAccountTransactions: IDictionary<IInvestorAccountTransaction> = {};

    _.each(state.withdrawalAbas.withdrawalAbaInvestorTransactionUuids[withdrawalAbaUuid], (investorTransactionUuid: string) => {
        investorAccountTransactions[investorTransactionUuid] = investorAccountTransactionSelector(state, investorTransactionUuid);
    });

    return investorAccountTransactions;
}
