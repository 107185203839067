const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
    currency: 'AUD',
    style: 'currency',
});

const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'percent',
});

export {
    currencyFormatter,
    percentageFormatter,
};
