import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin, Table, Tooltip, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import ICondition from '~Api/Application/ICondition';
import {
    applicationConditionDeleteAction,
    applicationConditionsAddAction,
    applicationConditionsListAction,
    applicationGetAction,
} from '~Applications/actions';
import {
    applicationConditionsSelector,
    applicationSelector,
} from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import { ColumnType } from 'antd/lib/table';
import { IDictionary } from '~utilities/IDictionary';
import _ from 'lodash';

interface IMatch {
    applicationUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    application: IApplication;
    conditions: IDictionary<ICondition>;
}

interface IPropsDispatch {
    applicationGet: () => void;
    conditionAdd: (condition: string) => void;
    conditionDelete: (conditionUuid: string) => void;
    conditionsList: () => void;
}

interface IState {
    name: string;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Conditions extends React.Component<Props> {
    public state: IState = {
        name: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeCondition = this.onChangeCondition.bind(this);
        this.onClickAdd = this.onClickAdd.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }

    public componentDidMount() {
        const { application, conditions } = this.props;

        if (!application) {
            this.props.applicationGet();
        }

        if (!conditions) {
            this.props.conditionsList();
        }
    }

    public render(): JSX.Element {
        const { application, conditions, match } = this.props;
        const { name } = this.state;

        if (!conditions || !application) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='conditions'>
                    <Spin/>
                </Layout>
            );
        }

        const columns: ColumnType<ICondition>[] = [
            {
                dataIndex: 'name',
                title: 'Name',
            },
            {
                render: (condition: ICondition) => {
                    const onClickDelete: () => void = () => this.onClickDelete(condition.uuid);

                    return (
                        <React.Fragment>
                            <Tooltip title='Delete'>
                                <Button key={condition.uuid}
                                    type='link'
                                    onClick={onClickDelete}
                                >
                                    <DeleteOutlined/>
                                </Button>
                            </Tooltip>
                        </React.Fragment>
                    );
                },
                title: 'Actions',
                width: '15%',
            },
        ];

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='conditions'>
                <Typography.Title level={2}>Custom Conditions</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(conditions)}
                    pagination={false}
                    rowKey='uuid'
                />
                <Form.Item label='Name' className='condition'>
                    <Input onChange={this.onChangeCondition} value={name} />
                </Form.Item>
                <Button
                    className='add-condition'
                    disabled={!name }
                    onClick={this.onClickAdd}
                    type='primary'
                >
                    Add Custom Condition
                </Button>
            </Layout>
        );
    }

    private onChangeCondition(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            name: event.target.value,
        });
    }

    private onClickAdd() {
        const { name } = this.state;

        this.setState({
            name: null,
        });

        this.props.conditionAdd(name);
    }

    private onClickDelete(conditionUuid: string): boolean {
        this.props.conditionDelete(conditionUuid);
        return false;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
        conditions: applicationConditionsSelector(state, ownProps.match.params.applicationUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
        conditionAdd: (name: string) => dispatch(applicationConditionsAddAction(ownProps.match.params.applicationUuid, name)),
        conditionDelete: (conditionUuid: string) => dispatch(applicationConditionDeleteAction(ownProps.match.params.applicationUuid, conditionUuid)),
        conditionsList: () => dispatch(applicationConditionsListAction(ownProps.match.params.applicationUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Conditions);
