import _ from 'lodash';
import { AnyAction } from 'redux';
import IAdministrator from '~Api/Administrator/IAdministrator';
import IHistory from '~Api/Administrator/IHistory';
import { IDictionary } from '~utilities/IDictionary';
import {
    IAdministratorHistoriesSetAction,
    IAdministratorPasskeySetAction,
    IAdministratorPasskeysSetAction,
    IAdministratorSetAction,
    IAdministratorValueSetAction,
    IAdministratorsSetAction,
} from './actions';
import AdministratorsActionsEnum from './ActionsEnum';
import IPasskey from '~Api/Administrator/IPasskey';

export interface IAdministratorsState {
    administratorPasskeyUuids: IDictionary<string[]>;
    administrators: IDictionary<IAdministrator>;
    histories: IDictionary<IDictionary<IHistory>>;
    passkeys: IDictionary<IPasskey>;
}

const initialData: IAdministratorsState = {
    administratorPasskeyUuids: {},
    administrators: null,
    histories: {},
    passkeys: {},
};

export function administratorsReducer(state: IAdministratorsState = initialData, action: AnyAction): IAdministratorsState {
    switch (action.type) {
        case AdministratorsActionsEnum.AdministratorHistoriesSet: {
            const typedAction: IAdministratorHistoriesSetAction = action as IAdministratorHistoriesSetAction;

            return {
                ...state,
                histories: {
                    ...state.histories,
                    [typedAction.uuid]: _.keyBy(typedAction.histories, 'uuid'),
                },
            };
        }

        case AdministratorsActionsEnum.AdministratorPasskeySet: {
            const typedAction: IAdministratorPasskeySetAction = action as IAdministratorPasskeySetAction;

            return {
                ...state,
                passkeys: {
                    ...state.passkeys,
                    [typedAction.administratorPasskey.uuid]: typedAction.administratorPasskey,
                },
            };
        }

        case AdministratorsActionsEnum.AdministratorPasskeysSet: {
            const typedAction: IAdministratorPasskeysSetAction = action as IAdministratorPasskeysSetAction;

            const passkeyUuids: string[] = [];
            const passkeys: IDictionary<IPasskey> = {};

            typedAction.passkeys.forEach((passkey: IPasskey) => {
                passkeyUuids.push(passkey.uuid);
                passkeys[passkey.uuid] = passkey;
            });

            return {
                ...state,
                administratorPasskeyUuids: {
                    ...state.administratorPasskeyUuids,
                    [typedAction.administratorUuid]: passkeyUuids,
                },
                passkeys: {
                    ...state.passkeys,
                    ...passkeys,
                },
            };
        }

        case AdministratorsActionsEnum.AdministratorSet: {
            const typedAction: IAdministratorSetAction = action as IAdministratorSetAction;

            return {
                ...state,
                administrators: {
                    ...state.administrators,
                    [typedAction.administrator.uuid]: typedAction.administrator,
                },
            };
        }

        case AdministratorsActionsEnum.AdministratorValueSet: {
            const typedAction: IAdministratorValueSetAction = action as IAdministratorValueSetAction;

            return {
                ...state,
                administrators: {
                    ...state.administrators,
                    [typedAction.uuid]: {
                        ...state.administrators[typedAction.uuid],
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        case AdministratorsActionsEnum.AdministratorsSet: {
            const typedAction: IAdministratorsSetAction = action as IAdministratorsSetAction;

            return {
                ...state,
                administrators: _.keyBy(typedAction.administrators, 'uuid'),
            };
        }

        default:
            return state;
    }
}
