import { Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import {
    investorAccountWithdrawalAbaInvestorTransactionsListAction,
    investorGetAction,
} from '~Investors/actions';
import {
    investorAccountSelector,
    investorAccountWithdrawalAbaInvestorTransactionsSelector,
    investorSelector,
} from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import { currencyFormatter } from '~utilities/formatters';
import IWithdrawalAba from '~Api/WithdrawalAba/IWithdrawalAba';
import IInvestorAccountTransaction from '~Api/Investor/IInvestorAccountTransaction';
import IWithdrawalAbaInvestorTransaction from '~Api/WithdrawalAba/IWithdrawalAbaInvestorTransaction';

interface IMatch {
    accountUuid: string;
    investorUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    investor: IInvestor;
    investorAccount: IAccount;
    withdrawalAbaInvestorTransactions: IDictionary<IWithdrawalAbaInvestorTransaction>;
}

interface IPropsDispatch {
    investorAccountWithdrawalAbaInvestorTransactionsList: () => void;
    investorGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class WithdrawalAbaInvestorTransactions extends React.Component<Props> {
    private loadingUuid: string = null;

    public componentDidMount(): void {
        const { investor, match } = this.props;

        if (!investor) {
            this.props.investorGet();
        }

        this.props.investorAccountWithdrawalAbaInvestorTransactionsList();

        this.loadingUuid = match.params.accountUuid;
    }

    public componentDidUpdate(): void {
        const { investor, match } = this.props;

        if (this.loadingUuid !== match.params.accountUuid) {
            if (!investor) {
                this.props.investorGet();
            }

            this.props.investorAccountWithdrawalAbaInvestorTransactionsList();

            this.loadingUuid = match.params.accountUuid;
        }
    }

    public render(): JSX.Element {
        const { withdrawalAbaInvestorTransactions, match } = this.props;

        if (!withdrawalAbaInvestorTransactions) {
            return (
                <Layout uuid={match.params.investorUuid} section={`'withdrawal-aba-investor-transactions-${match.params.accountUuid}`} sectionClass='withdrawal-aba-investor-transactions'>
                    <Typography.Title level={2}>Withdrawal Abas</Typography.Title>
                    <Spin />
                </Layout>
            );
        }

        const columns: ColumnType<IWithdrawalAbaInvestorTransaction>[] = [
            {
                dataIndex: 'withdrawalAba',
                render: (withdrawalAba: IWithdrawalAba) => <Link to={`/investors/withdrawal-abas/${withdrawalAba.uuid}/transactions`}>{dayjs(withdrawalAba.createdTime).format('DD/MM/YYYY HH:mm')}</Link>,
                title: 'Name',
            },
            {
                dataIndex: 'investorAccountTransaction',
                render: (investorAccountTransaction: IInvestorAccountTransaction) => currencyFormatter.format(investorAccountTransaction.amount),
                title: 'Transaction Amount',
                width: '20%',
            },
            {
                dataIndex: 'investorAccountTransaction',
                defaultSortOrder: 'descend',
                render: (investorAccountTransaction: IInvestorAccountTransaction) => dayjs(investorAccountTransaction.transactionTime).format('DD/MM/YYYY HH:mm'),
                sorter: (a: IWithdrawalAbaInvestorTransaction, b: IWithdrawalAbaInvestorTransaction) => dayjs(a.investorAccountTransaction.transactionTime) > dayjs(b.investorAccountTransaction.transactionTime) ? 1 : -1,
                title: 'Transaction Time',
                width: '30%',
            },
        ];

        return (
            <Layout uuid={match.params.investorUuid} section={`withdrawal-aba-investor-transactions-${match.params.accountUuid}`} sectionClass='withdrawal-aba-investor-transactions'>
                <Typography.Title level={2}>Withdrawal Abas</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(withdrawalAbaInvestorTransactions)}
                    pagination={{ defaultPageSize: 50 }}
                    rowKey='uuid'
                    size='middle'
                />
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investor: investorSelector(state, ownProps.match.params.investorUuid),
        investorAccount: investorAccountSelector(state, ownProps.match.params.accountUuid),
        withdrawalAbaInvestorTransactions: investorAccountWithdrawalAbaInvestorTransactionsSelector(state, ownProps.match.params.accountUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investorAccountWithdrawalAbaInvestorTransactionsList: () => dispatch(investorAccountWithdrawalAbaInvestorTransactionsListAction(ownProps.match.params.accountUuid)),
        investorGet: () => dispatch(investorGetAction(ownProps.match.params.investorUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WithdrawalAbaInvestorTransactions);
