import { SearchOutlined } from '@ant-design/icons';
import { Spin, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import IShare from '~Api/Investor/IShare';
import { investmentsListAction } from '~Investments/actions';
import { investmentsSelector } from '~Investments/selectors';
import {
    investorAccountSharesListAction,
    investorGetAction,
} from '~Investors/actions';
import {
    investorAccountSelector,
    investorAccountSharesSelector,
    investorSelector,
} from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import SearchDropdown from '~UI/SearchDropdown';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import { ColumnType } from 'antd/lib/table';
import { FilterDropdownProps } from 'antd/lib/table/interface';

interface IMatch {
    accountUuid: string;
    investorUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    account: IAccount;
    investments: IDictionary<IInvestment>;
    investor: IInvestor;
    shares: IShare[];
}

interface IPropsDispatch {
    investmentsList: () => void;
    investorGet: () => void;
    sharesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Shares extends React.Component<Props> {
    private loadingUuid: string = null;

    public componentDidMount(): void {
        const { investments, investor, match } = this.props;

        if (!investments) {
            this.props.investmentsList();
        }

        if (!investor) {
            this.props.investorGet();
        }

        this.props.sharesList();

        this.loadingUuid = match.params.accountUuid;
    }

    public componentDidUpdate(): void {
        const { investor, match } = this.props;

        if (this.loadingUuid !== match.params.accountUuid) {
            if (!investor) {
                this.props.investorGet();
            }

            this.props.sharesList();

            this.loadingUuid = match.params.accountUuid;
        }
    }

    public render(): JSX.Element {
        const { investments, investor, match, shares } = this.props;

        if (!investments || !investor || !shares) {
            return (
                <Layout uuid={match.params.investorUuid} section={`shares-${match.params.accountUuid}`} sectionClass='shares'>
                    <Typography.Title level={2}>Shares</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const columns: ColumnType<IShare>[] = [
            {
                dataIndex: 'loanName',
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: boolean|number|string, share: IShare) => share.loanName.toLowerCase().includes(value.toLocaleString().toLowerCase()),
                render: (loanName: string, share: IShare) => {
                    const investment: IInvestment = _.find(investments, { salesforceLoanId: share.salesforceLoanId });

                    if (!investment) {
                        return loanName;
                    }

                    return <Link to={`/investments/${investment.uuid}`}>{loanName}</Link>;
                },
                sorter: (a: IShare, b: IShare) => a.loanName.localeCompare(b.loanName),
                title: 'Loan',
            },
            {
                dataIndex: 'interestRate',
                render: (interestRate: number) => percentageFormatter.format(interestRate / 100),
                sorter: (a: IShare, b: IShare) => a.interestRate > b.interestRate ? 1 : -1,
                title: 'Rate',
                width: '10%',
            },
            {
                dataIndex: 'amountInvested',
                render: (amountInvested: number) => currencyFormatter.format(amountInvested),
                sorter: (a: IShare, b: IShare) => a.amountInvested > b.amountInvested ? 1 : -1,
                title: 'Invested',
                width: '15%',
            },
            {
                dataIndex: 'amountRemaining',
                render: (amountRemaining: number) => currencyFormatter.format(amountRemaining),
                sorter: (a: IShare, b: IShare) => a.amountRemaining > b.amountRemaining ? 1 : -1,
                title: 'Remaining',
                width: '15%',
            },
            {
                dataIndex: 'interestEarned',
                render: (interestEarned: number) => currencyFormatter.format(interestEarned),
                sorter: (a: IShare, b: IShare) => a.interestEarned > b.interestEarned ? 1 : -1,
                title: 'Earned',
                width: '10%',
            },
            {
                dataIndex: 'startDate',
                render: (startDate: string) => dayjs(startDate).format('DD/MM/YYYY'),
                sorter: (a: IShare, b: IShare) => dayjs(a.startDate) > dayjs(b.startDate)  ? 1 : -1,
                title: 'Starts',
                width: '10%',
            },
            {
                dataIndex: 'endDate',
                render: (endDate: string) => dayjs(endDate).format('DD/MM/YYYY'),
                sorter: (a: IShare, b: IShare) => dayjs(a.endDate) > dayjs(b.endDate)  ? 1 : -1,
                title: 'Expires',
                width: '10%',
            },
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => dayjs(createdTime).format('Do MMMM YYYY HH:mm:ss'),
                sorter: (a: IShare, b: IShare) => dayjs(a.createdTime) > dayjs(b.createdTime)  ? 1 : -1,
                title: 'Created',
                width: '15%',
            },
        ];

        return (
            <Layout uuid={match.params.investorUuid} section={`shares-${match.params.accountUuid}`} sectionClass='shares'>
                <Typography.Title level={2}>Shares</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={shares}
                    pagination={{ defaultPageSize: 50 }}
                    rowKey='uuid'
                    size='middle'
                />
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        account: investorAccountSelector(state, ownProps.match.params.accountUuid),
        investments: investmentsSelector(state),
        investor: investorSelector(state, ownProps.match.params.investorUuid),
        shares: investorAccountSharesSelector(state, ownProps.match.params.accountUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investmentsList: () => dispatch(investmentsListAction()),
        investorGet: () => dispatch(investorGetAction(ownProps.match.params.investorUuid)),
        sharesList: () => dispatch(investorAccountSharesListAction(ownProps.match.params.accountUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Shares);
