import { Spin, Timeline, Typography } from 'antd';
import React from 'react';
import { match as routerMatch } from 'react-router-dom';
import IHistory from '~Api/InvestmentSaleTransfer/IHistory';
import IInvestmentSaleTransfer from '~Api/InvestmentSaleTransfer/IInvestmentSaleTransfer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import dayjs from 'dayjs';
import _ from 'lodash';
import { IGlobalState } from '~reducer';
import {
    investmentSaleTransferHistoriesSelector,
    investmentSaleTransferSelector,
} from '~InvestmentSaleTransfers/selectors';
import {
    investmentSaleTransferGetAction,
    investmentSaleTransferHistoriesListAction,
} from '~InvestmentSaleTransfers/actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

interface IMatch {
    investmentSaleTransferUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    histories: IDictionary<IHistory>;
    investmentSaleTransfer: IInvestmentSaleTransfer;
}

interface IPropsDispatch {
    historiesList: () => void;
    investmentSaleTransferGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class History extends React.Component<Props> {
    public componentDidMount(): void {
        const { investmentSaleTransfer } = this.props;

        if (!investmentSaleTransfer) {
            this.props.investmentSaleTransferGet();
        }

        this.props.historiesList();
    }

    public render(): JSX.Element {
        const { histories, match, investmentSaleTransfer } = this.props;

        if (!investmentSaleTransfer || !histories) {
            return (
                <Layout investmentSaleTransferUuid={match.params.investmentSaleTransferUuid} section='history'>
                    <Typography.Title level={2}>History</Typography.Title>
                    <Spin />
                </Layout>
            );
        }

        const historiesBlock: JSX.Element[] = _.sortBy(_.filter(_.values(histories), (history: IHistory) => !!history.uuid), ['createdTime']).map((loopHistory: IHistory) => (
            <Timeline.Item key={loopHistory.uuid} label={dayjs(loopHistory.createdTime).format('YYYY-MM-DD - HH:mm')}>
                <Typography.Text>{loopHistory.description}</Typography.Text>
            </Timeline.Item>
        ));

        return (
            <Layout investmentSaleTransferUuid={match.params.investmentSaleTransferUuid} section='history'>
                <Typography.Title level={2}>History</Typography.Title>
                <Timeline mode='left' reverse={true}>
                    {historiesBlock}
                </Timeline>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        histories: investmentSaleTransferHistoriesSelector(state, ownProps.match.params.investmentSaleTransferUuid),
        investmentSaleTransfer: investmentSaleTransferSelector(state, ownProps.match.params.investmentSaleTransferUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        historiesList: () => dispatch(investmentSaleTransferHistoriesListAction(ownProps.match.params.investmentSaleTransferUuid)),
        investmentSaleTransferGet: () => dispatch(investmentSaleTransferGetAction(ownProps.match.params.investmentSaleTransferUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(History);