import MortgageTypeEnum from '~Api/Deal/MortgageTypeEnum';
import constants from '~constants';
import { IDealCalculateParameters } from '~Deals/utilities';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';

export interface IDealCalculateValidationErrors {
    establishmentFee?: string;
    interestRate?: string;
    legalFees?: string;
    maximumLvr?: string;
}

export function validateDealEstablishmentFee(parameters: IDealCalculateParameters, setError: (key: keyof IDealCalculateValidationErrors, value: string) => void): boolean {
    const {
        establishmentFeeDollarsMinimumOverride,
        establishmentFeePercentageMinimumOverride,
        establishmentFeeTotal,
        grossLoanAmount,
    } = parameters;

    const establishmentFeeDollarsMinimumLimit: number = establishmentFeeDollarsMinimumOverride ?? constants.DEAL_ESTABLISHMENT_FEE_DOLLARS_MINIMUM;
    const establishmentFeePercentageMinimumLimit: number = establishmentFeePercentageMinimumOverride ?? constants.DEAL_ESTABLISHMENT_FEE_PERCENTAGE_MINIMUM;

    let error: string = null;

    if (establishmentFeeTotal < establishmentFeeDollarsMinimumLimit) {
        error = `Establishment fee must be at least ${currencyFormatter.format(establishmentFeeDollarsMinimumLimit)}`;
    } else if (establishmentFeeTotal / grossLoanAmount < establishmentFeePercentageMinimumLimit / 100) {
        error = `Establishment fee must be at least ${percentageFormatter.format(establishmentFeePercentageMinimumLimit / 100)}`;
    }

    setError('establishmentFee', error);

    return !error;
}

export function validateDealInterestRate(parameters: IDealCalculateParameters, setError: (key: keyof IDealCalculateValidationErrors, value: string) => void): boolean {
    const { interestRate, interestRateMinimumOverride, mortgageType } = parameters;

    const interestRateMinimumLimit: number = interestRateMinimumOverride || (mortgageType === MortgageTypeEnum.SecondMortgage ? constants.DEAL_INTEREST_RATE_SECOND_MORTGAGE_MINIMUM : constants.DEAL_INTEREST_RATE_MINIMUM);

    let error: string = null;

    if (!interestRate || interestRate < interestRateMinimumLimit) {
        error = `Interest rate must be at least ${percentageFormatter.format(interestRateMinimumLimit / 100)}`;
    }

    setError('interestRate', error);

    return !error;
}

export function validateDealLegalFees(parameters: IDealCalculateParameters, setError: (key: keyof IDealCalculateValidationErrors, value: string) => void): boolean {
    const { legalFees, legalFeesDollarsMinimumOverride } = parameters;

    let error: string = null;

    const legalFeesDollarsMinimumLimit: number = legalFeesDollarsMinimumOverride ?? constants.DEAL_LEGAL_FEES_DOLLARS_MINIMUM;

    if (legalFees < legalFeesDollarsMinimumLimit) {
        error = `Legal fees must be at least ${currencyFormatter.format(legalFeesDollarsMinimumLimit)}`;
    }

    setError('legalFees', error);

    return !error;
}

export function validateDealMaximumLvr(parameters: IDealCalculateParameters, setError: (key: keyof IDealCalculateValidationErrors, value: string) => void): boolean {
    const { lvrMaximumOverride, maximumLvr, netPrepaidBalanceOnSettlement } = parameters;

    const lvrMaximumLimit: number = lvrMaximumOverride || constants.DEAL_LVR_MAXIMUM;

    let error: string = null;

    if (!maximumLvr) {
        error = 'Maximum LVR cannot be blank';
    } else if (maximumLvr > lvrMaximumLimit) {
        error = `Maximum LVR cannot be more than ${percentageFormatter.format(lvrMaximumLimit / 100)}`;
    } else if (netPrepaidBalanceOnSettlement < 0) {
        error = 'Maximum LVR cannot result in a negative payout';
    }

    setError('maximumLvr', error);

    return !error;
}
