import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import Layout from '~Layout';
import Application from './Application';
import Dashboard from './Dashboard';
import SettlementForecast from './SettlementForecast';
import List from './List';
import OutstandingValuations from './OutstandingValuations';
import ValuationExpiryNotifications from './ValuationExpiryNotifications';
import SettlementReport from './SettlementReport';
import UnpaidValuations from './UnpaidValuations';
import OutstandingBrokerCommissions from './OutstandingBrokerCommissions';

export default function Router(): ReactElement {
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);

    return (
        <Layout topSection='applications'>
            <Switch>
                <Route exact={true} path='/applications' component={Dashboard} />
                <Route exact={true} path='/applications/expiring-valuations' component={ValuationExpiryNotifications} />
                <Route exact={true} path='/applications/list' component={List} />
                {currentUser.permissions.includes(PermissionsEnum.Accounts) && <Route exact={true} path='/applications/outstanding-broker-commissions' component={OutstandingBrokerCommissions} />}
                <Route exact={true} path='/applications/outstanding-valuations' component={OutstandingValuations} />
                <Route exact={true} path='/applications/settlement-forecast' component={SettlementForecast} />
                {currentUser.permissions.includes(PermissionsEnum.ApplicationsSettlementReport) && <Route exact={true} path='/applications/settlement-report' component={SettlementReport} />}
                {currentUser.permissions.includes(PermissionsEnum.Accounts) && <Route exact={true} path='/applications/unpaid-valuations' component={UnpaidValuations} />}
                <Route path='/applications/:applicationUuid' component={Application} />
            </Switch>
        </Layout>
    );
}
