import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Spin } from 'antd';
import _ from 'lodash';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import {
    administratorsActiveSelector,
    administratorsSelector,
    currentAdministratorSelector,
} from '~Administrators/selectors';
import ActiveStatusEnum from '~Api/Administrator/ActiveStatusEnum';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import IDeal from '~Api/Deal/IDeal';
import { IDictionary } from '~utilities/IDictionary';
import {
    dealAssignProcessorAction,
    dealUnassignProcessorAction,
} from './actions';

export default function LoanProcessorSelector(props: {
    deal: IDeal;
}): ReactElement {
    const { deal } = props;

    const activeAdministrators: IAdministrator[] = useSelector(administratorsActiveSelector);
    const administrators: IDictionary<IAdministrator> = useSelector(administratorsSelector);
    const currentAdministrator: IAdministrator = useSelector(currentAdministratorSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!administrators) {
            dispatch(administratorsListAction());
        }
    }, [
        administrators,
        dispatch,
    ]);

    if (!activeAdministrators || !administrators || !currentAdministrator || !deal) {
        return (
            <Spin/>
        );
    }

    const loanProcessorUuids: string[] = deal.loanProcessorUuids;
    const loanProcessor: IAdministrator = administrators[deal.loanProcessorUuids[0]];

    if ([
        RoleEnum.BusinessDevelopmentManager,
        RoleEnum.InternalBusinessDevelopmentManager,
        RoleEnum.SeniorBusinessDevelopmentManager,
    ].includes(currentAdministrator.role)) {
        return (
            <>
                {loanProcessorUuids.length === 1 ? (loanProcessor ? loanProcessor.name : 'Inactive User') : (loanProcessorUuids.length > 1 ? loanProcessorUuids.length + ' Processors' : 'None')}
            </>
        );
    }

    const filteredAssignees: IAdministrator[] = _.filter(activeAdministrators, (admin: IAdministrator) => {
        return [
            RoleEnum.CreditManager,
            RoleEnum.SeniorCreditManager,
        ].includes(admin.role);
    });

    const dropdown: MenuProps = {
        items: [
            ..._.sortBy(filteredAssignees, ['name']).map((administrator: IAdministrator) => ({
                key: administrator.uuid,
                label: administrator.name,
            })),
            ..._.filter(loanProcessorUuids, (loanProcessorUuid: string) => {
                return administrators[loanProcessorUuid].status !== ActiveStatusEnum.Active;
            }).map((loanProcessorUuid: string) => ({
                key: loanProcessorUuid,
                label: 'Inactive User',
            })),
        ],
        multiple: true,
        onDeselect: (event: MenuInfo) => dispatch(dealUnassignProcessorAction(deal.uuid, event.key.toString())),
        onSelect: (event: MenuInfo) => dispatch(dealAssignProcessorAction(deal.uuid, event.key.toString())),
        selectable: true,
        selectedKeys: loanProcessorUuids,
    };

    return (
        <Dropdown menu={dropdown}>
            <a>
                {loanProcessorUuids.length === 1 ? (loanProcessor ? loanProcessor.name : 'Inactive User') : (loanProcessorUuids.length > 1 ? loanProcessorUuids.length + ' Processors' : 'None')}
                {' '}<DownOutlined/>
            </a>
        </Dropdown>
    );
}
