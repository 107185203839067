import { all, call, put, takeEvery } from '@redux-saga/core/effects';
import { notification } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import IInvestmentSaleTransfer from '~Api/InvestmentSaleTransfer/IInvestmentSaleTransfer';
import {
    parseInvestmentSaleTransfer,
    parseInvestmentSaleTransferHistory,
    parseUnallocatedInvestments,
} from '~Api/InvestmentSaleTransfer/parsers';
import {
    investmentSaleTransferGetRequest,
    investmentSaleTransferHistoriesListRequest,
    investmentSaleTransferInvestorAccountInvestmentTransactionsListRequest,
    investmentSaleTransferSendRequest,
    investmentSaleTransfersAddRequest,
    investmentSaleTransfersListRequest,
    investmentSaleTransfersUnallocatedInvestmentsListRequest,
} from '~Api/InvestmentSaleTransfer/requests';
import { IFetchResponse } from '~utilities/fetch';
import { renderNotificationLoadingIcon } from '~utilities/utils';
import {
    IInvestmentSaleTransferGetAction,
    IInvestmentSaleTransferHistoriesListAction,
    IInvestmentSaleTransferInvestorAccountInvestmentTransactionsListAction,
    IInvestmentSaleTransferSendAction,
    IInvestmentSaleTransfersAddAction,
    IInvestmentSaleTransfersListAction,
    IInvestmentSaleTransfersUnallocatedInvestmentsListAction,
    investmentSaleTransferHistoriesSetAction,
    investmentSaleTransferInvestorAccountInvestmentTransactionsSetAction,
    investmentSaleTransferSetAction,
    investmentSaleTransfersSetAction,
    investmentSaleTransfersUnallocatedInvestmentsListAction,
    investmentSaleTransfersUnallocatedInvestmentsSetAction,
} from './actions';
import InvestmentSaleTransfersActionsEnum from './ActionsEnum';
import IHistory from '~Api/InvestmentSaleTransfer/IHistory';
import IUnallocatedInvestment from '~Api/InvestmentSaleTransfer/IUnallocatedInvestment';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import { parseInvestorAccountInvestmentTransaction } from '~Api/Investor/parsers';

function* investmentSaleTransferGet(action: IInvestmentSaleTransferGetAction): Iterator<unknown> {
    const investmentSaleTransferResponse: IFetchResponse = yield call(investmentSaleTransferGetRequest, action.investmentSaleTransferUuid);
    const investmentSaleTransfer: IInvestmentSaleTransfer = parseInvestmentSaleTransfer(investmentSaleTransferResponse.body);
    yield put(investmentSaleTransferSetAction(investmentSaleTransfer));
}

function* investmentSaleTransferSend(action: IInvestmentSaleTransferSendAction): Iterator<unknown> {
    const key: string = `investmentSaleTransferSend ${action.investmentSaleTransferUuid}`;
    const message: string = 'Send Trustee Instructions Email';

    notification.open({
        description: 'Sending email...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    yield call(investmentSaleTransferSendRequest, action.investmentSaleTransferUuid);

    notification.success({
        description: 'The email has been sent.',
        duration: 4.5,
        key,
        message,
    });
}

function* investmentSaleTransferHistoriesList(action: IInvestmentSaleTransferHistoriesListAction): Iterator<unknown> {
    const historiesResponse: IFetchResponse = yield call(investmentSaleTransferHistoriesListRequest, action.investmentSaleTransferUuid);
    const histories: IHistory[] = yield Promise.all(historiesResponse.body.map(parseInvestmentSaleTransferHistory));
    yield put(investmentSaleTransferHistoriesSetAction(action.investmentSaleTransferUuid, histories));
}

function* investmentSaleTransferInvestorAccountInvestmentTransactionsList(action: IInvestmentSaleTransferInvestorAccountInvestmentTransactionsListAction): Iterator<unknown> {
    const investorAccountInvestmentTransactionsResponse: IFetchResponse = yield call(investmentSaleTransferInvestorAccountInvestmentTransactionsListRequest, action.investmentSaleTransferUuid);
    const investorAccountInvestmentTransactions: IInvestorAccountInvestmentTransaction[] = yield Promise.all(investorAccountInvestmentTransactionsResponse.body.map(parseInvestorAccountInvestmentTransaction));
    yield put(investmentSaleTransferInvestorAccountInvestmentTransactionsSetAction(action.investmentSaleTransferUuid, investorAccountInvestmentTransactions));
}

function* investmentSaleTransfersAdd(action: IInvestmentSaleTransfersAddAction): Iterator<unknown> {
    const key: string = `investmentSaleTransfersAdd ${dayjs().format()}`;
    const message: string = 'Add Investment Sale Transfer';

    notification.open({
        description: 'Adding Investment Sale Transfer...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const investmentSaleTransfersAddResponse: IFetchResponse = yield call(investmentSaleTransfersAddRequest, action.investmentUuid, action.investorAccountInvestmentTransactionsUuids);
    if (investmentSaleTransfersAddResponse.status === 422) {
        notification.error({
            description: `There was a problem adding the Investment Sale Transfer: ${_.values(investmentSaleTransfersAddResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const investmentSaleTransfer: IInvestmentSaleTransfer = parseInvestmentSaleTransfer(investmentSaleTransfersAddResponse.body);
        yield put(investmentSaleTransferSetAction(investmentSaleTransfer));

        notification.success({
            description: 'The Investment Sale Transfer has been added.',
            duration: 4.5,
            key,
            message,
        });

        yield put(investmentSaleTransfersUnallocatedInvestmentsListAction());
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* investmentSaleTransfersList(action: IInvestmentSaleTransfersListAction): Iterator<unknown> {
    const investmentSaleTransfersResponse: IFetchResponse = yield call(investmentSaleTransfersListRequest);
    const investmentSaleTransfers: IInvestmentSaleTransfer[] = yield Promise.all(investmentSaleTransfersResponse.body.map(parseInvestmentSaleTransfer));
    yield put(investmentSaleTransfersSetAction(investmentSaleTransfers));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* investmentSaleTransfersUnallocatedInvestmentsList(action: IInvestmentSaleTransfersUnallocatedInvestmentsListAction): Iterator<unknown> {
    const unallocatedInvestmentsResponse: IFetchResponse = yield call(investmentSaleTransfersUnallocatedInvestmentsListRequest);
    const unallocatedInvestments: IUnallocatedInvestment[] = yield Promise.all(unallocatedInvestmentsResponse.body.map(parseUnallocatedInvestments));
    yield put(investmentSaleTransfersUnallocatedInvestmentsSetAction(unallocatedInvestments));
}

export function* InvestmentSaleTransfersSagas(): Iterator<unknown> {
    yield all([
        takeEvery(InvestmentSaleTransfersActionsEnum.InvestmentSaleTransferGet, investmentSaleTransferGet),
        takeEvery(InvestmentSaleTransfersActionsEnum.InvestmentSaleTransferSend, investmentSaleTransferSend),

        takeEvery(InvestmentSaleTransfersActionsEnum.InvestmentSaleTransferHistoriesList, investmentSaleTransferHistoriesList),
        takeEvery(InvestmentSaleTransfersActionsEnum.InvestmentSaleTransferInvestorAccountInvestmentTransactionsList, investmentSaleTransferInvestorAccountInvestmentTransactionsList),

        takeEvery(InvestmentSaleTransfersActionsEnum.InvestmentSaleTransfersAdd, investmentSaleTransfersAdd),
        takeEvery(InvestmentSaleTransfersActionsEnum.InvestmentSaleTransfersList, investmentSaleTransfersList),

        takeEvery(InvestmentSaleTransfersActionsEnum.InvestmentSaleTransfersUnallocatedInvestmentsList, investmentSaleTransfersUnallocatedInvestmentsList),
    ]);
}
