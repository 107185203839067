import { Button, Space, Spin, Table, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IIndividual from '~Api/Investor/IIndividual';
import IInvestor from '~Api/Investor/IInvestor';
import InvestorTypeEnum from '~Api/Investor/InvestorTypeEnum';
import ICountry from '~Countries/ICountry';
import { countriesSelector } from '~Countries/selectors';
import { investorGetAction } from '~Investors/actions';
import { investorSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import IndividualAddModal from './IndividualAddModal';
import IndividualEditModal from './IndividualEditModal';
import Layout from './Layout';

interface IState {
    editIndividualIndex: number;
    isIndividualAddModalOpen: boolean;
}

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    countries: ICountry[];
    investor: IInvestor;
}

interface IPropsDispatch {
    investorGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Individuals extends React.Component<Props, IState> {
    public state: IState = {
        editIndividualIndex: null,
        isIndividualAddModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAddIndividual = this.onClickAddIndividual.bind(this);
        this.onCloseAddIndividual = this.onCloseAddIndividual.bind(this);
        this.onClickEditIndividual = this.onClickEditIndividual.bind(this);
        this.onCloseEditIndividual = this.onCloseEditIndividual.bind(this);
    }

    public componentDidMount(): void {
        const { investor } = this.props;

        if (!investor) {
            this.props.investorGet();
        }
    }

    public render(): JSX.Element {
        const { investor, match } = this.props;
        const { editIndividualIndex, isIndividualAddModalOpen } = this.state;

        if (!investor) {
            return (
                <Layout uuid={match.params.uuid} section='individuals'>
                    <Spin/>
                </Layout>
            );
        }

        const columns: any[] = [
            {
                render: (individual: IIndividual) => {
                    const onClick = (event: React.MouseEvent) => {
                        event.stopPropagation();
                        this.onClickEditIndividual(individual.index);
                    };

                    return (
                        <React.Fragment>
                            <a onClick={onClick}>{individual.firstName || individual.lastName ? `${individual.firstName || ''} ${individual.lastName || ''}` : '-'}</a>
                            <IndividualEditModal
                                investorUuid={investor.uuid}
                                index={individual.index}
                                isOpen={editIndividualIndex === individual.index}
                                onCancel={this.onCloseEditIndividual}
                            />
                        </React.Fragment>
                    );
                },
                title: 'Name',
            },
        ];

        const addIndividualButton: JSX.Element = [InvestorTypeEnum.JointIndividuals, InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany, InvestorTypeEnum.TrustIndividual].includes(investor.type) && investor.individuals.length < 2 && (
            <Space className='actions'>
                <Button className='add-individual' onClick={this.onClickAddIndividual}>Add Individual</Button>
            </Space>
        );

        return (
            <Layout uuid={match.params.uuid} section='individuals'>
                {addIndividualButton}
                <Typography.Title level={2}>Individuals</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={investor.individuals}
                    pagination={false}
                    rowKey='index'
                    size='middle'
                />
                <IndividualAddModal
                    investorUuid={investor.uuid}
                    isOpen={isIndividualAddModalOpen}
                    onCancel={this.onCloseAddIndividual}
                />
            </Layout>
        );
    }

    private onClickAddIndividual(): void {
        this.setState({
            isIndividualAddModalOpen: true,
        });
    }

    private onCloseAddIndividual(): void {
        this.setState({
            isIndividualAddModalOpen: false,
        });
    }

    private onClickEditIndividual(editIndividualIndex: number): void {
        this.setState({
            editIndividualIndex,
        });
    }

    private onCloseEditIndividual(event: React.MouseEvent): void {
        event.stopPropagation();
        this.setState({
            editIndividualIndex: null,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        countries: countriesSelector(state),
        investor: investorSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investorGet: () => dispatch(investorGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Individuals);
