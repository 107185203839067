import { WarningOutlined } from '@ant-design/icons';
import { Modal, Spin, Table, Tooltip } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvalidInvestorTransaction from '~/Api/Investor/IInvalidInvestorTransaction';
import IInvestorAccountTransaction from '~Api/Investor/IInvestorAccountTransaction';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import {
    withdrawalAbasAddAction,
    withdrawalAbasInvalidInvestorTransactionsListAction,
    withdrawalAbasUnallocatedInvestorTransactionsListAction,
} from './actions';
import {
     withdrawalAbasInvalidInvestorTransactionsSelector,
     withdrawalAbasUnallocatedInvestorTransactionsSelector,
} from './selectors';
import './withdrawal-abas.less';
import AccountTypeEnum from '~Api/WithdrawalAba/AccountTypeEnum';
import { currencyFormatter } from '~utilities/formatters';
import { TableRowSelection } from 'antd/lib/table/interface';
import IAccount from '~Api/Investor/IAccount';

interface ICheckboxProps {
    disabled: boolean;
    className: string;
    key: string;
}

interface IState {
    firstLoad: boolean;
    selectedRowKeys: string[];
}

interface IProps {
    accountType: AccountTypeEnum
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsSelector {
    invalidInvestorTransactions: IDictionary<IInvalidInvestorTransaction>;
    unallocatedInvestorTransactions: IDictionary<IInvestorAccountTransaction>;
}

interface IPropsDispatch {
    invalidInvestorTransactionsList: () => void;
    unallocatedInvestorTransactionsList: () => void;
    withdrawalAbaAdd: (selectedRowKeys: string[]) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

type Transaction = IInvalidInvestorTransaction & IInvestorAccountTransaction;

class AddModal extends React.Component<Props, IState> {
    public state: IState = {
        firstLoad: true,
        selectedRowKeys: [],
    };

    constructor(props: Props) {
        super(props);

        this.getCheckboxProps = this.getCheckboxProps.bind(this);
        this.onChangeSelectedRow = this.onChangeSelectedRow.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public componentDidMount(): void {
        const { invalidInvestorTransactions, unallocatedInvestorTransactions } = this.props;

        if (!invalidInvestorTransactions) {
            this.props.invalidInvestorTransactionsList();
        }

        if (!unallocatedInvestorTransactions) {
            this.props.unallocatedInvestorTransactionsList();
        }
    }

    public componentDidUpdate(): void {
        const { unallocatedInvestorTransactions } = this.props;
        const { firstLoad } = this.state;

        if (firstLoad && !!unallocatedInvestorTransactions) {
            const selectedRowKeys: string[] = _.map(unallocatedInvestorTransactions, (unallocatedInvestorTransaction: IInvestorAccountTransaction): string => unallocatedInvestorTransaction.uuid);

            this.setState({
                firstLoad: false,
                selectedRowKeys,
            });
        }
    }

    private getCheckboxProps(row: any): ICheckboxProps {
        return {
            className: null,
            disabled: !_.isEmpty(row.errors),
            key: row.key,
        };
    }

    public render(): JSX.Element {
        const { invalidInvestorTransactions, isOpen, unallocatedInvestorTransactions } = this.props;
        const { selectedRowKeys } = this.state;

        if (!invalidInvestorTransactions || !unallocatedInvestorTransactions) {
            return (
                <Modal
                    onCancel={this.props.onCancel}
                    onOk={this.onClickOk}
                    okText='Add'
                    okButtonProps={{ disabled: !selectedRowKeys.length }}
                    open={isOpen}
                    title='Add Withdrawal ABA'
                    width={900}
                    wrapClassName='withdrawal-abas-add-modal'
                >
                    <Spin />
                </Modal>
            );
        }

        const rowSelection: TableRowSelection<IInvestorAccountTransaction>  = {
            getCheckboxProps: this.getCheckboxProps,
            onChange: this.onChangeSelectedRow,
            renderCell: (value: boolean, record: Transaction, index: number, originNode: React.ReactNode): React.ReactNode => {
                if (!_.isEmpty(record.errors)) {
                    return {
                        children: <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={record.errors.join('\n')}><WarningOutlined /></Tooltip>,
                    };
                }

                return originNode;
            },
            selectedRowKeys,
        };

        const columns: ColumnType<Transaction>[] = [
            {
                dataIndex: 'investorAccount',
                render: (investorAccount: IAccount) => <Link to={`/investors/${investorAccount.investorUuid}/accounts/${investorAccount.uuid}`}>{investorAccount.code}</Link>,
                title: 'Account ID',
                width: '15%',
            },
            {
                dataIndex: 'investorAccount',
                render: (investorAccount: IAccount) => investorAccount.investorName,
                title: 'Investor',
            },
            {
                dataIndex: 'amount',
                render: (amount: number) => currencyFormatter.format(amount),
                sorter: (a: Transaction, b: Transaction) => a.amount > b.amount ? 1 : -1,
                title: 'Amount',
                width: '15%',
            },
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => dayjs(createdTime).format('DD/MM/YYYY HH:mm'),
                sorter: (a: Transaction, b: Transaction) => dayjs(a.createdTime) > dayjs(b.createdTime) ? 1 : -1,
                title: 'Created',
                width: '17%',
            },
        ];

        const transactions: IDictionary<Transaction> = {
            ...invalidInvestorTransactions,
            ...unallocatedInvestorTransactions,
        };

        return (
            <Modal
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                okText='Add'
                okButtonProps={{ disabled: !selectedRowKeys.length }}
                open={isOpen}
                title='Add Withdrawal ABA'
                width={900}
                wrapClassName='withdrawal-abas-add-modal'
            >
                <Table
                    columns={columns}
                    dataSource={_.values(transactions)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                    rowSelection={rowSelection}
                    scroll={{ y: 600 }}
                />
            </Modal>
        );
    }

    private onChangeSelectedRow(selectedRowKeys: string[]): void {
        this.setState({ selectedRowKeys });
    }

    private onClickOk(): void {
        const { selectedRowKeys } = this.state;

        this.props.withdrawalAbaAdd(selectedRowKeys);
        this.props.onCancel();
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        invalidInvestorTransactions: withdrawalAbasInvalidInvestorTransactionsSelector(state, ownProps.accountType),
        unallocatedInvestorTransactions: withdrawalAbasUnallocatedInvestorTransactionsSelector(state, ownProps.accountType),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        invalidInvestorTransactionsList: () => dispatch(withdrawalAbasInvalidInvestorTransactionsListAction(ownProps.accountType)),
        unallocatedInvestorTransactionsList: () => dispatch(withdrawalAbasUnallocatedInvestorTransactionsListAction(ownProps.accountType)),
        withdrawalAbaAdd: (selectedRowKeys: string[]) => dispatch(withdrawalAbasAddAction(ownProps.accountType, selectedRowKeys)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddModal);
