import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import Layout from '~Layout';
import ScenarioCalculator from '~Leads/ScenarioCalculator';
import Dashboard from './Dashboard';
import Lead from './Lead';
import './leads.less';
import List from './List';
import OriginationReport from './OriginationReport';
import RatesMatrix from './RatesMatrix';
import MovementReport from './MovementReport';
import PendingQuotes from './PendingQuotes';

export default function Router(): ReactElement {
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);

    return (
        <Layout topSection='leads'>
            <Switch>
                <Route exact={true} path='/leads' component={Dashboard} />
                <Route exact={true} path='/leads/list' component={List} />
                {currentUser.permissions.includes(PermissionsEnum.LeadsMovementReport) && <Route exact={true} path='/leads/movement-report' component={MovementReport} />}
                {currentUser.permissions.includes(PermissionsEnum.LeadsOriginationReport) && <Route exact={true} path='/leads/origination-report' component={OriginationReport} />}
                {currentUser.permissions.includes(PermissionsEnum.DealQuoteApproval) && <Route exact={true} path='/leads/pending-scenarios' component={PendingQuotes} />}
                <Route exact={true} path='/leads/rates-matrix' component={RatesMatrix} />
                <Route exact={true} path='/leads/scenario-calculator' component={ScenarioCalculator} />
                <Route path='/leads/:dealUuid' component={Lead} />
            </Switch>
        </Layout>
    );
}
