import { Tag } from 'antd';
import React from 'react';
import ILoan from '~Api/Loan/ILoan';
import WorkflowStatusEnum from '~Api/Loan/WorkflowStatusEnum';
import { IDictionary } from '~utilities/IDictionary';

interface ITag {
    colour: string;
    label: string;
}

const tagDetails: IDictionary<ITag> = {
    [WorkflowStatusEnum.Discharge]: {
        colour: 'green',
        label: 'Discharge',
    },
    [WorkflowStatusEnum.Extension]: {
        colour: 'blue',
        label: 'Extension',
    },
    [WorkflowStatusEnum.GracePeriod]: {
        colour: 'orange',
        label: 'Grace Period',
    },
    [WorkflowStatusEnum.Recovery]: {
        colour: 'red',
        label: 'Recovery',
    },
};

interface IProps {
    loan: ILoan;
}

const LoanStatusTag: React.FC<IProps> = ({ loan }: IProps) => {
    if ([
        WorkflowStatusEnum.Discharge,
        WorkflowStatusEnum.Extension,
        WorkflowStatusEnum.GracePeriod,
        WorkflowStatusEnum.Recovery,
    ].includes(loan.workflowStatus)) {
        return (
            <Tag color={tagDetails[loan.workflowStatus].colour}>
                {tagDetails[loan.workflowStatus].label}
            </Tag>
        );
    }

    return null;
};

export default LoanStatusTag;
