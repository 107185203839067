import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Edit from './Edit';
import History from './History';
import Notes from './Notes';
import Overview from './Overview';
import Investors from './Investors';

export default function router(): JSX.Element {
    return (
        <Switch>
            <Route exact={true} path='/advisers/:adviserUuid' component={Overview} />
            <Route exact={true} path='/advisers/:adviserUuid/edit' component={Edit} />
            <Route exact={true} path='/advisers/:adviserUuid/history' component={History} />
            <Route exact={true} path='/advisers/:adviserUuid/investors' component={Investors} />
            <Route exact={true} path='/advisers/:adviserUuid/notes' component={Notes} />
        </Switch>
    );
}
