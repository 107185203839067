import { Form, Input, Select, Spin, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import {
    administratorGetAction,
    administratorValueSetAction,
} from '~Administrators/actions';
import { administratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import { currencyFormatter } from '~utilities/formatters';

interface IMatch {
    administratorUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    administrator: IAdministrator;
}

interface IPropsDispatch {
    administratorGet: () => void;
    administratorValueSet: (key: keyof IAdministrator, value: boolean|number|string|string[]) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Edit extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePhotoUrl = this.onChangePhotoUrl.bind(this);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeRole = this.onChangeRole.bind(this);
        this.onChangeDealMaximumValue = this.onChangeDealMaximumValue.bind(this);
        this.onChangeDealMinimumValue = this.onChangeDealMinimumValue.bind(this);
    }

    public componentDidMount() {
        const { administrator } = this.props;

        if (!administrator) {
            this.props.administratorGet();
        }
    }

    public render(): JSX.Element {
        const { administrator, match } = this.props;

        if (!administrator) {
            return (
                <Layout administratorUuid={match.params.administratorUuid} section='edit'>
                    <Typography.Title level={2}>Edit</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const dealMinimumBlock: JSX.Element = [RoleEnum.CreditManager, RoleEnum.InternalBusinessDevelopmentManager, RoleEnum.SeniorCreditManager].includes(administrator.role) && (
            <Form.Item label='Deal Minimum' className='deal-minimum-value' extra={currencyFormatter.format(administrator.dealMinimumValue)}>
                <Input addonBefore='$' onChange={this.onChangeDealMinimumValue} type='number' value={administrator.dealMinimumValue} />
            </Form.Item>
        );

        const dealMaximumBlock: JSX.Element = [RoleEnum.CreditManager, RoleEnum.InternalBusinessDevelopmentManager, RoleEnum.SeniorCreditManager].includes(administrator.role) && (
            <Form.Item label='Deal Maximum' className='deal-maximum-value' extra={currencyFormatter.format(administrator.dealMaximumValue)}>
                <Input addonBefore='$' onChange={this.onChangeDealMaximumValue} type='number' value={administrator.dealMaximumValue} />
            </Form.Item>
        );

        return (
            <Layout administratorUuid={match.params.administratorUuid} section='edit'>
                <Typography.Title level={2}>Edit</Typography.Title>
                <Form.Item label='Name' className='name'>
                    <Input onChange={this.onChangeName} value={administrator.name} />
                </Form.Item>
                <Form.Item label='Email' className='email'>
                    <Input onChange={this.onChangeEmail} value={administrator.email} />
                </Form.Item>
                <Form.Item label='Photo URL' className='photo-url'>
                    <Input onChange={this.onChangePhotoUrl} value={administrator.photoUrl} />
                </Form.Item>
                <Form.Item label='Title' className='title'>
                    <Input onChange={this.onChangeTitle} value={administrator.title} />
                </Form.Item>
                <Form.Item label='Role' className='role'>
                    <Select onChange={this.onChangeRole} value={administrator.role}>
                        <Select.Option value={null}>None</Select.Option>
                        <Select.Option key={RoleEnum.BusinessDevelopmentManager} value={RoleEnum.BusinessDevelopmentManager}>Business Development Manager</Select.Option>
                        <Select.Option key={RoleEnum.CreditManager} value={RoleEnum.CreditManager}>Credit Manager</Select.Option>
                        <Select.Option key={RoleEnum.InternalBusinessDevelopmentManager} value={RoleEnum.InternalBusinessDevelopmentManager}>Internal Business Development Manager</Select.Option>
                        <Select.Option key={RoleEnum.InvestorManager} value={RoleEnum.InvestorManager}>Investor Manager</Select.Option>
                        <Select.Option key={RoleEnum.LoanManager} value={RoleEnum.LoanManager}>Loan Manager</Select.Option>
                        <Select.Option key={RoleEnum.SeniorBusinessDevelopmentManager} value={RoleEnum.SeniorBusinessDevelopmentManager}>Senior Business Development Manager</Select.Option>
                        <Select.Option key={RoleEnum.SeniorCreditManager} value={RoleEnum.SeniorCreditManager}>Senior Credit Manager</Select.Option>
                        <Select.Option key={RoleEnum.SeniorInvestorManager} value={RoleEnum.SeniorInvestorManager}>Senior Investor Manager</Select.Option>
                        <Select.Option key={RoleEnum.SeniorLoanManager} value={RoleEnum.SeniorLoanManager}>Senior Loan Manager</Select.Option>
                        <Select.Option key={RoleEnum.SuperAdmin} value={RoleEnum.SuperAdmin}>Super Admin</Select.Option>
                    </Select>
                </Form.Item>
                {dealMinimumBlock}
                {dealMaximumBlock}
            </Layout>
        );
    }

    private onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.administratorValueSet('name', event.target.value);
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.administratorValueSet('email', event.target.value);
    }

    private onChangePhotoUrl(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.administratorValueSet('photoUrl', event.target.value);
    }

    private onChangeTitle(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.administratorValueSet('title', event.target.value);
    }

    private onChangeRole(value: RoleEnum) {
        this.props.administratorValueSet('role', value);
    }

    private onChangeDealMaximumValue(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.administratorValueSet('dealMaximumValue', event.target.value.length > 0 ? Number(event.target.value) : null);
    }

    private onChangeDealMinimumValue(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.administratorValueSet('dealMinimumValue', event.target.value.length > 0 ? Number(event.target.value) : null);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        administrator: administratorSelector(state, ownProps.match.params.administratorUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        administratorGet: () => dispatch(administratorGetAction(ownProps.match.params.administratorUuid)),
        administratorValueSet: (key: keyof IAdministrator, value: boolean|number|string|string[]) => dispatch(administratorValueSetAction(ownProps.match.params.administratorUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Edit);
