import _ from 'lodash';
import { AnyAction } from 'redux';
import IDocument from '~Api/Loan/IDocument';
import IHistory from '~Api/Loan/IHistory';
import ILoan from '~Api/Loan/ILoan';
import ILoanDisbursement from '~Api/Loan/ILoanDisbursement';
import ILoanFee from '~Api/Loan/ILoanFee';
import ILoanGracePeriod from '~Api/Loan/ILoanGracePeriod';
import ILoanNote from '~Api/Loan/ILoanNote';
import ILoanPayoutFigure from '~Api/Loan/ILoanPayoutFigure';
import ILoanPayoutFigureItem from '~Api/Loan/ILoanPayoutFigureItem';
import ILoanPayoutFigureSection from '~Api/Loan/ILoanPayoutFigureSection';
import ILoanProperty from '~Api/Loan/ILoanProperty';
import ILoanTransaction from '~Api/Loan/ILoanTransaction';
import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import { IDictionary } from '~utilities/IDictionary';
import {
    ILoanDisbursementDeleteAction,
    ILoanDisbursementSetAction,
    ILoanDisbursementValueSetAction,
    ILoanDisbursementsSetAction,
    ILoanDischargeAction,
    ILoanDischargeDateAction,
    ILoanDocumentRemoveAction,
    ILoanDocumentSetAction,
    ILoanDocumentsSetAction,
    ILoanFeeRemoveAction,
    ILoanFeeSetAction,
    ILoanFeeValueSetAction,
    ILoanFeesSetAction,
    ILoanGracePeriodSetAction,
    ILoanGracePeriodsSetAction,
    ILoanHistoriesSetAction,
    ILoanListSettingsSetAction,
    ILoanNoteSetAction,
    ILoanNotesSetAction,
    ILoanPayoutFigureItemDeleteAction,
    ILoanPayoutFigureItemSetAction,
    ILoanPayoutFigureItemValueSetAction,
    ILoanPayoutFigureRemoveAction,
    ILoanPayoutFigureSectionDeleteAction,
    ILoanPayoutFigureSectionSetAction,
    ILoanPayoutFigureSetAction,
    ILoanPayoutFigureValueSetAction,
    ILoanPayoutFiguresSetAction,
    ILoanPropertiesSetAction,
    ILoanPropertyValueSetAction,
    ILoanSetAction,
    ILoanTransactionsSetAction,
    ILoanValueSetAction,
    ILoanWarehouseLoansSetAction,
    ILoansDashboardSetAction,
    ILoansDischargeForecastSetAction,
    ILoansDrawdownsSetAction,
    ILoansPaginatedSetAction,
    ILoansSearchResultsSetAction,
    ILoansSetAction,
} from './actions';
import LoansActionsEnum from './ActionsEnum';
import { ILoanListSettings } from './List';
import WarehousesActionsEnum from '~Warehouses/ActionsEnum';
import {
    IWarehouseEligibleLoansSetAction,
    IWarehouseLoanSetAction,
    IWarehouseLoansSetAction,
} from '~Warehouses/actions';
import IWarehouseEligibleLoan from '~Api/Warehouse/IWarehouseEligibleLoan';

export interface ILoansState {
    disbursements: IDictionary<ILoanDisbursement>;
    documents: IDictionary<IDocument>;
    drawdownUuids: string[];
    fees: IDictionary<IDictionary<ILoanFee>>;
    gracePeriods: IDictionary<ILoanGracePeriod>;
    histories: IDictionary<IDictionary<IHistory>>;
    loanDisbursementUuids: IDictionary<string[]>;
    loanDocumentUuids: IDictionary<string[]>;
    loanGracePeriodUuids: IDictionary<string[]>;
    loanListSettings: ILoanListSettings;
    loanPayoutFigureUuids: IDictionary<string[]>;
    loanTransactions: IDictionary<ILoanTransaction[]>;
    loanWarehouseLoanUuids: IDictionary<string[]>;
    loans: IDictionary<ILoan>;
    loansDashboardUuids: string[];
    loansDischargeForecastUuids: string[];
    loansListed: boolean;
    loansPaginatedCount: number;
    loansPaginatedUuids: string[];
    loansSearchResultUuids: string[];
    notes: IDictionary<IDictionary<ILoanNote>>;
    payoutFigureItems: IDictionary<ILoanPayoutFigureItem>;
    payoutFigureSectionItemUuids: IDictionary<string[]>;
    payoutFigureSectionUuids: IDictionary<string[]>;
    payoutFigureSections: IDictionary<ILoanPayoutFigureSection>;
    payoutFigures: IDictionary<ILoanPayoutFigure>;
    properties: IDictionary<IDictionary<ILoanProperty>>;
}

const initialData: ILoansState = {
    disbursements: {},
    documents: {},
    drawdownUuids: null,
    fees: {},
    gracePeriods: {},
    histories: {},
    loanDisbursementUuids: {},
    loanDocumentUuids: {},
    loanGracePeriodUuids: {},
    loanListSettings: {
        administratorUuidFilter: null,
        codeSearch: null,
        currentPage: 1,
        nameSearch: null,
        pageSize: 20,
        sortField: 'startDate',
        sortOrder: 'descend',
        statusFilter: [
            LoanStatusEnum.ActiveBadStanding,
            LoanStatusEnum.ActiveGoodStanding,
            LoanStatusEnum.ActiveMatured,
            LoanStatusEnum.ClosedObligationsMet,
            LoanStatusEnum.ClosedRefinanced,
            LoanStatusEnum.ClosedWrittenOff,
        ],
    },
    loanPayoutFigureUuids: {},
    loanTransactions: {},
    loanWarehouseLoanUuids: {},
    loans: {},
    loansDashboardUuids: null,
    loansDischargeForecastUuids: null,
    loansListed: false,
    loansPaginatedCount: null,
    loansPaginatedUuids: null,
    loansSearchResultUuids: null,
    notes: {},
    payoutFigureItems: {},
    payoutFigureSectionItemUuids: {},
    payoutFigureSectionUuids: {},
    payoutFigureSections: {},
    payoutFigures: {},
    properties: {},
};

function statifyLoan(state: ILoansState, loan: ILoan): ILoansState {
    const loans: IDictionary<ILoan> = { ...state.loans };
    const properties: IDictionary<IDictionary<ILoanProperty>> = { ...state.properties };
    let warehouseLoanUuids: string[] = null;

    if (loan.warehouseLoans) {
        warehouseLoanUuids = _.map(loan.warehouseLoans, (warehouseLoan: IWarehouseLoan) => warehouseLoan.uuid);
    }

    loans[loan.uuid] = _.omit(loan, [
        'application',
        'extensionApplication',
        'properties',
        'warehouseLoans',
    ]);

    if (!properties[loan.uuid]) {
        properties[loan.uuid] = {};
    }

    loan.properties.forEach((property: ILoanProperty) => {
        properties[loan.uuid][property.uuid] = property;
    });

    return {
        ...state,
        loanWarehouseLoanUuids: {
            ...state.loanWarehouseLoanUuids,
            [loan.uuid]: warehouseLoanUuids,
        },
        loans,
        properties,
    };
}

function statifyLoans(state: ILoansState, loans: ILoan[]): ILoansState {
    let clonedState: ILoansState = { ...state };

    loans.forEach((loan: ILoan) => {
        clonedState = statifyLoan(clonedState, loan);
    });

    return clonedState;
}

function dehydratePayoutFigure(payoutFigure: ILoanPayoutFigure): ILoanPayoutFigure {
    return _.omit(payoutFigure, ['sections']);
}

function dehydratePayoutFigureSection(payoutFigureSection: ILoanPayoutFigureSection): ILoanPayoutFigureSection {
    return _.omit(payoutFigureSection, ['items']);
}

export function loansReducer(state: ILoansState = initialData, action: AnyAction): ILoansState {
    switch (action.type) {
        case LoansActionsEnum.LoanDisbursementDelete: {
            const typedAction: ILoanDisbursementDeleteAction = action as ILoanDisbursementDeleteAction;

            return {
                ...state,
                disbursements: _.omit(state.disbursements, typedAction.disbursementUuid),
            };
        }

        case LoansActionsEnum.LoanDisbursementSet: {
            const typedAction: ILoanDisbursementSetAction = action as ILoanDisbursementSetAction;

            return {
                ...state,
                disbursements: {
                    ...state.disbursements,
                    [typedAction.disbursement.uuid]: typedAction.disbursement,
                },
                loanDisbursementUuids: {
                    ...state.loanDisbursementUuids,
                    [typedAction.disbursement.loanUuid]: _.uniq([
                        ...state.loanDisbursementUuids[typedAction.disbursement.loanUuid],
                        typedAction.disbursement.uuid,
                    ]),
                },
            };
        }

        case LoansActionsEnum.LoanDisbursementValueSet: {
            const typedAction: ILoanDisbursementValueSetAction = action as ILoanDisbursementValueSetAction;

            return {
                ...state,
                disbursements: {
                    ...state.disbursements,
                    [typedAction.disbursementUuid]: {
                        ...state.disbursements[typedAction.disbursementUuid],
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        case LoansActionsEnum.LoanDisbursementsSet: {
            const typedAction: ILoanDisbursementsSetAction = action as ILoanDisbursementsSetAction;

            const disbursements: IDictionary<ILoanDisbursement> = { ...state.disbursements };
            const loanDisbursementUuids: string[] = [];

            typedAction.disbursements.forEach((disbursement: ILoanDisbursement) => {
                disbursements[disbursement.uuid] = disbursement;
                loanDisbursementUuids.push(disbursement.uuid);
            });

            return {
                ...state,
                disbursements,
                loanDisbursementUuids: {
                    ...state.loanDisbursementUuids,
                    [action.loanUuid]: loanDisbursementUuids,
                },
            };
        }

        case LoansActionsEnum.LoanDocumentsSet: {
            const typedAction: ILoanDocumentsSetAction = action as ILoanDocumentsSetAction;

            const documents: IDictionary<IDocument> = {};
            const loanDocumentUuids: string[] = [];
            typedAction.documents.forEach((document: IDocument) => {
                documents[document.uuid] = document;
                loanDocumentUuids.push(document.uuid);
            });

            return {
                ...state,
                documents: {
                    ...state.documents,
                    ...documents,
                },
                loanDocumentUuids: {
                    ...state.loanDocumentUuids,
                    [typedAction.loanUuid]: loanDocumentUuids,
                },
            };
        }

        case LoansActionsEnum.LoanDocumentRemove: {
            const typedAction: ILoanDocumentRemoveAction = action as ILoanDocumentRemoveAction;

            const loanDocument: IDocument = state.documents[typedAction.documentUuid];

            return {
                ...state,
                documents: _.omit(state.documents, typedAction.documentUuid),
                loanDocumentUuids: {
                    ...state.loanDocumentUuids,
                    [loanDocument.loanUuid]: _.remove(state.loanDocumentUuids[loanDocument.loanUuid], typedAction.documentUuid),
                },
            };
        }

        case LoansActionsEnum.LoanDocumentSet: {
            const typedAction: ILoanDocumentSetAction = action as ILoanDocumentSetAction;

            return {
                ...state,
                documents: {
                    ...state.documents,
                    [typedAction.document.uuid]: typedAction.document,
                },
                loanDocumentUuids: {
                    ...state.loanDocumentUuids,
                    [typedAction.document.loanUuid]: _.uniq([
                        ...(state.loanDocumentUuids[typedAction.document.loanUuid] || []),
                        typedAction.document.uuid,
                    ]),
                },
            };
        }

        case LoansActionsEnum.LoanDischarge: {
            const typedAction: ILoanDischargeAction = action as ILoanDischargeAction;

            return {
                ...state,
                loans: {
                    ...state.loans,
                    [typedAction.uuid]: {
                        ...state.loans[typedAction.uuid],
                        dischargeDate: typedAction.dischargeDate,
                    },
                },
            };
        }

        case LoansActionsEnum.LoanDischargeDate: {
            const typedAction: ILoanDischargeDateAction = action as ILoanDischargeDateAction;

            return {
                ...state,
                loans: {
                    ...state.loans,
                    [typedAction.uuid]: {
                        ...state.loans[typedAction.uuid],
                        dischargeDate: typedAction.dischargeDate,
                    },
                },
            };
        }

        case LoansActionsEnum.LoanFeeRemove: {
            const typedAction: ILoanFeeRemoveAction = action as ILoanFeeRemoveAction;

            return {
                ...state,
                fees: {
                    ...state.fees,
                    [typedAction.loanUuid]: _.omit(state.fees[typedAction.loanUuid] || {}, typedAction.feeUuid),
                },
            };
        }

        case LoansActionsEnum.LoanFeeSet: {
            const typedAction: ILoanFeeSetAction = action as ILoanFeeSetAction;

            return {
                ...state,
                fees: {
                    ...state.fees,
                    [typedAction.loanUuid]: {
                        ...state.fees[typedAction.loanUuid],
                        [typedAction.fee.uuid]: typedAction.fee,
                    },
                },
            };
        }

        case LoansActionsEnum.LoanFeeValueSet: {
            const typedAction: ILoanFeeValueSetAction = action as ILoanFeeValueSetAction;

            return {
                ...state,
                fees: {
                    ...state.fees,
                    [typedAction.loanUuid]: {
                        ...state.fees[typedAction.loanUuid],
                        [typedAction.feeUuid]: {
                            ...state.fees[typedAction.loanUuid][typedAction.feeUuid],
                            [typedAction.key]: typedAction.value,
                        },
                    },
                },
            };
        }

        case LoansActionsEnum.LoanFeesSet: {
            const typedAction: ILoanFeesSetAction = action as ILoanFeesSetAction;

            return {
                ...state,
                fees: {
                    ...state.fees,
                    [typedAction.loanUuid]: _.keyBy(typedAction.fees, 'uuid'),
                },
            };
        }

        case LoansActionsEnum.LoanGracePeriodSet: {
            const typedAction: ILoanGracePeriodSetAction = action as ILoanGracePeriodSetAction;

            return {
                ...state,
                gracePeriods: {
                    ...state.gracePeriods,
                    [typedAction.loanGracePeriod.uuid]: typedAction.loanGracePeriod,
                },
                loanGracePeriodUuids: {
                    ...state.loanGracePeriodUuids,
                    [typedAction.loanGracePeriod.loanUuid]: _.uniq([
                        ...state.loanGracePeriodUuids[typedAction.loanGracePeriod.loanUuid],
                        typedAction.loanGracePeriod.uuid,
                    ]),
                },
            };
        }

        case LoansActionsEnum.LoanGracePeriodsSet: {
            const typedAction: ILoanGracePeriodsSetAction = action as ILoanGracePeriodsSetAction;

            const gracePeriods: IDictionary<ILoanGracePeriod> = { ...state.gracePeriods };
            const loanGracePeriodUuids: string[] = [];

            typedAction.gracePeriods.forEach((gracePeriod: ILoanGracePeriod) => {
                gracePeriods[gracePeriod.uuid] = gracePeriod;
                loanGracePeriodUuids.push(gracePeriod.uuid);
            });

            return {
                ...state,
                gracePeriods,
                loanGracePeriodUuids: {
                    ...state.loanGracePeriodUuids,
                    [action.loanUuid]: loanGracePeriodUuids,
                },
            };
        }

        case LoansActionsEnum.LoanHistoriesSet: {
            const typedAction: ILoanHistoriesSetAction = action as ILoanHistoriesSetAction;

            return {
                ...state,
                histories: {
                    ...state.histories,
                    [typedAction.uuid]: _.keyBy(typedAction.histories, 'uuid'),
                },
            };
        }

        case LoansActionsEnum.LoanListSettingsSet: {
            const typedAction: ILoanListSettingsSetAction = action as ILoanListSettingsSetAction;

            return {
                ...state,
                loanListSettings: typedAction.settings,
            };
        }

        case LoansActionsEnum.LoanNoteSet: {
            const typedAction: ILoanNoteSetAction = action as ILoanNoteSetAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.loanUuid]: {
                        ...(state.notes[typedAction.loanUuid] || {}),
                        [typedAction.note.uuid]: typedAction.note,
                    },
                },
            };
        }

        case LoansActionsEnum.LoanNotesSet: {
            const typedAction: ILoanNotesSetAction = action as ILoanNotesSetAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.uuid]: _.keyBy(typedAction.notes, 'uuid'),
                },
            };
        }

        case LoansActionsEnum.LoanSet: {
            const typedAction: ILoanSetAction = action as ILoanSetAction;

            return {
                ...state,
                ...statifyLoan(state, typedAction.loan),
            };
        }

        case LoansActionsEnum.LoanPayoutFigureRemove: {
            const typedAction: ILoanPayoutFigureRemoveAction = action as ILoanPayoutFigureRemoveAction;

            const loanUuid: string = state.payoutFigures[typedAction.payoutFigureUuid].loanUuid;
            const payoutFigureSections: IDictionary<ILoanPayoutFigureSection> = { ...state.payoutFigureSections };
            const payoutFigureItems: IDictionary<ILoanPayoutFigureItem> = { ...state.payoutFigureItems };
            const payoutFigureSectionItemUuids: IDictionary<string[]> = { ...state.payoutFigureSectionItemUuids };
            const payoutFigureSectionUuids: IDictionary<string[]> = { ...state.payoutFigureSectionUuids };

            _.forEach(payoutFigureSectionUuids[typedAction.payoutFigureUuid], (sectionUuid: string) => {
                _.forEach(payoutFigureSectionItemUuids[sectionUuid], (itemUuid: string) => {
                    delete payoutFigureItems[itemUuid];
                });
                delete payoutFigureSections[sectionUuid];
                delete payoutFigureSectionItemUuids[sectionUuid];
            });
            delete payoutFigureSectionUuids[typedAction.payoutFigureUuid];

            return {
                ...state,
                loanPayoutFigureUuids: {
                    ...state.loanPayoutFigureUuids,
                    [loanUuid]: _.filter(state.loanPayoutFigureUuids[loanUuid], (payoutFigureUuid: string) => payoutFigureUuid !== typedAction.payoutFigureUuid),
                },
                payoutFigureItems,
                payoutFigureSectionItemUuids,
                payoutFigureSectionUuids,
                payoutFigureSections,
                payoutFigures: _.omit(state.payoutFigures, typedAction.payoutFigureUuid),
            };
        }

        case LoansActionsEnum.LoanPayoutFigureSet: {
            const typedAction: ILoanPayoutFigureSetAction = action as ILoanPayoutFigureSetAction;

            const loanPayoutFigureUuids: string[] = state.loanPayoutFigureUuids[typedAction.payoutFigure.loanUuid] || [];
            const payoutFigureItems: IDictionary<ILoanPayoutFigureItem> = { ...state.payoutFigureItems };
            const payoutFigureSectionItemUuids: IDictionary<string[]> = { ...state.payoutFigureSectionItemUuids };
            const payoutFigureSectionUuids: string[] = [];
            const payoutFigureSections: IDictionary<ILoanPayoutFigureSection> = { ...state.payoutFigureSections };

            _.forEach(typedAction.payoutFigure.sections, (section: ILoanPayoutFigureSection) => {
                payoutFigureSectionItemUuids[section.uuid] = [];
                _.forEach(section.items, (item: ILoanPayoutFigureItem) => {
                    payoutFigureItems[item.uuid] = {
                        ...item,
                    };
                    payoutFigureSectionItemUuids[section.uuid].push(item.uuid);
                });
                payoutFigureSections[section.uuid] = {
                    ...dehydratePayoutFigureSection(section),
                };
                payoutFigureSectionUuids.push(section.uuid);
            });

            loanPayoutFigureUuids.push(typedAction.payoutFigure.uuid);

            return {
                ...state,
                loanPayoutFigureUuids: {
                    ...state.loanPayoutFigureUuids,
                    [typedAction.payoutFigure.loanUuid]: _.uniq(loanPayoutFigureUuids),
                },
                payoutFigureItems,
                payoutFigureSectionItemUuids,
                payoutFigureSectionUuids: {
                    ...state.payoutFigureSectionUuids,
                    [typedAction.payoutFigure.uuid]: _.uniq(payoutFigureSectionUuids),
                },
                payoutFigureSections,
                payoutFigures: {
                    ...state.payoutFigures,
                    [typedAction.payoutFigure.uuid]: dehydratePayoutFigure(typedAction.payoutFigure),
                },
            };
        }

        case LoansActionsEnum.LoanPayoutFigureValueSet: {
            const typedAction: ILoanPayoutFigureValueSetAction = action as ILoanPayoutFigureValueSetAction;

            return {
                ...state,
                payoutFigures: {
                    ...state.payoutFigures,
                    [typedAction.loanPayoutFigureUuid]: {
                        ...state.payoutFigures[typedAction.loanPayoutFigureUuid],
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        case LoansActionsEnum.LoanPayoutFigureItemDelete: {
            const typedAction: ILoanPayoutFigureItemDeleteAction = action as ILoanPayoutFigureItemDeleteAction;

            const payoutFigureItem: ILoanPayoutFigureItem = state.payoutFigureItems[typedAction.itemUuid];
            const payoutFigureSection: ILoanPayoutFigureSection = state.payoutFigureSections[payoutFigureItem.payoutFigureSectionUuid];

            return {
                ...state,
                payoutFigureItems: _.omit(state.payoutFigureItems, typedAction.itemUuid),
                payoutFigureSectionItemUuids: {
                    ...state.payoutFigureSectionItemUuids,
                    [payoutFigureSection.uuid]: _.filter(state.payoutFigureSectionItemUuids[payoutFigureSection.uuid], (itemUuid: string) => itemUuid !== typedAction.itemUuid),
                },
                payoutFigures: {
                    ...state.payoutFigures,
                    [payoutFigureSection.payoutFigureUuid]: {
                        ...state.payoutFigures[payoutFigureSection.payoutFigureUuid],
                        balanceAmount: state.payoutFigures[payoutFigureSection.payoutFigureUuid].balanceAmount - payoutFigureItem.amount,
                    },
                },
            };
        }

        case LoansActionsEnum.LoanPayoutFigureItemSet: {
            const typedAction: ILoanPayoutFigureItemSetAction = action as ILoanPayoutFigureItemSetAction;

            const payoutFigureSectionItemUuids: string[] = state.payoutFigureSectionItemUuids[typedAction.item.payoutFigureSectionUuid] || [];
            payoutFigureSectionItemUuids.push(typedAction.item.uuid);
            const payoutFigureSection: ILoanPayoutFigureSection = state.payoutFigureSections[typedAction.item.payoutFigureSectionUuid];
            let balanceAmount: number = typedAction.item.amount;

            _.forEach(state.payoutFigureSectionUuids[payoutFigureSection.payoutFigureUuid], (sectionUuid: string) => {
                _.forEach(state.payoutFigureSectionItemUuids[sectionUuid], (itemUuid: string) => {
                    if (itemUuid !== typedAction.item.uuid) {
                        balanceAmount += state.payoutFigureItems[itemUuid].amount;
                    }
                });
            });

            return {
                ...state,
                payoutFigureItems: {
                    ...state.payoutFigureItems,
                    [typedAction.item.uuid]: typedAction.item,
                },
                payoutFigureSectionItemUuids: {
                    ...state.payoutFigureSectionItemUuids,
                    [typedAction.item.payoutFigureSectionUuid]: _.uniq(payoutFigureSectionItemUuids),
                },
                payoutFigures: {
                    ...state.payoutFigures,
                    [payoutFigureSection.payoutFigureUuid]: {
                        ...state.payoutFigures[payoutFigureSection.payoutFigureUuid],
                        balanceAmount,
                    },
                },
            };
        }

        case LoansActionsEnum.LoanPayoutFigureItemValueSet: {
            const typedAction: ILoanPayoutFigureItemValueSetAction = action as ILoanPayoutFigureItemValueSetAction;

            const payoutFigureItem: ILoanPayoutFigureItem = state.payoutFigureItems[typedAction.itemUuid];
            const payoutFigureSection: ILoanPayoutFigureSection = state.payoutFigureSections[payoutFigureItem.payoutFigureSectionUuid];
            let balanceAmount: number = state.payoutFigures[payoutFigureSection.payoutFigureUuid].balanceAmount;

            if (typedAction.key === 'amount') {
                balanceAmount = balanceAmount - payoutFigureItem.amount + (typedAction.value as number);
            }

            return {
                ...state,
                payoutFigureItems: {
                    ...state.payoutFigureItems,
                    [typedAction.itemUuid]: {
                        ...state.payoutFigureItems[typedAction.itemUuid],
                        [typedAction.key]: typedAction.value,
                    },
                },
                payoutFigures: {
                    ...state.payoutFigures,
                    [payoutFigureSection.payoutFigureUuid]: {
                        ...state.payoutFigures[payoutFigureSection.payoutFigureUuid],
                        balanceAmount,
                    },
                },
            };
        }

        case LoansActionsEnum.LoanPayoutFigureSectionDelete: {
            const typedAction: ILoanPayoutFigureSectionDeleteAction = action as ILoanPayoutFigureSectionDeleteAction;

            const payoutFigureSection: ILoanPayoutFigureSection = state.payoutFigureSections[typedAction.sectionUuid];
            const payoutFigureItems: IDictionary<ILoanPayoutFigureItem> = { ...state.payoutFigureItems };
            const payoutFigureSectionItemUuids: IDictionary<string[]> = { ...state.payoutFigureSectionItemUuids };
            let balanceAmount: number = state.payoutFigures[payoutFigureSection.payoutFigureUuid].balanceAmount;

            _.forEach(payoutFigureSectionItemUuids[typedAction.sectionUuid], (itemUuid: string) => {
                balanceAmount -= payoutFigureItems[itemUuid].amount;
                delete payoutFigureItems[itemUuid];
            });

            return {
                ...state,
                payoutFigureItems,
                payoutFigureSectionItemUuids: _.omit(state.payoutFigureSectionItemUuids, typedAction.sectionUuid),
                payoutFigureSectionUuids: {
                    ...state.payoutFigureSectionUuids,
                    [payoutFigureSection.payoutFigureUuid]: _.filter(state.payoutFigureSectionUuids[payoutFigureSection.payoutFigureUuid], (sectionUuid: string) => sectionUuid !== typedAction.sectionUuid),
                },
                payoutFigureSections: _.omit(state.payoutFigureSections, typedAction.sectionUuid),
                payoutFigures: {
                    ...state.payoutFigures,
                    [payoutFigureSection.payoutFigureUuid]: {
                        ...state.payoutFigures[payoutFigureSection.payoutFigureUuid],
                        balanceAmount,
                    },
                },
            };
        }

        case LoansActionsEnum.LoanPayoutFigureSectionSet: {
            const typedAction: ILoanPayoutFigureSectionSetAction = action as ILoanPayoutFigureSectionSetAction;

            const payoutFigureItems: IDictionary<ILoanPayoutFigureItem> = { ...state.payoutFigureItems };
            const payoutFigureSectionUuids: string[] = state.payoutFigureSectionUuids[typedAction.section.payoutFigureUuid] || [];
            const payoutFigureSectionItemUuids: string[] = state.payoutFigureSectionItemUuids[typedAction.section.uuid];
            let balanceAmount: number = 0;

            _.forEach(typedAction.section.items, (item: ILoanPayoutFigureItem) => {
                payoutFigureItems[item.uuid] = item;
                payoutFigureSectionItemUuids.push(item.uuid);
                balanceAmount += item.amount;
            });
            payoutFigureSectionUuids.push(typedAction.section.uuid);

            _.forEach(state.payoutFigureSectionUuids[typedAction.section.payoutFigureUuid], (sectionUuid: string) => {
                if (sectionUuid !== typedAction.section.uuid) {
                    _.forEach(state.payoutFigureSectionItemUuids[sectionUuid], (itemUuid: string) => {
                        balanceAmount += state.payoutFigureItems[itemUuid].amount;
                    });
                }
            });

            return {
                ...state,
                payoutFigureItems,
                payoutFigureSectionItemUuids: {
                    ...state.payoutFigureSectionItemUuids,
                    [typedAction.section.uuid]: payoutFigureSectionItemUuids,
                },
                payoutFigureSectionUuids: {
                    ...state.payoutFigureSectionUuids,
                    [typedAction.section.payoutFigureUuid]: _.uniq(payoutFigureSectionUuids),
                },
                payoutFigureSections: {
                    ...state.payoutFigureSections,
                    [typedAction.section.uuid]: dehydratePayoutFigureSection(typedAction.section),
                },
                payoutFigures: {
                    ...state.payoutFigures,
                    [typedAction.section.payoutFigureUuid]: {
                        ...state.payoutFigures[typedAction.section.payoutFigureUuid],
                        balanceAmount,
                    },
                },
            };
        }

        case LoansActionsEnum.LoanPayoutFiguresSet: {
            const typedAction: ILoanPayoutFiguresSetAction = action as ILoanPayoutFiguresSetAction;

            const loanPayoutFigureUuids: string[] = state.loanPayoutFigureUuids[typedAction.loanUuid] || [];
            const payoutFigures: IDictionary<ILoanPayoutFigure> = { ...state.payoutFigures };
            const payoutFigureItems: IDictionary<ILoanPayoutFigureItem> = { ...state.payoutFigureItems };
            const payoutFigureSections: IDictionary<ILoanPayoutFigureSection> = { ...state.payoutFigureSections };
            const payoutFigureSectionItemUuids: IDictionary<string[]> = { ...state.payoutFigureSectionItemUuids };
            const payoutFigureSectionUuids: IDictionary<string[]> = { ...state.payoutFigureSectionItemUuids };

            _.forEach(typedAction.payoutFigures, (payoutFigure: ILoanPayoutFigure) => {
                payoutFigureSectionUuids[payoutFigure.uuid] = [];
                _.forEach(payoutFigure.sections, (section: ILoanPayoutFigureSection) => {
                    payoutFigureSectionItemUuids[section.uuid] = [];
                    _.forEach(section.items, (item: ILoanPayoutFigureItem) => {
                        payoutFigureItems[item.uuid] = item;
                        payoutFigureSectionItemUuids[section.uuid].push(item.uuid);
                    });
                    payoutFigureSections[section.uuid] = {
                        ...dehydratePayoutFigureSection(section),
                    };
                    payoutFigureSectionUuids[payoutFigure.uuid].push(section.uuid);
                });
                payoutFigures[payoutFigure.uuid] = {
                    ...dehydratePayoutFigure(payoutFigure),
                };
                loanPayoutFigureUuids.push(payoutFigure.uuid);
            });

            return {
                ...state,
                loanPayoutFigureUuids: {
                    ...state.loanPayoutFigureUuids,
                    [typedAction.loanUuid]: _.uniq(loanPayoutFigureUuids),
                },
                payoutFigureItems,
                payoutFigureSectionItemUuids,
                payoutFigureSectionUuids,
                payoutFigureSections,
                payoutFigures,
            };
        }

        case LoansActionsEnum.LoanPropertiesSet: {
            const typedAction: ILoanPropertiesSetAction = action as ILoanPropertiesSetAction;

            const properties: IDictionary<ILoanProperty> = {};

            typedAction.properties.forEach((property: ILoanProperty) => {
                properties[property.uuid] = property;
            });

            return {
                ...state,
                properties: {
                    ...state.properties,
                    [typedAction.uuid]: properties,
                },
            };
        }

        case LoansActionsEnum.LoanPropertyValueSet: {
            const typedAction: ILoanPropertyValueSetAction = action as ILoanPropertyValueSetAction;

            return {
                ...state,
                properties: {
                    ...state.properties,
                    [typedAction.loanUuid]: {
                        ...state.properties[typedAction.loanUuid],
                        [typedAction.propertyUuid]: {
                            ...state.properties[typedAction.loanUuid][typedAction.propertyUuid],
                            [typedAction.key]: typedAction.value,
                        },
                    },
                },
            };
        }

        case LoansActionsEnum.LoanTransactionsSet: {
            const typedAction: ILoanTransactionsSetAction = action as ILoanTransactionsSetAction;

            return {
                ...state,
                loanTransactions: {
                    ...state.loanTransactions,
                    [typedAction.loanUuid]: typedAction.loanTransactions,
                },
            };
        }

        case LoansActionsEnum.LoanValueSet: {
            const typedAction: ILoanValueSetAction = action as ILoanValueSetAction;

            return {
                ...state,
                loans: {
                    ...state.loans,
                    [typedAction.uuid]: {
                        ...state.loans[typedAction.uuid],
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        case LoansActionsEnum.LoanWarehouseLoansSet: {
            const typedAction: ILoanWarehouseLoansSetAction = action as ILoanWarehouseLoansSetAction;
            const loanWarehouseLoanUuids: IDictionary<string[]> = { ...state.loanWarehouseLoanUuids };

            loanWarehouseLoanUuids[typedAction.loanUuid] = [];

            _.each(typedAction.warehouseLoans, (warehouseLoan: IWarehouseLoan) => {
                loanWarehouseLoanUuids[typedAction.loanUuid].push(warehouseLoan.uuid);
            });

            return {
                ...state,
                loanWarehouseLoanUuids,
            };
        }

        case LoansActionsEnum.LoansDashboardSet: {
            const typedAction: ILoansDashboardSetAction = action as ILoansDashboardSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
                loansDashboardUuids: _.map(typedAction.loans, (loan: ILoan) => loan.uuid),
            };
        }

        case LoansActionsEnum.LoansDischargeForecastSet: {
            const typedAction: ILoansDischargeForecastSetAction = action as ILoansDischargeForecastSetAction;

            const loanWarehouseLoanUuids: IDictionary<string[]> = {};

            _.each(typedAction.loans, (loan: ILoan) => {
                loanWarehouseLoanUuids[loan.uuid] = _.map(loan.warehouseLoans, (warehouseLoan: IWarehouseLoan) => warehouseLoan.uuid);
            });

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
                loanWarehouseLoanUuids: {
                    ...state.loanWarehouseLoanUuids,
                    ...loanWarehouseLoanUuids,
                },
                loansDischargeForecastUuids: _.map(typedAction.loans, (loan: ILoan) => loan.uuid),
            };
        }

        case LoansActionsEnum.LoansDrawdownsSet: {
            const typedAction: ILoansDrawdownsSetAction = action as ILoansDrawdownsSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
                drawdownUuids: _.map(typedAction.loans, (loan: ILoan) => loan.uuid),
            };
        }

        case LoansActionsEnum.LoansPaginatedList: {
            return {
                ...state,
                loansPaginatedUuids: null,
            };
        }

        case LoansActionsEnum.LoansPaginatedSet: {
            const typedAction: ILoansPaginatedSetAction = action as ILoansPaginatedSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
                loansPaginatedCount: typedAction.loansPaginatedCount,
                loansPaginatedUuids: _.map(typedAction.loans, (loan: ILoan) => loan.uuid),
            };
        }

        case LoansActionsEnum.LoansSet: {
            const typedAction: ILoansSetAction = action as ILoansSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
                loansListed: true,
            };
        }

        case LoansActionsEnum.LoansSearchResultsSet: {
            const typedAction: ILoansSearchResultsSetAction = action as ILoansSearchResultsSetAction;

            const loansSearchResultUuids: string[] = _.map(typedAction.loans, (loan: ILoan) => loan.uuid);

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
                loansSearchResultUuids,
            };
        }

        case LoansActionsEnum.LoansSearchResultsClear: {
            return {
                ...state,
                loansSearchResultUuids: [],
            };
        }

        case WarehousesActionsEnum.WarehouseEligibleLoansSet: {
            const typedAction: IWarehouseEligibleLoansSetAction = action as IWarehouseEligibleLoansSetAction;

            return {
                ...state,
                ...statifyLoans(state, _.map(typedAction.eligibleLoans, (warehouseEligibleLoan: IWarehouseEligibleLoan) => warehouseEligibleLoan.loan)),
            };
        }

        case WarehousesActionsEnum.WarehouseLoanSet: {
            const typedAction: IWarehouseLoanSetAction = action as IWarehouseLoanSetAction;

            return {
                ...state,
                ...statifyLoan(state, typedAction.warehouseLoan.loan),
            };
        }

        case WarehousesActionsEnum.WarehouseLoansSet: {
            const typedAction: IWarehouseLoansSetAction = action as IWarehouseLoansSetAction;

            return {
                ...state,
                ...statifyLoans(state, _.map(typedAction.warehouseLoans, (warehouseLoan: IWarehouseLoan) => warehouseLoan.loan)),
            };
        }

        default:
            return state;
    }
}
