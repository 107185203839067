import { Layout as AntLayout, Breadcrumb, Button, Descriptions, Dropdown, Menu, MenuProps, PageHeader, Space, Spin } from 'antd';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import { aggregatorsListAction } from '~Aggregators/actions';
import IAggregator from '~Api/Aggregator/IAggregator';
import { aggregatorsSelector } from '~Aggregators/selectors';
import ApprovalStatusEnum from '~Api/Broker/ApprovalStatusEnum';
import IBroker from '~Api/Broker/IBroker';
import {
    brokerApproveAction,
    brokerResetApprovalStatusAction,
} from '~Brokers/actions';
import NextContact from '~Brokers/NextContact';
import { brokerSelector } from '~Brokers/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import BdmSelector from './BdmSelector';
import './broker.less';
import RejectModal from './RejectModal';
import RecordContactModal from './RecordContactModal';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import menuDivider from '~UI/menuDivider';

export default function Layout(props: {
    brokerUuid: string;
    breadcrumb?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    section: string;
}): ReactElement {
    const { breadcrumb, brokerUuid, children, section } = props;

    const [ approvalInProgress, setApprovalInProgress ] = useState<boolean>(false);
    const [ isRecordContactModalOpen, setIsRecordContactModalOpen ] = useState<boolean>(false);
    const [ isRejectModalOpen, setIsRejectModalOpen ] = useState<boolean>(false);

    const aggregators: IDictionary<IAggregator> = useSelector(aggregatorsSelector);
    const broker: IBroker = useSelector((state: IGlobalState) => brokerSelector(state, brokerUuid));

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!aggregators) {
            dispatch(aggregatorsListAction());
        }
    }, []);

    const onClickApprove: () => void = useCallback(() => {
        setApprovalInProgress(true);

        dispatch(brokerApproveAction(brokerUuid, false));
    }, []);

    const onClickApproveSendEmail: () => void = useCallback(() => {
        setApprovalInProgress(true);

        dispatch(brokerApproveAction(brokerUuid, true));
    }, []);

    const onClickRecordContact: () => void = useCallback(() => {
        setIsRecordContactModalOpen(true);
    }, []);

    const onRecordContactCancel: () => void = useCallback(() => {
        setIsRecordContactModalOpen(false);
    }, []);

    const onClickReject: () => void = useCallback(() => {
        setIsRejectModalOpen(true);
    }, []);

    const onRejectCancel: () => void = useCallback(() => {
        setIsRejectModalOpen(false);
    }, []);

    const onClickResetStatus: () => void = useCallback(() => {
        setApprovalInProgress(false);

        dispatch(brokerResetApprovalStatusAction(brokerUuid));
    }, []);

    if (!broker || !aggregators) {
        return (
            <AntLayout className='broker'>
                <Spin/>
            </AntLayout>
        );
    }

    const breadcrumbBlock: JSX.Element = breadcrumb ? (
        <>
            <Breadcrumb.Item><Link to={`/brokers/${broker.uuid}`}>{broker.firstName} {broker.lastName}</Link></Breadcrumb.Item>
            {breadcrumb}
        </>
    ) : (
        <Breadcrumb.Item>{broker.firstName} {broker.lastName}</Breadcrumb.Item>
    );

    const actionsMenu: MenuProps = {
        items: [
            {
                disabled: approvalInProgress,
                key: 'approve-email',
                label: 'Approve + Email',
                onClick: onClickApproveSendEmail,
            },
            {
                danger: true,
                disabled: approvalInProgress,
                key: 'reject',
                label: 'Reject',
                onClick: onClickReject,
            },
        ],
    };

    const extra: JSX.Element = (
        <Space>
            <Button type='default' disabled={approvalInProgress}  onClick={onClickRecordContact}>Record Contact</Button>
            {broker.approvalStatus === ApprovalStatusEnum.Pending && <Dropdown.Button disabled={approvalInProgress} onClick={onClickApprove} menu={actionsMenu} type='primary'>Approve</Dropdown.Button>}
            {broker.approvalStatus !== ApprovalStatusEnum.Pending && <Button type='default' onClick={onClickResetStatus}>{broker.approvalStatus === ApprovalStatusEnum.Approved ? 'Unapprove' : 'Unreject'}</Button>}
        </Space>
    );

    const menuItems: ItemType[] = [
        {
            key: 'overview',
            label: <NavLink to={`/brokers/${broker.uuid}`}>Overview</NavLink>,
        },
        {
            key: 'edit',
            label: <NavLink to={`/brokers/${broker.uuid}/edit`}>Edit</NavLink>,
        },
        {
            key: 'deals',
            label: <NavLink to={`/brokers/${broker.uuid}/leads`}>Leads</NavLink>,
        },
        {
            key: 'applications',
            label: <NavLink to={`/brokers/${broker.uuid}/applications`}>Applications</NavLink>,
        },
        {
            key: 'documents',
            label: <NavLink to={`/brokers/${broker.uuid}/documents`}>Documents</NavLink>,
        },
        {
            key: 'notes',
            label: <NavLink to={`/brokers/${broker.uuid}/notes`}>Notes</NavLink>,
        },
        menuDivider,
        {
            key: 'history',
            label: <NavLink to={`/brokers/${broker.uuid}/history`}>History</NavLink>,
        },
    ];

    return (
        <AntLayout className='broker'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/brokers'>Brokers</Link></Breadcrumb.Item>
                {breadcrumbBlock}
            </Breadcrumb>
            <PageHeader
                className='header'
                extra={extra}
                ghost={false}
                subTitle={broker.code}
                title={`${broker.firstName} ${broker.lastName}`}
            >
                <Descriptions size='small' column={4}>
                    <Descriptions.Item label='Phone'><a href={`tel:${broker.phone}`}>{broker.phone}</a></Descriptions.Item>
                    <Descriptions.Item label='Email'><a href={`mailto:${broker.email}`}>{broker.email}</a></Descriptions.Item>
                    <Descriptions.Item label='BDM'><BdmSelector broker={broker} /></Descriptions.Item>
                    {broker.linkedInUrl && <Descriptions.Item label='LinkedIn'><a href={broker.linkedInUrl} rel='noreferrer' target='_blank'>{broker.linkedInUrl}</a></Descriptions.Item>}
                    <Descriptions.Item label='Next Contact'><NextContact broker={broker} /></Descriptions.Item>
                    <Descriptions.Item label='Company'>{broker.companyName || '-'}</Descriptions.Item>
                    <Descriptions.Item label='Aggregator'>{broker.aggregatorUuid ? aggregators[broker.aggregatorUuid].name : '-'}</Descriptions.Item>
                    <Descriptions.Item label='Address'>{broker.postalAddress || '-'} {broker.suburb || '-'} {broker.state || '-'} {broker.postcode || '-'}</Descriptions.Item>
                </Descriptions>
            </PageHeader>
            <AntLayout className='content-wrapper'>
                <AntLayout.Sider className='menu' theme='light' width={240}>
                    <Menu
                        defaultSelectedKeys={[section]}
                        items={menuItems}
                        mode='inline'
                    />
                </AntLayout.Sider>
                <AntLayout.Content className={`content ${section}`}>
                    {children}
                </AntLayout.Content>
            </AntLayout>
            <RecordContactModal
                broker={broker}
                isOpen={isRecordContactModalOpen}
                onCancel={onRecordContactCancel}
            />
            <RejectModal
                broker={broker}
                isOpen={isRejectModalOpen}
                onCancel={onRejectCancel}
            />
        </AntLayout>
    );
}
