import { Modal, Space, Spin, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import Table, { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IDistribution from '~Api/IncomeTrust/IDistribution';
import { IGlobalState } from '~reducer';
import {
    investmentIncomeTrustDistributionsAddAction,
    investmentIncomeTrustDistributionsListAction,
} from '../actions';
import { incomeTrustDistributionsSelector } from '../selectors';
import IncomeTrustClassTypeEnum from '~Api/IncomeTrust/IncomeTrustClassTypeEnum';
import { currencyFormatter } from '~utilities/formatters';
import classTypeLabels from '~Api/IncomeTrust/classTypeLabels';
import InterestPaymentStrategyEnum from '~Api/IncomeTrust/InterestPaymentStrategyEnum';
import { Link } from 'react-router-dom';

interface IProps {
    classType: IncomeTrustClassTypeEnum;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsSelector {
    distributions: IDistribution[];
}

interface IPropsDispatch {
    distributionAdd: () => void;
    distributionsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class ClassTypeDistributionModal extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public componentDidMount(): void {
        this.props.distributionsList();
    }

    public render(): JSX.Element {
        const { classType, distributions, isOpen } = this.props;

        if (!distributions) {
            return (
                <Modal
                    okButtonProps={{ danger: true, disabled: true }}
                    okText='Create Distribution'
                    onCancel={this.props.onCancel}
                    onOk={this.onClickOk}
                    open={isOpen}
                    title={`New ${classTypeLabels[classType]} Distribution`}
                    width={1000}
                >
                    <Spin />
                </Modal>
            );
        }

        const columns: ColumnType<IDistribution>[] = [
            {
                defaultSortOrder: 'ascend',
                sorter: (a: IDistribution, b: IDistribution) => a.investorName.localeCompare(b.investorName),
                render: (distribution: IDistribution) => {
                    if (distribution.warning) {
                        return (
                            <Tooltip overlay={distribution.warning}>
                                <Space>
                                    <Link to={`/investors/${distribution.investorUuid}/accounts/${distribution.investorAccountUuid}/income-trust-investments`}>{distribution.investorName}</Link>
                                    <WarningOutlined />
                                </Space>
                            </Tooltip>
                        );
                    }

                    return (
                        <Link to={`/investors/${distribution.investorUuid}/accounts/${distribution.investorAccountUuid}/income-trust-investments`}>{distribution.investorName}</Link>
                    );
                },
                title: 'Investor',
            },
            {
                dataIndex: 'interestAmount',
                render: (interestAmount: number): string => currencyFormatter.format(interestAmount),
                title: 'Interest',
                width: '15%',
            },
            {
                dataIndex: 'whtAmount',
                render: (whtAmount: number): string => whtAmount ? currencyFormatter.format(whtAmount) : '-',
                title: 'WHT',
                width: '15%',
            },
        ];

        return (
            <Modal
                okButtonProps={{ danger: true, disabled: distributions.length === 0 }}
                okText='Create Distribution'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title={`New ${classTypeLabels[classType]} Distribution`}
                width={1000}
            >
                <Table
                    columns={columns}
                    dataSource={_.filter(distributions, (distribution: IDistribution) => !!distribution.interestAmount)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                    summary={this.summary}
                />
            </Modal>
        );
    }

    private summary(distributions: readonly IDistribution[]): JSX.Element {
        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>{currencyFormatter.format(_.sumBy(distributions, 'interestAmount'))}</Table.Summary.Cell>
                <Table.Summary.Cell index={4}>{currencyFormatter.format(_.sumBy(distributions, 'whtAmount'))}</Table.Summary.Cell>
            </Table.Summary.Row>
        );
    }

    private onClickOk(): void {
        Modal.confirm({
            content: 'Are you sure you want to create the distribution and pay investors?',
            okText: 'Create Distribution',
            okType: 'danger',
            onOk: () => {
                this.props.distributionAdd();
                this.props.onCancel();
            },
            title: 'Create Distribution',
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        distributions: incomeTrustDistributionsSelector(state, ownProps.classType),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        distributionAdd: () => dispatch(investmentIncomeTrustDistributionsAddAction(ownProps.classType)),
        distributionsList: () => dispatch(investmentIncomeTrustDistributionsListAction(ownProps.classType)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClassTypeDistributionModal);
