import { Button, Modal, Space, Spin, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import ILoanFee from '~Api/Loan/ILoanFee';
import {
    loanFeeDeleteAction,
    loanFeesListAction,
    loanGetAction,
} from '~Loans/actions';
import {
    loanFeesSelector,
    loanSelector,
} from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import FeeAddModal from './FeeAddModal';
import FeeEditModal from './FeeEditModal';
import Layout from './Layout';
import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import { ColumnType } from 'antd/lib/table';
import { currencyFormatter } from '~utilities/formatters';

interface IState {
    editFeeUuid: string;
    isAddModalOpen: boolean;
}

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    fees: ILoanFee[];
    loan: ILoan;
}

interface IPropsDispatch {
    feeDelete: (feeUuid: string) => void;
    feesList: () => void;
    loanGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Fees extends React.Component<Props, IState> {
    public state: IState = {
        editFeeUuid: null,
        isAddModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAdd = this.onClickAdd.bind(this);
        this.onClickEdit = this.onClickEdit.bind(this);
        this.onCloseAddModal = this.onCloseAddModal.bind(this);
        this.onCloseEditModal = this.onCloseEditModal.bind(this);
    }

    public componentDidMount() {
        const { loan, fees } = this.props;

        if (!loan) {
            this.props.loanGet();
        }

        if (!fees) {
            this.props.feesList();
        }
    }

    public render(): JSX.Element {
        const { loan, match, fees } = this.props;
        const { editFeeUuid, isAddModalOpen } = this.state;

        if (!loan || !fees) {
            return (
                <Layout uuid={match.params.uuid} section='fees'>
                    <Typography.Title level={2}>Fees &amp; Outlays</Typography.Title>
                    <Spin />
                </Layout>
            );
        }

        const addFeeOutlayButton: JSX.Element = (loan.status === LoanStatusEnum.ActiveBadStanding || loan.status === LoanStatusEnum.ActiveGoodStanding || loan.status === LoanStatusEnum.ActiveMatured) && (
            <Button onClick={this.onClickAdd}>Add Fee/Outlay</Button>
        );

        const columns: ColumnType<ILoanFee>[] = [
            {
                dataIndex: 'createdTime',
                render: (createdTime: string) => dayjs(createdTime).format('D/M/YYYY'),
                title: 'Date',
                width: '10%',
            },
            {
                dataIndex: 'description',
                title: 'Description',
            },
            {
                dataIndex: 'amount',
                render: (amount: number) => currencyFormatter.format(amount),
                title: 'Amount',
                width: '15%',
            },
            {
                render: (fee: ILoanFee) => {
                    const onClickEdit: () => void = () => this.onClickEdit(fee.uuid);

                    const onClickDelete: () => void = () => {
                        Modal.confirm({
                            content: 'Are you sure you want to delete this fee/outlay?',
                            okText: 'Delete',
                            okType: 'danger',
                            onOk: () => {
                                this.props.feeDelete(fee.uuid);
                            },
                            title: 'Delete',
                        });
                    };

                    return (
                        <Space>
                            <Button onClick={onClickEdit}>Edit</Button>
                            <Button danger={true} onClick={onClickDelete}>Delete</Button>
                        </Space>
                    );
                },
                title: 'Actions',
                width: '15%',
            },
        ];

        return (
            <Layout uuid={match.params.uuid} section='fees'>
                <Space className='actions'>
                    {addFeeOutlayButton}
                </Space>
                <Typography.Title level={2}>Fees &amp; Outlays</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.sortBy(fees, ['createdTime'])}
                    rowKey='uuid'
                    pagination={false}
                    size='middle'
                />
                <FeeEditModal
                    feeUuid={editFeeUuid}
                    loanUuid={loan.uuid}
                    isOpen={!!editFeeUuid}
                    onClose={this.onCloseEditModal}
                />
                <FeeAddModal
                    loanUuid={loan.uuid}
                    isOpen={isAddModalOpen}
                    onClose={this.onCloseAddModal}
                />
            </Layout>
        );
    }

    private onClickAdd() {
        this.setState({
            isAddModalOpen: true,
        });
    }

    private onClickEdit(feeUuid: string) {
        this.setState({
            editFeeUuid: feeUuid,
        });
    }

    private onCloseAddModal() {
        this.setState({
            isAddModalOpen: false,
        });
    }

    private onCloseEditModal() {
        this.setState({
            editFeeUuid: null,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        fees: loanFeesSelector(state, ownProps.match.params.uuid),
        loan: loanSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        feeDelete: (feeUuid: string) => dispatch(loanFeeDeleteAction(ownProps.match.params.uuid, feeUuid)),
        feesList: () => dispatch(loanFeesListAction(ownProps.match.params.uuid)),
        loanGet: () => dispatch(loanGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Fees);
