import { Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplicationFee from '~Api/Application/IApplicationFee';
import { applicationFeesAddAction } from '~Applications/actions';

interface IState {
    amount: number;
    description: string;
}

interface IProps {
    isOpen: boolean;
    applicationUuid: string;
    onClose: () => void;
}

interface IPropsDispatch {
    add: (fee: IApplicationFee) => void;
}

type Props = IProps & IPropsDispatch;

class FeeAddModal extends React.Component<Props, IState> {
    public state: IState = {
        amount: null,
        description: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { amount, description } = this.state;

        return (
            <Modal
                destroyOnClose={true}
                okText='Add'
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Fee/Outlay'
                wrapClassName='application-fee-add-modal'
            >
                <Form.Item label='Amount' className='amount'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeAmount} type='number' value={amount} placeholder='0' />
                </Form.Item>
                <Form.Item label='Description' className='description'>
                    <Input maxLength={255} onChange={this.onChangeDescription} value={description} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeAmount(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            amount: event.target.value ? event.target.valueAsNumber : null,
        });
    }

    private onChangeDescription(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            description: event.target.value,
        });
    }

    private onClickOk() {
        const { applicationUuid } = this.props;
        const { amount, description } = this.state;

        this.props.add({
            applicationUuid,
            amount: amount || 0,
            description,
        });

        this.props.onClose();
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        add: (fee: IApplicationFee) => dispatch(applicationFeesAddAction(ownProps.applicationUuid, fee)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(FeeAddModal);
