import { Breadcrumb, Button, Layout, Modal, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import IPendingDistribution from '~Api/Investment/IPendingDistribution';
import IPendingDistributionDetail from '~Api/Investment/IPendingDistributionDetail';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import {
    investmentPendingDistributionProcessAction,
    investmentPendingDistributionsListAction,
} from './actions';
import './investments.less';
import { investmentPendingDistributionsSelector } from './selectors';
import { WarningOutlined } from '@ant-design/icons';
import { currencyFormatter } from '~utilities/formatters';

interface IState {
    modalIsOpen: boolean;
    modalUuid: string;
}

interface IPropsSelector {
    pendingDistributions: IDictionary<IPendingDistribution>;
}

interface IPropsDispatch {
    pendingDistributionProcess: (warehouseLoanTransactionUuid: string) => void;
    pendingDistributionsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class PendingDistributions extends React.Component<Props, IState> {
    public state: IState = {
        modalIsOpen: false,
        modalUuid: null,
    };

    public componentDidMount(): void {
        this.props.pendingDistributionsList();
    }

    public render(): JSX.Element {
        const { pendingDistributions } = this.props;
        const { modalIsOpen, modalUuid } = this.state;

        if (!pendingDistributions) {
            return (
                <Layout className='investments'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Investments</Breadcrumb.Item>
                        <Breadcrumb.Item>Pending Distributions</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Pending Distributions</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const columns: ColumnType<IPendingDistribution>[] = [
            {
                dataIndex: 'transactionTime',
                render: (transactionTime: string) => dayjs(transactionTime).format('Do MMMM YYYY'),
                title: 'Transaction Time',
                width: '15%',
            },
            {
                dataIndex: 'investment',
                render: (investment: IInvestment, pendingDistribution: IPendingDistribution) => {
                    const link: JSX.Element = <Link to={`/investments/${investment.uuid}`}>{investment.code}</Link>;

                    if (pendingDistribution.warning) {
                        return (
                            <Tooltip overlay={pendingDistribution.warning}>
                                <Space>
                                    {link}
                                    <WarningOutlined/>
                                </Space>
                            </Tooltip>
                        );
                    }

                    return link;
                },
                title: 'Investment',
            },
            {
                dataIndex: 'principalAmount',
                render: (principalAmount: number) => principalAmount ? currencyFormatter.format(principalAmount) : '-',
                title: 'Principal',
                width: '15%',
            },
            {
                dataIndex: 'interestAmount',
                render: (interestAmount: number) => interestAmount ? currencyFormatter.format(interestAmount) : '-',
                title: 'Interest',
                width: '15%',
            },
            {
                dataIndex: 'distributions',
                render: (distributions: IPendingDistributionDetail[], pendingDistribution: IPendingDistribution) => {
                    const detailColumns: ColumnType<IPendingDistributionDetail>[] = [
                        {
                            dataIndex: 'investorName',
                            defaultSortOrder: 'ascend',
                            sorter: (a: IPendingDistributionDetail, b: IPendingDistributionDetail) => a.investorName.localeCompare(b.investorName),
                            title: 'Investor',
                        },
                        {
                            dataIndex: 'amount',
                            render: (amount: number) => amount ? currencyFormatter.format(amount) : '-',
                            title: 'Total',
                            width: '15%',
                        },
                        {
                            dataIndex: 'principalAmount',
                            render: (principalAmount: number) => principalAmount ? currencyFormatter.format(principalAmount) : '-',
                            title: 'Principal',
                            width: '15%',
                        },
                        {
                            dataIndex: 'interestAmount',
                            render: (interestAmount: number) => interestAmount ? currencyFormatter.format(interestAmount) : '-',
                            title: 'Interest',
                            width: '15%',
                        },
                        {
                            dataIndex: 'whtAmount',
                            render: (whtAmount: number) => whtAmount ? currencyFormatter.format(whtAmount) : '-',
                            title: 'WHT',
                            width: '15%',
                        },
                    ];

                    const onClickView: () => void = () => this.setState({
                        modalIsOpen: true,
                        modalUuid: pendingDistribution.warehouseLoanTransactionUuid,
                    });

                    const onCancelView: () => void = () => this.setState({
                        modalIsOpen: false,
                    });

                    const summary: () => ReactElement = () => {
                        let investorCount: number = 0;
                        let totalTotal: number = 0;
                        let principalTotal: number = 0;
                        let interestTotal: number = 0;
                        let whtTotal: number = 0;

                        _.forEach(distributions, (distribution: IPendingDistributionDetail) => {
                            investorCount++;
                            totalTotal += distribution.amount;
                            principalTotal += distribution.principalAmount;
                            interestTotal += distribution.interestAmount;
                            whtTotal += distribution.whtAmount;
                        });

                        return (
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}><strong>{investorCount}</strong></Table.Summary.Cell>
                                <Table.Summary.Cell index={1}><strong>{currencyFormatter.format(totalTotal)}</strong></Table.Summary.Cell>
                                <Table.Summary.Cell index={2}><strong>{currencyFormatter.format(principalTotal)}</strong></Table.Summary.Cell>
                                <Table.Summary.Cell index={3}><strong>{currencyFormatter.format(interestTotal)}</strong></Table.Summary.Cell>
                                <Table.Summary.Cell index={4}><strong>{currencyFormatter.format(whtTotal)}</strong></Table.Summary.Cell>
                            </Table.Summary.Row>
                        );
                    };

                    return (
                        <>
                            <Button onClick={onClickView}>View</Button>
                            <Modal
                                footer={false}
                                onCancel={onCancelView}
                                open={modalIsOpen && modalUuid === pendingDistribution.warehouseLoanTransactionUuid}
                                title='Investor Distributions'
                                width={1000}
                            >
                                <Table
                                    columns={detailColumns}
                                    dataSource={distributions}
                                    pagination={false}
                                    rowKey='investorName'
                                    size='middle'
                                    summary={summary}
                                />
                            </Modal>
                        </>
                    );
                },
                title: 'Investors',
                width: '10%',
            },
            {
                dataIndex: 'warehouseLoanTransactionUuid',
                render: (warehouseLoanTransactionUuid: string, pendingDistribution: IPendingDistribution) => {
                    const onClickProcess: () => void = () => {
                        Modal.confirm({
                            content: `Are you sure you want to process the pending distribution for ${pendingDistribution.investment.code}?`,
                            okText: 'Process',
                            okType: 'danger',
                            onOk: () => {
                                this.props.pendingDistributionProcess(warehouseLoanTransactionUuid);
                            },
                            title: 'Process Pending Distribution',
                        });
                    };

                    return <Button danger={!!pendingDistribution.warning} onClick={onClickProcess} type='primary'>Process</Button>;
                },
                title: 'Actions',
                width: '10%',
            },
        ];

        return (
            <Layout className='investments'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Investments</Breadcrumb.Item>
                    <Breadcrumb.Item>Pending Distributions</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Pending Distributions</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.values(pendingDistributions)}
                            pagination={false}
                            rowKey='warehouseLoanTransactionUuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        pendingDistributions: investmentPendingDistributionsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        pendingDistributionProcess: (warehouseLoanTransactionUuid: string) => dispatch(investmentPendingDistributionProcessAction(warehouseLoanTransactionUuid)),
        pendingDistributionsList: () => dispatch(investmentPendingDistributionsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PendingDistributions);
