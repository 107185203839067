import DischargeMethodEnum from './DischargeMethodEnum';
import ExtensionTypeEnum from './ExtensionTypeEnum';
import IDocument from './IDocument';
import IHistory from './IHistory';
import ILoan from './ILoan';
import ILoanDisbursement from './ILoanDisbursement';
import ILoanFee from './ILoanFee';
import ILoanGracePeriod from './ILoanGracePeriod';
import ILoanNote from './ILoanNote';
import ILoanPayoutFigure from './ILoanPayoutFigure';
import ILoanPayoutFigureItem from './ILoanPayoutFigureItem';
import ILoanPayoutFigureSection from './ILoanPayoutFigureSection';
import ILoanProperty from './ILoanProperty';
import ILoanTransaction from './ILoanTransaction';
import LoanPayoutFigureApprovalStatusEnum from './LoanPayoutFigureApprovalStatusEnum';
import LoanPayoutFigureSectionTypeEnum from './LoanPayoutFigureSectionTypeEnum';
import LoanStatusEnum from './LoanStatusEnum';
import MaturityReminderStatusEnum from './MaturityReminderStatusEnum';
import WorkflowStatusEnum from './WorkflowStatusEnum';
import { parseApplication } from '~Api/Application/parsers';
import IApplication from '~Api/Application/IApplication';
import { parseWarehouseLoan } from '~Api/Warehouse/parsers';
import LoanPayoutFigurePrepaidInterestModeEnum from './LoanPayoutFigurePrepaidInterestModeEnum';
import LoanPayoutFigureAccruedInterestModeEnum from './LoanPayoutFigureAccruedInterestModeEnum';
import LoanPayoutFigureMinimumTermInterestModeEnum from './LoanPayoutFigureMinimumTermInterestModeEnum';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseLoan(raw: any): ILoan {
    const extensionApplication: IApplication = raw.extensionApplication && parseApplication(raw.extensionApplication);
    return {
        administratorUuid: raw.administratorUuid,
        amount: Number(raw.amount),
        amountRemaining: Number(raw.amountRemaining),
        application: raw.application ? parseApplication(raw.application) : null,
        applicationUuid: raw.applicationUuid,
        code: raw.code,
        contactEmail: raw.contactEmail,
        contactName: raw.contactName,
        contactPhone: raw.contactPhone,
        createdTime: raw.createdTime,
        currentPaymentDate: raw.currentPaymentDate,
        dealUuid: raw.dealUuid,
        dischargeDate: raw.dischargeDate,
        dischargeMethod: raw.dischargeMethod as DischargeMethodEnum,
        endDate: raw.endDate,
        extensionApplication,
        extensionApplicationPayoutFigureUuid: raw.extensionApplicationPayoutFigureUuid,
        extensionApplicationUuid: raw.extensionApplicationUuid,
        extensionType: raw.extensionType as ExtensionTypeEnum,
        gracePeriodEndDate: raw.gracePeriodEndDate,
        gracePeriodMonths: raw.gracePeriodMonths,
        interestAmountPrepaid: Number(raw.interestAmountPrepaid),
        interestRate: Number(raw.interestRate),
        investmentUuid: raw.investmentUuid,
        isInGracePeriod: raw.isInGracePeriod,
        maturityReminderStatus: raw.maturityReminderStatus as MaturityReminderStatusEnum,
        name: raw.name,
        paymentAmountNext: Number(raw.paymentAmountNext),
        paymentDateNext: raw.paymentDateNext,
        prepaidInterestBalance: raw.prepaidInterestBalance,
        properties: raw.properties.map(parseLoanProperty),
        retainedContingency: raw.retainedContingency,
        retainedFunds: raw.retainedFunds,
        salesforceCode: raw.salesforceCode,
        startDate: raw.startDate,
        status: raw.status as LoanStatusEnum,
        termMonths: raw.termMonths,
        termMonthsMinimum: raw.termMonthsMinimum,
        uuid: raw.uuid,
        warehouseLoans: !!raw.warehouseLoans && raw.warehouseLoans.map(parseWarehouseLoan),
        workflowStatus: raw.workflowStatus as WorkflowStatusEnum,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseLoanDisbursement(raw: any): ILoanDisbursement {
    return {
        amount: raw.amount,
        createdTime: raw.createdTime,
        description: raw.description,
        loanUuid: raw.loanUuid,
        payeeName: raw.payeeName,
        type: raw.type,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseLoanDocument(raw: any): IDocument {
    return {
        createdTime: raw.createdTime,
        filename: raw.filename,
        loanUuid: raw.loanUuid,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseLoanFee(raw: any): ILoanFee {
    return {
        amount: raw.amount,
        createdTime: raw.createdTime,
        description: raw.description,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseLoanGracePeriod(raw: any): ILoanGracePeriod {
    return {
        code: raw.code,
        endDate: raw.endDate,
        loanUuid: raw.loanUuid,
        sequenceNumber: raw.sequenceNumber,
        startDate: raw.startDate,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseLoanHistory(raw: any): IHistory {
    return {
        createdTime: raw.createdTime,
        description: raw.description,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseLoanNote(raw: any): ILoanNote {
    return {
        administratorUuid: raw.administratorUuid,
        createdTime: raw.createdTime,
        note: raw.note,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseLoanPayoutFigure(raw: any): ILoanPayoutFigure {
    return {
        accruedInterest: raw.accruedInterest,
        accruedInterestLabel: raw.accruedInterestLabel,
        accruedInterestMode: raw.accruedInterestMode as LoanPayoutFigureAccruedInterestModeEnum,
        administratorUuid: raw.administratorUuid,
        applicationUuid: raw.applicationUuid,
        approvalStatus: raw.approvalStatus as LoanPayoutFigureApprovalStatusEnum,
        balanceAmount: raw.balanceAmount,
        borrowerName: raw.borrowerName,
        code: raw.code,
        createdTime: raw.createdTime,
        dischargeDate: raw.dischargeDate,
        interestAmount: raw.interestAmount,
        loanCode: raw.loanCode,
        loanUuid: raw.loanUuid,
        minimumTermInterest: raw.minimumTermInterest,
        minimumTermInterestMode: raw.minimumTermInterestMode as LoanPayoutFigureMinimumTermInterestModeEnum,
        prepaidInterest: raw.prepaidInterest,
        prepaidInterestAuto: raw.prepaidInterestAuto,
        prepaidInterestMode: raw.prepaidInterestMode as LoanPayoutFigurePrepaidInterestModeEnum,
        principalAmount: raw.principalAmount,
        sections: raw.sections.map(parseLoanPayoutFigureSection),
        trustAmount: raw.trustAmount,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseLoanPayoutFigureItem(raw: any): ILoanPayoutFigureItem {
    return {
        amount: raw.amount,
        name: raw.name,
        payoutFigureSectionUuid: raw.payoutFigureSectionUuid,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseLoanPayoutFigureSection(raw: any): ILoanPayoutFigureSection {
    return {
        items: raw.items.map(parseLoanPayoutFigureItem),
        name: raw.name,
        payoutFigureUuid: raw.payoutFigureUuid,
        type: raw.type as LoanPayoutFigureSectionTypeEnum,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseLoanProperty(raw: any): ILoanProperty {
    return {
        insuranceExpiryDate: raw.insuranceExpiryDate,
        insuranceReplacementValue: raw.insuranceReplacementValue,
        insuranceRequired: raw.insuranceRequired,
        postcode: raw.postcode,
        state: raw.state,
        streetAddress: raw.streetAddress,
        suburb: raw.suburb,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseLoanTransaction(raw: any): ILoanTransaction {
    return {
        amount: Number(raw.amount),
        amountInterest: raw.amountInterest,
        amountPrepaidInterest: raw.amountPrepaidInterest,
        amountPrincipal: raw.amountPrincipal,
        balance: Number(raw.balance),
        createdTime: raw.createdTime,
        description: raw.description,
        loanUuid: raw.loanUuid,
        retainedContingency: raw.retainedContingency,
        retainedFunds: raw.retainedFunds,
        transactionTime: raw.transactionTime,
        uuid: raw.uuid,
    };
}
