import { AnyAction } from 'redux';
import { IErrorSetAction } from './actions';
import ErrorActionsEnum from './ActionsEnum';

export interface IErrorState {
    error: string;
}

const initialData: IErrorState = {
    error: null,
};

export function errorReducer(state: IErrorState = initialData, action: AnyAction): IErrorState {
    switch (action.type) {
        case ErrorActionsEnum.ErrorSet: {
            const typedAction: IErrorSetAction = action as IErrorSetAction;

            return {
                ...state,
                error: typedAction.error,
            };
        }

        default:
            return state;
    }
}
