import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '~Layout';
import Administrator from './Administrator';
import List from './List';

export default function Router(): ReactElement {
    return (
        <Layout topSection='administrators'>
            <Switch>
                <Route exact={true} path='/administrators' component={List} />
                <Route path='/administrators/:uuid' component={Administrator} />
            </Switch>
        </Layout>
    );
}
