import { Space, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestor from '~Api/Investor/IInvestor';
import IInvestorUser from '~Api/Investor/IInvestorUser';
import { investorGetAction, investorUsersListAction } from '~Investors/actions';
import { investorSelector, investorUsersSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import { ColumnType } from 'antd/lib/table';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    investor: IInvestor;
    investorUsers: IInvestorUser[];
}

interface IPropsDispatch {
    investorGet: () => void;
    investorUsersList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Users extends React.Component<Props> {
    public componentDidMount() {
        const { investor, investorUsers } = this.props;

        if (!investor) {
            this.props.investorGet();
        }

        if (!investorUsers) {
            this.props.investorUsersList();
        }
    }

    public render(): JSX.Element {
        const { investor, investorUsers, match } = this.props;

        if (!investor || !investorUsers) {
            return (
                <Layout uuid={match.params.uuid} section='users'>
                    <Typography.Title level={2}>Users</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const columns: ColumnType<IInvestorUser>[] = [
            {
                render: (investorUser: IInvestorUser) => <Space><Link to={`/users/${investorUser.user.uuid}`}>{`${investorUser.user.firstName || ''} ${investorUser.user.lastName || ''}`.trim() || '-'}</Link> {investorUser.user.isBlocked ? <Tag color='error'>Blocked</Tag> : investorUser.user.isSuspicious && <Tag color='warning'>Suspicious</Tag>}</Space>,
                title: 'Name',
                width: '25%',
            },
            {
                render: (investorUser: IInvestorUser) => <a href={`mailto:${investorUser.user.email}`}>{investorUser.user.email}</a>,
                title: 'Email',
            },
            {
                render: (investorUser: IInvestorUser) => <a href={`tel:${investorUser.user.phone}`}>{investorUser.user.phone}</a>,
                title: 'Phone',
                width: '25%',
            },
            {
                dataIndex: 'createdTime',
                render: (createdTime: string) => createdTime ? <Tooltip title={dayjs(createdTime).format('Do MMMM YYYY HH:mm:ss')}><span>{dayjs(createdTime).fromNow()}</span></Tooltip> : '-',
                title: 'Access Added',
                width: '15%',
            },
        ];

        return (
            <Layout uuid={match.params.uuid} section='users'>
                <Typography.Title level={2}>Users</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={investorUsers}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                />
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investor: investorSelector(state, ownProps.match.params.uuid),
        investorUsers: investorUsersSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investorGet: () => dispatch(investorGetAction(ownProps.match.params.uuid)),
        investorUsersList: () => dispatch(investorUsersListAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Users);
