import { FilterFilled, FilterTwoTone } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Form, Layout, Modal, Space, Spin, Tree, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import {
    administratorsSelector,
    currentAdministratorSelector,
} from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import IApplication from '~Api/Application/IApplication';
import WorkflowStatusEnum from '~Api/Application/WorkflowStatusEnum';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import ITreeStructureGroup from '~utilities/ITreeStructureGroup';
import { applicationsDashboardListAction } from './actions';
import ApplicationCard from './Application/Card';
import Search from './Search';
import { dashboardApplicationsSelector } from './selectors';
import CheckedKeys from '~utilities/CheckedKeys';
import ExtensionTypeEnum from '~Api/Application/ExtensionTypeEnum';
import MortgageTypeEnum from '~Api/Application/MortgageTypeEnum';

export const dashboardVisibleStatuses: string[] = [
    WorkflowStatusEnum.ConditionalApproval,
    WorkflowStatusEnum.Draft,
    WorkflowStatusEnum.FormalApproval,
    WorkflowStatusEnum.LegalDocuments,
    WorkflowStatusEnum.New,
    WorkflowStatusEnum.Settlement,
    WorkflowStatusEnum.Underwriting,
];

const statusNames: IDictionary<string> = {
    [WorkflowStatusEnum.Draft]: 'Draft',
    [WorkflowStatusEnum.New]: 'New',
    [WorkflowStatusEnum.ConditionalApproval]: 'Conditional Approval',
    [WorkflowStatusEnum.Underwriting]: 'Underwriting',
    [WorkflowStatusEnum.FormalApproval]: 'Formal Approval',
    [WorkflowStatusEnum.LegalDocuments]: 'Legal Documents',
    [WorkflowStatusEnum.Settlement]: 'Settlement',
};

const unassignedKey: string = 'UNASSIGNED';

enum ChannelTreeGroupTypeEnum {
    Broker = 'BROKER',
    Direct = 'DIRECT',
}

enum LoanTypeTreeGroupTypeEnum {
    Extension = 'EXTENSION',
    NewLoan = 'NEW_LOAN',
}

enum MortgageTypeTreeGroupTypeEnum {
    FirstMortgage = 'FIRST_MORTGAGE',
    SecondMortgage = 'SECOND_MORTGAGE',
}

interface IState {
    bdmFilter: string[];
    channelFilter: string[];
    isChangeFiltersModalOpen: boolean;
    loanOfficerFilter: string[];
    loanProcessorFilter: string[];
    loanTypeFilter: string[];
    mortgageTypeFilter: string[];
}

interface IPropsSelector {
    administrators: IDictionary<IAdministrator>;
    currentAdministrator: IAdministrator;
    applications: IDictionary<IApplication>;
}

interface IPropsDispatch {
    administratorsList: () => void;
    applicationsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Dashboard extends React.Component<Props, IState> {
    private refreshInterval: NodeJS.Timeout;

    public state: IState = {
        bdmFilter: [],
        channelFilter: [],
        isChangeFiltersModalOpen: false,
        loanOfficerFilter: [],
        loanProcessorFilter: [],
        loanTypeFilter: [],
        mortgageTypeFilter: [],
    };

    constructor(props: Props) {
        super(props);

        this.onChangeBdmFilter = this.onChangeBdmFilter.bind(this);
        this.onChangeChannelFilter = this.onChangeChannelFilter.bind(this);
        this.onChangeLoanOfficerFilter = this.onChangeLoanOfficerFilter.bind(this);
        this.onChangeLoanProcessorFilter = this.onChangeLoanProcessorFilter.bind(this);
        this.onChangeLoanTypeFilter = this.onChangeLoanTypeFilter.bind(this);
        this.onChangeMortgageTypeFilter = this.onChangeMortgageTypeFilter.bind(this);

        this.onClickChangeFilters = this.onClickChangeFilters.bind(this);
        this.onCancelChangeFilters = this.onCancelChangeFilters.bind(this);
    }

    public componentDidMount(): void {
        const { administrators, currentAdministrator } = this.props;

        if (!administrators || !currentAdministrator) {
            this.props.administratorsList();
        }

        this.props.applicationsList();

        this.refreshInterval = setInterval(() => {
            this.props.applicationsList();
        }, 5 * 60 * 1000);
    }

    public componentWillUnmount(): void {
        clearInterval(this.refreshInterval);
    }

    public render(): JSX.Element {
        const { administrators, applications, currentAdministrator } = this.props;
        const {
            bdmFilter,
            channelFilter,
            isChangeFiltersModalOpen,
            loanOfficerFilter,
            loanProcessorFilter,
            loanTypeFilter,
            mortgageTypeFilter,
        } = this.state;

        if (!administrators || !applications || !currentAdministrator) {
            const filterButton: JSX.Element = ![
                RoleEnum.BusinessDevelopmentManager,
                RoleEnum.InternalBusinessDevelopmentManager,
                RoleEnum.CreditManager,
            ].includes(currentAdministrator.role) && (
                <Button disabled={true} icon={<FilterFilled/>} />
            );

            return (
                <Layout className='applications'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Applications</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Space className='actions'>
                                <Search/>
                                {filterButton}
                                <Link to='/applications/list'><Button>List View</Button></Link>
                            </Space>
                            <Typography.Title level={2}>Applications</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            minimumFractionDigits: 0,
            style: 'currency',
        });

        const columns: { [status: string]: IApplication[] } = {};
        const statusAmounts: { [status: string]: number } = {};

        const visibleBdms: IDictionary<IAdministrator> = {};
        const visibleLoanOfficers: IDictionary<IAdministrator> = {};
        const visibleLoanProcessors: IDictionary<IAdministrator> = {};

        _.sortBy(applications, (application: IApplication) => application.deal && application.deal.followUpTime).forEach((application: IApplication) => {
            if (!application.deal) {
                return;
            }

            const bdm: IAdministrator = administrators[application.deal.bdmUuid];
            const loanOfficer: IAdministrator = administrators[application.administratorUuid];

            if (bdm && !visibleBdms[application.deal.bdmUuid]) {
                visibleBdms[application.deal.bdmUuid] = bdm;
            }

            if (loanOfficer && !visibleLoanOfficers[application.administratorUuid]) {
                visibleLoanOfficers[application.administratorUuid] = loanOfficer;
            }

            _.forEach(application.deal.loanProcessorUuids, (loanProcessorUuid: string) => {
                if (!visibleLoanProcessors[loanProcessorUuid]) {
                    visibleLoanProcessors[loanProcessorUuid] = administrators[loanProcessorUuid];
                }
            });

            if (bdmFilter.length > 0) {
                let shouldShow: boolean = false;
                if (!application.deal.bdmUuid && bdmFilter.includes(unassignedKey)) {
                    shouldShow = true;
                }
                if (application.deal.bdmUuid && bdmFilter.includes(bdm.uuid)) {
                    shouldShow = true;
                }
                if (!shouldShow) {
                    return;
                }
            }

            if (channelFilter.length > 0) {
                let shouldShow: boolean = false;
                if (channelFilter.includes(ChannelTreeGroupTypeEnum.Broker) && (application.deal.brokerUuid || application.deal.isBroker)) {
                    shouldShow = true;
                }
                if (channelFilter.includes(ChannelTreeGroupTypeEnum.Direct) && !application.deal.brokerUuid && !application.deal.isBroker) {
                    shouldShow = true;
                }
                if (!shouldShow) {
                    return;
                }
            }

            if (loanTypeFilter.length > 0) {
                let shouldShow: boolean = false;
                if (loanTypeFilter.includes(LoanTypeTreeGroupTypeEnum.NewLoan) && application.extensionType !== ExtensionTypeEnum.Renewal) {
                    shouldShow = true;
                }
                if (loanTypeFilter.includes(LoanTypeTreeGroupTypeEnum.Extension) && application.extensionType === ExtensionTypeEnum.Renewal) {
                    shouldShow = true;
                }
                if (!shouldShow) {
                    return;
                }
            }

            if (mortgageTypeFilter.length > 0) {
                let shouldShow: boolean = false;
                if (mortgageTypeFilter.includes(MortgageTypeTreeGroupTypeEnum.FirstMortgage) && application.mortgageType === MortgageTypeEnum.FirstMortgage) {
                    shouldShow = true;
                }
                if (mortgageTypeFilter.includes(MortgageTypeTreeGroupTypeEnum.SecondMortgage) && application.mortgageType === MortgageTypeEnum.SecondMortgage) {
                    shouldShow = true;
                }
                if (!shouldShow) {
                    return;
                }
            }

            if (loanOfficerFilter.length > 0) {
                let shouldShow: boolean = false;
                if (!application.administratorUuid && loanOfficerFilter.includes(unassignedKey)) {
                    shouldShow = true;
                }
                if (application.administratorUuid && loanOfficerFilter.includes(loanOfficer.uuid)) {
                    shouldShow = true;
                }
                if (!shouldShow) {
                    return;
                }
            }

            if (loanProcessorFilter.length > 0) {
                let shouldShow: boolean = false;
                if (loanProcessorFilter.filter((loanProcessorUuid: string) => application.deal.loanProcessorUuids.includes(loanProcessorUuid)).length > 0) {
                    shouldShow = true;
                }
                if (application.deal.loanProcessorUuids.length === 0 && loanProcessorFilter.includes(unassignedKey)) {
                    shouldShow = true;
                }
                if (!shouldShow) {
                    return;
                }
            }

            if (RoleEnum.CreditManager === currentAdministrator.role) {
                // If admin is credit manager role then only show cards assigned to them.
                if (`${application.administratorUuid}` !== `${currentAdministrator.uuid}` && !application.deal.loanProcessorUuids.includes(currentAdministrator.uuid)) {
                    return;
                }
            }

            if (!columns[application.workflowStatus]) {
                columns[application.workflowStatus] = [];
            }

            columns[application.workflowStatus].push(application);

            if (!statusAmounts[application.workflowStatus]) {
                statusAmounts[application.workflowStatus] = 0;
            }

            statusAmounts[application.workflowStatus] += application.loanAmount;
        });

        const columnsBlock: JSX.Element[] = _.keys(statusNames).map((status: string) => {
            const amountBlock: JSX.Element = (
                <div className='amount'>
                    {columns[status] ? columns[status].length : 0} - {currencyFormatter.format(statusAmounts[status] || 0)}
                </div>
            );

            return (
                <Card key={status} title={<React.Fragment>{statusNames[status]}{amountBlock}</React.Fragment>}>
                    <Space direction='vertical'>
                        {columns[status] && columns[status].map((application: IApplication) => <ApplicationCard application={application} key={application.uuid} />)}
                    </Space>
                </Card>
            );
        });

        const bdmTreeData: ITreeStructureGroup[] = [
            {
                children: [],
                key: unassignedKey,
                title: 'Unassigned',
                value: unassignedKey,
            },
        ];

        _.forEach(_.sortBy(visibleBdms, ['name']), (loopAdmin: IAdministrator) => {
            if ([RoleEnum.BusinessDevelopmentManager, RoleEnum.InternalBusinessDevelopmentManager, RoleEnum.SeniorBusinessDevelopmentManager].includes(loopAdmin.role)) {
                bdmTreeData.push({
                    children: [],
                    key: loopAdmin.uuid,
                    title: loopAdmin.name,
                    value: loopAdmin.uuid,
                });
            }
        });

        const channelTreeData: ITreeStructureGroup[] = [
            {
                children: [],
                key: ChannelTreeGroupTypeEnum.Broker,
                title: 'Broker',
                value: ChannelTreeGroupTypeEnum.Broker,
            },
            {
                children: [],
                key: ChannelTreeGroupTypeEnum.Direct,
                title: 'Direct',
                value: ChannelTreeGroupTypeEnum.Direct,
            },
        ];

        const loanOfficerTreeData: ITreeStructureGroup[] = [
            {
                children: [],
                key: unassignedKey,
                title: 'Unassigned',
                value: unassignedKey,
            },
        ];

        _.forEach(_.sortBy(visibleLoanOfficers, ['name']), (loopAdmin: IAdministrator) => {
            loanOfficerTreeData.push({
                children: [],
                key: loopAdmin.uuid,
                title: loopAdmin.name,
                value: loopAdmin.uuid,
            });
        });

        const loanProcessorTreeData: ITreeStructureGroup[] = [
            {
                children: [],
                key: unassignedKey,
                title: 'Unassigned',
                value: unassignedKey,
            },
        ];

        _.forEach(_.sortBy(visibleLoanProcessors, ['name']), (loopAdmin: IAdministrator) => {
            loanProcessorTreeData.push({
                children: [],
                key: loopAdmin.uuid,
                title: loopAdmin.name,
                value: loopAdmin.uuid,
            });
        });

        const LoanTypeTreeData: ITreeStructureGroup[] = [
            {
                children: [],
                key: LoanTypeTreeGroupTypeEnum.NewLoan,
                title: 'New Loan',
                value: LoanTypeTreeGroupTypeEnum.NewLoan,
            },
            {
                children: [],
                key: LoanTypeTreeGroupTypeEnum.Extension,
                title: 'Extension',
                value: LoanTypeTreeGroupTypeEnum.Extension,
            },
        ];

        const MortgageTypeTreeData: ITreeStructureGroup[] = [
            {
                children: [],
                key: MortgageTypeTreeGroupTypeEnum.FirstMortgage,
                title: 'First Mortgage',
                value: MortgageTypeTreeGroupTypeEnum.FirstMortgage,
            },
            {
                children: [],
                key: MortgageTypeTreeGroupTypeEnum.SecondMortgage,
                title: 'Second Mortgage',
                value: MortgageTypeTreeGroupTypeEnum.SecondMortgage,
            },
        ];

        const filtered: boolean = bdmFilter.length > 0 || channelFilter.length > 0 || loanOfficerFilter.length > 0 || loanProcessorFilter.length > 0 || mortgageTypeFilter.length > 0;

        const filterButton: JSX.Element = ![
            RoleEnum.BusinessDevelopmentManager,
            RoleEnum.InternalBusinessDevelopmentManager,
            RoleEnum.CreditManager,
            RoleEnum.LoanManager,
        ].includes(currentAdministrator.role) && (
            <Button onClick={this.onClickChangeFilters} icon={filtered ? <FilterTwoTone/> : <FilterFilled/>} />
        );

        const modalFooter: JSX.Element = (
            <React.Fragment>
                <Button className='close' onClick={this.onCancelChangeFilters}>Close</Button>
            </React.Fragment>
        );

        return (
            <Layout className='applications'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Applications</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Search/>
                            {filterButton}
                            <Link to='/applications/list'><Button>List View</Button></Link>
                        </Space>
                        <Typography.Title level={2}>Applications</Typography.Title>
                        <Space align='start' className='columns'>
                            {columnsBlock}
                        </Space>
                    </Layout.Content>
                </Layout>
                <Modal
                    footer={modalFooter}
                    onCancel={this.onCancelChangeFilters}
                    open={isChangeFiltersModalOpen}
                    title='Change Filters'
                    wrapClassName='applications-dashboard-change-filters-modal'
                >
                    <Form.Item label='Loan Officers'>
                        <Tree
                            checkable={true}
                            treeData={loanOfficerTreeData}
                            checkedKeys={loanOfficerFilter}
                            onCheck={this.onChangeLoanOfficerFilter}
                            defaultExpandAll={true}
                            selectable={false}
                        />
                    </Form.Item>
                    <Form.Item label='Loan Processors'>
                        <Tree
                            checkable={true}
                            treeData={loanProcessorTreeData}
                            checkedKeys={loanProcessorFilter}
                            onCheck={this.onChangeLoanProcessorFilter}
                            defaultExpandAll={true}
                            selectable={false}
                        />
                    </Form.Item>
                    <Form.Item label='Loan Type'>
                        <Tree
                            checkable={true}
                            treeData={LoanTypeTreeData}
                            checkedKeys={loanTypeFilter}
                            onCheck={this.onChangeLoanTypeFilter}
                            defaultExpandAll={true}
                            selectable={false}
                        />
                    </Form.Item>
                    <Form.Item label='BDMs'>
                        <Tree
                            checkable={true}
                            treeData={bdmTreeData}
                            checkedKeys={bdmFilter}
                            onCheck={this.onChangeBdmFilter}
                            defaultExpandAll={true}
                            selectable={false}
                        />
                    </Form.Item>
                    <Form.Item label='Channel'>
                        <Tree
                            checkable={true}
                            treeData={channelTreeData}
                            checkedKeys={channelFilter}
                            onCheck={this.onChangeChannelFilter}
                            defaultExpandAll={true}
                            selectable={false}
                        />
                    </Form.Item>
                    <Form.Item label='Mortgage Type'>
                        <Tree
                            checkable={true}
                            treeData={MortgageTypeTreeData}
                            checkedKeys={mortgageTypeFilter}
                            onCheck={this.onChangeMortgageTypeFilter}
                            defaultExpandAll={true}
                            selectable={false}
                        />
                    </Form.Item>
                </Modal>
            </Layout>
        );
    }

    private onClickChangeFilters(): void {
        this.setState({
            isChangeFiltersModalOpen: true,
        });
    }

    private onCancelChangeFilters(): void {
        this.setState({
            isChangeFiltersModalOpen: false,
        });
    }

    private onChangeBdmFilter(checkedKeys: CheckedKeys): void {
        this.setState({
            bdmFilter: checkedKeys as string[],
        });
    }

    private onChangeChannelFilter(checkedKeys: CheckedKeys): void {
        this.setState({
            channelFilter: checkedKeys as string[],
        });
    }

    private onChangeLoanOfficerFilter(checkedKeys: CheckedKeys): void {
        this.setState({
            loanOfficerFilter: checkedKeys as string[],
        });
    }

    private onChangeLoanProcessorFilter(checkedKeys: CheckedKeys): void {
        this.setState({
            loanProcessorFilter: checkedKeys as string[],
        });
    }

    private onChangeLoanTypeFilter(checkedKeys: CheckedKeys): void {
        this.setState({
            loanTypeFilter: checkedKeys as string[],
        });
    }

    private onChangeMortgageTypeFilter(checkedKeys: CheckedKeys): void {
        this.setState({
            mortgageTypeFilter: checkedKeys as string[],
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        administrators: administratorsSelector(state),
        applications: dashboardApplicationsSelector(state),
        currentAdministrator: currentAdministratorSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        applicationsList: () => dispatch(applicationsDashboardListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dashboard);
