import { PhoneOutlined } from '@ant-design/icons';
import { Card as AntCard, Badge, Skeleton, Tag, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { ReactElement } from 'react';
import { TiEdit } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import ExtensionTypeEnum from '~Api/Application/ExtensionTypeEnum';
import IApplication from '~Api/Application/IApplication';
import AssigneeSelector from './AssigneeSelector';
import FollowUpTime from '~Deals/FollowUpTime';
import LoanProcessorSelector from '~Deals/LoanProcessorSelector';
import SettlementDate from '../SettlementDate';
import { currencyFormatter } from '~utilities/formatters';

export default function Card(props: {
    application: IApplication;
}): ReactElement {
    const { application } = props;

    if (!application.deal) {
        return (
            <AntCard key={application.uuid}>
                <Skeleton active={true} />
            </AntCard>
        );
    }

    const now: Dayjs = dayjs();
    const oneWeekFromNow: Dayjs = dayjs().add(7, 'days');

    const interestRateFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
        maximumFractionDigits: 2,
        style: 'percent',
    });

    const cardClasses: string[] = [];

    if (application.settlementDate) {
        const settlementDateDayjs: Dayjs = application.settlementDate && dayjs(application.settlementDate);

        if (settlementDateDayjs < now) {
            cardClasses.push('settlement-expired');
        } else if (settlementDateDayjs < oneWeekFromNow) {
            cardClasses.push('settles-soon');
        }
    }

    if (application.deal.followUpTime) {
        const followUpTimeDayjs: Dayjs = dayjs(application.deal.followUpTime);

        if (followUpTimeDayjs < now) {
            cardClasses.push('follow-up-time-expired');
        } else if (followUpTimeDayjs.format('YYYY-MM-DD') === now.format('YYYY-MM-DD')) {
            cardClasses.push('follow-up-time-today');
        }
    }

    const tagsBlock: JSX.Element = application.isExtension && (ExtensionTypeEnum.GracePeriod === application.extensionType ? <Tag color='orange'>Grace Period</Tag> : <Tag color='blue'>Extension</Tag>);

    const daysCurrent: number = Math.ceil(application.secondsInStatusCurrent / (60 * 60 * 24));
    const daysTotal: number = Math.ceil(application.secondsInStatusTotal / (60 * 60 * 24));

    let timeInStatusClass: string = 'default';
    if (daysCurrent >= 21) {
        timeInStatusClass = 'danger';
    } else if (daysCurrent >= 14) {
        timeInStatusClass = 'warning';
    }

    let daysInCurrentStatus: string = `${daysCurrent}`;
    if (application.secondsInStatusCurrent !== application.secondsInStatusTotal) {
        daysInCurrentStatus += ` / ${daysTotal}`;
    }

    return (
        <AntCard className={cardClasses.join(' ')} key={application.uuid}>
            <Badge className={`time-in-status ${timeInStatusClass}`} count={daysInCurrentStatus} showZero={true} />
            <div className='code'>{application.code}</div>
            <Link className='name' to={`/applications/${application.uuid}`}>{application.formattedName}</Link>
            <div className='amount'>{currencyFormatter.format(application.loanAmount)}</div>
            <div className='interest-rate'>{interestRateFormatter.format(application.interestRate / 100)}</div>
            <Tooltip className='assignee-officer' title='Loan Officer' placement='left'>
                <PhoneOutlined />
                <AssigneeSelector application={application} />
            </Tooltip>
            <Tooltip className='assignee-processor' title='Loan Processor' placement='left'>
                <TiEdit />
                <LoanProcessorSelector deal={application.deal} />
            </Tooltip>
            <div className='follow-up-time'>Follow Up <FollowUpTime dealUuid={application.deal.uuid} /></div>
            <div className='settlement-date'>Settles <SettlementDate application={application} /></div>
            {tagsBlock}
        </AntCard>
    );
}
