import { Action } from 'redux';
import ActionsEnum from './ActionsEnum';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAuthJokeGetAction extends Action {}

export function authJokeGetAction(): IAuthJokeGetAction {
    return {
        type: ActionsEnum.JokeGet,
    };
}

export interface IAuthJokeSetAction extends Action {
    joke: string;
}

export function authJokeSetAction(joke: string): IAuthJokeSetAction {
    return {
        joke,
        type: ActionsEnum.JokeSet,
    };
}

export interface IAuthLoginAction extends Action {
    email: string;
}

export function authLoginAction(email: string): IAuthLoginAction {
    return {
        email,
        type: ActionsEnum.Login,
    };
}

export interface IAuthLoginErrorSetAction extends Action {
    key: string;
    value: string;
}

export function authLoginErrorSetAction(key: string, value: string): IAuthLoginErrorSetAction {
    return {
        key,
        type: ActionsEnum.LoginErrorSet,
        value,
    };
}

export interface IAuthLoginErrorsSetAction extends Action {
    errors: any;
}

export function authLoginErrorsSetAction(errors: any): IAuthLoginErrorsSetAction {
    return {
        errors,
        type: ActionsEnum.LoginErrorsSet,
    };
}

export interface IAuthLoginInProgressSetAction extends Action {
    inProgress: boolean;
}

export function authLoginInProgressSetAction(inProgress: boolean): IAuthLoginInProgressSetAction {
    return {
        inProgress,
        type: ActionsEnum.LoginInProgressSet,
    };
}

export interface IAuthLogoutAction extends Action {
    rememberPath: boolean;
}

export function authLogoutAction(rememberPath: boolean = false): IAuthLogoutAction {
    return {
        rememberPath,
        type: ActionsEnum.Logout,
    };
}

export interface IAuthMagicLinkCreateAction extends Action {
    email: string;
}

export function authMagicLinkCreateAction(email: string): IAuthMagicLinkCreateAction {
    return {
        email,
        type: ActionsEnum.MagicLinkCreate,
    };
}

export interface IAuthMagicLinkRedeemAction extends Action {
    code: string;
}

export function authMagicLinkRedeemAction(code: string): IAuthMagicLinkRedeemAction {
    return {
        code,
        type: ActionsEnum.MagicLinkRedeem,
    };
}
