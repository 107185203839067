import { Alert, Button, Modal, Space, Spin, Table, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import DisbursementTypeEnum from '~Api/Application/DisbursementTypeEnum';
import IApplication from '~Api/Application/IApplication';
import IApplicationDisbursement from '~Api/Application/IApplicationDisbursement';
import {
    applicationDisbursementDeleteAction,
    applicationDisbursementsListAction,
    applicationGetAction,
} from '~Applications/actions';
import {
    applicationDisbursementsSelector,
    applicationSelector,
} from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import DisbursementAddModal from './DisbursementAddModal';
import DisbursementEditModal from './DisbursementEditModal';
import Layout from './Layout';

const typeLabels: IDictionary<string> = {
    [DisbursementTypeEnum.PrepaidInterest]: 'Prepaid Interest',
    [DisbursementTypeEnum.RetainedContingency]: 'Retained Contingency',
    [DisbursementTypeEnum.RetainedFunds]: 'Retained Funds',
};

interface IState {
    editDisbursementUuid: string;
    isAddModalOpen: boolean;
}

interface IMatch {
    applicationUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    application: IApplication;
    disbursements: IDictionary<IApplicationDisbursement>;
}

interface IPropsDispatch {
    applicationGet: () => void;
    disbursementDelete: (uuid: string) => void;
    disbursementsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Disbursements extends React.Component<Props, IState> {
    public state: IState = {
        editDisbursementUuid: null,
        isAddModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAdd = this.onClickAdd.bind(this);
        this.onClickEdit = this.onClickEdit.bind(this);
        this.onCloseAddModal = this.onCloseAddModal.bind(this);
        this.onCloseEditModal = this.onCloseEditModal.bind(this);
    }

    public componentDidMount() {
        const { application } = this.props;

        this.props.disbursementsList();

        if (!application) {
            this.props.applicationGet();
        }
    }

    public render(): JSX.Element {
        const { disbursements, application, match } = this.props;
        const { editDisbursementUuid, isAddModalOpen } = this.state;

        if (!disbursements || !application) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='disbursements'>
                    <Typography.Title level={2}>Disbursements</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const total: number = _.reduce(disbursements, (sum: number, loopDisbursement: IApplicationDisbursement) => sum + loopDisbursement.amount, 0);

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: any[] = [
            {
                dataIndex: 'payeeName',
                render: (payeeName: string) => payeeName || '-',
                title: 'Payee',
                width: '25%',
            },
            {
                dataIndex: 'description',
                title: 'Description',
            },
            {
                dataIndex: 'amount',
                render: (amount: number) => currencyFormatter.format(amount),
                title: 'Amount',
                width: '12%',
            },
            {
                dataIndex: 'type',
                render: (type: DisbursementTypeEnum) => typeLabels[type] ? typeLabels[type] : 'N/A',
                title: 'Type',
                width: '15%',
            },
            {
                render: (disbursement: IApplicationDisbursement) => {
                    const onClickEdit = () => this.onClickEdit(disbursement.uuid);

                    const onClickDelete = () => {
                        Modal.confirm({
                            content: 'Are you sure you want to delete this disbursement?',
                            okText: 'Delete',
                            okType: 'danger',
                            onOk: () => {
                                this.props.disbursementDelete(disbursement.uuid);
                            },
                            title: 'Delete',
                        });
                    };

                    return (
                        <Space>
                            <Button onClick={onClickEdit}>Edit</Button>
                            <Button danger={true} onClick={onClickDelete}>Delete</Button>
                        </Space>
                    );
                },
                title: 'Actions',
                width: '15%',
            },
        ];

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='disbursements'>
                <Space className='actions'>
                    <Button onClick={this.onClickAdd}>Add Disbursement</Button>
                </Space>
                <Typography.Title level={2}>Disbursements</Typography.Title>
                {Math.round(total * 100) !== Math.round(application.loanAmount * 100) && <Alert message={`Disbursements total ${currencyFormatter.format(total)} does not match application amount ${currencyFormatter.format(application.loanAmount)}`} type='error' />}
                <Table
                    columns={columns}
                    dataSource={_.values(disbursements)}
                    rowKey='uuid'
                    pagination={false}
                    size='middle'
                />
                <DisbursementEditModal
                    disbursementUuid={editDisbursementUuid}
                    isOpen={!!editDisbursementUuid}
                    onClose={this.onCloseEditModal}
                />
                <DisbursementAddModal
                    applicationUuid={application.uuid}
                    isOpen={isAddModalOpen}
                    onClose={this.onCloseAddModal}
                />
            </Layout>
        );
    }

    private onClickAdd() {
        this.setState({
            isAddModalOpen: true,
        });
    }

    private onClickEdit(disbursementUuid: string) {
        this.setState({
            editDisbursementUuid: disbursementUuid,
        });
    }

    private onCloseAddModal() {
        this.setState({
            isAddModalOpen: false,
        });
    }

    private onCloseEditModal() {
        this.setState({
            editDisbursementUuid: null,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
        disbursements: applicationDisbursementsSelector(state, ownProps.match.params.applicationUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
        disbursementDelete: (uuid: string) => dispatch(applicationDisbursementDeleteAction(uuid)),
        disbursementsList: () => dispatch(applicationDisbursementsListAction(ownProps.match.params.applicationUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Disbursements);
