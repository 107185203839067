import { Spin, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import {
    administratorGetAction,
    administratorHistoriesListAction,
} from '~Administrators/actions';
import {
    administratorHistoriesSelector,
    administratorSelector,
} from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import IHistory from '~Api/Administrator/IHistory';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';

interface IMatch {
    administratorUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    histories: IDictionary<IHistory>;
    administrator: IAdministrator;
}

interface IPropsDispatch {
    historiesList: () => void;
    administratorGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class History extends React.Component<Props> {
    public componentDidMount() {
        const { administrator } = this.props;

        if (!administrator) {
            this.props.administratorGet();
        }

        this.props.historiesList();
    }

    public render(): JSX.Element {
        const { administrator, match, histories } = this.props;

        if (!administrator || !histories) {
            return (
                <Layout administratorUuid={match.params.administratorUuid} section='history'>
                    <Typography.Title level={2}>History</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const historiesBlock: JSX.Element[] = _.map(_.sortBy(_.filter(histories, (history: IHistory) => !!history.uuid), ['createdTime']), (loopHistory: IHistory) => (
            <Timeline.Item key={loopHistory.uuid} label={dayjs(loopHistory.createdTime).format('YYYY-MM-DD - HH:mm')}>
                <Typography.Text>{loopHistory.description}</Typography.Text>
            </Timeline.Item>
        ));

        return (
            <Layout administratorUuid={match.params.administratorUuid} section='history'>
                <Typography.Title level={2}>History</Typography.Title>
                <Timeline mode='left' reverse={true}>
                    {historiesBlock}
                </Timeline>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        administrator: administratorSelector(state, ownProps.match.params.administratorUuid),
        histories: administratorHistoriesSelector(state, ownProps.match.params.administratorUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        administratorGet: () => dispatch(administratorGetAction(ownProps.match.params.administratorUuid)),
        historiesList: () => dispatch(administratorHistoriesListAction(ownProps.match.params.administratorUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(History);
