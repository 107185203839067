import { Button, Form, Input, Modal, Space } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import { applicationPropertyValuationValueAction } from '~Applications/actions';
import { IDictionary } from '~utilities/IDictionary';

interface IDefaultedValues {
    valuationValue: number;
}

interface IState {
    dirtyFields: IDictionary<boolean>;
    valuationValue: number;
}

interface IProps {
    applicationProperty: IApplicationProperty;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsDispatch {
    updateValuationValue: (valuationValue: number) => void;
}

type Props = IProps & IPropsDispatch;

class UpdateValuationValueModal extends React.Component<Props, IState> {
    public state: IState = {
        dirtyFields: {},
        valuationValue: null,
    };

    constructor(props: Props) {
        super(props);

        this.getDefaultedValues = this.getDefaultedValues.bind(this);

        this.onChangeValuationValue = this.onChangeValuationValue.bind(this);

        this.onClickClear = this.onClickClear.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen, onCancel } = this.props;
        const { valuationValue } = this.getDefaultedValues();

        return (
            <Modal
                okText='Update Valuation Value'
                onCancel={onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Update Valuation Value'
                wrapClassName='property-valuation-update-valuation-value-modal'
            >
                <Form.Item className='amount' label='Value'>
                    <Space>
                        <Input addonBefore='$' min={0} onChange={this.onChangeValuationValue} type='number' value={valuationValue} />
                        <Button onClick={this.onClickClear}>Clear</Button>
                    </Space>
                </Form.Item>
            </Modal>
        );
    }

    private getDefaultedValues(): IDefaultedValues {
        const { applicationProperty } = this.props;
        const { dirtyFields, valuationValue } = this.state;

        return {
            valuationValue: dirtyFields.valuationValue ? valuationValue : applicationProperty.valuationValue,
        };
    }

    private onChangeValuationValue(event: React.ChangeEvent<HTMLInputElement>) {
        const { dirtyFields } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                valuationValue: true,
            },
            valuationValue: event.target.value ? event.target.valueAsNumber : null,
        });

    }

    private onClickClear() {
        const { dirtyFields } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                valuationValue: true,
            },
            valuationValue: null,
        });
    }

    private onClickOk() {
        const { valuationValue } = this.getDefaultedValues();

        this.props.updateValuationValue(valuationValue);
        this.props.onCancel();
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        updateValuationValue: (valuationValue: number) => dispatch(applicationPropertyValuationValueAction(ownProps.applicationProperty.uuid, valuationValue)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(UpdateValuationValueModal);
