import { combineReducers } from 'redux';
import { accountsReducer, IAccountsState } from '~Accounts/reducer';
import { administratorsReducer, IAdministratorsState } from '~Administrators/reducer';
import { IAdvisersState, advisersReducer } from '~Advisers/reducer';
import { aggregatorsReducer, IAggregatorsState } from '~Aggregators/reducer';
import { applicationsReducer, IApplicationsState } from '~Applications/reducer';
import { authReducer, IAuthState } from '~Auth/reducer';
import { brokersReducer, IBrokersState } from '~Brokers/reducer';
import { countriesReducer, ICountriesState } from '~Countries/reducer';
import { dealsReducer, IDealsState } from '~Deals/reducer';
import { errorReducer, IErrorState } from '~Error/reducer';
import { IInvestmentSaleTransfersState, investmentSaleTransfersReducer } from '~InvestmentSaleTransfers/reducer';
import { IInvestmentsState, investmentsReducer } from '~Investments/reducer';
import { IInvestorsState, investorsReducer } from '~Investors/reducer';
import { ILeadsState, leadsReducer } from '~Leads/reducer';
import { ILoansState, loansReducer } from '~Loans/reducer';
import {
    IReferralPartnersState,
    referralPartnersReducer,
} from '~ReferralPartners/reducer';
import { ISolicitorsState, solicitorsReducer } from '~Solicitors/reducer';
import { IUsersState, usersReducer } from '~Users/reducer';
import { IValuersState, valuersReducer } from '~Valuers/reducer';
import { IWarehousesState, warehousesReducer } from '~Warehouses/reducer';
import { IWithdrawalAbasState, withdrawalAbasReducer } from '~WithdrawalAbas/reducer';

export interface IGlobalState {
    accounts: IAccountsState;
    administrators: IAdministratorsState;
    advisers: IAdvisersState;
    aggregators: IAggregatorsState;
    applications: IApplicationsState;
    auth: IAuthState;
    brokers: IBrokersState;
    countries: ICountriesState;
    deals: IDealsState;
    error: IErrorState;
    investmentSaleTransfers: IInvestmentSaleTransfersState;
    investments: IInvestmentsState;
    investors: IInvestorsState;
    leads: ILeadsState;
    loans: ILoansState;
    referralPartners: IReferralPartnersState;
    solicitors: ISolicitorsState;
    users: IUsersState;
    valuers: IValuersState;
    warehouses: IWarehousesState;
    withdrawalAbas: IWithdrawalAbasState;
}

export default combineReducers({
    accounts: accountsReducer,
    administrators: administratorsReducer,
    advisers: advisersReducer,
    aggregators: aggregatorsReducer,
    applications: applicationsReducer,
    auth: authReducer,
    brokers: brokersReducer,
    countries: countriesReducer,
    deals: dealsReducer,
    error: errorReducer,
    investmentSaleTransfers: investmentSaleTransfersReducer,
    investments: investmentsReducer,
    investors: investorsReducer,
    leads: leadsReducer,
    loans: loansReducer,
    referralPartners: referralPartnersReducer,
    solicitors: solicitorsReducer,
    users: usersReducer,
    valuers: valuersReducer,
    warehouses: warehousesReducer,
    withdrawalAbas: withdrawalAbasReducer,
});
