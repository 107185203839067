import { Action } from 'redux';
import IDistribution from '~Api/IncomeTrust/IDistribution';
import IHistory from '~Api/Investment/IHistory';
import IInvestment from '~Api/Investment/IInvestment';
import IInvestmentTransaction from '~Api/Investment/IInvestmentTransaction';
import IPendingDistribution from '~Api/Investment/IPendingDistribution';
import IInvestorAccountInvestment from '~Api/Investor/IInvestorAccountInvestment';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import InvestmentsActionsEnum from './ActionsEnum';
import IIncomeTrustTransaction from '~Api/IncomeTrust/IIncomeTrustTransaction';
import IInvestorIncomeTrustTransaction from '~Api/IncomeTrust/IInvestorIncomeTrustTransaction';
import IReservation from '~Api/Investment/IReservation';
import IncomeTrustClassTypeEnum from '~Api/IncomeTrust/IncomeTrustClassTypeEnum';
import IIncomeTrustInvestmentRequest from '~Api/IncomeTrust/IIncomeTrustInvestmentRequest';

export interface IInvestmentGetAction extends Action {
    uuid: string;
}

export function investmentGetAction(uuid: string): IInvestmentGetAction {
    return {
        type: InvestmentsActionsEnum.InvestmentGet,
        uuid,
    };
}

export interface IInvestmentGracePeriodAction extends Action {
    uuid: string;
}

export function investmentGracePeriodAction(uuid: string): IInvestmentGracePeriodAction {
    return {
        type: InvestmentsActionsEnum.InvestmentGracePeriod,
        uuid,
    };
}

export interface IInvestmentHideAction extends Action {
    uuid: string;
}

export function investmentHideAction(uuid: string): IInvestmentHideAction {
    return {
        type: InvestmentsActionsEnum.InvestmentHide,
        uuid,
    };
}

export interface IInvestmentHistoriesListAction extends Action {
    uuid: string;
}

export function investmentHistoriesListAction(uuid: string): IInvestmentHistoriesListAction {
    return {
        type: InvestmentsActionsEnum.InvestmentHistoriesList,
        uuid,
    };
}

export interface IInvestmentHistoriesSetAction extends Action {
    histories: IHistory[];
    uuid: string;
}

export function investmentHistoriesSetAction(uuid: string, histories: IHistory[]): IInvestmentHistoriesSetAction {
    return {
        histories,
        type: InvestmentsActionsEnum.InvestmentHistoriesSet,
        uuid,
    };
}

export interface IInvestmentIncomeTrustDistributionsAddAction extends Action {
    classType: IncomeTrustClassTypeEnum;
}

export function investmentIncomeTrustDistributionsAddAction(classType: IncomeTrustClassTypeEnum): IInvestmentIncomeTrustDistributionsAddAction {
    return {
        classType,
        type: InvestmentsActionsEnum.InvestmentIncomeTrustDistributionsAdd,
    };
}

export interface IInvestmentIncomeTrustDistributionsListAction extends Action {
    classType: IncomeTrustClassTypeEnum;
}

export function investmentIncomeTrustDistributionsListAction(classType: IncomeTrustClassTypeEnum): IInvestmentIncomeTrustDistributionsListAction {
    return {
        classType,
        type: InvestmentsActionsEnum.InvestmentIncomeTrustDistributionsList,
    };
}

export interface IInvestmentIncomeTrustDistributionsSetAction extends Action {
    classType: IncomeTrustClassTypeEnum;
    distributions: IDistribution[];
}

export function investmentIncomeTrustDistributionsSetAction(classType: IncomeTrustClassTypeEnum, distributions: IDistribution[]): IInvestmentIncomeTrustDistributionsSetAction {
    return {
        classType,
        distributions,
        type: InvestmentsActionsEnum.InvestmentIncomeTrustDistributionsSet,
    };
}

export interface IInvestmentIncomeTrustInvestmentRequestCancelAction extends Action {
    incomeTrustInvestmentRequestUuid: string;
    reason: string;
}

export function investmentIncomeTrustInvestmentRequestCancelAction(incomeTrustInvestmentRequestUuid: string, reason: string): IInvestmentIncomeTrustInvestmentRequestCancelAction {
    return {
        incomeTrustInvestmentRequestUuid,
        reason,
        type: InvestmentsActionsEnum.InvestmentIncomeTrustInvestmentRequestCancel,
    };
}

export interface IInvestmentIncomeTrustInvestmentRequestProcessAction extends Action {
    incomeTrustInvestmentRequestUuid: string;
    sendEmail: boolean;
}

export function investmentIncomeTrustInvestmentRequestProcessAction(incomeTrustInvestmentRequestUuid: string, sendEmail: boolean): IInvestmentIncomeTrustInvestmentRequestProcessAction {
    return {
        incomeTrustInvestmentRequestUuid,
        sendEmail,
        type: InvestmentsActionsEnum.InvestmentIncomeTrustInvestmentRequestProcess,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestmentIncomeTrustInvestmentRequestsPendingListAction extends Action {}

export function investmentIncomeTrustInvestmentRequestsPendingListAction(): IInvestmentIncomeTrustInvestmentRequestsPendingListAction {
    return {
        type: InvestmentsActionsEnum.InvestmentIncomeTrustInvestmentRequestsPendingList,
    };
}

export interface IInvestmentIncomeTrustInvestmentRequestsPendingSetAction extends Action {
    incomeTrustInvestmentRequests: IIncomeTrustInvestmentRequest[];
}

export function investmentIncomeTrustInvestmentRequestsPendingSetAction(incomeTrustInvestmentRequests: IIncomeTrustInvestmentRequest[]): IInvestmentIncomeTrustInvestmentRequestsPendingSetAction {
    return {
        incomeTrustInvestmentRequests,
        type: InvestmentsActionsEnum.InvestmentIncomeTrustInvestmentRequestsPendingSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestmentIncomeTrustTransactionsListAction extends Action {}

export function investmentIncomeTrustTransactionsListAction(): IInvestmentIncomeTrustTransactionsListAction {
    return {
        type: InvestmentsActionsEnum.InvestmentIncomeTrustTransactionsList,
    };
}

export interface IInvestmentIncomeTrustTransactionsSetAction extends Action {
    transactions: IIncomeTrustTransaction[];
}

export function investmentIncomeTrustTransactionsSetAction(transactions: IIncomeTrustTransaction[]): IInvestmentIncomeTrustTransactionsSetAction {
    return {
        transactions,
        type: InvestmentsActionsEnum.InvestmentIncomeTrustTransactionsSet,
    };
}

export interface IInvestmentIncomeTrustTransactionInvestorsListAction extends Action {
    incomeTrustTransactionUuid: string;
}

export function investmentIncomeTrustTransactionInvestorsListAction(incomeTrustTransactionUuid: string): IInvestmentIncomeTrustTransactionInvestorsListAction {
    return {
        incomeTrustTransactionUuid,
        type: InvestmentsActionsEnum.InvestmentIncomeTrustTransactionInvestorsList,
    };
}

export interface IInvestmentIncomeTrustTransactionInvestorsSetAction extends Action {
    incomeTrustTransactionUuid: string;
    transactions: IInvestorIncomeTrustTransaction[];
}

export function investmentIncomeTrustTransactionInvestorsSetAction(incomeTrustTransactionUuid: string, transactions: IInvestorIncomeTrustTransaction[]): IInvestmentIncomeTrustTransactionInvestorsSetAction {
    return {
        incomeTrustTransactionUuid,
        transactions,
        type: InvestmentsActionsEnum.InvestmentIncomeTrustTransactionInvestorsSet,
    };
}

export interface IInvestmentInterestRateAction extends Action {
    interestRate: number;
    investmentUuid: string;
}

export function investmentInterestRateAction(investmentUuid: string, interestRate: number): IInvestmentInterestRateAction {
    return {
        interestRate,
        investmentUuid,
        type: InvestmentsActionsEnum.InvestmentInterestRate,
    };
}

export interface IInvestmentInvestorsListAction extends Action {
    investmentUuid: string;
}

export function investmentInvestorsListAction(investmentUuid: string): IInvestmentInvestorsListAction {
    return {
        investmentUuid,
        type: InvestmentsActionsEnum.InvestmentInvestorsList,
    };
}

export interface IInvestmentInvestorsSetAction extends Action {
    investorAccountInvestments: IInvestorAccountInvestment[];
    investmentUuid: string;
}

export function investmentInvestorsSetAction(investmentUuid: string, investorAccountInvestments: IInvestorAccountInvestment[]): IInvestmentInvestorsSetAction {
    return {
        investmentUuid,
        investorAccountInvestments,
        type: InvestmentsActionsEnum.InvestmentInvestorsSet,
    };
}

export interface IInvestmentInvestorEmailSendAction extends Action {
    message: string;
    subject: string;
    uuid: string;
}

export function investmentInvestorEmailSendAction(uuid: string, subject: string, message: string): IInvestmentInvestorEmailSendAction {
    return {
        message,
        subject,
        type: InvestmentsActionsEnum.InvestmentInvestorEmailSend,
        uuid,
    };
}

export interface IInvestmentPayoutEmailSendAction extends Action {
    uuid: string;
}

export function investmentPayoutEmailSendAction(uuid: string): IInvestmentPayoutEmailSendAction {
    return {
        type: InvestmentsActionsEnum.InvestmentPayoutEmailSend,
        uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestmentPendingDistributionsListAction extends Action {}

export function investmentPendingDistributionsListAction(): IInvestmentPendingDistributionsListAction {
    return {
        type: InvestmentsActionsEnum.InvestmentPendingDistributionsList,
    };
}

export interface IInvestmentPendingDistributionsSetAction extends Action {
    pendingDistributions: IPendingDistribution[];
}

export function investmentPendingDistributionsSetAction(pendingDistributions: IPendingDistribution[]): IInvestmentPendingDistributionsSetAction {
    return {
        pendingDistributions,
        type: InvestmentsActionsEnum.InvestmentPendingDistributionsSet,
    };
}

export interface IInvestmentPendingDistributionProcessAction extends Action {
    warehouseLoanTransactionUuid: string;
}

export function investmentPendingDistributionProcessAction(warehouseLoanTransactionUuid: string): IInvestmentPendingDistributionProcessAction {
    return {
        type: InvestmentsActionsEnum.InvestmentPendingDistributionProcess,
        warehouseLoanTransactionUuid,
    };
}

export interface IInvestmentPhotoAction extends Action {
    file: File;
    uuid: string;
}

export function investmentPhotoAction(uuid: string, file: File): IInvestmentPhotoAction {
    return {
        file,
        type: InvestmentsActionsEnum.InvestmentPhoto,
        uuid,
    };
}

export interface IInvestmentPhotoInProgressSetAction extends Action {
    inProgress: boolean;
}

export function investmentPhotoInProgressSetAction(inProgress: boolean): IInvestmentPhotoInProgressSetAction {
    return {
        inProgress,
        type: InvestmentsActionsEnum.InvestmentPhotoInProgressSet,
    };
}

export interface IInvestmentReservationProcessAction extends Action {
    investmentReservationUuid: string;
}

export function investmentReservationProcessAction(investmentReservationUuid: string): IInvestmentReservationProcessAction {
    return {
        investmentReservationUuid,
        type: InvestmentsActionsEnum.InvestmentReservationProcess,
    };
}

export interface IInvestmentReservationsListAction extends Action {
    investmentUuid: string;
}

export function investmentReservationsListAction(investmentUuid: string): IInvestmentReservationsListAction {
    return {
        investmentUuid,
        type: InvestmentsActionsEnum.InvestmentReservationsList,
    };
}

export interface IInvestmentReservationsSetAction extends Action {
    reservations: IReservation[];
    investmentUuid: string;
}

export function investmentReservationsSetAction(investmentUuid: string, reservations: IReservation[]): IInvestmentReservationsSetAction {
    return {
        investmentUuid,
        reservations,
        type: InvestmentsActionsEnum.InvestmentReservationsSet,
    };
}

export interface IInvestmentSendAction extends Action {
    uuid: string;
}

export function investmentSendAction(uuid: string): IInvestmentSendAction {
    return {
        type: InvestmentsActionsEnum.InvestmentSend,
        uuid,
    };
}

export interface IInvestmentSetAction extends Action {
    investment: IInvestment;
}

export function investmentSetAction(investment: IInvestment): IInvestmentSetAction {
    return {
        investment,
        type: InvestmentsActionsEnum.InvestmentSet,
    };
}

export interface IInvestmentShowAction extends Action {
    interestRate?: number;
    sendEmail: boolean;
    uuid: string;
}

export function investmentShowAction(uuid: string, sendEmail: boolean, interestRate?: number): IInvestmentShowAction {
    return {
        interestRate,
        sendEmail,
        type: InvestmentsActionsEnum.InvestmentShow,
        uuid,
    };
}

export interface IInvestmentTransactionsListAction extends Action {
    investmentUuid: string;
}

export function investmentTransactionsListAction(investmentUuid: string): IInvestmentTransactionsListAction {
    return {
        investmentUuid,
        type: InvestmentsActionsEnum.InvestmentTransactionsList,
    };
}

export interface IInvestmentTransactionsSetAction extends Action {
    transactions: IInvestmentTransaction[];
    investmentUuid: string;
}

export function investmentTransactionsSetAction(investmentUuid: string, transactions: IInvestmentTransaction[]): IInvestmentTransactionsSetAction {
    return {
        investmentUuid,
        transactions,
        type: InvestmentsActionsEnum.InvestmentTransactionsSet,
    };
}

export interface IInvestmentTransactionGetAction extends Action {
    investmentTransactionUuid: string;
}

export function investmentTransactionGetAction(investmentTransactionUuid: string): IInvestmentTransactionGetAction {
    return {
        investmentTransactionUuid,
        type: InvestmentsActionsEnum.InvestmentTransactionGet,
    };
}

export interface IInvestmentTransactionSetAction extends Action {
    transaction: IInvestmentTransaction;
}

export function investmentTransactionSetAction(transaction: IInvestmentTransaction): IInvestmentTransactionSetAction {
    return {
        transaction,
        type: InvestmentsActionsEnum.InvestmentTransactionSet,
    };
}

export interface IInvestmentTransactionInvestorsListAction extends Action {
    investmentTransactionUuid: string;
}

export function investmentTransactionInvestorsListAction(investmentTransactionUuid: string): IInvestmentTransactionInvestorsListAction {
    return {
        investmentTransactionUuid,
        type: InvestmentsActionsEnum.InvestmentTransactionInvestorsList,
    };
}

export interface IInvestmentTransactionInvestorsSetAction extends Action {
    transactions: IInvestorAccountInvestmentTransaction[];
    investmentTransactionUuid: string;
}

export function investmentTransactionInvestorsSetAction(investmentTransactionUuid: string, transactions: IInvestorAccountInvestmentTransaction[]): IInvestmentTransactionInvestorsSetAction {
    return {
        investmentTransactionUuid,
        transactions,
        type: InvestmentsActionsEnum.InvestmentTransactionInvestorsSet,
    };
}

export interface IInvestmentValueSetAction extends Action {
    key: keyof IInvestment;
    uuid: string;
    value: boolean|number|object|string;
}

export function investmentValueSetAction(uuid: string, key: keyof IInvestment, value: boolean|number|object|string): IInvestmentValueSetAction {
    return {
        key,
        type: InvestmentsActionsEnum.InvestmentValueSet,
        uuid,
        value,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestmentsDashboardListAction extends Action {}

export function investmentsDashboardListAction(): IInvestmentsDashboardListAction {
    return {
        type: InvestmentsActionsEnum.InvestmentsDashboardList,
    };
}

export interface IInvestmentsDashboardSetAction extends Action {
    investments: IInvestment[];
}

export function investmentsDashboardSetAction(investments: IInvestment[]): IInvestmentsDashboardSetAction {
    return {
        investments,
        type: InvestmentsActionsEnum.InvestmentsDashboardSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestmentsListAction extends Action {}

export function investmentsListAction(): IInvestmentsListAction {
    return {
        type: InvestmentsActionsEnum.InvestmentsList,
    };
}

export interface IInvestmentsSearchAction extends Action {
    keyword: string;
}

export function investmentsSearchAction(keyword: string): IInvestmentsSearchAction {
    return {
        keyword,
        type: InvestmentsActionsEnum.InvestmentsSearch,
    };
}

export interface IInvestmentsSearchResultsSetAction extends Action {
    investments: IInvestment[];
}

export function investmentsSearchResultsSetAction(investments: IInvestment[]): IInvestmentsSearchResultsSetAction {
    return {
        investments,
        type: InvestmentsActionsEnum.InvestmentsSearchResultsSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestmentsSearchResultsClearAction extends Action {}

export function investmentsSearchResultsClearAction(): IInvestmentsSearchResultsClearAction {
    return {
        type: InvestmentsActionsEnum.InvestmentsSearchResultsClear,
    };
}

export interface IInvestmentsSetAction extends Action {
    investments: IInvestment[];
}

export function investmentsSetAction(investments: IInvestment[]): IInvestmentsSetAction {
    return {
        investments,
        type: InvestmentsActionsEnum.InvestmentsSet,
    };
}
