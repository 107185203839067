import { AutoComplete, Input } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import history from '~history';
import {
    loansSearchAction,
    loansSearchResultsClearAction,
} from '~Loans/actions';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { loansSearchResultsSelector } from './selectors';

interface IOption {
    label: JSX.Element;
    value: string;
}

interface IState {
    searchText: string;
}

interface IPropsSelector {
    loansSearchResults: IDictionary<ILoan>;
}

interface IPropsDispatch {
    loansSearchResultsClear: () => void;
    loansSearch: (keyword: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Search extends React.Component<Props, IState> {
    public state: IState = {
        searchText: '',
    };

    constructor(props: Props) {
        super(props);

        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    public componentDidMount(): void {
        this.props.loansSearchResultsClear();
    }

    public render(): JSX.Element {
        const { loansSearchResults } = this.props;

        const options: IOption[] = loansSearchResults && _.map(loansSearchResults, (loan: ILoan) => {
            const label: JSX.Element = (
                <div className='result'>
                    <div className='code'>{loan.code}</div>
                    {loan.salesforceCode && loan.salesforceCode !== loan.code && <div className='salesforce-code'>{loan.salesforceCode}</div>}
                    <div className='name'>{loan.contactName}</div>
                </div>
            );

            return {
                label,
                value: `${loan.uuid}`,
            };
        });

        return (
            <AutoComplete
                dropdownMatchSelectWidth={300}
                onSearch={this.onSearch}
                onSelect={this.onSelect}
                options={_.slice(options, 0, 20)}
                popupClassName='loans-search-dropdown'
                value={this.state.searchText}
            >
                <Input.Search/>
            </AutoComplete>
        );
    }

    private onSearch(value: string): void {
        this.setState({
            searchText: value,
        });

        this.props.loansSearch(value);
    }

    private onSelect(value: string): void {
        this.props.loansSearchResultsClear();

        history.push(`/loans/${value}`);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        loansSearchResults: loansSearchResultsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        loansSearch: (keyword) => dispatch(loansSearchAction(keyword)),
        loansSearchResultsClear: () => dispatch(loansSearchResultsClearAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Search);
