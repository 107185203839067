import _ from 'lodash';
import { AnyAction } from 'redux';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import IWarehouseLoanTransaction from '~Api/Warehouse/IWarehouseLoanTransaction';
import IWarehouseTransaction from '~Api/Warehouse/IWarehouseTransaction';
import {
    ILoanWarehouseLoansSetAction,
    ILoansDischargeForecastSetAction,
    ILoansDrawdownsSetAction,
} from '~Loans/actions';
import LoansActionsEnum from '~Loans/ActionsEnum';
import { IDictionary } from '~utilities/IDictionary';
import {
    IWarehouseActiveReportSetAction,
    IWarehouseEligibleLoansSetAction,
    IWarehouseLoanSetAction,
    IWarehouseLoanTransactionsSetAction,
    IWarehouseLoansSetAction,
    IWarehouseParametersSetAction,
    IWarehouseProposedPurchaseParametersSetAction,
    IWarehouseProposedSaleCancelAction,
    IWarehouseProposedSaleProcessAction,
    IWarehouseProposedSalesPendingSetAction,
    IWarehouseTransactionsSetAction,
    IWarehousesLoanBookForecastSetAction,
    IWarehousesPortfolioDashboardSetAction,
    IWarehousesSetAction,
} from './actions';
import WarehousesActionsEnum from './ActionsEnum';
import IWarehouseActiveReportLoan from '~Api/Warehouse/IWarehouseActiveReportLoan';
import IWarehouseEligibleLoan from '~Api/Warehouse/IWarehouseEligibleLoan';
import IWarehouseParameter from '~Api/Warehouse/IWarehouseParameter';
import IWarehouseProposedSale from '~Api/Warehouse/IWarehouseProposedSale';
import IWarehouseProposedSaleDestination from '~Api/Warehouse/IWarehouseProposedSaleDestination';
import IWarehousesPortfolioMetrics from '~Api/Warehouse/IWarehousesPortfolioMetrics';
import ILoan from '~Api/Loan/ILoan';
import ILoanBookForecastDay from '~Api/Warehouse/ILoanBookForecastDay';

export interface IWarehousesState {
    loanBookForecastDays: ILoanBookForecastDay[];
    pendingWarehouseProposedSaleUuids: string[];
    warehouseActiveReportLoans: {
        warehouseLoans: IDictionary<IWarehouseActiveReportLoan>;
        warehouseUuid: string;
    };
    warehouseEligibleLoans: IDictionary<IDictionary<IWarehouseEligibleLoan>>;
    warehouseLoanTransactionUuids: IDictionary<string[]>;
    warehouseLoanTransactions: IDictionary<IWarehouseLoanTransaction>;
    warehouseLoanUuids: IDictionary<string[]>;
    warehouseLoans: IDictionary<IWarehouseLoan>;
    warehouseLoansListed: boolean;
    warehouseProposedPurchaseParameters: IDictionary<IWarehouseParameter[]>;
    warehouseParameters: IDictionary<IWarehouseParameter[]>;
    warehouseProposedSaleDestinationUuids: IDictionary<string[]>;
    warehouseProposedSaleDestinations: IDictionary<IWarehouseProposedSaleDestination>;
    warehouseProposedSaleWarehouseLoanUuids: IDictionary<string>;
    warehouseProposedSales: IDictionary<IWarehouseProposedSale>;
    warehouseTransactionUuids: IDictionary<string[]>;
    warehouseTransactions: IDictionary<IWarehouseTransaction>;
    warehouses: IDictionary<IWarehouse>;
    warehousesPortfolioDashboard: IDictionary<IWarehousesPortfolioMetrics>;
}

const initialData: IWarehousesState = {
    loanBookForecastDays: null,
    pendingWarehouseProposedSaleUuids: null,
    warehouseActiveReportLoans: {
        warehouseLoans: {},
        warehouseUuid: null,
    },
    warehouseEligibleLoans: {},
    warehouseLoanTransactionUuids: {},
    warehouseLoanTransactions: {},
    warehouseLoanUuids: {},
    warehouseLoans: {},
    warehouseLoansListed: false,
    warehouseParameters: {},
    warehouseProposedPurchaseParameters: {},
    warehouseProposedSaleDestinationUuids: {},
    warehouseProposedSaleDestinations: {},
    warehouseProposedSaleWarehouseLoanUuids: {},
    warehouseProposedSales: {},
    warehouseTransactionUuids: {},
    warehouseTransactions: {},
    warehouses: null,
    warehousesPortfolioDashboard: null,
};

function statifyLoan(state: IWarehousesState, loan: ILoan): IWarehousesState {
    const warehouseLoans: IDictionary<IWarehouseLoan> = {};

    _.each(loan.warehouseLoans, (warehouseLoan: IWarehouseLoan) => {
        warehouseLoans[warehouseLoan.uuid] = warehouseLoan;
    });

    return {
        ...state,
        warehouseLoans: {
            ...state.warehouseLoans,
            ...warehouseLoans,
        },
    };
}

function statifyLoans(state: IWarehousesState, loans: ILoan[]): IWarehousesState {
    let clonedState: IWarehousesState = { ...state };

    loans.forEach((loan: ILoan) => {
        clonedState = statifyLoan(clonedState, loan);
    });

    return clonedState;
}

function dehydrateWarehouseProposedSale(warehouseProposedSale: IWarehouseProposedSale): IWarehouseProposedSale {
    return _.omit(warehouseProposedSale, ['sourceWarehouseLoan', 'warehouseProposedSaleDestinations']);
}

function dehydrateWarehouseEligibleLoan(warehouseEligibleLoan: IWarehouseEligibleLoan): IWarehouseEligibleLoan {
    return _.omit(warehouseEligibleLoan, ['loan', 'warehouseLoans']);
}

export function warehousesReducer(state: IWarehousesState = initialData, action: AnyAction): IWarehousesState {
    switch (action.type) {
        case WarehousesActionsEnum.WarehouseActiveReportSet: {
            const typedAction: IWarehouseActiveReportSetAction = action as IWarehouseActiveReportSetAction;

            return {
                ...state,
                warehouseActiveReportLoans: {
                    warehouseLoans: _.keyBy(typedAction.warehouseLoans, 'loanUuid'),
                    warehouseUuid: typedAction.warehouseUuid,
                },
            };
        }

        case WarehousesActionsEnum.WarehouseEligibleLoansSet: {
            const typedAction: IWarehouseEligibleLoansSetAction = action as IWarehouseEligibleLoansSetAction;

            const warehouseLoans: IDictionary<IWarehouseLoan> = { ...state.warehouseLoans };
            const newEligibleLoans: IDictionary<IWarehouseEligibleLoan> = {};

            typedAction.eligibleLoans.forEach((eligibleLoan: IWarehouseEligibleLoan) => {
                eligibleLoan.warehouseLoanUuids = [];
                eligibleLoan.warehouseLoans.forEach((warehouseLoan: IWarehouseLoan) => {
                    warehouseLoans[warehouseLoan.uuid] = warehouseLoan;
                    eligibleLoan.warehouseLoanUuids.push(warehouseLoan.uuid);
                });

                newEligibleLoans[eligibleLoan.loanUuid] = dehydrateWarehouseEligibleLoan(eligibleLoan);
            });

            return {
                ...state,
                warehouseEligibleLoans: {
                    ...state.warehouseEligibleLoans,
                    [typedAction.warehouseUuid]: newEligibleLoans,
                },
                warehouseLoans,
            };
        }

        case WarehousesActionsEnum.WarehouseLoanSet: {
            const typedAction: IWarehouseLoanSetAction = action as IWarehouseLoanSetAction;

            return {
                ...state,
                warehouseLoanUuids: {
                    ...state.warehouseLoanUuids,
                    [typedAction.warehouseLoan.warehouseUuid]: _.uniq([...state.warehouseLoanUuids[typedAction.warehouseLoan.warehouseUuid] || [], typedAction.warehouseLoan.uuid]),
                },
                warehouseLoans: {
                    ...state.warehouseLoans,
                    [typedAction.warehouseLoan.uuid]: _.omit(typedAction.warehouseLoan, ['loan']),
                },
            };
        }

        case WarehousesActionsEnum.WarehouseLoanTransactionsSet: {
            const typedAction: IWarehouseLoanTransactionsSetAction = action as IWarehouseLoanTransactionsSetAction;

            const warehouseLoanTransactions: IDictionary<IWarehouseLoanTransaction> = { ...state.warehouseLoanTransactions };
            const warehouseLoanTransactionUuids: string[] = [];

            typedAction.warehouseLoanTransactions.forEach((warehouseLoanTransaction: IWarehouseLoanTransaction) => {
                warehouseLoanTransactions[warehouseLoanTransaction.uuid] = warehouseLoanTransaction;
                warehouseLoanTransactionUuids.push(warehouseLoanTransaction.uuid);
            });

            return {
                ...state,
                warehouseLoanTransactionUuids: {
                    ...state.warehouseLoanTransactionUuids,
                    [typedAction.warehouseLoanUuid]: warehouseLoanTransactionUuids,
                },
                warehouseLoanTransactions,
            };
        }

        case WarehousesActionsEnum.WarehouseLoansSet: {
            const typedAction: IWarehouseLoansSetAction = action as IWarehouseLoansSetAction;

            const warehouseLoans: IDictionary<IWarehouseLoan> = { ...state.warehouseLoans };
            const warehouseLoanUuids: string[] = [];

            typedAction.warehouseLoans.forEach((warehouseLoan: IWarehouseLoan) => {
                warehouseLoans[warehouseLoan.uuid] = _.omit(warehouseLoan, ['loan']);
                warehouseLoanUuids.push(warehouseLoan.uuid);
            });

            return {
                ...state,
                warehouseLoanUuids: {
                    ...state.warehouseLoanUuids,
                    [typedAction.warehouseUuid]: warehouseLoanUuids,
                },
                warehouseLoans,
                warehouseLoansListed: true,
            };
        }

        case WarehousesActionsEnum.WarehouseParametersSet: {
            const typedAction: IWarehouseParametersSetAction = action as IWarehouseParametersSetAction;

            return {
                ...state,
                warehouseParameters: {
                    ...state.warehouseParameters,
                    [typedAction.warehouseUuid]: typedAction.warehouseParameters,
                },
            };
        }

        case WarehousesActionsEnum.WarehouseProposedPurchaseParametersSet: {
            const typedAction: IWarehouseProposedPurchaseParametersSetAction = action as IWarehouseProposedPurchaseParametersSetAction;

            return {
                ...state,
                warehouseProposedPurchaseParameters: {
                    ...state.warehouseProposedPurchaseParameters,
                    [typedAction.warehouseUuid]: typedAction.warehouseParameters,
                },
            };
        }

        case WarehousesActionsEnum.WarehouseProposedSalesPendingSet: {
            const typedAction: IWarehouseProposedSalesPendingSetAction = action as IWarehouseProposedSalesPendingSetAction;

            const pendingWarehouseProposedSaleUuids: string[] = state.pendingWarehouseProposedSaleUuids || [];
            const warehouseProposedSales: IDictionary<IWarehouseProposedSale> = { ...state.warehouseProposedSales };
            const warehouseProposedSaleDestinations: IDictionary<IWarehouseProposedSaleDestination> = { ...state.warehouseProposedSaleDestinations };
            const warehouseProposedSaleDestinationUuids: IDictionary<string[]> = { ...state.warehouseProposedSaleDestinationUuids };
            const warehouseLoans: IDictionary<IWarehouseLoan> = { ...state.warehouseLoans };
            const warehouseProposedSaleWarehouseLoanUuids: IDictionary<string> = { ...state.warehouseProposedSaleWarehouseLoanUuids };

            _.forEach(typedAction.warehouseProposedSales, (warehouseProposedSale: IWarehouseProposedSale) => {
                warehouseProposedSaleDestinationUuids[warehouseProposedSale.uuid] = [];

                _.forEach(warehouseProposedSale.warehouseProposedSaleDestinations, (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                    warehouseProposedSaleDestinations[warehouseProposedSaleDestination.uuid] = warehouseProposedSaleDestination;
                    warehouseProposedSaleDestinationUuids[warehouseProposedSale.uuid].push(warehouseProposedSaleDestination.uuid);
                });

                warehouseProposedSaleWarehouseLoanUuids[warehouseProposedSale.uuid] = warehouseProposedSale.sourceWarehouseLoan.uuid;
                warehouseLoans[warehouseProposedSale.sourceWarehouseLoan.uuid] = warehouseProposedSale.sourceWarehouseLoan;

                warehouseProposedSales[warehouseProposedSale.uuid] = {
                    ...dehydrateWarehouseProposedSale(warehouseProposedSale),
                };
                pendingWarehouseProposedSaleUuids.push(warehouseProposedSale.uuid);
            });

            return {
                ...state,
                pendingWarehouseProposedSaleUuids,
                warehouseLoans,
                warehouseProposedSaleDestinationUuids,
                warehouseProposedSaleDestinations,
                warehouseProposedSaleWarehouseLoanUuids,
                warehouseProposedSales,
            };
        }

        case WarehousesActionsEnum.WarehouseProposedSaleProcess: {
            const typedAction: IWarehouseProposedSaleProcessAction = action as IWarehouseProposedSaleProcessAction;

            const pendingWarehouseProposedSaleUuids: string[] = _.without(state.pendingWarehouseProposedSaleUuids, typedAction.warehouseProposedSaleUuid);

            return {
                ...state,
                pendingWarehouseProposedSaleUuids,
            };
        }

        case WarehousesActionsEnum.WarehouseProposedSaleCancel: {
            const typedAction: IWarehouseProposedSaleCancelAction = action as IWarehouseProposedSaleCancelAction;

            const pendingWarehouseProposedSaleUuids: string[] = _.without(state.pendingWarehouseProposedSaleUuids, typedAction.warehouseProposedSaleUuid);

            return {
                ...state,
                pendingWarehouseProposedSaleUuids,
            };
        }

        case WarehousesActionsEnum.WarehouseTransactionsSet: {
            const typedAction: IWarehouseTransactionsSetAction = action as IWarehouseTransactionsSetAction;

            const warehouseTransactions: IDictionary<IWarehouseTransaction> = { ...state.warehouseTransactions };
            const warehouseTransactionUuids: string[] = [];

            typedAction.warehouseTransactions.forEach((warehouseTransaction: IWarehouseTransaction) => {
                warehouseTransactions[warehouseTransaction.uuid] = warehouseTransaction;
                warehouseTransactionUuids.push(warehouseTransaction.uuid);
            });

            return {
                ...state,
                warehouseTransactionUuids: {
                    ...state.warehouseTransactionUuids,
                    [typedAction.warehouseUuid]: warehouseTransactionUuids,
                },
                warehouseTransactions,
            };
        }

        case WarehousesActionsEnum.WarehousesSet: {
            const typedAction: IWarehousesSetAction = action as IWarehousesSetAction;

            const warehouses: IDictionary<IWarehouse> = {};

            typedAction.warehouses.forEach((warehouse: IWarehouse) => {
                warehouses[warehouse.uuid] = warehouse;
            });

            return {
                ...state,
                warehouses,
            };
        }

        case WarehousesActionsEnum.WarehousesLoanBookForecastSet: {
            const typedAction: IWarehousesLoanBookForecastSetAction = action as IWarehousesLoanBookForecastSetAction;

            return {
                ...state,
                loanBookForecastDays: typedAction.forecastDays,
            };
        }

        case WarehousesActionsEnum.WarehousesPortfolioDashboardSet: {
            const typedAction: IWarehousesPortfolioDashboardSetAction = action as IWarehousesPortfolioDashboardSetAction;

            return {
                ...state,
                warehousesPortfolioDashboard: _.keyBy(typedAction.warehousesPortfolioDashboard, 'warehouseUuid'),
            };
        }

        case LoansActionsEnum.LoanWarehouseLoansSet: {
            const typedAction: ILoanWarehouseLoansSetAction = action as ILoanWarehouseLoansSetAction;

            const warehouseLoans: IDictionary<IWarehouseLoan> = { ...state.warehouseLoans };
            const warehouseLoanUuids: IDictionary<string[]> = { ...state.warehouseLoanUuids };

            typedAction.warehouseLoans.forEach((warehouseLoan: IWarehouseLoan) => {
                warehouseLoans[warehouseLoan.uuid] = warehouseLoan;
                (warehouseLoanUuids[warehouseLoan.warehouseUuid] ?? (warehouseLoanUuids[warehouseLoan.warehouseUuid] = [])).push(warehouseLoan.uuid);
            });

            return {
                ...state,
                warehouseLoanUuids,
                warehouseLoans,
            };
        }

        case LoansActionsEnum.LoansDischargeForecastSet: {
            const typedAction: ILoansDischargeForecastSetAction = action as ILoansDischargeForecastSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        case LoansActionsEnum.LoansDrawdownsSet: {
            const typedAction: ILoansDrawdownsSetAction = action as ILoansDrawdownsSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        default:
            return state;
    }
}
