import { Button, Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplicationWarehouse from '~Api/Application/IApplicationWarehouse';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import { applicationWarehouseValueSetAction } from '~Applications/actions';
import {
    applicationWarehouseSelector,
    applicationWarehousesSelector,
} from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { warehousesListAction } from '~Warehouses/actions';
import { warehousesSelector } from '~Warehouses/selectors';

interface IDefaultedValues {
    amount: number;
}

interface IState {
    amount: number;
    dirtyFields: IDictionary<boolean>;
}

interface IProps {
    applicationUuid: string;
    applicationWarehouseUuid: string;
    isOpen: boolean;
    onClose: () => void;
}

interface IPropsSelector {
    applicationWarehouse: IApplicationWarehouse;
    applicationWarehouses: IDictionary<IApplicationWarehouse>;
    warehouses: IDictionary<IWarehouse>;
}

interface IPropsDispatch {
    update: (key: keyof IApplicationWarehouse, value: any) => void;
    warehousesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class WarehouseEditModal extends React.Component<Props, IState> {
    public state: IState = {
        amount: null,
        dirtyFields: {},
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAmount = this.onChangeAmount.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public componentDidMount(): void {
        const { warehouses } = this.props;

        if (!warehouses) {
            this.props.warehousesList();
        }
    }

    public render(): JSX.Element {
        const { applicationWarehouse, isOpen, warehouses } = this.props;

        if (!applicationWarehouse || !warehouses) {
            return null;
        }

        const { amount } = this.getDefaultedValues();

        const footer: JSX.Element = (
            <React.Fragment>
                <Button className='close' onClick={this.props.onClose}>Close</Button>
            </React.Fragment>
        );

        return (
            <Modal
                destroyOnClose={true}
                footer={footer}
                okText='Close'
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title='Edit Warehouse'
                wrapClassName='application-warehouse-edit-modal'
            >
                <Form.Item label='Amount'>
                    <Input type='number' value={amount} placeholder='0' addonBefore='$' min={0} onChange={this.onChangeAmount} />
                </Form.Item>
            </Modal>
        );
    }

    private getDefaultedValues(): IDefaultedValues {
        const { applicationWarehouse } = this.props;
        const { amount, dirtyFields } = this.state;

        return {
            amount: dirtyFields.amount ? amount : applicationWarehouse.amount,
        };
    }

    private onChangeAmount(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        this.setState({
            amount: event.target.value ? event.target.valueAsNumber : null,
            dirtyFields: {
                ...dirtyFields,
                amount: true,
            },
        });
        this.props.update('amount', event.target.value ? event.target.valueAsNumber : 0);
    }

    private onClickOk(): void {
        this.props.onClose();
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        applicationWarehouse: applicationWarehouseSelector(state, ownProps.applicationWarehouseUuid),
        applicationWarehouses: applicationWarehousesSelector(state, ownProps.applicationUuid),
        warehouses: warehousesSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        update: (key: keyof IApplicationWarehouse, value: any) => dispatch(applicationWarehouseValueSetAction(ownProps.applicationWarehouseUuid, key, value)),
        warehousesList: () => dispatch(warehousesListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WarehouseEditModal);
