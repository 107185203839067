import { Action } from 'redux';
import CloseReasonEnum from '~Api/Application/CloseReasonEnum';
import IApplication from '~Api/Application/IApplication';
import IApplicationDisbursement from '~Api/Application/IApplicationDisbursement';
import IApplicationFee from '~Api/Application/IApplicationFee';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IApplicationPropertyValuer from '~Api/Application/IApplicationPropertyValuer';
import IApplicationWarehouse from '~Api/Application/IApplicationWarehouse';
import IBorrower from '~Api/Application/IBorrower';
import IConditionalCondition from '~Api/Application/IConditionalCondition';
import IConditional from '~Api/Application/IConditional';
import IFormal from '~Api/Application/IFormal';
import IHistory from '~Api/Application/IHistory';
import INote from '~Api/Application/INote';
import ApplicationFeeFormatEnum from '~Api/Deal/ApplicationFeeFormatEnum';
import BrokerageFeeFormatEnum from '~Api/Deal/BrokerageFeeFormatEnum';
import EstablishmentFeeFormatEnum from '~Api/Deal/EstablishmentFeeFormatEnum';
import IDeal from '~Api/Deal/IDeal';
import IDealBorrower from '~Api/Deal/IDealBorrower';
import { IDictionary } from '~utilities/IDictionary';
import ApplicationsActionsEnum from './ActionsEnum';
import ConditionTypeEnum from '~Api/Application/ConditionTypeEnum';
import ICondition from '~Api/Application/ICondition';
import IConditionDocument from '~Api/Application/IConditionDocument';
import IRfiItem from '~Api/Application/IRfiItem';
import ISettlementReportApplication from '~Api/Application/ISettlementReportApplication';
import { PeriodRangeEnum } from '~utilities/reportUtilities';
import { IStandardConditionGroup } from '~Api/Application/IStandardConditionGroup';

export interface IApplicationAssignAction extends Action {
    administratorUuid: string;
    applicationUuid: string;
}

export function applicationAssignAction(applicationUuid: string, administratorUuid: string): IApplicationAssignAction {
    return {
        administratorUuid,
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationAssign,
    };
}

export interface IApplicationsCreateRenewalFromLoanAction extends Action {
    applicationFee: number;
    applicationFeeFormat: ApplicationFeeFormatEnum;
    applicationFeePercentage: number;
    brokerageFee: number;
    brokerageFeeFormat: BrokerageFeeFormatEnum;
    brokerageFeePercentage: number;
    establishmentFee: number;
    establishmentFeeFormat: EstablishmentFeeFormatEnum;
    establishmentFeePercentage: number;
    estimatedOutlays: number;
    interestRate: number;
    legalFees: number;
    loanAmount: number;
    loanPayoutFigureUuid: string;
    lvr: number;
    maximumLvr: number;
    termMonths: number;
}

export function applicationsCreateRenewalFromLoanAction(applicationFee: number, applicationFeeFormat: ApplicationFeeFormatEnum, applicationFeePercentage: number, brokerageFee: number, brokerageFeeFormat: BrokerageFeeFormatEnum, brokerageFeePercentage: number, establishmentFee: number, establishmentFeeFormat: EstablishmentFeeFormatEnum, establishmentFeePercentage: number, estimatedOutlays: number, interestRate: number, legalFees: number, loanAmount: number, loanPayoutFigureUuid: string, lvr: number, maximumLvr: number, termMonths: number): IApplicationsCreateRenewalFromLoanAction {
    return {
        applicationFee,
        applicationFeeFormat,
        applicationFeePercentage,
        brokerageFee,
        brokerageFeeFormat,
        brokerageFeePercentage,
        establishmentFee,
        establishmentFeeFormat,
        establishmentFeePercentage,
        estimatedOutlays,
        interestRate,
        legalFees,
        loanAmount,
        loanPayoutFigureUuid,
        lvr,
        maximumLvr,
        termMonths,
        type: ApplicationsActionsEnum.ApplicationsCreateRenewalFromLoan,
    };
}

export interface IApplicationsCreateRenewalFromLoanErrorSetAction extends Action {
    key: string;
    loanPayoutFigureUuid: string;
    value: string;
}

export function applicationsCreateRenewalFromLoanErrorSetAction(loanPayoutFigureUuid: string, key: string, value: string): IApplicationsCreateRenewalFromLoanErrorSetAction {
    return {
        key,
        loanPayoutFigureUuid,
        type: ApplicationsActionsEnum.ApplicationsCreateRenewalFromLoanErrorSet,
        value,
    };
}

export interface IApplicationsCreateRenewalFromLoanErrorsSetAction extends Action {
    errors: IDictionary<string>;
    loanPayoutFigureUuid: string;
}

export function applicationsCreateRenewalFromLoanErrorsSetAction(loanPayoutFigureUuid: string, errors: IDictionary<string>): IApplicationsCreateRenewalFromLoanErrorsSetAction {
    return {
        errors,
        loanPayoutFigureUuid,
        type: ApplicationsActionsEnum.ApplicationsCreateRenewalFromLoanErrorsSet,
    };
}

export interface IApplicationsCreateRenewalFromLoanInProgressSetAction extends Action {
    inProgress: boolean;
}

export function applicationsCreateRenewalFromLoanInProgressSetAction(inProgress: boolean): IApplicationsCreateRenewalFromLoanInProgressSetAction {
    return {
        inProgress,
        type: ApplicationsActionsEnum.ApplicationsCreateRenewalFromLoanInProgressSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IApplicationsDashboardListAction extends Action {}

export function applicationsDashboardListAction(): IApplicationsDashboardListAction {
    return {
        type: ApplicationsActionsEnum.ApplicationsDashboardList,
    };
}

export interface IApplicationsDashboardSetAction extends Action {
    applications: IApplication[];
}

export function applicationsDashboardSetAction(applications: IApplication[]): IApplicationsDashboardSetAction {
    return {
        applications,
        type: ApplicationsActionsEnum.ApplicationsDashboardSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IApplicationsListAction extends Action {}

export function applicationsListAction(): IApplicationsListAction {
    return {
        type: ApplicationsActionsEnum.ApplicationsList,
    };
}

export interface IApplicationsSearchAction extends Action {
    keyword: string;
}

export function applicationsSearchAction(keyword: string): IApplicationsSearchAction {
    return {
        keyword,
        type: ApplicationsActionsEnum.ApplicationsSearch,
    };
}

export interface IApplicationsSearchResultsSetAction extends Action {
    applications: IApplication[];
}

export function applicationsSearchResultsSetAction(applications: IApplication[]): IApplicationsSearchResultsSetAction {
    return {
        applications,
        type: ApplicationsActionsEnum.ApplicationsSearchResultsSet,
    };
}

export function applicationsSearchResultsClearAction(): Action {
    return {
        type: ApplicationsActionsEnum.ApplicationsSearchResultsClear,
    };
}

export interface IApplicationsSetAction extends Action {
    applications: IApplication[];
}

export function applicationsSetAction(applications: IApplication[]): IApplicationsSetAction {
    return {
        applications,
        type: ApplicationsActionsEnum.ApplicationsSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IApplicationsSettlementForecastListAction extends Action {}

export function applicationsSettlementForecastListAction(): IApplicationsSettlementForecastListAction {
    return {
        type: ApplicationsActionsEnum.ApplicationsSettlementForecastList,
    };
}

export interface IApplicationsSettlementForecastSetAction extends Action {
    applications: IApplication[];
}

export function applicationsSettlementForecastSetAction(applications: IApplication[]): IApplicationsSettlementForecastSetAction {
    return {
        applications,
        type: ApplicationsActionsEnum.ApplicationsSettlementForecastSet,
    };
}

export interface IApplicationsSettlementReportListAction extends Action {
    endDate: string;
    periodRange: PeriodRangeEnum;
    startDate: string;
}

export function applicationsSettlementReportListAction(startDate: string, endDate: string, periodRange: PeriodRangeEnum): IApplicationsSettlementReportListAction {
    return {
        endDate,
        periodRange,
        startDate,
        type: ApplicationsActionsEnum.ApplicationsSettlementReportList,
    };
}

export interface IApplicationsSettlementReportSetAction extends Action {
    settlementReportApplications: ISettlementReportApplication[];
}

export function applicationsSettlementReportSetAction(settlementReportApplications: ISettlementReportApplication[]): IApplicationsSettlementReportSetAction {
    return {
        settlementReportApplications,
        type: ApplicationsActionsEnum.ApplicationsSettlementReportSet,
    };
}

export interface IApplicationAssignPayoutFigureAction extends Action {
    applicationUuid: string;
    payoutFigureUuid: string;
}

export function applicationAssignPayoutFigureAction(applicationUuid: string, payoutFigureUuid: string): IApplicationAssignPayoutFigureAction {
    return {
        applicationUuid,
        payoutFigureUuid,
        type: ApplicationsActionsEnum.ApplicationAssignPayoutFigure,
    };
}

export interface IApplicationCloseAction extends Action {
    applicationUuid: string;
    reason: CloseReasonEnum;
    note: string;
}

export function applicationCloseAction(applicationUuid: string, reason: CloseReasonEnum, note: string): IApplicationCloseAction {
    return {
        applicationUuid,
        note,
        reason,
        type: ApplicationsActionsEnum.ApplicationClose,
    };
}

export interface IApplicationConditionalsAddAction extends Action {
    applicationUuid: string;
    conditions: string[];
}

export function applicationConditionalsAddAction(applicationUuid: string, conditions: string[]): IApplicationConditionalsAddAction {
    return {
        applicationUuid,
        conditions,
        type: ApplicationsActionsEnum.ApplicationConditionalsAdd,
    };
}

export interface IApplicationConditionalsListAction extends Action {
    applicationUuid: string;
}

export function applicationConditionalsListAction(applicationUuid: string): IApplicationConditionalsListAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationConditionalsList,
    };
}

export interface IApplicationConditionalsSetAction extends Action {
    applicationUuid: string;
    conditionals: IConditional[];
}

export function applicationConditionalsSetAction(applicationUuid: string, conditionals: IConditional[]): IApplicationConditionalsSetAction {
    return {
        applicationUuid,
        conditionals,
        type: ApplicationsActionsEnum.ApplicationConditionalsSet,
    };
}

export interface IApplicationConditionalApproveAction extends Action {
    conditionalUuid: string;
}

export function applicationConditionalApproveAction(conditionalUuid: string): IApplicationConditionalApproveAction {
    return {
        conditionalUuid,
        type: ApplicationsActionsEnum.ApplicationConditionalApprove,
    };
}

export interface IApplicationConditionalDeleteAction extends Action {
    conditionalUuid: string;
}

export function applicationConditionalDeleteAction(conditionalUuid: string): IApplicationConditionalDeleteAction {
    return {
        conditionalUuid,
        type: ApplicationsActionsEnum.ApplicationConditionalDelete,
    };
}

export interface IApplicationConditionalGetAction extends Action {
    conditionalUuid: string;
}

export function applicationConditionalGetAction(conditionalUuid: string): IApplicationConditionalGetAction {
    return {
        conditionalUuid,
        type: ApplicationsActionsEnum.ApplicationConditionalGet,
    };
}

export interface IApplicationConditionalSendAction extends Action {
    conditionalUuid: string;
    email?: string;
    firstName?: string;
    lastName?: string;
}

export function applicationConditionalSendAction(conditionalUuid: string, email?: string, firstName?: string, lastName?: string): IApplicationConditionalSendAction {
    return {
        conditionalUuid,
        email,
        firstName,
        lastName,
        type: ApplicationsActionsEnum.ApplicationConditionalSend,
    };
}

export interface IApplicationConditionalSetAction extends Action {
    conditional: IConditional;
}

export function applicationConditionalSetAction(conditional: IConditional): IApplicationConditionalSetAction {
    return {
        conditional,
        type: ApplicationsActionsEnum.ApplicationConditionalSet,
    };
}

export interface IApplicationConditionalConditionAddAction extends Action {
    condition: IConditionalCondition;
    conditionalUuid: string;
}

export function applicationConditionalConditionAddAction(conditionalUuid: string, condition: IConditionalCondition): IApplicationConditionalConditionAddAction {
    return {
        condition,
        conditionalUuid,
        type: ApplicationsActionsEnum.ApplicationConditionalConditionAdd,
    };
}

export interface IApplicationConditionalConditionDeleteAction extends Action {
    condition: IConditionalCondition;
    conditionalUuid: string;
}

export function applicationConditionalConditionDeleteAction(conditionalUuid: string, condition: IConditionalCondition): IApplicationConditionalConditionDeleteAction {
    return {
        condition,
        conditionalUuid,
        type: ApplicationsActionsEnum.ApplicationConditionalConditionDelete,
    };
}

export interface IApplicationConditionalConditionSetAction extends Action {
    condition: IConditionalCondition;
    conditionalUuid: string;
}

export function applicationConditionalConditionSetAction(conditionalUuid: string, condition: IConditionalCondition): IApplicationConditionalConditionSetAction {
    return {
        condition,
        conditionalUuid,
        type: ApplicationsActionsEnum.ApplicationConditionalConditionSet,
    };
}

export interface IApplicationConditionalApprovalAction extends Action {
    applicationUuid: string;
}

export function applicationConditionalApprovalAction(applicationUuid: string): IApplicationConditionalApprovalAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationConditionalApproval,
    };
}

export interface IApplicationConditionsListAction extends Action {
    applicationUuid: string;
}

export function applicationConditionsListAction(applicationUuid: string): IApplicationConditionsListAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationConditionsList,
    };
}

export interface IApplicationConditionsAddAction extends Action {
    applicationUuid: string;
    name: string;
}

export function applicationConditionsAddAction(applicationUuid: string, name: string): IApplicationConditionsAddAction {
    return {
        applicationUuid,
        name,
        type: ApplicationsActionsEnum.ApplicationConditionsAdd,
    };
}

export interface IApplicationConditionsSetAction extends Action {
    applicationUuid: string;
    conditions: ICondition[];
}

export function applicationConditionsSetAction(applicationUuid: string, conditions: ICondition[]): IApplicationConditionsSetAction {
    return {
        applicationUuid,
        conditions,
        type: ApplicationsActionsEnum.ApplicationConditionsSet,
    };
}

export interface IApplicationConditionDeleteAction extends Action {
    applicationUuid: string;
    conditionUuid: string;
}

export function applicationConditionDeleteAction(applicationUuid: string, conditionUuid: string): IApplicationConditionDeleteAction {
    return {
        applicationUuid,
        conditionUuid,
        type: ApplicationsActionsEnum.ApplicationConditionDelete,
    };
}

export interface IApplicationConditionRemoveAction extends Action {
    applicationUuid: string;
    conditionUuid: string;
}

export function applicationConditionRemoveAction(applicationUuid: string, conditionUuid: string): IApplicationConditionRemoveAction {
    return {
        applicationUuid,
        conditionUuid,
        type: ApplicationsActionsEnum.ApplicationConditionRemove,
    };
}

export interface IApplicationConditionSetAction extends Action {
    applicationUuid: string;
    condition: ICondition;
}

export function applicationConditionSetAction(applicationUuid: string, condition: ICondition): IApplicationConditionSetAction {
    return {
        applicationUuid,
        condition,
        type: ApplicationsActionsEnum.ApplicationConditionSet,
    };
}

export interface IApplicationConditionalsStandardConditionsSetAction extends Action {
    standardConditions: IStandardConditionGroup[];
}

export function applicationConditionalsStandardConditionsSetAction(standardConditions: IStandardConditionGroup[]): IApplicationConditionalsStandardConditionsSetAction {
    return {
        standardConditions,
        type: ApplicationsActionsEnum.ApplicationConditionalsStandardConditionsSet,
    };
}

export type IApplicationConditionalsStandardConditionsListAction = Action<ApplicationsActionsEnum.ApplicationConditionalsStandardConditionsList>;

export function applicationConditionalsStandardConditionsListAction(): IApplicationConditionalsStandardConditionsListAction {
    return {
        type: ApplicationsActionsEnum.ApplicationConditionalsStandardConditionsList,
    };
}

export interface IApplicationConditionDocumentsAddAction extends Action {
    applicationUuid: string;
    conditionDocument: IConditionDocument;
    file: File;
}

export function applicationConditionDocumentsAddAction(applicationUuid: string, file: File, conditionDocument: IConditionDocument): IApplicationConditionDocumentsAddAction {
    return {
        applicationUuid,
        conditionDocument,
        file,
        type: ApplicationsActionsEnum.ApplicationConditionDocumentsAdd,
    };
}

export interface IApplicationConditionDocumentApproveAction extends Action {
    /** @deprecated We will remove this once application UUID is standard - when we have transitioned away from deal condition documents */
    applicationUuid: string;
    conditionDocumentUuid: string;
}

export function applicationConditionDocumentApproveAction(applicationUuid: string, conditionDocumentUuid: string): IApplicationConditionDocumentApproveAction {
    return {
        applicationUuid,
        conditionDocumentUuid,
        type: ApplicationsActionsEnum.ApplicationConditionDocumentApprove,
    };
}

export interface IApplicationConditionDocumentDeleteAction extends Action {
    /** @deprecated We will remove this once application UUID is standard - when we have transitioned away from deal condition documents */
    applicationUuid: string;
    conditionDocumentUuid: string;
}

export function applicationConditionDocumentDeleteAction(applicationUuid: string, conditionDocumentUuid: string): IApplicationConditionDocumentDeleteAction {
    return {
        applicationUuid,
        conditionDocumentUuid,
        type: ApplicationsActionsEnum.ApplicationConditionDocumentDelete,
    };
}

export interface IApplicationConditionDocumentRejectAction extends Action {
    /** @deprecated We will remove this once application UUID is standard - when we have transitioned away from deal condition documents */
    applicationUuid: string;
    conditionDocumentUuid: string;
}

export function applicationConditionDocumentRejectAction(applicationUuid: string, conditionDocumentUuid: string): IApplicationConditionDocumentRejectAction {
    return {
        applicationUuid,
        conditionDocumentUuid,
        type: ApplicationsActionsEnum.ApplicationConditionDocumentReject,
    };
}

export interface IApplicationConditionDocumentResetApprovalStatusAction extends Action {
    /** @deprecated We will remove this once application UUID is standard - when we have transitioned away from deal condition documents */
    applicationUuid: string;
    conditionDocumentUuid: string;
}

export function applicationConditionDocumentResetApprovalStatusAction(applicationUuid: string, conditionDocumentUuid: string): IApplicationConditionDocumentResetApprovalStatusAction {
    return {
        applicationUuid,
        conditionDocumentUuid,
        type: ApplicationsActionsEnum.ApplicationConditionDocumentResetApprovalStatus,
    };
}

export interface IApplicationConditionDocumentsDownloadAction extends Action {
    applicationUuid: string;
    conditionDocumentUuids: string[];
}

export function applicationConditionDocumentsDownloadAction(applicationUuid: string, conditionDocumentUuids: string[]): IApplicationConditionDocumentsDownloadAction {
    return {
        applicationUuid,
        conditionDocumentUuids,
        type: ApplicationsActionsEnum.ApplicationConditionDocumentDownload,
    };
}

export interface IApplicationConditionDocumentsUpdatingSolicitorAccessSetAction extends Action {
    updatingSolicitorAccess: boolean;
}

export function applicationConditionDocumentsUpdatingSolicitorAccessSetAction(updatingSolicitorAccess: boolean): IApplicationConditionDocumentsUpdatingSolicitorAccessSetAction {
    return {
        type: ApplicationsActionsEnum.ApplicationConditionDocumentsUpdatingSolicitorAccessSet,
        updatingSolicitorAccess,
    };
}

export interface IApplicationConditionDocumentsListAction extends Action {
    applicationUuid: string;
}

export function applicationConditionDocumentsListAction(applicationUuid: string): IApplicationConditionDocumentsListAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationConditionDocumentsList,
    };
}

export interface IApplicationConditionDocumentsSetAction extends Action {
    applicationUuid: string;
    conditionDocuments: IConditionDocument[];
}

export function applicationConditionDocumentsSetAction(applicationUuid: string, conditionDocuments: IConditionDocument[]): IApplicationConditionDocumentsSetAction {
    return {
        applicationUuid,
        conditionDocuments,
        type: ApplicationsActionsEnum.ApplicationConditionDocumentsSet,
    };
}

export interface IApplicationConditionDocumentSetAction extends Action {
    /** @deprecated We will remove this once application UUID is standard - when we have transitioned away from deal condition documents */
    applicationUuid: string;
    conditionDocument: IConditionDocument;
}

export function applicationConditionDocumentSetAction(applicationUuid: string, conditionDocument: IConditionDocument): IApplicationConditionDocumentSetAction {
    return {
        applicationUuid,
        conditionDocument,
        type: ApplicationsActionsEnum.ApplicationConditionDocumentSet,
    };
}

export interface IApplicationConditionDocumentRemoveAction extends Action {
    applicationUuid: string;
    conditionDocumentUuid: string;
}

export function applicationConditionDocumentRemoveAction(applicationUuid: string, conditionDocumentUuid: string): IApplicationConditionDocumentRemoveAction {
    return {
        applicationUuid,
        conditionDocumentUuid,
        type: ApplicationsActionsEnum.ApplicationConditionDocumentRemove,
    };
}

export interface IApplicationFormalsAddAction extends Action {
    applicationUuid: string;
}

export function applicationFormalsAddAction(applicationUuid: string): IApplicationFormalsAddAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationFormalsAdd,
    };
}

export interface IApplicationFormalsListAction extends Action {
    applicationUuid: string;
}

export function applicationFormalsListAction(applicationUuid: string): IApplicationFormalsListAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationFormalsList,
    };
}

export interface IApplicationFormalsSetAction extends Action {
    applicationUuid: string;
    formals: IFormal[];
}

export function applicationFormalsSetAction(applicationUuid: string, formals: IFormal[]): IApplicationFormalsSetAction {
    return {
        applicationUuid,
        formals,
        type: ApplicationsActionsEnum.ApplicationFormalsSet,
    };
}

export interface IApplicationHistoriesListAction extends Action {
    applicationUuid: string;
}

export function applicationHistoriesListAction(applicationUuid: string): IApplicationHistoriesListAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationHistoriesList,
    };
}

export interface IApplicationHistoriesSetAction extends Action {
    applicationUuid: string;
    histories: IHistory[];
}

export function applicationHistoriesSetAction(applicationUuid: string, histories: IHistory[]): IApplicationHistoriesSetAction {
    return {
        applicationUuid,
        histories,
        type: ApplicationsActionsEnum.ApplicationHistoriesSet,
    };
}

export interface IApplicationNotesAddAction extends Action {
    applicationUuid: string;
    note: string;
}

export function applicationNotesAddAction(applicationUuid: string, note: string): IApplicationNotesAddAction {
    return {
        applicationUuid,
        note,
        type: ApplicationsActionsEnum.ApplicationNotesAdd,
    };
}

export interface IApplicationNotesListAction extends Action {
    applicationUuid: string;
}

export function applicationNotesListAction(applicationUuid: string): IApplicationNotesListAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationNotesList,
    };
}

export interface IApplicationNotesSetAction extends Action {
    applicationUuid: string;
    notes: INote[];
}

export function applicationNotesSetAction(applicationUuid: string, notes: INote[]): IApplicationNotesSetAction {
    return {
        applicationUuid,
        notes,
        type: ApplicationsActionsEnum.ApplicationNotesSet,
    };
}

export interface IApplicationNoteRemoveAction extends Action {
    applicationUuid: string;
    noteUuid: string;
}

export function applicationNoteRemoveAction(applicationUuid: string, noteUuid: string): IApplicationNoteRemoveAction {
    return {
        applicationUuid,
        noteUuid,
        type: ApplicationsActionsEnum.ApplicationNoteRemove,
    };
}

export interface IApplicationNoteSetAction extends Action {
    applicationUuid: string;
    note: INote;
}

export function applicationNoteSetAction(applicationUuid: string, note: INote): IApplicationNoteSetAction {
    return {
        applicationUuid,
        note,
        type: ApplicationsActionsEnum.ApplicationNoteSet,
    };
}

export interface IApplicationMarkBrokerCommissionPaidAction extends Action {
    applicationUuid: string;
}

export function applicationMarkBrokerCommissionPaidAction(applicationUuid: string): IApplicationMarkBrokerCommissionPaidAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationMarkBrokerCommissionPaid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IApplicationsOutstandingBrokerCommissionsListAction extends Action {}

export function applicationsOutstandingBrokerCommissionsListAction(): IApplicationsOutstandingBrokerCommissionsListAction {
    return {
        type: ApplicationsActionsEnum.ApplicationsOutstandingBrokerCommissionsList,
    };
}

export interface IApplicationsOutstandingBrokerCommissionsSetAction extends Action {
    applications: IApplication[];
}

export function applicationsOutstandingBrokerCommissionsSetAction(applications: IApplication[]): IApplicationsOutstandingBrokerCommissionsSetAction {
    return {
        applications,
        type: ApplicationsActionsEnum.ApplicationsOutstandingBrokerCommissionsSet,
    };
}

export interface IApplicationDealValueSetAction extends Action {
    applicationUuid: string;
    key: keyof IDeal;
    value: boolean|number|string;
}

export function applicationDealValueSetAction(applicationUuid: string, key: keyof IDeal, value: boolean|number|string): IApplicationDealValueSetAction {
    return {
        applicationUuid,
        key,
        type: ApplicationsActionsEnum.ApplicationDealValueSet,
        value,
    };
}

export interface IApplicationFeeDeleteAction extends Action {
    applicationFeeUuid: string;
}

export function applicationFeeDeleteAction(applicationFeeUuid: string): IApplicationFeeDeleteAction {
    return {
        applicationFeeUuid,
        type: ApplicationsActionsEnum.ApplicationFeeDelete,
    };
}

export interface IApplicationFeeRemoveAction extends Action {
    applicationFeeUuid: string;
}

export function applicationFeeRemoveAction(applicationFeeUuid: string): IApplicationFeeRemoveAction {
    return {
        applicationFeeUuid,
        type: ApplicationsActionsEnum.ApplicationFeeRemove,
    };
}

export interface IApplicationFeeSendAction extends Action {
    applicationFeeUuid: string;
}

export function applicationFeeSendAction(applicationFeeUuid: string): IApplicationFeeSendAction {
    return {
        applicationFeeUuid,
        type: ApplicationsActionsEnum.ApplicationFeeSend,
    };
}

export interface IApplicationFeeSetAction extends Action {
    fee: IApplicationFee;
}

export function applicationFeeSetAction(fee: IApplicationFee): IApplicationFeeSetAction {
    return {
        fee,
        type: ApplicationsActionsEnum.ApplicationFeeSet,
    };
}

export interface IApplicationFeeValueSetAction extends Action {
    applicationFeeUuid: string;
    key: keyof IApplicationFee;
    value: boolean|number|string;
}

export function applicationFeeValueSetAction(applicationFeeUuid: string, key: keyof IApplicationFee, value: boolean|number|string): IApplicationFeeValueSetAction {
    return {
        applicationFeeUuid,
        key,
        type: ApplicationsActionsEnum.ApplicationFeeValueSet,
        value,
    };
}

export interface IApplicationFeesAddAction extends Action {
    applicationUuid: string;
    fee: IApplicationFee;
}

export function applicationFeesAddAction(applicationUuid: string, fee: IApplicationFee): IApplicationFeesAddAction {
    return {
        applicationUuid,
        fee,
        type: ApplicationsActionsEnum.ApplicationFeesAdd,
    };
}

export interface IApplicationFeesRemoveAction extends Action {
    applicationUuid: string;
}

export function applicationFeesRemoveAction(applicationUuid: string): IApplicationFeesRemoveAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationFeesRemove,
    };
}

export interface IApplicationFeesListAction extends Action {
    applicationUuid: string;
}

export function applicationFeesListAction(applicationUuid: string): IApplicationFeesListAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationFeesList,
    };
}

export interface IApplicationFeesSetAction extends Action {
    applicationUuid: string;
    fees: IApplicationFee[];
}

export function applicationFeesSetAction(applicationUuid: string, fees: IApplicationFee[]): IApplicationFeesSetAction {
    return {
        applicationUuid,
        fees,
        type: ApplicationsActionsEnum.ApplicationFeesSet,
    };
}

export interface IApplicationFormalApprovalAction extends Action {
    applicationUuid: string;
}

export function applicationFormalApprovalAction(applicationUuid: string): IApplicationFormalApprovalAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationFormalApproval,
    };
}

export interface IApplicationGetAction extends Action {
    applicationUuid: string;
}

export function applicationGetAction(applicationUuid: string): IApplicationGetAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationGet,
    };
}

export interface IApplicationLegalDocumentsAction extends Action {
    applicationUuid: string;
}

export function applicationLegalDocumentsAction(applicationUuid: string): IApplicationLegalDocumentsAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationLegalDocuments,
    };
}

export interface IApplicationNewAction extends Action {
    applicationUuid: string;
}

export function applicationNewAction(applicationUuid: string): IApplicationNewAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationNew,
    };
}

export interface IApplicationReopenAction extends Action {
    applicationUuid: string;
}

export function applicationReopenAction(applicationUuid: string): IApplicationReopenAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationReopen,
    };
}

export interface IApplicationRfiItemDeleteAction extends Action {
    applicationUuid: string;
    rfiItemUuid: string;
}

export function applicationRfiItemDeleteAction(applicationUuid: string, rfiItemUuid: string): IApplicationRfiItemDeleteAction {
    return {
        applicationUuid,
        rfiItemUuid,
        type: ApplicationsActionsEnum.ApplicationRfiItemDelete,
    };
}

export interface IApplicationRfiItemRemoveAction extends Action {
    applicationUuid: string;
    rfiItemUuid: string;
}

export function applicationRfiItemRemoveAction(applicationUuid: string, rfiItemUuid: string): IApplicationRfiItemRemoveAction {
    return {
        applicationUuid,
        rfiItemUuid,
        type: ApplicationsActionsEnum.ApplicationRfiItemRemove,
    };
}

export interface IApplicationRfiItemRequestedAction extends Action {
    rfiItemUuid: string;
}

export function applicationRfiItemRequestedAction(rfiItemUuid: string): IApplicationRfiItemRequestedAction {
    return {
        rfiItemUuid,
        type: ApplicationsActionsEnum.ApplicationRfiItemRequested,
    };
}

export interface IApplicationRfiItemSetAction extends Action {
    applicationUuid: string;
    rfiItem: IRfiItem;
}

export function applicationRfiItemSetAction(applicationUuid: string, rfiItem: IRfiItem): IApplicationRfiItemSetAction {
    return {
        applicationUuid,
        rfiItem,
        type: ApplicationsActionsEnum.ApplicationRfiItemSet,
    };
}

export interface IApplicationRfiItemSuppliedAction extends Action {
    rfiItemUuid: string;
}

export function applicationRfiItemSuppliedAction(rfiItemUuid: string): IApplicationRfiItemSuppliedAction {
    return {
        rfiItemUuid,
        type: ApplicationsActionsEnum.ApplicationRfiItemSupplied,
    };
}

export interface IApplicationRfiItemsAddAction extends Action {
    applicationBorrowerUuid: string;
    applicationConditionUuid: string;
    applicationPropertyUuid: string;
    applicationUuid: string;
    conditionType: ConditionTypeEnum;
}

export function applicationRfiItemsAddAction(
    applicationUuid: string,
    applicationBorrowerUuid: string,
    applicationConditionUuid: string,
    applicationPropertyUuid: string,
    conditionType: ConditionTypeEnum,
): IApplicationRfiItemsAddAction {
    return {
        applicationBorrowerUuid,
        applicationConditionUuid,
        applicationPropertyUuid,
        applicationUuid,
        conditionType,
        type: ApplicationsActionsEnum.ApplicationRfiItemsAdd,
    };
}

export interface IApplicationRfiItemsListAction extends Action {
    applicationUuid: string;
}

export function applicationRfiItemsListAction(applicationUuid: string): IApplicationRfiItemsListAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationRfiItemsList,
    };
}

export interface IApplicationRfiItemsSetAction extends Action {
    applicationUuid: string;
    rfiItems: IRfiItem[];
}

export function applicationRfiItemsSetAction(applicationUuid: string, rfiItems: IRfiItem[]): IApplicationRfiItemsSetAction {
    return {
        applicationUuid,
        rfiItems,
        type: ApplicationsActionsEnum.ApplicationRfiItemsSet,
    };
}

export interface IApplicationSendAction extends Action {
    applicationUuid: string;
}

export function applicationSendAction(applicationUuid: string): IApplicationSendAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationSend,
    };
}

export interface IApplicationSetAction extends Action {
    application: IApplication;
}

export function applicationSetAction(application: IApplication): IApplicationSetAction {
    return {
        application,
        type: ApplicationsActionsEnum.ApplicationSet,
    };
}

export interface IApplicationSettlementAction extends Action {
    applicationUuid: string;
}

export function applicationSettlementAction(applicationUuid: string): IApplicationSettlementAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationSettlement,
    };
}

export interface IApplicationSettlementDateAction extends Action {
    applicationUuid: string;
    settlementDate: string;
}

export function applicationSettlementDateAction(applicationUuid: string, settlementDate: string): IApplicationSettlementDateAction {
    return {
        applicationUuid,
        settlementDate,
        type: ApplicationsActionsEnum.ApplicationSettlementDate,
    };
}

export interface IApplicationSolicitorInstructionSentTimeAction extends Action {
    applicationUuid: string;
    solicitorInstructionSentTime: string;
}

export function applicationSolicitorInstructionsSentTimeAction(applicationUuid: string, solicitorInstructionSentTime: string): IApplicationSolicitorInstructionSentTimeAction {
    return {
        applicationUuid,
        solicitorInstructionSentTime,
        type: ApplicationsActionsEnum.ApplicationSolicitorInstructionSentTime,
    };
}

export interface IApplicationUnderwritingAction extends Action {
    applicationUuid: string;
}

export function applicationUnderwritingAction(applicationUuid: string): IApplicationUnderwritingAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationUnderwriting,
    };
}

export interface IApplicationValueSetAction extends Action {
    applicationUuid: string;
    key: keyof IApplication;
    value: boolean|number|string;
}

export function applicationValueSetAction(applicationUuid: string, key: keyof IApplication, value: boolean|number|string): IApplicationValueSetAction {
    return {
        applicationUuid,
        key,
        type: ApplicationsActionsEnum.ApplicationValueSet,
        value,
    };
}

export interface IApplicationWarehousedAction extends Action {
    applicationUuid: string;
    sendEmail: boolean;
    settlementDate: string;
}

export function applicationWarehousedAction(applicationUuid: string, settlementDate: string, sendEmail: boolean): IApplicationWarehousedAction {
    return {
        applicationUuid,
        sendEmail,
        settlementDate,
        type: ApplicationsActionsEnum.ApplicationWarehoused,
    };
}

export interface IApplicationBorrowersAddAction extends Action {
    applicationBorrower: IBorrower;
    applicationUuid: string;
    dealBorrower: IDealBorrower;
}

export function applicationBorrowersAddAction(applicationUuid: string, dealBorrower: IDealBorrower, applicationBorrower: IBorrower): IApplicationBorrowersAddAction {
    return {
        applicationBorrower,
        applicationUuid,
        dealBorrower,
        type: ApplicationsActionsEnum.ApplicationBorrowersAdd,
    };
}

export interface IApplicationBorrowersListAction extends Action {
    applicationUuid: string;
}

export function applicationBorrowersListAction(applicationUuid: string): IApplicationBorrowersListAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationBorrowersList,
    };
}

export interface IApplicationBorrowersSetAction extends Action {
    applicationUuid: string;
    borrowers: IBorrower[];
}

export function applicationBorrowersSetAction(applicationUuid: string, borrowers: IBorrower[]): IApplicationBorrowersSetAction {
    return {
        applicationUuid,
        borrowers,
        type: ApplicationsActionsEnum.ApplicationBorrowersSet,
    };
}

export interface IApplicationBorrowerBankruptcyCheckAction extends Action {
    applicationUuid: string;
    borrowerUuid: string;
    useMiddleName: boolean;
}

export function applicationBorrowerBankruptcyCheckAction(applicationUuid: string, borrowerUuid: string, useMiddleName: boolean): IApplicationBorrowerBankruptcyCheckAction {
    return {
        applicationUuid,
        borrowerUuid,
        type: ApplicationsActionsEnum.ApplicationBorrowerBankruptcyCheck,
        useMiddleName,
    };
}

export interface IApplicationBorrowerCompanyTradingHistoryCheckAction extends Action {
    applicationUuid: string;
    borrowerUuid: string;
}

export function applicationBorrowerCompanyTradingHistoryCheckAction(applicationUuid: string, borrowerUuid: string): IApplicationBorrowerCompanyTradingHistoryCheckAction {
    return {
        applicationUuid,
        borrowerUuid,
        type: ApplicationsActionsEnum.ApplicationBorrowerCompanyTradingHistoryCheck,
    };
}

export interface IApplicationBorrowerCreditCheckAction extends Action {
    applicationUuid: string;
    borrowerUuid: string;
}

export function applicationBorrowerCreditCheckAction(applicationUuid: string, borrowerUuid: string): IApplicationBorrowerCreditCheckAction {
    return {
        applicationUuid,
        borrowerUuid,
        type: ApplicationsActionsEnum.ApplicationBorrowerCreditCheck,
    };
}

export interface IApplicationBorrowerDeleteAction extends Action {
    applicationUuid: string;
    borrowerUuid: string;
}

export function applicationBorrowerDeleteAction(applicationUuid: string, borrowerUuid: string): IApplicationBorrowerDeleteAction {
    return {
        applicationUuid,
        borrowerUuid,
        type: ApplicationsActionsEnum.ApplicationBorrowerDelete,
    };
}

export interface IApplicationBorrowerGetAction extends Action {
    applicationUuid: string;
    borrowerUuid: string;
}

export function applicationBorrowerGetAction(applicationUuid: string, borrowerUuid: string): IApplicationBorrowerGetAction {
    return {
        applicationUuid,
        borrowerUuid,
        type: ApplicationsActionsEnum.ApplicationBorrowerGet,
    };
}

export interface IApplicationBorrowerRemoveAction extends Action {
    applicationUuid: string;
    borrowerUuid: string;
}

export function applicationBorrowerRemoveAction(applicationUuid: string, borrowerUuid: string): IApplicationBorrowerRemoveAction {
    return {
        applicationUuid,
        borrowerUuid,
        type: ApplicationsActionsEnum.ApplicationBorrowerRemove,
    };
}

export interface IApplicationBorrowerSendAction extends Action {
    applicationUuid: string;
    borrowerUuid: string;
}

export function applicationBorrowerSendAction(applicationUuid: string, borrowerUuid: string): IApplicationBorrowerSendAction {
    return {
        applicationUuid,
        borrowerUuid,
        type: ApplicationsActionsEnum.ApplicationBorrowerSend,
    };
}

export interface IApplicationBorrowerSetAction extends Action {
    applicationUuid: string;
    borrower: IBorrower;
}

export function applicationBorrowerSetAction(applicationUuid: string, borrower: IBorrower): IApplicationBorrowerSetAction {
    return {
        applicationUuid,
        borrower,
        type: ApplicationsActionsEnum.ApplicationBorrowerSet,
    };
}

export interface IApplicationBorrowerValueSetAction extends Action {
    applicationUuid: string;
    borrowerUuid: string;
    key: keyof IBorrower;
    value: boolean|number|string;
}

export function applicationBorrowerValueSetAction(applicationUuid: string, borrowerUuid: string, key: keyof IBorrower, value: boolean|number|string): IApplicationBorrowerValueSetAction {
    return {
        applicationUuid,
        borrowerUuid,
        key,
        type: ApplicationsActionsEnum.ApplicationBorrowerValueSet,
        value,
    };
}

export interface IApplicationDisbursementDeleteAction extends Action {
    disbursementUuid: string;
}

export function applicationDisbursementDeleteAction(disbursementUuid: string): IApplicationDisbursementDeleteAction {
    return {
        disbursementUuid,
        type: ApplicationsActionsEnum.ApplicationDisbursementDelete,
    };
}

export interface IApplicationDisbursementSendAction extends Action {
    disbursementUuid: string;
}

export function applicationDisbursementSendAction(disbursementUuid: string): IApplicationDisbursementSendAction {
    return {
        disbursementUuid,
        type: ApplicationsActionsEnum.ApplicationDisbursementSend,
    };
}

export interface IApplicationDisbursementSetAction extends Action {
    disbursement: IApplicationDisbursement;
}

export function applicationDisbursementSetAction(disbursement: IApplicationDisbursement): IApplicationDisbursementSetAction {
    return {
        disbursement,
        type: ApplicationsActionsEnum.ApplicationDisbursementSet,
    };
}

export interface IApplicationDisbursementValueSetAction extends Action {
    disbursementUuid: string;
    key: keyof IApplicationDisbursement;
    value: boolean|number|string;
}

export function applicationDisbursementValueSetAction(disbursementUuid: string, key: keyof IApplicationDisbursement, value: boolean|number|string): IApplicationDisbursementValueSetAction {
    return {
        disbursementUuid,
        key,
        type: ApplicationsActionsEnum.ApplicationDisbursementValueSet,
        value,
    };
}

export interface IApplicationDisbursementsAddAction extends Action {
    applicationUuid: string;
    disbursement: IApplicationDisbursement;
}

export function applicationDisbursementsAddAction(applicationUuid: string, disbursement: IApplicationDisbursement): IApplicationDisbursementsAddAction {
    return {
        applicationUuid,
        disbursement,
        type: ApplicationsActionsEnum.ApplicationDisbursementsAdd,
    };
}

export interface IApplicationDisbursementsListAction extends Action {
    applicationUuid: string;
}

export function applicationDisbursementsListAction(applicationUuid: string): IApplicationDisbursementsListAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationDisbursementsList,
    };
}

export interface IApplicationDisbursementsSetAction extends Action {
    applicationUuid: string;
    disbursements: IApplicationDisbursement[];
}

export function applicationDisbursementsSetAction(applicationUuid: string, disbursements: IApplicationDisbursement[]): IApplicationDisbursementsSetAction {
    return {
        applicationUuid,
        disbursements,
        type: ApplicationsActionsEnum.ApplicationDisbursementsSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IApplicationDisbursementSuggestionsListAction extends Action {}

export function applicationDisbursementSuggestionsListAction(): IApplicationDisbursementSuggestionsListAction {
    return {
        type: ApplicationsActionsEnum.ApplicationDisbursementSuggestionsList,
    };
}

export interface IApplicationDisbursementSuggestionsSetAction extends Action {
    descriptions: IDictionary<number>;
    payeeNames: IDictionary<number>;
}

export function applicationDisbursementSuggestionsSetAction(descriptions: IDictionary<number>, payeeNames: IDictionary<number>): IApplicationDisbursementSuggestionsSetAction {
    return {
        descriptions,
        payeeNames,
        type: ApplicationsActionsEnum.ApplicationDisbursementSuggestionsSet,
    };
}

export interface IApplicationGenerateMemoAction extends Action {
    applicationUuid: string;
}

export function applicationGenerateMemoAction(applicationUuid: string): IApplicationGenerateMemoAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationGenerateMemo,
    };
}

export interface IApplicationPropertiesListAction extends Action {
    applicationUuid: string;
}

export function applicationPropertiesListAction(applicationUuid: string): IApplicationPropertiesListAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationPropertiesList,
    };
}

export interface IApplicationPropertiesSetAction extends Action {
    applicationProperties: IApplicationProperty[];
    applicationUuid: string;
}

export function applicationPropertiesSetAction(applicationUuid: string, applicationProperties: IApplicationProperty[]): IApplicationPropertiesSetAction {
    return {
        applicationProperties,
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationPropertiesSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IApplicationPropertiesValuationsOutstandingListAction extends Action {
}

export function applicationPropertiesValuationsOutstandingListAction(): IApplicationPropertiesValuationsOutstandingListAction {
    return {
        type: ApplicationsActionsEnum.ApplicationPropertiesValuationsOutstandingList,
    };
}

export interface IApplicationPropertiesValuationsOutstandingSetAction extends Action {
    applicationProperties: IApplicationProperty[];
}

export function applicationPropertiesValuationsOutstandingSetAction(applicationProperties: IApplicationProperty[]): IApplicationPropertiesValuationsOutstandingSetAction {
    return {
        applicationProperties,
        type: ApplicationsActionsEnum.ApplicationPropertiesValuationsOutstandingSet,
    };
}

export interface IApplicationPropertyGetAction extends Action {
    applicationPropertyUuid: string;
}

export function applicationPropertyGetAction(applicationPropertyUuid: string): IApplicationPropertyGetAction {
    return {
        applicationPropertyUuid,
        type: ApplicationsActionsEnum.ApplicationPropertyGet,
    };
}

export interface IApplicationPropertySetAction extends Action {
    applicationProperty: IApplicationProperty;
}

export function applicationPropertySetAction(applicationProperty: IApplicationProperty): IApplicationPropertySetAction {
    return {
        applicationProperty,
        type: ApplicationsActionsEnum.ApplicationPropertySet,
    };
}

export interface IApplicationPropertyValuationValueAction extends Action {
    applicationPropertyUuid: string;
    valuationValue: number;
}

export function applicationPropertyValuationValueAction(applicationPropertyUuid: string, valuationValue: number): IApplicationPropertyValuationValueAction {
    return {
        applicationPropertyUuid,
        type: ApplicationsActionsEnum.ApplicationPropertyValuationValue,
        valuationValue,
    };
}

export interface IApplicationPropertyValuationDueDateAction extends Action {
    applicationPropertyUuid: string;
    valuationDueDate: string;
}

export function applicationPropertyValuationDueDateAction(applicationPropertyUuid: string, valuationDueDate: string): IApplicationPropertyValuationDueDateAction {
    return {
        applicationPropertyUuid,
        type: ApplicationsActionsEnum.ApplicationPropertyValuationDueDate,
        valuationDueDate,
    };
}

export interface IApplicationPropertyValuationInspectionDateAction extends Action {
    applicationPropertyUuid: string;
    valuationInspectionDate: string;
}

export function applicationPropertyValuationInspectionDateAction(applicationPropertyUuid: string, valuationInspectionDate: string): IApplicationPropertyValuationInspectionDateAction {
    return {
        applicationPropertyUuid,
        type: ApplicationsActionsEnum.ApplicationPropertyValuationInspectionDate,
        valuationInspectionDate,
    };
}

export interface IApplicationPropertyValuationReceivedDateAction extends Action {
    applicationPropertyUuid: string;
    valuationReceivedDate: string;
}

export function applicationPropertyValuationReceivedDateAction(applicationPropertyUuid: string, valuationReceivedDate: string): IApplicationPropertyValuationReceivedDateAction {
    return {
        applicationPropertyUuid,
        type: ApplicationsActionsEnum.ApplicationPropertyValuationReceivedDate,
        valuationReceivedDate,
    };
}

export interface IApplicationPropertyValuerQuoteAmountAction extends Action {
    applicationPropertyUuid: string;
    propertyValuerUuid: string;
    quoteAmount: number;
}

export function applicationPropertyValuerQuoteAmountAction(applicationPropertyUuid: string, propertyValuerUuid: string, quoteAmount: number): IApplicationPropertyValuerQuoteAmountAction {
    return {
        applicationPropertyUuid,
        propertyValuerUuid,
        quoteAmount,
        type: ApplicationsActionsEnum.ApplicationPropertyValuerQuoteAmount,
    };
}

export interface IApplicationPropertyValuerQuoteReceivedAction extends Action {
    applicationPropertyUuid: string;
    quoteAmount: number;
    quoteReceivedDateTime: string;
    quoteTimeFrameDays: number;
    valuerUuid: string;
}

export function applicationPropertyValuerQuoteReceivedAction(applicationPropertyUuid: string, valuerUuid: string, quoteAmount: number, quoteReceivedDateTime: string, quoteTimeFrameDays: number): IApplicationPropertyValuerQuoteReceivedAction {
    return {
        applicationPropertyUuid,
        quoteAmount,
        quoteReceivedDateTime,
        quoteTimeFrameDays,
        type: ApplicationsActionsEnum.ApplicationPropertyValuerQuoteReceived,
        valuerUuid,
    };
}

export interface IApplicationPropertyValuerQuoteRequestedAction extends Action {
    applicationPropertyUuid: string;
    quoteRequestedDateTime: string;
    valuerUuid: string;
}

export function applicationPropertyValuerQuoteRequestedAction(applicationPropertyUuid: string, valuerUuid: string, quoteRequestedDateTime: string): IApplicationPropertyValuerQuoteRequestedAction {
    return {
        applicationPropertyUuid,
        quoteRequestedDateTime,
        type: ApplicationsActionsEnum.ApplicationPropertyValuerQuoteRequested,
        valuerUuid,
    };
}

export interface IApplicationPropertyValuerQuoteTimeFrameAction extends Action {
    applicationPropertyUuid: string;
    propertyValuerUuid: string;
    quoteTimeFrameDays: number;
}

export function applicationPropertyValuerQuoteTimeFrameAction(applicationPropertyUuid: string, propertyValuerUuid: string, quoteTimeFrameDays: number): IApplicationPropertyValuerQuoteTimeFrameAction {
    return {
        applicationPropertyUuid,
        propertyValuerUuid,
        quoteTimeFrameDays,
        type: ApplicationsActionsEnum.ApplicationPropertyValuerQuoteTimeFrame,
    };
}

export interface IApplicationPropertyValuerSetAction extends Action {
    applicationPropertyUuid: string;
    valuer: IApplicationPropertyValuer;
}

export function applicationPropertyValuerSetAction(applicationPropertyUuid: string, valuer: IApplicationPropertyValuer): IApplicationPropertyValuerSetAction {
    return {
        applicationPropertyUuid,
        type: ApplicationsActionsEnum.ApplicationPropertyValuerSet,
        valuer,
    };
}

export interface IApplicationPropertyValuerValuationOrderedAction extends Action {
    applicationPropertyUuid: string;
    dueDate: string;
    valuationOrderedDateTime: string;
    valuerUuid: string;
}

export function applicationPropertyValuerValuationOrderedAction(applicationPropertyUuid: string, valuerUuid: string, dueDate: string, valuationOrderedDateTime: string): IApplicationPropertyValuerValuationOrderedAction {
    return {
        applicationPropertyUuid,
        dueDate,
        type: ApplicationsActionsEnum.ApplicationPropertyValuerValuationOrdered,
        valuationOrderedDateTime,
        valuerUuid,
    };
}

export interface IApplicationPropertyValuerValuationReceivedAction extends Action {
    applicationPropertyUuid: string;
    valuationInspectionDate: string;
    valuationReceivedDateTime: string;
    valuationValue: number;
    valuerUuid: string;
}

export function applicationPropertyValuerValuationReceivedAction(applicationPropertyUuid: string, valuerUuid: string, valuationInspectionDate: string, valuationReceivedDateTime: string, valuationValue: number): IApplicationPropertyValuerValuationReceivedAction {
    return {
        applicationPropertyUuid,
        type: ApplicationsActionsEnum.ApplicationPropertyValuerValuationReceived,
        valuationInspectionDate,
        valuationReceivedDateTime,
        valuationValue,
        valuerUuid,
    };
}

export interface IApplicationPropertyValuersListAction extends Action {
    applicationPropertyUuid: string;
}

export function applicationPropertyValuersListAction(applicationPropertyUuid: string): IApplicationPropertyValuersListAction {
    return {
        applicationPropertyUuid,
        type: ApplicationsActionsEnum.ApplicationPropertyValuersList,
    };
}

export interface IApplicationPropertyValuersSetAction extends Action {
    applicationPropertyUuid: string;
    valuers: IApplicationPropertyValuer[];
}

export function applicationPropertyValuersSetAction(applicationPropertyUuid: string, valuers: IApplicationPropertyValuer[]): IApplicationPropertyValuersSetAction {
    return {
        applicationPropertyUuid,
        type: ApplicationsActionsEnum.ApplicationPropertyValuersSet,
        valuers,
    };
}

export interface IApplicationPropertyValuerMarkPaidAction extends Action {
    applicationPropertyValuerUuid: string;
}

export function applicationPropertyValuerMarkPaidAction(applicationPropertyValuerUuid: string): IApplicationPropertyValuerMarkPaidAction {
    return {
        applicationPropertyValuerUuid,
        type: ApplicationsActionsEnum.ApplicationPropertyValuerMarkPaid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IApplicationPropertyValuersUnpaidListAction extends Action {}

export function applicationPropertyValuersUnpaidListAction(): IApplicationPropertyValuersUnpaidListAction {
    return {
        type: ApplicationsActionsEnum.ApplicationPropertyValuersUnpaidList,
    };
}

export interface IApplicationPropertyValuersUnpaidSetAction extends Action {
    valuers: IApplicationPropertyValuer[];
}

export function applicationPropertyValuersUnpaidSetAction(valuers: IApplicationPropertyValuer[]): IApplicationPropertyValuersUnpaidSetAction {
    return {
        type: ApplicationsActionsEnum.ApplicationPropertyValuersUnpaidSet,
        valuers,
    };
}

export interface IApplicationWarehouseDeleteAction extends Action {
    applicationWarehouseUuid: string;
}

export function applicationWarehouseDeleteAction(applicationWarehouseUuid: string): IApplicationWarehouseDeleteAction {
    return {
        applicationWarehouseUuid,
        type: ApplicationsActionsEnum.ApplicationWarehouseDelete,
    };
}

export interface IApplicationWarehouseSendAction extends Action {
    warehouseUuid: string;
}

export function applicationWarehouseSendAction(warehouseUuid: string): IApplicationWarehouseSendAction {
    return {
        type: ApplicationsActionsEnum.ApplicationWarehouseSend,
        warehouseUuid,
    };
}

export interface IApplicationWarehouseSetAction extends Action {
    warehouse: IApplicationWarehouse;
}

export function applicationWarehouseSetAction(warehouse: IApplicationWarehouse): IApplicationWarehouseSetAction {
    return {
        type: ApplicationsActionsEnum.ApplicationWarehouseSet,
        warehouse,
    };
}

export interface IApplicationWarehouseValueSetAction extends Action {
    warehouseUuid: string;
    key: keyof IApplicationWarehouse;
    value: boolean|number|string;
}

export function applicationWarehouseValueSetAction(warehouseUuid: string, key: keyof IApplicationWarehouse, value: boolean|number|string): IApplicationWarehouseValueSetAction {
    return {
        key,
        type: ApplicationsActionsEnum.ApplicationWarehouseValueSet,
        value,
        warehouseUuid,
    };
}

export interface IApplicationWarehousesAddAction extends Action {
    applicationUuid: string;
    warehouse: IApplicationWarehouse;
}

export function applicationWarehousesAddAction(applicationUuid: string, warehouse: IApplicationWarehouse): IApplicationWarehousesAddAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationWarehousesAdd,
        warehouse,
    };
}

export interface IApplicationWarehousesListAction extends Action {
    applicationUuid: string;
}

export function applicationWarehousesListAction(applicationUuid: string): IApplicationWarehousesListAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationWarehousesList,
    };
}

export interface IApplicationWarehousesSetAction extends Action {
    applicationUuid: string;
    warehouses: IApplicationWarehouse[];
}

export function applicationWarehousesSetAction(applicationUuid: string, warehouses: IApplicationWarehouse[]): IApplicationWarehousesSetAction {
    return {
        applicationUuid,
        type: ApplicationsActionsEnum.ApplicationWarehousesSet,
        warehouses,
    };
}
