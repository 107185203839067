import { Form, Modal, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IBroker from '~Api/Broker/IBroker';
import RejectReasonEnum from '~Api/Broker/RejectReasonEnum';
import { brokerRejectAction } from '~Brokers/actions';

interface IState {
    note: string;
    reason: RejectReasonEnum;
}

interface IProps {
    isOpen: boolean;
    broker: IBroker;
    onCancel: () => void;
}

interface IPropsDispatch {
    reject: (reason: RejectReasonEnum, note: string) => void;
}

type Props = IProps & IPropsDispatch;

class RejectModal extends React.Component<Props, IState> {
    public state: IState = {
        note: null,
        reason: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeReason = this.onChangeReason.bind(this);
        this.onChangeNote = this.onChangeNote.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { note } = this.state;
        const { reason } = this.state;

        return (
            <Modal
                okText='Reject'
                okType='danger'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Reject'
                wrapClassName='broker-reject-modal'
            >
                <Form.Item className='reason' label='Reason'>
                    <Select onChange={this.onChangeReason} value={reason}>
                        <Select.Option value={RejectReasonEnum.InsufficientDocumentation}>Insufficient Documentation</Select.Option>
                        <Select.Option value={RejectReasonEnum.PoorReputation}>Poor Reputation</Select.Option>
                        <Select.Option value={RejectReasonEnum.Other}>Other</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label='More Details' className='note'>
                    <TextArea onChange={this.onChangeNote} value={note} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeReason(value: RejectReasonEnum) {
        this.setState({
            reason: value,
        });
    }

    private onChangeNote(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            note: event.target.value,
        });
    }

    private onClickOk() {
        const { reason } = this.state;
        const { note } = this.state;

        this.props.onCancel();

        this.props.reject(reason, note);
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        reject: (reason: RejectReasonEnum, note: string) => dispatch(brokerRejectAction(ownProps.broker.uuid, reason, note)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(RejectModal);
