import _ from 'lodash';
import { AnyAction } from 'redux';
import IInvalidInvestorTransaction from '~Api/Investor/IInvalidInvestorTransaction';
import IInvestorAccountTransaction from '~Api/Investor/IInvestorAccountTransaction';
import IHistory from '~Api/WithdrawalAba/IHistory';
import IWithdrawalAba from '~Api/WithdrawalAba/IWithdrawalAba';
import { IDictionary } from '~utilities/IDictionary';
import {
    IWithdrawalAbaHistoriesSetAction,
    IWithdrawalAbaInvestorTransactionsSetAction,
    IWithdrawalAbaSetAction,
    IWithdrawalAbasInvalidInvestorTransactionsSetAction,
    IWithdrawalAbasSetAction,
    IWithdrawalAbasUnallocatedInvestorTransactionsSetAction,
} from '~WithdrawalAbas/actions';
import WithdrawalAbasActionsEnum from '~WithdrawalAbas/ActionsEnum';

export interface IWithdrawalAbasState {
    histories: IDictionary<IDictionary<IHistory>>;
    invalidInvestorTransactions: IDictionary<IDictionary<IInvalidInvestorTransaction>>;
    unallocatedInvestorTransactionUuids: IDictionary<string[]>;
    withdrawalAbaInvestorTransactionUuids: IDictionary<string[]>;
    withdrawalAbas: IDictionary<IWithdrawalAba>;
    withdrawalAbasListed: boolean;
}

const initialData: IWithdrawalAbasState = {
    histories: null,
    invalidInvestorTransactions: {},
    unallocatedInvestorTransactionUuids: {},
    withdrawalAbaInvestorTransactionUuids: null,
    withdrawalAbas: null,
    withdrawalAbasListed: false,
};

export function withdrawalAbasReducer(state: IWithdrawalAbasState = initialData, action: AnyAction): IWithdrawalAbasState {
    switch (action.type) {
        case WithdrawalAbasActionsEnum.WithdrawalAbaSet: {
            const typedAction: IWithdrawalAbaSetAction = action as IWithdrawalAbaSetAction;

            return {
                ...state,
                withdrawalAbas: {
                    ...state.withdrawalAbas,
                    [typedAction.withdrawalAba.uuid]: typedAction.withdrawalAba,
                },
            };
        }

        case WithdrawalAbasActionsEnum.WithdrawalAbasSet: {
            const typedAction: IWithdrawalAbasSetAction = action as IWithdrawalAbasSetAction;

            return {
                ...state,
                withdrawalAbas: _.keyBy(typedAction.withdrawalAbas, 'uuid'),
                withdrawalAbasListed: true,
            };
        }

        case WithdrawalAbasActionsEnum.WithdrawalAbaHistoriesSet: {
            const typedAction: IWithdrawalAbaHistoriesSetAction = action as IWithdrawalAbaHistoriesSetAction;

            return {
                ...state,
                histories: {
                    ...state.histories,
                    [typedAction.withdrawalAbaUuid]: _.keyBy(typedAction.histories, 'uuid'),
                },
            };
        }

        case WithdrawalAbasActionsEnum.WithdrawalAbaInvestorTransactionsSet: {
            const typedAction: IWithdrawalAbaInvestorTransactionsSetAction = action as IWithdrawalAbaInvestorTransactionsSetAction;

            const withdrawalAbaInvestorTransactionUuids: IDictionary<string[]> = { ...state.withdrawalAbaInvestorTransactionUuids };

            withdrawalAbaInvestorTransactionUuids[typedAction.withdrawalAbaUuid] = [];

            _.each(typedAction.investorAccountTransactions, (investorAccountTransaction: IInvestorAccountTransaction) => {
                withdrawalAbaInvestorTransactionUuids[typedAction.withdrawalAbaUuid].push(investorAccountTransaction.uuid);
            });

            return {
                ...state,
                withdrawalAbaInvestorTransactionUuids,
            };
        }

        case WithdrawalAbasActionsEnum.WithdrawalAbasUnallocatedInvestorTransactionsSet: {
            const typedAction: IWithdrawalAbasUnallocatedInvestorTransactionsSetAction = action as IWithdrawalAbasUnallocatedInvestorTransactionsSetAction;

            return {
                ...state,
                unallocatedInvestorTransactionUuids: {
                    ...state.unallocatedInvestorTransactionUuids,
                    [typedAction.accountType]: _.map(typedAction.unallocatedInvestorTransactions, (unallocatedInvestorTransaction: IInvestorAccountTransaction) => unallocatedInvestorTransaction.uuid),
                },
            };
        }

        case WithdrawalAbasActionsEnum.WithdrawalAbasInvalidInvestorTransactionsSet: {
            const typedAction: IWithdrawalAbasInvalidInvestorTransactionsSetAction = action as IWithdrawalAbasInvalidInvestorTransactionsSetAction;

            const invalidInvestorTransactions: IDictionary<IInvalidInvestorTransaction> = {};

            _.each(typedAction.invalidInvestorTransactions, (invalidInvestorTransaction: IInvalidInvestorTransaction) => {
                invalidInvestorTransactions[invalidInvestorTransaction.uuid] = _.omit(invalidInvestorTransaction, ['investorAccount', 'investor']);
            });

            return {
                ...state,
                invalidInvestorTransactions: {
                    ...state.invalidInvestorTransactions,
                    [typedAction.accountType]: invalidInvestorTransactions,
                },
            };
        }

        default: {
            return state;
        }
    }
}
