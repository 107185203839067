import { Action } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IBroker from '~Api/Broker/IBroker';
import IDocument from '~Api/Broker/IDocument';
import IHistory from '~Api/Broker/IHistory';
import INote from '~Api/Broker/INote';
import RejectReasonEnum from '~Api/Broker/RejectReasonEnum';
import IDeal from '~Api/Deal/IDeal';
import BrokerDocumentTypeEnum from '../Api/Broker/BrokerDocumentTypeEnum';
import BrokersActionsEnum from './ActionsEnum';

export interface IBrokersAddAction extends Action {
    broker: IBroker;
}

export function brokersAddAction(broker: IBroker): IBrokersAddAction {
    return {
        broker,
        type: BrokersActionsEnum.BrokersAdd,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBrokersListAction extends Action {}

export function brokersListAction(): IBrokersListAction {
    return {
        type: BrokersActionsEnum.BrokersList,
    };
}

export interface IBrokersSetAction extends Action {
    brokers: IBroker[];
}

export function brokersSetAction(brokers: IBroker[]): IBrokersSetAction {
    return {
        brokers,
        type: BrokersActionsEnum.BrokersSet,
    };
}

export interface IBrokersSearchAction extends Action {
    keyword: string;
}

export function brokersSearchAction(keyword: string): IBrokersSearchAction {
    return {
        keyword,
        type: BrokersActionsEnum.BrokersSearch,
    };
}

export interface IBrokersSearchResultsSetAction extends Action {
    brokers: IBroker[];
}

export function brokersSearchResultsSetAction(brokers: IBroker[]): IBrokersSearchResultsSetAction {
    return {
        brokers,
        type: BrokersActionsEnum.BrokersSearchResultsSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBrokersSearchResultsClearAction extends Action {}

export function brokersSearchResultsClearAction(): IBrokersSearchResultsClearAction {
    return {
        type: BrokersActionsEnum.BrokersSearchResultsClear,
    };
}

export interface IBrokerApplicationsListAction extends Action {
    brokerUuid: string;
}

export function brokerApplicationsListAction(brokerUuid: string): IBrokerApplicationsListAction {
    return {
        brokerUuid,
        type: BrokersActionsEnum.BrokerApplicationsList,
    };
}

export interface IBrokerApplicationsSetAction extends Action {
    applications: IApplication[];
    brokerUuid: string;
}

export function brokerApplicationsSetAction(brokerUuid: string, applications: IApplication[]): IBrokerApplicationsSetAction {
    return {
        applications,
        brokerUuid,
        type: BrokersActionsEnum.BrokerApplicationsSet,
    };
}

export interface IBrokerContactDateAction extends Action {
    date: string;
    brokerUuid: string;
}

export function brokerContactDateAction(brokerUuid: string, date: string): IBrokerContactDateAction {
    return {
        brokerUuid,
        date,
        type: BrokersActionsEnum.BrokerContactDate,
    };
}

export interface IBrokerDocumentsListAction extends Action {
    brokerUuid: string;
}

export function brokerDocumentsListAction(brokerUuid: string): IBrokerDocumentsListAction {
    return {
        brokerUuid,
        type: BrokersActionsEnum.BrokerDocumentsList,
    };
}

export interface IBrokerDocumentsSetAction extends Action {
    brokerUuid: string;
    documents: IDocument[];
}

export function brokerDocumentsSetAction(brokerUuid: string, documents: IDocument[]): IBrokerDocumentsSetAction {
    return {
        brokerUuid,
        documents,
        type: BrokersActionsEnum.BrokerDocumentsSet,
    };
}

export interface IBrokerDocumentSetAction extends Action {
    brokerUuid: string;
    document: IDocument;
}

export function brokerDocumentSetAction(brokerUuid: string, document: IDocument): IBrokerDocumentSetAction {
    return {
        brokerUuid,
        document,
        type: BrokersActionsEnum.BrokerDocumentSet,
    };
}

export interface IBrokerDocumentUploadAction extends Action {
    brokerUuid: string;
    document: File;
    documentType: BrokerDocumentTypeEnum;
}

export function brokerDocumentUploadAction(brokerUuid: string, document: File, documentType: BrokerDocumentTypeEnum): IBrokerDocumentUploadAction {
    return {
        brokerUuid,
        document,
        documentType,
        type: BrokersActionsEnum.BrokerDocumentUpload,
    };
}

export interface IBrokerHistoriesListAction extends Action {
    brokerUuid: string;
}

export function brokerHistoriesListAction(brokerUuid: string): IBrokerHistoriesListAction {
    return {
        brokerUuid,
        type: BrokersActionsEnum.BrokerHistoriesList,
    };
}

export interface IBrokerHistoriesSetAction extends Action {
    brokerUuid: string;
    histories: IHistory[];
}

export function brokerHistoriesSetAction(brokerUuid: string, histories: IHistory[]): IBrokerHistoriesSetAction {
    return {
        brokerUuid,
        histories,
        type: BrokersActionsEnum.BrokerHistoriesSet,
    };
}

export interface IBrokerDealsListAction extends Action {
    brokerUuid: string;
}

export function brokerDealsListAction(brokerUuid: string): IBrokerDealsListAction {
    return {
        brokerUuid,
        type: BrokersActionsEnum.BrokerDealsList,
    };
}

export interface IBrokerDealsSetAction extends Action {
    brokerUuid: string;
    deals: IDeal[];
}

export function brokerDealsSetAction(brokerUuid: string, deals: IDeal[]): IBrokerDealsSetAction {
    return {
        brokerUuid,
        deals,
        type: BrokersActionsEnum.BrokerDealsSet,
    };
}

export interface IBrokerNotesAddAction extends Action {
    brokerUuid: string;
    note: string;
}

export function brokerNotesAddAction(brokerUuid: string, note: string): IBrokerNotesAddAction {
    return {
        brokerUuid,
        note,
        type: BrokersActionsEnum.BrokerNotesAdd,
    };
}

export interface IBrokerNotesListAction extends Action {
    brokerUuid: string;
}

export function brokerNotesListAction(brokerUuid: string): IBrokerNotesListAction {
    return {
        brokerUuid,
        type: BrokersActionsEnum.BrokerNotesList,
    };
}

export interface IBrokerNotesSetAction extends Action {
    brokerUuid: string;
    notes: INote[];
}

export function brokerNotesSetAction(brokerUuid: string, notes: INote[]): IBrokerNotesSetAction {
    return {
        brokerUuid,
        notes,
        type: BrokersActionsEnum.BrokerNotesSet,
    };
}

export interface IBrokerNoteRemoveAction extends Action {
    brokerUuid: string;
    noteUuid: string;
}

export function brokerNoteRemoveAction(brokerUuid: string, noteUuid: string): IBrokerNoteRemoveAction {
    return {
        brokerUuid,
        noteUuid,
        type: BrokersActionsEnum.BrokerNoteRemove,
    };
}

export interface IBrokerNoteSetAction extends Action {
    brokerUuid: string;
    note: INote;
}

export function brokerNoteSetAction(brokerUuid: string, note: INote): IBrokerNoteSetAction {
    return {
        brokerUuid,
        note,
        type: BrokersActionsEnum.BrokerNoteSet,
    };
}

export interface IBrokerApproveAction extends Action {
    brokerUuid: string;
    sendEmail: boolean;
}

export function brokerApproveAction(brokerUuid: string, sendEmail: boolean): IBrokerApproveAction {
    return {
        brokerUuid,
        sendEmail,
        type: BrokersActionsEnum.BrokerApprove,
    };
}

export interface IBrokerAssignBdmAction extends Action {
    brokerUuid: string;
    administratorUuid: string;
}

export function brokerAssignBdmAction(brokerUuid: string, administratorUuid: string): IBrokerAssignBdmAction {
    return {
        administratorUuid,
        brokerUuid,
        type: BrokersActionsEnum.BrokerAssignBdm,
    };
}

export interface IBrokerGetAction extends Action {
    brokerUuid: string;
}

export function brokerGetAction(brokerUuid: string): IBrokerGetAction {
    return {
        brokerUuid,
        type: BrokersActionsEnum.BrokerGet,
    };
}

export interface IBrokerRecordContactAction extends Action {
    brokerUuid: string;
    note: string;
}

export function brokerRecordContactAction(brokerUuid: string, note: string): IBrokerRecordContactAction {
    return {
        brokerUuid,
        note,
        type: BrokersActionsEnum.BrokerRecordContact,
    };
}

export interface IBrokerRejectAction extends Action {
    brokerUuid: string;
    note: string;
    reason: RejectReasonEnum;
}

export function brokerRejectAction(brokerUuid: string, reason: RejectReasonEnum, note: string): IBrokerRejectAction {
    return {
        brokerUuid,
        note,
        reason,
        type: BrokersActionsEnum.BrokerReject,
    };
}

export interface IBrokerResetApprovalStatusAction extends Action {
    brokerUuid: string;
}

export function brokerResetApprovalStatusAction(brokerUuid: string): IBrokerResetApprovalStatusAction {
    return {
        brokerUuid,
        type: BrokersActionsEnum.BrokerResetApprovalStatus,
    };
}

export interface IBrokerSendAction extends Action {
    brokerUuid: string;
}

export function brokerSendAction(brokerUuid: string): IBrokerSendAction {
    return {
        brokerUuid,
        type: BrokersActionsEnum.BrokerSend,
    };
}

export interface IBrokerSetAction extends Action {
    broker: IBroker;
}

export function brokerSetAction(broker: IBroker): IBrokerSetAction {
    return {
        broker,
        type: BrokersActionsEnum.BrokerSet,
    };
}

export interface IBrokerUnassignBdmAction extends Action {
    brokerUuid: string;
}

export function brokerUnassignBdmAction(brokerUuid: string): IBrokerUnassignBdmAction {
    return {
        brokerUuid,
        type: BrokersActionsEnum.BrokerUnassignBdm,
    };
}

export interface IBrokerValueSetAction extends Action {
    brokerUuid: string;
    key: keyof IBroker;
    value: any;
}

export function brokerValueSetAction(brokerUuid: string, key: keyof IBroker, value: any): IBrokerValueSetAction {
    return {
        brokerUuid,
        key,
        type: BrokersActionsEnum.BrokerValueSet,
        value,
    };
}
