import { UnorderedListOutlined } from '@ant-design/icons';
import { Button, Modal, Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import ILoanTransaction from '~Api/Loan/ILoanTransaction';
import { loansListAction } from '~Loans/actions';
import { loansSelector } from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { loanPaymentsListAction } from './actions';
import Layout from './Layout';
import LoanTransactionModal from './LoanTransactionModal';
import { loanPaymentsSelector } from './selectors';

const historyDays: number = 30;

interface IState {
    modalIsOpen: boolean;
    modalUuid: string;
}

interface IPaymentToInvestor {
    date: string;
    transaction?: ILoanTransaction;
    uuid: string;
}

interface IDay {
    dailyInterest: number;
    dailyPrincipal: number;
    date: string;
    investmentTransactionCount: number;
    primaryRowUuid?: string;
}

interface IPropsSelector {
    loanPayments: IDictionary<ILoanTransaction>;
    loans: IDictionary<ILoan>;
}

interface IPropsDispatch {
    loanPaymentsList: () => void;
    loansList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class LoanPayments extends React.Component<Props, IState> {
    public state: IState = {
        modalIsOpen: false,
        modalUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickWarehouses = this.onClickWarehouses.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    public componentDidMount(): void {
        const { loans } = this.props;

        if (!loans) {
            this.props.loansList();
        }

        this.props.loanPaymentsList();
    }

    public render(): JSX.Element {
        const { loanPayments, loans } = this.props;
        const { modalIsOpen, modalUuid } = this.state;

        if (!loanPayments || !loans) {
            return (
                <Layout section='loan-payments'>
                    <Typography.Title level={2}>Loan Payments</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const days: IDictionary<IDay> = {};
        const paymentsToInvestors: IPaymentToInvestor[] = [];

        for (let i: number = 0; i <= historyDays; i++) {
            const loopDate: string = dayjs().subtract(i, 'days').format('YYYY-MM-DD');
            days[loopDate] = {
                dailyInterest: 0,
                dailyPrincipal: 0,
                date: loopDate,
                investmentTransactionCount: 0,
            };
            paymentsToInvestors.push({
                date: loopDate,
                uuid: loopDate,
            });
        }

        _.each(loanPayments, (transaction: ILoanTransaction) => {
            const transactionTimeDaysjs: Dayjs = dayjs(transaction.transactionTime);
            const transactionDate: string = transactionTimeDaysjs.format('YYYY-MM-DD');

            const day: IDay = days[transactionDate];
            if (!day.primaryRowUuid) {
                day.primaryRowUuid = transaction.uuid;

                const initialPaymentToInvestor: IPaymentToInvestor = _.find(paymentsToInvestors, (paymentToInvestor: IPaymentToInvestor) => paymentToInvestor.date === transactionDate);
                initialPaymentToInvestor.transaction = transaction;
                initialPaymentToInvestor.uuid = transaction.uuid;
            } else {
                paymentsToInvestors.push({
                    date: transactionDate,
                    transaction,
                    uuid: transaction.uuid,
                });
            }

            day.investmentTransactionCount++;
            day.dailyInterest += transaction.amountInterest;
            day.dailyPrincipal += transaction.amountPrincipal;
        });

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnsType<IPaymentToInvestor> = [
            {
                className: 'date',
                render: (paymentToInvestor: IPaymentToInvestor) => {
                    if (!paymentToInvestor.transaction) {
                        return dayjs(paymentToInvestor.date).format('D/M');
                    }

                    const day: IDay = days[paymentToInvestor.date];
                    return {
                        children: dayjs(paymentToInvestor.date).format('D/M'),
                        props: {
                            // When the rowSpan of the cell is set to zero, it essentially hides the cell.
                            rowSpan: (day.primaryRowUuid === paymentToInvestor.transaction.uuid) ? day.investmentTransactionCount : 0,
                        },
                    };
                },
                title: 'Date',
                width: '5%',
            },
            {
                className: 'day',
                render: (paymentToInvestor: IPaymentToInvestor) => {
                    if (!paymentToInvestor.transaction) {
                        return dayjs(paymentToInvestor.date).format('dddd');
                    }

                    const day: IDay = days[paymentToInvestor.date];
                    return {
                        children: dayjs(paymentToInvestor.date).format('dddd'),
                        props: {
                            rowSpan: (day.primaryRowUuid === paymentToInvestor.transaction.uuid) ? day.investmentTransactionCount : 0,
                        },
                    };
                },
                title: 'Day',
                width: '10%',
            },
            {
                className: 'daily-principal',
                render: (paymentToInvestor: IPaymentToInvestor) => {
                    if (!paymentToInvestor.transaction) {
                        return '-';
                    }

                    const day: IDay = days[paymentToInvestor.date];
                    return {
                        children: day.dailyPrincipal !== 0 ? currencyFormatter.format(day.dailyPrincipal) : '-',
                        props: {
                            rowSpan: (day.primaryRowUuid === paymentToInvestor.transaction.uuid) ? day.investmentTransactionCount : 0,
                        },
                    };
                },
                title: 'Daily Principal',
                width: '15%',
            },
            {
                className: 'daily-interest',
                render: (paymentToInvestor: IPaymentToInvestor) => {
                    if (!paymentToInvestor.transaction) {
                        return '-';
                    }

                    const day: IDay = days[paymentToInvestor.date];
                    return {
                        children: day.dailyInterest !== 0 ? currencyFormatter.format(day.dailyInterest) : '-',
                        props: {
                            rowSpan: (day.primaryRowUuid === paymentToInvestor.transaction.uuid) ? day.investmentTransactionCount : 0,
                        },
                    };
                },
                title: 'Daily Interest',
                width: '15%',
            },
            {
                dataIndex: 'transaction',
                render: (transaction: ILoanTransaction) => {
                    if (!transaction) {
                        return '-';
                    }

                    return (
                        <>
                            <Link to={`/loans/${transaction.loanUuid}`}>{loans[transaction.loanUuid].code}</Link>
                            {loans[transaction.loanUuid].salesforceCode && loans[transaction.loanUuid].salesforceCode !== loans[transaction.loanUuid].code && ` (${loans[transaction.loanUuid].salesforceCode})`}
                        </>
                    );
                },
                title: 'Loan',
            },
            {
                dataIndex: 'transaction',
                render: (transaction: ILoanTransaction) => transaction && transaction.amountPrincipal !== 0 ? currencyFormatter.format(transaction.amountPrincipal) : '-',
                title: 'Principal',
                width: '15%',
            },
            {
                dataIndex: 'transaction',
                render: (transaction: ILoanTransaction) => transaction && transaction.amountInterest !== 0 ? currencyFormatter.format(transaction.amountInterest) : '-',
                title: 'Interest',
                width: '15%',
            },
            {
                dataIndex: 'transaction',
                render: (transaction: ILoanTransaction) => {
                    if (!transaction) {
                        return '';
                    }

                    const onClick: () => void = () => this.onClickWarehouses(transaction.uuid);

                    const modalBlock: JSX.Element = modalUuid === transaction.uuid && (
                        <LoanTransactionModal
                            isOpen={modalIsOpen}
                            loanTransactionUuid={transaction.uuid}
                            onCancel={this.onCloseModal}
                        />
                    );

                    return (
                        <>
                            <Button icon={<UnorderedListOutlined/>} onClick={onClick} />
                            {modalBlock}
                        </>
                    );
                },
                width: '2%',
            },
        ];

        return (
            <Layout section='loan-payments'>
                <Typography.Title level={2}>Loan Payments</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(_.orderBy(paymentsToInvestors, ['date'], ['desc']))}
                    rowKey='uuid'
                    pagination={false}
                    size='middle'
                />
            </Layout>
        );
    }

    private onClickWarehouses(transactionUuid: string): void {
        this.setState({
            modalIsOpen: true,
            modalUuid: transactionUuid,
        });
    }

    private onCloseModal(): void {
        this.setState({
            modalIsOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        loanPayments: loanPaymentsSelector(state),
        loans: loansSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        loanPaymentsList: () => dispatch(loanPaymentsListAction()),
        loansList: () => dispatch(loansListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoanPayments);
