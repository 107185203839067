import React from 'react';
import {
    Route,
    Switch,
} from 'react-router-dom';
import Notes from '~ReferralPartners/ReferralPartner/Notes';
import Applications from './Applications';
import Deals from './Deals';
import Documents from './Documents';
import Edit from './Edit';
import History from './History';
import Overview from './Overview';

export default function router(): JSX.Element {
    return (
        <Switch>
            <Route exact={true} path='/referral-partners/:uuid' component={Overview} />
            <Route exact={true} path='/referral-partners/:uuid/applications' component={Applications} />
            <Route exact={true} path='/referral-partners/:uuid/documents' component={Documents} />
            <Route exact={true} path='/referral-partners/:uuid/edit' component={Edit} />
            <Route exact={true} path='/referral-partners/:uuid/history' component={History} />
            <Route exact={true} path='/referral-partners/:uuid/leads' component={Deals} />
            <Route exact={true} path='/referral-partners/:uuid/notes' component={Notes} />
        </Switch>
    );
}
