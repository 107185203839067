import ConditionTypeEnum from '~Api/Application/ConditionTypeEnum';
import INote from '~Api/Application/INote';
import IApplicationWarehouse from '~Api/Application/IApplicationWarehouse';
import ApplicationFeeFormatEnum from '~Api/Deal/ApplicationFeeFormatEnum';
import BrokerageFeeFormatEnum from '~Api/Deal/BrokerageFeeFormatEnum';
import EstablishmentFeeFormatEnum from '~Api/Deal/EstablishmentFeeFormatEnum';
import IDealBorrower from '~Api/Deal/IDealBorrower';
import { transformDealBorrower } from '~Api/Deal/transformers';
import { fetchDelete, fetchGet, fetchPost, fetchPut } from '~utilities/fetch';
import CloseReasonEnum from './CloseReasonEnum';
import IApplication from './IApplication';
import IApplicationDisbursement from './IApplicationDisbursement';
import IApplicationFee from './IApplicationFee';
import IBorrower from './IBorrower';
import IConditionalCondition from './IConditionalCondition';
import {
    transformApplication,
    transformApplicationBorrower,
    transformApplicationCondition,
    transformApplicationConditionDocument,
    transformApplicationConditionalCondition,
    transformApplicationDisbursement,
    transformApplicationFee,
    transformApplicationNote,
    transformApplicationWarehouse,
} from './transformers';
import ICondition from './ICondition';
import IConditionDocument from './IConditionDocument';

export function applicationsCreateRenewalFromLoanRequest(
    applicationFee: number,
    applicationFeeFormat: ApplicationFeeFormatEnum,
    applicationFeePercentage: number,
    brokerageFee: number,
    brokerageFeeFormat: BrokerageFeeFormatEnum,
    brokerageFeePercentage: number,
    establishmentFee: number,
    establishmentFeeFormat: EstablishmentFeeFormatEnum,
    establishmentFeePercentage: number,
    estimatedOutlays: number,
    interestRate: number,
    legalFees: number,
    loanAmount: number,
    loanPayoutFigureUuid: string,
    lvr: number,
    maximumLvr: number,
    termMonths: number,
): Promise<Response> {
    return fetchPost('/applications/create-renewal-from-loan', {
        applicationFee,
        applicationFeeFormat,
        applicationFeePercentage,
        brokerageFee,
        brokerageFeeFormat,
        brokerageFeePercentage,
        establishmentFee,
        establishmentFeeFormat,
        establishmentFeePercentage,
        estimatedOutlays,
        interestRate,
        legalFees,
        loanAmount,
        loanPayoutFigureUuid,
        lvr,
        maximumLvr,
        termMonths,
    });
}

export async function applicationsDashboardRequest(): Promise<Response> {
    return fetchGet('/applications/dashboard');
}

export async function applicationAssignRequest(applicationUuid: string, administratorUuid: string): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/assign`, {
        administratorUuid,
    });
}

export async function applicationsListRequest(): Promise<Response> {
    return fetchGet('/applications');
}

export async function applicationsSettlementForecastRequest(): Promise<Response> {
    return fetchGet('/applications/settlement-forecast');
}

export async function applicationsSettlementReportRequest(startDate: string, endDate: string): Promise<Response> {
    return fetchGet('/applications/settlement-report', {
        endDate,
        startDate,
    });
}

export async function applicationsSearchRequest(keyword: string): Promise<Response> {
    return fetchPost('/applications/search', { keyword });
}

export async function applicationBorrowersListRequest(applicationUuid: string): Promise<Response> {
    return fetchGet(`/applications/${applicationUuid}/borrowers`);
}

export async function applicationBorrowersAddRequest(applicationUuid: string, dealBorrower: IDealBorrower, applicationBorrower: IBorrower): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/borrowers`, {
        ...transformDealBorrower(dealBorrower),
        ...transformApplicationBorrower(applicationBorrower),
    });
}

export async function applicationBorrowersDeleteRequest(borrowerUuid: string): Promise<Response> {
    return fetchDelete(`/application-borrowers/${borrowerUuid}`);
}

export async function applicationBorrowersUpdateRequest(applicationBorrower: IBorrower): Promise<Response> {
    return fetchPut(`/application-borrowers/${applicationBorrower.uuid}`, {
        ...transformDealBorrower(applicationBorrower.dealBorrower),
        ...transformApplicationBorrower(applicationBorrower),
    });
}

export async function applicationBorrowerBankruptcyCheckRequest(borrowerUuid: string, useMiddleName: boolean): Promise<Response> {
    return fetchPost(`/application-borrowers/${borrowerUuid}/bankruptcy-check`, {
        useMiddleName,
    });
}

export async function applicationBorrowerCompanyTradingHistoryCheckRequest(borrowerUuid: string): Promise<Response> {
    return fetchPost(`/application-borrowers/${borrowerUuid}/company-trading-history-check`);
}

export async function applicationBorrowerCreditCheckRequest(borrowerUuid: string): Promise<Response> {
    return fetchPost(`/application-borrowers/${borrowerUuid}/credit-check`);
}

export async function applicationAssignPayoutFigureRequest(applicationUuid: string, loanPayoutFigureUuid: string): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/assign-payout-figure`, { loanPayoutFigureUuid });
}

export async function applicationCloseRequest(applicationUuid: string, reason: CloseReasonEnum, note: string): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/close`, {
        note,
        reason,
    });
}

export async function applicationConditionDeleteRequest(conditionUuid: string): Promise<Response> {
    return fetchDelete(`/application-conditions/${conditionUuid}`);
}

export async function applicationConditionsAddRequest(applicationUuid: string, condition: ICondition): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/conditions`, transformApplicationCondition(condition));
}

export async function applicationConditionsListRequest(applicationUuid: string): Promise<Response> {
    return fetchGet(`/applications/${applicationUuid}/conditions`);
}

export async function applicationConditionDocumentsAddRequest(applicationUuid: string, conditionDocument: IConditionDocument): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/condition-documents`, transformApplicationConditionDocument(conditionDocument));
}

export async function applicationConditionDocumentApproveRequest(conditionDocumentUuid: string): Promise<Response> {
    return fetchPost(`/application-condition-documents/${conditionDocumentUuid}/approve`);
}

export async function applicationConditionDocumentDeleteRequest(conditionDocumentUuid: string): Promise<Response> {
    return fetchDelete(`/application-condition-documents/${conditionDocumentUuid}`);
}

export async function applicationConditionDocumentsSolicitorAccessUpdateRequest(applicationUuid: string, dealConditionDocumentUuids: string[]): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/condition-documents-solicitor-access`, { dealConditionDocumentUuids });
}

export async function applicationConditionDocumentRejectRequest(conditionDocumentUuid: string): Promise<Response> {
    return fetchPost(`/application-condition-documents/${conditionDocumentUuid}/reject`);
}

export async function applicationConditionDocumentResetApprovalStatusRequest(conditionDocumentUuid: string): Promise<Response> {
    return fetchPost(`/application-condition-documents/${conditionDocumentUuid}/reset-approval-status`);
}

export async function applicationConditionDocumentsListRequest(applicationUuid: string): Promise<Response> {
    return fetchGet(`/applications/${applicationUuid}/condition-documents`);
}

export async function applicationConditionalsAddRequest(applicationUuid: string, conditions: string[]): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/conditionals`, {
        conditions,
    });
}

export async function applicationConditionalsListRequest(applicationUuid: string): Promise<Response> {
    return fetchGet(`/applications/${applicationUuid}/conditionals`);
}

export async function applicationConditionalApproveRequest(conditionalUuid: string): Promise<Response> {
    return fetchPost(`/application-conditionals/${conditionalUuid}/approve`);
}

export async function applicationConditionalDeleteRequest(conditionalUuid: string): Promise<Response> {
    return fetchDelete(`/application-conditionals/${conditionalUuid}/delete`);
}

export async function applicationConditionalGetRequest(conditionalUuid: string): Promise<Response> {
    return fetchGet(`/application-conditionals/${conditionalUuid}`);
}

export async function applicationConditionalSendRequest(conditionalUuid: string, email?: string, firstName?: string, lastName?: string): Promise<Response> {
    return fetchPost(`/application-conditionals/${conditionalUuid}/send`, !!email ? {
        email,
        firstName,
        lastName,
    } : null);
}

export async function applicationConditionalConditionAddRequest(conditionalUuid: string, condition: IConditionalCondition): Promise<Response> {
    return fetchPost(`/application-conditionals/${conditionalUuid}/condition`, transformApplicationConditionalCondition(condition));
}

export async function applicationConditionalConditionDeleteRequest(conditionUuid: string): Promise<Response> {
    return fetchDelete(`/application-conditional-conditions/${conditionUuid}/delete`);
}

export async function applicationConditionalsStandardConditionsListRequest(): Promise<Response> {
    return fetchGet(`/application-conditionals/standard-conditions`);
}

export async function applicationConditionalApprovalRequest(applicationUuid: string): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/conditional-approval`);
}

export async function applicationDisbursementsAddRequest(applicationUuid: string, disbursement: IApplicationDisbursement): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/disbursements`, transformApplicationDisbursement(disbursement));
}

export async function applicationDisbursementsListRequest(applicationUuid: string): Promise<Response> {
    return fetchGet(`/applications/${applicationUuid}/disbursements`);
}

export async function applicationDisbursementDeleteRequest(applicationDisbursementUuid: string): Promise<Response> {
    return fetchDelete(`/application-disbursements/${applicationDisbursementUuid}`);
}

export async function applicationDisbursementUpdateRequest(applicationDisbursement: IApplicationDisbursement): Promise<Response> {
    return fetchPut(`/application-disbursements/${applicationDisbursement.uuid}`, transformApplicationDisbursement(applicationDisbursement));
}

export async function applicationDisbursementSuggestionsListRequest(): Promise<Response> {
    return fetchGet('/application-disbursements/suggestions');
}

export async function applicationFeesAddRequest(applicationUuid: string, fee: IApplicationFee): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/fees`, transformApplicationFee(fee));
}

export async function applicationFeesListRequest(applicationUuid: string): Promise<Response> {
    return fetchGet(`/applications/${applicationUuid}/fees`);
}

export async function applicationFeeDeleteRequest(applicationFeeUuid: string): Promise<Response> {
    return fetchDelete(`/application-fees/${applicationFeeUuid}`);
}

export async function applicationFeeUpdateRequest(fee: IApplicationFee): Promise<Response> {
    return fetchPut(`/application-fees/${fee.uuid}`, transformApplicationFee(fee));
}

export async function applicationFormalsListRequest(applicationUuid: string): Promise<Response> {
    return fetchGet(`/applications/${applicationUuid}/formals`);
}

export async function applicationFormalAddRequest(applicationUuid: string): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/formals`);
}

export async function applicationFormalApprovalRequest(applicationUuid: string): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/formal-approval`);
}

export async function applicationHistoriesListRequest(applicationUuid: string): Promise<Response> {
    return fetchGet(`/applications/${applicationUuid}/history`);
}

export async function applicationNotesAddRequest(applicationUuid: string, note: INote): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/notes`, transformApplicationNote(note));
}

export async function applicationNotesListRequest(applicationUuid: string): Promise<Response> {
    return fetchGet(`/applications/${applicationUuid}/notes`);
}

export async function applicationGetRequest(applicationUuid: string): Promise<Response> {
    return fetchGet(`/applications/${applicationUuid}`);
}

export async function applicationLegalDocumentsRequest(applicationUuid: string): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/legal-documents`);
}

export async function applicationGenerateMemoRequest(applicationUuid: string): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/generate-memo`);
}

export async function applicationNewRequest(applicationUuid: string): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/new`);
}

export async function applicationsOutstandingBrokerCommissionsListRequest(): Promise<Response> {
    return fetchGet('/applications/outstanding-broker-commissions');
}

export async function applicationMarkBrokerCommissionPaidRequest(applicationUuid: string): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/mark-broker-commission-paid`);
}

export async function applicationReopenRequest(applicationUuid: string): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/reopen`);
}

export async function applicationPropertiesListRequest(applicationUuid: string): Promise<Response> {
    return fetchGet(`/application/${applicationUuid}/properties`);
}

export async function applicationPropertiesValuationsOutstandingListRequest(): Promise<Response> {
    return fetchGet('/application-properties/valuations-outstanding');
}

export async function applicationPropertyGetRequest(applicationPropertyUuid: string): Promise<Response> {
    return fetchGet(`/application-properties/${applicationPropertyUuid}`);
}

export async function applicationPropertyValuationDueDateRequest(applicationPropertyUuid: string, valuationDueDate: string): Promise<Response> {
    return fetchPost(`/application-properties/${applicationPropertyUuid}/valuation-due-date`, {
        valuationDueDate,
    });
}

export async function applicationPropertyValuationInspectionDateRequest(applicationPropertyUuid: string, valuationInspectionDate: string): Promise<Response> {
    return fetchPost(`/application-properties/${applicationPropertyUuid}/valuation-inspection-date`, {
        valuationInspectionDate,
    });
}

export async function applicationPropertyValuationReceivedDateRequest(applicationPropertyUuid: string, valuationReceivedDate: string): Promise<Response> {
    return fetchPost(`/application-properties/${applicationPropertyUuid}/valuation-received-date`, {
        valuationReceivedDate,
    });
}

export async function applicationPropertyValuationValueRequest(applicationPropertyUuid: string, valuationValue: number): Promise<Response> {
    return fetchPost(`/application-properties/${applicationPropertyUuid}/valuation-value`, {
        valuationValue,
    });
}

export async function applicationPropertyValuersAddRequest(applicationPropertyUuid: string, valuerUuid: string): Promise<Response> {
    return fetchPost(`/application-properties/${applicationPropertyUuid}/valuers`, {
        valuerUuid,
    });
}

export async function applicationPropertyValuersListRequest(applicationPropertyUuid: string): Promise<Response> {
    return fetchGet(`/application-properties/${applicationPropertyUuid}/valuers`);
}

export async function applicationPropertyValuersUnpaidRequest(): Promise<Response> {
    return fetchGet('/application-property-valuers/unpaid');
}

export async function applicationPropertyValuerMarkPaidRequest(applicationPropertyValuerUuid: string): Promise<Response> {
    return fetchPost(`/application-property-valuers/${applicationPropertyValuerUuid}/mark-paid`);
}

export async function applicationPropertyValuerQuoteReceivedRequest(applicationPropertyValuerUuid: string, quoteAmount: number, quoteReceivedDate: string, quoteTimeFrameDays: number): Promise<Response> {
    return fetchPost(`/application-property-valuers/${applicationPropertyValuerUuid}/quote-received`, {
        quoteAmount,
        quoteReceivedDate,
        quoteTimeFrameDays,
    });
}

export async function applicationPropertyValuerQuoteRequestedRequest(applicationPropertyValuerUuid: string, quoteRequestedDate: string): Promise<Response> {
    return fetchPost(`/application-property-valuers/${applicationPropertyValuerUuid}/quote-requested`, {
        quoteRequestedDate,
    });
}

export async function applicationPropertyValuerValuationOrderedRequest(applicationPropertyValuerUuid: string, dueDate: string, valuationOrderedDateTime: string): Promise<Response> {
    return fetchPost(`/application-property-valuers/${applicationPropertyValuerUuid}/valuation-ordered`, {
        dueDate,
        valuationOrderedDateTime,
    });
}

export async function applicationPropertyValuerValuationReceivedRequest(applicationPropertyValuerUuid: string, valuationInspectionDate: string, valuationReceivedDateTime: string, valuationValue: number): Promise<Response> {
    return fetchPost(`/application-property-valuers/${applicationPropertyValuerUuid}/valuation-received`, {
        valuationInspectionDate,
        valuationReceivedDateTime,
        valuationValue,
    });
}

export async function applicationPropertyValuerQuoteAmountRequest(applicationPropertyValuerUuid: string, quoteAmount: number): Promise<Response> {
    return fetchPost(`/application-property-valuers/${applicationPropertyValuerUuid}/quote-amount`, {
        quoteAmount,
    });
}

export async function applicationPropertyValuerQuoteTimeFrameRequest(applicationPropertyValuerUuid: string, quoteTimeFrame: number): Promise<Response> {
    return fetchPost(`/application-property-valuers/${applicationPropertyValuerUuid}/quote-time-frame`, {
        quoteTimeFrame,
    });
}

export async function applicationRfiItemDeleteRequest(applicationRfiItemUuid: string): Promise<Response> {
    return fetchDelete(`/application-rfi-items/${applicationRfiItemUuid}`);
}

export async function applicationRfiItemRequestedRequest(applicationRfiItemUuid: string): Promise<Response> {
    return fetchPost(`/application-rfi-items/${applicationRfiItemUuid}/requested`);
}

export async function applicationRfiItemSuppliedRequest(applicationRfiItemUuid: string): Promise<Response> {
    return fetchPost(`/application-rfi-items/${applicationRfiItemUuid}/supplied`);
}

export async function applicationRfiItemsAddRequest(
    applicationUuid: string,
    applicationBorrowerUuid: string,
    applicationConditionUuid: string,
    applicationPropertyUuid: string,
    conditionType: ConditionTypeEnum,
): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/rfi-items`, {
        applicationBorrowerUuid,
        applicationConditionUuid,
        applicationPropertyUuid,
        conditionType,
    });
}

export async function applicationRfiItemsListRequest(applicationUuid: string): Promise<Response> {
    return fetchGet(`/applications/${applicationUuid}/rfi-items`);
}

export async function applicationSettlementRequest(applicationUuid: string): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/settlement`);
}

export async function applicationSettlementDateRequest(applicationUuid: string, settlementDate: string): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/settlement-date`, {
        settlementDate,
    });
}

export async function applicationSolicitorInstructionsSentTimeRequest(applicationUuid: string, solicitorInstructionsSentTime: string): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/solicitor-instructions-sent-time`, {
        solicitorInstructionsSentTime,
    });
}

export async function applicationUnderwritingRequest(applicationUuid: string): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/underwriting`);
}

export async function applicationUpdateRequest(application: IApplication): Promise<Response> {
    return fetchPut(`/applications/${application.uuid}`, transformApplication(application));
}

export async function applicationWarehousedRequest(applicationUuid: string, settlementDate: string, sendEmail: boolean): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/warehoused`, {
        sendEmail,
        settlementDate,
    });
}

export async function applicationWarehousesAddRequest(applicationUuid: string, warehouse: IApplicationWarehouse): Promise<Response> {
    return fetchPost(`/applications/${applicationUuid}/warehouses`, transformApplicationWarehouse(warehouse));
}

export async function applicationWarehousesListRequest(applicationUuid: string): Promise<Response> {
    return fetchGet(`/applications/${applicationUuid}/warehouses`);
}

export async function applicationWarehouseDeleteRequest(applicationWarehouseUuid: string): Promise<Response> {
    return fetchDelete(`/application-warehouses/${applicationWarehouseUuid}`);
}

export async function applicationWarehouseUpdateRequest(applicationWarehouse: IApplicationWarehouse): Promise<Response> {
    return fetchPost(`/application-warehouses/${applicationWarehouse.uuid}`, transformApplicationWarehouse(applicationWarehouse));
}
