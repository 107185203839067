import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Alert, Button, Space, Spin, Table, Tag, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import BorrowerCategoryEnum from '~Api/Application/BorrowerCategoryEnum';
import BorrowerTypeEnum from '~Api/Application/BorrowerTypeEnum';
import IApplication from '~Api/Application/IApplication';
import IBorrower from '~Api/Application/IBorrower';
import {
    applicationBorrowersListAction,
    applicationGetAction,
} from '~Applications/actions';
import {
    applicationBorrowersSelector,
    applicationSelector,
} from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import BorrowerAddModal from './BorrowerAddModal';
import Layout from './Layout';

const categoryLabels: any = {
    [BorrowerCategoryEnum.Borrower]: 'Borrower',
    [BorrowerCategoryEnum.Guarantor]: 'Guarantor',
    [BorrowerCategoryEnum.Trustee]: 'Trustee',
};

const typeLabels: any = {
    [BorrowerTypeEnum.Company]: 'Company',
    [BorrowerTypeEnum.Individual]: 'Individual',
    [BorrowerTypeEnum.Trust]: 'Trust',
};

interface IState {
    isBorrowerModalOpen: boolean;
}

interface IMatch {
    applicationUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    application: IApplication;
    borrowers: IBorrower[];
}

interface IPropsDispatch {
    applicationGet: () => void;
    borrowersList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Borrowers extends React.Component<Props, IState> {
    public state: IState = {
        isBorrowerModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAddBorrower = this.onClickAddBorrower.bind(this);
        this.onCloseAddBorrower = this.onCloseAddBorrower.bind(this);
    }

    public componentDidMount() {
        const { application, borrowers } = this.props;

        if (!application) {
            this.props.applicationGet();
        }

        if (!borrowers) {
            this.props.borrowersList();
        }
    }

    public render(): JSX.Element {
        const { application, borrowers, match } = this.props;
        const { isBorrowerModalOpen } = this.state;

        if (!application || !borrowers) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='borrowers'>
                    <Spin/>
                </Layout>
            );
        }

        const columns = [
            {
                render: (borrower: IBorrower) => (
                    <Space>
                        <Link to={`/applications/${application.uuid}/borrowers/${borrower.uuid}`}>{borrower.dealBorrower.formattedName || '-'}</Link>
                        {application.primaryBorrowerUuid === borrower.uuid && <Tag>Primary</Tag>}
                    </Space>
                ),
                title: 'Name',
            },
            {
                render: (borrower: IBorrower) => <a href={`tel:${borrower.dealBorrower.phone}`}>{borrower.dealBorrower.phone}</a>,
                title: 'Phone',
                width: '15%',
            },
            {
                render: (borrower: IBorrower) => <a href={`mailto:${borrower.dealBorrower.email}`}>{borrower.dealBorrower.email}</a>,
                title: 'Email',
                width: '20%',
            },
            {
                render: (borrower: IBorrower) => categoryLabels[borrower.dealBorrower.category],
                title: 'Category',
                width: '10%',
            },
            {
                render: (borrower: IBorrower) => typeLabels[borrower.dealBorrower.type],
                title: 'Type',
                width: '10%',
            },
        ];

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='borrowers'>
                <Button className='add-borrower' onClick={this.onClickAddBorrower}>Add Borrower</Button>
                <Typography.Title level={2}>Borrowers</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={borrowers}
                    pagination={false}
                    rowKey='uuid'
                />
                <BorrowerAddModal
                    application={application}
                    isOpen={isBorrowerModalOpen}
                    onCancel={this.onCloseAddBorrower}
                />
            </Layout>
        );
    }

    private onClickAddBorrower() {
        this.setState({
            isBorrowerModalOpen: true,
        });
    }

    private onCloseAddBorrower() {
        this.setState({
            isBorrowerModalOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
        borrowers: applicationBorrowersSelector(state, ownProps.match.params.applicationUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
        borrowersList: () => dispatch(applicationBorrowersListAction(ownProps.match.params.applicationUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Borrowers);
