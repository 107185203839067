import _ from 'lodash';
import { AnyAction } from 'redux';
import BorrowerTypeEnum from '~Api/Application/BorrowerTypeEnum';
import IApplication from '~Api/Application/IApplication';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IBorrower from '~Api/Application/IBorrower';
import IDeal from '~Api/Deal/IDeal';
import IDealBorrower from '~Api/Deal/IDealBorrower';
import IPostcodeCategory from '~Api/Deal/IPostcodeCategory';
import IProperty from '~Api/Deal/IProperty';
import ISpPostcodeCategory from '~Api/Deal/ISpPostcodeCategory';
import {
    getFormattedAddress,
    getStreetAddress,
} from '~Api/Deal/parsers';
import {
    IApplicationBorrowerSetAction,
    IApplicationBorrowersSetAction,
    IApplicationConditionDocumentsSetAction,
    IApplicationPropertiesValuationsOutstandingSetAction,
    IApplicationSetAction,
    IApplicationsDashboardSetAction,
    IApplicationsOutstandingBrokerCommissionsSetAction,
    IApplicationsSearchResultsSetAction,
    IApplicationsSetAction,
    IApplicationsSettlementForecastSetAction,
} from '~Applications/actions';
import ApplicationsActionsEnum from '~Applications/ActionsEnum';
import {
    IBrokerApplicationsSetAction,
    IBrokerDealsSetAction,
} from '~Brokers/actions';
import BrokersActionsEnum from '~Brokers/ActionsEnum';
import {
    ILeadSetAction,
    ILeadsBoardSetAction,
    ILeadsSetAction,
} from '~Leads/actions';
import LeadsActionsEnum from '~Leads/ActionsEnum';
import {
    IReferralPartnerApplicationsSetAction,
    IReferralPartnerDealsSetAction,
} from '~ReferralPartners/actions';
import ReferralPartnersActionsEnum from '~ReferralPartners/ActionsEnum';
import { IDictionary } from '~utilities/IDictionary';
import {
    IDealBorrowerValueSetAction,
    IDealDocumentSetAction,
    IDealDocumentsSetAction,
    IDealHistoriesSetAction,
    IDealNoteRemoveAction,
    IDealNoteSetAction,
    IDealNotesSetAction,
    IDealPropertiesPostcodeCategoriesSetAction,
    IDealPropertiesSetAction,
    IDealPropertiesSpPostcodeCategoriesSetAction,
    IDealPropertyRemoveAction,
    IDealPropertySetAction,
    IDealPropertyValueSetAction,
} from './actions';
import DealsActionsEnum from './ActionsEnum';
import IApplicationWarehouse from '~Api/Application/IApplicationWarehouse';
import IConditionDocument from '~Api/Application/IConditionDocument';
import IHistory from '~Api/Deal/IHistory';
import IDocument from '~Api/Deal/IDocument';
import INote from '~Api/Deal/INote';
import ILoan from '~Api/Loan/ILoan';
import LoansActionsEnum from '~Loans/ActionsEnum';
import {
    ILoanSetAction,
    ILoansDrawdownsSetAction,
    ILoansPaginatedSetAction,
    ILoansSearchResultsSetAction,
    ILoansSetAction,
} from '~Loans/actions';
import WarehousesActionsEnum from '~Warehouses/ActionsEnum';
import {
    IWarehouseEligibleLoansSetAction,
    IWarehouseLoansSetAction,
    IWarehousePendingApplicationsSetAction,
} from '~Warehouses/actions';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import IWarehouseEligibleLoan from '~Api/Warehouse/IWarehouseEligibleLoan';

export interface IDealsState {
    borrowers: IDictionary<IDealBorrower>;
    dealDocumentUuids: IDictionary<string[]>;
    dealPropertyUuids: IDictionary<string[]>;
    documents: IDictionary<IDocument>;
    histories: IDictionary<IDictionary<IHistory>>;
    notes: IDictionary<IDictionary<INote>>;
    postcodeCategories: IDictionary<IPostcodeCategory>;
    properties: IDictionary<IProperty>;
    spPostcodeCategories: IDictionary<ISpPostcodeCategory>;
}

const initialData: IDealsState = {
    borrowers: {},
    dealDocumentUuids: {},
    dealPropertyUuids: {},
    documents: {},
    histories: {},
    notes: {},
    postcodeCategories: null,
    properties: {},
    spPostcodeCategories: null,
};

function statifyApplication(state: IDealsState, application: IApplication): IDealsState {
    const borrowers: IDictionary<IDealBorrower> = { ...state.borrowers };
    const dealPropertyUuids: IDictionary<string[]> = { ...state.dealPropertyUuids };
    const properties: IDictionary<IProperty> = { ...state.properties };

    application.borrowers.forEach((borrower: IBorrower) => {
        borrowers[borrower.dealBorrower.uuid] = borrower.dealBorrower;
    });

    if (!dealPropertyUuids[application.dealUuid]) {
        dealPropertyUuids[application.dealUuid] = [];
    }
    application.properties.forEach((applicationProperty: IApplicationProperty) => {
        properties[applicationProperty.dealPropertyUuid] = applicationProperty.dealProperty;
        dealPropertyUuids[application.dealUuid].push(applicationProperty.dealPropertyUuid);
    });

    dealPropertyUuids[application.dealUuid] = _.uniq(dealPropertyUuids[application.dealUuid]);

    return {
        ...state,
        borrowers,
        dealPropertyUuids,
        properties,
    };
}

function statifyApplications(state: IDealsState, applications: IApplication[]): IDealsState {
    let clonedState: IDealsState = { ...state };

    applications.forEach((application: IApplication) => {
        clonedState = statifyApplication(clonedState, application);
    });

    return clonedState;
}

function statifyDeal(state: IDealsState, deal: IDeal): IDealsState {
    const dealPropertyUuids: IDictionary<string[]> = { ...state.dealPropertyUuids };
    const properties: IDictionary<IProperty> = { ...state.properties };

    if (!dealPropertyUuids[deal.uuid]) {
        dealPropertyUuids[deal.uuid] = [];
    }

    deal.properties.forEach((property: IProperty) => {
        dealPropertyUuids[deal.uuid].push(property.uuid);
        properties[property.uuid] = property;
    });

    dealPropertyUuids[deal.uuid] = _.uniq(dealPropertyUuids[deal.uuid]);

    return {
        ...state,
        dealPropertyUuids,
        properties,
    };
}

function statifyDeals(state: IDealsState, deals: IDeal[]): IDealsState {
    let clonedState: IDealsState = { ...state };

    deals.forEach((deal: IDeal) => {
        clonedState = statifyDeal(clonedState, deal);
    });

    return clonedState;
}

function statifyLoan(state: IDealsState, loan: ILoan): IDealsState {
    let clonedState: IDealsState = { ...state };

    if (loan.extensionApplication) {
        clonedState = statifyApplication(clonedState, loan.extensionApplication);
    }

    if (loan.application) {
        clonedState = statifyApplication(clonedState, loan.application);
    }

    return clonedState;
}

function statifyLoans(state: IDealsState, loans: ILoan[]): IDealsState {
    let clonedState: IDealsState = { ...state };

    _.each(loans, (loan: ILoan) => {
        clonedState = statifyLoan(clonedState, loan);
    });

    return clonedState;
}

export function dealsReducer(state: IDealsState = initialData, action: AnyAction): IDealsState {
    switch (action.type) {

        case ApplicationsActionsEnum.ApplicationBorrowersSet: {
            const typedAction: IApplicationBorrowersSetAction = action as IApplicationBorrowersSetAction;

            const borrowers: IDictionary<IDealBorrower> = { ...state.borrowers };

            typedAction.borrowers.forEach((borrower: IBorrower) => {
                borrowers[borrower.dealBorrower.uuid] = borrower.dealBorrower;
            });

            return {
                ...state,
                borrowers,
            };
        }

        case ApplicationsActionsEnum.ApplicationBorrowerSet: {
            const typedAction: IApplicationBorrowerSetAction = action as IApplicationBorrowerSetAction;

            return {
                ...state,
                borrowers: {
                    ...state.borrowers,
                    [typedAction.borrower.dealBorrower.uuid]: typedAction.borrower.dealBorrower,
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationPropertiesValuationsOutstandingSet: {
            const typedAction: IApplicationPropertiesValuationsOutstandingSetAction = action as IApplicationPropertiesValuationsOutstandingSetAction;

            const applications: IApplication[] = _.map(typedAction.applicationProperties, (applicationProperty: IApplicationProperty) => applicationProperty.application);

            return {
                ...state,
                ...statifyApplications(state, applications),
            };
        }

        case ApplicationsActionsEnum.ApplicationsDashboardSet: {
            const typedAction: IApplicationsDashboardSetAction = action as IApplicationsDashboardSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case ApplicationsActionsEnum.ApplicationsSearchResultsSet: {
            const typedAction: IApplicationsSearchResultsSetAction = action as IApplicationsSearchResultsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case ApplicationsActionsEnum.ApplicationsSet: {
            const typedAction: IApplicationsSetAction = action as IApplicationsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case ApplicationsActionsEnum.ApplicationsSettlementForecastSet: {
            const typedAction: IApplicationsSettlementForecastSetAction = action as IApplicationsSettlementForecastSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case ApplicationsActionsEnum.ApplicationSet: {
            const typedAction: IApplicationSetAction = action as IApplicationSetAction;

            return {
                ...state,
                ...statifyApplication(state, typedAction.application),
            };
        }

        case ApplicationsActionsEnum.ApplicationsOutstandingBrokerCommissionsSet: {
            const typedAction: IApplicationsOutstandingBrokerCommissionsSetAction = action as IApplicationsOutstandingBrokerCommissionsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case BrokersActionsEnum.BrokerApplicationsSet: {
            const typedAction: IBrokerApplicationsSetAction = action as IBrokerApplicationsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case BrokersActionsEnum.BrokerDealsSet: {
            const typedAction: IBrokerDealsSetAction = action as IBrokerDealsSetAction;

            return {
                ...state,
                ...statifyDeals(state, typedAction.deals),
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerDealsSet: {
            const typedAction: IReferralPartnerDealsSetAction = action as IReferralPartnerDealsSetAction;

            return {
                ...state,
                ...statifyDeals(state, typedAction.deals),
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerApplicationsSet: {
            const typedAction: IReferralPartnerApplicationsSetAction = action as IReferralPartnerApplicationsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case LeadsActionsEnum.LeadsBoardSet: {
            const typedAction: ILeadsBoardSetAction = action as ILeadsBoardSetAction;

            return {
                ...state,
                ...statifyDeals(state, typedAction.deals),
            };
        }

        case LeadsActionsEnum.LeadsSearchResultsSet: {
            const typedAction: ILeadsSetAction = action as ILeadsSetAction;

            return {
                ...state,
                ...statifyDeals(state, typedAction.deals),
            };
        }

        case LeadsActionsEnum.LeadSet: {
            const typedAction: ILeadSetAction = action as ILeadSetAction;

            return {
                ...state,
                ...statifyDeal(state, typedAction.deal),
            };
        }

        case LeadsActionsEnum.LeadsSet: {
            const typedAction: ILeadsSetAction = action as ILeadsSetAction;

            return {
                ...state,
                ...statifyDeals(state, typedAction.deals),
            };
        }

        case DealsActionsEnum.DealBorrowerValueSet: {
            const typedAction: IDealBorrowerValueSetAction = action as IDealBorrowerValueSetAction;

            const borrower: IDealBorrower = {
                ...state.borrowers[typedAction.dealBorrowerUuid],
                [typedAction.key]: typedAction.value,
            };

            return {
                ...state,
                borrowers: {
                    ...state.borrowers,
                    [typedAction.dealBorrowerUuid]: {
                        ...state.borrowers[typedAction.dealBorrowerUuid],
                        formattedName: borrower.type === BorrowerTypeEnum.Individual ? `${borrower.firstName} ${borrower.lastName}` : (borrower.type === BorrowerTypeEnum.Company ? borrower.businessName : borrower.trustName),
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        case DealsActionsEnum.DealDocumentsSet: {
            const typedAction: IDealDocumentsSetAction = action as IDealDocumentsSetAction;

            const dealDocumentUuids: string[] = [];
            const documents: IDictionary<IDocument> = {};

            _.each(typedAction.documents, (document: IDocument) => {
                dealDocumentUuids.push(document.uuid);
                documents[document.uuid] = document;
            });

            return {
                ...state,
                dealDocumentUuids: {
                    ...state.dealDocumentUuids,
                    [typedAction.dealUuid]: dealDocumentUuids,
                },
                documents: {
                    ...state.documents,
                    ...documents,
                },
            };
        }

        case DealsActionsEnum.DealDocumentSet: {
            const typedAction: IDealDocumentSetAction = action as IDealDocumentSetAction;

            return {
                ...state,
                documents: {
                    ...state.documents,
                    [typedAction.document.uuid]: typedAction.document,
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationConditionDocumentsSet: {
            const typedAction: IApplicationConditionDocumentsSetAction = action as IApplicationConditionDocumentsSetAction;

            const documents: IDictionary<IDocument> = {};

            _.each(typedAction.conditionDocuments, (conditionDocument: IConditionDocument) => {
                documents[conditionDocument.document.uuid] = conditionDocument.document;
            });

            return {
                ...state,
                documents: {
                    ...state.documents,
                    ...documents,
                },
            };
        }

        case DealsActionsEnum.DealHistoriesSet: {
            const typedAction: IDealHistoriesSetAction = action as IDealHistoriesSetAction;

            return {
                ...state,
                histories: {
                    ...state.histories,
                    [typedAction.dealUuid]: _.keyBy(typedAction.histories, 'uuid'),
                },
            };
        }

        case DealsActionsEnum.DealNotesSet: {
            const typedAction: IDealNotesSetAction = action as IDealNotesSetAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.dealUuid]: _.keyBy(typedAction.notes, 'uuid'),
                },
            };
        }

        case DealsActionsEnum.DealNoteRemove: {
            const typedAction: IDealNoteRemoveAction = action as IDealNoteRemoveAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.dealUuid]: _.omit(state.notes[typedAction.dealUuid] || {}, typedAction.noteUuid),
                },
            };
        }

        case DealsActionsEnum.DealNoteSet: {
            const typedAction: IDealNoteSetAction = action as IDealNoteSetAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.dealUuid]: {
                        ...(state.notes[typedAction.dealUuid] || {}),
                        [typedAction.note.uuid || 'new']: typedAction.note,
                    },
                },
            };
        }

        case DealsActionsEnum.DealPropertiesPostcodeCategoriesSet: {
            const typedAction: IDealPropertiesPostcodeCategoriesSetAction = action as IDealPropertiesPostcodeCategoriesSetAction;

            const postcodeCategories: IDictionary<IPostcodeCategory> = {};
            typedAction.postcodeCategories.forEach((category: IPostcodeCategory) => {
                postcodeCategories[category.postcode] = category;
            });

            return {
                ...state,
                postcodeCategories,
            };
        }

        case DealsActionsEnum.DealPropertiesSpPostcodeCategoriesSet: {
            const typedAction: IDealPropertiesSpPostcodeCategoriesSetAction = action as IDealPropertiesSpPostcodeCategoriesSetAction;

            return {
                ...state,
                spPostcodeCategories: _.keyBy(typedAction.spPostcodeCategories, 'postcode'),
            };
        }

        case DealsActionsEnum.DealPropertiesSet: {
            const typedAction: IDealPropertiesSetAction = action as IDealPropertiesSetAction;

            const dealPropertyUuids: string[] = [];
            const properties: IDictionary<IProperty> = { ...state.properties };

            typedAction.properties.forEach((property: IProperty) => {
                properties[property.uuid] = property;
                dealPropertyUuids.push(property.uuid);
            });

            return {
                ...state,
                dealPropertyUuids: {
                    ...state.dealPropertyUuids,
                    [typedAction.dealUuid]: dealPropertyUuids,
                },
                properties,
            };
        }

        case DealsActionsEnum.DealPropertyRemove: {
            const typedAction: IDealPropertyRemoveAction = action as IDealPropertyRemoveAction;

            return {
                ...state,
                dealPropertyUuids: {
                    ...state.dealPropertyUuids,
                    [typedAction.dealUuid]: _.filter(state.dealPropertyUuids[typedAction.dealUuid], (uuid: string) => uuid !== typedAction.propertyUuid),
                },
                properties: _.omit(state.properties || {}, typedAction.propertyUuid),
            };
        }

        case DealsActionsEnum.DealPropertySet: {
            const typedAction: IDealPropertySetAction = action as IDealPropertySetAction;

            return {
                ...state,
                dealPropertyUuids: {
                    ...state.dealPropertyUuids,
                    [typedAction.dealUuid]: _.uniq([
                        ...state.dealPropertyUuids[typedAction.dealUuid] || [],
                        typedAction.property.uuid,
                    ]),
                },
                properties: {
                    ...state.properties,
                    [typedAction.property.uuid]: typedAction.property,
                },
            };
        }

        case DealsActionsEnum.DealPropertyValueSet: {
            const typedAction: IDealPropertyValueSetAction = action as IDealPropertyValueSetAction;

            const property: IProperty = {
                ...state.properties[typedAction.propertyUuid],
                [typedAction.key]: typedAction.value,
            };

            return {
                ...state,
                properties: {
                    ...state.properties,
                    [typedAction.propertyUuid]: {
                        ...property,
                        formattedAddress: getFormattedAddress(property),
                        streetAddress: getStreetAddress(property),
                    },
                },
            };
        }

        case LoansActionsEnum.LoanSet: {
            const typedAction: ILoanSetAction = action as ILoanSetAction;

            return {
                ...state,
                ...statifyLoan(state, typedAction.loan),
            };
        }

        case LoansActionsEnum.LoansSearchResultsSet: {
            const typedAction: ILoansSearchResultsSetAction = action as ILoansSearchResultsSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        case LoansActionsEnum.LoansSet: {
            const typedAction: ILoansSetAction = action as ILoansSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        case LoansActionsEnum.LoansDrawdownsSet: {
            const typedAction: ILoansDrawdownsSetAction = action as ILoansDrawdownsSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        case LoansActionsEnum.LoansPaginatedSet: {
            const typedAction: ILoansPaginatedSetAction = action as ILoansPaginatedSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        case WarehousesActionsEnum.WarehouseLoansSet: {
            const typedAction: IWarehouseLoansSetAction = action as IWarehouseLoansSetAction;
            let clonedState: IDealsState = { ...state };

            _.each(typedAction.warehouseLoans, (warehouseLoan: IWarehouseLoan) => {
                clonedState = statifyLoan(clonedState, warehouseLoan.loan);
            });

            return clonedState;
        }

        case WarehousesActionsEnum.WarehouseEligibleLoansSet: {
            const typedAction: IWarehouseEligibleLoansSetAction = action as IWarehouseEligibleLoansSetAction;

            return {
                ...state,
                ...statifyLoans(state, _.map(typedAction.eligibleLoans, (eligibleLoan: IWarehouseEligibleLoan) => eligibleLoan.loan)),
            };
        }

        case WarehousesActionsEnum.WarehousePendingApplicationsSet: {
            const typedAction: IWarehousePendingApplicationsSetAction = action as IWarehousePendingApplicationsSetAction;
            let clonedState: IDealsState = { ...state };

            _.each(typedAction.applicationWarehouses, (applicationWarehouse: IApplicationWarehouse) => {
                clonedState = statifyApplication(clonedState, applicationWarehouse.application);
            });

            return clonedState;
        }

        default:
            return state;
    }
}
