import { Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IInvestorAccountIncomeTrust from '~Api/IncomeTrust/IInvestorAccountIncomeTrust';
import classTypeLabels from '~Api/IncomeTrust/classTypeLabels';
import { investorAccountIncomeTrustDivestAction } from '~Investors/actions';

interface IState {
    amount: number;
    errors: {
        amount?: string;
    };
}

interface IProps {
    investorAccountIncomeTrust: IInvestorAccountIncomeTrust;
    isOpen: boolean;
    onClose: () => void;
}

interface IPropsDispatch {
    divest: (amount: number) => void;
}

type Props = IProps & IPropsDispatch;

class IncomeTrustDivestModal extends React.Component<Props, IState> {
    public state: IState = {
        amount: null,
        errors: {},
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.validateAmount = this.validateAmount.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { investorAccountIncomeTrust, isOpen } = this.props;
        const { amount, errors } = this.state;

        return (
            <Modal
                destroyOnClose={true}
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title={`Divest from ${classTypeLabels[investorAccountIncomeTrust.classType]} Income Trust`}
                wrapClassName='investor-income-trust-divest-modal'
            >
                <Form.Item className='amount' label='Amount' help={errors.amount} validateStatus={errors.amount && 'error'}>
                    <Input addonBefore='$' min={0} onChange={this.onChangeAmount} onBlur={this.validateAmount} type='number' value={amount || ''} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeAmount(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            amount: event.target.value ? event.target.valueAsNumber : null,
        });
    }

    private onClickOk(): void {
        const { amount } = this.state;

        if (!this.validateAmount()) {
            return;
        }

        this.props.divest(amount);
        this.props.onClose();
    }

    private validateAmount(): boolean {
        const { amount, errors } = this.state;
        const { investorAccountIncomeTrust } = this.props;

        let error: string;

        if (!amount) {
            error = 'Please enter an amount';
        } else if (amount > investorAccountIncomeTrust.principalBalance) {
            error = 'Amount exceeds investor\'s current investment';
        }

        this.setState({
            errors: {
                ...errors,
                amount: error,
            },
        });

        return !error;
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        divest: (amount: number) => dispatch(investorAccountIncomeTrustDivestAction(ownProps.investorAccountIncomeTrust.uuid, amount)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(IncomeTrustDivestModal);
