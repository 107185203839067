import React from 'react';

class Logo extends React.Component {
    public render(): JSX.Element {
        return (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 765 200'>
                <path fill='#68c2c4' d='M717.73,126.38v-19.6a3.24,3.24,0,0,0-4.69-2.9L690.71,115a3.22,3.22,0,0,1-2.9,0l-22.13-11a3.24,3.24,0,0,0-4.69,2.9v19.61a3.25,3.25,0,0,0,1.79,2.9l25,12.49a3.28,3.28,0,0,0,2.9,0l25.23-12.6A3.22,3.22,0,0,0,717.73,126.38Z' />
                <path fill='#68c2c4' d='M687.81,155.18l-22.13-11.05A3.24,3.24,0,0,0,661,147v19.61a3.22,3.22,0,0,0,1.79,2.89l25,12.5a3.28,3.28,0,0,0,2.9,0l25.23-12.6a3.24,3.24,0,0,0,1.79-2.9v-19.6A3.24,3.24,0,0,0,713,144l-22.33,11.15A3.22,3.22,0,0,1,687.81,155.18Z' />
                <path fill='#68c2c4' d='M690.71,61.58l71.73-35.81a3.24,3.24,0,0,0,1.79-2.9V3.27a3.24,3.24,0,0,0-4.69-2.9L690.71,34.73a3.28,3.28,0,0,1-2.9,0l-22.13-11a3.24,3.24,0,0,0-4.69,2.9v19.6a3.22,3.22,0,0,0,1.79,2.9l25,12.5A3.28,3.28,0,0,0,690.71,61.58Z' />
                <path fill='#68c2c4' d='M661,66.73v19.6a3.22,3.22,0,0,0,1.79,2.9l25,12.5a3.28,3.28,0,0,0,2.9,0l71.73-35.81a3.24,3.24,0,0,0,1.79-2.9V43.42a3.24,3.24,0,0,0-4.69-2.9L690.71,74.88a3.28,3.28,0,0,1-2.9,0l-22.13-11A3.24,3.24,0,0,0,661,66.73Z' />
                <path fill='#fff' d='M.77,52.77A65.37,65.37,0,0,1,2.54,37.42a34.85,34.85,0,0,1,6-12.94,29.19,29.19,0,0,1,11.08-8.84q6.88-3.25,17.3-3.26a59.66,59.66,0,0,1,6.24.28,31.06,31.06,0,0,1,5.11,1l-1.48,19a35.66,35.66,0,0,0-4-.93,26.49,26.49,0,0,0-4.56-.37,14.58,14.58,0,0,0-7.82,1.86,12.6,12.6,0,0,0-4.56,4.84,20.44,20.44,0,0,0-2.14,6.51,44.39,44.39,0,0,0-.56,6.88V66H43.76V83.85H23.1v71.46H.77Z' />
                <path fill='#fff' d='M138.85,155.31H117.64V141h-.37a28.9,28.9,0,0,1-10.33,11.82,29.72,29.72,0,0,1-17.4,5.12,33.52,33.52,0,0,1-15-3.08,29.44,29.44,0,0,1-10.24-8.09,32.72,32.72,0,0,1-5.86-11.54,48.55,48.55,0,0,1-1.86-13.4V66H78.93v49.51a61.92,61.92,0,0,0,.56,8.18,22.1,22.1,0,0,0,2.42,7.73A15.8,15.8,0,0,0,87,137.07a15,15,0,0,0,8.66,2.24,19.2,19.2,0,0,0,15.72-7.63,24.3,24.3,0,0,0,3.82-7.73,31.07,31.07,0,0,0,1.3-8.84V66h22.33Z' />
                <path fill='#fff' d='M158.27,66h21.22V80.31h.37q3-6.7,10.33-11.82a29.7,29.7,0,0,1,17.4-5.12,33.58,33.58,0,0,1,15,3.08,29.53,29.53,0,0,1,10.24,8.09,32.89,32.89,0,0,1,5.86,11.54,48.55,48.55,0,0,1,1.86,13.4v55.83H218.2v-49.5a63.71,63.71,0,0,0-.56-8.19,22.25,22.25,0,0,0-2.42-7.72,16,16,0,0,0-5.12-5.68A15,15,0,0,0,201.45,82a19.09,19.09,0,0,0-9.31,2.15,19.7,19.7,0,0,0-6.42,5.49,24.23,24.23,0,0,0-3.81,7.72,30.78,30.78,0,0,0-1.31,8.84v49.13H158.27Z' />
                <path fill='#fff' d='M350,155.31H328.74v-13.4h-.37a30.47,30.47,0,0,1-12.94,11.91,39.54,39.54,0,0,1-17.77,4.1,41.71,41.71,0,0,1-32.1-14,45.49,45.49,0,0,1-8.75-15.08,54.88,54.88,0,0,1-3.07-18.51,52.74,52.74,0,0,1,3.16-18.43A45.17,45.17,0,0,1,265.83,77,41.65,41.65,0,0,1,297.1,63.37a39.13,39.13,0,0,1,19.45,4.84,33.19,33.19,0,0,1,6.23,4.47,35.39,35.39,0,0,1,4.28,4.65h.56V14.62H350Zm-73.51-45A35.91,35.91,0,0,0,278,120.7a28.82,28.82,0,0,0,4.75,9.3,24.83,24.83,0,0,0,8,6.7,25.6,25.6,0,0,0,22.34.09,26.91,26.91,0,0,0,8.28-6.6A28.87,28.87,0,0,0,326.6,121a31.38,31.38,0,0,0,1.77-10.33,32.27,32.27,0,0,0-1.77-10.42,28.73,28.73,0,0,0-5.21-9.3,28.3,28.3,0,0,0-8.28-6.7,22.78,22.78,0,0,0-11-2.61,24.27,24.27,0,0,0-11.36,2.52,23.83,23.83,0,0,0-8,6.6A29,29,0,0,0,278,100,34.91,34.91,0,0,0,276.44,110.28Z' />
                <path fill='#fff' d='M366.22,35.65a12.86,12.86,0,0,1,4-9.4,13.74,13.74,0,0,1,10.14-4,14.77,14.77,0,0,1,10.33,3.81,12.44,12.44,0,0,1,4.19,9.59,12.42,12.42,0,0,1-4.19,9.58,14.78,14.78,0,0,1-10.33,3.82,13.78,13.78,0,0,1-10.14-4A12.87,12.87,0,0,1,366.22,35.65ZM369.38,66h22.33v89.33H369.38Z' />
                <path fill='#fff' d='M411.17,66h21.22V80.31h.37q3-6.7,10.33-11.82a29.7,29.7,0,0,1,17.4-5.12,33.58,33.58,0,0,1,15,3.08,29.44,29.44,0,0,1,10.24,8.09,32.89,32.89,0,0,1,5.86,11.54,48.55,48.55,0,0,1,1.86,13.4v55.83H471.1v-49.5a63.71,63.71,0,0,0-.56-8.19,22,22,0,0,0-2.42-7.72A16,16,0,0,0,463,84.22,15,15,0,0,0,454.35,82,19.09,19.09,0,0,0,445,84.13a19.7,19.7,0,0,0-6.42,5.49,24.23,24.23,0,0,0-3.81,7.72,30.78,30.78,0,0,0-1.31,8.84v49.13H411.17Z' />
                <path fill='#fff' d='M602.66,66v81.33a75.11,75.11,0,0,1-3,21.87,42.25,42.25,0,0,1-9.21,16.56,41.55,41.55,0,0,1-15.72,10.51q-9.5,3.72-22.52,3.73a75.53,75.53,0,0,1-11.45-.94,79.16,79.16,0,0,1-11.91-2.79,71.68,71.68,0,0,1-11.26-4.65,54.28,54.28,0,0,1-9.68-6.33L520.77,168a46.23,46.23,0,0,0,14.71,9.39A44.51,44.51,0,0,0,552,180.62a34.63,34.63,0,0,0,13.49-2.33,21.8,21.8,0,0,0,8.75-6.45,25.27,25.27,0,0,0,4.65-9.71,51.06,51.06,0,0,0,1.4-12.33v-6.54H580a31.52,31.52,0,0,1-12.38,10.06,39.73,39.73,0,0,1-16.84,3.48,43.6,43.6,0,0,1-18.24-3.72A41.38,41.38,0,0,1,518.63,143a46.11,46.11,0,0,1-8.84-14.8,50.83,50.83,0,0,1-3.16-17.95A53.83,53.83,0,0,1,509.7,92a44.55,44.55,0,0,1,8.75-14.88A41.47,41.47,0,0,1,532.22,67.1a43,43,0,0,1,18.15-3.73,40.65,40.65,0,0,1,17.68,3.91,29.93,29.93,0,0,1,13,12.1h.37V66ZM555,81.61a25.4,25.4,0,0,0-10.89,2.23,23.46,23.46,0,0,0-8,6,27,27,0,0,0-5,9A34.25,34.25,0,0,0,529.33,110a30.93,30.93,0,0,0,1.77,10.38,29.85,29.85,0,0,0,5,9,24.59,24.59,0,0,0,8,6.4,23.37,23.37,0,0,0,10.7,2.41,25.41,25.41,0,0,0,19.45-8.53,28,28,0,0,0,5.3-9A30.9,30.9,0,0,0,581.44,110a32.21,32.21,0,0,0-1.86-11,27.78,27.78,0,0,0-5.3-9.09A24.68,24.68,0,0,0,566,83.84,26.08,26.08,0,0,0,555,81.61Z' />
            </svg>
        );
    }
}

export default Logo;
