import { Form, Input, Modal, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import DatePicker from '~UI/DatePicker';
import TimePicker from '~UI/TimePicker';
import { warehouseAdjustInterestRateAction } from '~Warehouses/actions';

interface IState {
    description: string;
    interestRate: number;
    transactionDateTime: string;
}

interface IProps {
    isOpen: boolean;
    onCancel: () => void;
    warehouseUuid: string;
}

interface IPropsDispatch {
    adjustInterestRate: (interestRate: number, description: string, transactionTime: string) => void;
}

type Props = IProps  & IPropsDispatch;

class PrincipalModal extends React.Component<Props, IState> {
    public state: IState = {
        description: null,
        interestRate: null,
        transactionDateTime: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeInterestRate = this.onChangeInterestRate.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeTransactionDate = this.onChangeTransactionDate.bind(this);
        this.onChangeTransactionTime = this.onChangeTransactionTime.bind(this);

        this.onClickCancel = this.onClickCancel.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const {
            interestRate,
            description,
            transactionDateTime,
        } = this.state;

        const transactionDateTimeDayJs: Dayjs = transactionDateTime ? dayjs(transactionDateTime) : null;

        return (
            <Modal
                okText='Record Adjustment'
                onCancel={this.onClickCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Adjust Interest Rate'
                wrapClassName='warehouse-adjust-interest-rate-modal'
            >
                <Form.Item label='Transaction Date' className='transaction-date'>
                    <Space>
                        <DatePicker className='transaction-pick-date' onChange={this.onChangeTransactionDate} value={transactionDateTimeDayJs} format='DD/MM/YYYY' />
                        <TimePicker className='transaction-pick-time' onChange={this.onChangeTransactionTime} value={transactionDateTimeDayJs} format='H:mm' />
                    </Space>
                </Form.Item>
                <Form.Item label='Interest Rate' className='interest-rate'>
                    <Input addonAfter='%' onChange={this.onChangeInterestRate} type='number' value={interestRate} />
                </Form.Item>
                <Form.Item label='Description' className='description'>
                    <Input onChange={this.onChangeDescription} value={description} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeInterestRate(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9.]/.test(event.target.value)) {
            return;
        }

        this.setState({
            interestRate: event.target.value === '' ? null : event.target.valueAsNumber,
        });
    }

    private onChangeDescription(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            description: event.target.value,
        });
    }

    private onChangeTransactionDate(date: Dayjs) {
        this.setState({
            transactionDateTime: date ? date.format('YYYY-MM-DD') : null,
        });
    }

    private onChangeTransactionTime(time: Dayjs) {
        const { transactionDateTime } = this.state;

        this.setState({
            transactionDateTime: dayjs(`${dayjs(transactionDateTime || undefined).format('YYYY-MM-DD')} ${time.format('HH:mm')}`).format(),
        });
    }

    private onClickCancel() {
        this.setState({
            description: null,
            interestRate: null,
            transactionDateTime: dayjs().format(),
        });
        this.props.onCancel();
    }

    private onClickOk() {
        const { description, interestRate, transactionDateTime } = this.state;

        const transactionDateTimeDayJs: Dayjs = transactionDateTime ? dayjs(transactionDateTime) : null;
        if (!transactionDateTimeDayJs) {
            return;
        }

        this.props.adjustInterestRate(interestRate, description, transactionDateTimeDayJs.format('YYYY-MM-DDTHH:mm:ssZ'));
        this.onClickCancel();
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        adjustInterestRate: (amount: number, description: string, transactionDateTime: string) => dispatch(warehouseAdjustInterestRateAction(ownProps.warehouseUuid, amount, description, transactionDateTime)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(PrincipalModal);
