import IWarehouse from '~Api/Warehouse/IWarehouse';
import WarehouseTypeEnum from '~Api/Warehouse/WarehouseTypeEnum';

export function getUnusedFunds(warehouse: IWarehouse): number {
    if (!warehouse.totalCapital) {
        return 0;
    }

    let unusedFunds: number = warehouse.totalCapital + warehouse.totalBalancePrincipal + warehouse.reservedApplications;

    if (WarehouseTypeEnum.Fnom === warehouse.type) {
        unusedFunds += warehouse.retainedFunds + warehouse.retainedContingency;
    }

    return unusedFunds;
}