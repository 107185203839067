import { Spin } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import { applicationGetAction } from '~Applications/actions';
import { applicationSelector } from '~Applications/selectors';
import PropertiesList from '~Applications/PropertiesList';
import { IGlobalState } from '~reducer';
import Layout from './Layout';

interface IMatch {
    applicationUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    application: IApplication;
}

interface IPropsDispatch {
    applicationGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Properties extends React.Component<Props> {
    public componentDidMount() {
        const { application } = this.props;

        if (!application) {
            this.props.applicationGet();
        }
    }

    public render(): JSX.Element {
        const { application, match } = this.props;

        if (!application) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='properties'>
                    <Spin/>
                </Layout>
            );
        }

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='properties'>
                <PropertiesList
                    applicationUuid={match.params.applicationUuid}
                />
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Properties);
