import { Tag } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IPostcodeCategory from '~Api/Deal/IPostcodeCategory';
import IProperty from '~Api/Deal/IProperty';
import RegionEnum from '~Api/Deal/RegionEnum';
import RiskBandEnum from '~Api/Deal/RiskBandEnum';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { dealPropertiesPostcodeCategoriesListAction } from './actions';
import propertyStateLabels from './propertyStateLabels';
import { dealPropertiesPostcodeCategoriesSelector } from './selectors';

const regionLabels: IDictionary<string> = {
    [RegionEnum.Metro]: 'Metro',
    [RegionEnum.MetroHighDensity]: 'Metro (High Density)',
    [RegionEnum.Regional]: 'Regional',
    [RegionEnum.RegionalHighDensity]: 'Regional (High Density)',
};

const regionHighDensityMaps: IDictionary<RegionEnum> = {
    [RegionEnum.MetroHighDensity]: RegionEnum.Metro,
    [RegionEnum.RegionalHighDensity]: RegionEnum.Regional,
};

const riskBandLabels: IDictionary<string> = {
    [RiskBandEnum.BandOne]: '1',
    [RiskBandEnum.BandTwo]: '2',
    [RiskBandEnum.BandThree]: '3',
    [RiskBandEnum.BandZero]: '0',
};

interface IProps {
    property: IProperty;
}

interface IPropsSelector {
    postcodeCategories: IDictionary<IPostcodeCategory>;
}

interface IPropsDispatch {
    postcodeCategoriesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class PostcodeCategory extends React.Component<Props> {
    public componentDidMount(): void {
        const { postcodeCategories } = this.props;

        if (!postcodeCategories) {
            this.props.postcodeCategoriesList();
        }
    }

    public render(): JSX.Element {
        const { postcodeCategories, property } = this.props;

        if (!postcodeCategories) {
            return null;
        }

        const postcodeCategory = postcodeCategories[property.postcode];
        if (!postcodeCategory) {
            return null;
        }

        const isHighDensity = (property.zoneType === ZoneTypeEnum.ResidentialUnit);

        let region = postcodeCategory.region;
        if (!isHighDensity && regionHighDensityMaps[postcodeCategory.region]) {
            region = regionHighDensityMaps[postcodeCategory.region];
        }

        return <Tag>{`${propertyStateLabels[postcodeCategory.state]} ${regionLabels[region]} Cat ${riskBandLabels[postcodeCategory.riskBand]}`}</Tag>;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        postcodeCategories: dealPropertiesPostcodeCategoriesSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        postcodeCategoriesList: () => dispatch(dealPropertiesPostcodeCategoriesListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PostcodeCategory);
