import { IDictionary } from '~utilities/IDictionary';
import SourceEnum from '../Api/Deal/SourceEnum';

const sourceLabels: IDictionary<string> = {
    [SourceEnum.Bing]: 'Bing',
    [SourceEnum.Facebook]: 'Facebook',
    [SourceEnum.FacebookForm]: 'Facebook Form',
    [SourceEnum.FacebookPaid]: 'Facebook Paid',
    [SourceEnum.Google]: 'Google',
    [SourceEnum.GoogleForm]: 'Google Form',
    [SourceEnum.LinkedInForm]: 'LinkedIn Form',
    [SourceEnum.ReaGroup]: 'REA Group',
};

export default sourceLabels;
