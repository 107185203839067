import { SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Layout, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import WorkflowStatusEnum from '~Api/Investment/WorkflowStatusEnum';
import { IGlobalState } from '~reducer';
import SearchDropdown from '~UI/SearchDropdown';
import { IDictionary } from '~utilities/IDictionary';
import { investmentsListAction } from './actions';
import './investments.less';
import { investmentsSelector } from './selectors';
import { FilterDropdownProps } from 'antd/lib/table/interface';

const workflowStatusLabels: IDictionary<string> = {
    [WorkflowStatusEnum.Cancelled]: 'Cancelled',
    [WorkflowStatusEnum.Funded]: 'Funded',
    [WorkflowStatusEnum.GracePeriod]: 'Grace Period',
    [WorkflowStatusEnum.Incomplete]: 'Incomplete',
    [WorkflowStatusEnum.Live]: 'Live',
    [WorkflowStatusEnum.Ready]: 'Ready',
    [WorkflowStatusEnum.Repaid]: 'Repaid',
};

interface IPropsSelector {
    investments: IDictionary<IInvestment>;
}

interface IPropsDispatch {
    investmentsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class List extends React.Component<Props> {
    private refreshInterval: NodeJS.Timeout;

    public componentDidMount(): void {
        this.props.investmentsList();

        this.refreshInterval = setInterval(() => {
            this.props.investmentsList();
        }, 5 * 60 * 1000);
    }

    public componentWillUnmount(): void {
        clearInterval(this.refreshInterval);
    }

    public render(): JSX.Element {
        const { investments } = this.props;

        if (!investments) {
            return (
                <Layout className='investments'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Investments</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            minimumFractionDigits: 0,
            style: 'currency',
        });

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const columns: ColumnType<IInvestment>[] = [
            {
                dataIndex: 'code',
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string | number | boolean, investment: IInvestment) => `${investment.code} ${investment.loanCode}`.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                render: (code: string, investment: IInvestment) => <Link to={`/investments/${investment.uuid}`}>{code}</Link>,
                sorter: (a: IInvestment, b: IInvestment) => a.code.localeCompare(b.code),
                title: 'Loan',
                width: '10%',
            },
            {
                dataIndex: 'name',
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string | number | boolean, investment: IInvestment) => (investment.name || '').toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                render: (name: string) => name || '-',
                sorter: (a: IInvestment, b: IInvestment) => (a.name || '').localeCompare(b.name || ''),
                title: 'Name',
            },
            {
                dataIndex: 'amountTotal',
                render: (amountTotal: number) => currencyFormatter.format(amountTotal),
                sorter: (a: IInvestment, b: IInvestment) => a.amountTotal > b.amountTotal ? 1 : -1,
                title: 'Total',
                width: '10%',
            },
            {
                dataIndex: 'amountRemaining',
                render: (amountRemaining: number) => currencyFormatter.format(amountRemaining),
                sorter: (a: IInvestment, b: IInvestment) => a.amountRemaining > b.amountRemaining ? 1 : -1,
                title: 'Remaining',
                width: '10%',
            },
            {
                dataIndex: 'termMonths',
                sorter: (a: IInvestment, b: IInvestment) => a.termMonths > b.termMonths ? 1 : -1,
                title: 'Term',
                width: '7%',
            },
            {
                dataIndex: 'interestRate',
                render: (interestRate: number) => percentageFormatter.format(interestRate / 100),
                sorter: (a: IInvestment, b: IInvestment) => a.interestRate > b.interestRate ? 1 : -1,
                title: 'Rate',
                width: '7%',
            },
            {
                dataIndex: 'lvr',
                render: (lvr: number) => percentageFormatter.format(lvr / 100),
                sorter: (a: IInvestment, b: IInvestment) => a.lvr > b.lvr ? 1 : -1,
                title: 'LVR',
                width: '7%',
            },
            {
                dataIndex: 'workflowStatus',
                filters: [
                    {
                        text: workflowStatusLabels[WorkflowStatusEnum.Cancelled],
                        value: WorkflowStatusEnum.Cancelled,
                    },
                    {
                        text: workflowStatusLabels[WorkflowStatusEnum.Incomplete],
                        value: WorkflowStatusEnum.Incomplete,
                    },
                    {
                        text: workflowStatusLabels[WorkflowStatusEnum.Ready],
                        value: WorkflowStatusEnum.Ready,
                    },
                    {
                        text: workflowStatusLabels[WorkflowStatusEnum.Live],
                        value: WorkflowStatusEnum.Live,
                    },
                    {
                        text: workflowStatusLabels[WorkflowStatusEnum.Funded],
                        value: WorkflowStatusEnum.Funded,
                    },
                    {
                        text: workflowStatusLabels[WorkflowStatusEnum.Repaid],
                        value: WorkflowStatusEnum.Repaid,
                    },
                ],
                onFilter: (value: string | number | boolean, investment: IInvestment) => investment.workflowStatus === value,
                render: (workflowStatus: WorkflowStatusEnum) => workflowStatusLabels[workflowStatus],
                title: 'Status',
                width: '10%',
            },
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => createdTime ? <Tooltip title={dayjs(createdTime).format('Do MMMM YYYY HH:mm:ss')}><span>{dayjs(createdTime).fromNow()}</span></Tooltip> : '-',
                sorter: (a: IInvestment, b: IInvestment) => dayjs(a.createdTime) > dayjs(b.createdTime) ? 1 : -1,
                title: 'Created',
                width: '10%',
            },
        ];

        return (
            <Layout className='investments'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Investments</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Link to='/investments'><Button>Board View</Button></Link>
                        </Space>
                        <Typography.Title level={2}>Investments</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.values(investments)}
                            pagination={{ defaultPageSize: 50 }}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        investments: investmentsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        investmentsList: () => dispatch(investmentsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(List);
