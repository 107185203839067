import { Modal, Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import IWarehouseLoanTransaction from '~Api/Warehouse/IWarehouseLoanTransaction';
import { loansListAction } from '~Loans/actions';
import { loansSelector } from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { warehousesListAction } from '~Warehouses/actions';
import { warehousesSelector } from '~Warehouses/selectors';
import { warehouseMovementsListAction } from './actions';
import Layout from './Layout';
import { warehouseMovementsSelector } from './selectors';

const historyDays: number = 30;

interface IState {
    modalIsOpen: boolean;
    modalUniqueId: string;
}

interface IWarehouseMovement {
    date: string;
    loanUuid?: string;
    transactions: IWarehouseLoanTransaction[];
    uniqueId: string;
    warehouseUuid?: string;
}

interface IDay {
    date: string;
    movementCount: number;
    primaryRowUniqueId?: string;
}

interface IPropsSelector {
    loans: IDictionary<ILoan>;
    transactions: IDictionary<IWarehouseLoanTransaction>;
    warehouses: IDictionary<IWarehouse>;
}

interface IPropsDispatch {
    loansList: () => void;
    warehouseMovementsList: () => void;
    warehousesList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class WarehouseMovements extends React.Component<Props> {
    public state: IState = {
        modalIsOpen: false,
        modalUniqueId: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickTransactions = this.onClickTransactions.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    public componentDidMount(): void {
        const { loans, warehouses } = this.props;

        if (!loans) {
            this.props.loansList();
        }

        if (!warehouses) {
            this.props.warehousesList();
        }

        this.props.warehouseMovementsList();
    }

    public render(): JSX.Element {
        const { loans, transactions, warehouses } = this.props;
        const { modalIsOpen, modalUniqueId } = this.state;

        if (!loans || !transactions || !warehouses) {
            return (
                <Layout section='warehouse-movements'>
                    <Typography.Title level={2}>Warehouse Movements</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const days: IDictionary<IDay> = {};
        const warehouseMovements: IWarehouseMovement[] = [];

        for (let i: number = 0; i <= historyDays; i++) {
            const loopDate: string = dayjs().subtract(i, 'days').format('YYYY-MM-DD');
            days[loopDate] = {
                date: loopDate,
                movementCount: 0,
            };
            warehouseMovements.push({
                date: loopDate,
                transactions: [],
                uniqueId: loopDate,
            });
        }

        const dayWarehouseMovements: IDictionary<IWarehouseMovement> = {};

        _.each(transactions, (warehouseLoanTransaction: IWarehouseLoanTransaction) => {
            const transactionTimeDaysjs: Dayjs = dayjs(warehouseLoanTransaction.transactionTime);
            const transactionDate: string = transactionTimeDaysjs.format('YYYY-MM-DD');

            const uniqueId: string = `${transactionDate}|${warehouseLoanTransaction.warehouseLoanUuid}`;

            if (!dayWarehouseMovements[uniqueId]) {
                dayWarehouseMovements[uniqueId] = {
                    date: transactionDate,
                    loanUuid: warehouseLoanTransaction.loanUuid,
                    transactions: [],
                    uniqueId,
                    warehouseUuid: warehouseLoanTransaction.warehouseUuid,
                };
            }

            dayWarehouseMovements[uniqueId].transactions.push(warehouseLoanTransaction);
        });

        _.each(dayWarehouseMovements, (dayWarehouseLoan: IWarehouseMovement) => {
            const day: IDay = days[dayWarehouseLoan.date];
            if (!day.primaryRowUniqueId) {
                day.primaryRowUniqueId = dayWarehouseLoan.uniqueId;

                const initialWarehouseMovement: IWarehouseMovement = _.find(warehouseMovements, (warehouseMovement: IWarehouseMovement) => warehouseMovement.date === dayWarehouseLoan.date);
                initialWarehouseMovement.loanUuid = dayWarehouseLoan.loanUuid;
                initialWarehouseMovement.transactions = dayWarehouseLoan.transactions;
                initialWarehouseMovement.uniqueId = dayWarehouseLoan.uniqueId;
                initialWarehouseMovement.warehouseUuid = dayWarehouseLoan.warehouseUuid;
            } else {
                warehouseMovements.push(dayWarehouseLoan);
            }

            day.movementCount++;
        });

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnsType<IWarehouseMovement> = [
            {
                className: 'date',
                render: (warehouseMovement: IWarehouseMovement) => {
                    if (!warehouseMovement.warehouseUuid) {
                        return dayjs(warehouseMovement.date).format('D/M');
                    }

                    const day: IDay = days[warehouseMovement.date];
                    return {
                        children: dayjs(warehouseMovement.date).format('D/M'),
                        props: {
                            // When the rowSpan of the cell is set to zero, it essentially hides the cell.
                            rowSpan: (day.primaryRowUniqueId === warehouseMovement.uniqueId) ? day.movementCount : 0,
                        },
                    };
                },
                title: 'Date',
                width: '5%',
            },
            {
                className: 'day',
                render: (warehouseMovement: IWarehouseMovement) => {
                    if (!warehouseMovement.warehouseUuid) {
                        return dayjs(warehouseMovement.date).format('dddd');
                    }

                    const day: IDay = days[warehouseMovement.date];
                    return {
                        children: dayjs(warehouseMovement.date).format('dddd'),
                        props: {
                            rowSpan: (day.primaryRowUniqueId === warehouseMovement.uniqueId) ? day.movementCount : 0,
                        },
                    };
                },
                title: 'Day',
                width: '10%',
            },
            {
                dataIndex: 'warehouseUuid',
                render: (warehouseUuid: string) => {
                    if (!warehouseUuid) {
                        return '-';
                    }

                    return <Link to={`/warehouses/${warehouseUuid}`}>{warehouses[warehouseUuid].name}</Link>;
                },
                title: 'Warehouse',
                width: '25%',
            },
            {
                dataIndex: 'loanUuid',
                render: (loanUuid: string) => {
                    if (!loanUuid) {
                        return '-';
                    }

                    return (
                        <>
                            <Link to={`/loans/${loanUuid}`}>{loans[loanUuid].code}</Link>
                            {loans[loanUuid].salesforceCode && loans[loanUuid].salesforceCode !== loans[loanUuid].code && ` (${loans[loanUuid].salesforceCode})`}
                        </>
                    );
                },
                title: 'Loan',
            },
            {
                dataIndex: 'transactions',
                render: (loopTransactions: IWarehouseLoanTransaction[], warehouseMovement: IWarehouseMovement) => {
                    if (loopTransactions.length === 0) {
                        return '-';
                    }

                    const netPrincipal: number = _.sumBy(_.values(loopTransactions), (loopTransaction: IWarehouseLoanTransaction) => loopTransaction.amountPrincipal);

                    let modalBlock: JSX.Element = null;

                    if (modalUniqueId === warehouseMovement.uniqueId) {
                        const modalColumns: ColumnsType<IWarehouseLoanTransaction> = [
                            {
                                dataIndex: 'description',
                                title: 'Description',
                            },
                            {
                                dataIndex: 'amountPrincipal',
                                render: (amountPrincipal: number) => currencyFormatter.format(amountPrincipal),
                                title: 'Amount',
                                width: '20%',
                            },
                        ];

                        modalBlock = (
                            <Modal
                                footer={false}
                                onCancel={this.onCloseModal}
                                open={modalIsOpen}
                                title='Investment Details'
                                width={1000}
                            >
                                <Table
                                    columns={modalColumns}
                                    dataSource={loopTransactions}
                                    rowKey='uuid'
                                    pagination={false}
                                    size='middle'
                                />
                            </Modal>
                        );
                    }

                    const onClick: () => void = () => this.onClickTransactions(warehouseMovement.uniqueId);

                    return (
                        <>
                            <a onClick={onClick}>{currencyFormatter.format(netPrincipal)}</a>
                            {modalBlock}
                        </>
                    );
                },
                title: 'Net Principal',
                width: '15%',
            },
        ];

        return (
            <Layout section='warehouse-movements'>
                <Typography.Title level={2}>Warehouse Movements</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(_.orderBy(warehouseMovements, ['date'], ['desc']))}
                    rowKey='uniqueId'
                    pagination={false}
                    size='middle'
                />
            </Layout>
        );
    }

    private onClickTransactions(uniqueId: string): void {
        this.setState({
            modalIsOpen: true,
            modalUniqueId: uniqueId,
        });
    }

    private onCloseModal(): void {
        this.setState({
            modalIsOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        loans: loansSelector(state),
        transactions: warehouseMovementsSelector(state),
        warehouses: warehousesSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        loansList: () => dispatch(loansListAction()),
        warehouseMovementsList: () => dispatch(warehouseMovementsListAction()),
        warehousesList: () => dispatch(warehousesListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WarehouseMovements);
