import { Button, Space, Spin, Typography } from 'antd';
import Table, { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IGlobalState } from '~reducer';
import { incomeTrustTransactionsSelector } from '../selectors';
import IIncomeTrustTransaction from '~Api/IncomeTrust/IIncomeTrustTransaction';
import { IDictionary } from '~utilities/IDictionary';
import { investmentIncomeTrustTransactionsListAction } from '~Investments/actions';
import dayjs from 'dayjs';
import Layout from './Layout';
import TransactionModal from './TransactionModal';
import { UnorderedListOutlined } from '@ant-design/icons';
import ClassTypeDistributionModal from './ClassTypeDistributionModal';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import IncomeTrustClassTypeEnum from '~Api/IncomeTrust/IncomeTrustClassTypeEnum';
import classTypeLabels from '~Api/IncomeTrust/classTypeLabels';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';

interface IState {
    classModals: IDictionary<boolean>;
    classType: IncomeTrustClassTypeEnum;
    transactionModalIsOpen: boolean;
    transactionModalUuid: string;
}

interface IPropsSelector {
    currentUser: IAuthUser;
    transactions: IDictionary<IIncomeTrustTransaction>;
}

interface IPropsDispatch {
    transactionsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Distributions extends React.Component<Props, IState> {
    public state: IState = {
        classModals: {},
        classType: null,
        transactionModalIsOpen: false,
        transactionModalUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickInvestors = this.onClickInvestors.bind(this);
        this.onClickNewDistribution = this.onClickNewDistribution.bind(this);
        this.onCloseDistributionModal = this.onCloseDistributionModal.bind(this);
        this.onCloseTransactionModal = this.onCloseTransactionModal.bind(this);
    }

    public componentDidMount(): void {
        this.props.transactionsList();
    }

    public render(): JSX.Element {
        const { currentUser, transactions } = this.props;
        const { classModals, transactionModalIsOpen, transactionModalUuid } = this.state;

        if (!transactions) {
            return (
                <Layout section='distributions'>
                    <Typography.Title level={2}>Distributions</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const columns: ColumnType<IIncomeTrustTransaction>[] = [
            {
                dataIndex: 'transactionTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => dayjs(createdTime).format('Do MMMM YYYY'),
                sorter: (a: IIncomeTrustTransaction, b: IIncomeTrustTransaction) => dayjs(a.transactionTime) > dayjs(b.transactionTime) ? 1 : -1,
                title: 'Date',
            },
            {
                dataIndex: 'classType',
                filters: _.keys(classTypeLabels).map((classType: string) => ({
                    text: classTypeLabels[classType],
                    value: classType as IncomeTrustClassTypeEnum,
                })),
                onFilter: (value: string | number | boolean, incomeTrustTransaction: IIncomeTrustTransaction) => incomeTrustTransaction.classType === value,
                render: (classType: IncomeTrustClassTypeEnum) => classTypeLabels[classType],
                title: 'Class',
                width: '15%',
            },
            {
                dataIndex: 'interestRate',
                render: (interestRate: number) => percentageFormatter.format(interestRate / 100),
                title: 'Interest Rate',
                width: '15%',
            },
            {
                dataIndex: 'investorCount',
                title: 'Investors',
                width: '15%',
            },
            {
                dataIndex: 'totalAmount',
                render: (totalAmount: number) => currencyFormatter.format(totalAmount),
                title: 'Total',
                width: '15%',
            },
            {
                render: (transaction: IIncomeTrustTransaction) => {
                    const modalBlock: JSX.Element = transactionModalUuid === transaction.uuid && (
                        <TransactionModal
                            transaction={transaction}
                            isOpen={transactionModalIsOpen}
                            onCancel={this.onCloseTransactionModal}
                        />
                    );

                    const onClick: () => void = () => this.onClickInvestors(transaction.uuid);

                    return (
                        <>
                            <Button icon={<UnorderedListOutlined/>} onClick={onClick} />
                            {modalBlock}
                        </>
                    );
                },
                title: '',
                width: '2%',
            },
        ];

        const distributionModalsBlock: JSX.Element[] = currentUser.permissions.includes(PermissionsEnum.IncomeTrustProcessInterest) && _.keys(classModals).map((classType: string) => {
            const onClose: () => void = () => this.onCloseDistributionModal(classType as IncomeTrustClassTypeEnum);

            return (
                <ClassTypeDistributionModal
                    classType={classType as IncomeTrustClassTypeEnum}
                    key={classType}
                    isOpen={classModals[classType]}
                    onCancel={onClose}
                />
            );
    });

        const distributionButtons: JSX.Element[] = currentUser.permissions.includes(PermissionsEnum.IncomeTrustProcessInterest) && _.values(IncomeTrustClassTypeEnum).map((classType: IncomeTrustClassTypeEnum) => {
            const onClickNewDistribution: () => void = () => this.onClickNewDistribution(classType);

            return <Button onClick={onClickNewDistribution} key={classType}>New {classTypeLabels[classType]} Distribution</Button>;
        });

        return (
            <Layout section='distributions'>
                <Space className='actions'>
                    {distributionButtons}
                </Space>
                <Typography.Title level={2}>Distributions</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(transactions)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                />
                <>{distributionModalsBlock}</>
            </Layout>
        );
    }

    private onClickInvestors(transactionUuid: string): void {
        this.setState({
            transactionModalIsOpen: true,
            transactionModalUuid: transactionUuid,
        });
    }

    private onClickNewDistribution(classType: IncomeTrustClassTypeEnum): void {
        const { classModals } = this.state;
        this.setState({
            classModals: {
                ...classModals,
                [classType]: true,
            },
        });
    }

    private onCloseDistributionModal(classType: IncomeTrustClassTypeEnum): void {
        const { classModals } = this.state;
        this.setState({
            classModals: {
                ...classModals,
                [classType]: false,
            },
        });
    }

    private onCloseTransactionModal(): void {
        this.setState({
            transactionModalIsOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentUser: authCurrentUserSelector(state),
        transactions: incomeTrustTransactionsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        transactionsList: () => dispatch(investmentIncomeTrustTransactionsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Distributions);
