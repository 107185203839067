import { Action } from 'redux';
import ValuersActionsEnum from './ActionsEnum';
import IValuer from './IValuer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IValuersListAction extends Action {}

export function valuersListAction(): IValuersListAction {
    return {
        type: ValuersActionsEnum.ValuersList,
    };
}

export interface IValuersSetAction extends Action {
    valuers: IValuer[];
}

export function valuersSetAction(valuers: IValuer[]): IValuersSetAction {
    return {
        type: ValuersActionsEnum.ValuersSet,
        valuers,
    };
}
