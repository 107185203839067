import { IDictionary } from '~utilities/IDictionary';
import ZoneTypeEnum from './ZoneTypeEnum';

const zoneTypeLabels: IDictionary<string> = {
    [ZoneTypeEnum.CommercialIndustrial]: 'Commercial - Industrial',
    [ZoneTypeEnum.CommercialLand]: 'Commercial - Land',
    [ZoneTypeEnum.CommercialOffice]: 'Commercial - Office',
    [ZoneTypeEnum.CommercialRetail]: 'Commercial - Retail',
    [ZoneTypeEnum.ResidentialHouse]: 'Residential - House',
    [ZoneTypeEnum.ResidentialLand]: 'Residential - Land',
    [ZoneTypeEnum.ResidentialTownhouse]: 'Residential - Townhouse / Villa',
    [ZoneTypeEnum.ResidentialUnit]: 'Residential - Unit / Apartment',
    [ZoneTypeEnum.RuralLand]: 'Rural - Land',
    [ZoneTypeEnum.RuralResidential]: 'Rural - Residential',
};

export default zoneTypeLabels;