import { Checkbox, Form, Input, Modal, Select, Space, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import {
    administratorsSelector,
    currentAdministratorSelector,
} from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import IBroker from '~Api/Broker/IBroker';
import IDeal from '~Api/Deal/IDeal';
import LoanPurposeEnum from '~Api/Deal/LoanPurposeEnum';
import MortgageTypeEnum from '~Api/Deal/MortgageTypeEnum';
import PhoneSourceEnum from '~Api/Deal/PhoneSourceEnum';
import RepaymentStrategyEnum from '~Api/Deal/RepaymentStrategyEnum';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import { brokersSelector } from '~Brokers/selectors';
import { IGlobalState } from '~reducer';
import { referralPartnersListAction } from '~ReferralPartners/actions';
import { approvedReferralPartnersSelector } from '~ReferralPartners/selectors';
import { IDictionary } from '~utilities/IDictionary';
import {
    leadAddAction,
    leadAddErrorSetAction,
    leadBrokerSearchAction,
    leadBrokerSearchResultsClearAction,
} from './actions';
import './leads.less';
import { ILeadAddErrors } from './reducer';
import {
    leadAddErrorsSelector,
    leadBrokerSearchLoadingSelector,
    leadBrokerSearchResultsSelector,
} from './selectors';
import { validateEmail } from '~utilities/validators';
import { DefaultOptionType } from 'antd/lib/select';
import { longFormatName } from '~Brokers/utilities';

const loanPurposeLabels: IDictionary<string> = {
    [LoanPurposeEnum.BridgingLoan]: 'Bridging loan',
    [LoanPurposeEnum.Refinance]: 'Refinance an existing property',
    [LoanPurposeEnum.BusinessLoan]: 'Business loan',
    [LoanPurposeEnum.PersonalLoan]: 'Personal loan',
    [LoanPurposeEnum.RenovateOrBuild]: 'Renovate or build',
    [LoanPurposeEnum.PurchaseNew]: 'Purchase a new property',
    [LoanPurposeEnum.DebtConsolidation]: 'Debt consolidation',
    [LoanPurposeEnum.DevelopmentLoan]: 'Development loan',
};

interface IState {
    bdmUuid: string;
    brokerUuid: string;
    comments: string;
    email: string;
    firstName: string;
    isAdding: boolean;
    isBroker: boolean;
    isReferralPartner: boolean;
    lastName: string;
    loanAmount: number;
    loanPurpose: LoanPurposeEnum;
    mortgageType: MortgageTypeEnum;
    otherSource: string;
    phone: string;
    phoneSource: PhoneSourceEnum;
    repaymentStrategy: RepaymentStrategyEnum;
    referralPartnerCompanyName: string;
    referralPartnerEmail: string;
    referralPartnerFirstName: string;
    referralPartnerLastName: string;
    referralPartnerPhone: string;
    referralPartnerUuid: string;
    termMonths: number;
}

interface IProps {
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsSelector {
    administrators: IDictionary<IAdministrator>;
    brokerSearchLoading: boolean;
    brokerSearchResults: IBroker[];
    brokers: IDictionary<IBroker>;
    currentAdministrator: IAdministrator;
    errors: ILeadAddErrors;
    referralPartners: IDictionary<IReferralPartner>;
}

interface IPropsDispatch {
    administratorsList: () => void;
    errorSet: (key: keyof IDeal, value: string) => void;
    leadAdd: (deal: IDeal) => void;
    leadBrokerSearch: (keyword: string) => void;
    leadBrokerSearchClear: () => void;
    referralPartnersList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class LeadAddModal extends React.Component<Props, IState> {
    public state: IState = {
        bdmUuid: null,
        brokerUuid: null,
        comments: null,
        email: null,
        firstName: null,
        isAdding: false,
        isBroker: false,
        isReferralPartner: false,
        lastName: null,
        loanAmount: null,
        loanPurpose: null,
        mortgageType: null,
        otherSource: null,
        phone: null,
        phoneSource: null,
        referralPartnerCompanyName: null,
        referralPartnerEmail: null,
        referralPartnerFirstName: null,
        referralPartnerLastName: null,
        referralPartnerPhone: null,
        referralPartnerUuid: null,
        repaymentStrategy: null,
        termMonths: null,
    };

    constructor(props: Props) {
        super(props);

        this.onSearchBroker = this.onSearchBroker.bind(this);

        this.onChangeBdm = this.onChangeBdm.bind(this);
        this.onChangeBroker = this.onChangeBroker.bind(this);
        this.onChangeComments = this.onChangeComments.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeIsBroker = this.onChangeIsBroker.bind(this);
        this.onChangeIsReferralPartner = this.onChangeIsReferralPartner.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeLoanAmount = this.onChangeLoanAmount.bind(this);
        this.onChangeLoanPurpose = this.onChangeLoanPurpose.bind(this);
        this.onChangeMortgageType = this.onChangeMortgageType.bind(this);
        this.onChangeOtherSource = this.onChangeOtherSource.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeRepaymentStrategy = this.onChangeRepaymentStrategy.bind(this);
        this.onChangePhoneSource = this.onChangePhoneSource.bind(this);
        this.onChangeReferralPartner = this.onChangeReferralPartner.bind(this);
        this.onChangeReferralPartnerFirstName = this.onChangeReferralPartnerFirstName.bind(this);
        this.onChangeReferralPartnerLastName = this.onChangeReferralPartnerLastName.bind(this);
        this.onChangeReferralPartnerEmail = this.onChangeReferralPartnerEmail.bind(this);
        this.onChangeReferralPartnerPhone = this.onChangeReferralPartnerPhone.bind(this);
        this.onChangeReferralPartnerCompanyName = this.onChangeReferralPartnerCompanyName.bind(this);
        this.onChangeTermMonths = this.onChangeTermMonths.bind(this);

        this.onClickOk = this.onClickOk.bind(this);

        this.validateEmail = this.validateEmail.bind(this);
        this.validateFirstName = this.validateFirstName.bind(this);
        this.validateLastName = this.validateLastName.bind(this);
        this.validateLoanAmount = this.validateLoanAmount.bind(this);
        this.validateLoanPurpose = this.validateLoanPurpose.bind(this);
        this.validateOtherSource = this.validateOtherSource.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.validateTermMonths = this.validateTermMonths.bind(this);
    }

    public componentDidMount() {
        this.props.administratorsList();
        this.props.referralPartnersList();
    }

    public render(): JSX.Element {
        const {
            administrators,
            brokerSearchLoading,
            brokerSearchResults,
            brokers,
            currentAdministrator,
            errors,
            isOpen,
            referralPartners,
        } = this.props;
        const {
            bdmUuid,
            brokerUuid,
            comments,
            email,
            firstName,
            isAdding,
            isBroker,
            isReferralPartner,
            lastName,
            loanAmount,
            loanPurpose,
            mortgageType,
            otherSource,
            phone,
            phoneSource,
            repaymentStrategy,
            referralPartnerCompanyName,
            referralPartnerFirstName,
            referralPartnerLastName,
            referralPartnerEmail,
            referralPartnerPhone,
            referralPartnerUuid,
            termMonths,
        } = this.state;

        if (!administrators || !currentAdministrator || !referralPartners) {
            return (
                <Modal
                    onCancel={this.props.onCancel}
                    onOk={this.onClickOk}
                    open={isOpen}
                    title='Add Lead'
                    wrapClassName='lead-add-modal'
                >
                    <Spin/>
                </Modal>
            );
        }

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const bdmBlock: JSX.Element = ![RoleEnum.BusinessDevelopmentManager, RoleEnum.InternalBusinessDevelopmentManager, RoleEnum.SeniorBusinessDevelopmentManager].includes(currentAdministrator.role) && (
            <Form.Item label='BDM' className='bdm'>
                <Select onChange={this.onChangeBdm} value={bdmUuid}>
                    <Select.Option value={null}>None</Select.Option>
                    {_.sortBy(_.filter(administrators, (admin: IAdministrator) => [RoleEnum.BusinessDevelopmentManager, RoleEnum.InternalBusinessDevelopmentManager, RoleEnum.SeniorBusinessDevelopmentManager].includes(admin.role)), ['name']).map((administrator: IAdministrator) => <Select.Option key={administrator.uuid} value={administrator.uuid}>{administrator.name}</Select.Option>)}
                </Select>
            </Form.Item>
        );

        const broker: IBroker = brokerUuid && brokers[brokerUuid];
        const brokerOptions: DefaultOptionType[] = _.map(brokerSearchResults, (broker: IBroker) => ({
            label: longFormatName(broker),
            value: broker.uuid,
        }));
        const brokerBlock: JSX.Element = isBroker && (
            <Form.Item label='Broker' className='broker'>
                <Space>
                    <Select
                        allowClear={true}
                        disabled={!!isReferralPartner}
                        filterOption={false}
                        notFoundContent={null}
                        onChange={this.onChangeBroker}
                        onSearch={this.onSearchBroker}
                        options={brokerOptions}
                        placeholder='None'
                        showArrow={false}
                        showSearch={true}
                        value={broker ? longFormatName(broker) : brokerUuid}
                    />
                    {brokerSearchLoading && <Spin/>}
                </Space>
            </Form.Item>
        );

        const otherSourceBlock: JSX.Element = phoneSource && phoneSource === PhoneSourceEnum.Other && (
            <Form.Item help={errors.otherSource} label='Other' className='other-source' validateStatus={errors.otherSource && 'error'}>
                <Input onBlur={this.validateOtherSource} onChange={this.onChangeOtherSource} maxLength={50} value={otherSource} />
            </Form.Item>
        );

        const referralPartnerBlock: JSX.Element = isReferralPartner && (
            <React.Fragment>
                <Form.Item help={errors.referralPartner} label='Referred By' className='referral-partner' validateStatus={errors.referralPartner && 'error'}>
                    <Select
                        disabled={!!referralPartnerFirstName || !!referralPartnerLastName || !!referralPartnerEmail || !!referralPartnerPhone || !!referralPartnerCompanyName}
                        onChange={this.onChangeReferralPartner}
                        value={referralPartnerUuid}
                    >
                        <Select.Option value={null}>None</Select.Option>
                        {_.values(referralPartners).map((referralPartner: IReferralPartner) => <Select.Option key={referralPartner.uuid} value={referralPartner.uuid}>{referralPartner.firstName + ' ' + referralPartner.lastName}{referralPartner.companyName ? ' ' + referralPartner.companyName : ''}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label='Referral Partner First Name' className='referral-partner-first-name'>
                    <Input disabled={!!referralPartnerUuid} onChange={this.onChangeReferralPartnerFirstName} value={referralPartnerFirstName} />
                </Form.Item>
                <Form.Item label='Referral Partner Last Name' className='referral-partner-last-name'>
                    <Input disabled={!!referralPartnerUuid} onChange={this.onChangeReferralPartnerLastName} value={referralPartnerLastName} />
                </Form.Item>
                <Form.Item label='Referral Partner Email' className='referral-partner-email'>
                    <Input disabled={!!referralPartnerUuid} onChange={this.onChangeReferralPartnerEmail} value={referralPartnerEmail} />
                </Form.Item>
                <Form.Item label='Referral Partner Phone' className='referral-partner-phone'>
                    <Input disabled={!!referralPartnerUuid} onChange={this.onChangeReferralPartnerPhone} value={referralPartnerPhone} />
                </Form.Item>
                <Form.Item label='Referral Partner Company' className='referral-partner-company-name'>
                    <Input disabled={!!referralPartnerUuid} onChange={this.onChangeReferralPartnerCompanyName} value={referralPartnerCompanyName} />
                </Form.Item>
            </React.Fragment>
        );

        return (
            <Modal
                okButtonProps={{ loading: isAdding }}
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Lead'
                width={600}
                wrapClassName='lead-add-modal'
            >
                <Form.Item label='Mortgage Type' className='mortgage-type'>
                    <Select onChange={this.onChangeMortgageType} value={mortgageType}>
                        <Select.Option value={MortgageTypeEnum.FirstMortgage}>First Mortgage</Select.Option>
                        <Select.Option value={MortgageTypeEnum.SecondMortgage}>Second Mortgage</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item help={errors.loanPurpose} label='Loan Purpose' className='loan-purpose' validateStatus={errors.loanPurpose && 'error'}>
                    <Select onBlur={this.validateLoanPurpose} onChange={this.onChangeLoanPurpose} value={loanPurpose}>
                        {_.map(loanPurposeLabels, (loanPurposeLabel: string, key: LoanPurposeEnum) => <Select.Option value={key}>{loanPurposeLabel}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item help={errors.termMonths} label='Loan Term' className='term-months' validateStatus={errors.termMonths && 'error'}>
                    <Select onBlur={this.validateTermMonths} onChange={this.onChangeTermMonths} value={termMonths}>
                        {termMonths === null && <Select.Option value={null}>None</Select.Option>}
                        {_.times(36, (months: number) => (<Select.Option key={months + 1} value={months + 1}>{months + 1} months</Select.Option>))}
                    </Select>
                </Form.Item>
                <Form.Item label='Repayment Strategy' className='repayment-strategy'>
                    <Select onChange={this.onChangeRepaymentStrategy} value={repaymentStrategy}>
                        <Select.Option value={RepaymentStrategyEnum.PropertySaleSold}>Sale of Property - Already Sold</Select.Option>
                        <Select.Option value={RepaymentStrategyEnum.PropertySaleNotSold}>Sale of Property - Not Yet Sold</Select.Option>
                        <Select.Option value={RepaymentStrategyEnum.RefinanceApproved}>Refinance - Already Approved</Select.Option>
                        <Select.Option value={RepaymentStrategyEnum.RefinanceNotApproved}>Refinance - Not Yet Approved</Select.Option>
                        <Select.Option value={RepaymentStrategyEnum.BusinessSaleSold}>Sale of Business - Already Sold</Select.Option>
                        <Select.Option value={RepaymentStrategyEnum.BusinessSaleNotSold}>Sale of Business - Not Yet Sold</Select.Option>
                        <Select.Option value={RepaymentStrategyEnum.BusinessCashFlow}>Business Cash Flow</Select.Option>
                        <Select.Option value={RepaymentStrategyEnum.Inheritance}>Inheritance</Select.Option>
                        <Select.Option value={RepaymentStrategyEnum.CourtSettlement}>Court Settlement / Compensation</Select.Option>
                        <Select.Option value={RepaymentStrategyEnum.Other}>Other</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item help={errors.loanAmount} label='Requested Payout Amount' className='loan-amount' extra={currencyFormatter.format(loanAmount)} validateStatus={errors.loanAmount && 'error'}>
                    <Input addonBefore='$' min={0} onBlur={this.validateLoanAmount} onChange={this.onChangeLoanAmount} type='number' value={loanAmount} />
                </Form.Item>
                <Form.Item label='Is Broker' className='is-broker'>
                    <Checkbox
                        checked={isBroker}
                        disabled={!!isReferralPartner}
                        onChange={this.onChangeIsBroker}
                    />
                </Form.Item>
                {brokerBlock}
                {bdmBlock}
                <Form.Item help={errors.firstName} label='First Name' className='first-name' validateStatus={errors.firstName && 'error'}>
                    <Input disabled={!!brokerUuid} onBlur={this.validateFirstName} onChange={this.onChangeFirstName} value={firstName} />
                </Form.Item>
                <Form.Item help={errors.lastName} label='Last Name' className='last-name' validateStatus={errors.lastName && 'error'}>
                    <Input disabled={!!brokerUuid} onBlur={this.validateLastName} onChange={this.onChangeLastName} value={lastName} />
                </Form.Item>
                <Form.Item help={errors.email} label='Email' className='email' validateStatus={errors.email && 'error'}>
                    <Input disabled={!!brokerUuid} onBlur={this.validateEmail} onChange={this.onChangeEmail} value={email} />
                </Form.Item>
                <Form.Item help={errors.phone} label='Phone' className='phone' validateStatus={errors.phone && 'error'}>
                    <Input disabled={!!brokerUuid} onBlur={this.validatePhone} onChange={this.onChangePhone} value={phone} />
                </Form.Item>
                <Form.Item help={errors.phoneSource} label='Lead Source' className='phone-source' validateStatus={errors.phoneSource && 'error'}>
                    <Select onChange={this.onChangePhoneSource} value={phoneSource}>
                        <Select.Option value={PhoneSourceEnum.Bing}>Bing</Select.Option>
                        <Select.Option value={PhoneSourceEnum.Facebook}>Facebook</Select.Option>
                        <Select.Option value={PhoneSourceEnum.FacebookAd}>Facebook Ad</Select.Option>
                        <Select.Option value={PhoneSourceEnum.Google}>Google</Select.Option>
                        <Select.Option value={PhoneSourceEnum.GoogleAd}>Google Ad</Select.Option>
                        <Select.Option value={PhoneSourceEnum.LinkedIn}>LinkedIn</Select.Option>
                        <Select.Option value={PhoneSourceEnum.ClientReferral}>Client Referral</Select.Option>
                        <Select.Option value={PhoneSourceEnum.EmailSubscriber}>Email Subscriber</Select.Option>
                        <Select.Option value={PhoneSourceEnum.Other}>Other</Select.Option>
                    </Select>
                </Form.Item>
                {otherSourceBlock}
                <Form.Item label='Is Referral Partner' className='is-referral-partner'>
                    <Checkbox
                        checked={isReferralPartner}
                        disabled={!!isBroker}
                        onChange={this.onChangeIsReferralPartner}
                    />
                </Form.Item>
                {referralPartnerBlock}
                <Form.Item label='Comments' className='comments'>
                    <TextArea onChange={this.onChangeComments} value={comments} />
                </Form.Item>
            </Modal>
        );
    }

    private onSearchBroker(search: string): void {
        this.props.leadBrokerSearch(search);
    }

    private onChangeBdm(value: string) {
        this.setState({
            bdmUuid: value,
        });
    }

    private onChangeBroker(value: string) {
        const { brokers } = this.props;

        const broker: IBroker = brokers[value];

        this.setState({
            brokerUuid: (broker) ? value : null,
            email: (broker) ? broker.email : null,
            firstName: (broker) ? broker.firstName : null,
            lastName: (broker) ? broker.lastName : null,
            phone: (broker) ? broker.phone : null,
        });
        this.props.leadBrokerSearchClear();
    }

    private onChangeComments(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            comments: event.target.value,
        });
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            email: event.target.value,
        });
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            firstName: event.target.value,
        });
    }

    private onChangeIsBroker(event: CheckboxChangeEvent) {
        this.setState({
            isBroker: event.target.checked,
        });
    }

    private onChangeIsReferralPartner(event: CheckboxChangeEvent) {
        this.setState({
            isReferralPartner: event.target.checked,
        });
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            lastName: event.target.value,
        });
    }

    private onChangeLoanAmount(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            loanAmount: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeLoanPurpose(value: LoanPurposeEnum) {
        this.setState({
            loanPurpose: value,
        });
    }

    private onChangeMortgageType(value: MortgageTypeEnum) {
        this.setState({
            mortgageType: value,
        });
    }

    private onChangeOtherSource(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            otherSource: event.target.value.substr(0, 50),
        });
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            phone: event.target.value,
        });
    }

    private onChangePhoneSource(value: PhoneSourceEnum) {
        this.setState({
            phoneSource: value,
        });
    }

    private onChangeReferralPartner(value: string): void {
        this.setState({
            isReferralPartner: !!value,
            referralPartnerUuid: value,
        });
    }

    private onChangeReferralPartnerFirstName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            referralPartnerFirstName: event.target.value,
        });
    }

    private onChangeReferralPartnerLastName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            referralPartnerLastName: event.target.value,
        });
    }

    private onChangeReferralPartnerEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            referralPartnerEmail: event.target.value,
        });
    }

    private onChangeReferralPartnerPhone(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            referralPartnerPhone: event.target.value,
        });
    }

    private onChangeReferralPartnerCompanyName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            referralPartnerCompanyName: event.target.value,
        });
    }

    private onChangeRepaymentStrategy(value: RepaymentStrategyEnum) {
        this.setState({
            repaymentStrategy: value,
        });
    }

    private onChangeTermMonths(value: number) {
        this.setState({
            termMonths: value,
        });
    }

    private onClickOk() {
        let valid: boolean = true;

        valid = this.validateEmail() && valid;
        valid = this.validateFirstName() && valid;
        valid = this.validateLastName() && valid;
        valid = this.validateLoanAmount() && valid;
        valid = this.validateLoanPurpose() && valid;
        valid = this.validateOtherSource() && valid;
        valid = this.validatePhone() && valid;
        valid = this.validateTermMonths() && valid;

        if (!valid) {
            return;
        }

        const {
            bdmUuid,
            brokerUuid,
            comments,
            email,
            firstName,
            isBroker,
            isReferralPartner,
            lastName,
            loanAmount,
            loanPurpose,
            mortgageType,
            otherSource,
            phone,
            phoneSource,
            referralPartnerCompanyName,
            referralPartnerFirstName,
            referralPartnerLastName,
            referralPartnerEmail,
            referralPartnerPhone,
            referralPartnerUuid,
            repaymentStrategy,
            termMonths,
        } = this.state;

        this.setState({
            isAdding: true,
        });

        const deal: IDeal = {
            bdmUuid,
            brokerUuid,
            comments,
            email,
            firstName,
            isBroker,
            isReferralPartner,
            lastName,
            loanAmount,
            loanPurpose,
            mortgageType,
            otherSource,
            phone,
            phoneSource,
            referralPartnerCompanyName,
            referralPartnerEmail,
            referralPartnerFirstName,
            referralPartnerLastName,
            referralPartnerPhone,
            referralPartnerUuid,
            repaymentStrategy,
            termMonths,
        };

        this.props.leadAdd(deal);
    }

    private validateEmail(): boolean {
        const { email } = this.state;

        let error: string;

        if (!email || email.length === 0) {
            error = 'Please enter the email address';
        } else if (!validateEmail(email)) {
            error = 'Please enter a valid email address';
        }

        this.props.errorSet('email', error);

        return !error;
    }

    private validateFirstName(): boolean {
        const { firstName } = this.state;

        let error: string;

        if (!firstName || firstName.length === 0) {
            error = 'Please enter the first name';
        } else if (firstName.length < 2) {
            error = 'Please enter at least 2 characters';
        }

        this.props.errorSet('firstName', error);

        return !error;
    }

    private validateLastName(): boolean {
        const { lastName } = this.state;

        let error: string;

        if (!lastName || lastName.length === 0) {
            error = 'Please enter the last name';
        } else if (lastName.length < 2) {
            error = 'Please enter at least 2 characters';
        }

        this.props.errorSet('lastName', error);

        return !error;
    }

    private validateLoanAmount(): boolean {
        const { loanAmount } = this.state;

        let error: string;

        if (!loanAmount || loanAmount === 0) {
            error = 'Please enter the loan amount';
        }

        this.props.errorSet('loanAmount', error);

        return !error;
    }

    private validateLoanPurpose(): boolean {
        const { loanPurpose } = this.state;

        let error: string;

        if (!_.values(LoanPurposeEnum).includes(loanPurpose)) {
            error = 'Please select the loan purpose';
        }

        this.props.errorSet('loanPurpose', error);

        return !error;
    }

    private validateOtherSource(): boolean {
        const { otherSource, phoneSource } = this.state;

        let error: string;

        if (phoneSource && phoneSource === PhoneSourceEnum.Other && (!otherSource || otherSource.length === 0)) {
            error = 'Please enter the other source';
        }

        this.props.errorSet('otherSource', error);

        return !error;
    }

    private validatePhone(): boolean {
        const { phone } = this.state;

        let error: string;

        if (!phone || phone.length === 0) {
            error = 'Please enter the phone number';
        }

        this.props.errorSet('phone', error);

        return !error;
    }

    private validateTermMonths(): boolean {
        const { termMonths } = this.state;

        let error: string;

        if (!termMonths) {
            error = 'Please select the loan term';
        }

        this.props.errorSet('termMonths', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        administrators: administratorsSelector(state),
        brokerSearchLoading: leadBrokerSearchLoadingSelector(state),
        brokerSearchResults: leadBrokerSearchResultsSelector(state),
        brokers: brokersSelector(state, true),
        currentAdministrator: currentAdministratorSelector(state),
        errors: leadAddErrorsSelector(state),
        referralPartners: approvedReferralPartnersSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        errorSet: (key: keyof IDeal, value: string) => dispatch(leadAddErrorSetAction(key, value)),
        leadAdd: (deal: IDeal) => dispatch(leadAddAction(deal)),
        leadBrokerSearch: (keyword: string) => dispatch(leadBrokerSearchAction(keyword)),
        leadBrokerSearchClear: () => dispatch(leadBrokerSearchResultsClearAction()),
        referralPartnersList: () => dispatch(referralPartnersListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LeadAddModal);
