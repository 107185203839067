import { CheckOutlined, ClockCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Layout, Modal, Space, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { GrNew } from 'react-icons/gr';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import AccountApprovalStatusEnum from '~Api/Investor/AccountApprovalStatusEnum';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import accountTypeLabels from '~Api/Investor/accountTypeLabels';
import IAccount from '~Api/Investor/IAccount';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import {
    investorAccountPendingDepositApproveAction,
    pendingDepositsListAction,
} from './actions';
import PendingDepositRejectModal from './PendingDepositRejectModal';
import {
    pendingDepositsSelector,
} from './selectors';
import { currencyFormatter } from '~utilities/formatters';

interface IState {
    rejectDepositInvestorAccountUuid: string;
}

interface IPropsSelector {
    accounts: IDictionary<IAccount>;
    currentUser: IAuthUser;
}

interface IPropsDispatch {
    approve: (accountUuid: string, sendEmail: boolean) => void;
    pendingDepositsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class PendingDeposits extends React.Component<Props, IState> {
    public state: IState = {
        rejectDepositInvestorAccountUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickRejectDepositCancel = this.onClickRejectDepositCancel.bind(this);
        this.onClickApproveDeposit = this.onClickApproveDeposit.bind(this);
    }

    public componentDidMount(): void {
        this.props.pendingDepositsList();
    }

    public render(): JSX.Element {
        const { accounts, currentUser } = this.props;
        const { rejectDepositInvestorAccountUuid } = this.state;

        if (!accounts) {
            return (
                <Layout className='investors-pending-deposits'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/investors'>Investors</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Pending Deposits</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Pending Deposits</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const depositColumns: ColumnType<IAccount>[] = [
            {
                dataIndex: 'approvalStatus',
                render: (approvalStatus: AccountApprovalStatusEnum) => {
                    if (approvalStatus === AccountApprovalStatusEnum.Approved) {
                        return <CheckOutlined/>;
                    }

                    if (approvalStatus === AccountApprovalStatusEnum.Legacy) {
                        return <ClockCircleOutlined/>;
                    }

                    if (approvalStatus === AccountApprovalStatusEnum.Pending) {
                        return <GrNew/>;
                    }

                    if (approvalStatus === AccountApprovalStatusEnum.Rejected) {
                        return <CloseOutlined/>;
                    }
                },
                title: '',
                width: '5%',
            },
            {
                render: (account: IAccount): JSX.Element => <Link to={`/investors/${account.investorUuid}`}>{account.investorName}</Link>,
                title: 'Investor',
            },
            {
                dataIndex: 'accountType',
                filters: [
                    {
                        text: accountTypeLabels[AccountTypeEnum.IncomeTrust],
                        value: AccountTypeEnum.IncomeTrust,
                    },
                    {
                        text: accountTypeLabels[AccountTypeEnum.Marketplace],
                        value: AccountTypeEnum.Marketplace,
                    },
                ],
                onFilter: (value: string | number | boolean, account: IAccount) => account.accountType === value,
                render: (accountType: AccountTypeEnum, account: IAccount): JSX.Element => <Link to={`/investors/${account.investorUuid}/accounts/${account.uuid}`}>{accountTypeLabels[accountType]}</Link>,
                title: 'Account',
                width: '15%',
            },
            {
                dataIndex: 'code',
                title: 'Reference',
                width: '15%',
            },
            {
                dataIndex: 'depositAmountPending',
                render: (depositAmountPending: number): string => currencyFormatter.format(depositAmountPending),
                title: 'Amount',
                width: '10%',
            },
            {
                dataIndex: 'depositRequestTime',
                defaultSortOrder: 'descend',
                render: (depositRequestTime: string): string => dayjs(depositRequestTime).format('Do MMMM YYYY HH:mm:ss'),
                sorter: (a: IAccount, b: IAccount): number => {
                    return dayjs(a.depositRequestTime) > dayjs(b.depositRequestTime) ? 1 : -1;
                },
                title: 'Created',
                width: '15%',
            },
        ];

        if (currentUser.permissions.includes(PermissionsEnum.Accounts)) {
            depositColumns.push({
                render: (account: IAccount): JSX.Element => {
                    const onClickApprove: () => void = () => this.onClickApproveDeposit(account, false);
                    const onClickReject: () => void = () => this.setState({ rejectDepositInvestorAccountUuid: account.uuid });
                    const onClickApproveAndEmail: () => void = () => this.onClickApproveDeposit(account, true);

                    return (
                        <Space>
                            {account.accountType === AccountTypeEnum.Marketplace && <Button onClick={onClickApproveAndEmail} type='primary'>Approve + Email</Button>}
                            <Button onClick={onClickApprove} type={account.accountType === AccountTypeEnum.IncomeTrust ? 'primary' : 'default'}>Approve</Button>
                            <Button danger={true} onClick={onClickReject}>Reject</Button>
                        </Space>
                    );
                },
                title: 'Actions',
                width: '25%',
            });
        }

        return (
            <Layout className='investors-pending-deposits'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/investors'>Investors</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Pending Deposits</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Pending Deposits</Typography.Title>
                        <Table
                            columns={depositColumns}
                            dataSource={_.values(accounts)}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
                <PendingDepositRejectModal accountUuid={rejectDepositInvestorAccountUuid} onCancel={this.onClickRejectDepositCancel} />
            </Layout>
        );
    }

    private onClickRejectDepositCancel(): void {
        this.setState({
            rejectDepositInvestorAccountUuid: null,
        });
    }

    private onClickApproveDeposit(account: IAccount, sendEmail: boolean): void {
        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        Modal.confirm({
            content: `Are you sure you want to approve the ${currencyFormatter.format(account.depositAmountPending)} deposit for ${account.investorName}?`,
            okText: 'Approve',
            onOk: () => {
                this.props.approve(account.uuid, sendEmail);
            },
            title: 'Approve Deposit',
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        accounts: pendingDepositsSelector(state),
        currentUser: authCurrentUserSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        approve: (accountUuid: string, sendEmail: boolean) => dispatch(investorAccountPendingDepositApproveAction(accountUuid, sendEmail)),
        pendingDepositsList: () => dispatch(pendingDepositsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PendingDeposits);
