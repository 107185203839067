import { Modal, Tree } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
    administratorGetAction,
    administratorPermissionsSetAction,
} from '~Administrators/actions';
import permissionsTree from '~Administrators/permissionsTree';
import { administratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import { IGlobalState } from '~reducer';
import CheckedKeys, { CheckedKeyStructure } from '~utilities/CheckedKeys';

interface IState {
    permissions: PermissionsEnum[];
}

interface IProps {
    administratorUuid: string;
    close: () => void;
    open: boolean;
}

interface IPropsSelector {
    administrator: IAdministrator;
}

interface IPropsDispatch {
    administratorGet: () => void;
    administratorPermissions(permissions: PermissionsEnum[]): void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class PermissionsModal extends React.Component<Props, IState> {
    public state: IState = {
        permissions: null,
    };

    constructor(props: Props) {
        super(props);

        this.onCheckPermission = this.onCheckPermission.bind(this);
        this.onOk = this.onOk.bind(this);
    }

    public componentDidMount() {
        const { administrator } = this.props;

        if (!administrator) {
            this.props.administratorGet();
        }
    }

    public render(): JSX.Element {
        const { administrator, open } = this.props;
        const { permissions } = this.state;

        if (!administrator) {
            return null;
        }

        const expandedKeys: PermissionsEnum[] = [
            PermissionsEnum.Administrators,
            PermissionsEnum.Applications,
            PermissionsEnum.Advisers,
            PermissionsEnum.Brokers,
            PermissionsEnum.Investments,
            PermissionsEnum.Investors,
            PermissionsEnum.Leads,
            PermissionsEnum.Loans,
            PermissionsEnum.Reports,
            PermissionsEnum.Users,
            PermissionsEnum.Warehouses,
        ];

        return (
            <Modal
                okButtonProps={{ disabled: null === permissions }}
                onCancel={this.props.close}
                onOk={this.onOk}
                okText='Save'
                open={open}
                title='Permissions'
                wrapClassName='administrator-permissions-modal'
            >
                <Tree
                    checkStrictly={true}
                    checkable={true}
                    checkedKeys={null === permissions ? administrator.permissions : permissions}
                    expandedKeys={expandedKeys}
                    onCheck={this.onCheckPermission}
                    selectable={false}
                    treeData={permissionsTree}
                />
            </Modal>
        );
    }

    private onCheckPermission(checkedKeys: CheckedKeys): void {
        this.setState({
            permissions: (checkedKeys as CheckedKeyStructure).checked as PermissionsEnum[],
        });
    }

    private onOk(): void {
        const { permissions } = this.state;

        if (null !== permissions) {
            this.props.administratorPermissions(permissions);
        }

        this.props.close();
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        administrator: administratorSelector(state, ownProps.administratorUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        administratorGet: () => dispatch(administratorGetAction(ownProps.administratorUuid)),
        administratorPermissions: (permissions: PermissionsEnum[]) => dispatch(administratorPermissionsSetAction(ownProps.administratorUuid, permissions)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PermissionsModal);
