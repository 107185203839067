import { Form, InputNumber, Modal, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { ReactElement, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import { loanPrincipalReductionAction } from '~Loans/actions';
import DatePicker from '~UI/DatePicker';
import TimePicker from '~UI/TimePicker';

export default function PrincipalReductionModal(props: {
    loan: ILoan;
    isOpen: boolean;
    onCancel: () => void;
}): ReactElement {
    const { isOpen, loan, onCancel } = props;

    const [ amount, setAmount ] = useState<number>(null);
    const [ transactionDateTime, setTransactionDateTime ] = useState<string>(null);

    const dispatch: Dispatch = useDispatch();

    const onClickOk: () => void = useCallback(() => {
        dispatch(loanPrincipalReductionAction(loan.uuid, amount, dayjs(transactionDateTime || undefined).format('YYYY-MM-DDTHH:mm:ssZ')));
        onCancel();
    }, [amount, transactionDateTime]);

    const onChangeDate: (date: Dayjs) => void = useCallback((date: Dayjs) => {
        setTransactionDateTime(dayjs(`${date.format('YYYY-MM-DD')} ${dayjs(transactionDateTime || undefined).format('HH:mm')}`).format());
    }, [transactionDateTime]);

    const onChangeTime: (time: Dayjs) => void = useCallback((time: Dayjs) => {
        setTransactionDateTime(dayjs(`${dayjs(transactionDateTime || undefined).format('YYYY-MM-DD')} ${time.format('HH:mm')}`).format());
    }, [transactionDateTime]);

    const onChangeAmount: (value: number) => void = useCallback((value: number) => {
        setAmount(value);
    }, []);

    const transactionDateTimeDayjs: Dayjs = dayjs(transactionDateTime || undefined);

    return (
        <Modal
            destroyOnClose={true}
            okText='Record'
            okType='danger'
            onCancel={onCancel}
            onOk={onClickOk}
            open={isOpen}
            title='Principal Reduction'
            wrapClassName='loan-principal-reduction-modal'
        >
            <Form.Item className='transaction-date' label='Transaction Date'>
                <Space>
                    <DatePicker
                        className='transaction-pick-date'
                        onChange={onChangeDate}
                        value={transactionDateTimeDayjs || dayjs()}
                        format='DD/MM/YYYY'
                    />
                    <TimePicker
                        className='transaction-pick-time'
                        onChange={onChangeTime}
                        value={transactionDateTimeDayjs || dayjs()}
                        format='H:mm'
                    />
                </Space>
            </Form.Item>
            <Form.Item className='amount' label='Amount'>
                <InputNumber addonBefore='$' min={0} onChange={onChangeAmount} type='number' value={amount} />
            </Form.Item>
        </Modal>
    );
}
