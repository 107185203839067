import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '~Layout';
import Dashboard from './Dashboard';
import DischargeForecast from './DischargeForecast';
import Due from './Due';
import ExpiringInsurance from './ExpiringInsurance';
import Forecast from './Forecast';
import LegacyDashboard from './LegacyDashboard';
import List from './List';
import Loan from './Loan';
import MaturationReminders from './MaturationReminders';
import Drawdowns from './Drawdowns';

export default function Router(): ReactElement {
    return (
        <Layout topSection='loans'>
            <Switch>
                <Route exact={true} path='/loans' component={LegacyDashboard} />
                <Route exact={true} path='/loans/discharge-forecast' component={DischargeForecast} />
                <Route exact={true} path='/loans/drawdowns' component={Drawdowns} />
                <Route exact={true} path='/loans/due' component={Due} />
                <Route exact={true} path='/loans/elegant' component={Dashboard} />
                <Route exact={true} path='/loans/expiring-insurance' component={ExpiringInsurance} />
                <Route exact={true} path='/loans/forecast' component={Forecast} />
                <Route exact={true} path='/loans/list' component={List} />
                <Route exact={true} path='/loans/maturation-reminders' component={MaturationReminders} />
                <Route path='/loans/:uuid' component={Loan} />
            </Switch>
        </Layout>
    );
}
