import { all, call, put, takeEvery } from '@redux-saga/core/effects';
import _ from 'lodash';
import IInvestmentTransaction from '~Api/Investment/IInvestmentTransaction';
import { parseInvestmentTransaction } from '~Api/Investment/parsers';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import IInvestorAccountTransaction from '~Api/Investor/IInvestorAccountTransaction';
import {
    parseInvestorAccountInvestmentTransaction,
    parseInvestorAccountTransaction,
} from '~Api/Investor/parsers';
import ILoanTransaction from '~Api/Loan/ILoanTransaction';
import { parseLoanTransaction } from '~Api/Loan/parsers';
import IWarehouseLoanTransaction from '~Api/Warehouse/IWarehouseLoanTransaction';
import { parseWarehouseLoanTransaction } from '~Api/Warehouse/parsers';
import { IFetchResponse } from '~utilities/fetch';
import {
    IInvestorMovementsListAction,
    ILoanPaymentsListAction,
    ILoanTransactionWarehouseLoanTransactionsListAction,
    investorMovementsSetAction,
    IPaymentsFromInvestorsListAction,
    IPaymentsToInvestorsListAction,
    IWarehouseMovementsListAction,
    loanPaymentsSetAction,
    loanTransactionWarehouseLoanTransactionsSetAction,
    paymentsFromInvestorsSetAction,
    paymentsToInvestorsSetAction,
    warehouseMovementsSetAction,
} from './actions';
import AccountsActionsEnum from './ActionsEnum';
import {
    investmentTransactionsRecentRequest,
    investorAccountInvestmentTransactionsRecentInvestmentsRequest,
    investorAccountTransactionsRecentTransfersRequest,
    loanTransactionsRecentPaymentsRequest,
    loanTransactionWarehouseTransactionsRequest,
    warehouseLoanTransactionsRecentPrincipalRequest,
} from './requests';

function* investorMovementsList(action: IInvestorMovementsListAction): Iterator<any> {
    const investorAccountTransactionsRecentTransfersResponse: IFetchResponse = yield call(investorAccountTransactionsRecentTransfersRequest);
    const investorAccountTransactions: IInvestorAccountTransaction[] = yield Promise.all(investorAccountTransactionsRecentTransfersResponse.body.map(parseInvestorAccountTransaction));
    yield put(investorMovementsSetAction(investorAccountTransactions));
}

function* loanPaymentsList(action: ILoanPaymentsListAction): Iterator<any> {
    const loanTransactionsRecentPaymentsResponse: IFetchResponse = yield call(loanTransactionsRecentPaymentsRequest);
    const loanTransactions: ILoanTransaction[] = yield Promise.all(loanTransactionsRecentPaymentsResponse.body.map(parseLoanTransaction));
    yield put(loanPaymentsSetAction(loanTransactions));
}

function* loanTransactionWarehouseLoanTransactionsList(action: ILoanTransactionWarehouseLoanTransactionsListAction): Iterator<any> {
    const loanTransactionWarehouseLoanTransactionsListResponse: IFetchResponse = yield call(loanTransactionWarehouseTransactionsRequest, action.loanTransactionUuid);
    const warehouseLoanTransactions: IWarehouseLoanTransaction[] = yield Promise.all(loanTransactionWarehouseLoanTransactionsListResponse.body.map(parseWarehouseLoanTransaction));
    yield put(loanTransactionWarehouseLoanTransactionsSetAction(action.loanTransactionUuid, warehouseLoanTransactions));
}

function* paymentsFromInvestorsList(action: IPaymentsFromInvestorsListAction): Iterator<any> {
    const investorAccountInvestmentTransactionsRecentResponse: IFetchResponse = yield call(investorAccountInvestmentTransactionsRecentInvestmentsRequest);
    const investorAccountInvestmentTransactions: IInvestorAccountInvestmentTransaction[] = yield Promise.all(investorAccountInvestmentTransactionsRecentResponse.body.map(parseInvestorAccountInvestmentTransaction));
    yield put(paymentsFromInvestorsSetAction(investorAccountInvestmentTransactions));
}

function* paymentsToInvestorsList(action: IPaymentsToInvestorsListAction): Iterator<any> {
    const investmentTransactionsRecentResponse: IFetchResponse = yield call(investmentTransactionsRecentRequest);
    const investmentTransactions: IInvestmentTransaction[] = yield Promise.all(investmentTransactionsRecentResponse.body.map(parseInvestmentTransaction));
    yield put(paymentsToInvestorsSetAction(investmentTransactions));
}

function* warehouseMovementsList(action: IWarehouseMovementsListAction): Iterator<any> {
    const warehouseLoanTransactionsRecentPrincipalResponse: IFetchResponse = yield call(warehouseLoanTransactionsRecentPrincipalRequest);
    const warehouseLoanTransactions: IWarehouseLoanTransaction[] = yield Promise.all(warehouseLoanTransactionsRecentPrincipalResponse.body.map(parseWarehouseLoanTransaction));
    yield put(warehouseMovementsSetAction(warehouseLoanTransactions));
}

export function* AccountsSagas(): any {
    yield all([
        takeEvery(AccountsActionsEnum.InvestorMovementsList, investorMovementsList),
        takeEvery(AccountsActionsEnum.LoanPaymentsList, loanPaymentsList),
        takeEvery(AccountsActionsEnum.LoanTransactionWarehouseLoanTransactionsList, loanTransactionWarehouseLoanTransactionsList),
        takeEvery(AccountsActionsEnum.PaymentsFromInvestorsList, paymentsFromInvestorsList),
        takeEvery(AccountsActionsEnum.PaymentsToInvestorsList, paymentsToInvestorsList),
        takeEvery(AccountsActionsEnum.WarehouseMovementsList, warehouseMovementsList),
    ]);
}
