import { Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IBankAccount from '~Api/Investor/IBankAccount';
import { investorBankAccountsAddAction } from '~Investors/actions';
import { IDictionary } from '../../utilities/IDictionary';
import './investor.less';

interface IState {
    accountName: string;
    accountNumber: string;
    bankName: string;
    bsb: string;
    errors: IDictionary<string>;
}

interface IProps {
    investorUuid: string;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsDispatch {
    add: (bankAccount: IBankAccount) => void;
}

type Props = IProps & IPropsDispatch;

class BankAccountsAddModal extends React.Component<Props, IState> {
    public state: IState = {
        accountName: null,
        accountNumber: null,
        bankName: null,
        bsb: null,
        errors: {},
    };

    constructor(props: Props) {
        super(props);

        this.onClickOk = this.onClickOk.bind(this);

        this.onChangeAccountName = this.onChangeAccountName.bind(this);
        this.onChangeAccountNumber = this.onChangeAccountNumber.bind(this);
        this.onChangeBankName = this.onChangeBankName.bind(this);
        this.onChangeBsb = this.onChangeBsb.bind(this);

        this.setError = this.setError.bind(this);

        this.validateAccountName = this.validateAccountName.bind(this);
        this.validateAccountNumber = this.validateAccountNumber.bind(this);
        this.validateBankName = this.validateBankName.bind(this);
        this.validateBsb = this.validateBsb.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const {
            accountName,
            accountNumber,
            bankName,
            bsb,
            errors,
         } = this.state;

        return (
            <Modal
                destroyOnClose={true}
                okText='Add'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Bank Account'
                wrapClassName='investor-bank-account-add-modal'
            >
                <Form.Item className='bank-name' label='Bank Name' help={errors.bankName} validateStatus={errors.bankName && 'error'}>
                    <Input onChange={this.onChangeBankName} value={bankName} onBlur={this.validateBankName} />
                </Form.Item>
                <Form.Item className='account-name' label='Account Name' help={errors.accountName} validateStatus={errors.accountName && 'error'}>
                    <Input onChange={this.onChangeAccountName} value={accountName} onBlur={this.validateAccountName} />
                </Form.Item>
                <Form.Item className='bsb' label='BSB' help={errors.bsb} validateStatus={errors.bsb && 'error'}>
                    <Input onChange={this.onChangeBsb} value={bsb} onBlur={this.validateBsb} maxLength={6} />
                </Form.Item>
                <Form.Item className='account-number' label='Account Number' help={errors.accountNumber} validateStatus={errors.accountNumber && 'error'}>
                    <Input onChange={this.onChangeAccountNumber} value={accountNumber} onBlur={this.validateAccountNumber} maxLength={9} />
                </Form.Item>
            </Modal>
        );
    }

    private onClickOk(): void {
        let valid: boolean = true;

        valid = this.validateAccountName() && valid;
        valid = this.validateAccountNumber() && valid;
        valid = this.validateBankName() && valid;
        valid = this.validateBsb() && valid;

        if (!valid) {
            return;
        }

        const {
            accountName,
            accountNumber,
            bankName,
            bsb,
        } = this.state;

        const bankAccount: IBankAccount = {
            accountName,
            accountNumber,
            bankName,
            bsb,
        };

        this.props.add(bankAccount);
        this.props.onCancel();
    }

    private onChangeAccountName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            accountName: event.target.value ? event.target.value : null,
        });
    }

    private onChangeAccountNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.setState({
            accountNumber: event.target.value ? event.target.value : null,
        });
    }

    private onChangeBankName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            bankName: event.target.value ? event.target.value : null,
        });
    }

    private onChangeBsb(event: React.ChangeEvent<HTMLInputElement>): void {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.setState({
            bsb: event.target.value ? event.target.value : null,
        });
    }

    private validateAccountName(): boolean {
        const { accountName } = this.state;
        let error: string;

        if (!accountName) {
            error = 'Please enter the account name';
        }

        this.setError('accountName', error);

        return !error;
    }

    private validateAccountNumber(): boolean {
        const { accountNumber } = this.state;
        let error: string;

        if (!accountNumber) {
            error = 'Please enter the account number';
        } else if (accountNumber.length > 9) {
            error = 'Account number must not be more than 9 digits';
        }

        this.setError('accountNumber', error);

        return !error;
    }

    private validateBankName(): boolean {
        const { bankName } = this.state;
        let error: string;

        if (!bankName) {
            error = 'Please enter the bank name';
        }

        this.setError('bankName', error);

        return !error;
    }

    private validateBsb(): boolean {
        const { bsb } = this.state;
        let error: string;

        if (!bsb) {
            error = 'Please enter the BSB';
        } else if (bsb.length !== 6) {
            error = 'BSB number must be exactly 6 digits';
        }

        this.setError('bsb', error);

        return !error;
    }

    private setError(key: keyof IState['errors'], value: string): void {
        this.setState((previousState: IState) => ({
            errors: {
                ...previousState.errors,
                [key]: value,
            },
        }));
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        add: (bankAccount: IBankAccount) => dispatch(investorBankAccountsAddAction(ownProps.investorUuid, bankAccount)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(BankAccountsAddModal);
