enum ComplianceDocumentTypeEnum {
    AccountantCertificate = 'ACCOUNTANT_CERTIFICATE',
    CompanyFile = 'COMPANY_FILE',
    IdProof = 'ID_PROOF',
    IdentityVerification = 'IDENTITY_VERIFICATION',
    InformationMemorandum = 'IM',
    ProductDisclosureStatement = 'PDS',
    TargetMarketDetermination = 'TMD',
}

export default ComplianceDocumentTypeEnum;
