import { Descriptions, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import { investmentGetAction } from '~Investments/actions';
import { investmentSelector } from '~Investments/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    investment: IInvestment;
}

interface IPropsDispatch {
    investmentGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Overview extends React.Component<Props> {
    public componentDidMount(): void {
        const { investment } = this.props;

        if (!investment) {
            this.props.investmentGet();
        }
    }

    public render(): JSX.Element {
        const { investment, match } = this.props;

        if (!investment) {
            return (
                <Layout uuid={match.params.uuid} section='overview'>
                    <Typography.Title level={2}>Overview</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const photoBlock: JSX.Element = investment.photos.length > 0 && (
            <div className='photo'>
                <img src={investment.photos[0].url} />
            </div>
        );

        return (
            <Layout uuid={match.params.uuid} section='overview'>
                <Typography.Title level={2}>Overview</Typography.Title>
                {photoBlock}
                <Descriptions bordered={true} column={4} layout='vertical'>
                    <Descriptions.Item label='End Date'>{investment.endDate ? dayjs(investment.endDate).format('Do MMMM YYYY') : '-'}</Descriptions.Item>
                    <Descriptions.Item label='Property Value'>{currencyFormatter.format(investment.propertyValue)}</Descriptions.Item>
                    <Descriptions.Item label='Property Security'>{investment.propertySecurity || '-'}</Descriptions.Item>
                    <Descriptions.Item label='Property Type'>{investment.propertyType || '-'}</Descriptions.Item>
                    <Descriptions.Item label='Description' span={4}><span dangerouslySetInnerHTML={{ __html: investment.description || '-' }} /></Descriptions.Item>
                </Descriptions>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investment: investmentSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investmentGet: () => dispatch(investmentGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Overview);
