enum PropertyPurposeEnum {
    Develop = 'DEVELOP',
    Purchase = 'PURCHASE',
    PurchaseDevelop = 'PURCHASE_DEVELOP',
    Refinance = 'REFINANCE',
    Security = 'SECURITY',
    Sell = 'SELL',
}

export default PropertyPurposeEnum;
