import { Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IAdviser from '~Api/Adviser/IAdviser';
import { advisersAddAction } from './actions';
import './advisers.less';
import { validateEmail } from '~utilities/validators';

interface IState {
    afslNumber: string;
    authorisedRepresentativeNumber: string;
    companyName: string;
    dealerGroup: string;
    email: string;
    errors: {
        email?: string;
        phone?: string;
    };
    firstName: string;
    lastName: string;
    phone: string;
}

interface IProps {
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsDispatch {
    add: (adviser: IAdviser) => void;
}

type Props = IProps & IPropsDispatch;

class PropertyModal extends React.Component<Props, IState> {
    public state: IState = {
        afslNumber: null,
        authorisedRepresentativeNumber: null,
        companyName: null,
        dealerGroup: null,
        email: null,
        errors: {},
        firstName: null,
        lastName: null,
        phone: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAfslNumber = this.onChangeAfslNumber.bind(this);
        this.onChangeAuthorisedRepresentativeNumber = this.onChangeAuthorisedRepresentativeNumber.bind(this);
        this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
        this.onChangeDealerGroup = this.onChangeDealerGroup.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);

        this.validateEmail = this.validateEmail.bind(this);
        this.validatePhone = this.validatePhone.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const {
            afslNumber,
            authorisedRepresentativeNumber,
            companyName,
            dealerGroup,
            email,
            errors,
            firstName,
            lastName,
            phone,
        } = this.state;

        return (
            <Modal
                destroyOnClose={true}
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Adviser'
                width={700}
                wrapClassName='adviser-add-modal'
            >
                <Form.Item label='First Name' className='first-name'>
                    <Input onChange={this.onChangeFirstName} value={firstName} />
                </Form.Item>
                <Form.Item label='Last Name' className='last-name'>
                    <Input onChange={this.onChangeLastName} value={lastName} />
                </Form.Item>
                <Form.Item label='Phone' className='phone' help={errors.phone} validateStatus={errors.phone && 'error'}>
                    <Input onChange={this.onChangePhone} value={phone} />
                </Form.Item>
                <Form.Item label='Email' className='email' help={errors.email} validateStatus={errors.email && 'error'}>
                    <Input onChange={this.onChangeEmail} value={email} />
                </Form.Item>
                <Form.Item label='Company Name' className='company-name'>
                    <Input onChange={this.onChangeCompanyName} value={companyName} />
                </Form.Item>
                <Form.Item label='Dealer Group' className='dealer-group'>
                    <Input onChange={this.onChangeDealerGroup} value={dealerGroup} />
                </Form.Item>
                <Form.Item label='AFSL Number' className='afsl-number'>
                    <Input onChange={this.onChangeAfslNumber} value={afslNumber} />
                </Form.Item>
                <Form.Item label='Authorised Representative Number' className='authorised-representative-number'>
                    <Input onChange={this.onChangeAuthorisedRepresentativeNumber} value={authorisedRepresentativeNumber} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeAfslNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            afslNumber: event.target.value,
        });
    }

    private onChangeAuthorisedRepresentativeNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            authorisedRepresentativeNumber: event.target.value,
        });
    }

    private onChangeCompanyName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            companyName: event.target.value,
        });
    }

    private onChangeDealerGroup(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            dealerGroup: event.target.value,
        });
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            email: event.target.value,
        });
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            firstName: event.target.value,
        });
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            lastName: event.target.value,
        });
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            phone: event.target.value,
        });
    }

    private validateEmail(): boolean {
        const { email } = this.state;

        let error: string = null;

        if (!email || email.length === 0) {
            error = 'Please enter the email address';
        } else if (!validateEmail(email)) {
            error = 'Please enter a valid email address';
        }

        this.setState((previousState: IState) => ({
            errors: {
                ...previousState.errors,
                email: error,
            },
        }));

        return !error;
    }

    private validatePhone(): boolean {
        const { phone } = this.state;

        let error: string = null;

        if (!phone || phone.length === 0) {
            error = 'Please enter the phone number';
        }

        this.setState((previousState: IState) => ({
            errors: {
                ...previousState.errors,
                phone: error,
            },
        }));

        return !error;
    }

    private onClickOk(): void {
        const {
            afslNumber,
            authorisedRepresentativeNumber,
            companyName,
            dealerGroup,
            email,
            firstName,
            lastName,
            phone,
        } = this.state;

        let valid: boolean = true;

        valid = this.validateEmail() && valid;
        valid = this.validatePhone() && valid;

        if (!valid) {
            return;
        }

        const adviser: IAdviser = {
            afslNumber,
            authorisedRepresentativeNumber,
            companyName,
            dealerGroup,
            email,
            firstName,
            lastName,
            phone,
        };

        this.props.add(adviser);
        this.props.onCancel();
    }
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        add: (adviser: IAdviser) => dispatch(advisersAddAction(adviser)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(PropertyModal);
