import { all, call, debounce, put, select, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { notification } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { currentAdministratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import IInvestorIncomeTrustTransaction from '~Api/IncomeTrust/IInvestorIncomeTrustTransaction';
import { parseInvestorAccountIncomeTrust, parseInvestorIncomeTrustTransaction } from '~Api/IncomeTrust/parsers';
import {
    incomeTrustDivestRequest,
    incomeTrustInvestRequest,
    investorAccountIncomeTrustInterestPaymentStrategyRequest,
    investorAccountIncomeTrustTransactionsListRequest,
    investorAccountIncomeTrustsListRequest,
} from '~Api/IncomeTrust/requests';
import {
    investmentInvestRequest,
    investmentReserveRequest,
} from '~Api/Investment/requests';
import IAccount from '~Api/Investor/IAccount';
import IAnnualStatement from '~Api/Investor/IAnnualStatement';
import IBankAccount from '~Api/Investor/IBankAccount';
import IComplianceDocument from '~Api/Investor/IComplianceDocument';
import IDocument from '~Api/Investor/IDocument';
import IHistory from '~Api/Investor/IHistory';
import IIndividual from '~Api/Investor/IIndividual';
import IInvestor from '~Api/Investor/IInvestor';
import IInvestorAccountInvestment from '~Api/Investor/IInvestorAccountInvestment';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import IInvestorUser from '~Api/Investor/IInvestorUser';
import IMonthlyStatement from '~Api/Investor/IMonthlyStatement';
import INote from '~Api/Investor/INote';
import IPendingWithdrawal from '~Api/Investor/IPendingWithdrawal';
import IReferral from '~Api/Investor/IReferral';
import IShare from '~Api/Investor/IShare';
import ITransaction from '~Api/Investor/ITransaction';
import {
    parseAccount,
    parseAnnualStatement,
    parseBankAccount,
    parseIndividual,
    parseInvestor,
    parseInvestorAccountInvestment,
    parseInvestorAccountInvestmentTransaction,
    parseInvestorComplianceDocument,
    parseInvestorDocument,
    parseInvestorHistory,
    parseInvestorNote,
    parseInvestorPendingWithdrawal,
    parseInvestorUser,
    parseMonthlyStatement,
    parseReferral,
    parseShare,
    parseTransaction,
} from '~Api/Investor/parsers';
import {
    investorAccountAnnualStatementDeleteRequest,
    investorAccountAnnualStatementsAddRequest,
    investorAccountAnnualStatementsGenerateRequest,
    investorAccountAnnualStatementsListRequest,
    investorAccountApproveRequest,
    investorAccountDepositApproveRequest,
    investorAccountDepositRejectRequest,
    investorAccountDepositRequest,
    investorAccountInvestmentGetRequest,
    investorAccountInvestmentTransactionsListRequest,
    investorAccountInvestmentsListRequest,
    investorAccountMonthlyStatementDeleteRequest,
    investorAccountMonthlyStatementSendRequest,
    investorAccountMonthlyStatementsGenerateRequest,
    investorAccountMonthlyStatementsListRequest,
    investorAccountRejectRequest,
    investorAccountSharesListRequest,
    investorAccountTransactionsListRequest,
    investorAccountUnapproveRequest,
    investorAccountWithdrawRequest,
    investorAccountWithdrawalAbaInvestorTransactionsListRequest,
    investorAccountsIncomeTrustListRequest,
    investorAccountsPendingApprovalListRequest,
    investorAccountsPendingDepositsRequest,
    investorAssignAdviserRequest,
    investorAssignImRequest,
    investorBankAccountConfirmRequest,
    investorBankAccountUnconfirmRequest,
    investorBankAccountsAddRequest,
    investorComplianceDocumentDeleteRequest,
    investorComplianceDocumentsAddRequest,
    investorComplianceDocumentsListRequest,
    investorCreateIncomeTrustAccountRequest,
    investorDocumentsAddRequest,
    investorDocumentsListRequest,
    investorGetRequest,
    investorHistoriesListRequest,
    investorIndividualAddRequest,
    investorNextContactRequest,
    investorNotesAddRequest,
    investorNotesListRequest,
    investorPendingWithdrawalApproveRequest,
    investorPendingWithdrawalsUnapprovedListRequest,
    investorPrimaryBankAccountRequest,
    investorReferralAddRequest,
    investorReferralProcessRequest,
    investorReferralUnprocessRequest,
    investorReferralsInvestorListRequest,
    investorReferralsListRequest,
    investorUnassignAdviserRequest,
    investorUnassignImRequest,
    investorUpdateRequest,
    investorUsersListRequest,
    investorsBoardRequest,
    investorsListRequest,
    investorsManagedListRequest,
    investorsPrivateListRequest,
    investorsSearchRequest,
} from '~Api/Investor/requests';
import { IFetchResponse } from '~utilities/fetch';
import { renderNotificationLoadingIcon } from '~utilities/utils';
import {
    IInvestorAccountAnnualStatementDeleteAction,
    IInvestorAccountAnnualStatementsAddAction,
    IInvestorAccountAnnualStatementsGenerateAction,
    IInvestorAccountAnnualStatementsListAction,
    IInvestorAccountApproveAction,
    IInvestorAccountDepositAction,
    IInvestorAccountIncomeTrustDivestAction,
    IInvestorAccountIncomeTrustInterestPaymentStrategySetAction,
    IInvestorAccountIncomeTrustInvestAction,
    IInvestorAccountIncomeTrustTransactionsListAction,
    IInvestorAccountIncomeTrustsListAction,
    IInvestorAccountInvestAction,
    IInvestorAccountInvestmentGetAction,
    IInvestorAccountInvestmentTransactionsListAction,
    IInvestorAccountInvestmentsListAction,
    IInvestorAccountMonthlyStatementDeleteAction,
    IInvestorAccountMonthlyStatementSendAction,
    IInvestorAccountMonthlyStatementsGenerateAction,
    IInvestorAccountMonthlyStatementsListAction,
    IInvestorAccountPendingDepositApproveAction,
    IInvestorAccountPendingDepositRejectAction,
    IInvestorAccountRejectAction,
    IInvestorAccountReserveAction,
    IInvestorAccountSharesListAction,
    IInvestorAccountTransactionsListAction,
    IInvestorAccountUnapproveAction,
    IInvestorAccountWithdrawAction,
    IInvestorAccountWithdrawalAbaInvestorTransactionsListAction,
    IInvestorAccountsIncomeTrustListAction,
    IInvestorAccountsPendingApprovalListAction,
    IInvestorAssignAdviserAction,
    IInvestorAssignImAction,
    IInvestorBankAccountConfirmAction,
    IInvestorBankAccountsAddAction,
    IInvestorCompanyValueSetAction,
    IInvestorComplianceDocumentDeleteAction,
    IInvestorComplianceDocumentsAddAction,
    IInvestorComplianceDocumentsListAction,
    IInvestorContactDateAction,
    IInvestorCreateIncomeTrustAccountAction,
    IInvestorDocumentsAddAction,
    IInvestorDocumentsListAction,
    IInvestorGetAction,
    IInvestorHistoriesListAction,
    IInvestorIndividualAddAction,
    IInvestorIndividualValueSetAction,
    IInvestorNotesAddAction,
    IInvestorNotesListAction,
    IInvestorPendingWithdrawalApproveAction,
    IInvestorPendingWithdrawalsUnapprovedListAction,
    IInvestorPrimaryBankAccountAction,
    IInvestorReferralProcessAction,
    IInvestorReferralUnprocessAction,
    IInvestorReferralsAddAction,
    IInvestorReferralsInvestorListAction,
    IInvestorReferralsListAction,
    IInvestorSendAction,
    IInvestorTrustValueSetAction,
    IInvestorUnassignAdviserAction,
    IInvestorUnassignImAction,
    IInvestorUsersListAction,
    IInvestorValueSetAction,
    IInvestorsBoardAction,
    IInvestorsListAction,
    IInvestorsManagedListAction,
    IInvestorsPrivateListAction,
    IInvestorsSearchAction,
    IPendingDepositsListAction,
    investorAccountAnnualStatementSetAction,
    investorAccountAnnualStatementsSetAction,
    investorAccountIncomeTrustSetAction,
    investorAccountIncomeTrustTransactionsSetAction,
    investorAccountIncomeTrustsSetAction,
    investorAccountInvestmentSetAction,
    investorAccountInvestmentTransactionsSetAction,
    investorAccountInvestmentsListAction,
    investorAccountInvestmentsSetAction,
    investorAccountMonthlyStatementSetAction,
    investorAccountMonthlyStatementsSetAction,
    investorAccountSetAction,
    investorAccountSharesSetAction,
    investorAccountTransactionsSetAction,
    investorAccountWithdrawalAbaInvestorTransactionsSetAction,
    investorAccountsIncomeTrustSetAction,
    investorAccountsPendingApprovalSetAction,
    investorBankAccountSetAction,
    investorComplianceDocumentSetAction,
    investorComplianceDocumentsSetAction,
    investorDocumentSetAction,
    investorDocumentsSetAction,
    investorHistoriesSetAction,
    investorIndividualSetAction,
    investorNoteRemoveAction,
    investorNoteSetAction,
    investorNotesSetAction,
    investorPendingWithdrawalSetAction,
    investorPendingWithdrawalsUnapprovedSetAction,
    investorReferralSetAction,
    investorReferralsInvestorSetAction,
    investorReferralsSetAction,
    investorSendAction,
    investorSetAction,
    investorUsersSetAction,
    investorsBoardSetAction,
    investorsManagedSetAction,
    investorsPrivateSetAction,
    investorsSearchResultsSetAction,
    investorsSetAction,
    pendingDepositsSetAction,
} from './actions';
import InvestorsActionsEnum from './ActionsEnum';
import {
    investorAccountSelector,
    investorSelector,
} from './selectors';
import IWithdrawalAbaInvestorTransaction from '~Api/WithdrawalAba/IWithdrawalAbaInvestorTransaction';
import { parseWithdrawalAbaInvestorTransaction } from '~Api/WithdrawalAba/parsers';
import IInvestorAccountIncomeTrust from '~Api/IncomeTrust/IInvestorAccountIncomeTrust';

function* investorsBoard(action: IInvestorsBoardAction): Iterator<unknown> {
    const investorsBoardResponse: IFetchResponse = yield call(investorsBoardRequest);
    const investors: IInvestor[] = yield Promise.all(investorsBoardResponse.body.map(parseInvestor));
    yield put(investorsBoardSetAction(investors));
}

function* investorsList(action: IInvestorsListAction): Iterator<unknown> {
    const rawInvestors: IFetchResponse = yield call(investorsListRequest);
    const investors: IInvestor[] = yield Promise.all(rawInvestors.body.map(parseInvestor));
    yield put(investorsSetAction(investors));
}

function* investorsSearch(action: IInvestorsSearchAction): Iterator<unknown> {
    const investorsSearchResponse: IFetchResponse = yield call(investorsSearchRequest, action.keyword);
    const investors: IInvestor[] = yield Promise.all(investorsSearchResponse.body.map(parseInvestor));
    yield put(investorsSearchResultsSetAction(investors));
}

function* investorsPrivateList(action: IInvestorsPrivateListAction): Iterator<unknown> {
    const listResponse: IFetchResponse = yield call(investorsPrivateListRequest);
    const investors: IInvestor[] = yield Promise.all(listResponse.body.map(parseInvestor));
    yield put(investorsPrivateSetAction(investors));
}

function* investorAccountApprove(action: IInvestorAccountApproveAction): Iterator<unknown> {
    const key: string = `investorAccountApprove ${action.accountUuid}`;
    const message: string = 'Approve Account';

    notification.open({
        description: 'Approving account...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const accountApproveResponse: IFetchResponse = yield call(investorAccountApproveRequest, action.accountUuid);

    if (accountApproveResponse.status === 422) {
        notification.error({
            description: `There was a problem approving the account: ${_.values(accountApproveResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const account: IAccount = parseAccount(accountApproveResponse.body);
        yield put(investorAccountSetAction(account));

        notification.success({
            description: 'The account has been approved.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorAccountReject(action: IInvestorAccountRejectAction): Iterator<unknown> {
    const key: string = `investorAccountReject ${action.accountUuid}`;
    const message: string = 'Reject Account';

    notification.open({
        description: 'Rejecting account...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const accountRejectResponse: IFetchResponse = yield call(investorAccountRejectRequest, action.accountUuid);

    if (accountRejectResponse.status === 422) {
        notification.error({
            description: `There was a problem rejecting the account: ${_.values(accountRejectResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const account: IAccount = parseAccount(accountRejectResponse.body);
        yield put(investorAccountSetAction(account));

        notification.success({
            description: 'The account has been rejected.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorAccountUnapprove(action: IInvestorAccountUnapproveAction): Iterator<unknown> {
    const key: string = `investorAccountUnapprove ${action.accountUuid}`;
    const message: string = 'Unapprove Account';

    notification.open({
        description: 'Unapproving account...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const accountApproveResponse: IFetchResponse = yield call(investorAccountUnapproveRequest, action.accountUuid);

    if (accountApproveResponse.status === 422) {
        notification.error({
            description: `There was a problem unapproving the account: ${_.values(accountApproveResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const account: IAccount = parseAccount(accountApproveResponse.body);
        yield put(investorAccountSetAction(account));

        notification.success({
            description: 'The account has been unapproved.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorAccountDeposit(action: IInvestorAccountDepositAction): Iterator<unknown> {
    const key: string = `investorAccountDeposit ${action.accountUuid}`;

    notification.open({
        description: 'Adding deposit request...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Deposit',
    });

    const depositResponse: IFetchResponse = yield call(investorAccountDepositRequest, action.accountUuid, action.amount);

    if (depositResponse.status === 422) {
        notification.error({
            description: `There was a problem creating the deposit request: ${_.values(depositResponse.body)[0]}.`,
            duration: 0,
            key,
            message: 'Deposit',
        });
    } else {
        const account: IAccount = parseAccount(depositResponse.body);
        yield put(investorAccountSetAction(account));

        notification.success({
            description: 'The deposit request has been added.',
            duration: 4.5,
            key,
            message: 'Deposit',
        });
    }
}

function* investorAccountIncomeTrustInterestPaymentStrategySet(action: IInvestorAccountIncomeTrustInterestPaymentStrategySetAction): Iterator<unknown> {
    const key: string = `investorAccountIncomeTrustInterestPaymentStrategy ${dayjs().format()}`;
    const message: string = 'Interest Payment Strategy';

    notification.open({
        description: 'Updating...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const response: IFetchResponse = yield call(investorAccountIncomeTrustInterestPaymentStrategyRequest, action.investorAccountIncomeTrustUuid, action.interestPaymentStrategy);

    if (response.status === 422) {
        notification.error({
            description: `There was a problem updating the interest payment strategy: ${_.values(response.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        notification.success({
            description: 'Successfully updated.',
            duration: 4.5,
            key,
            message,
        });

        const investorAccountIncomeTrust: IInvestorAccountIncomeTrust = parseInvestorAccountIncomeTrust(response.body);
        yield put(investorAccountIncomeTrustSetAction(investorAccountIncomeTrust));
    }
}

function* investorAccountIncomeTrustDivest(action: IInvestorAccountIncomeTrustDivestAction): Iterator<unknown> {
    const key: string = `investorAccountIncomeTrustDivest ${dayjs().format()}`;
    const message: string = 'Divest from Income Trust';

    notification.open({
        description: 'Processing divestment...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const incomeTrustDivestResponse: IFetchResponse = yield call(incomeTrustDivestRequest, action.investorAccountIncomeTrustUuid, action.amount);

    if (incomeTrustDivestResponse.status === 422) {
        notification.error({
            description: `There was a problem divesting from the income trust: ${_.values(incomeTrustDivestResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const investorAccountIncomeTrust: IInvestorAccountIncomeTrust = parseInvestorAccountIncomeTrust(incomeTrustDivestResponse.body);
        yield put(investorAccountIncomeTrustSetAction(investorAccountIncomeTrust));

        notification.success({
            description: 'The divestment was successful.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorAccountIncomeTrustInvest(action: IInvestorAccountIncomeTrustInvestAction): Iterator<unknown> {
    const key: string = `investorAccountIncomeTrustInvest ${dayjs().format()}`;
    const message: string = 'Invest in Income Trust';

    notification.open({
        description: 'Processing investment...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const incomeTrustInvestResponse: IFetchResponse = yield call(incomeTrustInvestRequest, action.investorAccountIncomeTrustUuid, action.amount, action.sendEmail);
    if (incomeTrustInvestResponse.status === 422) {
        notification.error({
            description: `There was a problem investing in the income trust: ${_.values(incomeTrustInvestResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const investorAccountIncomeTrust: IInvestorAccountIncomeTrust = parseInvestorAccountIncomeTrust(incomeTrustInvestResponse.body);
        yield put(investorAccountIncomeTrustSetAction(investorAccountIncomeTrust));
        notification.success({
            description: 'The investment was successful.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorAccountIncomeTrustsList(action: IInvestorAccountIncomeTrustsListAction): Iterator<unknown> {
    const investorAccountIncomeTrustsListResponse: IFetchResponse = yield call(investorAccountIncomeTrustsListRequest, action.investorAccountUuid);
    const incomeTrusts: IInvestorAccountIncomeTrust[] = yield Promise.all(investorAccountIncomeTrustsListResponse.body.map(parseInvestorAccountIncomeTrust));
    yield put(investorAccountIncomeTrustsSetAction(action.investorAccountUuid, incomeTrusts));
}

function* investorAccountIncomeTrustTransactionsList(action: IInvestorAccountIncomeTrustTransactionsListAction): Iterator<unknown> {
    const transactionsListResponse: IFetchResponse = yield call(investorAccountIncomeTrustTransactionsListRequest, action.investorAccountIncomeTrustUuid);
    const transactions: IInvestorIncomeTrustTransaction[] = yield Promise.all(transactionsListResponse.body.map(parseInvestorIncomeTrustTransaction));
    yield put(investorAccountIncomeTrustTransactionsSetAction(action.investorAccountIncomeTrustUuid, transactions));
}

function* investorAccountInvestmentGet(action: IInvestorAccountInvestmentGetAction): Iterator<unknown> {
    const investorAccountInvestmentGetResponse: IFetchResponse = yield call(investorAccountInvestmentGetRequest, action.investorAccountInvestmentUuid);
    const investorAccountInvestment: IInvestorAccountInvestment = yield call(parseInvestorAccountInvestment, investorAccountInvestmentGetResponse.body);
    yield put(investorAccountInvestmentSetAction(investorAccountInvestment));
}

function* investorAccountInvestmentsList(action: IInvestorAccountInvestmentsListAction): Iterator<unknown> {
    const investorAccountInvestmentsListResponse: IFetchResponse = yield call(investorAccountInvestmentsListRequest, action.accountUuid);
    const investorAccountInvestments: IInvestorAccountInvestment[] = yield Promise.all(investorAccountInvestmentsListResponse.body.map(parseInvestorAccountInvestment));
    yield put(investorAccountInvestmentsSetAction(action.accountUuid, investorAccountInvestments));
}

function* investorAccountInvestmentTransactionsList(action: IInvestorAccountInvestmentTransactionsListAction): Iterator<unknown> {
    const investorAccountInvestmentTransactionsListResponse: IFetchResponse = yield call(investorAccountInvestmentTransactionsListRequest, action.investorAccountInvestmentUuid);
    const investorAccountInvestmentTransactions: IInvestorAccountInvestmentTransaction[] = yield Promise.all(investorAccountInvestmentTransactionsListResponse.body.map(parseInvestorAccountInvestmentTransaction));
    yield put(investorAccountInvestmentTransactionsSetAction(action.investorAccountInvestmentUuid, investorAccountInvestmentTransactions));
}

function* investorAccountPendingDepositApprove(action: IInvestorAccountPendingDepositApproveAction): Iterator<unknown> {
    const key: string = `investorAccountPendingDepositApprove ${action.investorAccountUuid}`;

    notification.open({
        description: 'Approving deposit...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Approve Deposit',
    });

    const approveResponse: IFetchResponse = yield call(investorAccountDepositApproveRequest, action.investorAccountUuid, action.sendEmail);

    if (approveResponse.status === 422) {
        notification.error({
            description: `There was a problem approving the deposit: ${_.values(approveResponse.body)[0]}.`,
            duration: 0,
            key,
            message: 'Approve Deposit',
        });
    } else {
        const investorAccount: IAccount = parseAccount(approveResponse.body);
        yield put(investorAccountSetAction(investorAccount));

        notification.success({
            description: 'The deposit has been approved.',
            duration: 4.5,
            key,
            message: 'Approve Deposit',
        });
    }
}

function* investorAccountPendingDepositReject(action: IInvestorAccountPendingDepositRejectAction): Iterator<unknown> {
    const key: string = `investorAccountPendingDepositReject ${action.investorAccountUuid}`;

    notification.open({
        description: 'Rejecting deposit...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Reject Deposit',
    });

    const rejectResponse: IFetchResponse = yield call(investorAccountDepositRejectRequest, action.investorAccountUuid, action.reason);

    if (rejectResponse.status === 422) {
        notification.error({
            description: `There was a problem rejecting the deposit: ${_.values(rejectResponse.body)[0]}.`,
            duration: 0,
            key,
            message: 'Reject Deposit',
        });
    } else {
        const investorAccount: IAccount = parseAccount(rejectResponse.body);
        yield put(investorAccountSetAction(investorAccount));

        notification.success({
            description: 'The deposit has been rejected.',
            duration: 4.5,
            key,
            message: 'Reject Deposit',
        });
    }
}

function* investorPendingWithdrawalsUnapprovedList(action: IInvestorPendingWithdrawalsUnapprovedListAction): Iterator<unknown> {
    const investorPendingWithdrawalsUnapprovedListResponse: IFetchResponse = yield call(investorPendingWithdrawalsUnapprovedListRequest);
    const investorPendingWithdrawalsUnapproved: IPendingWithdrawal[] = yield Promise.all(investorPendingWithdrawalsUnapprovedListResponse.body.map(parseInvestorPendingWithdrawal));

    yield put(investorPendingWithdrawalsUnapprovedSetAction(investorPendingWithdrawalsUnapproved));
}

function* investorPendingWithdrawalApprove(action: IInvestorPendingWithdrawalApproveAction): Iterator<unknown> {
    const key: string = `investorPendingWithdrawalApprove ${action.pendingWithdrawalUuid}`;
    const message: string = 'Approve Withdrawal';

    notification.open({
        description: 'Approving withdrawal...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const approveResponse: IFetchResponse = yield call(investorPendingWithdrawalApproveRequest, action.pendingWithdrawalUuid);

    if (approveResponse.status === 422) {
        notification.error({
            description: `There was a problem approving the withdrawal: ${_.values(approveResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const pendingWithdrawal: IPendingWithdrawal = parseInvestorPendingWithdrawal(approveResponse.body);
        yield put(investorPendingWithdrawalSetAction(pendingWithdrawal));

        notification.success({
            description: 'The withdrawal has been approved.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorAccountInvest(action: IInvestorAccountInvestAction): Iterator<unknown> {
    const key: string = `investorAccountInvest ${dayjs().format()}`;
    const message: string = 'Invest';

    notification.open({
        description: 'Investing...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const account: IAccount = yield select(investorAccountSelector, action.accountUuid);
    const investResponse: IFetchResponse = yield call(investmentInvestRequest, action.investmentUuid, account.uuid, action.amount);

    if (investResponse.status === 422) {
        notification.error({
            description: `There was a problem adding the investment: ${_.values(investResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(investorAccountInvestmentsListAction(action.accountUuid));

        notification.success({
            description: 'The investment has been added.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorAccountReserve(action: IInvestorAccountReserveAction): Iterator<unknown> {
    const key: string = `investorAccountReserve ${dayjs().format()}`;
    const message: string = 'Reserve';

    notification.open({
        description: 'Reserving...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const reserveResponse: IFetchResponse = yield call(investmentReserveRequest, action.investmentUuid, action.accountUuid, action.amount);

    if (reserveResponse.status === 422) {
        notification.error({
            description: `There was a problem reserving the investment: ${_.values(reserveResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        notification.success({
            description: 'The reservation has been added.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorAccountSharesList(action: IInvestorAccountSharesListAction): Iterator<unknown> {
    const rawShares: IFetchResponse = yield call(investorAccountSharesListRequest, action.accountUuid);
    const shares: IShare[] = yield Promise.all(rawShares.body.map(parseShare));
    yield put(investorAccountSharesSetAction(action.accountUuid, shares));
}

function* investorAccountTransactionsList(action: IInvestorAccountTransactionsListAction): Iterator<unknown> {
    const transactionsListResponse: IFetchResponse = yield call(investorAccountTransactionsListRequest, action.accountUuid);
    const transactions: ITransaction[] = yield Promise.all(transactionsListResponse.body.map(parseTransaction));
    yield put(investorAccountTransactionsSetAction(action.accountUuid, transactions));
}

function* investorAccountWithdraw(action: IInvestorAccountWithdrawAction): Iterator<unknown> {
    const key: string = `investorAccountWithdraw ${action.accountUuid}`;

    notification.open({
        description: 'Adding withdrawal request...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Withdraw',
    });

    const withdrawResponse: IFetchResponse = yield call(investorAccountWithdrawRequest, action.accountUuid, action.amount);

    if (withdrawResponse.status === 422) {
        notification.error({
            description: `There was a problem creating the withdraw request: ${_.values(withdrawResponse.body)[0]}.`,
            duration: 0,
            key,
            message: 'Withdraw',
        });
    } else {
        const account: IAccount = parseAccount(withdrawResponse.body);
        yield put(investorAccountSetAction(account));

        notification.success({
            description: 'The withdraw request has been added.',
            duration: 4.5,
            key,
            message: 'Withdraw',
        });
    }
}

function* investorAccountWithdrawalAbaInvestorTransactionsList(action: IInvestorAccountWithdrawalAbaInvestorTransactionsListAction): Iterator<unknown> {
    const investorAccountWithdrawalAbaInvestorTransactionsListResponse: IFetchResponse = yield call(investorAccountWithdrawalAbaInvestorTransactionsListRequest, action.investorAccountUuid);
    const investorAccountWithdrawalAbaInvestorTransactions: IWithdrawalAbaInvestorTransaction[] = yield Promise.all(investorAccountWithdrawalAbaInvestorTransactionsListResponse.body.map(parseWithdrawalAbaInvestorTransaction));
    yield put(investorAccountWithdrawalAbaInvestorTransactionsSetAction (action.investorAccountUuid, investorAccountWithdrawalAbaInvestorTransactions));
}

function* investorAssignAdviser(action: IInvestorAssignAdviserAction): Iterator<unknown> {
    const key: string = `investorAssignAdviser ${action.investorUuid}`;
    const message: string = 'Assign adviser to investor';

    notification.open({
        description: 'Assigning adviser to investor...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const investorAssignAdviserResponse: IFetchResponse = yield call(investorAssignAdviserRequest, action.investorUuid, action.adviserUuid);
    if (investorAssignAdviserResponse.status === 422) {
        notification.error({
            description: `There was a problem assigning the adviser: ${_.values(investorAssignAdviserResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const updatedInvestor: IInvestor = yield parseInvestor(investorAssignAdviserResponse.body);
        yield put(investorSetAction(updatedInvestor));

        notification.success({
            description: 'The adviser has been assigned.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorAssignIm(action: IInvestorAssignImAction): Iterator<unknown> {
    const key: string = `investorAssignIm ${action.investorUuid}`;
    const message: string = 'Assign IM to investor';

    notification.open({
        description: 'Assigning IM to investor...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const investorAssignImResponse: IFetchResponse = yield call(investorAssignImRequest, action.investorUuid, action.administratorUuid);
    if (investorAssignImResponse.status === 422) {
        notification.error({
            description: `There was a problem assigning the IM: ${_.values(investorAssignImResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const updatedInvestor: IInvestor = yield parseInvestor(investorAssignImResponse.body);
        yield put(investorSetAction(updatedInvestor));

        notification.success({
            description: 'The IM has been assigned.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorBankAccountsAdd(action: IInvestorBankAccountsAddAction): Iterator<unknown> {
    const key: string = `investorBankAccountsAdd ${dayjs().format()}`;

    const message = 'Add Bank Account';

    notification.open({
        description: 'Adding bank account...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const bankAccountsAddResponse: IFetchResponse = yield call(investorBankAccountsAddRequest, action.investorUuid, action.bankAccount);

    if (bankAccountsAddResponse.status === 422) {
        notification.error({
            description: `There was a problem adding the bank account: ${_.values(bankAccountsAddResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const bankAccount: IBankAccount = parseBankAccount(bankAccountsAddResponse.body);
        yield put(investorBankAccountSetAction(bankAccount));

        notification.success({
            description: 'The bank account has been added.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorBankAccountConfirm(action: IInvestorBankAccountConfirmAction): Iterator<unknown> {
    const key: string = `investorBankAccountConfirm ${action.bankAccountUuid}`;
    const message: string = 'Confirm Investor\'s Bank Details';

    notification.open({
        description: 'Confirming account details...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const investorBankAccountConfirmResponse: IFetchResponse = yield call(investorBankAccountConfirmRequest, action.bankAccountUuid);

    if (investorBankAccountConfirmResponse.status === 422) {
        notification.error({
            description: `There was a problem confirming the bank accounts details: ${_.values(investorBankAccountConfirmResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const investorBankAccount: IBankAccount = parseBankAccount(investorBankAccountConfirmResponse.body);
        yield put(investorBankAccountSetAction(investorBankAccount));

        notification.success({
            description: 'The investor\'s bank details have been confirmed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorBankAccountUnconfirm(action: IInvestorBankAccountConfirmAction): Iterator<unknown> {
    const key: string = `investorBankAccountUnconfirm ${action.bankAccountUuid}`;
    const message: string = 'Unconfirm Investor\'s Bank Details';

    notification.open({
        description: 'Updating confirmation status of account details...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const investorBankAccountUnconfirmResponse: IFetchResponse = yield call(investorBankAccountUnconfirmRequest, action.bankAccountUuid);

    if (investorBankAccountUnconfirmResponse.status === 422) {
        notification.error({
            description: `There was a problem unconfirming the bank accounts details: ${_.values(investorBankAccountUnconfirmResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const investorBankAccount: IBankAccount = parseBankAccount(investorBankAccountUnconfirmResponse.body);
        yield put(investorBankAccountSetAction(investorBankAccount));

        notification.success({
            description: 'The investor\'s bank details have been unconfirmed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorCompanyValueSet(action: IInvestorCompanyValueSetAction): Iterator<unknown> {
    yield put(investorSendAction(action.uuid));
}

function* investorComplianceDocumentsAdd(action: IInvestorComplianceDocumentsAddAction): Iterator<unknown> {
    const key: string = `investorComplianceDocumentsAdd ${dayjs().format()}`;
    const message: string = 'Add Compliance Document';

    notification.open({
        description: 'Adding compliance document...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const documentAddResponse: IFetchResponse = yield call(investorDocumentsAddRequest, action.investorUuid, action.file);
    const document: IDocument = parseInvestorDocument(documentAddResponse.body);
    yield put(investorDocumentSetAction(action.investorUuid, document));

    const complianceDocumentAddResponse: IFetchResponse = yield call(investorComplianceDocumentsAddRequest, action.investorUuid, {
        ...action.complianceDocument,
        investorFileUuid: document.uuid,
    });
    const conditionDocument = parseInvestorComplianceDocument(complianceDocumentAddResponse.body);
    yield put(investorComplianceDocumentSetAction(action.investorUuid, conditionDocument));

    notification.success({
        description: 'The compliance document has been added.',
        duration: 4.5,
        key,
        message,
    });
}

function* investorComplianceDocumentsList(action: IInvestorComplianceDocumentsListAction): Iterator<unknown> {
    const complianceDocumentsListResponse: IFetchResponse = yield call(investorComplianceDocumentsListRequest, action.investorUuid);
    const complianceDocuments: IComplianceDocument[] = yield Promise.all(complianceDocumentsListResponse.body.map(parseInvestorComplianceDocument));
    yield put(investorComplianceDocumentsSetAction(action.investorUuid, complianceDocuments));
}

function* investorComplianceDocumentDelete(action: IInvestorComplianceDocumentDeleteAction): Iterator<unknown> {
    const key: string = `investorComplianceDocumentDelete ${action.complianceDocumentUuid}`;
    const message: string = 'Delete Compliance Document';

    notification.open({
        description: 'Deleting compliance document...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const documentDeleteResponse: IFetchResponse = yield call(investorComplianceDocumentDeleteRequest, action.complianceDocumentUuid);

    notification.success({
        description: 'The compliance document has been deleted.',
        duration: 4.5,
        key,
        message,
    });
}

function* investorContactDate(action: IInvestorContactDateAction): Iterator<unknown> {
    const key: string = `investorContactDate ${action.investorUuid}`;

    notification.open({
        description: 'Updating...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Next Contact',
    });

    yield call(investorNextContactRequest, action.investorUuid, action.date);

    notification.success({
        description: 'The next contact date has been updated.',
        duration: 4.5,
        key,
        message: 'Next Contact',
    });
}

function* investorCreateIncomeTrustAccount(action: IInvestorCreateIncomeTrustAccountAction): Iterator<unknown> {
    const key: string = `investorCreateIncomeTrustAccount ${action.investorUuid}`;
    const message: string = 'Create Income Trust Account';

    notification.open({
        description: 'Creating Income Trust account...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const rawInvestor: IFetchResponse = yield call(investorCreateIncomeTrustAccountRequest, action.investorUuid);
    const investor: IInvestor = parseInvestor(rawInvestor.body);
    yield put(investorSetAction(investor));

    notification.success({
        description: 'The Income Trust account has been created.',
        duration: 4.5,
        key,
        message,
    });
}

function* investorDocumentsAdd(action: IInvestorDocumentsAddAction): Iterator<unknown> {
    const key: string = `investorDocumentsAdd ${dayjs().format()}`;

    notification.open({
        description: 'Adding document...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Document',
    });

    const rawDocument: IFetchResponse = yield call(investorDocumentsAddRequest, action.investorUuid, action.file);
    const document: IDocument = parseInvestorDocument(rawDocument.body);
    yield put(investorDocumentSetAction(action.investorUuid, document));

    notification.success({
        description: 'The document has been added.',
        duration: 4.5,
        key,
        message: 'Add Document',
    });
}

function* investorDocumentsList(action: IInvestorDocumentsListAction): Iterator<unknown> {
    const rawDocuments: IFetchResponse = yield call(investorDocumentsListRequest, action.investorUuid);
    const documents: IDocument[] = yield Promise.all(rawDocuments.body.map(parseInvestorDocument));
    yield put(investorDocumentsSetAction(action.investorUuid, documents));
}

function* investorGet(action: IInvestorGetAction): Iterator<unknown> {
    const rawInvestor: IFetchResponse = yield call(investorGetRequest, action.uuid);
    const investor: IInvestor = parseInvestor(rawInvestor.body);
    yield put(investorSetAction(investor));
}

function* investorHistoriesList(action: IInvestorHistoriesListAction): Iterator<unknown> {
    const rawHistories: IFetchResponse = yield call(investorHistoriesListRequest, action.uuid);
    const histories: IHistory[] = yield Promise.all(rawHistories.body.map(parseInvestorHistory));
    yield put(investorHistoriesSetAction(action.uuid, histories));
}

function* investorIndividualAdd(action: IInvestorIndividualAddAction): Iterator<unknown> {
    const key: string = `investorIndividualAdd ${action.individual.investorUuid}`;

    notification.open({
        description: 'Adding individual...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Individual',
    });

    const investorIndividualAddResponse: IFetchResponse = yield call(investorIndividualAddRequest, action.individual);
    const investorIndividual: IIndividual = parseIndividual(investorIndividualAddResponse.body);
    yield put(investorIndividualSetAction(investorIndividual));

    notification.success({
        description: 'The individual has been added.',
        duration: 4.5,
        key,
        message: 'Add Individual',
    });
}

function* investorIndividualValueSet(action: IInvestorIndividualValueSetAction): Iterator<unknown> {
    yield put(investorSendAction(action.investorUuid));
}

function* investorPrimaryBankAccount(action: IInvestorPrimaryBankAccountAction): Iterator<unknown> {
    const key: string = `investorPrimaryBankAccount ${action.bankAccountUuid}`;
    const message: string = 'Set the Investor\'s Primary Bank Account';

    notification.open({
        description: 'Marking as primary account...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const investorPrimaryBankAccountResponse: IFetchResponse = yield call(investorPrimaryBankAccountRequest, action.bankAccountUuid, action.investorUuid);

    if (investorPrimaryBankAccountResponse.status === 422) {
        notification.error({
            description: `There was an error marking the account as primary: ${_.values(investorPrimaryBankAccountResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const investor: IInvestor = parseInvestor(investorPrimaryBankAccountResponse.body);
        yield put(investorSetAction(investor));

        notification.success({
            description: 'The account was marked as primary account.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorReferralsAdd(action: IInvestorReferralsAddAction): Iterator<unknown> {
    const key: string = `investorReferralAdd ${action.uuid}`;

    notification.open({
        description: 'Adding referral...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Referral',
    });

    const rawReferral: IFetchResponse = yield call(investorReferralAddRequest, action.uuid, action.referralUuid);
    const referral: IReferral = parseReferral(rawReferral.body);
    yield put(investorReferralSetAction(referral));

    notification.success({
        description: 'The referral has been added.',
        duration: 4.5,
        key,
        message: 'Add Referral',
    });
}

function* investorReferralsInvestorList(action: IInvestorReferralsInvestorListAction): Iterator<unknown> {
    const rawReferrals: IFetchResponse = yield call(investorReferralsInvestorListRequest, action.uuid);
    const referrals: IReferral[] = yield Promise.all(rawReferrals.body.map(parseReferral));
    yield put(investorReferralsInvestorSetAction(action.uuid, referrals));
}

function* investorReferralsList(action: IInvestorReferralsListAction): Iterator<unknown> {
    const rawReferrals: IFetchResponse = yield call(investorReferralsListRequest);
    const referrals: IReferral[] = yield Promise.all(rawReferrals.body.map(parseReferral));
    yield put(investorReferralsSetAction(referrals));
}

function* investorAccountAnnualStatementsAdd(action: IInvestorAccountAnnualStatementsAddAction): Iterator<unknown> {
    const key: string = `investorAccountAnnualStatementsAdd ${action.investorAccountUuid}`;
    const message: string = 'Add Annual Statement';

    notification.open({
        description: 'Adding Annual Statement...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const investorDocumentsAddResponse: IFetchResponse = yield call(investorDocumentsAddRequest, action.investorUuid, action.file);
    const document: IDocument = parseInvestorDocument(investorDocumentsAddResponse.body);
    yield put(investorDocumentSetAction(action.investorAccountUuid, document));

    const investorAccountAnnualStatementAddResponse: IFetchResponse = yield call(investorAccountAnnualStatementsAddRequest, action.investorAccountUuid, document.uuid, action.year);
    if (investorAccountAnnualStatementAddResponse.status === 422) {
        notification.error({
            description: `There was a problem adding the annual statement: ${_.values(investorAccountAnnualStatementAddResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const annualStatement: IAnnualStatement = yield parseAnnualStatement(investorAccountAnnualStatementAddResponse.body);
        yield put(investorAccountAnnualStatementSetAction(annualStatement));

        notification.success({
            description: 'The annual statement has been added.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorAccountAnnualStatementsGenerate(action: IInvestorAccountAnnualStatementsGenerateAction): Iterator<unknown> {
    const key: string = `investorAccountAnnualStatementsGenerate ${action.investorAccountUuid}`;
    const message: string = 'Generate Annual Statement';

    notification.open({
        description: 'Generating Annual Statement...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const investorAccountAnnualStatementGenerateResponse: IFetchResponse = yield call(investorAccountAnnualStatementsGenerateRequest, action.investorAccountUuid, action.financialYear);
    if (investorAccountAnnualStatementGenerateResponse.status === 422) {
        notification.error({
            description: `There was a problem generating the annual statement: ${_.values(investorAccountAnnualStatementGenerateResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const annualStatement: IAnnualStatement = yield parseAnnualStatement(investorAccountAnnualStatementGenerateResponse.body);
        yield put(investorAccountAnnualStatementSetAction(annualStatement));

        notification.success({
            description: 'The annual statement has been generated.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorAccountAnnualStatementDelete(action: IInvestorAccountAnnualStatementDeleteAction): Iterator<unknown> {
    yield call(investorAccountAnnualStatementDeleteRequest, action.annualStatementUuid);
}

function* investorAccountAnnualStatementsList(action: IInvestorAccountAnnualStatementsListAction): Iterator<unknown> {
    const investorAccountAnnualStatementsListResponse: IFetchResponse = yield call(investorAccountAnnualStatementsListRequest, action.investorAccountUuid);
    const annualStatements: IAnnualStatement[] = yield Promise.all(investorAccountAnnualStatementsListResponse.body.map(parseAnnualStatement));
    yield put(investorAccountAnnualStatementsSetAction(action.investorAccountUuid, annualStatements));
}

function* investorAccountMonthlyStatementsGenerate(action: IInvestorAccountMonthlyStatementsGenerateAction): Iterator<unknown> {
    const key: string = `investorAccountMonthlyStatementsGenerate ${action.month}-${action.year} ${action.investorAccountUuid}`;
    const message: string = 'Generate Monthly Statement';

    notification.open({
        description: 'Generating Monthly Statement...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const investorAccountMonthlyStatementGenerateResponse: IFetchResponse = yield call(investorAccountMonthlyStatementsGenerateRequest, action.investorAccountUuid, action.month, action.year);
    if (investorAccountMonthlyStatementGenerateResponse.status === 422) {
        notification.error({
            description: `There was a problem generating the monthly statement: ${_.values(investorAccountMonthlyStatementGenerateResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const monthlyStatement: IMonthlyStatement = yield parseMonthlyStatement(investorAccountMonthlyStatementGenerateResponse.body);
        yield put(investorAccountMonthlyStatementSetAction(monthlyStatement));

        notification.success({
            description: 'The monthly statement has been generated.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorAccountMonthlyStatementsList(action: IInvestorAccountMonthlyStatementsListAction): Iterator<unknown> {
    const investorAccountMonthlyStatementsListResponse: IFetchResponse = yield call(investorAccountMonthlyStatementsListRequest, action.investorAccountUuid);
    const monthlyStatements: IMonthlyStatement[] = yield Promise.all(investorAccountMonthlyStatementsListResponse.body.map(parseMonthlyStatement));
    yield put(investorAccountMonthlyStatementsSetAction(action.investorAccountUuid, monthlyStatements));
}

function* investorAccountMonthlyStatementDelete(action: IInvestorAccountMonthlyStatementDeleteAction): Iterator<unknown> {
    yield call(investorAccountMonthlyStatementDeleteRequest, action.monthlyStatementUuid);
}

function* investorAccountMonthlyStatementSend(action: IInvestorAccountMonthlyStatementSendAction): Iterator<unknown> {
    const key: string = `investorAccountMonthlyStatementSend ${action.monthlyStatementUuid}`;

    notification.open({
        description: 'Sending Monthly Statement...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Send Monthly Statement',
    });

    yield call(investorAccountMonthlyStatementSendRequest, action.monthlyStatementUuid, action.email, action.firstName, action.lastName);

    notification.success({
        description: 'The monthly statement has been sent.',
        duration: 4.5,
        key,
        message: 'Send Monthly Statement',
    });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* investorAccountsIncomeTrustList(action: IInvestorAccountsIncomeTrustListAction): Iterator<unknown> {
    const investorAccountsIncomeTrustListResponse: IFetchResponse = yield call(investorAccountsIncomeTrustListRequest);
    const investorAccounts: IAccount[] = yield Promise.all(investorAccountsIncomeTrustListResponse.body.map(parseAccount));
    yield put(investorAccountsIncomeTrustSetAction(investorAccounts));
}

function* investorAccountsPendingApprovalList(action: IInvestorAccountsPendingApprovalListAction): Iterator<unknown> {
    const investorAccountsPendingApprovalListResponse: IFetchResponse = yield call(investorAccountsPendingApprovalListRequest);
    const investorAccounts: IAccount[] = yield Promise.all(investorAccountsPendingApprovalListResponse.body.map(parseAccount));
    yield put(investorAccountsPendingApprovalSetAction(investorAccounts));
}

function* investorReferralProcess(action: IInvestorReferralProcessAction): Iterator<unknown> {
    const key: string = `investorReferralInvestor ${action.referralUuid}`;

    notification.open({
        description: 'Processing referral...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Process Referral',
    });

    const rawReferral: IFetchResponse = yield call(investorReferralProcessRequest, action.referralUuid, action.sendEmail, action.process);
    const referral: IReferral = parseReferral(rawReferral.body);
    yield put(investorReferralSetAction(referral));

    notification.success({
        description: 'The referral has been processed.',
        duration: 4.5,
        key,
        message: 'Process Referral',
    });
}

function* investorReferralUnprocess(action: IInvestorReferralUnprocessAction): Iterator<unknown> {
    const key: string = `investorReferralUnprocess ${action.referralUuid}`;

    notification.open({
        description: 'Unprocessing referral...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Unprocess Referral',
    });

    const rawReferral: IFetchResponse = yield call(investorReferralUnprocessRequest, action.referralUuid);
    const referral: IReferral = parseReferral(rawReferral.body);
    yield put(investorReferralSetAction(referral));

    notification.success({
        description: 'The referral has been unprocessed.',
        duration: 4.5,
        key,
        message: 'Unprocess Referral',
    });
}

function* investorSend(action: IInvestorSendAction): Iterator<unknown> {
    const investor: IInvestor = yield select(investorSelector, action.uuid);
    yield call(investorUpdateRequest, investor);
}

function* investorTrustValueSet(action: IInvestorTrustValueSetAction): Iterator<unknown> {
    yield put(investorSendAction(action.uuid));
}

function* investorUnassignAdviser(action: IInvestorUnassignAdviserAction): Iterator<unknown> {
    const key: string = `investorUnassignAdviser ${action.investorUuid}`;
    const message: string = 'Unassign adviser from investor';

    notification.open({
        description: 'Unassigning adviser from investor...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const investorUnassignAdviserResponse: IFetchResponse = yield call(investorUnassignAdviserRequest, action.investorUuid);
    if (investorUnassignAdviserResponse.status === 422) {
        notification.error({
            description: `There was a problem unassigning the adviser: ${_.values(investorUnassignAdviserResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const updatedInvestor: IInvestor = yield parseInvestor(investorUnassignAdviserResponse.body);
        yield put(investorSetAction(updatedInvestor));

        notification.success({
            description: 'The adviser has been unassigned.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorUnassignIm(action: IInvestorUnassignImAction): Iterator<unknown> {
    const key: string = `investorUnassignIm ${action.investorUuid}`;
    const message: string = 'Unassign IM from investor';

    notification.open({
        description: 'Unassigning IM from investor...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const investorUnassignImResponse: IFetchResponse = yield call(investorUnassignImRequest, action.investorUuid);
    if (investorUnassignImResponse.status === 422) {
        notification.error({
            description: `There was a problem unassigning the IM: ${_.values(investorUnassignImResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const updatedInvestor: IInvestor = yield parseInvestor(investorUnassignImResponse.body);
        yield put(investorSetAction(updatedInvestor));

        notification.success({
            description: 'The IM has been unassigned.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* investorUsersList(action: IInvestorUsersListAction): Iterator<unknown> {
    const rawInvestorUsers: IFetchResponse = yield call(investorUsersListRequest, action.uuid);
    const investorUsers: IInvestorUser[] = yield Promise.all(rawInvestorUsers.body.map(parseInvestorUser));
    yield put(investorUsersSetAction(action.uuid, investorUsers));
}

function* investorValueSet(action: IInvestorValueSetAction): Iterator<unknown> {
    yield put(investorSendAction(action.uuid));
}

function* notesAdd(action: IInvestorNotesAddAction): Iterator<unknown> {
    const key: string = `investorNotesAdd ${dayjs().format()}`;

    notification.open({
        description: 'Adding note...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Note',
    });

    const currentAdministrator: IAdministrator = yield select(currentAdministratorSelector);

    const preNote: INote = {
        administratorUuid: currentAdministrator.uuid,
        createdTime: dayjs().format(),
        note: action.note,
    };

    yield put(investorNoteSetAction(action.investorUuid, preNote));

    const rawNote: IFetchResponse = yield call(investorNotesAddRequest, action.investorUuid, preNote);
    const note: INote = parseInvestorNote(rawNote.body);

    yield put(investorNoteRemoveAction(action.investorUuid, 'new'));
    yield put(investorNoteSetAction(action.investorUuid, note));

    notification.success({
        description: 'The note has been added.',
        duration: 4.5,
        key,
        message: 'Add Note',
    });
}

function* notesList(action: IInvestorNotesListAction): Iterator<unknown> {
    const rawNotes: IFetchResponse = yield call(investorNotesListRequest, action.investorUuid);
    const notes: INote[] = yield Promise.all(rawNotes.body.map(parseInvestorNote));
    yield put(investorNotesSetAction(action.investorUuid, notes));
}

function* investorsManagedList(action: IInvestorsManagedListAction): Iterator<unknown> {
    const listResponse: IFetchResponse = yield call(investorsManagedListRequest);
    const investors: IInvestor[] = yield Promise.all(listResponse.body.map(parseInvestor));
    yield put(investorsManagedSetAction(investors));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* pendingDepositsList(action: IPendingDepositsListAction): Iterator<unknown> {
    const pendingDepositsListResponse: IFetchResponse = yield call(investorAccountsPendingDepositsRequest);
    const investorAccounts: IAccount[] = yield Promise.all(pendingDepositsListResponse.body.map(parseAccount));
    yield put(pendingDepositsSetAction(investorAccounts));
}

export function* InvestorsSagas(): any {
    yield all([
        takeEvery(InvestorsActionsEnum.InvestorsManagedList, investorsManagedList),

        takeEvery(InvestorsActionsEnum.InvestorsPrivateList, investorsPrivateList),

        takeEvery(InvestorsActionsEnum.InvestorsBoard, investorsBoard),
        debounce(20, InvestorsActionsEnum.InvestorsList, investorsList),

        takeLatest(InvestorsActionsEnum.InvestorsSearch, investorsSearch),

        takeEvery(InvestorsActionsEnum.InvestorAccountIncomeTrustsList, investorAccountIncomeTrustsList),
        debounce(20, InvestorsActionsEnum.InvestorAccountIncomeTrustInterestPaymentStrategySet, investorAccountIncomeTrustInterestPaymentStrategySet),
        debounce(20, InvestorsActionsEnum.InvestorAccountIncomeTrustDivest, investorAccountIncomeTrustDivest),
        debounce(20, InvestorsActionsEnum.InvestorAccountIncomeTrustInvest, investorAccountIncomeTrustInvest),
        takeEvery(InvestorsActionsEnum.InvestorAccountIncomeTrustTransactionsList, investorAccountIncomeTrustTransactionsList),

        takeEvery(InvestorsActionsEnum.InvestorAccountInvestmentsList, investorAccountInvestmentsList),
        takeEvery(InvestorsActionsEnum.InvestorAccountInvestmentTransactionsList, investorAccountInvestmentTransactionsList),

        takeEvery(InvestorsActionsEnum.InvestorAccountApprove, investorAccountApprove),
        debounce(20, InvestorsActionsEnum.InvestorAccountDeposit, investorAccountDeposit),
        debounce(20, InvestorsActionsEnum.InvestorAccountInvest, investorAccountInvest),
        debounce(20, InvestorsActionsEnum.InvestorAccountPendingDepositApprove, investorAccountPendingDepositApprove),
        debounce(20, InvestorsActionsEnum.InvestorAccountPendingDepositReject, investorAccountPendingDepositReject),
        takeEvery(InvestorsActionsEnum.InvestorAccountReject, investorAccountReject),
        debounce(20, InvestorsActionsEnum.InvestorAccountReserve, investorAccountReserve),
        takeEvery(InvestorsActionsEnum.InvestorAccountSharesList, investorAccountSharesList),
        takeEvery(InvestorsActionsEnum.InvestorAccountTransactionsList, investorAccountTransactionsList),
        takeEvery(InvestorsActionsEnum.InvestorAccountUnapprove, investorAccountUnapprove),
        debounce(20, InvestorsActionsEnum.InvestorAccountWithdraw, investorAccountWithdraw),
        takeEvery(InvestorsActionsEnum.InvestorAccountWithdrawalAbaInvestorTransactionsList, investorAccountWithdrawalAbaInvestorTransactionsList),

        takeEvery(InvestorsActionsEnum.InvestorAccountAnnualStatementDelete, investorAccountAnnualStatementDelete),
        takeEvery(InvestorsActionsEnum.InvestorAccountAnnualStatementsAdd, investorAccountAnnualStatementsAdd),
        takeEvery(InvestorsActionsEnum.InvestorAccountAnnualStatementsGenerate, investorAccountAnnualStatementsGenerate),
        takeEvery(InvestorsActionsEnum.InvestorAccountAnnualStatementsList, investorAccountAnnualStatementsList),

        takeEvery(InvestorsActionsEnum.InvestorAccountInvestmentGet, investorAccountInvestmentGet),

        takeEvery(InvestorsActionsEnum.InvestorAccountMonthlyStatementsGenerate, investorAccountMonthlyStatementsGenerate),
        takeEvery(InvestorsActionsEnum.InvestorAccountMonthlyStatementsList, investorAccountMonthlyStatementsList),

        takeEvery(InvestorsActionsEnum.InvestorAccountMonthlyStatementDelete, investorAccountMonthlyStatementDelete),
        takeEvery(InvestorsActionsEnum.InvestorAccountMonthlyStatementSend, investorAccountMonthlyStatementSend),

        takeEvery(InvestorsActionsEnum.InvestorAccountsIncomeTrustList, investorAccountsIncomeTrustList),
        takeEvery(InvestorsActionsEnum.InvestorAccountsPendingApprovalList, investorAccountsPendingApprovalList),

        takeEvery(InvestorsActionsEnum.InvestorCompanyValueSet, investorCompanyValueSet),

        takeEvery(InvestorsActionsEnum.InvestorComplianceDocumentsAdd, investorComplianceDocumentsAdd),
        takeEvery(InvestorsActionsEnum.InvestorComplianceDocumentsList, investorComplianceDocumentsList),

        takeEvery(InvestorsActionsEnum.InvestorComplianceDocumentDelete, investorComplianceDocumentDelete),

        takeEvery(InvestorsActionsEnum.InvestorDocumentsAdd, investorDocumentsAdd),
        takeEvery(InvestorsActionsEnum.InvestorDocumentsList, investorDocumentsList),

        takeEvery(InvestorsActionsEnum.InvestorAssignAdviser, investorAssignAdviser),
        takeEvery(InvestorsActionsEnum.InvestorAssignIm, investorAssignIm),
        takeEvery(InvestorsActionsEnum.InvestorContactDate, investorContactDate),
        debounce(20, InvestorsActionsEnum.InvestorCreateIncomeTrustAccount, investorCreateIncomeTrustAccount),
        debounce(20, InvestorsActionsEnum.InvestorGet, investorGet),
        takeEvery(InvestorsActionsEnum.InvestorIndividualAdd, investorIndividualAdd),
        takeEvery(InvestorsActionsEnum.InvestorIndividualValueSet, investorIndividualValueSet),
        debounce(500, InvestorsActionsEnum.InvestorSend, investorSend),
        takeEvery(InvestorsActionsEnum.InvestorTrustValueSet, investorTrustValueSet),
        takeEvery(InvestorsActionsEnum.InvestorUnassignAdviser, investorUnassignAdviser),
        takeEvery(InvestorsActionsEnum.InvestorUnassignIm, investorUnassignIm),
        takeEvery(InvestorsActionsEnum.InvestorValueSet, investorValueSet),

        takeEvery(InvestorsActionsEnum.InvestorBankAccountsAdd, investorBankAccountsAdd),
        takeEvery(InvestorsActionsEnum.InvestorBankAccountConfirm, investorBankAccountConfirm),
        takeEvery(InvestorsActionsEnum.InvestorBankAccountUnconfirm, investorBankAccountUnconfirm),

        takeEvery(InvestorsActionsEnum.InvestorHistoriesList, investorHistoriesList),

        takeEvery(InvestorsActionsEnum.InvestorPrimaryBankAccount, investorPrimaryBankAccount),

        takeEvery(InvestorsActionsEnum.InvestorPendingWithdrawalsUnapprovedList, investorPendingWithdrawalsUnapprovedList),
        takeEvery(InvestorsActionsEnum.InvestorPendingWithdrawalApprove, investorPendingWithdrawalApprove),

        debounce(20, InvestorsActionsEnum.InvestorReferralsAdd, investorReferralsAdd),
        takeEvery(InvestorsActionsEnum.InvestorReferralsInvestorList, investorReferralsInvestorList),
        takeEvery(InvestorsActionsEnum.InvestorReferralsList, investorReferralsList),

        debounce(20, InvestorsActionsEnum.InvestorReferralProcess, investorReferralProcess),
        debounce(20, InvestorsActionsEnum.InvestorReferralUnprocess, investorReferralUnprocess),

        takeEvery(InvestorsActionsEnum.InvestorUsersList, investorUsersList),

        debounce(20, InvestorsActionsEnum.InvestorNotesAdd, notesAdd),
        takeEvery(InvestorsActionsEnum.InvestorNotesList, notesList),

        takeEvery(InvestorsActionsEnum.PendingDepositsList, pendingDepositsList),
    ]);
}
