import { SearchOutlined } from '@ant-design/icons';
import { Space, Spin, Table, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { FaCity, FaHome, FaHorse } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import { administratorsActiveSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import IBroker from '~Api/Broker/IBroker';
import ApprovalStatusEnum from '~Api/Deal/ApprovalStatusEnum';
import IDeal from '~Api/Deal/IDeal';
import IProperty from '~Api/Deal/IProperty';
import PropertyTypeEnum from '~Api/Deal/PropertyTypeEnum';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';
import {
    brokerDealsListAction,
    brokerGetAction,
} from '~Brokers/actions';
import {
    brokerDealsSelector,
    brokerSelector,
} from '~Brokers/selectors';
import AssigneeSelector from '~Deals/AssigneeSelector';
import ApprovalStatusBadge from '~Leads/Lead/ApprovalStatusBadge';
import { IGlobalState } from '~reducer';
import SearchDropdown from '~UI/SearchDropdown';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import { ColumnType } from 'antd/lib/table';
import { FilterDropdownProps } from 'antd/lib/table/interface';

interface IMatch {
    brokerUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    activeAdministrators: IAdministrator[];
    broker: IBroker;
    deals: IDictionary<IDeal>;
}

interface IPropsDispatch {
    administratorsList: () => void;
    brokerGet: () => void;
    brokerDealsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Deals extends React.Component<Props> {
    public componentDidMount(): void {
        const { activeAdministrators, broker, deals } = this.props;

        if (!activeAdministrators) {
            this.props.administratorsList();
        }

        if (!broker) {
            this.props.brokerGet();
        }

        if (!deals) {
            this.props.brokerDealsList();
        }
    }

    public render(): JSX.Element {
        const { activeAdministrators, broker, deals, match } = this.props;

        if (!activeAdministrators || !broker || !deals) {
            return (
                <Layout brokerUuid={match.params.brokerUuid} section='deals'>
                    <Typography.Title level={2}>Leads</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const lvrFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            style: 'percent',
        });

        const columns: ColumnType<IDeal>[] = [
            {
                dataIndex: 'approvalStatus',
                filters: [
                    {
                        text: 'Initial Call',
                        value: ApprovalStatusEnum.InitialCall,
                    },
                    {
                        text: 'Uncontactable Follow Up 1',
                        value: ApprovalStatusEnum.UncontactableFollowUp1,
                    },
                    {
                        text: 'Uncontactable Follow Up 2',
                        value: ApprovalStatusEnum.UncontactableFollowUp2,
                    },
                    {
                        text: 'Uncontactable Follow Up 3',
                        value: ApprovalStatusEnum.UncontactableFollowUp3,
                    },
                    {
                        text: 'Quoted',
                        value: ApprovalStatusEnum.Quote,
                    },
                    {
                        text: 'Follow Up 1',
                        value: ApprovalStatusEnum.FollowUp1,
                    },
                    {
                        text: 'Follow Up 2',
                        value: ApprovalStatusEnum.FollowUp2,
                    },
                    {
                        text: 'Follow Up 3',
                        value: ApprovalStatusEnum.FollowUp3,
                    },
                    {
                        text: 'Rejected',
                        value: ApprovalStatusEnum.Rejected,
                    },
                    {
                        text: 'Referred',
                        value: ApprovalStatusEnum.Referred,
                    },
                    {
                        text: 'New',
                        value: ApprovalStatusEnum.Pending,
                    },
                    {
                        text: 'Application',
                        value: ApprovalStatusEnum.Application,
                    },
                ],
                onFilter: (value: boolean|number|string, deal: IDeal) => deal.approvalStatus === value,
                render: (approvalStatus: ApprovalStatusEnum) => <ApprovalStatusBadge approvalStatus={approvalStatus} />,
                width: '5%',
            },
            {
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: boolean|number|string, deal: IDeal) => deal.code.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                render: (deal: IDeal) => <Link to={`/leads/${deal.uuid}`}>{deal.code}</Link>,
                sorter: (a: IDeal, b: IDeal) => a.code.localeCompare(b.code),
                title: 'Code',
                width: '10%',
            },
            {
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: boolean|number|string, deal: IDeal) => `${deal.firstName} ${deal.lastName}`.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()) || (deal.email && deal.email.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase())),
                render: (deal: IDeal) => <Link to={`/leads/${deal.uuid}`}>{deal.firstName} {deal.lastName}</Link>,
                sorter: (a: IDeal, b: IDeal) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
                title: 'Name',
            },
            {
                dataIndex: 'loanAmount',
                render: (loanAmount: number) => currencyFormatter.format(loanAmount),
                sorter: (a: IDeal, b: IDeal) => a.loanAmount > b.loanAmount  ? 1 : -1,
                title: 'Loan Amount',
                width: '15%',
            },
            {
                dataIndex: 'administratorUuid',
                filters: activeAdministrators.map((administrator: IAdministrator) => ({
                    text: administrator.name,
                    value: administrator.uuid,
                })),
                onFilter: (value: boolean|number|string, deal: IDeal) => deal.administratorUuid === value,
                render: (administratorUuid: string, deal: IDeal) => <AssigneeSelector deal={deal} />,
                title: 'Assignee',
                width: '15%',
            },
            {
                dataIndex: 'propertyType',
                filters: [
                    {
                        text: 'Commercial',
                        value: PropertyTypeEnum.Commercial,
                    },
                    {
                        text: 'Residential',
                        value: PropertyTypeEnum.Residential,
                    },
                    {
                        text: 'Rural',
                        value: PropertyTypeEnum.Rural,
                    },
                ],
                onFilter: (value: boolean|number|string, deal: IDeal) => {
                    for (const dealProperty of deal.properties) {
                        switch (dealProperty.zoneType) {
                            case ZoneTypeEnum.CommercialIndustrial:
                            case ZoneTypeEnum.CommercialLand:
                            case ZoneTypeEnum.CommercialOffice:
                            case ZoneTypeEnum.CommercialRetail:
                                if (value === PropertyTypeEnum.Commercial) {
                                    return true;
                                }
                                break;
                            case ZoneTypeEnum.ResidentialHouse:
                            case ZoneTypeEnum.ResidentialLand:
                            case ZoneTypeEnum.ResidentialTownhouse:
                            case ZoneTypeEnum.ResidentialUnit:
                                if (value === PropertyTypeEnum.Residential) {
                                    return true;
                                }
                                break;
                            case ZoneTypeEnum.RuralLand:
                            case ZoneTypeEnum.RuralResidential:
                                if (value === PropertyTypeEnum.Rural) {
                                    return true;
                                }
                                break;
                        }
                    }

                    return false;
                },
                render: (propertyType: PropertyTypeEnum, deal: IDeal) => {
                    if (deal.properties.length > 0) {
                        const icons: JSX.Element[] = deal.properties.map((dealProperty: IProperty) => {
                            switch (dealProperty.zoneType) {
                                case ZoneTypeEnum.CommercialIndustrial:
                                case ZoneTypeEnum.CommercialLand:
                                case ZoneTypeEnum.CommercialOffice:
                                case ZoneTypeEnum.CommercialRetail:
                                    return <FaCity key={dealProperty.uuid} />;
                                case ZoneTypeEnum.ResidentialHouse:
                                case ZoneTypeEnum.ResidentialLand:
                                case ZoneTypeEnum.ResidentialTownhouse:
                                case ZoneTypeEnum.ResidentialUnit:
                                    return <FaHome key={dealProperty.uuid} />;
                                case ZoneTypeEnum.RuralLand:
                                case ZoneTypeEnum.RuralResidential:
                                    return <FaHorse key={dealProperty.uuid} />;
                            }
                        });

                        return (
                            <Space>
                                {icons}
                            </Space>
                        );
                    }

                    if (propertyType === PropertyTypeEnum.Commercial) {
                        return <FaCity/>;
                    }

                    if (propertyType === PropertyTypeEnum.Residential) {
                        return <FaHome/>;
                    }

                    if (propertyType === PropertyTypeEnum.Rural) {
                        return <FaHorse/>;
                    }
                },
                title: 'Type',
                width: '10%',
            },
            {
                filters: [
                    {
                        text: '< 55%',
                        value: 'GREEN',
                    },
                    {
                        text: '55-65%',
                        value: 'ORANGE',
                    },
                    {
                        text: '> 65%',
                        value: 'RED',
                    },
                ],
                onFilter: (value: boolean|number|string, deal: IDeal) => {
                    const totalValue: number = _.sumBy(deal.properties, (dealProperty: IProperty) => dealProperty.estimatedValue);
                    const totalCurrentDebt: number = _.sumBy(deal.properties, (dealProperty: IProperty) => dealProperty.currentDebt);
                    const lvr: number = (deal.loanAmount + totalCurrentDebt) / totalValue;
                    const dealValue: string = (lvr < 0.55 ? 'GREEN' : (lvr > 0.65 ? 'RED' : 'ORANGE'));
                    return value === dealValue;
                },
                render: (deal: IDeal) => {
                    const totalValue: number = _.sumBy(deal.properties, (dealProperty: IProperty) => dealProperty.estimatedValue);
                    const totalCurrentDebt: number = _.sumBy(deal.properties, (dealProperty: IProperty) => dealProperty.currentDebt);
                    const lvr: number = (deal.loanAmount + totalCurrentDebt) / totalValue;
                    return lvrFormatter.format(lvr);
                },
                title: 'LVR',
                width: '5%',
            },
            {
                dataIndex: 'lastQuoteTime',
                render: (lastQuoteTime: string) => lastQuoteTime ? dayjs(lastQuoteTime).fromNow() : '-',
                sorter: (a: IDeal, b: IDeal) => dayjs(a.lastQuoteTime) > dayjs(b.lastQuoteTime)  ? 1 : -1,
                title: 'Quoted',
                width: '10%',
            },
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => <Tooltip title={dayjs(createdTime).format('Do MMMM YYYY HH:mm:ss')}><span>{dayjs(createdTime).fromNow()}</span></Tooltip>,
                sorter: (a: IDeal, b: IDeal) => dayjs(a.createdTime) > dayjs(b.createdTime)  ? 1 : -1,
                title: 'Created',
                width: '10%',
            },
        ];

        return (
            <Layout brokerUuid={match.params.brokerUuid} section='deals'>
                <Typography.Title level={2}>Leads</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(deals)}
                    rowClassName={this.getRowClassName}
                    rowKey='uuid'
                    size='middle'
                />
            </Layout>
        );
    }

    private getRowClassName(deal: IDeal): string {
        if (deal.closedTime) {
            return 'closed';
        }
        return null;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        activeAdministrators: administratorsActiveSelector(state),
        broker: brokerSelector(state, ownProps.match.params.brokerUuid),
        deals: brokerDealsSelector(state, ownProps.match.params.brokerUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        brokerDealsList: () => dispatch(brokerDealsListAction(ownProps.match.params.brokerUuid)),
        brokerGet: () => dispatch(brokerGetAction(ownProps.match.params.brokerUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Deals);
