import { Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IMonthlyStatement from '~Api/Investor/IMonthlyStatement';
import { investorAccountMonthlyStatementSendAction } from '~Investors/actions';

interface IState {
    email: string;
    firstName: string;
    lastName: string;
}

interface IProps {
    isOpen: boolean;
    monthlyStatement: IMonthlyStatement;
    onCancel: () => void;
}

interface IPropsDispatch {
    send: (email: string, firstName: string, lastName: string) => void;
}

type Props = IProps & IPropsDispatch;

class MonthlyStatementSendModal extends React.Component<Props, IState> {
    public state: IState = {
        email: null,
        firstName: null,
        lastName: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { email, firstName, lastName } = this.state;

        return (
            <Modal
                okText='Send'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Send Monthly Statement'
                wrapClassName='investor-monthly-statement-send-modal'
            >
                <Form.Item label='First Name' className='first-name'>
                    <Input onChange={this.onChangeFirstName} value={firstName} />
                </Form.Item>
                <Form.Item label='Last Name' className='last-name'>
                    <Input onChange={this.onChangeLastName} value={lastName} />
                </Form.Item>
                <Form.Item label='Email' className='email'>
                    <Input onChange={this.onChangeEmail} value={email} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            firstName: event.target.value,
        });
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            lastName: event.target.value,
        });
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            email: event.target.value,
        });
    }

    private onClickOk(): void {
        const { email, firstName, lastName } = this.state;

        this.props.onCancel();

        this.props.send(email, firstName, lastName);
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        send: (email: string, firstName: string, lastName: string) => dispatch(investorAccountMonthlyStatementSendAction(ownProps.monthlyStatement.uuid, email, firstName, lastName)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(MonthlyStatementSendModal);
