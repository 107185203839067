import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '~Layout';
import List from './List';
import InvestmentSaleTransfer from './InvestmentSaleTransfer';

export default function Router(): ReactElement {
    return (
        <Layout topSection='accounts'>
            <Switch>
                <Route exact={true} path='/investment-sale-transfers' component={List} />
                <Route path='/investment-sale-transfers/:investmentSaleTransferUuid' component={InvestmentSaleTransfer} />
            </Switch>
        </Layout>
    );
}
