import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Spin } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import {
    administratorsActiveSelector,
    administratorsSelector,
    currentAdministratorSelector,
} from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import IDeal from '~Api/Deal/IDeal';
import { leadValueSetAction } from '~Leads/actions';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

interface IProps {
    deal: IDeal;
}

interface IPropsSelector {
    activeAdministrators: IAdministrator[];
    administrators: IDictionary<IAdministrator>;
    currentAdministrator: IAdministrator;
}

interface IPropsDispatch {
    administratorsList: () => void;
    dealValueSet: (key: keyof IDeal, value: boolean|number|string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class BdmSelector extends React.Component<Props> {
    public componentDidMount() {
        const { activeAdministrators, administrators } = this.props;

        if (!activeAdministrators || !administrators) {
            this.props.administratorsList();
        }
    }

    public render(): JSX.Element {
        const { activeAdministrators, administrators, currentAdministrator, deal } = this.props;

        if (!activeAdministrators || !administrators || !currentAdministrator || !deal) {
            return (
                <Spin />
            );
        }

        const bdm: IAdministrator = !!deal.bdmUuid && administrators[deal.bdmUuid];

        if ([RoleEnum.BusinessDevelopmentManager, RoleEnum.InternalBusinessDevelopmentManager, RoleEnum.SeniorBusinessDevelopmentManager].includes(currentAdministrator.role)) {
            return (
                <React.Fragment>
                    {deal.bdmUuid ? (bdm ? bdm.name : 'Inactive User') : 'None'}
                </React.Fragment>
            );
        }

        const filteredBdms: IAdministrator[] = _.filter(activeAdministrators, (admin: IAdministrator) => {
            if ([RoleEnum.BusinessDevelopmentManager, RoleEnum.InternalBusinessDevelopmentManager, RoleEnum.SeniorBusinessDevelopmentManager].includes(admin.role)) {
                return true;
            }

            return false;
        });

        const menu: MenuProps = {
            items: [
                {
                    key: 0,
                    label: 'None',
                    onClick: () => this.props.dealValueSet('bdmUuid', null),
                },
                ...(_.sortBy(filteredBdms, ['name']).map((administrator: IAdministrator): ItemType => ({
                    key: administrator.uuid,
                    label: administrator.name,
                    onClick: () => this.props.dealValueSet('bdmUuid', administrator.uuid),
                }))),
                ...(!activeAdministrators.includes(bdm) ? [
                    {
                        disabled: true,
                        key: bdm.uuid,
                        label: bdm.name,
                    },
                ] : []),
            ],
            selectedKeys: deal.bdmUuid ? [deal.bdmUuid] : ['0'],
        };

        return (
            <Dropdown menu={menu}>
                <a>
                    {deal.bdmUuid ? (bdm ? bdm.name : 'Inactive User') : 'None'}
                    {' '}<DownOutlined/>
                </a>
            </Dropdown>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        activeAdministrators: administratorsActiveSelector(state),
        administrators: administratorsSelector(state),
        currentAdministrator: currentAdministratorSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        dealValueSet: (key: keyof IDeal, value: boolean|number|string) => dispatch(leadValueSetAction(ownProps.deal.uuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BdmSelector);
