import { CloudDownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Spin, Table, Tooltip, Typography, Upload } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IDocument from '~Api/Loan/IDocument';
import ILoan from '~Api/Loan/ILoan';
import { authTokenSelector } from '~Auth/selectors';
import {
    loanDocumentDeleteAction,
    loanDocumentsAddAction,
    loanDocumentsListAction,
    loanGetAction,
} from '~Loans/actions';
import {
    loanDocumentsSelector,
    loanSelector,
} from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import { ColumnType } from 'antd/lib/table';
import { IDictionary } from '~utilities/IDictionary';
import _ from 'lodash';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    documents: IDictionary<IDocument>;
    loan: ILoan;
    token: string;
}

interface IPropsDispatch {
    documentDelete: (documentUuid: string) => void;
    loanDocumentsAdd: (file: File) => void;
    loanDocumentsList: () => void;
    loanGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class DocumentsList extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.beforeUpload = this.beforeUpload.bind(this);
    }

    public componentDidMount() {
        const { documents, loan } = this.props;

        if (!documents) {
            this.props.loanDocumentsList();
        }

        if (!loan) {
            this.props.loanGet();
        }
    }

    public render(): JSX.Element {
        const { documents, loan, match, token } = this.props;

        if (!documents || !loan) {
            return (
                <Layout uuid={match.params.uuid} section='documents'>
                    <Typography.Title level={2}>Documents</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const columns: ColumnType<IDocument>[] = [
            {
                dataIndex: 'filename',
                title: 'Filename',
            },
            {
                render: (document: IDocument) => {
                    const onClickDelete: () => void = () => this.onClickDelete(document.uuid);

                    return (
                        <React.Fragment>
                            <Tooltip title='Download'>
                                <Button
                                    type='link'
                                    href={`${process.env.API_HOST}/loan-documents/${document.uuid}/download?token=${token}`}
                                    target='_blank'
                                >
                                    <CloudDownloadOutlined/>
                                </Button>
                            </Tooltip>
                            <Tooltip title='Delete'>
                                <Button
                                    onClick={onClickDelete}
                                    type='link'
                                >
                                    <DeleteOutlined/>
                                </Button>
                            </Tooltip>
                        </React.Fragment>
                    );
                },
                title: 'Actions',
                width: '15%',
            },
        ];

        return (
            <Layout uuid={match.params.uuid} section='documents'>
                <Upload className='add-document' beforeUpload={this.beforeUpload}>
                    <Button>Add Document</Button>
                </Upload>
                <Typography.Title level={2}>Documents</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(documents)}
                    pagination={false}
                    rowKey='uuid'
                />
            </Layout>
        );
    }

    private beforeUpload(file: File): boolean {
        this.props.loanDocumentsAdd(file);

        return false;
    }

    private onClickDelete(documentUuid: string) {
        Modal.confirm({
            content: 'Are you sure you want to delete this loan document?',
            okText: 'Delete',
            okType: 'danger',
            onOk: () => {
                this.props.documentDelete(documentUuid);
            },
            title: 'Delete',
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        documents: loanDocumentsSelector(state, ownProps.match.params.uuid),
        loan: loanSelector(state, ownProps.match.params.uuid),
        token: authTokenSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        documentDelete: (documentUuid: string) => dispatch(loanDocumentDeleteAction(documentUuid)),
        loanDocumentsAdd: (file: File) => dispatch(loanDocumentsAddAction(ownProps.match.params.uuid, file)),
        loanDocumentsList: () => dispatch(loanDocumentsListAction(ownProps.match.params.uuid)),
        loanGet: () => dispatch(loanGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DocumentsList);
