import { Breadcrumb, Button, Layout, Modal, Space, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import accountTypeLabels from '~Api/Investor/accountTypeLabels';
import IInvestorAccountTransaction from '~Api/Investor/IInvestorAccountTransaction';
import IPendingWithdrawal from '~Api/Investor/IPendingWithdrawal';
import { IGlobalState } from '~reducer';
import { currencyFormatter } from '~utilities/formatters';
import { IDictionary } from '~utilities/IDictionary';
import {
    investorPendingWithdrawalApproveAction,
    investorPendingWithdrawalsUnapprovedListAction,
} from './actions';
import { investorPendingWithdrawalsUnapprovedSelector } from './selectors';

interface IPropsSelector {
    pendingWithdrawalsUnapproved: IDictionary<IPendingWithdrawal>;
}

interface IPropsDispatch {
    approve: (uuid: string) => void;
    pendingWithdrawalsUnapprovedList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class PendingWithdrawals extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickApproveWithdrawal = this.onClickApproveWithdrawal.bind(this);
    }

    public componentDidMount(): void {
        this.props.pendingWithdrawalsUnapprovedList();
    }

    public render(): JSX.Element {
        const { pendingWithdrawalsUnapproved } = this.props;

        if (!pendingWithdrawalsUnapproved) {
            return (
                <Layout className='investors-pending-withdrawals'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/investors'>Investors</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Pending Withdrawals</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Pending Withdrawals</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const withdrawalsColumns: ColumnType<IPendingWithdrawal>[] = [
            {
                dataIndex: 'investorAccountTransaction',
                render: (investorAccountTransaction: IInvestorAccountTransaction): JSX.Element => <Link to={`/investors/${investorAccountTransaction.investorUuid}`}>{investorAccountTransaction.investorAccount.investorName}</Link>,
                title: 'Investor',
            },
            {
                dataIndex: 'investorAccountTransaction',
                render: (investorAccountTransaction: IInvestorAccountTransaction): string => accountTypeLabels[investorAccountTransaction.investorAccount.accountType],
                title: 'Account Type',
                width: '12%',
            },
            {
                dataIndex: 'investorAccountTransaction',
                render: (investorAccountTransaction: IInvestorAccountTransaction): string => investorAccountTransaction.investorAccount.code,
                title: 'Account Code',
                width: '12%',
            },
            {
                dataIndex: 'investorAccountTransaction',
                render: (investorAccountTransaction: IInvestorAccountTransaction): string => currencyFormatter.format(investorAccountTransaction.amount),
                title: 'Amount',
                width: '12%',
            },
            {
                dataIndex: 'investorAccountTransaction',
                defaultSortOrder: 'descend',
                render: (investorAccountTransaction: IInvestorAccountTransaction): string => dayjs(investorAccountTransaction.transactionTime).format('Do MMMM YYYY HH:mm:ss'),
                sorter: (a: IInvestorAccountTransaction, b: IInvestorAccountTransaction): number => {
                    return dayjs(a.transactionTime) > dayjs(b.transactionTime) ? 1 : -1;
                },
                title: 'Time',
                width: '12%',
            },
            {
                dataIndex: 'reasons',
                title: 'Reasons',
            },
        ];

        withdrawalsColumns.push({
            render: (pendingWithdrawal: IPendingWithdrawal): JSX.Element => {
                const onClickApprove: () => void = () => this.onClickApproveWithdrawal(pendingWithdrawal);

                return (
                    <Space>
                        <Button onClick={onClickApprove}>Approve</Button>
                    </Space>
                );
            },
            title: 'Actions',
            width: '10%',
        });

        return (
            <Layout className='investors-pending-withdrawals'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/investors'>Investors</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Pending Withdrawals</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Pending Withdrawals</Typography.Title>
                        <Table
                            columns={withdrawalsColumns}
                            dataSource={_.values(_.orderBy(pendingWithdrawalsUnapproved, ['time'], ['desc']))}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    private onClickApproveWithdrawal(pendingWithdrawal: IPendingWithdrawal): void {
        Modal.confirm({
            content: `Are you sure you want to approve the ${currencyFormatter.format(pendingWithdrawal.investorAccountTransaction.amount)} withdrawal for ${pendingWithdrawal.investorAccountTransaction.investorAccount.investorName}?`,
            okText: 'Approve',
            onOk: () => {
                this.props.approve(pendingWithdrawal.uuid);
            },
            title: 'Approve Withdrawal',
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        pendingWithdrawalsUnapproved: investorPendingWithdrawalsUnapprovedSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        approve: (pendingWithdrawalUuid: string) => dispatch(investorPendingWithdrawalApproveAction(pendingWithdrawalUuid)),
        pendingWithdrawalsUnapprovedList: () => dispatch(investorPendingWithdrawalsUnapprovedListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PendingWithdrawals);
