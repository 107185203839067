import { ReactElement } from 'react';
import { Card as AntCard, Progress } from 'antd';
import IInvestment from '~Api/Investment/IInvestment';
import React from 'react';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';
import { Link } from 'react-router-dom';
import WorkflowStatusEnum from '~Api/Investment/WorkflowStatusEnum';
import InvestmentCodeTypeEnum from '~Api/Investment/InvestmentCodeTypeEnum';
import dayjs from 'dayjs';

export default function Card(props: {
    investment: IInvestment,
}): ReactElement {
    const { investment } = props;

    const hasGracePeriod: boolean = investment.gracePeriodMonths >= 1;

    return (
        <AntCard key={investment.uuid}>
            <div className='code'>{investment.code}</div>
            <Link className='name' to={`/investments/${investment.uuid}`}>{investment.name || '-'}</Link>
            <div className='amount'>{currencyFormatter.format([WorkflowStatusEnum.Ready, WorkflowStatusEnum.Live].includes(investment.workflowStatus) ? investment.amountRemaining : investment.amountTotal)}</div>
            <div className='interest-rate'>{investment.interestRate !== 0 ? percentageFormatter.format(investment.interestRate / 100) : '-'}</div>
            <div className='term'>{investment.termMonths} months</div>
            {investment.codeType === InvestmentCodeTypeEnum.New && <div className='loan-code'>{investment.loanCode}</div>}
            {hasGracePeriod && <div className='grace-period-status'>Grace Period</div>}
            <div className='created-time'>Created {dayjs(investment.createdTime).fromNow()}</div>
            {[WorkflowStatusEnum.Ready, WorkflowStatusEnum.Live].includes(investment.workflowStatus) && <Progress percent={(investment.amountTotal - investment.amountRemaining) / investment.amountTotal * 100} showInfo={false} size='small' />}
        </AntCard>
    );
}