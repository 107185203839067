import { Button, Dropdown, MenuProps, Modal, Space, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import IMonthlyStatement from '~Api/Investor/IMonthlyStatement';
import { authTokenSelector } from '~Auth/selectors';
import {
    investorAccountMonthlyStatementSendAction,
    investorAccountMonthlyStatementsListAction,
    investorGetAction,
    investorMonthlyStatementDeleteAction,
} from '~Investors/actions';
import {
    investorAccountMonthlyStatementsSelector,
    investorAccountSelector,
    investorSelector,
} from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import MonthlyStatementGenerateModal from './MonthlyStatementGenerateModal';
import MonthlyStatementSendModal from './MonthlyStatementSendModal';

interface IState {
    sendStatementUuid: string;
    showGenerateStatementModal: boolean;
}

interface IMatch {
    accountUuid: string;
    investorUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    monthlyStatements: IDictionary<IMonthlyStatement>;
    investor: IInvestor;
    investorAccount: IAccount;
    token: string;
}

interface IPropsDispatch {
    monthlyStatementsList: () => void;
    deleteStatement: (monthlyStatementUuid: string) => void;
    sendStatement: (monthlyStatementUuid: string) => void;
    investorGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class MonthlyStatements extends React.Component<Props, IState> {
    private loadingUuid: string = null;

    public state: IState = {
        sendStatementUuid: null,
        showGenerateStatementModal: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickGenerateStatement = this.onClickGenerateStatement.bind(this);
        this.onClickGenerateStatementCancel = this.onClickGenerateStatementCancel.bind(this);
        this.onClickStatementDelete = this.onClickStatementDelete.bind(this);

        this.onClickSendStatement = this.onClickSendStatement.bind(this);
        this.onClickSendStatementTo = this.onClickSendStatementTo.bind(this);
        this.onCancelSendStatementTo = this.onCancelSendStatementTo.bind(this);
    }

    public componentDidMount(): void {
        const { investor, match } = this.props;

        if (!investor) {
            this.props.investorGet();
        }

        this.props.monthlyStatementsList();

        this.loadingUuid = match.params.accountUuid;
    }

    public componentDidUpdate(): void {
        const { investor, match } = this.props;

        if (this.loadingUuid !== match.params.accountUuid) {
            if (!investor) {
                this.props.investorGet();
            }

            this.props.monthlyStatementsList();

            this.loadingUuid = match.params.accountUuid;
        }
    }

    public render(): JSX.Element {
        const { monthlyStatements, investor, match, token } = this.props;
        const { sendStatementUuid, showGenerateStatementModal } = this.state;

        if (!monthlyStatements || !investor) {
            return (
                <Layout uuid={match.params.investorUuid} section={`monthly-statements-${match.params.accountUuid}`} sectionClass='monthly-statements'>
                    <Typography.Title level={2}>Monthly Statements</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const columns: ColumnType<IMonthlyStatement>[] = [
            {
                defaultSortOrder: 'descend',
                render: (statement: IMonthlyStatement) => dayjs(statement.month + '-' + statement.year, 'M-YYYY').format('MMMM YYYY'),
                sorter: (a: IMonthlyStatement, b: IMonthlyStatement) => `${a.year}${a.month.padStart(2, '0')}` > `${b.year}${b.month.padStart(2, '0')}` ? 1 : 0,
                title: 'Date',
            },
            {
                render: (statement: IMonthlyStatement) => {
                    const menu: MenuProps = {
                        items: [
                            {
                                key: 'send',
                                label: 'Send',
                                onClick: () => this.onClickSendStatement(statement.uuid),
                            },
                            {
                                key: 'send-to',
                                label: 'Send To',
                                onClick: () => this.onClickSendStatementTo(statement.uuid),
                            },
                            {
                                danger: true,
                                key: 'delete',
                                label: 'Delete',
                                onClick: () => this.onClickStatementDelete(statement.uuid),
                            },
                        ],
                    };

                    return (
                        <Space>

                            <Dropdown.Button menu={menu}>
                                <a
                                    href={`${process.env.API_HOST}/investor-account-monthly-statements/${statement.uuid}/download?token=${token}`}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    Download
                                </a>
                            </Dropdown.Button>
                            <MonthlyStatementSendModal
                                isOpen={sendStatementUuid === statement.uuid}
                                onCancel={this.onCancelSendStatementTo}
                                monthlyStatement={statement}
                            />
                        </Space>
                    );
                },
                width: '25%',
            },
        ];

        return (
            <Layout uuid={match.params.investorUuid} section={`monthly-statements-${match.params.accountUuid}`} sectionClass='monthly-statements'>
                <Space className='actions'>
                    <Button onClick={this.onClickGenerateStatement}>Generate Statement</Button>
                </Space>
                <Typography.Title level={2}>Monthly Statements</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(monthlyStatements)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                />
                <MonthlyStatementGenerateModal
                    accountUuid={match.params.accountUuid}
                    onClose={this.onClickGenerateStatementCancel}
                    isOpen={showGenerateStatementModal}
                />
            </Layout>
        );
    }

    private onClickGenerateStatement(): void {
        this.setState({
            showGenerateStatementModal: true,
        });
    }

    private onClickGenerateStatementCancel(): void {
        this.setState({
            showGenerateStatementModal: false,
        });
    }

    private onClickStatementDelete(monthlyStatementUuid: string): void {
        const { monthlyStatements } = this.props;

        const statement: IMonthlyStatement = monthlyStatements[monthlyStatementUuid];

        Modal.confirm({
            content: `Are you sure you want to delete the statement for ${dayjs(`${statement.month}-${statement.year}`, 'M-YYYY').format('MMMM YYYY')}?`,
            okText: 'Delete',
            okType: 'danger',
            onOk: () => {
                this.props.deleteStatement(monthlyStatementUuid);
            },
            title: 'Delete',
        });
    }

    private onClickSendStatement(sendStatementUuid: string): void {
        Modal.confirm({
            content: 'Are you sure you want to send this statement?',
            okText: 'Send',
            onOk: () => {
                this.props.sendStatement(sendStatementUuid);
            },
            title: 'Send Statement',
        });
    }

    private onClickSendStatementTo(sendStatementUuid: string): void {
        this.setState({
            sendStatementUuid,
        });
    }

    private onCancelSendStatementTo(): void {
        this.setState({
            sendStatementUuid: null,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investor: investorSelector(state, ownProps.match.params.investorUuid),
        investorAccount: investorAccountSelector(state, ownProps.match.params.accountUuid),
        monthlyStatements: investorAccountMonthlyStatementsSelector(state, ownProps.match.params.accountUuid),
        token: authTokenSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        deleteStatement: (monthlyStatementUuid: string) => dispatch(investorMonthlyStatementDeleteAction(monthlyStatementUuid)),
        investorGet: () => dispatch(investorGetAction(ownProps.match.params.investorUuid)),
        monthlyStatementsList: () => dispatch(investorAccountMonthlyStatementsListAction(ownProps.match.params.accountUuid)),
        sendStatement: (monthlyStatementUuid: string) => dispatch(investorAccountMonthlyStatementSendAction(monthlyStatementUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MonthlyStatements);
