import { SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Layout, Space, Spin, Table, TablePaginationConfig, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { FaCity, FaHome, FaHorse } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import { administratorsActiveSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import ApprovalStatusEnum from '~Api/Deal/ApprovalStatusEnum';
import IDeal from '~Api/Deal/IDeal';
import IProperty from '~Api/Deal/IProperty';
import ListSortFieldEnum from '~Api/Deal/ListSortFieldEnum';
import ListSortOrderEnum from '~Api/Deal/ListSortOrderEnum';
import PropertyTypeEnum from '~Api/Deal/PropertyTypeEnum';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';
import AssigneeSelector from '~Deals/AssigneeSelector';
import { IGlobalState } from '~reducer';
import SearchDropdown from '~UI/SearchDropdown';
import { IDictionary } from '~utilities/IDictionary';
import { leadsListAction } from './actions';
import ApprovalStatusBadge from './Lead/ApprovalStatusBadge';
import LeadAddModal from './LeadAddModal';
import Search from './Search';
import {
    leadsPaginatedCountSelector,
    leadsPaginatedSelector,
} from './selectors';
import { ColumnType } from 'antd/lib/table';
import { FilterDropdownProps } from 'antd/lib/table/interface';

const tableSortFieldMappings: IDictionary<ListSortFieldEnum> = {
    approvalStatus: ListSortFieldEnum.ApprovalStatus,
    code: ListSortFieldEnum.Code,
    createdTime: ListSortFieldEnum.CreatedTime,
    lastQuoteTime: ListSortFieldEnum.LastQuoteTime,
    loanAmount: ListSortFieldEnum.LoanAmount,
    name: ListSortFieldEnum.Name,
};

const tableSortOrderMappings: IDictionary<ListSortOrderEnum> = {
    ascend: ListSortOrderEnum.Ascending,
    descend: ListSortOrderEnum.Descending,
};

interface ILeadListSettings {
    administratorUuidFilter: string[];
    approvalStatusFilter: ApprovalStatusEnum[];
    currentPage: number;
    nameSearch: string[];
    pageSize: number;
    sortField: string;
    sortOrder: string;
}

interface IState {
    isAddModalOpen: boolean;
    leadListSettings: ILeadListSettings;
}

interface IPropsSelector {
    activeAdministrators: IAdministrator[];
    deals: IDictionary<IDeal>;
    leadsPaginatedCount: number;
}

interface IPropsDispatch {
    administratorsList: () => void;
    leadsList: (page: number, perPage: number, orderBy: ListSortFieldEnum, order: ListSortOrderEnum, administratorUuids: string[], approvalStatuses: ApprovalStatusEnum[], name?: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class List extends React.Component<Props, IState> {
    public state: IState = {
        isAddModalOpen: false,
        leadListSettings: {
            administratorUuidFilter: [],
            approvalStatusFilter: [
                ApprovalStatusEnum.Draft,
                ApprovalStatusEnum.FollowUp1,
                ApprovalStatusEnum.FollowUp2,
                ApprovalStatusEnum.FollowUp3,
                ApprovalStatusEnum.InitialCall,
                ApprovalStatusEnum.UncontactableFollowUp1,
                ApprovalStatusEnum.UncontactableFollowUp2,
                ApprovalStatusEnum.UncontactableFollowUp3,
                ApprovalStatusEnum.Pending,
                ApprovalStatusEnum.Quote,
            ],
            currentPage: 1,
            nameSearch: null,
            pageSize: 10,
            sortField: 'createdTime',
            sortOrder: 'descend',
        },
    };

    constructor(props: Props) {
        super(props);

        this.onClickAddLead = this.onClickAddLead.bind(this);
        this.onCancelAddLead = this.onCancelAddLead.bind(this);
        this.onChangeTable = this.onChangeTable.bind(this);
        this.settingsSet = this.settingsSet.bind(this);
    }

    public componentDidMount(): void {
        const { activeAdministrators } = this.props;
        const { leadListSettings } = this.state;

        this.props.leadsList(
            leadListSettings.currentPage,
            leadListSettings.pageSize,
            ListSortFieldEnum.CreatedTime,
            ListSortOrderEnum.Descending,
            leadListSettings.administratorUuidFilter,
            leadListSettings.approvalStatusFilter,
            leadListSettings.nameSearch ? leadListSettings.nameSearch[0] : null,
        );

        if (!activeAdministrators) {
            this.props.administratorsList();
        }
    }

    public render(): JSX.Element {
        const { activeAdministrators, deals, leadsPaginatedCount } = this.props;
        const { isAddModalOpen, leadListSettings } = this.state;

        if (!activeAdministrators) {
            return (
                <Layout className='leads'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Leads</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const lvrFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            style: 'percent',
        });

        const columns: ColumnType<IDeal>[] = [
            {
                dataIndex: 'approvalStatus',
                filteredValue: leadListSettings.approvalStatusFilter,
                filters: [
                    {
                        text: 'Draft',
                        value: ApprovalStatusEnum.Draft,
                    },
                    {
                        text: 'Initial Call',
                        value: ApprovalStatusEnum.InitialCall,
                    },
                    {
                        text: 'Uncontactable Follow Up 1',
                        value: ApprovalStatusEnum.UncontactableFollowUp1,
                    },
                    {
                        text: 'Uncontactable Follow Up 2',
                        value: ApprovalStatusEnum.UncontactableFollowUp2,
                    },
                    {
                        text: 'Uncontactable Follow Up 3',
                        value: ApprovalStatusEnum.UncontactableFollowUp3,
                    },
                    {
                        text: 'Quoted',
                        value: ApprovalStatusEnum.Quote,
                    },
                    {
                        text: 'Follow Up 1',
                        value: ApprovalStatusEnum.FollowUp1,
                    },
                    {
                        text: 'Follow Up 2',
                        value: ApprovalStatusEnum.FollowUp2,
                    },
                    {
                        text: 'Follow Up 3',
                        value: ApprovalStatusEnum.FollowUp3,
                    },
                    {
                        text: 'Rejected',
                        value: ApprovalStatusEnum.Rejected,
                    },
                    {
                        text: 'Referred',
                        value: ApprovalStatusEnum.Referred,
                    },
                    {
                        text: 'New',
                        value: ApprovalStatusEnum.Pending,
                    },
                    {
                        text: 'Application',
                        value: ApprovalStatusEnum.Application,
                    },
                ],
                key: 'approvalStatus',
                render: (approvalStatus: ApprovalStatusEnum) => <ApprovalStatusBadge approvalStatus={approvalStatus} />,
                width: '5%',
            },
            {
                key: 'code',
                render: (deal: IDeal) => <Link to={`/leads/${deal.uuid}`}>{deal.code}</Link>,
                sorter: true,
                title: 'Code',
                width: '10%',
            },
            {
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                filteredValue: leadListSettings.nameSearch,
                key: 'name',
                render: (deal: IDeal) => <Link to={`/leads/${deal.uuid}`}>{deal.formattedName || '-'}</Link>,
                sorter: true,
                title: 'Name',
            },
            {
                dataIndex: 'loanAmount',
                key: 'loanAmount',
                render: (loanAmount: number) => currencyFormatter.format(loanAmount),
                sorter: true,
                title: 'Loan Amount',
                width: '15%',
            },
            {
                dataIndex: 'administratorUuid',
                filteredValue: leadListSettings.administratorUuidFilter,
                filters: activeAdministrators.map((administrator: IAdministrator) => ({
                    text: administrator.name,
                    value: administrator.uuid,
                })),
                key: 'administratorUuid',
                render: (administratorUuid: string, deal: IDeal) => <AssigneeSelector deal={deal} />,
                title: 'Loan Officer',
                width: '15%',
            },
            {
                dataIndex: 'propertyType',
                key: 'propertyType',
                render: (propertyType: PropertyTypeEnum, deal: IDeal) => {
                    if (deal.properties.length > 0) {
                        const icons: JSX.Element[] = deal.properties.map((leadProperty: IProperty) => {
                            switch (leadProperty.zoneType) {
                                case ZoneTypeEnum.CommercialIndustrial:
                                case ZoneTypeEnum.CommercialLand:
                                case ZoneTypeEnum.CommercialOffice:
                                case ZoneTypeEnum.CommercialRetail:
                                    return <FaCity key={leadProperty.uuid} />;
                                case ZoneTypeEnum.ResidentialHouse:
                                case ZoneTypeEnum.ResidentialLand:
                                case ZoneTypeEnum.ResidentialTownhouse:
                                case ZoneTypeEnum.ResidentialUnit:
                                    return <FaHome key={leadProperty.uuid} />;
                                case ZoneTypeEnum.RuralLand:
                                case ZoneTypeEnum.RuralResidential:
                                    return <FaHorse key={leadProperty.uuid} />;
                            }
                        });

                        return (
                            <Space>
                                {icons}
                            </Space>
                        );
                    }

                    if (propertyType === PropertyTypeEnum.Commercial) {
                        return <FaCity/>;
                    }

                    if (propertyType === PropertyTypeEnum.Residential) {
                        return <FaHome/>;
                    }

                    if (propertyType === PropertyTypeEnum.Rural) {
                        return <FaHorse/>;
                    }
                },
                title: 'Type',
                width: '10%',
            },
            {
                key: 'lvr',
                render: (deal: IDeal) => {
                    const totalValue: number = _.sumBy(deal.properties, (leadProperty: IProperty) => leadProperty.estimatedValue);
                    const totalCurrentDebt: number = _.sumBy(deal.properties, (leadProperty: IProperty) => leadProperty.currentDebt);
                    const lvr: number = (deal.loanAmount + totalCurrentDebt) / totalValue;
                    return lvrFormatter.format(lvr);
                },
                title: 'LVR',
                width: '5%',
            },
            {
                dataIndex: 'lastQuoteTime',
                key: 'lastQuoteTime',
                render: (lastQuoteTime: string) => lastQuoteTime ? dayjs(lastQuoteTime).fromNow() : '-',
                sorter: true,
                title: 'Quoted',
                width: '10%',
            },
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                key: 'createdTime',
                render: (createdTime: string) => <Tooltip title={dayjs(createdTime).format('Do MMMM YYYY HH:mm:ss')}><span>{dayjs(createdTime).fromNow()}</span></Tooltip>,
                sorter: true,
                title: 'Created',
                width: '10%',
            },
        ];

        return (
            <Layout className='leads'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Leads</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Search/>
                            <Link to='/leads'><Button>Board View</Button></Link>
                            <Button className='add-lead' onClick={this.onClickAddLead} type='primary'>Add Lead</Button>
                        </Space>
                        <Typography.Title level={2}>Leads</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.values(deals)}
                            loading={!deals}
                            pagination={{ current: leadListSettings.currentPage, pageSize: leadListSettings.pageSize, total: leadsPaginatedCount }}
                            onChange={this.onChangeTable}
                            rowClassName={this.getRowClassName}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>

                <LeadAddModal
                    isOpen={isAddModalOpen}
                    onCancel={this.onCancelAddLead}
                />
            </Layout>
        );
    }

    private getRowClassName(deal: IDeal): string {
        if (deal.closedTime) {
            return 'closed';
        }

        return null;
    }

    private onClickAddLead(): void {
        this.setState({
            isAddModalOpen: true,
        });
    }

    private onCancelAddLead(): void {
        this.setState({
            isAddModalOpen: false,
        });
    }

    private onChangeTable(pagination: TablePaginationConfig, filters: any, sorter: any): void {
        this.props.leadsList(
            pagination.current,
            pagination.pageSize,
            tableSortFieldMappings[sorter.columnKey] || ListSortFieldEnum.Code,
            tableSortOrderMappings[sorter.order] || ListSortOrderEnum.Descending,
            filters.administratorUuid || [],
            filters.approvalStatus || [],
            filters.name ? filters.name[0] : null,
        );

        this.settingsSet({
            administratorUuidFilter: filters.administratorUuid,
            approvalStatusFilter: filters.approvalStatus,
            currentPage: pagination.current,
            nameSearch: filters.name,
            pageSize: pagination.pageSize,
            sortField: sorter.field,
            sortOrder: sorter.order,
        });
    }

    private settingsSet(leadListSettings: ILeadListSettings): void {
        this.setState({
            leadListSettings,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        activeAdministrators: administratorsActiveSelector(state),
        deals: leadsPaginatedSelector(state),
        leadsPaginatedCount: leadsPaginatedCountSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        leadsList: (page: number, perPage: number, orderBy: ListSortFieldEnum, order: ListSortOrderEnum, administratorUuids: string[], approvalStatuses: ApprovalStatusEnum[], name?: string) => dispatch(leadsListAction(page, perPage, orderBy, order, administratorUuids, approvalStatuses, name)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(List);
