import { Breadcrumb, Layout, Space, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IApplicationPropertyValuer from '~Api/Application/IApplicationPropertyValuer';
import ValuerStatusEnum from '~Api/Application/ValuerStatusEnum';
import { applicationPropertiesValuationsOutstandingListAction } from '~Applications/actions';
import { outstandingValuationsApplicationPropertiesSelector} from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { valuersListAction } from '~Valuers/actions';
import IValuer from '~Valuers/IValuer';
import { valuersSelector } from '~Valuers/selectors';

const valuerStatusLabels: IDictionary<string> = {
    [ValuerStatusEnum.New]: 'New',
    [ValuerStatusEnum.QuoteRequested]: 'Quote Requested',
    [ValuerStatusEnum.QuoteReceived]: 'Quote Received',
    [ValuerStatusEnum.ValuationOrdered]: 'Valuation Ordered',
};

interface IPropsSelector {
    properties: IApplicationProperty[];
    valuers: IDictionary<IValuer>;
}

interface IPropsDispatch {
    applicationPropertiesValuationsOutstandingList: () => void;
    valuersList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class OutstandingValuations extends React.Component<Props> {
    public componentDidMount() {
        const { valuers } = this.props;
        this.props.applicationPropertiesValuationsOutstandingList();
        if (!valuers) {
            this.props.valuersList();
        }
    }

    public render(): JSX.Element {
        const { properties, valuers } = this.props;

        if (!properties || !valuers) {
            return (
                <Layout className='applications'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/applications'>Applications</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Outstanding Valuations</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Outstanding Valuations</Typography.Title>
                            <Spin />
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const columns: ColumnType<IApplicationProperty>[] = [
            {
                render: (applicationProperty: IApplicationProperty) => {
                    return <Link to={`/applications/${applicationProperty.application.uuid}`}>{applicationProperty.application.code}</Link>;
                },
                title: 'Application Code',
                width: '10%',
            },
            {
                render: (applicationProperty: IApplicationProperty) => applicationProperty.application.formattedName,
                title: 'Application Name',
                width: '15%',
            },
            {
                render: (applicationProperty: IApplicationProperty) => {
                    return <Link to={`/applications/${applicationProperty.application.uuid}/property-valuations/${applicationProperty.uuid}?`}>{applicationProperty.dealProperty.formattedAddress}</Link>;
                },
                title: 'Property',
                width: '25%',
            },
            {
                dataIndex: 'valuers',
                render: (applicationPropertyValuers: IApplicationPropertyValuer[]) => applicationPropertyValuers.length > 0 ? valuerStatusLabels[this.findMostProgressedValuations(applicationPropertyValuers)[0].status] : '-',
                title: 'Status',
                width: '15%',
            },
            {
                dataIndex: 'valuers',
                render: (applicationPropertyValuers: IApplicationPropertyValuer[]) => {
                    if (applicationPropertyValuers.length === 0) {
                        return '-';
                    }
                    return (
                        <Space direction='vertical' >{this.findMostProgressedValuations(applicationPropertyValuers).map((applicationPropertyValuer: IApplicationPropertyValuer) => valuers[applicationPropertyValuer.valuerUuid].name)}</Space >
                    );
                },
                title: 'Valuer',
                width: '25%',
            },
            {
                defaultSortOrder: 'descend',
                render: (applicationProperty: IApplicationProperty) => {
                    if (!applicationProperty.valuationDueDate) {
                        return '-';
                    }
                    const valuationDueDateDayJs: Dayjs = dayjs(applicationProperty.valuationDueDate);
                    if (valuationDueDateDayJs < dayjs()) {
                        return (
                            <Typography.Text type='danger'>{valuationDueDateDayJs.format('Do MMMM YYYY')}</Typography.Text>
                        );
                    }
                    return valuationDueDateDayJs.format('Do MMMM YYYY');
                },
                sorter: (a: IApplicationProperty, b: IApplicationProperty) => {
                    if (!b.valuationDueDate) {
                        return 1;
                    }
                    if (!a.valuationDueDate) {
                        return -1;
                    }
                    return dayjs(a.valuationDueDate) > dayjs(b.valuationDueDate) ? -1 : 1;
                },
                title: 'Due Date',
                width: '10%',
            },
        ];

        return (
            <Layout className='applications'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/applications'>Applications</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Outstanding Valuations</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Outstanding Valuations</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={properties}
                            rowKey='uuid'
                            pagination={{ defaultPageSize: 50 }}
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    private findMostProgressedValuations(applicationPropertyValuers: IApplicationPropertyValuer[]): IApplicationPropertyValuer[] {
        const newValuations: IApplicationPropertyValuer[] = [];
        const quoteRequestedValuations: IApplicationPropertyValuer[] = [];
        const quoteReceivedValuations: IApplicationPropertyValuer[] = [];
        const valuationOrderedValuations: IApplicationPropertyValuer[] = [];

        _.forEach(applicationPropertyValuers, (applicationPropertyValuer: IApplicationPropertyValuer) => {
            switch (applicationPropertyValuer.status) {
                case ValuerStatusEnum.New:
                    newValuations.push(applicationPropertyValuer);
                    break;
                case ValuerStatusEnum.QuoteRequested:
                    quoteRequestedValuations.push(applicationPropertyValuer);
                    break;
                case ValuerStatusEnum.QuoteReceived:
                    quoteReceivedValuations.push(applicationPropertyValuer);
                    break;
                case ValuerStatusEnum.ValuationOrdered:
                    valuationOrderedValuations.push(applicationPropertyValuer);
                    break;
            }
        });

        if (valuationOrderedValuations.length > 0) {
            return valuationOrderedValuations;
        }
        if (quoteReceivedValuations.length > 0) {
            return quoteReceivedValuations;
        }
        if (quoteRequestedValuations.length > 0) {
            return quoteRequestedValuations;
        }
        if (newValuations.length > 0) {
            return newValuations;
        }
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        properties: outstandingValuationsApplicationPropertiesSelector(state),
        valuers: valuersSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        applicationPropertiesValuationsOutstandingList: () => dispatch((applicationPropertiesValuationsOutstandingListAction())),
        valuersList: () => dispatch(valuersListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OutstandingValuations);
