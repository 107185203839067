import { Breadcrumb, Layout, Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import { IDictionary } from '~utilities/IDictionary';
import { warehousesListAction } from './actions';
import { warehousesSelector } from './selectors';
import { currencyFormatter } from '~utilities/formatters';
import { getUnusedFunds } from './utilities';

export default function List(): ReactElement {
    const warehouses: IDictionary<IWarehouse> = useSelector(warehousesSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        dispatch(warehousesListAction());

        const refreshInterval: NodeJS.Timeout = setInterval(() => {
            dispatch(warehousesListAction());
        }, 5 * 60 * 1000);

        return () => {
            clearInterval(refreshInterval);
        };
    }, [dispatch]);

    const summary: () => JSX.Element = useCallback(() => {
        let totalActiveLoans: number = 0;
        let totalUsableFunds: number = 0;
        let totalUsedFunds: number = 0;
        let totalReservedApplications: number = 0;
        let totalReservedPurchases: number = 0;
        let totalReservedSales: number = 0;
        let totalRetainedContingency: number = 0;
        let totalRetainedFunds: number = 0;
        let totalUnusedFunds: number = 0;

        _.forEach(warehouses, (warehouse: IWarehouse) => {
            totalActiveLoans += warehouse.activeLoanCount;
            totalUsableFunds += warehouse.totalCapital || 0;
            totalUsedFunds += warehouse.totalBalancePrincipal;
            totalReservedApplications += warehouse.reservedApplications;
            totalReservedPurchases += warehouse.reservedPurchases;
            totalReservedSales += warehouse.reservedSales;
            totalRetainedContingency += warehouse.retainedContingency;
            totalRetainedFunds += warehouse.retainedFunds;
            totalUnusedFunds += getUnusedFunds(warehouse);
        });

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}><strong>Total</strong></Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={1}><strong>{totalActiveLoans}</strong></Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={2}><strong>{currencyFormatter.format(totalUsableFunds)}</strong></Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={3}><strong>{currencyFormatter.format(totalUsedFunds)}</strong></Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={4}><strong>{currencyFormatter.format(totalReservedApplications)}</strong></Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={5}><strong>{currencyFormatter.format(totalReservedPurchases)}</strong></Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={6}><strong>{currencyFormatter.format(totalReservedSales)}</strong></Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={6}><strong>{currencyFormatter.format(totalRetainedContingency + totalRetainedFunds)}</strong></Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={7}><strong>{currencyFormatter.format(totalUnusedFunds)}</strong></Table.Summary.Cell>
            </Table.Summary.Row>
        );
    }, [warehouses]);

    if (!warehouses) {
        return (
            <Layout className='warehouses'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Warehouses</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Warehouses</Typography.Title>
                        <Spin/>
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    const columns: ColumnsType<IWarehouse> = [
        {
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            render: (name: string, warehouse: IWarehouse) => <Link to={`/warehouses/${warehouse.uuid}`}>{name}</Link>,
            sorter: (a: IWarehouse, b: IWarehouse) => a.name.localeCompare(b.name),
            title: 'Name',
        },
        {
            align: 'right',
            dataIndex: 'activeLoanCount',
            render: (activeLoanCount: number, warehouse: IWarehouse) => <Link to={`/warehouses/${warehouse.uuid}/loans`}>{activeLoanCount}</Link>,
            title: 'Loans',
            width: '5%',
        },
        {
            align: 'right',
            dataIndex: 'totalCapital',
            render: (totalCapital: number) => totalCapital ? currencyFormatter.format(totalCapital) : '-',
            title: 'Usable Funds',
            width: '11%',
        },
        {
            align: 'right',
            dataIndex: 'totalBalancePrincipal',
            render: (totalBalancePrincipal: number, warehouse: IWarehouse) => <Link to={`/warehouses/${warehouse.uuid}/loans`}>{currencyFormatter.format(totalBalancePrincipal)}</Link>,
            title: 'Used Funds',
            width: '11%',
        },
        {
            align: 'right',
            dataIndex: 'reservedApplications',
            render: (reservedApplications: number, warehouse: IWarehouse) => <Link to={`/warehouses/${warehouse.uuid}/pending-applications`}>{reservedApplications ? currencyFormatter.format(reservedApplications) : '-'}</Link>,
            title: 'Applications',
            width: '11%',
        },
        {
            align: 'right',
            dataIndex: 'reservedPurchases',
            render: (reservedPurchases: number, warehouse: IWarehouse) => <Link to={`/warehouses/${warehouse.uuid}/proposed-purchases`}>{reservedPurchases ? currencyFormatter.format(reservedPurchases) : '-'}</Link>,
            title: 'Purchases',
            width: '11%',
        },
        {
            align: 'right',
            dataIndex: 'reservedSales',
            render: (reservedSales: number, warehouse: IWarehouse) => <Link to={`/warehouses/${warehouse.uuid}/proposed-sales`}>{reservedSales ? currencyFormatter.format(reservedSales) : '-'}</Link>,
            title: 'Sales',
            width: '11%',
        },
        {
            align: 'right',
            render: (warehouse: IWarehouse) => warehouse.retainedFunds ? currencyFormatter.format(warehouse.retainedFunds + warehouse.retainedContingency) : '-',
            title: 'Retained',
            width: '11%',
        },
        {
            align: 'right',
            render: (warehouse: IWarehouse) => warehouse.totalCapital ? currencyFormatter.format(getUnusedFunds(warehouse)) : '-',
            title: 'Unused Funds',
            width: '11%',
        },
    ];

    return (
        <Layout className='warehouses'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Warehouses</Breadcrumb.Item>
            </Breadcrumb>
            <Layout className='content-wrapper'>
                <Layout.Content className='content'>
                    <Typography.Title level={2}>Warehouses</Typography.Title>
                    <Table
                        columns={columns}
                        dataSource={_.values(warehouses)}
                        pagination={false}
                        rowKey='uuid'
                        size='middle'
                        summary={summary}
                    />
                </Layout.Content>
            </Layout>
        </Layout>
    );
}
