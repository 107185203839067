import IWarehouse from '~Api/Warehouse/IWarehouse';
import IWarehouseActiveReportLoan from '~Api/Warehouse/IWarehouseActiveReportLoan';
import IWarehouseEligibleLoan from '~Api/Warehouse/IWarehouseEligibleLoan';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import IWarehouseLoanTransaction from '~Api/Warehouse/IWarehouseLoanTransaction';
import IWarehouseParameter from '~Api/Warehouse/IWarehouseParameter';
import IWarehouseProposedSale from '~Api/Warehouse/IWarehouseProposedSale';
import IWarehouseProposedSaleDestination from '~Api/Warehouse/IWarehouseProposedSaleDestination';
import IWarehouseTransaction from '~Api/Warehouse/IWarehouseTransaction';
import IWarehousesPortfolioMetrics from '~Api/Warehouse/IWarehousesPortfolioMetrics';
import { loanSelector } from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import _ from 'lodash';
import ILoanBookForecastDay from '~Api/Warehouse/ILoanBookForecastDay';

function hydrateWarehouseProposedSale(state: IGlobalState, warehouseProposedSale: IWarehouseProposedSale): IWarehouseProposedSale {
    const cloneWarehouseProposedSale: IWarehouseProposedSale = { ...warehouseProposedSale };
    cloneWarehouseProposedSale.warehouseProposedSaleDestinations = _.values(warehouseProposedSaleDestinationsSelector(state, warehouseProposedSale.uuid));
    cloneWarehouseProposedSale.sourceWarehouseLoan = warehouseProposedSaleWarehouseLoanSelector(state, warehouseProposedSale.uuid);
    return cloneWarehouseProposedSale;
}

function hydrateWarehouseLoan(state: IGlobalState, warehouseLoan: IWarehouseLoan, nested: boolean = false): IWarehouseLoan {
    const cloneWarehouseLoan: IWarehouseLoan = { ...warehouseLoan };
    if (!nested) {
        cloneWarehouseLoan.loan = loanSelector(state, warehouseLoan.loanUuid);
    }
    return cloneWarehouseLoan;
}

export function warehouseActiveReportLoansSelector(state: IGlobalState, warehouseUuid: string): IDictionary<IWarehouseActiveReportLoan> {
    if (state.warehouses.warehouseActiveReportLoans.warehouseUuid !== warehouseUuid) {
        return null;
    }

    return state.warehouses.warehouseActiveReportLoans.warehouseLoans;
}

export function warehouseEligibleLoansSelector(state: IGlobalState, warehouseUuid: string): IDictionary<IWarehouseEligibleLoan> {
    if (!state.warehouses.warehouseEligibleLoans[warehouseUuid]) {
        return null;
    }

    const eligibleLoans: IDictionary<IWarehouseEligibleLoan> = state.warehouses.warehouseEligibleLoans[warehouseUuid];
    _.forEach(eligibleLoans, (eligibleLoan: IWarehouseEligibleLoan) => {
        eligibleLoan.loan = loanSelector(state, eligibleLoan.loanUuid);
        eligibleLoan.warehouseLoans = _.map(eligibleLoan.warehouseLoanUuids, (warehouseLoanUuid: string) => {
            return warehouseLoanSelector(state, warehouseLoanUuid);
        });
    });

    return eligibleLoans;
}

export function warehouseLoanSelector(state: IGlobalState, warehouseLoanUuid: string, nested: boolean = false): IWarehouseLoan {
    return state.warehouses.warehouseLoans && state.warehouses.warehouseLoans[warehouseLoanUuid] && hydrateWarehouseLoan(state, state.warehouses.warehouseLoans[warehouseLoanUuid], nested);
}

export function warehouseLoanTransactionsSelector(state: IGlobalState, warehouseLoanUuid: string): IDictionary<IWarehouseLoanTransaction> {
    if (!state.warehouses.warehouseLoanTransactionUuids[warehouseLoanUuid]) {
        return null;
    }

    const warehouseLoanTransactions: IDictionary<IWarehouseLoanTransaction> = {};
    state.warehouses.warehouseLoanTransactionUuids[warehouseLoanUuid].forEach((warehouseLoanTransactionUuid: string) => {
        warehouseLoanTransactions[warehouseLoanTransactionUuid] = state.warehouses.warehouseLoanTransactions[warehouseLoanTransactionUuid];
    });

    return warehouseLoanTransactions;
}

export function warehouseLoansSelector(state: IGlobalState, warehouseUuid: string): IDictionary<IWarehouseLoan> {
    if (!state.warehouses.warehouseLoansListed || !state.warehouses.warehouseLoanUuids[warehouseUuid]) {
        return null;
    }

    const warehouseLoans: IDictionary<IWarehouseLoan> = {};
    state.warehouses.warehouseLoanUuids[warehouseUuid].forEach((warehouseLoanUuid: string) => {
        warehouseLoans[warehouseLoanUuid] = hydrateWarehouseLoan(state, state.warehouses.warehouseLoans[warehouseLoanUuid]);
    });

    return warehouseLoans;
}

export function warehouseParametersSelector(state: IGlobalState, warehouseUuid: string): IWarehouseParameter[] {
    return state.warehouses.warehouseParameters[warehouseUuid];
}

export function warehouseProposedPurchaseParametersSelector(state: IGlobalState, warehouseUuid: string): IWarehouseParameter[] {
    return state.warehouses.warehouseProposedPurchaseParameters[warehouseUuid];
}

export function warehouseProposedSalesPendingSelector(state: IGlobalState): IDictionary<IWarehouseProposedSale> {
    if (!state.warehouses.pendingWarehouseProposedSaleUuids) {
        return null;
    }

    const warehouseProposedSales: IDictionary<IWarehouseProposedSale> = {};
    _.forEach(state.warehouses.pendingWarehouseProposedSaleUuids, (pendingWarehouseProposedSaleUuid: string) => {
        warehouseProposedSales[pendingWarehouseProposedSaleUuid] = hydrateWarehouseProposedSale(state, state.warehouses.warehouseProposedSales[pendingWarehouseProposedSaleUuid]);
    });

    return warehouseProposedSales;
}

export function warehouseProposedSaleDestinationsSelector(state: IGlobalState, warehouseProposedSaleUuid: string): IDictionary<IWarehouseProposedSaleDestination> {
    if (!state.warehouses.warehouseProposedSaleDestinationUuids[warehouseProposedSaleUuid]) {
        return null;
    }

    const warehouseProposedSaleDestinations: IDictionary<IWarehouseProposedSaleDestination> = {};
    _.forEach(state.warehouses.warehouseProposedSaleDestinationUuids[warehouseProposedSaleUuid], (warehouseProposedSaleDestinationUuid: string) => {
        warehouseProposedSaleDestinations[warehouseProposedSaleDestinationUuid] = state.warehouses.warehouseProposedSaleDestinations[warehouseProposedSaleDestinationUuid];
    });

    return warehouseProposedSaleDestinations;
}

export function warehouseProposedSaleWarehouseLoanSelector(state: IGlobalState, warehouseProposedSaleUuid: string): IWarehouseLoan {
    if (!state.warehouses.warehouseProposedSaleWarehouseLoanUuids || !state.warehouses.warehouseProposedSaleWarehouseLoanUuids[warehouseProposedSaleUuid] || !state.warehouses.warehouseLoans) {
        return null;
    }

    return state.warehouses.warehouseLoans[state.warehouses.warehouseProposedSaleWarehouseLoanUuids[warehouseProposedSaleUuid]];
}

export function warehouseTransactionsSelector(state: IGlobalState, warehouseUuid: string): IDictionary<IWarehouseTransaction> {
    if (!state.warehouses.warehouseTransactionUuids[warehouseUuid]) {
        return null;
    }

    const warehouseTransactions: IDictionary<IWarehouseTransaction> = {};
    state.warehouses.warehouseTransactionUuids[warehouseUuid].forEach((warehouseTransactionUuid: string) => {
        warehouseTransactions[warehouseTransactionUuid] = state.warehouses.warehouseTransactions[warehouseTransactionUuid];
    });

    return warehouseTransactions;
}

export function warehouseSelector(state: IGlobalState, warehouseUuid: string): IWarehouse {
    return state.warehouses.warehouses && state.warehouses.warehouses[warehouseUuid];
}

export function warehousesSelector(state: IGlobalState): IDictionary<IWarehouse> {
    return state.warehouses.warehouses;
}

export function warehousesLoanBookForecastDaysSelector(state: IGlobalState): ILoanBookForecastDay[] {
    return state.warehouses.loanBookForecastDays;
}

export function warehousesPortfolioDashboardSelector(state: IGlobalState): IDictionary<IWarehousesPortfolioMetrics> {
    return state.warehouses.warehousesPortfolioDashboard;
}
