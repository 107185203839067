import { Layout as AntLayout, Breadcrumb, Button, Descriptions, Menu, Modal, PageHeader, Space, Spin } from 'antd';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import accountTypeLabels from '~Api/Investor/accountTypeLabels';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import NextContact from '~Investors/NextContact';
import { investorSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import {
    investorCreateIncomeTrustAccountAction,
    investorGetAction,
} from '../actions';
import ImSelector from './ImSelector';
import './investor.less';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import AdviserSelector from '~Investors/AdviserSelector';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import menuDivider from '~UI/menuDivider';

export default function Layout(props: {
    breadcrumb?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    uuid: string;
    section: string;
    sectionClass?: string;
}): ReactElement {
    const { breadcrumb, children, section, sectionClass, uuid } = props;

    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);
    const investor: IInvestor = useSelector((state: IGlobalState) => investorSelector(state, uuid));

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!investor) {
            dispatch(investorGetAction(uuid));
        }
    }, []);

    const onClickIncomeTrust: () => void = useCallback(() => {
        Modal.confirm({
            cancelText: 'No',
            content: 'Are you sure you want to create an Income Trust account for this investor?',
            okText: 'Yes',
            onOk: () => {
                dispatch(investorCreateIncomeTrustAccountAction(uuid));
            },
            title: 'Create Income Trust Account',
        });
    }, []);

    if (!investor) {
        return (
            <AntLayout className='investor'>
                <Spin/>
            </AntLayout>
        );
    }

    const incomeTrustAccount: IAccount = investor.accounts[AccountTypeEnum.IncomeTrust];

    const breadcrumbBlock: JSX.Element = breadcrumb ? (
        <React.Fragment>
            <Breadcrumb.Item><Link to={`/investors/${investor.uuid}`}>{investor.name}</Link></Breadcrumb.Item>
            {breadcrumb}
        </React.Fragment>
    ) : (
        <Breadcrumb.Item>{investor.name}</Breadcrumb.Item>
    );

    const extra: JSX.Element = (
        <Space>
            {!incomeTrustAccount && <Button onClick={onClickIncomeTrust}>Create Income Trust Account</Button>}
        </Space>
    );

    const menuItems: ItemType[] = [
        {
            key: 'overview',
            label: <NavLink to={`/investors/${investor.uuid}`}>Overview</NavLink>,
        },
        {
            key: 'edit',
            label: <NavLink to={`/investors/${investor.uuid}/edit`}>Edit</NavLink>,
        },
        {
            key: 'bank-accounts',
            label: <NavLink to={`/investors/${investor.uuid}/bank-accounts`}>Bank Accounts</NavLink>,
        },
        {
            key: 'compliance',
            label: <NavLink to={`/investors/${investor.uuid}/compliance`}>Compliance</NavLink>,
        },
        {
            key: 'individuals',
            label: <NavLink to={`/investors/${investor.uuid}/individuals`}>Individuals</NavLink>,
        },
        ...(currentUser.permissions.includes(PermissionsEnum.Users) ? [
            {
                key: 'users',
                label: <NavLink to={`/investors/${investor.uuid}/users`}>Users</NavLink>,
            },
        ] : []),
        {
            key: 'referrals',
            label: <NavLink to={`/investors/${investor.uuid}/referrals`}>Referrals</NavLink>,
        },
        {
            key: 'documents',
            label: <NavLink to={`/investors/${investor.uuid}/documents`}>Documents</NavLink>,
        },
        {
            key: 'notes',
            label: <NavLink to={`/investors/${investor.uuid}/notes`}>Notes</NavLink>,
        },
        ...(_.reduce(_.orderBy(investor.accounts, 'accountType'), (items: ItemType[], account: IAccount): ItemType[] => {
            const codeText: string = (account.code === null) ? '' : `(${account.code})`;

            return [
                ...items,
                menuDivider,
                {
                    children: [
                        {
                            key: `account-${account.uuid}`,
                            label: <NavLink to={`/investors/${investor.uuid}/accounts/${account.uuid}`}>Overview</NavLink>,
                        },
                        ...(account.accountType === AccountTypeEnum.IncomeTrust ? [
                            {
                                key: `income-trust-${account.uuid}`,
                                label: <NavLink to={`/investors/${investor.uuid}/accounts/${account.uuid}/income-trust-investments`}>Investments</NavLink>,
                            },
                        ] : []),
                        ...(account.accountType === AccountTypeEnum.Marketplace ? [
                            {
                                key: `investments-${account.uuid}`,
                                label: <NavLink to={`/investors/${investor.uuid}/accounts/${account.uuid}/investments`}>Investments</NavLink>,
                            },
                        ] : []),
                        {
                            key: `transactions-${account.uuid}`,
                            label: <NavLink to={`/investors/${investor.uuid}/accounts/${account.uuid}/transactions`}>Transactions</NavLink>,
                        },
                        {
                            key: `withdrawal-aba-investor-transactions-${account.uuid}`,
                            label: <NavLink to={`/investors/${investor.uuid}/accounts/${account.uuid}/withdrawal-aba-investor-transactions`}>Withdrawal Abas</NavLink>,
                        },
                        ...([AccountTypeEnum.Marketplace, AccountTypeEnum.IncomeTrust].includes(account.accountType) ? [
                            {
                                key: `monthly-statements-${account.uuid}`,
                                label: <NavLink to={`/investors/${investor.uuid}/accounts/${account.uuid}/monthly-statements`}>Monthly Statements</NavLink>,
                            },
                        ] : []),
                        {
                            key: `annual-statements-${account.uuid}`,
                            label: <NavLink to={`/investors/${investor.uuid}/accounts/${account.uuid}/annual-statements`}>Annual Statements</NavLink>,
                        },
                    ],
                    key: account.accountType,
                    label: `${accountTypeLabels[account.accountType]} ${codeText}`,
                    type: 'group',
                },
            ];
        }, [])),
        menuDivider,
        {
            key: 'history',
            label: <NavLink to={`/investors/${investor.uuid}/history`}>History</NavLink>,
        },
    ];

    return (
        <AntLayout className='investor'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/investors'>Investors</Link></Breadcrumb.Item>
                {breadcrumbBlock}
            </Breadcrumb>
            <PageHeader
                className='header'
                extra={extra}
                ghost={false}
                title={investor.name || '-'}
            >
                <Descriptions size='small' column={3}>
                    <Descriptions.Item label='IM'><ImSelector investor={investor} /></Descriptions.Item>
                    <Descriptions.Item label='Next Contact'><NextContact investor={investor} /></Descriptions.Item>
                    <Descriptions.Item label='Adviser'><AdviserSelector investor={investor} /></Descriptions.Item>
                </Descriptions>
            </PageHeader>
            <AntLayout className='content-wrapper'>
                <AntLayout.Sider className='menu' theme='light' width={240}>
                    <Menu
                        defaultSelectedKeys={[section]}
                        items={menuItems}
                        mode='inline'
                    />
                </AntLayout.Sider>
                <AntLayout.Content className={`content ${section} ${sectionClass}`}>
                    {children}
                </AntLayout.Content>
            </AntLayout>
        </AntLayout>
    );
}
