import { Form, Input, Modal, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import GenderEnum from '~Api/Application/GenderEnum';
import IDealBorrower from '~Api/Deal/IDealBorrower';
import { applicationBorrowerCreditCheckAction } from '~Applications/actions';
import { dealBorrowerValueSetAction } from '~Deals/actions';
import { applicationBorrowerDealBorrowerSelector } from '~Deals/selectors';
import { IGlobalState } from '~reducer';
import DatePicker from '~UI/DatePicker';
import IConditionComponentProps from './IConditionComponentProps';

interface IState {
    isModalOpen: boolean;
}

interface IPropsSelector {
    dealBorrower: IDealBorrower;
}

interface IPropsDispatch {
    creditCheck: () => void;
    dealBorrowerValueSet: (dealBorrowerUuid: string, key: keyof IDealBorrower, value: boolean|number|string) => void;
}

type Props = IConditionComponentProps & IPropsSelector & IPropsDispatch;

class CreditCheck extends React.Component<Props, IState> {
    public state: IState = {
        isModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickSearch = this.onClickSearch.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);

        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeDob = this.onChangeDob.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeGender = this.onChangeGender.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeLicenceNumber = this.onChangeLicenceNumber.bind(this);
    }

    public render(): JSX.Element {
        const { dealBorrower } = this.props;
        const { isModalOpen } = this.state;

        if (!dealBorrower) {
            return null;
        }

        return (
            <React.Fragment>
                <a onClick={this.onClickSearch}><AiOutlineFileSearch/></a>
                <Modal
                    okText='Run Credit Check'
                    onCancel={this.onClickCancel}
                    onOk={this.onClickOk}
                    open={isModalOpen}
                    title='Credit File Search Report'
                    wrapClassName='application-borrower-credit-check-modal'
                >
                    <Form.Item className='first-name' label='First Name'>
                        <Input onChange={this.onChangeFirstName} value={dealBorrower.firstName} />
                    </Form.Item>
                    <Form.Item className='last-name' label='Last Name'>
                        <Input onChange={this.onChangeLastName} value={dealBorrower.lastName} />
                    </Form.Item>
                    <Form.Item className='dob' label='DOB'>
                        <DatePicker onChange={this.onChangeDob} format='DD/MM/YYYY' value={dealBorrower.dob ? dayjs(dealBorrower.dob) : null} />
                    </Form.Item>
                    <Form.Item className='gender' label='Gender'>
                        <Select onChange={this.onChangeGender} value={dealBorrower.gender}>
                            <Select.Option value={GenderEnum.Female}>Female</Select.Option>
                            <Select.Option value={GenderEnum.Male}>Male</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item className='licence-number' label='Licence Number'>
                        <Input onChange={this.onChangeLicenceNumber} value={dealBorrower.licenceNumber} />
                    </Form.Item>
                    <Form.Item className='address' label='Address'>
                        <Input onChange={this.onChangeAddress} value={dealBorrower.address} />
                    </Form.Item>
                </Modal>
            </React.Fragment>
        );
    }

    private onClickSearch() {
        this.setState({
            isModalOpen: true,
        });
    }

    private onClickOk() {
        this.setState({
            isModalOpen: false,
        });

        this.props.creditCheck();
    }

    private onClickCancel() {
        this.setState({
            isModalOpen: false,
        });
    }

    private onChangeAddress(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'address', event.target.value.substr(0, 255));
    }

    private onChangeDob(date: Dayjs) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'dob', date ? date.format('YYYY-MM-DD') : null);
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'firstName', event.target.value.substr(0, 50));
    }

    private onChangeGender(value: GenderEnum) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'gender', value);
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'lastName', event.target.value.substr(0, 50));
    }

    private onChangeLicenceNumber(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'licenceNumber', event.target.value.substr(0, 20));
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IConditionComponentProps): IPropsSelector {
    return {
        dealBorrower: applicationBorrowerDealBorrowerSelector(state, ownProps.applicationUuid, ownProps.applicationBorrowerUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IConditionComponentProps): IPropsDispatch {
    return {
        creditCheck: () => dispatch(applicationBorrowerCreditCheckAction(ownProps.applicationUuid, ownProps.applicationBorrowerUuid)),
        dealBorrowerValueSet: (dealBorrowerUuid: string, key: keyof IDealBorrower, value: boolean|number|string) => dispatch(dealBorrowerValueSetAction(dealBorrowerUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreditCheck);
