import { Spin, Table, Typography } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestorAccountTransaction from '~Api/Investor/IInvestorAccountTransaction';
import IWithdrawalAba from '~Api/WithdrawalAba/IWithdrawalAba';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { withdrawalAbaGetAction, withdrawalAbaInvestorTransactionsListAction } from '~WithdrawalAbas/actions';
import { withdrawalAbaInvestorTransactionsSelector, withdrawalAbaSelector } from '~WithdrawalAbas/selectors';
import Layout from './Layout';
import { currencyFormatter } from '~utilities/formatters';
import IAccount from '~Api/Investor/IAccount';

interface IMatch {
    withdrawalAbaUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    investorAccountTransactions: IDictionary<IInvestorAccountTransaction>;
    withdrawalAba: IWithdrawalAba;
}

interface IPropsDispatch {
    investorAccountTransactionsList: () => void;
    withdrawalAbaGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Transactions extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.summary = this.summary.bind(this);
    }

    public componentDidMount(): void {
        const { withdrawalAba, investorAccountTransactions } = this.props;

        if (!withdrawalAba) {
            this.props.withdrawalAbaGet();
        }

        if (!investorAccountTransactions) {
            this.props.investorAccountTransactionsList();
        }
    }

    public render(): JSX.Element {
        const { match, investorAccountTransactions, withdrawalAba } = this.props;

        if (!investorAccountTransactions || !withdrawalAba) {
            return (
                <Layout uuid={match.params.withdrawalAbaUuid} section='transactions'>
                    <Typography.Title level={2}>Transactions</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const columns: ColumnType<IInvestorAccountTransaction>[] = [
            {
                dataIndex: 'investorAccount',
                render: (investorAccount: IAccount) => <Link to={`/investors/${investorAccount.investorUuid}/accounts/${investorAccount.uuid}`}>{investorAccount.code}</Link>,
                title: 'Account ID',
                width: '15%',
            },
            {
                dataIndex: 'investorAccount',
                render: (investorAccount: IAccount) => investorAccount.investorName,
                title: 'Investor',
            },
            {
                dataIndex: 'amount',
                render: (amount: number) => currencyFormatter.format(amount),
                sorter: (a: IInvestorAccountTransaction, b: IInvestorAccountTransaction) => a.amount > b.amount ? 1 : -1,
                title: 'Amount',
                width: '15%',
            },
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => dayjs(createdTime).format('DD/MM/YYYY'),
                sorter: (a: IInvestorAccountTransaction, b: IInvestorAccountTransaction) => dayjs(a.createdTime) > dayjs(b.createdTime) ? 1 : -1,
                title: 'Created',
                width: '8%',
            },
        ];

        return (
            <Layout uuid={match.params.withdrawalAbaUuid} section='transactions'>
                <Typography.Title level={2}>Transactions</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(investorAccountTransactions)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                    summary={this.summary}
                />
            </Layout>
        );
    }

    private summary(): JSX.Element {
        const { investorAccountTransactions } = this.props;

        if (!investorAccountTransactions) {
            return null;
        }

        const total: number = _.reduce(investorAccountTransactions, (sum: number, transaction: IInvestorAccountTransaction) => sum + transaction.amount, 0);

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}/>
                <Table.Summary.Cell index={2}>{currencyFormatter.format(total)}</Table.Summary.Cell>
                <Table.Summary.Cell index={3}/>
            </Table.Summary.Row>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investorAccountTransactions: withdrawalAbaInvestorTransactionsSelector(state, ownProps.match.params.withdrawalAbaUuid),
        withdrawalAba: withdrawalAbaSelector(state, ownProps.match.params.withdrawalAbaUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investorAccountTransactionsList: () => dispatch(withdrawalAbaInvestorTransactionsListAction(ownProps.match.params.withdrawalAbaUuid)),
        withdrawalAbaGet: () => dispatch(withdrawalAbaGetAction(ownProps.match.params.withdrawalAbaUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Transactions);
