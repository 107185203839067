import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IGlobalState } from '~reducer';
import IInvestor from '../../Api/Investor/IInvestor';
import Table, { ColumnType } from 'antd/lib/table';
import ComplianceDocumentTypeEnum from '~Api/Investor/ComplianceDocumentTypeEnum';
import { Button, Dropdown, MenuProps, Modal, Upload } from 'antd';
import { CloudDownloadOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import IIndividual from '~Api/Investor/IIndividual';
import { IDictionary } from '~utilities/IDictionary';
import IAccount from '~Api/Investor/IAccount';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import InvestorTypeEnum from '~Api/Investor/InvestorTypeEnum';
import IComplianceDocument from '~Api/Investor/IComplianceDocument';
import {
    investorComplianceDocumentDeleteAction,
    investorComplianceDocumentsAddAction,
    investorComplianceDocumentsListAction,
} from '~Investors/actions';
import { investorComplianceDocumentsSelector } from '~Investors/selectors';
import { authTokenSelector } from '~Auth/selectors';

const documentTypeLabels: IDictionary<string> = {
    [ComplianceDocumentTypeEnum.AccountantCertificate]: 'Accountant Certificate',
    [ComplianceDocumentTypeEnum.CompanyFile]: 'Company File',
    [ComplianceDocumentTypeEnum.IdProof]: 'Proof of Identity',
    [ComplianceDocumentTypeEnum.IdentityVerification]: 'Identity Verification',
    [ComplianceDocumentTypeEnum.InformationMemorandum]: 'Information Memorandum',
    [ComplianceDocumentTypeEnum.ProductDisclosureStatement]: 'Product Disclosure Statement',
    [ComplianceDocumentTypeEnum.TargetMarketDetermination]: 'Target Market Determination',
};

interface IComplianceRow {
    documentType: ComplianceDocumentTypeEnum;
    documents?: IComplianceDocument[];
    uid: string; // This is a unique ID not a UUID
}

interface IProps {
    account?: IAccount;
    individual?: IIndividual;
    investor?: IInvestor;
    investorUuid: string;
}

interface IPropsSelector {
    complianceDocuments: IDictionary<IComplianceDocument>;
    token: string;
}

interface IPropsDispatch {
    complianceDocumentsAdd: (file: File, complianceDocument: IComplianceDocument) => void;
    complianceDocumentsList: () => void;
    complianceDocumentDelete: (complianceDocumentUuid: string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class ComplianceDocumentsList extends React.Component<Props> {
    public componentDidMount(): void {
        const { complianceDocuments } = this.props;

        if (!complianceDocuments) {
            this.props.complianceDocumentsList();
        }
    }

    public render(): JSX.Element {
        const { account, complianceDocuments, individual, investor, token } = this.props;

        if (!complianceDocuments) {
            return null;
        }

        const columns: ColumnType<IComplianceRow>[] = [
            {
                dataIndex: 'documentType',
                render: (documentType: ComplianceDocumentTypeEnum) => documentTypeLabels[documentType] || documentType,
                title: 'Purpose',
            },
            {
                dataIndex: 'documents',
                render: (documents: IComplianceDocument[]) => {
                    return documents.map((document: IComplianceDocument) => {
                        const menu: MenuProps = {
                            items: [
                                {
                                    danger: true,
                                    icon: <DeleteOutlined/>,
                                    key: document.uuid,
                                    label: 'Delete',
                                    onClick: () => {
                                        Modal.confirm({
                                            content: 'Are you sure you want to delete this compliance document?',
                                            okText: 'Delete',
                                            okType: 'danger',
                                            onOk: () => {
                                                this.props.complianceDocumentDelete(document.uuid);
                                            },
                                            title: 'Delete Compliance Document',
                                        });
                                    },
                                },
                            ],
                        };

                        return (
                            <Dropdown key={document.uuid} menu={menu}>
                                <Button
                                    href={`${process.env.API_HOST}/investor-documents/${document.investorFileUuid}/download?token=${token}`}
                                    target='_blank'
                                    type='link'
                                >
                                    <CloudDownloadOutlined/>
                                </Button>
                            </Dropdown>
                        );
                    });
                },
                title: 'Documents',
                width: '30%',
            },
            {
                render: (complianceRow: IComplianceRow) => {
                    const beforeUpload: (file: File) => boolean = (file: File) => {
                        const complianceDocument: IComplianceDocument = {
                            documentType: complianceRow.documentType,
                        };

                        if (account) {
                            complianceDocument.investorAccountUuid = account.uuid;
                        } else if (individual) {
                            complianceDocument.investorIndividualUuid = individual.uuid;
                        } else {
                            complianceDocument.investorUuid = investor.uuid;
                        }

                        this.props.complianceDocumentsAdd(file, complianceDocument);

                        return false;
                    };

                    return (
                        <Upload className='add-document' beforeUpload={beforeUpload} showUploadList={false}>
                            <Button type='link'><UploadOutlined/></Button>
                        </Upload>
                    );
                },
                title: 'Actions',
                width: '10%',
            },
        ];

        const documentTypes: ComplianceDocumentTypeEnum[] = [];
        let filterField: string;
        let filterValue: string;

        switch (true) {
            case (!!account):
                filterField = 'investorAccountUuid';
                filterValue = account.uuid;

                if (account.accountType === AccountTypeEnum.IncomeTrust) {
                    documentTypes.push(ComplianceDocumentTypeEnum.AccountantCertificate);
                    documentTypes.push(ComplianceDocumentTypeEnum.InformationMemorandum);
                }

                if (account.accountType === AccountTypeEnum.Marketplace) {
                    documentTypes.push(ComplianceDocumentTypeEnum.TargetMarketDetermination);
                    documentTypes.push(ComplianceDocumentTypeEnum.ProductDisclosureStatement);
                }

                break;
            case (!!individual):
                filterField = 'investorIndividualUuid';
                filterValue = individual.uuid;

                documentTypes.push(ComplianceDocumentTypeEnum.IdProof);
                documentTypes.push(ComplianceDocumentTypeEnum.IdentityVerification);

                break;
            case (!!investor):
                filterField = 'investorUuid';
                filterValue = investor.uuid;

                if ([InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany].includes(investor.type)) {
                    documentTypes.push(ComplianceDocumentTypeEnum.CompanyFile);
                }

                break;
        }

        const rows: IComplianceRow[] = documentTypes.map((documentType: ComplianceDocumentTypeEnum) => ({
            documentType,
            documents: _.filter(complianceDocuments, { documentType, [filterField]: filterValue }),
            uid: `${filterValue}-${documentType}`,
        }));

        return (
            <Table
                columns={columns}
                dataSource={rows}
                pagination={false}
                rowKey='uid'
            />
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        complianceDocuments: investorComplianceDocumentsSelector(state, ownProps.investorUuid),
        token: authTokenSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        complianceDocumentDelete: (complianceDocumentUuid: string) => dispatch(investorComplianceDocumentDeleteAction(complianceDocumentUuid)),
        complianceDocumentsAdd: (file: File, complianceDocument: IComplianceDocument) => dispatch(investorComplianceDocumentsAddAction(ownProps.investorUuid, file, complianceDocument)),
        complianceDocumentsList: () => dispatch(investorComplianceDocumentsListAction(ownProps.investorUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ComplianceDocumentsList);
