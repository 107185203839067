import { Action } from 'redux';
import IInvestorUser from '~Api/Investor/IInvestorUser';
import IHistory from '~Api/User/IHistory';
import IUser from '~Api/User/IUser';
import UsersActionsEnum from './ActionsEnum';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUsersListAction extends Action {}

export function usersListAction(): IUsersListAction {
    return {
        type: UsersActionsEnum.UsersList,
    };
}

export interface IUsersSetAction extends Action {
    users: IUser[];
}

export function usersSetAction(users: IUser[]): IUsersSetAction {
    return {
        type: UsersActionsEnum.UsersSet,
        users,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUsersSuspiciousListAction extends Action {}

export function usersSuspiciousListAction(): IUsersSuspiciousListAction {
    return {
        type: UsersActionsEnum.UsersSuspiciousList,
    };
}

export interface IUsersSuspiciousSetAction extends Action {
    users: IUser[];
}

export function usersSuspiciousSetAction(users: IUser[]): IUsersSuspiciousSetAction {
    return {
        type: UsersActionsEnum.UsersSuspiciousSet,
        users,
    };
}

export interface IUserBlockAction extends Action {
    userUuid: string;
}

export function userBlockAction(userUuid: string): IUserBlockAction {
    return {
        type: UsersActionsEnum.UserBlock,
        userUuid,
    };
}

export interface IUserEmailAction extends Action {
    email: string;
    uuid: string;
}

export function userEmailAction(uuid: string, email: string): IUserEmailAction {
    return {
        email,
        type: UsersActionsEnum.UserEmail,
        uuid,
    };
}

export interface IUserGetAction extends Action {
    uuid: string;
}

export function userGetAction(uuid: string): IUserGetAction {
    return {
        type: UsersActionsEnum.UserGet,
        uuid,
    };
}

export interface IUserHistoriesListAction extends Action {
    uuid: string;
}

export function userHistoriesListAction(uuid: string): IUserHistoriesListAction {
    return {
        type: UsersActionsEnum.UserHistoriesList,
        uuid,
    };
}

export interface IUserHistoriesSetAction extends Action {
    histories: IHistory[];
    uuid: string;
}

export function userHistoriesSetAction(uuid: string, histories: IHistory[]): IUserHistoriesSetAction {
    return {
        histories,
        type: UsersActionsEnum.UserHistoriesSet,
        uuid,
    };
}

export interface IUserInvestorsListAction extends Action {
    uuid: string;
}

export function userInvestorsListAction(uuid: string): IUserInvestorsListAction {
    return {
        type: UsersActionsEnum.UserInvestorsList,
        uuid,
    };
}

export interface IUserInvestorsSetAction extends Action {
    uuid: string;
    investorUsers: IInvestorUser[];
}

export function userInvestorsSetAction(uuid: string, investorUsers: IInvestorUser[]): IUserInvestorsSetAction {
    return {
        investorUsers,
        type: UsersActionsEnum.UserInvestorsSet,
        uuid,
    };
}

export interface IUserPhoneAction extends Action {
    uuid: string;
    phone: string;
}

export function userPhoneAction(uuid: string, phone: string): IUserPhoneAction {
    return {
        phone,
        type: UsersActionsEnum.UserPhone,
        uuid,
    };
}

export interface IUserSendAction extends Action {
    uuid: string;
}

export function userSendAction(uuid: string): IUserSendAction {
    return {
        type: UsersActionsEnum.UserSend,
        uuid,
    };
}

export interface IUserSetAction extends Action {
    user: IUser;
}

export function userSetAction(user: IUser): IUserSetAction {
    return {
        type: UsersActionsEnum.UserSet,
        user,
    };
}

export interface IUserUnblockAction extends Action {
    userUuid: string;
}

export function userUnblockAction(userUuid: string): IUserUnblockAction {
    return {
        type: UsersActionsEnum.UserUnblock,
        userUuid,
    };
}

export interface IUserValueSetAction extends Action {
    key: keyof IUser;
    uuid: string;
    value: any;
}

export function userValueSetAction(uuid: string, key: keyof IUser, value: any): IUserValueSetAction {
    return {
        key,
        type: UsersActionsEnum.UserValueSet,
        uuid,
        value,
    };
}
