enum CloseReasonEnum {
    Cancelled = 'CANCELLED',
    Cold = 'COLD',
    Credit = 'CREDIT',
    LostToOther = 'LOST_TO_OTHER',
    Settled = 'SETTLED',
    Valuation = 'VALUATION',
}

export default CloseReasonEnum;
