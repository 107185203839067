import _ from 'lodash';
import { AnyAction } from 'redux';
import IInvestmentSaleTransfer from '~Api/InvestmentSaleTransfer/IInvestmentSaleTransfer';
import { IDictionary } from '~utilities/IDictionary';
import InvestmentSaleTransfersActionsEnum from '~InvestmentSaleTransfers/ActionsEnum';
import {
    IInvestmentSaleTransferHistoriesSetAction,
    IInvestmentSaleTransferInvestorAccountInvestmentTransactionsSetAction,
    IInvestmentSaleTransferSetAction,
    IInvestmentSaleTransfersSetAction,
    IInvestmentSaleTransfersUnallocatedInvestmentsSetAction,
} from './actions';
import IHistory from '~Api/InvestmentSaleTransfer/IHistory';
import IUnallocatedInvestment from '~Api/InvestmentSaleTransfer/IUnallocatedInvestment';

export interface IInvestmentSaleTransfersState {
    histories: IDictionary<IDictionary<IHistory>>;
    investmentSaleTransferInvestorAccountInvestmentTransactionUuids: IDictionary<string[]>;
    investmentSaleTransfers: IDictionary<IInvestmentSaleTransfer>;
    unallocatedInvestments: IDictionary<IUnallocatedInvestment>;
}

const initialData: IInvestmentSaleTransfersState = {
    histories: {},
    investmentSaleTransferInvestorAccountInvestmentTransactionUuids: {},
    investmentSaleTransfers: null,
    unallocatedInvestments: null,
};

function dehydrateInvestmentSaleTransfer(investmentSaleTransfer: IInvestmentSaleTransfer): IInvestmentSaleTransfer {
    return _.omit(investmentSaleTransfer, ['investment']);
}

function dehydratUnallocatedInvestment(unallocatedInvestment: IUnallocatedInvestment): IUnallocatedInvestment {
    return _.omit(unallocatedInvestment, ['investment']);
}

export function investmentSaleTransfersReducer(state: IInvestmentSaleTransfersState = initialData, action: AnyAction): IInvestmentSaleTransfersState {
    switch (action.type) {
        case InvestmentSaleTransfersActionsEnum.InvestmentSaleTransferSet: {
            const typedAction: IInvestmentSaleTransferSetAction = action as IInvestmentSaleTransferSetAction;

            return {
                ...state,
                investmentSaleTransfers: {
                    ...state.investmentSaleTransfers,
                    [typedAction.investmentSaleTransfer.uuid]: dehydrateInvestmentSaleTransfer(typedAction.investmentSaleTransfer),
                },
            };
        }

        case InvestmentSaleTransfersActionsEnum.InvestmentSaleTransfersSet: {
            const typedAction: IInvestmentSaleTransfersSetAction = action as IInvestmentSaleTransfersSetAction;

            const investmentSaleTransfers: IDictionary<IInvestmentSaleTransfer> = {};

            _.each(typedAction.investmentSaleTransfers, (investmentSaleTransfer: IInvestmentSaleTransfer) => {
                investmentSaleTransfers[investmentSaleTransfer.uuid] = dehydrateInvestmentSaleTransfer(investmentSaleTransfer);
            });

            return {
                ...state,
                investmentSaleTransfers,
            };
        }

        case InvestmentSaleTransfersActionsEnum.InvestmentSaleTransferHistoriesSet: {
            const typedAction: IInvestmentSaleTransferHistoriesSetAction = action as IInvestmentSaleTransferHistoriesSetAction;

            return {
                ...state,
                histories: {
                    ...state.histories,
                    [typedAction.investmentSaleTransferUuid]: _.keyBy(typedAction.histories, 'uuid'),
                },
            };
        }

        case InvestmentSaleTransfersActionsEnum.InvestmentSaleTransferInvestorAccountInvestmentTransactionsSet: {
            const typedAction: IInvestmentSaleTransferInvestorAccountInvestmentTransactionsSetAction = action as IInvestmentSaleTransferInvestorAccountInvestmentTransactionsSetAction;

            return {
                ...state,
                investmentSaleTransferInvestorAccountInvestmentTransactionUuids: {
                    ...state.investmentSaleTransferInvestorAccountInvestmentTransactionUuids,
                   [typedAction.investmentSaleTransferUuid] : _.map(typedAction.investorAccountInvestmentTransactions, 'uuid'),
                },
            };
        }

        case InvestmentSaleTransfersActionsEnum.InvestmentSaleTransfersUnallocatedInvestmentsSet: {
            const typedAction: IInvestmentSaleTransfersUnallocatedInvestmentsSetAction = action as IInvestmentSaleTransfersUnallocatedInvestmentsSetAction;

            const unallocatedInvestments: IDictionary<IUnallocatedInvestment> = {};

            _.each(typedAction.unallocatedInvestments, (unallocatedInvestment: IUnallocatedInvestment) => {
                unallocatedInvestments[unallocatedInvestment.investmentUuid] = dehydratUnallocatedInvestment(unallocatedInvestment);
            });

            return {
                ...state,
                unallocatedInvestments,
            };
        }

        default: {
            return state;
        }
    }
}
