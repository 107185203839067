import { SearchOutlined } from '@ant-design/icons';
import { Popover, Space, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IAdviser from '~Api/Adviser/IAdviser';
import IInvestor from '~Api/Investor/IInvestor';
import {
    adviserGetAction,
    adviserInvestorsListAction,
} from '~Advisers/actions';
import {
    adviserInvestorsSelector,
    adviserSelector,
} from '~Advisers/selectors';
import { IGlobalState } from '~reducer';
import SearchDropdown from '~UI/SearchDropdown';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import { ColumnType } from 'antd/lib/table';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import AccountTypeTag from '~Investors/Investor/AccountTypeTag';
import { currencyFormatter } from '~utilities/formatters';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import accountTypeLabels from '~Api/Investor/accountTypeLabels';

interface IMatch {
    adviserUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    adviser: IAdviser;
    investors: IDictionary<IInvestor>;
}

interface IPropsDispatch {
    adviserGet: () => void;
    adviserInvestorsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Investors extends React.Component<Props> {
    public componentDidMount(): void {
        const { adviser } = this.props;

        if (!adviser) {
            this.props.adviserGet();
        }

        this.props.adviserInvestorsList();
    }

    public render(): JSX.Element {
        const { adviser, investors, match } = this.props;

        if (!adviser || !investors) {
            return (
                <Layout adviserUuid={match.params.adviserUuid} section='investors'>
                    <Typography.Title level={2}>Investors</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const columns: ColumnType<IInvestor>[] = [
            {
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string | number | boolean, investor: IInvestor) => investor.name.toLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                render: (investor: IInvestor) => {
                    return (
                        <React.Fragment>
                            <Link to={`/investors/${investor.uuid}`}>{investor.name || '-'}</Link>
                        </React.Fragment>
                    );
                },
                sorter: (a: IInvestor, b: IInvestor) => a.name.localeCompare(b.name),
                title: 'Name',
            },
            {
                filters: [
                    {
                        text: 'Marketplace',
                        value: AccountTypeEnum.Marketplace,
                    },
                    {
                        text: 'Income Trust',
                        value: AccountTypeEnum.IncomeTrust,
                    },
                ],
                onFilter: (value: string | number | boolean, investor: IInvestor): boolean => Object.keys(investor.accounts).includes(value as AccountTypeEnum),
                render: (investor: IInvestor) => {
                    return (
                        <React.Fragment>
                            {_.map(investor.accounts, (account: IAccount) => <AccountTypeTag key={account.uuid} account={account} />)}
                        </React.Fragment>
                    );
                },
                title: 'Account Type',
                width: '20%',
            },
            {
                render: (investor: IInvestor) => {
                    const popoverContent: JSX.Element[] = _.map(investor.accounts, (account: IAccount) => <p>{accountTypeLabels[account.accountType]}: {currencyFormatter.format(account.balance)}</p>);
                    return investor.balanceTotal !== 0 ? <Space>{currencyFormatter.format(investor.balanceTotal)} <Popover content={popoverContent}><Tag>∑</Tag></Popover></Space> : '';
                },
                sorter: (a: IInvestor, b: IInvestor) => a.balanceTotal > b.balanceTotal ? 1 : -1,
                title: 'Balance',
                width: '20%',
            },
            {
                render: (investor: IInvestor) => {
                    const popoverContent: JSX.Element[] = _.map(investor.accounts, (account: IAccount) => <p>{accountTypeLabels[account.accountType]}: {currencyFormatter.format(account.investedAmountCurrent)}</p>);
                    return investor.investedAmountCurrentTotal !== 0 ? <Space>{currencyFormatter.format(investor.investedAmountCurrentTotal)} <Popover content={popoverContent}><Tag>∑</Tag></Popover></Space> : '';
                },
                sorter: (a: IInvestor, b: IInvestor) => a.investedAmountCurrentTotal > b.investedAmountCurrentTotal ? 1 : -1,
                title: 'Invested',
                width: '20%',
            },
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => createdTime ? <Tooltip title={dayjs(createdTime).format('Do MMMM YYYY HH:mm:ss')}><span>{dayjs(createdTime).fromNow()}</span></Tooltip> : '-',
                sorter: (a: IInvestor, b: IInvestor) => {
                    if (!b.createdTime) {
                        return 1;
                    }

                    if (!a.createdTime) {
                        return -1;
                    }

                    return dayjs(a.createdTime) > dayjs(b.createdTime) ? 1 : -1;
                },
                title: 'Created',
                width: '10%',
            },
        ];

        return (
            <Layout adviserUuid={match.params.adviserUuid} section='investors'>
                <Typography.Title level={2}>Investors</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(investors)}
                    rowKey='uuid'
                    size='middle'
                />
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        adviser: adviserSelector(state, ownProps.match.params.adviserUuid),
        investors: adviserInvestorsSelector(state, ownProps.match.params.adviserUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        adviserGet: () => dispatch(adviserGetAction(ownProps.match.params.adviserUuid)),
        adviserInvestorsList: () => dispatch(adviserInvestorsListAction(ownProps.match.params.adviserUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Investors);
