import { Form, Input, Modal } from 'antd';
import CKEditor from 'ckeditor4-react';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import { investmentInvestorEmailSendAction } from '~Investments/actions';

interface IState {
    message: string;
    subject: string;
}

interface IProps {
    isOpen: boolean;
    investment: IInvestment;
    onCancel: () => void;
}

interface IPropsDispatch {
    send: (subject: string, message: string) => void;
}

type Props = IProps & IPropsDispatch;

class SendModal extends React.Component<Props, IState> {
    public state: IState = {
        message: null,
        subject: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeMessage = this.onChangeMessage.bind(this);
        this.onChangeSubject = this.onChangeSubject.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { message, subject } = this.state;

        const editorConfig: any = {
            disableNativeSpellChecker: false,
            toolbar: [
                ['Bold', 'Italic', '-', 'NumberedList', 'BulletedList', '-', 'Link', 'Unlink'],
                ['FontSize', 'TextColor', 'BGColor'],
            ],
        };

        return (
            <Modal
                okText='Send'
                okType='danger'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Send Email'
                wrapClassName='investment-send-modal'
            >
                <Form.Item className='subject'>
                    <Input onChange={this.onChangeSubject} placeholder='Subject' value={subject} />
                </Form.Item>
                <Form.Item className='message'>
                    Dear [Investor Name],<br/>
                    <br/>
                    <CKEditor
                        config={editorConfig}
                        data={message}
                        onChange={this.onChangeMessage}
                    />
                    <br/>
                    Best Regards,<br/>
                    <br/>
                    The Funding Team
                </Form.Item>
            </Modal>
        );
    }

    private onChangeMessage(event: any) {
        this.setState({
            message: event.editor.getData(),
        });
    }

    private onChangeSubject(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            subject: event.target.value,
        });
    }

    private onClickOk() {
        const { message, subject } = this.state;

        Modal.confirm({
            content: 'Are you sure you want to send this email?',
            okText: 'Send',
            okType: 'danger',
            onOk: () => {
                this.props.onCancel();
                this.props.send(subject, message);
            },
            title: 'Send Investor Email',
        });
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        send: (subject: string, message: string) => dispatch(investmentInvestorEmailSendAction(ownProps.investment.uuid, subject, message)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(SendModal);
