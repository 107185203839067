import { Form, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { dealQuoteRejectAction } from '~Deals/actions';

interface IState {
    reason: string;
}

interface IProps {
    isOpen: boolean;
    onCancel: () => void;
    quoteUuid: string;
}

interface IPropsDispatch {
    reject: (reason: string) => void;
}

type Props = IProps & IPropsDispatch;

class QuoteRejectModal extends React.Component<Props, IState> {
    public state: IState = {
        reason: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeReason = this.onChangeReason.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { reason } = this.state;

        return (
            <Modal
                okText='Reject'
                okType='danger'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Reject Quote'
                wrapClassName='lead-quote-reject-modal'
            >
                <Form.Item label='Reason' className='reason'>
                    <TextArea onChange={this.onChangeReason} value={reason} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeReason(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            reason: event.target.value,
        });
    }

    private onClickOk() {
        const { reason } = this.state;

        this.props.onCancel();

        this.props.reject(reason);
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        reject: (reason: string) => dispatch(dealQuoteRejectAction(ownProps.quoteUuid, reason)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(QuoteRejectModal);
