import { all, call, put, takeEvery } from '@redux-saga/core/effects';
import { IFetchResponse } from '~utilities/fetch';
import { IValuersListAction, valuersSetAction } from './actions';
import ValuersActionsEnum from './ActionsEnum';
import IValuer from './IValuer';
import { parseValuer } from './parsers';
import { valuersListRequest } from './requests';

function* valuersList(action: IValuersListAction): Iterator<any> {
    const valuersListResponse: IFetchResponse = yield call(valuersListRequest);
    const valuers: IValuer[] = valuersListResponse.body.map(parseValuer);
    yield put(valuersSetAction(valuers));
}

export function* ValuersSagas(): any {
    yield all([
        takeEvery(ValuersActionsEnum.ValuersList, valuersList),
    ]);
}
