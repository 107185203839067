import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Breadcrumb, Button, Layout, Space, Spin, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IBroker from '~Api/Broker/IBroker';
import { IGlobalState } from '~reducer';
import { brokersListAction } from './actions';
import AddModal from './AddModal';
import BrokerMarker from './Broker/BrokerMarker';
import Search from './Search';
import './brokers.less';
import './map.less';
import { brokersSelector } from './selectors';
import { IDictionary } from '~utilities/IDictionary';

interface IState {
    isAddModalOpen: boolean;
    map: google.maps.Map;
    scriptLoaded: boolean;
}

interface IPropsSelector {
    brokers: IDictionary<IBroker>;
}

interface IPropsDispatch {
    brokersList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Map extends React.Component<Props> {
    public state: IState = {
        isAddModalOpen: false,
        map: null,
        scriptLoaded: !!window.google,
    };

    public scriptLoadCheckInterval: number;

    constructor(props: Props) {
        super(props);

        this.checkIfScriptLoaded = this.checkIfScriptLoaded.bind(this);
        this.setScriptLoadedCallback = this.setScriptLoadedCallback.bind(this);

        this.onClickAdd = this.onClickAdd.bind(this);
        this.onCloseAddModal = this.onCloseAddModal.bind(this);

        this.onLoad = this.onLoad.bind(this);
        this.onUnmount = this.onUnmount.bind(this);

        this.scriptLoadCheckInterval = window.setInterval(this.checkIfScriptLoaded, 200);
    }

    public componentDidMount() {
        const { brokers } = this.props;

        if (!brokers) {
            this.props.brokersList();
        }
    }

    public componentWillUnmount() {
        window.clearInterval(this.scriptLoadCheckInterval);
    }

    public render(): JSX.Element {
        const { brokers } = this.props;
        const { isAddModalOpen, scriptLoaded } = this.state;

        if (!brokers) {
            return (
                <Layout className='brokers'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Brokers</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Brokers</Typography.Title>
                            <div className='map-container'>
                                <Spin/>
                            </div>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const markers: JSX.Element[] = [];
        _.each(brokers, (broker: IBroker) => {
            if (!broker.latitude || !broker.longitude) {
                return;
            }

            markers.push(<BrokerMarker key={`broker-marker-${broker.uuid}`} broker={broker}/>);
        });

        return (
            <Layout className='brokers'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Brokers</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Search/>
                            <Link to='/brokers'><Button>Board View</Button></Link>
                            <Link to='/brokers/list'><Button>List View</Button></Link>
                            <Button onClick={this.onClickAdd}>Add Broker</Button>
                        </Space>
                        <Typography.Title level={2}>Brokers</Typography.Title>
                        <div className='map-container'>
                            <LoadScript googleMapsApiKey={process.env.GOOGLE_MAPS_API_KEY}>
                                {!scriptLoaded && <Spin/>}
                                {scriptLoaded && <GoogleMap
                                    mapContainerClassName='brokers-map'
                                    onLoad={this.onLoad}
                                    onUnmount={this.onUnmount}
                                >
                                    {markers}
                                </GoogleMap>}
                            </LoadScript>
                        </div>
                    </Layout.Content>
                </Layout>

                <AddModal
                    isOpen={isAddModalOpen}
                    onCancel={this.onCloseAddModal}
                />
            </Layout>
        );
    }

    private checkIfScriptLoaded() {
        if (window.google) {
            this.setState({
                scriptLoaded: true,
            }, this.setScriptLoadedCallback);
        }
    }

    private setScriptLoadedCallback() {
        if (this.state.scriptLoaded) {
            window.clearInterval(this.scriptLoadCheckInterval);
        }
    }

    private onClickAdd() {
        this.setState({
            isAddModalOpen: true,
        });
    }

    private onCloseAddModal() {
        this.setState({
            isAddModalOpen: false,
        });
    }

    private onLoad(map: google.maps.Map) {
        const bounds: google.maps.LatLngBounds = new window.google.maps.LatLngBounds({
            lat: -28.004898421665832,
            lng: 153.42817918449626,
        });

        map.fitBounds(bounds);

        google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
            map.setZoom(5);
        });

        this.setState({
            map,
        });
    }

    private onUnmount() {
        this.setState({
            map: null,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        brokers: brokersSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        brokersList: () => dispatch(brokersListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Map);
