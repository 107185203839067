import { AutoComplete, Input } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import history from '~history';
import { IGlobalState } from '~reducer';
import IInvestment from '~Api/Investment/IInvestment';
import {
    investmentsSearchAction,
    investmentsSearchResultsClearAction,
} from './actions';
import { investmentsSearchResultsSelector } from './selectors';

interface IOption {
    label: JSX.Element;
    value: string;
}

interface IState {
    searchText: string;
}

interface IPropsSelector {
    investmentSearchResults: IInvestment[];
}

interface IPropsDispatch {
    investmentsSearchResultsClear: () => void;
    investmentsSearch: (keyword: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Search extends React.Component<Props, IState> {
    public state: IState = {
        searchText: '',
    };

    constructor(props: Props) {
        super(props);

        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    public render(): JSX.Element {
        const { investmentSearchResults } = this.props;
        const { searchText } = this.state;

        const searchResults: IInvestment[] = investmentSearchResults ?? [];

        const options: IOption[] = searchResults.map((investment: IInvestment) => {
            const label: JSX.Element = (
                <div className='result'>
                    <div className='code'>{investment.code}</div>
                    {investment.loanCode && investment.loanCode !== investment.code && <div className='salesforce-code'>{investment.loanCode}</div>}
                    <div className='name'>{investment.name}</div>
                </div>
            );

            return {
                label,
                value: `${investment.uuid}`,
            };
        });

        return (
            <AutoComplete
                dropdownMatchSelectWidth={300}
                onSearch={this.onSearch}
                onSelect={this.onSelect}
                options={_.slice(options, 0, 20)}
                popupClassName='investments-search-dropdown'
                value={searchText}
            >
                <Input.Search/>
            </AutoComplete>
        );
    }

    private onSearch(value: string): void {
        this.setState({
            searchText: value,
        });

        this.props.investmentsSearch(value);
    }

    private onSelect(value: string): void {
        this.props.investmentsSearchResultsClear();

        history.push(`/investments/${value}`);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        investmentSearchResults: investmentsSearchResultsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        investmentsSearch: (keyword: string) => dispatch(investmentsSearchAction(keyword)),
        investmentsSearchResultsClear: () => dispatch(investmentsSearchResultsClearAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Search);
