import { Form, Modal, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IInvestor from '~Api/Investor/IInvestor';
import ILoan from '~Api/Loan/ILoan';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import WarehouseTypeEnum from '~Api/Warehouse/WarehouseTypeEnum';
import { investorsPrivateListAction } from '~Investors/actions';
import { privateInvestorsSelector } from '~Investors/selectors';
import { loanFundAction } from '~Loans/actions';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { warehousesListAction } from '~Warehouses/actions';
import { warehousesSelector } from '~Warehouses/selectors';

interface IState {
    investorAccountUuid: string;
    warehouseUuid: string;
}

interface IProps {
    loan: ILoan;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsSelector {
    privateInvestors: IDictionary<IInvestor>;
    warehouses: IDictionary<IWarehouse>;
}

interface IPropsDispatch {
    fund: (warehouseUuid: string, investorAccountUuid: string) => void;
    privateInvestorsList: () => void;
    warehousesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class FundModal extends React.Component<Props, IState> {
    public state: IState = {
        investorAccountUuid: null,
        warehouseUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeInvestorAccountUuid = this.onChangeInvestorAccountUuid.bind(this);
        this.onChangeWarehouseUuid = this.onChangeWarehouseUuid.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public componentDidMount(): void {
        const { privateInvestors, warehouses } = this.props;

        if (!privateInvestors) {
            this.props.privateInvestorsList();
        }

        if (!warehouses) {
            this.props.warehousesList();
        }
    }

    public render(): JSX.Element {
        const { isOpen, privateInvestors, warehouses } = this.props;
        const { investorAccountUuid, warehouseUuid } = this.state;

        if (!privateInvestors || !warehouses) {
            return null;
        }

        const chosenWarehouse: IWarehouse = !!warehouseUuid && warehouses[warehouseUuid];

        const warehouseOptions: JSX.Element[] = [];

        _.sortBy(warehouses, 'name').forEach((warehouse: IWarehouse) => {
            // FIT, FWT1 and FWT2 can't fund loans
            if ([WarehouseTypeEnum.Fit, WarehouseTypeEnum.Fwt1, WarehouseTypeEnum.Fwt2].includes(warehouse.type)) {
                return;
            }

            warehouseOptions.push(<Select.Option key={warehouse.uuid} value={warehouse.uuid}>{warehouse.name}</Select.Option>);
        });

        const investorBlock: JSX.Element = !!chosenWarehouse && chosenWarehouse.type === WarehouseTypeEnum.Private && (
            <Form.Item label='Investor' className='investor'>
                <Select onChange={this.onChangeInvestorAccountUuid} value={investorAccountUuid}>
                    {_.map(privateInvestors, (investor: IInvestor) => <Select.Option key={investor.accounts[AccountTypeEnum.Private].uuid} value={investor.accounts[AccountTypeEnum.Private].uuid}>{investor.name}</Select.Option>)}
                </Select>
            </Form.Item>
        );

        return (
            <Modal
                destroyOnClose={true}
                okText='Fund'
                okType='danger'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Fund Loan'
                wrapClassName='loan-fund-modal'
            >
                <Form.Item label='Warehouse' className='warehouse'>
                    <Select onChange={this.onChangeWarehouseUuid} value={warehouseUuid}>
                        {warehouseOptions}
                    </Select>
                </Form.Item>
                {investorBlock}
            </Modal>
        );
    }

    private onChangeWarehouseUuid(value: string): void {
        this.setState({
            warehouseUuid: value,
        });
    }

    private onChangeInvestorAccountUuid(value: string): void {
        this.setState({
            investorAccountUuid: value,
        });
    }

    private onClickOk(): void {
        const { investorAccountUuid, warehouseUuid } = this.state;

        this.props.fund(warehouseUuid, investorAccountUuid);

        this.props.onCancel();
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        privateInvestors: privateInvestorsSelector(state),
        warehouses: warehousesSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        fund: (warehouseUuid: string, investorAccountUuid: string) => dispatch(loanFundAction(ownProps.loan.uuid, warehouseUuid, investorAccountUuid)),
        privateInvestorsList: () => dispatch(investorsPrivateListAction()),
        warehousesList: () => dispatch(warehousesListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FundModal);
