enum InvestorsActionsEnum {
    InvestorsBoard = 'INVESTORS/BOARD',
    InvestorsBoardSet = 'INVESTORS/BOARD/SET',
    InvestorsList = 'INVESTORS/LIST',
    InvestorsManagedList = 'INVESTORS/MANAGED/LIST',
    InvestorsManagedSet = 'INVESTORS/MANAGED/SET',
    InvestorsPrivateList = 'INVESTORS/PRIVATE/LIST',
    InvestorsPrivateSet = 'INVESTORS/PRIVATE/SET',
    InvestorsSet = 'INVESTORS/SET',

    InvestorsSearch = 'INVESTORS/SEARCH',
    InvestorsSearchResultsSet = 'INVESTORS/SEARCH/RESULTS/SET',
    InvestorsSearchResultsClear = 'INVESTORS/SEARCH/RESULTS/CLEAR',

    InvestorAccountApprove = 'INVESTOR/ACCOUNT/APPROVE',
    InvestorAccountDeposit = 'INVESTOR/ACCOUNT/DEPOSIT',
    InvestorAccountIncomeTrustDivest = 'INVESTOR/ACCOUNT/INCOME_TRUST/DIVEST',
    InvestorAccountIncomeTrustInvest = 'INVESTOR/ACCOUNT/INCOME_TRUST/INVEST',
    InvestorAccountInvest = 'INVESTOR/ACCOUNT/INVEST',
    InvestorAccountInvestmentsList = 'INVESTOR/ACCOUNT/INVESTMENTS/LIST',
    InvestorAccountInvestmentsSet = 'INVESTOR/ACCOUNT/INVESTMENTS/SET',
    InvestorAccountPendingDepositApprove = 'INVESTOR/ACCOUNT/PENDING_DEPOSIT/APPROVE',
    InvestorAccountPendingDepositReject = 'INVESTOR/ACCOUNT/PENDING_DEPOSIT/REJECT',
    InvestorAccountReject = 'INVESTOR/ACCOUNT/REJECT',
    InvestorAccountReserve = 'INVESTOR/ACCOUNT/RESERVE',
    InvestorAccountSet = 'INVESTOR/ACCOUNT/SET',
    InvestorAccountSharesList = 'INVESTOR/ACCOUNT/SHARES/LIST',
    InvestorAccountSharesSet = 'INVESTOR/ACCOUNT/SHARES/SET',
    InvestorAccountTransactionsList = 'INVESTOR/ACCOUNT/TRANSACTIONS/LIST',
    InvestorAccountTransactionsSet = 'INVESTOR/ACCOUNT/TRANSACTIONS/SET',
    InvestorAccountUnapprove = 'INVESTOR/ACCOUNT/UNAPPROVE',
    InvestorAccountWithdraw = 'INVESTOR/ACCOUNT/WITHDRAW',

    InvestorAccountAnnualStatementsAdd = 'INVESTOR/ACCOUNT/ANNUAL_STATEMENTS/ADD',
    InvestorAccountAnnualStatementsGenerate = 'INVESTOR/ACCOUNT/ANNUAL_STATEMENTS/GENERATE',
    InvestorAccountAnnualStatementsList = 'INVESTOR/ACCOUNT/ANNUAL_STATEMENTS/LIST',
    InvestorAccountAnnualStatementsSet = 'INVESTOR/ACCOUNT/ANNUAL_STATEMENTS/SET',

    InvestorAccountAnnualStatementDelete = 'INVESTOR/ACCOUNT/ANNUAL_STATEMENT/DELETE',
    InvestorAccountAnnualStatementSet = 'INVESTOR/ACCOUNT/ANNUAL_STATEMENT/SET',

    InvestorAccountIncomeTrustSet = 'INVESTOR/ACCOUNT/INCOME_TRUST/SET',
    InvestorAccountIncomeTrustsList = 'INVESTOR/ACCOUNT/INCOME_TRUSTS/LIST',
    InvestorAccountIncomeTrustsSet = 'INVESTOR/ACCOUNT/INCOME_TRUSTS/SET',
    InvestorAccountIncomeTrustInterestPaymentStrategySet = 'INVESTOR/ACCOUNT/INCOME_TRUST/INTEREST_PAYMENT_STRATEGY/SET',
    InvestorAccountIncomeTrustTransactionsList = 'INVESTOR/ACCOUNT/INCOME_TRUST/TRANSACTIONS/LIST',
    InvestorAccountIncomeTrustTransactionsSet = 'INVESTOR/ACCOUNT/INCOME_TRUST/TRANSACTIONS/SET',

    InvestorAccountInvestmentGet = 'INVESTOR/ACCOUNT/INVESTMENT/GET',
    InvestorAccountInvestmentSet = 'INVESTOR/ACCOUNT/INVESTMENT/SET',
    InvestorAccountInvestmentTransactionsList = 'INVESTOR/ACCOUNT/INVESTMENT/TRANSACTIONS/LIST',
    InvestorAccountInvestmentTransactionsSet = 'INVESTOR/ACCOUNT/INVESTMENT/TRANSACTIONS/SET',

    InvestorAccountWithdrawalAbaInvestorTransactionsList = 'INVESTOR/ACCOUNT/WITHDRAWAL_ABA_INVESTOR_TRANSACTIONS/LIST',
    InvestorAccountWithdrawalAbaInvestorTransactionsSet = 'INVESTOR/ACCOUNT/WITHDRAWAL_ABA_INVESTOR_TRANSACTIONS/SET',

    InvestorAccountMonthlyStatementsGenerate = 'INVESTOR/ACCOUNT/MONTHLY_STATEMENTS/GENERATE',
    InvestorAccountMonthlyStatementsList = 'INVESTOR/ACCOUNT/MONTHLY_STATEMENTS/LIST',
    InvestorAccountMonthlyStatementsSet = 'INVESTOR/ACCOUNT/MONTHLY_STATEMENTS/SET',

    InvestorAccountMonthlyStatementDelete = 'INVESTOR/ACCOUNT/MONTHLY_STATEMENT/DELETE',
    InvestorAccountMonthlyStatementSend = 'INVESTOR/ACCOUNT/MONTHLY_STATEMENT/SEND',
    InvestorAccountMonthlyStatementSet = 'INVESTOR/ACCOUNT/MONTHLY_STATEMENT/SET',

    InvestorAccountsIncomeTrustList = 'INVESTOR/ACCOUNTS/INCOME_TRUST/LIST',
    InvestorAccountsIncomeTrustSet = 'INVESTOR/ACCOUNTS/INCOME_TRUST/SET',
    InvestorAccountsPendingApprovalList = 'INVESTOR/ACCOUNTS/PENDING_APPROVAL/LIST',
    InvestorAccountsPendingApprovalSet = 'INVESTOR/ACCOUNTS/PENDING_APPROVAL/SET',

    InvestorAssignAdviser = 'INVESTOR/ASSIGN_ADVISER',
    InvestorUnassignAdviser = 'INVESTOR/UNASSIGN_ADVISER',

    InvestorAssignIm = 'INVESTOR/ASSIGN_IM',
    InvestorUnassignIm = 'INVESTOR/UNASSIGN_IM',

    InvestorBankAccountConfirm = 'INVESTOR/BANK_ACCOUNT/CONFIRM',
    InvestorBankAccountsAdd = 'INVESTOR/BANK_ACCOUNTS/ADD',
    InvestorBankAccountSet = 'INVESTOR/BANK_ACCOUNT/SET',
    InvestorBankAccountUnconfirm = 'INVESTOR/BANK_ACCOUNT/UNCONFIRM',

    InvestorComplianceDocumentsAdd = 'INVESTOR/COMPLIANCE_DOCUMENTS/ADD',
    InvestorComplianceDocumentsList = 'INVESTOR/COMPLIANCE_DOCUMENTS/LIST',
    InvestorComplianceDocumentsSet = 'INVESTOR/COMPLIANCE_DOCUMENTS/SET',

    InvestorComplianceDocumentDelete = 'INVESTOR/COMPLIANCE_DOCUMENT/DELETE',
    InvestorComplianceDocumentSet = 'INVESTOR/COMPLIANCE_DOCUMENT/SET',

    InvestorDocumentsAdd = 'INVESTOR/DOCUMENTS/ADD',
    InvestorDocumentsList = 'INVESTOR/DOCUMENTS/LIST',
    InvestorDocumentsSet = 'INVESTOR/DOCUMENTS/SET',

    InvestorDocumentSet = 'INVESTOR/DOCUMENT/SET',

    InvestorContactDate = 'INVESTOR/CONTACT_DATE',
    InvestorCreateIncomeTrustAccount = 'INVESTOR/CREATE_INCOME_TRUST_ACCOUNT',
    InvestorGet = 'INVESTOR/GET',
    InvestorPrimaryBankAccount = 'INVESTOR/PRIMARY_BANK_ACCOUNT',
    InvestorSend = 'INVESTOR/SEND',
    InvestorSet = 'INVESTOR/SET',
    InvestorValueSet = 'INVESTOR/VALUE/SET',

    InvestorCompanyValueSet = 'INVESTOR/COMPANY/VALUE/SET',

    InvestorHistoriesList = 'INVESTOR/HISTORIES/LIST',
    InvestorHistoriesSet = 'INVESTOR/HISTORIES/SET',

    InvestorIndividualAdd = 'INVESTOR/INDIVIDUAL/ADD',
    InvestorIndividualSet = 'INVESTOR/INDIVIDUAL/SET',
    InvestorIndividualValueSet = 'INVESTOR/INDIVIDUAL/VALUE/SET',

    InvestorNotesAdd = 'INVESTOR/NOTES/ADD',
    InvestorNotesList = 'INVESTOR/NOTES/LIST',
    InvestorNotesSet = 'INVESTOR/NOTES/SET',

    InvestorNoteRemove = 'INVESTOR/NOTE/REMOVE',
    InvestorNoteSet = 'INVESTOR/NOTE/SET',

    InvestorPendingWithdrawalApprove = 'INVESTOR/PENDING_WITHDRAWAL/APPROVE',
    InvestorPendingWithdrawalSet = 'INVESTOR/PENDING_WITHDRAWAL/SET',
    InvestorPendingWithdrawalsUnapprovedList = 'INVESTOR/PENDING_WITHDRAWALS/UNAPPROVED/LIST',
    InvestorPendingWithdrawalsUnapprovedSet = 'INVESTOR/PENDING_WITHDRAWALS/UNAPPROVED/SET',

    InvestorReferralProcess = 'INVESTOR/REFERRAL/PROCESS',
    InvestorReferralUnprocess = 'INVESTOR/REFERRAL/UNPROCESS',
    InvestorReferralSet = 'INVESTOR/REFERRAL/SET',

    InvestorReferralsAdd = 'INVESTOR/REFERRALS/ADD',
    InvestorReferralsInvestorList = 'INVESTOR/REFERRALS/INVESTOR_LIST',
    InvestorReferralsInvestorSet = 'INVESTOR/REFERRALS/INVESTOR_SET',
    InvestorReferralsList = 'INVESTOR/REFERRALS/LIST',
    InvestorReferralsSet = 'INVESTOR/REFERRALS/SET',

    InvestorTrustValueSet = 'INVESTOR/TRUST/VALUE/SET',

    InvestorUsersList = 'INVESTOR/USERS/LIST',
    InvestorUsersSet = 'INVESTOR/USERS/SET',

    PendingDepositsList = 'INVESTOR/PENDING_DEPOSITS/LIST',
    PendingDepositsSet = 'INVESTOR/PENDING_DEPOSITS/SET',
}

export default InvestorsActionsEnum;
