import { FilterFilled, FilterTwoTone } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Form, Layout, Modal, Space, Spin, Tree, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import {
    administratorsSelector,
    currentAdministratorSelector,
} from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import ApprovalStatusEnum from '~Api/Investor/ApprovalStatusEnum';
import IInvestor from '~Api/Investor/IInvestor';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import ITreeStructureGroup from '~utilities/ITreeStructureGroup';
import { investorsBoardAction, } from './actions';
import Search from './Search';
import InvestorCard from './InvestorCard';
import { investorsBoardSelector } from './selectors';

const unassignedKey: string = 'UNASSIGNED';

interface IState {
    accountTypeFilter: string[];
    imFilter: string[];
    isChangeFiltersModalOpen: boolean;
}

interface IPropsSelector {
    administrators: IDictionary<IAdministrator>;
    investors: IDictionary<IInvestor>;
    currentAdministrator: IAdministrator;
}

interface IPropsDispatch {
    administratorsList: () => void;
    investorsBoard: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Dashboard extends React.Component<Props> {
    public state: IState = {
        accountTypeFilter: [],
        imFilter: [],
        isChangeFiltersModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeImFilter = this.onChangeImFilter.bind(this);
        this.onChangeAccountTypeFilter = this.onChangeAccountTypeFilter.bind(this);

        this.onClickChangeFilters = this.onClickChangeFilters.bind(this);
        this.onCancelChangeFilters = this.onCancelChangeFilters.bind(this);
    }

    public componentDidMount() {
        const { administrators, investors, currentAdministrator } = this.props;

        if (!administrators || !currentAdministrator) {
            this.props.administratorsList();
        }

        if (!investors) {
            this.props.investorsBoard();
        }
    }

    public render(): JSX.Element {
        const { administrators, investors, currentAdministrator } = this.props;
        const { accountTypeFilter, imFilter, isChangeFiltersModalOpen } = this.state;

        if (!administrators || !investors) {
            return (
                <Layout className='investors'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Investors</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Spin />
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const columnInvestors: { [date: string]: IInvestor[] } = {};

        for (let i = 0; i < 7; i++) {
            const dateString: string = dayjs().add(i, 'day').format('YYYY-MM-DD');
            columnInvestors[dateString] = [];
        }

        const today: Dayjs = dayjs();
        const visibleIms: IDictionary<IAdministrator> = {};

        _.forEach(investors, (investor: IInvestor) => {
            if (investor.approvalStatus === ApprovalStatusEnum.Rejected) {
                return;
            }

            if (!investor.contactDateNext) {
                return;
            }

            const im: IAdministrator = administrators[investor.imUuid] || null;

            if (im && !visibleIms[investor.imUuid]) {
                visibleIms[investor.imUuid] = im;
            }

            if (RoleEnum.InvestorManager === currentAdministrator.role) {
                if (investor.imUuid !== currentAdministrator.uuid) {
                    return;
                }
            } else {
                if (imFilter.length > 0) {
                    let shouldShow: boolean = false;
                    if (!investor.imUuid && imFilter.includes(unassignedKey)) {
                        shouldShow = true;
                    }
                    if (investor.imUuid && imFilter.includes(im.uuid)) {
                        shouldShow = true;
                    }
                    if (!shouldShow) {
                        return;
                    }
                }
            }

            if (accountTypeFilter.length > 0) {
                let shouldShow: boolean = false;
                if (accountTypeFilter.includes(AccountTypeEnum.Marketplace) && !!investor.accounts[AccountTypeEnum.Marketplace]) {
                    shouldShow = true;
                }
                if (accountTypeFilter.includes(AccountTypeEnum.IncomeTrust) && !!investor.accounts[AccountTypeEnum.IncomeTrust]) {
                    shouldShow = true;
                }
                if (!shouldShow) {
                    return;
                }
            }

            const contactDayjs: Dayjs = dayjs(investor.contactDateNext);
            const dateString: string = (contactDayjs.isBefore(today) ? today : contactDayjs).format('YYYY-MM-DD');
            if (!columnInvestors[dateString]) {
                return;
            }

            columnInvestors[dateString].push(investor);
        });

        const columnsBlock: JSX.Element[] = _.keys(columnInvestors).map((dateString: string) => {
            const cards: JSX.Element[] = _.sortBy(columnInvestors[dateString], ['contactDateNext']).map((investor: IInvestor) => (
                <InvestorCard
                    investor={investor}
                    key={investor.uuid}
                />
            ));

            return (
                <Card key={dateString} title={dayjs(dateString).format('dddd')}>
                    <Space direction='vertical'>
                        {cards}
                    </Space>
                </Card>
            );
        });


        const imTreeData: ITreeStructureGroup[] = [
            {
                children: [],
                key: unassignedKey,
                title: 'Unassigned',
                value: unassignedKey,
            },
        ];

        _.forEach(_.sortBy(visibleIms, ['name']), (loopAdmin: IAdministrator) => {
            imTreeData.push({
                children: [],
                key: loopAdmin.uuid,
                title: loopAdmin.name,
                value: loopAdmin.uuid,
            });
        });

        const accountTypeTreeData: ITreeStructureGroup[] = [
            {
                children: [],
                key: AccountTypeEnum.Marketplace,
                title: 'Marketplace',
                value: AccountTypeEnum.Marketplace,
            },
            {
                children: [],
                key: AccountTypeEnum.IncomeTrust,
                title: 'Income Trust',
                value: AccountTypeEnum.IncomeTrust,
            },
        ];

        const filtered: boolean = imFilter.length > 0 || accountTypeFilter.length > 0;

        const imFilterOptions: JSX.Element = currentAdministrator.role !== RoleEnum.InvestorManager && (
            <Form.Item label='IMs'>
                <Tree
                    checkable={true}
                    treeData={imTreeData}
                    checkedKeys={imFilter}
                    onCheck={this.onChangeImFilter}
                    defaultExpandAll={true}
                    selectable={false}
                />
            </Form.Item>
        );

        const modalFooter: JSX.Element = (
            <React.Fragment>
                <Button className='close' onClick={this.onCancelChangeFilters}>Close</Button>
            </React.Fragment>
        );

        return (
            <Layout className='investors'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Investors</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Search />
                            <Button onClick={this.onClickChangeFilters} icon={filtered ? <FilterTwoTone /> : <FilterFilled />} />
                            <Link to='/investors/list'><Button>List View</Button></Link>
                        </Space>
                        <Typography.Title level={2}>Investors</Typography.Title>
                        <Space align='start' className='columns'>
                            {columnsBlock}
                        </Space>
                    </Layout.Content>
                </Layout>
                <Modal
                    footer={modalFooter}
                    onCancel={this.onCancelChangeFilters}
                    open={isChangeFiltersModalOpen}
                    title='Change Filters'
                    wrapClassName='investors-dashboard-change-filters-modal'
                >
                    {imFilterOptions}
                    <Form.Item label='Account Type'>
                        <Tree
                            checkable={true}
                            treeData={accountTypeTreeData}
                            checkedKeys={accountTypeFilter}
                            onCheck={this.onChangeAccountTypeFilter}
                            defaultExpandAll={true}
                            selectable={false}
                        />
                    </Form.Item>
                </Modal>
            </Layout>
        );
    }

    private onClickChangeFilters(): void {
        this.setState({
            isChangeFiltersModalOpen: true,
        });
    }

    private onCancelChangeFilters(): void {
        this.setState({
            isChangeFiltersModalOpen: false,
        });
    }

    private onChangeImFilter(checkedKeys: any): void {
        this.setState({
            imFilter: checkedKeys,
        });
    }

    private onChangeAccountTypeFilter(checkedKeys: any): void {
        this.setState({
            accountTypeFilter: checkedKeys,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        administrators: administratorsSelector(state),
        investors: investorsBoardSelector(state),
        currentAdministrator: currentAdministratorSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        investorsBoard: () => dispatch(investorsBoardAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dashboard);
