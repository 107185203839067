import { Spin, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IHistory from '~Api/Investment/IHistory';
import IInvestment from '~Api/Investment/IInvestment';
import {
    investmentGetAction,
    investmentHistoriesListAction,
} from '~Investments/actions';
import {
    investmentHistoriesSelector,
    investmentSelector,
} from '~Investments/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    histories: IHistory[];
    investment: IInvestment;
}

interface IPropsDispatch {
    historiesList: () => void;
    investmentGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class History extends React.Component<Props> {
    public componentDidMount() {
        const { investment } = this.props;

        if (!investment) {
            this.props.investmentGet();
        }

        this.props.historiesList();
    }

    public render(): JSX.Element {
        const { investment, match, histories } = this.props;

        if (!investment || !histories) {
            return (
                <Layout uuid={match.params.uuid} section='history'>
                    <Typography.Title level={2}>History</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const historiesBlock: JSX.Element[] = _.sortBy(_.filter(histories, (history: IHistory) => !!history.uuid), ['createdTime']).map((loopHistory: IHistory) => (
            <Timeline.Item key={loopHistory.uuid} label={dayjs(loopHistory.createdTime).format('YYYY-MM-DD - HH:mm')}>
                <Typography.Text>{loopHistory.description}</Typography.Text>
            </Timeline.Item>
        ));

        return (
            <Layout uuid={match.params.uuid} section='history'>
                <Typography.Title level={2}>History</Typography.Title>
                <Timeline mode='left' reverse={true}>
                    {historiesBlock}
                </Timeline>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        histories: investmentHistoriesSelector(state, ownProps.match.params.uuid),
        investment: investmentSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        historiesList: () => dispatch(investmentHistoriesListAction(ownProps.match.params.uuid)),
        investmentGet: () => dispatch(investmentGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(History);
