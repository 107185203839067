import { fetchGet, fetchPost, fetchPostFile, fetchPut } from '~utilities/fetch';
import IBroker from './IBroker';
import INote from './INote';
import RejectReasonEnum from './RejectReasonEnum';
import {
    transformBroker,
    transformBrokerNote,
} from './transformers';

export async function brokersAddRequest(broker: IBroker): Promise<Response> {
    return fetchPost('/brokers', transformBroker(broker));
}

export async function brokersListRequest(): Promise<Response> {
    return fetchGet('/brokers');
}

export async function brokersSearchRequest(keyword: string): Promise<Response> {
    return fetchPost('/brokers/search', { keyword });
}

export async function brokerGetRequest(brokerUuid: string): Promise<Response> {
    return fetchGet(`/brokers/${brokerUuid}`);
}

export async function brokerApproveRequest(brokerUuid: string, sendEmail: boolean): Promise<Response> {
    return fetchPost(`/brokers/${brokerUuid}/approve`, { sendEmail });
}

export async function brokerAssignBdmRequest(brokerUuid: string, administratorUuid: string) {
    return fetchPost(`/brokers/${brokerUuid}/assign-bdm`, { administratorUuid });
}

export async function brokerNextContactRequest(brokerUuid: string, contactDateNext: string): Promise<Response> {
    return fetchPost(`/brokers/${brokerUuid}/next-contact`, { contactDateNext });
}

export async function brokerRecordContactRequest(brokerUuid: string, note?: string): Promise<Response> {
    return fetchPost(`/brokers/${brokerUuid}/record-contact`, { note });
}

export async function brokerRejectRequest(brokerUuid: string, reason: RejectReasonEnum, note: string): Promise<Response> {
    return fetchPost(`/brokers/${brokerUuid}/reject`, {
        note,
        reason,
    });
}

export async function brokerResetApprovalStatusRequest(brokerUuid: string) {
    return fetchPost(`/brokers/${brokerUuid}/reset-approval-status`);
}

export async function brokerUnassignBdmRequest(brokerUuid: string) {
    return fetchPost(`/brokers/${brokerUuid}/unassign-bdm`);
}

export async function brokerUpdateRequest(broker: IBroker): Promise<Response> {
    return fetchPut(`/brokers/${broker.uuid}`, transformBroker(broker));
}

export async function brokersApplicationsListRequest(brokerUuid: string): Promise<Response> {
    return fetchGet(`/brokers/${brokerUuid}/applications`);
}

export async function brokersDealsListRequest(brokerUuid: string): Promise<Response> {
    return fetchGet(`/brokers/${brokerUuid}/deals`);
}

export async function brokerDocumentsListRequest(brokerUuid: string): Promise<Response> {
    return fetchGet(`/brokers/${brokerUuid}/documents`);
}

export async function brokerInsuranceProofRequest(brokerUuid: string, insuranceProof: File): Promise<Response> {
    return fetchPostFile(`/brokers/${brokerUuid}/insurance-proof`, insuranceProof);
}

export async function brokerMembershipDocumentRequest(brokerUuid: string, membershipDocument: File): Promise<Response> {
    return fetchPostFile(`/brokers/${brokerUuid}/membership-document`, membershipDocument);
}

export async function brokerPhotoIdRequest(brokerUuid: string, photoId: File): Promise<Response> {
    return fetchPostFile(`/brokers/${brokerUuid}/photo-id`, photoId);
}

export async function brokerNotesAddRequest(brokerUuid: string, note: INote): Promise<Response> {
    return fetchPost(`/brokers/${brokerUuid}/notes`, transformBrokerNote(note));
}

export async function brokerNotesListRequest(brokerUuid: string): Promise<Response> {
    return fetchGet(`/brokers/${brokerUuid}/notes`);
}

export async function brokerHistoriesListRequest(brokerUuid: string): Promise<Response> {
    return fetchGet(`/brokers/${brokerUuid}/history`);
}
