import {
    Button,
    Divider,
    Form,
    Spin,
    Timeline,
    Typography,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import { administratorsSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import INote from '~Api/ReferralPartner/INote';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import { IGlobalState } from '~reducer';
import {
    referralPartnerNotesSelector,
    referralPartnerSelector,
} from '~ReferralPartners/selectors';
import { IDictionary } from '~utilities/IDictionary';
import {
    referralPartnerGetAction,
    referralPartnerNotesAddAction,
    referralPartnerNotesListAction,
} from '../actions';
import Layout from './Layout';

interface IState {
    note: string;
}

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    administrators: IDictionary<IAdministrator>;
    notes: IDictionary<INote>;
    referralPartner: IReferralPartner;
}

interface IPropsDispatch {
    administratorsList: () => void;
    noteAdd: (note: string) => void;
    notesList: () => void;
    referralPartnerGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Notes extends React.Component<Props, IState> {
    public state: IState = {
        note: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeNote = this.onChangeNote.bind(this);
        this.onClickAddNote = this.onClickAddNote.bind(this);
    }

    public componentDidMount(): void {
        const { administrators, referralPartner } = this.props;

        if (!referralPartner) {
            this.props.referralPartnerGet();
        }

        if (!administrators) {
            this.props.administratorsList();
        }

        this.props.notesList();
    }

    public render(): JSX.Element {
        const { administrators, referralPartner, match, notes } = this.props;
        const { note } = this.state;

        if (!administrators || !referralPartner || !notes) {
            return (
                <Layout uuid={match.params.uuid} section='notes'>
                    <Spin/>
                </Layout>
            );
        }

        const unsavedNote: INote = _.find(_.values(notes), (loopNote: INote) => !loopNote.uuid);

        const unsavedNoteBlock: JSX.Element = unsavedNote && (
            <React.Fragment>
                <Typography.Text type='secondary'>{administrators[unsavedNote.administratorUuid].name}</Typography.Text><br/>
                <Typography.Text>{unsavedNote.note}</Typography.Text>
            </React.Fragment>
        );

        const notesBlock: JSX.Element[] = _.sortBy(_.filter(notes, (loopNote: INote) => !!loopNote.uuid), 'createdTime').map((loopNote: INote) => (
            <Timeline.Item key={loopNote.uuid} label={dayjs(loopNote.createdTime).format('YYYY-MM-DD - HH:mm')}>
                <Typography.Text type='secondary'>{administrators[loopNote.administratorUuid].name}</Typography.Text><br/>
                <Typography.Text>{loopNote.note}</Typography.Text>
            </Timeline.Item>
        ));

        return (
            <Layout uuid={match.params.uuid} section='notes'>
                <Typography.Title level={2}>Notes</Typography.Title>
                <Form.Item label='Note' className='note'>
                    <TextArea disabled={!!unsavedNote} onChange={this.onChangeNote} rows={15} value={note} />
                </Form.Item>
                <Button
                    className='add-note'
                    disabled={!note || !!unsavedNote}
                    onClick={this.onClickAddNote}
                    type='primary'
                >
                    Add Note
                </Button>
                {notesBlock.length > 0 && <Divider/>}
                <Timeline mode='left' pending={unsavedNoteBlock} reverse={true}>
                    {notesBlock}
                </Timeline>
            </Layout>
        );
    }

    private onChangeNote(event: React.ChangeEvent<HTMLTextAreaElement>): void {
        this.setState({
            note: event.target.value,
        });
    }

    private onClickAddNote(): void {
        const { note } = this.state;

        this.setState({
            note: null,
        });

        this.props.noteAdd(note);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        administrators: administratorsSelector(state),
        notes: referralPartnerNotesSelector(state, ownProps.match.params.uuid),
        referralPartner: referralPartnerSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        noteAdd: (note: string) => dispatch(referralPartnerNotesAddAction(ownProps.match.params.uuid, note)),
        notesList: () => dispatch(referralPartnerNotesListAction(ownProps.match.params.uuid)),
        referralPartnerGet: () => dispatch(referralPartnerGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Notes);
