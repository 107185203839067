import { fetchDelete, fetchGet, fetchPost, fetchPostFile, fetchPut } from '~utilities/fetch';
import IBankAccount from './IBankAccount';
import IComplianceDocument from './IComplianceDocument';
import IIndividual from './IIndividual';
import IInvestor from './IInvestor';
import INote from './INote';
import {
    transformBankAccount,
    transformComplianceDocument,
    transformIndividual,
    transformInvestor,
    transformNote,
} from './transformers';

export async function incomeTrustInvestmentRequestCancelRequest(incomeTrustInvestmentRequestUuid: string, reason: string): Promise<Response> {
    return fetchPost(`/investor-income-trust-investment-request/${incomeTrustInvestmentRequestUuid}/cancel`, {
        reason,
    });
}

export async function incomeTrustInvestmentRequestProcessRequest(incomeTrustInvestmentRequestUuid: string, sendEmail: boolean): Promise<Response> {
    return fetchPost(`/investor-income-trust-investment-request/${incomeTrustInvestmentRequestUuid}/process`, {
        sendEmail,
    });
}

export async function incomeTrustInvestmentRequestsPendingListRequest(): Promise<Response> {
    return fetchGet('/investor-income-trust-investment-request/pending');
}

export async function investorsBoardRequest(): Promise<Response> {
    return fetchGet('/investors/board');
}

export async function investorsListRequest(): Promise<Response> {
    return fetchGet('/investors');
}

export async function investorsSearchRequest(keyword: string): Promise<Response> {
    return fetchPost(`/investors/search`, { keyword });
}

export async function investorsManagedListRequest(): Promise<Response> {
    return fetchGet(`/investors/managed`);
}

export async function investorsPrivateListRequest(): Promise<Response> {
    return fetchGet('/investors/private');
}

export async function investorAccountApproveRequest(investorAccountUuid: string): Promise<Response> {
    return fetchPost(`/investor-accounts/${investorAccountUuid}/approve`);
}

export async function investorAccountDepositApproveRequest(investorAccountUuid: string, sendEmail: boolean): Promise<Response> {
    return fetchPost(`/investor-accounts/${investorAccountUuid}/deposit-approve`, {
        sendEmail,
    });
}

export async function investorAccountDepositRejectRequest(investorAccountUuid: string, reason: string): Promise<Response> {
    return fetchPost(`/investor-accounts/${investorAccountUuid}/deposit-reject`, {
        reason,
    });
}

export async function investorAccountDepositRequest(uuid: string, amount: number): Promise<Response> {
    return fetchPost(`/investor-accounts/${uuid}/deposit`, {
        amount,
    });
}

export async function investorAccountInvestmentGetRequest(investorAccountInvestmentUuid: string): Promise<Response> {
    return fetchGet(`/investor-account-investments/${investorAccountInvestmentUuid}`);
}

export async function investorAccountInvestmentsListRequest(accountUuid: string): Promise<Response> {
    return fetchGet(`/investor-accounts/${accountUuid}/investments`);
}

export async function investorAccountInvestmentTransactionsListRequest(investorAccountInvestmentUuid: string): Promise<Response> {
    return fetchGet(`/investor-account-investments/${investorAccountInvestmentUuid}/transactions`);
}

export async function investorAccountRejectRequest(investorAccountUuid: string): Promise<Response> {
    return fetchPost(`/investor-accounts/${investorAccountUuid}/reject`);
}

export async function investorAccountSharesListRequest(accountUuid: string): Promise<Response> {
    return fetchGet(`/investor-accounts/${accountUuid}/shares`);
}

export async function investorAccountTransactionsListRequest(accountUuid: string): Promise<Response> {
    return fetchGet(`/investor-accounts/${accountUuid}/transactions`);
}

export async function investorAccountUnapproveRequest(investorAccountUuid: string): Promise<Response> {
    return fetchPost(`/investor-accounts/${investorAccountUuid}/unapprove`);
}

export async function investorAccountWithdrawRequest(accountUuid: string, amount: number): Promise<Response> {
    return fetchPost(`/investor-accounts/${accountUuid}/withdraw`, {
        amount,
    });
}

export async function investorAccountWithdrawalAbaInvestorTransactionsListRequest(accountUuid: string): Promise<Response> {
    return fetchGet(`/investor-accounts/${accountUuid}/withdrawal-aba-investor-transactions`);
}

export async function investorPendingWithdrawalsUnapprovedListRequest(): Promise<Response> {
    return fetchGet(`/investor-pending-withdrawals/unapproved`);
}

export async function investorPendingWithdrawalApproveRequest(investorPendingWithdrawalUuid: string): Promise<Response> {
    return fetchPost(`/investor-pending-withdrawals/${investorPendingWithdrawalUuid}/approve`);
}

export async function investorAccountAnnualStatementsAddRequest(investorAccountUuid: string, investorFileUuid: string, year: string): Promise<Response> {
    return fetchPost(`/investor-accounts/${investorAccountUuid}/annual-statements`, {
        investorFileUuid,
        year,
    });
}

export async function investorAccountAnnualStatementsGenerateRequest(investorAccountUuid: string, financialYear: string): Promise<Response> {
    return fetchPost(`/investor-accounts/${investorAccountUuid}/annual-statements/generate`, {
        financialYear,
    });
}

export async function investorAccountAnnualStatementDeleteRequest(annualStatementUuid: string): Promise<Response> {
    return fetchDelete(`/investor-annual-statements/${annualStatementUuid}`);
}

export async function investorAccountAnnualStatementsListRequest(investorAccountUuid: string): Promise<Response> {
    return fetchGet(`/investor-accounts/${investorAccountUuid}/annual-statements`);
}

export async function investorAccountMonthlyStatementsGenerateRequest(investorAccountUuid: string, month: string, year: string): Promise<Response> {
    return fetchPost(`/investor-accounts/${investorAccountUuid}/generate-monthly-statement`, {
        month,
        year,
    });
}

export async function investorAccountMonthlyStatementsListRequest(investorAccountUuid: string): Promise<Response> {
    return fetchGet(`/investor-accounts/${investorAccountUuid}/monthly-statements`);
}

export async function investorAccountMonthlyStatementDeleteRequest(monthlyStatementUuid: string): Promise<Response> {
    return fetchDelete(`/investor-account-monthly-statements/${monthlyStatementUuid}`);
}

export async function investorAccountMonthlyStatementSendRequest(monthlyStatementUuid: string, email?: string, firstName?: string, lastName?: string): Promise<Response> {
    return fetchPost(`/investor-account-monthly-statements/${monthlyStatementUuid}/send`, !!email ? {
        email,
        firstName,
        lastName,
    } : null);
}

export async function investorAccountsIncomeTrustListRequest(): Promise<Response> {
    return fetchGet('/investor-accounts/income-trust');
}

export async function investorAccountsPendingApprovalListRequest(): Promise<Response> {
    return fetchGet('/investor-accounts/pending-approval');
}

export async function investorAssignAdviserRequest(investorUuid: string, adviserUuid: string): Promise<Response> {
    return fetchPost(`/investors/${investorUuid}/assign-adviser`, {
        adviserUuid,
    });
}

export async function investorAssignImRequest(investorUuid: string, administratorUuid: string): Promise<Response> {
    return fetchPost(`/investors/${investorUuid}/assign-im`, {
        administratorUuid,
    });
}

export async function investorBankAccountsAddRequest(investorUuid: string, bankAccount: IBankAccount): Promise<Response> {
    return fetchPost(`/investors/${investorUuid}/bank-accounts`, transformBankAccount(bankAccount));
}

export async function investorBankAccountConfirmRequest(bankAccountUuid: string): Promise<Response> {
    return fetchPost(`/investor-bank-accounts/${bankAccountUuid}/confirm`);
}

export async function investorBankAccountsListRequest(investorUuid: string): Promise<Response> {
    return fetchGet(`/investors/${investorUuid}/bank-accounts`);
}

export async function investorBankAccountUnconfirmRequest(bankAccountUuid: string): Promise<Response> {
    return fetchPost(`/investor-bank-accounts/${bankAccountUuid}/unconfirm`);
}

export async function investorComplianceDocumentsAddRequest(investorUuid: string, complianceDocument: IComplianceDocument): Promise<Response> {
    return fetchPost(`/investors/${investorUuid}/compliance-documents`, transformComplianceDocument(complianceDocument));
}

export async function investorComplianceDocumentsListRequest(investorUuid: string): Promise<Response> {
    return fetchGet(`/investors/${investorUuid}/compliance-documents`);
}

export async function investorComplianceDocumentDeleteRequest(complianceDocumentUuid: string): Promise<Response> {
    return fetchDelete(`/investor-compliance-documents/${complianceDocumentUuid}`);
}

export async function investorNextContactRequest(investorUuid: string, contactDateNext: string): Promise<Response> {
    return fetchPost(`/investors/${investorUuid}/next-contact`, { contactDateNext });
}

export async function investorCreateIncomeTrustAccountRequest(investorUuid: string): Promise<Response> {
    return fetchPost(`/investors/${investorUuid}/create-income-trust-account`);
}

export async function investorDocumentsAddRequest(investorUuid: string, file: File): Promise<Response> {
    return fetchPostFile(`/investors/${investorUuid}/documents`, file);
}

export async function investorDocumentsListRequest(investorUuid: string): Promise<Response> {
    return fetchGet(`/investors/${investorUuid}/documents`);
}

export async function investorGetRequest(investorUuid: string): Promise<Response> {
    return fetchGet(`/investors/${investorUuid}`);
}

export async function investorHistoriesListRequest(uuid: string): Promise<Response> {
    return fetchGet(`/investors/${uuid}/history`);
}

export async function investorIndividualAddRequest(individual: IIndividual): Promise<Response> {
    return fetchPost(`/investors/${individual.investorUuid}/individuals`, transformIndividual(individual));
}

export async function investorNotesAddRequest(investorUuid: string, note: INote): Promise<Response> {
    return fetchPost(`/investors/${investorUuid}/notes`, transformNote(note));
}

export async function investorNotesListRequest(uuid: string): Promise<Response> {
    return fetchGet(`/investors/${uuid}/notes`);
}

export async function investorPrimaryBankAccountRequest(bankAccountUuid: string, investorUuid: string): Promise<Response> {
    return fetchPost(`/investors/${investorUuid}/primary-bank-account`, {
        bankAccountUuid,
    });
}

export async function investorReferralAddRequest(investorUuid: string, referralInvestorUuid: string): Promise<Response> {
    return fetchPost(`/investors/${investorUuid}/referrals`, {
        investorUuid: referralInvestorUuid,
    });
}

export async function investorReferralsInvestorListRequest(investorUuid: string): Promise<Response> {
    return fetchGet(`/investors/${investorUuid}/referrals`);
}

export async function investorReferralsListRequest(): Promise<Response> {
    return fetchGet('/investor-referrals');
}

export async function investorReferralProcessRequest(referralUuid: string, sendEmail: boolean, process: boolean): Promise<Response> {
    return fetchPost(`/investor-referrals/${referralUuid}/process`, {
        process,
        sendEmail,
    });
}

export async function investorReferralUnprocessRequest(referralUuid: string): Promise<Response> {
    return fetchPost(`/investor-referrals/${referralUuid}/unprocess`);
}

export async function investorUpdateRequest(investor: IInvestor): Promise<Response> {
    return fetchPut(`/investors/${investor.uuid}`, transformInvestor(investor));
}

export async function investorUnassignAdviserRequest(investorUuid: string): Promise<Response> {
    return fetchPost(`/investors/${investorUuid}/unassign-adviser`);
}

export async function investorUnassignImRequest(investorUuid: string): Promise<Response> {
    return fetchPost(`/investors/${investorUuid}/unassign-im`);
}

export async function investorUsersListRequest(investorUuid: string): Promise<Response> {
    return fetchGet(`/investors/${investorUuid}/users`);
}

export async function investorAccountsPendingDepositsRequest(): Promise<Response> {
    return fetchGet('/investor-accounts/pending-deposits');
}
