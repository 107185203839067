import { Descriptions, Divider, Spin, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import { IGlobalState } from '~reducer';
import { referralPartnerGetAction } from '~ReferralPartners/actions';
import { referralPartnerSelector } from '~ReferralPartners/selectors';
import Layout from './Layout';
import './referralPartner.less';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    referralPartner: IReferralPartner;
}

interface IPropsDispatch {
    referralPartnerGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Overview extends React.Component<Props> {
    public componentDidMount(): void {
        const { referralPartner } = this.props;

        if (!referralPartner) {
            this.props.referralPartnerGet();
        }
    }

    public render(): JSX.Element {
        const { referralPartner, match } = this.props;

        if (!referralPartner) {
            return (
                <Layout uuid={match.params.uuid} section='overview'>
                    <Typography.Title level={2}>Overview</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        return (
            <Layout uuid={match.params.uuid} section='overview'>
                <Typography.Title level={2}>Overview</Typography.Title>
                <Descriptions bordered={true} column={4} layout='vertical'>
                    <Descriptions.Item label='Company'>{referralPartner.companyName || '-'}</Descriptions.Item>
                    <Descriptions.Item label='ABN/ACN'>{referralPartner.abn || '-'}</Descriptions.Item>
                    <Descriptions.Item label='Group'>{referralPartner.groupName || '-'}</Descriptions.Item>
                    <Descriptions.Item label='Address'>{referralPartner.streetAddress} {referralPartner.suburb}, {referralPartner.state} {referralPartner.postcode}</Descriptions.Item>
                </Descriptions>
                <Descriptions column={2} bordered={true} layout='horizontal' title='Compliance Questions'>
                    <Descriptions.Item label='Holds ACL?'>{referralPartner.acl ? 'Yes' : 'No'}</Descriptions.Item>
                    <Descriptions.Item label='Agrees to comply with NCCP?'>{referralPartner.nccp ? 'Yes' : 'No'}</Descriptions.Item>
                    <Descriptions.Item label='Has been banned?'>{referralPartner.banned ? 'Yes' : 'No'}</Descriptions.Item>
                    <Descriptions.Item label='Charges the consumer a referral fee?'>{referralPartner.chargesFee ? 'Yes' : 'No'}</Descriptions.Item>
                    <Descriptions.Item label='Conducts business in person?'>{referralPartner.conductsBusinessInPerson ? 'Yes' : 'No'}</Descriptions.Item>
                    <Descriptions.Item label='Agrees to disclose benefits?'>{referralPartner.disclosesBenefits ? 'Yes' : 'No'}</Descriptions.Item>
                    <Descriptions.Item label='Agrees to refer the client to Funding?'>{referralPartner.refersClient ? 'Yes' : 'No'}</Descriptions.Item>
                    <Descriptions.Item label='Agrees to provide customer details?'>{referralPartner.includesCustomerInfo ? 'Yes' : 'No'}</Descriptions.Item>
                </Descriptions>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        referralPartner: referralPartnerSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        referralPartnerGet: () => dispatch(referralPartnerGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Overview);
