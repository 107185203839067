import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import ActiveReport from './ActiveReport';
import EligibleLoans from './EligibleLoans';
import Loan from './Loan';
import Loans from './Loans';
import Overview from './Overview';
import PendingApplications from './PendingApplications';
import Transactions from './Transactions';
import ProposedSales from './ProposedSales';
import ProposedPurchases from './ProposedPurchases';

export default function Router(): ReactElement {
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);

    return (
        <Switch>
            <Route exact={true} path='/warehouses/:warehouseUuid' component={Overview} />
            {currentUser.permissions.includes(PermissionsEnum.WarehouseActiveReport) && <Route exact={true} path='/warehouses/:warehouseUuid/active-report' component={ActiveReport} />}
            <Route exact={true} path='/warehouses/:warehouseUuid/eligible-loans' component={EligibleLoans} />
            <Route exact={true} path='/warehouses/:warehouseUuid/loans' component={Loans} />
            <Route exact={true} path='/warehouses/:warehouseUuid/loans/:warehouseLoanUuid' component={Loan} />
            <Route exact={true} path='/warehouses/:warehouseUuid/proposed-purchases' component={ProposedPurchases} />
            <Route exact={true} path='/warehouses/:warehouseUuid/proposed-sales' component={ProposedSales} />
            <Route exact={true} path='/warehouses/:warehouseUuid/transactions' component={Transactions} />
            <Route exact={true} path='/warehouses/:warehouseUuid/pending-applications' component={PendingApplications} />
        </Switch>
    );
}
