enum SourceEnum {
    Bing = 'BING',
    Facebook = 'FACEBOOK',
    FacebookForm = 'FACEBOOK_FORM',
    FacebookPaid = 'FACEBOOK_PAID',
    Google = 'GOOGLE',
    GoogleForm = 'GOOGLE_FORM',
    LinkedInForm = 'LINKED_FORM',
    ReaGroup = 'REA',
}

export default SourceEnum;
