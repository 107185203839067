import { Spin } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IDeal from '~Api/Deal/IDeal';
import PropertiesList from '~Deals/PropertiesList';
import { leadGetAction } from '~Leads/actions';
import { leadSelector } from '~Leads/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';

interface IMatch {
    dealUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    deal: IDeal;
}

interface IPropsDispatch {
    leadGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Properties extends React.Component<Props> {
    public componentDidMount() {
        const { deal } = this.props;

        if (!deal) {
            this.props.leadGet();
        }
    }

    public render(): JSX.Element {
        const { deal, match } = this.props;

        if (!deal) {
            return (
                <Layout dealUuid={match.params.dealUuid} section='properties'>
                    <Spin/>
                </Layout>
            );
        }

        return (
            <Layout dealUuid={match.params.dealUuid} section='properties'>
                <PropertiesList dealUuid={deal.uuid} />
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        deal: leadSelector(state, ownProps.match.params.dealUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        leadGet: () => dispatch(leadGetAction(ownProps.match.params.dealUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Properties);
