import { Spin, Typography } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import { IGlobalState } from '~reducer';
import {
    warehouseGetAction,
    warehouseParametersListAction,
} from '~Warehouses/actions';
import {
    warehouseParametersSelector,
    warehouseSelector,
} from '~Warehouses/selectors';
import Layout from './Layout';
import IWarehouseParameter from '~Api/Warehouse/IWarehouseParameter';
import Parameters from './Parameters';

export default function Overview(props: {
    match: routerMatch<{
        warehouseUuid: string;
    }>;
}): ReactElement {
    const { match } = props;

    const parameters: IWarehouseParameter[] = useSelector((state: IGlobalState) => warehouseParametersSelector(state, match.params.warehouseUuid));
    const warehouse: IWarehouse = useSelector((state: IGlobalState) => warehouseSelector(state, match.params.warehouseUuid));

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!parameters) {
            dispatch(warehouseParametersListAction(match.params.warehouseUuid));
        }

        if (!warehouse) {
            dispatch(warehouseGetAction(match.params.warehouseUuid));
        }
    }, []);

    if (!parameters || !warehouse) {
        return (
            <Layout uuid={match.params.warehouseUuid} section='overview'>
                <Typography.Title level={2}>Overview</Typography.Title>
                <Spin/>
            </Layout>
        );
    }

    return (
        <Layout uuid={match.params.warehouseUuid} section='overview'>
            <Typography.Title level={2}>Overview</Typography.Title>
            <Parameters parameters={parameters} />
        </Layout>
    );
}
