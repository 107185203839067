import IAdviser from '~Api/Adviser/IAdviser';
import IHistory from '~Api/Adviser/IHistory';
import INote from '~Api/Adviser/INote';
import IInvestor from '~Api/Investor/IInvestor';
import { investorSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { keyMap } from '~utilities/utils';

export function advisersSelector(state: IGlobalState): IDictionary<IAdviser> {
    if (!state.advisers.advisersListed) {
        return null;
    }

    return state.advisers.advisers;
}

export function adviserSelector(state: IGlobalState, adviserUuid: string): IAdviser {
    return state.advisers.advisers[adviserUuid];
}

export function adviserHistoriesSelector(state: IGlobalState, adviserUuid: string): IDictionary<IHistory> {
    return state.advisers.histories[adviserUuid];
}

export function adviserInvestorsSelector(state: IGlobalState, adviserUuid: string): IDictionary<IInvestor> {
    if (!state.advisers.adviserInvestorUuids[adviserUuid]) {
        return null;
    }

    return keyMap(state.advisers.adviserInvestorUuids[adviserUuid], 'uuid', (investorUuid: string) => investorSelector(state, investorUuid));

}

export function adviserNotesSelector(state: IGlobalState, adviserUuid: string): IDictionary<INote> {
    return state.advisers.notes[adviserUuid];
}
