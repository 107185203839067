enum ConditionTypeEnum {
    AdverseCreditStatement = 'ADVERSE_CREDIT_STATEMENT',
    AgentAgreement = 'AGENT_AGREEMENT',
    AssetLiabilityStatement = 'ASSET_LIABILITY_STATEMENT',
    BankruptcyRegisterSearch = 'BANKRUPTCY_REGISTER_SEARCH',
    BankSolicitorAccountantDetails = 'BANK_SOLICITOR_ACCOUNTANT_DETAILS',
    BankStatementsThreeMonths = 'BANK_STATEMENTS_THREE_MONTHS',
    BankStatementsSixMonths = 'BANK_STATEMENTS_SIX_MONTHS',
    BrokerInvoice = 'BROKER_INVOICE',
    BuilderCapabilityStatement = 'BUILDERS_CAPABILITY_STATEMENT',
    BuildersInsurance = 'BUILDERS_INSURANCE',
    BuildingContractPlans = 'BUILDING_CONTRACT',
    BuildingPermitApproval = 'BUILDING_PERMIT_APPROVAL',
    BuildingReplacementInsurance = 'BUILDING_REPLACEMENT_INSURANCE',
    BusinessActivityStatements = 'BUSINESS_ACTIVITY_STATEMENTS',
    BusinessPurposeDeclaration = 'BUSINESS_PURPOSE_DECLARATION',
    CouncilWaterRatesNotice = 'COUNCIL_WATER_RATES_NOTICE',
    CompanyInDepthTradingHistory = 'ICTH_REPORT',
    CompletedLoanApplication = 'COMPLETED_LOAN_APPLICATION',
    ConsumerPurposeDeclaration = 'CONSUMER_PURPOSE_DECLARATION',
    ContractOfSale = 'CONTRACT_OF_SALE',
    CreditFileSearchReport = 'CREDIT_FILE_SEARCH_REPORT',
    CurrentTitleSearch = 'CURRENT_TITLE_SEARCH',
    DebtPayoutAmount = 'DEBT_PAYOUT_AMOUNT',
    EvidenceAdditionalFunding = 'EVIDENCE_ADDITIONAL_FUNDING',
    EvidenceCommitmentFeePaid = 'EVIDENCE_COMMITMENT_FEE_PAID',
    EvidenceLeviesPaid = 'EVIDENCE_LEVIES_PAID',
    EvidencePurchaseDeposit = 'EVIDENCE_PURCHASE_DEPOSIT',
    ExitStrategyLetter = 'EXIT_STRATEGY_LETTER',
    FeasibilityStudyTable = 'FEASIBILITY_STUDY_TABLE',
    FirbApprovalLetter = 'FIRB_APPROVAL_LETTER',
    GeneralSecurityAgreement = 'GENERAL_SECURITY_AGREEMENT',
    IdentificationPoints = 'IDENTIFICATION_POINTS',
    IdentityVerification = 'IDENTITY_VERIFICATION',
    LeaseAgreement = 'LEASE_AGREEMENT',
    ListImprovementsEstimates = 'LIST_IMPROVEMENTS_ESTIMATES',
    LoanPurposeDeclaration = 'LOAN_PURPOSE_DECLARATION',
    Memo = 'MEMO',
    MonthlyBudget = 'MONTHLY_BUDGET',
    MortgageStatements = 'MORTGAGE_STATEMENTS',
    NominationContractVariation = 'NOMINATION_CONTRACT_VARIATION',
    PaygPayslips = 'PAYG_PAYSLIPS',
    PlanningDevelopmentPermit = 'PLANNING_DEVELOPMENT_PERMIT',
    PlanSearch = 'PLAN_SEARCH',
    PreSalesContracts = 'PRE_SALES_CONTRACTS',
    PriceFinderReport = 'PRICE_FINDER_REPORT',
    PrivacyConsentForm = 'PRIVACY_CONSENT_FORM',
    ProfitLossStatement = 'PROFIT_LOSS_STATEMENT',
    ProofUseOfFunds = 'PROOF_USE_OF_FUNDS',
    PropertyPhoto = 'PROPERTY_PHOTO',
    PropertyProfileReport = 'PROPERTY_PROFILE_REPORT',
    PurchaseContract = 'PURCHASE_CONTRACT',
    RecordOfInterview = 'RECORD_OF_INTERVIEW',
    SecondMortgageAuthority = 'SECOND_MORTGAGE_AUTHORITY',
    SelfEmployedDeclaration = 'SELF_EMPLOYED_DECLARATION',
    SignedConditionalApproval = 'SIGNED_CONDITIONAL_APPROVAL',
    SignedLoanDocuments = 'SIGNED_LOAN_DOCUMENTS',
    SolicitorsCertification = 'SOLICITORS_CERTIFICATION',
    SolicitorsDisbursements = 'SOLICITORS_DISBURSEMENTS',
    StrataTitleNotice = 'STRATA_TITLE_NOTICE',
    TrustDeed = 'TRUST_DEED',
    UnsignedLoanDocuments = 'UNSIGNED_LOAN_DOCUMENTS',
    Valuation = 'VALUATION',
}

export default ConditionTypeEnum;
