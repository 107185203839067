import { Button, Form, Modal, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import { loanDischargeDateAction } from '~Loans/actions';
import DatePicker from '~UI/DatePicker';

interface IState {
    dischargeDate: string;
}

interface IProps {
    loan: ILoan;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsDispatch {
    dischargeDate: (dischargeDate: string) => void;
}

type Props = IProps & IPropsDispatch;

class DischargeDateModal extends React.Component<Props, IState> {
    public state: IState = {
        dischargeDate: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeDischargeDate = this.onChangeDischargeDate.bind(this);
        this.onClickClear = this.onClickClear.bind(this);
        this.onClickOk = this.onClickOk.bind(this);

        this.state.dischargeDate = props.loan.dischargeDate;
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { dischargeDate } = this.state;

        return (
            <Modal
                destroyOnClose={true}
                okText='Update'
                okType='danger'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Change Discharge Date'
                wrapClassName='loan-discharge-date-modal'
            >
                <Form.Item label='Discharge Date' className='discharge-date'>
                    <Space>
                        <DatePicker onChange={this.onChangeDischargeDate} format='DD/MM/YYYY' value={dischargeDate ? dayjs(dischargeDate) : null} />
                        <Button onClick={this.onClickClear}>Clear</Button>
                    </Space>
                </Form.Item>
            </Modal>
        );
    }

    private onChangeDischargeDate(date: Dayjs) {
        this.setState({
            dischargeDate: date ? date.format('YYYY-MM-DD') : null,
        });
    }

    private onClickClear() {
        this.setState({
            dischargeDate: null,
        });
    }

    private onClickOk() {
        const { dischargeDate } = this.state;

        this.props.onCancel();

        this.props.dischargeDate(dischargeDate);
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        dischargeDate: (dischargeDate: string) => dispatch(loanDischargeDateAction(ownProps.loan.uuid, dischargeDate)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(DischargeDateModal);
