import { Button, Dropdown, MenuProps, Modal, Space, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import RoleEnum from '~Api/Administrator/RoleEnum';
import ConditionalApprovalStatusEnum from '~Api/Application/ConditionalApprovalStatusEnum';
import IApplication from '~Api/Application/IApplication';
import IConditional from '~Api/Application/IConditional';
import {
    applicationConditionalApproveAction,
    applicationConditionalDeleteAction,
    applicationConditionalSendAction,
    applicationConditionalsListAction,
    applicationGetAction,
} from '~Applications/actions';
import {
    applicationConditionalsSelector,
    applicationSelector,
} from '~Applications/selectors';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector, authTokenSelector } from '~Auth/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import ConditionalSendModal from './ConditionalSendModal';
import Layout from './Layout';
import IAdministrator from '~Api/Administrator/IAdministrator';
import { currentAdministratorSelector } from '~Administrators/selectors';

interface IState {
    sendConditionalUuid: string;
}

interface IMatch {
    applicationUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    application: IApplication;
    conditionals: IDictionary<IConditional>;
    currentAdministrator: IAdministrator;
    currentUser: IAuthUser;
    token: string;
}

interface IPropsDispatch {
    applicationGet: () => void;
    approve: (payoutFigureUuid: string) => void;
    conditionalsList: () => void;
    delete: (payoutFigureUuid: string) => void;
    send: (conditionalUuid: string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Conditionals extends React.Component<Props> {
    public state: IState = {
        sendConditionalUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickApprove = this.onClickApprove.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.onClickSend = this.onClickSend.bind(this);
        this.onClickSendTo = this.onClickSendTo.bind(this);
        this.onCancelSendTo = this.onCancelSendTo.bind(this);
    }

    public componentDidMount(): void {
        const { application } = this.props;

        if (!application) {
            this.props.applicationGet();
        }

        this.props.conditionalsList();
    }

    public render(): JSX.Element {
        const { application, conditionals, currentAdministrator, currentUser, match, token } = this.props;
        const { sendConditionalUuid } = this.state;

        const isBdm: boolean = [RoleEnum.BusinessDevelopmentManager, RoleEnum.InternalBusinessDevelopmentManager, RoleEnum.SeniorBusinessDevelopmentManager].includes(currentAdministrator.role);

        if (!application || !conditionals) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='conditional-approvals'>
                    <Typography.Title level={2}>Conditional Approvals</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const columns: ColumnType<IConditional>[] = [
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => dayjs(createdTime).format('DD/MM/YYYY HH:mm'),
                sorter: (a: IConditional, b: IConditional) => dayjs(a.createdTime) > dayjs(b.createdTime) ? 1 : -1,
                title: 'Created',
                width: '15%',
            },
            {
                dataIndex: 'code',
                title: 'Code',
            },
            {
                dataIndex: 'loanAmount',
                render: (loanAmount: number) => currencyFormatter.format(loanAmount),
                title: 'Loan Amount',
                width: '15%',
            },
            {
                dataIndex: 'termMonths',
                render: (termMonths: number) => `${termMonths} months`,
                title: 'Term',
                width: '15%',
            },
            {
                dataIndex: 'interestRate',
                render: (interestRate: number) => percentageFormatter.format(interestRate / 100),
                title: 'Interest Rate',
                width: '15%',
            },
            {
                render: (conditional: IConditional) => {
                    const onClickApprove: () => void = () => this.onClickApprove(conditional.uuid);
                    const onClickDelete: () => void = () => this.onClickDelete(conditional.uuid);
                    const onClickSend: () => void = () => this.onClickSend(conditional.uuid);
                    const isDraft: boolean = conditional.approvalStatus === ConditionalApprovalStatusEnum.Draft;

                    const menu: MenuProps = {
                        items: [
                            {
                                key: 'send-to',
                                label: 'Send To',
                                onClick: () => this.onClickSendTo(conditional.uuid),
                            },
                        ],
                    };

                    return (
                        <Space>
                            <Button href={`${process.env.API_HOST}/application-conditionals/${conditional.uuid}/pdf?token=${token}`} target='_blank'>View PDF</Button>
                            {conditional.approvalStatus === ConditionalApprovalStatusEnum.Approved && <Dropdown.Button menu={menu} onClick={onClickSend} type={'primary'}>Send to {application.deal.brokerUuid ? 'Broker' : 'Borrower'}</Dropdown.Button>}
                            {isDraft && <Link to={`/applications/${application.uuid}/conditional-approvals/${conditional.uuid}/edit`}><Button>Edit</Button></Link>}
                            {isDraft && currentUser.permissions.includes(PermissionsEnum.ApplicationConditionalApprove) && <Button onClick={onClickApprove} type={'primary'}>Approve</Button>}
                            {isDraft && <Button onClick={onClickDelete} danger={true}>Delete</Button>}
                            <ConditionalSendModal
                                isOpen={sendConditionalUuid === conditional.uuid}
                                onCancel={this.onCancelSendTo}
                                conditional={conditional}
                            />
                        </Space>
                    );
                },
                width: '10%',
            },
        ];

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='conditional-approvals'>
                <Space className='issue'>
                    {!isBdm && <Link to={`/applications/${application.uuid}/conditional-approvals/add`}><Button className='issue'>Issue Conditional Approval</Button></Link>}
                </Space>
                <Typography.Title level={2}>Conditional Approvals</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(conditionals)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                />
            </Layout>
        );
    }

    private onClickApprove(conditionalUuid: string): void {
        const { conditionals } = this.props;

        Modal.confirm({
            content: `Are you sure you want to approve draft Conditional Approval ${conditionals[conditionalUuid].code}?`,
            okText: 'Approve',
            onOk: () => {
                this.props.approve(conditionalUuid);
            },
            title: 'Approve Conditional Approval',
        });
    }

    private onClickDelete(conditionalUuid: string): void {
        const { conditionals } = this.props;

        Modal.confirm({
            content: `Are you sure you want to delete draft Conditional Approval ${conditionals[conditionalUuid].code}?`,
            okText: 'Delete',
            onOk: () => {
                this.props.delete(conditionalUuid);
            },
            title: 'Delete Conditional Approval',
        });
    }

    private onClickSend(conditionalUuid: string): void {
        const { conditionals } = this.props;

        Modal.confirm({
            content: `Are you sure you want to send the Conditional Approval ${conditionals[conditionalUuid].code}?`,
            okText: 'Send',
            onOk: () => {
                this.props.send(conditionalUuid);
            },
            title: 'Send Conditional Approval',
        });
    }

    private onClickSendTo(sendConditionalUuid: string): void {
        this.setState({
            sendConditionalUuid,
        });
    }

    private onCancelSendTo(): void {
        this.setState({
            sendConditionalUuid: null,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
        conditionals: applicationConditionalsSelector(state, ownProps.match.params.applicationUuid),
        currentAdministrator: currentAdministratorSelector(state),
        currentUser: authCurrentUserSelector(state),
        token: authTokenSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
        approve: (conditionalUuid: string) => dispatch(applicationConditionalApproveAction(conditionalUuid)),
        conditionalsList: () => dispatch(applicationConditionalsListAction(ownProps.match.params.applicationUuid)),
        delete: (conditionalUuid: string) => dispatch(applicationConditionalDeleteAction(conditionalUuid)),
        send: (conditionalUuid: string) => dispatch(applicationConditionalSendAction(conditionalUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Conditionals);
