import { PercentageOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import React from 'react';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import accountTypeLabels from '~Api/Investor/accountTypeLabels';
import IAccount from '~Api/Investor/IAccount';
import { IDictionary } from '~utilities/IDictionary';

interface IAccountTypeTag {
    colour: string;
    icon: JSX.Element;
    label: string;
}

const accountTypeTagDetails: IDictionary<IAccountTypeTag> = {
    [AccountTypeEnum.IncomeTrust]: {
        colour: 'purple',
        icon: <PercentageOutlined />,
        label: 'IT',
    },
    [AccountTypeEnum.Marketplace]: {
        colour: 'green',
        icon: <ShoppingCartOutlined />,
        label: 'MP',
    },
};

interface IProps {
    account: IAccount;
}

type Props = IProps;

class AccountTypeTag extends React.Component<Props> {
    public render(): JSX.Element {
        const { account } = this.props;

        if (account.accountType === AccountTypeEnum.Private) {
            return null;
        }

        return (
            <Tooltip title={accountTypeLabels[account.accountType]}>
                <Tag
                    color={accountTypeTagDetails[account.accountType].colour}
                    icon={accountTypeTagDetails[account.accountType].icon}
                >
                    {accountTypeTagDetails[account.accountType].label}
                </Tag>
            </Tooltip>
        );
    }
}

export default AccountTypeTag;
