import { AutoComplete, Input } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IBroker from '~Api/Broker/IBroker';
import history from '~history';
import { IGlobalState } from '~reducer';
import {
    brokersSearchAction,
    brokersSearchResultsClearAction,
} from './actions';
import { brokersSearchResultsSelector } from './selectors';

interface IOption {
    label: JSX.Element;
    value: string;
}

interface IState {
    searchText: string;
}

interface IPropsSelector {
    brokerSearchResults: IBroker[];
}

interface IPropsDispatch {
    brokersSearchResultsClear: () => void;
    brokersSearch: (keyword: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Search extends React.Component<Props, IState> {
    public state: IState = {
        searchText: '',
    };

    constructor(props: Props) {
        super(props);

        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    public render(): JSX.Element {
        const { brokerSearchResults } = this.props;
        const { searchText } = this.state;

        const searchResults: IBroker[] = brokerSearchResults ?? [];

        const options: IOption[] = searchResults.map((broker: IBroker) => {
            const label: JSX.Element = (
                <div className='broker-search-option'>
                    <span className='option-code'>{broker.code}</span>
                    <span className='option-name'>{broker.firstName || '-'} {broker.lastName || '-'}</span>
                </div>
            );

            return {
                label,
                value: `${broker.uuid}`,
            };
        });

        return (
            <AutoComplete
                dropdownMatchSelectWidth={300}
                onSearch={this.onSearch}
                onSelect={this.onSelect}
                options={_.slice(options, 0, 20)}
                value={searchText}
            >
                <Input.Search/>
            </AutoComplete>
        );
    }

    private onSearch(value: string) {
        this.setState({
            searchText: value,
        });

        this.props.brokersSearch(value);
    }

    private onSelect(value: string) {
        this.props.brokersSearchResultsClear();

        history.push(`/brokers/${value}`);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        brokerSearchResults: brokersSearchResultsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        brokersSearch: (keyword: string) => dispatch(brokersSearchAction(keyword)),
        brokersSearchResultsClear: () => dispatch(brokersSearchResultsClearAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Search);
