import { CheckOutlined, SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Spin, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestor from '~Api/Investor/IInvestor';
import IReferral from '~Api/Investor/IReferral';
import { IGlobalState } from '~reducer';
import SearchDropdown from '~UI/SearchDropdown';
import { IDictionary } from '~utilities/IDictionary';
import {
    investorReferralsListAction,
    investorsListAction,
} from './actions';
import ReferralActions from './ReferralActions';
import {
    investorReferralsSelector,
    investorsSelector,
} from './selectors';

interface IPropsSelector {
    investors: IDictionary<IInvestor>;
    referrals: IReferral[];
}

interface IPropsDispatch {
    investorsList: () => void;
    referralsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Referrals extends React.Component<Props> {
    public componentDidMount() {
        this.props.investorsList();
        this.props.referralsList();
    }

    public render(): JSX.Element {
        const { investors, referrals } = this.props;

        if (!investors || !referrals) {
            return (
                <Layout className='investors-referrals'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/investors'>Investors</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Referrals</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Referrals</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: any[] = [
            {
                dataIndex: 'code',
                filterDropdown: (params: any) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string, referral: IReferral) => referral.code.toLowerCase().includes(value.toLowerCase()),
                title: 'Code',
            },
            {
                filterDropdown: (params: any) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string, referral: IReferral) => referral.investor.name.toLowerCase().includes(value.toLowerCase()),
                render: (referral: IReferral) => <Link to={`/investors/${referral.investor.uuid}`}>{referral.investor.name}</Link>,
                title: 'Investor',
                width: '20%',
            },
            {
                dataIndex: 'investorRewardAmount',
                render: (investorRewardAmount: number) => currencyFormatter.format(investorRewardAmount),
                sorter: (a: IReferral, b: IReferral) => a.investorRewardAmount > b.investorRewardAmount ? 1 : -1,
                title: 'Reward',
                width: '10%',
            },
            {
                filterDropdown: (params: any) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: string, referral: IReferral) => {
                    let match: boolean = false;

                    if (referral.referralInvestor) {
                        match = match || referral.referralInvestor.name.toLowerCase().includes(value.toLowerCase());
                    }

                    return match || referral.referralEmailAddress.toLowerCase().includes(value.toLowerCase());
                },
                render: (referral: IReferral) => <Link to={`/investors/${referral.referralInvestor.uuid}`}>{referral.referralInvestor.name}</Link>,
                title: 'Referral',
                width: '20%',
            },
            {
                dataIndex: 'referralRewardAmount',
                render: (referralRewardAmount: number) => currencyFormatter.format(referralRewardAmount),
                sorter: (a: IReferral, b: IReferral) => a.referralRewardAmount > b.referralRewardAmount ? 1 : -1,
                title: 'Reward',
                width: '10%',
            },
            {
                defaultFilteredValue: [true],
                filters: [{ text: 'Yes', value: true }, { text: 'No', value: false }],
                onFilter: (value: boolean, referral: IReferral) => value === referral.isEligible,
                render: (referral: IReferral) => referral.isEligible ? <CheckOutlined/> : '-',
                title: 'Eligible',
                width: '5%',
            },
            {
                filters: [{ text: 'Yes', value: true }, { text: 'No', value: false }],
                onFilter: (value: boolean, referral: IReferral) => value === referral.isProcessed,
                render: (referral: IReferral) => <ReferralActions referral={referral} />,
                title: 'Processed',
                width: '5%',
            },
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => dayjs(createdTime).format('Do MMMM YYYY HH:mm:ss'),
                sorter: (a: IReferral, b: IReferral) => dayjs(a.createdTime) > dayjs(b.createdTime)  ? 1 : -1,
                title: 'Created',
                width: '15%',
            },
        ];

        return (
            <Layout className='investors-referrals'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/investors'>Investors</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Referrals</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Referrals</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={referrals}
                            pagination={{ defaultPageSize: 50 }}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        investors: investorsSelector(state),
        referrals: investorReferralsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        investorsList: () => dispatch(investorsListAction()),
        referralsList: () => dispatch(investorReferralsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Referrals);
