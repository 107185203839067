import { all, call, put, takeEvery } from '@redux-saga/core/effects';
import { notification } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import IInvalidInvestorTransaction from '~Api/Investor/IInvalidInvestorTransaction';
import IInvestorAccountTransaction from '~Api/Investor/IInvestorAccountTransaction';
import { parseInvalidInvestorTransaction, parseInvestorAccountTransaction } from '~Api/Investor/parsers';
import IHistory from '~Api/WithdrawalAba/IHistory';
import IWithdrawalAba from '~Api/WithdrawalAba/IWithdrawalAba';
import { parseWithdrawalAba, paseWithdrawalAbaHistory } from '~Api/WithdrawalAba/parsers';
import {
    withdrawalAbaGetRequest,
    withdrawalAbaHistoriesListRequest,
    withdrawalAbaInvestorTransactionsListRequest,
    withdrawalAbaSendRequest,
    withdrawalAbasAddRequest,
    withdrawalAbasInvalidInvestorTransactionsListRequest,
    withdrawalAbasListRequest,
    withdrawalAbasUnallocatedInvestorTransactionsListRequest,
} from '~Api/WithdrawalAba/requests';
import { IFetchResponse } from '~utilities/fetch';
import { renderNotificationLoadingIcon } from '~utilities/utils';
import {
    IWithdrawalAbaGetAction,
    IWithdrawalAbaHistoriesListAction,
    IWithdrawalAbaInvestorTransactionsListAction,
    IWithdrawalAbaSendAction,
    IWithdrawalAbasAddAction,
    IWithdrawalAbasInvalidInvestorTransactionsListAction,
    IWithdrawalAbasListAction,
    IWithdrawalAbasUnallocatedInvestorTransactionsListAction,
    withdrawalAbaHistoriesSetAction,
    withdrawalAbaInvestorTransactionsSetAction,
    withdrawalAbaSetAction,
    withdrawalAbasInvalidInvestorTransactionsSetAction,
    withdrawalAbasSetAction,
    withdrawalAbasUnallocatedInvestorTransactionsListAction,
    withdrawalAbasUnallocatedInvestorTransactionsSetAction,
} from './actions';
import WithdrawalAbasActionsEnum from './ActionsEnum';

function* withdrawalAbaGet(action: IWithdrawalAbaGetAction): Iterator<any> {
    const withdrawalAbaResponse: IFetchResponse = yield call(withdrawalAbaGetRequest, action.withdrawalAbaUuid);
    const withdrawalAba: IWithdrawalAba = parseWithdrawalAba(withdrawalAbaResponse.body);
    yield put(withdrawalAbaSetAction(withdrawalAba));
}

function* withdrawalAbaSend(action: IWithdrawalAbaSendAction): Iterator<any> {
    yield call(withdrawalAbaSendRequest, action.withdrawalAbaUuid);
}

function* withdrawalAbasAdd(action: IWithdrawalAbasAddAction): Iterator<any> {
    const key: string = `withdrawalAbasAdd ${dayjs().format()}`;
    const message: string = 'Add Withdrawal ABA';

    notification.open({
        description: 'Adding Withdrawal ABA...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const withdrawalAbasAddResponse: IFetchResponse = yield call(withdrawalAbasAddRequest, action.accountType, action.investorTransactionsUuids);
    if (withdrawalAbasAddResponse.status === 422) {
        notification.error({
            description: `There was a problem adding the Withdrawal ABA: ${_.values(withdrawalAbasAddResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const withdrawalAba: IWithdrawalAba = parseWithdrawalAba(withdrawalAbasAddResponse.body);
        yield put(withdrawalAbaSetAction(withdrawalAba));

        notification.success({
            description: 'The Withdrawal ABA has been added.',
            duration: 4.5,
            key,
            message,
        });

        yield put(withdrawalAbasUnallocatedInvestorTransactionsListAction(action.accountType));
    }
}

function* withdrawalAbasList(action: IWithdrawalAbasListAction): Iterator<any> {
    const withdrawalAbasResponse: IFetchResponse = yield call(withdrawalAbasListRequest);
    const withdrawalAbas: IWithdrawalAba[] = yield Promise.all(withdrawalAbasResponse.body.map(parseWithdrawalAba));

    yield put(withdrawalAbasSetAction(withdrawalAbas));
}

function* withdrawalAbaHistoriesList(action: IWithdrawalAbaHistoriesListAction): Iterator<any> {
    const historiesResponse: IFetchResponse = yield call(withdrawalAbaHistoriesListRequest, action.withdrawalAbaUuid);
    const histories: IHistory[] = yield Promise.all(historiesResponse.body.map(paseWithdrawalAbaHistory));
    yield put(withdrawalAbaHistoriesSetAction(action.withdrawalAbaUuid, histories));
}

function* withdrawalAbaInvestorTransactionsList(action: IWithdrawalAbaInvestorTransactionsListAction): Iterator<any> {
    const investorTransactionsResponse: IFetchResponse = yield call(withdrawalAbaInvestorTransactionsListRequest, action.withdrawalAbaUuid);
    const investorAccountTransactions: IInvestorAccountTransaction[] = yield Promise.all(investorTransactionsResponse.body.map(parseInvestorAccountTransaction));
    yield put(withdrawalAbaInvestorTransactionsSetAction(action.withdrawalAbaUuid, investorAccountTransactions));
}

function* withdrawalAbasUnallocatedInvestorTransactionsList(action: IWithdrawalAbasUnallocatedInvestorTransactionsListAction): Iterator<any> {
    const unallocatedInvestorTransactionsResponse: IFetchResponse = yield call(withdrawalAbasUnallocatedInvestorTransactionsListRequest, action.accountType);
    const unallocatedInvestorTransactions: IInvestorAccountTransaction[] = yield Promise.all(unallocatedInvestorTransactionsResponse.body.map(parseInvestorAccountTransaction));

    yield put(withdrawalAbasUnallocatedInvestorTransactionsSetAction(action.accountType, unallocatedInvestorTransactions));
}

function* withdrawalAbasInvalidInvestorTransactionsList(action: IWithdrawalAbasInvalidInvestorTransactionsListAction): Iterator<any> {
    const invalidInvestorTransactionsResponse: IFetchResponse = yield call(withdrawalAbasInvalidInvestorTransactionsListRequest, action.accountType);
    const invalidInvestorTransactions: IInvalidInvestorTransaction[] = yield Promise.all(invalidInvestorTransactionsResponse.body.map(parseInvalidInvestorTransaction));

    yield put(withdrawalAbasInvalidInvestorTransactionsSetAction(action.accountType, invalidInvestorTransactions));
}

export function* WithdrawalAbasSagas(): any {
    yield all([
        takeEvery(WithdrawalAbasActionsEnum.WithdrawalAbaGet, withdrawalAbaGet),
        takeEvery(WithdrawalAbasActionsEnum.WithdrawalAbaSend, withdrawalAbaSend),

        takeEvery(WithdrawalAbasActionsEnum.WithdrawalAbasAdd, withdrawalAbasAdd),
        takeEvery(WithdrawalAbasActionsEnum.WithdrawalAbasList, withdrawalAbasList),

        takeEvery(WithdrawalAbasActionsEnum.WithdrawalAbasInvalidInvestorTransactionsList, withdrawalAbasInvalidInvestorTransactionsList),
        takeEvery(WithdrawalAbasActionsEnum.WithdrawalAbasUnallocatedInvestorTransactionsList, withdrawalAbasUnallocatedInvestorTransactionsList),
        takeEvery(WithdrawalAbasActionsEnum.WithdrawalAbaInvestorTransactionsList, withdrawalAbaInvestorTransactionsList),

        takeEvery(WithdrawalAbasActionsEnum.WithdrawalAbaHistoriesList, withdrawalAbaHistoriesList),
    ]);
}
