import { G2, Pie } from '@ant-design/plots';
import { IGroup } from '@antv/g2';
import { PieOptions } from '@antv/g2plot';
import { IG } from '@antv/g2/lib/dependents';
import { Datum, MappingDatum } from '@antv/g2/lib/interface.d';
import { Card, Col, Radio, RadioChangeEvent, Row, Space, Spin, Statistic, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { loanPurposeLabels } from '~Api/Application/loanPurposeLabels';
import { riskBandLabels } from '~Api/Deal/riskBandLabels';
import zoneTypeLabels from '~Api/Deal/ZoneTypeLabels';
import IWarehouseActiveReportLoan from '~Api/Warehouse/IWarehouseActiveReportLoan';
import { IGlobalState } from '~reducer';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';
import { IDictionary } from '~utilities/IDictionary';
import { CountOrDollarEnum, IPieData} from '~utilities/reportUtilities';
import { warehouseActiveReportListAction } from '~Warehouses/actions';
import { warehouseActiveReportLoansSelector } from '~Warehouses/selectors';
import Layout from './Layout';

interface IMatch {
    warehouseUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    warehouseLoans: IDictionary<IWarehouseActiveReportLoan>;
}

interface IPropsDispatch {
    warehouseActiveReportList: () => void;
}

interface IState {
    countOrDollar: CountOrDollarEnum;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class ActiveReport extends React.Component<Props> {
    public state: IState = {
        countOrDollar: CountOrDollarEnum.Dollar,
    };

    constructor(props: Props) {
        super(props);
        this.onChangeCountValueSwitcher = this.onChangeCountValueSwitcher.bind(this);
    }

    public componentDidMount(): void {
        this.props.warehouseActiveReportList();
    }

    public render(): JSX.Element {
        const { match, warehouseLoans } = this.props;
        const { countOrDollar } = this.state;

        const countValueSwitcher: JSX.Element = (
            <Radio.Group
                value={countOrDollar}
                onChange={this.onChangeCountValueSwitcher}
                disabled={!warehouseLoans}
            >
                <Radio.Button value={CountOrDollarEnum.Dollar}>Value</Radio.Button>
                <Radio.Button value={CountOrDollarEnum.Count}>Count</Radio.Button>
            </Radio.Group>
        );

        if (!warehouseLoans) {
            return (
                <Layout uuid={match.params.warehouseUuid} section='warehouse-active-report'>
                    <Space className='actions'>
                        {countValueSwitcher}
                    </Space>
                    <Typography.Title level={2}>Active Report</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const loanPurposeData: IDictionary<IPieData> = {};
        const lvrData: IDictionary<IPieData> = {};
        const termData: IDictionary<IPieData> = {};
        const interestRateData: IDictionary<IPieData> = {};
        const propertyLocationData: IDictionary<IPieData> = {};
        const propertyTypeData: IDictionary<IPieData> = {};
        const propertyCategoryData: IDictionary<IPieData> = {};
        const extensionData: IDictionary<IPieData> = {};
        let activeWarehouseLoansCount: number = 0;
        let activeWarehouseLoansValue: number = 0;

        _.forEach(warehouseLoans, (warehouseLoan: IWarehouseActiveReportLoan) => {
            const loanPurposeType: string = warehouseLoan.loanPurpose === null ? 'Unknown' : loanPurposeLabels[warehouseLoan.loanPurpose];
            if (!loanPurposeData[warehouseLoan.loanPurpose]) {
                loanPurposeData[warehouseLoan.loanPurpose] = {
                    type: loanPurposeType,
                    value: 0,
                };
            }
            loanPurposeData[warehouseLoan.loanPurpose].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : warehouseLoan.amount);

            const lvr: number = warehouseLoan.lvr === null ? 0 : warehouseLoan.lvr;
            const lvrRangeStart: number = Math.floor(lvr / 10) * 10;
            const lvrRangeEnd: number = lvrRangeStart + 10;
            const lvrRange: string = lvrRangeStart === 0 ? `0% - ${lvrRangeEnd}%` : `${lvrRangeStart}% - ${lvrRangeEnd}%`;
            if (!lvrData[lvrRange]) {
                lvrData[lvrRange] = {
                    type: lvrRange,
                    value: 0,
                };
            }
            lvrData[lvrRange].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : warehouseLoan.amount);

            if (!termData[warehouseLoan.termMonths]) {
                termData[warehouseLoan.termMonths] = {
                    type: `${warehouseLoan.termMonths} months`,
                    value: 0,
                };
            }
            termData[warehouseLoan.termMonths].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : warehouseLoan.amount);

            const interestRate: number = warehouseLoan.interestRate === null ? 0 : warehouseLoan.interestRate;
            const interestRateRangeStart: number = Math.floor(interestRate / 0.5) * 0.5;
            const interestRateRangeEnd: number = interestRateRangeStart + 0.5;
            const interestRateRange: string = interestRateRangeStart === 0 ? `0% - ${interestRateRangeEnd}%` : `${interestRateRangeStart}% - ${interestRateRangeEnd}%`;
            if (!interestRateData[interestRateRange]) {
                interestRateData[interestRateRange] = {
                    type: interestRateRange,
                    value: 0,
                };
            }
            interestRateData[interestRateRange].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : warehouseLoan.amount);

            const propertyLocationType: string = warehouseLoan.propertyLocation === null ? 'Others' : warehouseLoan.propertyLocation;
            if (!propertyLocationData[warehouseLoan.propertyLocation]) {
                propertyLocationData[warehouseLoan.propertyLocation] = {
                    type: propertyLocationType,
                    value: 0,
                };
            }
            propertyLocationData[warehouseLoan.propertyLocation].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : warehouseLoan.amount);

            const propertyType: string = warehouseLoan.propertyType === null ? 'Others' : zoneTypeLabels[warehouseLoan.propertyType];
            if (!propertyTypeData[warehouseLoan.propertyType]) {
                propertyTypeData[warehouseLoan.propertyType] = {
                    type: propertyType,
                    value: 0,
                };
            }
            propertyTypeData[warehouseLoan.propertyType].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : warehouseLoan.amount);

            if (!propertyCategoryData[warehouseLoan.propertyCategory]) {
                propertyCategoryData[warehouseLoan.propertyCategory] = {
                    type: riskBandLabels[warehouseLoan.propertyCategory],
                    value: 0,
                };
            }
            propertyCategoryData[warehouseLoan.propertyCategory].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : warehouseLoan.amount);

            const extensionType: string = warehouseLoan.extensionNumber === null ? 'New' : `Extension ${warehouseLoan.extensionNumber}`;
            if (!extensionData[warehouseLoan.extensionNumber]) {
                extensionData[warehouseLoan.extensionNumber] = {
                    type: extensionType,
                    value: 0,
                };
            }
            extensionData[warehouseLoan.extensionNumber].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : warehouseLoan.amount);

            activeWarehouseLoansCount++;
            activeWarehouseLoansValue += warehouseLoan.amount;
        });

        return (
            <Layout uuid={match.params.warehouseUuid} section='warehouse-active-report'>
                <Space className='actions'>
                    {countValueSwitcher}
                </Space>
                <Typography.Title level={2}>Active Report</Typography.Title>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} title='Active Warehouse Loan Count' className='active-warehouse-loans-count'>
                            <Statistic
                                value={activeWarehouseLoansCount}
                                precision={0}
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} title='Active Warehouse Loan Value' className='active-warehouse-loans-value'>
                            <Statistic
                                value={currencyFormatter.format(activeWarehouseLoansValue)}
                                precision={0}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} title='Loan Purpose' className='pie-chart-left'>
                            {this.renderPieChart(_.values(_.sortBy(loanPurposeData, 'type')))}
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} title='LVR' className='pie-chart-right'>
                            {this.renderPieChart(_.values(_.sortBy(lvrData, 'type')))}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} title='Term' className='pie-chart-left'>
                            {this.renderPieChart(_.values(_.sortBy(termData, (term: IPieData) => parseInt(term.type))))}
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} title='Interest Rate' className='pie-chart-right'>
                            {this.renderPieChart(_.values(_.sortBy(interestRateData, (interestRate: IPieData) => parseInt(interestRate.type))))}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} title='Property Type' className='pie-chart-left'>
                            {this.renderPieChart(_.values(_.sortBy(propertyTypeData, 'type')))}
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} title='Property Location' className='pie-chart-right'>
                            {this.renderPieChart(_.values(_.sortBy(propertyLocationData, 'type')))}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} title='Property Category' className='pie-chart-left'>
                            {this.renderPieChart(_.values(_.sortBy(propertyCategoryData, 'type')))}
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} title='Extension' className='pie-chart-right'>
                            {this.renderPieChart(_.values(_.sortBy(extensionData, 'type')))}
                        </Card>
                    </Col>
                </Row>
            </Layout>
        );
    }

    private onChangeCountValueSwitcher(e: RadioChangeEvent): void {
        this.setState({
            countOrDollar: e.target.value,
        });
    }

    private renderPieChart(data: IPieData[]): JSX.Element {
        const G: IG = G2.getEngine('canvas');
        const labelFormatter: (data: Datum, mappingData: MappingDatum) => IGroup = (data: Datum, mappingData: MappingDatum) => {
            const group: IGroup = new G.Group({});
            group.addShape({
                attrs: {
                    fill: mappingData.color,
                    height: 50,
                    r: 5,
                    width: 40,
                    x: 0,
                    y: 0,
                },
                type: 'circle',
            });
            group.addShape({
                attrs: {
                    fill: mappingData.color,
                    text: `${data.type}`,
                    x: 10,
                    y: 8,
                },
                type: 'text',
            });
            group.addShape({
                attrs: {
                    fill: 'rgba(0, 0, 0, 0.65)',
                    fontWeight: 700,
                    text: (this.state.countOrDollar === CountOrDollarEnum.Count) 
                        ? `${data.value} - ${percentageFormatter.format(data.percent)}` 
                        : `${currencyFormatter.format(data.value)} - ${percentageFormatter.format(data.percent)}`,
                    x: 0,
                    y: 25,
                },
                type: 'text',
            });
            return group;
        };

        const config: PieOptions = {
            angleField: 'value',
            appendPadding: 5,
            colorField: 'type',
            data,
            interactions: [
                {
                    type: 'element-selected',
                },
                {
                    type: 'element-active',
                },
            ],
            label: {
                formatter: labelFormatter,
                labelHeight: 40,
                type: 'spider',
            },
            legend: {
                flipPage: false,
                position: 'bottom',
            },
            radius: 0.6,
            tooltip: {
                formatter: (data: Datum) => {
                    return {
                        name: data.type,
                        value: (this.state.countOrDollar === CountOrDollarEnum.Count) ? data.value : `${currencyFormatter.format(data.value)}`,
                    };
                },
            },
        };

        return <Pie {...config} />;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        warehouseLoans: warehouseActiveReportLoansSelector(state, ownProps.match.params.warehouseUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        warehouseActiveReportList: () => dispatch(warehouseActiveReportListAction(ownProps.match.params.warehouseUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ActiveReport);
