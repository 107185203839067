enum ListSortFieldEnum {
    ApprovalStatus = 'APPROVAL_STATUS',
    CreatedTime = 'CREATED_TIME',
    Name = 'NAME',
    Code = 'CODE',
    LastQuoteTime = 'LAST_QUOTE_TIME',
    LoanAmount = 'LOAN_AMOUNT',
}

export default ListSortFieldEnum;
