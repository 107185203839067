import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '~Layout';
import List from './List';
import Adviser from './Adviser';

export default function Router(): ReactElement {
    return (
        <Layout topSection='accounts'>
            <Switch>
                <Route exact={true} path='/advisers' component={List} />
                <Route path='/advisers/:adviserUuid' component={Adviser} />
            </Switch>
        </Layout>
    );
}
