import { Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import { investmentInterestRateAction } from '~Investments/actions';
import { IDictionary } from '~utilities/IDictionary';

interface IDefaultedValues {
    interestRate: number;
}

interface IState {
    dirtyFields: IDictionary<boolean>;
    errors: {
        interestRate?: string;
    };
    interestRate: number;
}

interface IProps {
    investment: IInvestment;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsDispatch {
    interestRate: (interestRate: number) => void;
}

type Props = IProps & IPropsDispatch;

class InterestRateModal extends React.Component<Props, IState> {
    public state: IState = {
        dirtyFields: {},
        errors: {},
        interestRate: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeInterestRate = this.onChangeInterestRate.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
        this.validateInterestRate = this.validateInterestRate.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { errors } = this.state;

        const { interestRate } = this.getDefaultedValues();

        return (
            <Modal
                destroyOnClose={true}
                okText='Update'
                okType='danger'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Change Interest Rate'
                wrapClassName='investment-interest-rate-modal'
            >
                <Form.Item label='Interest Rate' className='interest-rate' help={errors.interestRate} validateStatus={errors.interestRate && 'error'}>
                    <Input addonAfter='%' min={0} step={0.1} type='number' value={interestRate} onChange={this.onChangeInterestRate} onBlur={this.validateInterestRate} />
                </Form.Item>
            </Modal>
        );
    }

    private getDefaultedValues(): IDefaultedValues {
        const { investment } = this.props;
        const { dirtyFields, interestRate } = this.state;

        return {
            interestRate: dirtyFields.interestRate ? interestRate : investment.interestRate !== 0 ? investment.interestRate : null,
        };
    }

    private onChangeInterestRate(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                interestRate: true,
            },
            interestRate: event.target.value ? event.target.valueAsNumber : null,
        });
    }

    private onClickOk(): void {
        const { interestRate } = this.state;

        let valid: boolean = true;

        valid = this.validateInterestRate() && valid;

        if (!valid) {
            return;
        }

        this.props.onCancel();

        this.props.interestRate(interestRate);
    }

    private validateInterestRate(): boolean {
        const { interestRate, errors } = this.state;

        let error: string;

        if (!interestRate && interestRate !== 0) {
            error = 'Please enter an interest rate';
        } else if (interestRate <= 0) {
            error = 'Interest rate must be greater than 0';
        }

        this.setState({
            errors: {
                ...errors,
                interestRate: error,
            },
        });

        return !error;
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        interestRate: (investmentRate: number) => dispatch(investmentInterestRateAction(ownProps.investment.uuid, investmentRate)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(InterestRateModal);
