import React from 'react';
import {
    Route,
    Switch,
} from 'react-router-dom';
import History from './History';
import Transactions from './Transactions';

export default function router(): JSX.Element {
    return (
        <Switch>
            <Route exact={true} path='/investors/withdrawal-abas/:withdrawalAbaUuid/history' component={History} />
            <Route exact={true} path='/investors/withdrawal-abas/:withdrawalAbaUuid/transactions' component={Transactions} />
        </Switch>
    );
}
