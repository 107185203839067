import INote from './INote';
import IReferralPartner from './IReferralPartner';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformReferralPartner(referralPartner: IReferralPartner): any {
    return {
        abn: referralPartner.abn,
        acl: referralPartner.acl,
        aggregatorUuid: referralPartner.aggregatorUuid,
        approvalStatus: referralPartner.approvalStatus,
        banned: referralPartner.banned,
        chargesFee: referralPartner.chargesFee,
        companyName: referralPartner.companyName,
        conductsBusinessInPerson: referralPartner.conductsBusinessInPerson,
        disclosesBenefits: referralPartner.disclosesBenefits,
        email: referralPartner.email,
        firstName: referralPartner.firstName,
        groupName: referralPartner.groupName,
        includesCustomerInfo: referralPartner.includesCustomerInfo,
        lastName: referralPartner.lastName,
        linkedInUrl: referralPartner.linkedInUrl,
        nccp: referralPartner.nccp,
        phone: referralPartner.phone,
        postcode: referralPartner.postcode,
        refersClient: referralPartner.refersClient,
        state: referralPartner.state,
        streetAddress: referralPartner.streetAddress,
        suburb: referralPartner.suburb,
        type: referralPartner.type,
        uuid: referralPartner.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformReferralPartnerNote(note: INote): any {
    return {
        note: note.note,
    };
}
