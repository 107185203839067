enum LoanStatusEnum {
    ActiveBadStanding = 'ACTIVE_BAD_STANDING',
    ActiveGoodStanding = 'ACTIVE_GOOD_STANDING',
    ActiveMatured = 'ACTIVE_MATURED',
    Approved = 'APPROVED',
    Cancelled = 'CANCELLED',
    ClosedObligationsMet = 'CLOSED_OBLIGATIONS_MET',
    ClosedRefinanced = 'CLOSED_REFINANCED',
    ClosedWrittenOff = 'CLOSED_WRITTEN_OFF',
    PartialApplication = 'PARTIAL_APPLICATION',
    PendingApproval = 'PENDING_APPROVAL',
}

export default LoanStatusEnum;
