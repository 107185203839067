enum BrokersActionsEnum {
    BrokersAdd = 'BROKERS/ADD',
    BrokersList = 'BROKERS/LIST',
    BrokersSet = 'BROKERS/SET',

    BrokersSearch = 'BROKERS/SEARCH',
    BrokersSearchResultsSet = 'BROKERS/SEARCH/RESULTS/SET',
    BrokersSearchResultsClear = 'BROKERS/SEARCH/RESULTS/CLEAR',

    BrokerApprove = 'BROKER/APPROVE',
    BrokerAssignBdm = 'BROKER/ASSIGN_BDM',
    BrokerContactDate = 'BROKER/CONTACT_DATE',
    BrokerGet = 'BROKER/GET',
    BrokerRecordContact = 'BROKER/RECORD_CONTACT',
    BrokerReject = 'BROKER/REJECT',
    BrokerResetApprovalStatus = 'BROKER/RESET_APPROVAL_STATUS',
    BrokerSend = 'BROKER/SEND',
    BrokerSet = 'BROKER/SET',
    BrokerUnassignBdm = 'BROKER/UNASSIGN_BDM',
    BrokerValueSet = 'BROKER/VALUE/SET',

    BrokerApplicationsList = 'BROKER/APPLICATIONS/LIST',
    BrokerApplicationsSet = 'BROKER/APPLICATIONS/SET',

    BrokerDocumentsList = 'BROKER/DOCUMENTS/LIST',
    BrokerDocumentsSet = 'BROKER/DOCUMENTS/SET',

    BrokerDocumentSet = 'BROKER/DOCUMENT/SET',
    BrokerDocumentUpload = 'BROKER/DOCUMENT/UPLOAD',

    BrokerHistoriesList = 'BROKER/HISTORIES/LIST',
    BrokerHistoriesSet = 'BROKER/HISTORIES/SET',

    BrokerDealsList = 'BROKER/DEALS/LIST',
    BrokerDealsSet = 'BROKER/DEALS/SET',

    BrokerNotesAdd = 'BROKER/NOTES/ADD',
    BrokerNotesList = 'BROKER/NOTES/LIST',
    BrokerNotesSet = 'BROKER/NOTES/SET',

    BrokerNoteRemove = 'BROKER/NOTE/REMOVE',
    BrokerNoteSet = 'BROKER/NOTE/SET',
}

export default BrokersActionsEnum;
