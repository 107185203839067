import _ from 'lodash';
import { AnyAction } from 'redux';
import IApplication from '~Api/Application/IApplication';
import ApprovalStatusEnum from '~Api/Broker/ApprovalStatusEnum';
import IDeal from '~Api/Deal/IDeal';
import { IDictionary } from '~utilities/IDictionary';
import {
    IBrokerApplicationsSetAction,
    IBrokerApproveAction,
    IBrokerContactDateAction,
    IBrokerDealsSetAction,
    IBrokerDocumentSetAction,
    IBrokerDocumentsSetAction,
    IBrokerHistoriesSetAction,
    IBrokerNoteRemoveAction,
    IBrokerNoteSetAction,
    IBrokerNotesSetAction,
    IBrokerRejectAction,
    IBrokerSetAction,
    IBrokerValueSetAction,
    IBrokersSearchResultsSetAction,
    IBrokersSetAction,
} from './actions';
import BrokersActionsEnum from './ActionsEnum';
import IBroker from '~Api/Broker/IBroker';
import IDocument from '~Api/Broker/IDocument';
import IHistory from '~Api/Broker/IHistory';
import INote from '~Api/Broker/INote';
import LeadsActionsEnum from '~Leads/ActionsEnum';
import {
    ILeadBrokerSearchResultsSetAction,
    ILeadSetAction,
    ILeadsBoardSetAction,
    ILeadsSearchResultSetAction,
    ILeadsSetAction,
} from '~Leads/actions';
import ApplicationsActionsEnum from '~Applications/ActionsEnum';
import {
    IApplicationSetAction,
    IApplicationsDashboardSetAction,
    IApplicationsOutstandingBrokerCommissionsSetAction,
    IApplicationsSearchResultsSetAction,
    IApplicationsSetAction,
    IApplicationsSettlementForecastSetAction,
} from '~Applications/actions';
import ReferralPartnersActionsEnum from '~ReferralPartners/ActionsEnum';
import {
    IReferralPartnerApplicationsSetAction,
    IReferralPartnerDealsSetAction,
} from '~ReferralPartners/actions';
import ILoan from '~Api/Loan/ILoan';
import {
    ILoanSetAction,
    ILoansDrawdownsSetAction,
    ILoansPaginatedSetAction,
    ILoansSearchResultsSetAction,
    ILoansSetAction,
} from '~Loans/actions';
import LoansActionsEnum from '~Loans/ActionsEnum';
import WarehousesActionsEnum from '~Warehouses/ActionsEnum';
import { IWarehouseEligibleLoansSetAction } from '~Warehouses/actions';
import IWarehouseEligibleLoan from '~Api/Warehouse/IWarehouseEligibleLoan';

export interface IBrokersState {
    brokerApplicationUuids: IDictionary<string[]>;
    brokerDealUuids: IDictionary<string[]>;
    brokers: IDictionary<IBroker>;
    brokersListed: boolean;
    brokersSearchResultUuids: string[];
    documents: IDictionary<IDictionary<IDocument>>;
    histories: IDictionary<IDictionary<IHistory>>;
    notes: IDictionary<IDictionary<INote>>;
}

const initialData: IBrokersState = {
    brokerApplicationUuids: {},
    brokerDealUuids: {},
    brokers: {},
    brokersListed: false,
    brokersSearchResultUuids: null,
    documents: {},
    histories: {},
    notes: {},
};

function statifyApplication(state: IBrokersState, application: IApplication): IBrokersState {
    return {
        ...state,
        ...statifyDeal(state, application.deal),
    };
}

function statifyApplications(state: IBrokersState, applications: IApplication[]): IBrokersState {
    let clonedState: IBrokersState = { ...state };

    _.each(applications, (application: IApplication) => {
        clonedState = statifyApplication(clonedState, application);
    });

    return clonedState;
}

function statifyDeal(state: IBrokersState, deal: IDeal): IBrokersState {
    if (!deal.broker) {
        return {
            ...state,
        };
    }

    return {
        ...state,
        brokers: {
            ...state.brokers,
            [deal.broker.uuid]: deal.broker,
        },
    };
}

function statifyDeals(state: IBrokersState, deals: IDeal[]): IBrokersState {
    let clonedState: IBrokersState = { ...state };

    _.each(deals, (deal: IDeal) => {
        clonedState = statifyDeal(clonedState, deal);
    });

    return clonedState;
}

function statifyLoan(state: IBrokersState, loan: ILoan): IBrokersState {
    let clonedState: IBrokersState = { ...state };

    if (loan.extensionApplication) {
        clonedState = statifyApplication(clonedState, loan.extensionApplication);
    }

    if (loan.application) {
        clonedState = statifyApplication(clonedState, loan.application);
    }

    return clonedState;
}

function statifyLoans(state: IBrokersState, loans: ILoan[]): IBrokersState {
    let clonedState: IBrokersState = { ...state };

    _.each(loans, (loan: ILoan) => {
        clonedState = statifyLoan(clonedState, loan);
    });

    return clonedState;
}

export function brokersReducer(state: IBrokersState = initialData, action: AnyAction): IBrokersState {
    switch (action.type) {
        case BrokersActionsEnum.BrokersSet: {
            const typedAction: IBrokersSetAction = action as IBrokersSetAction;

            return {
                ...state,
                brokers: _.keyBy(typedAction.brokers, 'uuid'),
                brokersListed: true,
            };
        }

        case BrokersActionsEnum.BrokersSearchResultsSet: {
            const typedAction: IBrokersSearchResultsSetAction = action as IBrokersSearchResultsSetAction;

            const brokers: IDictionary<IBroker> = { ...state.brokers };

            const brokersSearchResultUuids: string[] = [];

            typedAction.brokers.forEach((broker: IBroker) => {
                brokers[broker.uuid] = broker;
                brokersSearchResultUuids.push(broker.uuid);
            });

            return {
                ...state,
                brokers,
                brokersSearchResultUuids,
            };
        }

        case BrokersActionsEnum.BrokersSearchResultsClear: {
            return {
                ...state,
                brokersSearchResultUuids: [],
            };
        }

        case BrokersActionsEnum.BrokerApplicationsSet: {
            const typedAction: IBrokerApplicationsSetAction = action as IBrokerApplicationsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
                brokerApplicationUuids: {
                    ...state.brokerApplicationUuids,
                    [typedAction.brokerUuid]: _.map(typedAction.applications, (application: IApplication) => application.uuid),
                },
            };
        }

        case BrokersActionsEnum.BrokerApprove: {
            const typedAction: IBrokerApproveAction = action as IBrokerApproveAction;

            return {
                ...state,
                brokers: {
                    ...state.brokers,
                    [typedAction.brokerUuid]: {
                        ...state.brokers[typedAction.brokerUuid],
                        approvalStatus: ApprovalStatusEnum.Approved,
                    },
                },
            };
        }

        case BrokersActionsEnum.BrokerContactDate: {
            const typedAction: IBrokerContactDateAction = action as IBrokerContactDateAction;

            return {
                ...state,
                brokers: {
                    ...state.brokers,
                    [typedAction.brokerUuid]: {
                        ...state.brokers[typedAction.brokerUuid],
                        contactDateNext: typedAction.date,
                    },
                },
            };
        }

        case BrokersActionsEnum.BrokerReject: {
            const typedAction: IBrokerRejectAction = action as IBrokerRejectAction;

            return {
                ...state,
                brokers: {
                    ...state.brokers,
                    [typedAction.brokerUuid]: {
                        ...state.brokers[typedAction.brokerUuid],
                        approvalStatus: ApprovalStatusEnum.Rejected,
                    },
                },
            };
        }

        case BrokersActionsEnum.BrokerDocumentsSet: {
            const typedAction: IBrokerDocumentsSetAction = action as IBrokerDocumentsSetAction;

            return {
                ...state,
                documents: {
                    ...state.documents,
                    [typedAction.brokerUuid]: _.keyBy(typedAction.documents, 'type'),
                },
            };
        }

        case BrokersActionsEnum.BrokerDocumentSet: {
            const typedAction: IBrokerDocumentSetAction = action as IBrokerDocumentSetAction;

            return {
                ...state,
                documents: {
                    ...state.documents,
                    [typedAction.brokerUuid]: {
                        ...(state.documents[typedAction.brokerUuid] || {}),
                        [typedAction.document.type]: typedAction.document,
                    },
                },
            };
        }

        case BrokersActionsEnum.BrokerHistoriesSet: {
            const typedAction: IBrokerHistoriesSetAction = action as IBrokerHistoriesSetAction;

            return {
                ...state,
                histories: {
                    ...state.histories,
                    [typedAction.brokerUuid]: _.keyBy(typedAction.histories, 'uuid'),
                },
            };
        }

        case BrokersActionsEnum.BrokerDealsSet: {
            const typedAction: IBrokerDealsSetAction = action as IBrokerDealsSetAction;

            return {
                ...state,
                ...statifyDeals(state, typedAction.deals),
                brokerDealUuids: {
                    ...state.brokerDealUuids,
                    [typedAction.brokerUuid]: _.map(typedAction.deals, (deal: IDeal) => deal.uuid),
                },
            };
        }

        case BrokersActionsEnum.BrokerNotesSet: {
            const typedAction: IBrokerNotesSetAction = action as IBrokerNotesSetAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.brokerUuid]: _.keyBy(typedAction.notes, 'uuid'),
                },
            };
        }

        case BrokersActionsEnum.BrokerNoteRemove: {
            const typedAction: IBrokerNoteRemoveAction = action as IBrokerNoteRemoveAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.brokerUuid]: _.omit(state.notes[typedAction.brokerUuid] || {}, typedAction.noteUuid),
                },
            };
        }

        case BrokersActionsEnum.BrokerNoteSet: {
            const typedAction: IBrokerNoteSetAction = action as IBrokerNoteSetAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.brokerUuid]: {
                        ...(state.notes[typedAction.brokerUuid] || {}),
                        [typedAction.note.uuid || 'new']: typedAction.note,
                    },
                },
            };
        }

        case BrokersActionsEnum.BrokerSet: {
            const typedAction: IBrokerSetAction = action as IBrokerSetAction;

            return {
                ...state,
                brokers: {
                    ...state.brokers,
                    [typedAction.broker.uuid]: typedAction.broker,
                },
            };
        }

        case BrokersActionsEnum.BrokerValueSet: {
            const typedAction: IBrokerValueSetAction = action as IBrokerValueSetAction;

            return {
                ...state,
                brokers: {
                    ...state.brokers,
                    [typedAction.brokerUuid]: {
                        ...state.brokers[typedAction.brokerUuid],
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        case LeadsActionsEnum.LeadBrokerSearchResultsSet: {
            const typedAction: ILeadBrokerSearchResultsSetAction = action as ILeadBrokerSearchResultsSetAction;

            return {
                ...state,
                brokers: {
                    ...state.brokers,
                    ..._.keyBy(typedAction.brokers, 'uuid'),
                },
            };
        }

        case ApplicationsActionsEnum.ApplicationsDashboardSet: {
            const typedAction: IApplicationsDashboardSetAction = action as IApplicationsDashboardSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case ApplicationsActionsEnum.ApplicationsSearchResultsSet: {
            const typedAction: IApplicationsSearchResultsSetAction = action as IApplicationsSearchResultsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case ApplicationsActionsEnum.ApplicationsSet: {
            const typedAction: IApplicationsSetAction = action as IApplicationsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case ApplicationsActionsEnum.ApplicationsSettlementForecastSet: {
            const typedAction: IApplicationsSettlementForecastSetAction = action as IApplicationsSettlementForecastSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case ApplicationsActionsEnum.ApplicationSet: {
            const typedAction: IApplicationSetAction = action as IApplicationSetAction;

            return {
                ...state,
                ...statifyApplication(state, typedAction.application),
            };
        }

        case ApplicationsActionsEnum.ApplicationsOutstandingBrokerCommissionsSet: {
            const typedAction: IApplicationsOutstandingBrokerCommissionsSetAction = action as IApplicationsOutstandingBrokerCommissionsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case LeadsActionsEnum.LeadsBoardSet: {
            const typedAction: ILeadsBoardSetAction = action as ILeadsBoardSetAction;

            return {
                ...state,
                ...statifyDeals(state, typedAction.deals),
            };
        }

        case LeadsActionsEnum.LeadsSet: {
            const typedAction: ILeadsSetAction = action as ILeadsSetAction;

            return {
                ...state,
                ...statifyDeals(state, typedAction.deals),
            };
        }

        case LeadsActionsEnum.LeadsSearchResultsSet: {
            const typedAction: ILeadsSearchResultSetAction = action as ILeadsSearchResultSetAction;

            return {
                ...state,
                ...statifyDeals(state, typedAction.deals),
            };
        }

        case LeadsActionsEnum.LeadSet: {
            const typedAction: ILeadSetAction = action as ILeadSetAction;

            return {
                ...state,
                ...statifyDeal(state, typedAction.deal),
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerDealsSet: {
            const typedAction: IReferralPartnerDealsSetAction = action as IReferralPartnerDealsSetAction;

            return {
                ...state,
                ...statifyDeals(state, typedAction.deals),
            };
        }

        case ReferralPartnersActionsEnum.ReferralPartnerApplicationsSet: {
            const typedAction: IReferralPartnerApplicationsSetAction = action as IReferralPartnerApplicationsSetAction;

            return {
                ...state,
                ...statifyApplications(state, typedAction.applications),
            };
        }

        case LoansActionsEnum.LoanSet: {
            const typedAction: ILoanSetAction = action as ILoanSetAction;

            return {
                ...state,
                ...statifyLoan(state, typedAction.loan),
            };
        }

        case LoansActionsEnum.LoansSet: {
            const typedAction: ILoansSetAction = action as ILoansSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        case LoansActionsEnum.LoansDrawdownsSet: {
            const typedAction: ILoansDrawdownsSetAction = action as ILoansDrawdownsSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        case LoansActionsEnum.LoansPaginatedSet: {
            const typedAction: ILoansPaginatedSetAction = action as ILoansPaginatedSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        case LoansActionsEnum.LoansSearchResultsSet: {
            const typedAction: ILoansSearchResultsSetAction = action as ILoansSearchResultsSetAction;

            return {
                ...state,
                ...statifyLoans(state, typedAction.loans),
            };
        }

        case WarehousesActionsEnum.WarehouseEligibleLoansSet: {
            const typedAction: IWarehouseEligibleLoansSetAction = action as IWarehouseEligibleLoansSetAction;

            return {
                ...state,
                ...statifyLoans(state, _.map(typedAction.eligibleLoans, (eligibleLoan: IWarehouseEligibleLoan) => eligibleLoan.loan)),
            };
        }

        default:
            return state;
    }
}
