import _ from 'lodash';
import ConditionDocumentApprovalStatusEnum from '~Api/Application/ConditionDocumentApprovalStatusEnum';
import IApplication from '~Api/Application/IApplication';
import IApplicationDisbursement from '~Api/Application/IApplicationDisbursement';
import IApplicationFee from '~Api/Application/IApplicationFee';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IApplicationPropertyValuer from '~Api/Application/IApplicationPropertyValuer';
import IApplicationWarehouse from '~Api/Application/IApplicationWarehouse';
import IBorrower from '~Api/Application/IBorrower';
import ICondition from '~Api/Application/ICondition';
import IConditional from '~Api/Application/IConditional';
import IConditionalCondition from '~Api/Application/IConditionalCondition';
import IConditionDocument from '~Api/Application/IConditionDocument';
import IFormal from '~Api/Application/IFormal';
import IHistory from '~Api/Application/IHistory';
import INote from '~Api/Application/INote';
import IRfiItem from '~Api/Application/IRfiItem';
import ISettlementReportApplication from '~Api/Application/ISettlementReportApplication';
import { IStandardConditionGroup } from '~Api/Application/IStandardConditionGroup';
import IProperty from '~Api/Deal/IProperty';
import ILoan from '~Api/Loan/ILoan';
import ILoanPayoutFigure from '~Api/Loan/ILoanPayoutFigure';
import {
    dealBorrowerSelector,
    dealDocumentSelector,
    dealPropertiesSelector,
    dealPropertySelector,
} from '~Deals/selectors';
import { leadSelector } from '~Leads/selectors';
import {
    loanPayoutFigureSelector,
    loanSelector,
} from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { PeriodRangeEnum } from '~utilities/reportUtilities';
import { keyMap } from '~utilities/utils';

function hydrateApplication(state: IGlobalState, application: IApplication, nestProperties: boolean = true): IApplication {
    const cloneApplication: IApplication = { ...application };
    cloneApplication.borrowers = applicationBorrowersSelector(state, application.uuid);
    cloneApplication.deal = leadSelector(state, application.dealUuid);
    if (nestProperties) {
        cloneApplication.properties = _.values(applicationPropertiesSelector(state, application.uuid));
    }

    return cloneApplication;
}

function hydrateApplicationBorrower(state: IGlobalState, applicationBorrower: IBorrower): IBorrower {
    const cloneApplicationBorrower: IBorrower = { ...applicationBorrower };
    cloneApplicationBorrower.dealBorrower = dealBorrowerSelector(state, applicationBorrower.dealBorrowerUuid);

    return cloneApplicationBorrower;
}

function hydrateApplicationConditional(state: IGlobalState, conditional: IConditional): IConditional {
    const cloneConditional: IConditional = { ...conditional };
    cloneConditional.conditions = applicationConditionalConditionsSelector(state, conditional.uuid);

    return cloneConditional;
}

function hydrateApplicationConditionDocument(state: IGlobalState, conditionDocument: IConditionDocument): IConditionDocument {
    const cloneConditionDocument: IConditionDocument = { ...conditionDocument };
    cloneConditionDocument.document = dealDocumentSelector(state, conditionDocument.documentUuid);

    return cloneConditionDocument;
}

function hydrateApplicationProperty(state: IGlobalState, applicationProperty: IApplicationProperty): IApplicationProperty {
    const cloneApplicationProperty: IApplicationProperty = { ...applicationProperty };
    cloneApplicationProperty.application = applicationSelector(state, applicationProperty.applicationUuid, false);
    cloneApplicationProperty.dealProperty = dealPropertySelector(state, applicationProperty.dealPropertyUuid);
    cloneApplicationProperty.valuers = _.values(applicationPropertyValuersSelector(state, applicationProperty.uuid));

    return cloneApplicationProperty;
}

function hydrateApplicationWarehouse(state: IGlobalState, applicationWarehouse: IApplicationWarehouse): IApplicationWarehouse {
    const cloneApplicationWarehouse: IApplicationWarehouse = { ...applicationWarehouse };
    cloneApplicationWarehouse.application = applicationSelector(state, applicationWarehouse.applicationUuid);

    return cloneApplicationWarehouse;
}

export function applicationsSelector(state: IGlobalState): IApplication[] {
    if (!state.applications.applications || !state.applications.applicationsListed) {
        return null;
    }

    return _.map(state.applications.applications, (application: IApplication) => hydrateApplication(state, application));
}

export function dashboardApplicationsSelector(state: IGlobalState): IDictionary<IApplication> {
    if (!state.applications.dashboardUuids) {
        return null;
    }

    const applications: IDictionary<IApplication> = {};

    state.applications.dashboardUuids.forEach((applicationUuid: string) => {
        applications[applicationUuid] = applicationSelector(state, applicationUuid);
    });

    return applications;
}

export function settlementForecastApplicationsSelector(state: IGlobalState): IDictionary<IApplication> {
    if (!state.applications.settlementForecastUuids) {
        return null;
    }

    const applications: IDictionary<IApplication> = {};

    state.applications.settlementForecastUuids.forEach((applicationUuid: string) => {
        applications[applicationUuid] = applicationSelector(state, applicationUuid);
    });

    return applications;
}

export function applicationsSettlementReportApplicationSelector(state: IGlobalState): IDictionary<ISettlementReportApplication> {
    return state.applications.settlementReport.applications;
}

export function applicationsSettlementReportEndDateSelector(state: IGlobalState): string {
    return state.applications.settlementReport.endDate;
}

export function applicationsSettlementReportPeriodRangeSelector(state: IGlobalState): PeriodRangeEnum {
    return state.applications.settlementReport.periodRange;
}

export function applicationsSettlementReportStartDateSelector(state: IGlobalState): string {
    return state.applications.settlementReport.startDate;
}

export function dealApplicationsSelector(state: IGlobalState, dealUuid: string): IApplication[] {
    if (!state.applications.applications) {
        return null;
    }

    return _.map(state.applications.dealApplicationUuids[dealUuid], (applicationUuid: string) => applicationSelector(state, applicationUuid));
}

export function outstandingValuationsApplicationPropertiesSelector(state: IGlobalState): IApplicationProperty[] {
    if (!state.applications.outstandingValuationsApplicationPropertyUuids || !state.applications.applicationProperties) {
        return null;
    }

    return _.map(state.applications.outstandingValuationsApplicationPropertyUuids, (applicationPropertyUuid: string) => applicationPropertySelector(state, applicationPropertyUuid));
}

export function applicationSelector(state: IGlobalState, applicationUuid: string, nestProperties: boolean = true): IApplication {
    if (!state.applications.applications || !state.applications.applications[applicationUuid]) {
        return null;
    }

    return hydrateApplication(state, state.applications.applications[applicationUuid], nestProperties);
}

export function applicationBorrowersSelector(state: IGlobalState, applicationUuid: string): IBorrower[] {
    if (!state.applications.borrowers[applicationUuid] || !state.applications.borrowers[applicationUuid]) {
        return null;
    }

    return _.map(state.applications.borrowers[applicationUuid], (applicationBorrower: IBorrower) => hydrateApplicationBorrower(state, applicationBorrower));
}

export function applicationBorrowerSelector(state: IGlobalState, applicationUuid: string, borrowerUuid: string): IBorrower {
    if (!state.applications.borrowers[applicationUuid] || !state.applications.borrowers[applicationUuid][borrowerUuid]) {
        return null;
    }

    return hydrateApplicationBorrower(state, state.applications.borrowers[applicationUuid][borrowerUuid]);
}

export function applicationConditionRfiItemSelector(state: IGlobalState, applicationUuid: string, conditionUuid: string): IRfiItem {
    return _.find(state.applications.rfiItems[applicationUuid], { applicationConditionUuid: conditionUuid });
}

export function applicationConditionsSelector(state: IGlobalState, applicationUuid: string): IDictionary<ICondition> {
    return state.applications.conditions[applicationUuid];
}

export function applicationConditionalsStandardConditionsSelector(state: IGlobalState): IStandardConditionGroup[] {
    return state.applications.standardConditions;
}

export function applicationConditionalsSelector(state: IGlobalState, applicationUuid: string): IDictionary<IConditional> {
    if (!state.applications.applicationConditionalUuids[applicationUuid]) {
        return null;
    }

    const conditionals: IDictionary<IConditional> = {};
    _.forEach(state.applications.applicationConditionalUuids[applicationUuid], (conditionalUuid: string) => {
        if (!state.applications.applicationConditionals[conditionalUuid]) {
            // Account for deleted conditionals
            return;
        }

        conditionals[conditionalUuid] = hydrateApplicationConditional(state, state.applications.applicationConditionals[conditionalUuid]);
    });

    return conditionals;
}

export function applicationConditionalSelector(state: IGlobalState, conditionalUuid: string): IConditional {
    return state.applications.applicationConditionals[conditionalUuid] && hydrateApplicationConditional(state, state.applications.applicationConditionals[conditionalUuid]);
}

export function applicationConditionalConditionsSelector(state: IGlobalState, conditionalUuid: string): IConditionalCondition[] {
    if (!state.applications.applicationConditionalConditionUuids[conditionalUuid]) {
        return null;
    }

    const conditions: IConditionalCondition[] = [];
    state.applications.applicationConditionalConditionUuids[conditionalUuid].forEach((conditionUuid: string) => {
        conditions.push(state.applications.applicationConditionalConditions[conditionUuid]);
    });

    return conditions;
}

export function applicationConditionDocumentsSelector(state: IGlobalState, applicationUuid: string): IDictionary<IConditionDocument> {
    if (!state.applications.conditionDocuments || !state.applications.applicationConditionDocumentUuids || !state.applications.applicationConditionDocumentUuids[applicationUuid]) {
        return null;
    }

    return keyMap(state.applications.applicationConditionDocumentUuids[applicationUuid], 'uuid', (conditionDocumentUuid: string) => {
        return state.applications.conditionDocuments[conditionDocumentUuid];
    });
}

export function applicationDownloadableConditionDocumentsSelector(state: IGlobalState, applicationUuid: string): IDictionary<IConditionDocument> {
    if (!state.applications.conditionDocuments || !state.applications.applicationConditionDocumentUuids[applicationUuid]) {
        return null;
    }

    const downloadableConditionDocuments: IDictionary<IConditionDocument> = {};
    _.each(state.applications.applicationConditionDocumentUuids[applicationUuid], (conditionDocumentUuid: string) => {
        const conditionDocument: IConditionDocument = state.applications.conditionDocuments[conditionDocumentUuid];

        if (!([ConditionDocumentApprovalStatusEnum.Approved, ConditionDocumentApprovalStatusEnum.Legacy].includes(conditionDocument.approvalStatus))) {
            return;
        }

        downloadableConditionDocuments[conditionDocumentUuid] = hydrateApplicationConditionDocument(state, conditionDocument);
    });

    return downloadableConditionDocuments;
}

export function applicationConditionDocumentSelector(state: IGlobalState, conditionDocumentUuid: string): IConditionDocument {
    if (!state.applications.conditionDocuments || !state.applications.conditionDocuments[conditionDocumentUuid]) {
        return null;
    }

    return hydrateApplicationConditionDocument(state, state.applications.conditionDocuments[conditionDocumentUuid]);
}

export function applicationConditionDocumentsUpdatingSolicitorAccessSelector(state: IGlobalState): boolean {
    return state.applications.conditionDocumentsUpdatingSolicitorAccess;
}

export function applicationDealPropertiesSelector(state: IGlobalState, applicationUuid: string): IDictionary<IProperty> {
    const application: IApplication = applicationSelector(state, applicationUuid);

    if (!application) {
        return null;
    }

    return dealPropertiesSelector(state, application.dealUuid);
}

export function applicationDisbursementSelector(state: IGlobalState, disbursementUuid: string): IApplicationDisbursement {
    return state.applications.disbursements[disbursementUuid];
}

export function applicationDisbursementApplicationSelector(state: IGlobalState, disbursementUuid: string): IApplication {
    if (!state.applications.disbursements || !state.applications.disbursements[disbursementUuid]) {
        return null;
    }

    return applicationSelector(state, state.applications.disbursements[disbursementUuid].applicationUuid);
}

export function applicationDisbursementsSelector(state: IGlobalState, applicationUuid: string): IDictionary<IApplicationDisbursement> {
    if (!state.applications.applicationDisbursementUuids[applicationUuid]) {
        return null;
    }

    const disbursements: IDictionary<IApplicationDisbursement> = {};
    state.applications.applicationDisbursementUuids[applicationUuid].forEach((applicationDisbursementUuid: string) => {
        if (!state.applications.disbursements[applicationDisbursementUuid]) {
            // Account for deleted disbursements
            return;
        }

        disbursements[applicationDisbursementUuid] = state.applications.disbursements[applicationDisbursementUuid];
    });

    return disbursements;
}

export function applicationDisbursementDescriptionSuggestionsSelector(state: IGlobalState): IDictionary<number> {
    return state.applications.disbursementSuggestions && state.applications.disbursementSuggestions.descriptions;
}

export function applicationDisbursementPayeeNameSuggestionsSelector(state: IGlobalState): IDictionary<number> {
    return state.applications.disbursementSuggestions && state.applications.disbursementSuggestions.payeeNames;
}

export function applicationFeeSelector(state: IGlobalState, applicationFeeUuid: string): IApplicationFee {
    return state.applications.fees[applicationFeeUuid];
}

export function applicationFeesSelector(state: IGlobalState, applicationUuid: string): IDictionary<IApplicationFee> {
    if (!state.applications.applicationFeeUuids[applicationUuid]) {
        return null;
    }

    const applicationFees: IDictionary<IApplicationFee> = {};
    state.applications.applicationFeeUuids[applicationUuid].forEach((applicationFeeUuid: string) => {
        applicationFees[applicationFeeUuid] = applicationFeeSelector(state, applicationFeeUuid);
    });

    return applicationFees;
}

export function applicationFormalsSelector(state: IGlobalState, applicationUuid: string): IFormal[] {
    return state.applications.formals[applicationUuid] && _.values(state.applications.formals[applicationUuid]);
}

export function applicationHistoriesSelector(state: IGlobalState, applicationUuid: string): IDictionary<IHistory> {
    return state.applications.histories[applicationUuid];
}

export function applicationNotesSelector(state: IGlobalState, applicationUuid: string): IDictionary<INote> {
    return state.applications.notes[applicationUuid];
}

export function applicationsOutstandingBrokerCommissionsSelector(state: IGlobalState): IDictionary<IApplication> {
    if (!state.applications.outstandingBrokerCommissionsUuids) {
        return null;
    }

    return keyMap(state.applications.outstandingBrokerCommissionsUuids, 'uuid', (uuid: string) => applicationSelector(state, uuid));
}

export function applicationPropertiesSelector(state: IGlobalState, applicationUuid: string): IDictionary<IApplicationProperty> {
    if (!state.applications.applicationProperties || !state.applications.applicationPropertyUuids || !state.applications.applicationPropertyUuids[applicationUuid]) {
        return null;
    }

    const applicationProperties: IDictionary<IApplicationProperty> = {};
    _.each(state.applications.applicationPropertyUuids[applicationUuid], (applicationPropertyUuid: string) => {
        applicationProperties[applicationPropertyUuid] = hydrateApplicationProperty(state, state.applications.applicationProperties[applicationPropertyUuid]);
    });

    return applicationProperties;
}

export function applicationPropertySelector(state: IGlobalState, applicationPropertyUuid: string): IApplicationProperty {
    if (!state.applications.applicationProperties || !state.applications.applicationProperties[applicationPropertyUuid]) {
        return null;
    }

    return hydrateApplicationProperty(state, state.applications.applicationProperties[applicationPropertyUuid]);
}

export function applicationPropertyValuerByValuerSelector(state: IGlobalState, applicationPropertyUuid: string, valuerUuid: string): IApplicationPropertyValuer {
    if (!state.applications.applicationPropertyValuers || !state.applications.applicationPropertyValuerUuids || !state.applications.applicationPropertyValuerUuids[applicationPropertyUuid]) {
        return null;
    }

    const applicationPropertyValuerUuid: string = _.find(state.applications.applicationPropertyValuerUuids[applicationPropertyUuid], (loopApplicationPropertyValuerUuid: string) => state.applications.applicationPropertyValuers[loopApplicationPropertyValuerUuid].valuerUuid === valuerUuid);

    return state.applications.applicationPropertyValuers[applicationPropertyValuerUuid];
}

export function applicationPropertyValuersSelector(state: IGlobalState, applicationPropertyUuid: string): IDictionary<IApplicationPropertyValuer> {
    if (!state.applications.applicationPropertyValuers || !state.applications.applicationPropertyValuerUuids || !state.applications.applicationPropertyValuerUuids[applicationPropertyUuid]) {
        return null;
    }

    const applicationPropertyValuers: IDictionary<IApplicationPropertyValuer> = {};
    _.each(state.applications.applicationPropertyValuerUuids[applicationPropertyUuid], (applicationPropertyValuerUuid: string) => {
        applicationPropertyValuers[applicationPropertyValuerUuid] = state.applications.applicationPropertyValuers[applicationPropertyValuerUuid];
    });

    return applicationPropertyValuers;
}

export function applicationPropertyValuersUnpaidSelector(state: IGlobalState): IDictionary<IApplicationPropertyValuer> {
    if (!state.applications.applicationPropertyValuerUnpaidUuids) {
        return null;
    }

    return keyMap(state.applications.applicationPropertyValuerUnpaidUuids, 'uuid', (applicationPropertyValuerUnpaidUuid: string) => {
        return state.applications.applicationPropertyValuers[applicationPropertyValuerUnpaidUuid];
    });
}

export function applicationRfiItemsSelector(state: IGlobalState, applicationUuid: string): IDictionary<IRfiItem> {
    return state.applications.rfiItems[applicationUuid];
}

export function applicationsCreateRenewalFromLoanErrorsSelector(state: IGlobalState, loanPayoutFigureUuid: string): IDictionary<string> {
    return state.applications.createRenewalFromLoanErrors[loanPayoutFigureUuid] || {};
}

export function applicationsCreateRenewalFromLoanInProgressSelector(state: IGlobalState): boolean {
    return state.applications.createRenewalFromLoanInProgress;
}

export function applicationsSearchResultsSelector(state: IGlobalState): IDictionary<IApplication> {
    if (!state.applications.applicationsSearchResultUuids) {
        return null;
    }

    const applicationsSearchResults: IDictionary<IApplication> = {};
    _.each(state.applications.applicationsSearchResultUuids, (uuid: string) => {
        applicationsSearchResults[uuid] = applicationSelector(state, uuid);
    });

    return applicationsSearchResults;
}

export function applicationWarehouseSelector(state: IGlobalState, warehouseUuid: string): IApplicationWarehouse {
    if (!state.applications.warehouses || !state.applications.warehouses[warehouseUuid]) {
        return null;
    }

    return hydrateApplicationWarehouse(state, state.applications.warehouses[warehouseUuid]);
}

export function applicationWarehousesSelector(state: IGlobalState, applicationUuid: string): IDictionary<IApplicationWarehouse> {
    if (!state.applications.applicationWarehouseUuids[applicationUuid]) {
        return null;
    }

    const warehouses: IDictionary<IApplicationWarehouse> = {};
    state.applications.applicationWarehouseUuids[applicationUuid].forEach((applicationWarehouseUuid: string) => {
        if (!state.applications.warehouses[applicationWarehouseUuid]) {
            // Account for deleted warehouses
            return;
        }

        warehouses[applicationWarehouseUuid] = hydrateApplicationWarehouse(state, state.applications.warehouses[applicationWarehouseUuid]);
    });

    return warehouses;
}

export function loanPayoutFigureApplicationSelector(state: IGlobalState, loanPayoutFigureUuid: string): IApplication {
    const loanPayoutFigure: ILoanPayoutFigure = loanPayoutFigureSelector(state, loanPayoutFigureUuid);

    if (!loanPayoutFigure) {
        return null;
    }

    const loan: ILoan = loanSelector(state, loanPayoutFigure.loanUuid);

    return loan && applicationSelector(state, loan.applicationUuid);
}

export function warehousePendingApplicationSelector(state: IGlobalState, warehouseUuid: string): IDictionary<IApplicationWarehouse> {
    if (!state.applications.warehouses || !state.applications.pendingWarehouseUuids || !state.applications.pendingWarehouseUuids[warehouseUuid]) {
        return null;
    }

    const applicationWarehouses: IDictionary<IApplicationWarehouse> = {};
    _.each(state.applications.pendingWarehouseUuids[warehouseUuid], ((applicationWarehouseUuid: string) => {
        applicationWarehouses[applicationWarehouseUuid] = hydrateApplicationWarehouse(state, state.applications.warehouses[applicationWarehouseUuid]);
    }));

    return applicationWarehouses;
}
