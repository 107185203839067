import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '~Layout';
import List from './List';
import ProposedSales from './ProposedSales';
import PortfolioDashboard from './PortfolioDashboard';
import Warehouse from './Warehouse';
import { useSelector } from 'react-redux';
import { authCurrentUserSelector } from '~Auth/selectors';
import IAuthUser from '~Auth/IAuthUser';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import LoanBookForecast from './LoanBookForecast';

export default function Router(): ReactElement {
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);

    return (
        <Layout topSection='accounts'>
            <Switch>
                <Route exact={true} path='/warehouses' component={List} />
                <Route exact={true} path='/warehouses/loan-book-forecast' component={LoanBookForecast} />
                {currentUser.permissions.includes(PermissionsEnum.WarehouseLoanSell) && <Route exact={true} path='/warehouses/proposed-sales' component={ProposedSales} />}
                {currentUser.permissions.includes(PermissionsEnum.WarehousesPortfolioDashboard) && <Route exact={true} path='/warehouses/portfolio-dashboard' component={PortfolioDashboard} />}
                <Route path='/warehouses/:warehouseUuid' component={Warehouse} />
            </Switch>
        </Layout>
    );
}
