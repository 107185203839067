import { Breadcrumb, Button, Layout, Space, Spin, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import MaturityReminderStatusEnum from '~Api/Loan/MaturityReminderStatusEnum';
import { IGlobalState } from '~reducer';
import {
    loanSendMaturationReminderAction,
    loanSkipMaturationReminderAction,
    loansListAction,
} from './actions';
import { loansSelector } from './selectors';
import { ColumnType } from 'antd/lib/table';

interface IPropsSelector {
    loans: { [loanUuid: string]: ILoan };
}

interface IPropsDispatch {
    loansList: () => void;
    sendReminder: (uuid: string, status: MaturityReminderStatusEnum) => void;
    skipReminder: (uuid: string, status: MaturityReminderStatusEnum) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class MaturationReminders extends React.Component<Props> {
    public componentDidMount() {
        this.props.loansList();
    }

    public render(): JSX.Element {
        const { loans } = this.props;

        if (!loans) {
            return (
                <Layout className='loans'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/loans'>Loans</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Maturation Reminders</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Maturation Reminders</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const rangeStart30: dayjs.Dayjs = dayjs().add(25, 'days');
        const rangeEnd30: dayjs.Dayjs = dayjs().add(35, 'days');
        const loans30: ILoan[] = _.filter(loans, (loan: ILoan) => {
            if (![LoanStatusEnum.ActiveBadStanding, LoanStatusEnum.ActiveGoodStanding, LoanStatusEnum.ActiveMatured].includes(loan.status)) {
                return false;
            }
            if (loan.maturityReminderStatus === MaturityReminderStatusEnum.ThirtyDays) {
                return false;
            }
            const endDate: dayjs.Dayjs = dayjs(loan.endDate);
            return endDate > rangeStart30 && endDate < rangeEnd30;
        });

        const rangeStart60: dayjs.Dayjs = dayjs().add(55, 'days');
        const rangeEnd60: dayjs.Dayjs = dayjs().add(65, 'days');
        const loans60: ILoan[] = _.filter(loans, (loan: ILoan) => {
            if (![LoanStatusEnum.ActiveBadStanding, LoanStatusEnum.ActiveGoodStanding, LoanStatusEnum.ActiveMatured].includes(loan.status)) {
                return false;
            }
            if (loan.maturityReminderStatus === MaturityReminderStatusEnum.SixtyDays) {
                return false;
            }
            const endDate: dayjs.Dayjs = dayjs(loan.endDate);
            return endDate > rangeStart60 && endDate < rangeEnd60;
        });

        const rangeStart90: dayjs.Dayjs = dayjs().add(85, 'days');
        const rangeEnd90: dayjs.Dayjs = dayjs().add(95, 'days');
        const loans90: ILoan[] = _.filter(loans, (loan: ILoan) => {
            if (![LoanStatusEnum.ActiveBadStanding, LoanStatusEnum.ActiveGoodStanding, LoanStatusEnum.ActiveMatured].includes(loan.status)) {
                return false;
            }
            if (loan.maturityReminderStatus === MaturityReminderStatusEnum.NinetyDays) {
                return false;
            }
            const endDate: dayjs.Dayjs = dayjs(loan.endDate);
            return endDate > rangeStart90 && endDate < rangeEnd90;
        });

        const columns30: ColumnType<ILoan>[] = [
            {
                dataIndex: 'code',
                render: (code: string, loan: ILoan) => <Link to={`/loans/${loan.uuid}`}>{code}</Link>,
                title: 'Code',
            },
            {
                dataIndex: 'endDate',
                title: 'End Date',
                width: '15%',
            },
            {
                render: (loan: ILoan) => {
                    const onClickSend: () => void = () => this.props.sendReminder(loan.uuid, MaturityReminderStatusEnum.ThirtyDays);
                    const onClickSkip: () => void = () => this.props.skipReminder(loan.uuid, MaturityReminderStatusEnum.ThirtyDays);
                    return (
                        <Space>
                            <Button onClick={onClickSkip}>Skip</Button>
                            <Button onClick={onClickSend} type='primary'>Send</Button>
                        </Space>
                    );
                },
                title: 'Actions',
                width: '10%',
            },
        ];

        const columns60: ColumnType<ILoan>[] = [
            {
                dataIndex: 'code',
                render: (code: string, loan: ILoan) => <Link to={`/loans/${loan.uuid}`}>{code}</Link>,
                title: 'Code',
            },
            {
                dataIndex: 'endDate',
                title: 'End Date',
                width: '15%',
            },
            {
                render: (loan: ILoan) => {
                    const onClickSend: () => void = () => this.props.sendReminder(loan.uuid, MaturityReminderStatusEnum.SixtyDays);
                    const onClickSkip: () => void = () => this.props.skipReminder(loan.uuid, MaturityReminderStatusEnum.SixtyDays);
                    return (
                        <Space>
                            <Button onClick={onClickSkip}>Skip</Button>
                            <Button onClick={onClickSend} type='primary'>Send</Button>
                        </Space>
                    );
                },
                title: 'Actions',
                width: '10%',
            },
        ];

        const columns90: ColumnType<ILoan>[] = [
            {
                dataIndex: 'code',
                render: (code: string, loan: ILoan) => <Link to={`/loans/${loan.uuid}`}>{code}</Link>,
                title: 'Code',
            },
            {
                dataIndex: 'endDate',
                title: 'End Date',
                width: '15%',
            },
            {
                render: (loan: ILoan) => {
                    const onClickSend: () => void = () => this.props.sendReminder(loan.uuid, MaturityReminderStatusEnum.NinetyDays);
                    const onClickSkip: () => void = () => this.props.skipReminder(loan.uuid, MaturityReminderStatusEnum.NinetyDays);
                    return (
                        <Space>
                            <Button onClick={onClickSkip}>Skip</Button>
                            <Button onClick={onClickSend} type='primary'>Send</Button>
                        </Space>
                    );
                },
                title: 'Actions',
                width: '10%',
            },
        ];

        return (
            <Layout className='loans'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/loans'>Loans</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Maturation Reminders</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Maturation Reminders</Typography.Title>
                        <Typography.Title level={3}>30 Days</Typography.Title>
                        <Table
                            columns={columns30}
                            dataSource={loans30}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                        <Typography.Title level={3} style={{ marginTop: '40px' }}>60 Days</Typography.Title>
                        <Table
                            columns={columns60}
                            dataSource={loans60}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                        <Typography.Title level={3} style={{ marginTop: '40px' }}>90 Days</Typography.Title>
                        <Table
                            columns={columns90}
                            dataSource={loans90}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        loans: loansSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        loansList: () => dispatch(loansListAction()),
        sendReminder: (uuid: string, status: MaturityReminderStatusEnum) => dispatch(loanSendMaturationReminderAction(uuid, status)),
        skipReminder: (uuid: string, status: MaturityReminderStatusEnum) => dispatch(loanSkipMaturationReminderAction(uuid, status)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MaturationReminders);
