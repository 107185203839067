import { Dropdown, MenuProps } from 'antd';
import React from 'react';
import IApplication from '~Api/Application/IApplication';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IApplicationPropertyValuer from '~Api/Application/IApplicationPropertyValuer';
import ValuerStatusEnum from '~Api/Application/ValuerStatusEnum';
import IValuer from '~Valuers/IValuer';
import EnterQuoteModal from './EnterQuoteModal';
import EnterValuationModal from './EnterValuationModal';
import QuoteRequestedModal from './QuoteRequestedModal';
import ValuationOrderedModal from './ValuationOrderedModal';

interface IState {
    isQuoteReceivedModalOpen: boolean;
    isQuoteRequestedModalOpen: boolean;
    isValuationOrderedModalOpen: boolean;
    isValuationReceivedModalOpen: boolean;
}

interface IProps {
    application: IApplication;
    applicationProperty: IApplicationProperty;
    valuation: IApplicationPropertyValuer;
    valuer: IValuer;
}

class PropertyValuerActions extends React.Component<IProps, IState> {
    public state: IState = {
        isQuoteReceivedModalOpen: false,
        isQuoteRequestedModalOpen: false,
        isValuationOrderedModalOpen: false,
        isValuationReceivedModalOpen: false,
    };

    constructor(props: IProps) {
        super(props);

        this.onClickEnterQuote = this.onClickEnterQuote.bind(this);
        this.onClickEnterQuoteCancel = this.onClickEnterQuoteCancel.bind(this);
        this.onClickEnterValuation = this.onClickEnterValuation.bind(this);
        this.onClickEnterValuationCancel = this.onClickEnterValuationCancel.bind(this);
        this.onClickQuoteRequested = this.onClickQuoteRequested.bind(this);
        this.onClickQuoteRequestedCancel = this.onClickQuoteRequestedCancel.bind(this);
        this.onClickValuationOrdered = this.onClickValuationOrdered.bind(this);
        this.onClickValuationOrderedCancel = this.onClickValuationOrderedCancel.bind(this);
    }

    public render(): JSX.Element {
        const {
            application,
            applicationProperty,
            valuation,
            valuer,
        } = this.props;
        const {
            isQuoteReceivedModalOpen,
            isQuoteRequestedModalOpen,
            isValuationOrderedModalOpen,
            isValuationReceivedModalOpen,
        } = this.state;

        const workflowMenu: MenuProps = {
            items: [
                ...((valuation && [ValuerStatusEnum.QuoteRequested, ValuerStatusEnum.QuoteReceived, ValuerStatusEnum.ValuationOrdered, ValuerStatusEnum.ValuationReceived].includes(valuation.status)) ? [
                    {
                        key: 'quote-requested',
                        label: 'Quote Requested',
                        onClick: this.onClickQuoteRequested,
                    },
                ] : []),
                ...((!valuation || [ValuerStatusEnum.QuoteReceived, ValuerStatusEnum.ValuationOrdered, ValuerStatusEnum.ValuationReceived].includes(valuation.status)) ? [
                    {
                        key: 'enter-quote',
                        label: 'Enter Quote',
                        onClick: this.onClickEnterQuote,
                    },
                ] : []),
                ...((!valuation || [ValuerStatusEnum.QuoteRequested, ValuerStatusEnum.ValuationOrdered, ValuerStatusEnum.ValuationReceived].includes(valuation.status)) ? [
                    {
                        key: 'valuation-ordered',
                        label: 'Valuation Ordered',
                        onClick: this.onClickValuationOrdered,
                    },
                ] : []),
                ...((!valuation || [ValuerStatusEnum.QuoteReceived, ValuerStatusEnum.QuoteRequested].includes(valuation.status)) ? [
                    {
                        key: 'enter-valuation',
                        label: 'Enter Valuation',
                        onClick: this.onClickEnterValuation,
                    },
                ] : []),
            ],
        };

        return (
            <React.Fragment>
                {(!valuation || (valuation && valuation.status === ValuerStatusEnum.New)) && <Dropdown.Button onClick={this.onClickQuoteRequested} className='workflow-dropdown-button' menu={workflowMenu} type='primary'>Quote Requested</Dropdown.Button>}
                {valuation && valuation.status === ValuerStatusEnum.QuoteRequested && <Dropdown.Button onClick={this.onClickEnterQuote} className='workflow-dropdown-button' menu={workflowMenu} type='primary'>Enter Quote</Dropdown.Button>}
                {valuation && valuation.status === ValuerStatusEnum.QuoteReceived && <Dropdown.Button onClick={this.onClickValuationOrdered} className='workflow-dropdown-button' menu={workflowMenu} type='primary'>Valuation Ordered</Dropdown.Button>}
                {valuation && valuation.status === ValuerStatusEnum.ValuationOrdered && <Dropdown.Button onClick={this.onClickEnterValuation} className='workflow-dropdown-button' menu={workflowMenu} type='primary'>Enter Valuation</Dropdown.Button>}
                {valuation && valuation.status === ValuerStatusEnum.ValuationReceived && <Dropdown.Button onClick={this.onClickEnterValuation} className='workflow-dropdown-button' menu={workflowMenu} type='primary'>Update Valuation</Dropdown.Button>}

                <EnterQuoteModal applicationProperty={applicationProperty} isOpen={isQuoteReceivedModalOpen} onCancel={this.onClickEnterQuoteCancel} valuer={valuer} />
                <EnterValuationModal application={application} applicationProperty={applicationProperty} isOpen={isValuationReceivedModalOpen} onCancel={this.onClickEnterValuationCancel} valuer={valuer} />
                <QuoteRequestedModal applicationProperty={applicationProperty} isOpen={isQuoteRequestedModalOpen} onCancel={this.onClickQuoteRequestedCancel} valuer={valuer} />
                <ValuationOrderedModal applicationProperty={applicationProperty} isOpen={isValuationOrderedModalOpen} onCancel={this.onClickValuationOrderedCancel} valuer={valuer} />
            </React.Fragment>
        );
    }

    private onClickEnterQuote() {
        this.setState({
            isQuoteReceivedModalOpen: true,
        });
    }

    private onClickEnterQuoteCancel() {
        this.setState({
            isQuoteReceivedModalOpen: false,
        });
    }

    private onClickEnterValuation() {
        this.setState({
            isValuationReceivedModalOpen: true,
        });
    }

    private onClickEnterValuationCancel() {
        this.setState({
            isValuationReceivedModalOpen: false,
        });
    }

    private onClickQuoteRequested() {
        this.setState({
            isQuoteRequestedModalOpen: true,
        });
    }

    private onClickQuoteRequestedCancel() {
        this.setState({
            isQuoteRequestedModalOpen: false,
        });
    }

    private onClickValuationOrdered() {
        this.setState({
            isValuationOrderedModalOpen: true,
        });
    }

    private onClickValuationOrderedCancel() {
        this.setState({
            isValuationOrderedModalOpen: false,
        });
    }
}

export default PropertyValuerActions;
