enum ApplicationsActionsEnum {
    ApplicationsCreateRenewalFromLoan = 'APPLICATIONS/CREATE_RENEWAL_FROM_LOAN',
    ApplicationsCreateRenewalFromLoanErrorSet = 'APPLICATION/CREATE_RENEWAL_FROM_LOAN/ERROR/SET',
    ApplicationsCreateRenewalFromLoanErrorsSet = 'APPLICATION/CREATE_RENEWAL_FROM_LOAN/ERRORS/SET',
    ApplicationsCreateRenewalFromLoanInProgressSet = 'APPLICATION/CREATE_RENEWAL_FROM_LOAN/IN_PROGRESS/SET',
    ApplicationAssign = 'APPLICATION/ASSIGN',
    ApplicationsDashboardList = 'APPLICATIONS/DASHBOARD/LIST',
    ApplicationsDashboardSet = 'APPLICATIONS/DASHBOARD/SET',
    ApplicationsList = 'APPLICATIONS/LIST',
    ApplicationsOutstandingBrokerCommissionsSet = 'APPLICATIONS/OUTSTANDING_BROKER_COMMISSIONS/SET',
    ApplicationsOutstandingBrokerCommissionsList = 'APPLICATIONS/OUTSTANDING_BROKER_COMMISSIONS/LIST',
    ApplicationsSearch = 'APPLICATION/SEARCH',
    ApplicationsSearchResultsSet = 'APPLICATION/SEARCH/RESULTS/SET',
    ApplicationsSearchResultsClear = 'APPLICATION/SEARCH/RESULTS/CLEAR',
    ApplicationsSettlementForecastList = 'APPLICATIONS/SETTLEMENT_FORECAST/LIST',
    ApplicationsSettlementForecastSet = 'APPLICATIONS/SETTLEMENT_FORECAST/SET',
    ApplicationsSettlementReportList = 'APPLICATIONS/SETTLEMENT_REPORT/LIST',
    ApplicationsSettlementReportSet = 'APPLICATIONS/SETTLEMENT_REPORT/SET',
    ApplicationsSet = 'APPLICATIONS/SET',

    ApplicationAssignPayoutFigure = 'APPLICATION/ASSIGN_PAYOUT_FIGURE',
    ApplicationClose = 'APPLICATION/CLOSE',
    ApplicationConditionalApproval = 'APPLICATION/CONDITIONAL_APPROVAL',
    ApplicationDealValueSet = 'APPLICATION/DEAL/VALUE/SET',
    ApplicationFormalApproval = 'APPLICATION/FORMAL_APPROVAL',
    ApplicationGenerateMemo = 'APPLICATION/GENERATE_MEMO',
    ApplicationGet = 'APPLICATION/GET',
    ApplicationLegalDocuments = 'APPLICATION/LEGAL_DOCUMENTS',
    ApplicationNew = 'APPLICATION/NEW',
    ApplicationReopen = 'APPLICATION/REOPEN',
    ApplicationSend = 'APPLICATION/SEND',
    ApplicationSet = 'APPLICATION/SET',
    ApplicationSettlement = 'APPLICATION/SETTLEMENT',
    ApplicationSettlementDate = 'APPLICATION/SETTLEMENT_DATE',
    ApplicationSolicitorInstructionSentTime = 'APPLICATION/SOLICITOR_INSTRUCTION_SENT_TIME',
    ApplicationUnderwriting = 'APPLICATION/UNDERWRITING',
    ApplicationValueSet = 'APPLICATION/VALUE/SET',
    ApplicationWarehoused = 'APPLICATION/WAREHOUSED',

    ApplicationBorrowersAdd = 'APPLICATION/BORROWERS/ADD',
    ApplicationBorrowersList = 'APPLICATION/BORROWERS/LIST',
    ApplicationBorrowersSet = 'APPLICATION/BORROWERS/SET',

    ApplicationBorrowerBankruptcyCheck = 'APPLICATION/BORROWER/BANKRUPTCY_CHECK',
    ApplicationBorrowerCompanyTradingHistoryCheck = 'APPLICATION/BORROWER/COMPANY_TRADING_HISTORY_CHECK',
    ApplicationBorrowerCreditCheck = 'APPLICATION/BORROWER/CREDIT_CHECK',
    ApplicationBorrowerDelete = 'APPLICATION/BORROWER/DELETE',
    ApplicationBorrowerGet = 'APPLICATION/BORROWER/GET',
    ApplicationBorrowerRemove = 'APPLICATION/BORROWER/REMOVE',
    ApplicationBorrowerSend = 'APPLICATION/BORROWER/SEND',
    ApplicationBorrowerSet = 'APPLICATION/BORROWER/SET',
    ApplicationBorrowerValueSet = 'APPLICATION/BORROWER/VALUE/SET',

    ApplicationConditionalsAdd = 'APPLICATION/CONDITIONALS/ADD',
    ApplicationConditionalsList = 'APPLICATION/CONDITIONALS/LIST',
    ApplicationConditionalsSet = 'APPLICATION/CONDITIONALS/SET',

    ApplicationConditionalApprove = 'APPLICATION/CONDITIONAL/APPROVE',
    ApplicationConditionalDelete = 'APPLICATION/CONDITIONAL/DELETE',
    ApplicationConditionalGet = 'APPLICATION/CONDITIONAL/GET',
    ApplicationConditionalSend = 'APPLICATION/CONDITIONAL/SEND',
    ApplicationConditionalSet = 'APPLICATION/CONDITIONAL/SET',

    ApplicationConditionalConditionAdd = 'APPLICATION/CONDITIONAL/CONDITION/ADD',
    ApplicationConditionalConditionDelete = 'APPLICATION/CONDITIONAL/CONDITION/DELETE',
    ApplicationConditionalConditionSet = 'APPLICATION/CONDITIONAL/CONDITION/SET',

    ApplicationConditionalsStandardConditionsList = 'APPLICATION_CONDITIONALS/STANDARD_CONDITIONS_LIST',
    ApplicationConditionalsStandardConditionsSet = 'APPLICATION_CONDITIONALS/STANDARD_CONDITIONS_SET',

    ApplicationConditionDelete = 'APPLICATION/CONDITION/DELETE',
    ApplicationConditionRemove = 'APPLICATION/CONDITION/REMOVE',
    ApplicationConditionSet = 'APPLICATION/CONDITION/SET',

    ApplicationConditionsAdd = 'APPLICATION/CONDITIONS/ADD',
    ApplicationConditionsList = 'APPLICATION/CONDITIONS/LIST',
    ApplicationConditionsSet = 'APPLICATION/CONDITIONS/SET',

    ApplicationConditionDocumentsAdd = 'APPLICATION/CONDITION_DOCUMENTS/ADD',
    ApplicationConditionDocumentDownload = 'APPLICATION/CONDITION_DOCUMENTS/DOWNLOAD',
    ApplicationConditionDocumentsUpdatingSolicitorAccessSet = 'APPLICATION/CONDITION_DOCUMENTS/UPDATING_SOLICITOR_ACCESS/SET',

    ApplicationConditionDocumentsList = 'APPLICATION/CONDITION_DOCUMENTS/LIST',
    ApplicationConditionDocumentsSet = 'APPLICATION/CONDITION_DOCUMENTS/SET',

    ApplicationConditionDocumentApprove = 'APPLICATION/CONDITION_DOCUMENT/APPROVE',
    ApplicationConditionDocumentDelete = 'APPLICATION/CONDITION_DOCUMENT/DELETE',
    ApplicationConditionDocumentReject = 'APPLICATION/CONDITION_DOCUMENT/REJECT',
    ApplicationConditionDocumentRemove = 'APPLICATION/CONDITION_DOCUMENT/REMOVE',
    ApplicationConditionDocumentResetApprovalStatus = 'APPLICATION/CONDITION_DOCUMENT/RESET_APPROVAL_STATUS',
    ApplicationConditionDocumentSet = 'APPLICATION/CONDITION_DOCUMENT/SET',

    ApplicationDisbursementDelete = 'APPLICATION/DISBURSEMENT/DELETE',
    ApplicationDisbursementSend = 'APPLICATION/DISBURSEMENT/SEND',
    ApplicationDisbursementSet = 'APPLICATION/DISBURSEMENT/SET',
    ApplicationDisbursementValueSet = 'APPLICATION/DISBURSEMENT/VALUE/SET',

    ApplicationDisbursementsAdd = 'APPLICATION/DISBURSEMENTS/ADD',
    ApplicationDisbursementsList = 'APPLICATION/DISBURSEMENTS/LIST',
    ApplicationDisbursementsSet = 'APPLICATION/DISBURSEMENTS/SET',

    ApplicationDisbursementSuggestionsList = 'APPLICATION/DISBURSEMENTS/SUGGESTIONS/LIST',
    ApplicationDisbursementSuggestionsSet = 'APPLICATION/DISBURSEMENTS/SUGGESTIONS/SET',

    ApplicationFeeDelete = 'APPLICATION/FEE/DELETE',
    ApplicationFeeRemove = 'APPLICATION/FEE/REMOVE',
    ApplicationFeeSend = 'APPLICATION/FEE/SEND',
    ApplicationFeeSet = 'APPLICATION/FEE/SET',
    ApplicationFeeValueSet = 'APPLICATION/FEE/VALUE/SET',

    ApplicationFeesAdd = 'APPLICATION/FEES/ADD',
    ApplicationFeesRemove = 'APPLICATION/FEES/REMOVE',
    ApplicationFeesList = 'APPLICATION/FEES/LIST',
    ApplicationFeesSet = 'APPLICATION/FEES/SET',

    ApplicationFormalsAdd = 'APPLICATION/FORMALS/ADD',
    ApplicationFormalsList = 'APPLICATION/FORMALS/LIST',
    ApplicationFormalsSet = 'APPLICATION/FORMALS/SET',

    ApplicationHistoriesList = 'APPLICATION/HISTORIES/LIST',
    ApplicationHistoriesSet = 'APPLICATION/HISTORIES/SET',

    ApplicationNotesAdd = 'APPLICATION/NOTES/ADD',
    ApplicationNotesList = 'APPLICATION/NOTES/LIST',
    ApplicationNotesSet = 'APPLICATION/NOTES/SET',

    ApplicationNoteRemove = 'APPLICATION/NOTE/REMOVE',
    ApplicationNoteSet = 'APPLICATION/NOTE/SET',

    ApplicationMarkBrokerCommissionPaid = 'APPLICATION/MARK_BROKER_COMMISSION_PAID',

    ApplicationPropertiesList = 'APPLICATION/PROPERTIES/LIST',
    ApplicationPropertiesSet = 'APPLICATION/PROPERTIES/SET',

    ApplicationPropertiesValuationsOutstandingList = 'APPLICATION_PROPERTIES/VALUATIONS_OUTSTANDING/LIST',
    ApplicationPropertiesValuationsOutstandingSet = 'APPLICATION_PROPERTIES/VALUATIONS_OUTSTANDING/SET',

    ApplicationPropertyGet = 'APPLICATION/PROPERTY/GET',
    ApplicationPropertySet = 'APPLICATION/PROPERTY/SET',

    ApplicationPropertyValuationDueDate = 'APPLICATION/PROPERTY/VALUATION_DUE_DATE',
    ApplicationPropertyValuationInspectionDate = 'APPLICATION/PROPERTY/VALUATION_INSPECTION_DATE',
    ApplicationPropertyValuationReceivedDate = 'APPLICATION/PROPERTY/VALUATION_RECEIVED_DATE',
    ApplicationPropertyValuationValue = 'APPLICATION/PROPERTY/VALUATION_VALUE',

    ApplicationPropertyValuerQuoteAmount = 'APPLICATION/PROPERTY/VALUER/QUOTE_AMOUNT',
    ApplicationPropertyValuerQuoteReceived = 'APPLICATION/PROPERTY/VALUER/QUOTE_RECEIVED',
    ApplicationPropertyValuerQuoteRequested = 'APPLICATION/PROPERTY/VALUER/QUOTE_REQUESTED',
    ApplicationPropertyValuerQuoteTimeFrame = 'APPLICATION/PROPERTY/VALUER/QUOTE_TIME_FRAME',
    ApplicationPropertyValuerSet = 'APPLICATION/PROPERTY/VALUER/SET',
    ApplicationPropertyValuerValuationOrdered = 'APPLICATION/PROPERTY/VALUER/VALUATION_ORDERED',
    ApplicationPropertyValuerValuationReceived = 'APPLICATION/PROPERTY/VALUER/VALUATION_RECEIVED',

    ApplicationPropertyValuersList = 'APPLICATION/PROPERTY/VALUERS/LIST',
    ApplicationPropertyValuersSet = 'APPLICATION/PROPERTY/VALUERS/SET',

    ApplicationPropertyValuerMarkPaid = 'APPLICATION_PROPERTY_VALUER/MARK_PAID',

    ApplicationPropertyValuersUnpaidList = 'APPLICATION_PROPERTY_VALUERS/UNPAID/LIST',
    ApplicationPropertyValuersUnpaidSet = 'APPLICATION_PROPERTY_VALUERS/UNPAID/SET',

    ApplicationRfiItemDelete = 'APPLICATION/RFI_ITEM/DELETE',
    ApplicationRfiItemRemove = 'APPLICATION/RFI_ITEM/REMOVE',
    ApplicationRfiItemRequested = 'APPLICATION/RFI_ITEM/REQUESTED',
    ApplicationRfiItemSet = 'APPLICATION/RFI_ITEM/SET',
    ApplicationRfiItemSupplied = 'APPLICATION/RFI_ITEM/SUPPLIED',

    ApplicationRfiItemsAdd = 'APPLICATION/RFI_ITEMS/ADD',
    ApplicationRfiItemsList = 'APPLICATION/RFI_ITEMS/LIST',
    ApplicationRfiItemsSet = 'APPLICATION/RFI_ITEMS/SET',

    ApplicationWarehouseDelete = 'APPLICATION/WAREHOUSE/DELETE',
    ApplicationWarehouseSend = 'APPLICATION/WAREHOUSE/SEND',
    ApplicationWarehouseSet = 'APPLICATION/WAREHOUSE/SET',
    ApplicationWarehouseValueSet = 'APPLICATION/WAREHOUSE/VALUE/SET',

    ApplicationWarehousesAdd = 'APPLICATION/WAREHOUSES/ADD',
    ApplicationWarehousesList = 'APPLICATION/WAREHOUSES/LIST',
    ApplicationWarehousesSet = 'APPLICATION/WAREHOUSES/SET',
}

export default ApplicationsActionsEnum;
