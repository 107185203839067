import { UnorderedListOutlined } from '@ant-design/icons';
import { Button, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import IInvestmentTransaction from '~Api/Investment/IInvestmentTransaction';
import {
    investmentGetAction,
    investmentTransactionsListAction,
} from '~Investments/actions';
import {
    investmentSelector,
    investmentTransactionsSelector,
} from '~Investments/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import TransactionModal from './TransactionModal';

interface IState {
    modalIsOpen: boolean;
    modalUuid: string;
}

interface IMatch {
    investmentUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    investment: IInvestment;
    transactions: IDictionary<IInvestmentTransaction>;
}

interface IPropsDispatch {
    investmentGet: () => void;
    transactionsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Transactions extends React.Component<Props, IState> {
    public state: IState = {
        modalIsOpen: false,
        modalUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickInvestors = this.onClickInvestors.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    public componentDidMount(): void {
        const { investment, transactions } = this.props;

        if (!investment) {
            this.props.investmentGet();
        }

        if (!transactions) {
            this.props.transactionsList();
        }
    }

    public render(): JSX.Element {
        const { investment, match, transactions } = this.props;
        const { modalIsOpen, modalUuid } = this.state;

        if (!investment || !transactions) {
            return (
                <Layout uuid={match.params.investmentUuid} section='transactions'>
                    <Typography.Title level={2}>Transactions</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnType<IInvestmentTransaction>[] = [
            {
                dataIndex: 'transactionTime',
                defaultSortOrder: 'descend',
                render: (transactionTime: string) => dayjs(transactionTime).format('Do MMMM YYYY HH:mm:ss'),
                sorter: (a: IInvestmentTransaction, b: IInvestmentTransaction) => dayjs(a.transactionTime) > dayjs(b.transactionTime)  ? 1 : -1,
                title: 'Date',
                width: '20%',
            },
            {
                dataIndex: 'description',
                title: 'Description',
            },
            {
                dataIndex: 'amountPrincipal',
                render: (amountPrincipal: number) => currencyFormatter.format(amountPrincipal),
                title: 'Principal',
                width: '15%',
            },
            {
                dataIndex: 'amountInterest',
                render: (amountInterest: number) => currencyFormatter.format(amountInterest),
                title: 'Interest',
                width: '15%',
            },
            {
                dataIndex: 'amountInvestment',
                render: (amountInvestment: number) => currencyFormatter.format(amountInvestment),
                title: 'Investment',
                width: '15%',
            },
            {
                render: (transaction: IInvestmentTransaction) => {
                    const modalBlock: JSX.Element = modalUuid === transaction.uuid && (
                        <TransactionModal
                            investmentTransactionUuid={transaction.uuid}
                            isOpen={modalIsOpen}
                            onCancel={this.onCloseModal}
                        />
                    );

                    const onClick: () => void = () => this.onClickInvestors(transaction.uuid);

                    return (
                        <>
                            <Button icon={<UnorderedListOutlined/>} onClick={onClick} />
                            {modalBlock}
                        </>
                    );
                },
                title: '',
                width: '2%',
            },
        ];

        return (
            <Layout uuid={match.params.investmentUuid} section='transactions'>
                <Typography.Title level={2}>Transactions</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(transactions)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                />
            </Layout>
        );
    }

    private onClickInvestors(transactionUuid: string): void {
        this.setState({
            modalIsOpen: true,
            modalUuid: transactionUuid,
        });
    }

    private onCloseModal(): void {
        this.setState({
            modalIsOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investment: investmentSelector(state, ownProps.match.params.investmentUuid),
        transactions: investmentTransactionsSelector(state, ownProps.match.params.investmentUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investmentGet: () => dispatch(investmentGetAction(ownProps.match.params.investmentUuid)),
        transactionsList: () => dispatch(investmentTransactionsListAction(ownProps.match.params.investmentUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Transactions);
