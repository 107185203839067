import { WarningFilled } from '@ant-design/icons';
import { Descriptions, Spin, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import AvailableFundsTypeEnum from '~Api/Investor/AvailableFundsTypeEnum';
import IdProofTypeEnum from '~Api/Investor/IdProofTypeEnum';
import IIndividual from '~Api/Investor/IIndividual';
import IInvestor from '~Api/Investor/IInvestor';
import InvestorTypeEnum from '~Api/Investor/InvestorTypeEnum';
import investorTypeLabels from '~Api/Investor/investorTypeLabels';
import SourceEnum from '~Api/Investor/SourceEnum';
import { investorGetAction } from '~Investors/actions';
import { investorSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import {
    individualRequiresTfn,
    isTfnValid,
} from '~utilities/tfn';
import Layout from './Layout';

const availableFundsTypeLabels: IDictionary<string> = {
    [AvailableFundsTypeEnum.UnderTenThousand]: 'Under $10,000',
    [AvailableFundsTypeEnum.TenToOneHundredThousand]: 'Between $10,000 and $100,000',
    [AvailableFundsTypeEnum.OneHundredToTwoHundredFiftyThousand]: 'Between $100,000 and $250,000',
    [AvailableFundsTypeEnum.TwoHundredFiftyToFiveHundredThousand]: 'Between $250,000 and $500,000',
    [AvailableFundsTypeEnum.FiveHundredThousandToOneMillion]: 'Between $500,000 and $1m',
    [AvailableFundsTypeEnum.OverOneMillion]: '$1m +',
};

const sourceLabels: IDictionary<string> = {
    [SourceEnum.Bing]: 'Bing',
    [SourceEnum.Facebook]: 'Facebook',
    [SourceEnum.FacebookPaid]: 'Facebook Paid',
    [SourceEnum.Google]: 'Google',
};

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    investor: IInvestor;
}

interface IPropsDispatch {
    investorGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Overview extends React.Component<Props> {
    public componentDidMount(): void {
        const { investor } = this.props;

        if (!investor) {
            this.props.investorGet();
        }
    }

    public render(): JSX.Element {
        const { investor, match } = this.props;

        if (!investor) {
            return (
                <Layout uuid={match.params.uuid} section='overview'>
                    <Typography.Title level={2}>Overview</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const individualName: string = [InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany].includes(investor.type) ? 'Director' : 'Individual';

        const individualsBlock: JSX.Element[] = investor.individuals.map((individual: IIndividual, index: number) => {
            let idProofBlock: JSX.Element = null;

            switch (individual.idProofType) {
                case IdProofTypeEnum.DriverLicence:
                    idProofBlock = (
                        <React.Fragment>
                            <Descriptions.Item label='ID Proof Type'>Australian Driver Licence</Descriptions.Item>
                            <Descriptions.Item label='Licence Number'>{individual.idProofNumber || '-'}</Descriptions.Item>
                            <Descriptions.Item label='Licence Card Number'>{individual.driverLicenceCardNumber || '-'}</Descriptions.Item>
                            <Descriptions.Item label='Licence State'>{individual.driverLicenceState || '-'}</Descriptions.Item>
                            <Descriptions.Item label='Licence Expiry Date'>{individual.idProofExpiryDate || '-'}</Descriptions.Item>
                        </React.Fragment>
                    );

                    break;
                case IdProofTypeEnum.Passport:
                    idProofBlock = (
                        <React.Fragment>
                            <Descriptions.Item label='ID Proof Type'>Passport</Descriptions.Item>
                            <Descriptions.Item label='Passport Number'>{individual.idProofNumber || '-'}</Descriptions.Item>
                            <Descriptions.Item label='Passport Expiry Date'>{individual.idProofExpiryDate || '-'}</Descriptions.Item>
                        </React.Fragment>
                    );

                    break;
            }

            return (
                <div key={index}>
                    <Descriptions bordered={true} layout='vertical' title={`${individualName} ${index + 1}`}>
                        <Descriptions.Item label='Name'>{individual.firstName || individual.lastName ? `${individual.firstName || '-'} ${individual.lastName || ''}` : '-'}</Descriptions.Item>
                        <Descriptions.Item label='Email'>{individual.email ? <a href={`mailto:${individual.email}`}>{individual.email}</a> : '-'}</Descriptions.Item>
                        <Descriptions.Item label='Date of Birth'>{individual.dateOfBirth || '-'}</Descriptions.Item>
                        <Descriptions.Item label='TFN'>{individual.taxFileNumber || '-'} {individualRequiresTfn(individual) && !isTfnValid(individual.taxFileNumber) && <WarningFilled/>}</Descriptions.Item>
                        <Descriptions.Item label='Street Address'>{individual.streetAddress || '-'}</Descriptions.Item>
                        <Descriptions.Item label='Suburb'>{individual.suburb || '-'}</Descriptions.Item>
                        <Descriptions.Item label='Postcode'>{individual.postcode || '-'}</Descriptions.Item>
                        <Descriptions.Item label='State'>{individual.state || '-'}</Descriptions.Item>
                        {idProofBlock}
                    </Descriptions>
                </div>
            );
        });

        const trustBlock: JSX.Element = [InvestorTypeEnum.TrustCompany, InvestorTypeEnum.TrustIndividual].includes(investor.type) && (
            <React.Fragment>
                <Descriptions bordered={true} layout='vertical' title='Trust'>
                    <Descriptions.Item label='Name'>{investor.trust.name || '-'}</Descriptions.Item>
                    <Descriptions.Item label='ABN'>{investor.trust.australianBusinessNumber || '-'}</Descriptions.Item>
                    <Descriptions.Item label='TFN'>{investor.trust.taxFileNumber || '-'} {investor.trust.taxFileNumber && !isTfnValid(investor.trust.taxFileNumber) && <WarningFilled/>}</Descriptions.Item>
                </Descriptions>
            </React.Fragment>
        );

        const companyBlock: JSX.Element = [InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany].includes(investor.type) && (
            <React.Fragment>
                <Descriptions bordered={true} layout='vertical' title='Company'>
                    <Descriptions.Item label='Name'>{investor.company.name || '-'}</Descriptions.Item>
                    <Descriptions.Item label='ACN'>{investor.company.australianCompanyNumber || '-'}</Descriptions.Item>
                    <Descriptions.Item label='TFN'>{investor.company.taxFileNumber || '-'} {!isTfnValid(investor.company.taxFileNumber) && <WarningFilled/>}</Descriptions.Item>
                    <Descriptions.Item label='Street Address'>{investor.company.streetAddress || '-'}</Descriptions.Item>
                    <Descriptions.Item label='Suburb'>{investor.company.suburb || '-'}</Descriptions.Item>
                    <Descriptions.Item label='Postcode'>{investor.company.postcode || '-'}</Descriptions.Item>
                    <Descriptions.Item label='State'>{investor.company.state || '-'}</Descriptions.Item>
                </Descriptions>
            </React.Fragment>
        );

        return (
            <Layout uuid={match.params.uuid} section='overview'>
                <Typography.Title level={2}>Overview</Typography.Title>
                <Descriptions bordered={true} layout='vertical'>
                    <Descriptions.Item label='Type'>{investorTypeLabels[investor.type]}</Descriptions.Item>
                    <Descriptions.Item label='Available Funds' span={2}>{availableFundsTypeLabels[investor.availableFundsType]}</Descriptions.Item>
                    <Descriptions.Item label='Source'>{investor.source ? sourceLabels[investor.source] : '-'}</Descriptions.Item>
                    <Descriptions.Item label='Landing Page' span={2}>{investor.landingPage || '-'}</Descriptions.Item>
                </Descriptions>
                {trustBlock}
                {companyBlock}
                <div>{individualsBlock}</div>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investor: investorSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investorGet: () => dispatch(investorGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Overview);
