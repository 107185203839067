import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Spin } from 'antd';
import _ from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import {
    administratorsActiveSelector,
    administratorsSelector,
} from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import RoleEnum from '~Api/Administrator/RoleEnum';
import IBroker from '~Api/Broker/IBroker';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import {
    brokerAssignBdmAction,
    brokerUnassignBdmAction,
} from '~Brokers/actions';
import menuDivider from '~UI/menuDivider';
import constants from '~constants';
import { IDictionary } from '~utilities/IDictionary';

export default function BdmSelector(props: {
    broker: IBroker;
}): ReactElement {
    const { broker } = props;

    const activeAdministrators: IAdministrator[] = useSelector(administratorsActiveSelector);
    const administrators: IDictionary<IAdministrator> = useSelector(administratorsSelector);
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!activeAdministrators || !administrators) {
            dispatch(administratorsListAction());
        }
    }, []);

    if (!activeAdministrators || !administrators || !broker) {
        return <Spin size='small' />;
    }

    const assignee: IAdministrator = !!broker.bdmUuid && administrators[broker.bdmUuid];
    const assigneeName: string = broker.bdmUuid === constants.BROKER_BDM_UUID_HEAD_OFFICE ? 'Head Office' : (broker.bdmUuid ? (assignee ? assignee.name : 'Inactive User') : 'None');

    if (!currentUser.permissions.includes(PermissionsEnum.BrokerAssignBdm)) {
        return <>{assigneeName}</>;
    }

    const dropdown: MenuProps = {
        items: [
            {
                key: 'none',
                label: 'None',
                onClick: () => dispatch(brokerUnassignBdmAction(broker.uuid)),
            },
            {
                key: constants.BROKER_BDM_UUID_HEAD_OFFICE,
                label: 'Head Office',
                onClick: () => dispatch(brokerAssignBdmAction(broker.uuid, constants.BROKER_BDM_UUID_HEAD_OFFICE)),
            },
            menuDivider,
            ..._.sortBy(_.filter(activeAdministrators, (admin: IAdministrator) => [
                RoleEnum.BusinessDevelopmentManager,
                RoleEnum.SeniorBusinessDevelopmentManager,
            ].includes(admin.role)), ['name']).map((administrator: IAdministrator) => ({
                key: administrator.uuid,
                label: administrator.name,
                onClick: () => dispatch(brokerAssignBdmAction(broker.uuid, administrator.uuid)),
            })),
        ],
    };

    return (
        <Dropdown menu={dropdown}>
            <a>
                {assigneeName}
                {' '}<DownOutlined/>
            </a>
        </Dropdown>
    );
}
