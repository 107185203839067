import { Alert, Layout as AntLayout, Button, Form, Input, Spin, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import Layout from '~Layout';
import { IGlobalState } from '~reducer';
import { authJokeGetAction, authLoginAction } from './actions';
import './auth.less';
import {
    authJokeSelector,
    authLoginErrorsSelector,
    authLoginInProgressSelector,
    authUsernameSelector,
} from './selectors';
import MoveForwardFaster from '~UI/MoveForwardFaster';

interface IState {
    email: string;
}

interface IPropsSelector {
    errors: {
        email?: string;
    };
    inProgress: boolean;
    joke: string;
    username: string;
}

interface IPropsDispatch {
    jokeGet: () => void;
    login: (email: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Login extends React.Component<Props, IState> {
    public state: IState = {
        email: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onClickPasskey = this.onClickPasskey.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
    }

    public componentDidMount(): void {
        const { joke } = this.props;

        if (!joke) {
            this.props.jokeGet();
        }
    }

    public render(): JSX.Element {
        const { errors, inProgress, joke, username } = this.props;
        const { email } = this.state;

        return (
            <Layout topSection='login'>
                <AntLayout className='login'>
                    <AntLayout className='content-wrapper'>
                        <AntLayout.Content className='content'>
                            <Typography.Title level={2}>Login</Typography.Title>
                            <Form.Item help={errors.email} className='email' validateStatus={errors.email && 'error'}>
                                <Input autoFocus={true} onChange={this.onChangeEmail} onKeyUp={this.onKeyUp} placeholder='Email' value={email || username || ''} autoComplete='username email webauthn' />
                            </Form.Item>
                            <Form.Item>
                                <Alert message={joke || <Spin/>} type='info' />
                            </Form.Item>
                            <Form.Item>
                                <Link className='account-recovery' to='/account-recovery'>Account Recovery</Link>
                                <Button disabled={(!email && !username) || inProgress} loading={inProgress} onClick={this.onClickPasskey} type='primary' onSubmit={this.onClickPasskey}>
                                    Login
                                    <MoveForwardFaster/>
                                </Button>
                            </Form.Item>
                        </AntLayout.Content>
                    </AntLayout>
                </AntLayout>
            </Layout>
        );
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            email: event.target.value,
        });
    }

    private onClickPasskey(): void {
        const { username } = this.props;
        const { email } = this.state;

        this.props.login(email || username);
    }

    private onKeyUp(event: React.KeyboardEvent<HTMLInputElement>): void {
        if (event.key === 'Enter') {
            this.onClickPasskey();
        }
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        errors: authLoginErrorsSelector(state),
        inProgress: authLoginInProgressSelector(state),
        joke: authJokeSelector(state),
        username: authUsernameSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        jokeGet: () => dispatch(authJokeGetAction()),
        login: (email: string) => dispatch(authLoginAction(email)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Login);
