import { Modal, Spin, Table, Tabs } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IInvestorAccountInvestment from '~Api/Investor/IInvestorAccountInvestment';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import {
    investorAccountInvestmentGetAction,
    investorAccountInvestmentTransactionsListAction,
} from '~Investors/actions';
import {
    investorAccountInvestmentSelector,
    investorAccountInvestmentTransactionsSelector,
} from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

interface ITransaction {
    amount: number;
    balance: number;
    description: string;
    transactionTime: string;
    uuid: string;
}

interface IProps {
    investorAccountInvestmentUuid: string;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsSelector {
    investorAccountInvestment: IInvestorAccountInvestment;
    transactions: IDictionary<IInvestorAccountInvestmentTransaction>;
}

interface IPropsDispatch {
    investorAccountInvestmentGet: () => void;
    transactionsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class InvestmentTransactions extends React.Component<Props> {
    public componentDidMount(): void {
        const { investorAccountInvestment } = this.props;

        if (!investorAccountInvestment) {
            this.props.investorAccountInvestmentGet();
        }

        this.props.transactionsList();
    }

    public render(): JSX.Element {
        const { investorAccountInvestment, isOpen, transactions } = this.props;

        if (!investorAccountInvestment || !transactions) {
            return (
                <Modal
                    footer={false}
                    onCancel={this.props.onCancel}
                    open={isOpen}
                    title='Transaction Details'
                    width={1000}
                >
                    <Spin/>
                </Modal>
            );
        }

        let interestBalance: number = 0;
        let principalBalance: number = 0;
        const interestTransactions: ITransaction[] = [];
        const principalTransactions: ITransaction[] = [];

        _.each(_.sortBy(transactions, ['transactionTime']), (transaction: IInvestorAccountInvestmentTransaction) => {
            if (transaction.interestAmount !== 0) {
                interestBalance += transaction.interestAmount;

                interestTransactions.push({
                    amount: transaction.interestAmount,
                    balance: interestBalance,
                    description: transaction.description,
                    transactionTime: transaction.transactionTime,
                    uuid: transaction.uuid,
                });
            }

            if (transaction.principalAmount !== 0) {
                principalBalance += transaction.principalAmount;

                principalTransactions.push({
                    amount: transaction.principalAmount,
                    balance: principalBalance,
                    description: transaction.description,
                    transactionTime: transaction.transactionTime,
                    uuid: transaction.uuid,
                });
            }
        });

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnType<ITransaction>[] = [
            {
                dataIndex: 'transactionTime',
                defaultSortOrder: 'descend',
                render: (transactionTime: string) => dayjs(transactionTime).format('D/M/YYYY HH:mm'),
                sorter: (a: ITransaction, b: ITransaction) => {
                    return a.transactionTime > b.transactionTime ? 1 : -1;
                },
                title: 'Date',
                width: '15%',
            },
            {
                dataIndex: 'description',
                title: 'Description',
            },
            {
                dataIndex: 'amount',
                render: (amount: number) => currencyFormatter.format(amount),
                title: 'Amount',
                width: '15%',
            },
            {
                dataIndex: 'balance',
                render: (balance: number) => currencyFormatter.format(balance),
                title: 'Balance',
                width: '15%',
            },
        ];

        return (
            <Modal
                footer={false}
                onCancel={this.props.onCancel}
                open={isOpen}
                title='Transaction Details'
                width={1000}
            >
                <Tabs defaultActiveKey='principal'>
                    <Tabs.TabPane key='principal' tab='Principal'>
                        <Table
                            columns={columns}
                            dataSource={principalTransactions}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='interest' tab='Interest'>
                        <Table
                            columns={columns}
                            dataSource={interestTransactions}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Tabs.TabPane>
                </Tabs>
            </Modal>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investorAccountInvestment: investorAccountInvestmentSelector(state, ownProps.investorAccountInvestmentUuid),
        transactions: investorAccountInvestmentTransactionsSelector(state, ownProps.investorAccountInvestmentUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investorAccountInvestmentGet: () => dispatch(investorAccountInvestmentGetAction(ownProps.investorAccountInvestmentUuid)),
        transactionsList: () => dispatch(investorAccountInvestmentTransactionsListAction(ownProps.investorAccountInvestmentUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InvestmentTransactions);
