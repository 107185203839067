import { Button, Form, Modal, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { currentAdministratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import ExtensionTypeEnum from '~Api/Application/ExtensionTypeEnum';
import IApplication from '~Api/Application/IApplication';
import { IGlobalState } from '~reducer';
import DatePicker from '~UI/DatePicker';
import { applicationSettlementDateAction } from './actions';

interface IState {
    isSettlementDateModalOpen: boolean;
    settlementDate: string;
}

interface IProps {
    application: IApplication;
}

interface IPropsSelector {
    currentAdministrator: IAdministrator;
}

interface IPropsDispatch {
    settlementDate: (settlementDate: string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class SettlementDate extends React.Component<Props, IState> {
    public state: IState = {
        isSettlementDateModalOpen: false,
        settlementDate: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickSettlementDate = this.onClickSettlementDate.bind(this);
        this.onSettlementDateCancel = this.onSettlementDateCancel.bind(this);
        this.onChangeSettlementDate = this.onChangeSettlementDate.bind(this);
        this.onClickClear = this.onClickClear.bind(this);
        this.onClickOk = this.onClickOk.bind(this);

        this.state.settlementDate = props.application.settlementDate;
    }

    public render(): JSX.Element {
        const { application, currentAdministrator } = this.props;
        const { isSettlementDateModalOpen, settlementDate } = this.state;

        if (!currentAdministrator) {
            return null;
        }

        const isRenewal: boolean = ExtensionTypeEnum.Renewal === application.extensionType;

        const settlementDateDayjs: Dayjs = settlementDate ? dayjs(settlementDate) : null;

        const formattedDate: string = settlementDateDayjs ? settlementDateDayjs.format('D/M') : 'Unknown';

        return (
            <React.Fragment>
                {!isRenewal && ![RoleEnum.BusinessDevelopmentManager, RoleEnum.InternalBusinessDevelopmentManager, RoleEnum.SeniorBusinessDevelopmentManager].includes(currentAdministrator.role) && <a onClick={this.onClickSettlementDate}>{formattedDate}</a>}
                {(isRenewal || [RoleEnum.BusinessDevelopmentManager, RoleEnum.InternalBusinessDevelopmentManager, RoleEnum.SeniorBusinessDevelopmentManager].includes(currentAdministrator.role)) && formattedDate}
                <Modal
                    destroyOnClose={true}
                    okText='Update'
                    okType='danger'
                    onCancel={this.onSettlementDateCancel}
                    onOk={this.onClickOk}
                    open={isSettlementDateModalOpen}
                    title='Change Settlement Date'
                    wrapClassName='application-settlement-date-modal'
                >
                    <Form.Item label='Settlement Date' className='settlement-date'>
                        <Space>
                            <DatePicker onChange={this.onChangeSettlementDate} format='DD/MM/YYYY' value={settlementDateDayjs} />
                            <Button onClick={this.onClickClear}>Clear</Button>
                        </Space>
                    </Form.Item>
                </Modal>
            </React.Fragment>
        );
    }

    private onClickSettlementDate(): void {
        this.setState({
            isSettlementDateModalOpen: true,
        });
    }

    private onSettlementDateCancel(): void {
        this.setState({
            isSettlementDateModalOpen: false,
        });
    }

    private onChangeSettlementDate(date: Dayjs): void {
        this.setState({
            settlementDate: date ? date.format('YYYY-MM-DD') : null,
        });
    }

    private onClickClear(): void {
        this.setState({
            settlementDate: null,
        });
    }

    private onClickOk(): void {
        const { settlementDate } = this.state;

        this.setState({
            isSettlementDateModalOpen: false,
        });

        this.props.settlementDate(settlementDate);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentAdministrator: currentAdministratorSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        settlementDate: (settlementDate: string) => dispatch(applicationSettlementDateAction(ownProps.application.uuid, settlementDate)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettlementDate);
