import { Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ILoanFee from '~Api/Loan/ILoanFee';
import { loanFeesAddAction } from '~Loans/actions';

interface IState {
    amount: number;
    description: string;
}

interface IProps {
    isOpen: boolean;
    loanUuid: string;
    onClose: () => void;
}

interface IPropsDispatch {
    add: (fee: ILoanFee) => void;
}

type Props = IProps & IPropsDispatch;

class FeeAddModal extends React.Component<Props, IState> {
    public state: IState = {
        amount: null,
        description: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { amount, description } = this.state;

        return (
            <Modal
                destroyOnClose={true}
                okText='Add'
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Fee/Outlay'
                wrapClassName='loan-fee-add-modal'
            >
                <Form.Item label='Amount' className='amount'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeAmount} type='number' value={amount} />
                </Form.Item>
                <Form.Item label='Description' className='description'>
                    <Input maxLength={255} onChange={this.onChangeDescription} value={description} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeAmount(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            amount: Number(event.target.value),
        });
    }

    private onChangeDescription(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            description: event.target.value,
        });
    }

    private onClickOk() {
        const { amount, description } = this.state;

        this.props.add({
            amount,
            description,
        });

        this.props.onClose();
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        add: (fee: ILoanFee) => dispatch(loanFeesAddAction(ownProps.loanUuid, fee)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(FeeAddModal);
