import { Layout as AntLayout, Breadcrumb, Button, Descriptions, Menu, PageHeader, Spin } from 'antd';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import IAdviser from '~Api/Adviser/IAdviser';
import {
    adviserApproveAction,
    adviserGetAction,
    adviserRejectAction,
    adviserResetApprovalStatusAction,
} from '~Advisers/actions';
import { adviserSelector } from '~Advisers/selectors';
import { IGlobalState } from '~reducer';
import './adviser.less';
import ApprovalStatusEnum from '~Api/Adviser/ApprovalStatusEnum';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import menuDivider from '~UI/menuDivider';

export default function Layout(props: {
    adviserUuid: string;
    breadcrumb?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    section: string;
}): ReactElement {
    const { adviserUuid, breadcrumb, children, section } = props;

    const [ approvalInProgress, setApprovalInProgress ] = useState<boolean>(false);

    const adviser: IAdviser = useSelector((state: IGlobalState) => adviserSelector(state, adviserUuid));

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!adviser) {
            dispatch(adviserGetAction(adviserUuid));
        }
    }, []);

    const onClickApprove: () => void = useCallback(() => {
        setApprovalInProgress(true);

        dispatch(adviserApproveAction(adviserUuid));
    }, []);

    const onClickReject: () => void = useCallback(() => {
        dispatch(adviserRejectAction(adviserUuid));
    }, []);

    const onClickResetStatus: () => void = useCallback(() => {
        setApprovalInProgress(false);

        dispatch(adviserResetApprovalStatusAction(adviserUuid));
    }, []);

    if (!adviser) {
        return (
            <AntLayout className='adviser'>
                <Spin />
            </AntLayout>
        );
    }

    const breadcrumbBlock: JSX.Element = breadcrumb ? (
        <>
            <Breadcrumb.Item><Link to={`/advisers/${adviser.uuid}`}>{adviser.formattedName}</Link></Breadcrumb.Item>
            {breadcrumb}
        </>
    ) : (
        <Breadcrumb.Item>{adviser.formattedName}</Breadcrumb.Item>
    );

    const extra: JSX.Element = (
        <>
            {(adviser.approvalStatus === ApprovalStatusEnum.Pending || adviser.approvalStatus === ApprovalStatusEnum.Legacy) && <Button danger={true} disabled={approvalInProgress} onClick={onClickReject}>Reject</Button>}
            {(adviser.approvalStatus === ApprovalStatusEnum.Pending || adviser.approvalStatus === ApprovalStatusEnum.Legacy) && <Button type='default' disabled={approvalInProgress} loading={approvalInProgress} onClick={onClickApprove}>Approve</Button>}
            {(adviser.approvalStatus !== ApprovalStatusEnum.Pending && adviser.approvalStatus !== ApprovalStatusEnum.Legacy) && <Button type='default' onClick={onClickResetStatus}>{adviser.approvalStatus === ApprovalStatusEnum.Approved ? 'Unapprove' : 'Unreject'}</Button>}
        </>
    );

    const menuItems: ItemType[] = [
        {
            key: 'overview',
            label: <NavLink to={`/advisers/${adviser.uuid}`}>Overview</NavLink>,
        },
        {
            key: 'edit',
            label: <NavLink to={`/advisers/${adviser.uuid}/edit`}>Edit</NavLink>,
        },
        {
            key: 'investors',
            label: <NavLink to={`/advisers/${adviser.uuid}/investors`}>Investors</NavLink>,
        },
        {
            key: 'notes',
            label: <NavLink to={`/advisers/${adviser.uuid}/notes`}>Notes</NavLink>,
        },
        menuDivider,
        {
            key: 'history',
            label: <NavLink to={`/advisers/${adviser.uuid}/history`}>History</NavLink>,
        },
    ];

    return (
        <AntLayout className='adviser'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/advisers'>Advisers</Link></Breadcrumb.Item>
                {breadcrumbBlock}
            </Breadcrumb>
            <PageHeader
                className='header'
                extra={extra}
                ghost={false}
                subTitle={adviser.code}
                title={adviser.formattedName}
            >
                <Descriptions size='small' column={4}>
                    <Descriptions.Item label='Phone'><a href={`tel:${adviser.phone}`}>{adviser.phone}</a></Descriptions.Item>
                    <Descriptions.Item label='Email'><a href={`mailto:${adviser.email}`}>{adviser.email}</a></Descriptions.Item>
                    <Descriptions.Item label='Company'>{adviser.companyName || '-'}</Descriptions.Item>
                    <Descriptions.Item label='Dealer Group'>{adviser.dealerGroup || '-'}</Descriptions.Item>
                    <Descriptions.Item label='AFSL Number'>{adviser.afslNumber || '-'}</Descriptions.Item>
                    <Descriptions.Item label='Authorised Representative Number'>{adviser.authorisedRepresentativeNumber || '-'}</Descriptions.Item>
                </Descriptions>
            </PageHeader>
            <AntLayout className='content-wrapper'>
                <AntLayout.Sider className='menu' theme='light' width={240}>
                    <Menu
                        defaultSelectedKeys={[section]}
                        items={menuItems}
                        mode='inline'
                    />
                </AntLayout.Sider>
                <AntLayout.Content className={`content ${section}`}>
                    {children}
                </AntLayout.Content>
            </AntLayout>
        </AntLayout>
    );
}
