import { Button, Descriptions, Dropdown, MenuProps, Modal, Space, Spin, Tag, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import {
    investorAccountApproveAction,
    investorAccountRejectAction,
    investorAccountUnapproveAction,
    investorGetAction,
} from '~Investors/actions';
import {
    investorAccountSelector,
    investorSelector,
} from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import TopUpModal from './TopUpModal';
import WithdrawModal from './WithdrawModal';
import accountTypeLabels from '~Api/Investor/accountTypeLabels';
import { investorHasValidTfn, investorRequiresTfn } from '~utilities/tfn';
import AccountApprovalStatusEnum from '~Api/Investor/AccountApprovalStatusEnum';
import { currencyFormatter } from '~utilities/formatters';

interface IAccountApprovalStatusTag {
    colour: string;
    label: string;
}

const accountApprovalStatusTagDetails: IDictionary<IAccountApprovalStatusTag> = {
    [AccountApprovalStatusEnum.Approved]: {
        colour: 'green',
        label: 'Approved',
    },
    [AccountApprovalStatusEnum.Draft]: {
        colour: '',
        label: 'Draft',
    },
    [AccountApprovalStatusEnum.Legacy]: {
        colour: '',
        label: 'Legacy',
    },
    [AccountApprovalStatusEnum.Pending]: {
        colour: '',
        label: 'Pending',
    },
    [AccountApprovalStatusEnum.Rejected]: {
        colour: 'red',
        label: 'Rejected',
    },
};

interface IState {
    showTopUpModal: boolean;
    showWithdrawModal: boolean;
}

interface IMatch {
    accountUuid: string;
    investorUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    account: IAccount;
    currentUser: IAuthUser;
    investor: IInvestor;
}

interface IPropsDispatch {
    accountApprove: () => void;
    accountReject: () => void;
    accountUnapprove: () => void;
    investorGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Overview extends React.Component<Props, IState> {
    public state: IState = {
        showTopUpModal: false,
        showWithdrawModal: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickTopUp = this.onClickTopUp.bind(this);
        this.onCloseTopUp = this.onCloseTopUp.bind(this);
        this.onClickWithdraw = this.onClickWithdraw.bind(this);
        this.onCloseWithdraw = this.onCloseWithdraw.bind(this);
        this.onClickApprove = this.onClickApprove.bind(this);
        this.onClickReject = this.onClickReject.bind(this);
        this.onClickUnapprove = this.onClickUnapprove.bind(this);
    }

    public componentDidMount(): void {
        const { investor } = this.props;

        if (!investor) {
            this.props.investorGet();
        }
    }

    public render(): JSX.Element {
        const { account, currentUser, investor, match } = this.props;
        const { showTopUpModal, showWithdrawModal } = this.state;

        if (!investor) {
            return (
                <Layout uuid={match.params.investorUuid} section={`account-${match.params.accountUuid}`} sectionClass='account'>
                    <Typography.Title level={2}>Overview</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const approveMenu: MenuProps = {
            items: [
                ...(account.approvalStatus === AccountApprovalStatusEnum.Approved && [AccountTypeEnum.Marketplace, AccountTypeEnum.IncomeTrust].includes(account.accountType) ? [
                    {
                        key: 'unapprove',
                        label: 'Unapprove',
                        onClick: this.onClickUnapprove,
                    },
                ] : []),
                ...(account.approvalStatus !== AccountApprovalStatusEnum.Rejected && [AccountTypeEnum.Marketplace, AccountTypeEnum.IncomeTrust].includes(account.accountType) ? [
                    {
                        danger: true,
                        key: 'reject',
                        label: 'Reject',
                        onClick: this.onClickReject,
                    },
                ] : []),
            ],
        };

        return (
            <Layout uuid={match.params.investorUuid} section={`account-${match.params.accountUuid}`} sectionClass='account'>
                <Space className='actions'>
                    <Tag color={accountApprovalStatusTagDetails[account.approvalStatus].colour}>{accountApprovalStatusTagDetails[account.approvalStatus].label}</Tag>
                    {[AccountTypeEnum.Marketplace, AccountTypeEnum.IncomeTrust].includes(account.accountType) && <Button disabled={account.depositAmountPending > 0} onClick={this.onClickTopUp}>Top Up</Button>}
                    {[AccountTypeEnum.Marketplace, AccountTypeEnum.IncomeTrust].includes(account.accountType) && <Button onClick={this.onClickWithdraw}>Withdraw</Button>}
                    {(currentUser.permissions.includes(PermissionsEnum.InvestorPendingAccounts)) && account.approvalStatus !== AccountApprovalStatusEnum.Approved && [AccountTypeEnum.Marketplace, AccountTypeEnum.IncomeTrust].includes(account.accountType) && <Button onClick={this.onClickApprove} type='primary'>Approve</Button>}
                    {(currentUser.permissions.includes(PermissionsEnum.InvestorPendingAccounts)) && [AccountTypeEnum.Marketplace, AccountTypeEnum.IncomeTrust].includes(account.accountType) && <Dropdown.Button className='other-actions' onClick={this.onClickApprove} menu={approveMenu} />}
                </Space>
                <Typography.Title level={2}>Account Overview</Typography.Title>
                <Descriptions bordered={true} layout='vertical'>
                    <Descriptions.Item label='Type'>{accountTypeLabels[account.accountType]}</Descriptions.Item>
                    <Descriptions.Item label='Code'>{account.code}</Descriptions.Item>
                    <Descriptions.Item label='Cash Balance'>{currencyFormatter.format(account.balance)}</Descriptions.Item>
                    <Descriptions.Item label='Pending Top Up'>{currencyFormatter.format(account.depositAmountPending)}</Descriptions.Item>
                    <Descriptions.Item label='Currently Invested'>{currencyFormatter.format(account.investedAmountCurrent)}</Descriptions.Item>
                    <Descriptions.Item label='Total Portfolio'>{currencyFormatter.format(account.balance + account.investedAmountCurrent)}</Descriptions.Item>
                    <Descriptions.Item label='Interest Earned'>{currencyFormatter.format(account.interestAmountEarned)}</Descriptions.Item>
                    {AccountTypeEnum.IncomeTrust !== account.accountType && <Descriptions.Item label='Investment Count'>{account.investmentCountTotal}</Descriptions.Item>}
                </Descriptions>

                <TopUpModal
                    accountUuid={`${account.uuid}`}
                    onClose={this.onCloseTopUp}
                    show={showTopUpModal}
                />

                <WithdrawModal
                    accountUuid={`${account.uuid}`}
                    onClose={this.onCloseWithdraw}
                    show={showWithdrawModal}
                />
            </Layout>
        );
    }

    private onClickTopUp(): void {
        this.setState({
            showTopUpModal: true,
        });
    }

    private onCloseTopUp(): void {
        this.setState({
            showTopUpModal: false,
        });
    }

    private onClickWithdraw(): void {
        this.setState({
            showWithdrawModal: true,
        });
    }

    private onCloseWithdraw(): void {
        this.setState({
            showWithdrawModal: false,
        });
    }

    private onClickApprove(): void {
        const { investor } = this.props;

        if (investorRequiresTfn(investor) && !investorHasValidTfn(investor)) {
            Modal.error({
                content: 'This investor does not have a valid TFN',
                title: 'Invalid TFN',
            });

            return;
        }

        Modal.confirm({
            cancelText: 'No',
            content: 'Are you sure you want to approve this account?',
            okText: 'Yes',
            onOk: () => {
                this.props.accountApprove();
            },
            title: 'Approve',
        });
    }

    private onClickReject(): void {
        Modal.confirm({
            cancelText: 'No',
            content: 'Are you sure you want to reject this account?',
            okText: 'Yes',
            onOk: () => {
                this.props.accountReject();
            },
            title: 'Reject',
        });
    }

    private onClickUnapprove(): void {
        Modal.confirm({
            cancelText: 'No',
            content: 'Are you sure you want to unapprove this account?',
            okText: 'Yes',
            onOk: () => {
                this.props.accountUnapprove();
            },
            title: 'Unapprove',
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        account: investorAccountSelector(state, ownProps.match.params.accountUuid),
        currentUser: authCurrentUserSelector(state),
        investor: investorSelector(state, ownProps.match.params.investorUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        accountApprove: () => dispatch(investorAccountApproveAction(ownProps.match.params.accountUuid)),
        accountReject: () => dispatch(investorAccountRejectAction(ownProps.match.params.accountUuid)),
        accountUnapprove: () => dispatch(investorAccountUnapproveAction(ownProps.match.params.accountUuid)),
        investorGet: () => dispatch(investorGetAction(ownProps.match.params.investorUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Overview);
