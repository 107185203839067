import { Action } from 'redux';
import IDocument from '~Api/Loan/IDocument';
import IHistory from '~Api/Loan/IHistory';
import ILoan from '~Api/Loan/ILoan';
import ILoanDisbursement from '~Api/Loan/ILoanDisbursement';
import ILoanFee from '~Api/Loan/ILoanFee';
import ILoanGracePeriod from '~Api/Loan/ILoanGracePeriod';
import ILoanNote from '~Api/Loan/ILoanNote';
import ILoanPayoutFigure from '~Api/Loan/ILoanPayoutFigure';
import ILoanPayoutFigureItem from '~Api/Loan/ILoanPayoutFigureItem';
import ILoanPayoutFigureSection from '~Api/Loan/ILoanPayoutFigureSection';
import ILoanProperty from '~Api/Loan/ILoanProperty';
import ILoanTransaction from '~Api/Loan/ILoanTransaction';
import MaturityReminderStatusEnum from '~Api/Loan/MaturityReminderStatusEnum';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import LoansActionsEnum from './ActionsEnum';
import { ILoanListSettings } from './List';
import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import ListSortOrderEnum from '~Api/Loan/ListSortOrderEnum';

export interface ILoanActiveAction extends Action {
    uuid: string;
}

export function loanActiveAction(uuid: string): ILoanActiveAction {
    return {
        type: LoansActionsEnum.LoanActive,
        uuid,
    };
}

export interface ILoanAssignAction extends Action {
    administratorUuid: string;
    loanUuid: string;
}

export function loanAssignAction(loanUuid: string, administratorUuid: string): ILoanAssignAction {
    return {
        administratorUuid,
        loanUuid,
        type: LoansActionsEnum.LoanAssign,
    };
}

export interface ILoanDisbursementDeleteAction extends Action {
    disbursementUuid: string;
}

export function loanDisbursementDeleteAction(disbursementUuid: string): ILoanDisbursementDeleteAction {
    return {
        disbursementUuid,
        type: LoansActionsEnum.LoanDisbursementDelete,
    };
}

export interface ILoanDisbursementSendAction extends Action {
    disbursementUuid: string;
}

export function loanDisbursementSendAction(disbursementUuid: string): ILoanDisbursementSendAction {
    return {
        disbursementUuid,
        type: LoansActionsEnum.LoanDisbursementSend,
    };
}

export interface ILoanDisbursementSetAction extends Action {
    disbursement: ILoanDisbursement;
}

export function loanDisbursementSetAction(disbursement: ILoanDisbursement): ILoanDisbursementSetAction {
    return {
        disbursement,
        type: LoansActionsEnum.LoanDisbursementSet,
    };
}

export interface ILoanDisbursementValueSetAction extends Action {
    disbursementUuid: string;
    key: keyof ILoanDisbursement;
    value: boolean|number|string;
}

export function loanDisbursementValueSetAction(disbursementUuid: string, key: keyof ILoanDisbursement, value: boolean|number|string): ILoanDisbursementValueSetAction {
    return {
        disbursementUuid,
        key,
        type: LoansActionsEnum.LoanDisbursementValueSet,
        value,
    };
}

export interface ILoanDisbursementsAddAction extends Action {
    disbursement: ILoanDisbursement;
    loanUuid: string;
}

export function loanDisbursementsAddAction(loanUuid: string, disbursement: ILoanDisbursement): ILoanDisbursementsAddAction {
    return {
        disbursement,
        loanUuid,
        type: LoansActionsEnum.LoanDisbursementsAdd,
    };
}

export interface ILoanDisbursementsListAction extends Action {
    loanUuid: string;
}

export function loanDisbursementsListAction(loanUuid: string): ILoanDisbursementsListAction {
    return {
        loanUuid,
        type: LoansActionsEnum.LoanDisbursementsList,
    };
}

export interface ILoanDisbursementsSetAction extends Action {
    disbursements: ILoanDisbursement[];
    loanUuid: string;
}

export function loanDisbursementsSetAction(loanUuid: string, disbursements: ILoanDisbursement[]): ILoanDisbursementsSetAction {
    return {
        disbursements,
        loanUuid,
        type: LoansActionsEnum.LoanDisbursementsSet,
    };
}

export interface ILoanDischargeAction extends Action {
    uuid: string;
    dischargeDate: string;
}

export function loanDischargeAction(uuid: string, dischargeDate: string): ILoanDischargeAction {
    return {
        dischargeDate,
        type: LoansActionsEnum.LoanDischarge,
        uuid,
    };
}

export interface ILoanDischargeDateAction extends Action {
    uuid: string;
    dischargeDate: string;
}

export function loanDischargeDateAction(uuid: string, dischargeDate: string): ILoanDischargeDateAction {
    return {
        dischargeDate,
        type: LoansActionsEnum.LoanDischargeDate,
        uuid,
    };
}

export interface ILoanDocumentsAddAction extends Action {
    file: File;
    loanUuid: string;
}

export function loanDocumentsAddAction(loanUuid: string, file: File): ILoanDocumentsAddAction {
    return {
        file,
        loanUuid,
        type: LoansActionsEnum.LoanDocumentsAdd,
    };
}

export interface ILoanDocumentsListAction extends Action {
    loanUuid: string;
}

export function loanDocumentsListAction(loanUuid: string): ILoanDocumentsListAction {
    return {
        loanUuid,
        type: LoansActionsEnum.LoanDocumentsList,
    };
}

export interface ILoanDocumentsSetAction extends Action {
    documents: IDocument[];
    loanUuid: string;
}

export function loanDocumentsSetAction(loanUuid: string, documents: IDocument[]): ILoanDocumentsSetAction {
    return {
        documents,
        loanUuid,
        type: LoansActionsEnum.LoanDocumentsSet,
    };
}

export interface ILoanDocumentSetAction extends Action {
    document: IDocument;
}

export function loanDocumentSetAction(document: IDocument): ILoanDocumentSetAction {
    return {
        document,
        type: LoansActionsEnum.LoanDocumentSet,
    };
}

export interface ILoanDocumentDeleteAction extends Action {
    documentUuid: string;
}

export function loanDocumentDeleteAction(documentUuid: string): ILoanDocumentDeleteAction {
    return {
        documentUuid,
        type: LoansActionsEnum.LoanDocumentDelete,
    };
}

export interface ILoanDocumentRemoveAction extends Action {
    documentUuid: string;
}

export function loanDocumentRemoveAction(documentUuid: string): ILoanDocumentRemoveAction {
    return {
        documentUuid,
        type: LoansActionsEnum.LoanDocumentRemove,
    };
}

export interface ILoanDrawdownAction extends Action {
    uuid: string;
}

export function loanDrawdownAction(uuid: string): ILoanDrawdownAction {
    return {
        type: LoansActionsEnum.LoanDrawdown,
        uuid,
    };
}

export interface ILoanExtensionAction extends Action {
    uuid: string;
}

export function loanExtensionAction(uuid: string): ILoanExtensionAction {
    return {
        type: LoansActionsEnum.LoanExtension,
        uuid,
    };
}

export interface ILoanFeeDeleteAction extends Action {
    feeUuid: string;
    loanUuid: string;
}

export function loanFeeDeleteAction(loanUuid: string, feeUuid: string): ILoanFeeDeleteAction {
    return {
        feeUuid,
        loanUuid,
        type: LoansActionsEnum.LoanFeeDelete,
    };
}

export interface ILoanFeeRemoveAction extends Action {
    feeUuid: string;
    loanUuid: string;
}

export function loanFeeRemoveAction(loanUuid: string, feeUuid: string): ILoanFeeRemoveAction {
    return {
        feeUuid,
        loanUuid,
        type: LoansActionsEnum.LoanFeeRemove,
    };
}

export interface ILoanFeeSendAction extends Action {
    feeUuid: string;
    loanUuid: string;
}

export function loanFeeSendAction(loanUuid: string, feeUuid: string): ILoanFeeSendAction {
    return {
        feeUuid,
        loanUuid,
        type: LoansActionsEnum.LoanFeeSend,
    };
}

export interface ILoanFeeSetAction extends Action {
    fee: ILoanFee;
    loanUuid: string;
}

export function loanFeeSetAction(loanUuid: string, fee: ILoanFee): ILoanFeeSetAction {
    return {
        fee,
        loanUuid,
        type: LoansActionsEnum.LoanFeeSet,
    };
}

export interface ILoanFeeValueSetAction extends Action {
    feeUuid: string;
    key: keyof ILoanFee;
    loanUuid: string;
    value: boolean|number|string;
}

export function loanFeeValueSetAction(loanUuid: string, feeUuid: string, key: keyof ILoanFee, value: boolean|number|string): ILoanFeeValueSetAction {
    return {
        feeUuid,
        key,
        loanUuid,
        type: LoansActionsEnum.LoanFeeValueSet,
        value,
    };
}

export interface ILoanFeesAddAction extends Action {
    fee: ILoanFee;
    loanUuid: string;
}

export function loanFeesAddAction(loanUuid: string, fee: ILoanFee): ILoanFeesAddAction {
    return {
        fee,
        loanUuid,
        type: LoansActionsEnum.LoanFeesAdd,
    };
}

export interface ILoanFeesListAction extends Action {
    loanUuid: string;
}

export function loanFeesListAction(loanUuid: string): ILoanFeesListAction {
    return {
        loanUuid,
        type: LoansActionsEnum.LoanFeesList,
    };
}

export interface ILoanFeesSetAction extends Action {
    fees: ILoanFee[];
    loanUuid: string;
}

export function loanFeesSetAction(loanUuid: string, fees: ILoanFee[]): ILoanFeesSetAction {
    return {
        fees,
        loanUuid,
        type: LoansActionsEnum.LoanFeesSet,
    };
}

export interface ILoanFundAction extends Action {
    investorAccountUuid: string;
    uuid: string;
    warehouseUuid: string;
}

export function loanFundAction(uuid: string, warehouseUuid: string, investorAccountUuid: string): ILoanFundAction {
    return {
        investorAccountUuid,
        type: LoansActionsEnum.LoanFund,
        uuid,
        warehouseUuid,
    };
}

export interface ILoanGetAction extends Action {
    uuid: string;
}

export function loanGetAction(uuid: string): ILoanGetAction {
    return {
        type: LoansActionsEnum.LoanGet,
        uuid,
    };
}

export interface ILoanGracePeriodAction extends Action {
    uuid: string;
}

export function loanGracePeriodAction(uuid: string): ILoanGracePeriodAction {
    return {
        type: LoansActionsEnum.LoanGracePeriod,
        uuid,
    };
}

export interface ILoanGracePeriodSetAction extends Action {
    loanGracePeriod: ILoanGracePeriod;
}

export function loanGracePeriodSetAction(loanGracePeriod: ILoanGracePeriod): ILoanGracePeriodSetAction {
    return {
        loanGracePeriod,
        type: LoansActionsEnum.LoanGracePeriodSet,
    };
}

export interface ILoanGracePeriodsAddAction extends Action {
    loanGracePeriod: ILoanGracePeriod;
    loanUuid: string;
}

export function loanGracePeriodsAddAction(loanUuid: string, loanGracePeriod: ILoanGracePeriod): ILoanGracePeriodsAddAction {
    return {
        loanGracePeriod,
        loanUuid,
        type: LoansActionsEnum.LoanGracePeriodsAdd,
    };
}

export interface ILoanGracePeriodsListAction extends Action {
    loanUuid: string;
}

export function loanGracePeriodsListAction(loanUuid: string): ILoanGracePeriodsListAction {
    return {
        loanUuid,
        type: LoansActionsEnum.LoanGracePeriodsList,
    };
}

export interface ILoanGracePeriodsSetAction extends Action {
    gracePeriods: ILoanGracePeriod[];
    loanUuid: string;
}

export function loanGracePeriodsSetAction(loanUuid: string, gracePeriods: ILoanGracePeriod[]): ILoanGracePeriodsSetAction {
    return {
        gracePeriods,
        loanUuid,
        type: LoansActionsEnum.LoanGracePeriodsSet,
    };
}

export interface ILoanHistoriesListAction extends Action {
    uuid: string;
}

export function loanHistoriesListAction(uuid: string): ILoanHistoriesListAction {
    return {
        type: LoansActionsEnum.LoanHistoriesList,
        uuid,
    };
}

export interface ILoanHistoriesSetAction extends Action {
    histories: IHistory[];
    uuid: string;
}

export function loanHistoriesSetAction(uuid: string, histories: IHistory[]): ILoanHistoriesSetAction {
    return {
        histories,
        type: LoansActionsEnum.LoanHistoriesSet,
        uuid,
    };
}

export interface ILoanListSettingsSetAction extends Action {
    settings: ILoanListSettings;
}

export function loanListSettingsSetAction(settings: ILoanListSettings): ILoanListSettingsSetAction {
    return {
        settings,
        type: LoansActionsEnum.LoanListSettingsSet,
    };
}

export interface ILoanNoteSetAction extends Action {
    loanUuid: string;
    note: ILoanNote;
}

export function loanNoteSetAction(loanUuid: string, note: ILoanNote): ILoanNoteSetAction {
    return {
        loanUuid,
        note,
        type: LoansActionsEnum.LoanNoteSet,
    };
}

export interface ILoanNotesAddAction extends Action {
    loanUuid: string;
    note: string;
}

export function loanNotesAddAction(loanUuid: string, note: string): ILoanNotesAddAction {
    return {
        loanUuid,
        note,
        type: LoansActionsEnum.LoanNotesAdd,
    };
}

export interface ILoanNotesListAction extends Action {
    uuid: string;
}

export function loanNotesListAction(uuid: string): ILoanNotesListAction {
    return {
        type: LoansActionsEnum.LoanNotesList,
        uuid,
    };
}

export interface ILoanNotesSetAction extends Action {
    uuid: string;
    notes: ILoanNote[];
}

export function loanNotesSetAction(uuid: string, notes: ILoanNote[]): ILoanNotesSetAction {
    return {
        notes,
        type: LoansActionsEnum.LoanNotesSet,
        uuid,
    };
}

export interface ILoanPayoutFigureApproveAction extends Action {
    payoutFigureUuid: string;
}

export function loanPayoutFigureApproveAction(payoutFigureUuid: string): ILoanPayoutFigureApproveAction {
    return {
        payoutFigureUuid,
        type: LoansActionsEnum.LoanPayoutFigureApprove,
    };
}

export interface ILoanPayoutFigureDeleteAction extends Action {
    payoutFigureUuid: string;
}

export function loanPayoutFigureDeleteAction(payoutFigureUuid: string): ILoanPayoutFigureDeleteAction {
    return {
        payoutFigureUuid,
        type: LoansActionsEnum.LoanPayoutFigureDelete,
    };
}

export interface ILoanPayoutFigureRemoveAction extends Action {
    payoutFigureUuid: string;
}

export function loanPayoutFigureRemoveAction(payoutFigureUuid: string): ILoanPayoutFigureRemoveAction {
    return {
        payoutFigureUuid,
        type: LoansActionsEnum.LoanPayoutFigureRemove,
    };
}

export interface ILoanPayoutFigureGetAction extends Action {
    payoutFigureUuid: string;
}

export function loanPayoutFigureGetAction(payoutFigureUuid: string): ILoanPayoutFigureGetAction {
    return {
        payoutFigureUuid,
        type: LoansActionsEnum.LoanPayoutFigureGet,
    };
}

export interface ILoanPayoutFigureGetWithLoanAndApplicationAction extends Action {
    loanPayoutFigureUuid: string;
}

export function loanPayoutFigureGetWithLoanAndApplicationAction(loanPayoutFigureUuid: string): ILoanPayoutFigureGetWithLoanAndApplicationAction {
    return {
        loanPayoutFigureUuid,
        type: LoansActionsEnum.LoanPayoutFigureGetWithLoanAndApplication,
    };
}

export interface ILoanPayoutFigureProcessAction extends Action {
    force: boolean
    payoutFigureUuid: string;
}

export function loanPayoutFigureProcessAction(payoutFigureUuid: string, force: boolean = false): ILoanPayoutFigureProcessAction {
    return {
        force,
        payoutFigureUuid,
        type: LoansActionsEnum.LoanPayoutFigureProcess,
    };
}

export interface ILoanPayoutFigureSendAction extends Action {
    loanPayoutFigureUuid: string;
}

export function loanPayoutFigureSendAction(loanPayoutFigureUuid: string): ILoanPayoutFigureSendAction {
    return {
        loanPayoutFigureUuid,
        type: LoansActionsEnum.LoanPayoutFigureSend,
    };
}

export interface ILoanPayoutFigureSetAction extends Action {
    payoutFigure: ILoanPayoutFigure;
}

export function loanPayoutFigureSetAction(payoutFigure: ILoanPayoutFigure): ILoanPayoutFigureSetAction {
    return {
        payoutFigure,
        type: LoansActionsEnum.LoanPayoutFigureSet,
    };
}

export interface ILoanPayoutFigureValueSetAction extends Action {
    key: keyof ILoanPayoutFigure;
    loanPayoutFigureUuid: string;
    value: boolean|number|string;
}

export function loanPayoutFigureValueSetAction(loanPayoutFigureUuid: string, key: keyof ILoanPayoutFigure, value: boolean|number|string): ILoanPayoutFigureValueSetAction {
    return {
        key,
        loanPayoutFigureUuid,
        type: LoansActionsEnum.LoanPayoutFigureValueSet,
        value,
    };
}

export interface ILoanPayoutFigureItemAddAction extends Action {
    loanPayoutFigureItem: ILoanPayoutFigureItem;
}

export function loanPayoutFigureItemAddAction(loanPayoutFigureItem: ILoanPayoutFigureItem): ILoanPayoutFigureItemAddAction {
    return {
        loanPayoutFigureItem,
        type: LoansActionsEnum.LoanPayoutFigureItemAdd,
    };
}

export interface ILoanPayoutFigureItemDeleteAction extends Action {
    itemUuid: string;
}

export function loanPayoutFigureItemDeleteAction(itemUuid: string): ILoanPayoutFigureItemDeleteAction {
    return {
        itemUuid,
        type: LoansActionsEnum.LoanPayoutFigureItemDelete,
    };
}

export interface ILoanPayoutFigureItemSendAction extends Action {
    itemUuid: string;
}

export function loanPayoutFigureItemSendAction(itemUuid: string): ILoanPayoutFigureItemSendAction {
    return {
        itemUuid,
        type: LoansActionsEnum.LoanPayoutFigureItemSend,
    };
}

export interface ILoanPayoutFigureItemSetAction extends Action {
    item: ILoanPayoutFigureItem;
}

export function loanPayoutFigureItemSetAction(item: ILoanPayoutFigureItem): ILoanPayoutFigureItemSetAction {
    return {
        item,
        type: LoansActionsEnum.LoanPayoutFigureItemSet,
    };
}

export interface ILoanPayoutFigureItemValueSetAction extends Action {
    itemUuid: string;
    key: keyof ILoanPayoutFigureItem;
    value: boolean|number|string;
}

export function loanPayoutFigureItemValueSetAction(itemUuid: string, key: keyof ILoanPayoutFigureItem, value: boolean|number|string): ILoanPayoutFigureItemValueSetAction {
    return {
        itemUuid,
        key,
        type: LoansActionsEnum.LoanPayoutFigureItemValueSet,
        value,
    };
}

export interface ILoanPayoutFigureSectionAddAction extends Action {
    loanPayoutFigureSection: ILoanPayoutFigureSection;
}

export function loanPayoutFigureSectionAddAction(loanPayoutFigureSection: ILoanPayoutFigureSection): ILoanPayoutFigureSectionAddAction {
    return {
        loanPayoutFigureSection,
        type: LoansActionsEnum.LoanPayoutFigureSectionAdd,
    };
}

export interface ILoanPayoutFigureSectionDeleteAction extends Action {
    sectionUuid: string;
}

export function loanPayoutFigureSectionDeleteAction(sectionUuid: string): ILoanPayoutFigureSectionDeleteAction {
    return {
        sectionUuid,
        type: LoansActionsEnum.LoanPayoutFigureSectionDelete,
    };
}

export interface ILoanPayoutFigureSectionSetAction extends Action {
    section: ILoanPayoutFigureSection;
}

export function loanPayoutFigureSectionSetAction(section: ILoanPayoutFigureSection): ILoanPayoutFigureSectionSetAction {
    return {
        section,
        type: LoansActionsEnum.LoanPayoutFigureSectionSet,
    };
}

export interface ILoanPayoutFiguresAddAction extends Action {
    payoutFigure: ILoanPayoutFigure;
}

export function loanPayoutFiguresAddAction(payoutFigure: ILoanPayoutFigure): ILoanPayoutFiguresAddAction {
    return {
        payoutFigure,
        type: LoansActionsEnum.LoanPayoutFiguresAdd,
    };
}

export interface ILoanPayoutFiguresListAction extends Action {
    loanUuid: string;
}

export function loanPayoutFiguresListAction(loanUuid: string): ILoanPayoutFiguresListAction {
    return {
        loanUuid,
        type: LoansActionsEnum.LoanPayoutFiguresList,
    };
}

export interface ILoanPayoutFiguresSetAction extends Action {
    loanUuid: string;
    payoutFigures: ILoanPayoutFigure[];
}

export function loanPayoutFiguresSetAction(loanUuid: string, payoutFigures: ILoanPayoutFigure[]): ILoanPayoutFiguresSetAction {
    return {
        loanUuid,
        payoutFigures,
        type: LoansActionsEnum.LoanPayoutFiguresSet,
    };
}

export interface ILoanPrincipalReductionAction extends Action {
    amount: number;
    loanUuid: string;
    transactionDate: string;
}

export function loanPrincipalReductionAction(loanUuid: string, amount: number, transactionDate: string): ILoanPrincipalReductionAction {
    return {
        amount,
        loanUuid,
        transactionDate,
        type: LoansActionsEnum.LoanPrincipalReduction,
    };
}

export interface ILoanPropertiesListAction extends Action {
    uuid: string;
}

export function loanPropertiesListAction(uuid: string): ILoanPropertiesListAction {
    return {
        type: LoansActionsEnum.LoanPropertiesList,
        uuid,
    };
}

export interface ILoanPropertiesSetAction extends Action {
    uuid: string;
    properties: ILoanProperty[];
}

export function loanPropertiesSetAction(uuid: string, properties: ILoanProperty[]): ILoanPropertiesSetAction {
    return {
        properties,
        type: LoansActionsEnum.LoanPropertiesSet,
        uuid,
    };
}

export interface ILoanPropertySendAction extends Action {
    loanUuid: string;
    propertyUuid: string;
}

export function loanPropertySendAction(loanUuid: string, propertyUuid: string): ILoanPropertySendAction {
    return {
        loanUuid,
        propertyUuid,
        type: LoansActionsEnum.LoanPropertySend,
    };
}

export interface ILoanPropertySetAction extends Action {
    loanUuid: string;
    property: ILoanProperty;
}

export function loanPropertySetAction(loanUuid: string, property: ILoanProperty): ILoanPropertySetAction {
    return {
        loanUuid,
        property,
        type: LoansActionsEnum.LoanPropertySet,
    };
}

export interface ILoanPropertyValueSetAction extends Action {
    key: keyof ILoanProperty;
    loanUuid: string;
    propertyUuid: string;
    value: boolean|number|string;
}

export function loanPropertyValueSetAction(loanUuid: string, propertyUuid: string, key: keyof ILoanProperty, value: boolean|number|string): ILoanPropertyValueSetAction {
    return {
        key,
        loanUuid,
        propertyUuid,
        type: LoansActionsEnum.LoanPropertyValueSet,
        value,
    };
}

export interface ILoanRecordNextPaymentAction extends Action {
    uuid: string;
}

export function loanRecordNextPaymentAction(uuid: string): ILoanRecordNextPaymentAction {
    return {
        type: LoansActionsEnum.LoanRecordNextPayment,
        uuid,
    };
}

export interface ILoanRecoveryAction extends Action {
    loanUuid: string;
}

export function loanRecoveryAction(loanUuid: string): ILoanRecoveryAction {
    return {
        loanUuid,
        type: LoansActionsEnum.LoanRecovery,
    };
}

export interface ILoanSendAction extends Action {
    loanUuid: string;
}

export function loanSendAction(loanUuid: string): ILoanSendAction {
    return {
        loanUuid,
        type: LoansActionsEnum.LoanSend,
    };
}

export interface ILoanSendMaturationReminderAction extends Action {
    status: MaturityReminderStatusEnum;
    uuid: string;
}

export function loanSendMaturationReminderAction(uuid: string, status: MaturityReminderStatusEnum): ILoanSendMaturationReminderAction {
    return {
        status,
        type: LoansActionsEnum.LoanSendMaturationReminder,
        uuid,
    };
}

export interface ILoanSkipMaturationReminderAction extends Action {
    status: MaturityReminderStatusEnum;
    uuid: string;
}

export function loanSkipMaturationReminderAction(uuid: string, status: MaturityReminderStatusEnum): ILoanSkipMaturationReminderAction {
    return {
        status,
        type: LoansActionsEnum.LoanSkipMaturationReminder,
        uuid,
    };
}

export interface ILoanSetAction extends Action {
    loan: ILoan;
}

export function loanSetAction(loan: ILoan): ILoanSetAction {
    return {
        loan,
        type: LoansActionsEnum.LoanSet,
    };
}

export interface ILoanTransactionsListAction extends Action {
    loanUuid: string;
}

export function loanTransactionsListAction(loanUuid: string): ILoanTransactionsListAction {
    return {
        loanUuid,
        type: LoansActionsEnum.LoanTransactionsList,
    };
}

export interface ILoanTransactionsSetAction extends Action {
    loanTransactions: ILoanTransaction[];
    loanUuid: string;
}

export function loanTransactionsSetAction(loanUuid: string, loanTransactions: ILoanTransaction[]): ILoanTransactionsSetAction {
    return {
        loanTransactions,
        loanUuid,
        type: LoansActionsEnum.LoanTransactionsSet,
    };
}

export interface ILoanValueSetAction extends Action {
    key: keyof ILoan;
    uuid: string;
    value: boolean|number|string;
}

export function loanValueSetAction(uuid: string, key: keyof ILoan, value: boolean|number|string): ILoanValueSetAction {
    return {
        key,
        type: LoansActionsEnum.LoanValueSet,
        uuid,
        value,
    };
}

export interface ILoanWarehouseLoansListAction extends Action {
    loanUuid: string;
}

export function loanWarehouseLoansListAction(loanUuid: string): ILoanWarehouseLoansListAction {
    return {
        loanUuid,
        type: LoansActionsEnum.LoanWarehouseLoansList,
    };
}

export interface ILoanWarehouseLoansSetAction extends Action {
    loanUuid: string;
    warehouseLoans: IWarehouseLoan[];
}

export function loanWarehouseLoansSetAction(loanUuid: string, warehouseLoans: IWarehouseLoan[]): ILoanWarehouseLoansSetAction {
    return {
        loanUuid,
        type: LoansActionsEnum.LoanWarehouseLoansSet,
        warehouseLoans,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILoansDashboardListAction extends Action {}

export function loansDashboardListAction(): ILoansDashboardListAction {
    return {
        type: LoansActionsEnum.LoansDashboardList,
    };
}

export interface ILoansDashboardSetAction extends Action {
    loans: ILoan[];
}

export function loansDashboardSetAction(loans: ILoan[]): ILoansDashboardSetAction {
    return {
        loans,
        type: LoansActionsEnum.LoansDashboardSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILoansDischargeForecastListAction extends Action {}

export function loansDischargeForecastListAction(): ILoansDischargeForecastListAction {
    return {
        type: LoansActionsEnum.LoansDischargeForecastList,
    };
}

export interface ILoansDischargeForecastSetAction extends Action {
    loans: ILoan[];
}

export function loansDischargeForecastSetAction(loans: ILoan[]): ILoansDischargeForecastSetAction {
    return {
        loans,
        type: LoansActionsEnum.LoansDischargeForecastSet,
    };
}

type ILoansDrawdownsListAction = Action<LoansActionsEnum.LoansDrawdownsList>;

export function loansDrawdownsListAction(): ILoansDrawdownsListAction {
    return {
        type: LoansActionsEnum.LoansDrawdownsList,
    };
}

export interface ILoansDrawdownsSetAction extends Action {
    loans: ILoan[];
}

export function loansDrawdownsSetAction(loans: ILoan[]): ILoansDrawdownsSetAction {
    return {
        loans,
        type: LoansActionsEnum.LoansDrawdownsSet,
    };
}

export interface ILoansPaginatedListAction extends Action {
    administratorUuids: string[],
    code: string;
    name: string;
    order: ListSortOrderEnum;
    orderBy: string;
    page: number;
    perPage: number;
    statuses: LoanStatusEnum[];
}

export function loansPaginatedListAction(
    page: number,
    perPage: number,
    orderBy: string,
    order: ListSortOrderEnum,
    statuses: LoanStatusEnum[],
    administratorUuids: string[],
    code: string,
    name: string,
): ILoansPaginatedListAction {
    return {
        administratorUuids,
        code,
        name,
        order,
        orderBy,
        page,
        perPage,
        statuses,
        type: LoansActionsEnum.LoansPaginatedList,
    };
}

export interface ILoansPaginatedSetAction extends Action {
    loans: ILoan[];
    loansPaginatedCount: number;
    page: number;
    perPage: number;
}

export function loansPaginatedSetAction(loans: ILoan[], loansPaginatedCount: number, page: number, perPage: number): ILoansPaginatedSetAction {
    return {
        loans,
        loansPaginatedCount,
        page,
        perPage,
        type: LoansActionsEnum.LoansPaginatedSet,
    };
}

/** @deprecated */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILoansListAction extends Action {}

/** @deprecated */
export function loansListAction(): ILoansListAction {
    return {
        type: LoansActionsEnum.LoansList,
    };
}

/** @deprecated */
export interface ILoansSetAction extends Action {
    loans: ILoan[];
}

/** @deprecated */
export function loansSetAction(loans: ILoan[]): ILoansSetAction {
    return {
        loans,
        type: LoansActionsEnum.LoansSet,
    };
}

export interface ILoansSearchAction extends Action {
    keyword: string;
}

export function loansSearchAction(keyword: string): ILoansSearchAction {
    return {
        keyword,
        type: LoansActionsEnum.LoansSearch,
    };
}

export interface ILoansSearchResultsSetAction extends Action {
    loans: ILoan[];
}

export function loansSearchResultsSetAction(loans: ILoan[]): ILoansSearchResultsSetAction {
    return {
        loans,
        type: LoansActionsEnum.LoansSearchResultsSet,
    };
}

export function loansSearchResultsClearAction(): Action {
    return {
        type: LoansActionsEnum.LoansSearchResultsClear,
    };
}
