import { Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import roundTo from 'round-to';
import { investorAccountDepositAction } from '~Investors/actions';

interface IState {
    amount: number;
}

interface IProps {
    accountUuid: string;
    onClose: () => void;
    show: boolean;
}

interface IPropsDispatch {
    topup: (amount: number) => void;
}

type Props = IProps & IPropsDispatch;

class TopUpModal extends React.Component<Props, IState> {
    public state: IState = {
        amount: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { show } = this.props;
        const { amount } = this.state;

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        return (
            <Modal
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={show}
                title='Top Up'
                wrapClassName='investor-top-up-modal'
            >
                <Form.Item className='amount' extra={currencyFormatter.format(amount)} label='Amount'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeAmount} type='number' value={amount} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeAmount(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            amount: event.target.value ? roundTo(Number(event.target.value), 2) : null,
        });
    }

    private onClickOk(): void {
        const { amount } = this.state;

        this.props.topup(amount);
        this.props.onClose();
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        topup: (amount: number) => dispatch(investorAccountDepositAction(ownProps.accountUuid, amount)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(TopUpModal);
