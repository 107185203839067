import { Form, Modal } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import ILoanGracePeriod from '~Api/Loan/ILoanGracePeriod';
import {
    loanGetAction,
    loanGracePeriodsAddAction,
    loanGracePeriodsListAction,
} from '~Loans/actions';
import {
    loanGracePeriodsSelector,
    loanSelector,
} from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import DatePicker from '~UI/DatePicker';
import { IDictionary } from '~utilities/IDictionary';

interface IDefaultedValues {
    startDate: string;
}

interface IState {
    dirtyFields: IDictionary<boolean>;
    errors: {
        startDate?: string;
    };
    startDate: string;
}

interface IProps {
    isOpen: boolean;
    loanUuid: string;
    onClose: () => void;
}

interface IPropsSelector {
    gracePeriods: IDictionary<ILoanGracePeriod>;
    loan: ILoan;
}

interface IPropsDispatch {
    add: (loanGracePeriod: ILoanGracePeriod) => void;
    gracePeriodsList: () => void;
    loanGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class GracePeriodAddModal extends React.Component<Props, IState> {
    public state: IState = {
        dirtyFields: {},
        errors: {},
        startDate: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeStartDate = this.onChangeStartDate.bind(this);
        this.validateStartDate = this.validateStartDate.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public componentDidMount(): void {
        const { gracePeriods, loan } = this.props;

        if (!loan) {
            this.props.loanGet();
        }

        if (!gracePeriods) {
            this.props.gracePeriodsList();
        }
    }

    public render(): JSX.Element {
        const { isOpen, loan } = this.props;
        const { errors } = this.state;

        if (!loan) {
            return null;
        }

        const { startDate } = this.getDefaultedValues();

        const startDateDayjs: Dayjs = startDate ? dayjs(startDate) : null;

        return (
            <Modal
                destroyOnClose={true}
                okText='Add'
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Grace Period'
                wrapClassName='loan-grace-period-add-modal'
            >
                <Form.Item label='Start Date' className='start-date' help={errors.startDate} validateStatus={errors.startDate && 'error'}>
                    <DatePicker onChange={this.onChangeStartDate} format='DD/MM/YYYY' value={startDateDayjs} onBlur={this.validateStartDate} />
                </Form.Item>
            </Modal>
        );
    }

    private getDefaultedValues(): IDefaultedValues {
        const { loan } = this.props;
        const { startDate, dirtyFields } = this.state;

        return {
            startDate: dirtyFields.startDate ? startDate : (loan.gracePeriodEndDate || loan.endDate),
        };
    }

    private onChangeStartDate(date: Dayjs): void {
        const { dirtyFields } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                startDate: true,
            },
            startDate: date ? date.format('YYYY-MM-DD') : null,
        });
    }

    private onClickOk(): void {
        const { loan } = this.props;
        const { startDate } = this.getDefaultedValues();

        let valid: boolean = true;

        valid = this.validateStartDate() && valid;

        if (!valid) {
            return;
        }

        this.props.add({
            loanUuid: loan.uuid,
            startDate,
        });

        this.props.onClose();
    }

    private validateStartDate(): boolean {
        const { gracePeriods, loan } = this.props;
        const { errors } = this.state;
        const { startDate } = this.getDefaultedValues();

        const latestGracePeriod: ILoanGracePeriod = _.maxBy(_.values(gracePeriods), 'endDate');
        const earliestStartDateDayjs: Dayjs = dayjs(latestGracePeriod ? latestGracePeriod.endDate : loan.endDate);

        let error: string;

        if (!startDate) {
            error = 'Please enter a start date';
        } else if (dayjs(startDate) < earliestStartDateDayjs) {
            error = `Grace period can't start earlier than ${earliestStartDateDayjs.format('DD/MM/YYYY')}`;
        }

        this.setState({
            errors: {
                ...errors,
                startDate: error,
            },
        });

        return !error;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        gracePeriods: loanGracePeriodsSelector(state, ownProps.loanUuid),
        loan: loanSelector(state, ownProps.loanUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        add: (loanGracePeriod: ILoanGracePeriod) => dispatch(loanGracePeriodsAddAction(ownProps.loanUuid, loanGracePeriod)),
        gracePeriodsList: () => dispatch(loanGracePeriodsListAction(ownProps.loanUuid)),
        loanGet: () => dispatch(loanGetAction(ownProps.loanUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GracePeriodAddModal);
