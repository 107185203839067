import { Spin, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IHistory from '~Api/ReferralPartner/IHistory';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import { IGlobalState } from '~reducer';
import {
    referralPartnerGetAction,
    referralPartnerHistoriesListAction,
} from '~ReferralPartners/actions';
import {
    referralPartnerHistoriesSelector,
    referralPartnerSelector,
} from '~ReferralPartners/selectors';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import './referralPartner.less';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    histories: IDictionary<IHistory>;
    referralPartner: IReferralPartner;
}

interface IPropsDispatch {
    historiesList: () => void;
    referralPartnerGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class History extends React.Component<Props> {
    public componentDidMount(): void {
        const { referralPartner } = this.props;

        if (!referralPartner) {
            this.props.referralPartnerGet();
        }

        this.props.historiesList();
    }

    public render(): JSX.Element {
        const { histories, match, referralPartner } = this.props;

        if (!referralPartner || !histories) {
            return (
                <Layout uuid={match.params.uuid} section='history'>
                    <Typography.Title level={2}>History</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const historiesBlock: JSX.Element[] = _.sortBy(_.filter(_.values(histories), (history) => !!history.uuid), ['createdTime']).map((loopHistory) => (
            <Timeline.Item key={loopHistory.uuid} label={dayjs(loopHistory.createdTime).format('YYYY-MM-DD - HH:mm')}>
                <Typography.Text>{loopHistory.description}</Typography.Text>
            </Timeline.Item>
        ));

        return (
            <Layout uuid={match.params.uuid} section='history'>
                <Typography.Title level={2}>History</Typography.Title>
                <Timeline mode='left' reverse={true}>
                    {historiesBlock}
                </Timeline>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        histories: referralPartnerHistoriesSelector(state, ownProps.match.params.uuid),
        referralPartner: referralPartnerSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        historiesList: () => dispatch(referralPartnerHistoriesListAction(ownProps.match.params.uuid)),
        referralPartnerGet: () => dispatch(referralPartnerGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(History);
