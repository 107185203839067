enum RoleEnum {
    BusinessDevelopmentManager = 'BUSINESS_DEVELOPMENT_MANAGER',
    CreditManager = 'CREDIT_MANAGER',
    InternalBusinessDevelopmentManager = 'INTERNAL_BUSINESS_DEVELOPMENT_MANAGER',
    InvestorManager = 'INVESTOR_MANAGER',
    LoanManager = 'LOAN_MANAGER',
    SeniorBusinessDevelopmentManager = 'SENIOR_BUSINESS_DEVELOPMENT_MANAGER',
    SeniorCreditManager = 'SENIOR_CREDIT_MANAGER',
    SeniorInvestorManager = 'SENIOR_INVESTOR_MANAGER',
    SeniorLoanManager = 'SENIOR_LOAN_MANAGER',
    SuperAdmin = 'SUPER_ADMIN',
}

export default RoleEnum;
