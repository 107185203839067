import _ from 'lodash';
import ActiveStatusEnum from '~Api/Administrator/ActiveStatusEnum';
import IAdministrator from '~Api/Administrator/IAdministrator';
import IHistory from '~Api/Administrator/IHistory';
import IPasskey from '~Api/Administrator/IPasskey';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { keyMap } from '~utilities/utils';

export function administratorSelector(state: IGlobalState, uuid: string): IAdministrator {
    return state.administrators.administrators && state.administrators.administrators[uuid];
}

export function administratorPasskeysSelector(state: IGlobalState, administratorUuid: string): IDictionary<IPasskey> {
    if (!state.administrators.administratorPasskeyUuids[administratorUuid]) {
        return null;
    }

    return keyMap(state.administrators.administratorPasskeyUuids[administratorUuid], 'uuid', (uuid: string) => state.administrators.passkeys[uuid]);
}

export function administratorsSelector(state: IGlobalState): IDictionary<IAdministrator> {
    if (!state.administrators.administrators) {
        return null;
    }

    const administrators: IDictionary<IAdministrator> = {};
    _.each(state.administrators.administrators, ((administrator: IAdministrator) => {
        administrators[administrator.uuid] = administrator;
    }));

    return administrators;
}

export function administratorsActiveSelector(state: IGlobalState): IAdministrator[] {
    return state.administrators.administrators && _.filter(_.values(state.administrators.administrators), { status: ActiveStatusEnum.Active });
}

export function administratorHistoriesSelector(state: IGlobalState, administratorUuid: string): IDictionary<IHistory> {
    return state.administrators.histories[administratorUuid];
}

export function currentAdministratorSelector(state: IGlobalState): IAdministrator {
    const currentUser: IAuthUser = authCurrentUserSelector(state);
    return currentUser && currentUser.uuid && administratorSelector(state, currentUser.uuid);
}
