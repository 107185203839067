import { Button, Typography } from 'antd';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { authTokenSelector } from '~Auth/selectors';
import Layout from './Layout';

export default function WarehousesFwt2PoolCut(): ReactElement {
    const token: string = useSelector(authTokenSelector);

    return (
        <Layout section='warehouse-fwt2-pool-cut'>
            <Typography.Title level={2}>Warehouses FWT2 Pool Cut Report</Typography.Title>
            <Button
                href={`${process.env.API_HOST}/warehouse-reports/download-fwt2-pool-cut-report?token=${token}`}
                target='_blank'
                type='primary'
            >
                Download
            </Button>
        </Layout>
    );
}
