import { fetchGet, fetchPost, fetchPut } from '~utilities/fetch';
import IAdministrator from './IAdministrator';
import { transformAdministrator } from './transformers';
import PermissionsEnum from './PermissionsEnum';

export async function administratorActivateRequest(administratorUuid: string): Promise<Response> {
    return fetchPost(`/administrators/${administratorUuid}/activate`);
}

export async function administratorDeactivateRequest(administratorUuid: string): Promise<Response> {
    return fetchPost(`/administrators/${administratorUuid}/deactivate`);
}

export async function administratorHistoriesListRequest(uuid: string): Promise<Response> {
    return fetchGet(`/administrators/${uuid}/history`);
}

export async function administratorMagicLinkRequestApproveRequest(administratorUuid: string): Promise<Response> {
    return fetchPost(`/administrators/${administratorUuid}/magic-link/approve`);
}

export async function administratorMagicLinkRequestCancelRequest(administratorUuid: string): Promise<Response> {
    return fetchPost(`/administrators/${administratorUuid}/magic-link/cancel`);
}

export async function administratorPermissionsRequest(administratorUuid: string, permissions: PermissionsEnum[]): Promise<Response> {
    return fetchPost(`/administrators/${administratorUuid}/permissions`, { permissions });
}

export async function administratorUpdateRequest(administrator: IAdministrator): Promise<Response> {
    return fetchPut(`/administrators/${administrator.uuid}`, transformAdministrator(administrator));
}

export async function administratorWebauthnDeactivateRequest(administratorWebauthnUuid: string): Promise<Response> {
    return fetchPost(`/administrator-webauthns/${administratorWebauthnUuid}/deactivate`);
}

export async function administratorWebauthnEnableRequest(administratorUuid: string, body: any): Promise<Response> {
    return fetchPost(`/administrators/${administratorUuid}/webauthn-enable`, body);
}

export async function administratorWebauthnsListRequest(administratorUuid: string): Promise<Response> {
    return fetchGet(`/administrators/${administratorUuid}/webauthns`);
}

export async function administratorWebauthnOptionsRequest(administratorUuid: string): Promise<Response> {
    return fetchPost(`/administrators/${administratorUuid}/webauthn-options`);
}

export async function administratorsAddRequest(administrator: IAdministrator): Promise<Response> {
    return fetchPost('/administrators', transformAdministrator(administrator));
}

export async function administratorsListRequest(): Promise<Response> {
    return fetchGet('/administrators');
}
