import { Button, Checkbox, Form, Input, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ILoanProperty from '~Api/Loan/ILoanProperty';
import { loanPropertyValueSetAction } from '~Loans/actions';
import { loanPropertySelector } from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import DatePicker from '~UI/DatePicker';

interface IProps {
    isOpen: boolean;
    loanUuid: string;
    onClose: () => void;
    propertyUuid: string;
}

interface IPropsSelector {
    property: ILoanProperty;
}

interface IPropsDispatch {
    update: (key: keyof ILoanProperty, value: boolean|number|string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class PropertyEditModal extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeInsuranceExpiryDate = this.onChangeInsuranceExpiryDate.bind(this);
        this.onChangeInsuranceReplacementValue = this.onChangeInsuranceReplacementValue.bind(this);
        this.onChangeInsuranceRequired = this.onChangeInsuranceRequired.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { property, isOpen } = this.props;

        if (!property) {
            return null;
        }

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const footer: JSX.Element = (
            <React.Fragment>
                <Button className='close' onClick={this.props.onClose}>Close</Button>
            </React.Fragment>
        );

        return (
            <Modal
                destroyOnClose={true}
                footer={footer}
                okText='Close'
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title='Edit Property'
                width={530}
                wrapClassName='loan-property-edit-modal'
            >
                <Form.Item label='Insurance Required' className='insurance-required'>
                    <Checkbox onChange={this.onChangeInsuranceRequired} checked={property.insuranceRequired} />
                </Form.Item>
                <Form.Item label='Insurance Expiry Date' className='insurance-expiry-date'>
                    <DatePicker onChange={this.onChangeInsuranceExpiryDate} format='DD/MM/YYYY' value={property.insuranceExpiryDate ? dayjs(property.insuranceExpiryDate) : null} />
                </Form.Item>
                <Form.Item className='insurance-replacement-value' extra={currencyFormatter.format(property.insuranceReplacementValue)} label='Insurance Replacement Value'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeInsuranceReplacementValue} type='number' value={property.insuranceReplacementValue} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeInsuranceExpiryDate(date: Dayjs) {
        this.props.update('insuranceExpiryDate', date ? date.format('YYYY-MM-DD') : null);
    }

    private onChangeInsuranceReplacementValue(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.update('insuranceReplacementValue', event.target.value ? event.target.valueAsNumber : null);
    }

    private onChangeInsuranceRequired(event: CheckboxChangeEvent) {
        this.props.update('insuranceRequired', event.target.checked);
    }

    private onClickOk() {
        this.props.onClose();
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        property: loanPropertySelector(state, ownProps.loanUuid, ownProps.propertyUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        update: (key: keyof ILoanProperty, value: boolean|number|string) => dispatch(loanPropertyValueSetAction(ownProps.loanUuid, ownProps.propertyUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PropertyEditModal);
