import { Button, Dropdown, MenuProps, Modal, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IAccount from '~Api/Investor/IAccount';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { currencyFormatter } from '~utilities/formatters';
import Layout from './Layout';
import classTypeLabels from '~Api/IncomeTrust/classTypeLabels';
import IIncomeTrustInvestmentRequest from '~Api/IncomeTrust/IIncomeTrustInvestmentRequest';
import IInvestorAccountIncomeTrust from '~Api/IncomeTrust/IInvestorAccountIncomeTrust';
import PendingInvestmentCancelModal from './PendingRequestCancelModal';
import dayjs from 'dayjs';
import { CheckOutlined } from '@ant-design/icons';
import {
    investmentIncomeTrustInvestmentRequestProcessAction,
    investmentIncomeTrustInvestmentRequestsPendingListAction,
} from '~Investments/actions';
import { incomeTrustInvestmentRequestsPendingSelector } from '~Investments/selectors';

interface IState {
    cancelIncomeTrustInvestmentRequestUuid: string;
}

interface IPropsSelector {
    pendingInvestmentRequests: IDictionary<IIncomeTrustInvestmentRequest>;
}

interface IPropsDispatch {
    pendingInvestmentRequestsList: () => void;
    process: (incomeTrustInvestmentRequestUuid: string, sendEmail: boolean) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class PendingRequests extends React.Component<Props, IState> {
    public state: IState = {
        cancelIncomeTrustInvestmentRequestUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickPendingInvestmentRequestCancel = this.onClickPendingInvestmentRequestCancel.bind(this);
        this.onClickProcess = this.onClickProcess.bind(this);
    }

    public componentDidMount(): void {
        this.props.pendingInvestmentRequestsList();
    }

    public render(): JSX.Element {
        const { pendingInvestmentRequests } = this.props;
        const { cancelIncomeTrustInvestmentRequestUuid } = this.state;

        if (!pendingInvestmentRequests) {
            return (
                <Layout section='pending-requests'>
                    <Typography.Title level={3}>Pending Requests</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const pendingColumns: ColumnType<IIncomeTrustInvestmentRequest>[] = [
            {
                filters: [{ text: 'Yes', value: true }, { text: 'No', value: false }],
                onFilter: (value: boolean|number|string, incomeTrustInvestmentRequest: IIncomeTrustInvestmentRequest) => value === incomeTrustInvestmentRequest.investorAccount.balance > incomeTrustInvestmentRequest.amount,
                render: (incomeTrustInvestmentRequest: IIncomeTrustInvestmentRequest) => incomeTrustInvestmentRequest.investorAccount.balance >= incomeTrustInvestmentRequest.amount ? <CheckOutlined/> : '-',
                width: '2%',
            },
            {
                dataIndex: 'investorAccount',
                render: (investorAccount: IAccount): JSX.Element => <Link to={`/investors/${investorAccount.investorUuid}/accounts/${investorAccount.uuid}`}>{investorAccount.investorName}</Link>,
                title: 'Investor',
            },
            {
                dataIndex: 'investorAccount',
                render: (investorAccount: IAccount) => investorAccount.code,
                title: 'Account ID',
                width: '10%',
            },
            {
                dataIndex: 'investorAccountIncomeTrust',
                defaultSortOrder: 'ascend',
                render: (investorAccountIncomeTrust: IInvestorAccountIncomeTrust) => classTypeLabels[investorAccountIncomeTrust.classType],
                sorter: (a: IIncomeTrustInvestmentRequest, b: IIncomeTrustInvestmentRequest) => {
                    return a.investorAccountIncomeTrust.classType.localeCompare(b.investorAccountIncomeTrust.classType);
                },
                title: 'Class',
                width: '8%',
            },
            {
                dataIndex: 'amount',
                render: (amount: number) => currencyFormatter.format(amount),
                sorter: (a: IIncomeTrustInvestmentRequest, b: IIncomeTrustInvestmentRequest) => {
                    return a.amount > b.amount ? 1 : -1;
                },
                title: 'Amount',
                width: '12%',
            },
            {
                dataIndex: 'investorAccount',
                render: (investorAccount: IAccount) => currencyFormatter.format(investorAccount.balance),
                title: 'Cash Balance',
                width: '12%',
            },
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => dayjs(createdTime).format('DD/MM/YYYY HH:mm'),
                sorter: (a: IIncomeTrustInvestmentRequest, b: IIncomeTrustInvestmentRequest) => dayjs(a.createdTime) > dayjs(b.createdTime) ? 1 : -1,
                title: 'Created',
                width: '15%',
            },
            {
                render: (incomeTrustInvestmentRequest: IIncomeTrustInvestmentRequest): JSX.Element => {
                    const onClickCancel: () => void = () => this.setState({ cancelIncomeTrustInvestmentRequestUuid: incomeTrustInvestmentRequest.uuid });

                    if (incomeTrustInvestmentRequest.investorAccount.balance >= incomeTrustInvestmentRequest.amount) {
                        const onClickProcessAndEmail: () => void = () => this.onClickProcess(incomeTrustInvestmentRequest, true);

                        const menuItems: MenuProps = {
                            items: [
                                {
                                    key: 'process',
                                    label: 'Process',
                                    onClick: () => this.onClickProcess(incomeTrustInvestmentRequest, false),
                                },
                                {
                                    key: 'cancel',
                                    label: 'Cancel',
                                    onClick: onClickCancel,
                                },
                            ],
                        };

                        return (
                            <Dropdown.Button
                                menu={menuItems}
                                onClick={onClickProcessAndEmail}
                                type='primary'
                            >
                                Process + Email
                            </Dropdown.Button>
                        );
                    }

                    return <Button danger={true} onClick={onClickCancel}>Cancel</Button>;
                },
                title: 'Actions',
                width: '15%',
            },
        ];

        return (
            <Layout section='pending-requests'>
                <Typography.Title level={3}>Pending Requests</Typography.Title>
                <Table
                    columns={pendingColumns}
                    dataSource={_.values(pendingInvestmentRequests)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                />
                <PendingInvestmentCancelModal incomeTrustInvestmentRequestUuid={cancelIncomeTrustInvestmentRequestUuid} onCancel={this.onClickPendingInvestmentRequestCancel} />
            </Layout>

        );
    }

    private onClickPendingInvestmentRequestCancel(): void {
        this.setState({
            cancelIncomeTrustInvestmentRequestUuid: null,
        });
    }

    private onClickProcess(incomeTrustInvestmentRequest: IIncomeTrustInvestmentRequest, sendEmail: boolean): void {
        Modal.confirm({
            content: `Are you sure you want to process the ${currencyFormatter.format(incomeTrustInvestmentRequest.amount)} investment request for ${incomeTrustInvestmentRequest.investorAccount.investorName}?`,
            okText: 'Process',
            onOk: () => {
                this.props.process(incomeTrustInvestmentRequest.uuid, sendEmail);
            },
            title: 'Process Investment',
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        pendingInvestmentRequests: incomeTrustInvestmentRequestsPendingSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        pendingInvestmentRequestsList: () => dispatch(investmentIncomeTrustInvestmentRequestsPendingListAction()),
        process: (incomeTrustInvestmentRequestUuid: string, sendEmail: boolean) => dispatch(investmentIncomeTrustInvestmentRequestProcessAction(incomeTrustInvestmentRequestUuid, sendEmail)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PendingRequests);
