import IIndividual from '~Api/Investor/IIndividual';
import IInvestor from '~Api/Investor/IInvestor';
import InvestorTypeEnum from '~Api/Investor/InvestorTypeEnum';

export function isTfnValid(tfn?: string): boolean {
    const cleanTfn: string = tfn ? tfn.replace(/[^\d]/g, '') : '';

    if (cleanTfn.length < 8 || cleanTfn.length > 9) {
        return false;
    }

    let sum: number = 0;
    const weights: number[] = cleanTfn.length === 9 ? [10, 7, 8, 4, 6, 3, 5, 2, 1] : [10, 7, 8, 4, 6, 3, 5, 1];

    weights.forEach((weight: number, position: number) => {
        sum += weight * Number(cleanTfn[position]);
    });

    return (sum % 11) === 0;
}

export function individualRequiresTfn(individual: IIndividual): boolean {
    return (!individual.country || 'AUS' === individual.country);
}

export function investorRequiresTfn(investor: IInvestor): boolean {
    if ([InvestorTypeEnum.Individual, InvestorTypeEnum.JointIndividuals].includes(investor.type)) {
        let requiresTfn: boolean = false;

        investor.individuals.forEach((individual: IIndividual) => {
            requiresTfn = requiresTfn || individualRequiresTfn(individual);
        });

        return requiresTfn;
    }

    return true;
}

export function investorHasValidTfn(investor: IInvestor): boolean {
    if ([InvestorTypeEnum.TrustCompany, InvestorTypeEnum.TrustIndividual].includes(investor.type) && isTfnValid(investor.trust.taxFileNumber)) {
        return true;
    }

    if ([InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany].includes(investor.type) && isTfnValid(investor.company.taxFileNumber)) {
        return true;
    }

    if ([InvestorTypeEnum.Individual, InvestorTypeEnum.JointIndividuals].includes(investor.type)) {
        let hasTfn: boolean = false;

        investor.individuals.forEach((individual: IIndividual) => {
            hasTfn = hasTfn || isTfnValid(individual.taxFileNumber);
        });

        return hasTfn;
    }

    return false;
}
