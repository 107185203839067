import { Button, Dropdown, Menu, Space, Spin, Typography } from 'antd';
import Table, { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestor from '~Api/Investor/IInvestor';
import {
    investorAccountIncomeTrustsListAction,
    investorGetAction,
} from '~Investors/actions';
import {
    investorAccountIncomeTrustsSelector,
    investorSelector,
} from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import IInvestorAccountIncomeTrust from '~Api/IncomeTrust/IInvestorAccountIncomeTrust';
import { currencyFormatter } from '~utilities/formatters';
import IncomeTrustTransactionsModal from './IncomeTrustTransactionsModal';
import { UnorderedListOutlined } from '@ant-design/icons';
import IncomeTrustClassTypeEnum from '~Api/IncomeTrust/IncomeTrustClassTypeEnum';
import IncomeTrustDivestModal from './IncomeTrustDivestModal';
import IncomeTrustInvestModal from './IncomeTrustInvestModal';
import IncomeTrustUpdateInterestPaymentStrategyModal from './IncomeTrustUpdateInterestPaymentStrategyModal';
import InterestPaymentStrategyEnum from '~Api/IncomeTrust/InterestPaymentStrategyEnum';

const classTypeLabels: IDictionary<string> = {
    [IncomeTrustClassTypeEnum.ClassC]: 'Class C - 6 months',
    [IncomeTrustClassTypeEnum.ClassD]: 'Class D - 12 months',
};

const interestPaymentStrategyLabels: IDictionary<string> = {
    [InterestPaymentStrategyEnum.Off]: 'Off',
    [InterestPaymentStrategyEnum.Reinvest]: 'Auto Reinvest',
    [InterestPaymentStrategyEnum.Withdraw]: 'Auto Withdraw',
};

interface IState {
    divestModalUuid: string;
    interestPaymentStrategyModalIsOpen: boolean;
    interestPaymentStrategyModalUuid: string;
    investModalUuid: string;
    showDivestModal: boolean;
    showInvestModal: boolean;
    showTransactionsModal: boolean;
    transactionsModalUuid: string;
}

interface IMatch {
    accountUuid: string;
    investorUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    investor: IInvestor;
    investorAccountIncomeTrusts: IDictionary<IInvestorAccountIncomeTrust>;
}

interface IPropsDispatch {
    investorAccountIncomeTrustsList: () => void;
    investorGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class IncomeTrustInvestments extends React.Component<Props, IState> {
    public state: IState = {
        divestModalUuid: null,
        interestPaymentStrategyModalIsOpen: false,
        interestPaymentStrategyModalUuid: null,
        investModalUuid: null,
        showDivestModal: false,
        showInvestModal: false,
        showTransactionsModal: false,
        transactionsModalUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickDivest = this.onClickDivest.bind(this);
        this.onCloseDivest = this.onCloseDivest.bind(this);
        this.onClickInvest = this.onClickInvest.bind(this);
        this.onCloseInvest = this.onCloseInvest.bind(this);
        this.summary = this.summary.bind(this);
        this.onClickInterestPaymentStrategyModal = this.onClickInterestPaymentStrategyModal.bind(this);
        this.onCloseInterestPaymentStrategyModal = this.onCloseInterestPaymentStrategyModal.bind(this);
        this.onClickTransactionsModal = this.onClickTransactionsModal.bind(this);
        this.onCloseTransactionsModal = this.onCloseTransactionsModal.bind(this);
    }

    public componentDidMount(): void {
        const { investor } = this.props;

        if (!investor) {
            this.props.investorGet();
        }

        this.props.investorAccountIncomeTrustsList();
    }

    public render(): JSX.Element {
        const { investor, investorAccountIncomeTrusts, match } = this.props;
        const {
            divestModalUuid,
            interestPaymentStrategyModalIsOpen,
            interestPaymentStrategyModalUuid,
            investModalUuid,
            showDivestModal,
            showInvestModal,
            showTransactionsModal,
            transactionsModalUuid,
        } = this.state;

        if (!investor || !investorAccountIncomeTrusts) {
            return (
                <Layout uuid={match.params.investorUuid} section={`income-trust-${match.params.accountUuid}`} sectionClass='income-trust'>
                    <Typography.Title level={2}>Investments</Typography.Title>
                    <Spin />
                </Layout>
            );
        }

        const divestModal: JSX.Element = divestModalUuid && (
            <IncomeTrustDivestModal
                investorAccountIncomeTrust={investorAccountIncomeTrusts[divestModalUuid]}
                isOpen={showDivestModal}
                onClose={this.onCloseDivest}
            />
        );

        const investModal: JSX.Element = investModalUuid && (
            <IncomeTrustInvestModal
                investorAccountIncomeTrust={investorAccountIncomeTrusts[investModalUuid]}
                isOpen={showInvestModal}
                onClose={this.onCloseInvest}
            />
        );

        const transactionsModal: JSX.Element = transactionsModalUuid && (
            <IncomeTrustTransactionsModal
                investorAccountIncomeTrust={investorAccountIncomeTrusts[transactionsModalUuid]}
                isOpen={showTransactionsModal}
                onCancel={this.onCloseTransactionsModal}
            />
        );

        const columns: ColumnType<IInvestorAccountIncomeTrust>[] = [
            {
                dataIndex: 'classType',
                defaultSortOrder: 'ascend',
                render: (classType: IncomeTrustClassTypeEnum) => classTypeLabels[classType],
                sorter: (a: IInvestorAccountIncomeTrust, b: IInvestorAccountIncomeTrust) => {
                    return a.classType.localeCompare(b.classType);
                },
                title: 'Class',
            },
            {
                dataIndex: 'interestPaymentStrategy',
                render: (interestPaymentStrategy: string, investorAccountIncomeTrust: IInvestorAccountIncomeTrust) => {
                    const onClick: () => void = () => this.onClickInterestPaymentStrategyModal(investorAccountIncomeTrust.uuid);
                    return (
                        <>
                            <Button type='link' onClick={onClick}>{interestPaymentStrategyLabels[interestPaymentStrategy] ?? interestPaymentStrategyLabels[InterestPaymentStrategyEnum.Off]}</Button>
                            <IncomeTrustUpdateInterestPaymentStrategyModal
                                investorAccountIncomeTrust={investorAccountIncomeTrusts[investorAccountIncomeTrust.uuid]}
                                isOpen={interestPaymentStrategyModalIsOpen && interestPaymentStrategyModalUuid === investorAccountIncomeTrust.uuid}
                                onClose={this.onCloseInterestPaymentStrategyModal}
                            />
                        </>
                    );
                },
                sorter: (a: IInvestorAccountIncomeTrust, b: IInvestorAccountIncomeTrust) => {
                    return a.interestPaymentStrategy > b.interestPaymentStrategy ? 1 : -1;
                },
                title: 'Interest Payment Strategy',
                width: '25%',
            },
            {
                dataIndex: 'principalBalance',
                render: (principalBalance: number) => currencyFormatter.format(principalBalance),
                sorter: (a: IInvestorAccountIncomeTrust, b: IInvestorAccountIncomeTrust) => {
                    return a.principalBalance > b.principalBalance ? 1 : -1;
                },
                title: 'Principal',
                width: '15%',
            },
            {
                dataIndex: 'interestBalance',
                render: (interestBalance: number) => currencyFormatter.format(interestBalance),
                sorter: (a: IInvestorAccountIncomeTrust, b: IInvestorAccountIncomeTrust) => {
                    return a.interestBalance > b.interestBalance ? 1 : -1;
                },
                title: 'Interest',
                width: '15%',
            },
            {
                dataIndex: 'uuid',
                render: (uuid: string) => {
                    const onClickTransactions: () => void = () => this.onClickTransactionsModal(uuid);
                    const onClickDivest: () => void = () => this.onClickDivest(uuid);
                    const onClickInvest: () => void = () => this.onClickInvest(uuid);

                    return (
                        <Space>
                            <Button onClick={onClickInvest}>Invest</Button>
                            <Button onClick={onClickDivest}>Divest</Button>
                            <Button icon={<UnorderedListOutlined />} onClick={onClickTransactions} />
                        </Space>
                    );
                },
                width: '20%',
            },
        ];

        return (
            <Layout uuid={match.params.investorUuid} section={`income-trust-${match.params.accountUuid}`} sectionClass='income-trust'>
                <Typography.Title level={2}>Investments</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(investorAccountIncomeTrusts)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                    summary={this.summary}
                />
                {divestModal}
                {investModal}
                {transactionsModal}
            </Layout>
        );
    }

    private summary(): JSX.Element {
        const { investorAccountIncomeTrusts } = this.props;

        let principalTotal: number = 0;
        let interestTotal: number = 0;

        _.forEach(investorAccountIncomeTrusts, (investorAccountIncomeTrust: IInvestorAccountIncomeTrust) => {
            principalTotal += investorAccountIncomeTrust.principalBalance;
            interestTotal += investorAccountIncomeTrust.interestBalance;
        });

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0} />
                <Table.Summary.Cell index={1} />
                <Table.Summary.Cell index={2}><strong>{currencyFormatter.format(principalTotal)}</strong></Table.Summary.Cell>
                <Table.Summary.Cell index={3}><strong>{currencyFormatter.format(interestTotal)}</strong></Table.Summary.Cell>
                <Table.Summary.Cell index={4} />
            </Table.Summary.Row>
        );
    }

    private onClickInterestPaymentStrategyModal(investorAccountIncomeTrustUuid: string): void {
        this.setState({
            interestPaymentStrategyModalIsOpen: true,
            interestPaymentStrategyModalUuid: investorAccountIncomeTrustUuid,
        });
    }

    private onCloseInterestPaymentStrategyModal(): void {
        this.setState({
            interestPaymentStrategyModalIsOpen: false,
        });
    }

    private onClickTransactionsModal(investorAccountIncomeTrustUuid: string): void {
        this.setState({
            showTransactionsModal: true,
            transactionsModalUuid: investorAccountIncomeTrustUuid,
        });
    }

    private onCloseTransactionsModal(): void {
        this.setState({
            showTransactionsModal: false,
            transactionsModalUuid: null,
        });
    }

    private onClickDivest(investorAccountIncomeTrustUuid: string): void {
        this.setState({
            divestModalUuid: investorAccountIncomeTrustUuid,
            showDivestModal: true,
        });
    }

    private onCloseDivest(): void {
        this.setState({
            divestModalUuid: null,
            showDivestModal: false,
        });
    }

    private onClickInvest(investorAccountIncomeTrustUuid: string): void {
        this.setState({
            investModalUuid: investorAccountIncomeTrustUuid,
            showInvestModal: true,
        });
    }

    private onCloseInvest(): void {
        this.setState({
            investModalUuid: null,
            showInvestModal: false,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investor: investorSelector(state, ownProps.match.params.investorUuid),
        investorAccountIncomeTrusts: investorAccountIncomeTrustsSelector(state, ownProps.match.params.accountUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investorAccountIncomeTrustsList: () => dispatch(investorAccountIncomeTrustsListAction(ownProps.match.params.accountUuid)),
        investorGet: () => dispatch(investorGetAction(ownProps.match.params.investorUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IncomeTrustInvestments);
