import dayjs from 'dayjs';
import _ from 'lodash';
import roundTo from 'round-to';
import DischargeInterestTypeEnum from '~Api/Application/DischargeInterestTypeEnum';
import ILoan from '~Api/Loan/ILoan';
import ILoanPayoutFigure from '~Api/Loan/ILoanPayoutFigure';
import ILoanPayoutFigureItem from '~Api/Loan/ILoanPayoutFigureItem';
import ILoanPayoutFigureSection from '~Api/Loan/ILoanPayoutFigureSection';
import LoanPayoutFigureAccruedInterestModeEnum from '~Api/Loan/LoanPayoutFigureAccruedInterestModeEnum';
import LoanPayoutFigureMinimumTermInterestModeEnum from '~Api/Loan/LoanPayoutFigureMinimumTermInterestModeEnum';
import LoanPayoutFigurePrepaidInterestModeEnum from '~Api/Loan/LoanPayoutFigurePrepaidInterestModeEnum';
import { percentageFormatter } from '~utilities/formatters';

export interface ILoanInterestCalculation {
    accruedInterest: number;
    accruedInterestLabel: string;
    minimumTermInterest: number;
    prepaidInterest: number;
}

/**
 * Calculate the accrued interest of a loan for a period between the discharge date and the last payment date before the discharge date
 * Calcultate the prepaid interest remaining between the discharge date and the last payment date before the discharge date
 */
export function getLoanInterestCalculation(loan: ILoan, dischargeDate: string): ILoanInterestCalculation {
    // Check if the discharge date is earlier than the last loan payment date
    if (loan.currentPaymentDate > dischargeDate) {
        throw new Error(`Discharge date is earlier than the last loan payment for ${loan.code}`);
    }

    let lastPaymentDate: string = loan.currentPaymentDate;
    let numberOfMonthlyPayments: number = 0;

    // The loop continues until it either finds the last payment date before the discharge date or reaches the loan end date.
    while (lastPaymentDate < loan.endDate) {
        const nextPaymentDate: string = getLoanNextPaymentDate(loan, lastPaymentDate);

        // Check if the next payment date is greater than the discharge date
        // This is to break if it is greater and use the last value assigned to last payment date before discharge date
        if (nextPaymentDate > dischargeDate) {
            break;
        }

        lastPaymentDate = nextPaymentDate;
        numberOfMonthlyPayments++;
    }

    const dailyInterest: number = loan.amount * loan.interestRate / 100 / 360;

    const daysUtilised: number = Math.abs(dayjs(dischargeDate).diff(dayjs(lastPaymentDate), 'days'));

    const accruedInterest: number = roundTo(dailyInterest * daysUtilised, 2);

    const monthlyInterest: number = roundTo(loan.amount * loan.interestRate / 100 / 12, 2);

    const prepaidInterest: number = roundTo(loan.prepaidInterestBalance - (monthlyInterest * numberOfMonthlyPayments), 2);

    const accruedInterestLabel: string = `Remaining interest payable at the rate of ${percentageFormatter.format(loan.interestRate / 100)} p.a`;

    // Calculation of minimum term interest
    let minimumTermInterest: number = null;

    if (dischargeDate < loan.endDate && loan.application.dischargeInterestType === DischargeInterestTypeEnum.MinimumTerm) {
        let minimumTermMonthsLeft: number = loan.termMonthsMinimum || loan.termMonths;
        let paymentDate: string = loan.startDate;

        while (paymentDate < dischargeDate) {
            const nextPaymentDate: string = getLoanNextPaymentDate(loan, paymentDate);
            if (nextPaymentDate > dischargeDate) {
                break;
            }
            paymentDate = nextPaymentDate;
            minimumTermMonthsLeft--;
        }

        minimumTermInterest = minimumTermMonthsLeft > 0 ? roundTo(monthlyInterest * minimumTermMonthsLeft, 2) - accruedInterest : 0;
    }

    return {
        accruedInterest,
        accruedInterestLabel,
        minimumTermInterest,
        prepaidInterest,
    };
}

/**
 * Determine the next loan payment date
 */
function getLoanNextPaymentDate(loan: ILoan, currentPaymentDate: string = null): string {
    // If current payment date is not provided, use the next payment date or the loan start date
    if (!currentPaymentDate) {
        currentPaymentDate = loan.paymentDateNext ? loan.paymentDateNext : loan.startDate;
    }

    // Get the next payment date by moving back to the start of the current month.
    // This is to avoid overflow issues when adding a month.
    const nextPaymentDate: dayjs.Dayjs = dayjs(currentPaymentDate).startOf('month').add(1, 'month');

    // Determine the number of days in the next payment month
    const daysInNextPaymentMonth: number = nextPaymentDate.endOf('month').date();

    // Determine the payment day based on the loan start date
    const paymentDay: number = dayjs(loan.startDate).date();

    // Determine the next payment day by selecting the payment day or the last day of the month, whichever is smaller
    const nextPaymentDay: number = daysInNextPaymentMonth >= paymentDay ? paymentDay : daysInNextPaymentMonth;

    const nextPaymentDateString: string = dayjs(`${nextPaymentDate.format('YYYY-MM')}-${nextPaymentDay}`).format('YYYY-MM-DD');

    // Ensure the next payment date does not exceed the loan end date
    return nextPaymentDateString > loan.endDate ? loan.endDate : nextPaymentDateString;
}

export function getLoanPayoutFigureTotals(loan: ILoan, loanPayoutFigure: ILoanPayoutFigure): { balanceAmount: number, totalPayoutFigure: number } {
    let totalPayoutFigure: number = loanPayoutFigure.principalAmount;

    if (loanPayoutFigure.accruedInterestMode === LoanPayoutFigureAccruedInterestModeEnum.Auto) {
        totalPayoutFigure += getLoanAppliedAccruedInterestTotal(loanPayoutFigure);
    }

    // Iterate over sections and their items to add their amounts to the balance amount.
    _.each(loanPayoutFigure.sections, (section: ILoanPayoutFigureSection) => {
        _.each(section.items, (item: ILoanPayoutFigureItem) => {
            totalPayoutFigure += item.amount;
        });
    });

    const appliedPrepaidInterest: number = loan.currentPaymentDate && loanPayoutFigure.prepaidInterestMode === LoanPayoutFigurePrepaidInterestModeEnum.Auto ? loanPayoutFigure.prepaidInterestAuto : loanPayoutFigure.prepaidInterest;

    const balanceAmount: number = totalPayoutFigure - loanPayoutFigure.trustAmount - appliedPrepaidInterest;

    return {
        balanceAmount,
        totalPayoutFigure,
    };
}

export function getLoanAppliedAccruedInterestTotal(loanPayoutFigure: ILoanPayoutFigure): number {
    let accruedInterestTotal: number = 0;

    if (loanPayoutFigure.accruedInterestMode === LoanPayoutFigureAccruedInterestModeEnum.Auto) {
        accruedInterestTotal += loanPayoutFigure.accruedInterest;

        if (loanPayoutFigure.minimumTermInterestMode === LoanPayoutFigureMinimumTermInterestModeEnum.Auto) {
            accruedInterestTotal += loanPayoutFigure.minimumTermInterest;
        }
    }

    return accruedInterestTotal;
}
