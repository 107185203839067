import { Form, Input, Spin, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IAdviser from '~Api/Adviser/IAdviser';
import {
    adviserGetAction,
    adviserValueSetAction,
} from '~Advisers/actions';
import { adviserSelector } from '~Advisers/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';

interface IMatch {
    adviserUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    adviser: IAdviser;
}

interface IPropsDispatch {
    adviserGet: () => void;
    adviserValueSet: (key: keyof IAdviser, value: string|number|boolean) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Edit extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeAfslNumber = this.onChangeAfslNumber.bind(this);
        this.onChangeAuthorisedRepresentativeNumber = this.onChangeAuthorisedRepresentativeNumber.bind(this);
        this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
        this.onChangeDealerGroup = this.onChangeDealerGroup.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
    }

    public componentDidMount() {
        this.props.adviserGet();
    }

    public render(): JSX.Element {
        const { adviser, match } = this.props;

        if (!adviser) {
            return (
                <Layout adviserUuid={match.params.adviserUuid} section='edit'>
                    <Typography.Title level={2}>Edit</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        return (
            <Layout adviserUuid={match.params.adviserUuid} section='edit'>
                <Typography.Title level={2}>Edit</Typography.Title>
                <Form.Item label='First Name' className='first-name'>
                    <Input onChange={this.onChangeFirstName} value={adviser.firstName} />
                </Form.Item>
                <Form.Item label='Last Name' className='last-name'>
                    <Input onChange={this.onChangeLastName} value={adviser.lastName} />
                </Form.Item>
                <Form.Item label='Phone' className='phone'>
                    <Input onChange={this.onChangePhone} value={adviser.phone} />
                </Form.Item>
                <Form.Item label='Email' className='email'>
                    <Input onChange={this.onChangeEmail} value={adviser.email} />
                </Form.Item>
                <Form.Item label='Company Name' className='company-name'>
                    <Input onChange={this.onChangeCompanyName} value={adviser.companyName} />
                </Form.Item>
                <Form.Item label='Dealer Group' className='dealer-group'>
                    <Input onChange={this.onChangeDealerGroup} value={adviser.dealerGroup} />
                </Form.Item>
                <Form.Item label='AFSL Number' className='afsl-number'>
                    <Input onChange={this.onChangeAfslNumber} value={adviser.afslNumber} />
                </Form.Item>
                <Form.Item label='Authorised Representative Number' className='authorised-representative-number'>
                    <Input onChange={this.onChangeAuthorisedRepresentativeNumber} value={adviser.authorisedRepresentativeNumber} />
                </Form.Item>
            </Layout>
        );
    }

    private onChangeAfslNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.adviserValueSet('afslNumber', event.target.value);
    }

    private onChangeAuthorisedRepresentativeNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.adviserValueSet('authorisedRepresentativeNumber', event.target.value);
    }

    private onChangeCompanyName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.adviserValueSet('companyName', event.target.value);
    }

    private onChangeDealerGroup(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.adviserValueSet('dealerGroup', event.target.value);
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.adviserValueSet('email', event.target.value);
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.adviserValueSet('firstName', event.target.value);
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.adviserValueSet('lastName', event.target.value);
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.adviserValueSet('phone', event.target.value);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        adviser: adviserSelector(state, ownProps.match.params.adviserUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        adviserGet: () => dispatch(adviserGetAction(ownProps.match.params.adviserUuid)),
        adviserValueSet: (key: keyof IAdviser, value: string|number|boolean) => dispatch(adviserValueSetAction(ownProps.match.params.adviserUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Edit);
