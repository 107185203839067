import _ from 'lodash';
import IInvestment from '~Api/Investment/IInvestment';
import { parseInvestment } from '~Api/Investment/parsers';
import SourceEnum from '~Api/Investor/SourceEnum';
import IUser from '~Api/User/IUser';
import { parseUser } from '~Api/User/parsers';
import AccountApprovalStatusEnum from './AccountApprovalStatusEnum';
import AccountTypeEnum from './AccountTypeEnum';
import ApprovalStatusEnum from './ApprovalStatusEnum';
import AvailableFundsTypeEnum from './AvailableFundsTypeEnum';
import ComplianceDocumentTypeEnum from './ComplianceDocumentTypeEnum';
import BankAccountConfirmationStatusEnum from './ConfirmationStatusEnum';
import IAccount from './IAccount';
import IAnnualStatement from './IAnnualStatement';
import IBankAccount from './IBankAccount';
import ICompany from './ICompany';
import IComplianceDocument from './IComplianceDocument';
import IDocument from './IDocument';
import IdProofTypeEnum from './IdProofTypeEnum';
import IHistory from './IHistory';
import IIndividual from './IIndividual';
import IInvalidInvestorTransaction from './IInvalidInvestorTransaction';
import IInvestor from './IInvestor';
import IInvestorAccountInvestment from './IInvestorAccountInvestment';
import IInvestorAccountInvestmentTransaction from './IInvestorAccountInvestmentTransaction';
import IInvestorAccountTransaction from './IInvestorAccountTransaction';
import IInvestorUser from './IInvestorUser';
import IMonthlyStatement from './IMonthlyStatement';
import INote from './INote';
import InvestorAccountTransactionTypeEnum from './InvestorAccountTransactionTypeEnum';
import InvestorTypeEnum from './InvestorTypeEnum';
import IPendingWithdrawal from './IPendingWithdrawal';
import IReferral from './IReferral';
import IShare from './IShare';
import ITransaction from './ITransaction';
import ITrust from './ITrust';
import ResidentTypeEnum from './ResidentTypeEnum';
import TransactionTypeEnum from './TransactionTypeEnum';
import IInvestorAccountIncomeTrust from '~Api/IncomeTrust/IInvestorAccountIncomeTrust';
import { parseInvestorAccountIncomeTrust } from '~Api/IncomeTrust/parsers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseAccount(raw: any): IAccount {
    const incomeTrusts: IInvestorAccountIncomeTrust[] = raw.incomeTrusts && raw.incomeTrusts.map(parseInvestorAccountIncomeTrust);

    return {
        accountType: raw.accountType as AccountTypeEnum,
        approvalStatus: raw.approvalStatus as AccountApprovalStatusEnum,
        balance: raw.balance,
        code: raw.code,
        createdTime: raw.createdTime,
        depositAmountPending: raw.depositAmountPending,
        depositRequestTime: raw.depositRequestTime,
        incomeTrusts,
        interestAmountEarned: raw.interestAmountEarned,
        investedAmountCurrent: raw.investedAmountCurrent,
        investmentCountTotal: raw.investmentCountTotal,
        investorName: raw.investorName,
        investorUuid: raw.investorUuid,
        isCompleted: raw.isCompleted,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseAnnualStatement(raw: any): IAnnualStatement {
    return {
        investorAccountUuid: raw.investorAccountUuid,
        uuid: raw.uuid,
        year: raw.year,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseBankAccount(raw: any): IBankAccount {
    return {
        accountName: raw.accountName,
        accountNumber: raw.accountNumber,
        bankName: raw.bankName,
        bsb: raw.bsb,
        confirmationStatus: raw.confirmationStatus as BankAccountConfirmationStatusEnum,
        createdTime: raw.createdTime,
        investorUuid: raw.investorUuid,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseCompany(raw: any): ICompany {
    return {
        australianCompanyNumber: raw.australianCompanyNumber,
        name: raw.name,
        postcode: raw.postcode,
        state: raw.state,
        streetAddress: raw.streetAddress,
        suburb: raw.suburb,
        taxFileNumber: raw.taxFileNumber,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseIndividual(raw: any): IIndividual {
    return {
        country: raw.country,
        dateOfBirth: raw.dateOfBirth,
        driverLicenceCardNumber: raw.driverLicenceCardNumber,
        driverLicenceState: raw.driverLicenceState,
        email: raw.email,
        firstName: raw.firstName,
        idProofExpiryDate: raw.idProofExpiryDate,
        idProofNumber: raw.idProofNumber,
        idProofType: raw.idProofType as IdProofTypeEnum,
        index: raw.index,
        investorUuid: raw.investorUuid,
        lastName: raw.lastName,
        postcode: raw.postcode,
        residentType: raw.residentType as ResidentTypeEnum,
        state: raw.state,
        streetAddress: raw.streetAddress,
        suburb: raw.suburb,
        taxFileNumber: raw.taxFileNumber,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvestor(raw: any): IInvestor {
    const accounts: { [accountType: string]: IAccount } = {};
    let balanceTotal: number = 0;
    let investedAmountCurrentTotal: number = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _.each(raw.accounts, (rawAccount: any) => {
        const account: IAccount = parseAccount(rawAccount);
        accounts[account.accountType] = account;
        balanceTotal += account.balance;
        investedAmountCurrentTotal += account.investedAmountCurrent;
    });

    const bankAccounts: { [bankAccountUuid: string]: IBankAccount } = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _.each(raw.bankAccounts, (rawBankAccount: any) => {
        const bankAccount: IBankAccount = parseBankAccount(rawBankAccount);
        bankAccounts[bankAccount.uuid] = bankAccount;
    });

    return {
        accounts,
        adviserName: raw.adviserName,
        adviserUuid: raw.adviserUuid,
        approvalStatus: raw.approvalStatus as ApprovalStatusEnum,
        availableFundsType: raw.availableFundsType as AvailableFundsTypeEnum,
        balanceTotal,
        bankAccounts,
        company: raw.company && parseCompany(raw.company),
        contactDateNext: raw.contactDateNext,
        createdTime: raw.createdTime,
        imUuid: raw.imUuid,
        individuals: raw.individuals.map(parseIndividual),
        investedAmountCurrentTotal,
        isCompleted: raw.isCompleted,
        isManaged: raw.isManaged,
        landingPage: raw.landingPage,
        name: raw.name,
        primaryBankAccountUuid: raw.primaryBankAccountUuid,
        source: raw.source as SourceEnum,
        trust: raw.trust && parseTrust(raw.trust),
        type: raw.type as InvestorTypeEnum,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvestorAccountInvestment(raw: any): IInvestorAccountInvestment {
    const investment: IInvestment = raw.investment && parseInvestment(raw.investment);
    const investor: IInvestor = raw.investor && parseInvestor(raw.investor);

    return {
        feesBalance: raw.feesBalance,
        interestBalance: raw.interestBalance,
        investment,
        investmentUuid: investment && investment.uuid,
        investor,
        investorUuid: investor && investor.uuid,
        principalBalance: raw.principalBalance,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvalidInvestorTransaction(rawInvalidInvestorTransaction: any): IInvalidInvestorTransaction {
    const investorAccountTransaction: IInvestorAccountTransaction = parseInvestorAccountTransaction(rawInvalidInvestorTransaction);

    return {
        ...investorAccountTransaction,
        errors: rawInvalidInvestorTransaction.errors,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvestorPendingWithdrawal(rawPendingWithdrawal: any): IPendingWithdrawal {
    const investorAccountTransaction: IInvestorAccountTransaction = parseInvestorAccountTransaction(rawPendingWithdrawal.investorAccountTransaction);
    return {
        approvedTime: rawPendingWithdrawal.approvedTime,
        investorAccountTransaction,
        investorAccountTransactionUuid: investorAccountTransaction.uuid,
        reasons: rawPendingWithdrawal.reasons,
        uuid: rawPendingWithdrawal.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvestorAccountInvestmentTransaction(raw: any): IInvestorAccountInvestmentTransaction {
    const investorAccount: IAccount = raw.investorAccount && parseAccount(raw.investorAccount);

    return {
        createdTime: raw.createdTime,
        description: raw.description,
        interestAmount: raw.interestAmount,
        investmentUuid: raw.investmentUuid,
        investorAccount,
        investorAccountUuid: raw.investorAccountUuid,
        investorUuid: raw.investorUuid,
        principalAmount: raw.principalAmount,
        transactionTime: raw.transactionTime,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvestorAccountTransaction(raw: any): IInvestorAccountTransaction {
    const investorAccount: IAccount = raw.investorAccount && parseAccount(raw.investorAccount);

    return {
        amount: raw.amount,
        createdTime: raw.createdTime,
        description: raw.description,
        investor: !!raw.investor ? parseInvestor(raw.investor) : null,
        investorAccount,
        investorAccountUuid: raw.investorAccountUuid,
        investorUuid: raw.investorUuid,
        transactionTime: raw.transactionTime,
        transactionType: raw.transactionType as InvestorAccountTransactionTypeEnum,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvestorComplianceDocument(raw: any): IComplianceDocument {
    return {
        documentType: raw.documentType as ComplianceDocumentTypeEnum,
        investorAccountUuid: raw.investorAccountUuid,
        investorFileUuid: raw.investorFileUuid,
        investorIndividualUuid: raw.investorIndividualUuid,
        investorUuid: raw.investorUuid,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvestorDocument(raw: any): IDocument {
    return {
        createdTime: raw.createdTime,
        filename: raw.filename,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvestorHistory(raw: any): IHistory {
    return {
        createdTime: raw.createdTime,
        description: raw.description,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvestorUser(raw: any): IInvestorUser {
    const investor: IInvestor = raw.investor && parseInvestor(raw.investor);
    const user: IUser = raw.user && parseUser(raw.user);

    return {
        createdTime: raw.createdTime,
        investor,
        investorUuid: investor && investor.uuid,
        user,
        userUuid: user && user.uuid,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvestorNote(raw: any): INote {
    return {
        administratorUuid: raw.administratorUuid,
        createdTime: raw.createdTime,
        note: raw.note,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseMonthlyStatement(raw: any): IMonthlyStatement {
    return {
        investorAccountUuid: raw.investorAccountUuid,
        month: raw.month,
        uuid: raw.uuid,
        year: raw.year,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseReferral(raw: any): IReferral {
    return {
        code: raw.code,
        createdTime: raw.createdTime,
        investorId: raw.investorId,
        investorRewardAmount: raw.investorRewardAmount,
        investorUuid: raw.investorUuid,
        isEligible: raw.isEligible,
        isProcessed: raw.isProcessed,
        referralEmailAddress: raw.referralEmailAddress,
        referralInvestorId: raw.referralInvestorId,
        referralInvestorUuid: raw.referralInvestorUuid,
        referralRewardAmount: raw.referralRewardAmount,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseShare(raw: any): IShare {
    return {
        amountInvested: raw.amountInvested,
        amountRemaining: raw.amountRemaining,
        createdTime: raw.createdTime,
        endDate: raw.endDate,
        interestEarned: raw.interestEarned,
        interestRate: raw.interestRate,
        loanName: raw.loanName,
        name: raw.name,
        salesforceLoanId: raw.loanId,
        startDate: raw.startDate,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseTransaction(raw: any): ITransaction {
    return {
        activityDate: raw.activityDate,
        amount: raw.amount,
        balance: raw.balance,
        createdTime: raw.createdTime,
        description: raw.description,
        type: raw.type as TransactionTypeEnum,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseTrust(raw: any): ITrust {
    return {
        australianBusinessNumber: raw.australianBusinessNumber,
        name: raw.name,
        taxFileNumber: raw.taxFileNumber,
    };
}