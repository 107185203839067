import { fetchDelete, fetchGet, fetchPost, fetchPostFile, fetchPut } from '~utilities/fetch';
import ApprovalStatusEnum from './ApprovalStatusEnum';
import CloseReasonEnum from './CloseReasonEnum';
import IDeal from './IDeal';
import IDealBorrower from './IDealBorrower';
import INote from './INote';
import IProperty from './IProperty';
import IQuote from './IQuote';
import ListSortOrderEnum from './ListSortOrderEnum';
import ReferredToEnum from './ReferredToEnum';
import RejectReasonEnum from './RejectReasonEnum';
import {
    transformDeal,
    transformDealBorrower,
    transformDealNote,
    transformDealProperty,
    transformDealQuote,
} from './transformers';

export async function dealsListRequest(page: number, perPage: number, orderBy: string, order: ListSortOrderEnum, administratorUuids: string[], approvalStatuses: ApprovalStatusEnum[], name: string): Promise<Response> {
    return fetchGet('/deals' , {
        administratorUuids,
        approvalStatuses,
        name,
        order,
        orderBy,
        page,
        perPage,
    });
}

export async function dealsBoardRequest(): Promise<Response> {
    return fetchGet('/deals/board');
}

export async function dealsSearchRequest(keyword: string): Promise<Response> {
    return fetchPost('/deals/search', { keyword });
}

export async function dealAddRequest(deal: IDeal): Promise<Response> {
    return fetchPost('/deals', transformDeal(deal));
}

export async function dealAssignRequest(dealUuid: string, administratorUuid: string): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/assign`, {
        administratorUuid,
    });
}

export async function dealAssignProcessorRequest(dealUuid: string, administratorUuid: string): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/assign-processor`, {
        administratorUuid,
    });
}

export async function dealBorrowerUpdateRequest(borrower: IDealBorrower): Promise<Response> {
    return fetchPut(`/deal-borrowers/${borrower.uuid}`, transformDealBorrower(borrower));
}

export async function dealCloseRequest(dealUuid: string, reason: CloseReasonEnum, note: string): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/close`, {
        note,
        reason,
    });
}

export async function dealGetRequest(dealUuid: string): Promise<Response> {
    return fetchGet(`/deals/${dealUuid}`);
}

export async function dealDocumentsAddRequest(dealUuid: string, file: File): Promise<Response> {
    return fetchPostFile(`/deals/${dealUuid}/files`, file);
}

export async function dealDocumentsListRequest(dealUuid: string): Promise<Response> {
    return fetchGet(`/deals/${dealUuid}/documents`);
}

export async function dealEstablishmentFeeMinimumRequest(dealUuid: string, establishmentFeeDollarsMinimum: number, establishmentFeePercentageMinimum: number): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/establishment-fee-minimum`, {
        establishmentFeeDollarsMinimum,
        establishmentFeePercentageMinimum,
    });
}

export async function dealFollowUpRequest(dealUuid: string, sendEmail: boolean): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/follow-up`, { sendEmail });
}

export async function dealFollowUpTimeRequest(dealUuid: string, followUpTime: string, reason: string): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/follow-up-time`, {
        followUpTime,
        reason,
    });
}

export async function dealInitialCallRequest(dealUuid: string): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/initial-call`);
}

export async function dealInterestRateMinimumRequest(dealUuid: string, interestRateMinimum: number): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/interest-rate-minimum`, { interestRateMinimum });
}

export async function dealLegalFeesDollarsMinimumRequest(dealUuid: string, legalFeesDollarsMinimum: number): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/legal-fees-dollars-minimum`, { legalFeesDollarsMinimum });
}

export async function dealLvrMaximumRequest(dealUuid: string, lvrMaximum: number): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/lvr-maximum`, { lvrMaximum });
}

export async function dealMovementReportRequest(startTime: string, endTime: string): Promise<Response> {
    return fetchGet('/deals/movement-report', {
        endTime,
        startTime,
    });
}

export async function dealOriginationReportRequest(startDate: string, endDate: string): Promise<Response> {
    return fetchGet('/deals/origination-report', {
        endDate,
        startDate,
    });
}

export async function dealReferRequest(dealUuid: string, referredTo: ReferredToEnum, sendEmail: boolean): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/refer`, {
        referredTo,
        sendEmail,
    });
}

export async function dealRejectRequest(dealUuid: string, reason: RejectReasonEnum, sendEmail: boolean): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/reject`, {
        reason,
        sendEmail,
    });
}

export async function dealUpdateRequest(deal: IDeal): Promise<Response> {
    return fetchPut(`/deals/${deal.uuid}`, transformDeal(deal));
}

export async function dealNotesAddRequest(dealUuid: string, note: INote): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/notes`, transformDealNote(note));
}

export async function dealNotesListRequest(dealUuid: string): Promise<Response> {
    return fetchGet(`/deals/${dealUuid}/notes`);
}

export async function dealHistoriesListRequest(dealUuid: string): Promise<Response> {
    return fetchGet(`/deals/${dealUuid}/history`);
}

export async function dealPropertiesAddRequest(dealUuid: string, property: IProperty): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/properties`, transformDealProperty(property));
}

export async function dealPropertiesListRequest(dealUuid: string): Promise<Response> {
    return fetchGet(`/deals/${dealUuid}/properties`);
}

export async function dealPropertiesPostcodeCategoryListRequest(): Promise<Response> {
    return fetchGet('/deal-properties/postcode-categories');
}

export async function dealPropertiesSpPostcodeCategoryRequest(): Promise<Response> {
    return fetchGet('/deal-properties/sp-postcode-categories');
}

export async function dealPropertyDeleteRequest(propertyUuid: string): Promise<Response> {
    return fetchDelete(`/deal-properties/${propertyUuid}`);
}

export async function dealPropertyUpdateRequest(property: IProperty): Promise<Response> {
    return fetchPut(`/deal-properties/${property.uuid}`, transformDealProperty(property));
}

export async function dealQuotesPendingListRequest(): Promise<Response> {
    return fetchGet('/deal-quotes/pending');
}

export async function dealQuotesListRequest(dealUuid: string): Promise<Response> {
    return fetchGet(`/deals/${dealUuid}/quotes`);
}

export async function dealQuotesAddRequest(dealUuid: string, quote: IQuote, sendEmail: boolean, isScenario: boolean): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/quotes`, {
        ...transformDealQuote(quote),
        isScenario,
        sendEmail,
    });
}

export async function dealQuoteApproveRequest(quoteUuid: string): Promise<Response> {
    return fetchPost(`/deal-quotes/${quoteUuid}/approve`);
}

export async function dealQuoteConvertScenarioRequest(quoteUuid: string, sendEmail: boolean,): Promise<Response> {
    return fetchPost(`/deal-quotes/${quoteUuid}/convert-scenario`, { sendEmail });
}

export async function dealQuoteRejectRequest(quoteUuid: string, reason: string): Promise<Response> {
    return fetchPost(`/deal-quotes/${quoteUuid}/reject`, { reason });
}

export async function dealQuoteCreateApplicationRequest(quoteUuid: string): Promise<Response> {
    return fetchPost(`/deal-quotes/${quoteUuid}/create-application`);
}

export async function dealQuoteSendRequest(quoteUuid: string, email?: string, firstName?: string, lastName?: string): Promise<Response> {
    return fetchPost(`/deal-quotes/${quoteUuid}/send`, !!email ? {
        email,
        firstName,
        lastName,
    } : null);
}

export async function dealReopenRequest(dealUuid: string): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/reopen`);
}

export async function dealUnassignProcessorRequest(dealUuid: string, administratorUuid: string): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/unassign-processor`, {
        administratorUuid,
    });
}

export async function dealUncontactableFollowUpRequest(dealUuid: string, sendEmail: boolean): Promise<Response> {
    return fetchPost(`/deals/${dealUuid}/uncontactable-follow-up`, { sendEmail });
}
