import { Button, Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ILoanFee from '~Api/Loan/ILoanFee';
import { loanFeeValueSetAction } from '~Loans/actions';
import { loanFeeSelector } from '~Loans/selectors';
import { IGlobalState } from '~reducer';

interface IProps {
    feeUuid: string;
    isOpen: boolean;
    loanUuid: string;
    onClose: () => void;
}

interface IPropsSelector {
    fee: ILoanFee;
}

interface IPropsDispatch {
    update: (key: keyof ILoanFee, value: string|number|boolean) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class FeeEditModal extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { fee, isOpen } = this.props;

        if (!fee) {
            return null;
        }

        const footer: JSX.Element = (
            <React.Fragment>
                <Button className='close' onClick={this.props.onClose}>Close</Button>
            </React.Fragment>
        );

        return (
            <Modal
                destroyOnClose={true}
                footer={footer}
                okText='Close'
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title='Edit Fee/Outlay'
                wrapClassName='loan-fee-edit-modal'
            >
                <Form.Item label='Amount' className='amount'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeAmount} type='number' value={fee.amount} />
                </Form.Item>
                <Form.Item label='Description' className='description'>
                    <Input maxLength={255} onChange={this.onChangeDescription} value={fee.description} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeAmount(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.update('amount', Number(event.target.value));
    }

    private onChangeDescription(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.update('description', event.target.value);
    }

    private onClickOk() {
        this.props.onClose();
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        fee: loanFeeSelector(state, ownProps.loanUuid, ownProps.feeUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        update: (key: keyof ILoanFee, value: string|number|boolean) => dispatch(loanFeeValueSetAction(ownProps.loanUuid, ownProps.feeUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FeeEditModal);
