import { Action } from 'redux';
import IInvestorIncomeTrustTransaction from '~Api/IncomeTrust/IInvestorIncomeTrustTransaction';
import InterestPaymentStrategyEnum from '~Api/IncomeTrust/InterestPaymentStrategyEnum';
import IAccount from '~Api/Investor/IAccount';
import IAnnualStatement from '~Api/Investor/IAnnualStatement';
import IBankAccount from '~Api/Investor/IBankAccount';
import ICompany from '~Api/Investor/ICompany';
import IComplianceDocument from '~Api/Investor/IComplianceDocument';
import IDocument from '~Api/Investor/IDocument';
import IHistory from '~Api/Investor/IHistory';
import IIndividual from '~Api/Investor/IIndividual';
import IInvestor from '~Api/Investor/IInvestor';
import IInvestorAccountIncomeTrust from '~Api/IncomeTrust/IInvestorAccountIncomeTrust';
import IInvestorAccountInvestment from '~Api/Investor/IInvestorAccountInvestment';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import IInvestorUser from '~Api/Investor/IInvestorUser';
import IMonthlyStatement from '~Api/Investor/IMonthlyStatement';
import INote from '~Api/Investor/INote';
import IPendingWithdrawal from '~Api/Investor/IPendingWithdrawal';
import IReferral from '~Api/Investor/IReferral';
import IShare from '~Api/Investor/IShare';
import ITransaction from '~Api/Investor/ITransaction';
import ITrust from '~Api/Investor/ITrust';
import InvestorsActionsEnum from './ActionsEnum';
import IWithdrawalAbaInvestorTransaction from '~Api/WithdrawalAba/IWithdrawalAbaInvestorTransaction';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestorsBoardAction extends Action {}

export function investorsBoardAction(): IInvestorsBoardAction {
    return {
        type: InvestorsActionsEnum.InvestorsBoard,
    };
}

export interface IInvestorsBoardSetAction extends Action {
    investors: IInvestor[];
}

export function investorsBoardSetAction(investors: IInvestor[]): IInvestorsBoardSetAction {
    return {
        investors,
        type: InvestorsActionsEnum.InvestorsBoardSet,
    };
}

/** @deprecated */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestorsListAction extends Action { }

/** @deprecated */
export function investorsListAction(): IInvestorsListAction {
    return {
        type: InvestorsActionsEnum.InvestorsList,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestorsPrivateListAction extends Action { }

export function investorsPrivateListAction(): IInvestorsPrivateListAction {
    return {
        type: InvestorsActionsEnum.InvestorsPrivateList,
    };
}

export interface IInvestorsPrivateSetAction extends Action {
    investors: IInvestor[];
}

export function investorsPrivateSetAction(investors: IInvestor[]): IInvestorsPrivateSetAction {
    return {
        investors,
        type: InvestorsActionsEnum.InvestorsPrivateSet,
    };
}

/** @deprecated */
export interface IInvestorsSetAction extends Action {
    investors: IInvestor[];
}

/** @deprecated */
export function investorsSetAction(investors: IInvestor[]): IInvestorsSetAction {
    return {
        investors,
        type: InvestorsActionsEnum.InvestorsSet,
    };
}

export interface IInvestorsSearchAction extends Action {
    keyword: string;
}

export function investorsSearchAction(keyword: string): IInvestorsSearchAction {
    return {
        keyword,
        type: InvestorsActionsEnum.InvestorsSearch,
    };
}

export interface IInvestorsSearchResultsSetAction extends Action {
    investors: IInvestor[];
}

export function investorsSearchResultsSetAction(investors: IInvestor[]): IInvestorsSearchResultsSetAction {
    return {
        investors,
        type: InvestorsActionsEnum.InvestorsSearchResultsSet,
    };
}

export function investorsSearchResultsClearAction(): Action {
    return {
        type: InvestorsActionsEnum.InvestorsSearchResultsClear,
    };
}

export interface IInvestorAccountApproveAction extends Action {
    accountUuid: string;
}

export function investorAccountApproveAction(accountUuid: string): IInvestorAccountApproveAction {
    return {
        accountUuid,
        type: InvestorsActionsEnum.InvestorAccountApprove,
    };
}

export interface IInvestorAccountDepositAction extends Action {
    accountUuid: string;
    amount: number;
}

export function investorAccountDepositAction(accountUuid: string, amount: number): IInvestorAccountDepositAction {
    return {
        accountUuid,
        amount,
        type: InvestorsActionsEnum.InvestorAccountDeposit,
    };
}

export interface IInvestorAccountIncomeTrustDivestAction extends Action {
    amount: number;
    investorAccountIncomeTrustUuid: string;
}

export function investorAccountIncomeTrustDivestAction(investorAccountIncomeTrustUuid: string, amount: number): IInvestorAccountIncomeTrustDivestAction {
    return {
        amount,
        investorAccountIncomeTrustUuid,
        type: InvestorsActionsEnum.InvestorAccountIncomeTrustDivest,
    };
}

export interface IInvestorAccountIncomeTrustInvestAction extends Action {
    amount: number;
    investorAccountIncomeTrustUuid: string;
    sendEmail:boolean;
}

export function investorAccountIncomeTrustInvestAction(investorAccountIncomeTrustUuid: string, amount: number, sendEmail:boolean): IInvestorAccountIncomeTrustInvestAction {
    return {
        amount,
        investorAccountIncomeTrustUuid,
        sendEmail,
        type: InvestorsActionsEnum.InvestorAccountIncomeTrustInvest,
    };
}

export interface IInvestorAccountInvestmentGetAction extends Action {
    investorAccountInvestmentUuid: string;
}

export function investorAccountInvestmentGetAction(investorAccountInvestmentUuid: string): IInvestorAccountInvestmentGetAction {
    return {
        investorAccountInvestmentUuid,
        type: InvestorsActionsEnum.InvestorAccountInvestmentGet,
    };
}

export interface IInvestorAccountInvestmentSetAction extends Action {
    investorAccountInvestment: IInvestorAccountInvestment;
}

export function investorAccountInvestmentSetAction(investorAccountInvestment: IInvestorAccountInvestment): IInvestorAccountInvestmentSetAction {
    return {
        investorAccountInvestment,
        type: InvestorsActionsEnum.InvestorAccountInvestmentSet,
    };
}

export interface IInvestorAccountInvestmentsListAction extends Action {
    accountUuid: string;
}

export function investorAccountInvestmentsListAction(accountUuid: string): IInvestorAccountInvestmentsListAction {
    return {
        accountUuid,
        type: InvestorsActionsEnum.InvestorAccountInvestmentsList,
    };
}

export interface IInvestorAccountInvestmentsSetAction extends Action {
    accountUuid: string;
    investorAccountInvestments: IInvestorAccountInvestment[];
}

export function investorAccountInvestmentsSetAction(accountUuid: string, investorAccountInvestments: IInvestorAccountInvestment[]): IInvestorAccountInvestmentsSetAction {
    return {
        accountUuid,
        investorAccountInvestments,
        type: InvestorsActionsEnum.InvestorAccountInvestmentsSet,
    };
}

export interface IInvestorAccountInvestmentTransactionsListAction extends Action {
    investorAccountInvestmentUuid: string;
}

export function investorAccountInvestmentTransactionsListAction(investorAccountInvestmentUuid: string): IInvestorAccountInvestmentTransactionsListAction {
    return {
        investorAccountInvestmentUuid,
        type: InvestorsActionsEnum.InvestorAccountInvestmentTransactionsList,
    };
}

export interface IInvestorAccountInvestmentTransactionsSetAction extends Action {
    investorAccountInvestmentUuid: string;
    transactions: IInvestorAccountInvestmentTransaction[];
}

export function investorAccountInvestmentTransactionsSetAction(investorAccountInvestmentUuid: string, transactions: IInvestorAccountInvestmentTransaction[]): IInvestorAccountInvestmentTransactionsSetAction {
    return {
        investorAccountInvestmentUuid,
        transactions,
        type: InvestorsActionsEnum.InvestorAccountInvestmentTransactionsSet,
    };
}

export interface IInvestorAccountRejectAction extends Action {
    accountUuid: string;
}

export function investorAccountRejectAction(accountUuid: string): IInvestorAccountRejectAction {
    return {
        accountUuid,
        type: InvestorsActionsEnum.InvestorAccountReject,
    };
}

export interface IInvestorAccountSetAction extends Action {
    account: IAccount;
}

export function investorAccountSetAction(account: IAccount): IInvestorAccountSetAction {
    return {
        account,
        type: InvestorsActionsEnum.InvestorAccountSet,
    };
}

export interface IInvestorAccountUnapproveAction extends Action {
    accountUuid: string;
}

export function investorAccountUnapproveAction(accountUuid: string): IInvestorAccountUnapproveAction {
    return {
        accountUuid,
        type: InvestorsActionsEnum.InvestorAccountUnapprove,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestorAccountsIncomeTrustListAction extends Action {}

export function investorAccountsIncomeTrustListAction(): IInvestorAccountsIncomeTrustListAction {
    return {
        type: InvestorsActionsEnum.InvestorAccountsIncomeTrustList,
    };
}

export interface IInvestorAccountsIncomeTrustSetAction extends Action {
    investorAccounts: IAccount[];
}

export function investorAccountsIncomeTrustSetAction(investorAccounts: IAccount[]): IInvestorAccountsIncomeTrustSetAction {
    return {
        investorAccounts,
        type: InvestorsActionsEnum.InvestorAccountsIncomeTrustSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestorAccountsPendingApprovalListAction extends Action {}

export function investorAccountsPendingApprovalListAction(): IInvestorAccountsPendingApprovalListAction {
    return {
        type: InvestorsActionsEnum.InvestorAccountsPendingApprovalList,
    };
}

export interface IInvestorAccountsPendingApprovalSetAction extends Action {
    investorAccounts: IAccount[];
}

export function investorAccountsPendingApprovalSetAction(investorAccounts: IAccount[]): IInvestorAccountsPendingApprovalSetAction {
    return {
        investorAccounts,
        type: InvestorsActionsEnum.InvestorAccountsPendingApprovalSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestorsManagedListAction extends Action {}

export function investorsManagedListAction(): IInvestorsManagedListAction {
    return {
        type: InvestorsActionsEnum.InvestorsManagedList,
    };
}

export interface IInvestorsManagedSetAction extends Action {
    investors: IInvestor[];
}

export function investorsManagedSetAction(investors: IInvestor[]): IInvestorsManagedSetAction {
    return {
        investors,
        type: InvestorsActionsEnum.InvestorsManagedSet,
    };
}

export interface IInvestorAccountPendingDepositApproveAction extends Action {
    investorAccountUuid: string;
    sendEmail: boolean;
}

export function investorAccountPendingDepositApproveAction(investorAccountUuid: string, sendEmail: boolean): IInvestorAccountPendingDepositApproveAction {
    return {
        investorAccountUuid,
        sendEmail,
        type: InvestorsActionsEnum.InvestorAccountPendingDepositApprove,
    };
}

export interface IInvestorAccountPendingDepositRejectAction extends Action {
    investorAccountUuid: string;
    reason: string;
}

export function investorAccountPendingDepositRejectAction(investorAccountUuid: string, reason: string): IInvestorAccountPendingDepositRejectAction {
    return {
        investorAccountUuid,
        reason,
        type: InvestorsActionsEnum.InvestorAccountPendingDepositReject,
    };
}

export interface IInvestorAccountIncomeTrustInterestPaymentStrategySetAction extends Action {
    interestPaymentStrategy: InterestPaymentStrategyEnum;
    investorAccountIncomeTrustUuid: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestorPendingWithdrawalsUnapprovedListAction extends Action {}

export function investorPendingWithdrawalsUnapprovedListAction(): IInvestorPendingWithdrawalsUnapprovedListAction {
    return {
        type: InvestorsActionsEnum.InvestorPendingWithdrawalsUnapprovedList,
    };
}

export interface IInvestorPendingWithdrawalSetAction extends Action {
    investorPendingWithdrawal: IPendingWithdrawal;
}

export function investorPendingWithdrawalSetAction(investorPendingWithdrawal: IPendingWithdrawal): IInvestorPendingWithdrawalSetAction {
    return {
        investorPendingWithdrawal,
        type: InvestorsActionsEnum.InvestorPendingWithdrawalSet,
    };
}

export interface IInvestorPendingWithdrawalsUnapprovedSetAction extends Action {
    investorPendingWithdrawalsUnapproved: IPendingWithdrawal[];
}

export function investorPendingWithdrawalsUnapprovedSetAction(investorPendingWithdrawalsUnapproved: IPendingWithdrawal[]): IInvestorPendingWithdrawalsUnapprovedSetAction {
    return {
        investorPendingWithdrawalsUnapproved,
        type: InvestorsActionsEnum.InvestorPendingWithdrawalsUnapprovedSet,
    };
}

export interface IInvestorPendingWithdrawalApproveAction extends Action {
    pendingWithdrawalUuid: string;
}

export function investorPendingWithdrawalApproveAction(pendingWithdrawalUuid: string): IInvestorPendingWithdrawalApproveAction {
    return {
        pendingWithdrawalUuid,
        type: InvestorsActionsEnum.InvestorPendingWithdrawalApprove,
    };
}

export function investorAccountIncomeTrustInterestPaymentStrategySetAction(investorAccountIncomeTrustUuid: string, interestPaymentStrategy: InterestPaymentStrategyEnum): IInvestorAccountIncomeTrustInterestPaymentStrategySetAction {
    return {
        interestPaymentStrategy,
        investorAccountIncomeTrustUuid,
        type: InvestorsActionsEnum.InvestorAccountIncomeTrustInterestPaymentStrategySet,
    };
}

export interface IInvestorAccountInvestAction extends Action {
    accountUuid: string;
    amount: number;
    investmentUuid: string;
}

export function investorAccountInvestAction(accountUuid: string, investmentUuid: string, amount: number): IInvestorAccountInvestAction {
    return {
        accountUuid,
        amount,
        investmentUuid,
        type: InvestorsActionsEnum.InvestorAccountInvest,
    };
}

export interface IInvestorAccountReserveAction extends Action {
    accountUuid: string;
    amount: number;
    investmentUuid: string;
}

export function investorAccountReserveAction(accountUuid: string, investmentUuid: string, amount: number): IInvestorAccountReserveAction {
    return {
        accountUuid,
        amount,
        investmentUuid,
        type: InvestorsActionsEnum.InvestorAccountReserve,
    };
}

export interface IInvestorAccountSharesListAction extends Action {
    accountUuid: string;
}

export function investorAccountSharesListAction(accountUuid: string): IInvestorAccountSharesListAction {
    return {
        accountUuid,
        type: InvestorsActionsEnum.InvestorAccountSharesList,
    };
}

export interface IInvestorAccountSharesSetAction extends Action {
    accountUuid: string;
    shares: IShare[];
}

export function investorAccountSharesSetAction(accountUuid: string, shares: IShare[]): IInvestorAccountSharesSetAction {
    return {
        accountUuid,
        shares,
        type: InvestorsActionsEnum.InvestorAccountSharesSet,
    };
}

export interface IInvestorAccountTransactionsListAction extends Action {
    accountUuid: string;
}

export function investorAccountTransactionsListAction(accountUuid: string): IInvestorAccountTransactionsListAction {
    return {
        accountUuid,
        type: InvestorsActionsEnum.InvestorAccountTransactionsList,
    };
}

export interface IInvestorAccountTransactionsSetAction extends Action {
    accountUuid: string;
    transactions: ITransaction[];
}

export function investorAccountTransactionsSetAction(accountUuid: string, transactions: ITransaction[]): IInvestorAccountTransactionsSetAction {
    return {
        accountUuid,
        transactions,
        type: InvestorsActionsEnum.InvestorAccountTransactionsSet,
    };
}

export interface IInvestorAccountWithdrawalAbaInvestorTransactionsListAction extends Action {
    investorAccountUuid: string;
}

export function investorAccountWithdrawalAbaInvestorTransactionsListAction(investorAccountUuid: string): IInvestorAccountWithdrawalAbaInvestorTransactionsListAction {
    return {
        investorAccountUuid,
        type: InvestorsActionsEnum.InvestorAccountWithdrawalAbaInvestorTransactionsList,
    };
}

export interface IInvestorAccountWithdrawalAbaInvestorTransactionsSetAction extends Action {
    investorAccountUuid: string;
    investorAccountWithdrawalAbaInvestorTransactions: IWithdrawalAbaInvestorTransaction[];
}

export function investorAccountWithdrawalAbaInvestorTransactionsSetAction(investorAccountUuid: string, investorAccountWithdrawalAbaInvestorTransactions: IWithdrawalAbaInvestorTransaction[]): IInvestorAccountWithdrawalAbaInvestorTransactionsSetAction {
    return {
        investorAccountUuid,
        investorAccountWithdrawalAbaInvestorTransactions,
        type: InvestorsActionsEnum.InvestorAccountWithdrawalAbaInvestorTransactionsSet,
    };
}

export interface IInvestorAccountWithdrawAction extends Action {
    accountUuid: string;
    amount: number;
}

export function investorAccountWithdrawAction(accountUuid: string, amount: number): IInvestorAccountWithdrawAction {
    return {
        accountUuid,
        amount,
        type: InvestorsActionsEnum.InvestorAccountWithdraw,
    };
}

export interface IInvestorAccountAnnualStatementsAddAction extends Action {
    file: File;
    investorAccountUuid: string;
    investorUuid: string;
    year: string;
}

export function investorAccountAnnualStatementsAddAction(investorAccountUuid: string, investorUuid: string, file: File, year: string): IInvestorAccountAnnualStatementsAddAction {
    return {
        file,
        investorAccountUuid,
        investorUuid,
        type: InvestorsActionsEnum.InvestorAccountAnnualStatementsAdd,
        year,
    };
}

export interface IInvestorAccountAnnualStatementsGenerateAction extends Action {
    financialYear: string;
    investorAccountUuid: string;
}

export function investorAccountAnnualStatementsGenerateAction(investorAccountUuid: string, financialYear: string): IInvestorAccountAnnualStatementsGenerateAction {
    return {
        financialYear,
        investorAccountUuid,
        type: InvestorsActionsEnum.InvestorAccountAnnualStatementsGenerate,
    };
}

export interface IInvestorAccountAnnualStatementDeleteAction extends Action {
    annualStatementUuid: string;
}

export function investorAnnualStatementDeleteAction(annualStatementUuid: string): IInvestorAccountAnnualStatementDeleteAction {
    return {
        annualStatementUuid,
        type: InvestorsActionsEnum.InvestorAccountAnnualStatementDelete,
    };
}

export interface IInvestorAccountAnnualStatementsListAction extends Action {
    investorAccountUuid: string;
}

export function investorAccountAnnualStatementsListAction(investorAccountUuid: string): IInvestorAccountAnnualStatementsListAction {
    return {
        investorAccountUuid,
        type: InvestorsActionsEnum.InvestorAccountAnnualStatementsList,
    };
}

export interface IInvestorAccountAnnualStatementSetAction extends Action {
    annualStatement: IAnnualStatement;
}

export function investorAccountAnnualStatementSetAction(annualStatement: IAnnualStatement): IInvestorAccountAnnualStatementSetAction {
    return {
        annualStatement,
        type: InvestorsActionsEnum.InvestorAccountAnnualStatementSet,
    };
}

export interface IInvestorAccountAnnualStatementsSetAction extends Action {
    annualStatements: IAnnualStatement[];
    investorAccountUuid: string;
}

export function investorAccountAnnualStatementsSetAction(investorAccountUuid: string, annualStatements: IAnnualStatement[]): IInvestorAccountAnnualStatementsSetAction {
    return {
        annualStatements,
        investorAccountUuid,
        type: InvestorsActionsEnum.InvestorAccountAnnualStatementsSet,
    };
}

export interface IInvestorAccountIncomeTrustSetAction extends Action {
    incomeTrust: IInvestorAccountIncomeTrust;
}

export function investorAccountIncomeTrustSetAction(incomeTrust: IInvestorAccountIncomeTrust): IInvestorAccountIncomeTrustSetAction {
    return {
        incomeTrust,
        type: InvestorsActionsEnum.InvestorAccountIncomeTrustSet,
    };
}

export interface IInvestorAccountIncomeTrustsListAction extends Action {
    investorAccountUuid: string;
}

export function investorAccountIncomeTrustsListAction(investorAccountUuid: string): IInvestorAccountIncomeTrustsListAction {
    return {
        investorAccountUuid,
        type: InvestorsActionsEnum.InvestorAccountIncomeTrustsList,
    };
}

export interface IInvestorAccountIncomeTrustsSetAction extends Action {
    investorAccountUuid: string;
    incomeTrusts: IInvestorAccountIncomeTrust[];
}

export function investorAccountIncomeTrustsSetAction(investorAccountUuid: string, incomeTrusts: IInvestorAccountIncomeTrust[]): IInvestorAccountIncomeTrustsSetAction {
    return {
        incomeTrusts,
        investorAccountUuid,
        type: InvestorsActionsEnum.InvestorAccountIncomeTrustsSet,
    };
}

export interface IInvestorAccountIncomeTrustTransactionsListAction extends Action {
    investorAccountIncomeTrustUuid: string;
}

export function investorAccountIncomeTrustTransactionsListAction(investorAccountIncomeTrustUuid: string): IInvestorAccountIncomeTrustTransactionsListAction {
    return {
        investorAccountIncomeTrustUuid,
        type: InvestorsActionsEnum.InvestorAccountIncomeTrustTransactionsList,
    };
}

export interface IInvestorAccountIncomeTrustTransactionsSetAction extends Action {
    investorAccountIncomeTrustUuid: string;
    transactions: IInvestorIncomeTrustTransaction[];
}

export function investorAccountIncomeTrustTransactionsSetAction(investorAccountIncomeTrustUuid: string, transactions: IInvestorIncomeTrustTransaction[]): IInvestorAccountIncomeTrustTransactionsSetAction {
    return {
        investorAccountIncomeTrustUuid,
        transactions,
        type: InvestorsActionsEnum.InvestorAccountIncomeTrustTransactionsSet,
    };
}

export interface IInvestorAccountMonthlyStatementsGenerateAction extends Action {
    investorAccountUuid: string;
    month: string;
    year: string;
}

export function investorAccountMonthlyStatementsGenerateAction(investorAccountUuid: string, month: string, year: string): IInvestorAccountMonthlyStatementsGenerateAction {
    return {
        investorAccountUuid,
        month,
        type: InvestorsActionsEnum.InvestorAccountMonthlyStatementsGenerate,
        year,
    };
}

export interface IInvestorAccountMonthlyStatementsListAction extends Action {
    investorAccountUuid: string;
}

export function investorAccountMonthlyStatementsListAction(investorAccountUuid: string): IInvestorAccountMonthlyStatementsListAction {
    return {
        investorAccountUuid,
        type: InvestorsActionsEnum.InvestorAccountMonthlyStatementsList,
    };
}

export interface IInvestorAccountMonthlyStatementsSetAction extends Action {
    monthlyStatements: IMonthlyStatement[];
    investorAccountUuid: string;
}

export function investorAccountMonthlyStatementsSetAction(investorAccountUuid: string, monthlyStatements: IMonthlyStatement[]): IInvestorAccountMonthlyStatementsSetAction {
    return {
        investorAccountUuid,
        monthlyStatements,
        type: InvestorsActionsEnum.InvestorAccountMonthlyStatementsSet,
    };
}

export interface IInvestorAccountMonthlyStatementDeleteAction extends Action {
    monthlyStatementUuid: string;
}

export function investorMonthlyStatementDeleteAction(monthlyStatementUuid: string): IInvestorAccountMonthlyStatementDeleteAction {
    return {
        monthlyStatementUuid,
        type: InvestorsActionsEnum.InvestorAccountMonthlyStatementDelete,
    };
}

export interface IInvestorAccountMonthlyStatementSendAction extends Action {
    email?: string;
    firstName?: string;
    lastName?: string;
    monthlyStatementUuid: string;
}

export function investorAccountMonthlyStatementSendAction(monthlyStatementUuid: string, email?: string, firstName?: string, lastName?: string): IInvestorAccountMonthlyStatementSendAction {
    return {
        email,
        firstName,
        lastName,
        monthlyStatementUuid,
        type: InvestorsActionsEnum.InvestorAccountMonthlyStatementSend,
    };
}

export interface IInvestorAccountMonthlyStatementSetAction extends Action {
    monthlyStatement: IMonthlyStatement;
}

export function investorAccountMonthlyStatementSetAction(monthlyStatement: IMonthlyStatement): IInvestorAccountMonthlyStatementSetAction {
    return {
        monthlyStatement,
        type: InvestorsActionsEnum.InvestorAccountMonthlyStatementSet,
    };
}

export interface IInvestorAssignAdviserAction extends Action {
    adviserUuid: string;
    investorUuid: string;
}

export function investorAssignAdviserAction(investorUuid: string, adviserUuid: string): IInvestorAssignAdviserAction {
    return {
        adviserUuid,
        investorUuid,
        type: InvestorsActionsEnum.InvestorAssignAdviser,
    };
}

export interface IInvestorAssignImAction extends Action {
    investorUuid: string;
    administratorUuid: string;
}

export function investorAssignImAction(investorUuid: string, administratorUuid: string): IInvestorAssignImAction {
    return {
        administratorUuid,
        investorUuid,
        type: InvestorsActionsEnum.InvestorAssignIm,
    };
}

export interface IInvestorBankAccountsAddAction extends Action {
    bankAccount: IBankAccount;
    investorUuid: string;
}

export function investorBankAccountsAddAction(investorUuid: string, bankAccount: IBankAccount): IInvestorBankAccountsAddAction {
    return {
        bankAccount,
        investorUuid,
        type: InvestorsActionsEnum.InvestorBankAccountsAdd,
    };
}

export interface IInvestorBankAccountConfirmAction extends Action {
    bankAccountUuid: string;
}

export function investorBankAccountConfirmAction(bankAccountUuid: string): IInvestorBankAccountConfirmAction {
    return {
        bankAccountUuid,
        type: InvestorsActionsEnum.InvestorBankAccountConfirm,
    };
}

export interface IInvestorBankAccountSetAction extends Action {
    bankAccount: IBankAccount;
}

export function investorBankAccountSetAction(investorBankAccount: IBankAccount): IInvestorBankAccountSetAction {
    return {
        bankAccount: investorBankAccount,
        type: InvestorsActionsEnum.InvestorBankAccountSet,
    };
}

export interface IInvestorBankAccountUnconfirmAction extends Action {
    bankAccountUuid: string;
}

export function investorBankAccountUnconfirmAction(bankAccountUuid: string): IInvestorBankAccountUnconfirmAction {
    return {
        bankAccountUuid,
        type: InvestorsActionsEnum.InvestorBankAccountUnconfirm,
    };
}

export interface IInvestorContactDateAction extends Action {
    date: string;
    investorUuid: string;
}

export function investorContactDateAction(investorUuid: string, date: string): IInvestorContactDateAction {
    return {
        date,
        investorUuid,
        type: InvestorsActionsEnum.InvestorContactDate,
    };
}

export interface IInvestorCompanyValueSetAction extends Action {
    key: keyof ICompany;
    uuid: string;
    value: boolean|number|string;
}

export function investorCompanyValueSetAction(uuid: string, key: keyof ICompany, value: boolean|number|string): IInvestorCompanyValueSetAction {
    return {
        key,
        type: InvestorsActionsEnum.InvestorCompanyValueSet,
        uuid,
        value,
    };
}

export interface IInvestorComplianceDocumentsAddAction extends Action {
    complianceDocument: IComplianceDocument;
    investorUuid: string;
    file: File;
}

export function investorComplianceDocumentsAddAction(investorUuid: string, file: File, complianceDocument: IComplianceDocument): IInvestorComplianceDocumentsAddAction {
    return {
        complianceDocument,
        file,
        investorUuid,
        type: InvestorsActionsEnum.InvestorComplianceDocumentsAdd,
    };
}

export interface IInvestorComplianceDocumentsListAction extends Action {
    investorUuid: string;
}

export function investorComplianceDocumentsListAction(investorUuid: string): IInvestorComplianceDocumentsListAction {
    return {
        investorUuid,
        type: InvestorsActionsEnum.InvestorComplianceDocumentsList,
    };
}

export interface IInvestorComplianceDocumentsSetAction extends Action {
    complianceDocuments: IComplianceDocument[];
    investorUuid: string;
}

export function investorComplianceDocumentsSetAction(investorUuid: string, complianceDocuments: IComplianceDocument[]): IInvestorComplianceDocumentsSetAction {
    return {
        complianceDocuments,
        investorUuid,
        type: InvestorsActionsEnum.InvestorComplianceDocumentsSet,
    };
}

export interface IInvestorComplianceDocumentDeleteAction extends Action {
    complianceDocumentUuid: string;
}

export function investorComplianceDocumentDeleteAction(complianceDocumentUuid: string): IInvestorComplianceDocumentDeleteAction {
    return {
        complianceDocumentUuid,
        type: InvestorsActionsEnum.InvestorComplianceDocumentDelete,
    };
}

export interface IInvestorComplianceDocumentSetAction extends Action {
    complianceDocument: IComplianceDocument;
    investorUuid: string;
}

export function investorComplianceDocumentSetAction(investorUuid: string, complianceDocument: IComplianceDocument): IInvestorComplianceDocumentSetAction {
    return {
        complianceDocument,
        investorUuid,
        type: InvestorsActionsEnum.InvestorComplianceDocumentSet,
    };
}

export interface IInvestorCreateIncomeTrustAccountAction extends Action {
    investorUuid: string;
}

export function investorCreateIncomeTrustAccountAction(investorUuid: string): IInvestorCreateIncomeTrustAccountAction {
    return {
        investorUuid,
        type: InvestorsActionsEnum.InvestorCreateIncomeTrustAccount,
    };
}

export interface IInvestorDocumentsAddAction extends Action {
    file: File;
    investorUuid: string;
}

export function investorDocumentsAddAction(investorUuid: string, file: File): IInvestorDocumentsAddAction {
    return {
        file,
        investorUuid,
        type: InvestorsActionsEnum.InvestorDocumentsAdd,
    };
}

export interface IInvestorDocumentsListAction extends Action {
    investorUuid: string;
}

export function investorDocumentsListAction(investorUuid: string): IInvestorDocumentsListAction {
    return {
        investorUuid,
        type: InvestorsActionsEnum.InvestorDocumentsList,
    };
}

export interface IInvestorDocumentsSetAction extends Action {
    documents: IDocument[];
    investorUuid: string;
}

export function investorDocumentsSetAction(investorUuid: string, documents: IDocument[]): IInvestorDocumentsSetAction {
    return {
        documents,
        investorUuid,
        type: InvestorsActionsEnum.InvestorDocumentsSet,
    };
}

export interface IInvestorDocumentSetAction extends Action {
    document: IDocument;
    investorUuid: string;
}

export function investorDocumentSetAction(investorUuid: string, document: IDocument): IInvestorDocumentSetAction {
    return {
        document,
        investorUuid,
        type: InvestorsActionsEnum.InvestorDocumentSet,
    };
}

export interface IInvestorHistoriesListAction extends Action {
    uuid: string;
}

export function investorHistoriesListAction(uuid: string): IInvestorHistoriesListAction {
    return {
        type: InvestorsActionsEnum.InvestorHistoriesList,
        uuid,
    };
}

export interface IInvestorHistoriesSetAction extends Action {
    histories: IHistory[];
    uuid: string;
}

export function investorHistoriesSetAction(uuid: string, histories: IHistory[]): IInvestorHistoriesSetAction {
    return {
        histories,
        type: InvestorsActionsEnum.InvestorHistoriesSet,
        uuid,
    };
}

export interface IInvestorGetAction extends Action {
    uuid: string;
}

export function investorGetAction(uuid: string): IInvestorGetAction {
    return {
        type: InvestorsActionsEnum.InvestorGet,
        uuid,
    };
}

export interface IInvestorIndividualAddAction extends Action {
    individual: IIndividual;
}

export function investorIndividualAddAction(individual: IIndividual): IInvestorIndividualAddAction {
    return {
        individual,
        type: InvestorsActionsEnum.InvestorIndividualAdd,
    };
}

export interface IInvestorIndividualSetAction extends Action {
    individual: IIndividual;
}

export function investorIndividualSetAction(individual: IIndividual): IInvestorIndividualSetAction {
    return {
        individual,
        type: InvestorsActionsEnum.InvestorIndividualSet,
    };
}

export interface IInvestorIndividualValueSetAction extends Action {
    index: number;
    investorUuid: string;
    key: keyof IIndividual;
    value: boolean|number|string;
}

export function investorIndividualValueSetAction(investorUuid: string, index: number, key: keyof IIndividual, value: boolean|number|string): IInvestorIndividualValueSetAction {
    return {
        index,
        investorUuid,
        key,
        type: InvestorsActionsEnum.InvestorIndividualValueSet,
        value,
    };
}

export interface IInvestorNotesAddAction extends Action {
    investorUuid: string;
    note: string;
}

export function investorNotesAddAction(investorUuid: string, note: string): IInvestorNotesAddAction {
    return {
        investorUuid,
        note,
        type: InvestorsActionsEnum.InvestorNotesAdd,
    };
}

export interface IInvestorNotesListAction extends Action {
    investorUuid: string;
}

export function investorNotesListAction(investorUuid: string): IInvestorNotesListAction {
    return {
        investorUuid,
        type: InvestorsActionsEnum.InvestorNotesList,
    };
}

export interface IInvestorNotesSetAction extends Action {
    investorUuid: string;
    notes: INote[];
}

export function investorNotesSetAction(investorUuid: string, notes: INote[]): IInvestorNotesSetAction {
    return {
        investorUuid,
        notes,
        type: InvestorsActionsEnum.InvestorNotesSet,
    };
}

export interface IInvestorNoteRemoveAction extends Action {
    investorUuid: string;
    noteId: string;
}

export function investorNoteRemoveAction(investorUuid: string, noteId: string): IInvestorNoteRemoveAction {
    return {
        investorUuid,
        noteId,
        type: InvestorsActionsEnum.InvestorNoteRemove,
    };
}

export interface IInvestorNoteSetAction extends Action {
    investorUuid: string;
    note: INote;
}

export function investorNoteSetAction(investorUuid: string, note: INote): IInvestorNoteSetAction {
    return {
        investorUuid,
        note,
        type: InvestorsActionsEnum.InvestorNoteSet,
    };
}

export interface IInvestorPrimaryBankAccountAction extends Action {
    bankAccountUuid: string;
    investorUuid: string;
}

export function investorPrimaryBankAccountAction(bankAccountUuid: string, investorUuid: string): IInvestorPrimaryBankAccountAction {
    return {
        bankAccountUuid,
        investorUuid,
        type: InvestorsActionsEnum.InvestorPrimaryBankAccount,
    };
}

export interface IInvestorSendAction extends Action {
    uuid: string;
}

export function investorSendAction(uuid: string): IInvestorSendAction {
    return {
        type: InvestorsActionsEnum.InvestorSend,
        uuid,
    };
}

export interface IInvestorSetAction extends Action {
    investor: IInvestor;
}

export function investorSetAction(investor: IInvestor): IInvestorSetAction {
    return {
        investor,
        type: InvestorsActionsEnum.InvestorSet,
    };
}

export interface IInvestorReferralsAddAction extends Action {
    referralUuid: string;
    uuid: string;
}

export function investorReferralsAddAction(uuid: string, referralUuid: string): IInvestorReferralsAddAction {
    return {
        referralUuid,
        type: InvestorsActionsEnum.InvestorReferralsAdd,
        uuid,
    };
}

export interface IInvestorReferralProcessAction extends Action {
    process: boolean;
    referralUuid: string;
    sendEmail: boolean;
}

export function investorReferralProcessAction(referralUuid: string, sendEmail: boolean, process: boolean): IInvestorReferralProcessAction {
    return {
        process,
        referralUuid,
        sendEmail,
        type: InvestorsActionsEnum.InvestorReferralProcess,
    };
}

export interface IInvestorReferralSetAction extends Action {
    referral: IReferral;
}

export function investorReferralSetAction(referral: IReferral): IInvestorReferralSetAction {
    return {
        referral,
        type: InvestorsActionsEnum.InvestorReferralSet,
    };
}

export interface IInvestorReferralUnprocessAction extends Action {
    referralUuid: string;
}

export function investorReferralUnprocessAction(referralUuid: string): IInvestorReferralUnprocessAction {
    return {
        referralUuid,
        type: InvestorsActionsEnum.InvestorReferralUnprocess,
    };
}

export interface IInvestorReferralsInvestorListAction extends Action {
    uuid: string;
}

export function investorReferralsInvestorListAction(uuid: string): IInvestorReferralsInvestorListAction {
    return {
        type: InvestorsActionsEnum.InvestorReferralsInvestorList,
        uuid,
    };
}

export interface IInvestorReferralsInvestorSetAction extends Action {
    referrals: IReferral[];
    uuid: string;
}

export function investorReferralsInvestorSetAction(uuid: string, referrals: IReferral[]): IInvestorReferralsInvestorSetAction {
    return {
        referrals,
        type: InvestorsActionsEnum.InvestorReferralsInvestorSet,
        uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestorReferralsListAction extends Action { }

export function investorReferralsListAction(): IInvestorReferralsListAction {
    return {
        type: InvestorsActionsEnum.InvestorReferralsList,
    };
}

export interface IInvestorReferralsSetAction extends Action {
    referrals: IReferral[];
}

export function investorReferralsSetAction(referrals: IReferral[]): IInvestorReferralsSetAction {
    return {
        referrals,
        type: InvestorsActionsEnum.InvestorReferralsSet,
    };
}

export interface IInvestorTrustValueSetAction extends Action {
    key: keyof ITrust;
    uuid: string;
    value: boolean|number|string;
}

export function investorTrustValueSetAction(uuid: string, key: keyof ITrust, value: boolean|number|string): IInvestorTrustValueSetAction {
    return {
        key,
        type: InvestorsActionsEnum.InvestorTrustValueSet,
        uuid,
        value,
    };
}

export interface IInvestorUnassignAdviserAction extends Action {
    investorUuid: string;
}

export function investorUnassignAdviserAction(investorUuid: string): IInvestorUnassignAdviserAction {
    return {
        investorUuid,
        type: InvestorsActionsEnum.InvestorUnassignAdviser,
    };
}

export interface IInvestorUnassignImAction extends Action {
    investorUuid: string;
}

export function investorUnassignImAction(investorUuid: string): IInvestorUnassignImAction {
    return {
        investorUuid,
        type: InvestorsActionsEnum.InvestorUnassignIm,
    };
}

export interface IInvestorUsersListAction extends Action {
    uuid: string;
}

export function investorUsersListAction(uuid: string): IInvestorUsersListAction {
    return {
        type: InvestorsActionsEnum.InvestorUsersList,
        uuid,
    };
}

export interface IInvestorUsersSetAction extends Action {
    investorUsers: IInvestorUser[];
    uuid: string;
}

export function investorUsersSetAction(uuid: string, investorUsers: IInvestorUser[]): IInvestorUsersSetAction {
    return {
        investorUsers,
        type: InvestorsActionsEnum.InvestorUsersSet,
        uuid,
    };
}

export interface IInvestorValueSetAction extends Action {
    key: keyof IInvestor;
    uuid: string;
    value: boolean|number|string;
}

export function investorValueSetAction(uuid: string, key: keyof IInvestor, value: boolean|number|string): IInvestorValueSetAction {
    return {
        key,
        type: InvestorsActionsEnum.InvestorValueSet,
        uuid,
        value,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPendingDepositsListAction extends Action {}

export function pendingDepositsListAction(): IPendingDepositsListAction {
    return {
        type: InvestorsActionsEnum.PendingDepositsList,
    };
}

export interface IPendingDepositsSetAction extends Action {
    investorAccounts: IAccount[];
}

export function pendingDepositsSetAction(investorAccounts: IAccount[]): IPendingDepositsSetAction {
    return {
        investorAccounts,
        type: InvestorsActionsEnum.PendingDepositsSet,
    };
}
