import { Modal, Spin, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import ILoanTransaction from '~Api/Loan/ILoanTransaction';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import IWarehouseLoanTransaction from '~Api/Warehouse/IWarehouseLoanTransaction';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { warehousesListAction } from '~Warehouses/actions';
import { warehousesSelector } from '~Warehouses/selectors';
import { loanTransactionWarehouseLoanTransactionsListAction } from './actions';
import {
    loanTransactionSelector,
    loanTransactionWarehouseLoanTransactionsSelector,
} from './selectors';

interface IProps {
    loanTransactionUuid: string;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsSelector {
    transaction: ILoanTransaction;
    warehouseLoanTransactions: IDictionary<IWarehouseLoanTransaction>;
    warehouses: IDictionary<IWarehouse>;
}

interface IPropsDispatch {
    warehouseLoanTransactionsList: () => void;
    warehousesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class LoanTransactionModal extends React.Component<Props> {
    public componentDidMount(): void {
        const { warehouseLoanTransactions, warehouses } = this.props;

        if (!warehouseLoanTransactions) {
            this.props.warehouseLoanTransactionsList();
        }

        if (!warehouses) {
            this.props.warehousesList();
        }
    }

    public render(): JSX.Element {
        const { isOpen, transaction, warehouseLoanTransactions, warehouses } = this.props;

        if (!transaction || !warehouseLoanTransactions || !warehouses) {
            return (
                <Modal
                    footer={false}
                    onCancel={this.props.onCancel}
                    open={isOpen}
                    title='Transaction Details'
                    width={1000}
                >
                    <Spin/>
                </Modal>
            );
        }

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnType<IWarehouseLoanTransaction>[] = [
            {
                dataIndex: 'warehouseUuid',
                render: (warehouseUuid: string, warehouseLoanTransaction: IWarehouseLoanTransaction) => warehouses[warehouseUuid] ? <Link to={`/warehouses/${warehouseUuid}/loans/${warehouseLoanTransaction.warehouseLoanUuid}`}>{warehouses[warehouseUuid].name}</Link> : '-',
                title: 'Warehouse',
            },
            {
                dataIndex: 'amountPrincipal',
                render: (amountPrincipal: number) => amountPrincipal > 0 ? currencyFormatter.format(amountPrincipal) : '-',
                title: 'Principal',
                width: '20%',
            },
            {
                dataIndex: 'amountInterest',
                render: (amountInterest: number) => amountInterest > 0 ? currencyFormatter.format(amountInterest) : '-',
                title: 'Interest',
                width: '20%',
            },
        ];

        return (
            <Modal
                footer={false}
                onCancel={this.props.onCancel}
                open={isOpen}
                title='Transaction Details'
                width={1000}
            >
                <Table
                    columns={columns}
                    dataSource={_.values(warehouseLoanTransactions)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                />
            </Modal>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        transaction: loanTransactionSelector(state, ownProps.loanTransactionUuid),
        warehouseLoanTransactions: loanTransactionWarehouseLoanTransactionsSelector(state, ownProps.loanTransactionUuid),
        warehouses: warehousesSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        warehouseLoanTransactionsList: () => dispatch(loanTransactionWarehouseLoanTransactionsListAction(ownProps.loanTransactionUuid)),
        warehousesList: () => dispatch(warehousesListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoanTransactionModal);
