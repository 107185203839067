import { CalendarOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IProperty from '~Api/Deal/IProperty';
import {
    applicationPropertySelector,
    applicationSelector,
} from '~Applications/selectors';
import { dealPropertyValueSetAction } from '~Deals/actions';
import { IGlobalState } from '~reducer';
import DatePicker from '~UI/DatePicker';
import { IDictionary } from '~utilities/IDictionary';
import IConditionComponentProps from './IConditionComponentProps';

interface IDefaultedValues {
    insuranceExpiryDate: string;
}

interface IState {
    isModalOpen: boolean;
    dirtyFields: IDictionary<boolean>;
    insuranceExpiryDate: string;
}

interface IPropsSelector {
    application: IApplication;
    applicationProperty: IApplicationProperty;
}

interface IPropsDispatch {
    dealPropertyInsuranceExpiryDateSet: (key: keyof IProperty, value: string, dealUuid: string, dealPropertyUuid: string) => void;
}

type Props = IConditionComponentProps & IPropsSelector & IPropsDispatch;

class InsuranceExpiryDate extends React.Component<Props, IState> {
    public state: IState = {
        dirtyFields: {},
        insuranceExpiryDate: null,
        isModalOpen: false,
    };

    constructor(props: Props) {
        super(props);
        this.getDefaultedValues = this.getDefaultedValues.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
        this.onClickClear = this.onClickClear.bind(this);
        this.onChangeInsuranceExpiryDate = this.onChangeInsuranceExpiryDate.bind(this);
        this.onClickCalendar = this.onClickCalendar.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
    }

    public render(): JSX.Element {
        const { application, applicationProperty } = this.props;
        const { isModalOpen } = this.state;
        const { insuranceExpiryDate } = this.getDefaultedValues();

        if (!application || !applicationProperty) {
            return null;
        }

        const insuranceExpiryDateDayjs: Dayjs = insuranceExpiryDate ? dayjs(insuranceExpiryDate) : dayjs();

        return (
            <React.Fragment>
                <a onClick={this.onClickCalendar}><CalendarOutlined/></a>
                <Modal
                    okText='Update'
                    onCancel={this.onClickCancel}
                    onOk={this.onClickOk}
                    open={isModalOpen}
                    title='Insurance Expiry Date'
                    wrapClassName='property-insurance-expiry-date-modal'
                >
                    <Form.Item className='insurance-expiry-date' label='Expiry Date'>
                        <Space>
                            <DatePicker onChange={this.onChangeInsuranceExpiryDate} value={insuranceExpiryDateDayjs} format='DD/MM/YYYY' />
                            <Button onClick={this.onClickClear}>Clear</Button>
                        </Space>
                    </Form.Item>
                </Modal>
            </React.Fragment>
        );
    }

    private getDefaultedValues(): IDefaultedValues {
        const { applicationProperty } = this.props;
        const { dirtyFields, insuranceExpiryDate } = this.state;

        return {
            insuranceExpiryDate: dirtyFields.insuranceExpiryDate ? insuranceExpiryDate : applicationProperty.dealProperty.insuranceExpiryDate,
        };
    }

    private onChangeInsuranceExpiryDate(date: Dayjs) {
        const { dirtyFields, insuranceExpiryDate } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                insuranceExpiryDate: true,
            },
            insuranceExpiryDate: dayjs(`${date.format('YYYY-MM-DD')} ${dayjs(insuranceExpiryDate || undefined).format('HH:mm')}`).format(),
        });
    }

    private onClickClear() {
        const { dirtyFields } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                insuranceExpiryDate: true,
            },
            insuranceExpiryDate: null,
        });
    }

    private onClickOk() {
        const { application, applicationProperty } = this.props;
        const { insuranceExpiryDate } = this.getDefaultedValues();

        this.props.dealPropertyInsuranceExpiryDateSet('insuranceExpiryDate', insuranceExpiryDate, application.dealUuid, applicationProperty.dealPropertyUuid);

        this.onClickCancel();
    }

    private onClickCalendar() {
        this.setState({
            isModalOpen: true,
        });
    }

    private onClickCancel() {
        this.setState({
            isModalOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IConditionComponentProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.applicationUuid),
        applicationProperty: applicationPropertySelector(state, ownProps.applicationPropertyUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        dealPropertyInsuranceExpiryDateSet: (key: keyof IProperty, value: string, dealUuid: string, dealPropertyUuid: string) => dispatch(dealPropertyValueSetAction(dealUuid, dealPropertyUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InsuranceExpiryDate);
