import { Form, Input, InputNumber, Modal, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import DisbursalTypeEnum from '~Api/Application/DisbursalTypeEnum';
import DisbursementTypeEnum from '~Api/Loan/DisbursementTypeEnum';
import ILoan from '~Api/Loan/ILoan';
import ILoanDisbursement from '~Api/Loan/ILoanDisbursement';
import { loanDisbursementsAddAction } from '~Loans/actions';
import {
    loanDisbursementsSelector,
    loanSelector,
} from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

interface IState {
    amount: number;
    description: string;
    errorType?: string;
    payeeName: string;
    type: DisbursementTypeEnum;
}

interface IProps {
    isOpen: boolean;
    loanUuid: string;
    onClose: () => void;
}

interface IPropsSelector {
    disbursements: IDictionary<ILoanDisbursement>;
    loan: ILoan;
}

interface IPropsDispatch {
    add: (disbursement: ILoanDisbursement) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class DisbursementAddModal extends React.Component<Props, IState> {
    public state: IState = {
        amount: null,
        description: null,
        payeeName: null,
        type: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangePayeeName = this.onChangePayeeName.bind(this);
        this.onChangeType = this.onChangeType.bind(this);

        this.onClickOk = this.onClickOk.bind(this);

        this.validateType = this.validateType.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen, loan } = this.props;
        const { amount, description, errorType, payeeName, type } = this.state;

        return (
            <Modal
                destroyOnClose={true}
                okText='Add'
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Disbursement'
                wrapClassName='loan-disbursement-add-modal'
            >
                <Form.Item label='Payee Name' className='payee-name'>
                    <Input maxLength={255} onChange={this.onChangePayeeName} value={payeeName} />
                </Form.Item>
                <Form.Item label='Amount' className='amount'>
                    <InputNumber addonBefore='$' min={0} onChange={this.onChangeAmount} value={amount} />
                </Form.Item>
                <Form.Item label='Description' className='description'>
                    <Input maxLength={255} onChange={this.onChangeDescription} value={description} />
                </Form.Item>
                <Form.Item className='type' label='Disbursement Type' help={errorType} validateStatus={errorType && 'error'}>
                    <Select onChange={this.onChangeType} value={type} onBlur={this.validateType}>
                        <Select.Option value={null}>N/A</Select.Option>
                        <Select.Option value={DisbursementTypeEnum.PrepaidInterest}>Prepaid Interest</Select.Option>
                        {loan.application.disbursalType === DisbursalTypeEnum.Drawdown && <Select.Option value={DisbursementTypeEnum.RetainedFunds}>Retained Funds</Select.Option>}
                        {loan.application.disbursalType === DisbursalTypeEnum.Drawdown && <Select.Option value={DisbursementTypeEnum.RetainedContingency}>Retained Contingency</Select.Option>}
                    </Select>
                </Form.Item>
            </Modal>
        );
    }

    private onChangeAmount(value: number) {
        this.setState({
            amount: value || null,
        });
    }

    private onChangeDescription(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            description: event.target.value,
        });
    }

    private onChangePayeeName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            payeeName: event.target.value,
        });
    }

    private onChangeType(value: DisbursementTypeEnum) {
        this.setState({
            type: value,
        });
    }

    private onClickOk() {
        const { amount, description, payeeName, type } = this.state;

        let valid: boolean = true;

        valid = this.validateType() && valid;

        if (!valid) {
            return;
        }

        this.props.add({
            amount,
            description,
            payeeName,
            type,
        });

        this.props.onClose();
    }

    private validateType(): boolean {
        const { disbursements } = this.props;
        const { type } = this.state;

        let error: string = null;

        if (type === DisbursementTypeEnum.PrepaidInterest) {
            const prepaidInterestDisbursement: ILoanDisbursement = _.find(disbursements, { type: DisbursementTypeEnum.PrepaidInterest });
            if (prepaidInterestDisbursement) {
                error = 'There is already an existing prepaid interest disbursement';
            }
        }

        if (type === DisbursementTypeEnum.RetainedFunds) {
            const retainedFundsDisbursement: ILoanDisbursement = _.find(disbursements, { type: DisbursementTypeEnum.RetainedFunds });
            if (retainedFundsDisbursement) {
                error = 'There is already an existing retained funds disbursement';
            }
        }

        if (type === DisbursementTypeEnum.RetainedContingency) {
            const retainedContingencyDisbursement: ILoanDisbursement = _.find(disbursements, { type: DisbursementTypeEnum.RetainedContingency });
            if (retainedContingencyDisbursement) {
                error = 'There is already an existing retained contingency disbursement';
            }
        }

        this.setState({
            errorType: error,
        });

        return !error;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        disbursements: loanDisbursementsSelector(state, ownProps.loanUuid),
        loan: loanSelector(state, ownProps.loanUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        add: (disbursement: ILoanDisbursement) => dispatch(loanDisbursementsAddAction(ownProps.loanUuid, disbursement)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DisbursementAddModal);
