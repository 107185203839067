import { FileSyncOutlined, QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Space, Spin, Table } from 'antd';
import _ from 'lodash';
import React from 'react';
import { FaRegFilePdf } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { currentAdministratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import IApplication from '~Api/Application/IApplication';
import IBorrower from '~Api/Application/IBorrower';
import WorkflowStatusEnum from '~Api/Application/WorkflowStatusEnum';
import ConditionTypeEnum from '~Api/Application/ConditionTypeEnum';
import ICondition from '~Api/Application/ICondition';
import IConditionDocument from '~Api/Application/IConditionDocument';
import BankruptcyCheck from '~Applications/Application/BankruptcyCheck';
import CompanyTradingHistory from '~Applications/Application/CompanyTradingHistory';
import CreditCheck from '~Applications/Application/CreditCheck';
import CreditMemo from '~Applications/Application/CreditMemo';
import InsuranceExpiryDate from '~Applications/Application/InsuranceExpiryDate';
import ValuationInspectionDate from '~Applications/Application/ValuationInspectionDate';
import { IGlobalState } from '~reducer';
import UploadConditionDocument from './UploadConditionDocument';
import UploadedConditionDocumentList from './UploadedConditionDocumentList';
import { IDictionary } from '~utilities/IDictionary';
import { ColumnType } from 'antd/lib/table';
import {
    applicationConditionDocumentsSelector,
    applicationConditionsSelector,
    applicationRfiItemsSelector,
} from '~Applications/selectors';
import {
    applicationConditionDocumentsListAction,
    applicationConditionsListAction,
    applicationRfiItemDeleteAction,
    applicationRfiItemRequestedAction,
    applicationRfiItemSuppliedAction,
    applicationRfiItemsAddAction,
    applicationRfiItemsListAction,
} from '~Applications/actions';
import {
    conditionLabels,
    filterApplicationConditionDocuments,
    filterBorrowerConditionDocuments,
    filterCustomConditionDocuments,
    filterPropertyConditionDocuments,
    getApplicationConditions,
    getBorrowerConditions,
    getPropertyConditions,
    getRequiredConditionTypes,
} from '~Applications/utilities';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import ConditionDocumentApprovalStatusEnum from '~Api/Application/ConditionDocumentApprovalStatusEnum';
import IRfiItem from '~Api/Application/IRfiItem';
import RfiWorkflowStatusEnum from '~Api/Application/RfiWorkflowStatusEnum';
import { aggregatorsListAction } from '~Aggregators/actions';
import { aggregatorsSelector } from '~Aggregators/selectors';
import IAggregator from '~Api/Aggregator/IAggregator';

const nextWorkflowStatuses: IDictionary<WorkflowStatusEnum> = {
    [WorkflowStatusEnum.Draft]: WorkflowStatusEnum.New,
    [WorkflowStatusEnum.New]: WorkflowStatusEnum.ConditionalApproval,
    [WorkflowStatusEnum.ConditionalApproval]: WorkflowStatusEnum.Underwriting,
    [WorkflowStatusEnum.Underwriting]: WorkflowStatusEnum.FormalApproval,
    [WorkflowStatusEnum.FormalApproval]: WorkflowStatusEnum.LegalDocuments,
    [WorkflowStatusEnum.LegalDocuments]: WorkflowStatusEnum.Settlement,
    [WorkflowStatusEnum.Settlement]: WorkflowStatusEnum.Warehoused,
};

const conditionUrls: IDictionary<string> = {
    [ConditionTypeEnum.AssetLiabilityStatement]: 'https://www.funding.com.au/assets-liabilities-statement/',
    [ConditionTypeEnum.BankSolicitorAccountantDetails]: 'https://www.funding.com.au/bank-solicitor-accountant-details/',
    [ConditionTypeEnum.BusinessPurposeDeclaration]: 'https://www.funding.com.au/business-purpose-declaration/',
    [ConditionTypeEnum.CompletedLoanApplication]: 'https://www.funding.com.au/loan-application/',
    [ConditionTypeEnum.ConsumerPurposeDeclaration]: 'https://www.funding.com.au/consumer-purpose-declaration/',
    [ConditionTypeEnum.LoanPurposeDeclaration]: 'https://www.funding.com.au/loan-purpose-declaration/',
    [ConditionTypeEnum.MonthlyBudget]: 'https://www.funding.com.au/monthly-budget/',
    [ConditionTypeEnum.PrivacyConsentForm]: 'https://www.funding.com.au/privacy-consent-form/',
    [ConditionTypeEnum.RecordOfInterview]: 'https://www.funding.com.au/record-of-interview/',
};

const conditionComponents: IDictionary<object> = {
    [ConditionTypeEnum.BankruptcyRegisterSearch]: BankruptcyCheck,
    [ConditionTypeEnum.BuildingReplacementInsurance]: InsuranceExpiryDate,
    [ConditionTypeEnum.CompanyInDepthTradingHistory]: CompanyTradingHistory,
    [ConditionTypeEnum.CreditFileSearchReport]: CreditCheck,
    [ConditionTypeEnum.Memo]: CreditMemo,
    [ConditionTypeEnum.Valuation]: ValuationInspectionDate,
};

const rfiWorkflowStatusClassNames: IDictionary<string> = {
    [RfiWorkflowStatusEnum.Requested]: 'requested',
    [RfiWorkflowStatusEnum.Supplied]: 'supplied',
};

interface IConditionTypeDocument {
    conditionUuid?: string;
    conditionType?: ConditionTypeEnum;
    typedConditionDocuments?: IConditionDocument[];
    uid: string;
}

interface IProps {
    application: IApplication;
    applicationBorrower?: IBorrower;
    applicationProperty?: IApplicationProperty;
}

interface IPropsSelector {
    aggregators: IDictionary<IAggregator>;
    conditions: IDictionary<ICondition>;
    currentAdministrator: IAdministrator;
    conditionDocuments: IDictionary<IConditionDocument>;
    rfiItems: IDictionary<IRfiItem>;
}

interface IPropsDispatch {
    aggregatorsList: () => void;
    applicationConditionDocumentsList: () => void;
    applicationConditionsList: () => void;
    rfiItemDelete: (rfiItemUuid: string) => void;
    rfiItemRequested: (rfiItemUuid: string) => void;
    rfiItemSupplied: (rfiItemUuid: string) => void;
    rfiItemsAdd: (applicationBorrowerUuid: string, applicationConditionUuid: string, applicationPropertyUuid: string, conditionType: ConditionTypeEnum) => void;
    rfiItemsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class ConditionDocumentsList extends React.Component<Props> {
    public componentDidMount(): void {
        const { aggregators, conditionDocuments, conditions, rfiItems } = this.props;

        if (!aggregators) {
            this.props.aggregatorsList();
        }

        if (!conditionDocuments) {
            this.props.applicationConditionDocumentsList();
        }

        if (!conditions) {
            this.props.applicationConditionsList();
        }

        if (!rfiItems) {
            this.props.rfiItemsList();
        }
    }

    public render(): JSX.Element {
        const {
            aggregators,
            application,
            applicationBorrower,
            applicationProperty,
            conditionDocuments,
            conditions,
            currentAdministrator,
            rfiItems,
        } = this.props;

        if (!aggregators || !conditionDocuments || !conditions || !currentAdministrator || !rfiItems) {
            return (
                <Spin/>
            );
        }

        let conditionTypes: ConditionTypeEnum[] = [];
        let conditionTypeDocuments: IConditionTypeDocument[] = [];

        if (applicationBorrower) {
            conditionTypes = getBorrowerConditions(application, applicationBorrower);

            conditionTypeDocuments = conditionTypes.map((conditionType: ConditionTypeEnum) => ({
                conditionType,
                typedConditionDocuments: filterBorrowerConditionDocuments(_.values(conditionDocuments), applicationBorrower, conditionType),
                uid: `borrower-condition-documents-${applicationBorrower.uuid}-${conditionType}`,
            }));

        } else if (applicationProperty) {
            conditionTypes = getPropertyConditions(application, applicationProperty);

            conditionTypeDocuments = conditionTypes.map((conditionType: ConditionTypeEnum) => ({
                conditionType,
                typedConditionDocuments: filterPropertyConditionDocuments(_.values(conditionDocuments), applicationProperty, conditionType),
                uid: `property-condition-documents-${applicationProperty.uuid}-${conditionType}`,
            }));

        } else {
            conditionTypes = getApplicationConditions(application);

            conditionTypeDocuments = conditionTypes.map((conditionType: ConditionTypeEnum) => ({
                conditionType,
                typedConditionDocuments: filterApplicationConditionDocuments(_.values(conditionDocuments), application, conditionType),
                uid: `application-condition-documents-${application.uuid}-${conditionType}`,
            }));

            if (conditions && _.keys(conditions).length > 0) {
                _.each(conditions, (condition: ICondition) => {
                    conditionTypeDocuments.push({
                        conditionUuid: condition.uuid,
                        typedConditionDocuments: filterCustomConditionDocuments(_.values(conditionDocuments), condition),
                        uid: `application-custom-condition-documents-${condition.uuid}`,
                    });
                });
            }
        }

        const nextWorkflowStatus: WorkflowStatusEnum = nextWorkflowStatuses[application.workflowStatus] || null;
        const currentRequiredConditionTypes: ConditionTypeEnum[] = nextWorkflowStatus ? getRequiredConditionTypes(application, nextWorkflowStatus, aggregators) : [];

        const columns: ColumnType<IConditionTypeDocument>[] = [
            {
                dataIndex: 'typedConditionDocuments',
                render: (typedConditionDocuments: IConditionDocument[], conditionTypeDocument : IConditionTypeDocument) => {
                    if (_.some(typedConditionDocuments, (typedConditionDocument: IConditionDocument) => [ConditionDocumentApprovalStatusEnum.Legacy, ConditionDocumentApprovalStatusEnum.Approved].includes(typedConditionDocument.approvalStatus))) {
                        return;
                    }

                    if (conditionTypeDocument.conditionUuid || currentRequiredConditionTypes.includes(conditionTypeDocument.conditionType)) {
                        return <WarningOutlined/>;
                    }

                    return <QuestionCircleOutlined/>;
                },
                title: '',
                width: '5%',
            },
            {
                render: (conditionTypeDocument : IConditionTypeDocument) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const ConditionComponent: any = conditionComponents[conditionTypeDocument.conditionType];
                    const conditionComponentBlock: JSX.Element = ConditionComponent && (
                        <ConditionComponent
                            applicationUuid={application.uuid}
                            applicationBorrowerUuid={applicationBorrower && applicationBorrower.uuid}
                            applicationPropertyUuid={applicationProperty && applicationProperty.uuid}
                        />
                    );

                    return (
                        <Space>
                            <span>{conditionTypeDocument.conditionUuid ? conditions[conditionTypeDocument.conditionUuid].name : conditionLabels[conditionTypeDocument.conditionType]}</span>
                            {conditionUrls[conditionTypeDocument.conditionType] && <a href={conditionUrls[conditionTypeDocument.conditionType]} target='_blank' rel='noreferrer'><FaRegFilePdf/></a>}
                            {conditionComponentBlock}
                        </Space>
                    );
                },
                title: 'Condition',
            },
            {
                render: (conditionTypeDocument : IConditionTypeDocument) => (
                    <UploadedConditionDocumentList
                        application={application}
                        applicationBorrower={applicationBorrower}
                        applicationProperty={applicationProperty}
                        conditionUuid={conditionTypeDocument.conditionUuid}
                        conditionType={conditionTypeDocument.conditionType}
                    />
                ),
                title: 'Documents',
                width: '30%',
            },
            {
                render: (conditionTypeDocument : IConditionTypeDocument) => {
                    let rfiItem: IRfiItem;

                    if (applicationBorrower) {
                        rfiItem = _.find(rfiItems, { applicationBorrowerUuid: applicationBorrower.uuid, conditionType: conditionTypeDocument.conditionType });
                    } else if (applicationProperty) {
                        rfiItem = _.find(rfiItems, { applicationPropertyUuid: applicationProperty.uuid, conditionType: conditionTypeDocument.conditionType });
                    } else if (conditionTypeDocument.conditionUuid) {
                        rfiItem = _.find(rfiItems, { applicationConditionUuid: conditionTypeDocument.conditionUuid });
                    } else {
                        // Application condition type
                        rfiItem = _.find(rfiItems, { applicationUuid: application.uuid, conditionType: conditionTypeDocument.conditionType });
                    }

                    const isOwnerOrSenior: boolean = application.administratorUuid === currentAdministrator.uuid || RoleEnum.SeniorCreditManager === currentAdministrator.role;

                    const rfiMenu: MenuProps = {
                        className: 'rfi-item-dropdown',
                        items: [
                            ...(!rfiItem ? [
                                {
                                    key: 'request',
                                    label: 'Request',
                                    onClick: () => this.props.rfiItemsAdd(
                                        applicationBorrower && applicationBorrower.uuid,
                                        conditionTypeDocument.conditionUuid,
                                        applicationProperty && applicationProperty.uuid,
                                        conditionTypeDocument.conditionType,
                                    ),
                                },
                            ] : []),
                            ...(rfiItem && rfiItem.workflowStatus !== RfiWorkflowStatusEnum.Requested ? [
                                {
                                    key: 'requested',
                                    label: 'Requested',
                                    onClick: () => this.props.rfiItemRequested(rfiItem.uuid),
                                },
                            ] : []),
                            ...(isOwnerOrSenior && rfiItem && rfiItem.workflowStatus !== RfiWorkflowStatusEnum.Supplied ? [
                                {
                                    key: 'supplied',
                                    label: 'Supplied',
                                    onClick: () => this.props.rfiItemSupplied(rfiItem.uuid),
                                },
                            ] : []),
                            ...(isOwnerOrSenior && rfiItem ? [
                                {
                                    danger: true,
                                    key: 'cancel-request',
                                    label: 'Cancel Request',
                                    onClick: () => this.props.rfiItemDelete(rfiItem.uuid),
                                },
                            ] : []),
                        ],
                    };

                    const rfiBlock: JSX.Element = (isOwnerOrSenior || RoleEnum.CreditManager === currentAdministrator.role) && (
                        <Dropdown menu={rfiMenu}>
                            <Button className={`rfi-item ${rfiItem && rfiWorkflowStatusClassNames[rfiItem.workflowStatus]}`} type='link'>
                                <FileSyncOutlined/>
                            </Button>
                        </Dropdown>
                    );

                    return (
                        <Space>
                            <UploadConditionDocument
                                application={application}
                                applicationBorrower={applicationBorrower}
                                applicationProperty={applicationProperty}
                                conditionUuid={conditionTypeDocument.conditionUuid}
                                conditionType={conditionTypeDocument.conditionType}
                            />
                            {rfiBlock}
                        </Space>
                    );
                },
                title: 'Actions',
                width: '10%',
            },
        ];

        return (
            <Table
                columns={columns}
                dataSource={conditionTypeDocuments}
                pagination={false}
                rowKey='uid'
            />
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        aggregators: aggregatorsSelector(state),
        conditionDocuments: applicationConditionDocumentsSelector(state, ownProps.application.uuid),
        conditions: applicationConditionsSelector(state, ownProps.application.uuid),
        currentAdministrator: currentAdministratorSelector(state),
        rfiItems: applicationRfiItemsSelector(state, ownProps.application.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        aggregatorsList: () => dispatch(aggregatorsListAction()),
        applicationConditionDocumentsList: () => dispatch(applicationConditionDocumentsListAction(ownProps.application.uuid)),
        applicationConditionsList: () => dispatch(applicationConditionsListAction(ownProps.application.uuid)),
        rfiItemDelete: (rfiItemUuid: string) => dispatch(applicationRfiItemDeleteAction(ownProps.application.uuid, rfiItemUuid)),
        rfiItemRequested: (rfiItemUuid: string) => dispatch(applicationRfiItemRequestedAction(rfiItemUuid)),
        rfiItemSupplied: (rfiItemUuid: string) => dispatch(applicationRfiItemSuppliedAction(rfiItemUuid)),
        rfiItemsAdd: (applicationBorrowerUuid: string, applicationConditionUuid: string, applicationPropertyUuid: string, conditionType: ConditionTypeEnum) => dispatch(applicationRfiItemsAddAction(ownProps.application.uuid, applicationBorrowerUuid, applicationConditionUuid, applicationPropertyUuid, conditionType)),
        rfiItemsList: () => dispatch(applicationRfiItemsListAction(ownProps.application.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConditionDocumentsList);
