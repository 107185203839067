import { all, call, put, takeEvery, takeLeading } from '@redux-saga/core/effects';
import { notification } from 'antd';
import _ from 'lodash';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import IWarehouseLoanTransaction from '~Api/Warehouse/IWarehouseLoanTransaction';
import IWarehouseParameter from '~Api/Warehouse/IWarehouseParameter';
import IWarehouseTransaction from '~Api/Warehouse/IWarehouseTransaction';
import {
    parseLoanBookForecastDay,
    parseWarehouse,
    parseWarehouseActiveReportLoan,
    parseWarehouseEligibleLoan,
    parseWarehouseLoan,
    parseWarehouseLoanTransaction,
    parseWarehouseParameter,
    parseWarehouseProposedSale,
    parseWarehouseTransaction,
    parseWarehousesPortfolioMetrics,
} from '~Api/Warehouse/parsers';
import {
    warehouseActiveReportRequest,
    warehouseAdjustInterestRateRequest,
    warehouseAdjustPrincipalRequest,
    warehouseEligibleLoansRequest,
    warehouseLoanGetRequest,
    warehouseLoanSellRequest,
    warehouseLoanTransactionsListRequest,
    warehouseLoansListRequest,
    warehouseParametersListRequest,
    warehousePendingApplicationsRequest,
    warehouseProposedPurchaseParametersListRequest,
    warehouseProposedSaleCancelRequest,
    warehouseProposedSaleProcessRequest,
    warehouseProposedSalesAddRequest,
    warehouseProposedSalesPendingListRequest,
    warehouseTransactionsListRequest,
    warehousesListRequest,
    warehousesLoanBookForecastRequest,
    warehousesPortfolioDashboardListRequest,
} from '~Api/Warehouse/requests';
import { IFetchResponse } from '~utilities/fetch';
import { renderNotificationLoadingIcon } from '~utilities/utils';
import {
    IWarehouseActiveReportListAction,
    IWarehouseAdjustInterestRateAction,
    IWarehouseAdjustPrincipalAction,
    IWarehouseEligibleLoansListAction,
    IWarehouseGetAction,
    IWarehouseLoanGetAction,
    IWarehouseLoanSellAction,
    IWarehouseLoanTransactionsListAction,
    IWarehouseLoansListAction,
    IWarehouseParametersListAction,
    IWarehousePendingApplicationsListAction,
    IWarehouseProposedPurchaseParametersListAction,
    IWarehouseProposedSaleCancelAction,
    IWarehouseProposedSaleProcessAction,
    IWarehouseProposedSalesAddAction,
    IWarehouseProposedSalesPendingListAction,
    IWarehouseTransactionsListAction,
    IWarehousesListAction,
    IWarehousesLoanBookForecastListAction,
    IWarehousesPortfolioDashboardListAction,
    warehouseActiveReportSetAction,
    warehouseEligibleLoansSetAction,
    warehouseLoanSetAction,
    warehouseLoanTransactionsSetAction,
    warehouseLoansSetAction,
    warehouseParametersSetAction,
    warehousePendingApplicationsSetAction,
    warehouseProposedPurchaseParametersSetAction,
    warehouseProposedSalesPendingSetAction,
    warehouseTransactionsListAction,
    warehouseTransactionsSetAction,
    warehousesListAction,
    warehousesLoanBookForecastSetAction,
    warehousesPortfolioDashboardSetAction,
    warehousesSetAction,
} from './actions';
import WarehousesActionsEnum from './ActionsEnum';
import IApplicationWarehouse from '~Api/Application/IApplicationWarehouse';
import { parseApplicationWarehouse } from '~Api/Application/parsers';
import IWarehouseActiveReportLoan from '~Api/Warehouse/IWarehouseActiveReportLoan';
import IWarehouseEligibleLoan from '~Api/Warehouse/IWarehouseEligibleLoan';
import IWarehouseProposedSale from '~Api/Warehouse/IWarehouseProposedSale';
import IWarehousesPortfolioMetrics from '~Api/Warehouse/IWarehousesPortfolioMetrics';
import ILoanBookForecastDay from '~Api/Warehouse/ILoanBookForecastDay';

function* warehouseActiveReportList(action: IWarehouseActiveReportListAction): Iterator<unknown> {
    const rawWarehouseActiveReportLoans: IFetchResponse = yield call(warehouseActiveReportRequest, action.warehouseUuid);
    const warehouseActiveReportLoans: IWarehouseActiveReportLoan[] = yield Promise.all(rawWarehouseActiveReportLoans.body.map(parseWarehouseActiveReportLoan));
    yield put(warehouseActiveReportSetAction(action.warehouseUuid, warehouseActiveReportLoans));
}

function* warehouseAdjustInterestRate(action: IWarehouseAdjustInterestRateAction): Iterator<unknown> {
    const key: string = `warehouseAdjustInterestRate ${action.warehouseUuid}`;
    const message: string = 'Adjust Interest Rate';

    notification.open({
        description: 'Adjusting warehouse interest rate...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const warehouseAdjustInterestRateResponse: IFetchResponse = yield call(warehouseAdjustInterestRateRequest, action.warehouseUuid, action.interestRate, action.description, action.transactionTime);
    if (warehouseAdjustInterestRateResponse.status === 422) {
        notification.error({
            description: `There was a problem adjusting the warehouse interest rate: ${_.values(warehouseAdjustInterestRateResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(warehouseTransactionsListAction(action.warehouseUuid));

        notification.success({
            description: 'The warehouse interest rate has been adjusted.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* warehouseAdjustPrincipal(action: IWarehouseAdjustPrincipalAction): Iterator<unknown> {
    const key: string = `warehouseAdjustPrincipal ${action.warehouseUuid}`;
    const message: string = 'Adjust Principal';

    notification.open({
        description: 'Adjusting warehouse principal...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const warehouseAdjustPrincipalResponse: IFetchResponse = yield call(
        warehouseAdjustPrincipalRequest,
        action.warehouseUuid,
        action.amount,
        action.capacityLimit,
        action.principalDraw,
        action.sellerNotes,
        action.description,
        action.transactionTime,
    );
    if (warehouseAdjustPrincipalResponse.status === 422) {
        notification.error({
            description: `There was a problem adjusting the warehouse principal: ${_.values(warehouseAdjustPrincipalResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(warehouseTransactionsListAction(action.warehouseUuid));

        notification.success({
            description: 'The warehouse principal has been adjusted.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* warehouseEligibleLoansList(action: IWarehouseEligibleLoansListAction): Iterator<unknown> {
    const warehouseEligibleLoansResponse: IFetchResponse = yield call(warehouseEligibleLoansRequest, action.warehouseUuid);
    const warehouseEligibleLoans: IWarehouseEligibleLoan[] = yield Promise.all(warehouseEligibleLoansResponse.body.map(parseWarehouseEligibleLoan));
    yield put(warehouseEligibleLoansSetAction(action.warehouseUuid, warehouseEligibleLoans));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* warehouseGet(action: IWarehouseGetAction): Iterator<unknown> {
    // @TODO: Replace this with an actual get
    yield put(warehousesListAction());
}

function* warehouseLoanGet(action: IWarehouseLoanGetAction): Iterator<unknown> {
    const warehouseLoanGetResponse: IFetchResponse = yield call(warehouseLoanGetRequest, action.warehouseLoanUuid);
    const warehouseLoan: IWarehouseLoan = yield call(parseWarehouseLoan, warehouseLoanGetResponse.body);
    yield put(warehouseLoanSetAction(warehouseLoan));
}

function* warehouseLoanTransactionsList(action: IWarehouseLoanTransactionsListAction): Iterator<unknown> {
    const warehouseLoanTransactionsListResponse: IFetchResponse = yield call(warehouseLoanTransactionsListRequest, action.warehouseLoanUuid);
    const warehouseLoanTransactions: IWarehouseLoanTransaction[] = yield Promise.all(warehouseLoanTransactionsListResponse.body.map(parseWarehouseLoanTransaction));
    yield put(warehouseLoanTransactionsSetAction(action.warehouseLoanUuid, warehouseLoanTransactions));
}

function* warehouseLoansList(action: IWarehouseLoansListAction): Iterator<unknown> {
    const warehouseLoansListResponse: IFetchResponse = yield call(warehouseLoansListRequest, action.warehouseUuid);
    const warehouseLoans: IWarehouseLoan[] = yield Promise.all(warehouseLoansListResponse.body.map(parseWarehouseLoan));
    yield put(warehouseLoansSetAction(action.warehouseUuid, warehouseLoans));
}

function* warehouseParametersList(action: IWarehouseParametersListAction): Iterator<unknown> {
    const warehouseParametersListResponse: IFetchResponse = yield call(warehouseParametersListRequest, action.warehouseUuid);
    const warehouseParameters: IWarehouseParameter[] = yield Promise.all(warehouseParametersListResponse.body.map(parseWarehouseParameter));
    yield put(warehouseParametersSetAction(action.warehouseUuid, warehouseParameters));
}

function* warehousePendingApplicationsList(action: IWarehousePendingApplicationsListAction): Iterator<unknown> {
    const warehousePendingApplicationsResponse: IFetchResponse = yield call(warehousePendingApplicationsRequest, action.warehouseUuid);
    const applicationWarehouses: IApplicationWarehouse[] = yield Promise.all(warehousePendingApplicationsResponse.body.map(parseApplicationWarehouse));
    yield put(warehousePendingApplicationsSetAction(action.warehouseUuid, applicationWarehouses));
}

function* warehouseProposedPurchaseParametersList(action: IWarehouseProposedPurchaseParametersListAction): Iterator<unknown> {
    const warehouseProposedPurchaseParametersListResponse: IFetchResponse = yield call(warehouseProposedPurchaseParametersListRequest, action.warehouseUuid);
    const warehouseParameters: IWarehouseParameter[] = yield Promise.all(warehouseProposedPurchaseParametersListResponse.body.map(parseWarehouseParameter));
    yield put(warehouseProposedPurchaseParametersSetAction(action.warehouseUuid, warehouseParameters));
}

function* warehouseLoanSell(action: IWarehouseLoanSellAction): Iterator<unknown> {
    const key: string = `warehouseLoanSell ${action.warehouseLoanUuid}`;
    const message: string = 'Sell Loan';

    notification.open({
        description: 'Selling loan...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const warehouseLoanSellResponse: IFetchResponse = yield call(
        warehouseLoanSellRequest,
        action.warehouseLoanUuid,
        _.map(
            action.destinationWarehouseAmounts,
            (amountPrincipal: number, warehouseUuid: string) => ({ amountPrincipal, warehouseUuid }),
        ),
        action.transactionTime,
    );
    if (warehouseLoanSellResponse.status === 422) {
        notification.error({
            description: `There was a problem selling the loan: ${_.values(warehouseLoanSellResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const warehouseLoan: IWarehouseLoan = parseWarehouseLoan(warehouseLoanSellResponse.body);
        yield put(warehouseLoanSetAction(warehouseLoan));

        notification.success({
            description: 'The loan has been sold.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* warehouseProposedSalesAdd(action: IWarehouseProposedSalesAddAction): Iterator<unknown> {
    const key: string = `warehouseProposedSalesAdd ${action.sourceWarehouseLoanUuid}`;
    const message: string = 'Propose Sale';

    notification.open({
        description: 'Proposing sale...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const warehouseProposedSalesAddResponse: IFetchResponse = yield call(warehouseProposedSalesAddRequest, {
        destinationWarehouseAmounts: _.map(
            action.destinationWarehouseAmounts,
            (amountPrincipal: number, warehouseUuid: string) => ({ amountPrincipal, warehouseUuid }),
        ),
        sourceWarehouseLoanUuid: action.sourceWarehouseLoanUuid,
        transactionTime: action.transactionTime,
    });

    if (warehouseProposedSalesAddResponse.status === 422) {
        notification.error({
            description: `There was a problem proposing the sale: ${_.values(warehouseProposedSalesAddResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        notification.success({
            description: 'The sale has been proposed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* warehouseProposedSalesPendingList(action: IWarehouseProposedSalesPendingListAction): Iterator<unknown> {
    const warehouseProposedSalesPendingListResponse: IFetchResponse = yield call(warehouseProposedSalesPendingListRequest);
    const warehouseProposedSalesPending: IWarehouseProposedSale[] = yield Promise.all(warehouseProposedSalesPendingListResponse.body.map(parseWarehouseProposedSale));
    yield put(warehouseProposedSalesPendingSetAction(warehouseProposedSalesPending));
}

function* warehouseProposedSaleCancel(action: IWarehouseProposedSaleCancelAction): Iterator<unknown> {
    const key: string = `warehouseProposedSaleCancel ${action.warehouseProposedSaleUuid}`;
    const message: string = 'Cancel Proposed Sale';

    notification.open({
        description: 'Cancelling proposed sale...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const warehouseProposedSaleCancelResponse: IFetchResponse = yield call(warehouseProposedSaleCancelRequest, action.warehouseProposedSaleUuid);
    if (warehouseProposedSaleCancelResponse.status === 422) {
        notification.error({
            description: `There was a problem canceling the proposed sale: ${_.values(warehouseProposedSaleCancelResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        notification.success({
            description: 'The proposed sale has been cancelled.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* warehouseProposedSaleProcess(action: IWarehouseProposedSaleProcessAction): Iterator<unknown> {
    const key: string = `warehouseProposedSaleProcess ${action.warehouseProposedSaleUuid}`;
    const message: string = 'Process Proposed Sale';

    notification.open({
        description: 'Processing proposed sale...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const warehouseProposedSaleProcessResponse: IFetchResponse = yield call(warehouseProposedSaleProcessRequest, action.warehouseProposedSaleUuid);
    if (warehouseProposedSaleProcessResponse.status === 422) {
        notification.error({
            description: `There was a problem processing the proposed sale: ${_.values(warehouseProposedSaleProcessResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        notification.success({
            description: 'The proposed sale has been processed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* warehouseTransactionsList(action: IWarehouseTransactionsListAction): Iterator<unknown> {
    const warehouseTransactionsListResponse: IFetchResponse = yield call(warehouseTransactionsListRequest, action.warehouseUuid);
    const warehouseTransactions: IWarehouseTransaction[] = yield Promise.all(warehouseTransactionsListResponse.body.map(parseWarehouseTransaction));
    yield put(warehouseTransactionsSetAction(action.warehouseUuid, warehouseTransactions));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* warehousesList(action: IWarehousesListAction): Iterator<unknown> {
    const warehousesListResponse: IFetchResponse = yield call(warehousesListRequest);
    const warehouses: IWarehouse[] = yield Promise.all(warehousesListResponse.body.map(parseWarehouse));
    yield put(warehousesSetAction(warehouses));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* warehousesLoanBookForecastList(action: IWarehousesLoanBookForecastListAction): Iterator<unknown> {
    const warehousesLoanBookForecastResponse: IFetchResponse = yield call(warehousesLoanBookForecastRequest);
    const forecastDays: ILoanBookForecastDay[] = yield Promise.all(warehousesLoanBookForecastResponse.body.map(parseLoanBookForecastDay));
    yield put(warehousesLoanBookForecastSetAction(forecastDays));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* warehousesPortfolioDashboardList(action: IWarehousesPortfolioDashboardListAction): Iterator<unknown> {
    const warehousesPortfolioDashboardResponse: IFetchResponse = yield call(warehousesPortfolioDashboardListRequest);
    const warehousesPortfolioMetrics: IWarehousesPortfolioMetrics[] = yield Promise.all(warehousesPortfolioDashboardResponse.body.map(parseWarehousesPortfolioMetrics));
    yield put(warehousesPortfolioDashboardSetAction(warehousesPortfolioMetrics));
}

export function* WarehousesSagas(): Iterator<unknown> {
    yield all([
        takeEvery(WarehousesActionsEnum.WarehouseActiveReportList, warehouseActiveReportList),

        takeLeading(WarehousesActionsEnum.WarehouseAdjustInterestRate, warehouseAdjustInterestRate),
        takeLeading(WarehousesActionsEnum.WarehouseAdjustPrincipal, warehouseAdjustPrincipal),

        takeEvery(WarehousesActionsEnum.WarehouseEligibleLoansList, warehouseEligibleLoansList),

        takeLeading(WarehousesActionsEnum.WarehouseGet, warehouseGet),

        takeLeading(WarehousesActionsEnum.WarehouseLoanGet, warehouseLoanGet),

        takeEvery(WarehousesActionsEnum.WarehouseLoanTransactionsList, warehouseLoanTransactionsList),

        takeEvery(WarehousesActionsEnum.WarehouseLoansList, warehouseLoansList),

        takeEvery(WarehousesActionsEnum.WarehousePendingApplicationsList, warehousePendingApplicationsList),

        takeEvery(WarehousesActionsEnum.WarehouseProposedPurchaseParametersList, warehouseProposedPurchaseParametersList),
        takeEvery(WarehousesActionsEnum.WarehouseParametersList, warehouseParametersList),

        takeEvery(WarehousesActionsEnum.WarehouseLoanSell, warehouseLoanSell),

        takeEvery(WarehousesActionsEnum.WarehouseProposedSalesAdd, warehouseProposedSalesAdd),
        takeEvery(WarehousesActionsEnum.WarehouseProposedSalesPendingList, warehouseProposedSalesPendingList),

        takeEvery(WarehousesActionsEnum.WarehouseProposedSaleCancel, warehouseProposedSaleCancel),
        takeEvery(WarehousesActionsEnum.WarehouseProposedSaleProcess, warehouseProposedSaleProcess),

        takeEvery(WarehousesActionsEnum.WarehouseTransactionsList, warehouseTransactionsList),

        takeLeading(WarehousesActionsEnum.WarehousesList, warehousesList),

        takeEvery(WarehousesActionsEnum.WarehousesLoanBookForecastList, warehousesLoanBookForecastList),
        takeEvery(WarehousesActionsEnum.WarehousesPortfolioDashboardList, warehousesPortfolioDashboardList),
    ]);
}
