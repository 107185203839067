import { Checkbox, Form, Input, Modal, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { aggregatorsListAction } from '~Aggregators/actions';
import IAggregator from '~Api/Aggregator/IAggregator';
import { aggregatorsSelector } from '~Aggregators/selectors';
import BrokerTypeEnum from '~Api/Broker/BrokerTypeEnum';
import IBroker from '~Api/Broker/IBroker';
import StateEnum from '~Api/Broker/StateEnum';
import { IGlobalState } from '~reducer';
import { brokersAddAction } from './actions';
import './brokers.less';
import { IDictionary } from '~utilities/IDictionary';

interface IState {
    aclNumber: string;
    aggregatorUuid: string;
    companyName: string;
    email: string;
    firstName: string;
    hasBeenBankrupted: boolean;
    hasBeenConvicted: boolean;
    hasIndemnityInsurance: boolean;
    lastName: string;
    linkedInUrl: string;
    phone: string;
    postalAddress: string;
    postcode: string;
    state: StateEnum;
    suburb: string;
    type: BrokerTypeEnum;
}

interface IProps {
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsSelector {
    aggregators: IDictionary<IAggregator>;
}

interface IPropsDispatch {
    add: (broker: IBroker) => void;
    aggregatorsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class PropertyModal extends React.Component<Props, IState> {
    public state: IState = {
        aclNumber: null,
        aggregatorUuid: null,
        companyName: null,
        email: null,
        firstName: null,
        hasBeenBankrupted: null,
        hasBeenConvicted: null,
        hasIndemnityInsurance: null,
        lastName: null,
        linkedInUrl: null,
        phone: null,
        postalAddress: null,
        postcode: null,
        state: null,
        suburb: null,
        type: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAclNumber = this.onChangeAclNumber.bind(this);
        this.onChangeAggregator = this.onChangeAggregator.bind(this);
        this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeHasBeenBankrupted = this.onChangeHasBeenBankrupted.bind(this);
        this.onChangeHasBeenConvicted = this.onChangeHasBeenConvicted.bind(this);
        this.onChangeHasIndemnityInsurance = this.onChangeHasIndemnityInsurance.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeLinkedInUrl = this.onChangeLinkedInUrl.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangePostalAddress = this.onChangePostalAddress.bind(this);
        this.onChangePostcode = this.onChangePostcode.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeSuburb = this.onChangeSuburb.bind(this);
        this.onChangeType = this.onChangeType.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public componentDidMount() {
        this.props.aggregatorsList();
    }

    public render(): JSX.Element {
        const { aggregators, isOpen } = this.props;
        const {
            aclNumber,
            aggregatorUuid,
            companyName,
            email,
            firstName,
            hasBeenBankrupted,
            hasBeenConvicted,
            hasIndemnityInsurance,
            lastName,
            linkedInUrl,
            phone,
            postalAddress,
            postcode,
            state,
            suburb,
            type,
        } = this.state;

        if (!aggregators) {
            return null;
        }

        return (
            <Modal
                destroyOnClose={true}
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Broker'
                wrapClassName='broker-add-modal'
            >
                <Form.Item label='First Name' className='first-name'>
                    <Input onChange={this.onChangeFirstName} value={firstName} />
                </Form.Item>
                <Form.Item label='Last Name' className='last-name'>
                    <Input onChange={this.onChangeLastName} value={lastName} />
                </Form.Item>
                <Form.Item label='Phone' className='phone'>
                    <Input onChange={this.onChangePhone} value={phone} />
                </Form.Item>
                <Form.Item label='Email' className='email'>
                    <Input onChange={this.onChangeEmail} value={email} />
                </Form.Item>
                <Form.Item label='Company Name' className='company-name'>
                    <Input onChange={this.onChangeCompanyName} value={companyName} />
                </Form.Item>
                <Form.Item label='Address' className='postal-address'>
                    <Input onChange={this.onChangePostalAddress} value={postalAddress} />
                </Form.Item>
                <Form.Item label='Suburb' className='suburb'>
                    <Input onChange={this.onChangeSuburb} value={suburb} />
                </Form.Item>
                <Form.Item className='state' label='State'>
                    <Select onChange={this.onChangeState} value={state}>
                        <Select.Option value={StateEnum.AustralianCapitalTerritory}>ACT</Select.Option>
                        <Select.Option value={StateEnum.NewSouthWales}>NSW</Select.Option>
                        <Select.Option value={StateEnum.NorthernTerritory}>NT</Select.Option>
                        <Select.Option value={StateEnum.Queensland}>QLD</Select.Option>
                        <Select.Option value={StateEnum.SouthAustralia}>SA</Select.Option>
                        <Select.Option value={StateEnum.Tasmania}>TAS</Select.Option>
                        <Select.Option value={StateEnum.Victoria}>VIC</Select.Option>
                        <Select.Option value={StateEnum.WesternAustralia}>WA</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item className='postcode' label='Postcode'>
                    <Input onChange={this.onChangePostcode} value={postcode} />
                </Form.Item>
                <Form.Item label='Type' className='type'>
                    <Select onChange={this.onChangeType} value={type}>
                        <Select.Option value={BrokerTypeEnum.AustralianCreditLicence}>Australian Credit Licence</Select.Option>
                        <Select.Option value={BrokerTypeEnum.AuthorisedCreditRepresentative}>Authorised Credit Representative</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label='ACL/CR Number' className='acl-number'>
                    <Input onChange={this.onChangeAclNumber} value={aclNumber} />
                </Form.Item>
                <Form.Item label='Aggregator' className='aggregator'>
                    <Select onChange={this.onChangeAggregator} value={aggregatorUuid}>
                        <Select.Option value={null}>None</Select.Option>
                        {_.sortBy(aggregators, ['name']).map((aggregator) => <Select.Option key={aggregator.uuid} value={aggregator.uuid}>{aggregator.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label='LinkedIn URL' className='linkedin-url'>
                    <Input onChange={this.onChangeLinkedInUrl} value={linkedInUrl} />
                </Form.Item>
                <Form.Item label='Has Indemnity Insurance' className='has-indemnity-insurance'>
                    <Checkbox checked={hasIndemnityInsurance} onChange={this.onChangeHasIndemnityInsurance} />
                </Form.Item>
                <Form.Item label='Has Been Convicted' className='has-been-convicted'>
                    <Checkbox checked={hasBeenConvicted} onChange={this.onChangeHasBeenConvicted} />
                </Form.Item>
                <Form.Item label='Has Been Bankrupted' className='has-been-bankrupted'>
                    <Checkbox checked={hasBeenBankrupted} onChange={this.onChangeHasBeenBankrupted} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeAclNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            aclNumber: event.target.value,
        });
    }

    private onChangeAggregator(value: string) {
        this.setState({
            aggregatorUuid: value,
        });
    }

    private onChangeCompanyName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            companyName: event.target.value,
        });
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            email: event.target.value,
        });
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            firstName: event.target.value,
        });
    }

    private onChangeHasBeenBankrupted(event: CheckboxChangeEvent) {
        this.setState({
            hasBeenBankrupted: event.target.checked,
        });
    }

    private onChangeHasBeenConvicted(event: CheckboxChangeEvent) {
        this.setState({
            hasBeenConvicted: event.target.checked,
        });
    }

    private onChangeHasIndemnityInsurance(event: CheckboxChangeEvent) {
        this.setState({
            hasIndemnityInsurance: event.target.checked,
        });
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            lastName: event.target.value,
        });
    }

    private onChangeLinkedInUrl(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            linkedInUrl: event.target.value,
        });
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            phone: event.target.value,
        });
    }

    private onChangePostalAddress(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            postalAddress: event.target.value,
        });
    }

    private onChangePostcode(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            postcode: event.target.value,
        });
    }

    private onChangeState(value: StateEnum) {
        this.setState({
            state: value,
        });
    }

    private onChangeSuburb(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            suburb: event.target.value,
        });
    }

    private onChangeType(value: BrokerTypeEnum) {
        this.setState({
            type: value,
        });
    }

    private onClickOk() {
        const {
            aclNumber,
            aggregatorUuid,
            companyName,
            email,
            firstName,
            hasBeenBankrupted,
            hasBeenConvicted,
            hasIndemnityInsurance,
            lastName,
            linkedInUrl,
            phone,
            postalAddress,
            postcode,
            state,
            suburb,
            type,
        } = this.state;

        this.props.onCancel();

        const broker: IBroker = {
            aclNumber,
            aggregatorUuid,
            companyName,
            email,
            firstName,
            hasBeenBankrupted,
            hasBeenConvicted,
            hasIndemnityInsurance,
            lastName,
            linkedInUrl,
            phone,
            postalAddress,
            postcode,
            state,
            suburb,
            type,
        };

        this.props.add(broker);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        aggregators: aggregatorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        add: (broker: IBroker) => dispatch(brokersAddAction(broker)),
        aggregatorsList: () => dispatch(aggregatorsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PropertyModal);
