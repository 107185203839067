import { FilterFilled, FilterTwoTone } from '@ant-design/icons';
import { Breadcrumb, Button, Form, Layout, Modal, Row, Space, Spin, Table, Tree, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import { administratorsSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import CloseReasonEnum from '~Api/Application/CloseReasonEnum';
import ExtensionTypeEnum from '~Api/Application/ExtensionTypeEnum';
import IApplication from '~Api/Application/IApplication';
import MortgageTypeEnum from '~Api/Application/MortgageTypeEnum';
import WorkflowStatusEnum from '~Api/Application/WorkflowStatusEnum';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import ITreeStructureGroup from '~utilities/ITreeStructureGroup';
import { applicationsSettlementForecastListAction } from './actions';
import { settlementForecastApplicationsSelector } from './selectors';
import CheckedKeys from '~utilities/CheckedKeys';

const unassignedKey: string = 'UNASSIGNED';

enum ChannelTreeGroupTypeEnum {
    Broker = 'BROKER',
    Direct = 'DIRECT',
}

interface IBarData {
    extensionsSettled: number;
    extensionsUnsettled: number;
    month: string;
    newLoansSettled: number;
    newLoansUnsettled: number;
    totalSettled: number;
    totalUnsettled: number;
}

interface IDay {
    applicationCount: number;
    date: string;
    loanTotal: number;
    primaryRowUuid?: string;
}

interface IForecast {
    application?: IApplication;
    date: string;
    uuid: string;
}

interface IState {
    bdmFilter: string[];
    channelFilter: string[];
    isChangeFiltersModalOpen: boolean;
    mortgageTypeFilter: string[];
}

interface IPropsSelector {
    administrators: IDictionary<IAdministrator>;
    applications: IDictionary<IApplication>;
}

interface IPropsDispatch {
    administratorsList: () => void;
    applicationsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class SettlementForecast extends React.Component<Props, IState> {
    private refreshInterval: NodeJS.Timeout;

    public state: IState = {
        bdmFilter: [],
        channelFilter: [],
        isChangeFiltersModalOpen: false,
        mortgageTypeFilter: [],
    };

    constructor(props: Props) {
        super(props);

        this.onChangeBdmFilter = this.onChangeBdmFilter.bind(this);
        this.onChangeChannelFilter = this.onChangeChannelFilter.bind(this);
        this.onChangeMortgageTypeFilter = this.onChangeMortgageTypeFilter.bind(this);

        this.onClickChangeFilters = this.onClickChangeFilters.bind(this);
        this.onCancelChangeFilters = this.onCancelChangeFilters.bind(this);
        this.onClickChangeFiltersOk = this.onClickChangeFiltersOk.bind(this);
    }

    public componentDidMount(): void {
        const { administrators } = this.props;

        this.props.applicationsList();

        if (!administrators) {
            this.props.administratorsList();
        }

        this.refreshInterval = setInterval(() => {
            this.props.applicationsList();
        }, 5 * 60 * 1000);
    }

    public componentWillUnmount(): void {
        clearInterval(this.refreshInterval);
    }

    public render(): JSX.Element {
        const { administrators, applications } = this.props;
        const { bdmFilter, channelFilter, isChangeFiltersModalOpen, mortgageTypeFilter } = this.state;

        if (!administrators || !applications) {
            return (
                <Layout className='applications forecast'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/applications'>Applications</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Settlement Forecast</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Spin />
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const days: { [date: string]: IDay } = {};
        const expiredSettlements: IApplication[] = [];
        const forecasts: IForecast[] = [];
        const months: { [date: string]: IBarData } = {};
        const visibleBdms: IDictionary<IAdministrator> = {};

        for (let i: number = 0; i < 45; i++) {
            const loopDate: dayjs.Dayjs = dayjs().add(i, 'days');
            days[loopDate.format('YYYY-MM-DD')] = {
                applicationCount: 0,
                date: loopDate.format('YYYY-MM-DD'),
                loanTotal: 0,
            };
            forecasts.push({
                date: loopDate.format('YYYY-MM-DD'),
                uuid: loopDate.format('YYYY-MM-DD'),
            });
        }

        for (let i: number = -5; i <= 2; i++) {
            const loopDate: dayjs.Dayjs = dayjs().add(i, 'months');
            months[loopDate.format('YYYY-MM')] = {
                extensionsSettled: 0,
                extensionsUnsettled: 0,
                month: loopDate.format('MMMM'),
                newLoansSettled: 0,
                newLoansUnsettled: 0,
                totalSettled: 0,
                totalUnsettled: 0,
            };
        }

        const checkDate: Dayjs = dayjs();
        _.each(applications, (application: IApplication) => {
            const { deal } = application;

            if (!application.settlementDate) {
                return;
            }

            const bdm: IAdministrator = administrators[deal.bdmUuid];

            if (deal.bdmUuid && !visibleBdms[bdm.uuid]) {
                visibleBdms[bdm.uuid] = bdm;
            }

            if (bdmFilter.length > 0) {
                let shouldShow: boolean = false;
                if (!deal.bdmUuid && bdmFilter.includes(unassignedKey)) {
                    shouldShow = true;
                } else if (deal.bdmUuid && bdmFilter.includes(bdm.uuid)) {
                    shouldShow = true;
                }
                if (!shouldShow) {
                    return;
                }
            }

            if (channelFilter.length > 0) {
                let shouldShow: boolean = false;
                if (channelFilter.includes(ChannelTreeGroupTypeEnum.Broker) && (deal.brokerUuid || deal.isBroker)) {
                    shouldShow = true;
                }
                if (channelFilter.includes(ChannelTreeGroupTypeEnum.Direct) && !deal.brokerUuid && !deal.isBroker) {
                    shouldShow = true;
                }
                if (!shouldShow) {
                    return;
                }
            }

            if (mortgageTypeFilter.length > 0) {
                let shouldShow: boolean = false;
                if (application.mortgageType && mortgageTypeFilter.includes(application.mortgageType)) {
                    shouldShow = true;
                }
                if (!shouldShow) {
                    return;
                }
            }

            const settlementDateDaysjs: Dayjs = dayjs(application.settlementDate);
            const settlementMonth: string = settlementDateDaysjs.format('YYYY-MM');
            const settlementDate: string = settlementDateDaysjs.format('YYYY-MM-DD');

            if (application.extensionType !== ExtensionTypeEnum.GracePeriod && (!application.closeReason || application.closeReason === CloseReasonEnum.Settled)) {
                // Settled or open and not a grace period type
                if (application.workflowStatus === WorkflowStatusEnum.Warehoused) {
                    // Settled
                    if (months[settlementMonth]) {
                        months[settlementMonth].totalSettled += application.loanAmount;

                        if (application.extensionNumber) {
                            months[settlementMonth].extensionsSettled += application.loanAmount;
                        } else {
                            months[settlementMonth].newLoansSettled += application.loanAmount;
                        }
                    }
                } else {
                    // Unsettled
                    if (settlementDateDaysjs.isBefore(checkDate, 'day')) {
                        // Expired (before today)
                        expiredSettlements.push(application);
                    }

                    if (months[settlementMonth]) {
                        // Today or future
                        months[settlementMonth].totalUnsettled += application.loanAmount;

                        if (application.extensionNumber) {
                            months[settlementMonth].extensionsUnsettled += application.loanAmount;
                        } else {
                            months[settlementMonth].newLoansUnsettled += application.loanAmount;
                        }

                        if (!days[settlementDate]) {
                            return;
                        }

                        days[settlementDate].applicationCount++;
                        days[settlementDate].loanTotal += application.loanAmount;

                        if (!days[settlementDate].primaryRowUuid) {
                            days[settlementDate].primaryRowUuid = application.uuid;
                        }

                        const initialForecast: IForecast = _.find(forecasts, (forecast: IForecast) => forecast.date === settlementDate);
                        if (!initialForecast.application) {
                            initialForecast.application = application;
                        } else {
                            forecasts.push({
                                application,
                                date: settlementDate,
                                uuid: application.uuid,
                            });
                        }
                    }
                }
            }
        });

        const bdmTreeData: ITreeStructureGroup[] = [
            {
                children: [],
                key: unassignedKey,
                title: 'Unassigned',
                value: unassignedKey,
            },
        ];

        _.forEach(_.sortBy(visibleBdms, ['name']), (loopAdmin: IAdministrator) => {
            if ([RoleEnum.BusinessDevelopmentManager, RoleEnum.InternalBusinessDevelopmentManager, RoleEnum.SeniorBusinessDevelopmentManager].includes(loopAdmin.role)) {
                bdmTreeData.push({
                    children: [],
                    key: loopAdmin.uuid,
                    title: loopAdmin.name,
                    value: loopAdmin.uuid,
                });
            }
        });

        const channelTreeData: ITreeStructureGroup[] = [
            {
                children: [],
                key: ChannelTreeGroupTypeEnum.Broker,
                title: 'Broker',
                value: ChannelTreeGroupTypeEnum.Broker,
            },
            {
                children: [],
                key: ChannelTreeGroupTypeEnum.Direct,
                title: 'Direct',
                value: ChannelTreeGroupTypeEnum.Direct,
            },
        ];

        const mortgageTypeTreeData: ITreeStructureGroup[] = [
            {
                children: [],
                key: MortgageTypeEnum.FirstMortgage,
                title: 'First Mortgage',
                value: MortgageTypeEnum.FirstMortgage,
            },
            {
                children: [],
                key: MortgageTypeEnum.SecondMortgage,
                title: 'Second Mortgage',
                value: MortgageTypeEnum.SecondMortgage,
            },
        ];

        const applicationStatusLabels: IDictionary<string> = {
            [WorkflowStatusEnum.ConditionalApproval]: 'Conditional Approval',
            [WorkflowStatusEnum.Draft]: 'Draft',
            [WorkflowStatusEnum.FormalApproval]: 'Formal Approval',
            [WorkflowStatusEnum.LegalDocuments]: 'Legal Documents',
            [WorkflowStatusEnum.New]: 'New',
            [WorkflowStatusEnum.Settlement]: 'Settlement',
            [WorkflowStatusEnum.Underwriting]: 'Underwriting',
            [WorkflowStatusEnum.Warehoused]: 'Warehoused',
        };

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnType<IForecast>[] = [
            {
                className: 'date',
                render: (forecast: IForecast) => {
                    if (!forecast.application) {
                        return dayjs(forecast.date).format('D/M');
                    }

                    const day: IDay = days[forecast.date];

                    return {
                        children: dayjs(day.date).format('D/M'),
                        props: {
                            rowSpan: day.primaryRowUuid === forecast.application.uuid ? day.applicationCount : 0,
                        },
                    };
                },
                title: 'Date',
                width: '10%',
            },
            {
                className: 'day',
                render: (forecast: IForecast) => {
                    if (!forecast.application) {
                        return dayjs(forecast.date).format('dddd');
                    }

                    const day: IDay = days[forecast.date];

                    return {
                        children: dayjs(day.date).format('dddd'),
                        props: {
                            rowSpan: day.primaryRowUuid === forecast.application.uuid ? day.applicationCount : 0,
                        },
                    };
                },
                title: 'Day',
                width: '10%',
            },
            {
                className: 'total',
                render: (forecast: IForecast) => {
                    if (!forecast.application) {
                        return '-';
                    }

                    const day: IDay = days[forecast.date];

                    return {
                        children: day.loanTotal > 0 ? currencyFormatter.format(day.loanTotal) : '-',
                        props: {
                            rowSpan: day.primaryRowUuid === forecast.application.uuid ? day.applicationCount : 0,
                        },
                    };
                },
                title: 'Total',
                width: '15%',
            },
            {
                dataIndex: 'application',
                render: (application: IApplication) => application ? (application.code) : null,
                title: 'Code',
                width: '10%',
            },
            {
                dataIndex: 'application',
                render: (application: IApplication) => {
                    return {
                        children: application ? (
                            <Row key={application.uuid}>
                                <Link to={`/applications/${application.uuid}`}>{application.formattedName}</Link>
                            </Row>
                        ) : null,
                    };
                },
                title: 'Name',
            },
            {
                dataIndex: 'application',
                render: (application: IApplication) => application && application.loanAmount > 0 ? currencyFormatter.format(application.loanAmount) : null,
                title: 'Amount',
                width: '15%',
            },
            {
                dataIndex: 'application',
                render: (application: IApplication) => application ? applicationStatusLabels[application.workflowStatus] : null,
                title: 'Status',
                width: '15%',
            },
        ];

        const expiredColumns: ColumnType<IApplication>[] = [
            {
                dataIndex: 'settlementDate',
                render: (settlementDate: string) => dayjs(settlementDate).format('D/M'),
                title: 'Settlement Date',
                width: '10%',
            },
            {
                dataIndex: 'code',
                title: 'Code',
                width: '10%',
            },
            {
                render: (application: IApplication) => <Link to={`/applications/${application.uuid}`}>{application.formattedName}</Link>,
                title: 'Name',
            },
            {
                dataIndex: 'loanAmount',
                render: (loanAmount: number) => loanAmount > 0 ? currencyFormatter.format(loanAmount) : '-',
                title: 'Amount',
                width: '15%',
            },
            {
                dataIndex: 'workflowStatus',
                render: (workflowStatus: WorkflowStatusEnum) => applicationStatusLabels[workflowStatus],
                title: 'Status',
                width: '15%',
            },
        ];

        const hasPastDays: boolean = _.size(expiredSettlements) > 0;
        const pastSettlementBlock: JSX.Element = hasPastDays && (
            <div className='expired-settlements'>
                <h3>Expired Settlements</h3>
                <Table
                    columns={expiredColumns}
                    dataSource={_.orderBy(expiredSettlements, ['settlementDate'], ['asc'])}
                    pagination={false}
                    rowClassName='forecast-expired-settlement-row'
                    rowKey='uuid'
                    size='middle'
                />
            </div>
        );

        const tooltipCurrencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            minimumFractionDigits: 0,
            style: 'currency',
        });

        const tooltipFormatter: (value: number) => string = (value: number) => tooltipCurrencyFormatter.format(value);

        return (
            <Layout className='applications forecast'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/applications'>Applications</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Settlement Forecast</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Button onClick={this.onClickChangeFilters} icon={(bdmFilter.length > 0 || channelFilter.length > 0 || mortgageTypeFilter.length > 0) ? <FilterTwoTone/> : <FilterFilled/>} />
                        </Space>
                        <Typography.Title level={2}>Settlement Forecast</Typography.Title>
                        <ResponsiveContainer width='97%' height={250}>
                            <BarChart data={_.values(months)}>
                                <XAxis dataKey='month' />
                                <YAxis tickFormatter={tooltipFormatter} width={100} />
                                <Tooltip formatter={tooltipFormatter} />
                                <Bar dataKey='extensionsSettled' fill='#f06305' name='Extensions Settled' stackId='extensions' />
                                <Bar dataKey='extensionsUnsettled' fill='#f29352' name='Extensions Unsettled' stackId='extensions' />
                                <Bar dataKey='newLoansSettled' fill='#006eb8' name='New Loans Settled' stackId='newLoans' />
                                <Bar dataKey='newLoansUnsettled' fill='#639bbf' name='New Loans Unsettled' stackId='newLoans' />
                                <Bar dataKey='totalSettled' fill='#00c48c' name='Total Settled' stackId='total' />
                                <Bar dataKey='totalUnsettled' fill='#7ddfc3' name='Total Unsettled' stackId='total' />
                            </BarChart>
                        </ResponsiveContainer>
                        {pastSettlementBlock}
                        <div className='forecast-current'>
                            {hasPastDays && <h3>Current Forecast</h3>}
                            <Table
                                columns={columns}
                                dataSource={_.sortBy(forecasts, ['date'], ['desc'])}
                                pagination={false}
                                rowKey='uuid'
                                size='middle'
                            />
                        </div>
                    </Layout.Content>
                </Layout>
                <Modal
                    onCancel={this.onCancelChangeFilters}
                    onOk={this.onClickChangeFiltersOk}
                    open={isChangeFiltersModalOpen}
                    title='Change Filters'
                    wrapClassName='settlement-forecast-change-filters-modal'
                >
                    <Form.Item label='Channel'>
                        <Tree
                            checkable={true}
                            treeData={channelTreeData}
                            checkedKeys={channelFilter}
                            onCheck={this.onChangeChannelFilter}
                            defaultExpandAll={true}
                            selectable={false}
                        />
                    </Form.Item>
                    <Form.Item label='BDMs'>
                        <Tree
                            checkable={true}
                            treeData={bdmTreeData}
                            checkedKeys={bdmFilter}
                            onCheck={this.onChangeBdmFilter}
                            defaultExpandAll={true}
                            selectable={false}
                        />
                    </Form.Item>
                    <Form.Item label='Mortgage Types'>
                        <Tree
                            checkable={true}
                            treeData={mortgageTypeTreeData}
                            checkedKeys={mortgageTypeFilter}
                            onCheck={this.onChangeMortgageTypeFilter}
                            defaultExpandAll={true}
                            selectable={false}
                        />
                    </Form.Item>
                </Modal>
            </Layout>
        );
    }

    private onChangeBdmFilter(checkedKeys: CheckedKeys): void {
        this.setState({
            bdmFilter: checkedKeys as string[],
        });
    }

    private onChangeChannelFilter(checkedKeys: CheckedKeys): void {
        this.setState({
            channelFilter: checkedKeys as string[],
        });
    }

    private onChangeMortgageTypeFilter(checkedKeys: CheckedKeys): void {
        this.setState({
            mortgageTypeFilter: checkedKeys as string[],
        });
    }

    private onClickChangeFiltersOk(): void {
        this.setState({
            isChangeFiltersModalOpen: false,
        });
    }

    private onClickChangeFilters(): void {
        this.setState({
            isChangeFiltersModalOpen: true,
        });
    }

    private onCancelChangeFilters(): void {
        this.setState({
            isChangeFiltersModalOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        administrators: administratorsSelector(state),
        applications: settlementForecastApplicationsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        applicationsList: () => dispatch(applicationsSettlementForecastListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettlementForecast);
