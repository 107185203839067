import { Checkbox, Form, Input, Select, Spin, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import AvailableFundsTypeEnum from '~Api/Investor/AvailableFundsTypeEnum';
import ICompany from '~Api/Investor/ICompany';
import IInvestor from '~Api/Investor/IInvestor';
import InvestorTypeEnum from '~Api/Investor/InvestorTypeEnum';
import ITrust from '~Api/Investor/ITrust';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import ICountry from '~Countries/ICountry';
import { countriesSelector } from '~Countries/selectors';
import {
    investorCompanyValueSetAction,
    investorGetAction,
    investorTrustValueSetAction,
    investorValueSetAction,
} from '~Investors/actions';
import { investorSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    countries: ICountry[];
    investor: IInvestor;
}

interface IPropsDispatch {
    investorCompanyValueSet: (key: keyof ICompany, value: any) => void;
    investorGet: () => void;
    investorTrustValueSet: (key: keyof ITrust, value: any) => void;
    investorValueSet: (key: keyof IInvestor, value: any) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Edit extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeAvailableFundsType = this.onChangeAvailableFundsType.bind(this);
        this.onChangeInvestorType = this.onChangeInvestorType.bind(this);
        this.onChangeIsManaged = this.onChangeIsManaged.bind(this);

        this.onChangeTrustName = this.onChangeTrustName.bind(this);
        this.onChangeTrustTaxFileNumber = this.onChangeTrustTaxFileNumber.bind(this);
        this.onChangeTrustAustralianBusinessNumber = this.onChangeTrustAustralianBusinessNumber.bind(this);

        this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
        this.onChangeCompanyTaxFileNumber = this.onChangeCompanyTaxFileNumber.bind(this);
        this.onChangeCompanyAustralianCompanyNumber = this.onChangeCompanyAustralianCompanyNumber.bind(this);
        this.onChangeCompanyStreetAddress = this.onChangeCompanyStreetAddress.bind(this);
        this.onChangeCompanySuburb = this.onChangeCompanySuburb.bind(this);
        this.onChangeCompanyPostcode = this.onChangeCompanyPostcode.bind(this);
        this.onChangeCompanyState = this.onChangeCompanyState.bind(this);
    }

    public componentDidMount(): void {
        const { investor } = this.props;

        if (!investor) {
            this.props.investorGet();
        }
    }

    public render(): JSX.Element {
        const { investor, match } = this.props;

        if (!investor) {
            return (
                <Layout uuid={match.params.uuid} section='edit'>
                    <Spin/>
                </Layout>
            );
        }

        const company: ICompany = this.getCompany();
        const trust: ITrust = this.getTrust();

        const trustBlock = [InvestorTypeEnum.TrustIndividual, InvestorTypeEnum.TrustCompany].includes(investor.type) && (
            <React.Fragment>
                <Typography.Title level={3}>Trust</Typography.Title>
                <Form.Item label='Name' className='trust-name'>
                    <Input onChange={this.onChangeTrustName} value={trust.name} />
                </Form.Item>
                <Form.Item label='TFN' className='trust-tax-file-number'>
                    <Input onChange={this.onChangeTrustTaxFileNumber} value={trust.taxFileNumber} />
                </Form.Item>
                <Form.Item label='ABN' className='trust-australian-business-number'>
                    <Input onChange={this.onChangeTrustAustralianBusinessNumber} value={trust.australianBusinessNumber} />
                </Form.Item>
            </React.Fragment>
        );

        const companyBlock = [InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany].includes(investor.type) && (
            <React.Fragment>
                <Typography.Title level={3}>Company</Typography.Title>
                <Form.Item label='Name' className='company-name'>
                    <Input onChange={this.onChangeCompanyName} value={company.name} />
                </Form.Item>
                <Form.Item label='TFN' className='company-tax-file-number'>
                    <Input onChange={this.onChangeCompanyTaxFileNumber} value={company.taxFileNumber} />
                </Form.Item>
                <Form.Item label='ACN' className='company-australian-company-number'>
                    <Input onChange={this.onChangeCompanyAustralianCompanyNumber} value={company.australianCompanyNumber} />
                </Form.Item>
                <Form.Item label='Street Address' className='company-street-address'>
                    <Input onChange={this.onChangeCompanyStreetAddress} value={company.streetAddress} />
                </Form.Item>
                <Form.Item label='Suburb' className='company-suburb'>
                    <Input onChange={this.onChangeCompanySuburb} value={company.suburb} />
                </Form.Item>
                <Form.Item label='Postcode' className='company-postcode'>
                    <Input onChange={this.onChangeCompanyPostcode} value={company.postcode} />
                </Form.Item>
                <Form.Item label='State' className='company-state'>
                    <Input onChange={this.onChangeCompanyState} value={company.state} />
                </Form.Item>
            </React.Fragment>
        );

        return (
            <Layout uuid={match.params.uuid} section='edit'>
                <Typography.Title level={2}>Edit</Typography.Title>
                <Form.Item label='Available Funds' className='available-funds-type'>
                    <Select onChange={this.onChangeAvailableFundsType} value={investor.availableFundsType}>
                        <Select.Option value={AvailableFundsTypeEnum.UnderTenThousand}>Under $10,000</Select.Option>
                        <Select.Option value={AvailableFundsTypeEnum.TenToOneHundredThousand}>Between $10,000 and $100,000</Select.Option>
                        <Select.Option value={AvailableFundsTypeEnum.OneHundredToTwoHundredFiftyThousand}>Between $100,000 and $250,000</Select.Option>
                        <Select.Option value={AvailableFundsTypeEnum.TwoHundredFiftyToFiveHundredThousand}>Between $250,000 and $500,000</Select.Option>
                        <Select.Option value={AvailableFundsTypeEnum.FiveHundredThousandToOneMillion}>Between $500,000 and $1m</Select.Option>
                        <Select.Option value={AvailableFundsTypeEnum.OverOneMillion}>$1m +</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label='Investor Type' className='investor-type'>
                    <Select onChange={this.onChangeInvestorType} value={investor.type}>
                        <Select.Option value={InvestorTypeEnum.Individual}>Individual</Select.Option>
                        <Select.Option value={InvestorTypeEnum.JointIndividuals}>Joint - individuals</Select.Option>
                        <Select.Option value={InvestorTypeEnum.Company}>Company</Select.Option>
                        <Select.Option value={InvestorTypeEnum.TrustIndividual}>Trust - individual trustee</Select.Option>
                        <Select.Option value={InvestorTypeEnum.TrustCompany}>Trust - company trustee</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label='Managed' className='is-managed'>
                    <Checkbox checked={investor.isManaged} onChange={this.onChangeIsManaged} />
                </Form.Item>
                {trustBlock}
                {companyBlock}
            </Layout>
        );
    }

    private getCompany(): ICompany {
        const { investor } = this.props;

        return investor.company || {
            australianCompanyNumber: null,
            name: null,
            postcode: null,
            state: null,
            streetAddress: null,
            suburb: null,
            taxFileNumber: null,
        };
    }

    private getTrust(): ITrust {
        const { investor } = this.props;

        return investor.trust || {
            australianBusinessNumber: null,
            name: null,
            taxFileNumber: null,
        };
    }


    private onChangeAvailableFundsType(value: AvailableFundsTypeEnum) {
        this.props.investorValueSet('availableFundsType', value);
    }

    private onChangeInvestorType(value: InvestorTypeEnum) {
        this.props.investorValueSet('type', value);
    }

    private onChangeIsManaged(event: CheckboxChangeEvent) {
        this.props.investorValueSet('isManaged', event.target.checked);
    }

    private onChangeTrustName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.investorTrustValueSet('name', event.target.value);
    }

    private onChangeTrustTaxFileNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.investorTrustValueSet('taxFileNumber', event.target.value);
    }

    private onChangeTrustAustralianBusinessNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.investorTrustValueSet('australianBusinessNumber', event.target.value);
    }

    private onChangeCompanyName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.investorCompanyValueSet('name', event.target.value);
    }

    private onChangeCompanyTaxFileNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.investorCompanyValueSet('taxFileNumber', event.target.value);
    }

    private onChangeCompanyAustralianCompanyNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.investorCompanyValueSet('australianCompanyNumber', event.target.value);
    }

    private onChangeCompanyStreetAddress(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.investorCompanyValueSet('streetAddress', event.target.value);
    }

    private onChangeCompanySuburb(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.investorCompanyValueSet('suburb', event.target.value);
    }

    private onChangeCompanyPostcode(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.investorCompanyValueSet('postcode', event.target.value);
    }

    private onChangeCompanyState(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.investorCompanyValueSet('state', event.target.value);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        countries: countriesSelector(state),
        investor: investorSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investorCompanyValueSet: (key: keyof ICompany, value: any) => dispatch(investorCompanyValueSetAction(ownProps.match.params.uuid, key, value)),
        investorGet: () => dispatch(investorGetAction(ownProps.match.params.uuid)),
        investorTrustValueSet: (key: keyof ITrust, value: any) => dispatch(investorTrustValueSetAction(ownProps.match.params.uuid, key, value)),
        investorValueSet: (key: keyof IInvestor, value: any) => dispatch(investorValueSetAction(ownProps.match.params.uuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Edit);
