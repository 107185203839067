import { all, call, debounce, put, select, takeEvery, takeLeading } from '@redux-saga/core/effects';
import { notification } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { currentAdministratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import IApplication from '~Api/Application/IApplication';
import IDeal from '~Api/Deal/IDeal';
import IDealBorrower from '~Api/Deal/IDealBorrower';
import IDocument from '~Api/Deal/IDocument';
import IHistory from '~Api/Deal/IHistory';
import INote from '~Api/Deal/INote';
import IPostcodeCategory from '~Api/Deal/IPostcodeCategory';
import IProperty from '~Api/Deal/IProperty';
import ISpPostcodeCategory from '~Api/Deal/ISpPostcodeCategory';
import {
    parseDeal,
    parseDealDocument,
    parseDealHistory,
    parseDealNote,
    parseDealPropertiesPostcodeCategory,
    parseDealPropertiesSpPostcodeCategory,
    parseDealProperty,
    parseDealQuote,
} from '~Api/Deal/parsers';
import {
    dealAssignProcessorRequest,
    dealAssignRequest,
    dealBorrowerUpdateRequest,
    dealCloseRequest,
    dealDocumentsAddRequest,
    dealDocumentsListRequest,
    dealEstablishmentFeeMinimumRequest,
    dealFollowUpRequest,
    dealFollowUpTimeRequest,
    dealHistoriesListRequest,
    dealInterestRateMinimumRequest,
    dealLegalFeesDollarsMinimumRequest,
    dealLvrMaximumRequest,
    dealNotesAddRequest,
    dealNotesListRequest,
    dealPropertiesAddRequest,
    dealPropertiesListRequest,
    dealPropertiesPostcodeCategoryListRequest,
    dealPropertiesSpPostcodeCategoryRequest,
    dealPropertyDeleteRequest,
    dealPropertyUpdateRequest,
    dealQuoteApproveRequest,
    dealQuoteConvertScenarioRequest,
    dealQuoteRejectRequest,
    dealReopenRequest,
    dealUnassignProcessorRequest,
    dealUncontactableFollowUpRequest,
} from '~Api/Deal/requests';
import { applicationGetAction } from '~Applications/actions';
import { dealApplicationsSelector } from '~Applications/selectors';
import { leadQuoteSetAction, leadSetAction } from '~Leads/actions';
import { IFetchResponse } from '~utilities/fetch';
import { renderNotificationLoadingIcon } from '~utilities/utils';
import {
    IDealAssignAction,
    IDealAssignProcessorAction,
    IDealBorrowerSendAction,
    IDealBorrowerValueSetAction,
    IDealCloseAction,
    IDealDocumentsAddAction,
    IDealDocumentsListAction,
    IDealEstablishmentFeeMinimumAction,
    IDealFollowUpAction,
    IDealFollowUpTimeAction,
    IDealHistoriesListAction,
    IDealInterestRateMinimumAction,
    IDealLegalFeesDollarsMinimumAction,
    IDealLvrMaximumAction,
    IDealNotesAddAction,
    IDealNotesListAction,
    IDealPropertiesAddAction,
    IDealPropertiesListAction,
    IDealPropertiesPostcodeCategoriesListAction,
    IDealPropertiesSpPostcodeCategoriesListAction,
    IDealPropertyDeleteAction,
    IDealPropertyGetAction,
    IDealPropertySendAction,
    IDealPropertyValueSetAction,
    IDealQuoteApproveAction,
    IDealQuoteConvertScenarioAction,
    IDealQuoteRejectAction,
    IDealReopenAction,
    IDealUnassignProcessorAction,
    IDealUncontactableFollowUpAction,
    dealBorrowerSendAction,
    dealDocumentSetAction,
    dealDocumentsSetAction,
    dealHistoriesSetAction,
    dealNoteRemoveAction,
    dealNoteSetAction,
    dealNotesSetAction,
    dealPropertiesListAction,
    dealPropertiesPostcodeCategoriesSetAction,
    dealPropertiesSetAction,
    dealPropertiesSpPostcodeCategoriesSetAction,
    dealPropertyRemoveAction,
    dealPropertySendAction,
    dealPropertySetAction,
} from './actions';
import DealsActionsEnum from './ActionsEnum';
import {
    dealBorrowerSelector,
    dealPropertySelector,
} from './selectors';
import IQuote from '~Api/Deal/IQuote';
import parameterDebounce from '~utilities/parameterDebounce';

function* dealAssign(action: IDealAssignAction): Iterator<unknown> {
    const key: string = `dealAssign ${action.dealUuid}`;
    const message: string = 'Assign Deal';

    notification.open({
        description: 'Assigning deal...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const dealAssignResponse: IFetchResponse = yield call(dealAssignRequest, action.dealUuid, action.administratorUuid);
    if (dealAssignResponse.status === 422) {
        notification.error({
            description: `There was a problem assigning the deal: ${_.values(dealAssignResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const deal: IDeal = parseDeal(dealAssignResponse.body);
        yield put(leadSetAction(deal));

        notification.success({
            description: 'The deal has been assigned.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* dealAssignProcessor(action: IDealAssignProcessorAction): Iterator<unknown> {
    const key: string = `dealAssignProcessor ${action.dealUuid}`;

    notification.open({
        description: 'Assigning loan processor...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Assign Loan Processor',
    });

    const rawLead: IFetchResponse = yield call(dealAssignProcessorRequest, action.dealUuid, action.administratorUuid);
    const deal: IDeal = parseDeal(rawLead.body);
    yield put(leadSetAction(deal));

    notification.success({
        description: 'The loan processor has been assigned.',
        duration: 4.5,
        key,
        message: 'Assign Loan Processor',
    });
}

function* dealBorrowerSend(action: IDealBorrowerSendAction): Iterator<unknown> {
    const borrower: IDealBorrower = yield select(dealBorrowerSelector, action.dealBorrowerUuid);
    if (borrower && borrower.uuid) {
        yield call(dealBorrowerUpdateRequest, borrower);
    }
}

function* dealBorrowerValueSet(action: IDealBorrowerValueSetAction): Iterator<unknown> {
    yield put(dealBorrowerSendAction(action.dealBorrowerUuid));
}

function* dealClose(action: IDealCloseAction): Iterator<unknown> {
    const key: string = `dealClose ${action.dealUuid}`;
    const message: string = 'Close Lead';

    notification.open({
        description: 'Closing deal...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const rawLead: IFetchResponse = yield call(dealCloseRequest, action.dealUuid, action.reason, action.note);

    if (rawLead.status === 422) {
        notification.error({
            description: `There was a problem closing the lead: ${_.values(rawLead.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const deal: IDeal = parseDeal(rawLead.body);
        yield put(leadSetAction(deal));

        notification.success({
            description: 'The deal has been closed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* dealDocumentsAdd(action: IDealDocumentsAddAction): Iterator<unknown> {
    const key: string = `dealDocumentsAdd ${dayjs().format()}`;

    notification.open({
        description: 'Adding document...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Document',
    });

    const rawDocument: IFetchResponse = yield call(dealDocumentsAddRequest, action.dealUuid, action.file);
    const document: IDocument = parseDealDocument(rawDocument.body);
    yield put(dealDocumentSetAction(action.dealUuid, document));

    notification.success({
        description: 'The document has been added.',
        duration: 4.5,
        key,
        message: 'Add Document',
    });
}

function* dealDocumentsList(action: IDealDocumentsListAction): Iterator<unknown> {
    const dealDocumentsListResponse: IFetchResponse = yield call(dealDocumentsListRequest, action.dealUuid);
    const documents: IDocument[] = yield Promise.all(dealDocumentsListResponse.body.map(parseDealDocument));
    yield put(dealDocumentsSetAction(action.dealUuid, documents));
}

function* dealEstablishmentFeeMinimum(action: IDealEstablishmentFeeMinimumAction): Iterator<unknown> {
    const key: string = `dealEstablishmentFeeMinimum ${dayjs().format()}`;
    const message: string = 'Change Establishment Fee Minimum';

    notification.open({
        description: 'Changing Establishment Fee minimum...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const establishmentFeeMinimumResponse: IFetchResponse = yield call(dealEstablishmentFeeMinimumRequest, action.dealUuid, action.establishmentFeeDollarsMinimum, action.establishmentFeePercentageMinimum);

    if (establishmentFeeMinimumResponse.status === 422) {
        notification.error({
            description: `There was a problem changing the Establishment Fee minimum: ${_.values(establishmentFeeMinimumResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(leadSetAction(parseDeal(establishmentFeeMinimumResponse.body)));

        notification.success({
            description: 'The Establishment Fee minimum has been changed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* dealHistoriesList(action: IDealHistoriesListAction): Iterator<unknown> {
    const rawHistories: IFetchResponse = yield call(dealHistoriesListRequest, action.dealUuid);
    const histories: IHistory[] = yield Promise.all(rawHistories.body.map(parseDealHistory));
    yield put(dealHistoriesSetAction(action.dealUuid, histories));
}

function* dealInterestRateMinimum(action: IDealInterestRateMinimumAction): Iterator<unknown> {
    const key: string = `dealInterestRateMinimum ${dayjs().format()}`;
    const message: string = 'Change Interest Rate Minimum';

    notification.open({
        description: 'Changing interest rate minimum...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const interestRateMinimumResponse: IFetchResponse = yield call(dealInterestRateMinimumRequest, action.dealUuid, action.interestRateMinimum);

    if (interestRateMinimumResponse.status === 422) {
        notification.error({
            description: `There was a problem changing the interest rate minimum: ${_.values(interestRateMinimumResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(leadSetAction(parseDeal(interestRateMinimumResponse.body)));

        notification.success({
            description: 'The interest rate minimum has been changed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* dealLegalFeesDollarsMinimum(action: IDealLegalFeesDollarsMinimumAction): Iterator<unknown> {
    const key: string = `dealLegalFeesDollarsMinimum ${dayjs().format()}`;
    const message: string = 'Change Legal Fees Minimum';

    notification.open({
        description: 'Changing Legal Fees minimum...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const legalFeesDollarsMinimumResponse: IFetchResponse = yield call(dealLegalFeesDollarsMinimumRequest, action.dealUuid, action.legalFeesDollarsMinimum);

    if (legalFeesDollarsMinimumResponse.status === 422) {
        notification.error({
            description: `There was a problem changing the Legal Fees minimum: ${_.values(legalFeesDollarsMinimumResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(leadSetAction(parseDeal(legalFeesDollarsMinimumResponse.body)));

        notification.success({
            description: 'The Legal Fees minimum has been changed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* dealLvrMaximum(action: IDealLvrMaximumAction): Iterator<unknown> {
    const key: string = `dealLvrMaximum ${dayjs().format()}`;
    const message: string = 'Change LVR Maximum';

    notification.open({
        description: 'Changing LVR maximum...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const lvrMaximumResponse: IFetchResponse = yield call(dealLvrMaximumRequest, action.dealUuid, action.lvrMaximum);

    if (lvrMaximumResponse.status === 422) {
        notification.error({
            description: `There was a problem changing the LVR maximum: ${_.values(lvrMaximumResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(leadSetAction(parseDeal(lvrMaximumResponse.body)));

        notification.success({
            description: 'The LVR maximum has been changed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* dealFollowUp(action: IDealFollowUpAction): Iterator<unknown> {
    const key: string = `dealFollowUp ${action.dealUuid}`;
    const message: string = 'Update Lead';

    notification.open({
        description: 'Updating lead...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const dealFollowUpResponse: IFetchResponse = yield call(dealFollowUpRequest, action.dealUuid, action.sendEmail);

    if (dealFollowUpResponse.status === 422) {
        notification.error({
            description: `There was a problem updating the lead: ${_.values(dealFollowUpResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const parsedDeal: IDeal = yield parseDeal(dealFollowUpResponse.body);
        yield put(leadSetAction(parsedDeal));
        notification.success({
            description: 'The lead has been updated.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* dealFollowUpTime(action: IDealFollowUpTimeAction): Iterator<unknown> {
    const key: string = `dealFollowUpTime ${dayjs().format()}`;
    const message: string = 'Change Follow Up';

    notification.open({
        description: 'Changing follow up...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const followUpTimeResponse: IFetchResponse = yield call(dealFollowUpTimeRequest, action.dealUuid, action.followUpTime, action.reason);

    if (followUpTimeResponse.status === 422) {
        notification.error({
            description: `There was a problem changing the follow up: ${_.values(followUpTimeResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(leadSetAction(parseDeal(followUpTimeResponse.body)));

        notification.success({
            description: 'The follow up has been changed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* dealNotesAdd(action: IDealNotesAddAction): Iterator<unknown> {
    const key: string = `dealNotesAdd ${dayjs().format()}`;

    notification.open({
        description: 'Adding note...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Note',
    });

    const currentAdministrator: IAdministrator = yield select(currentAdministratorSelector);

    const preNote: INote = {
        administratorUuid: currentAdministrator.uuid,
        createdTime: dayjs().format(),
        note: action.note,
    };

    yield put(dealNoteSetAction(action.dealUuid, preNote));

    const rawNote: IFetchResponse = yield call(dealNotesAddRequest, action.dealUuid, preNote);
    const note: INote = parseDealNote(rawNote.body);

    yield put(dealNoteRemoveAction(action.dealUuid, 'new'));
    yield put(dealNoteSetAction(action.dealUuid, note));

    notification.success({
        description: 'The note has been added.',
        duration: 4.5,
        key,
        message: 'Add Note',
    });
}

function* dealNotesList(action: IDealNotesListAction): Iterator<unknown> {
    const rawNotes: IFetchResponse = yield call(dealNotesListRequest, action.dealUuid);
    const notes: INote[] = yield Promise.all(rawNotes.body.map(parseDealNote));
    yield put(dealNotesSetAction(action.dealUuid, notes));
}

function* dealPropertiesAdd(action: IDealPropertiesAddAction): Iterator<unknown> {
    const key: string = `dealPropertiesAdd ${dayjs().format()}`;

    notification.open({
        description: 'Adding property...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Property',
    });

    const rawProperty: IFetchResponse = yield call(dealPropertiesAddRequest, action.dealUuid, action.property);
    const property: IProperty = parseDealProperty(rawProperty.body);
    yield put(dealPropertySetAction(action.dealUuid, property));

    // Reload the applications belonging to this deal as they will contain the updated properties.
    const applications: IApplication[] = yield select(dealApplicationsSelector, action.dealUuid);
    if (applications) {
        applications.forEach((application: IApplication) => put(applicationGetAction(application.uuid)));
    }

    notification.success({
        description: 'The property has been added.',
        duration: 4.5,
        key,
        message: 'Add Property',
    });
}

function* dealPropertiesList(action: IDealPropertiesListAction): Iterator<unknown> {
    const rawProperties: IFetchResponse = yield call(dealPropertiesListRequest, action.dealUuid);
    const properties: IProperty[] = yield Promise.all(rawProperties.body.map(parseDealProperty));
    yield put(dealPropertiesSetAction(action.dealUuid, properties));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* dealPropertiesPostcodeCategoriesList(action: IDealPropertiesPostcodeCategoriesListAction): Iterator<unknown> {
    const rawPostcodeCategories: IFetchResponse = yield call(dealPropertiesPostcodeCategoryListRequest);
    const postcodeCategories: IPostcodeCategory[] = rawPostcodeCategories.body.map(parseDealPropertiesPostcodeCategory);
    yield put(dealPropertiesPostcodeCategoriesSetAction(postcodeCategories));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* dealPropertiesSpPostcodeCategoriesList(action: IDealPropertiesSpPostcodeCategoriesListAction): Iterator<unknown> {
    const rawSpPostcodeCategories: IFetchResponse = yield call(dealPropertiesSpPostcodeCategoryRequest);
    const spPostcodeCategories: ISpPostcodeCategory[] = _.map((rawSpPostcodeCategories.body), parseDealPropertiesSpPostcodeCategory);
    yield put(dealPropertiesSpPostcodeCategoriesSetAction(spPostcodeCategories));
}

function* dealPropertyDelete(action: IDealPropertyDeleteAction): Iterator<unknown> {
    const key: string = `dealPropertyDelete ${action.propertyUuid}`;

    notification.open({
        description: 'Deleting property...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Delete Property',
    });

    yield call(dealPropertyDeleteRequest, action.propertyUuid);
    yield put(dealPropertyRemoveAction(action.dealUuid, action.propertyUuid));

    notification.success({
        description: 'The property has been deleted.',
        duration: 4.5,
        key,
        message: 'Delete Property',
    });
}

function* dealPropertyGet(action: IDealPropertyGetAction): Iterator<unknown> {
    // @TODO: Make this actually fetch a single property
    yield put(dealPropertiesListAction(action.dealUuid));
}

function* dealPropertySend(action: IDealPropertySendAction): Iterator<unknown> {
    const property: IProperty = yield select(dealPropertySelector, action.propertyUuid);
    if (property && property.uuid) {
        yield call(dealPropertyUpdateRequest, property);
    }
}

function* dealPropertyValueSet(action: IDealPropertyValueSetAction): Iterator<unknown> {
    yield put(dealPropertySendAction(action.dealUuid, action.propertyUuid));
}

function* dealReopen(action: IDealReopenAction): Iterator<unknown> {
    const key: string = `dealReopen ${action.dealUuid}`;
    const message: string = 'Reopen Lead';

    notification.open({
        description: 'Reopening lead...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const dealReopenResponse: IFetchResponse = yield call(dealReopenRequest, action.dealUuid);

    if (dealReopenResponse.status === 422) {
        notification.error({
            description: `There was a problem reopening the lead: ${_.values(dealReopenResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const parsedDeal: IDeal = yield parseDeal(dealReopenResponse.body);
        yield put(leadSetAction(parsedDeal));

        notification.success({
            description: 'The lead has successfully been reopened.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* dealUnassignProcessor(action: IDealUnassignProcessorAction): Iterator<unknown> {
    const key: string = `dealUnassignProcessor ${action.dealUuid}`;

    notification.open({
        description: 'Unassigning loan processor...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Unassign Loan Processor',
    });

    const rawLead: IFetchResponse = yield call(dealUnassignProcessorRequest, action.dealUuid, action.administratorUuid);
    const deal: IDeal = parseDeal(rawLead.body);
    yield put(leadSetAction(deal));

    notification.success({
        description: 'The loan processor has been unassigned.',
        duration: 4.5,
        key,
        message: 'Unassign Loan Processor',
    });
}

function* dealUncontactableFollowUp(action: IDealUncontactableFollowUpAction): Iterator<unknown> {
    const key: string = `dealUncontactableFollowUp ${action.dealUuid}`;
    const message: string = action.sendEmail ? 'Send Uncontactable Email' : 'Record Uncontactable Lead';

    notification.open({
        description: action.sendEmail ? 'Sending uncontactable email...' : 'Recording uncontactable lead...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const dealUncontactableFollowUpResponse: IFetchResponse = yield call(dealUncontactableFollowUpRequest, action.dealUuid, action.sendEmail);

    if (dealUncontactableFollowUpResponse.status === 422) {
        notification.error({
            description: `There was a problem ${action.sendEmail ? 'sending the uncontactable email' : 'recording the lead as uncontactable'}: ${_.values(dealUncontactableFollowUpResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const parsedDeal: IDeal = yield parseDeal(dealUncontactableFollowUpResponse.body);
        yield put(leadSetAction(parsedDeal));
        notification.success({
            description: action.sendEmail ? 'The uncontactable email has been sent.' : 'The lead has been recorded as uncontactable.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* dealQuoteApprove(action: IDealQuoteApproveAction): Iterator<unknown> {
    const key: string = `dealQuoteApprove ${action.quoteUuid}`;
    const message: string = 'Approve Quote';

    notification.open({
        description: 'Approving quote...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const dealQuoteApproveResponse: IFetchResponse = yield call(dealQuoteApproveRequest, action.quoteUuid);

    if (dealQuoteApproveResponse.status === 422) {
        notification.error({
            description: `There was a problem approving the quote: ${_.values(dealQuoteApproveResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const parsedDealQuote: IQuote = yield parseDealQuote(dealQuoteApproveResponse.body);
        yield put(leadQuoteSetAction(parsedDealQuote.dealUuid, parsedDealQuote));
        notification.success({
            description: 'The quote has been approved',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* dealQuoteConvertScenario(action: IDealQuoteConvertScenarioAction): Iterator<unknown> {
    const key: string = `dealQuoteConvertScenario ${action.quoteUuid}`;
    const message: string = 'Convert Scenario Quote';

    notification.open({
        description: 'Converting scenario...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const dealQuoteConvertScenarioResponse: IFetchResponse = yield call(dealQuoteConvertScenarioRequest, action.quoteUuid, action.sendEmail);

    if (dealQuoteConvertScenarioResponse.status === 422) {
        notification.error({
            description: `There was a problem converting the scenario: ${_.values(dealQuoteConvertScenarioResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const parsedDealQuote: IQuote = yield parseDealQuote(dealQuoteConvertScenarioResponse.body);
        yield put(leadQuoteSetAction(parsedDealQuote.dealUuid, parsedDealQuote));
        notification.success({
            description: 'The scenario has been converted',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* dealQuoteReject(action: IDealQuoteRejectAction): Iterator<unknown> {
    const key: string = `dealQuoteReject ${action.quoteUuid}`;
    const message: string = 'Reject Quote';

    notification.open({
        description: 'Rejecting quote...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const dealQuoteRejectResponse: IFetchResponse = yield call(dealQuoteRejectRequest, action.quoteUuid, action.reason);

    if (dealQuoteRejectResponse.status === 422) {
        notification.error({
            description: `There was a problem rejecting the quote: ${_.values(dealQuoteRejectResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const parsedDealQuote: IQuote = yield parseDealQuote(dealQuoteRejectResponse.body);
        yield put(leadQuoteSetAction(parsedDealQuote.dealUuid, parsedDealQuote));
        notification.success({
            description: 'The quote has been rejected',
            duration: 4.5,
            key,
            message,
        });
    }
}

export function* DealsSagas(): Iterator<unknown> {
    yield all([
        takeEvery(DealsActionsEnum.DealAssign, dealAssign),
        takeEvery(DealsActionsEnum.DealAssignProcessor, dealAssignProcessor),
        takeEvery(DealsActionsEnum.DealClose, dealClose),
        takeEvery(DealsActionsEnum.DealEstablishmentFeeMinimum, dealEstablishmentFeeMinimum),
        takeEvery(DealsActionsEnum.DealFollowUp, dealFollowUp),
        debounce(20, DealsActionsEnum.DealFollowUpTime, dealFollowUpTime),
        takeEvery(DealsActionsEnum.DealInterestRateMinimum, dealInterestRateMinimum),
        takeEvery(DealsActionsEnum.DealLegalFeesDollarsMinimum, dealLegalFeesDollarsMinimum),
        takeEvery(DealsActionsEnum.DealLvrMaximum, dealLvrMaximum),
        debounce(500, DealsActionsEnum.DealReopen, dealReopen),

        debounce(500, DealsActionsEnum.DealBorrowerSend, dealBorrowerSend),
        takeEvery(DealsActionsEnum.DealBorrowerValueSet, dealBorrowerValueSet),

        takeEvery(DealsActionsEnum.DealDocumentsAdd, dealDocumentsAdd),
        takeEvery(DealsActionsEnum.DealDocumentsList, parameterDebounce(dealDocumentsList)),

        takeEvery(DealsActionsEnum.DealHistoriesList, dealHistoriesList),

        takeEvery(DealsActionsEnum.DealNotesAdd, dealNotesAdd),
        takeEvery(DealsActionsEnum.DealNotesList, dealNotesList),

        takeEvery(DealsActionsEnum.DealPropertiesAdd, dealPropertiesAdd),
        takeEvery(DealsActionsEnum.DealPropertiesList, dealPropertiesList),
        takeLeading(DealsActionsEnum.DealPropertiesPostcodeCategoriesList, dealPropertiesPostcodeCategoriesList),
        takeLeading(DealsActionsEnum.DealPropertiesSpPostcodeCategoriesList, dealPropertiesSpPostcodeCategoriesList),

        takeEvery(DealsActionsEnum.DealPropertyDelete, dealPropertyDelete),
        takeEvery(DealsActionsEnum.DealPropertyGet, dealPropertyGet),
        debounce(500, DealsActionsEnum.DealPropertySend, dealPropertySend),
        takeEvery(DealsActionsEnum.DealPropertyValueSet, dealPropertyValueSet),

        takeEvery(DealsActionsEnum.DealUnassignProcessor, dealUnassignProcessor),
        takeEvery(DealsActionsEnum.DealUncontactableFollowUp, dealUncontactableFollowUp),

        takeEvery(DealsActionsEnum.DealQuoteApprove, dealQuoteApprove),
        takeEvery(DealsActionsEnum.DealQuoteConvertScenario, dealQuoteConvertScenario),
        takeEvery(DealsActionsEnum.DealQuoteReject, dealQuoteReject),
    ]);
}
