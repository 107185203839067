import { Checkbox, Form, Input, Modal, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import BorrowerCategoryEnum from '~Api/Application/BorrowerCategoryEnum';
import BorrowerTypeEnum from '~Api/Application/BorrowerTypeEnum';
import GenderEnum from '~Api/Application/GenderEnum';
import IApplication from '~Api/Application/IApplication';
import IBorrower from '~Api/Application/IBorrower';
import IncomeTypeEnum from '~Api/Application/IncomeTypeEnum';
import LicenceStateEnum from '~Api/Application/LicenceStateEnum';
import MaritalStatusEnum from '~Api/Application/MaritalStatusEnum';
import IDealBorrower from '~Api/Deal/IDealBorrower';
import { applicationBorrowersAddAction } from '~Applications/actions';
import { countriesGetAction } from '~Countries/actions';
import ICountry from '~Countries/ICountry';
import { countriesSelector } from '~Countries/selectors';
import { IGlobalState } from '~reducer';
import DatePicker from '~UI/DatePicker';

interface IState {
    abn: string;
    address: string;
    businessName: string;
    category: BorrowerCategoryEnum;
    creditScore: number;
    dependentNumber: number;
    dob: string;
    email: string;
    firstName: string;
    gender: GenderEnum;
    incomeType: IncomeTypeEnum;
    jobTitle: string;
    lastName: string;
    licenceNumber: string;
    licenceState: LicenceStateEnum;
    maritalStatus: MaritalStatusEnum;
    middleName: string;
    monthlyGrossExpenses: number;
    monthlyGrossTurnover: number;
    monthlyHouseholdExpenses: number;
    onTitle: boolean;
    otherIncomeType: string;
    passportCountry: string;
    passportNumber: string;
    phone: string;
    totalAssets: number;
    totalLiabilities: number;
    trustName: string;
    type: BorrowerTypeEnum;
    yearlyIncome: number;
}

interface IProps {
    application: IApplication;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsSelector {
    countries: ICountry[];
}

interface IPropsDispatch {
    borrowerAdd: (dealBorrower: IDealBorrower, applicationBorrower: IBorrower) => void;
    countriesGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class BorrowerAddModal extends React.Component<Props, IState> {
    public state: IState = {
        abn: null,
        address: null,
        businessName: null,
        category: BorrowerCategoryEnum.Borrower,
        creditScore: null,
        dependentNumber: null,
        dob: null,
        email: null,
        firstName: null,
        gender: null,
        incomeType: null,
        jobTitle: null,
        lastName: null,
        licenceNumber: null,
        licenceState: null,
        maritalStatus: null,
        middleName: null,
        monthlyGrossExpenses: null,
        monthlyGrossTurnover: null,
        monthlyHouseholdExpenses: null,
        onTitle: null,
        otherIncomeType: null,
        passportCountry: null,
        passportNumber: null,
        phone: null,
        totalAssets: null,
        totalLiabilities: null,
        trustName: null,
        type: BorrowerTypeEnum.Individual,
        yearlyIncome: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeCategory = this.onChangeCategory.bind(this);
        this.onChangeType = this.onChangeType.bind(this);

        this.onChangeAbn = this.onChangeAbn.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeBusinessName = this.onChangeBusinessName.bind(this);
        this.onChangeCreditScore = this.onChangeCreditScore.bind(this);
        this.onChangeDependentNumber = this.onChangeDependentNumber.bind(this);
        this.onChangeDob = this.onChangeDob.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeGender = this.onChangeGender.bind(this);
        this.onChangeJobTitle = this.onChangeJobTitle.bind(this);
        this.onChangeIncomeType = this.onChangeIncomeType.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeLicenceNumber = this.onChangeLicenceNumber.bind(this);
        this.onChangeLicenceState = this.onChangeLicenceState.bind(this);
        this.onChangeMaritalStatus = this.onChangeMaritalStatus.bind(this);
        this.onChangeMiddleName = this.onChangeMiddleName.bind(this);
        this.onChangeMonthlyGrossExpenses = this.onChangeMonthlyGrossExpenses.bind(this);
        this.onChangeMonthlyGrossTurnover = this.onChangeMonthlyGrossTurnover.bind(this);
        this.onChangeMonthlyHouseholdExpenses = this.onChangeMonthlyHouseholdExpenses.bind(this);
        this.onChangeOnTitle = this.onChangeOnTitle.bind(this);
        this.onChangeOtherIncomeType = this.onChangeOtherIncomeType.bind(this);
        this.onChangePassportCountry = this.onChangePassportCountry.bind(this);
        this.onChangePassportNumber = this.onChangePassportNumber.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeTotalAssets = this.onChangeTotalAssets.bind(this);
        this.onChangeTotalLiabilities = this.onChangeTotalLiabilities.bind(this);
        this.onChangeTrustName = this.onChangeTrustName.bind(this);
        this.onChangeYearlyIncome = this.onChangeYearlyIncome.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public componentDidMount() {
        const { countries } = this.props;

        if (!countries) {
            this.props.countriesGet();
        }
    }

    public render(): JSX.Element {
        const { countries, isOpen } = this.props;
        const {
            abn,
            address,
            businessName,
            category,
            creditScore,
            dependentNumber,
            dob,
            email,
            firstName,
            gender,
            incomeType,
            jobTitle,
            lastName,
            licenceNumber,
            licenceState,
            maritalStatus,
            middleName,
            monthlyGrossExpenses,
            monthlyGrossTurnover,
            monthlyHouseholdExpenses,
            onTitle,
            otherIncomeType,
            passportCountry,
            passportNumber,
            phone,
            totalAssets,
            totalLiabilities,
            trustName,
            type,
            yearlyIncome,
        } = this.state;

        if (!countries) {
            return null;
        }

        const companyBlock: JSX.Element = type === BorrowerTypeEnum.Company && (
            <React.Fragment>
                <Form.Item className='business-name' label='Company Name'>
                    <Input onChange={this.onChangeBusinessName} value={businessName} />
                </Form.Item>
                <Form.Item className='abn' label='ACN or ABN'>
                    <Input onChange={this.onChangeAbn} value={abn} />
                </Form.Item>
                <Form.Item className='monthly-gross-turnover' label='Monthly Gross Turnover'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeMonthlyGrossTurnover} type='number' value={monthlyGrossTurnover} />
                </Form.Item>
                <Form.Item className='monthly-gross-expenses' label='Monthly Gross Expenses'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeMonthlyGrossExpenses} type='number' value={monthlyGrossExpenses} />
                </Form.Item>
                <Form.Item className='total-assets' label='Total Assets'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeTotalAssets} type='number' value={totalAssets} />
                </Form.Item>
                <Form.Item className='total-liabilities' label='Total Liabilities'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeTotalLiabilities} type='number' value={totalLiabilities} />
                </Form.Item>
                <Form.Item className='credit-score' label='Credit Score'>
                    <Input min={0} max={1200}  onChange={this.onChangeCreditScore} type='number' value={creditScore} />
                </Form.Item>
            </React.Fragment>
        );

        const jobTitleBlock: JSX.Element = [IncomeTypeEnum.PaygFullTime, IncomeTypeEnum.PaygPartTime].includes(incomeType) && (
            <Form.Item className='job-title' label='Job Title'>
                <Input onChange={this.onChangeJobTitle} value={jobTitle} />
            </Form.Item>
        );

        const otherIncomeTypeBlock: JSX.Element = incomeType === IncomeTypeEnum.Other && (
            <Form.Item className='otherIncomeType' label='Other Income Details'>
                <Input onChange={this.onChangeOtherIncomeType} value={otherIncomeType} />
            </Form.Item>
        );

        const selfEmployedBlock: JSX.Element = incomeType === IncomeTypeEnum.SelfEmployed && (
            <React.Fragment>
                <Form.Item className='business-name' label='Business Name'>
                    <Input onChange={this.onChangeBusinessName} value={businessName} />
                </Form.Item>
                <Form.Item className='abn' label='ABN'>
                    <Input onChange={this.onChangeAbn} value={abn} />
                </Form.Item>
            </React.Fragment>
        );

        const individualBlock: JSX.Element = type === BorrowerTypeEnum.Individual && (
            <React.Fragment>
                <Form.Item className='first-name' label='First Name'>
                    <Input onChange={this.onChangeFirstName} value={firstName} />
                </Form.Item>
                <Form.Item className='middle-name' label='Middle Name(s)'>
                    <Input onChange={this.onChangeMiddleName} value={middleName} />
                </Form.Item>
                <Form.Item className='last-name' label='Last Name'>
                    <Input onChange={this.onChangeLastName} value={lastName} />
                </Form.Item>
                <Form.Item className='phone' label='Phone'>
                    <Input onChange={this.onChangePhone} value={phone} />
                </Form.Item>
                <Form.Item className='email' label='Email'>
                    <Input onChange={this.onChangeEmail} value={email} />
                </Form.Item>
                <Form.Item className='dob' label='DOB'>
                    <DatePicker onChange={this.onChangeDob} format='DD/MM/YYYY' value={dob ? dayjs(dob) : null} />
                </Form.Item>
                <Form.Item className='gender' label='Gender'>
                    <Select onChange={this.onChangeGender} value={gender}>
                        <Select.Option value={GenderEnum.Female}>Female</Select.Option>
                        <Select.Option value={GenderEnum.Male}>Male</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item className='licence-state' label='Licence State'>
                    <Select onChange={this.onChangeLicenceState} value={licenceState}>
                        {_.values(LicenceStateEnum).map((stateCode: string) => <Select.Option key={stateCode} value={stateCode}>{stateCode}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item className='licence-number' label='Licence Number'>
                    <Input onChange={this.onChangeLicenceNumber} value={licenceNumber} />
                </Form.Item>
                <Form.Item className='passport-country' label='Passport Country'>
                    <Select onChange={this.onChangePassportCountry} value={passportCountry}>
                        {countries.map((country: ICountry) => <Select.Option key={country.iso3} value={country.iso3}>{country.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item className='passport-number' label='Passport Number'>
                    <Input onChange={this.onChangePassportNumber} value={passportNumber} />
                </Form.Item>
                <Form.Item className='address' label='Address'>
                    <Input onChange={this.onChangeAddress} value={address} />
                </Form.Item>
                <Form.Item className='dependent-number' label='Number of Dependents'>
                    <Select onChange={this.onChangeDependentNumber} value={dependentNumber}>
                        {_.times(11, (n: number) => <Select.Option key={n} value={n}>{n}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item className='marital-status' label='Marital Status'>
                    <Select onChange={this.onChangeMaritalStatus} value={maritalStatus}>
                        <Select.Option value={MaritalStatusEnum.Single}>Single</Select.Option>
                        <Select.Option value={MaritalStatusEnum.Married}>Married</Select.Option>
                        <Select.Option value={MaritalStatusEnum.DeFacto}>De Facto</Select.Option>
                        <Select.Option value={MaritalStatusEnum.Separated}>Separated</Select.Option>
                        <Select.Option value={MaritalStatusEnum.Divorced}>Divorced</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item className='income-type' label='Income Type'>
                    <Select onChange={this.onChangeIncomeType} value={incomeType}>
                        <Select.Option value={IncomeTypeEnum.PaygFullTime}>PAYG - Full Time</Select.Option>
                        <Select.Option value={IncomeTypeEnum.PaygPartTime}>PAYG - Part Time</Select.Option>
                        <Select.Option value={IncomeTypeEnum.SelfEmployed}>Self Employed</Select.Option>
                        <Select.Option value={IncomeTypeEnum.Other}>Other</Select.Option>
                    </Select>
                </Form.Item>
                {otherIncomeTypeBlock}
                {jobTitleBlock}
                {selfEmployedBlock}
                <Form.Item className='yearly-income' label='Yearly Income'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeYearlyIncome} type='number' value={yearlyIncome} />
                </Form.Item>
                <Form.Item className='monthly-household-expenses' extra='Monthly' label='Household Expenses'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeMonthlyHouseholdExpenses} type='number' value={monthlyHouseholdExpenses} />
                </Form.Item>
                <Form.Item className='total-assets' label='Total Assets'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeTotalAssets} type='number' value={totalAssets} />
                </Form.Item>
                <Form.Item className='total-liabilities' label='Total Liabilities'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeTotalLiabilities} type='number' value={totalLiabilities} />
                </Form.Item>
                <Form.Item className='credit-score' label='Credit Score'>
                    <Input min={0} max={1200}  onChange={this.onChangeCreditScore} type='number' value={creditScore} />
                </Form.Item>
            </React.Fragment>
        );

        const trustBlock: JSX.Element = type === BorrowerTypeEnum.Trust && (
            <React.Fragment>
                <Form.Item className='trust-name' label='Trust Name'>
                    <Input onChange={this.onChangeTrustName} value={trustName} />
                </Form.Item>
                <Form.Item className='abn' label='ACN or ABN'>
                    <Input onChange={this.onChangeAbn} value={abn} />
                </Form.Item>
                <Form.Item className='total-assets' label='Total Assets'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeTotalAssets} type='number' value={totalAssets} />
                </Form.Item>
                <Form.Item className='total-liabilities' label='Total Liabilities'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeTotalLiabilities} type='number' value={totalLiabilities} />
                </Form.Item>
            </React.Fragment>
        );

        return (
            <Modal
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Borrower'
                wrapClassName='application-borrower-modal'
            >
                <Form.Item className='category' label='Category'>
                    <Select onChange={this.onChangeCategory} value={category}>
                        <Select.Option value={BorrowerCategoryEnum.Borrower}>Borrower</Select.Option>
                        <Select.Option value={BorrowerCategoryEnum.Guarantor}>Guarantor</Select.Option>
                        <Select.Option value={BorrowerCategoryEnum.Trustee}>Trustee</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item className='type' label='Type'>
                    <Select onChange={this.onChangeType} value={type}>
                        <Select.Option value={BorrowerTypeEnum.Company}>Company</Select.Option>
                        <Select.Option value={BorrowerTypeEnum.Individual}>Individual</Select.Option>
                        {category !== BorrowerCategoryEnum.Trustee && <Select.Option value={BorrowerTypeEnum.Trust}>Trust</Select.Option>}
                    </Select>
                </Form.Item>
                {companyBlock}
                {individualBlock}
                {trustBlock}
                <Form.Item className='on-title' label='On Title'>
                    <Checkbox onChange={this.onChangeOnTitle} checked={onTitle} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeCategory(value: BorrowerCategoryEnum) {
        this.setState({
            category: value,
        });
    }

    private onChangeType(value: BorrowerTypeEnum) {
        this.setState({
            type: value,
        });
    }

    private onChangeAbn(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.setState({
            abn: event.target.value.substr(0, 11),
        });
    }

    private onChangeAddress(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            address: event.target.value.substr(0, 255),
        });
    }

    private onChangeBusinessName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            businessName: event.target.value.substr(0, 100),
        });
    }

    private onChangeCreditScore(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 1200) {
            return;
        }

        this.setState({
            creditScore: event.target.value === '' ? null : event.target.valueAsNumber,
        });
    }

    private onChangeDependentNumber(value: number) {
        this.setState({
            dependentNumber: value,
        });
    }

    private onChangeDob(date: Dayjs) {
        this.setState({
            dob: date ? date.format('YYYY-MM-DD') : null,
        });
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            email: event.target.value.substr(0, 100),
        });
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            firstName: event.target.value.substr(0, 50),
        });
    }

    private onChangeGender(value: GenderEnum) {
        this.setState({
            gender: value,
        });
    }

    private onChangeIncomeType(value: IncomeTypeEnum) {
        this.setState({
            incomeType: value,
        });
    }

    private onChangeJobTitle(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            jobTitle: event.target.value,
        });
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            lastName: event.target.value.substr(0, 50),
        });
    }

    private onChangeLicenceNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            licenceNumber: event.target.value.substr(0, 20),
        });
    }

    private onChangeLicenceState(value: LicenceStateEnum) {
        this.setState({
            licenceState: value,
        });
    }

    private onChangeMaritalStatus(value: MaritalStatusEnum) {
        this.setState({
            maritalStatus: value,
        });
    }

    private onChangeMiddleName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            middleName: event.target.value.substr(0, 255),
        });
    }

    private onChangeMonthlyGrossExpenses(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.setState({
            monthlyGrossExpenses: event.target.value === '' ? null : event.target.valueAsNumber,
        });
    }

    private onChangeMonthlyGrossTurnover(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.setState({
            monthlyGrossTurnover: event.target.value === '' ? null : event.target.valueAsNumber,
        });
    }

    private onChangeMonthlyHouseholdExpenses(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.setState({
            monthlyHouseholdExpenses: event.target.value === '' ? null : event.target.valueAsNumber,
        });
    }

    private onChangeOtherIncomeType(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            otherIncomeType: event.target.value.substr(0, 100),
        });
    }

    private onChangeOnTitle(event: CheckboxChangeEvent) {
        this.setState({
            onTitle: event.target.checked,
        });
    }

    private onChangePassportCountry(value: string) {
        this.setState({
            passportCountry: value.substr(0, 3),
        });
    }

    private onChangePassportNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            passportNumber: event.target.value.substr(0, 20),
        });
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            phone: event.target.value.substr(0, 20),
        });
    }

    private onChangeTotalAssets(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.setState({
            totalAssets: event.target.value === '' ? null : event.target.valueAsNumber,
        });
    }

    private onChangeTotalLiabilities(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.setState({
            totalLiabilities: event.target.value === '' ? null : event.target.valueAsNumber,
        });
    }

    private onChangeTrustName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            trustName: event.target.value.substr(0, 100),
        });
    }

    private onChangeYearlyIncome(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.setState({
            yearlyIncome: event.target.value === '' ? null : event.target.valueAsNumber,
        });
    }

    private onClickOk() {
        const {
            abn,
            address,
            businessName,
            category,
            creditScore,
            dependentNumber,
            dob,
            email,
            firstName,
            gender,
            incomeType,
            jobTitle,
            lastName,
            licenceNumber,
            licenceState,
            maritalStatus,
            middleName,
            monthlyGrossExpenses,
            monthlyGrossTurnover,
            monthlyHouseholdExpenses,
            onTitle,
            otherIncomeType,
            passportCountry,
            passportNumber,
            phone,
            totalAssets,
            totalLiabilities,
            trustName,
            type,
            yearlyIncome,
        } = this.state;

        this.props.onCancel();

        this.setState({
            abn: null,
            address: null,
            businessName: null,
            category: null,
            creditScore: null,
            dependentNumber: null,
            dob: null,
            email: null,
            firstName: null,
            gender: null,
            incomeType: null,
            jobTitle: null,
            lastName: null,
            licenceNumber,
            licenceState,
            maritalStatus: null,
            middleName: null,
            monthlyGrossExpenses: null,
            monthlyGrossTurnover: null,
            monthlyHouseholdExpenses: null,
            onTitle: null,
            otherIncomeType: null,
            passportCountry,
            passportNumber,
            phone: null,
            totalAssets: null,
            totalLiabilities: null,
            trustName: null,
            type: null,
            yearlyIncome: null,
        });

        const dealBorrower: IDealBorrower = {
            abn,
            address,
            businessName,
            category,
            dependentNumber,
            dob,
            email,
            firstName,
            formattedName: type === BorrowerTypeEnum.Individual ? `${firstName} ${lastName}` : (type === BorrowerTypeEnum.Company ? businessName : trustName),
            gender,
            incomeType,
            jobTitle,
            lastName,
            licenceNumber,
            licenceState,
            maritalStatus,
            middleName,
            monthlyGrossExpenses,
            monthlyGrossTurnover,
            monthlyHouseholdExpenses,
            onTitle,
            otherIncomeType,
            passportCountry,
            passportNumber,
            phone,
            totalAssets,
            totalLiabilities,
            trustName,
            type,
            yearlyIncome,
        };

        const applicationBorrower: IBorrower = {
            creditScore,
        };

        this.props.borrowerAdd(dealBorrower, applicationBorrower);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        countries: countriesSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        borrowerAdd: (dealBorrower: IDealBorrower, applicationBorrower: IBorrower) => dispatch(applicationBorrowersAddAction(ownProps.application.uuid, dealBorrower, applicationBorrower)),
        countriesGet: () => dispatch(countriesGetAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BorrowerAddModal);
