import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '~Layout';
import List from './List';
import User from './User';
import SuspiciousActivity from './SuspiciousActivity';
import IAuthUser from '~Auth/IAuthUser';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import { authCurrentUserSelector } from '~Auth/selectors';
import { IGlobalState } from '~reducer';
import { connect } from 'react-redux';

interface IPropsSelector {
    currentUser: IAuthUser;
}

type Props = IPropsSelector;

class Users extends React.Component<Props> {
    public render(): JSX.Element {
        const { currentUser } = this.props;

        return (
            <Layout topSection='users'>
                <Switch>
                    <Route exact={true} path='/users' component={List} />
                    {currentUser.permissions.includes(PermissionsEnum.UserBlock) && <Route exact={true} path='/users/suspicious-activity' component={SuspiciousActivity} />}
                    <Route path='/users/:uuid' component={User} />
                </Switch>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentUser: authCurrentUserSelector(state),
    };
}

export default connect(
    mapStateToProps,
)(Users);
