import { Action } from 'redux';
import AggregatorsActionsEnum from './ActionsEnum';
import IAggregator from '~Api/Aggregator/IAggregator';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAggregatorsListAction extends Action {}

export function aggregatorsListAction(): IAggregatorsListAction {
    return {
        type: AggregatorsActionsEnum.AggregatorsList,
    };
}

export interface IAggregatorsSetAction extends Action {
    aggregators: IAggregator[];
}

export function aggregatorsSetAction(aggregators: IAggregator[]): IAggregatorsSetAction {
    return {
        aggregators,
        type: AggregatorsActionsEnum.AggregatorsSet,
    };
}
