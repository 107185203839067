import _ from 'lodash';
import { AnyAction } from 'redux';
import IInvestorUser from '~Api/Investor/IInvestorUser';
import IHistory from '~Api/User/IHistory';
import IUser from '~Api/User/IUser';
import {
    IInvestorUsersSetAction,
} from '~Investors/actions';
import InvestorsActionsEnum from '~Investors/ActionsEnum';
import {
    IUserHistoriesSetAction,
    IUserInvestorsSetAction,
    IUserSetAction,
    IUserValueSetAction,
    IUsersSetAction,
    IUsersSuspiciousSetAction,
} from './actions';
import UsersActionsEnum from './ActionsEnum';
import { IDictionary } from '~utilities/IDictionary';

export interface IUsersState {
    histories: {
        [userUuid: string]: {
            [userHistoryId: string]: IHistory,
        },
    };
    users: {
        [userUuid: string]: IUser,
    };
    usersInvestors: IDictionary<IDictionary<IInvestorUser>>;
    usersListed: boolean;
    usersSuspiciousUuids: string[];
}

const initialData: IUsersState = {
    histories: {},
    users: null,
    usersInvestors: {},
    usersListed: false,
    usersSuspiciousUuids: null,
};

export function usersReducer(state: IUsersState = initialData, action: AnyAction): IUsersState {
    switch (action.type) {
        case InvestorsActionsEnum.InvestorUsersSet: {
            const typedAction: IInvestorUsersSetAction = action as IInvestorUsersSetAction;

            const users: {
                [userUuid: string]: IUser,
            } = {};

            typedAction.investorUsers.forEach((investorUser: IInvestorUser) => {
                users[investorUser.user.uuid] = investorUser.user;
            });

            return {
                ...state,
                users: {
                    ...state.users,
                    ...users,
                },
            };
        }

        case UsersActionsEnum.UsersSet: {
            const typedAction: IUsersSetAction = action as IUsersSetAction;

            const users: {
                [userUuid: string]: IUser,
            } = {};

            typedAction.users.forEach((user: IUser) => {
                users[user.uuid] = user;
            });

            return {
                ...state,
                users: {
                    ...state.users,
                    ...users,
                },
                usersListed: true,
            };
        }

        case UsersActionsEnum.UsersSuspiciousSet: {
            const typedAction: IUsersSuspiciousSetAction = action as IUsersSuspiciousSetAction;

            const users: IDictionary<IUser> = { ...state.users };
            const usersSuspiciousUuids: string[] = [];

            typedAction.users.forEach((user: IUser) => {
                users[user.uuid] = user;
                usersSuspiciousUuids.push(user.uuid);
            });

            return {
                ...state,
                users,
                usersSuspiciousUuids,
            };
        }

        case UsersActionsEnum.UserHistoriesSet: {
            const typedAction: IUserHistoriesSetAction = action as IUserHistoriesSetAction;

            return {
                ...state,
                histories: {
                    ...state.histories,
                    [typedAction.uuid]: _.keyBy(typedAction.histories, 'uuid'),
                },
            };
        }

        case UsersActionsEnum.UserInvestorsSet: {
            const typedAction: IUserInvestorsSetAction = action as IUserInvestorsSetAction;

            const userInvestors: IDictionary<IInvestorUser> = {};
            typedAction.investorUsers.forEach((investorUser: IInvestorUser) => {
                userInvestors[investorUser.uuid] = _.omit(investorUser, ['investor', 'user']);
            });

            return {
                ...state,
                usersInvestors: {
                    ...state.usersInvestors,
                    [typedAction.uuid]: userInvestors,
                },
            };
        }

        case UsersActionsEnum.UserSet: {
            const typedAction: IUserSetAction = action as IUserSetAction;

            return {
                ...state,
                users: {
                    ...state.users,
                    [typedAction.user.uuid]: typedAction.user,
                },
            };
        }

        case UsersActionsEnum.UserValueSet: {
            const typedAction: IUserValueSetAction = action as IUserValueSetAction;

            return {
                ...state,
                users: {
                    ...state.users,
                    [typedAction.uuid]: {
                        ...state.users[typedAction.uuid],
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        default:
            return state;
    }
}
