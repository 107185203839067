import { Action } from 'redux';
import ActionsEnum from './ActionsEnum';
import ICountry from './ICountry';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICountriesGetAction extends Action {}

export function countriesGetAction(): ICountriesGetAction {
    return {
        type: ActionsEnum.CountriesGet,
    };
}

export interface ICountriesSetAction extends Action {
    countries: ICountry[];
}

export function countriesSetAction(countries: ICountry[]): ICountriesSetAction {
    return {
        countries,
        type: ActionsEnum.CountriesSet,
    };
}
