import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Edit from './Edit';
import History from './History';
import Investors from './Investors';
import Overview from './Overview';

export default function router(): JSX.Element {
    return (
        <Switch>
            <Route exact={true} path='/users/:uuid' component={Overview} />
            <Route exact={true} path='/users/:uuid/edit' component={Edit} />
            <Route exact={true} path='/users/:uuid/history' component={History} />
            <Route exact={true} path='/users/:uuid/investors' component={Investors} />
        </Switch>
    );
}
