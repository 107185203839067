import { all, takeEvery } from '@redux-saga/core/effects';
import { message } from 'antd';
import _ from 'lodash';
import {
    IErrorSetAction,
} from './actions';
import ErrorActionsEnum from './ActionsEnum';

function* errorSet(action: IErrorSetAction): Iterator<any> {
    message.error(action.error);
}

export function* ErrorSagas(): any {
    yield all([
        takeEvery(ErrorActionsEnum.ErrorSet, errorSet),
    ]);
}
