import { AutoComplete, Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import DisbursalTypeEnum from '~Api/Application/DisbursalTypeEnum';
import DisbursementTypeEnum from '~Api/Application/DisbursementTypeEnum';
import IApplicationDisbursement from '~Api/Application/IApplicationDisbursement';
import {
    applicationDisbursementSuggestionsListAction,
    applicationDisbursementValueSetAction,
} from '~Applications/actions';
import {
    applicationDisbursementApplicationSelector,
    applicationDisbursementDescriptionSuggestionsSelector,
    applicationDisbursementPayeeNameSuggestionsSelector,
    applicationDisbursementSelector,
} from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

interface IDefaultedValues {
    amount: number;
    type: DisbursementTypeEnum;
}

interface IOption {
    label: string;
    value: string;
}

interface IState {
    amount: number;
    descriptionOptions: IOption[];
    dirtyFields: IDictionary<boolean>;
    payeeNameOptions: IOption[];
    type: DisbursementTypeEnum;
}

interface IProps {
    disbursementUuid: string;
    isOpen: boolean;
    onClose: () => void;
}

interface IPropsSelector {
    application: IApplication;
    descriptionSuggestions: IDictionary<number>;
    disbursement: IApplicationDisbursement;
    payeeNameSuggestions: IDictionary<number>;
}

interface IPropsDispatch {
    suggestionsList: () => void;
    update: (key: keyof IApplicationDisbursement, value: any) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class DisbursementEditModal extends React.Component<Props, IState> {
    public state: IState = {
        amount: null,
        descriptionOptions: [],
        dirtyFields: {},
        payeeNameOptions: [],
        type: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onSearchDescription = this.onSearchDescription.bind(this);
        this.onSelectDescription = this.onSelectDescription.bind(this);
        this.onSearchPayeeName = this.onSearchPayeeName.bind(this);
        this.onSelectPayeeName = this.onSelectPayeeName.bind(this);
        this.onChangeType = this.onChangeType.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public componentDidMount() {
        this.props.suggestionsList();
    }

    public render(): JSX.Element {
        const { application, descriptionSuggestions, disbursement, isOpen, payeeNameSuggestions } = this.props;
        const { descriptionOptions, payeeNameOptions } = this.state;

        if (!disbursement || !descriptionSuggestions || !payeeNameSuggestions) {
            return null;
        }

        const { amount, type } = this.getDefaultedValues();

        const footer: JSX.Element = (
            <React.Fragment>
                <Button className='close' onClick={this.props.onClose}>Close</Button>
            </React.Fragment>
        );

        return (
            <Modal
                destroyOnClose={true}
                footer={footer}
                okText='Close'
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title='Edit Disbursement/Outlay'
                wrapClassName='application-disbursement-edit-modal'
            >
                <Form.Item label='Payee Name' className='payee-name'>
                    <AutoComplete
                        dropdownMatchSelectWidth={300}
                        maxLength={255}
                        onSearch={this.onSearchPayeeName}
                        onSelect={this.onSelectPayeeName}
                        options={_.slice(payeeNameOptions, 0, 20)}
                        value={disbursement.payeeName}
                    />
                </Form.Item>
                <Form.Item label='Amount' className='amount'>
                    <InputNumber addonBefore='$' min={0} onChange={this.onChangeAmount} value={amount} placeholder='0' />
                </Form.Item>
                <Form.Item label='Description' className='description'>
                    <AutoComplete
                        dropdownMatchSelectWidth={300}
                        maxLength={255}
                        onSearch={this.onSearchDescription}
                        onSelect={this.onSelectDescription}
                        options={_.slice(descriptionOptions, 0, 20)}
                        value={disbursement.description}
                    />
                </Form.Item>
                <Form.Item className='type' label='Disbursement Type'>
                    <Select onChange={this.onChangeType} value={type}>
                        <Select.Option value={null}>N/A</Select.Option>
                        <Select.Option value={DisbursementTypeEnum.PrepaidInterest}>Prepaid Interest</Select.Option>
                        {application.disbursalType === DisbursalTypeEnum.Drawdown && <Select.Option value={DisbursementTypeEnum.RetainedFunds}>Retained Funds</Select.Option>}
                        {application.disbursalType === DisbursalTypeEnum.Drawdown && <Select.Option value={DisbursementTypeEnum.RetainedContingency}>Retained Contingency</Select.Option>}
                    </Select>
                </Form.Item>
            </Modal>
        );
    }

    private getDefaultedValues(): IDefaultedValues {
        const { disbursement } = this.props;
        const { dirtyFields, amount, type } = this.state;

        return {
            amount: dirtyFields.amount ? amount : disbursement.amount !== 0 ? disbursement.amount : null,
            type: dirtyFields.type ? type : disbursement.type,
        };
    }

    private onChangeAmount(value: number) {
        const { dirtyFields } = this.state;

        this.setState({
            amount: value,
            dirtyFields: {
                ...dirtyFields,
                amount: true,
            },
        });
        this.props.update('amount', value || 0);
    }

    private onChangeType(value: DisbursementTypeEnum) {
        const { dirtyFields } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                type: true,
            },
            type: value,
        });
        this.props.update('type', value);
    }

    private onClickOk() {
        this.props.onClose();
    }

    private onSearchDescription(value: string) {
        const { descriptionSuggestions } = this.props;

        const searchValue: string = value.toLocaleLowerCase();

        const results: IOption[] = [];

        _.keys(descriptionSuggestions).forEach((suggestion: string) => {
            if (suggestion.toLocaleLowerCase().includes(searchValue)) {
                results.push({
                    label: suggestion,
                    value: suggestion,
                });
            }
        });

        this.setState({
            descriptionOptions: _.reverse(_.sortBy(results, (result: IOption) => descriptionSuggestions[result.value])),
        });

        this.props.update('description', value);
    }

    private onSelectDescription(value: string) {
        this.props.update('description', value);
    }

    private onSearchPayeeName(value: string) {
        const { payeeNameSuggestions } = this.props;

        const searchValue: string = value.toLocaleLowerCase();

        const results: IOption[] = [];

        _.keys(payeeNameSuggestions).forEach((suggestion: string) => {
            if (suggestion.toLocaleLowerCase().includes(searchValue)) {
                results.push({
                    label: suggestion,
                    value: suggestion,
                });
            }
        });

        this.setState({
            payeeNameOptions: _.reverse(_.sortBy(results, (result: IOption) => payeeNameSuggestions[result.value])),
        });

        this.props.update('payeeName', value);
    }

    private onSelectPayeeName(value: string) {
        this.props.update('payeeName', value);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationDisbursementApplicationSelector(state, ownProps.disbursementUuid),
        descriptionSuggestions: applicationDisbursementDescriptionSuggestionsSelector(state),
        disbursement: applicationDisbursementSelector(state, ownProps.disbursementUuid),
        payeeNameSuggestions: applicationDisbursementPayeeNameSuggestionsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        suggestionsList: () => dispatch(applicationDisbursementSuggestionsListAction()),
        update: (key: keyof IApplicationDisbursement, value: any) => dispatch(applicationDisbursementValueSetAction(ownProps.disbursementUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DisbursementEditModal);
