import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import DealProperties from './DealProperties';
import Disbursements from './Disbursements';
import Documents from './Documents';
import Edit from './Edit';
import Fees from './Fees';
import GracePeriods from './GracePeriods';
import History from './History';
import Notes from './Notes';
import Overview from './Overview';
import PayoutFigureAdd from './PayoutFigureAdd';
import PayoutFigureEdit from './PayoutFigureEdit';
import PayoutFigures from './PayoutFigures';
import Properties from './Properties';
import Transactions from './Transactions';
import Warehouses from './Warehouses';

export default function Router(): ReactElement {
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);

    return (
        <Switch>
            <Route exact={true} path='/loans/:uuid' component={Overview} />
            <Route exact={true} path='/loans/:loanUuid/deal-properties' component={DealProperties} />
            <Route exact={true} path='/loans/:uuid/disbursements' component={Disbursements} />
            <Route exact={true} path='/loans/:uuid/documents' component={Documents} />
            <Route exact={true} path='/loans/:uuid/edit' component={Edit} />
            <Route exact={true} path='/loans/:uuid/fees' component={Fees} />
            <Route exact={true} path='/loans/:uuid/grace-periods' component={GracePeriods} />
            <Route exact={true} path='/loans/:uuid/history' component={History} />
            <Route exact={true} path='/loans/:uuid/notes' component={Notes} />
            <Route exact={true} path='/loans/:uuid/payout-figures/add' component={PayoutFigureAdd} />
            <Route exact={true} path='/loans/:loanUuid/payout-figures/:payoutFigureUuid/edit' component={PayoutFigureEdit} />
            <Route exact={true} path='/loans/:uuid/payout-figures' component={PayoutFigures} />
            <Route exact={true} path='/loans/:uuid/properties' component={Properties} />
            <Route exact={true} path='/loans/:uuid/transactions' component={Transactions} />
            {currentUser.permissions.includes(PermissionsEnum.Warehouses) && <Route exact={true} path='/loans/:loanUuid/warehouses' component={Warehouses} />}
        </Switch>
    );
}
