import { Button, Dropdown, Image, MenuProps, Modal, Space, Spin, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import {
    administratorGetAction,
    administratorMagicLinkRequestApproveAction,
    administratorMagicLinkRequestCancelAction,
} from '~Administrators/actions';
import { administratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import dayjs from 'dayjs';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';

interface IMatch {
    administratorUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    administrator: IAdministrator;
    currentUser: IAuthUser;
}

interface IPropsDispatch {
    administratorGet: () => void;
    magicLinkRequestApprove: () => void;
    magicLinkRequestCancel: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Overview extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickMagicLinkRequestApprove = this.onClickMagicLinkRequestApprove.bind(this);
        this.onClickMagicLinkRequestCancel = this.onClickMagicLinkRequestCancel.bind(this);
    }

    public componentDidMount() {
        const { administrator } = this.props;

        if (!administrator) {
            this.props.administratorGet();
        }
    }

    public render(): JSX.Element {
        const { administrator, currentUser, match } = this.props;

        if (!administrator) {
            return (
                <Layout administratorUuid={match.params.administratorUuid} section='overview'>
                    <Typography.Title level={2}>Overview</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        let requestButton: JSX.Element = null;

        if (currentUser.permissions.includes(PermissionsEnum.AdministratorMagicLink) && administrator.magicLinkRequestedTime) {
            if (dayjs(administrator.magicLinkRequestedTime).isAfter(dayjs().subtract(1, 'days'))) {
                const actionsMenu: MenuProps = {
                    items: [
                        {
                            danger: true,
                            key: 'cancel-magic-link',
                            label: 'Cancel Magic Link Request',
                            onClick: this.onClickMagicLinkRequestCancel,
                        },
                    ],
                };

                requestButton = <Dropdown.Button onClick={this.onClickMagicLinkRequestApprove} menu={actionsMenu}>Approve Magic Link Request</Dropdown.Button>;
            } else {
                requestButton = <Button danger={true} onClick={this.onClickMagicLinkRequestCancel}>Cancel Magic Link Request</Button>;
            }
        }

        return (
            <Layout administratorUuid={match.params.administratorUuid} section='overview'>
                <Space className='actions'>
                    {requestButton}
                </Space>
                <Typography.Title level={2}>Overview</Typography.Title>
                {administrator.photoUrl && <Image height={120} src={administrator.photoUrl} width={120} />}
            </Layout>
        );
    }

    private onClickMagicLinkRequestApprove(): void {
        const { administrator } = this.props;

        Modal.confirm({
            content: `Are you sure you want to approve the magic link request made on ${dayjs(administrator.magicLinkRequestedTime).format('DD/MM/YYYY')}?`,
            okText: 'Approve',
            okType: 'danger',
            onOk: () => {
                this.props.magicLinkRequestApprove();
            },
            title: 'Approve Magic Link Request',
        });
    }

    private onClickMagicLinkRequestCancel(): void {
        const { administrator } = this.props;

        Modal.confirm({
            content: `Are you sure you want to cancel the magic link request made on ${dayjs(administrator.magicLinkRequestedTime).format('DD/MM/YYYY')}?`,
            okText: 'Cancel',
            okType: 'danger',
            onOk: () => {
                this.props.magicLinkRequestCancel();
            },
            title: 'Cancel Magic Link Request',
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        administrator: administratorSelector(state, ownProps.match.params.administratorUuid),
        currentUser: authCurrentUserSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        administratorGet: () => dispatch(administratorGetAction(ownProps.match.params.administratorUuid)),
        magicLinkRequestApprove: () => dispatch(administratorMagicLinkRequestApproveAction(ownProps.match.params.administratorUuid)),
        magicLinkRequestCancel: () => dispatch(administratorMagicLinkRequestCancelAction(ownProps.match.params.administratorUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Overview);
