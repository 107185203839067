import { Button, Divider, Form, Spin, Timeline, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import { administratorsSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import INote from '~Api/Deal/INote';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import {
    dealNotesAddAction,
    dealNotesListAction,
} from './actions';
import './deals.less';
import { dealNotesSelector } from './selectors';

interface IState {
    note: string;
}

interface IProps {
    dealUuid: string;
}

interface IPropsSelector {
    administrators: IDictionary<IAdministrator>;
    notes: INote[];
}

interface IPropsDispatch {
    administratorsList: () => void;
    noteAdd: (note: string) => void;
    notesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class NotesList extends React.Component<Props, IState> {
    public state: IState = {
        note: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeNote = this.onChangeNote.bind(this);
        this.onClickAddNote = this.onClickAddNote.bind(this);
    }

    public componentDidMount() {
        const { administrators } = this.props;

        if (!administrators) {
            this.props.administratorsList();
        }

        this.props.notesList();
    }

    public render(): JSX.Element {
        const { administrators, notes } = this.props;
        const { note } = this.state;

        if (!administrators || !notes) {
            return (
                <Spin/>
            );
        }

        const unsavedNote: INote = _.find(notes, (loopNote: INote) => !loopNote.uuid);

        const unsavedNoteBlock: JSX.Element = unsavedNote && (
            <React.Fragment>
                <Typography.Text type='secondary'>{administrators[unsavedNote.administratorUuid].name}</Typography.Text><br/>
                <Typography.Text>{unsavedNote.note}</Typography.Text>
            </React.Fragment>
        );

        const notesBlock: JSX.Element[] = _.sortBy(_.filter(notes, (loopNote: INote) => !!loopNote.uuid), 'createdTime').map((loopNote: INote) => (
            <Timeline.Item key={loopNote.uuid} label={dayjs(loopNote.createdTime).format('YYYY-MM-DD - HH:mm')}>
                <Typography.Text type='secondary'>{administrators[loopNote.administratorUuid].name}</Typography.Text><br/>
                <Typography.Text>{loopNote.note}</Typography.Text>
            </Timeline.Item>
        ));

        return (
            <div className='deal-notes'>
                <Form.Item label='Note' className='note'>
                    <TextArea disabled={!!unsavedNote} onChange={this.onChangeNote} rows={15} value={note} />
                </Form.Item>
                <Button
                    className='add-note'
                    disabled={!note || !!unsavedNote}
                    onClick={this.onClickAddNote}
                    type='primary'
                >
                    Add Note
                </Button>
                {notesBlock.length > 0 && <Divider/>}
                <Timeline mode='left' pending={unsavedNoteBlock} reverse={true}>
                    {notesBlock}
                </Timeline>
            </div>
        );
    }

    private onChangeNote(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            note: event.target.value,
        });
    }

    private onClickAddNote() {
        const { note } = this.state;

        this.setState({
            note: null,
        });

        this.props.noteAdd(note);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        administrators: administratorsSelector(state),
        notes: dealNotesSelector(state, ownProps.dealUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        noteAdd: (note: string) => dispatch(dealNotesAddAction(ownProps.dealUuid, note)),
        notesList: () => dispatch(dealNotesListAction(ownProps.dealUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NotesList);
