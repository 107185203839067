import { Form, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IBroker from '~Api/Broker/IBroker';
import { brokerRecordContactAction } from '~Brokers/actions';

interface IState {
    note: string;
}

interface IProps {
    isOpen: boolean;
    broker: IBroker;
    onCancel: () => void;
}

interface IPropsDispatch {
    recordContact: (note: string) => void;
}

type Props = IProps & IPropsDispatch;

class RecordContactModal extends React.Component<Props, IState> {
    public state: IState = {
        note: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeNote = this.onChangeNote.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { note } = this.state;

        return (
            <Modal
                okText='Record Contact'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Record Contact'
                wrapClassName='broker-record-contact-modal'
            >
                <Form.Item label='Note' className='note'>
                    <TextArea onChange={this.onChangeNote} value={note} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeNote(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            note: event.target.value,
        });
    }

    private onClickOk() {
        const { note } = this.state;

        this.props.onCancel();

        this.props.recordContact(note);
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        recordContact: (note: string) => dispatch(brokerRecordContactAction(ownProps.broker.uuid, note)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(RecordContactModal);
