import { fetchGet, fetchPost, fetchPut } from '~utilities/fetch';
import { transformAdviser, transformAdviserNote } from './transformers';
import IAdviser from './IAdviser';
import INote from './INote';

export async function advisersAddRequest(adviser: IAdviser): Promise<Response> {
    return fetchPost('/advisers', transformAdviser(adviser));
}

export async function advisersListRequest(): Promise<Response> {
    return fetchGet('/advisers');
}

export async function adviserGetRequest(adviserUuid: string): Promise<Response> {
    return fetchGet(`/advisers/${adviserUuid}`);
}

export async function adviserApproveRequest(adviserUuid: string): Promise<Response> {
    return fetchPost(`/advisers/${adviserUuid}/approve`);
}

export async function adviserHistoriesListRequest(adviserUuid: string): Promise<Response> {
    return fetchGet(`/advisers/${adviserUuid}/history`);
}

export async function advisersInvestorsListRequest(adviserUuid: string): Promise<Response> {
    return fetchGet(`/advisers/${adviserUuid}/investors`);
}

export async function adviserNotesAddRequest(adviserUuid: string, note: INote): Promise<Response> {
    return fetchPost(`/advisers/${adviserUuid}/notes`, transformAdviserNote(note));
}

export async function adviserNotesListRequest(adviserUuid: string): Promise<Response> {
    return fetchGet(`/advisers/${adviserUuid}/notes`);
}

export async function adviserRejectRequest(adviserUuid: string): Promise<Response> {
    return fetchPost(`/advisers/${adviserUuid}/reject`);
}

export async function adviserResetApprovalStatusRequest(adviserUuid: string): Promise<Response> {
    return fetchPost(`/advisers/${adviserUuid}/reset-approval-status`);
}

export async function adviserUpdateRequest(adviser: IAdviser): Promise<Response> {
    return fetchPut(`/advisers/${adviser.uuid}`, transformAdviser(adviser));
}
