import { Descriptions, Divider, Spin, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import WorkflowStatusEnum from '~Api/Application/WorkflowStatusEnum';
import BrokerTypeEnum from '~Api/Broker/BrokerTypeEnum';
import IBroker from '~Api/Broker/IBroker';
import RegistrationSourceEnum from '~Api/Broker/RegistrationSourceEnum';
import IDeal from '~Api/Deal/IDeal';
import {
    brokerApplicationsListAction,
    brokerDealsListAction,
    brokerGetAction,
} from '~Brokers/actions';
import {
    brokerApplicationsSelector,
    brokerDealsSelector,
    brokerSelector,
} from '~Brokers/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';

const typeLabels: IDictionary<string> = {
    [BrokerTypeEnum.AustralianCreditLicence]: 'Australian Credit Licence',
    [BrokerTypeEnum.AuthorisedCreditRepresentative]: 'Authorised Credit Representative',
};

const registrationSourceLabels: IDictionary<string> = {
    [RegistrationSourceEnum.Advisor]: 'Advisor',
    [RegistrationSourceEnum.AggregatorBdm]: 'Aggregator BDM',
    [RegistrationSourceEnum.AustralianBroker]: 'Australian Broker',
    [RegistrationSourceEnum.FinanceAndCoffee]: 'Finance & Coffee',
    [RegistrationSourceEnum.FundingBdm]: 'Funding BDM',
    [RegistrationSourceEnum.Google]: 'Google',
    [RegistrationSourceEnum.IndustryEvent]: 'Industry Event',
    [RegistrationSourceEnum.LunchAndLearn]: 'Lunch & Learn',
    [RegistrationSourceEnum.MortgageBusiness]: 'Mortgage Business',
    [RegistrationSourceEnum.Mpa]: 'MPA',
    [RegistrationSourceEnum.PdDay]: 'PD Day',
    [RegistrationSourceEnum.Webinar]: 'Webinar',
};

interface IMatch {
    brokerUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    applications: IDictionary<IApplication>;
    broker: IBroker;
    deals: IDictionary<IDeal>;
}

interface IPropsDispatch {
    brokerApplicationsList: () => void;
    brokerGet: () => void;
    brokerDealsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Overview extends React.Component<Props> {
    public componentDidMount() {
        const { applications, broker, deals } = this.props;

        if (!broker) {
            this.props.brokerGet();
        }

        if (!applications) {
            this.props.brokerApplicationsList();
        }

        if (!deals) {
            this.props.brokerDealsList();
        }
    }

    public render(): JSX.Element {
        const { applications, broker, deals, match } = this.props;

        if (!broker || !deals || !applications) {
            return (
                <Layout brokerUuid={match.params.brokerUuid} section='overview'>
                    <Typography.Title level={2}>Overview</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const settledApplications: IApplication[] = _.filter(applications, (application: IApplication) =>  application.workflowStatus === WorkflowStatusEnum.Warehoused);

        const latestDeal: IDeal = _.reduce(deals, (a: IDeal, b: IDeal) => (a.createdTime > b.createdTime ? a : b));

        const latestSettlement: IApplication = _.reduce(settledApplications, (a: IApplication, b: IApplication) => (a.createdTime > b.createdTime ? a : b));

        const settledTotal: number = _.reduce(settledApplications, (sum: number, loopApplication: IApplication) => sum + loopApplication.loanAmount, 0);

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        return (
            <Layout brokerUuid={match.params.brokerUuid} section='overview'>
                <Typography.Title level={2}>Overview</Typography.Title>
                <Descriptions bordered={true} layout='vertical' column={3}>
                    <Descriptions.Item label='Latest Lead'>{latestDeal ? <Link to={`/leads/${latestDeal.uuid}`}> {latestDeal.firstName} {latestDeal.lastName} {latestDeal.code}</Link> : '-'}</Descriptions.Item>
                    <Descriptions.Item label='Latest Settlement'>{latestSettlement ? <Link to={`/applications/${latestSettlement.uuid}`}> {latestSettlement.formattedName} {latestSettlement.deal.code}</Link> : '-'}</Descriptions.Item>
                    <Descriptions.Item label='Settled Applications'>{settledApplications ? settledApplications.length : '-' }</Descriptions.Item>
                    <Descriptions.Item label='Latest Lead Value'>{latestDeal ?  currencyFormatter.format(latestDeal.loanAmount) : '-'}</Descriptions.Item>
                    <Descriptions.Item label='Latest Settlement Value'>{latestSettlement ?  currencyFormatter.format(latestSettlement.loanAmount) : '-'}</Descriptions.Item>
                    <Descriptions.Item label='Settled Total Value'> {settledTotal ?  currencyFormatter.format(settledTotal) : '-'} </Descriptions.Item>
                    <Descriptions.Item label='Source'>{broker.registrationSource ? registrationSourceLabels[broker.registrationSource] : '-'}</Descriptions.Item>
                </Descriptions>
                <Divider style={{ 'opacity': 0 }}/>
                <Typography.Title level={3}>Personal Details</Typography.Title>
                <Descriptions bordered={true} layout='vertical'>
                    <Descriptions.Item label='First Name'>{broker.firstName || '-'}</Descriptions.Item>
                    <Descriptions.Item label='Last Name'>{broker.lastName || '-'}</Descriptions.Item>
                    <Descriptions.Item label={typeLabels[broker.type]}>{broker.aclNumber || '-'}</Descriptions.Item>
                    <Descriptions.Item label='Has Indemnity Insurance'>{broker.hasIndemnityInsurance ? 'Yes' : 'No'}</Descriptions.Item>
                    <Descriptions.Item label='Has Been Convicted'>{broker.hasBeenConvicted ? 'Yes' : 'No'}</Descriptions.Item>
                    <Descriptions.Item label='Has Been Bankrupted'>{broker.hasBeenBankrupted ? 'Yes' : 'No'}</Descriptions.Item>
                </Descriptions>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        applications: brokerApplicationsSelector(state, ownProps.match.params.brokerUuid),
        broker: brokerSelector(state, ownProps.match.params.brokerUuid),
        deals: brokerDealsSelector(state, ownProps.match.params.brokerUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        brokerApplicationsList: () => dispatch(brokerApplicationsListAction(ownProps.match.params.brokerUuid)),
        brokerDealsList: () => dispatch(brokerDealsListAction(ownProps.match.params.brokerUuid)),
        brokerGet: () => dispatch(brokerGetAction(ownProps.match.params.brokerUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Overview);
