import { parseInvestment } from '~Api/Investment/parsers';
import IHistory from './IHistory';
import IInvestmentSaleTransfer from './IInvestmentSaleTransfer';
import { parseInvestorAccountInvestmentTransaction } from '~Api/Investor/parsers';
import IUnallocatedInvestment from './IUnallocatedInvestment';
import _ from 'lodash';

export function parseInvestmentSaleTransfer(rawInvestmentSaleTransfer: any): IInvestmentSaleTransfer {
    return {
        createdTime: rawInvestmentSaleTransfer.createdTime,
        investment: parseInvestment(rawInvestmentSaleTransfer.investment),
        investmentUuid: rawInvestmentSaleTransfer.investmentUuid,
        transactionAmountTotal: rawInvestmentSaleTransfer.transactionAmountTotal,
        transactionCount: rawInvestmentSaleTransfer.transactionCount,
        uuid: rawInvestmentSaleTransfer.uuid,
    };
}

export function parseInvestmentSaleTransferHistory(rawHistory: any): IHistory {
    return {
        createdTime: rawHistory.createdTime,
        description: rawHistory.description,
        uuid: rawHistory.uuid,
    };
}

export function parseUnallocatedInvestments(rawUnallocatedInvestments: any): IUnallocatedInvestment {
    return {
        investment: parseInvestment(rawUnallocatedInvestments.investment),
        investmentUuid: rawUnallocatedInvestments.investmentUuid,
        investorAccountInvestmentTransactions: _.keyBy(_.map(rawUnallocatedInvestments.investorAccountInvestmentTransactions, parseInvestorAccountInvestmentTransaction), 'uuid'),
    };
}