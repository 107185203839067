import { fetchPost } from '~utilities/fetch';

export async function authJokeRequest(): Promise<Response> {
    return fetch('https://icanhazdadjoke.com/', {
        headers: {
            accept: 'application/json',
        },
        method: 'GET',
    }).then((response: Response): Promise<any> => {
        return response.json().then((jsonResponse: any) => {
            if (![200].includes(response.status)) {
                return {
                    body: {
                        joke: 'Sorry, no joke today!',
                    },
                };
            }

            return {
                body: jsonResponse,
                headers: response.headers,
                status: response.status,
            };
        });
    });
}

export async function authMagicLinkCreateRequest(email: string): Promise<Response> {
    return fetchPost('/auth/magic-link', {
        email,
        type: 'USER',
    });
}

export async function authMagicLinkRedeemRequest(code: string): Promise<Response> {
    return fetchPost(`/auth/magic-link/${code}/redeem`, {
        type: 'USER',
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function authWebauthnLoginRequest(body: any): Promise<Response> {
    return fetchPost('/auth/webauthn-login', body);
}

export async function authWebauthnOptionsRequest(email: string): Promise<Response> {
    return fetchPost('/auth/webauthn-options', {
        email,
        type: 'USER',
    });
}
