enum PhoneSourceEnum {
    Bing = 'BING',
    ClientReferral = 'CLIENT_REFERRAL',
    EmailSubscriber = 'EMAIL_SUBSCRIBER',
    Facebook = 'FACEBOOK',
    FacebookAd = 'FACEBOOK_AD',
    Google = 'GOOGLE',
    GoogleAd = 'GOOGLE_AD',
    LinkedIn = 'LINKEDIN',
    Other = 'OTHER',
}

export default PhoneSourceEnum;
