import { Form, Modal, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import { applicationPropertyValuerValuationOrderedAction } from '~Applications/actions';
import DatePicker from '~UI/DatePicker';
import TimePicker from '~UI/TimePicker';
import IValuer from '~Valuers/IValuer';

interface IState {
    valuationDueDate: string;
    valuationOrderedDateTime: string;
}

interface IProps {
    applicationProperty: IApplicationProperty;
    isOpen: boolean;
    onCancel: () => void;
    valuer: IValuer;
}

interface IPropsDispatch {
    valuationOrdered: (dueDate: string, valuationOrderedDateTime: string) => void;
}

type Props = IProps & IPropsDispatch;

class ValuationOrderedModal extends React.Component<Props, IState> {
    public state: IState = {
        valuationDueDate: null,
        valuationOrderedDateTime: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeDueDate = this.onChangeDueDate.bind(this);
        this.onChangeValuationOrderedDate = this.onChangeValuationOrderedDate.bind(this);
        this.onChangeValuationOrderedTime = this.onChangeValuationOrderedTime.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen, onCancel } = this.props;
        const { valuationDueDate, valuationOrderedDateTime } = this.state;

        const valuationDueDateDayJs: Dayjs = valuationDueDate ? dayjs(valuationDueDate) : null;
        const valuationOrderedDateTimeDayJs: Dayjs = valuationOrderedDateTime ? dayjs(valuationOrderedDateTime) : null;

        return (
            <Modal
                okText='Valuation Ordered'
                onCancel={onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Valuation Ordered'
                wrapClassName='property-valuation-order-valuation-modal'
            >
                <Form.Item className='ordered-date' label='Order Date'>
                    <Space>
                        <DatePicker onChange={this.onChangeValuationOrderedDate} value={valuationOrderedDateTimeDayJs || dayjs()} format='DD/MM/YYYY' />
                        <TimePicker onChange={this.onChangeValuationOrderedTime} value={valuationOrderedDateTimeDayJs || dayjs()} format='H:mm' />
                    </Space>
                </Form.Item>
                <Form.Item className='valuation-due-date' label='Due Date'>
                    <DatePicker onChange={this.onChangeDueDate} value={valuationDueDateDayJs || dayjs()} format='DD/MM/YYYY' />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeValuationOrderedDate(date: Dayjs) {
        const { valuationOrderedDateTime } = this.state;

        this.setState({
            valuationOrderedDateTime: dayjs(`${date.format('YYYY-MM-DD')} ${dayjs(valuationOrderedDateTime || undefined).format('HH:mm')}`).format(),
        });
    }

    private onChangeValuationOrderedTime(time: Dayjs) {
        const { valuationOrderedDateTime } = this.state;

        this.setState({
            valuationOrderedDateTime: dayjs(`${dayjs(valuationOrderedDateTime || undefined).format('YYYY-MM-DD')} ${time.format('HH:mm')}`).format(),
        });
    }

    private onChangeDueDate(date: Dayjs) {
        const { valuationDueDate } = this.state;

        this.setState({
            valuationDueDate: dayjs(`${date.format('YYYY-MM-DD')} ${dayjs(valuationDueDate || undefined).format('HH:mm')}`).format(),
        });
    }

    private onClickOk() {
        const { valuationDueDate, valuationOrderedDateTime } = this.state;

        this.props.valuationOrdered(valuationDueDate, valuationOrderedDateTime);
        this.props.onCancel();
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        valuationOrdered: (dueDate: string, valuationOrderedDateTime: string) => dispatch(applicationPropertyValuerValuationOrderedAction(ownProps.applicationProperty.uuid, ownProps.valuer.uuid, dueDate, valuationOrderedDateTime)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(ValuationOrderedModal);
