import { UserOutlined, WarningOutlined } from '@ant-design/icons';
import { Alert, Layout as AntLayout, Menu, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Snowfall from 'react-snowfall';
import { Dispatch } from 'redux';
import {
    administratorFidoAction,
    administratorsListAction,
} from '~Administrators/actions';
import { currentAdministratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import { authLogoutAction } from '~Auth/actions';
import IAuthUser from '~Auth/IAuthUser';
import RolesEnum from '~Auth/RolesEnum';
import { authCurrentUserSelector } from '~Auth/selectors';
import Logo from '~UI/Logo';
import Santa from '~UI/Santa';
import './layout.less';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

export default function Layout(props: {
    children: ReactElement;
    topSection?: string;
}): ReactElement {
    const { children, topSection = '' } = props;

    const currentAdministrator: IAdministrator = useSelector(currentAdministratorSelector);
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (currentUser.role === RolesEnum.Authenticated && !currentAdministrator) {
            dispatch(administratorsListAction());
        }
    }, [
        currentAdministrator,
        currentUser.role,
        dispatch,
    ]);

    const onClickFido: () => void = useCallback(() => {
        dispatch(administratorFidoAction(currentAdministrator.uuid));
    }, [
        currentAdministrator,
        dispatch,
    ]);

    if (currentUser.role === RolesEnum.Authenticated && !currentAdministrator) {
        return <Spin/>;
    }

    const menuItems: ItemType[] = [
        ...(currentUser.permissions.includes(PermissionsEnum.Brokers) ? [
            {
                key: 'brokers',
                label: <NavLink to='/brokers'>Brokers</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.Leads) ? [
            {
                children: [
                    {
                        key: 'leads-list',
                        label: <NavLink to='/leads'>Leads List</NavLink>,
                    },
                    {
                        key: 'leads-scenario-calculator',
                        label: <NavLink to='/leads/scenario-calculator'>Scenario Calculator</NavLink>,
                    },
                    ...((currentUser.permissions.includes(PermissionsEnum.DealQuoteApproval)) ? [
                        {
                            key: 'leads-pending-scenarios',
                            label: <NavLink to='/leads/pending-scenarios'>Pending Scenarios</NavLink>,
                        },
                    ] : []),
                    ...(currentUser.permissions.includes(PermissionsEnum.ReferralPartners) ? [
                        {
                            key: 'leads-referral-partners',
                            label: <NavLink to='/referral-partners'>Referral Partners</NavLink>,
                        },
                    ] : []),
                    ...(currentUser.permissions.includes(PermissionsEnum.LeadsOriginationReport) ? [
                        {
                            key: 'leads-origination-report',
                            label: <NavLink to='/leads/origination-report'>Origination Report</NavLink>,
                        },
                    ] : []),
                    ...(currentUser.permissions.includes(PermissionsEnum.LeadsMovementReport) ? [
                        {
                            key: 'leads-movement-report',
                            label: <NavLink to='/leads/movement-report'>Movement Report</NavLink>,
                        },
                    ] : []),
                ],
                key: 'leads',
                label: <NavLink to='/leads'>Leads</NavLink>,
                popupClassName: 'leads-menu',
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.Applications) ? [
            {
                children: [
                    {
                        key: 'applications-list',
                        label: <NavLink to='/applications'>Applications List</NavLink>,
                    },
                    {
                        key: 'applications-settlement-forecast',
                        label: <NavLink to='/applications/settlement-forecast'>Settlement Forecast</NavLink>,
                    },
                    {
                        key: 'applications-expiring-valuations',
                        label: <NavLink to='/applications/expiring-valuations'>Expiring Valuations</NavLink>,
                    },
                    {
                        key: 'applications-outstanding-valuations',
                        label: <NavLink to='/applications/outstanding-valuations'>Outstanding Valuations</NavLink>,
                    },
                    ...(currentUser.permissions.includes(PermissionsEnum.ApplicationsSettlementReport) ? [
                        {
                            key: 'applications-settlement-report',
                            label: <NavLink to='/applications/settlement-report'>New Settlement Report</NavLink>,
                        },
                    ] : []),
                    ...(currentUser.permissions.includes(PermissionsEnum.Accounts) ? [
                        {
                            key: 'applications-outstanding-broker-commissions',
                            label: <NavLink to='/applications/outstanding-broker-commissions'>Outstanding Broker Commissions</NavLink>,
                        },
                        {
                            key: 'applications-unpaid-valuations',
                            label: <NavLink to='/applications/unpaid-valuations'>Unpaid Valuations</NavLink>,
                        },
                    ] : []),
                ],
                key: 'applications',
                label: <NavLink to='/applications'>Applications</NavLink>,
                popupClassName: 'applications-menu',
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.Loans) ? [
            {
                children: [
                    {
                        key: 'loans-list',
                        label: <NavLink to='/loans'>Loan List</NavLink>,
                    },
                    {
                        key: 'loans-due',
                        label: <NavLink to='/loans/due'>Due Loans</NavLink>,
                    },
                    {
                        key: 'loans-maturation-reminders',
                        label: <NavLink to='/loans/maturation-reminders'>Maturation Reminders</NavLink>,
                    },
                    {
                        key: 'loans-expiring-insurance',
                        label: <NavLink to='/loans/expiring-insurance'>Expiring Insurance</NavLink>,
                    },
                    {
                        key: 'loans-discharge-forecast',
                        label: <NavLink to='/loans/discharge-forecast'>Discharge Forecast</NavLink>,
                    },
                    {
                        key: 'loans-drawdowns',
                        label: <NavLink to='/loans/drawdowns'>Drawdowns</NavLink>,
                    },
                    ...(currentUser.permissions.includes(PermissionsEnum.Warehouses) ? [
                        {
                            children: [
                                {
                                    key: 'warehouses-list',
                                    label: <NavLink to='/warehouses'>Warehouses List</NavLink>,
                                },
                                ...(currentUser.permissions.includes(PermissionsEnum.WarehouseLoanSell) ? [
                                    {
                                        key: 'warehouses-proposed-sales',
                                        label: <NavLink to='/warehouses/proposed-sales'>Proposed Warehouse Sales</NavLink>,
                                    },
                                ] : []),
                                ...(currentUser.permissions.includes(PermissionsEnum.WarehousesPortfolioDashboard) ? [
                                    {
                                        key: 'warehouses-portfolio-dashboard',
                                        label: <NavLink to='/warehouses/portfolio-dashboard'>Portfolio Dashboard</NavLink>,
                                    },
                                ] : []),
                                {
                                    key: 'warehouses-loan-book-forecast',
                                    label: <NavLink to='/warehouses/loan-book-forecast'>Loan Book Forecast</NavLink>,
                                },
                            ],
                            key: 'warehouses',
                            label: <NavLink to='/warehouses'>Warehouses</NavLink>,
                            popupClassName: 'warehouses-menu',
                        },
                    ] : []),
                ],
                key: 'loans',
                label: <NavLink to='/loans'>Loans</NavLink>,
                popupClassName: 'loans-menu',
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.Investments) ? [
            {
                children: [
                    {
                        key: 'investments-list',
                        label: <NavLink to='/investments'>Marketplace</NavLink>,
                    },
                    ...(currentUser.permissions.includes(PermissionsEnum.InvestmentPendingDistributions) ? [
                        {
                            key: 'investments-pending-distributions',
                            label: <NavLink to='/investments/pending-distributions'>Pending Distributions</NavLink>,
                        },
                    ] : []),
                    {
                        key: 'investments-income-trust',
                        label: <NavLink to='/investments/income-trust'>Income Trust</NavLink>,
                    },
                ],
                key: 'investments',
                label: <NavLink to='/investments'>Investments</NavLink>,
                popupClassName: 'investments-menu',
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.Investors) ? [
            {
                children: [
                    {
                        key: 'investors-list',
                        label: <NavLink to='/investors'>Investor List</NavLink>,
                    },
                    {
                        key: 'investors-managed',
                        label: <NavLink to='/investors/managed'>Managed Investors</NavLink>,
                    },
                    {
                        key: 'investors-pending-approval',
                        label: <NavLink to='/investors/pending-approval'>Pending Approval</NavLink>,
                    },
                    ...(currentUser.permissions.includes(PermissionsEnum.InvestorPendingDeposits) ? [
                        {
                            key: 'investors-pending-deposits',
                            label: <NavLink to='/investors/pending-deposits'>Pending Deposits</NavLink>,
                        },
                    ] : []),
                    ...(currentUser.permissions.includes(PermissionsEnum.InvestorPendingWithdrawals) ? [
                        {
                            key: 'investors-pending-withdrawals',
                            label: <NavLink to='/investors/pending-withdrawals'>Pending Withdrawals</NavLink>,
                        },
                    ] : []),
                    {
                        key: 'investors-referrals',
                        label: <NavLink to='/investors/referrals'>Referrals</NavLink>,
                    },
                    ...(currentUser.permissions.includes(PermissionsEnum.WithdrawalAbas) ? [
                        {
                            key: 'investors-withdrawal-abas',
                            label: <NavLink to='/investors/withdrawal-abas'>Withdrawal ABAs</NavLink>,
                        },
                    ] : []),
                    ...(currentUser.permissions.includes(PermissionsEnum.Advisers) ? [
                        {
                            key: 'investors-advisers',
                            label: <NavLink to='/advisers'>Advisers</NavLink>,
                        },
                    ] : []),
                ],
                key: 'investors',
                label: <NavLink to='/investors'>Investors</NavLink>,
                popupClassName: 'investors-menu',
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.Reports) ? [
            {
                key: 'reports',
                label: <NavLink to='/reports'>Reports</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.Users) ? (currentUser.permissions.includes(PermissionsEnum.Administrators) ? [
            {
                children: [
                    {
                        key: 'users-list',
                        label: <NavLink to='/users'>User List</NavLink>,
                    },
                    {
                        key: 'users-administrators',
                        label: <NavLink to='/administrators'>Administrators</NavLink>,
                    },
                    ...(currentUser.permissions.includes(PermissionsEnum.UserBlock) ? [
                        {
                            key: 'users-suspicious-activity',
                            label: <NavLink to='/users/suspicious-activity'>Suspicious Activity</NavLink>,
                        },
                    ] : []),
                ],
                key: 'users',
                label: <NavLink to='/users'>Users</NavLink>,
                popupClassName: 'users-menu',
            },
        ] : [
            {
                key: 'users',
                label: <NavLink to='/users'>Users</NavLink>,
            },
        ]) : []),
        ...(currentUser.permissions.includes(PermissionsEnum.Accounts) ? [
            {
                children: [
                    {
                        key: 'accounts/reports',
                        label: <NavLink to='/accounts'>Reports</NavLink>,
                    },
                    {
                        key: 'accounts/investment-sale-transfers',
                        label: <NavLink to='/investment-sale-transfers'>Investment Sale Transfers</NavLink>,
                    },
                ],
                key: 'accounts',
                label: <NavLink to='/accounts'>Accounts</NavLink>,
                popupClassName: 'accounts-menu',
            },
        ] : []),
    ];

    const topMenu: ReactElement = currentUser.role === RolesEnum.Authenticated && (
        <Menu
            className='top-menu'
            defaultSelectedKeys={[topSection]}
            items={menuItems}
            mode='horizontal'
            theme='dark'
        />
    );

    const grinch: string = localStorage.getItem('grinch');

    let santa: JSX.Element = null;
    let snowfall: JSX.Element = null;

    const now: Dayjs = dayjs();
    const month: number = Number(now.format('M'));
    const day: number = Number(now.format('D'));

    if (month === 12 && day <= 25) {
        if (!grinch) {
            snowfall = (
                <Snowfall
                    speed={[0.5, 1.5]}
                    snowflakeCount={day * 5}
                />
            );
        }

        santa = <div className='santa'><Santa/></div>;
    }

    const loginMenuItems: ItemType[] = [
        {
            children: [
                {
                    key: 'user-fido',
                    label: 'Register Passkey',
                    onClick: onClickFido,
                },
                ...(!!snowfall ? [
                    {
                        key: 'user-grinch',
                        label: 'I\'m a Grinch',
                        onClick: () => {
                            localStorage.setItem('grinch', 'boo');
                            document.location.reload();
                        },
                    },
                ] : []),
                {
                    key: 'user-logout',
                    label: 'Logout',
                    onClick: () => {
                        dispatch(authLogoutAction());
                    },
                },
            ],
            className: 'user-menu-item',
            key: 'login',
            label: <UserOutlined/>,
            popupClassName: 'user-menu',
        },
    ];

    const loginMenu: JSX.Element = currentUser.role === RolesEnum.Authenticated && (
        <Menu
            className='login-menu'
            defaultSelectedKeys={[topSection]}
            items={loginMenuItems}
            mode='horizontal'
            theme='dark'
        />
    );

    const passKeyAlert: ReactElement = currentAdministrator && 0 === currentAdministrator.activePasskeyCount && (
        <Alert
            className='passkey-not-enabled'
            message={<><WarningOutlined/> You do not have an active Passkey, for maximum security please <a onClick={onClickFido}>register a Passkey</a> now <WarningOutlined/></>}
            type='error'
        />
    );

    return (
        <AntLayout className='layout'>
            <AntLayout.Header className='header'>
                {santa}
                <div className='logo'><Logo/></div>
                {topMenu}
                {loginMenu}
            </AntLayout.Header>
            <AntLayout.Content className='body'>
                {passKeyAlert}
                {children}
            </AntLayout.Content>
            {snowfall}
        </AntLayout>
    );
}
