enum WorkflowStatusEnum {
    Active = 'ACTIVE',
    Discharge = 'DISCHARGE',
    Drawdown = 'DRAWDOWN',
    Extension = 'EXTENSION',
    GracePeriod = 'GRACE_PERIOD',
    Recovery = 'RECOVERY',
}

export default WorkflowStatusEnum;
