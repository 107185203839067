import { fetchGet } from '~utilities/fetch';

export async function investmentTransactionsRecentRequest(): Promise<Response> {
    return fetchGet('/investment-transactions/recent');
}

export async function investorAccountInvestmentTransactionsRecentInvestmentsRequest(): Promise<Response> {
    return fetchGet('/investor-account-investment-transactions/recent-investments');
}

export async function investorAccountTransactionsRecentTransfersRequest(): Promise<Response> {
    return fetchGet('/investor-account-transactions/recent-transfers');
}

export async function loanTransactionsRecentPaymentsRequest(): Promise<Response> {
    return fetchGet('/loan-transactions/recent-payments');
}

export async function loanTransactionWarehouseTransactionsRequest(loanTransactionUuid: string): Promise<Response> {
    return fetchGet(`/loan-transactions/${loanTransactionUuid}/warehouse-transactions`);
}

export async function warehouseLoanTransactionsRecentPrincipalRequest(): Promise<Response> {
    return fetchGet('/warehouse-loan-transactions/recent-principal');
}
