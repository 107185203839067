import { Checkbox, Form, Modal, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IDeal from '~Api/Deal/IDeal';
import ReferredToEnum from '~Api/Deal/ReferredToEnum';
import { leadReferAction } from '~Leads/actions';

interface IState {
    referredTo: ReferredToEnum;
    sendEmail: boolean;
}

interface IProps {
    isOpen: boolean;
    deal: IDeal;
    onCancel: () => void;
}

interface IPropsDispatch {
    refer: (referredTo: ReferredToEnum, sendEmail: boolean) => void;
}

type Props = IProps & IPropsDispatch;

class ReferModal extends React.Component<Props, IState> {
    public state: IState = {
        referredTo: null,
        sendEmail: true,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeReferral = this.onChangeReferral.bind(this);
        this.onChangeSendEmail = this.onChangeSendEmail.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { referredTo, sendEmail } = this.state;

        const sendEmailBlock: JSX.Element = [ReferredToEnum.Bizcap, ReferredToEnum.TLC].includes(referredTo) && (
            <Form.Item className='send-email' label='Send Customer Email'>
                <Checkbox checked={sendEmail} onChange={this.onChangeSendEmail} />
            </Form.Item>
        );

        return (
            <Modal
                okText='Refer'
                okType='danger'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Refer'
                wrapClassName='lead-refer-modal'
            >
                <Form.Item className='referred-to' label='Refer to'>
                    <Select onChange={this.onChangeReferral} value={referredTo}>
                        <Select.Option value={ReferredToEnum.Bizcap}>Bizcap</Select.Option>
                        <Select.Option value={ReferredToEnum.DirectCredit}>Direct Credit</Select.Option>
                        <Select.Option value={ReferredToEnum.TLC}>TLC</Select.Option>
                    </Select>
                </Form.Item>
                {sendEmailBlock}
            </Modal >
        );
    }

    private onChangeReferral(value: ReferredToEnum): void {
        this.setState({
            referredTo: value,
        });
    }

    private onChangeSendEmail(event: CheckboxChangeEvent): void {
        this.setState({
            sendEmail: event.target.checked,
        });
    }

    private onClickOk(): void {
        const { referredTo, sendEmail } = this.state;

        this.props.onCancel();

        this.props.refer(referredTo, [ReferredToEnum.Bizcap, ReferredToEnum.TLC].includes(referredTo) ? sendEmail : false);
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        refer: (referredTo: ReferredToEnum, sendEmail: boolean) => dispatch(leadReferAction(ownProps.deal.uuid, referredTo, sendEmail)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(ReferModal);
