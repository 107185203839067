import React from 'react';
import {
    Route,
    Switch,
} from 'react-router-dom';
import History from './History';
import Transactions from './Transactions';

export default function router(): JSX.Element {
    return (
        <Switch>
            <Route exact={true} path='/investment-sale-transfers/:investmentSaleTransferUuid/history' component={History} />
            <Route exact={true} path='/investment-sale-transfers/:investmentSaleTransferUuid/transactions' component={Transactions} />
        </Switch>
    );
}
