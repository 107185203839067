import _ from 'lodash';
import roundTo from 'round-to';
import IAggregator from '~Api/Aggregator/IAggregator';
import InvoiceTypeEnum from '~Api/Aggregator/InvoiceTypeEnum';
import BorrowerTypeEnum from '~Api/Application/BorrowerTypeEnum';
import ConditionDocumentApprovalStatusEnum from '~Api/Application/ConditionDocumentApprovalStatusEnum';
import ConditionTypeEnum from '~Api/Application/ConditionTypeEnum';
import ExtensionTypeEnum from '~Api/Application/ExtensionTypeEnum';
import IApplication from '~Api/Application/IApplication';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IBorrower from '~Api/Application/IBorrower';
import ICondition from '~Api/Application/ICondition';
import IConditionDocument from '~Api/Application/IConditionDocument';
import IncomeTypeEnum from '~Api/Application/IncomeTypeEnum';
import LoanPurposeEnum from '~Api/Application/LoanPurposeEnum';
import MortgageTypeEnum from '~Api/Application/MortgageTypeEnum';
import RepaymentStrategyEnum from '~Api/Application/RepaymentStrategyEnum';
import WorkflowStatusEnum from '~Api/Application/WorkflowStatusEnum';
import IProperty from '~Api/Deal/IProperty';
import PropertyPurposeEnum from '~Api/Deal/PropertyPurposeEnum';
import StrataTypeEnum from '~Api/Deal/StrataTypeEnum';
import TenancyTypeEnum from '~Api/Deal/TenancyTypeEnum';
import { propertyRequiresInsurance } from '~Deals/utilities';
import constants from '~constants';
import { IDictionary } from '~utilities/IDictionary';

export const conditionLabels: IDictionary<string> = {
    [ConditionTypeEnum.AdverseCreditStatement]: 'Adverse Credit Record Explanation Statement',
    [ConditionTypeEnum.AgentAgreement]: 'Agent Agreement and Details',
    [ConditionTypeEnum.AssetLiabilityStatement]: 'Statement of Assets and Liabilities',
    [ConditionTypeEnum.BankruptcyRegisterSearch]: 'Bankruptcy Register Search Report',
    [ConditionTypeEnum.BankSolicitorAccountantDetails]: 'Bank, Solicitor and Accountant Details',
    [ConditionTypeEnum.BankStatementsThreeMonths]: 'Last 3 Months Personal Bank Statements',
    [ConditionTypeEnum.BankStatementsSixMonths]: 'Last 6 Months Bank Statements',
    [ConditionTypeEnum.BrokerInvoice]: 'Broker Invoice',
    [ConditionTypeEnum.BuilderCapabilityStatement]: 'Builders and Developers Capability Statements',
    [ConditionTypeEnum.BuildersInsurance]: 'Builders Personal Indemnity Insurance',
    [ConditionTypeEnum.BuildingContractPlans]: 'Building Contract & Plans',
    [ConditionTypeEnum.BuildingPermitApproval]: 'Building Permit/Approval',
    [ConditionTypeEnum.BuildingReplacementInsurance]: 'Replacement Insurance Noting Lender',
    [ConditionTypeEnum.BusinessActivityStatements]: 'Last 2 Business Activity Statements (BAS)',
    [ConditionTypeEnum.BusinessPurposeDeclaration]: 'Signed Business Loan Purpose Declaration',
    [ConditionTypeEnum.CompanyInDepthTradingHistory]: 'In-depth Company Trading History Report',
    [ConditionTypeEnum.CompletedLoanApplication]: 'Loan Application',
    [ConditionTypeEnum.ConsumerPurposeDeclaration]: 'Signed Consumer Loan Purpose Declaration',
    [ConditionTypeEnum.ContractOfSale]: 'Complete Contract of Sale',
    [ConditionTypeEnum.CouncilWaterRatesNotice]: 'Most Recent Council Water/Rates Notice',
    [ConditionTypeEnum.CreditFileSearchReport]: 'Credit File Search Report',
    [ConditionTypeEnum.CurrentTitleSearch]: 'Current Title Search',
    [ConditionTypeEnum.DebtPayoutAmount]: 'Payout Amounts for all Debts to Refinanced / Consolidated',
    [ConditionTypeEnum.EvidenceAdditionalFunding]: 'Evidence of Additional Funds Required to Complete Construction',
    [ConditionTypeEnum.EvidenceCommitmentFeePaid]: 'Evidence of Commitment Fee Paid',
    [ConditionTypeEnum.EvidenceLeviesPaid]: 'Evidence Rates, Water, Strata/Body Corporate Levies Paid and up to Date',
    [ConditionTypeEnum.EvidencePurchaseDeposit]: 'Evidence of Deposit and Sufficient Funds to Complete',
    [ConditionTypeEnum.ExitStrategyLetter]: 'Exit Strategy Letter and Supporting Documentation',
    [ConditionTypeEnum.FeasibilityStudyTable]: 'Development Feasibility Study Table',
    [ConditionTypeEnum.FirbApprovalLetter]: 'FIRB Approval Letter',
    [ConditionTypeEnum.GeneralSecurityAgreement]: 'General Security Agreement',
    [ConditionTypeEnum.IdentificationPoints]: '100 Points of Identification',
    [ConditionTypeEnum.IdentityVerification]: 'Identity Verification Search Report',
    [ConditionTypeEnum.LeaseAgreement]: 'Lease Agreement',
    [ConditionTypeEnum.ListImprovementsEstimates]: 'List of Property Improvements and Estimates',
    [ConditionTypeEnum.LoanPurposeDeclaration]: 'Signed Loan Purpose Declaration',
    [ConditionTypeEnum.Memo]: 'Memo',
    [ConditionTypeEnum.MonthlyBudget]: 'Monthly Income and Expenses',
    [ConditionTypeEnum.MortgageStatements]: 'Last 6 months Mortgage Statements',
    [ConditionTypeEnum.NominationContractVariation]: 'Signed Nomination, Contract Variation and/or Transfer Form',
    [ConditionTypeEnum.PaygPayslips]: 'Last 2 pay slips',
    [ConditionTypeEnum.PlanningDevelopmentPermit]: 'Planning Permit/Development Approval',
    [ConditionTypeEnum.PlanSearch]: 'Plan Search',
    [ConditionTypeEnum.PreSalesContracts]: 'Pre-sales Contracts',
    [ConditionTypeEnum.PriceFinderReport]: 'Price Finder Report',
    [ConditionTypeEnum.PrivacyConsentForm]: 'Signed Privacy and Consent Form',
    [ConditionTypeEnum.ProfitLossStatement]: 'Profit and Loss Statement',
    [ConditionTypeEnum.ProofUseOfFunds]: 'Proof of Use of Funds',
    [ConditionTypeEnum.PropertyPhoto]: 'Property Photos',
    [ConditionTypeEnum.PropertyProfileReport]: 'Property Profile Report',
    [ConditionTypeEnum.PurchaseContract]: 'Copy of complete purchase contract',
    [ConditionTypeEnum.RecordOfInterview]: 'Record of Interview',
    [ConditionTypeEnum.SecondMortgageAuthority]: 'Second Mortgage Authority',
    [ConditionTypeEnum.SelfEmployedDeclaration]: 'Self Employed Accountants Declaration of Income',
    [ConditionTypeEnum.SignedConditionalApproval]: 'Signed Conditional Approval',
    [ConditionTypeEnum.SignedLoanDocuments]: 'Signed Loan Documents',
    [ConditionTypeEnum.SolicitorsCertification]: 'Solicitors Certification',
    [ConditionTypeEnum.SolicitorsDisbursements]: 'Solicitors Disbursements',
    [ConditionTypeEnum.StrataTitleNotice]: 'Body Corporate / Strata Title Notice',
    [ConditionTypeEnum.TrustDeed]: 'Trust Deed / Deed Variations',
    [ConditionTypeEnum.UnsignedLoanDocuments]: 'Unsigned Loan Documents',
    [ConditionTypeEnum.Valuation]: 'Valuation',
};

export function getRequiredConditionTypes(application: IApplication, status: WorkflowStatusEnum, aggregators: IDictionary<IAggregator>): ConditionTypeEnum[] {
    const isRenewal: boolean = ExtensionTypeEnum.Renewal === application.extensionType;

    const conditionTypes: ConditionTypeEnum[] = [];

    switch (status) {
        case WorkflowStatusEnum.Warehoused:
            if (!isRenewal) {
                conditionTypes.push(ConditionTypeEnum.StrataTitleNotice);
                conditionTypes.push(ConditionTypeEnum.SignedLoanDocuments);
                conditionTypes.push(ConditionTypeEnum.SolicitorsCertification);
                conditionTypes.push(ConditionTypeEnum.SolicitorsDisbursements);
                conditionTypes.push(ConditionTypeEnum.UnsignedLoanDocuments);
            }
        // eslint-disable-next-line no-fallthrough
        case WorkflowStatusEnum.Settlement:
        // eslint-disable-next-line no-fallthrough
        case WorkflowStatusEnum.LegalDocuments:
            if (!isRenewal) {
                conditionTypes.push(ConditionTypeEnum.RecordOfInterview);
            }
        // eslint-disable-next-line no-fallthrough
        case WorkflowStatusEnum.FormalApproval:
            if (!isRenewal) {
                conditionTypes.push(ConditionTypeEnum.AdverseCreditStatement);
                conditionTypes.push(ConditionTypeEnum.BankruptcyRegisterSearch);
                if (application.deal.broker && application.deal.broker.aggregatorUuid && aggregators[application.deal.broker.aggregatorUuid].invoiceType !== InvoiceTypeEnum.DIRECT) {
                    conditionTypes.push(ConditionTypeEnum.BrokerInvoice);
                }
                conditionTypes.push(ConditionTypeEnum.CompanyInDepthTradingHistory);
                conditionTypes.push(ConditionTypeEnum.CompletedLoanApplication);
                conditionTypes.push(ConditionTypeEnum.CreditFileSearchReport);
                conditionTypes.push(ConditionTypeEnum.CurrentTitleSearch);
                conditionTypes.push(ConditionTypeEnum.IdentificationPoints);
                conditionTypes.push(ConditionTypeEnum.IdentityVerification);
                conditionTypes.push(ConditionTypeEnum.Memo);
                conditionTypes.push(ConditionTypeEnum.TrustDeed);
                conditionTypes.push(ConditionTypeEnum.LeaseAgreement);
            }
            conditionTypes.push(ConditionTypeEnum.Valuation);
        // eslint-disable-next-line no-fallthrough
        case WorkflowStatusEnum.Underwriting:
            if (!isRenewal) {
                conditionTypes.push(ConditionTypeEnum.EvidenceCommitmentFeePaid);
            }
    }

    return conditionTypes;
}

export function approvedApplicationConditionDocumentExists(conditionDocuments: IConditionDocument[], application: IApplication, conditionType: ConditionTypeEnum): boolean {
    return _.some(conditionDocuments, (conditionDocument: IConditionDocument) => (
        conditionDocument.applicationUuid === application.uuid
        && conditionDocument.conditionType === conditionType
        && [ConditionDocumentApprovalStatusEnum.Approved, ConditionDocumentApprovalStatusEnum.Legacy].includes(conditionDocument.approvalStatus)
    ));
}

export function approvedBorrowerConditionDocumentExists(conditionDocuments: IConditionDocument[], borrower: IBorrower, conditionType: ConditionTypeEnum): boolean {
    return _.some(conditionDocuments, (conditionDocument: IConditionDocument) => (
        conditionDocument.borrowerUuid === borrower.uuid
        && conditionDocument.conditionType === conditionType
        && [ConditionDocumentApprovalStatusEnum.Approved, ConditionDocumentApprovalStatusEnum.Legacy].includes(conditionDocument.approvalStatus)
    ));
}

export function approvedPropertyConditionDocumentExists(conditionDocuments: IConditionDocument[], applicationProperty: IApplicationProperty, conditionType: ConditionTypeEnum): boolean {
    return _.some(conditionDocuments, (conditionDocument: IConditionDocument) => (
        (conditionDocument.applicationPropertyUuid === applicationProperty.uuid || conditionDocument.dealPropertyUuid === applicationProperty.dealPropertyUuid)
        && conditionDocument.conditionType === conditionType
        && [ConditionDocumentApprovalStatusEnum.Approved, ConditionDocumentApprovalStatusEnum.Legacy].includes(conditionDocument.approvalStatus)
    ));
}

export function approvedCustomConditionDocumentExists(conditionDocuments: IConditionDocument[], condition: ICondition): boolean {
    return _.some(conditionDocuments, (conditionDocument: IConditionDocument) => (
        conditionDocument.conditionUuid === condition.uuid
        && [ConditionDocumentApprovalStatusEnum.Approved, ConditionDocumentApprovalStatusEnum.Legacy].includes(conditionDocument.approvalStatus)
    ));
}

export function filterApplicationConditionDocuments(conditionDocuments: IConditionDocument[], application: IApplication, conditionType: ConditionTypeEnum): IConditionDocument[] {
    return _.filter(conditionDocuments, (conditionDocument: IConditionDocument) => (
        conditionDocument.applicationUuid === application.uuid
        && conditionDocument.conditionType === conditionType
    ));
}

export function filterBorrowerConditionDocuments(conditionDocuments: IConditionDocument[], borrower: IBorrower, conditionType: ConditionTypeEnum): IConditionDocument[] {
    return _.filter(conditionDocuments, (conditionDocument: IConditionDocument) => (
        conditionDocument.borrowerUuid === borrower.uuid
        && conditionDocument.conditionType === conditionType
    ));
}

export function filterPropertyConditionDocuments(conditionDocuments: IConditionDocument[], applicationProperty: IApplicationProperty, conditionType: ConditionTypeEnum): IConditionDocument[] {
    return _.filter(conditionDocuments, (conditionDocument: IConditionDocument) => (
        (conditionDocument.applicationPropertyUuid === applicationProperty.uuid || conditionDocument.dealPropertyUuid === applicationProperty.dealPropertyUuid)
        && conditionDocument.conditionType === conditionType
    ));
}

export function filterCustomConditionDocuments(conditionDocuments: IConditionDocument[], condition: ICondition): IConditionDocument[] {
    return _.filter(conditionDocuments, (conditionDocument: IConditionDocument) => (
        conditionDocument.conditionUuid === condition.uuid
    ));
}

export function getApplicationConditions(application: IApplication): ConditionTypeEnum[] {
    const conditionTypes: ConditionTypeEnum[] = [];

    conditionTypes.push(ConditionTypeEnum.CompletedLoanApplication);
    conditionTypes.push(ConditionTypeEnum.SignedConditionalApproval);
    conditionTypes.push(ConditionTypeEnum.ExitStrategyLetter);
    conditionTypes.push(ConditionTypeEnum.PrivacyConsentForm);
    conditionTypes.push(ConditionTypeEnum.BankSolicitorAccountantDetails);
    conditionTypes.push(ConditionTypeEnum.LoanPurposeDeclaration);

    switch (application.loanPurpose) {
        case LoanPurposeEnum.BridgingLoan:
            conditionTypes.push(ConditionTypeEnum.ConsumerPurposeDeclaration);
            break;

        case LoanPurposeEnum.BusinessLoan:
            conditionTypes.push(ConditionTypeEnum.BusinessPurposeDeclaration);
            conditionTypes.push(ConditionTypeEnum.ProofUseOfFunds);
            break;

        case LoanPurposeEnum.DebtConsolidation:
            conditionTypes.push(ConditionTypeEnum.ConsumerPurposeDeclaration);
            conditionTypes.push(ConditionTypeEnum.DebtPayoutAmount);
            conditionTypes.push(ConditionTypeEnum.ProofUseOfFunds);
            break;

        case LoanPurposeEnum.PersonalLoan:
            conditionTypes.push(ConditionTypeEnum.ConsumerPurposeDeclaration);
            conditionTypes.push(ConditionTypeEnum.ProofUseOfFunds);
            break;

        case LoanPurposeEnum.PurchaseNew:
            conditionTypes.push(ConditionTypeEnum.ConsumerPurposeDeclaration);
            break;

        case LoanPurposeEnum.Refinance:
            conditionTypes.push(ConditionTypeEnum.ConsumerPurposeDeclaration);
            conditionTypes.push(ConditionTypeEnum.ProofUseOfFunds);
            break;

        case LoanPurposeEnum.RenovateOrBuild:
            conditionTypes.push(ConditionTypeEnum.ConsumerPurposeDeclaration);
            conditionTypes.push(ConditionTypeEnum.BuilderCapabilityStatement);
            conditionTypes.push(ConditionTypeEnum.EvidenceAdditionalFunding);
            conditionTypes.push(ConditionTypeEnum.PreSalesContracts);
            conditionTypes.push(ConditionTypeEnum.FirbApprovalLetter);
            break;
    }

    if (application.commitmentFee && application.commitmentFee > 0) {
        conditionTypes.push(ConditionTypeEnum.EvidenceCommitmentFeePaid);
    }

    if ([RepaymentStrategyEnum.BusinessSaleSold, RepaymentStrategyEnum.PropertySaleSold].includes(application.repaymentStrategy)) {
        conditionTypes.push(ConditionTypeEnum.AgentAgreement);
        conditionTypes.push(ConditionTypeEnum.ContractOfSale);
    }

    if (application.deal.brokerUuid || application.deal.isBroker || application.brokerageFee) {
        conditionTypes.push(ConditionTypeEnum.BrokerInvoice);
    }

    conditionTypes.push(ConditionTypeEnum.Memo);
    conditionTypes.push(ConditionTypeEnum.RecordOfInterview);
    conditionTypes.push(ConditionTypeEnum.UnsignedLoanDocuments);
    conditionTypes.push(ConditionTypeEnum.SignedLoanDocuments);
    conditionTypes.push(ConditionTypeEnum.SolicitorsCertification);
    conditionTypes.push(ConditionTypeEnum.SolicitorsDisbursements);

    return conditionTypes;
}

// Application is here to standardise params and we will need it when breaking down extension conditions
export function getBorrowerConditions(application: IApplication, applicationBorrower: IBorrower): ConditionTypeEnum[] {
    const conditionTypes: ConditionTypeEnum[] = [];

    switch (applicationBorrower.dealBorrower.type) {
        case BorrowerTypeEnum.Individual:
            conditionTypes.push(ConditionTypeEnum.IdentificationPoints);
            conditionTypes.push(ConditionTypeEnum.IdentityVerification);
            conditionTypes.push(ConditionTypeEnum.MonthlyBudget);
            conditionTypes.push(ConditionTypeEnum.AssetLiabilityStatement);
            conditionTypes.push(ConditionTypeEnum.BankStatementsThreeMonths);

            switch (applicationBorrower.dealBorrower.incomeType) {
                case IncomeTypeEnum.PaygFullTime:
                    conditionTypes.push(ConditionTypeEnum.PaygPayslips);
                    break;

                case IncomeTypeEnum.PaygPartTime:
                    conditionTypes.push(ConditionTypeEnum.PaygPayslips);
                    break;

                case IncomeTypeEnum.SelfEmployed:
                    conditionTypes.push(ConditionTypeEnum.SelfEmployedDeclaration);
                    conditionTypes.push(ConditionTypeEnum.BusinessActivityStatements);
                    conditionTypes.push(ConditionTypeEnum.ProfitLossStatement);
                    break;
            }

            conditionTypes.push(ConditionTypeEnum.CreditFileSearchReport);
            conditionTypes.push(ConditionTypeEnum.BankruptcyRegisterSearch);
            if (applicationBorrower.creditScore && applicationBorrower.creditScore <= constants.APPLICATION_ADVERSE_CREDIT_SCORE_THRESHOLD) {
                conditionTypes.push(ConditionTypeEnum.AdverseCreditStatement);
            }
            break;

        case BorrowerTypeEnum.Trust:
            conditionTypes.push(ConditionTypeEnum.TrustDeed);
            break;

        case BorrowerTypeEnum.Company:
            conditionTypes.push(ConditionTypeEnum.BankStatementsSixMonths);
            conditionTypes.push(ConditionTypeEnum.BusinessActivityStatements);
            conditionTypes.push(ConditionTypeEnum.ProfitLossStatement);
            conditionTypes.push(ConditionTypeEnum.GeneralSecurityAgreement);
            conditionTypes.push(ConditionTypeEnum.CompanyInDepthTradingHistory);
            if (applicationBorrower.creditScore && applicationBorrower.creditScore <= constants.APPLICATION_ADVERSE_CREDIT_SCORE_THRESHOLD) {
                conditionTypes.push(ConditionTypeEnum.AdverseCreditStatement);
            }
            break;
    }

    return conditionTypes;
}

export function getPropertyConditions(application: IApplication, applicationProperty: IApplicationProperty): ConditionTypeEnum[] {
    const conditionTypes: ConditionTypeEnum[] = [];

    conditionTypes.push(ConditionTypeEnum.CurrentTitleSearch);
    conditionTypes.push(ConditionTypeEnum.PlanSearch);
    conditionTypes.push(ConditionTypeEnum.NominationContractVariation);
    conditionTypes.push(ConditionTypeEnum.PriceFinderReport);
    conditionTypes.push(ConditionTypeEnum.PropertyProfileReport);
    conditionTypes.push(ConditionTypeEnum.Valuation);

    if (application.mortgageType === MortgageTypeEnum.SecondMortgage) {
        conditionTypes.push(ConditionTypeEnum.SecondMortgageAuthority);
    }

    switch (applicationProperty.dealProperty.purpose) {
        case PropertyPurposeEnum.Develop:
            conditionTypes.push(ConditionTypeEnum.ListImprovementsEstimates);
            conditionTypes.push(ConditionTypeEnum.BuildingPermitApproval);
            conditionTypes.push(ConditionTypeEnum.FeasibilityStudyTable);
            conditionTypes.push(ConditionTypeEnum.BuildingContractPlans);
            conditionTypes.push(ConditionTypeEnum.PlanningDevelopmentPermit);
            conditionTypes.push(ConditionTypeEnum.BuildersInsurance);
            break;

        case PropertyPurposeEnum.Purchase:
            conditionTypes.push(ConditionTypeEnum.PurchaseContract);
            conditionTypes.push(ConditionTypeEnum.EvidencePurchaseDeposit);
            break;

        case PropertyPurposeEnum.PurchaseDevelop:
            conditionTypes.push(ConditionTypeEnum.PurchaseContract);
            conditionTypes.push(ConditionTypeEnum.EvidencePurchaseDeposit);
            conditionTypes.push(ConditionTypeEnum.ListImprovementsEstimates);
            conditionTypes.push(ConditionTypeEnum.BuildingPermitApproval);
            conditionTypes.push(ConditionTypeEnum.FeasibilityStudyTable);
            conditionTypes.push(ConditionTypeEnum.BuildingContractPlans);
            conditionTypes.push(ConditionTypeEnum.PlanningDevelopmentPermit);
            conditionTypes.push(ConditionTypeEnum.BuildersInsurance);
            break;

        case PropertyPurposeEnum.Refinance:
            conditionTypes.push(ConditionTypeEnum.MortgageStatements);
            conditionTypes.push(ConditionTypeEnum.DebtPayoutAmount);
            break;

        case PropertyPurposeEnum.Security:
            // Nothing specific
            break;

        case PropertyPurposeEnum.Sell:
            conditionTypes.push(ConditionTypeEnum.AgentAgreement);
            conditionTypes.push(ConditionTypeEnum.ContractOfSale);
            break;
    }

    if (propertyRequiresInsurance(applicationProperty.dealProperty)) {
        conditionTypes.push(ConditionTypeEnum.BuildingReplacementInsurance);
    }

    conditionTypes.push(ConditionTypeEnum.CouncilWaterRatesNotice);
    conditionTypes.push(ConditionTypeEnum.EvidenceLeviesPaid);
    conditionTypes.push(ConditionTypeEnum.PropertyPhoto);

    if (applicationProperty.dealProperty.strataType === StrataTypeEnum.Strata) {
        conditionTypes.push(ConditionTypeEnum.StrataTitleNotice);
    }

    if (applicationProperty.dealProperty.tenancyType === TenancyTypeEnum.Tenanted) {
        conditionTypes.push(ConditionTypeEnum.LeaseAgreement);
    }

    return conditionTypes;
}

export function calculateNetPrepaidBalanceOnSettlement(application: IApplication, properties: IDictionary<IProperty>): number {
    const interestPayable: number = Math.ceil(application.loanAmount * (application.interestRate / 100) / 12 * application.termMonths);

    let totalCurrentDebt: number = 0;
    if (application.mortgageType === MortgageTypeEnum.FirstMortgage && application.extensionType !== ExtensionTypeEnum.Renewal) {
        totalCurrentDebt = roundTo(_.sumBy(_.values(properties), 'currentDebt') || 0, 2);
    } else if (application.extensionType === ExtensionTypeEnum.Renewal) {
        totalCurrentDebt = application.payoutFigure ? application.payoutFigure.balanceAmount : roundTo(_.sumBy(_.values(properties), 'currentDebt') || 0, 2);
    }

    return roundTo(application.loanAmount - interestPayable - application.establishmentFee - (roundTo(application.applicationFee * 1.1, 2)) - (roundTo(application.brokerageFee * 1.1, 2)) - (roundTo(application.legalFees * 1.1, 2)) - application.estimatedOutlays - totalCurrentDebt, 2);
}
