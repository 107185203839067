import _ from 'lodash';
import IInvestmentSaleTransfer from '~Api/InvestmentSaleTransfer/IInvestmentSaleTransfer';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import IHistory from '~Api/InvestmentSaleTransfer/IHistory';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import IUnallocatedInvestment from '~Api/InvestmentSaleTransfer/IUnallocatedInvestment';
import { investmentSelector } from '~Investments/selectors';

function hydrateInvestmentSaleTransfer(state: IGlobalState, investmentSaleTransfer: IInvestmentSaleTransfer): IInvestmentSaleTransfer {
    const cloneInvestmentSaleTransfer: IInvestmentSaleTransfer = { ...investmentSaleTransfer };
    cloneInvestmentSaleTransfer.investment = investmentSelector(state, investmentSaleTransfer.investmentUuid);
    return cloneInvestmentSaleTransfer;
}

function hydrateUnallocatedInvestment(state: IGlobalState, unallocatedInvestment: IUnallocatedInvestment): IUnallocatedInvestment {
    const cloneUnallocatedInvestment: IUnallocatedInvestment = { ...unallocatedInvestment };
    cloneUnallocatedInvestment.investment = investmentSelector(state, unallocatedInvestment.investmentUuid);
    return cloneUnallocatedInvestment;
}

export function investmentSaleTransferSelector(state: IGlobalState, investmentSaleTransferUuid: string): IInvestmentSaleTransfer {
    if (!state.investmentSaleTransfers.investmentSaleTransfers || !state.investmentSaleTransfers.investmentSaleTransfers[investmentSaleTransferUuid]) {
        return null;
    }

    return hydrateInvestmentSaleTransfer(state, state.investmentSaleTransfers.investmentSaleTransfers[investmentSaleTransferUuid]);
}

export function investmentSaleTransfersSelector(state: IGlobalState): IDictionary<IInvestmentSaleTransfer> {
    if (!state.investmentSaleTransfers.investmentSaleTransfers) {
        return null;
    }

    const investmentSaleTransfers: IDictionary<IInvestmentSaleTransfer> = {};

    _.each(state.investmentSaleTransfers.investmentSaleTransfers, (investmentSaleTransfer: IInvestmentSaleTransfer) => {
        investmentSaleTransfers[investmentSaleTransfer.uuid] = hydrateInvestmentSaleTransfer(state, investmentSaleTransfer);
    });

    return investmentSaleTransfers;
}

export function investmentSaleTransferHistoriesSelector(state: IGlobalState, investmentSaleTransferUuid: string): IDictionary<IHistory> {
    return state.investmentSaleTransfers.histories[investmentSaleTransferUuid];
}

export function investmentSaleTransfersUnallocatedInvestmentsSelector(state: IGlobalState): IDictionary<IUnallocatedInvestment> {
    if (!state.investmentSaleTransfers.unallocatedInvestments) {
        return null;
    }

    const unallocatedInvestments: IDictionary<IUnallocatedInvestment> = {};

    _.each(state.investmentSaleTransfers.unallocatedInvestments, (unallocatedInvestment: IUnallocatedInvestment) => {
        unallocatedInvestments[unallocatedInvestment.investmentUuid] = hydrateUnallocatedInvestment(state, unallocatedInvestment);
    });

    return unallocatedInvestments;
}

export function investmentSaleTransferInvestorAccountInvestmentTransactionsSelector(state: IGlobalState, investmentSaleTransferUuid: string): IDictionary<IInvestorAccountInvestmentTransaction> {
    if (!state.investmentSaleTransfers.investmentSaleTransferInvestorAccountInvestmentTransactionUuids[investmentSaleTransferUuid]) {
        return null;
    }

    const investorAccountInvestmentTransactions: IDictionary<IInvestorAccountInvestmentTransaction> = {};

    _.each(state.investmentSaleTransfers.investmentSaleTransferInvestorAccountInvestmentTransactionUuids[investmentSaleTransferUuid], (investorAccountInvestmentTransactionUuid: string) => {
        investorAccountInvestmentTransactions[investorAccountInvestmentTransactionUuid] = state.investors.investorAccountInvestmentTransactions[investorAccountInvestmentTransactionUuid];
    });

    return investorAccountInvestmentTransactions;
}
