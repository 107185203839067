import { Alert, Button, Divider, Dropdown, Form, Input, InputNumber, MenuProps, Modal, Radio, RadioChangeEvent, Select, Space, Spin, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import ILoanPayoutFigure from '~Api/Loan/ILoanPayoutFigure';
import ILoanPayoutFigureItem from '~Api/Loan/ILoanPayoutFigureItem';
import ILoanPayoutFigureSection from '~Api/Loan/ILoanPayoutFigureSection';
import LoanPayoutFigureApprovalStatusEnum from '~Api/Loan/LoanPayoutFigureApprovalStatusEnum';
import LoanPayoutFigureSectionTypeEnum from '~Api/Loan/LoanPayoutFigureSectionTypeEnum';
import {
    loanGetAction,
    loanPayoutFigureGetAction,
    loanPayoutFigureItemAddAction,
    loanPayoutFigureItemDeleteAction,
    loanPayoutFigureItemValueSetAction,
    loanPayoutFigureSectionAddAction,
    loanPayoutFigureSectionDeleteAction,
    loanPayoutFigureValueSetAction,
} from '~Loans/actions';
import {
    loanPayoutFigureSelector,
    loanSelector,
} from '~Loans/selectors';
import {
    ILoanInterestCalculation,
    getLoanAppliedAccruedInterestTotal,
    getLoanInterestCalculation,
    getLoanPayoutFigureTotals,
} from '~Loans/utilities';
import { IGlobalState } from '~reducer';
import DatePicker from '~UI/DatePicker';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import { currencyFormatter } from '~utilities/formatters';
import LoanPayoutFigurePrepaidInterestModeEnum from '~Api/Loan/LoanPayoutFigurePrepaidInterestModeEnum';
import { DownOutlined } from '@ant-design/icons';
import LoanPayoutFigureAccruedInterestModeEnum from '~Api/Loan/LoanPayoutFigureAccruedInterestModeEnum';
import LoanPayoutFigureMinimumTermInterestModeEnum from '~Api/Loan/LoanPayoutFigureMinimumTermInterestModeEnum';
import PayoutFigureInterestCalculatorModal from './PayoutFigureInterestCalculatorModal';
import DischargeInterestTypeEnum from '~Api/Application/DischargeInterestTypeEnum';
import { applicationValueSetAction } from '~Applications/actions';
import IApplication from '~Api/Application/IApplication';

const defaultSections: LoanPayoutFigureSectionTypeEnum[] = [
    LoanPayoutFigureSectionTypeEnum.Interest,
    LoanPayoutFigureSectionTypeEnum.Other,
    LoanPayoutFigureSectionTypeEnum.Renewal,
    LoanPayoutFigureSectionTypeEnum.DefaultFees,
];

const sectionLabels: IDictionary<string> = {
    [LoanPayoutFigureSectionTypeEnum.Interest]: 'Interest',
    [LoanPayoutFigureSectionTypeEnum.Other]: 'Other (Inc GST)',
    [LoanPayoutFigureSectionTypeEnum.Renewal]: 'Renewal/Variation',
    [LoanPayoutFigureSectionTypeEnum.DefaultFees]: 'Default Fees',
};

interface IState {
    dischargeInterestType: string;
    isInterestCalculatorModalOpen: boolean;
    isMissingDetailsModalOpen: boolean;
}

interface IMatch {
    loanUuid: string;
    payoutFigureUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    loan: ILoan;
    payoutFigure: ILoanPayoutFigure;
}

interface IPropsDispatch {
    applicationValueSet: (applicationUuid: string, key: keyof IApplication, value: boolean|number|string) => void;
    loanGet: () => void;
    payoutFigureGet: () => void;
    payoutFigureItemAdd: (item: ILoanPayoutFigureItem) => void;
    payoutFigureItemDelete: (itemUuid: string) => void;
    payoutFigureItemValueSet: (itemUuid: string, key: keyof ILoanPayoutFigureItem, value: boolean|number|string) => void;
    payoutFigureSectionAdd: (section: ILoanPayoutFigureSection) => void;
    payoutFigureSectionDelete: (sectionUuid: string) => void;
    payoutFigureValueSet: (key: keyof ILoanPayoutFigure, value: boolean|number|string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class PayoutFigureEdit extends React.Component<Props, IState> {
    public state: IState = {
        dischargeInterestType: null,
        isInterestCalculatorModalOpen: false,
        isMissingDetailsModalOpen: true,
    };

    constructor(props: Props) {
        super(props);

        this.disabledDate = this.disabledDate.bind(this);

        this.onChangeItemAmount = this.onChangeItemAmount.bind(this);
        this.onChangeItemName = this.onChangeItemName.bind(this);
        this.onClickItemAdd = this.onClickItemAdd.bind(this);
        this.onClickItemDelete = this.onClickItemDelete.bind(this);

        this.onClickSectionAdd = this.onClickSectionAdd.bind(this);
        this.onClickSectionDelete = this.onClickSectionDelete.bind(this);

        this.onChangeBorrowerName = this.onChangeBorrowerName.bind(this);
        this.onChangeDischargeDate = this.onChangeDischargeDate.bind(this);
        this.onChangeLoanCode = this.onChangeLoanCode.bind(this);
        this.onChangePrepaidInterest = this.onChangePrepaidInterest.bind(this);
        this.onChangePrincipalAmount = this.onChangePrincipalAmount.bind(this);
        this.onChangeTrustAmount = this.onChangeTrustAmount.bind(this);

        this.onChangeAccruedInterestMode = this.onChangeAccruedInterestMode.bind(this);
        this.onChangeMinimumTermInterestMode = this.onChangeMinimumTermInterestMode.bind(this);
        this.onChangeDischargeInterestType = this.onChangeDischargeInterestType.bind(this);

        this.onCloseInterestCalculatorModal = this.onCloseInterestCalculatorModal.bind(this);
        this.onClickAutoCalculate = this.onClickAutoCalculate.bind(this);

        this.onClickMissingDetailsCancel = this.onClickMissingDetailsCancel.bind(this);
        this.onClickMissingDetailsOk = this.onClickMissingDetailsOk.bind(this);

        this.switchToPrepaidInterestAuto = this.switchToPrepaidInterestAuto.bind(this);
        this.switchToPrepaidInterestManual = this.switchToPrepaidInterestManual.bind(this);

        this.recalculateInterest = this.recalculateInterest.bind(this);
    }

    public componentDidMount(): void {
        const { loan, payoutFigure } = this.props;

        if (!loan) {
            this.props.loanGet();
        }
        if (!payoutFigure) {
            this.props.payoutFigureGet();
        }
    }

    public render(): JSX.Element {
        const { loan, match, payoutFigure } = this.props;
        const { isInterestCalculatorModalOpen, isMissingDetailsModalOpen } = this.state;

        if (!loan || !payoutFigure) {
            return (
                <Layout uuid={match.params.loanUuid} section='payout-figure'>
                    <Typography.Title level={2}>Payout Figure</Typography.Title>
                    <Spin />
                </Layout>
            );
        }

        if (payoutFigure.approvalStatus !== LoanPayoutFigureApprovalStatusEnum.Draft) {
            return (
                <Layout uuid={match.params.loanUuid} section='payout-figure'>
                    <Typography.Title level={2}>Payout Figure</Typography.Title>
                    <Alert
                        message='Unable to edit this Payout Figure'
                        description='This Payout Figure is not in the Draft status and is unable to be edited.'
                        type='error'
                        showIcon={true}
                    />
                </Layout>
            );
        }

        if (!loan.application.dischargeInterestType) {
            return (
                <Layout uuid={match.params.loanUuid} section='payout-figure'>
                    <p>Please set the discharge interest type</p>
                    <Modal
                        destroyOnClose={true}
                        okText='Confirm'
                        onCancel={this.onClickMissingDetailsCancel}
                        onOk={this.onClickMissingDetailsOk}
                        open={isMissingDetailsModalOpen}
                        title='Missing Details'
                    >
                        <Form.Item label='Discharge Interest Type' className='discharge-interest-type'>
                            <Select onChange={this.onChangeDischargeInterestType}>
                                <Select.Option value={DischargeInterestTypeEnum.BreakCost}>Break Cost</Select.Option>
                                <Select.Option value={DischargeInterestTypeEnum.MinimumTerm}>Minimum Term</Select.Option>
                            </Select>
                        </Form.Item>
                    </Modal>
                </Layout>
            );
        }

        const { balanceAmount, totalPayoutFigure } = getLoanPayoutFigureTotals(loan, payoutFigure);
        const appliedAccruedInterestTotal: number = getLoanAppliedAccruedInterestTotal(payoutFigure);

        // Loans without the current payment date (the most recent interest inclusive loan transaction date or loan start date) cannot be calculated.
        const canAutoCalculateInterest: boolean = !!loan.currentPaymentDate;

        const prepaidInterestMenu: MenuProps = {
            items: [
                ...(payoutFigure.prepaidInterestMode === LoanPayoutFigurePrepaidInterestModeEnum.Manual ? [
                    {
                        key: 'auto-calculate',
                        label: 'Auto Calculate',
                        onClick: this.switchToPrepaidInterestAuto,
                    },
                ] : []),
                ...(payoutFigure.prepaidInterestMode === LoanPayoutFigurePrepaidInterestModeEnum.Auto ? [
                    {
                        key: 'enter-amount',
                        label: 'Enter Amount',
                        onClick: this.switchToPrepaidInterestManual,
                    },
                ] : []),
            ],
        };

        const prepaidInterestLabel: JSX.Element = (
            <Dropdown menu={prepaidInterestMenu}>
                <a>Less Remaining Prepaid Interest <DownOutlined /></a>
            </Dropdown>
        );

        const prepaidInterestLabelBlock: JSX.Element = canAutoCalculateInterest ? (
            <Form.Item className='dropdown-label' label={prepaidInterestLabel} />
        ) : (
            <span className='label'>Less Remaining Prepaid Interest</span>
        );

        const prepaidInterestBlock: JSX.Element = payoutFigure.prepaidInterestMode === LoanPayoutFigurePrepaidInterestModeEnum.Auto ? (
            <Space className='amount'>
                <span>{currencyFormatter.format(payoutFigure.prepaidInterestAuto)}</span>
            </Space>
        ) : (
            <Form.Item>
                <InputNumber addonBefore='$' className='amount' onChange={this.onChangePrepaidInterest} value={payoutFigure.prepaidInterest} />
            </Form.Item>
        );

        const accruedInterestTotalBlock: JSX.Element = payoutFigure.accruedInterestMode === LoanPayoutFigureAccruedInterestModeEnum.Auto && (
            <Space direction='vertical'>
                <Space className='line-item'>
                    <span className='label'>{payoutFigure.accruedInterestLabel}</span>
                    <Space className='amount'>
                        <span>{currencyFormatter.format(appliedAccruedInterestTotal)}</span>
                    </Space>
                </Space>
            </Space>
        );

        const sectionsBlock: JSX.Element[] = _.map(defaultSections, (defaultSection: LoanPayoutFigureSectionTypeEnum) => {
            const section: ILoanPayoutFigureSection = _.find(payoutFigure.sections, (loopSection: ILoanPayoutFigureSection) => loopSection.type === defaultSection);

            if (!section) {
                const onClickSectionAdd: () => void = () => this.onClickSectionAdd(defaultSection);
                return (
                    <React.Fragment key={sectionLabels[defaultSection]}>
                        <Divider />
                        <Space direction='vertical'>
                            <Space>
                                <strong>{sectionLabels[defaultSection]}</strong>
                                <Button onClick={onClickSectionAdd} size='small' type='link'>Add</Button>
                            </Space>
                        </Space>
                    </React.Fragment>
                );
            }

            const itemsBlock: JSX.Element[] = _.map(section.items, (item: ILoanPayoutFigureItem) => {
                const onClickDelete: () => void = () => this.onClickItemDelete(item.uuid);
                const onChangeAmount: (value: number) => void = (value: number) => this.onChangeItemAmount(item.uuid, value);
                const onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void = (event: React.ChangeEvent<HTMLInputElement>) => this.onChangeItemName(item.uuid, event);

                return (
                    <Space className='line-item' key={item.uuid}>
                        <Form.Item>
                            <Input className='label' onChange={onChangeName} value={item.name} />
                        </Form.Item>
                        <Form.Item>
                            <InputNumber addonBefore='$' className='amount' onChange={onChangeAmount} value={item.amount || null} />
                        </Form.Item>
                        <Button onClick={onClickDelete} size='small' type='link'>Delete</Button>
                    </Space>
                );
            });

            const onClickItemAdd: () => void = () => this.onClickItemAdd(section.uuid);
            const onClickSectionDelete: () => void = () => this.onClickSectionDelete(section.uuid);

            return (
                <React.Fragment key={section.name}>
                    <Divider />
                    <Space direction='vertical'>
                        <Space>
                            <strong>{section.name}</strong>
                            {[LoanPayoutFigureSectionTypeEnum.DefaultFees, LoanPayoutFigureSectionTypeEnum.Renewal].includes(section.type) && <Button onClick={onClickSectionDelete} size='small' type='link'>Remove</Button>}
                            {section.type === LoanPayoutFigureSectionTypeEnum.Interest && canAutoCalculateInterest && <Button onClick={this.onClickAutoCalculate} size='small' type='link'>Auto Calculate</Button>}
                        </Space>
                        <React.Fragment>
                            {section.type === LoanPayoutFigureSectionTypeEnum.Interest && accruedInterestTotalBlock}
                            {itemsBlock}
                        </React.Fragment>
                        <Button onClick={onClickItemAdd}>Add Item</Button>
                    </Space>
                </React.Fragment>
            );
        });

        const loanCodeBlock: JSX.Element = loan.salesforceCode && loan.code !== loan.salesforceCode && (
            <Form.Item label='Loan ID'>
                <Radio.Group onChange={this.onChangeLoanCode} value={payoutFigure.loanCode}>
                    <Radio value={loan.code}>{loan.code}</Radio>
                    <Radio value={loan.salesforceCode}>{loan.salesforceCode}</Radio>
                </Radio.Group>
            </Form.Item>
        );

        // If there is a current payment date, check if the discharge date is earlier.
        const isDischargeDateValid: boolean = (loan.currentPaymentDate && payoutFigure.dischargeDate >= loan.currentPaymentDate) || !loan.currentPaymentDate;

        return (
            <Layout uuid={match.params.loanUuid} section='payout-figure'>
                <Typography.Title level={2}>Payout Figure</Typography.Title>
                {loanCodeBlock}
                <Form.Item className='borrower-name' label='Borrower'>
                    <Input onChange={this.onChangeBorrowerName} value={payoutFigure.borrowerName} />
                </Form.Item>
                <Form.Item className='discharge-date' help={!isDischargeDateValid && `Discharge date is earlier than the last loan payment for ${loan.code}`} label='Proposed Payout Date' validateStatus={!isDischargeDateValid && 'error'}>
                    <DatePicker
                        disabledDate={this.disabledDate}
                        onChange={this.onChangeDischargeDate}
                        format='DD/MM/YYYY'
                        value={dayjs(payoutFigure.dischargeDate)}
                        allowClear={false}
                    />
                </Form.Item>
                <Divider />
                <Space className='line-item'>
                    <span className='label'>Principal</span>
                    <InputNumber addonBefore='$' className='amount' onChange={this.onChangePrincipalAmount} value={payoutFigure.principalAmount || null} />
                </Space>
                <React.Fragment>
                    {sectionsBlock}
                </React.Fragment>
                <Divider />
                <Space direction='vertical'>
                    <Space className='line-item'>
                        <span className='label'>Total Payout Figure</span>
                        <span className='amount'>{currencyFormatter.format(totalPayoutFigure)}</span>
                    </Space>
                    <Space className='line-item'>
                        {prepaidInterestLabelBlock}
                        {prepaidInterestBlock}
                    </Space>
                    <Space className='line-item'>
                        <span className='label'>Less Amount Held In Trust</span>
                        <Form.Item>
                            <InputNumber addonBefore='$' className='amount' onChange={this.onChangeTrustAmount} value={payoutFigure.trustAmount || null} />
                        </Form.Item>
                    </Space>
                    <Space className='line-item'>
                        <span className='label'><strong>Balance Due</strong></span>
                        <span className='amount'><strong>{currencyFormatter.format(balanceAmount)}</strong></span>
                    </Space>
                </Space>
                <PayoutFigureInterestCalculatorModal
                    isOpen={isInterestCalculatorModalOpen}
                    onClose={this.onCloseInterestCalculatorModal}
                    payoutFigure={payoutFigure}
                    onChangeAccruedInterestMode={this.onChangeAccruedInterestMode}
                    onChangeMinimumTermInterestMode={this.onChangeMinimumTermInterestMode}
                />
            </Layout>
        );
    }

    /**
     * Disables dates before the current payment date for the loan
     */
    private disabledDate(date: Dayjs): boolean {
        const { loan } = this.props;

        return loan.currentPaymentDate && date.format('YYYY-MM-DD') < loan.currentPaymentDate;
    }

    private onChangeItemAmount(itemUuid: string, value: number): void {
        this.props.payoutFigureItemValueSet(itemUuid, 'amount', value ? value : null);
    }

    private onChangeItemName(itemUuid: string, event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.payoutFigureItemValueSet(itemUuid, 'name', event.target.value);
    }

    private onClickItemAdd(sectionUuid: string): void {
        const item: ILoanPayoutFigureItem = {
            amount: null,
            name: null,
            payoutFigureSectionUuid: sectionUuid,
        };

        this.props.payoutFigureItemAdd(item);
    }

    private onClickItemDelete(itemUuid: string): void {
        Modal.confirm({
            okText: 'Delete',
            onOk: () => {
                this.props.payoutFigureItemDelete(itemUuid);
            },
            title: 'Delete Line Item',
        });
    }

    private onClickSectionAdd(sectionType: LoanPayoutFigureSectionTypeEnum): void {
        const { payoutFigure } = this.props;

        const section: ILoanPayoutFigureSection = {
            items: [],
            name: sectionLabels[sectionType],
            payoutFigureUuid: payoutFigure.uuid,
            type: sectionType,
        };

        this.props.payoutFigureSectionAdd(section);
    }

    private onClickSectionDelete(sectionUuid: string): void {
        Modal.confirm({
            okText: 'Delete',
            onOk: () => {
                this.props.payoutFigureSectionDelete(sectionUuid);
            },
            title: 'Delete Section',
        });
    }

    private onClickMissingDetailsCancel(): void {
        this.setState({
            isMissingDetailsModalOpen: false,
        });
    }

    private onClickMissingDetailsOk(): void {
        const { loan } = this.props;
        const { dischargeInterestType } = this.state;

        this.props.applicationValueSet(loan.applicationUuid, 'dischargeInterestType', dischargeInterestType);

        this.onClickMissingDetailsCancel();
    }

    private onCloseInterestCalculatorModal(): void {
        this.setState({
            isInterestCalculatorModalOpen: false,
        });
    }

    private onClickAutoCalculate(): void {
        this.setState({
            isInterestCalculatorModalOpen: true,
        });
    }

    private onChangeBorrowerName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.payoutFigureValueSet('borrowerName', event.target.value);
    }

    private onChangeDischargeDate(date: Dayjs): void {
        const dischargeDate: string = date.format('YYYY-MM-DD');

        this.props.payoutFigureValueSet('dischargeDate', dischargeDate);

        this.recalculateInterest(dischargeDate);
    }

    private onChangeLoanCode(event: RadioChangeEvent): void {
        this.props.payoutFigureValueSet('loanCode', event.target.value);
    }

    private onChangePrincipalAmount(value: number): void {
        this.props.payoutFigureValueSet('principalAmount', value ? value : 0);
    }

    private onChangeTrustAmount(value: number): void {
        this.props.payoutFigureValueSet('trustAmount', value ? value : 0);
    }

    private onChangePrepaidInterest(value: number): void {
        this.props.payoutFigureValueSet('prepaidInterest', value ? value : 0);
    }

    private onChangeAccruedInterestMode(accruedInterestMode: LoanPayoutFigureAccruedInterestModeEnum): void {
        const { payoutFigure } = this.props;
        this.props.payoutFigureValueSet('accruedInterestMode', accruedInterestMode);

        // We recalculate in the accrued  interest mode change due to legacy payout figures missing their calculations,
        this.recalculateInterest(payoutFigure.dischargeDate);
    }

    private onChangeMinimumTermInterestMode(minimumTermInterestMode: LoanPayoutFigureMinimumTermInterestModeEnum): void {
        const { payoutFigure } = this.props;
        this.props.payoutFigureValueSet('minimumTermInterestMode', minimumTermInterestMode);

        // We recalculate in the minimum term interest mode change due to legacy payout figures missing their calculations,
        // In this case discharge interest type is not filled for existing applications which in turn can affect the calculation
        this.recalculateInterest(payoutFigure.dischargeDate);
    }

    private onChangeDischargeInterestType(dischargeInterestType: DischargeInterestTypeEnum): void {
        this.setState({
            dischargeInterestType,
        });
    }

    private switchToPrepaidInterestAuto(): void {
        const { payoutFigure } = this.props;
        this.props.payoutFigureValueSet('prepaidInterestMode', LoanPayoutFigurePrepaidInterestModeEnum.Auto);

        // We recalculate in the prepaid interest mode change due to legacy payout figures missing their calculations
        this.recalculateInterest(payoutFigure.dischargeDate);
    }

    private switchToPrepaidInterestManual(): void {
        const { payoutFigure } = this.props;
        this.props.payoutFigureValueSet('prepaidInterestMode', LoanPayoutFigurePrepaidInterestModeEnum.Manual);

        // We recalculate in the prepaid interest mode change due to legacy payout figures missing their calculations
        this.recalculateInterest(payoutFigure.dischargeDate);
    }

    private recalculateInterest(dischargeDate: string): void {
        const { loan } = this.props;

        const loanInterestCalculation: ILoanInterestCalculation = getLoanInterestCalculation(loan, dischargeDate);

        this.props.payoutFigureValueSet('prepaidInterestAuto', loanInterestCalculation.prepaidInterest);
        this.props.payoutFigureValueSet('accruedInterest', loanInterestCalculation.accruedInterest);
        this.props.payoutFigureValueSet('accruedInterestLabel', loanInterestCalculation.accruedInterestLabel);
        this.props.payoutFigureValueSet('minimumTermInterest', loanInterestCalculation.minimumTermInterest);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        loan: loanSelector(state, ownProps.match.params.loanUuid),
        payoutFigure: loanPayoutFigureSelector(state, ownProps.match.params.payoutFigureUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationValueSet: (applicationUuid: string, key: keyof IApplication, value: boolean|number|string) => dispatch(applicationValueSetAction(applicationUuid, key, value)),
        loanGet: () => dispatch(loanGetAction(ownProps.match.params.loanUuid)),
        payoutFigureGet: () => dispatch(loanPayoutFigureGetAction(ownProps.match.params.payoutFigureUuid)),
        payoutFigureItemAdd: (item: ILoanPayoutFigureItem) => dispatch(loanPayoutFigureItemAddAction(item)),
        payoutFigureItemDelete: (itemUuid: string) => dispatch(loanPayoutFigureItemDeleteAction(itemUuid)),
        payoutFigureItemValueSet: (itemUuid: string, key: keyof ILoanPayoutFigureItem, value: boolean|number|string) => dispatch(loanPayoutFigureItemValueSetAction(itemUuid, key, value)),
        payoutFigureSectionAdd: (section: ILoanPayoutFigureSection) => dispatch(loanPayoutFigureSectionAddAction(section)),
        payoutFigureSectionDelete: (sectionUuid: string) => dispatch(loanPayoutFigureSectionDeleteAction(sectionUuid)),
        payoutFigureValueSet: (key: keyof ILoanPayoutFigure, value: boolean|number|string) => dispatch(loanPayoutFigureValueSetAction(ownProps.match.params.payoutFigureUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PayoutFigureEdit);
