import { Layout as AntLayout, Breadcrumb, Button, Descriptions, Menu, Modal, PageHeader, Space, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import IUser from '~Api/User/IUser';
import { IGlobalState } from '~reducer';
import { userSelector } from '~Users/selectors';
import EmailModal from './EmailModal';
import PhoneModal from './PhoneModal';
import './user.less';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import { Dispatch } from 'redux';
import { userBlockAction, userUnblockAction } from '~Users/actions';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import menuDivider from '~UI/menuDivider';

interface IState {
    isEmailModalOpen: boolean;
    isPhoneModalOpen: boolean;
}

interface IProps {
    uuid: string;
    breadcrumb?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    section: string;
}

interface IPropsSelector {
    currentUser: IAuthUser;
    user: IUser;
}

interface IPropsDispatch {
    block: () => void;
    unblock: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Layout extends React.Component<Props, IState> {
    public state: IState = {
        isEmailModalOpen: false,
        isPhoneModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickBlock = this.onClickBlock.bind(this);
        this.onClickUnblock = this.onClickUnblock.bind(this);

        this.onClickChangeEmail = this.onClickChangeEmail.bind(this);
        this.cancelChangeEmail = this.cancelChangeEmail.bind(this);
        this.onClickChangePhone = this.onClickChangePhone.bind(this);
        this.cancelChangePhone = this.cancelChangePhone.bind(this);
    }

    public render(): JSX.Element {
        const { breadcrumb, children, currentUser, section, user } = this.props;
        const {
            isEmailModalOpen,
            isPhoneModalOpen,
        } = this.state;

        if (!user) {
            return (
                <AntLayout className='user'>
                    <Spin />
                </AntLayout>
            );
        }

        const breadcrumbBlock: JSX.Element = breadcrumb ? (
            <React.Fragment>
                <Breadcrumb.Item><Link to={`/users/${user.uuid}`}>{user.firstName} {user.lastName}</Link></Breadcrumb.Item>
                {breadcrumb}
            </React.Fragment>
        ) : (
            <Breadcrumb.Item>{user.firstName} {user.lastName}</Breadcrumb.Item>
        );

        const blockButton: JSX.Element = currentUser.permissions.includes(PermissionsEnum.Loans) && (
            user.isBlocked ? <Button onClick={this.onClickUnblock} danger={true}>Unblock</Button> : <Button onClick={this.onClickBlock} danger={true}>Block</Button>
        );

        const extra: JSX.Element = (
            <Space>
                <Button onClick={this.onClickChangePhone}>Change Phone</Button>
                <Button onClick={this.onClickChangeEmail}>Change Email</Button>
                {blockButton}
            </Space>
        );

        const menuItems: ItemType[] = [
            {
                key: 'overview',
                label: <NavLink to={`/users/${user.uuid}`}>Overview</NavLink>,
            },
            {
                key: 'edit',
                label: <NavLink to={`/users/${user.uuid}/edit`}>Edit</NavLink>,
            },
            {
                key: 'investors',
                label: <NavLink to={`/users/${user.uuid}/investors`}>Investors</NavLink>,
            },
            menuDivider,
            {
                key: 'history',
                label: <NavLink to={`/users/${user.uuid}/history`}>History</NavLink>,
            },
        ];

        return (
            <AntLayout className='user'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/users'>Users</Link></Breadcrumb.Item>
                    {breadcrumbBlock}
                </Breadcrumb>
                <PageHeader
                    className='header'
                    extra={extra}
                    ghost={false}
                    title={`${user.firstName} ${user.lastName}`}
                >
                    <Descriptions size='small' column={3}>
                        <Descriptions.Item label='Email'><a href={`mailto:${user.email}`}>{user.email}</a></Descriptions.Item>
                        <Descriptions.Item label='Phone'>{user.phone ? <a href={`tel:${user.phone}`}>{user.phone}</a> : 'None'}</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
                <AntLayout className='content-wrapper'>
                    <AntLayout.Sider className='menu' theme='light' width={240}>
                        <Menu
                            defaultSelectedKeys={[section]}
                            items={menuItems}
                            mode='inline'
                        />
                    </AntLayout.Sider>
                    <AntLayout.Content className={`content ${section}`}>
                        {children}
                    </AntLayout.Content>
                </AntLayout>
                <EmailModal
                    isOpen={isEmailModalOpen}
                    onCancel={this.cancelChangeEmail}
                    user={user}
                />
                <PhoneModal
                    isOpen={isPhoneModalOpen}
                    onCancel={this.cancelChangePhone}
                    user={user}
                />
            </AntLayout>
        );
    }

    private onClickBlock() {
        Modal.confirm({
            content: 'Are you sure you want to block this user?',
            okText: 'Block',
            onOk: () => {
                this.props.block();
            },
            title: 'Block User',
        });
    }

    private onClickUnblock() {
        Modal.confirm({
            content: 'Are you sure you want to unblock this user?',
            okText: 'Unblock',
            onOk: () => {
                this.props.unblock();
            },
            title: 'Unblock User',
        });
    }

    private onClickChangeEmail() {
        this.setState({
            isEmailModalOpen: true,
        });
    }

    private cancelChangeEmail() {
        this.setState({
            isEmailModalOpen: false,
        });
    }

    private onClickChangePhone() {
        this.setState({
            isPhoneModalOpen: true,
        });
    }

    private cancelChangePhone() {
        this.setState({
            isPhoneModalOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        currentUser: authCurrentUserSelector(state),
        user: userSelector(state, ownProps.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        block: () => dispatch(userBlockAction(ownProps.uuid)),
        unblock: () => dispatch(userUnblockAction(ownProps.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Layout);
