import { fetchGet, fetchPost, fetchPostFile, fetchPut } from '~utilities/fetch';
import IInvestment from './IInvestment';
import { transformInvestment } from './transformers';

export async function investmentGetRequest(uuid: string): Promise<Response> {
    return fetchGet(`/investments/${uuid}`);
}

export async function investmentGracePeriodRequest(uuid: string): Promise<Response> {
    return fetchPost(`/investments/${uuid}/grace-period`);
}

export async function investmentHideRequest(uuid: string): Promise<Response> {
    return fetchPost(`/investments/${uuid}/hide`);
}

export async function investmentHistoriesListRequest(uuid: string): Promise<Response> {
    return fetchGet(`/investments/${uuid}/history`);
}

export async function investmentInterestRateRequest(investmentUuid: string, interestRate: number): Promise<Response> {
    return fetchPost(`/investments/${investmentUuid}/interest-rate`, {
        interestRate,
    });
}

export async function investmentInvestRequest(uuid: string, investorAccountUuid: string, amount: number): Promise<Response> {
    return fetchPost(`/investments/${uuid}/invest`, {
        amount,
        investorAccountUuid,
    });
}

export async function investmentReserveRequest(investmentUuid: string, investorAccountUuid: string, amount: number): Promise<Response> {
    return fetchPost(`/investments/${investmentUuid}/reservations`, {
        amount,
        investorAccountUuid,
    });
}

export async function investmentInvestorsListRequest(investmentUuid: string): Promise<Response> {
    return fetchGet(`/investments/${investmentUuid}/investors`);
}

export async function investmentPhotoRequest(uuid: string, file: File): Promise<Response> {
    return fetchPostFile(`/investments/${uuid}/photo`, file);
}

export async function investmentReservationProcessRequest(investmentReservationUuid: string): Promise<Response> {
    return fetchPost(`/investment-reservations/${investmentReservationUuid}/process`);
}

export async function investmentReservationsListRequest(investmentUuid: string): Promise<Response> {
    return fetchGet(`/investments/${investmentUuid}/reservations`);
}

export async function investmentSendInvestorEmailRequest(uuid: string, subject: string, message: string): Promise<Response> {
    return fetchPost(`/investments/${uuid}/send-investor-email`, {
        message,
        subject,
    });
}

export async function investmentSendPayoutEmailRequest(uuid: string): Promise<Response> {
    return fetchPost(`/investments/${uuid}/send-payout-email`);
}

export async function investmentShowRequest(uuid: string, sendEmail: boolean, interestRate?: number): Promise<Response> {
    return fetchPost(`/investments/${uuid}/show`, {
        interestRate,
        sendEmail,
    });
}

export async function investmentTransactionsListRequest(investmentUuid: string): Promise<Response> {
    return fetchGet(`/investments/${investmentUuid}/transactions`);
}

export async function investmentTransactionGetRequest(investmentTransactionUuid: string): Promise<Response> {
    return fetchGet(`/investment-transactions/${investmentTransactionUuid}`);
}

export async function investmentTransactionInvestorsListRequest(investmentTransactionUuid: string): Promise<Response> {
    return fetchGet(`/investment-transactions/${investmentTransactionUuid}/investors`);
}

export async function investmentTransactionsPendingDistributionsListRequest(): Promise<Response> {
    return fetchGet('/investment-transactions/pending-distributions');
}

export async function investmentTransactionsCreateFromWarehouseLoanTransactionRequest(warehouseLoanTransactionUuid: string): Promise<Response> {
    return fetchPost('/investment-transactions/create-from-warehouse-loan-transaction', {
        warehouseLoanTransactionUuid,
    });
}

export async function investmentUpdateRequest(investment: IInvestment): Promise<Response> {
    return fetchPut(`/investments/${investment.uuid}`, transformInvestment(investment));
}

export async function investmentsDashboardRequest(): Promise<Response> {
    return fetchGet('/investments/dashboard');
}

export async function investmentsListRequest(): Promise<Response> {
    return fetchGet('/investments');
}

export async function investmentsSearchRequest(keyword: string): Promise<Response> {
    return fetchPost('/investments/search', { keyword });
}
