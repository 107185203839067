import { Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { investmentIncomeTrustInvestmentRequestCancelAction } from '~Investments/actions';

interface IState {
    reason: string;
}

interface IProps {
    incomeTrustInvestmentRequestUuid: string;
    onCancel: () => void;
}

interface IPropsDispatch {
    cancel: (reason: string) => void;
}

type Props = IProps & IPropsDispatch;

class PendingRequestCancelModal extends React.Component<Props, IState> {
    public state: IState = {
        reason: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeReason = this.onChangeReason.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { incomeTrustInvestmentRequestUuid } = this.props;
        const { reason } = this.state;

        return (
            <Modal
                okButtonProps={{ danger: true }}
                okText='Cancel'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={!!incomeTrustInvestmentRequestUuid}
                title='Cancel Investment'
            >
                <Form.Item label='Reason' className='reason'>
                    <Input onChange={this.onChangeReason} value={reason} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeReason(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            reason: event.target.value,
        });
    }

    private onClickOk() {
        const { reason } = this.state;

        this.props.cancel(reason);
        this.props.onCancel();
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        cancel: (reason: string) => dispatch(investmentIncomeTrustInvestmentRequestCancelAction(ownProps.incomeTrustInvestmentRequestUuid, reason)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(PendingRequestCancelModal);
