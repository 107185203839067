import { ArrowLeftOutlined } from '@ant-design/icons';
import { Layout as AntLayout, Breadcrumb, Button, Descriptions, Dropdown, Menu, MenuProps, Modal, PageHeader, Space, Spin, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IInvestment from '~Api/Investment/IInvestment';
import WorkflowStatusEnum from '~Api/Investment/WorkflowStatusEnum';
import IAuthUser from '~Auth/IAuthUser';
import {
    authCurrentUserSelector,
    authTokenSelector,
} from '~Auth/selectors';
import {
    investmentGetAction,
    investmentGracePeriodAction,
    investmentHideAction,
    investmentPayoutEmailSendAction,
} from '~Investments/actions';
import InterestRateModal from '~Investments/Investment/InterestRateModal';
import { investmentSelector } from '~Investments/selectors';
import { IGlobalState } from '~reducer';
import './investment.less';
import SendModal from './SendModal';
import ShowModal from './ShowModal';
import { IDictionary } from '~utilities/IDictionary';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import menuDivider from '~UI/menuDivider';

const statusNames: IDictionary<string> = {
    [WorkflowStatusEnum.Cancelled]: 'Cancelled',
    [WorkflowStatusEnum.Funded]: 'Funded',
    [WorkflowStatusEnum.GracePeriod]: 'Grace Period',
    [WorkflowStatusEnum.Incomplete]: 'Incomplete',
    [WorkflowStatusEnum.Live]: 'Live',
    [WorkflowStatusEnum.Ready]: 'Ready',
    [WorkflowStatusEnum.Repaid]: 'Repaid',
};

export default function Layout(props: {
    breadcrumb?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    uuid: string;
    section: string;
}): ReactElement {
    const { breadcrumb, children, section, uuid } = props;

    const [ isInterestRateModalOpen, setIsInterestRateModalOpen ] = useState<boolean>(false);
    const [ isSendModalOpen, setIsSendModalOpen ] = useState<boolean>(false);
    const [ isShowModalOpen, setIsShowModalOpen ] = useState<boolean>(false);

    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);
    const investment: IInvestment = useSelector((state: IGlobalState) => investmentSelector(state, uuid));
    const token: string = useSelector(authTokenSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!investment) {
            dispatch(investmentGetAction(uuid));
        }
    }, []);

    const investmentHide: () => void = useCallback(() => dispatch(investmentHideAction(uuid)), []);

    const onClickInvestmentEnterGracePeriod: () => void = useCallback(() => {
        Modal.confirm({
            content: 'Are you sure you want this investment to enter a grace period?',
            okText: 'Enter Grace Period',
            okType: 'primary',
            onOk: () => {
                dispatch(investmentGracePeriodAction(uuid));
            },
            title: 'Enter Grace Period',
        });
    }, []);

    const onClickSendInvestorEmail: () => void = useCallback(() => {
        setIsSendModalOpen(true);
    }, []);

    const onCancelSendInvestorEmail: () => void = useCallback(() => {
        setIsSendModalOpen(false);
    }, []);

    const onClickShowInvestment: () => void = useCallback(() => {
        setIsShowModalOpen(true);
    }, []);

    const onCancelShowInvestment: () => void = useCallback(() => {
        setIsShowModalOpen(false);
    }, []);

    const onClickInterestRate: () => void = useCallback(() => {
        setIsInterestRateModalOpen(true);
    }, []);

    const onCancelInterestRate: () => void = useCallback(() => {
        setIsInterestRateModalOpen(false);
    }, []);

    const onClickSendPayoutEmail: () => void = useCallback(() => {
        Modal.confirm({
            content: 'Are you sure you want to send this email?',
            okText: 'Send',
            okType: 'danger',
            onOk: () => {
                dispatch(investmentPayoutEmailSendAction(uuid));
            },
            title: 'Send Payout Email',
        });
    }, []);

    if (!investment) {
        return (
            <AntLayout className='investment'>
                <Spin/>
            </AntLayout>
        );
    }

    const breadcrumbBlock: JSX.Element = breadcrumb ? (
        <>
            <Breadcrumb.Item><Link to={`/investments/${investment.uuid}`}>{investment.code}</Link></Breadcrumb.Item>
            {breadcrumb}
        </>
    ) : (
        <Breadcrumb.Item>{investment.code}</Breadcrumb.Item>
    );

    const sendMenu: MenuProps = {
        items: [
            {
                key: 'send-payout-email',
                label: 'Send Payout Email',
                onClick: onClickSendPayoutEmail,
            },
        ],
    };

    const extra: JSX.Element = (
        <Space>
            <Tag className='workflow-status'>{statusNames[investment.workflowStatus]}</Tag>
            {investment.workflowStatus === WorkflowStatusEnum.Funded && <Button onClick={onClickInvestmentEnterGracePeriod}>Enter Grace Period</Button>}
            {investment.workflowStatus === WorkflowStatusEnum.Ready && <Button onClick={onClickShowInvestment}>Show</Button>}
            {investment.workflowStatus === WorkflowStatusEnum.Live && <Button onClick={investmentHide}>Hide</Button>}
            <Dropdown.Button onClick={onClickSendInvestorEmail} menu={sendMenu}>Send Investor Email</Dropdown.Button>
            <Button
                href={`${process.env.API_HOST}/investments/${investment.uuid}/download-memo-pdf?token=${token}`}
                target='_blank'
            >
                Download Investment Memo
            </Button>
        </Space>
    );

    const interestRateBlock: JSX.Element = investment.amountTotal === investment.amountRemaining ? (
        <Button onClick={onClickInterestRate} type='link' className='inline'>
            {investment.interestRate !== 0 ? percentageFormatter.format(investment.interestRate / 100) : '-'}
        </Button>
    ) : (
        <>
            {investment.interestRate !== 0 ? percentageFormatter.format(investment.interestRate / 100) : '-'}
        </>
    );

    const menuItems: ItemType[] = [
        {
            key: 'overview',
            label: <NavLink to={`/investments/${investment.uuid}`}>Overview</NavLink>,
        },
        {
            key: 'edit',
            label: <NavLink to={`/investments/${investment.uuid}/edit`}>Edit</NavLink>,
        },
        {
            key: 'reservations',
            label: <NavLink to={`/investments/${investment.uuid}/reservations`}>Reservations</NavLink>,
        },
        {
            key: 'investors',
            label: <NavLink to={`/investments/${investment.uuid}/investors`}>Investors</NavLink>,
        },
        {
            key: 'transactions',
            label: <NavLink to={`/investments/${investment.uuid}/transactions`}>Transactions</NavLink>,
        },
        menuDivider,
        {
            key: 'history',
            label: <NavLink to={`/investments/${investment.uuid}/history`}>History</NavLink>,
        },
        ...(currentUser.permissions.includes(PermissionsEnum.Loans) ? [
            menuDivider,
            {
                key: 'loan',
                label: <NavLink to={`/loans/${investment.loanUuid}`}><ArrowLeftOutlined/> Loan</NavLink>,
            },
        ] : []),
    ];

    return (
        <AntLayout className='investment'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/investments'>Investments</Link></Breadcrumb.Item>
                {breadcrumbBlock}
            </Breadcrumb>
            <PageHeader
                className='header'
                extra={extra}
                ghost={false}
                subTitle={investment.code}
                title={investment.name || '-'}
            >
                <Descriptions size='small' column={4}>
                    <Descriptions.Item label='Total'>{currencyFormatter.format(investment.amountTotal)}</Descriptions.Item>
                    <Descriptions.Item label='Remaining'>
                        {currencyFormatter.format(investment.amountRemaining)}
                        {investment.amountReserved && ` (${currencyFormatter.format(investment.amountReserved)} reserved)`}
                    </Descriptions.Item>
                    <Descriptions.Item label='Term'>{investment.termMonths} months {investment.gracePeriodMonths ? `+ ${investment.gracePeriodMonths} month${investment.gracePeriodMonths !== 1 ? 's' : ''} of Grace Period` : ''}</Descriptions.Item>
                    <Descriptions.Item className='interest-rate' label='Rate'>{interestRateBlock}</Descriptions.Item>
                    <Descriptions.Item label='LVR'>{percentageFormatter.format(investment.lvr / 100)}</Descriptions.Item>
                    <Descriptions.Item label='Created'>{investment.createdTime ? dayjs(investment.createdTime).format('Do MMMM YYYY HH:mm:ss') : '-'}</Descriptions.Item>
                    {investment.closedDate && <Descriptions.Item label='Closed'>{dayjs(investment.closedDate).format('Do MMMM YYYY')}</Descriptions.Item>}
                </Descriptions>
            </PageHeader>
            <AntLayout className='content-wrapper'>
                <AntLayout.Sider className='menu' theme='light' width={240}>
                    <Menu
                        defaultSelectedKeys={[section]}
                        items={menuItems}
                        mode='inline'
                    />
                </AntLayout.Sider>
                <AntLayout.Content className={`content ${section}`}>
                    {children}
                </AntLayout.Content>
            </AntLayout>
            <InterestRateModal
                investment={investment}
                isOpen={isInterestRateModalOpen}
                onCancel={onCancelInterestRate}
            />
            <SendModal
                investment={investment}
                isOpen={isSendModalOpen}
                onCancel={onCancelSendInvestorEmail}
            />
            <ShowModal
                investment={investment}
                isOpen={isShowModalOpen}
                onCancel={onCancelShowInvestment}
            />
        </AntLayout>
    );
}
