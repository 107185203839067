import { Breadcrumb, Button, Layout, Space, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import IInvestmentSaleTransfer from '~Api/InvestmentSaleTransfer/IInvestmentSaleTransfer';
import { investmentSaleTransfersListAction } from './actions';
import { investmentSaleTransfersSelector } from './selectors';
import AddModal from './AddModal';
import './investment-sale-transfers.less';
import IInvestment from '~Api/Investment/IInvestment';
import { currencyFormatter } from '~utilities/formatters';

interface IState {
    isAddModalOpen: boolean;
}

interface IPropsSelector {
    investmentSaleTransfers: IDictionary<IInvestmentSaleTransfer>;
}

interface IPropsDispatch {
    investmentSaleTransfersList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class List extends React.Component<Props, IState> {
    public state: IState = {
        isAddModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAdd = this.onClickAdd.bind(this);
        this.onCloseAddModal = this.onCloseAddModal.bind(this);
    }

    public componentDidMount(): void {
        this.props.investmentSaleTransfersList();
    }

    public render(): JSX.Element {
        const { investmentSaleTransfers } = this.props;
        const { isAddModalOpen } = this.state;

        if (!investmentSaleTransfers) {
            return (
                <Layout>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Investment Sale Transfers</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Investment Sale Transfers</Typography.Title>
                            <Spin />
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const columns: ColumnType<IInvestmentSaleTransfer>[] = [
            {
                defaultSortOrder: 'descend',
                render: (investmentSaleTransfer: IInvestmentSaleTransfer) => <Link to={`/investment-sale-transfers/${investmentSaleTransfer.uuid}/transactions`}>{dayjs(investmentSaleTransfer.createdTime).format('DD/MM/YYYY HH:mm')}</Link>,
                sorter: (a: IInvestmentSaleTransfer, b: IInvestmentSaleTransfer) => dayjs(a.createdTime) > dayjs(b.createdTime) ? 1 : -1,
                title: 'Created',
            },
            {
                dataIndex: 'investment',
                render: (investment: IInvestment) => investment.code,
                title: 'Investment',
            },
            {
                dataIndex: 'investment',
                render: (investment: IInvestment) => investment.loanCode,
                title: 'Loan',
            },
            {
                render: (investmentSaleTransfer: IInvestmentSaleTransfer) => investmentSaleTransfer.transactionCount,
                title: 'Transaction Count',
                width: '20%',
            },
            {
                render: (investmentSaleTransfer: IInvestmentSaleTransfer) => currencyFormatter.format(investmentSaleTransfer.transactionAmountTotal),
                title: 'Transaction Total',
                width: '20%',
            },
        ];

        return (
            <Layout>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Investment Sale Transfers</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Button onClick={this.onClickAdd}>Add Investment Sale Transfer</Button>
                        </Space>
                        <Typography.Title level={2}>Investment Sale Transfers</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.values(investmentSaleTransfers)}
                            pagination={{ defaultPageSize: 50 }}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
                <AddModal
                    isOpen={isAddModalOpen}
                    onCancel={this.onCloseAddModal}
                />
            </Layout>
        );
    }

    private onClickAdd(): void {
        this.setState({
            isAddModalOpen: true,
        });
    }

    private onCloseAddModal(): void {
        this.setState({
            isAddModalOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        investmentSaleTransfers: investmentSaleTransfersSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        investmentSaleTransfersList: () => dispatch(investmentSaleTransfersListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(List);
