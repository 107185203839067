import { SearchOutlined } from '@ant-design/icons';
import {
    Space,
    Spin,
    Table,
    Tag,
    Typography,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import {
    Link,
    match as routerMatch,
} from 'react-router-dom';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import { administratorsActiveSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import IApplication from '~Api/Application/IApplication';
import WorkflowStatusEnum from '~Api/Application/WorkflowStatusEnum';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import AssigneeSelector from '~Deals/AssigneeSelector';
import LoanProcessorSelector from '~Deals/LoanProcessorSelector';
import { IGlobalState } from '~reducer';
import {
    referralPartnerApplicationsListAction,
    referralPartnerDealsListAction,
    referralPartnerGetAction,
} from '~ReferralPartners/actions';
import {
    referralPartnerApplicationsSelector,
    referralPartnerSelector,
} from '~ReferralPartners/selectors';
import SearchDropdown from '~UI/SearchDropdown';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import { ColumnFilterItem, FilterDropdownProps } from 'antd/lib/table/interface';

const workflowStatusLabels: IDictionary<string> = {
    [WorkflowStatusEnum.ConditionalApproval]: 'Conditional Approval',
    [WorkflowStatusEnum.Draft]: 'Draft',
    [WorkflowStatusEnum.LegalDocuments]: 'Legal Documents',
    [WorkflowStatusEnum.New]: 'New',
    [WorkflowStatusEnum.Settlement]: 'Settlement',
    [WorkflowStatusEnum.Underwriting]: 'Underwriting',
    [WorkflowStatusEnum.Warehoused]: 'Settled',
};

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    activeAdministrators: IAdministrator[];
    applications: IDictionary<IApplication>;
    referralPartner: IReferralPartner;
}

interface IPropsDispatch {
    administratorsList: () => void;
    referralPartnerApplicationsList: () => void;
    referralPartnerGet: () => void;
    referralPartnerDealsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Applications extends React.Component<Props> {
    public componentDidMount(): void {
        const { activeAdministrators, applications, referralPartner } = this.props;

        if (!referralPartner) {
            this.props.referralPartnerGet();
        }

        if (!activeAdministrators) {
            this.props.administratorsList();
        }

        if (!applications) {
            this.props.referralPartnerApplicationsList();
            this.props.referralPartnerDealsList();
        }
    }

    public render(): JSX.Element {
        const { activeAdministrators, applications, referralPartner, match } = this.props;

        if (!referralPartner || !applications || !activeAdministrators) {
            return (
                <Layout uuid={match.params.uuid} section='applications'>
                    <Typography.Title level={2}>Applications</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const workflowFilters: ColumnFilterItem[] = _.keys(workflowStatusLabels).map((workflowStatus: string) => ({
            text: workflowStatusLabels[workflowStatus],
            value: workflowStatus,
        }));

        const columns: ColumnType<IApplication>[] = [
            {
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: boolean|number|string, application: IApplication) => application.code.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                render: (application: IApplication) => (
                    <Space>
                        <Link to={`/applications/${application.uuid}`}>{application.code || '-'}</Link>
                        {application.isExtension && <Tag color='blue'>E</Tag>}
                    </Space>
                ),
                sorter: (a: IApplication, b: IApplication) => a.code.localeCompare(b.code),
                title: 'Code',
                width: '10%',
            },
            {
                dataIndex: 'formattedName',
                filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
                filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
                onFilter: (value: boolean|number|string, application: IApplication) => application.formattedName.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
                render: (formattedName: string, application: IApplication) => <Link to={`/applications/${application.uuid}`}>{formattedName}</Link>,
                sorter: (a: IApplication, b: IApplication) => a.formattedName.localeCompare(b.formattedName),
                title: 'Name',
            },
            {
                dataIndex: 'loanAmount',
                render: (loanAmount: number) => currencyFormatter.format(loanAmount),
                sorter: (a: IApplication, b: IApplication) => a.loanAmount > b.loanAmount ? 1 : -1,
                title: 'Loan Amount',
                width: '15%',
            },
            {
                dataIndex: 'administratorUuid',
                filters: activeAdministrators.map((administrator: IAdministrator) => ({
                    text: administrator.name,
                    value: administrator.uuid,
                })),
                onFilter: (value: boolean|number|string, application: IApplication) => application.deal.administratorUuid === value,
                render: (administratorUuid: string, application: IApplication) => <AssigneeSelector deal={application.deal} />,
                title: 'Loan Officer',
                width: '15%',
            },
            {
                filters: activeAdministrators.map((administrator: IAdministrator) => ({
                    text: administrator.name,
                    value: administrator.uuid,
                })),
                onFilter: (value: boolean|number|string, application: IApplication) => application.deal.loanProcessorUuids.includes(value.toLocaleString()),
                render: (application: IApplication) => <LoanProcessorSelector deal={application.deal} />,
                title: 'Loan Processor',
                width: '15%',
            },
            {
                dataIndex: 'workflowStatus',
                filters: [
                    ...workflowFilters,
                    {
                        text: 'Closed',
                        value: 'CLOSED',
                    },
                ],
                onFilter: (value: boolean|number|string, application: IApplication) => value === (application.workflowStatus === WorkflowStatusEnum.Warehoused || !application.closeReason ? application.workflowStatus : 'CLOSED'),
                render: (workflowStatus: WorkflowStatusEnum, application: IApplication) => workflowStatus === WorkflowStatusEnum.Warehoused || !application.closeReason ? workflowStatusLabels[workflowStatus] : 'Closed',
                title: 'Status',
                width: '15%',
            },
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => dayjs(createdTime).fromNow(),
                sorter: (a: IApplication, b: IApplication) => dayjs(a.createdTime) > dayjs(b.createdTime)  ? 1 : -1,
                title: 'Created',
                width: '10%',
            },
        ];

        return (
            <Layout uuid={match.params.uuid} section='applications'>
                <Typography.Title level={2}>Applications</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(applications)}
                    rowKey='uuid'
                    size='middle'
                />
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        activeAdministrators: administratorsActiveSelector(state),
        applications: referralPartnerApplicationsSelector(state, ownProps.match.params.uuid),
        referralPartner: referralPartnerSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        referralPartnerApplicationsList: () => dispatch(referralPartnerApplicationsListAction(ownProps.match.params.uuid)),
        referralPartnerDealsList: () => dispatch(referralPartnerDealsListAction(ownProps.match.params.uuid)),
        referralPartnerGet: () => dispatch(referralPartnerGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Applications);
