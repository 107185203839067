import { Spin, Table, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestorUser from '~Api/Investor/IInvestorUser';
import IUser from '~Api/User/IUser';
import { IGlobalState } from '~reducer';
import { userGetAction, userInvestorsListAction } from '~Users/actions';
import { userInvestorsSelector, userSelector } from '~Users/selectors';
import Layout from './Layout';
import { ColumnType } from 'antd/lib/table';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    investorUsers: IInvestorUser[];
    user: IUser;
}

interface IPropsDispatch {
    investorUsersList: () => void;
    userGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Investors extends React.Component<Props> {
    public componentDidMount() {
        const { investorUsers, user } = this.props;

        if (!user) {
            this.props.userGet();
        }

        if (!investorUsers) {
            this.props.investorUsersList();
        }
    }

    public render(): JSX.Element {
        const { investorUsers, match, user } = this.props;

        if (!user || !investorUsers) {
            return (
                <Layout uuid={match.params.uuid} section='investors'>
                    <Typography.Title level={2}>Investors</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const columns: ColumnType<IInvestorUser>[] = [
            {
                render: (investorUser: IInvestorUser) => <Link to={`/investors/${investorUser.investor.uuid}`}>{investorUser.investor.name}</Link>,
                title: 'Name',
            },
            {
                dataIndex: 'createdTime',
                render: (createdTime: string) => createdTime ? <Tooltip title={dayjs(createdTime).format('Do MMMM YYYY HH:mm:ss')}><span>{dayjs(createdTime).fromNow()}</span></Tooltip> : '-',
                title: 'Access Added',
                width: '15%',
            },
        ];

        return (
            <Layout uuid={match.params.uuid} section='investors'>
                <Typography.Title level={2}>Investors</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={investorUsers}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                />
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        investorUsers: userInvestorsSelector(state, ownProps.match.params.uuid),
        user: userSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investorUsersList: () => dispatch(userInvestorsListAction(ownProps.match.params.uuid)),
        userGet: () => dispatch(userGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Investors);
