import { Button, Space, Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import ILoan from '~Api/Loan/ILoan';
import ILoanGracePeriod from '~Api/Loan/ILoanGracePeriod';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import {
    loanGetAction,
    loanGracePeriodsListAction,
} from '~Loans/actions';
import {
    loanGracePeriodsSelector,
    loanSelector,
} from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import GracePeriodAddModal from './GracePeriodAddModal';
import Layout from './Layout';

interface IState {
    isAddModalOpen: boolean;
}

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    currentUser: IAuthUser;
    gracePeriods: IDictionary<ILoanGracePeriod>;
    loan: ILoan;
}

interface IPropsDispatch {
    gracePeriodsList: () => void;
    loanGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class GracePeriods extends React.Component<Props, IState> {
    public state: IState = {
        isAddModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAdd = this.onClickAdd.bind(this);
        this.onCloseAddModal = this.onCloseAddModal.bind(this);
    }

    public componentDidMount(): void {
        const { loan, gracePeriods } = this.props;

        if (!loan) {
            this.props.loanGet();
        }

        if (!gracePeriods) {
            this.props.gracePeriodsList();
        }
    }

    public render(): JSX.Element {
        const { currentUser, gracePeriods, loan, match } = this.props;
        const { isAddModalOpen } = this.state;

        if (!loan || !gracePeriods) {
            return (
                <Layout uuid={match.params.uuid} section='grace-periods'>
                    <Typography.Title level={2}>Grace Periods</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const actionsBlock: JSX.Element = currentUser.permissions.includes(PermissionsEnum.LoanGracePeriodsAdd) && (
            <Space className='actions'>
                <Button onClick={this.onClickAdd}>Add Grace Period</Button>
            </Space>
        );

        const columns: ColumnsType<ILoanGracePeriod> = [
            {
                dataIndex: 'startDate',
                render: (startDate: string) => startDate,
                sorter: (a: ILoanGracePeriod, b: ILoanGracePeriod) => dayjs(a.startDate) > dayjs(b.startDate) ? 1 : -1,
                title: 'Start Date',
            },
            {
                dataIndex: 'code',
                render: (code: string) => code,
                title: 'Code',
            },
        ];

        return (
            <Layout uuid={match.params.uuid} section='grace-periods'>
                {actionsBlock}
                <Typography.Title level={2}>Grace Periods</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(gracePeriods)}
                    rowKey='uuid'
                    pagination={false}
                    size='middle'
                />
                <GracePeriodAddModal
                    loanUuid={loan.uuid}
                    isOpen={isAddModalOpen}
                    onClose={this.onCloseAddModal}
                />
            </Layout>
        );
    }

    private onClickAdd(): void {
        this.setState({
            isAddModalOpen: true,
        });
    }

    private onCloseAddModal(): void {
        this.setState({
            isAddModalOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        currentUser: authCurrentUserSelector(state),
        gracePeriods: loanGracePeriodsSelector(state, ownProps.match.params.uuid),
        loan: loanSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        gracePeriodsList: () => dispatch(loanGracePeriodsListAction(ownProps.match.params.uuid)),
        loanGet: () => dispatch(loanGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GracePeriods);
