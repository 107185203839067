import _ from 'lodash';
import { AnyAction } from 'redux';
import IInvestorIncomeTrustTransaction from '~Api/IncomeTrust/IInvestorIncomeTrustTransaction';
import IReservation from '~Api/Investment/IReservation';
import IAccount from '~Api/Investor/IAccount';
import IAnnualStatement from '~Api/Investor/IAnnualStatement';
import IBankAccount from '~Api/Investor/IBankAccount';
import ICompany from '~Api/Investor/ICompany';
import IComplianceDocument from '~Api/Investor/IComplianceDocument';
import IDocument from '~Api/Investor/IDocument';
import IHistory from '~Api/Investor/IHistory';
import IIndividual from '~Api/Investor/IIndividual';
import IInvalidInvestorTransaction from '~Api/Investor/IInvalidInvestorTransaction';
import IInvestor from '~Api/Investor/IInvestor';
import IInvestorAccountInvestment from '~Api/Investor/IInvestorAccountInvestment';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import IInvestorAccountTransaction from '~Api/Investor/IInvestorAccountTransaction';
import IInvestorUser from '~Api/Investor/IInvestorUser';
import IMonthlyStatement from '~Api/Investor/IMonthlyStatement';
import INote from '~Api/Investor/INote';
import IPendingWithdrawal from '~Api/Investor/IPendingWithdrawal';
import IReferral from '~Api/Investor/IReferral';
import IShare from '~Api/Investor/IShare';
import ITransaction from '~Api/Investor/ITransaction';
import ITrust from '~Api/Investor/ITrust';
import {
    IInvestmentInvestorsSetAction,
    IInvestmentReservationsSetAction,
    IInvestmentTransactionInvestorsSetAction,
} from '~Investments/actions';
import InvestmentsActionsEnum from '~Investments/ActionsEnum';
import { IDictionary } from '~utilities/IDictionary';
import {
    IWithdrawalAbaInvestorTransactionsSetAction,
    IWithdrawalAbasInvalidInvestorTransactionsSetAction,
    IWithdrawalAbasUnallocatedInvestorTransactionsSetAction,
} from '~WithdrawalAbas/actions';
import WithdrawalAbasActionsEnum from '~WithdrawalAbas/ActionsEnum';
import {
    IInvestorAccountAnnualStatementDeleteAction,
    IInvestorAccountAnnualStatementSetAction,
    IInvestorAccountAnnualStatementsSetAction,
    IInvestorAccountIncomeTrustSetAction,
    IInvestorAccountIncomeTrustTransactionsSetAction,
    IInvestorAccountIncomeTrustsSetAction,
    IInvestorAccountInvestmentSetAction,
    IInvestorAccountInvestmentTransactionsSetAction,
    IInvestorAccountInvestmentsSetAction,
    IInvestorAccountMonthlyStatementDeleteAction,
    IInvestorAccountMonthlyStatementSetAction,
    IInvestorAccountMonthlyStatementsSetAction,
    IInvestorAccountPendingDepositApproveAction,
    IInvestorAccountPendingDepositRejectAction,
    IInvestorAccountSetAction,
    IInvestorAccountSharesSetAction,
    IInvestorAccountTransactionsSetAction,
    IInvestorAccountWithdrawalAbaInvestorTransactionsSetAction,
    IInvestorAccountsIncomeTrustSetAction,
    IInvestorAccountsPendingApprovalSetAction,
    IInvestorBankAccountSetAction,
    IInvestorCompanyValueSetAction,
    IInvestorComplianceDocumentDeleteAction,
    IInvestorComplianceDocumentSetAction,
    IInvestorComplianceDocumentsSetAction,
    IInvestorContactDateAction,
    IInvestorDocumentSetAction,
    IInvestorDocumentsSetAction,
    IInvestorHistoriesSetAction,
    IInvestorIndividualSetAction,
    IInvestorIndividualValueSetAction,
    IInvestorNoteRemoveAction,
    IInvestorNoteSetAction,
    IInvestorNotesSetAction,
    IInvestorPendingWithdrawalSetAction,
    IInvestorPendingWithdrawalsUnapprovedSetAction,
    IInvestorReferralSetAction,
    IInvestorReferralsInvestorSetAction,
    IInvestorReferralsSetAction,
    IInvestorSetAction,
    IInvestorTrustValueSetAction,
    IInvestorUsersSetAction,
    IInvestorValueSetAction,
    IInvestorsBoardSetAction,
    IInvestorsManagedSetAction,
    IInvestorsPrivateSetAction,
    IInvestorsSearchResultsSetAction,
    IInvestorsSetAction,
    IPendingDepositsSetAction,
} from './actions';
import InvestorsActionsEnum from './ActionsEnum';
import { IInvestorMovementsSetAction } from '~Accounts/actions';
import AccountsActionsEnum from '~Accounts/ActionsEnum';
import InvestmentSaleTransfersActionsEnum from '~InvestmentSaleTransfers/ActionsEnum';
import {
    IInvestmentSaleTransferInvestorAccountInvestmentTransactionsSetAction,
    IInvestmentSaleTransfersUnallocatedInvestmentsSetAction,
} from '~InvestmentSaleTransfers/actions';
import IUnallocatedInvestment from '~Api/InvestmentSaleTransfer/IUnallocatedInvestment';
import IWithdrawalAbaInvestorTransaction from '~Api/WithdrawalAba/IWithdrawalAbaInvestorTransaction';
import IInvestorAccountIncomeTrust from '~Api/IncomeTrust/IInvestorAccountIncomeTrust';
import AdvisersActionsEnum from '~Advisers/ActionsEnum';
import { IAdviserInvestorsSetAction } from '~Advisers/actions';

export interface IInvestorsState {
    accounts: IDictionary<IAccount>;
    accountsIncomeTrustUuids: string[];
    accountsListed: boolean;
    accountsPendingApprovalUuids: string[];
    annualStatements: IDictionary<IAnnualStatement>;
    bankAccounts: IDictionary<IBankAccount>;
    boardListed: boolean;
    complianceDocuments: IDictionary<IComplianceDocument>;
    documents: {
        [investorUuid: string]: {
            [documentUuid: number]: IDocument;
        };
    };
    histories: {
        [investorUuid: string]: {
            [investorHistoryId: string]: IHistory;
        };
    };
    incomeTrustTransactions: IDictionary<IDictionary<IInvestorIncomeTrustTransaction>>;
    incomeTrusts: IDictionary<IDictionary<IInvestorAccountIncomeTrust>>;
    investorAccountAnnualStatementUuids: IDictionary<string[]>;
    investorAccountInvestmentTransactionUuids: IDictionary<string[]>;
    investorAccountInvestmentTransactions: IDictionary<IInvestorAccountInvestmentTransaction>;
    investorAccountInvestmentUuids: IDictionary<string[]>;
    investorAccountInvestments: IDictionary<IInvestorAccountInvestment>;
    investorAccountMonthlyStatementUuids: IDictionary<string[]>;
    investorAccountTransactions: IDictionary<IInvestorAccountTransaction>;
    investorPendingWithdrawals: IDictionary<IPendingWithdrawal>;
    investorBankAccountUuids: IDictionary<string[]>;
    investorComplianceDocumentUuids: IDictionary<string[]>;
    investorUsers: IDictionary<IDictionary<IInvestorUser>>;
    investors: IDictionary<IInvestor>;
    investorsListed: boolean;
    investorsSearchResultUuids: string[];
    monthlyStatements: IDictionary<IMonthlyStatement>;
    managedListed: boolean;
    notes: IDictionary<IDictionary<INote>>;
    pendingDepositUuids: string[];
    privateListed: boolean;
    referrals: {
        [investorUuid: string]: {
            [referralUuid: string]: IReferral;
        };
    };
    referralsListed: boolean;
    shares: IDictionary<IDictionary<IShare>>;
    transactions: {
        [accountUuid: string]: {
            [transactionId: string]: ITransaction;
        };
    };
    investorAccountWithdrawalAbaInvestorTransactions: IDictionary<IDictionary<IWithdrawalAbaInvestorTransaction>>;
}

const initialData: IInvestorsState = {
    accounts: {},
    accountsIncomeTrustUuids: null,
    accountsListed: false,
    accountsPendingApprovalUuids: null,
    annualStatements: {},
    bankAccounts: {},
    boardListed: false,
    complianceDocuments: {},
    documents: {},
    histories: {},
    incomeTrustTransactions: {},
    incomeTrusts: {},
    investorAccountAnnualStatementUuids: {},
    investorAccountInvestmentTransactionUuids: {},
    investorAccountInvestmentTransactions: {},
    investorAccountInvestmentUuids: {},
    investorAccountInvestments: {},
    investorAccountMonthlyStatementUuids: {},
    investorAccountTransactions: {},
    investorAccountWithdrawalAbaInvestorTransactions: {},
    investorBankAccountUuids: {},
    investorComplianceDocumentUuids: {},
    investorPendingWithdrawals: null,
    investorUsers: {},
    investors: null,
    investorsListed: false,
    investorsSearchResultUuids: null,
    managedListed: false,
    monthlyStatements: {},
    notes: {},
    pendingDepositUuids: null,
    privateListed: false,
    referrals: {},
    referralsListed: false,
    shares: {},
    transactions: {},
};

function statifyInvestorAccounts(state: IInvestorsState, investorAccounts: IAccount[]): IInvestorsState {
    let clonedState: IInvestorsState = { ...state };

    _.each(investorAccounts, (investorAccount: IAccount) => {
        clonedState = statifyInvestorAccount(clonedState, investorAccount);
    });

    return clonedState;
}

function statifyInvestorAccount(state: IInvestorsState, investorAccount: IAccount): IInvestorsState {
    const accounts: IDictionary<IAccount> = { ...state.accounts };
    const incomeTrusts: IDictionary<IDictionary<IInvestorAccountIncomeTrust>> = { ...state.incomeTrusts };

    _.each(investorAccount.incomeTrusts, (incomeTrust: IInvestorAccountIncomeTrust) => {
        incomeTrusts[investorAccount.uuid] = {
            ...incomeTrusts[investorAccount.uuid],
            [incomeTrust.uuid]: incomeTrust,
        };
    });

    accounts[investorAccount.uuid] = dehydrateInvestorAccount(investorAccount);

    return {
        ...state,
        accounts,
        incomeTrusts,
    };
}

function statifyInvestorAccountTransactions(state: IInvestorsState, investorAccountTransactions: IInvestorAccountTransaction[]): IInvestorsState {
    let clonedState: IInvestorsState = { ...state };

    _.each(investorAccountTransactions, (investorAccountTransaction: IInvestorAccountTransaction) => {
        clonedState = statifyInvestorAccountTransaction(clonedState, investorAccountTransaction);
    });

    return clonedState;
}

function statifyInvestorAccountTransaction(state: IInvestorsState, investorAccountTransaction: IInvestorAccountTransaction): IInvestorsState {
    let clonedState: IInvestorsState = { ...state };

    if (investorAccountTransaction.investorAccount) {
        clonedState = statifyInvestorAccount(clonedState, investorAccountTransaction.investorAccount);
    }

    return {
        ...clonedState,
        investorAccountTransactions: {
            ...state.investorAccountTransactions,
            [investorAccountTransaction.uuid]: _.omit(investorAccountTransaction, ['investorAccount', 'investor']),
        },
    };
}

function statifyInvestors(state: IInvestorsState, investors: IInvestor[]): IInvestorsState {
    const accounts: IDictionary<IAccount> = { ...state.accounts };
    const bankAccounts: IDictionary<IBankAccount> = { ...state.bankAccounts };
    const investorBankAccountUuids: IDictionary<string[]> = { ...state.investorBankAccountUuids };
    const stateInvestors: IDictionary<IInvestor> = { ...state.investors };

    investors.forEach((investor: IInvestor) => {
        investor.accountTypeUuids = {};

        _.each(investor.accounts, (account: IAccount) => {
            accounts[account.uuid] = account;
            investor.accountTypeUuids[account.accountType] = account.uuid;
        });

        investorBankAccountUuids[investor.uuid] = [];

        _.each(investor.bankAccounts, (bankAccount: IBankAccount) => {
            bankAccounts[bankAccount.uuid] = bankAccount;
            investorBankAccountUuids[investor.uuid].push(bankAccount.uuid);
        });

        stateInvestors[investor.uuid] = _.omit(investor, ['accounts', 'bankAccounts']);
    });

    return {
        ...state,
        accounts,
        bankAccounts,
        investorBankAccountUuids,
        investors: stateInvestors,
    };
}

function dehydrateInvestorAccountInvestment(investorAccountInvestment: IInvestorAccountInvestment): IInvestorAccountInvestment {
    return _.omit(investorAccountInvestment, ['investment', 'investor']);
}

function dehydrateInvestorAccount(investorAccount: IAccount): IAccount {
    return _.omit(investorAccount, ['incomeTrusts']);
}

export function investorsReducer(state: IInvestorsState = initialData, action: AnyAction): IInvestorsState {
    switch (action.type) {
        case AdvisersActionsEnum.AdviserInvestorsSet: {
            const typedAction: IAdviserInvestorsSetAction = action as IAdviserInvestorsSetAction;

            return {
                ...state,
                ...statifyInvestors(state, typedAction.investors),
            };
        }

        case InvestmentsActionsEnum.InvestmentReservationsSet: {
            const typedAction: IInvestmentReservationsSetAction = action as IInvestmentReservationsSetAction;

            let clonedState: IInvestorsState = { ...state };

            typedAction.reservations.forEach((reservation: IReservation) => {
                clonedState = statifyInvestorAccount(clonedState, reservation.investorAccount);
            });

            return clonedState;
        }

        case InvestorsActionsEnum.InvestorsBoardSet: {
            const typedAction: IInvestorsBoardSetAction = action as IInvestorsBoardSetAction;

            return {
                ...state,
                ...statifyInvestors(state, typedAction.investors),
                boardListed: true,
            };
        }

        case InvestorsActionsEnum.InvestorsPrivateSet: {
            const typedAction: IInvestorsPrivateSetAction = action as IInvestorsPrivateSetAction;

            return {
                ...state,
                ...statifyInvestors(state, typedAction.investors),
                privateListed: true,
            };
        }

        case InvestorsActionsEnum.InvestorsSet: {
            const typedAction: IInvestorsSetAction = action as IInvestorsSetAction;

            return {
                ...state,
                ...statifyInvestors(state, typedAction.investors),
                accountsListed: true,
                investorsListed: true,
            };
        }

        case InvestorsActionsEnum.InvestorsSearchResultsSet: {
            const typedAction: IInvestorsSearchResultsSetAction = action as IInvestorsSearchResultsSetAction;

            const investorsSearchResultUuids: string[] = _.map(typedAction.investors, (investor: IInvestor) => investor.uuid);

            return {
                ...state,
                ...statifyInvestors(state, typedAction.investors),
                investorsSearchResultUuids,
            };
        }

        case InvestorsActionsEnum.InvestorsSearchResultsClear: {
            return {
                ...state,
                investorsSearchResultUuids: [],
            };
        }

        case InvestorsActionsEnum.InvestorAccountSet: {
            const typedAction: IInvestorAccountSetAction = action as IInvestorAccountSetAction;

            return {
                ...state,
                ...statifyInvestorAccount(state, typedAction.account),
            };
        }

        case InvestorsActionsEnum.InvestorAccountInvestmentsSet: {
            const typedAction: IInvestorAccountInvestmentsSetAction = action as IInvestorAccountInvestmentsSetAction;

            const investorAccountInvestments: IDictionary<IInvestorAccountInvestment> = {};
            const investorAccountInvestmentUuids: string[] = [];

            typedAction.investorAccountInvestments.forEach((investorAccountInvestment: IInvestorAccountInvestment) => {
                investorAccountInvestments[investorAccountInvestment.uuid] = dehydrateInvestorAccountInvestment(investorAccountInvestment);
                investorAccountInvestmentUuids.push(investorAccountInvestment.uuid);
            });

            return {
                ...state,
                investorAccountInvestmentUuids: {
                    ...state.investorAccountInvestmentUuids,
                    [typedAction.accountUuid]: investorAccountInvestmentUuids,
                },
                investorAccountInvestments: {
                    ...state.investorAccountInvestments,
                    ...investorAccountInvestments,
                },
            };
        }

        case InvestorsActionsEnum.InvestorAccountInvestmentSet: {
            const typedAction: IInvestorAccountInvestmentSetAction = action as IInvestorAccountInvestmentSetAction;

            return {
                ...state,
                ...statifyInvestors(state, [typedAction.investorAccountInvestment.investor]),
                investorAccountInvestments: {
                    ...state.investorAccountInvestments,
                    [typedAction.investorAccountInvestment.uuid]: dehydrateInvestorAccountInvestment(typedAction.investorAccountInvestment),
                },
            };
        }

        case InvestorsActionsEnum.InvestorAccountInvestmentTransactionsSet: {
            const typedAction: IInvestorAccountInvestmentTransactionsSetAction = action as IInvestorAccountInvestmentTransactionsSetAction;

            const investorAccountInvestmentTransactions: IDictionary<IInvestorAccountInvestmentTransaction> = {};
            const investorAccountInvestmentTransactionUuids: string[] = [];

            typedAction.transactions.forEach((transaction: IInvestorAccountInvestmentTransaction) => {
                investorAccountInvestmentTransactions[transaction.uuid] = transaction;
                investorAccountInvestmentTransactionUuids.push(transaction.uuid);
            });

            return {
                ...state,
                investorAccountInvestmentTransactionUuids: {
                    ...state.investorAccountInvestmentTransactionUuids,
                    [typedAction.investorAccountInvestmentUuid]: investorAccountInvestmentTransactionUuids,
                },
                investorAccountInvestmentTransactions: {
                    ...state.investorAccountInvestmentTransactions,
                    ...investorAccountInvestmentTransactions,
                },
            };
        }

        case InvestmentsActionsEnum.InvestmentTransactionInvestorsSet: {
            const typedAction: IInvestmentTransactionInvestorsSetAction = action as IInvestmentTransactionInvestorsSetAction;

            return {
                ...state,
                investorAccountInvestmentTransactions: {
                    ...state.investorAccountInvestmentTransactions,
                    ..._.keyBy(typedAction.transactions, 'uuid'),
                },
            };
        }

        case InvestmentsActionsEnum.InvestmentInvestorsSet: {
            const typedAction: IInvestmentInvestorsSetAction = action as IInvestmentInvestorsSetAction;

            const investorAccountInvestments: IDictionary<IInvestorAccountInvestment> = {};
            const investors: IInvestor[] = [];

            typedAction.investorAccountInvestments.forEach((investorAccountInvestment: IInvestorAccountInvestment) => {
                investorAccountInvestments[investorAccountInvestment.uuid] = dehydrateInvestorAccountInvestment(investorAccountInvestment);
                investors.push(investorAccountInvestment.investor);
            });

            return {
                ...state,
                ...statifyInvestors(state, investors),
                investorAccountInvestments: {
                    ...state.investorAccountInvestments,
                    ...investorAccountInvestments,
                },
            };
        }

        case InvestorsActionsEnum.InvestorAccountSharesSet: {
            const typedAction: IInvestorAccountSharesSetAction = action as IInvestorAccountSharesSetAction;

            return {
                ...state,
                shares: {
                    ...state.shares,
                    [typedAction.accountUuid]: _.keyBy(typedAction.shares, 'uuid'),
                },
            };
        }

        case InvestorsActionsEnum.InvestorAccountTransactionsSet: {
            const typedAction: IInvestorAccountTransactionsSetAction = action as IInvestorAccountTransactionsSetAction;

            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    [typedAction.accountUuid]: _.keyBy(typedAction.transactions, 'uuid'),
                },
            };
        }

        case InvestorsActionsEnum.InvestorPendingWithdrawalSet: {
            const typedAction: IInvestorPendingWithdrawalSetAction = action as IInvestorPendingWithdrawalSetAction;

            return {
                ...state,
                investorPendingWithdrawals: {
                    ...state.investorPendingWithdrawals,
                    [typedAction.investorPendingWithdrawal.uuid]: typedAction.investorPendingWithdrawal,
                },
            };
        }

        case InvestorsActionsEnum.InvestorPendingWithdrawalsUnapprovedSet: {
            const typedAction: IInvestorPendingWithdrawalsUnapprovedSetAction = action as IInvestorPendingWithdrawalsUnapprovedSetAction;

            let clonedState: IInvestorsState = {
                ...state,
                investorPendingWithdrawals: {},
            };

            _.each(typedAction.investorPendingWithdrawalsUnapproved, (investorPendingWithdrawal: IPendingWithdrawal) => {
                clonedState = statifyInvestorAccountTransaction(clonedState, investorPendingWithdrawal.investorAccountTransaction);

                clonedState.investorPendingWithdrawals[investorPendingWithdrawal.uuid] = _.omit(investorPendingWithdrawal, ['investorAccountTransaction']);
            });

            return {
                ...state,
                ...clonedState,
            };
        }

        case InvestorsActionsEnum.InvestorAccountAnnualStatementDelete: {
            const typedAction: IInvestorAccountAnnualStatementDeleteAction = action as IInvestorAccountAnnualStatementDeleteAction;

            const annualStatement: IAnnualStatement = state.annualStatements[typedAction.annualStatementUuid];

            return {
                ...state,
                annualStatements: _.omit(state.annualStatements || {}, typedAction.annualStatementUuid),
                investorAccountAnnualStatementUuids: {
                    ...state.investorAccountAnnualStatementUuids,
                    [annualStatement.investorAccountUuid]: _.pull(state.investorAccountAnnualStatementUuids[annualStatement.investorAccountUuid] || [], annualStatement.uuid),
                },
            };
        }

        case InvestorsActionsEnum.InvestorAccountAnnualStatementSet: {
            const typedAction: IInvestorAccountAnnualStatementSetAction = action as IInvestorAccountAnnualStatementSetAction;

            return {
                ...state,
                annualStatements: {
                    ...state.annualStatements,
                    [typedAction.annualStatement.uuid]: typedAction.annualStatement,
                },
                investorAccountAnnualStatementUuids: {
                    ...state.investorAccountAnnualStatementUuids,
                    [typedAction.annualStatement.investorAccountUuid]: _.uniq([...(state.investorAccountAnnualStatementUuids[typedAction.annualStatement.investorAccountUuid] || []), typedAction.annualStatement.uuid]),
                },
            };
        }

        case InvestorsActionsEnum.InvestorAccountAnnualStatementsSet: {
            const typedAction: IInvestorAccountAnnualStatementsSetAction = action as IInvestorAccountAnnualStatementsSetAction;

            const annualStatements: IDictionary<IAnnualStatement> = { ...state.annualStatements };
            const investorAccountAnnualStatementUuids: IDictionary<string[]> = { ...state.investorAccountAnnualStatementUuids };

            investorAccountAnnualStatementUuids[typedAction.investorAccountUuid] = [];

            _.each(typedAction.annualStatements, (annualStatement: IAnnualStatement) => {
                annualStatements[annualStatement.uuid] = annualStatement;
                investorAccountAnnualStatementUuids[typedAction.investorAccountUuid].push(annualStatement.uuid);
            });

            return {
                ...state,
                annualStatements,
                investorAccountAnnualStatementUuids,
            };
        }

        case InvestorsActionsEnum.InvestorAccountIncomeTrustTransactionsSet: {
            const typedAction: IInvestorAccountIncomeTrustTransactionsSetAction = action as IInvestorAccountIncomeTrustTransactionsSetAction;

            return {
                ...state,
                incomeTrustTransactions: {
                    ...state.incomeTrustTransactions,
                    [typedAction.investorAccountIncomeTrustUuid]: _.keyBy(typedAction.transactions, 'uuid'),
                },
            };
        }

        case InvestorsActionsEnum.InvestorAccountIncomeTrustSet: {
            const typedAction: IInvestorAccountIncomeTrustSetAction = action as IInvestorAccountIncomeTrustSetAction;

            return {
                ...state,
                incomeTrusts: {
                    ...state.incomeTrusts,
                    [typedAction.incomeTrust.investorAcountUuid]: {
                        ...state.incomeTrusts[typedAction.incomeTrust.investorAcountUuid],
                        [typedAction.incomeTrust.uuid]: typedAction.incomeTrust,
                    },
                },
            };
        }

        case InvestorsActionsEnum.InvestorAccountIncomeTrustsSet: {
            const typedAction: IInvestorAccountIncomeTrustsSetAction = action as IInvestorAccountIncomeTrustsSetAction;

            return {
                ...state,
                incomeTrusts: {
                    ...state.incomeTrusts,
                    [typedAction.investorAccountUuid]: _.keyBy(typedAction.incomeTrusts, 'uuid'),
                },
            };
        }

        case InvestorsActionsEnum.InvestorAccountMonthlyStatementsSet: {
            const typedAction: IInvestorAccountMonthlyStatementsSetAction = action as IInvestorAccountMonthlyStatementsSetAction;

            const monthlyStatements: IDictionary<IMonthlyStatement> = { ...state.monthlyStatements };
            const investorAccountMonthlyStatementUuids: IDictionary<string[]> = { ...state.investorAccountMonthlyStatementUuids };

            investorAccountMonthlyStatementUuids[typedAction.investorAccountUuid] = [];

            _.each(typedAction.monthlyStatements, (monthlyStatement: IMonthlyStatement) => {
                monthlyStatements[monthlyStatement.uuid] = monthlyStatement;
                investorAccountMonthlyStatementUuids[typedAction.investorAccountUuid].push(monthlyStatement.uuid);
            });

            return {
                ...state,
                investorAccountMonthlyStatementUuids,
                monthlyStatements,
            };
        }

        case InvestorsActionsEnum.InvestorAccountMonthlyStatementDelete: {
            const typedAction: IInvestorAccountMonthlyStatementDeleteAction = action as IInvestorAccountMonthlyStatementDeleteAction;

            const monthlyStatement: IMonthlyStatement = state.monthlyStatements[typedAction.monthlyStatementUuid];

            return {
                ...state,
                investorAccountMonthlyStatementUuids: {
                    ...state.investorAccountMonthlyStatementUuids,
                    [monthlyStatement.investorAccountUuid]: _.pull(state.investorAccountMonthlyStatementUuids[monthlyStatement.investorAccountUuid] || [], monthlyStatement.uuid),
                },
                monthlyStatements: _.omit(state.monthlyStatements || {}, typedAction.monthlyStatementUuid),
            };
        }

        case InvestorsActionsEnum.InvestorAccountMonthlyStatementSet: {
            const typedAction: IInvestorAccountMonthlyStatementSetAction = action as IInvestorAccountMonthlyStatementSetAction;

            return {
                ...state,
                investorAccountMonthlyStatementUuids: {
                    ...state.investorAccountMonthlyStatementUuids,
                    [typedAction.monthlyStatement.investorAccountUuid]: _.uniq([...(state.investorAccountMonthlyStatementUuids[typedAction.monthlyStatement.investorAccountUuid] || []), typedAction.monthlyStatement.uuid]),
                },
                monthlyStatements: {
                    ...state.monthlyStatements,
                    [typedAction.monthlyStatement.uuid]: typedAction.monthlyStatement,
                },
            };
        }

        case InvestorsActionsEnum.InvestorAccountsIncomeTrustSet: {
            const typedAction: IInvestorAccountsIncomeTrustSetAction = action as IInvestorAccountsIncomeTrustSetAction;

            const investorAccountUuids: string[] = [];

            typedAction.investorAccounts.forEach((investorAccount: IAccount) => {
                investorAccountUuids.push(investorAccount.uuid);
            });

            return {
                ...state,
                ...statifyInvestorAccounts(state, typedAction.investorAccounts),
                accountsIncomeTrustUuids: investorAccountUuids,
            };
        }

        case InvestorsActionsEnum.InvestorAccountsPendingApprovalSet: {
            const typedAction: IInvestorAccountsPendingApprovalSetAction = action as IInvestorAccountsPendingApprovalSetAction;
            const investorAccountUuids: string[] = [];

            typedAction.investorAccounts.forEach((investorAccount: IAccount) => {
                investorAccountUuids.push(investorAccount.uuid);
            });

            return {
                ...state,
                ...statifyInvestorAccounts(state, typedAction.investorAccounts),
                accountsPendingApprovalUuids: investorAccountUuids,
            };
        }

        case InvestorsActionsEnum.InvestorAccountPendingDepositApprove: {
            const typedAction: IInvestorAccountPendingDepositApproveAction = action as IInvestorAccountPendingDepositApproveAction;

            return {
                ...state,
                pendingDepositUuids: _.without(state.pendingDepositUuids, typedAction.investorAccountUuid),
            };
        }

        case InvestorsActionsEnum.InvestorAccountPendingDepositReject: {
            const typedAction: IInvestorAccountPendingDepositRejectAction = action as IInvestorAccountPendingDepositRejectAction;

            return {
                ...state,
                pendingDepositUuids: _.without(state.pendingDepositUuids, typedAction.investorAccountUuid),
            };
        }

        case InvestorsActionsEnum.InvestorAccountWithdrawalAbaInvestorTransactionsSet: {
            const typedAction: IInvestorAccountWithdrawalAbaInvestorTransactionsSetAction = action as IInvestorAccountWithdrawalAbaInvestorTransactionsSetAction;

            return {
                ...state,
                investorAccountWithdrawalAbaInvestorTransactions: {
                    ...state.investorAccountWithdrawalAbaInvestorTransactions,
                    [typedAction.investorAccountUuid]: _.keyBy(typedAction.investorAccountWithdrawalAbaInvestorTransactions, 'uuid'),
                },
            };
        }

        case InvestorsActionsEnum.InvestorBankAccountSet: {
            const typedAction: IInvestorBankAccountSetAction = action as IInvestorBankAccountSetAction;

            const investorBankAccountUuids: IDictionary<string[]> = { ...state.investorBankAccountUuids };

            investorBankAccountUuids[typedAction.bankAccount.investorUuid].push(typedAction.bankAccount.uuid);

            return {
                ...state,
                bankAccounts: {
                    ...state.bankAccounts,
                    [typedAction.bankAccount.uuid]: typedAction.bankAccount,
                },
                investorBankAccountUuids,
            };
        }

        case InvestorsActionsEnum.InvestorCompanyValueSet: {
            const typedAction: IInvestorCompanyValueSetAction = action as IInvestorCompanyValueSetAction;

            const company: ICompany = state.investors[typedAction.uuid].company ? { ...state.investors[typedAction.uuid].company } : {
                australianCompanyNumber: null,
                name: null,
                postcode: null,
                state: null,
                streetAddress: null,
                suburb: null,
                taxFileNumber: null,
            };

            company[typedAction.key] = typedAction.value;

            return {
                ...state,
                investors: {
                    ...state.investors,
                    [typedAction.uuid]: {
                        ...state.investors[typedAction.uuid],
                        company,
                    },
                },
            };
        }

        case InvestorsActionsEnum.InvestorComplianceDocumentsSet: {
            const typedAction: IInvestorComplianceDocumentsSetAction = action as IInvestorComplianceDocumentsSetAction;

            const complianceDocuments: IDictionary<IComplianceDocument> = {};
            const complianceDocumentUuids: string[] = [];

            typedAction.complianceDocuments.forEach((complianceDocument: IComplianceDocument) => {
                complianceDocuments[complianceDocument.uuid] = complianceDocument;
                complianceDocumentUuids.push(complianceDocument.uuid);
            });

            return {
                ...state,
                complianceDocuments: {
                    ...state.complianceDocuments,
                    ...complianceDocuments,
                },
                investorComplianceDocumentUuids: {
                    ...state.investorComplianceDocumentUuids,
                    [typedAction.investorUuid]: complianceDocumentUuids,
                },
            };
        }

        case InvestorsActionsEnum.InvestorComplianceDocumentDelete: {
            const typedAction: IInvestorComplianceDocumentDeleteAction = action as IInvestorComplianceDocumentDeleteAction;

            return {
                ...state,
                complianceDocuments: _.omit(state.complianceDocuments, typedAction.complianceDocumentUuid),
            };
        }

        case InvestorsActionsEnum.InvestorComplianceDocumentSet: {
            const typedAction: IInvestorComplianceDocumentSetAction = action as IInvestorComplianceDocumentSetAction;

            return {
                ...state,
                complianceDocuments: {
                    ...state.complianceDocuments,
                    [typedAction.complianceDocument.uuid]: typedAction.complianceDocument,
                },
                investorComplianceDocumentUuids: {
                    ...state.investorComplianceDocumentUuids,
                    [typedAction.investorUuid]: [
                        ...(state.investorComplianceDocumentUuids[typedAction.investorUuid] || []),
                        typedAction.complianceDocument.uuid,
                    ],
                },
            };
        }

        case InvestorsActionsEnum.InvestorContactDate: {
            const typedAction: IInvestorContactDateAction = action as IInvestorContactDateAction;

            return {
                ...state,
                investors: {
                    ...state.investors,
                    [typedAction.investorUuid]: {
                        ...state.investors[typedAction.investorUuid],
                        contactDateNext: typedAction.date,
                    },
                },
            };
        }

        case InvestorsActionsEnum.InvestorDocumentsSet: {
            const typedAction: IInvestorDocumentsSetAction = action as IInvestorDocumentsSetAction;

            return {
                ...state,
                documents: {
                    ...state.documents,
                    [typedAction.investorUuid]: _.keyBy(typedAction.documents, 'uuid'),
                },
            };
        }

        case InvestorsActionsEnum.InvestorDocumentSet: {
            const typedAction: IInvestorDocumentSetAction = action as IInvestorDocumentSetAction;

            return {
                ...state,
                documents: {
                    ...state.documents,
                    [typedAction.investorUuid]: {
                        ...state.documents[typedAction.investorUuid],
                        [typedAction.document.uuid]: typedAction.document,
                    },
                },
            };
        }

        case InvestorsActionsEnum.InvestorHistoriesSet: {
            const typedAction: IInvestorHistoriesSetAction = action as IInvestorHistoriesSetAction;

            return {
                ...state,
                histories: {
                    ...state.histories,
                    [typedAction.uuid]: _.keyBy(typedAction.histories, 'uuid'),
                },
            };
        }

        case InvestorsActionsEnum.InvestorIndividualSet: {
            const typedAction: IInvestorIndividualSetAction = action as IInvestorIndividualSetAction;

            const individuals: IIndividual[] = [...state.investors[typedAction.individual.investorUuid].individuals];
            individuals[typedAction.individual.index] = typedAction.individual;

            return {
                ...state,
                investors: {
                    ...state.investors,
                    [typedAction.individual.investorUuid]: {
                        ...state.investors[typedAction.individual.investorUuid],
                        individuals,
                    },
                },
            };
        }

        case InvestorsActionsEnum.InvestorIndividualValueSet: {
            const typedAction: IInvestorIndividualValueSetAction = action as IInvestorIndividualValueSetAction;

            const individuals: IIndividual[] = [...state.investors[typedAction.investorUuid].individuals];

            if (!individuals[typedAction.index]) {
                individuals[typedAction.index] = {
                    country: null,
                    dateOfBirth: null,
                    driverLicenceCardNumber: null,
                    driverLicenceState: null,
                    email: null,
                    firstName: null,
                    idProofExpiryDate: null,
                    idProofNumber: null,
                    idProofType: null,
                    index: typedAction.index,
                    investorUuid: typedAction.investorUuid,
                    lastName: null,
                    postcode: null,
                    residentType: null,
                    state: null,
                    streetAddress: null,
                    suburb: null,
                    taxFileNumber: null,
                };
            }

            (individuals[typedAction.index][typedAction.key] as boolean|number|string) = typedAction.value;

            return {
                ...state,
                investors: {
                    ...state.investors,
                    [typedAction.investorUuid]: {
                        ...state.investors[typedAction.investorUuid],
                        individuals,
                    },
                },
            };
        }

        case InvestorsActionsEnum.InvestorNotesSet: {
            const typedAction: IInvestorNotesSetAction = action as IInvestorNotesSetAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.investorUuid]: _.keyBy(typedAction.notes, 'uuid'),
                },
            };
        }

        case InvestorsActionsEnum.InvestorNoteRemove: {
            const typedAction: IInvestorNoteRemoveAction = action as IInvestorNoteRemoveAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.investorUuid]: _.omit(state.notes[typedAction.investorUuid] || {}, action.noteId),
                },
            };
        }

        case InvestorsActionsEnum.InvestorNoteSet: {
            const typedAction: IInvestorNoteSetAction = action as IInvestorNoteSetAction;

            return {
                ...state,
                notes: {
                    ...state.notes,
                    [typedAction.investorUuid]: {
                        ...(state.notes[typedAction.investorUuid] || {}),
                        [typedAction.note.uuid || 'new']: typedAction.note,
                    },
                },
            };
        }

        case InvestorsActionsEnum.InvestorReferralSet: {
            const typedAction: IInvestorReferralSetAction = action as IInvestorReferralSetAction;

            return {
                ...state,
                referrals: {
                    ...state.referrals,
                    [typedAction.referral.investorUuid]: {
                        ...state.referrals[typedAction.referral.investorUuid],
                        [typedAction.referral.uuid]: typedAction.referral,
                    },
                },
            };
        }

        case InvestorsActionsEnum.InvestorReferralsInvestorSet: {
            const typedAction: IInvestorReferralsInvestorSetAction = action as IInvestorReferralsInvestorSetAction;

            const referrals: { [referralUuid: string]: IReferral } = {};

            typedAction.referrals.forEach((referral: IReferral) => {
                referrals[referral.uuid] = referral;
            });

            return {
                ...state,
                referrals: {
                    ...state.referrals,
                    [typedAction.uuid]: referrals,
                },
            };
        }

        case InvestorsActionsEnum.InvestorReferralsSet: {
            const typedAction: IInvestorReferralsSetAction = action as IInvestorReferralsSetAction;

            const referrals: { [investorUuid: string]: { [referralId: string]: IReferral } } = {};

            typedAction.referrals.forEach((referral: IReferral) => {
                if (!referrals[referral.investorUuid]) {
                    referrals[referral.investorUuid] = {};
                }

                referrals[referral.investorUuid][referral.uuid] = referral;
            });

            return {
                ...state,
                referrals,
                referralsListed: true,
            };
        }

        case InvestorsActionsEnum.InvestorSet: {
            const typedAction: IInvestorSetAction = action as IInvestorSetAction;

            return {
                ...state,
                ...statifyInvestors(state, [typedAction.investor]),
            };
        }

        case InvestorsActionsEnum.InvestorTrustValueSet: {
            const typedAction: IInvestorTrustValueSetAction = action as IInvestorTrustValueSetAction;

            const trust: ITrust = state.investors[typedAction.uuid].trust ? { ...state.investors[typedAction.uuid].trust } : {
                australianBusinessNumber: null,
                name: null,
                taxFileNumber: null,
            };

            trust[typedAction.key] = typedAction.value;

            return {
                ...state,
                investors: {
                    ...state.investors,
                    [typedAction.uuid]: {
                        ...state.investors[typedAction.uuid],
                        trust,
                    },
                },
            };
        }

        case InvestorsActionsEnum.InvestorUsersSet: {
            const typedAction: IInvestorUsersSetAction = action as IInvestorUsersSetAction;

            const investorUsers: IDictionary<IInvestorUser> = {};

            typedAction.investorUsers.forEach((investorUser: IInvestorUser) => {
                investorUsers[investorUser.uuid] = _.omit(investorUser, ['investor', 'user']);
            });

            return {
                ...state,
                investorUsers: {
                    ...state.investorUsers,
                    [typedAction.uuid]: investorUsers,
                },
            };
        }

        case InvestorsActionsEnum.InvestorValueSet: {
            const typedAction: IInvestorValueSetAction = action as IInvestorValueSetAction;

            return {
                ...state,
                investors: {
                    ...state.investors,
                    [typedAction.uuid]: {
                        ...state.investors[typedAction.uuid],
                        [typedAction.key]: typedAction.value,
                    },
                },
            };
        }

        case InvestorsActionsEnum.InvestorsManagedSet: {
            const typedAction: IInvestorsManagedSetAction = action as IInvestorsManagedSetAction;

            return {
                ...state,
                ...statifyInvestors(state, typedAction.investors),
                managedListed: true,
            };
        }

        case InvestorsActionsEnum.PendingDepositsSet: {
            const typedAction: IPendingDepositsSetAction = action as IPendingDepositsSetAction;

            const pendingDepositUuids: string[] = [];

            typedAction.investorAccounts.forEach((investorAccount: IAccount) => {
                pendingDepositUuids.push(investorAccount.uuid);
            });

            return {
                ...state,
                ...statifyInvestorAccounts(state, typedAction.investorAccounts),
                pendingDepositUuids,
            };
        }

        case WithdrawalAbasActionsEnum.WithdrawalAbasInvalidInvestorTransactionsSet: {
            const typedAction: IWithdrawalAbasInvalidInvestorTransactionsSetAction = action as IWithdrawalAbasInvalidInvestorTransactionsSetAction;

            let clonedState: IInvestorsState = { ...state };

            _.each(typedAction.invalidInvestorTransactions, (invalidInvestorTransaction: IInvalidInvestorTransaction) => {
                clonedState = statifyInvestorAccount(clonedState, invalidInvestorTransaction.investorAccount);
            });

            return clonedState;
        }

        case WithdrawalAbasActionsEnum.WithdrawalAbaInvestorTransactionsSet: {
            const typedAction: IWithdrawalAbaInvestorTransactionsSetAction = action as IWithdrawalAbaInvestorTransactionsSetAction;

            return {
                ...state,
                ...statifyInvestorAccountTransactions(state, typedAction.investorAccountTransactions),
            };
        }

        case WithdrawalAbasActionsEnum.WithdrawalAbasUnallocatedInvestorTransactionsSet: {
            const typedAction: IWithdrawalAbasUnallocatedInvestorTransactionsSetAction = action as IWithdrawalAbasUnallocatedInvestorTransactionsSetAction;

            return {
                ...state,
                ...statifyInvestorAccountTransactions(state, typedAction.unallocatedInvestorTransactions),
            };
        }

        case AccountsActionsEnum.InvestorMovementsSet: {
            const typedAction: IInvestorMovementsSetAction = action as IInvestorMovementsSetAction;

            const investors: IInvestor[] = [];
            _.each(typedAction.transactions, (investorTransaction: IInvestorAccountTransaction) => {
                if (investorTransaction.investor) {
                    investors.push(investorTransaction.investor);
                }
            });

            return {
                ...state,
                ...statifyInvestors(state, investors),
            };
        }

        case InvestmentSaleTransfersActionsEnum.InvestmentSaleTransferInvestorAccountInvestmentTransactionsSet: {
            const typedAction: IInvestmentSaleTransferInvestorAccountInvestmentTransactionsSetAction = action as IInvestmentSaleTransferInvestorAccountInvestmentTransactionsSetAction;

            const investorAccountInvestmentTransactions: IDictionary<IInvestorAccountInvestmentTransaction> = _.keyBy(typedAction.investorAccountInvestmentTransactions, 'uuid');

            return {
                ...state,
                investorAccountInvestmentTransactions: {
                    ...state.investorAccountInvestmentTransactions,
                    ...investorAccountInvestmentTransactions,
                },
            };
        }

        case InvestmentSaleTransfersActionsEnum.InvestmentSaleTransfersUnallocatedInvestmentsSet: {
            const typedAction: IInvestmentSaleTransfersUnallocatedInvestmentsSetAction = action as IInvestmentSaleTransfersUnallocatedInvestmentsSetAction;

            let investorAccountInvestmentTransactions: IDictionary<IInvestorAccountInvestmentTransaction> = {};

            _.each(typedAction.unallocatedInvestments, (unallocatedInvestment: IUnallocatedInvestment) => {
                investorAccountInvestmentTransactions = {
                    ...investorAccountInvestmentTransactions,
                    ...unallocatedInvestment.investorAccountInvestmentTransactions,
                };
            });

            return {
                ...state,
                investorAccountInvestmentTransactions: {
                    ...state.investorAccountInvestmentTransactions,
                    ...investorAccountInvestmentTransactions,
                },
            };
        }

        default:
            return state;
    }
}
