import { all, call, debounce, put, select, takeEvery } from '@redux-saga/core/effects';
import { notification } from 'antd';
import _ from 'lodash';
import IInvestorUser from '~Api/Investor/IInvestorUser';
import { parseInvestorUser } from '~Api/Investor/parsers';
import IHistory from '~Api/User/IHistory';
import IUser from '~Api/User/IUser';
import {
    parseUser,
    parseUserHistory,
} from '~Api/User/parsers';
import {
    userBlockRequest,
    userEmailRequest,
    userGetRequest,
    userHistoriesListRequest,
    userInvestorsListRequest,
    userPhoneRequest,
    userUnblockRequest,
    userUpdateRequest,
    usersListRequest,
    usersSuspiciousListRequest,
} from '~Api/User/requests';
import { investorsListAction } from '~Investors/actions';
import { IFetchResponse } from '~utilities/fetch';
import { renderNotificationLoadingIcon } from '~utilities/utils';
import {
    IUserBlockAction,
    IUserEmailAction,
    IUserGetAction,
    IUserHistoriesListAction,
    IUserInvestorsListAction,
    IUserPhoneAction,
    IUserSendAction,
    IUserUnblockAction,
    IUserValueSetAction,
    IUsersListAction,
    IUsersSuspiciousListAction,
    userHistoriesSetAction,
    userInvestorsSetAction,
    userSendAction,
    userSetAction,
    usersSetAction,
    usersSuspiciousSetAction,
} from './actions';
import UsersActionsEnum from './ActionsEnum';
import { userSelector } from './selectors';

function* usersList(action: IUsersListAction): Iterator<any> {
    const rawUsers: IFetchResponse = yield call(usersListRequest);
    const users: IUser[] = yield Promise.all(rawUsers.body.map(parseUser));
    yield put(usersSetAction(users));
}

function* usersSuspiciousList(action: IUsersSuspiciousListAction): Iterator<any> {
    const rawUsers: IFetchResponse = yield call(usersSuspiciousListRequest);
    const users: IUser[] = yield Promise.all(rawUsers.body.map(parseUser));
    yield put(usersSuspiciousSetAction(users));
}

function* userBlock(action: IUserBlockAction): Iterator<any> {
    const key: string = `userBlock ${action.userUuid}`;
    const message: string = 'Block User';

    notification.open({
        description: 'Blocking User...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const userBlockResponse: IFetchResponse = yield call(userBlockRequest, action.userUuid);
    if (userBlockResponse.status === 422) {
        notification.error({
            description: `There was a problem blocking the user: ${_.values(userBlockResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const user: IUser = parseUser(userBlockResponse.body);
        yield put(userSetAction(user));

        notification.success({
            description: 'The user has been blocked.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* userEmail(action: IUserEmailAction): Iterator<any> {
    const key: string = `userEmail ${action.uuid}`;
    const message: string = 'Change Email';

    notification.open({
        description: 'Changing email...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const userEmailResponse: IFetchResponse = yield call(userEmailRequest, action.uuid, action.email);
    if (userEmailResponse.status === 422) {
        notification.error({
            description: `There was a problem changing the email: ${_.values(userEmailResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const user: IUser = parseUser(userEmailResponse.body);
        yield put(userSetAction(user));

        notification.success({
            description: 'The email has been changed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* userGet(action: IUserGetAction): Iterator<any> {
    const rawUser: IFetchResponse = yield call(userGetRequest, action.uuid);
    const user: IUser = parseUser(rawUser.body);
    yield put(userSetAction(user));
}

function* userHistoriesList(action: IUserHistoriesListAction): Iterator<any> {
    const rawHistories: IFetchResponse = yield call(userHistoriesListRequest, action.uuid);
    const histories: IHistory[] = yield Promise.all(rawHistories.body.map(parseUserHistory));
    yield put(userHistoriesSetAction(action.uuid, histories));
}

function* userInvestorsList(action: IUserInvestorsListAction): Iterator<any> {
    // @TODO: Paginate investors so we can save them to redux without having it think that's all of them
    yield put(investorsListAction());

    const rawUserInvestors: IFetchResponse = yield call(userInvestorsListRequest, action.uuid);
    const investorUsers: IInvestorUser[] = yield Promise.all(rawUserInvestors.body.map(parseInvestorUser));
    yield put(userInvestorsSetAction(action.uuid, investorUsers));
}

function* userPhone(action: IUserPhoneAction): Iterator<any> {
    const key: string = `userPhone ${action.uuid}`;
    const message: string = 'Change Phone';

    notification.open({
        description: 'Changing phone...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const userPhoneResponse: IFetchResponse = yield call(userPhoneRequest, action.uuid, action.phone);
    if (userPhoneResponse.status === 422) {
        notification.error({
            description: `There was a problem changing the phone: ${_.values(userPhoneResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const user: IUser = parseUser(userPhoneResponse.body);
        yield put(userSetAction(user));

        notification.success({
            description: 'The phone has been changed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* userSend(action: IUserSendAction): Iterator<any> {
    const user: IUser = yield select(userSelector, action.uuid);
    yield call(userUpdateRequest, user);
}

function* userUnblock(action: IUserUnblockAction): Iterator<any> {
    const key: string = `userUnblock ${action.userUuid}`;
    const message: string = 'Unblock User';

    notification.open({
        description: 'Unblocking User...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const userUnblockResponse: IFetchResponse = yield call(userUnblockRequest, action.userUuid);
    if (userUnblockResponse.status === 422) {
        notification.error({
            description: `There was a problem unblocking the user: ${_.values(userUnblockResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const user: IUser = parseUser(userUnblockResponse.body);
        yield put(userSetAction(user));

        notification.success({
            description: 'The user has been unblocked.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* userValueSet(action: IUserValueSetAction): Iterator<any> {
    yield put(userSendAction(action.uuid));
}

export function* UsersSagas(): any {
    yield all([
        takeEvery(UsersActionsEnum.UsersList, usersList),
        takeEvery(UsersActionsEnum.UsersSuspiciousList, usersSuspiciousList),

        takeEvery(UsersActionsEnum.UserBlock, userBlock),
        takeEvery(UsersActionsEnum.UserEmail, userEmail),
        takeEvery(UsersActionsEnum.UserGet, userGet),
        takeEvery(UsersActionsEnum.UserPhone, userPhone),
        debounce(500, UsersActionsEnum.UserSend, userSend),
        takeEvery(UsersActionsEnum.UserUnblock, userUnblock),
        takeEvery(UsersActionsEnum.UserValueSet, userValueSet),

        takeEvery(UsersActionsEnum.UserHistoriesList, userHistoriesList),

        takeEvery(UsersActionsEnum.UserInvestorsList, userInvestorsList),
    ]);
}
