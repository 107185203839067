import { Button, Divider, Dropdown, Form, Input, InputNumber, MenuProps, Modal, Radio, RadioChangeEvent, Select, Space, Spin, Tooltip, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import ILoanFee from '~Api/Loan/ILoanFee';
import ILoanPayoutFigure from '~Api/Loan/ILoanPayoutFigure';
import ILoanPayoutFigureItem from '~Api/Loan/ILoanPayoutFigureItem';
import ILoanPayoutFigureSection from '~Api/Loan/ILoanPayoutFigureSection';
import LoanPayoutFigureApprovalStatusEnum from '~Api/Loan/LoanPayoutFigureApprovalStatusEnum';
import LoanPayoutFigureSectionTypeEnum from '~Api/Loan/LoanPayoutFigureSectionTypeEnum';
import {
    loanFeesListAction,
    loanGetAction,
    loanPayoutFiguresAddAction,
} from '~Loans/actions';
import {
    loanFeesSelector,
    loanSelector,
} from '~Loans/selectors';
import {
    ILoanInterestCalculation,
    getLoanAppliedAccruedInterestTotal,
    getLoanInterestCalculation,
    getLoanPayoutFigureTotals,
} from '~Loans/utilities';
import { IGlobalState } from '~reducer';
import DatePicker from '~UI/DatePicker';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import { currencyFormatter } from '~utilities/formatters';
import LoanPayoutFigureAccruedInterestModeEnum from '~Api/Loan/LoanPayoutFigureAccruedInterestModeEnum';
import LoanPayoutFigurePrepaidInterestModeEnum from '~Api/Loan/LoanPayoutFigurePrepaidInterestModeEnum';
import { DownOutlined, WarningOutlined } from '@ant-design/icons';
import PayoutFigureInterestCalculatorModal from './PayoutFigureInterestCalculatorModal';
import LoanPayoutFigureMinimumTermInterestModeEnum from '~Api/Loan/LoanPayoutFigureMinimumTermInterestModeEnum';
import DischargeInterestTypeEnum from '~Api/Application/DischargeInterestTypeEnum';
import { applicationValueSetAction } from '~Applications/actions';
import IApplication from '~Api/Application/IApplication';

const defaultSections: LoanPayoutFigureSectionTypeEnum[] = [
    LoanPayoutFigureSectionTypeEnum.Interest,
    LoanPayoutFigureSectionTypeEnum.Other,
    LoanPayoutFigureSectionTypeEnum.Renewal,
    LoanPayoutFigureSectionTypeEnum.DefaultFees,
];

const sectionLabels: IDictionary<string> = {
    [LoanPayoutFigureSectionTypeEnum.Interest]: 'Interest',
    [LoanPayoutFigureSectionTypeEnum.Other]: 'Other (Inc GST)',
    [LoanPayoutFigureSectionTypeEnum.Renewal]: 'Renewal/Variation',
    [LoanPayoutFigureSectionTypeEnum.DefaultFees]: 'Default Fees',
};

interface ISection {
    name: string;
    lineItems: IDictionary<ILineItem>;
    type: LoanPayoutFigureSectionTypeEnum;
}

interface ILineItem {
    amount: number;
    code: string;
    name: string;
}

interface IState {
    accruedInterest: number;
    accruedInterestLabel: string;
    accruedInterestMode: LoanPayoutFigureAccruedInterestModeEnum;
    borrowerName: string;
    dischargeDate: string;
    dischargeInterestType: string;
    errors: IDictionary<string>;
    isInterestCalculatorModalOpen: boolean;
    isMissingDetailsModalOpen: boolean;
    loanCode: string;
    minimumTermInterest: number;
    minimumTermInterestMode: LoanPayoutFigureMinimumTermInterestModeEnum;
    prepaidInterest: number;
    prepaidInterestAuto: number;
    prepaidInterestMode: LoanPayoutFigurePrepaidInterestModeEnum;
    principalAmount: number;
    sections: IDictionary<ISection>;
    trustAmount: number;
}

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    fees: ILoanFee[];
    loan: ILoan;
}

interface IPropsDispatch {
    applicationValueSet: (applicationUuid: string, key: keyof IApplication, value: boolean|number|string) => void;
    create: (payoutFigure: ILoanPayoutFigure) => void;
    feesList: () => void;
    loanGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class PayoutFigureAdd extends React.Component<Props, IState> {
    public state: IState = {
        accruedInterest: null,
        accruedInterestLabel: null,
        accruedInterestMode: LoanPayoutFigureAccruedInterestModeEnum.Auto,
        borrowerName: null,
        dischargeDate: null,
        dischargeInterestType: null,
        errors: {},
        isInterestCalculatorModalOpen: false,
        isMissingDetailsModalOpen: true,
        loanCode: null,
        minimumTermInterest: null,
        minimumTermInterestMode: LoanPayoutFigureMinimumTermInterestModeEnum.Auto,
        prepaidInterest: null,
        prepaidInterestAuto: null,
        prepaidInterestMode: LoanPayoutFigurePrepaidInterestModeEnum.Auto,
        principalAmount: null,
        sections: null,
        trustAmount: null,
    };

    constructor(props: Props) {
        super(props);

        this.disabledDate = this.disabledDate.bind(this);

        this.getLoanPayoutFigure = this.getLoanPayoutFigure.bind(this);

        this.getSections = this.getSections.bind(this);
        this.onClickAddLineItem = this.onClickAddLineItem.bind(this);
        this.onClickDeleteLineItem = this.onClickDeleteLineItem.bind(this);
        this.onChangeLineItemAmount = this.onChangeLineItemAmount.bind(this);
        this.onChangeLineItemName = this.onChangeLineItemName.bind(this);

        this.onClickAddSection = this.onClickAddSection.bind(this);
        this.onClickDeleteSection = this.onClickDeleteSection.bind(this);

        this.onChangeBorrowerName = this.onChangeBorrowerName.bind(this);
        this.onChangeDischargeDate = this.onChangeDischargeDate.bind(this);
        this.onChangeLoanCode = this.onChangeLoanCode.bind(this);
        this.onChangePrepaidInterest = this.onChangePrepaidInterest.bind(this);
        this.onChangePrincipalAmount = this.onChangePrincipalAmount.bind(this);
        this.onChangeTrustAmount = this.onChangeTrustAmount.bind(this);
        this.onClickCreate = this.onClickCreate.bind(this);

        this.getAppliedAccruedInterestMode = this.getAppliedAccruedInterestMode.bind(this);
        this.getAppliedPrepaidInterestMode = this.getAppliedPrepaidInterestMode.bind(this);
        this.getAppliedMinimumTermInterestMode = this.getAppliedMinimumTermInterestMode.bind(this);

        this.onChangeAccruedInterestMode = this.onChangeAccruedInterestMode.bind(this);
        this.onChangeMinimumTermInterestMode = this.onChangeMinimumTermInterestMode.bind(this);
        this.onChangeDischargeInterestType = this.onChangeDischargeInterestType.bind(this);

        this.onCloseInterestCalculatorModal = this.onCloseInterestCalculatorModal.bind(this);
        this.onClickAutoCalculate = this.onClickAutoCalculate.bind(this);

        this.onClickMissingDetailsCancel = this.onClickMissingDetailsCancel.bind(this);
        this.onClickMissingDetailsOk = this.onClickMissingDetailsOk.bind(this);

        this.switchToPrepaidInterestAuto = this.switchToPrepaidInterestAuto.bind(this);
        this.switchToPrepaidInterestManual = this.switchToPrepaidInterestManual.bind(this);

        this.recalculateInterest = this.recalculateInterest.bind(this);

        this.validateDischargeDate = this.validateDischargeDate.bind(this);
        this.validateLineItemAmount = this.validateLineItemAmount.bind(this);
        this.validateLineItemName = this.validateLineItemName.bind(this);
        this.validatePrepaidInterest = this.validatePrepaidInterest.bind(this);
    }

    public componentDidMount(): void {
        this.props.feesList();
        this.props.loanGet();
    }

    public render(): JSX.Element {
        const { fees, loan, match } = this.props;
        const {
            accruedInterestLabel,
            borrowerName,
            dischargeDate,
            errors,
            isMissingDetailsModalOpen,
            isInterestCalculatorModalOpen,
            loanCode,
            prepaidInterest,
            prepaidInterestAuto,
            principalAmount,
            trustAmount,
        } = this.state;

        if (!fees || !loan) {
            return (
                <Layout uuid={match.params.uuid} section='payout-figure'>
                    <Spin />
                </Layout>
            );
        }

        if (!loan.application.dischargeInterestType) {
            return (
                <Layout uuid={match.params.uuid} section='payout-figure'>
                    <p>Please set the discharge interest type</p>
                    <Modal
                        destroyOnClose={true}
                        okText='Confirm'
                        onCancel={this.onClickMissingDetailsCancel}
                        onOk={this.onClickMissingDetailsOk}
                        open={isMissingDetailsModalOpen}
                        title='Missing Details'
                    >
                        <Form.Item label='Discharge Interest Type' className='discharge-interest-type'>
                            <Select onChange={this.onChangeDischargeInterestType}>
                                <Select.Option value={DischargeInterestTypeEnum.BreakCost}>Break Cost</Select.Option>
                                <Select.Option value={DischargeInterestTypeEnum.MinimumTerm}>Minimum Term</Select.Option>
                            </Select>
                        </Form.Item>
                    </Modal>
                </Layout>
            );
        }

        const sections: IDictionary<ISection> = this.getSections();

        const loanPayoutFigure: ILoanPayoutFigure = this.getLoanPayoutFigure();
        const { balanceAmount, totalPayoutFigure } = getLoanPayoutFigureTotals(loan, loanPayoutFigure);
        const appliedAccruedInterestTotal: number = getLoanAppliedAccruedInterestTotal(loanPayoutFigure);

        const appliedPrepaidInterestMode: LoanPayoutFigurePrepaidInterestModeEnum = this.getAppliedPrepaidInterestMode();
        const appliedAccruedInterestMode: LoanPayoutFigureAccruedInterestModeEnum = this.getAppliedAccruedInterestMode();

        // Loans without the current payment date (the most recent interest inclusive loan transaction date or loan start date) cannot be calculated.
        const canAutoCalculateInterest: boolean = !!loan.currentPaymentDate;

        const prepaidInterestMenu: MenuProps = {
            items: [
                ...(appliedPrepaidInterestMode === LoanPayoutFigurePrepaidInterestModeEnum.Manual ? [
                    {
                        key: 'auto-calculate',
                        label: 'Auto Calculate',
                        onClick: this.switchToPrepaidInterestAuto,
                    },
                ] : []),
                ...(appliedPrepaidInterestMode === LoanPayoutFigurePrepaidInterestModeEnum.Auto ? [
                    {
                        key: 'enter-amount',
                        label: 'Enter Amount',
                        onClick: this.switchToPrepaidInterestManual,
                    },
                ] : []),
            ],
        };

        const prepaidInterestLabel: JSX.Element = (
            <Dropdown menu={prepaidInterestMenu}>
                <a>Less Remaining Prepaid Interest <DownOutlined /></a>
            </Dropdown>
        );

        const prepaidInterestLabelBlock: JSX.Element = canAutoCalculateInterest ? (
            <Form.Item className='dropdown-label' label={prepaidInterestLabel} />
        ) : (
            <span className='label'>Less Remaining Prepaid Interest</span>
        );

        const prepaidInterestBlock: JSX.Element = appliedPrepaidInterestMode === LoanPayoutFigurePrepaidInterestModeEnum.Auto ? (
            <Space className='amount'>
                {!dischargeDate ? <Tooltip overlay='Select a proposed payout date'><WarningOutlined /></Tooltip> : <span>{currencyFormatter.format(prepaidInterestAuto)}</span>}
            </Space>
        ) : (
            <Form.Item help={errors.prepaidInterest} validateStatus={errors.prepaidInterest && 'error'}>
                <InputNumber addonBefore='$' className='amount' onChange={this.onChangePrepaidInterest} value={prepaidInterest} />
            </Form.Item>
        );

        const accruedInterestTotalBlock: JSX.Element = dischargeDate && appliedAccruedInterestMode === LoanPayoutFigureAccruedInterestModeEnum.Auto && (
            <Space direction='vertical'>
                <Space className='line-item'>
                    <span className='label'>{accruedInterestLabel}</span>
                    <Space className='amount'>
                        <span>{currencyFormatter.format(appliedAccruedInterestTotal)}</span>
                    </Space>
                </Space>
            </Space>
        );

        const sectionsBlock: JSX.Element[] = _.map(defaultSections, (defaultSection: LoanPayoutFigureSectionTypeEnum) => {
            const section: ISection = _.find(sections, (loopSection: ILoanPayoutFigureSection) => loopSection.type === defaultSection);

            if (!section) {
                const onClickAddSection: () => void = () => this.onClickAddSection(defaultSection);
                return (
                    <React.Fragment key={sectionLabels[defaultSection]}>
                        <Divider />
                        <Space direction='vertical'>
                            <Space>
                                <strong>{sectionLabels[defaultSection]}</strong>
                                <Button onClick={onClickAddSection} size='small' type='link'>Add</Button>
                            </Space>
                        </Space>
                    </React.Fragment>
                );
            }

            const itemsBlock: JSX.Element[] = _.map(section.lineItems, (lineItem: ILineItem) => {
                const onClickDelete: () => void = () => this.onClickDeleteLineItem(section.type, lineItem.code);
                const onChangeAmount: (value: number) => void = (value: number) => this.onChangeLineItemAmount(section.type, lineItem.code, value);
                const onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void = (event: React.ChangeEvent<HTMLInputElement>) => this.onChangeLineItemName(section.type, lineItem.code, event);

                return (
                    <Space className='line-item' key={lineItem.code}>
                        <Form.Item help={errors[`lineItem_${lineItem.code}_name`]} validateStatus={errors[`lineItem_${lineItem.code}_name`] && 'error'}>
                            <Input className='label' onChange={onChangeName} value={lineItem.name} />
                        </Form.Item>
                        <Form.Item help={errors[`lineItem_${lineItem.code}_amount`]} validateStatus={errors[`lineItem_${lineItem.code}_amount`] && 'error'}>
                            <InputNumber addonBefore='$' className='amount' onChange={onChangeAmount} value={lineItem.amount} />
                        </Form.Item>
                        <Button onClick={onClickDelete} size='small' type='link'>Delete</Button>
                    </Space>
                );
            });

            const onClickDeleteSection: () => void = () => this.onClickDeleteSection(section.type);
            const onClickAddItem: () => void = () => this.onClickAddLineItem(section.type);

            return (
                <React.Fragment key={section.type}>
                    <Divider />
                    <Space direction='vertical'>
                        <Space>
                            <strong>{section.name}</strong>
                            {[LoanPayoutFigureSectionTypeEnum.DefaultFees, LoanPayoutFigureSectionTypeEnum.Renewal].includes(section.type) && <Button onClick={onClickDeleteSection} size='small' type='link'>Remove</Button>}
                            {section.type === LoanPayoutFigureSectionTypeEnum.Interest && canAutoCalculateInterest && <Button onClick={this.onClickAutoCalculate} size='small' type='link'>Auto Calculate</Button>}
                        </Space>
                        <React.Fragment>
                            {section.type === LoanPayoutFigureSectionTypeEnum.Interest && accruedInterestTotalBlock}
                            {itemsBlock}
                        </React.Fragment>
                        <Button onClick={onClickAddItem}>Add Item</Button>
                    </Space>
                </React.Fragment>
            );
        });

        const loanCodeBlock: JSX.Element = loan.salesforceCode && loan.code !== loan.salesforceCode && (
            <Form.Item label='Loan ID'>
                <Radio.Group onChange={this.onChangeLoanCode} value={loanCode || loan.code}>
                    <Radio value={loan.code}>{loan.code}</Radio>
                    <Radio value={loan.salesforceCode}>{loan.salesforceCode}</Radio>
                </Radio.Group>
            </Form.Item>
        );

        return (
            <Layout uuid={match.params.uuid} section='payout-figure'>
                <Space className='actions'>
                    <Button className='create' onClick={this.onClickCreate} type='primary'>Create</Button>
                </Space>
                <Typography.Title level={2}>Payout Figure</Typography.Title>
                {loanCodeBlock}
                <Form.Item className='borrower-name' label='Borrower'>
                    <Input onChange={this.onChangeBorrowerName} value={borrowerName || loan.contactName} />
                </Form.Item>
                <Form.Item className='discharge-date' help={errors.dischargeDate} label='Proposed Payout Date' validateStatus={errors.dischargeDate && 'error'}>
                    <DatePicker
                        disabledDate={this.disabledDate}
                        onChange={this.onChangeDischargeDate}
                        format='DD/MM/YYYY'
                        value={dischargeDate ? dayjs(dischargeDate) : null}
                    />
                </Form.Item>
                <Divider />
                <Space className='line-item'>
                    <span className='label'>Principal</span>
                    <InputNumber addonBefore='$' className='amount' onChange={this.onChangePrincipalAmount} value={principalAmount || loan.amount} />
                </Space>
                <React.Fragment>
                    {sectionsBlock}
                </React.Fragment>
                <Divider />
                <Space direction='vertical'>
                    <Space className='line-item'>
                        <span className='label'>Total Payout Figure</span>
                        <span className='amount'>{currencyFormatter.format(totalPayoutFigure)}</span>
                    </Space>
                    <Space className='line-item'>
                        {prepaidInterestLabelBlock}
                        {prepaidInterestBlock}
                    </Space>
                    <Space className='line-item'>
                        <span className='label'>Less Amount Held In Trust</span>
                        <Form.Item help={errors.trustAmount} validateStatus={errors.trustAmount && 'error'}>
                            <InputNumber addonBefore='$' className='amount' onChange={this.onChangeTrustAmount} value={trustAmount} />
                        </Form.Item>
                    </Space>
                    <Space className='line-item'>
                        <span className='label'><strong>Balance Due</strong></span>
                        <span className='amount'><strong>{currencyFormatter.format(balanceAmount)}</strong></span>
                    </Space>
                </Space>
                <PayoutFigureInterestCalculatorModal
                    isOpen={isInterestCalculatorModalOpen}
                    onClose={this.onCloseInterestCalculatorModal}
                    payoutFigure={loanPayoutFigure}
                    onChangeAccruedInterestMode={this.onChangeAccruedInterestMode}
                    onChangeMinimumTermInterestMode={this.onChangeMinimumTermInterestMode}
                />
            </Layout>
        );
    }

    /**
     * Disables dates before the current payment date for the loan
     */
    private disabledDate(date: Dayjs): boolean {
        const { loan } = this.props;

        return date && loan.currentPaymentDate && date.format('YYYY-MM-DD') < loan.currentPaymentDate;
    }

    /**
     * Simimulates a payout figure object used for interest calculations
     */
    private getLoanPayoutFigure(): ILoanPayoutFigure {
        const { loan } = this.props;
        const {
            accruedInterest,
            accruedInterestLabel,
            borrowerName,
            dischargeDate,
            loanCode,
            minimumTermInterest,
            prepaidInterest,
            prepaidInterestAuto,
            principalAmount,
            trustAmount,
        } = this.state;
        const sections: IDictionary<ISection> = this.getSections();

        return {
            accruedInterest,
            accruedInterestLabel,
            accruedInterestMode: this.getAppliedAccruedInterestMode(),
            approvalStatus: LoanPayoutFigureApprovalStatusEnum.Draft,
            borrowerName: borrowerName || loan.contactName,
            dischargeDate,
            loanCode: loanCode || loan.code || loan.salesforceCode,
            loanUuid: loan.uuid,
            minimumTermInterest,
            minimumTermInterestMode: this.getAppliedMinimumTermInterestMode(),
            prepaidInterest,
            prepaidInterestAuto,
            prepaidInterestMode: this.getAppliedPrepaidInterestMode(),
            principalAmount: principalAmount || loan.amount,
            sections: _.map(sections, (section: ISection): ILoanPayoutFigureSection => ({
                items: _.map(section.lineItems, (lineItem: ILineItem): ILoanPayoutFigureItem => ({
                    amount: lineItem.amount,
                    name: lineItem.name,
                })),
                name: section.name,
                type: section.type,
            })),
            trustAmount,
        };
    }

    private getAppliedPrepaidInterestMode(): LoanPayoutFigurePrepaidInterestModeEnum {
        const { loan } = this.props;
        const { prepaidInterestMode } = this.state;

        if (loan.currentPaymentDate) {
            return prepaidInterestMode;
        }
        return LoanPayoutFigurePrepaidInterestModeEnum.Manual;
    }

    private getAppliedAccruedInterestMode(): LoanPayoutFigureAccruedInterestModeEnum {
        const { loan } = this.props;
        const { accruedInterestMode } = this.state;

        if (loan.currentPaymentDate) {
            return accruedInterestMode;
        }
        return LoanPayoutFigureAccruedInterestModeEnum.Off;
    }

    private getAppliedMinimumTermInterestMode(): LoanPayoutFigureMinimumTermInterestModeEnum {
        const { loan } = this.props;
        const { minimumTermInterestMode } = this.state;

        if (loan.currentPaymentDate && loan.application.dischargeInterestType === DischargeInterestTypeEnum.MinimumTerm) {
            return minimumTermInterestMode;
        }

        return LoanPayoutFigureMinimumTermInterestModeEnum.Off;
    }

    private getSections(): IDictionary<ISection> {
        const { fees, loan } = this.props;
        const { sections } = this.state;

        if (sections) {
            return sections;
        }

        const feeLineItems: IDictionary<ILineItem> = {};

        fees.forEach((fee: ILoanFee) => {
            feeLineItems[`FEE_${fee.uuid}`] = {
                amount: fee.amount,
                code: `FEE_${fee.uuid}`,
                name: fee.description,
            };
        });

        return {
            [LoanPayoutFigureSectionTypeEnum.Interest]: {
                // Do not include the default filled interest field if we can calculate the interest automatically
                lineItems: loan.currentPaymentDate ? {} : {
                    ['INTEREST_INTEREST_CHARGED']: {
                        amount: null,
                        code: 'INTEREST_INTEREST_CHARGED',
                        name: 'Interest Charged',
                    },
                },
                name: 'Interest',
                type: LoanPayoutFigureSectionTypeEnum.Interest,
            },
            [LoanPayoutFigureSectionTypeEnum.Other]: {
                lineItems: {
                    ['OTHER_LENDERS_DISCHARGE_FEE']: {
                        amount: null,
                        code: 'OTHER_LENDERS_DISCHARGE_FEE',
                        name: 'Lender\'s Discharge Fee',
                    },
                    // eslint-disable-next-line sort-keys
                    ['OTHER_LAWYERS_DISCHARGE_FEE']: {
                        amount: null,
                        code: 'OTHER_LAWYERS_DISCHARGE_FEE',
                        name: 'Lawyer\'s Discharge Fee & Outlays',
                    },
                    ['OTHER_LENDERS_OUTLAYS']: {
                        amount: null,
                        code: 'OTHER_LENDERS_OUTLAYS',
                        name: 'Lender\'s Outlays',
                    },
                    // eslint-disable-next-line sort-keys
                    ['OTHER_FAILED_SETTLEMENT_FEES']: {
                        amount: null,
                        code: 'OTHER_FAILED_SETTLEMENT_FEES',
                        name: 'Failed Settlement Fees',
                    },
                    ['OTHER_SETTLEMENT_FEE']: {
                        amount: null,
                        code: 'OTHER_SETTLEMENT_FEE',
                        name: '30 Days Not / Urgent Settlement Fee',
                    },
                    ...feeLineItems,
                },
                name: 'Other (Inc GST)',
                type: LoanPayoutFigureSectionTypeEnum.Other,
            },
            [LoanPayoutFigureSectionTypeEnum.Renewal]: {
                lineItems: {
                    ['RENEWAL_OUTSTANDING_FEE']: {
                        amount: null,
                        code: 'RENEWAL_OUTSTANDING_FEE',
                        name: 'Outstanding Renewal Fee',
                    },
                    // eslint-disable-next-line sort-keys
                    ['RENEWAL_OUTSTANDING_DOC_FEE']: {
                        amount: null,
                        code: 'RENEWAL_OUTSTANDING_DOC_FEE',
                        name: 'Outstanding Renewal Doc Fee',
                    },
                },
                name: 'Renewal/Variation',
                type: LoanPayoutFigureSectionTypeEnum.Renewal,
            },
            [LoanPayoutFigureSectionTypeEnum.DefaultFees]: {
                lineItems: {
                    ['DEFAULT_NOTICE_FEE']: {
                        amount: null,
                        code: 'DEFAULT_NOTICE_FEE',
                        name: 'Default Notice Fee',
                    },
                    // eslint-disable-next-line sort-keys
                    ['DEFAULT_MONTHLY_ADMIN_FEES']: {
                        amount: null,
                        code: 'DEFAULT_MONTHLY_ADMIN_FEES',
                        name: 'X Monthly Default Admin Fees',
                    },
                    // eslint-disable-next-line sort-keys
                    ['DEFAULT_LAWYERS_COSTS']: {
                        amount: null,
                        code: 'DEFAULT_LAWYERS_COSTS',
                        name: 'Lawyer\'s Default Costs',
                    },
                    ['DEFAULT_LENDERS_OUTLAYS']: {
                        amount: null,
                        code: 'DEFAULT_LENDERS_OUTLAYS',
                        name: 'Lender\'s Outlays (Court Filing Costs, Agent Fees etc.)',
                    },
                },
                name: 'Default Fees',
                type: LoanPayoutFigureSectionTypeEnum.DefaultFees,
            },
        };
    }

    private onClickAddLineItem(sectionCode: string): void {
        const sections: IDictionary<ISection> = this.getSections();

        const code: string = dayjs().format();

        this.setState({
            sections: {
                ...sections,
                [sectionCode]: {
                    ...sections[sectionCode],
                    lineItems: {
                        ...sections[sectionCode].lineItems,
                        [code]: {
                            amount: null,
                            code,
                            name: null,
                        },
                    },
                },
            },
        });
    }

    private onClickDeleteLineItem(sectionCode: string, lineItemCode: string): void {
        const sections: IDictionary<ISection> = this.getSections();

        Modal.confirm({
            okText: 'Delete',
            onOk: () => {
                this.setState({
                    sections: {
                        ...sections,
                        [sectionCode]: {
                            ...sections[sectionCode],
                            lineItems: _.omit(sections[sectionCode].lineItems, [lineItemCode]),
                        },
                    },
                });
            },
            title: 'Delete Line Item',
        });
    }

    private onClickMissingDetailsCancel(): void {
        this.setState({
            isMissingDetailsModalOpen: false,
        });
    }

    private onClickMissingDetailsOk(): void {
        const { loan } = this.props;
        const { dischargeInterestType } = this.state;

        this.props.applicationValueSet(loan.applicationUuid, 'dischargeInterestType', dischargeInterestType);

        this.onClickMissingDetailsCancel();
    }

    private onCloseInterestCalculatorModal(): void {
        this.setState({
            isInterestCalculatorModalOpen: false,
        });
    }

    private onClickAutoCalculate(): void {
        const { dischargeDate } = this.state;

        if (!dischargeDate) {
            Modal.error({
                content: 'Please select a proposed payout date',
                title: 'Unable to Auto Calculate',
            });

            return;
        }

        this.setState({
            isInterestCalculatorModalOpen: true,
        });
    }

    private onChangeLineItemAmount(sectionCode: string, lineItemCode: string, value: number): void {
        const sections: IDictionary<ISection> = this.getSections();

        this.setState({
            sections: {
                ...sections,
                [sectionCode]: {
                    ...sections[sectionCode],
                    lineItems: {
                        ...sections[sectionCode].lineItems,
                        [lineItemCode]: {
                            ...sections[sectionCode].lineItems[lineItemCode],
                            amount: value,
                        },
                    },
                },
            },
        });
    }

    private onChangeLineItemName(sectionCode: string, lineItemCode: string, event: React.ChangeEvent<HTMLInputElement>): void {
        const sections: IDictionary<ISection> = this.getSections();

        this.setState({
            sections: {
                ...sections,
                [sectionCode]: {
                    ...sections[sectionCode],
                    lineItems: {
                        ...sections[sectionCode].lineItems,
                        [lineItemCode]: {
                            ...sections[sectionCode].lineItems[lineItemCode],
                            name: event.target.value,
                        },
                    },
                },
            },
        });
    }

    private onClickAddSection(sectionCode: LoanPayoutFigureSectionTypeEnum): void {
        const sections: IDictionary<ISection> = this.getSections();

        this.setState({
            sections: {
                ...sections,
                [sectionCode]: {
                    lineItems: {},
                    name: sectionLabels[sectionCode],
                    type: sectionCode,
                },
            },
        });
    }

    private onClickDeleteSection(sectionCode: string): void {
        const sections: IDictionary<ISection> = this.getSections();

        this.setState({
            sections: _.omit(sections, [sectionCode]),
        });
    }

    private onChangeBorrowerName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            borrowerName: event.target.value,
        });
    }

    private onChangeDischargeDate(date: Dayjs): void {
        const dischargeDate: string = date ? date.format('YYYY-MM-DD') : null;

        this.setState({
            dischargeDate,
        });

        this.recalculateInterest(dischargeDate);
    }

    private onChangeLoanCode(event: RadioChangeEvent): void {
        this.setState({
            loanCode: event.target.value,
        });
    }

    private onChangePrincipalAmount(value: number): void {
        this.setState({
            principalAmount: value,
        });
    }

    private onChangeTrustAmount(value: number): void {
        this.setState({
            trustAmount: value,
        });
    }

    private onChangePrepaidInterest(value: number): void {
        this.setState({
            prepaidInterest: value || null,
        });
    }

    private onChangeDischargeInterestType(dischargeInterestType: DischargeInterestTypeEnum): void {
        this.setState({
            dischargeInterestType,
        });
    }

    private onClickCreate(): void {
        const sections: IDictionary<ISection> = this.getSections();

        let valid: boolean = true;
        const errors: IDictionary<string> = {};

        valid = this.validateDischargeDate(errors) && valid;

        if (this.getAppliedPrepaidInterestMode() === LoanPayoutFigurePrepaidInterestModeEnum.Manual) {
            valid = this.validatePrepaidInterest(errors) && valid;
        }

        _.each(sections, (section: ISection) => {
            _.each(section.lineItems, (lineItem: ILineItem) => {
                valid = this.validateLineItemAmount(lineItem, errors) && valid;
                valid = this.validateLineItemName(lineItem, errors) && valid;
            });
        });

        if (!valid) {
            return;
        }

        this.props.create(this.getLoanPayoutFigure());
    }

    private onChangeAccruedInterestMode(accruedInterestMode: LoanPayoutFigureAccruedInterestModeEnum): void {
        this.setState({
            accruedInterestMode,
        });
    }

    private onChangeMinimumTermInterestMode(minimumTermInterestMode: LoanPayoutFigureMinimumTermInterestModeEnum): void {
        this.setState({
            minimumTermInterestMode,
        });
    }

    private switchToPrepaidInterestAuto(): void {
        this.setState({
            prepaidInterestMode: LoanPayoutFigurePrepaidInterestModeEnum.Auto,
        });
    }

    private switchToPrepaidInterestManual(): void {
        this.setState({
            prepaidInterestMode: LoanPayoutFigurePrepaidInterestModeEnum.Manual,
        });
    }

    private recalculateInterest(dischargeDate: string): void {
        const { loan } = this.props;

        // There is no need to do interest calculations if there is no loan or a loan current payment date
        if (!loan || !loan.currentPaymentDate) {
            return;
        }

        if (!dischargeDate) {
            this.setState({
                accruedInterest: null,
                accruedInterestLabel: null,
                minimumTermInterest: null,
                prepaidInterestAuto: null,
            });

            return;
        }

        const loanInterestCalculation: ILoanInterestCalculation = getLoanInterestCalculation(loan, dischargeDate);

        this.setState({
            accruedInterest: loanInterestCalculation.accruedInterest,
            accruedInterestLabel: loanInterestCalculation.accruedInterestLabel,
            minimumTermInterest: loanInterestCalculation.minimumTermInterest,
            prepaidInterestAuto: loanInterestCalculation.prepaidInterest,
        });
    }

    private validateDischargeDate(errors: IDictionary<string>): boolean {
        const { dischargeDate } = this.state;

        let error: string;

        if (!dischargeDate) {
            error = 'Please enter the proposed payout date';
        }

        errors.dischargeDate = error;

        this.setState({
            errors,
        });

        return !error;
    }

    private validateLineItemAmount(lineItem: ILineItem, errors: IDictionary<string>): boolean {
        let error: string;

        if (null === lineItem.amount) {
            error = 'Please enter the amount';
        }

        errors[`lineItem_${lineItem.code}_amount`] = error;

        this.setState({
            errors,
        });

        return !error;
    }

    private validateLineItemName(lineItem: ILineItem, errors: IDictionary<string>): boolean {
        let error: string;

        if (!lineItem.name) {
            error = 'Please enter the label';
        }

        errors[`lineItem_${lineItem.code}_name`] = error;

        this.setState({
            errors,
        });

        return !error;
    }

    private validatePrepaidInterest(errors: IDictionary<string>): boolean {
        const { prepaidInterest } = this.state;

        let error: string;

        if (null === prepaidInterest) {
            error = 'Please enter the remaining prepaid interest';
        }

        errors.prepaidInterest = error;

        this.setState({
            errors,
        });

        return !error;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        fees: loanFeesSelector(state, ownProps.match.params.uuid),
        loan: loanSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationValueSet: (applicationUuid: string, key: keyof IApplication, value: boolean|number|string) => dispatch(applicationValueSetAction(applicationUuid, key, value)),
        create: (payoutFigure: ILoanPayoutFigure) => dispatch(loanPayoutFiguresAddAction(payoutFigure)),
        feesList: () => dispatch(loanFeesListAction(ownProps.match.params.uuid)),
        loanGet: () => dispatch(loanGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PayoutFigureAdd);
