import IAdviser from '~Api/Adviser/IAdviser';
import AdvisersActionsEnum from './ActionsEnum';
import { Action } from 'redux';
import INote from '~Api/Adviser/INote';
import IHistory from '~Api/Adviser/IHistory';
import IInvestor from '~Api/Investor/IInvestor';

export interface IAdvisersAddAction extends Action {
    adviser: IAdviser;
}

export function advisersAddAction(adviser: IAdviser): IAdvisersAddAction {
    return {
        adviser,
        type: AdvisersActionsEnum.AdvisersAdd,
    };
}

export interface IAdviserApproveAction extends Action {
    adviserUuid: string;
}

export function adviserApproveAction(adviserUuid: string): IAdviserApproveAction {
    return {
        adviserUuid,
        type: AdvisersActionsEnum.AdviserApprove,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAdvisersListAction extends Action {}

export function advisersListAction(): IAdvisersListAction {
    return {
        type: AdvisersActionsEnum.AdvisersList,
    };
}

export interface IAdvisersSetAction extends Action {
    advisers: IAdviser[];
}

export function advisersSetAction(advisers: IAdviser[]): IAdvisersSetAction {
    return {
        advisers,
        type: AdvisersActionsEnum.AdvisersSet,
    };
}

export interface IAdviserGetAction extends Action {
    adviserUuid: string;
}

export function adviserGetAction(adviserUuid: string): IAdviserGetAction {
    return {
        adviserUuid,
        type: AdvisersActionsEnum.AdviserGet,
    };
}

export interface IAdviserRejectAction extends Action {
    adviserUuid: string;
}

export function adviserRejectAction(adviserUuid: string): IAdviserRejectAction {
    return {
        adviserUuid,
        type: AdvisersActionsEnum.AdviserReject,
    };
}

export interface IAdviserResetApprovalStatusAction extends Action {
    adviserUuid: string;
}

export function adviserResetApprovalStatusAction(adviserUuid: string): IAdviserResetApprovalStatusAction {
    return {
        adviserUuid,
        type: AdvisersActionsEnum.AdviserResetApprovalStatus,
    };
}

export interface IAdviserSendAction extends Action {
    adviserUuid: string;
}

export function adviserSendAction(adviserUuid: string): IAdviserSendAction {
    return {
        adviserUuid,
        type: AdvisersActionsEnum.AdviserSend,
    };
}

export interface IAdviserSetAction extends Action {
    adviser: IAdviser;
}

export function adviserSetAction(adviser: IAdviser): IAdviserSetAction {
    return {
        adviser,
        type: AdvisersActionsEnum.AdviserSet,
    };
}

export interface IAdviserValueSetAction extends Action {
    adviserUuid: string;
    key: keyof IAdviser;
    value: string|number|boolean;
}

export function adviserValueSetAction(adviserUuid: string, key: keyof IAdviser, value: string|number|boolean): IAdviserValueSetAction {
    return {
        adviserUuid,
        key,
        type: AdvisersActionsEnum.AdviserValueSet,
        value,
    };
}

export interface IAdviserHistoriesListAction extends Action {
    adviserUuid: string;
}

export function adviserHistoriesListAction(adviserUuid: string): IAdviserHistoriesListAction {
    return {
        adviserUuid,
        type: AdvisersActionsEnum.AdviserHistoriesList,
    };
}

export interface IAdviserHistoriesSetAction extends Action {
    adviserUuid: string;
    histories: IHistory[];
}

export function adviserHistoriesSetAction(adviserUuid: string, histories: IHistory[]): IAdviserHistoriesSetAction {
    return {
        adviserUuid,
        histories,
        type: AdvisersActionsEnum.AdviserHistoriesSet,
    };
}

export interface IAdviserInvestorsListAction extends Action {
    adviserUuid: string;
}

export function adviserInvestorsListAction(adviserUuid: string): IAdviserInvestorsListAction {
    return {
        adviserUuid,
        type: AdvisersActionsEnum.AdviserInvestorsList,
    };
}

export interface IAdviserInvestorsSetAction extends Action {
    adviserUuid: string;
    investors: IInvestor[];
}

export function adviserInvestorsSetAction(adviserUuid: string, investors: IInvestor[]): IAdviserInvestorsSetAction {
    return {
        adviserUuid,
        investors,
        type: AdvisersActionsEnum.AdviserInvestorsSet,
    };
}

export interface IAdviserNotesAddAction extends Action {
    adviserUuid: string;
    note: string;
}

export function adviserNotesAddAction(adviserUuid: string, note: string): IAdviserNotesAddAction {
    return {
        adviserUuid,
        note,
        type: AdvisersActionsEnum.AdviserNotesAdd,
    };
}

export interface IAdviserNotesListAction extends Action {
    adviserUuid: string;
}

export function adviserNotesListAction(adviserUuid: string): IAdviserNotesListAction {
    return {
        adviserUuid,
        type: AdvisersActionsEnum.AdviserNotesList,
    };
}

export interface IAdviserNotesSetAction extends Action {
    adviserUuid: string;
    notes: INote[];
}

export function adviserNotesSetAction(adviserUuid: string, notes: INote[]): IAdviserNotesSetAction {
    return {
        adviserUuid,
        notes,
        type: AdvisersActionsEnum.AdviserNotesSet,
    };
}

export interface IAdviserNoteRemoveAction extends Action {
    adviserUuid: string;
    noteUuid: string;
}

export function adviserNoteRemoveAction(adviserUuid: string, noteUuid: string): IAdviserNoteRemoveAction {
    return {
        adviserUuid,
        noteUuid,
        type: AdvisersActionsEnum.AdviserNoteRemove,
    };
}

export interface IAdviserNoteSetAction extends Action {
    adviserUuid: string;
    note: INote;
}

export function adviserNoteSetAction(adviserUuid: string, note: INote): IAdviserNoteSetAction {
    return {
        adviserUuid,
        note,
        type: AdvisersActionsEnum.AdviserNoteSet,
    };
}
