import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import DisbursalTypeEnum from '~Api/Application/DisbursalTypeEnum';
import DisbursementTypeEnum from '~Api/Loan/DisbursementTypeEnum';
import ILoan from '~Api/Loan/ILoan';
import ILoanDisbursement from '~Api/Loan/ILoanDisbursement';
import { loanDisbursementValueSetAction } from '~Loans/actions';
import {
    loanDisbursementLoanSelector,
    loanDisbursementSelector,
} from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

interface IDefaultedValues {
    amount: number;
    type: DisbursementTypeEnum;
}

interface IState {
    amount: number;
    dirtyFields: IDictionary<boolean>;
    type: DisbursementTypeEnum;
}

interface IProps {
    disbursementUuid: string;
    isOpen: boolean;
    onClose: () => void;
}

interface IPropsSelector {
    disbursement: ILoanDisbursement;
    loan: ILoan;
}

interface IPropsDispatch {
    update: (key: keyof ILoanDisbursement, value: any) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class DisbursementEditModal extends React.Component<Props, IState> {
    public state: IState = {
        amount: null,
        dirtyFields: {},
        type: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangePayeeName = this.onChangePayeeName.bind(this);
        this.onChangeType = this.onChangeType.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { disbursement, isOpen, loan } = this.props;

        if (!disbursement) {
            return null;
        }

        const { amount, type } = this.getDefaultedValues();

        const footer: JSX.Element = (
            <React.Fragment>
                <Button className='close' onClick={this.props.onClose}>Close</Button>
            </React.Fragment>
        );

        return (
            <Modal
                destroyOnClose={true}
                footer={footer}
                okText='Close'
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title='Edit Disbursement/Outlay'
                wrapClassName='loan-disbursement-edit-modal'
            >
                <Form.Item label='Payee Name' className='payee-name'>
                    <Input maxLength={255} onChange={this.onChangePayeeName} value={disbursement.payeeName} />
                </Form.Item>
                <Form.Item label='Amount' className='amount'>
                    <InputNumber addonBefore='$' min={0} onChange={this.onChangeAmount} value={amount} placeholder='0' />
                </Form.Item>
                <Form.Item label='Description' className='description'>
                    <Input maxLength={255} onChange={this.onChangeDescription} value={disbursement.description} />
                </Form.Item>
                <Form.Item className='type' label='Disbursement Type'>
                    <Select onChange={this.onChangeType} value={type}>
                        <Select.Option value={null}>N/A</Select.Option>
                        <Select.Option value={DisbursementTypeEnum.PrepaidInterest}>Prepaid Interest</Select.Option>
                        {loan.application.disbursalType === DisbursalTypeEnum.Drawdown && <Select.Option value={DisbursementTypeEnum.RetainedFunds}>Retained Funds</Select.Option>}
                        {loan.application.disbursalType === DisbursalTypeEnum.Drawdown && <Select.Option value={DisbursementTypeEnum.RetainedContingency}>Retained Contingency</Select.Option>}
                    </Select>
                </Form.Item>
            </Modal>
        );
    }

    private getDefaultedValues(): IDefaultedValues {
        const { disbursement } = this.props;
        const { dirtyFields, amount, type } = this.state;

        return {
            amount: dirtyFields.amount ? amount : disbursement.amount !== 0 ? disbursement.amount : null,
            type: dirtyFields.type ? type : disbursement.type,
        };
    }

    private onChangeAmount(value: number) {
        const { dirtyFields } = this.state;

        this.setState({
            amount: value || null,
            dirtyFields: {
                ...dirtyFields,
                amount: true,
            },
        });
        this.props.update('amount', value ? value : 0);
    }

    private onChangeDescription(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.update('description', event.target.value);
    }

    private onChangePayeeName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.update('payeeName', event.target.value);
    }

    private onChangeType(value: DisbursementTypeEnum) {
        const { dirtyFields } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                type: true,
            },
            type: value,
        });
        this.props.update('type', value);
    }

    private onClickOk() {
        this.props.onClose();
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        disbursement: loanDisbursementSelector(state, ownProps.disbursementUuid),
        loan: loanDisbursementLoanSelector(state, ownProps.disbursementUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        update: (key: keyof ILoanDisbursement, value: any) => dispatch(loanDisbursementValueSetAction(ownProps.disbursementUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DisbursementEditModal);
