import { Form, Modal, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CloseReasonEnum from '~Api/Deal/CloseReasonEnum';
import IDeal from '~Api/Deal/IDeal';
import { dealCloseAction } from '~Deals/actions';

interface IState {
    note: string;
    reason: CloseReasonEnum;
}

interface IProps {
    isOpen: boolean;
    deal: IDeal;
    onCancel: () => void;
}

interface IPropsDispatch {
    close: (reason: CloseReasonEnum, note: string) => void;
}

type Props = IProps & IPropsDispatch;

class CloseModal extends React.Component<Props, IState> {
    public state: IState = {
        note: null,
        reason: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeReason = this.onChangeReason.bind(this);
        this.onChangeNote = this.onChangeNote.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { note, reason } = this.state;

        return (
            <Modal
                okText='Close'
                okType='danger'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Close'
                wrapClassName='lead-close-modal'
            >
                <Form.Item className='reason' label='Reason'>
                    <Select onChange={this.onChangeReason} value={reason}>
                        <Select.Option value={CloseReasonEnum.LostToOther}>Lost to Other</Select.Option>
                        <Select.Option value={CloseReasonEnum.Stale}>Stale</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label='More Details' className='note'>
                    <TextArea onChange={this.onChangeNote} value={note} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeReason(value: CloseReasonEnum) {
        this.setState({
            reason: value,
        });
    }

    private onChangeNote(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            note: event.target.value,
        });
    }

    private onClickOk() {
        const { note, reason } = this.state;

        this.props.onCancel();

        this.props.close(reason, note);
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        close: (reason: CloseReasonEnum, note: string) => dispatch(dealCloseAction(ownProps.deal.uuid, reason, note)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(CloseModal);
