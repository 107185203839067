import { Form, Modal, Select } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IInvestorAccountIncomeTrust from '~Api/IncomeTrust/IInvestorAccountIncomeTrust';
import InterestPaymentStrategyEnum from '~Api/IncomeTrust/InterestPaymentStrategyEnum';
import { investorAccountIncomeTrustInterestPaymentStrategySetAction } from '~Investors/actions';
import { IDictionary } from '~utilities/IDictionary';

const interestPaymentStrategyLabels: IDictionary<string> = {
    [InterestPaymentStrategyEnum.Off]: 'Off',
    [InterestPaymentStrategyEnum.Reinvest]: 'Auto Reinvest',
    [InterestPaymentStrategyEnum.Withdraw]: 'Auto Withdraw',
};

interface IState {
    interestPaymentStrategy: InterestPaymentStrategyEnum;
}

interface IProps {
    investorAccountIncomeTrust: IInvestorAccountIncomeTrust;
    isOpen: boolean;
    onClose: () => void;
}

interface IPropsDispatch {
    updateInterestPaymentStrategy: (interestPaymentStrategy: InterestPaymentStrategyEnum) => void;
}

type Props = IProps & IPropsDispatch;

class IncomeTrustUpdateInterestPaymentStrategyModal extends React.Component<Props, IState> {
    public state: IState = {
        interestPaymentStrategy: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeInterestPaymentStrategy = this.onChangeInterestPaymentStrategy.bind(this);
        this.onClickOk = this.onClickOk.bind(this);

        this.state.interestPaymentStrategy = props.investorAccountIncomeTrust.interestPaymentStrategy;
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const { interestPaymentStrategy } = this.state;

        return (
            <Modal
                destroyOnClose={true}
                onCancel={this.props.onClose}
                onOk={this.onClickOk}
                open={isOpen}
                title='Interest Payment Strategy'
                wrapClassName='investor-income-trust-update-strategy-modal'
            >
                <Form.Item label='Strategy'>
                    <Select onChange={this.onChangeInterestPaymentStrategy} value={interestPaymentStrategy ?? InterestPaymentStrategyEnum.Off}>
                        <Select.Option value={InterestPaymentStrategyEnum.Off}>{interestPaymentStrategyLabels[InterestPaymentStrategyEnum.Off]}</Select.Option>
                        <Select.Option value={InterestPaymentStrategyEnum.Withdraw}>{interestPaymentStrategyLabels[InterestPaymentStrategyEnum.Withdraw]}</Select.Option>
                        <Select.Option value={InterestPaymentStrategyEnum.Reinvest}>{interestPaymentStrategyLabels[InterestPaymentStrategyEnum.Reinvest]}</Select.Option>
                    </Select>
                </Form.Item>
            </Modal>
        );
    }

    private onChangeInterestPaymentStrategy(value: InterestPaymentStrategyEnum): void {
        this.setState({
            interestPaymentStrategy: value,
        });
    }

    private onClickOk(): void {
        const { interestPaymentStrategy } = this.state;

        this.props.updateInterestPaymentStrategy(interestPaymentStrategy);
        this.props.onClose();
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        updateInterestPaymentStrategy: (interestPaymentStrategy: InterestPaymentStrategyEnum) => dispatch(investorAccountIncomeTrustInterestPaymentStrategySetAction(ownProps.investorAccountIncomeTrust.uuid, interestPaymentStrategy)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(IncomeTrustUpdateInterestPaymentStrategyModal);
