import { Card as AntCard, Progress, Tag } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import ILoan from '~Api/Loan/ILoan';
import WorkflowStatusEnum from '~Api/Loan/WorkflowStatusEnum';
import { IDictionary } from '~utilities/IDictionary';
import { BoardColumnEnum } from '../Dashboard';
import IAdministrator from '~Api/Administrator/IAdministrator';
import { connect } from 'react-redux';
import { currentAdministratorSelector } from '~Administrators/selectors';
import { IGlobalState } from '~reducer';
import AssigneeSelector from './AssigneeSelector';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import RoleEnum from '~Api/Administrator/RoleEnum';

interface ITag {
    colour: string;
    label: string;
}

const tagDetails: IDictionary<ITag> = {
    [WorkflowStatusEnum.Discharge]: {
        colour: 'green',
        label: 'Discharge',
    },
    [WorkflowStatusEnum.Extension]: {
        colour: 'blue',
        label: 'Extension',
    },
    [WorkflowStatusEnum.GracePeriod]: {
        colour: 'orange',
        label: 'Grace Period',
    },
    [WorkflowStatusEnum.Recovery]: {
        colour: 'red',
        label: 'Recovery',
    },
};

interface IProps {
    boardColumn: BoardColumnEnum;
    loan: ILoan;
}

interface IPropsSelector {
    currentAdministrator: IAdministrator;
}

type Props = IProps & IPropsSelector;

class Card extends React.Component<Props> {
    public render(): JSX.Element {
        const { boardColumn, loan, currentAdministrator } = this.props;

        const todayDayjs: Dayjs = dayjs();
        const maturingSoonDate: Dayjs = dayjs().add(14, 'days');

        const startDateDayjs: Dayjs = dayjs(loan.startDate);
        const endDateDayjs: Dayjs = dayjs(loan.endDate);
        const nextPaymentDateDayjs: Dayjs = !!loan.paymentDateNext ? dayjs(loan.paymentDateNext) : null;
        const gracePeriodEndDateDayjs: Dayjs = dayjs(loan.gracePeriodEndDate);
        const dischargeDateDayjs: Dayjs = !!loan.dischargeDate ? dayjs(loan.dischargeDate) : null;

        const monthDisplay: string = (loan.termMonths === 1) ? 'month' : 'months';
        const totalTermDays: number = dayjs.duration(endDateDayjs.diff(startDateDayjs)).as('days');
        const completedTermDays: number = dayjs.duration(todayDayjs.diff(startDateDayjs)).as('days');

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        return (
            <AntCard key={loan.uuid} className={(boardColumn === BoardColumnEnum.Maturing && endDateDayjs < maturingSoonDate) && 'maturing-soon'}>
                <div className='code'>{loan.code || loan.salesforceCode}</div>
                <Link className='name' to={`/loans/${loan.uuid}`}>{loan.name || '-'}</Link>
                <div className='amount'>{currencyFormatter.format(loan.amount)}</div>
                <div className='interest-rate'>{percentageFormatter.format(loan.interestRate / 100)}</div>
                <div className='assignee'><AssigneeSelector loan={loan} /></div>
                <div className='term'>{loan.termMonths} {monthDisplay}</div>
                {[BoardColumnEnum.Active, BoardColumnEnum.Drawdown].includes(boardColumn) && <div className='next-payment-date'>Next payment {nextPaymentDateDayjs ? nextPaymentDateDayjs.fromNow() : 'date unknown'}</div>}
                {endDateDayjs.isBefore(todayDayjs) && <div className='overdue-date'>Overdue {nextPaymentDateDayjs ? nextPaymentDateDayjs.fromNow() : 'date unknown'}</div>}
                {boardColumn === BoardColumnEnum.Maturing && <div className='end-date'>Maturing {endDateDayjs ? endDateDayjs.fromNow() : 'date unknown'}</div>}
                {[BoardColumnEnum.PastDue, BoardColumnEnum.Maturing].includes(boardColumn) && dischargeDateDayjs && <div className='discharge-date'>Discharges {dischargeDateDayjs.fromNow()}</div>}
                {loan.isInGracePeriod && <div className='grace-period-end-date'>Grace Period ends {gracePeriodEndDateDayjs.fromNow()}</div>}
                <Progress percent={completedTermDays / totalTermDays * 100} showInfo={false} size='small' strokeColor='#1890ff' />
                {[WorkflowStatusEnum.Discharge, WorkflowStatusEnum.Extension, WorkflowStatusEnum.GracePeriod, WorkflowStatusEnum.Recovery].includes(loan.workflowStatus) && <Tag color={tagDetails[loan.workflowStatus].colour}>{tagDetails[loan.workflowStatus].label}</Tag>}
            </AntCard>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentAdministrator: currentAdministratorSelector(state),
    };
}

export default connect(
    mapStateToProps,
    null,
)(Card);
