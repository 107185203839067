enum LoansActionsEnum {
    LoanActive = 'LOAN/ACTIVE',
    LoanAssign = 'LOAN/ASSIGN',
    LoanDischarge = 'LOAN/DISCHARGE',
    LoanDischargeDate = 'LOAN/DISCHARGE_DATE',
    LoanDrawdown = 'LOAN/DRAWDOWN',
    LoanExtension = 'LOAN/EXTENSION',
    LoanFund = 'LOAN/FUND',
    LoanGet = 'LOAN/GET',
    LoanGracePeriod = 'LOAN/GRACE_PERIOD',
    LoanListSettingsSet = 'LOAN/LIST_SETTINGS/SET',
    LoanPrincipalReduction = 'LOAN/PRINCIPAL_REDUCTION',
    LoanRecordNextPayment = 'LOAN/RECORD_NEXT_PAYMENT',
    LoanRecovery = 'LOAN/RECOVERY',
    LoanGrantGracePeriod = 'LOAN/GRANT_GRACE_PERIOD',
    LoanSend = 'LOAN/SEND',
    LoanSendMaturationReminder = 'LOAN/SEND_MATURATION_REMINDER',
    LoanSet = 'LOAN/SET',
    LoanSkipMaturationReminder = 'LOAN/SKIP_MATURATION_REMINDER',
    LoanValueSet = 'LOAN/VALUE/SET',

    LoanDisbursementDelete = 'LOAN/DISBURSEMENT/DELETE',
    LoanDisbursementSend = 'LOAN/DISBURSEMENT/SEND',
    LoanDisbursementSet = 'LOAN/DISBURSEMENT/SET',
    LoanDisbursementValueSet = 'LOAN/DISBURSEMENT/VALUE/SET',

    LoanDisbursementsAdd = 'LOAN/DISBURSEMENTS/ADD',
    LoanDisbursementsList = 'LOAN/DISBURSEMENTS/LIST',
    LoanDisbursementsSet = 'LOAN/DISBURSEMENTS/SET',

    LoanDocumentsAdd = 'LOAN/DOCUMENTS/ADD',
    LoanDocumentsList = 'LOAN/DOCUMENTS/LIST',
    LoanDocumentsSet = 'LOAN/DOCUMENTS/SET',

    LoanDocumentDelete = 'LOAN/DOCUMENT/DELETE',
    LoanDocumentRemove = 'LOAN/DOCUMENT/REMOVE',
    LoanDocumentSet = 'LOAN/DOCUMENT/SET',

    LoanFeeDelete = 'LOAN/FEE/DELETE',
    LoanFeeRemove = 'LOAN/FEE/REMOVE',
    LoanFeeSend = 'LOAN/FEE/SEND',
    LoanFeeSet = 'LOAN/FEE/SET',
    LoanFeeValueSet = 'LOAN/FEE/VALUE/SET',

    LoanFeesAdd = 'LOAN/FEES/ADD',
    LoanFeesList = 'LOAN/FEES/LIST',
    LoanFeesSet = 'LOAN/FEES/SET',

    LoanGracePeriodSet = 'LOAN/GRACE_PERIOD/SET',

    LoanGracePeriodsAdd = 'LOAN/GRACE_PERIODS/ADD',
    LoanGracePeriodsList = 'LOAN/GRACE_PERIODS/LIST',
    LoanGracePeriodsSet = 'LOAN/GRACE_PERIODS/SET',

    LoanHistoriesList = 'LOAN/HISTORIES/LIST',
    LoanHistoriesSet = 'LOAN/HISTORIES/SET',

    LoanNoteSet = 'LOAN/NOTE/SET',

    LoanNotesAdd = 'LOAN/NOTES/ADD',
    LoanNotesList = 'LOAN/NOTES/LIST',
    LoanNotesSet = 'LOAN/NOTES/SET',

    LoanPayoutFigureApprove = 'LOAN/PAYOUT_FIGURE/APPROVE',
    LoanPayoutFigureDelete = 'LOAN/PAYOUT_FIGURE/DELETE',
    LoanPayoutFigureGet = 'LOAN/PAYOUT_FIGURE/GET',
    LoanPayoutFigureGetWithLoanAndApplication = 'LOAN/PAYOUT_FIGURE/GET_WITH_LOAN_AND_APPLICATION',
    LoanPayoutFigureProcess = 'LOAN/PAYOUT_FIGURE/PROCESS',
    LoanPayoutFigureRemove = 'LOAN/PAYOUT_FIGURE/REMOVE',
    LoanPayoutFigureSend = 'LOAN/PAYOUT_FIGURE/SEND',
    LoanPayoutFigureSet = 'LOAN/PAYOUT_FIGURE/SET',
    LoanPayoutFigureValueSet = 'LOAN/PAYOUT_FIGURE/VALUE/SET',

    LoanPayoutFigureItemAdd = 'LOAN/PAYOUT_FIGURE/ITEM/ADD',
    LoanPayoutFigureItemDelete = 'LOAN/PAYOUT_FIGURE/ITEM/DELETE',
    LoanPayoutFigureItemSend = 'LOAN/PAYOUT_FIGURE/ITEM/SEND',
    LoanPayoutFigureItemSet = 'LOAN/PAYOUT_FIGURE/ITEM/SET',
    LoanPayoutFigureItemValueSet = 'LOAN/PAYOUT_FIGURE/ITEM/VALUE/SET',

    LoanPayoutFigureSectionAdd = 'LOAN/PAYOUT_FIGURE/SECTION/ADD',
    LoanPayoutFigureSectionDelete = 'LOAN/PAYOUT_FIGURE/SECTION/DELETE',
    LoanPayoutFigureSectionSet = 'LOAN/PAYOUT_FIGURE/SECTION/SET',

    LoanPayoutFiguresAdd = 'LOAN/PAYOUT_FIGURES/ADD',
    LoanPayoutFiguresList = 'LOAN/PAYOUT_FIGURES/LIST',
    LoanPayoutFiguresSet = 'LOAN/PAYOUT_FIGURES/SET',

    LoanPropertiesList = 'LOAN/PROPERTIES/LIST',
    LoanPropertiesSet = 'LOAN/PROPERTIES/SET',

    LoanPropertySend = 'LOAN/PROPERTY/SEND',
    LoanPropertySet = 'LOAN/PROPERTY/SET',
    LoanPropertyValueSet = 'LOAN/PROPERTY/VALUE/SET',

    LoanTransactionsList = 'LOAN/TRANSACTIONS/LIST',
    LoanTransactionsSet = 'LOAN/TRANSACTIONS/SET',

    LoanWarehouseLoansList = 'LOAN/WAREHOUSE_LOANS/LIST',
    LoanWarehouseLoansSet = 'LOAN/WAREHOUSE_LOANS/SET',

    LoansDashboardList = 'LOANS/DASHBOARD/LIST',
    LoansDashboardSet = 'LOANS/DASHBOARD/SET',
    LoansDischargeForecastList = 'LOANS/DISCHARGE_FORECAST/LIST',
    LoansDischargeForecastSet = 'LOANS/DISCHARGE_FORECAST/SET',
    LoansDrawdownsList = 'LOANS/DRAWDOWNS/LIST',
    LoansDrawdownsSet = 'LOANS/DRAWDOWNS/SET',
    LoansPaginatedList = 'LOANS/PAGINATED_LIST',
    LoansPaginatedSet = 'LOANS/PAGINATED_SET',
    /** @deprecated */
    LoansList = 'LOANS/LIST',
    /** @deprecated */
    LoansSet = 'LOANS/SET',

    LoansSearch = 'LOANS/SEARCH',
    LoansSearchResultsSet = 'LOANS/SEARCH/RESULTS/SET',
    LoansSearchResultsClear = 'LOANS/SEARCH/RESULTS/CLEAR',
}

export default LoansActionsEnum;
