import { Breadcrumb, Button, Layout, Modal, Spin, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import ExtensionTypeEnum from '~Api/Loan/ExtensionTypeEnum';
import ILoan from '~Api/Loan/ILoan';
import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import { IGlobalState } from '~reducer';
import {
    loanRecordNextPaymentAction,
    loansListAction,
} from './actions';
import { loansSelector } from './selectors';
import { IDictionary } from '~utilities/IDictionary';
import { ColumnType } from 'antd/lib/table';

const loanStatusLabels: IDictionary<string> = {
    [LoanStatusEnum.ActiveBadStanding]: 'Active - Bad Standing',
    [LoanStatusEnum.ActiveGoodStanding]: 'Active - Good Standing',
    [LoanStatusEnum.ActiveMatured]: 'Active - Matured',
    [LoanStatusEnum.Approved]: 'Approved',
    [LoanStatusEnum.Cancelled]: 'Cancelled',
    [LoanStatusEnum.ClosedObligationsMet]: 'Closed - Obligations Met',
    [LoanStatusEnum.ClosedRefinanced]: 'Closed - Refinanced',
    [LoanStatusEnum.ClosedWrittenOff]: 'Closed - Written Off',
    [LoanStatusEnum.PartialApplication]: 'Partial Application',
    [LoanStatusEnum.PendingApproval]: 'Pending Approval',
};

interface IState {
    pendingUuids: string[];
}

interface IPropsSelector {
    currentUser: IAuthUser;
    loans: { [loanUuid: string]: ILoan };
}

interface IPropsDispatch {
    loansList: () => void;
    recordNextPayment: (uuid: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Due extends React.Component<Props, IState> {
    public state: IState = {
        pendingUuids: [],
    };

    constructor(props: Props) {
        super(props);

        this.onClickRecordFinalPayment = this.onClickRecordFinalPayment.bind(this);
        this.onClickRecordNextPayment = this.onClickRecordNextPayment.bind(this);
    }

    public componentDidMount(): void {
        this.props.loansList();
    }

    public render(): JSX.Element {
        const { currentUser, loans } = this.props;
        const { pendingUuids } = this.state;

        if (!loans) {
            return (
                <Layout className='loans'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/loans'>Loans</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Due Loans</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Due Loans</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const columns: ColumnType<ILoan>[] = [
            {
                dataIndex: 'code',
                render: (code: string, loan: ILoan) => <Link to={`/loans/${loan.uuid}`}>{code}</Link>,
                title: 'Code',
            },
            {
                dataIndex: 'paymentDateNext',
                render: (paymentDateNext: string) => dayjs(paymentDateNext).format('Do MMMM YYYY'),
                title: 'Payment Date',
                width: '15%',
            },
            {
                dataIndex: 'paymentAmountNext',
                render: (amount: number) => currencyFormatter.format(amount),
                title: 'Amount',
                width: '10%',
            },
            {
                dataIndex: 'status',
                render: (status: LoanStatusEnum) => loanStatusLabels[status],
                title: 'Status',
                width: '15%',
            },
        ];

        if (currentUser.permissions.includes(PermissionsEnum.Accounts)) {
            columns.push({
                render: (loan: ILoan) => {
                    if (loan.extensionType !== ExtensionTypeEnum.GracePeriod && dayjs().isSameOrAfter(dayjs(loan.endDate), 'day')) {
                        const onClick = () => this.onClickRecordFinalPayment(loan);
                        return (
                            <Button danger={true} disabled={pendingUuids.includes(loan.uuid)} onClick={onClick} type='primary'>Record Final Interest Payment</Button>
                        );
                    }

                    const onClick = () => this.onClickRecordNextPayment(loan);
                    return (
                        <Button disabled={pendingUuids.includes(loan.uuid)} onClick={onClick} type='primary'>Record Next Payment</Button>
                    );
                },
                title: 'Actions',
                width: '15%',
            });
        }

        const filteredLoans: ILoan[] = _.sortBy(_.filter(loans, (loan: ILoan) => {
            if (![LoanStatusEnum.ActiveBadStanding, LoanStatusEnum.ActiveGoodStanding, LoanStatusEnum.ActiveMatured].includes(loan.status)) {
                return false;
            }

            if (loan.extensionType === ExtensionTypeEnum.GracePeriod) {
                return false;
            }

            if (loan.paymentAmountNext <= 0) {
                return false;
            }

            if (!loan.paymentDateNext || dayjs(loan.paymentDateNext) > dayjs()) {
                return false;
            }

            return true;
        }), ['paymentDateNext']);

        return (
            <Layout className='loans'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/loans'>Loans</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Due Loans</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Due Loans</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={filteredLoans}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    private onClickRecordFinalPayment(loan: ILoan): void {
        const { pendingUuids } = this.state;

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const code: string = loan.salesforceCode && loan.salesforceCode !== loan.code ? loan.code + ' (' + loan.salesforceCode + ')' : loan.code;

        Modal.confirm({
            content: <>Are you sure you want to record a final <strong>interest only</strong> payment of {currencyFormatter.format(loan.paymentAmountNext)} for {code}?</>,
            okText: 'Record',
            onOk: () => {
                this.props.recordNextPayment(loan.uuid);
                this.setState({
                    pendingUuids: [
                        ...pendingUuids,
                        loan.uuid,
                    ],
                });
            },
            title: 'Record Final Interest Payment',
        });
    }

    private onClickRecordNextPayment(loan: ILoan): void {
        const { pendingUuids } = this.state;

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const code: string = loan.salesforceCode && loan.salesforceCode !== loan.code ? loan.code + ' (' + loan.salesforceCode + ')' : loan.code;

        Modal.confirm({
            content: `Are you sure you want to record a payment of ${currencyFormatter.format(loan.paymentAmountNext)} for ${code}?`,
            okText: 'Record',
            onOk: () => {
                this.props.recordNextPayment(loan.uuid);
                this.setState({
                    pendingUuids: [
                        ...pendingUuids,
                        loan.uuid,
                    ],
                });
            },
            title: 'Record Next Payment',
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentUser: authCurrentUserSelector(state),
        loans: loansSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        loansList: () => dispatch(loansListAction()),
        recordNextPayment: (uuid: string) => dispatch(loanRecordNextPaymentAction(uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Due);
