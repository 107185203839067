enum WithdrawalAbasActionsEnum {
    WithdrawalAbaGet = 'WITHDRAWAL_ABA/GET',
    WithdrawalAbaSend = 'WITHDRAWAL_ABAS/SEND',
    WithdrawalAbaSet = 'WITHDRAWAL_ABA/SET',

    WithdrawalAbasAdd = 'WITHDRAWAL_ABAS/ADD',
    WithdrawalAbasList = 'WITHDRAWAL_ABAS/LIST',
    WithdrawalAbasSet = 'WITHDRAWAL_ABAS/SET',

    WithdrawalAbaHistoriesList = 'WITHDRAWAL_ABA/HISTORIES/LIST',
    WithdrawalAbaHistoriesSet = 'WITHDRAWAL_ABA/HISTORIES/SET',

    WithdrawalAbaInvestorTransactionsList = 'WITHDRAWAL_ABA/INVESTOR_TRANSACTIONS/LIST',
    WithdrawalAbaInvestorTransactionsSet = 'WITHDRAWAL_ABA/INVESTOR_TRANSACTIONS/SET',

    WithdrawalAbasInvalidInvestorTransactionsList = 'WITHDRAWAL_ABAS/INVALID_INVESTOR_TRANSACTIONS/LIST',
    WithdrawalAbasInvalidInvestorTransactionsSet = 'WITHDRAWAL_ABAS/INVALID_INVESTOR_TRANSACTIONS/SET',

    WithdrawalAbasUnallocatedInvestorTransactionsList = 'WITHDRAWAL_ABAS/UNALLOCATED_INVESTOR_TRANSACTIONS/LIST',
    WithdrawalAbasUnallocatedInvestorTransactionsSet = 'WITHDRAWAL_ABAS/UNALLOCATED_INVESTOR_TRANSACTIONS/SET',
}

export default WithdrawalAbasActionsEnum;
