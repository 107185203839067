import { Dropdown, MenuProps, Space, Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import zoneTypeLabels from '~Api/Deal/ZoneTypeLabels';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import WarehouseTypeEnum from '~Api/Warehouse/WarehouseTypeEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import PostcodeCategory from '~Deals/PostcodeCategory';
import LoanStatusTag from '~Loans/LoanStatusTag';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';
import {
    warehouseGetAction,
    warehouseLoansListAction,
} from '~Warehouses/actions';
import {
    warehouseLoansSelector,
    warehouseSelector,
} from '~Warehouses/selectors';
import Layout from './Layout';
import SellLoanModal from './SellLoanModal';
import ProposeSaleModal from './ProposeSaleModal';
import ILoan from '~Api/Loan/ILoan';

enum WarehouseLoanStatusEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
}

export default function Loans(): ReactElement {
    const { warehouseUuid } = useParams<{ warehouseUuid: string }>();

    const [ isProposeSaleModalOpen, setIsProposeSaleModalOpen ] = useState<boolean>(false);
    const [ isSellLoanModalOpen, setIsSellLoanModalOpen ] = useState<boolean>(false);
    const [ currentWarehouseLoanUuid, setCurrentWarehouseLoanUuid ] = useState<string>(null);

    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);
    const warehouse: IWarehouse = useSelector((state: IGlobalState) => warehouseSelector(state, warehouseUuid));
    const warehouseLoans: IDictionary<IWarehouseLoan> = useSelector((state: IGlobalState) => warehouseLoansSelector(state, warehouseUuid));

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!warehouse) {
            dispatch(warehouseGetAction(warehouseUuid));
        }
    }, [
        dispatch,
        warehouse,
        warehouseUuid,
    ]);

    useEffect(() => {
        dispatch(warehouseLoansListAction(warehouseUuid));
    }, [
        dispatch,
        warehouseUuid,
    ]);

    const onClickSellLoan: (warehouseLoanUuid: string) => void = useCallback((warehouseLoanUuid: string) => {
        setCurrentWarehouseLoanUuid(warehouseLoanUuid);
        setIsSellLoanModalOpen(true);
    }, []);

    const onClickSellLoanCancel: () => void = useCallback(() => {
        setIsSellLoanModalOpen(false);
    }, []);

    const onClickProposeSale: (warehouseLoanUuid: string) => void = useCallback((warehouseLoanUuid: string) => {
        setCurrentWarehouseLoanUuid(warehouseLoanUuid);
        setIsProposeSaleModalOpen(true);
    }, []);

    const onClickProposeSaleCancel: () => void = useCallback(() => {
        setIsProposeSaleModalOpen(false);
    }, []);

    const summary: () => JSX.Element = useCallback(() => {
        const totalPrincipal: number = _.reduce(warehouseLoans, (sum: number, warehouseLoan: IWarehouseLoan) => {
            if (null === warehouseLoan.balancePrincipal) {
                return sum + (warehouseLoan.loan ? warehouseLoan.loan.amount * -1 : 0);
            }

            return sum + warehouseLoan.balancePrincipal;
        }, 0);

        const totalLoanAmountRemaining: number = _.reduce(warehouseLoans, (sum: number, warehouseLoan: IWarehouseLoan) => {
            if (warehouseLoan.balancePrincipal < 0) {
                return sum + warehouseLoan.loan.amountRemaining;
            }

            return sum;
        }, 0);

        return (
            <Table.Summary fixed>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>&nbsp;</Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>&nbsp;</Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>&nbsp;</Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>{currencyFormatter.format(totalLoanAmountRemaining)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>&nbsp;</Table.Summary.Cell>
                    <Table.Summary.Cell index={6}>&nbsp;</Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>&nbsp;</Table.Summary.Cell>
                    <Table.Summary.Cell index={8}>&nbsp;</Table.Summary.Cell>
                    <Table.Summary.Cell index={9}>&nbsp;</Table.Summary.Cell>
                    <Table.Summary.Cell index={10}>{currencyFormatter.format(totalPrincipal)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={11}>&nbsp;</Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        );
    }, [warehouseLoans]);

    if (!warehouse || !warehouseLoans) {
        return (
            <Layout uuid={warehouseUuid} section='loans'>
                <Typography.Title level={2}>Loans</Typography.Title>
                <Spin/>
            </Layout>
        );
    }

    const columns: ColumnsType<IWarehouseLoan> = [
        {
            fixed: 'left',
            render: (warehouseLoan: IWarehouseLoan) => <><Link to={`/warehouses/${warehouseUuid}/loans/${warehouseLoan.uuid}`}>{warehouseLoan.loan.code}</Link> {warehouseLoan.loan.salesforceCode && warehouseLoan.loan.salesforceCode !== warehouseLoan.loan.code && `(${warehouseLoan.loan.salesforceCode})`}</>,
            sorter: (a: IWarehouseLoan, b: IWarehouseLoan) => {
                if (!a.loan) {
                    return -1;
                }

                if (!b.loan) {
                    return 1;
                }

                return a.loan.code.localeCompare(b.loan.code);
            },
            title: 'Code',
            width: 150,
        },
        {
            render: (warehouseLoan: IWarehouseLoan) => <Link to={`/warehouses/${warehouseUuid}/loans/${warehouseLoan.uuid}`}>{warehouseLoan.loan.name}</Link>,
            sorter: (a: IWarehouseLoan, b: IWarehouseLoan) => {
                if (!a.loan) {
                    return -1;
                }

                if (!b.loan) {
                    return 1;
                }

                return a.loan.name.localeCompare(b.loan.name);
            },
            title: 'Name',
            width: 200,
        },
        {
            render: (warehouseLoan: IWarehouseLoan) => {
                let primaryProperty: IApplicationProperty = null;
                let highestPropertyValue: number = 0;
                _.each(warehouseLoan.loan.application.properties, (property: IApplicationProperty) => {
                    const propertyValue: number = property.valuationValue || property.dealProperty.estimatedValue;

                    if (propertyValue > highestPropertyValue) {
                        primaryProperty = property;
                        highestPropertyValue = propertyValue;
                    }
                });

                return primaryProperty ? zoneTypeLabels[primaryProperty.dealProperty.zoneType] : '-';
            },
            title: 'Property Type',
            width: 180,
        },
        {
            render: (warehouseLoan: IWarehouseLoan) => {
                let primaryProperty: IApplicationProperty = null;
                let highestPropertyValue: number = 0;
                _.each(warehouseLoan.loan.application.properties, (property: IApplicationProperty) => {
                    const propertyValue: number = property.valuationValue || property.dealProperty.estimatedValue;

                    if (propertyValue > highestPropertyValue) {
                        primaryProperty = property;
                        highestPropertyValue = propertyValue;
                    }
                });

                return (
                    <Space>
                        <PostcodeCategory property={primaryProperty.dealProperty} />
                    </Space>
                );
            },
            title: 'Property Category',
            width: 220,
        },
        {
            render: (warehouseLoan: IWarehouseLoan) => currencyFormatter.format(warehouseLoan.loan.amountRemaining),
            title: 'Amount Remaining (Loan)',
            width: 180,
        },
        {
            render: (warehouseLoan: IWarehouseLoan) => warehouseLoan.loan.application.lvr ? percentageFormatter.format(warehouseLoan.loan.application.lvr / 100) : '-',
            sorter: (a: IWarehouseLoan, b: IWarehouseLoan) => {
                return a.loan.application.lvr > b.loan.application.lvr ? 1 : -1;
            },
            title: 'LVR',
            width: 100,
        },
        {
            render: (warehouseLoan: IWarehouseLoan) => `${warehouseLoan.loan.termMonths} months`,
            sorter: (a: IWarehouseLoan, b: IWarehouseLoan) => {
                return a.loan.termMonths > b.loan.termMonths ? 1 : -1;
            },
            title: 'Term',
            width: 120,
        },
        {
            dataIndex: ['loan', 'endDate'],
            render: (endDate: string) => dayjs(endDate).format('Do MMMM YYYY'),
            sorter: (a: IWarehouseLoan, b: IWarehouseLoan) => {
                const aMaturityDate: Dayjs = dayjs(a.loan.endDate);
                const bMaturityDate: Dayjs = dayjs(b.loan.endDate);
                return aMaturityDate > bMaturityDate ? 1 : -1;
            },
            title: 'Maturity Date',
            width: 180,
        },
        {
            render: (warehouseLoan: IWarehouseLoan): JSX.Element => {
                if (!warehouseLoan.loan) {
                    return;
                }

                return <LoanStatusTag loan={warehouseLoan.loan} />;
            },
            title: 'Loan Status',
            width: 150,
        },
        {
            render: (warehouseLoan: IWarehouseLoan) => percentageFormatter.format(warehouseLoan.loan.interestRate / 100),
            sorter: (a: IWarehouseLoan, b: IWarehouseLoan) => {
                return a.loan.interestRate > b.loan.interestRate ? 1 : -1;
            },
            title: 'Interest Rate',
            width: 120,
        },
        {
            dataIndex: 'loan',
            render: (loan: ILoan) => {
                const totalRetained: number = loan.retainedContingency + loan.retainedFunds;

                return totalRetained > 0 ? currencyFormatter.format(totalRetained) : '-';
            },
            sorter: (a: IWarehouseLoan, b: IWarehouseLoan) => {
                const aRetained: number = a.loan.retainedContingency + a.loan.retainedFunds;
                const bRetained: number = b.loan.retainedContingency + b.loan.retainedFunds;

                return aRetained > bRetained ? 1 : -1;
            },
            title: 'Retained',
            width: 180,
        },
        {
            dataIndex: 'balancePrincipal',
            defaultFilteredValue: [WarehouseLoanStatusEnum.Active],
            defaultSortOrder: 'ascend',
            filters: [
                {
                    text: 'Active',
                    value: WarehouseLoanStatusEnum.Active,
                },
                {
                    text: 'Inactive',
                    value: WarehouseLoanStatusEnum.Inactive,
                },
            ],
            fixed: 'right',
            onFilter: (value: string | number | boolean, warehouseLoan: IWarehouseLoan) => value === WarehouseLoanStatusEnum.Active && warehouseLoan.balancePrincipal < 0,
            render: (balancePrincipal: number, warehouseLoan: IWarehouseLoan) => {
                if (null === balancePrincipal) {
                    return warehouseLoan.loan ? currencyFormatter.format(warehouseLoan.loan.amount * -1) : '-';
                }

                return currencyFormatter.format(balancePrincipal);
            },
            sorter: (a: IWarehouseLoan, b: IWarehouseLoan) => {
                return a.balancePrincipal > b.balancePrincipal ? 1 : -1;
            },
            title: 'Principal',
            width: 180,
        },
    ];

    if (currentUser.permissions.includes(PermissionsEnum.WarehouseLoanSell) && ![WarehouseTypeEnum.Private].includes(warehouse.type)) {
        columns.push({
            fixed: 'right',
            render: (warehouseLoan: IWarehouseLoan) => {
                if (warehouseLoan.balancePrincipal < 0) {
                    const onClick: () => void = () => onClickProposeSale(warehouseLoan.uuid);

                    const menu: MenuProps = {
                        items: [
                            {
                                key: 'sell-loan',
                                label: 'Sell Loan',
                                onClick: () => onClickSellLoan(warehouseLoan.uuid),
                            },
                        ],
                    };

                    return (
                        <Space>
                            <Dropdown.Button
                                menu={menu}
                                type='primary'
                                onClick={onClick}
                            >Propose Sale</Dropdown.Button>
                        </Space>
                    );
                }
            },
            title: 'Actions',
            width: 200,
        });
    }

    return (
        <Layout uuid={warehouseUuid} section='loans'>
            <Typography.Title level={2}>Loans</Typography.Title>
            <Table
                columns={columns}
                dataSource={_.values(warehouseLoans)}
                pagination={false}
                rowKey='uuid'
                scroll={{ x: 1, y: 800 }}
                size='middle'
                summary={summary}
            />
            <SellLoanModal
                isOpen={isSellLoanModalOpen}
                onCancel={onClickSellLoanCancel}
                warehouseLoanUuid={currentWarehouseLoanUuid}
            />
            <ProposeSaleModal
                isOpen={isProposeSaleModalOpen}
                onCancel={onClickProposeSaleCancel}
                warehouseLoanUuid={currentWarehouseLoanUuid}
            />
        </Layout>
    );
}
