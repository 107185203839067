import _ from 'lodash';
import IInvestorUser from '~Api/Investor/IInvestorUser';
import IHistory from '~Api/User/IHistory';
import IUser from '~Api/User/IUser';
import { investorSelector } from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

export function usersSelector(state: IGlobalState): IUser[] {
    return state.users.usersListed && _.values(state.users.users);
}

export function usersSuspiciousSelector(state: IGlobalState): IDictionary<IUser> {
    if (!state.users.usersSuspiciousUuids || !state.users.users) {
        return null;
    }

    const users: IDictionary<IUser> = {};
    _.each(state.users.usersSuspiciousUuids, (uuid: string) => {
        users[uuid] = userSelector(state, uuid);
    });

    return users;
}

export function userSelector(state: IGlobalState, uuid: string): IUser {
    return state.users.users && state.users.users[uuid];
}

export function userHistoriesSelector(state: IGlobalState, userUuid: string): IHistory[] {
    return state.users.histories[userUuid] && _.values(state.users.histories[userUuid]);
}

export function userInvestorsSelector(state: IGlobalState, userUuid: string): IInvestorUser[] {
    return state.investors.investors && state.users.usersInvestors && state.users.usersInvestors[userUuid] &&  _.values(state.users.usersInvestors[userUuid]).map((investorUser: IInvestorUser) => {
        investorUser.investor = investorSelector(state, investorUser.investorUuid);
        return investorUser;
    });
}
