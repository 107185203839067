import { Button, Popover, Space, Spin, Table, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { FiMapPin } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IProperty from '~Api/Deal/IProperty';
import PropertyAddressVersionUpgradeModal from '~Deals/PropertyAddressVersionUpgradeModal';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { applicationGetAction, applicationPropertiesListAction } from './actions';
import './applications.less';
import PostcodeCategory from '~Deals/PostcodeCategory';
import PropertyAddModal from '~Deals/PropertyAddModal';
import PropertyEditModal from '~Deals/PropertyEditModal';
import { applicationPropertiesSelector, applicationSelector } from './selectors';
import { ColumnsType } from 'antd/lib/table';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import { currencyFormatter } from '~utilities/formatters';
import zoneTypeLabels from '~Api/Deal/ZoneTypeLabels';
import IApplication from '~Api/Application/IApplication';
import { WarningOutlined } from '@ant-design/icons';

interface IState {
    editPropertyUuid: string;
    isPropertyAddressVersionUpgradeModalOpen: boolean;
    isPropertyModalOpen: boolean;
}

interface IProps {
    applicationUuid: string;
}

interface IPropsSelector {
    application: IApplication;
    applicationProperties: IDictionary<IApplicationProperty>;
}

interface IPropsDispatch {
    applicationGet: () => void;
    applicationPropertiesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class applicationPropertiesList extends React.Component<Props> {
    public state: IState = {
        editPropertyUuid: null,
        isPropertyAddressVersionUpgradeModalOpen: false,
        isPropertyModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.closePropertyUpgradeAddressVersionModal = this.closePropertyUpgradeAddressVersionModal.bind(this);
        this.onClickAddProperty = this.onClickAddProperty.bind(this);
        this.onCloseAddProperty = this.onCloseAddProperty.bind(this);
        this.onClickEditProperty = this.onClickEditProperty.bind(this);
        this.onClickPropertyUpgradeAddressVersion = this.onClickPropertyUpgradeAddressVersion.bind(this);
        this.onCloseEditProperty = this.onCloseEditProperty.bind(this);
    }

    public componentDidMount(): void {
        const { application, applicationProperties } = this.props;

        if (!application) {
            this.props.applicationGet();
        }

        if (!applicationProperties) {
            this.props.applicationPropertiesList();
        }
    }

    public render(): JSX.Element {
        const { application, applicationProperties } = this.props;
        const { editPropertyUuid, isPropertyAddressVersionUpgradeModalOpen, isPropertyModalOpen } = this.state;

        if (!application || !applicationProperties) {
            return (
                <Spin/>
            );
        }

        const columns: ColumnsType<IApplicationProperty> = [
            {
                dataIndex: 'dealProperty',
                render: (dealProperty: IProperty) => {
                    const onClick: () => void = () => this.onClickEditProperty(dealProperty.uuid);

                    return (
                        <Space>
                            <a onClick={onClick}>{dealProperty.formattedAddress}</a>
                            <PostcodeCategory property={dealProperty} />
                        </Space>
                    );
                },
                title: 'Address',
            },
            {
                dataIndex: 'dealProperty',
                render: (dealProperty: IProperty) => <Button href={`https://www.google.com/maps/place/${dealProperty.formattedAddress}`} target='_blank' type='link' danger={true}><FiMapPin /></Button>,
                width: '5%',
            },
            {
                dataIndex: 'valuationValue',
                render: (valuationValue: number, applicationProperty: IApplicationProperty) => {
                    if (valuationValue) {
                        return currencyFormatter.format(valuationValue);
                    }

                    if (applicationProperty.dealProperty.estimatedValue) {
                        return <Popover content='Estimated value - Professional property valuation required.'>{currencyFormatter.format(applicationProperty.dealProperty.estimatedValue)} <WarningOutlined/></Popover>;
                    }

                    return '-';
                },
                title: 'Property Value',
                width: '15%',
            },
            {
                dataIndex: 'dealProperty',
                render: (dealProperty: IProperty) => dealProperty.currentDebt ? currencyFormatter.format(dealProperty.currentDebt) : '-',
                title: 'Current Debt',
                width: '15%',
            },
            {
                dataIndex: 'dealProperty',
                render: (dealProperty: IProperty) => dealProperty.zoneType ? zoneTypeLabels[dealProperty.zoneType] : '-',
                title: 'Zoning',
                width: '20%',
            },
        ];

        return (
            <div className='application-properties'>
                <Button className='add-property' onClick={this.onClickAddProperty}>Add Property</Button>
                <Typography.Title level={2}>Properties</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(applicationProperties)}
                    pagination={false}
                    rowKey='uuid'
                />
                <PropertyAddModal
                    dealUuid={application.dealUuid}
                    isOpen={isPropertyModalOpen}
                    onCancel={this.onCloseAddProperty}
                />
                <PropertyEditModal
                    dealUuid={application.dealUuid}
                    onCancel={this.onCloseEditProperty}
                    onClickAddressVersionUpgradeButton={this.onClickPropertyUpgradeAddressVersion}
                    propertyUuid={editPropertyUuid}
                    isOpen={!!editPropertyUuid}
                />
                <PropertyAddressVersionUpgradeModal
                    dealUuid={application.dealUuid}
                    isOpen={isPropertyAddressVersionUpgradeModalOpen}
                    onCancel={this.closePropertyUpgradeAddressVersionModal}
                    propertyUuid={editPropertyUuid}
                />
            </div>
        );
    }

    private closePropertyUpgradeAddressVersionModal(): void {
        this.setState({
            isPropertyAddressVersionUpgradeModalOpen: false,
        });
    }

    private onClickAddProperty(): void {
        this.setState({
            isPropertyModalOpen: true,
        });
    }

    private onCloseAddProperty(): void {
        this.setState({
            isPropertyModalOpen: false,
        });
    }

    private onClickEditProperty(editPropertyUuid: string): void {
        this.setState({
            editPropertyUuid,
        });
    }

    private onClickPropertyUpgradeAddressVersion(): void {
        this.setState({
            isPropertyAddressVersionUpgradeModalOpen: true,
        });
    }

    private onCloseEditProperty(): void {
        this.setState({
            editPropertyUuid: null,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.applicationUuid),
        applicationProperties: applicationPropertiesSelector(state, ownProps.applicationUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationGet: () => dispatch(applicationGetAction(ownProps.applicationUuid)),
        applicationPropertiesList: () => dispatch(applicationPropertiesListAction(ownProps.applicationUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(applicationPropertiesList);
