enum InvestmentSaleTransfersActionsEnum {
    InvestmentSaleTransferGet = 'INVESTMENT_SALE_TRANSFER/GET',
    InvestmentSaleTransferSend = 'INVESTMENT_SALE_TRANSFER/SEND',
    InvestmentSaleTransferSet = 'INVESTMENT_SALE_TRANSFER/SET',

    InvestmentSaleTransferHistoriesList = 'INVESTMENT_SALE_TRANSFER/HISTORIES/LIST',
    InvestmentSaleTransferHistoriesSet = 'INVESTMENT_SALE_TRANSFER/HISTORIES/SET',

    InvestmentSaleTransferInvestorAccountInvestmentTransactionsList = 'INVESTMENT_SALE_TRANSFER/INVESTOR_INVESTMENT_TRANSACTIONS/LIST',
    InvestmentSaleTransferInvestorAccountInvestmentTransactionsSet = 'INVESTMENT_SALE_TRANSFER/INVESTOR_INVESTMENT_TRANSACTIONS/SET',

    InvestmentSaleTransfersAdd = 'INVESTMENT_SALE_TRANSFERS/ADD',
    InvestmentSaleTransfersList = 'INVESTMENT_SALE_TRANSFERS/LIST',
    InvestmentSaleTransfersSet = 'INVESTMENT_SALE_TRANSFERS/SET',

    InvestmentSaleTransfersUnallocatedInvestmentsList = 'INVESTMENT_SALE_TRANSFERS/UNALLOCATED_INVESTOR_INVESTMENT_TRANSACTIONS/LIST',
    InvestmentSaleTransfersUnallocatedInvestmentsSet = 'INVESTMENT_SALE_TRANSFERS/UNALLOCATED_INVESTOR_INVESTMENT_TRANSACTIONS/SET',
}

export default InvestmentSaleTransfersActionsEnum;
